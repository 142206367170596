import { put, takeEvery } from 'typed-redux-saga';

import { loadRuns } from '../runs.slice';
import { setItemsPerPage, setRunsPage } from './pagination.slice';

function* setPageHandler() {
  yield* put(loadRuns());
}

export function* runsPaginationSaga() {
  yield* takeEvery([setRunsPage, setItemsPerPage], setPageHandler);
}
