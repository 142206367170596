import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { VideoFilters, Video } from '../../../../../api/domainModels/video';
import {
  LoadingState,
  loadingStateBuilder,
} from '../../../../utils/loadingState';
import { VideoStatus } from '../../../../../api/constants/videos';

export const fileManagerVideoAdapter = createEntityAdapter<Video>();
export const FILE_MANAGER_PER_PAGE = 100;

export type FileManagerVideoFilters = Omit<VideoFilters, 'offset' | 'limit'> & {
  page: number;
  perPage: number;
};

export const defaultFilters: FileManagerVideoFilters = {
  datasetId: undefined,
  videoStatus: [],
  activity: [],
  videoName: '',
  page: 1,
  perPage: FILE_MANAGER_PER_PAGE,
};

export type FileManagerVideosState = {
  loadingState: LoadingState;
  actionLoadingState: LoadingState;
  editedVideoId: string | null;
  filters: FileManagerVideoFilters;
  allDatasetsTotalVideos: number | null;
  selectedDatasetTotalVideos: number | null;
  selectedIds: string[];
};

const { actions, reducer: videosReducer } = createSlice({
  name: 'fileManager/videos',
  initialState: fileManagerVideoAdapter.getInitialState<FileManagerVideosState>(
    {
      loadingState: loadingStateBuilder.initial(),
      actionLoadingState: loadingStateBuilder.initial(),
      editedVideoId: null,
      filters: defaultFilters,
      allDatasetsTotalVideos: null,
      selectedDatasetTotalVideos: null,
      selectedIds: [],
    },
  ),
  reducers: {
    loadVideos(
      state,
      {
        payload = defaultFilters,
      }: PayloadAction<FileManagerVideoFilters | undefined>,
    ) {
      fileManagerVideoAdapter.setAll(state, []);
      state.selectedIds = [];
      state.filters = payload;
      state.loadingState = loadingStateBuilder.inProgress();
    },
    loadVideosSuccess(
      state,
      action: PayloadAction<{
        items: Video[];
        total: number;
        allDatasetsSelected: boolean;
      }>,
    ) {
      const { items, total, allDatasetsSelected } = action.payload;

      state.loadingState = loadingStateBuilder.success();
      fileManagerVideoAdapter.setAll(state, items);
      state.selectedDatasetTotalVideos = total;

      if (allDatasetsSelected) {
        state.allDatasetsTotalVideos = total;
      }
    },
    loadVideosFailure(state, { payload }: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.failure(payload);
    },
    setSelectedVideoIds(state, { payload }: PayloadAction<string[]>) {
      state.selectedIds = payload;
    },
    deleteSelectedVideos(state) {
      if (state.selectedIds.length) {
        state.actionLoadingState = loadingStateBuilder.inProgress();
      }
    },
    moveSelectedVideos(state, _action: PayloadAction<{ datasetId: string }>) {
      if (state.selectedIds.length) {
        state.actionLoadingState = loadingStateBuilder.inProgress();
      }
    },
    changeStatusSelectedVideos(
      state,
      _action: PayloadAction<{ status: VideoStatus; videoId?: string }>,
    ) {
      if (state.selectedIds.length) {
        state.actionLoadingState = loadingStateBuilder.inProgress();
      }
    },
    videoActionSuccess(state) {
      state.actionLoadingState = loadingStateBuilder.success();
      state.selectedIds = [];
    },
    setEditedVideoId(state, action: PayloadAction<string | null>) {
      state.editedVideoId = action.payload;
    },
    updateVideo(
      state,
      _action: PayloadAction<{ id: string; filename: string }>,
    ) {
      state.actionLoadingState = loadingStateBuilder.inProgress();
    },
    updateVideoSuccess(state, action: PayloadAction<Video>) {
      state.actionLoadingState = loadingStateBuilder.success();
      fileManagerVideoAdapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      });
    },
    clearVideos(state) {
      fileManagerVideoAdapter.removeAll(state);
    },
    updateVideoFailure(state, action: PayloadAction<string>) {
      state.actionLoadingState = loadingStateBuilder.failure(action.payload);
    },
  },
});

export { videosReducer };
export const {
  loadVideos,
  loadVideosFailure,
  loadVideosSuccess,
  setSelectedVideoIds,
  deleteSelectedVideos,
  moveSelectedVideos,
  changeStatusSelectedVideos,
  videoActionSuccess,
  updateVideo,
  updateVideoSuccess,
  updateVideoFailure,
  setEditedVideoId,
  clearVideos,
} = actions;
