import { createSelector, Dictionary } from '@reduxjs/toolkit';

import {
  DEFAULT_GROUP_ID,
  DEFAULT_GROUP_NAME,
  DEFAULT_GROUP_TYPE,
  TagGroup,
} from '../../../../../api/domainModels/tagGroup';
import { tagGroupsAdapter } from './tagGroups.slice';

const tagGroupsStateSelector = (state: RootState) =>
  state.project.annotationTaxonomy.tagGroups;
const selectors = tagGroupsAdapter.getSelectors(tagGroupsStateSelector);

export const tagGroupsSelector = selectors.selectAll;
export const tagGroupByIdSelector = selectors.selectById;
export const tagGroupsMapSelector = selectors.selectEntities;

export const tagGroupsListLoadingStateSelector = createSelector(
  tagGroupsStateSelector,
  (state) => state.listLoadingState,
);
export const tagGroupsItemLoadingStateSelector = createSelector(
  tagGroupsStateSelector,
  (state) => state.itemLoadingState,
);

export const tagGroupsWithDefaultSelector = createSelector(
  [tagGroupsSelector],
  (groups) => [
    {
      name: DEFAULT_GROUP_NAME,
      id: DEFAULT_GROUP_ID,
      type: DEFAULT_GROUP_TYPE,
      norder: 0,
    },
    ...groups,
  ],
);

export const tagGroupsMapWithDefaultSelector = createSelector(
  [tagGroupsMapSelector],
  (groups) =>
    ({
      [DEFAULT_GROUP_ID]: {
        name: DEFAULT_GROUP_NAME,
        id: DEFAULT_GROUP_ID,
        type: DEFAULT_GROUP_TYPE,
        norder: 0,
      },
      ...groups,
    } as Dictionary<TagGroup>),
);

export const tagGroupsAsOptionsSelector = createSelector(
  [tagGroupsSelector],
  (groups) =>
    groups.map(({ id, name }) => ({
      key: id,
      text: name,
      value: id,
    })),
);

export const tagGroupsAsOptionsWithDefaultSelector = createSelector(
  [tagGroupsAsOptionsSelector],
  (options) => [
    {
      text: DEFAULT_GROUP_NAME,
      key: DEFAULT_GROUP_ID,
      value: DEFAULT_GROUP_ID,
    },
    ...options,
  ],
);
