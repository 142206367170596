import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
  Update,
} from '@reduxjs/toolkit';

import { FrameImportJob } from '../../../../../api/domainModels/frameImports';
import { setProjectId } from '../../../core/imageView/project/project.slice';

export const adapter = createEntityAdapter<FrameImportJob>();
const initialState = adapter.getInitialState();

const { actions, reducer: frameImportsReducer } = createSlice({
  name: 'frameImports',
  initialState,
  reducers: {
    uploadVideoFrames(
      _state,
      _action: PayloadAction<{
        files: File[];
        frames: number;
        duration: number;
      }>,
    ) {},
    loadFrameImportsStart() {},
    loadFrameImportsSuccess(state, action: PayloadAction<FrameImportJob[]>) {
      adapter.addMany(state, action.payload);
    },
    startFrameImportJobStart(
      _state,
      _action: PayloadAction<{
        projectId: string;
        datasetId: string;
        filename: string;
        uploadId: string;
        frames: number;
        duration: number;
      }>,
    ) {},
    startFrameImportJobSuccess(state, action: PayloadAction<FrameImportJob>) {
      adapter.addOne(state, action.payload);
    },
    retryFrameImportStart(_state, _action: PayloadAction<string>) {},
    retryFrameImportSuccess(
      state,
      action: PayloadAction<Update<FrameImportJob>>,
    ) {
      adapter.updateOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setProjectId, () => initialState);
  },
});

export { frameImportsReducer };
export const {
  uploadVideoFrames,
  retryFrameImportStart,
  retryFrameImportSuccess,
  loadFrameImportsStart,
  loadFrameImportsSuccess,
  startFrameImportJobStart,
  startFrameImportJobSuccess,
} = actions;
