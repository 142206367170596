import { WORKSPACE_OWNER_ROLE_ID } from '../../redux/state/sections/managedWorkspace/managedWorkspace.constants';
import { ApiRoleParams } from '../schemas/workspaceRoles';
import { ApiRole, Role } from './role';

const fromBackend = ({ roleId, roleName, color }: ApiRole): Role => ({
  id: roleId,
  name: roleName,
  color,
  readOnly: roleId === WORKSPACE_OWNER_ROLE_ID,
});

export type RoleParams = ApiRoleParams;

export type WorkspaceRole = ReturnType<typeof fromBackend>;

export const workspaceRoleDataMapper = {
  fromBackend,
};
