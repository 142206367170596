import { call, put, takeEvery, select } from 'typed-redux-saga';

import { apiGetWidgetClassificationInferences } from '../../../../../../../api/requests/widgets';
import { getErrorMessage } from '../../../../../../../api/utils';
import { activeProjectIdSelector } from '../../../../../project/project.selectors';
import {
  loadClassificationInferences,
  loadClassificationInferencesSuccess,
  loadClassificationInferencesFailure,
  resetWidget,
} from './classificationInferences.slice';
import { dashboardActiveExperimentSelector } from '../../activeExperiment/activeExperiment.selectors';
import { refetchWidgetData } from '../widgetsLiveUpdate/widgetsLiveUpdate.actions';
import {
  classificationInferencesLimitSelector,
  classificationInferencesOffsetSelector,
} from './classificationInferences.selectors';
import { activeSplitIdSelector } from '../../../activeSplit/activeSplitId/activeSplitId.selectors';
import { splitByIdSelector } from '../../../splits/data/data.selectors';
import { setActiveExperimentId } from '../../activeExperiment/activeExperiment.slice';
import { enqueueNotification } from '../../../../../ui/stackNotifications/stackNotifications.slice';
import { ModelFamily } from '../../../../../../../api/constants/modelFamily';

function* loadDataHandler(
  action: ActionType<
    typeof loadClassificationInferences | typeof refetchWidgetData
  >,
) {
  const projectId = yield* select(activeProjectIdSelector);
  const experiment = yield* select(dashboardActiveExperimentSelector);
  const activeSplitId = yield* select(activeSplitIdSelector);

  if (!experiment || typeof experiment.iterationNum !== 'number') return;

  if (action.type === refetchWidgetData.type && activeSplitId) {
    const activeSplit = yield* select((state: RootState) =>
      splitByIdSelector(state, activeSplitId),
    );

    if (!activeSplit) {
      return;
    }

    const { modelFamily } = activeSplit;

    if (modelFamily !== ModelFamily.Classifier) {
      return;
    }
  }

  const limit =
    action?.payload?.limit === undefined
      ? yield* select(classificationInferencesLimitSelector)
      : action?.payload?.limit;
  const offset =
    action?.payload?.offset === undefined
      ? yield* select(classificationInferencesOffsetSelector)
      : action?.payload?.offset;

  try {
    const { data } = yield* call(apiGetWidgetClassificationInferences, {
      projectId,
      experimentId: experiment.id,
      limit,
      offset,
      iterationId: experiment.iterationNum,
      ...action?.payload,
    });

    const { items, meta } = data;

    yield* put(
      loadClassificationInferencesSuccess({
        meta,
        items: items.map((item) => ({
          id: `${item.imageId}-${item.labelId}-${item.iteration}`,
          ...item,
        })),
      }),
    );
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to fetch widget data');

    yield* put(loadClassificationInferencesFailure(message));

    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
          refresh: false,
        },
      }),
    );
  }
}

export function* setActiveExperimentIdHandler() {
  yield* put(resetWidget());
}

export function* classificationInferenceSaga() {
  yield* takeEvery(
    [loadClassificationInferences, refetchWidgetData],
    loadDataHandler,
  );
  yield* takeEvery(setActiveExperimentId, setActiveExperimentIdHandler);
}
