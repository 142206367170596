import { createSelector } from '@reduxjs/toolkit';

import { keypointsSchemasAdapter } from './keypointsSchemas.slice';

const keypointsStateSelector = (state: RootState) =>
  state.project.annotationTaxonomy.keypointsSchemas;

const selectors = keypointsSchemasAdapter.getSelectors(keypointsStateSelector);
export const keypointsSchemasSelector = selectors.selectAll;
export const keypointsSchemaByIdSelector = selectors.selectById;
export const keypointsSchemasMapSelector = selectors.selectEntities;
export const keypointsSchemasKeypointsClassesSelector = createSelector(
  keypointsSchemasSelector,
  (schemas) =>
    schemas
      .map(({ keypointClasses }) => keypointClasses)
      .flat()
      .filter(Boolean),
);
export const keypointsSchemasItemLoadingStateSelector = createSelector(
  keypointsStateSelector,
  (state) => state.itemLoadingState,
);
export const keypointsSchemasLoadingStateSelector = createSelector(
  keypointsStateSelector,
  (state) => state.listLoadingState,
);

export const keypointSchemasAsOptionsSelector = createSelector(
  keypointsSchemasSelector,
  (schemas) =>
    schemas.map(({ id, name }) => ({
      key: id,
      text: name,
      value: id,
    })),
);
export const keypointSchemasAsOptionsWithAllSelector = createSelector(
  keypointSchemasAsOptionsSelector,
  (options) => [
    {
      text: 'All',
      key: 'all',
      value: null,
    },
    ...options,
  ],
);
