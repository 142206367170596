import { put, select, takeEvery } from 'typed-redux-saga';

import {
  acceptKeypointsResult,
  addKeypoints,
  discardKeypointsValues,
  redoKeypoints,
  removeKeypoint,
  undoKeypoints,
} from './keypointsValues.slice';
import { addLabels, updateLabels } from '../../../labels/labels.slice';
import { LabelType } from '../../../../../../../api/constants/label';
import { ImageTool } from '../../tools.constants';
import {
  keypointsValuesEditedLabelChangedSelector,
  keypointsValuesPointsSelector,
} from './keypointsValues.selectors';
import {
  activeLabelClassIdSelector,
  activeLabelClassSelector,
} from '../../../labelClasses/labelClasses.selectors';
import { editedKeypointsLabelSelector } from '../../../labels/selectedLabels/selectedLabels.selectors';
import { resetActiveTool, trySetActiveTool } from '../../tools.slice';
import { resetKeypoints } from '../keypoints.actions';
import { uuidv4 } from '../../../../../../../util/uuidv4';
import { labelClassKeypointsSchemaClassesSelector } from '../../../../../project/annotationTaxonomy/annotationTaxonomy.selectors';
import { setKeypointsClassIndex } from '../keypointsEditingState/keypointsEditingState.slice';
import { getAvailableKeypoints } from '../../../../../../../util/keypoints';

function* acceptKeypointsResultHandler() {
  const editedLabel = yield* select(editedKeypointsLabelSelector);
  const editedLabelChanged = yield* select(
    keypointsValuesEditedLabelChangedSelector,
  );
  const keypoints = yield* select(keypointsValuesPointsSelector);
  const activeLabelClassId = yield* select(activeLabelClassIdSelector);

  if ((editedLabel && !editedLabelChanged) || !keypoints.length) {
    yield* put(trySetActiveTool(ImageTool.Selection));

    return;
  }

  if (editedLabel) {
    const keypointsIds = keypoints.map((keypoint) => keypoint.id);
    const deletedKeypoints =
      editedLabel.keypoints?.filter(
        (keypoint) => !keypointsIds.includes(keypoint.id),
      ) || [];

    yield* put(
      updateLabels([
        {
          id: editedLabel.id,
          changes: {
            keypoints: [
              ...keypoints,
              ...deletedKeypoints.map((keypoint) => ({
                ...keypoint,
                isDeleted: true,
              })),
            ],
          },
        },
      ]),
    );
    yield* put(resetActiveTool());
  } else {
    yield* put(
      addLabels([
        {
          id: uuidv4(),
          classId: activeLabelClassId,
          keypoints,
          type: LabelType.Keypoints,
          toolUsed: ImageTool.Keypoints,
        },
      ]),
    );
    yield* put(resetKeypoints());
  }
}

function* discardKeypointsValuesHandler() {
  const editedLabel = yield* select(editedKeypointsLabelSelector);

  if (editedLabel) {
    yield* put(
      addKeypoints({
        points: getAvailableKeypoints(editedLabel.keypoints),
      }),
    );
  } else {
    yield* put(
      addKeypoints({
        points: [],
      }),
    );
  }
}

function* updateKeypointsClassIndex() {
  const keypointsValuesPoints = yield* select(keypointsValuesPointsSelector);
  const activeLabelClass = yield* select(activeLabelClassSelector);

  const labelClassKeypointsSchemaClasses = yield* select((state) =>
    labelClassKeypointsSchemaClassesSelector(state, activeLabelClass?.id),
  );

  const lastItemIndex = keypointsValuesPoints.length
    ? labelClassKeypointsSchemaClasses.findIndex(
        (item) =>
          item.id ===
          keypointsValuesPoints[keypointsValuesPoints.length - 1]
            .keypointClassId,
      )
    : -1;

  yield* put(
    setKeypointsClassIndex({
      index: lastItemIndex + 1,
    }),
  );
}

export function* keypointsValuesSaga() {
  yield* takeEvery(acceptKeypointsResult, acceptKeypointsResultHandler);
  yield* takeEvery(discardKeypointsValues, discardKeypointsValuesHandler);
  yield* takeEvery(
    [undoKeypoints, redoKeypoints, removeKeypoint],
    updateKeypointsClassIndex,
  );
}
