import { call, takeEvery, put, select } from 'typed-redux-saga';

import {
  apiLoadFrameImportJobs,
  apiLoadVideoUploadData,
  apiRetryFrameImportJob,
  apiStartFrameImportJob,
} from '../../../../../api/requests/frameImports';
import { getErrorMessage } from '../../../../../api/utils';
import { handleError } from '../../../commonFeatures/errorHandler/errorHandler.actions';
import { activeProjectIdSelector } from '../../../project/project.selectors';
import { selectedDatasetIdSelector } from '../selectedDataset/selectedDataset.selectors';
import { uploadVideoFile } from '../videoUpload/videoUpload.saga';
import { uploadVideoFailure } from '../videoUpload/videoUpload.slice';
import {
  startFrameImportJobStart,
  startFrameImportJobSuccess,
  loadFrameImportsStart,
  loadFrameImportsSuccess,
  retryFrameImportStart,
  uploadVideoFrames,
  retryFrameImportSuccess,
} from './frameImports.slice';

function* uploadVideoFramesHandler(
  action: ActionType<typeof uploadVideoFrames>,
) {
  const projectId = yield* select(activeProjectIdSelector);
  const datasetId = yield* select(selectedDatasetIdSelector);
  const videoFiles = action.payload.files;

  try {
    const uploadDataItems = (yield* call(apiLoadVideoUploadData, {
      projectId,
      data: {
        count: videoFiles.length,
      },
    })).data.items;

    if (datasetId) {
      for (let i = 0; i < videoFiles.length; i += 1) {
        const isUploadSuccess = yield* uploadVideoFile({
          uploadData: uploadDataItems[i],
          file: videoFiles[i],
          projectId,
          datasetId,
        });

        if (!isUploadSuccess) {
          return;
        }

        yield* put(
          startFrameImportJobStart({
            projectId,
            datasetId,
            filename: videoFiles[i].name,
            uploadId: uploadDataItems[i].id,
            frames: action.payload.frames,
            duration: action.payload.duration,
          }),
        );
      }
    }
  } catch (error) {
    const errorMessage = getErrorMessage(
      error,
      'Not able to start frame extraction',
    );

    yield* put(
      handleError({
        message: errorMessage,
        allowOutsideOfEditor: true,
        error,
      }),
    );
  }
}

function* startFrameImportJobStartHandler(
  action: ActionType<typeof startFrameImportJobStart>,
) {
  const { projectId, ...params } = action.payload;

  try {
    const { data } = yield* call(apiStartFrameImportJob, {
      projectId,
      params,
    });

    yield* put(startFrameImportJobSuccess(data));
  } catch (error) {
    const { filename, uploadId } = params;

    const message = getErrorMessage(
      error,
      `Video ${filename} validation has failed`,
    );

    yield* put(uploadVideoFailure({ id: uploadId, message }));

    yield* put(
      handleError({
        message,
        allowOutsideOfEditor: true,
        error,
      }),
    );
  }
}

function* loadFrameImportsStartHandler() {
  const projectId = yield* select(activeProjectIdSelector);

  const { data } = yield* call(apiLoadFrameImportJobs, {
    projectId,
  });
  yield* put(loadFrameImportsSuccess(data.items));
}

function* retryFrameImportStartHandler(
  action: ActionType<typeof retryFrameImportStart>,
) {
  const projectId = yield* select(activeProjectIdSelector);

  const { data } = yield* call(apiRetryFrameImportJob, {
    projectId,
    frameImportId: action.payload,
  });

  yield* put(retryFrameImportSuccess({ id: data.id, changes: data }));
}

export function* frameImportsSaga() {
  yield* takeEvery(loadFrameImportsStart, loadFrameImportsStartHandler);
  yield* takeEvery(retryFrameImportStart, retryFrameImportStartHandler);
  yield* takeEvery(uploadVideoFrames, uploadVideoFramesHandler);
  yield* takeEvery(startFrameImportJobStart, startFrameImportJobStartHandler);
}
