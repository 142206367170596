// I needed to convert it to a string value as it was assumed to be non-null across the app.
// However, if I change it to its actual type `(string | null)`, TypeScript will throw an error.
// We could fix this by adding checks like `if (!activeProjectId) return null` everywhere it's used,
// but that would require significant changes. It's a daunting task, to say the least.
export const activeProjectIdSelector: (state: RootState) => string = (
  state: RootState,
) =>
  state.sections.editedProject?.project.projectId ||
  state.core.imageView.project.projectParams?.projectId ||
  state.core.videoView?.project.projectId ||
  '';

export const projectSelector = (state: RootState) => state.project;
