import { AxiosResponse } from 'axios';

import { ErrorParams } from '../api/utils';

type LoadAllParams = {
  apiHelper: (...params: any) => any;
  requestParams?: object;
  requestData?: object;
  errorParams?: ErrorParams;
  fromBackend?: (...params: any) => any;
};

export const loadAll = async ({
  apiHelper,
  requestParams = {},
  requestData = {},
  errorParams = {},
  fromBackend = (entity: any) => entity,
}: LoadAllParams) => {
  const entities: any[] = [];

  const { data }: AxiosResponse = await apiHelper(
    { params: requestParams, data: requestData },
    errorParams,
  );

  entities.push(...data.items.map(fromBackend));

  const { limit, total } = data.meta;

  const additionalRequests = [];
  for (let i = 1; i <= Math.ceil(total / limit) - 1; i++) {
    additionalRequests.push(
      apiHelper(
        {
          params: {
            ...requestParams,
            pagination: {
              offset: limit * i,
              limit,
            },
          },
          data: requestData,
        },
        errorParams,
      ),
    );
  }
  const responses: Array<AxiosResponse> = await Promise.all(additionalRequests);

  entities.push(
    ...responses.map((response) => response.data.items.map(fromBackend)).flat(),
  );

  return entities;
};
