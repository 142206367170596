import { call, put, takeEvery, select } from 'typed-redux-saga';

import { apiLoadWidgetExperimentsMetrics } from '../../../../../../../api/requests/widgets';
import { getErrorMessage } from '../../../../../../../api/utils';
import { activeProjectIdSelector } from '../../../../../project/project.selectors';
import {
  loadBestPerforming,
  loadBestPerformingSuccess,
  loadBestPerformingFailure,
} from './bestPerforming.slice';
import { widgetBestPerformingMapper } from '../../../../../../../api/domainModels/widgets';
import { enqueueNotification } from '../../../../../ui/stackNotifications/stackNotifications.slice';
import { activeSplitDefaultMetricsSelector } from '../../../activeSplit/activeSplitId/activeSplitId.selectors';

function* loadBestPerformingHandler(
  action: ActionType<typeof loadBestPerforming>,
) {
  const projectId = yield* select(activeProjectIdSelector);
  const experimentIds = action.payload;
  const activeSplitDefaultMetrics = yield* select(
    activeSplitDefaultMetricsSelector,
  );

  try {
    const { data } = yield* call(
      apiLoadWidgetExperimentsMetrics,
      {
        projectId,
      },
      { experimentIds },
    );

    const items = data.items.map((item) =>
      widgetBestPerformingMapper.fromBackend({
        item,
        defaultMetricName: activeSplitDefaultMetrics?.metricName,
      }),
    );

    yield* put(loadBestPerformingSuccess(items));
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to fetch widget data');

    yield* put(loadBestPerformingFailure(message));
    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
          refresh: false,
        },
      }),
    );
  }
}

export function* bestPerformingSaga() {
  yield* takeEvery(loadBestPerforming, loadBestPerformingHandler);
}
