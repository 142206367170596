import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { API_URL } from './constants/paths';
import { enhancedHistory } from './redux/config/enhancedHistory';
import { sentryModalFeature } from './util/features';

const ignoreKP = true;
const isKP = () => window.REACT_APP_SENTRY_ENV === 'kp';

const getSentryEnv = () => {
  if (window.REACT_APP_SENTRY_ENV !== 'kp') {
    return window.REACT_APP_SENTRY_ENV;
  }

  const regex = /app\b(\w+)\bnone/gi;
  const result = regex.exec(window.location.hostname);
  if (result && result[1]) {
    return `kp-${result[1]}`;
  }

  return 'kp-00';
};

export const setupSentry = () => {
  const sentryConfig: Sentry.BrowserOptions = {
    dsn: window.REACT_APP_SENTRY_DSN,
    release: `frontend@${window.REACT_APP_VERSION}`,
    environment: getSentryEnv(),
    normalizeDepth: 10,
    attachStacktrace: true,
    ignoreErrors: [
      new RegExp('500'),
      new RegExp('401'),
      new RegExp('403'),
      new RegExp('Network error'),
      new RegExp('Network Error'),
    ],
    beforeSend(event) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception && sentryModalFeature) {
        Sentry.showReportDialog({ eventId: event.event_id });
      }

      return event;
    },
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation:
          Sentry.reactRouterV5Instrumentation(enhancedHistory),
        tracingOrigins: [new URL(API_URL).hostname],
      }),
    ],
    tracesSampleRate: 0.1, // 10% for now, in order to not consume everything in the package
    enabled:
      window.REACT_APP_VERSION !== 'local' && (ignoreKP ? !isKP() : true),
  };

  Sentry.init(sentryConfig);
};
