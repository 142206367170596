import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingState, loadingStateBuilder } from '../../../utils/loadingState';
import { CreateProjectReportsData } from '../../../../api/domainModels/project';
import {
  AggEntity,
  Kpi,
} from '../../../../api/domainModels/projectReportEntities';

export type CreateProjectReportPayload = CreateProjectReportsData;

export type ProjectReportFormValues = Omit<
  CreateProjectReportPayload,
  'startDate' | 'endDate'
> & {
  timeRange: [Date, Date] | null;
};

type ProjectOperationsState = {
  projectId: string | null;
  reportEntities: {
    aggEntities: AggEntity[];
    kpis: Kpi[];
    loadingState: LoadingState;
  };
};

const initialState: ProjectOperationsState = {
  projectId: null,
  reportEntities: {
    aggEntities: [],
    kpis: [],
    loadingState: loadingStateBuilder.initial(),
  },
};

const { actions, reducer: projectOperationsReducer } = createSlice({
  name: 'projectOperations',
  initialState,
  reducers: {
    triggerProjectReport(
      _state,
      _action: PayloadAction<
        {
          projectId: string;
        } & CreateProjectReportPayload
      >,
    ) {},
    loadProjectOperationsReportEntities(state) {
      state.reportEntities = {
        aggEntities: [],
        kpis: [],
        loadingState: loadingStateBuilder.inProgress(
          'Loading report configuration',
        ),
      };
    },
    loadProjectOperationsReportEntitiesSuccess(
      state,
      action: PayloadAction<{
        kpis: Kpi[];
        aggEntities: AggEntity[];
      }>,
    ) {
      state.reportEntities = {
        aggEntities: action.payload.aggEntities,
        kpis: action.payload.kpis,
        loadingState: loadingStateBuilder.success(),
      };
    },
    loadProjectOperationsReportEntitiesFailure(
      state,
      action: PayloadAction<string>,
    ) {
      state.reportEntities = {
        kpis: [],
        aggEntities: [],
        loadingState: loadingStateBuilder.failure(action.payload),
      };
    },
  },
});

export { projectOperationsReducer };

export const {
  triggerProjectReport,
  loadProjectOperationsReportEntities,
  loadProjectOperationsReportEntitiesFailure,
  loadProjectOperationsReportEntitiesSuccess,
} = actions;
