import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum ActionTrigger {
  Discard = 'discard',
  Accept = 'accept',
  Undo = 'undo',
  Redo = 'redo',
  Skip = 'skip',
  Occlude = 'occlude',
  Invert = 'invert',
  FillClosed = 'fillClosed',
  Convert = 'convert',
  ToggleUseSAM = 'toggleUseSAM',
}
const { actions, reducer: toolbarActionTriggerReducer } = createSlice({
  name: 'toolbarActionTrigger',
  initialState: null as ActionTrigger | null,
  reducers: {
    triggerAction(state, action: PayloadAction<ActionTrigger>) {
      return action.payload;
    },
    resetTrigger() {
      return null;
    },
  },
});
export { toolbarActionTriggerReducer };
export const { triggerAction, resetTrigger } = actions;
