/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Type of error
 */
export enum CLErrorTypeQuery {
  NOT_AN_ERROR = 'NOT_AN_ERROR',
  MISCLASSIFICATION = 'MISCLASSIFICATION',
}
