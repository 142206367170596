import {
  ExperimentParameterValue,
  ExperimentParameter,
} from '../../../../../api/domainModels/modelPlayground';
import { ParameterType } from '../../../../../api/constants/modelPlayground';

export const parseParameterValue = (
  parameter: ExperimentParameter,
  value: ExperimentParameterValue['value'],
) => {
  const { type, parameterValues, items } = parameter;
  // if parameter is of type array we have to parse it depending on it's items type
  if (type === ParameterType.Array) {
    let valueArray;

    if (parameterValues && parameterValues.length > 0) {
      valueArray = value;
    }
    // if it's a metric array it's a multiselect
    if (items?.type === ParameterType.Metric) {
      valueArray = value;
    }

    // if it should be an int parse it as ints
    if (items?.type === ParameterType.Integer) {
      valueArray = (value as string[]).map((value: string) =>
        Number.parseInt(value, 10),
      );
    }
    // if it should be a number parse it as numbers
    if (items?.type === ParameterType.Number) {
      // @TODO: probably no need to parseFloat since the number input already does this
      valueArray = (value as string[]).map((value: string) =>
        parseFloat(value),
      );
    }
    // if the values are of type array ([[1, 2, 3]] or [[1], [2], [3]])
    if (items?.type === ParameterType.Array && Array.isArray(value)) {
      valueArray = value;
    }

    return valueArray;
  }
  if (type === ParameterType.Integer && typeof value === 'string') {
    return Number.parseInt(value, 10);
  }
  if (type === ParameterType.Number && typeof value === 'string') {
    return parseFloat(value);
  }

  return value;
};
