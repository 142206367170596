import { createSelector } from '@reduxjs/toolkit';

import { imageViewSelector } from '../../../imageView.selectors';

const atomEditingStateSelector = createSelector(
  imageViewSelector,
  (imageView) => imageView.tools.atom.editingState.present,
);

export const atomEditingStateUseViewportSelector = createSelector(
  atomEditingStateSelector,
  (editingState) => editingState.useViewport,
);
export const atomEditingStateStrategySelector = createSelector(
  atomEditingStateSelector,
  (editingState) => editingState.strategy,
);
export const atomEditingStateMaskSizeLevelSelector = createSelector(
  atomEditingStateSelector,
  (editingState) => editingState.maskSizeLevel,
);
export const atomEditingStateBrushSizeSelector = createSelector(
  atomEditingStateSelector,
  (editingState) => editingState.brushSize,
);
