import axios from 'axios';

import { apiWrapper } from '../utils';
import {
  ApiDeleteResponse,
  headers,
  transformRequest,
  transformResponse,
} from '../helpers';
import { API_URL_V2, NEW_API_URL } from '../../constants/paths';
import {
  ApiKeypointSchema,
  ApiDeleteKeypointSchemaParams,
  ApiUpdateKeypointSchemaParams,
  ApiUpdateAssistantKeypointSchemaParams,
  ApiKeypointTemplate,
  ApiUpsertKeypointsSchemaFormValues,
} from '../schemas/keypoints';

export const apiLoadKeypointsSchemas = ({ projectId }: { projectId: string }) =>
  apiWrapper(
    axios.request<ApiKeypointSchema[]>({
      method: 'get',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${projectId}/keypoint_schemas`,
    }),
  );

export const apiAddKeypointsSchema = (
  { projectId }: { projectId: string },
  data: ApiUpsertKeypointsSchemaFormValues,
) =>
  apiWrapper(
    axios.request<ApiKeypointSchema>({
      method: 'post',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${projectId}/keypoint_schemas`,
      data,
    }),
  );

export const apiDeleteKeypointSchema = (
  params: ApiDeleteKeypointSchemaParams,
) =>
  apiWrapper(
    axios.request<ApiDeleteResponse>({
      method: 'delete',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/keypoint_schemas/${params.schemaId}`,
    }),
  );

export const apiUpdateKeypointSchema = (
  params: ApiUpdateKeypointSchemaParams,
  data: ApiUpsertKeypointsSchemaFormValues,
) =>
  apiWrapper(
    axios.request<ApiKeypointSchema>({
      method: 'put',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/keypoint_schemas/${params.schemaId}`,
      data,
    }),
  );

export const apiLoadKeypointsTemplates = ({
  projectId,
}: {
  projectId: string;
}) =>
  apiWrapper(
    axios.request<ApiKeypointTemplate[]>({
      method: 'get',
      headers,
      transformRequest,
      transformResponse,
      url: `${API_URL_V2}/projects/${projectId}/keypoint_templates`,
    }),
  );

export const apiLoadAssistantKeypointSchema = ({
  projectId,
}: {
  projectId: string;
}) =>
  apiWrapper(
    axios.request<ApiKeypointSchema | void>({
      method: 'get',
      headers,
      transformRequest,
      transformResponse,
      url: `${API_URL_V2}/projects/${projectId}/assistant_keypoint_schema`,
    }),
  );

export const apiUpdateAssistantKeypointSchema = (
  params: ApiUpdateAssistantKeypointSchemaParams,
  data: { keypointSchemaId: string },
) =>
  apiWrapper(
    axios.request<string[]>({
      method: 'put',
      headers,
      transformRequest,
      transformResponse,
      url: `${API_URL_V2}/projects/${params.projectId}/assistant_keypoint_schema`,
      data,
    }),
  );
