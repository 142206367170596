import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { Lock } from '../../../../api/domainModels/lock';
import { loadingStateBuilder } from '../../../utils/loadingState';

export const adapter = createEntityAdapter<Lock>({
  selectId: (lock) => lock.subjectId,
});

const { actions, reducer: locksReducer } = createSlice({
  name: 'locks',
  initialState: adapter.getInitialState({
    loadingState: loadingStateBuilder.initial(),
    loaded: false,
  }),
  reducers: {
    loadSubjectLocks(state, _action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.inProgress('Loading locks');
    },
    loadSubjectLocksError(state, action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.failure(action.payload);
    },
    loadSubjectLocksSuccess(state, action: PayloadAction<Lock[]>) {
      adapter.setAll(state, action.payload);
      state.loadingState = loadingStateBuilder.success();
      state.loaded = true; // TODO:: success should be enough?
    },
    subjectLockedSuccess(state, action: PayloadAction<Lock[]>) {
      adapter.addMany(state, action.payload);
    },
    subjectUnlockedSuccess(state, action: PayloadAction<Lock[]>) {
      adapter.removeMany(
        state,
        action.payload.map((lock) => lock.subjectId),
      );
    },
    lockSubject(
      _state,
      _action: PayloadAction<{ projectId: string; subjectId: string }>,
    ) {},
    unlockSubject(
      _state,
      _action: PayloadAction<{ projectId: string; lock: Lock }>,
    ) {},
    renewSubjectLock(
      _state,
      _action: PayloadAction<{ projectId: string; lock: Lock }>,
    ) {},
  },
});
export const {
  loadSubjectLocks,
  loadSubjectLocksError,
  loadSubjectLocksSuccess,
  subjectLockedSuccess,
  subjectUnlockedSuccess,
  lockSubject,
  unlockSubject,
  renewSubjectLock,
} = actions;

export { locksReducer };
