export const REVIEW_LABEL_OPTIONS = (
  deleteLabel: () => void,
  editLabel: () => void,
) => [
  {
    name: 'edit',
    notification: 'edit',
    identifier: 'edit',
    label: 'Edit',
    clickHandler: editLabel,
  },
  {
    name: 'delete',
    notification: 'delete',
    identifier: 'delete',
    label: 'Delete',
    clickHandler: deleteLabel,
  },
];
export const DEFAULT_OPACITY = 0.3;
