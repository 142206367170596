import { createSelector } from '@reduxjs/toolkit';

import { packageFeaturesPlansSelector } from '../../../commonFeatures/packageFeatures/packageFeatures.selectors';
import { projectListSelector } from '../projectList.selectors';

const createWorkspaceSelector = createSelector(
  projectListSelector,
  (projectList) => projectList.createWorkspace,
);

const createWorkspacePackageNameSelector = createSelector(
  createWorkspaceSelector,
  (createWorkspace) => createWorkspace.name,
);

export const createWorkspaceLoadingStateSelector = createSelector(
  createWorkspaceSelector,
  (createWorkspace) => createWorkspace.loadingState,
);

export const createWorkspaceWorkspaceIdSelector = createSelector(
  createWorkspaceSelector,
  (createWorkspace) => createWorkspace.workspaceId,
);

export const createWorkspaceTargetPlanSelector = createSelector(
  createWorkspacePackageNameSelector,
  packageFeaturesPlansSelector,
  (name, plans) => (plans ? plans.find((plan) => plan.name === name) : null),
);
