import { createSelector } from '@reduxjs/toolkit';

import { fileManagerVideoAdapter } from './videos.slice';
import { projectSelector } from '../../project.selectors';

const baseStateSelector = createSelector(
  projectSelector,
  (state) => state.fileManager.videos,
);

const selectors = fileManagerVideoAdapter.getSelectors(baseStateSelector);

export const fileManagerVideosSelector = selectors.selectAll;
export const fileManagerVideosMapSelector = selectors.selectEntities;
export const fileManagerVideosTotalOnPageSelector = selectors.selectTotal;

export const fileManagerVideosLoadingStateSelector = createSelector(
  baseStateSelector,
  (state) => state.loadingState,
);

export const fileManagerVideosActionLoadingStateSelector = createSelector(
  baseStateSelector,
  (state) => state.actionLoadingState,
);

export const fileManagerVideosFiltersSelector = createSelector(
  baseStateSelector,
  (state) => state.filters,
);

export const fileManagerSelectedDatasetsTotalFoundVideosSelector =
  createSelector(
    baseStateSelector,
    (state) => state.selectedDatasetTotalVideos,
  );

export const fileManagerAllDatasetsTotalFoundVideosSelector = createSelector(
  baseStateSelector,
  (state) => state.allDatasetsTotalVideos,
);

export const fileManagerVideosSelectedIdsSelector = createSelector(
  baseStateSelector,
  (state) => state.selectedIds,
);

export const videosFromDatasetsAsOptionsSelectors = (datasetIds: string[]) =>
  createSelector(fileManagerVideosSelector, (videos) =>
    videos
      .filter(({ datasetId }) =>
        datasetIds.length === 0 ? true : datasetIds.includes(datasetId),
      )
      .map(({ id, name }) => ({ key: id, value: id, text: name })),
  );
