import {
  put,
  select,
  take,
  takeLatest,
  takeEvery,
  call,
  delay,
} from 'typed-redux-saga';

import {
  THREE_D_SECURE_INTENT_SUCCESS,
  THREE_D_SECURE_PREFIX,
} from '../../../../../constants/threeDSecure';
import { saveBillingDetailsHandler as saveBillingDetailsHandlerAtomic } from '../../../../atoms/payments/saveBillingDetailsHandler';
import {
  packageIsDowngradingSelector,
  packageTitleByNameSelector,
  parkingPackageNameSelector,
} from '../../../commonFeatures/packageFeatures/packageFeatures.selectors';
import { hideModals, showModal } from '../../../ui/modals/modals.slice';
import {
  apiLoadCustomer,
  apiUpdatePaymentPlan,
  apiLoadIntentStatus,
} from '../../../../../api/requests/payment';
import { getErrorMessage } from '../../../../../api/utils';
import {
  managedWorkspaceCurrentPlanNameBillingSelector,
  managedWorkspaceSubscriptionSelector,
} from './payments.selectors';
import { handleError } from '../../../commonFeatures/errorHandler/errorHandler.actions';
import { notifyPaymentInformationSaved } from '../../../../atoms/payments/actions';
import {
  PackageName,
  isFreeOrParkedPackage,
} from '../../../../../constants/packageFeatures';
import { navigateToWorkspace } from '../../projectList/createWorkspace/createWorkspace.slice';
import { updateWorkspaceSubscription } from '../../projectList/workspaces/workspaces.slice';
import {
  loadCustomer,
  saveBillingDetails,
  saveBillingDetailsSuccess,
  savePaymentInformation,
  threeDSecureSuccess,
  updatePaymentPlan,
  loadCustomerFailure,
  saveBillingDetailsFailure,
  updatePaymentPlanSuccess,
  requestUpgradeToPlanModal,
  loadCustomerSuccess,
  updatePaymentPlanFailure,
  savePaymentInformationSuccess,
  savePaymentInformationFailure,
  threeDSecureError,
} from './payments.slice';
import { isErrorInvalidCoupon } from '../../../../atoms/payments/util';
import { workspaceIdSelector } from '../../../commonFeatures/workspaceId/workspaceId.selectors';
import { loadWorkspaceCredits } from '../credits/credits.slice';
import { savePaymentOptionHandler } from '../../../../atoms/payments/savePaymentOptionHandler';

function* savePaymentInformationHandler(
  action: ActionType<typeof savePaymentInformation>,
) {
  try {
    yield* call(saveBillingDetailsHandlerAtomic, action.payload.workspaceId);

    const paymentOption: { redirectUrl: string } = yield* call(
      savePaymentOptionHandler,
      action.payload.stripeRef,
      action.payload.elementsRef,
      action.payload.workspaceId,
    );
    yield* put(savePaymentInformationSuccess());

    if (paymentOption.redirectUrl) {
      yield* put(
        showModal({
          modalName: 'threeDSecure',
          modalProps: {
            redirectUrl: paymentOption.redirectUrl,
          },
        }),
      );
    } else {
      yield* put(notifyPaymentInformationSaved(action.payload.entityName));
    }
  } catch (error) {
    yield* put(
      savePaymentInformationFailure(
        getErrorMessage(error, 'Not able to update payment information'),
      ),
    );
  }
}

function* saveBillingDetailsSuccessHandler() {
  yield* put(hideModals());
}

function* loadCustomerHandler(action: ActionType<typeof loadCustomer>) {
  try {
    const { data: customer } = yield* call(apiLoadCustomer, action.payload);

    yield* put(loadCustomerSuccess(customer));
  } catch (error) {
    yield* put(
      loadCustomerFailure(getErrorMessage(error, 'Not able to load customer')),
    );
  }
}

function* saveBillingDetailsHandler(
  action: ActionType<typeof saveBillingDetails>,
) {
  try {
    yield* call(saveBillingDetailsHandlerAtomic, action.payload);
    yield* put(loadCustomer(action.payload));
    yield* put(saveBillingDetailsSuccess());
  } catch (error) {
    yield* put(
      saveBillingDetailsFailure(
        getErrorMessage(error, 'Not able to update payment information'),
      ),
    );
  }
}

function* updatePaymentPlanHandler(
  action: ActionType<typeof updatePaymentPlan>,
) {
  const { workspaceId, targetPlanName, scheduleUpgrade, coupon } =
    action.payload;

  try {
    yield* delay(2000);
    yield* call(apiUpdatePaymentPlan, {
      workspaceId,
      targetPlanName,
      scheduleUpgrade,
      coupon,
    });

    const { data: customer } = yield* call(apiLoadCustomer, workspaceId);

    yield* put(loadWorkspaceCredits(workspaceId));
    // todo should we move this outside of atom and delegate to parent saga?
    yield* put(
      updatePaymentPlanSuccess({
        workspaceId,
        targetPlanName: action.payload.targetPlanName,
        customer,
        isCancelingDowngrade:
          action.payload.targetPlanName === action.payload.currentPlanName,
      }),
    );
  } catch (error) {
    const defaultErrorMessage = 'Not able to update plan';
    const invalidCouponErrorMessage =
      'Invalid promotion coupon, please go back and try again or proceed without it';

    const apiErrorMessage = getErrorMessage(error, defaultErrorMessage);

    // If error is invalid coupon code use different error message due to different flow
    if (isErrorInvalidCoupon(apiErrorMessage)) {
      yield* put(
        updatePaymentPlanFailure(
          `${defaultErrorMessage}: ${invalidCouponErrorMessage}`,
        ),
      );
    } else {
      yield* put(updatePaymentPlanFailure(apiErrorMessage));
    }
  }
}

function* updatePaymentPlanSuccessHandler(
  action: ActionType<typeof updatePaymentPlanSuccess>,
) {
  const subscription = yield* select(managedWorkspaceSubscriptionSelector);
  const parkingPackageName = yield* select(parkingPackageNameSelector);
  const { isCancelingDowngrade, targetPlanName, customer, workspaceId } =
    action.payload;

  if (workspaceId) {
    const subscription = customer.subscription.planCurrentPeriod;
    const subscriptionTitle = yield* select((state: RootState) =>
      packageTitleByNameSelector(state, subscription),
    );

    // update also subscription and it's title directly in workspace
    if (subscriptionTitle) {
      yield* put(
        updateWorkspaceSubscription({
          id: workspaceId,
          subscription,
          subscriptionTitle,
        }),
      );
    }
  }
  if (targetPlanName === PackageName.Free) {
    yield* put(navigateToWorkspace());
  }

  yield* call(saveBillingDetailsSuccessHandler);

  if (subscription && subscription.planNextPeriod !== parkingPackageName) {
    const isDowngrade = yield* select(
      packageIsDowngradingSelector,
      subscription,
    );

    yield* put(
      showModal({
        modalName: 'workspacePurchaseCompleted',
        modalProps: { isCancelingDowngrade, subscription, isDowngrade },
      }),
    );
  }
}

function* threeDSecureCompletedHandler(
  action: ActionType<typeof threeDSecureSuccess>,
) {
  if (!action.payload.pathname.includes('payments')) return;

  const workspaceId = yield* select(workspaceIdSelector);

  if (!workspaceId) return;

  try {
    const result = yield* call(
      apiLoadIntentStatus,
      action.payload.setupIntentId,
    );

    if (result.data.status === THREE_D_SECURE_INTENT_SUCCESS) {
      yield* put(loadCustomer(workspaceId));
      yield* put(notifyPaymentInformationSaved(THREE_D_SECURE_PREFIX));
      yield* put(loadWorkspaceCredits(workspaceId));
    } else {
      yield* put(
        showModal({
          modalName: 'workspaceEditPaymentMethod',
          modalProps: { showPayment: true },
        }),
      );
      yield* put(threeDSecureError({ message: '3DS authentication failed' }));
    }
  } catch (error) {
    const errorMessage = 'Could not get 3DS result';

    yield* put(
      handleError({ message: errorMessage, allowOutsideOfEditor: true }),
    );
  }
}

function* requestUpgradeToPlanModalHandler(
  action: ActionType<typeof requestUpgradeToPlanModal>,
) {
  const currentPlanNameBilling = yield* select(
    managedWorkspaceCurrentPlanNameBillingSelector,
  );

  const assigned = !isFreeOrParkedPackage(currentPlanNameBilling);

  if (assigned) {
    yield* put(
      showModal({
        modalName: 'workspaceUpgradePlanConfirmation',
        modalProps: { planName: action.payload },
      }),
    );
  } else {
    yield* put(
      showModal({
        modalName: 'workspaceEditPaymentMethod',
        modalProps: { isSubscribing: true, showPayment: true },
      }),
    );
    const delayAction = yield* take(notifyPaymentInformationSaved);

    if (
      notifyPaymentInformationSaved.match(delayAction) &&
      delayAction.payload !== THREE_D_SECURE_PREFIX
    ) {
      return;
    }

    yield* put(
      showModal({
        modalName: 'workspaceUpgradePlanConfirmation',
        modalProps: { planName: action.payload },
      }),
    );
  }
}

function* savePaymentInformationSuccessHandler(
  action: ActionType<typeof notifyPaymentInformationSaved>,
) {
  if (action.payload !== THREE_D_SECURE_PREFIX) return;

  yield* put(hideModals());
}

export function* managedWorkspacePaymentSaga() {
  yield* takeEvery(loadCustomer, loadCustomerHandler);
  yield* takeEvery(saveBillingDetails, saveBillingDetailsHandler);
  // This is almost the same saga as in web/src/redux/projects/projectList/createWorkspace/createWorkspace.saga.ts,
  // it should be extracted when this functionality will be migrated to slices
  yield* takeEvery(savePaymentInformation, savePaymentInformationHandler);
  // This is almost the same saga as in web/src/redux/projects/projectList/createWorkspace/createWorkspace.saga.ts,
  // it should be extracted when this functionality will be migrated to slices
  yield* takeEvery(updatePaymentPlan, updatePaymentPlanHandler);
  yield* takeEvery(saveBillingDetailsSuccess, saveBillingDetailsSuccessHandler);
  yield* takeEvery(updatePaymentPlanSuccess, updatePaymentPlanSuccessHandler);
  yield* takeEvery(requestUpgradeToPlanModal, requestUpgradeToPlanModalHandler);
  yield* takeEvery(
    notifyPaymentInformationSaved,
    savePaymentInformationSuccessHandler,
  );
  yield* takeLatest(threeDSecureSuccess, threeDSecureCompletedHandler);
}
