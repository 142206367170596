import { LocalStorageKey, LocalStorageValue } from './types';

export const getLocalStorageItem = <T extends LocalStorageValue>(
  key: LocalStorageKey,
  defaultValue?: T | null,
): T | null => {
  try {
    const serializedValue = localStorage.getItem(key);

    if (serializedValue === null) {
      return defaultValue || null;
    }

    return JSON.parse(serializedValue) as T;
  } catch (error) {
    return defaultValue || null;
  }
};

export const upsertLocalStorageItem = (
  key: LocalStorageKey,
  value: LocalStorageValue,
): void => {
  try {
    const serializedValue = JSON.stringify(value);
    localStorage.setItem(key, serializedValue);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`Error setting local storage item: ${error}`);
  }
};

export const removeLocalStorageItem = (key: LocalStorageKey): void => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`Error removing local storage item: ${error}`);
  }
};
