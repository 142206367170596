import {
  createAction,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { Datasplit } from '../../../../../../api/domainModels/modelPlayground';
import { loadingStateBuilder } from '../../../../../utils/loadingState';
import { SplitFormValues } from '../splits.types';
import { setEditedProjectId } from '../../../../sections/editedProject/project/project.slice';

export const adapter = createEntityAdapter<Datasplit>({
  sortComparer: (a, b) => (dayjs(a.createdOn).isAfter(b.createdOn) ? -1 : 1),
});
const name = 'splits';
const initialState = adapter.getInitialState({
  loadingState: loadingStateBuilder.initial(),
  itemLoadingState: loadingStateBuilder.initial(),
});

const { actions, reducer: splitsDataReducer } = createSlice({
  name,
  initialState,
  reducers: {
    loadSplits(state) {
      state.loadingState = loadingStateBuilder.inProgress();
      adapter.removeAll(state);
    },
    loadSplitsSuccess(state, action: PayloadAction<Datasplit[]>) {
      state.loadingState = loadingStateBuilder.success();
      adapter.setAll(state, action.payload);
    },
    loadSplitsFailure(state, action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.failure(action.payload);
      adapter.setAll(state, []);
    },
    loadSplit(state, _action: PayloadAction<{ splitId: string }>) {
      state.itemLoadingState = loadingStateBuilder.inProgress();
    },
    loadSplitSuccess(state, action: PayloadAction<Datasplit>) {
      state.itemLoadingState = loadingStateBuilder.success();
      adapter.upsertOne(state, action.payload);
    },
    loadSplitFailure(state, action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.failure(action.payload);
    },
    removeSplit(
      state,
      _action: PayloadAction<{ splitId: string; parentSplitId?: string }>,
    ) {
      state.itemLoadingState = loadingStateBuilder.inProgress();
    },
    removeSplitFailure(state, action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.failure(action.payload);
    },
    removeSplitSuccess(state, action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.success();
      adapter.removeOne(state, action.payload);
    },
    createSplit(state, _action: PayloadAction<SplitFormValues>) {
      state.itemLoadingState = loadingStateBuilder.inProgress();
    },
    createSplitSuccess(state) {
      state.itemLoadingState = loadingStateBuilder.success();
    },
    createSplitFailure(state, action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.failure(action.payload);
    },
    createNestedSplit(
      state,
      _action: PayloadAction<{
        data: {
          name: string;
          trainDatasetIds: string[];
          trainFraction: number;
          nestingMethod: string;
        };
        params: { splitId: string };
      }>,
    ) {},
    addSplit(state, action: PayloadAction<Datasplit>) {
      adapter.addOne(state, action.payload);
    },
    resetItemLoadingState(state) {
      state.itemLoadingState = loadingStateBuilder.initial();
    },
    removeNestedSplitSuccess(
      state,
      action: PayloadAction<{ splitId: string; parentSplitId: string }>,
    ) {
      adapter.updateOne(state, {
        id: action.payload.parentSplitId,
        changes: {
          children: state.entities[
            action.payload.parentSplitId
          ]?.children.filter((split) => split.id !== action.payload.splitId),
        },
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setEditedProjectId, () => initialState);
  },
});

export { splitsDataReducer };
export const {
  loadSplits,
  loadSplitsSuccess,
  loadSplitsFailure,
  loadSplit,
  loadSplitSuccess,
  loadSplitFailure,
  removeSplit,
  removeSplitFailure,
  removeSplitSuccess,
  createSplit,
  createSplitFailure,
  createSplitSuccess,
  createNestedSplit,
  addSplit,
  resetItemLoadingState,
  removeNestedSplitSuccess,
} = actions;

export const addSplitFromWebsocket = createAction<{
  split: Datasplit;
  projectId: string;
}>(`${name}/addSplitFromWebsocket`);
