import { all, call, takeEvery, put } from 'typed-redux-saga';
import dayjs from 'dayjs';

import { convertBackendObject } from '../../../utils/backendParams';
import { handleError } from '../../commonFeatures/errorHandler/errorHandler.actions';
import {
  setPermanentMessage,
  PermanentMessageTypes,
} from '../permanentMessages/permanentMessages.slice';
import { fetchApplicationStatus } from './applicationStatus.actions';

const getHoursUntil = (date: number): number => {
  const now = dayjs();
  const end = dayjs(date);

  return end.diff(now, 'hours');
};

const formatDate = (date: string) => dayjs(date).format('HH:mm, MMMM DD, YYYY');

function* maintenanceHandler(data: any[]) {
  if (data.length === 0) {
    return;
  }

  const actualMaintenanceList = data.filter(
    (maintenance: any) => getHoursUntil(maintenance.scheduledFor) <= 72,
  );

  yield* all(
    actualMaintenanceList.map((actualMaintenance: any) =>
      put(
        setPermanentMessage({
          id: PermanentMessageTypes.Maintenance,
          message: `There's planned maintenance from ${formatDate(
            actualMaintenance.scheduledFor,
          )} to ${formatDate(actualMaintenance.scheduledUntil)}`,
        }),
      ),
    ),
  );
}

function* incidentsHandler(data: any[]) {
  if (data.length === 0) {
    return;
  }

  yield* all(
    data.map((incident: any) =>
      put(
        setPermanentMessage({
          id: PermanentMessageTypes.Incident,
          message: `${incident.name} - ${incident.incidentUpdates[0].body} We sincerely apologize for any inconvenience.`,
        }),
      ),
    ),
  );
}

function* loadApplicationStatusHandler() {
  try {
    const data = yield* call(
      fetch,
      'https://q310kbj3jpt6.statuspage.io/api/v2/summary.json',
    );
    const jsonData = yield* call(() => data.json());
    const convertedData = convertBackendObject(jsonData);

    yield* call(maintenanceHandler, convertedData.scheduledMaintenances);
    yield* call(incidentsHandler, convertedData.incidents);
  } catch (error) {
    const errorMessage = 'Failed to fetch status page';

    yield* put(
      handleError({
        message: errorMessage,
        error,
      }),
    );
  }
}

export function* applicationStatusSaga() {
  yield* takeEvery(fetchApplicationStatus, loadApplicationStatusHandler);
}
