import { createSelector } from '@reduxjs/toolkit';

import { adapter, ParseLog } from './importParseLogs.slice';
import { MISSING_IMAGES_WARNING } from './importParseLogs.util';
import { editedProjectSelector } from '../../editedProject.selectors';

const importParseLogsStateSelector = createSelector(
  editedProjectSelector,
  (editedProject) => editedProject.imports.parseLogs,
);

const selectors = adapter.getSelectors(importParseLogsStateSelector);

export const importParseLogsSelector = selectors.selectAll;

export const importParseLogsIdsSelector = selectors.selectIds as (
  state: RootState,
) => string[];

export const importParseLogByIdSelector = selectors.selectById;

export const importParseLogsListLoadingStateSelector = createSelector(
  importParseLogsStateSelector,
  (state) => state.listLoadingState,
);

type ParseLogsByFileId = {
  parseLogs: ParseLog[];
  hasErrors?: boolean;
  hasWarnings?: boolean;
  fileId?: string;
};

export const importParseLogsByFileIdSelectorFactory = (fileId?: string) =>
  createSelector(
    (state: RootState) => state,
    (state): ParseLogsByFileId => {
      const fileLogs = fileId
        ? importParseLogByIdSelector(state, fileId)
        : undefined;

      const hasErrors = !!(
        fileLogs &&
        fileLogs.ERROR &&
        fileLogs.ERROR.length > 0
      );
      const hasWarnings = !!(
        fileLogs &&
        fileLogs.WARNING &&
        fileLogs.WARNING.length > 0
      );

      const hasParseLogs = hasErrors || hasWarnings;
      const parseLogs = hasParseLogs
        ? [
            ...(hasErrors ? fileLogs?.ERROR || [] : []),
            ...(hasWarnings ? fileLogs?.WARNING || [] : []),
          ]
        : [];

      return {
        fileId,
        parseLogs,
        hasErrors,
        hasWarnings,
      };
    },
  );

export const importHasMissingImagesWarningSelector = createSelector(
  importParseLogsIdsSelector,
  (state: RootState) => state,
  (allParseLogIds = [], state) => {
    const groupedParseLogs = allParseLogIds.map((id) =>
      importParseLogsByFileIdSelectorFactory(id)(state),
    );

    return groupedParseLogs.some(({ parseLogs }) =>
      parseLogs.some(({ logMessage }) => logMessage === MISSING_IMAGES_WARNING),
    );
  },
);

export const importHasWarningsSelector = createSelector(
  importParseLogsIdsSelector,
  (state: RootState) => state,
  (allParseLogIds = [], state) => {
    const groupedParseLogs = allParseLogIds.map((id) =>
      importParseLogsByFileIdSelectorFactory(id)(state),
    );

    return groupedParseLogs.some(({ hasWarnings }) => hasWarnings);
  },
);

export const importFileIdsWithMissingImagesWarningSelector = createSelector(
  importParseLogsIdsSelector,
  (state: RootState) => state,
  (allParseLogIds = [], state) => {
    const groupedParseLogs = allParseLogIds.map((id) =>
      importParseLogsByFileIdSelectorFactory(id)(state),
    );

    return groupedParseLogs.reduce((acc, item) => {
      const { parseLogs, hasWarnings, fileId } = item;
      if (!hasWarnings || !fileId) return acc;

      const hasMissingImages = parseLogs.some(
        ({ logMessage }) => logMessage === MISSING_IMAGES_WARNING,
      );

      if (hasMissingImages) return [...acc, fileId];

      return acc;
    }, [] as string[]);
  },
);
