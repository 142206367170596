import { authSaga } from './auth/auth.saga';
import { errorHandlerSaga } from './errorHandler/errorHandler.saga';
import { locksSaga } from './locks/locks.saga';
import { modelQualitySaga } from './modelQuality/modelQuality.saga';
import { notificationsSaga } from './notifications/notifications.saga';
import { urlSearchParamsSaga } from './urlSearchParams/urlSearchParams.saga';
import { packageFeaturesSaga } from './packageFeatures/packageFeatures.saga';

export const commonFeaturesSagas = [
  authSaga,
  errorHandlerSaga,
  locksSaga,
  modelQualitySaga,
  notificationsSaga,
  urlSearchParamsSaga,
  packageFeaturesSaga,
];
