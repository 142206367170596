import {
  ImageTool,
  ImageToolTitleMap,
} from '../redux/state/core/imageView/tools/tools.constants';

export const UNLOCKED_KEY_MAP = {
  setImageToReview: {
    name: 'Set image status to review',
    sequence: ['shift+r'],
  },
  setImageToDone: {
    name: 'Set image status to done',
    sequence: ['shift+d'],
  },
  selectAll: {
    name: 'Select all',
    description: 'Select all labels',
    sequence: ['mod+a'],
  },
  setImageToSkipped: {
    name: 'Set image status to skipped',
    sequence: ['shift+q'],
  },
  zoomIn: {
    name: 'Zoom in',
    sequence: '+',
  },
  zoomOut: {
    name: 'Zoom out',
    sequence: '-',
  },
  escape: {
    name: 'Escape',
    sequence: 'esc',
  },
  undo: {
    name: 'Undo',
    sequence: ['mod+z'],
  },
  redo: {
    name: 'Redo',
    sequence: ['shift+mod+z'],
  },
  selectNextLabelClassClass: {
    name: 'Select next label class',
    sequence: 'y',
  },
  fillAnnotations: {
    name: 'Fill annotations',
    sequence: 'v',
    action: 'keydown',
  },
  clarifyAnnotations: {
    name: 'Unfill annotations',
    sequence: 'v',
    action: 'keyup',
  },
  hideAnnotations: {
    name: 'Hide annotation',
    sequence: 'd',
    action: 'keydown',
  },
  showAnnotations: {
    name: 'Show annotations',
    sequence: 'd',
    action: 'keyup',
  },
  showClassName: {
    name: 'Show class name',
    sequence: 'z',
    action: 'keydown',
  },
  hideClassName: {
    name: 'Hide class names',
    sequence: 'z',
    action: 'keyup',
  },
  showAttributesName: {
    name: 'Show attributes name',
    sequence: ['f'],
    action: 'keydown',
  },
  hideAttributesName: {
    name: 'Hide attributes names',
    sequence: ['f'],
    action: 'keyup',
  },
  selectPreviousLabelClassClass: {
    name: 'Select previous label class',
    sequence: ['shift+y'],
  },
  selection: {
    name: 'Selection',
    sequence: 'm',
  },
  objectDetection: {
    name: ImageToolTitleMap[ImageTool.ObjectDetection],
    sequence: 'j',
  },
  polygon: {
    name: 'Create a polygon',
    sequence: 'p',
  },
  boundingBox: {
    name: 'Create a bounding box',
    sequence: 'r',
  },
  brush: {
    name: 'Brush tool',
    sequence: 'b',
  },
  eraser: {
    name: 'Brush tool eraser mode',
    sequence: 'e',
  },
  toggleFiltersVisibility: {
    name: 'Toggle show filters',
    sequence: 'shift+e',
  },
  semanticSegmentation: {
    name: ImageToolTitleMap[ImageTool.SemanticSegmentation],
    sequence: 's',
  },
  instanceSegmentation: {
    name: ImageToolTitleMap[ImageTool.InstanceSegmentation],
    sequence: 'i',
  },
  boxToInstance: {
    name: ImageToolTitleMap[ImageTool.BoxToInstance],
    sequence: 'o',
  },
  atomTool: {
    name: ImageToolTitleMap[ImageTool.Atom],
    sequence: 'c',
  },
  keypointsTool: {
    name: ImageToolTitleMap[ImageTool.Keypoints],
    sequence: 'k',
  },
  keypointsDetectionTool: {
    name: ImageToolTitleMap[ImageTool.KeypointsDetection],
    sequence: 'q',
  },
  selectPanTool: {
    name: 'Pan tool',
    sequence: 'h',
  },
  activatePanTool: {
    name: 'Activate Pan tool temporarily',
    sequence: 'space',
    action: 'keydown',
  },
  deactivatePanTool: {
    name: 'Deactivate Pan tool',
    sequence: 'space',
    action: 'keyup',
  },
  textPromptTool: {
    name: ImageToolTitleMap[ImageTool.TextPrompt],
    sequence: 'u',
  },
} as const;

export const LOCKED_KEY_MAP = {
  // it's in `LOCKED_KEY_MAP` to avoid default browser behavior of `cmd+shift+D`
  setImageToDoneAndGoNext: {
    name: 'Set to done and go next',
    sequence: ['mod+shift+d'],
  },
  goToPreviousImage: {
    name: 'Go to previous image',
    sequence: ['shift+b'],
  },
  goToNextImage: {
    name: 'Go to next image',
    sequence: ['shift+n'],
  },
  zoomToOriginalSize: {
    name: 'Zoom to original size',
    sequence: ['shift+1'],
  },
  zoomToFit: {
    name: 'Zoom to fit to screen',
    sequence: ['shift+0'],
  },
  arrowLeft: {
    name: 'Move selected labels or pan left',
    sequence: ['left'],
    customKeysText: 'left',
  },
  arrowRight: {
    name: 'Move selected labels or pan right',
    sequence: ['right'],
    customKeysText: 'right',
  },
  arrowUp: {
    name: 'Move selected labels or pan up',
    sequence: ['up'],
    customKeysText: 'up',
  },
  arrowDown: {
    name: 'Move selected labels or pan down',
    sequence: ['down'],
    customKeysText: 'down',
  },
} as const;

export const HEADER_HEIGHT = '2.7rem';
export const DEFAULT_ZOOM_SPEED = 0.2;
export const ZOOM_IN_MAX_VALUE = 640;
export const STAGE_INTERNAL_PADDING = 30;

export const ZOOM_SPEED_OPTIONS = [
  {
    key: 'slow,',
    text: 'Slow',
    value: 0.1,
  },
  {
    key: 'normal,',
    text: 'Normal',
    value: 0.2,
  },
  {
    key: 'fast,',
    text: 'Fast',
    value: 0.35,
  },
  {
    key: 'very_fast,',
    text: 'Very fast',
    value: 0.5,
  },
];
