import React from 'react';
import { GlobalStyles, useTheme } from '@mui/material';

export const GlobalStyle: React.FC = () => {
  const theme = useTheme();

  return (
    <GlobalStyles
      styles={{
        body: {
          color: theme.palette.text.primary,
          background: theme.palette.background.body,
          '--cord-color-base': theme.palette.background.menu,
          '--cord-color-content-primary': theme.palette.neutral.light,
          '--cord-color-base-strong': theme.palette.interactive.main,
          '--cord-color-base-x-strong': theme.palette.background.body,
          '--cord-color-content-secondary': theme.palette.common.white,
          '--cord-color-content-emphasis': theme.palette.neutral.light,
          '--cord-color-brand-primary': theme.palette.interactive.light,
          '& ::-webkit-scrollbar-thumb:hover': {
            background: theme.palette.neutral.main,
            border: `1px solid ${theme.palette.action.hover}`,
          },
          '& ::-webkit-scrollbar': {
            '-webkit-appearance': 'none',
            width: '10px',
            height: '10px',
          },
          '& ::-webkit-scrollbar-track': {
            background: theme.palette.action.tooltip,
            borderRadius: '0px',
            border: `1px solid ${theme.palette.action.hover}`,
          },
          '& ::-webkit-scrollbar-thumb, & ::-webkit-scrollbar-thumb:window-inactive':
            {
              cursor: 'pointer',
              borderRadius: '4px',
              background: theme.palette.neutral.light,
              '-webkit-transition': 'color 0.2s ease',
              transition: 'color 0.2s ease',
            },
        },
        a: {
          color: theme.palette.primary.light,
          '&:hover': {
            color: theme.palette.primary.main,
          },
        },
        '::-webkit-selection': {
          backgroundColor: theme.palette.neutral.main,
          color: theme.palette.primary.contrastText,
        },
        '::selection': {
          backgroundColor: theme.palette.neutral.main,
          color: theme.palette.primary.contrastText,
        },
        'textarea::-webkit-selection, input::-webkit-selection': {
          backgroundColor: theme.palette.neutral.main,
          color: theme.palette.primary.contrastText,
        },
        'textarea::selection, input::selection': {
          backgroundColor: theme.palette.neutral.main,
          color: theme.palette.primary.contrastText,
        },
      }}
    />
  );
};
