import { put, select, takeEvery, call } from 'typed-redux-saga';

import { tagClassDataMapper } from '../../../../../api/domainModels/tagClass';
import {
  apiLoadProjectTagClasses,
  apiCreateProjectTagClass,
  apiUpdateProjectTagClass,
  apiDeleteProjectTagClass,
} from '../../../../../api/requests/tagClass';
import { WebsocketTagClassModifiedPayload } from '../../../../../api/domainModels/websocketTypes';
import { getErrorMessage } from '../../../../../api/utils';
import { handleError } from '../../../commonFeatures/errorHandler/errorHandler.actions';
import { setProjectId } from '../../../core/imageView/project/project.slice';
import { initImageProject } from '../../../sections/editedProject/project/project.slice';
import {
  TAG_CLASS_ADDED,
  TAG_CLASS_DELETED,
  TAG_CLASS_MODIFIED,
} from '../../../ws/ws.constants';
import { hideModals } from '../../../ui/modals/modals.slice';
import { activeProjectIdSelector } from '../../project.selectors';
import {
  addTagClass,
  addTagClassFailure,
  addTagClassSuccess,
  loadTagClassesFailure,
  loadTagClassesStart,
  loadTagClassesSuccess,
  removeTagClass,
  removeTagClassFailure,
  removeTagClassFromWebsockets,
  removeTagClassSuccess,
  resetTagClassLoadingState,
  updateTagClass,
  updateTagClassFailure,
  updateTagClassSuccess,
  upsertTagClassFromWebsockets,
} from './tagClasses.slice';
import {
  DEFAULT_GROUP_ID,
  DEFAULT_GROUP_NAME,
} from '../../../../../api/domainModels/tagGroup';
import { loadAll } from '../../../../utils/api';

function* listHandler(
  action: ActionType<typeof initImageProject | typeof setProjectId>,
) {
  const projectId = action.payload.id;

  yield* put(loadTagClassesStart());

  try {
    const data = yield* loadAll({
      apiHelper: apiLoadProjectTagClasses,
      params: {
        projectId,
      },
      fromBackend: tagClassDataMapper.fromBackend,
    });

    yield* put(loadTagClassesSuccess(data));
  } catch (e) {
    yield* put(
      loadTagClassesFailure(getErrorMessage(e, 'Not able to load tag classes')),
    );
  }
}

function* addTagClassHandler(action: ActionType<typeof addTagClass>) {
  const projectId = yield* select(activeProjectIdSelector);
  try {
    const { data } = yield* call(
      apiCreateProjectTagClass,
      { projectId },
      tagClassDataMapper.toBackend(action.payload),
    );
    const newTagClass = tagClassDataMapper.fromBackend(data);

    yield* put(addTagClassSuccess(newTagClass));
    yield* put(hideModals());
  } catch (e) {
    yield* put(
      addTagClassFailure(getErrorMessage(e, 'Not able to create a tag class')),
    );
  }
}

function* updateTagClassHandler(action: ActionType<typeof updateTagClass>) {
  const { id, ...payload } = action.payload;
  const projectId = yield* select(activeProjectIdSelector);

  try {
    const { data } = yield* call(
      apiUpdateProjectTagClass,
      { projectId, tagClassId: id },
      tagClassDataMapper.toBackend(payload),
    );

    yield* put(
      updateTagClassSuccess({
        changes: tagClassDataMapper.fromBackend(data),
        id,
      }),
    );
    yield* put(hideModals());
  } catch (e) {
    yield* put(
      updateTagClassFailure({
        message: getErrorMessage(e, 'Not able to update the tag class'),
        id,
      }),
    );
  }
}

function* removeTagClassHandler(action: ActionType<typeof removeTagClass>) {
  const projectId = yield* select(activeProjectIdSelector);
  const id = action.payload;

  try {
    yield* call(apiDeleteProjectTagClass, {
      projectId,
      tagClassId: id,
    });
    yield* put(removeTagClassSuccess(id));
    yield* put(hideModals());
  } catch (error) {
    const errorMessage = getErrorMessage(
      error,
      'Not able to remove the tag class',
    );
    yield* put(removeTagClassFailure(errorMessage));
    yield* put(handleError({ message: errorMessage, error })); // TODO:: switch to removal confirmation modal
  }
}

function* websocketTagClassRemovedHandler({
  projectId,
  id,
}: WebsocketTagClassModifiedPayload & { type: string }) {
  const currentProjectId = yield* select(activeProjectIdSelector);

  if (currentProjectId && currentProjectId === projectId) {
    yield* put(removeTagClassFromWebsockets(id));
  }
}

function* websocketTagClassUpsertHandler({
  projectId,
  id,
  name,
  groupId,
  groupName,
}: WebsocketTagClassModifiedPayload & { type: string }) {
  const currentProjectId = yield* select(activeProjectIdSelector);

  if (currentProjectId && currentProjectId === projectId) {
    yield* put(
      upsertTagClassFromWebsockets({
        id,
        name,
        groupId: groupId || DEFAULT_GROUP_ID,
        groupName: groupName || DEFAULT_GROUP_NAME,
      }),
    );
  }
}

function* hideModalsHandler() {
  yield* put(resetTagClassLoadingState());
}

export function* tagClassesSaga() {
  yield* takeEvery([initImageProject, setProjectId], listHandler);
  yield* takeEvery(addTagClass, addTagClassHandler);
  yield* takeEvery(updateTagClass, updateTagClassHandler);
  yield* takeEvery(removeTagClass, removeTagClassHandler);
  yield* takeEvery(
    [TAG_CLASS_ADDED, TAG_CLASS_MODIFIED],
    websocketTagClassUpsertHandler,
  );
  yield* takeEvery([TAG_CLASS_DELETED], websocketTagClassRemovedHandler);
  yield* takeEvery(hideModals, hideModalsHandler);
}
