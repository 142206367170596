import { combineReducers } from 'redux';

import { imageViewReducer } from './imageView/imageView.reducer';
import { videoViewReducer } from './videoView/videoView.slice';
import { modelPlaygroundReducer } from './modelPlayground/modelPlayground.reducer';
import { errorFinderReducer } from './errorFinder/errorFinder.slice';
import { manualReviewReducer } from './manualReview/manualReview.slice';

export const coreReducer = combineReducers({
  imageView: imageViewReducer,
  videoView: videoViewReducer,
  modelPlayground: modelPlaygroundReducer,
  errorFinder: errorFinderReducer,
  manualReview: manualReviewReducer,
});
