/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BatchMeta } from '../models/BatchMeta';
import type { Image } from '../models/Image';
import type { Image1 } from '../models/Image1';
import type { ImageExternalReplaceRequest } from '../models/ImageExternalReplaceRequest';
import type { ImageExternalUploadRequest } from '../models/ImageExternalUploadRequest';
import type { ImageMoveRenameRequest } from '../models/ImageMoveRenameRequest';
import type { ImageReplaceRequest } from '../models/ImageReplaceRequest';
import type { ImageStatus } from '../models/ImageStatus';
import type { ImageStatusWrite } from '../models/ImageStatusWrite';
import type { ImageUploadRequest } from '../models/ImageUploadRequest';
import type { PaginationMeta } from '../models/PaginationMeta';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { OmitReadonly } from '../core/utils/OmitReadonly';

export class ImageService {

  /**
   * Returns image info
   * @returns Image Returns image properties
   * @throws ApiError
   */
  public static getApiV10ProjectImage({
    projectId,
    imageId,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Image ID
     */
    imageId: string,
  }): CancelablePromise<Image> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1.0/project/{project_id}/image/{image_id}',
      path: {
        'project_id': projectId,
        'image_id': imageId,
      },
    });
  }

  /**
   * Returns an offset for specific image
   * @returns any Successful read an offset of the images
   * @throws ApiError
   */
  public static getApiV10ProjectImageOffset({
    projectId,
    imageId,
    filterByDataset,
    filterByImageStatus,
    filterByImageTags,
    filterByEfRun,
    filterByEfUserAction,
    searchByImageName,
    filterByEfErrorType,
    filterByAldiSession,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Image ID
     */
    imageId: string,
    /**
     * Dataset identifier
     */
    filterByDataset?: string,
    /**
     * Image status
     */
    filterByImageStatus?: ImageStatus,
    /**
     * Tag class IDs
     */
    filterByImageTags?: Array<string>,
    /**
     * Error finder run id
     */
    filterByEfRun?: string,
    /**
     * Filter by All/Resolved/Unresolved images
     */
    filterByEfUserAction?: 'ALL' | 'RESOLVED' | 'UNRESOLVED',
    /**
     * Search pattern
     */
    searchByImageName?: string,
    /**
     * Type of error
     */
    filterByEfErrorType?: 'NOT_AN_ERROR' | 'LOW_IOU' | 'MISSING_LABEL' | 'EXTRA_LABEL' | 'MISCLASSIFICATION' | 'EXTRA_TAG' | 'MISSING_TAG' | 'EXTRA_ATTRIBUTE' | 'MISSING_ATTRIBUTE' | 'EXTRA_AREA' | 'MISSING_AREA',
    /**
     * ALDI session ID
     */
    filterByAldiSession?: string,
  }): CancelablePromise<{
    /**
     * Image id
     */
    imageId: string;
    /**
     * The image offset (starts with 0)
     */
    offset?: number;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1.0/project/{project_id}/image/{image_id}/offset',
      path: {
        'project_id': projectId,
        'image_id': imageId,
      },
      query: {
        'filter_by_dataset': filterByDataset,
        'filter_by_image_status': filterByImageStatus,
        'filter_by_image_tags': filterByImageTags,
        'filter_by_ef_run': filterByEfRun,
        'filter_by_ef_user_action': filterByEfUserAction,
        'search_by_image_name': searchByImageName,
        'filter_by_ef_error_type': filterByEfErrorType,
        'filter_by_aldi_session': filterByAldiSession,
      },
    });
  }

  /**
   * Set image status
   * @returns any OK
   * @throws ApiError
   */
  public static postApiV10ProjectImageStatus({
    projectId,
    imageId,
    statusName,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Image ID
     */
    imageId: string,
    /**
     * Status name
     */
    statusName: OmitReadonly<ImageStatusWrite>,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1.0/project/{project_id}/image/{image_id}/status/{status_name}',
      path: {
        'project_id': projectId,
        'image_id': imageId,
        'status_name': statusName,
      },
    });
  }

  /**
   * Return the list of images
   * @returns any Successful read the list of images
   * @throws ApiError
   */
  public static projectImagesList({
    projectId,
    offset,
    limit,
    filterByDataset,
    filterByImageStatus,
    filterByImageTags,
    filterByEfRun,
    filterByEfUserAction,
    searchByImageName,
    filterByAldiSession,
    filterByEfErrorType,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * The number of items to skip before starting to collect the result set
     */
    offset?: number,
    /**
     * The numbers of items to return
     */
    limit?: number,
    /**
     * Dataset identifier
     */
    filterByDataset?: string,
    /**
     * Image status
     */
    filterByImageStatus?: ImageStatus,
    /**
     * Tag class IDs
     */
    filterByImageTags?: Array<string>,
    /**
     * Error finder run id
     */
    filterByEfRun?: string,
    /**
     * Filter by All/Resolved/Unresolved images
     */
    filterByEfUserAction?: 'ALL' | 'RESOLVED' | 'UNRESOLVED',
    /**
     * Search pattern
     */
    searchByImageName?: string,
    /**
     * ALDI session ID
     */
    filterByAldiSession?: string,
    /**
     * Type of error
     */
    filterByEfErrorType?: 'NOT_AN_ERROR' | 'LOW_IOU' | 'MISSING_LABEL' | 'EXTRA_LABEL' | 'MISCLASSIFICATION' | 'EXTRA_TAG' | 'MISSING_TAG' | 'EXTRA_ATTRIBUTE' | 'MISSING_ATTRIBUTE' | 'EXTRA_AREA' | 'MISSING_AREA',
  }): CancelablePromise<{
    items?: Array<Image>;
    /**
     * Number of items per page
     */
    itemsPerPage?: number;
    /**
     * Total number of items
     */
    total?: number;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1.0/project/{project_id}/images',
      path: {
        'project_id': projectId,
      },
      query: {
        'offset': offset,
        'limit': limit,
        'filter_by_dataset': filterByDataset,
        'filter_by_image_status': filterByImageStatus,
        'filter_by_image_tags': filterByImageTags,
        'filter_by_ef_run': filterByEfRun,
        'filter_by_ef_user_action': filterByEfUserAction,
        'search_by_image_name': searchByImageName,
        'filter_by_aldi_session': filterByAldiSession,
        'filter_by_ef_error_type': filterByEfErrorType,
      },
    });
  }

  /**
   * Generate signed URLs for uploads.
   * @returns any Returns a list of upload IDs and signed URLs for uploading images.
   * @throws ApiError
   */
  public static imagesUrl({
    projectId,
    requestBody,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    requestBody?: {
      /**
       * The number of signed URLs to generate for upload.
       */
      count: number;
    },
  }): CancelablePromise<{
    meta?: BatchMeta;
    items?: Array<{
      id?: string;
      url?: string;
    }>;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/projects/{project_id}/image_uploads',
      path: {
        'project_id': projectId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Fetch the list of images
   * @returns any Successful read of the list of images
   * @throws ApiError
   */
  public static imagesList({
    projectId,
    offset,
    limit,
    datasetId,
    imageStatus,
    imageName,
    imageTags,
    updatedSince,
    createdSince,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Offset for this paginated query
     */
    offset?: number,
    /**
     * Limit for this paginated query
     */
    limit?: number,
    /**
     * Dataset ID
     */
    datasetId?: string,
    /**
     * Image status
     */
    imageStatus?: string,
    /**
     * Image name filtering
     */
    imageName?: string,
    /**
     * Tag class IDs
     */
    imageTags?: Array<string>,
    /**
     * Filter for getting images updated since
     */
    updatedSince?: number,
    /**
     * Filter for getting images created since
     */
    createdSince?: number,
  }): CancelablePromise<{
    meta: PaginationMeta;
    items: Array<Image1>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/projects/{project_id}/images',
      path: {
        'project_id': projectId,
      },
      query: {
        'offset': offset,
        'limit': limit,
        'dataset_id': datasetId,
        'image_status': imageStatus,
        'image_name': imageName,
        'image_tags': imageTags,
        'updated_since': updatedSince,
        'created_since': createdSince,
      },
    });
  }

  /**
   * Import image to project
   * @returns Image1 Returns the uploaded image metadata
   * @throws ApiError
   */
  public static imagesUpload({
    projectId,
    requestBody,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    requestBody?: (ImageUploadRequest | ImageExternalUploadRequest),
  }): CancelablePromise<Image1> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/projects/{project_id}/images',
      path: {
        'project_id': projectId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Fetch image metadata
   * @returns Image1 Successful read of image properties
   * @throws ApiError
   */
  public static imagesGet({
    projectId,
    imageId,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Image ID
     */
    imageId: string,
  }): CancelablePromise<Image1> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/projects/{project_id}/images/{image_id}',
      path: {
        'project_id': projectId,
        'image_id': imageId,
      },
    });
  }

  /**
   * Update an existing image
   * @returns Image1 Returns the uploaded image metadata
   * @throws ApiError
   */
  public static imagesUpdate({
    projectId,
    imageId,
    requestBody,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Image ID
     */
    imageId: string,
    requestBody?: (ImageReplaceRequest | ImageExternalReplaceRequest),
  }): CancelablePromise<Image1> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/v1/projects/{project_id}/images/{image_id}',
      path: {
        'project_id': projectId,
        'image_id': imageId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Move or rename an existing image
   * @returns Image1 Returns the image metadata
   * @throws ApiError
   */
  public static imagesPatch({
    projectId,
    imageId,
    requestBody,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Image ID
     */
    imageId: string,
    requestBody?: OmitReadonly<ImageMoveRenameRequest>,
  }): CancelablePromise<Image1> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/v1/projects/{project_id}/images/{image_id}',
      path: {
        'project_id': projectId,
        'image_id': imageId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Deletes image
   * @returns void
   * @throws ApiError
   */
  public static imagesDelete({
    projectId,
    imageId,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Image ID
     */
    imageId: string,
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/v1/projects/{project_id}/images/{image_id}',
      path: {
        'project_id': projectId,
        'image_id': imageId,
      },
    });
  }

  /**
   * Update image status
   * @returns void
   * @throws ApiError
   */
  public static imagesStatusUpdate({
    projectId,
    imageId,
    requestBody,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Image ID
     */
    imageId: string,
    requestBody?: {
      status?: ImageStatusWrite;
    },
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/v1/projects/{project_id}/images/{image_id}/status',
      path: {
        'project_id': projectId,
        'image_id': imageId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
