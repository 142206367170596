import { takeEvery } from 'typed-redux-saga';

import { NOTIFICATION_SENT } from '../../ws/ws.constants';
import { invalidateQueries } from '../../../../api/api';
import { getNotificationsInfiniteQuery } from '../../../../api/domain/notification';
import { NotificationsService, ProjectService } from '../../../../api/codegen';
import { WebsocketNotificationPayload } from '../../../../api/domainModels/websocketTypes';

function websocketNotificationSentHandler({
  payload: { jobId },
}: WebsocketNotificationPayload & { type: string }) {
  invalidateQueries(NotificationsService.getV1NotificationsStatus);
  invalidateQueries(getNotificationsInfiniteQuery);
  if (jobId) invalidateQueries(ProjectService.getV1Jobs, { jobId });
}

export function* notificationsSaga() {
  yield* takeEvery(NOTIFICATION_SENT, websocketNotificationSentHandler);
}
