import { combineReducers } from 'redux';

import { apiKeysReducer } from './apiAccounts/apiKeys.slice';
import { serviceAccountsReducer } from './apiAccounts/serviceAccounts.slice';
import { basicsReducer } from './basics/basics.slice';
import { collaboratorsReducer } from './collaborators/collaborators.slice';
import { invoicesReducer } from './invoices/invoices.slice';
import { paymentsReducer } from './payments/payments.slice';
import { rolesReducer } from './roles/roles.slice';
import { externalBucketsReducer } from './externalBuckets/externalBuckets.slice';
import { creditsReducer } from './credits/credits.slice';
import { stripeReducer } from './stripe/stripe.slice';

export const managedWorkspaceReducer = combineReducers({
  payments: paymentsReducer,
  invoices: invoicesReducer,
  basics: basicsReducer,
  roles: rolesReducer,
  collaborators: collaboratorsReducer,
  serviceAccounts: serviceAccountsReducer,
  apiKeys: apiKeysReducer,
  externalBuckets: externalBucketsReducer,
  credits: creditsReducer,
  stripe: stripeReducer,
});
