import { labelsSaga } from './labels/labels.saga';
import { labelClassesSaga } from './labelClasses/labelClasses.saga';
import { selectedLabelsSaga } from './labels/selectedLabels/selectedLabels.saga';
import { clipboardSaga } from './clipboard/clipboard.saga';
import { imageGallerySaga } from './imageGallery/imageGallery.saga';
import { labelAttributesValuesSaga } from './labelAttributeValues/labelAttributeValues.saga';
import { currentImageSagas } from './currentImage/currentImage.saga';
import { hiddenClassIdsSaga } from './hiddenClasses/hiddenClasses.saga';
import { projectSagas } from './project/project.saga';
import { toolSagas } from './tools/tools.saga';
import { persistedProjectImageSaga } from './persistedProjectImage/persitedProjectImage.saga';
import { labelSyncSaga } from './labels/labelSync/labelSync.saga';
import { imageNavigationSaga } from './imageNavigation/imageNavigation.saga';
import { consensusScoringSaga } from './consensusScoring/consensusScoring.saga';
import { multiLabelAttributesValuesSaga } from './multiLabelAttributeValues/multiLabelAttributeValues.saga';
import { fingerInputSaga } from './fingerInput/fingerInput.saga';
import { imageGalleryItemsSaga } from './imageGallery/imageGalleryItems/imageGalleryItems.saga';
import { filtersSaga as imageGalleryFiltersSaga } from './imageGallery/bottomBar/filters/filters.saga';

export const imageViewSagas = [
  labelsSaga,
  labelClassesSaga,
  selectedLabelsSaga,
  clipboardSaga,
  labelAttributesValuesSaga,
  multiLabelAttributesValuesSaga,
  hiddenClassIdsSaga,
  persistedProjectImageSaga,
  labelSyncSaga,
  imageNavigationSaga,
  consensusScoringSaga,
  fingerInputSaga,
  imageGallerySaga,
  imageGalleryItemsSaga,
  imageGalleryFiltersSaga,
  ...projectSagas,
  ...currentImageSagas,
  ...toolSagas,
];
