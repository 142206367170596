import chroma from 'chroma-js';
import { schemePaired } from 'd3-scale-chromatic';
import { RGBColor } from 'react-color';

import { DEFAULT_OPACITY } from '../constants/labels';

const MAX_COLOR_VALUE = 16581375;

export const colorNameToHex = (color: string) => {
  const colors: Record<string, string> = {
    aliceblue: '#f0f8ff',
    antiquewhite: '#faebd7',
    aqua: '#00ffff',
    aquamarine: '#7fffd4',
    azure: '#f0ffff',
    beige: '#f5f5dc',
    bisque: '#ffe4c4',
    black: '#000000',
    blanchedalmond: '#ffebcd',
    blue: '#0000ff',
    blueviolet: '#8a2be2',
    brown: '#a52a2a',
    burlywood: '#deb887',
    cadetblue: '#5f9ea0',
    chartreuse: '#7fff00',
    chocolate: '#d2691e',
    coral: '#ff7f50',
    cornflowerblue: '#6495ed',
    cornsilk: '#fff8dc',
    crimson: '#dc143c',
    cyan: '#00ffff',
    darkblue: '#00008b',
    darkcyan: '#008b8b',
    darkgoldenrod: '#b8860b',
    darkgray: '#a9a9a9',
    darkgreen: '#006400',
    darkkhaki: '#bdb76b',
    darkmagenta: '#8b008b',
    darkolivegreen: '#556b2f',
    darkorange: '#ff8c00',
    darkorchid: '#9932cc',
    darkred: '#8b0000',
    darksalmon: '#e9967a',
    darkseagreen: '#8fbc8f',
    darkslateblue: '#483d8b',
    darkslategray: '#2f4f4f',
    darkturquoise: '#00ced1',
    darkviolet: '#9400d3',
    deeppink: '#ff1493',
    deepskyblue: '#00bfff',
    dimgray: '#696969',
    dodgerblue: '#1e90ff',
    firebrick: '#b22222',
    floralwhite: '#fffaf0',
    forestgreen: '#228b22',
    fuchsia: '#ff00ff',
    gainsboro: '#dcdcdc',
    ghostwhite: '#f8f8ff',
    gold: '#ffd700',
    goldenrod: '#daa520',
    grey: '#808080',
    gray: '#808080',
    green: '#008000',
    greenyellow: '#adff2f',
    honeydew: '#f0fff0',
    hotpink: '#ff69b4',
    indianred: '#cd5c5c',
    indigo: '#4b0082',
    ivory: '#fffff0',
    khaki: '#f0e68c',
    lavender: '#e6e6fa',
    lavenderblush: '#fff0f5',
    lawngreen: '#7cfc00',
    lemonchiffon: '#fffacd',
    lightblue: '#add8e6',
    lightcoral: '#f08080',
    lightcyan: '#e0ffff',
    lightgoldenrodyellow: '#fafad2',
    lightgrey: '#d3d3d3',
    lightgreen: '#90ee90',
    lightpink: '#ffb6c1',
    lightsalmon: '#ffa07a',
    lightseagreen: '#20b2aa',
    lightskyblue: '#87cefa',
    lightslategray: '#778899',
    lightsteelblue: '#b0c4de',
    lightyellow: '#ffffe0',
    lime: '#00ff00',
    limegreen: '#32cd32',
    linen: '#faf0e6',
    magenta: '#ff00ff',
    maroon: '#800000',
    mediumaquamarine: '#66cdaa',
    mediumblue: '#0000cd',
    mediumorchid: '#ba55d3',
    mediumpurple: '#9370d8',
    mediumseagreen: '#3cb371',
    mediumslateblue: '#7b68ee',
    mediumspringgreen: '#00fa9a',
    mediumturquoise: '#48d1cc',
    mediumvioletred: '#c71585',
    midnightblue: '#191970',
    mintcream: '#f5fffa',
    mistyrose: '#ffe4e1',
    moccasin: '#ffe4b5',
    navajowhite: '#ffdead',
    navy: '#000080',
    oldlace: '#fdf5e6',
    olive: '#808000',
    olivedrab: '#6b8e23',
    orange: '#ffa500',
    orangered: '#ff4500',
    orchid: '#da70d6',
    palegoldenrod: '#eee8aa',
    palegreen: '#98fb98',
    paleturquoise: '#afeeee',
    palevioletred: '#d87093',
    papayawhip: '#ffefd5',
    peachpuff: '#ffdab9',
    peru: '#cd853f',
    pink: '#ffc0cb',
    plum: '#dda0dd',
    powderblue: '#b0e0e6',
    purple: '#800080',
    rebeccapurple: '#663399',
    red: '#ff0000',
    rosybrown: '#bc8f8f',
    royalblue: '#4169e1',
    saddlebrown: '#8b4513',
    salmon: '#fa8072',
    sandybrown: '#f4a460',
    seagreen: '#2e8b57',
    seashell: '#fff5ee',
    sienna: '#a0522d',
    silver: '#c0c0c0',
    skyblue: '#87ceeb',
    slateblue: '#6a5acd',
    slategray: '#708090',
    snow: '#fffafa',
    springgreen: '#00ff7f',
    steelblue: '#4682b4',
    tan: '#d2b48c',
    teal: '#008080',
    thistle: '#d8bfd8',
    tomato: '#ff6347',
    turquoise: '#40e0d0',
    violet: '#ee82ee',
    wheat: '#f5deb3',
    white: '#ffffff',
    whitesmoke: '#f5f5f5',
    yellow: '#ffff00',
    yellowgreen: '#9acd32',
  };

  if (typeof colors[color.toLowerCase()] !== 'undefined') {
    return colors[color.toLowerCase()];
  }

  return '';
};

export const getColorValue = (color: string) =>
  color.charAt(0) !== '#' ? colorNameToHex(color) : color;

export const generateHexFromRGBA = (color: string | RGBColor) =>
  chroma(
    typeof color === 'string'
      ? color
      : `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
  ).hex('rgba');

export const generateRGBA = (color: string, defaultOpacity = true) => {
  const [r, g, b, opacity] = chroma(color).rgba();
  const a = defaultOpacity ? DEFAULT_OPACITY : opacity;

  return { r, g, b, a };
};

export const generateFullHex = (color: string) => {
  let convertedColor = getColorValue(color);

  if (convertedColor && convertedColor.length === 7) {
    convertedColor = chroma(convertedColor).alpha(DEFAULT_OPACITY).hex('rgba');
  }

  return convertedColor;
};

export const adaptColorWithOpacity = (
  color: string,
  opacityChange: number,
  addition = true,
) => {
  const rgba = generateRGBA(color, false);

  if (addition) {
    rgba.a = rgba.a + opacityChange > 1 ? 1 : rgba.a + opacityChange;
  } else {
    rgba.a = opacityChange;
  }

  return chroma(`rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})`).hex('rgba');
};

export const getColorWithContrastingOpacityOverride = (
  color: string,
  threshold: number,
  lowOverride: number,
  highOverride: number,
) => {
  const rgba = generateRGBA(color, false);

  if (rgba.a > threshold) {
    rgba.a = rgba.a + lowOverride > 1 ? 1 : rgba.a + lowOverride;
  } else {
    rgba.a = highOverride;
  }

  return chroma(`rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})`).hex('rgba');
};

export const isColorOpacityAboveThreshold = (
  color: string,
  threshold: number,
) => {
  const rgba = generateRGBA(color, false);

  return rgba.a > threshold;
};

export const isEnoughContrast = (color1: string, color2: string) =>
  chroma.contrast(color1, color2) > 2;

export const getBetterContrastingColor = (
  color: string,
  compare1 = '#ffffff',
  compare2 = '#000000',
) => {
  const colors = [compare1, compare2];
  const contrasts = colors.map((compare) => chroma.contrast(color, compare));

  return colors[contrasts.indexOf(Math.max(...contrasts))];
};

export const getBorderColor = (rectColor: string, background: string) => {
  const step = 0.2;
  const defaultColor = '#ffffff';
  let currentColor = rectColor;
  let keepAdjusting;

  do {
    let chromaColor;
    try {
      chromaColor = chroma(currentColor);
    } catch (e) {
      chromaColor = chroma(defaultColor);
    }
    const brighterColor = chromaColor.brighten(step).hex();
    keepAdjusting =
      !isEnoughContrast(background, brighterColor) &&
      currentColor !== brighterColor;
    currentColor = brighterColor;
  } while (keepAdjusting);

  return currentColor;
};

export const generateColor = (i: number) => {
  if (i < schemePaired.length) {
    return schemePaired[i];
  }

  return `#${Math.round(Math.random() * MAX_COLOR_VALUE)
    .toString(16)
    .padStart(6, '0')}`;
};
