import { createSelector } from '@reduxjs/toolkit';

import { errorFinderSelector } from '../../errorFinder.selectors';

export const runsPaginationSelector = createSelector(
  errorFinderSelector,
  (errorFinder) => errorFinder.runsPagination,
);

export const itemsPerPageSelector = createSelector(
  runsPaginationSelector,
  (pagination) => pagination.itemsPerPage,
);
