import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Role } from '../../../../../api/domainModels/role';
import { ProjectRoleParams } from '../../../../../api/domainModels/projectRoles';
import {
  LoadingState,
  loadingStateBuilder,
} from '../../../../utils/loadingState';
import { setProjectId } from '../../../core/imageView/project/project.slice';

export type RolePrivilegeValue = {
  roleId: number;
  value?: boolean;
  originalValue?: boolean;
  readOnly: boolean;
};

export type PrivilegeState = {
  id: string;
  title: string;
  description: string;
  simple: boolean;
  values: Record<number, RolePrivilegeValue>;
};

export type RolePrivilegesState = {
  group: string;
  simple: boolean;
  values: Record<number, RolePrivilegeValue>;
  privileges: Record<string, PrivilegeState>;
};

export type RolesState = {
  loadingState: LoadingState;
  updateLoadingState: LoadingState;
  actionLoadingState: LoadingState;
  rolePrivileges: {
    data: Record<string, RolePrivilegesState>;
    loadingState: LoadingState;
  };
  data: Role[];
};

const initialState: RolesState = {
  loadingState: loadingStateBuilder.initial(),
  updateLoadingState: loadingStateBuilder.initial(),
  actionLoadingState: loadingStateBuilder.initial(),
  rolePrivileges: {
    data: {},
    loadingState: loadingStateBuilder.initial(),
  },
  data: [],
};

const { actions, reducer: rolesReducer } = createSlice({
  name: 'editedProject/roles',
  initialState,
  reducers: {
    loadRoles: (state, _action: PayloadAction<{ projectId: string }>) => {
      state.loadingState = loadingStateBuilder.inProgress('Loading roles');
    },
    loadRolesFailure: (state, action: PayloadAction<string>) => {
      state.loadingState = loadingStateBuilder.failure(action.payload);
    },
    loadRolesSuccess: (state, action: PayloadAction<Role[]>) => {
      state.loadingState = loadingStateBuilder.success();
      state.data = action.payload;
    },
    createRole: (
      state,
      _action: PayloadAction<{
        projectId: string;
        params: ProjectRoleParams;
      }>,
    ) => {
      state.actionLoadingState =
        loadingStateBuilder.inProgress('Creating role');
    },
    createRoleFailure: (state, action: PayloadAction<string>) => {
      state.actionLoadingState = loadingStateBuilder.failure(action.payload);
    },
    createRoleSuccess: (state, action: PayloadAction<Role>) => {
      state.actionLoadingState = loadingStateBuilder.success();
      state.data.push(action.payload);
    },
    updateRole: (
      state,
      _action: PayloadAction<{
        projectId: string;
        roleId: number;
        params: ProjectRoleParams;
      }>,
    ) => {
      state.updateLoadingState =
        loadingStateBuilder.inProgress('Updating role');
    },
    updateRoleFailure: (state, action: PayloadAction<string>) => {
      state.updateLoadingState = loadingStateBuilder.failure(action.payload);
    },
    updateRoleSuccess: (state, action: PayloadAction<Role>) => {
      state.updateLoadingState = loadingStateBuilder.success();
      const updatedRole = action.payload;
      const index = state.data.findIndex(
        (role: Role) => role.id === updatedRole.id,
      );
      state.data[index] = updatedRole;
    },
    deleteRole: (
      state,
      _action: PayloadAction<{ projectId: string; roleId: number }>,
    ) => {
      state.actionLoadingState =
        loadingStateBuilder.inProgress('Deleting role');
    },
    deleteRoleFailure: (state, action: PayloadAction<string>) => {
      state.actionLoadingState = loadingStateBuilder.failure(action.payload);
    },
    deleteRoleSuccess: (state, action: PayloadAction<{ roleId: number }>) => {
      const { roleId } = action.payload;
      state.actionLoadingState = loadingStateBuilder.success();
      state.data = state.data.filter((role) => role.id !== roleId);
    },
    loadRolePrivileges: (
      state,
      _action: PayloadAction<{ projectId: string }>,
    ) => {
      state.rolePrivileges.loadingState = loadingStateBuilder.inProgress(
        'Loading role privileges',
      );
    },
    loadRolePrivilegesFailure: (state, action: PayloadAction<string>) => {
      state.rolePrivileges.loadingState = loadingStateBuilder.failure(
        action.payload,
      );
    },
    loadRolePrivilegesSuccess: (
      state,
      action: PayloadAction<Record<string, RolePrivilegesState>>,
    ) => {
      state.rolePrivileges.loadingState = loadingStateBuilder.success();
      state.rolePrivileges.data = action.payload;
    },
    grantPrivileges: (
      state,
      _action: PayloadAction<{
        projectId: string;
        roleId: number;
        privilegeIds: string[];
      }>,
    ) => {
      state.actionLoadingState = loadingStateBuilder.inProgress(
        'Granting privileges',
      );
    },
    grantPrivilegesFailure: (state, action: PayloadAction<string>) => {
      state.actionLoadingState = loadingStateBuilder.failure(action.payload);
    },
    grantPrivilegesSuccess: (state, _action: PayloadAction) => {
      state.actionLoadingState = loadingStateBuilder.success();
    },
    revokePrivileges: (
      state,
      _action: PayloadAction<{
        projectId: string;
        roleId: number;
        privilegeIds: string[];
      }>,
    ) => {
      state.actionLoadingState = loadingStateBuilder.inProgress(
        'Revoking privileges',
      );
    },
    revokePrivilegesFailure: (state, action: PayloadAction<string>) => {
      state.actionLoadingState = loadingStateBuilder.failure(action.payload);
    },
    revokePrivilegesSuccess: (
      state,
      _action: PayloadAction<{ projectId: string; roleId: number }>,
    ) => {
      state.actionLoadingState = loadingStateBuilder.success();
    },
    changeRolePrivilege: (
      _state,
      _action: PayloadAction<{
        value: boolean;
        privilegeId: string;
        roleId: number;
        groupId: string;
      }>,
    ) => {},
    changeRolePrivilegeGroup: (
      _state,
      _action: PayloadAction<{
        value: boolean;
        groupId: string;
        roleId: number;
      }>,
    ) => {},
    replaceRolePrivileges: (
      state,
      action: PayloadAction<{ [key: string]: RolePrivilegesState }>,
    ) => {
      state.rolePrivileges.loadingState = loadingStateBuilder.success();
      state.rolePrivileges.data = action.payload;
    },
    saveRolePrivilegesChanges: (_state, _action: PayloadAction) => {},
    cancelRolePrivilegesChanges: (_state, _action: PayloadAction) => {},
  },
  extraReducers: (builder) => {
    builder.addCase(setProjectId, () => initialState);
  },
});

export const {
  loadRoles,
  loadRolesFailure,
  loadRolesSuccess,
  createRole,
  createRoleFailure,
  createRoleSuccess,
  updateRole,
  updateRoleFailure,
  updateRoleSuccess,
  deleteRole,
  deleteRoleFailure,
  deleteRoleSuccess,
  loadRolePrivileges,
  loadRolePrivilegesFailure,
  loadRolePrivilegesSuccess,
  replaceRolePrivileges,
  saveRolePrivilegesChanges,
  cancelRolePrivilegesChanges,
  grantPrivileges,
  grantPrivilegesFailure,
  grantPrivilegesSuccess,
  revokePrivileges,
  revokePrivilegesFailure,
  revokePrivilegesSuccess,
  changeRolePrivilege,
  changeRolePrivilegeGroup,
} = actions;

export { rolesReducer };
