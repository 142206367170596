import { call, put, takeEvery, select } from 'typed-redux-saga';

import { apiLoadWidgetExperimentsMetrics } from '../../../../../../../api/requests/widgets';
import { getErrorMessage } from '../../../../../../../api/utils';
import { activeProjectIdSelector } from '../../../../../project/project.selectors';
import {
  loadMultipleGpuConsumption,
  loadMultipleGpuConsumptionSuccess,
  loadMultipleGpuConsumptionFailure,
} from './multipleGpuConsumption.slice';
import { widgetMultipleGpuConsumptionMapper } from '../../../../../../../api/domainModels/widgets';
import { enqueueNotification } from '../../../../../ui/stackNotifications/stackNotifications.slice';

function* loadMultipleGpuConsumptionHandler(
  action: ActionType<typeof loadMultipleGpuConsumption>,
) {
  const projectId = yield* select(activeProjectIdSelector);
  const experimentIds = action.payload;

  try {
    const { data } = yield* call(
      apiLoadWidgetExperimentsMetrics,
      {
        projectId,
      },
      { experimentIds },
    );

    const items = data.items.map(
      widgetMultipleGpuConsumptionMapper.fromBackend,
    );

    yield* put(loadMultipleGpuConsumptionSuccess(items));
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to fetch widget data');

    yield* put(loadMultipleGpuConsumptionFailure(message));
    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
          refresh: false,
        },
      }),
    );
  }
}

export function* multipleGpuConsumptionSaga() {
  yield* takeEvery(
    loadMultipleGpuConsumption,
    loadMultipleGpuConsumptionHandler,
  );
}
