import { call, put, takeEvery, select } from 'typed-redux-saga';

import { apiGetWidgetSemanticInferences } from '../../../../../../../api/requests/widgets';
import { getErrorMessage } from '../../../../../../../api/utils';
import { activeProjectIdSelector } from '../../../../../project/project.selectors';
import {
  loadSemanticInferences,
  loadSemanticInferencesSuccess,
  loadSemanticInferencesFailure,
  resetWidget,
} from './semanticInferences.slice';
import { dashboardActiveExperimentIdSelector } from '../../activeExperiment/activeExperiment.selectors';
import { handleError } from '../../../../../commonFeatures/errorHandler/errorHandler.actions';
import {
  semanticInferencesLimitSelector,
  semanticInferencesOffsetSelector,
} from './semanticInferences.selectors';
import { refetchWidgetData } from '../widgetsLiveUpdate/widgetsLiveUpdate.actions';
import { splitByIdSelector } from '../../../splits/data/data.selectors';
import { activeSplitIdSelector } from '../../../activeSplit/activeSplitId/activeSplitId.selectors';
import { setActiveExperimentId } from '../../activeExperiment/activeExperiment.slice';
import { ModelFamily } from '../../../../../../../api/constants/modelFamily';

function* loadDataHandler(
  action: ActionType<typeof loadSemanticInferences | typeof refetchWidgetData>,
) {
  const projectId = yield* select(activeProjectIdSelector);
  const experimentId = yield* select(dashboardActiveExperimentIdSelector);

  if (!projectId || !experimentId) return;

  const activeSplitId = yield* select(activeSplitIdSelector);

  if (!projectId || !experimentId) return;

  if (action.type === refetchWidgetData.type && activeSplitId) {
    const activeSplit = yield* select((state: RootState) =>
      splitByIdSelector(state, activeSplitId),
    );

    if (!activeSplit) {
      return;
    }

    const { modelFamily } = activeSplit;

    if (modelFamily !== ModelFamily.SemanticSegmentor) {
      return;
    }
  }

  const limit =
    action?.payload?.limit || (yield* select(semanticInferencesLimitSelector));
  const offset =
    action?.payload?.offset === undefined
      ? yield* select(semanticInferencesOffsetSelector)
      : action?.payload?.offset;

  try {
    const { data } = yield* call(apiGetWidgetSemanticInferences, {
      projectId,
      experimentId,
      params: { ...(action?.payload || {}), limit, offset },
    });
    const { items, meta } = data;

    yield* put(
      loadSemanticInferencesSuccess({
        meta,
        items: items.map((item, index) => ({
          id: `${item.imageId}-${item.iteration}-${index}`,
          ...item,
        })),
      }),
    );
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to fetch widget data');

    yield* put(loadSemanticInferencesFailure(message));

    yield* put(handleError({ message, error, allowOutsideOfEditor: true }));
  }
}

export function* setActiveExperimentIdHandler() {
  yield* put(resetWidget());
}

export function* semanticInferencesSaga() {
  yield* takeEvery(
    [loadSemanticInferences, refetchWidgetData],
    loadDataHandler,
  );
  yield* takeEvery(setActiveExperimentId, setActiveExperimentIdHandler);
}
