export enum ImportSessionStatus {
  New = 'NEW',
  Parsed = 'PARSED',
  Failed = 'FAILED',
  Imported = 'IMPORTED',
  FailedToParse = 'FAILED_TO_PARSE',
}

export enum ImportFileStatus {
  Uploaded = 'UPLOADED',
  Processed = 'PROCESSED',
  Failed = 'FAILED',
  Parsing = 'PARSING',
}
