import axios from 'axios';

import {
  ApiAttributeClassMapping,
  ApiDeleteProjectAttributesClassesParams,
  ApiLoadProjectAttributesClassesParams,
  ApiCreateProjectAttributesClassesParams,
} from '../schemas/attributeClasses';
import { API_URL } from '../../constants/paths';
import { apiWrapper } from '../utils';
import { headers, transformRequest, transformResponse } from '../helpers';

export const apiDeleteProjectAttributesClasses = (
  params: ApiDeleteProjectAttributesClassesParams,
  data: Partial<ApiAttributeClassMapping[]>,
) =>
  apiWrapper(
    axios.request<ApiAttributeClassMapping[]>({
      method: 'delete',
      headers,
      transformRequest,
      transformResponse,
      url: `${API_URL}/project/${params.projectId}/attributes_classes`,
      data,
    }),
  );

export const apiLoadProjectAttributesClasses = (
  params: ApiLoadProjectAttributesClassesParams,
) =>
  apiWrapper(
    axios.request<ApiAttributeClassMapping[]>({
      method: 'get',
      headers,
      transformRequest,
      transformResponse,
      url: `${API_URL}/project/${params.projectId}/attributes_classes`,
      params: {
        offset: params.offset,
        limit: params.limit,
      },
    }),
  );

export const apiCreateProjectAttributesClasses = (
  params: ApiCreateProjectAttributesClassesParams,
  data: Partial<ApiAttributeClassMapping[]>,
) =>
  apiWrapper(
    axios.request<ApiAttributeClassMapping[]>({
      method: 'post',
      headers,
      transformRequest,
      transformResponse,
      url: `${API_URL}/project/${params.projectId}/attributes_classes`,
      data,
    }),
  );
