import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  LoadingState,
  loadingStateBuilder,
} from '../../../../utils/loadingState';
import { resetManagedWorkspaceId } from '../../../commonFeatures/workspaceId/workspaceId.slice';

type InitialState = {
  portalUrl: string | null;
  workspaceId: string | null;
  loadingState: LoadingState;
};

const initialState: InitialState = {
  portalUrl: null,
  workspaceId: null,
  loadingState: loadingStateBuilder.initial(),
};

const { actions, reducer: stripeReducer } = createSlice({
  name: 'stripe',
  initialState,
  reducers: {
    loadStripePortalUrlAndOpen(state) {
      state.loadingState = loadingStateBuilder.inProgress();
    },
    loadStripePortalUrlSuccess(
      state,
      action: PayloadAction<{ url: string; workspaceId: string }>,
    ) {
      const { url, workspaceId } = action.payload;

      state.loadingState = loadingStateBuilder.success();

      state.portalUrl = url;
      state.workspaceId = workspaceId;
    },
    loadStripePortalUrlFailure(state, action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.failure(action.payload);

      state.portalUrl = null;
      state.workspaceId = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetManagedWorkspaceId, () => initialState);
  },
});

export { stripeReducer };
export const {
  loadStripePortalUrlAndOpen,
  loadStripePortalUrlSuccess,
  loadStripePortalUrlFailure,
} = actions;
