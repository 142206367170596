import { createSlice } from '@reduxjs/toolkit';

const { actions, reducer: panningReducer } = createSlice({
  name: 'panning',
  initialState: {
    enabled: false,
  },
  reducers: {
    enablePanning(state) {
      state.enabled = true;
    },
    disablePanning(state) {
      state.enabled = false;
    },
  },
});

export const { enablePanning, disablePanning } = actions;
export { panningReducer };
