import { ApiBoxToInstanceResponseItem } from '../schemas/boxToInstance';

const fromBackend = ({ labelId, ...entry }: ApiBoxToInstanceResponseItem) => ({
  ...entry,
  id: labelId,
});

export const boxToInstanceDataMapper = {
  fromBackend,
};
