// User Mission Trigger
import { Dictionary } from '@reduxjs/toolkit';

export enum TriggerStatusRaw {
  Hidden = 'false',
  Visible = 'true',
  Completed = 'completed',
}

export enum TriggerStatus {
  Pending = 'pending',
  Hidden = 'hidden',
  Visible = 'visible',
  Completed = 'completed',
}

export type TriggerData = {
  [key: string]: boolean;
};

type TriggerInitialStep = {
  id:
    | UserMissionStepIds.WelcomeStep
    | DataCleaningStepIds.WelcomeStep
    | OptimizeModelsStepIds.WelcomeStep;
  intro: string;
  title: string;
  subtitle: string;
};

export type TriggerStep = {
  id: UserMissionStepIds | DataCleaningStepIds | OptimizeModelsStepIds;
  TitleComponent: React.FC;
  ContentComponent: React.FC;
  isCompleted: (props: TriggerInputProps) => boolean;
};

export type TriggerWidget = {
  id: string;
  status: TriggerStatus;
  data?: TriggerData;
};

export type DisplayTrigger = {
  id: TriggerIds;
  title: string;
  lockedText?: string;
  initial: TriggerInitialStep;
  steps: TriggerStep[];
  privileges: Record<string, string>;
};

export type TriggerInputProps = {
  noOfDatasets?: number;
  noOfImages?: number;
  noOfLabelClasses?: number;
  noOfLabeledImages?: number;
  noOfAiCSRuns?: number;
  noOfAiCSDoneRuns?: number;
  noOfUserMPSplits?: number;
  hasCreatedAnyMPExperiment?: boolean;
  noOfUsersMPStartedExperiments?: number;
  noOfUsersMPCompletedExperiments?: number;
  localStorageTriggersData?: Dictionary<TriggerWidget>;
};

export enum TriggerIds {
  UserMission = 'user_mission',
  DataCleaning = 'data_cleaning',
  OptimizeModels = 'optimize_models',
}

export enum UserMissionStepIds {
  WelcomeStep = 'welcome_step_user_mission',
  Step1 = 'create_dataset_and_files_step',
  Step2 = 'create_classes_step',
  Step3 = 'goal_1_labeled_image_step',
  Step4 = 'goal_10_labeled_images_step',
  Step5 = 'goal_50_labeled_images_step',
  Step6 = 'goal_200_labeled_images_step',
  FinalStep = 'final_step_user_mission',
}

export enum DataCleaningStepIds {
  WelcomeStep = 'welcome_step_data_cleaning',
  Step1 = 'create_ai_cs_run',
  Step2 = 'check_ai_cs_results',
  Step3 = 'check_update_ai_cs_results',
  FinalStep = 'final_step_data_cleaning',
}

export enum OptimizeModelsStepIds {
  WelcomeStep = 'welcome_step_optimize_models',
  Step1 = 'create_split',
  Step2 = 'create_experiment',
  Step3 = 'start_experiment',
  Step4 = 'check_experiment_results',
  Step5 = 'deploy_experiment',
  FinalStep = 'final_step_optimize_models',
}
