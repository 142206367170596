/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Image status
 */
export enum ImageStatus {
  NEW = 'NEW',
  IN_PROGRESS = 'IN PROGRESS',
  TO_REVIEW = 'TO REVIEW',
  DONE = 'DONE',
  SKIPPED = 'SKIPPED',
  AUTO_LABELLED = 'AUTO-LABELLED',
  COMPLETED = 'COMPLETED',
}
