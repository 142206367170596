import { createSelector } from '@reduxjs/toolkit';

import { editedProjectSelector } from '../../editedProject.selectors';

const activeSessionSelector = createSelector(
  editedProjectSelector,
  (editedProject) => editedProject.imports.activeSession,
);

export const activeImportSessionIdSelector = createSelector(
  activeSessionSelector,
  (session) => session.id,
);

export const isImportSessionActiveSelector = createSelector(
  activeSessionSelector,
  (session) => session.id !== null,
);
