import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { Workspace } from '../../../../../api/domainModels/workspace';
import { isFreePackage } from '../../../../../constants/packageFeatures';
import { updateWorkspaceSuccess } from '../../managedWorkspace/basics/basics.slice';
import {
  LoadingState,
  loadingStateBuilder,
} from '../../../../utils/loadingState';
import { createWorkspaceSuccess } from '../createWorkspace/createWorkspace.slice';
import { hideModals } from '../../../ui/modals/modals.slice';

export const workspacesListAdapter = createEntityAdapter<Workspace>({
  selectId: (item) => item.workspaceId,
});

const initialState = workspacesListAdapter.getInitialState<{
  freeWorkspace: Workspace | null;
  archiveWorkspaceLoadingState: LoadingState;
  loadingState: LoadingState;
  workspacesLoaded: boolean;
  workspaceSearchString: string;
}>({
  freeWorkspace: null,
  archiveWorkspaceLoadingState: loadingStateBuilder.initial(),
  loadingState: loadingStateBuilder.initial(),
  workspacesLoaded: false,
  workspaceSearchString: '',
});

const { actions, reducer: workspacesReducer } = createSlice({
  name: 'workspaces',
  initialState,
  reducers: {
    changeWorkspaceSearchString(state, action: PayloadAction<string>) {
      state.workspaceSearchString = action.payload;
    },
    resetFreeWorkspace(state) {
      state.freeWorkspace = null;
    },
    archiveWorkspace(state, _action: PayloadAction<string>) {
      state.archiveWorkspaceLoadingState = loadingStateBuilder.inProgress();
    },
    archiveWorkspaceError(state, action: PayloadAction<string>) {
      state.archiveWorkspaceLoadingState = loadingStateBuilder.failure(
        action.payload,
      );
    },
    archiveWorkspaceSuccess(state, action: PayloadAction<string>) {
      state.archiveWorkspaceLoadingState = loadingStateBuilder.success();
      workspacesListAdapter.removeOne(state, action.payload);
    },
    loadWorkspaces(state) {
      state.loadingState = loadingStateBuilder.inProgress();
    },
    loadWorkspacesError(state, action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.failure(action.payload);
    },
    loadWorkspacesSuccess(state, action: PayloadAction<Workspace[]>) {
      state.loadingState = loadingStateBuilder.success();
      state.workspacesLoaded = true;
      const freeWorkspace = action.payload.find(({ subscription }) =>
        isFreePackage(subscription),
      );
      if (freeWorkspace) {
        state.freeWorkspace = freeWorkspace;
      }
      workspacesListAdapter.setAll(state, action.payload);
      state.workspacesLoaded = true;
    },
    updateWorkspaceSubscription(
      state,
      action: PayloadAction<{
        id: string;
        subscription: string;
        subscriptionTitle: string;
      }>,
    ) {
      const { id, subscription, subscriptionTitle } = action.payload;
      workspacesListAdapter.updateOne(state, {
        id,
        changes: {
          subscription,
          subscriptionTitle,
        },
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createWorkspaceSuccess, (state, { payload }) => {
      workspacesListAdapter.addOne(state, payload);
    });
    builder.addCase(
      updateWorkspaceSuccess,
      (state, action: PayloadAction<Workspace>) => {
        workspacesListAdapter.updateOne(state, {
          id: action.payload.workspaceId,
          changes: action.payload,
        });
      },
    );
    builder.addCase(hideModals, (state) => {
      state.archiveWorkspaceLoadingState = loadingStateBuilder.initial();
    });
  },
});

export { workspacesReducer };
export const {
  changeWorkspaceSearchString,
  resetFreeWorkspace,
  archiveWorkspace,
  archiveWorkspaceError,
  archiveWorkspaceSuccess,
  loadWorkspaces,
  loadWorkspacesError,
  loadWorkspacesSuccess,
  updateWorkspaceSubscription,
} = actions;
