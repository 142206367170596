export enum LoadingStatus {
  Failure = 'ERROR',
  Initial = 'INITIAL',
  InProgress = 'IN_PROGRESS',
  Success = 'SUCCESS',
}

export const statusChecks = {
  isError: (status: LoadingStatus) => status === LoadingStatus.Failure,
  isInitial: (status: LoadingStatus) => status === LoadingStatus.Initial,
  isInProgress: (status: LoadingStatus) => status === LoadingStatus.InProgress,
  isSuccess: (status: LoadingStatus) => status === LoadingStatus.Success,
};
