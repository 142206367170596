import shortid from 'shortid';

import RleWorker from './rle/rle.worker?worker';
import MaskWorker from './mask/mask.worker?worker';
import ImageDataWorker from './imageData/imageData.worker?worker';
import WSWorker from './ws/ws.worker?worker';
import drawingWorker from './drawing/drawing.worker?worker';

export const RLE_WORKER = 'rle';
export const MASK_WORKER = 'mask';
export const IMAGE_DATA_WORKER = 'imageDataWorker';
export const WS_WORKER = 'wsWorker';
export const DRAWING_WORKER = 'drawing';

export class TerminationError extends Error {}

const workerMap = {
  [RLE_WORKER]: {
    Factory: RleWorker,
  },
  [`${RLE_WORKER}_0`]: {
    Factory: RleWorker,
  },
  [`${RLE_WORKER}_1`]: {
    Factory: RleWorker,
  },
  [`${RLE_WORKER}_2`]: {
    Factory: RleWorker,
  },
  [`${RLE_WORKER}_3`]: {
    Factory: RleWorker,
  },
  [`${RLE_WORKER}_4`]: {
    Factory: RleWorker,
  },

  [MASK_WORKER]: {
    Factory: MaskWorker,
  },
  [`${MASK_WORKER}_0`]: {
    Factory: MaskWorker,
  },
  [`${MASK_WORKER}_1`]: {
    Factory: MaskWorker,
  },
  [`${MASK_WORKER}_2`]: {
    Factory: MaskWorker,
  },
  [`${MASK_WORKER}_3`]: {
    Factory: MaskWorker,
  },
  [`${MASK_WORKER}_4`]: {
    Factory: MaskWorker,
  },

  [IMAGE_DATA_WORKER]: {
    Factory: ImageDataWorker,
  },
  [`${IMAGE_DATA_WORKER}_0`]: {
    Factory: ImageDataWorker,
  },
  [`${IMAGE_DATA_WORKER}_1`]: {
    Factory: ImageDataWorker,
  },
  [`${IMAGE_DATA_WORKER}_2`]: {
    Factory: ImageDataWorker,
  },
  [`${IMAGE_DATA_WORKER}_3`]: {
    Factory: ImageDataWorker,
  },
  [`${IMAGE_DATA_WORKER}_4`]: {
    Factory: ImageDataWorker,
  },

  [WS_WORKER]: {
    Factory: WSWorker,
  },
  [`${DRAWING_WORKER}`]: {
    Factory: drawingWorker,
  },
  [`${DRAWING_WORKER}_0`]: {
    Factory: drawingWorker,
  },
  [`${DRAWING_WORKER}_1`]: {
    Factory: drawingWorker,
  },
  [`${DRAWING_WORKER}_2`]: {
    Factory: drawingWorker,
  },
  [`${DRAWING_WORKER}_3`]: {
    Factory: drawingWorker,
  },
  [`${DRAWING_WORKER}_4`]: {
    Factory: drawingWorker,
  },
};

const Observer = {
  addEventListener(requestId, callback, errorCallback) {
    this.listeners[requestId] = [callback, errorCallback];
  },

  dispatch(requestId = null, payload) {
    const callbacks = this.listeners[requestId];
    if (callbacks instanceof Array) {
      const [callback] = callbacks;
      callback(payload);
    }

    delete this.listeners[requestId];
  },

  dispatchTerminate() {
    const { listeners } = this;
    this.listeners = {};
    Object.values(listeners).forEach((callbacks) => {
      if (callbacks.length > 1) {
        const errorCallback = callbacks[1];
        errorCallback(new TerminationError());
      }
    });
  },

  init() {
    this.listeners = {};
  },
};

export const terminateWorker = (workerId) => {
  const workerData = workerMap[workerId];

  if (workerData.instance) {
    workerData.instance.terminate();
    workerData.observer.dispatchTerminate();
    workerData.instance = undefined;
  }
};

export const getWorker = (workerId) => {
  const workerData = workerMap[workerId];

  if (!workerData.instance) {
    const worker = new workerData.Factory();
    workerData.instance = worker;

    workerData.observer = Object.create(Observer);
    workerData.observer.init();

    const listener = (event) => {
      if (!event.data) return;
      const { requestId, payload } = event.data;
      workerData.observer.dispatch(requestId, payload);
    };

    worker.addEventListener('message', listener);
  }

  return workerData.instance;
};

export function* getResultFromWorker(workerId, params) {
  const workerRequestId = shortid.generate();
  const worker = getWorker(workerId);

  // run the action inside of worker
  return yield new Promise((resolve, reject) => {
    workerMap[workerId].observer.addEventListener(
      workerRequestId,
      (r) => {
        if (!r || r.error) {
          reject(new TerminationError());
        } else {
          resolve(r);
        }

        const listenerIds = Object.keys(workerMap[workerId].observer.listeners);

        if (listenerIds.length === 1 && listenerIds[0] === workerRequestId) {
          terminateWorker(workerId);
        }
      },
      reject,
    );

    // now we need to wait for an answer from the worker
    worker.postMessage({
      requestId: workerRequestId,
      ...params,
    });
  });
}

function poorMansHash(str) {
  let hash = 0;
  let i;
  let chr;
  let len;
  if (str.length === 0) return hash;
  for (i = 0, len = str.length; i < len; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }

  return Math.abs(hash) % 5;
}

export function getResultFromWorkerPool(_workerId, params) {
  const workerRequestId = shortid.generate();
  const workerId = `${_workerId}_${poorMansHash(workerRequestId)}`;
  const worker = getWorker(workerId);

  // run the action inside of worker
  return new Promise((resolve, reject) => {
    workerMap[workerId].observer.addEventListener(
      workerRequestId,
      (r) => {
        resolve(r);
        const listenerIds = Object.keys(workerMap[workerId].observer.listeners);
        if (listenerIds.length === 1 && listenerIds[0] === workerRequestId) {
          terminateWorker(workerId);
        }
      },
      reject,
    );

    // now we need to wait for an answer from the worker
    worker.postMessage({
      requestId: workerRequestId,
      ...params,
    });
  });
}

/**
 * @param {string} workerId
 * @returns {*}
 */
export function* getNextMessage(workerId) {
  // the below call will instantiate worker if not already instantiated
  getWorker(workerId);

  // run the action inside of worker
  return yield new Promise((resolve, reject) => {
    workerMap[workerId].observer.addEventListener(null, resolve, reject);
  });
}

export function passMessageToWorker(workerId, params) {
  const worker = getWorker(workerId);
  worker.postMessage({
    ...params,
  });
}
