import {
  CreateSplitData,
  SplitObjectsNumResponse,
} from '../../../../../api/domainModels/modelPlayground';

export type SplitFormValues = CreateSplitData;

const fromBackendSplitCounters = ({
  objectsNum,
  ...rest
}: SplitObjectsNumResponse) => ({
  objectCount: objectsNum,
  ...rest,
});

export const newSplitCountersMapper = {
  fromBackend: fromBackendSplitCounters,
};

export type SplitCounterItem = ReturnType<typeof fromBackendSplitCounters>;
