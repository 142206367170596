import { createSelector } from '@reduxjs/toolkit';

import { componentsAdapter, parametersAdapter } from './solvers.slice';
import { modelPlaygroundSelector } from '../../modelPlayground.selectors';

export const experimentSolversStateSelector = createSelector(
  modelPlaygroundSelector,
  (modelPlayground) => modelPlayground.activeExperiment.solvers,
);

export const experimentSolversComponentsStateSelector = createSelector(
  experimentSolversStateSelector,
  (state) => state.components,
);

const componentsSelectors = componentsAdapter.getSelectors(
  experimentSolversComponentsStateSelector,
);
export const experimentSolversDataSelector = componentsSelectors.selectAll;

export const selectedSolverSelector = createSelector(
  experimentSolversDataSelector,
  (solvers) => solvers.find((solver) => solver.selected),
);
export const selectedSolverIdSelector = createSelector(
  selectedSolverSelector,
  (selectedSolver) => selectedSolver?.id,
);
export const selectedSolverComponentIdSelector = createSelector(
  selectedSolverSelector,
  (selectedSolver) => selectedSolver?.componentId,
);
export const experimentSolversLoadingStateSelector = createSelector(
  experimentSolversComponentsStateSelector,
  (state) => state.loadingState,
);
export const experimentSolversUpdateLoadingStateSelector = createSelector(
  experimentSolversComponentsStateSelector,
  (state) => state.updateLoadingState,
);

export const experimentSolversParametersStateSelector = createSelector(
  experimentSolversStateSelector,
  (state) => state.parameters,
);

const parametersSelectors = parametersAdapter.getSelectors(
  experimentSolversParametersStateSelector,
);
export const solverParametersSelector = parametersSelectors.selectAll;

export const solverParametersLoadingStateSelector = createSelector(
  experimentSolversParametersStateSelector,
  (state) => state.loadingState,
);
export const solverParametersUpdateLoadingStateSelector = createSelector(
  experimentSolversParametersStateSelector,
  (state) => state.updateLoadingState,
);
export const solverParameterByIdSelector = (state: RootState, id: string) => {
  const parameters = solverParametersSelector(state);

  return parameters.find((parameter) => parameter.id === id);
};
