import { ABGroup } from '../api/domainModels/auth';
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  upsertLocalStorageItem,
} from '../helpers/localStorage';
import { getFeatureStorageKey } from '../helpers/localStorage/types';
import { Feature } from './features.types';

export const getFeatureLocalStorageItem = (key: Feature) => {
  const featureKey = getFeatureStorageKey(key);

  return getLocalStorageItem<string>(featureKey, null);
};

export const removeFeatureLocalStorageItem = (key: Feature) => {
  const featureKey = getFeatureStorageKey(key);

  removeLocalStorageItem(featureKey);
};

export const setFeatureLocalStorageItem = (key: Feature, value = 'enabled') => {
  const featureKey = getFeatureStorageKey(key);

  upsertLocalStorageItem(featureKey, value);
};

export const featuresList: Record<
  Feature,
  { description: string; nonBinary?: boolean }
> = {
  [Feature.SentryModal]: {
    description: 'Sentry error modal',
  },
  [Feature.LogWebsocket]: {
    description: 'Log websocket messages',
  },
  [Feature.DisableVersionCheck]: {
    description: 'Disable version check',
  },
  [Feature.DisableResolutionCheck]: {
    description: 'Disable resolution check',
  },
  [Feature.QuickFeatureToggler]: {
    description: 'Quick feature toggler',
  },
  [Feature.WidgetsLogScale]: {
    description: 'MP Widgets log scale',
  },
  [Feature.UserDataModal]: {
    description: 'User data modal',
  },
  [Feature.ABGroup]: {
    description: 'A/B group',
    nonBinary: true,
  },
  [Feature.NestedSplits]: {
    description: 'Nested splits',
  },
  [Feature.FingerTouchInput]: {
    description: 'Finger touch input',
  },
  [Feature.Keypoints]: {
    description: 'Keypoints',
  },
  [Feature.IncreasedImportLimit]: {
    description: 'Increased import limit',
  },
  [Feature.KeypointsDetection]: {
    description: 'Keypoints detection',
  },
  [Feature.SamMode]: {
    description: 'SAM mode',
  },
  [Feature.TextPrompt]: {
    description: 'Text prompt',
  },
  [Feature.MultipleInstance]: {
    description: 'SAM multiple instance',
  },
};

export const features: Record<Feature, string | boolean> = Object.entries(
  featuresList,
).reduce((result, [key, entry]) => {
  const isEnabled = getFeatureLocalStorageItem(key as Feature);

  result[key as Feature] = entry.nonBinary ? isEnabled || false : !!isEnabled;

  return result;
}, {} as Record<Feature, string | boolean>);

export const sentryModalFeature = features[Feature.SentryModal] as boolean;
export const logWebsocketMessagesFeature = features[
  Feature.LogWebsocket
] as boolean;
export const disableVersionCheckFeature = features[
  Feature.DisableVersionCheck
] as boolean;
export const disableResolutionCheckFeature = features[
  Feature.DisableResolutionCheck
] as boolean;
export const quickFeatureToggler = features[
  Feature.QuickFeatureToggler
] as boolean;
export const widgetsLogScaleFeature = features[
  Feature.WidgetsLogScale
] as boolean;
export const userDataModalFeature = features[Feature.UserDataModal] as boolean;
export const abGroupFeature = features[Feature.ABGroup] as ABGroup;
export const fingerTouchInputFeature = features[
  Feature.FingerTouchInput
] as boolean;
export const nestedSplitsFeature = features[Feature.NestedSplits] as boolean;
export const keypointsFeature = features[Feature.Keypoints] as boolean;
export const increasedImportLimitFeature = features[
  Feature.IncreasedImportLimit
] as boolean;
export const keypointsDetectionFeature = features[
  Feature.KeypointsDetection
] as boolean;
export const textPromptFeature = features[Feature.TextPrompt] as boolean;
export const samModeFeature = features[Feature.SamMode] as boolean;
export const multipleInstanceFeature = features[
  Feature.MultipleInstance
] as boolean;

export const API_FEATURES = [
  'videoActivityRecognition',
  'keypoints',
  'keypointsDetection',
];
