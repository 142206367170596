import { createBrowserHistory, History } from 'history';

const enhanceHistory = (history: History) => {
  let previous = 0;
  const { push, goBack } = history;

  history.push = (...args: Parameters<typeof push>) => {
    previous += 1;
    push(...args);
  };

  history.goBack = () => {
    if (previous > 0) {
      previous -= 1;
      goBack();
    } else {
      // If there is no hasty related history, send back to start page
      history.push('/');
    }
  };

  return history;
};

export const enhancedHistory = enhanceHistory(createBrowserHistory());
