import { createSelector } from '@reduxjs/toolkit';

import { componentsAdapter, parametersAdapter } from './losses.slice';
import { modelPlaygroundSelector } from '../../modelPlayground.selectors';

export const experimentLossesStateSelector = createSelector(
  modelPlaygroundSelector,
  (modelPlayground) => modelPlayground.activeExperiment.losses,
);

export const experimentLossesComponentsStateSelector = createSelector(
  experimentLossesStateSelector,
  (state) => state.components,
);

const componentsSelectors = componentsAdapter.getSelectors(
  experimentLossesComponentsStateSelector,
);
export const experimentLossesDataSelector = componentsSelectors.selectAll;

export const selectedLossSelector = createSelector(
  experimentLossesDataSelector,
  (losses) => losses.find((loss) => loss.selected),
);
export const selectedLossIdSelector = createSelector(
  selectedLossSelector,
  (selectedLoss) => selectedLoss?.id,
);
export const selectedLossComponentIdSelector = createSelector(
  selectedLossSelector,
  (selectedLoss) => selectedLoss?.componentId,
);
export const experimentLossesLoadingStateSelector = createSelector(
  experimentLossesComponentsStateSelector,
  (state) => state.loadingState,
);
export const experimentLossesUpdateLoadingStateSelector = createSelector(
  experimentLossesComponentsStateSelector,
  (state) => state.updateLoadingState,
);

export const experimentLossesParametersStateSelector = createSelector(
  experimentLossesStateSelector,
  (state) => state.parameters,
);

const parametersSelectors = parametersAdapter.getSelectors(
  experimentLossesParametersStateSelector,
);
export const lossParametersSelector = parametersSelectors.selectAll;

export const lossParametersLoadingStateSelector = createSelector(
  experimentLossesParametersStateSelector,
  (state) => state.loadingState,
);
export const lossParametersUpdateLoadingStateSelector = createSelector(
  experimentLossesParametersStateSelector,
  (state) => state.updateLoadingState,
);
export const lossParameterByIdSelector = (state: RootState, id: string) => {
  const parameters = lossParametersSelector(state);

  return parameters.find((parameter) => parameter.id === id);
};
