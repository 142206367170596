import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
  Update,
} from '@reduxjs/toolkit';

import {
  LabelClass,
  LabelClassType,
} from '../../../../../api/domainModels/labelClass';
import { loadingStateBuilder } from '../../../../utils/loadingState';

export type AddLabelClassFormValues = Pick<
  LabelClass,
  'name' | 'color' | 'type' | 'description'
> & {
  attributes: string[]; // ids
};

export type UpdateLabelClassFormValues = LabelClass & {
  attributes: string[]; // ids
};

export const labelClassesAdapter = createEntityAdapter<LabelClass>({
  sortComparer: (a, b) => (a.norder > b.norder ? 1 : -1),
});

const initialState = labelClassesAdapter.getInitialState({
  itemLoadingState: loadingStateBuilder.initial(),
  listLoadingState: loadingStateBuilder.initial(),
});

const { actions, reducer: labelClassesReducer } = createSlice({
  name: 'labelClasses',
  initialState,
  reducers: {
    addLabelClass(state, _action: PayloadAction<AddLabelClassFormValues>) {
      state.itemLoadingState = loadingStateBuilder.inProgress();
    },
    addLabelClassFailure(state, action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.failure(action.payload);
    },
    addLabelClassSuccess(state, action: PayloadAction<LabelClass>) {
      state.itemLoadingState = loadingStateBuilder.success();
      labelClassesAdapter.addOne(state, action.payload);
    },
    updateLabelClass(
      state,
      _action: PayloadAction<UpdateLabelClassFormValues>,
    ) {
      state.itemLoadingState = loadingStateBuilder.inProgress();
    },
    upsertLabelClassFromWebsockets(state, action: PayloadAction<LabelClass>) {
      labelClassesAdapter.upsertOne(state, action.payload);
    },
    updateLabelClassFailure(state, action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.failure(action.payload);
    },
    updateLabelClassOrder(
      _state,
      _action: PayloadAction<{
        id: string;
        index: number;
        classType: LabelClassType;
      }>,
    ) {},
    updateLabelClassSuccess(state, action: PayloadAction<Update<LabelClass>>) {
      state.itemLoadingState = loadingStateBuilder.success();
      labelClassesAdapter.updateOne(state, action.payload);
    },
    removeLabelClass(state, _action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.inProgress();
    },
    removeLabelClassFailure(state, action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.failure(action.payload);
    },
    removeLabelClassSuccess(state, action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.success();
      labelClassesAdapter.removeOne(state, action.payload);
    },
    removeLabelClassFromWebsockets(state, action: PayloadAction<string>) {
      labelClassesAdapter.removeOne(state, action.payload);
    },
    loadLabelClassesStart(state) {
      state.listLoadingState = loadingStateBuilder.inProgress();
    },
    loadLabelClassesFailure(state, action: PayloadAction<string>) {
      state.listLoadingState = loadingStateBuilder.failure(action.payload);
      labelClassesAdapter.setAll(state, []);
    },
    loadLabelClassesSuccess(state, action: PayloadAction<LabelClass[]>) {
      state.listLoadingState = loadingStateBuilder.success();
      labelClassesAdapter.setAll(state, action.payload);
    },
    resetLabelClassLoadingState(state) {
      state.itemLoadingState = loadingStateBuilder.initial();
    },
    reloadLabelClasses(_, _action: PayloadAction<{ id: string }>) {},
  },
});

export { labelClassesReducer };
export const {
  addLabelClass,
  addLabelClassFailure,
  addLabelClassSuccess,
  updateLabelClass,
  updateLabelClassFailure,
  updateLabelClassSuccess,
  removeLabelClass,
  removeLabelClassFailure,
  removeLabelClassSuccess,
  loadLabelClassesStart,
  loadLabelClassesFailure,
  loadLabelClassesSuccess,
  resetLabelClassLoadingState,
  updateLabelClassOrder,
  upsertLabelClassFromWebsockets,
  removeLabelClassFromWebsockets,
  reloadLabelClasses,
} = actions;
