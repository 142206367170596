import { atom, selector } from 'recoil';

import { apiLoadProjectRecoil } from '../../api/requests/project';

export const nullableProjectIdState = atom<string | null>({
  key: 'video/nullableProjectId',
  default: null,
});

export const projectState = selector({
  key: 'video/project',
  get: async ({ get }) => {
    const projectId = get(nullableProjectIdState);

    if (!projectId) return null;

    try {
      const { data } = await apiLoadProjectRecoil(
        { projectId },
        { errorMessage: "Couldn't load the project" },
      );

      return data;
    } catch {
      return null;
    }
  },
});
