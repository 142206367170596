import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  SidebarPanel,
  SidebarTab,
} from '../../../../@types/imageView/Sidebar.types';

const initialState: Record<SidebarTab, SidebarPanel[]> = {
  [SidebarTab.Annotation]: [SidebarPanel.LabelClasses],
  [SidebarTab.DataCleaning]: [],
};
const { actions, reducer: expandedPanelsReducer } = createSlice({
  name: 'sidebarPanels',
  initialState,
  reducers: {
    toggleSidebarPanel(
      state,
      _action: PayloadAction<{ id: SidebarPanel; activeTab: SidebarTab }>,
    ) {},
    setExpandedSidePanels(
      state,
      action: PayloadAction<{
        expandedPanels: SidebarPanel[];
        activeTab: SidebarTab;
      }>,
    ) {
      state[action.payload.activeTab] = action.payload.expandedPanels;
    },
  },
});

export const { toggleSidebarPanel, setExpandedSidePanels } = actions;
export { expandedPanelsReducer };
