/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Family of model
 */
export enum ModelFamilyActiveLearning {
  DETECTOR = 'detector',
  SEGMENTOR = 'segmentor',
  SEMANTIC_SEGMENTOR = 'semantic-segmentor',
}
