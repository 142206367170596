import { put, takeEvery, select } from 'typed-redux-saga';

import { apiLoadParseLogsForImportSession } from '../../../../../../api/requests/imports';
import { getErrorMessage } from '../../../../../../api/utils';
import { handleError } from '../../../../commonFeatures/errorHandler/errorHandler.actions';
import { activeProjectIdSelector } from '../../../../project/project.selectors';
import { loadAll } from '../../../../../utils/api';
import { activeImportSessionIdSelector } from '../session/importSession.selectors';
import {
  loadParseLogsStart,
  loadParseLogsSuccess,
  loadParseLogsFailure,
} from './importParseLogs.slice';
import { groupParseLogsByFileId } from './importParseLogs.util';

function* loadHandler(action: ActionType<typeof loadParseLogsStart>) {
  const { sessionId: sId, projectId: pId } = action.payload;
  const projectId = pId || (yield* select(activeProjectIdSelector));
  const sessionId = sId || (yield* select(activeImportSessionIdSelector));

  try {
    const parseLogs = yield* loadAll({
      apiHelper: apiLoadParseLogsForImportSession,
      params: {
        projectId,
        sessionId,
      },
    });

    const parseLogsByFileId = groupParseLogsByFileId(parseLogs);

    yield* put(loadParseLogsSuccess(parseLogsByFileId));
  } catch (error) {
    const errorMessage = getErrorMessage(
      error,
      'Not able to load session parse logs.',
    );

    yield* put(loadParseLogsFailure(errorMessage));
    yield* put(
      handleError({ message: errorMessage, error, allowOutsideOfEditor: true }),
    );
  }
}

export function* importParseLogsSaga() {
  yield* takeEvery(loadParseLogsStart, loadHandler);
}
