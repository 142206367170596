import { call, put, select, takeEvery } from 'typed-redux-saga';

import {
  apiCreateProjectAttributesClasses,
  apiDeleteProjectAttributesClasses,
  apiLoadProjectAttributesClasses,
} from '../../../../../api/requests/attributeClasses';
import { WebsocketAttributeClassPayload } from '../../../../../api/domainModels/websocketTypes';
import { getErrorMessage } from '../../../../../api/utils';
import { initImageProject } from '../../../sections/editedProject/project/project.slice';
import {
  ATTRIBUTE_CLASS_DELETED,
  ATTRIBUTE_CLASS_UPDATED,
} from '../../../ws/ws.constants';
import { activeProjectIdSelector } from '../../project.selectors';
import {
  deleteACManySuccess,
  deleteACRelationByWebsocket,
  loadACRelations,
  loadACRelationsFailure,
  loadACRelationsSuccess,
  processACRelationsFailure,
  processACRelationsStart,
  selectId,
  updateACManySuccess,
  updateACRelationByWebsocket,
} from './ACRelations.slice';
import {
  ACRelationsForAttributeSelector,
  ACRelationsForLabelClassSelector,
  ACRelationsMapSelector,
} from './ACRelations.selectors';
import { setProjectId } from '../../../core/imageView/project/project.slice';
import { AttributeClassMapping } from '../../../../../api/domainModels/attributeClasses';

function* listHandler(
  action: ActionType<typeof initImageProject | typeof setProjectId>,
) {
  const projectId = action.payload.id;

  yield* put(loadACRelations());

  try {
    const { data } = yield* call(apiLoadProjectAttributesClasses, {
      projectId,
    });
    yield* put(loadACRelationsSuccess(data));
  } catch (error) {
    yield* put(
      loadACRelationsFailure(
        getErrorMessage(error, 'Not able to reload relations'),
      ),
    );
  }
}

function* updateACRelations(relations: AttributeClassMapping[]) {
  const projectId = yield* select(activeProjectIdSelector);
  if (relations.length) {
    yield* put(processACRelationsStart());
    // insert/update the ones we got in the multiselect
    try {
      const { data } = yield* call(
        apiCreateProjectAttributesClasses,
        {
          projectId,
        },
        relations,
      );
      yield* put(updateACManySuccess(data));
    } catch (e) {
      yield* put(
        processACRelationsFailure(
          getErrorMessage(e, 'Not able to update relations'),
        ),
      );
      throw e; // throw so that the caller can process the error as well
    }
  }
}

function* deleteObsoleteACRelations(
  relations: AttributeClassMapping[],
  existingRelations: AttributeClassMapping[],
) {
  const projectId = yield* select(activeProjectIdSelector);
  const newRelationsIds = relations.map(selectId);
  const relationsToDelete = existingRelations.filter(
    (r) => !newRelationsIds.includes(selectId(r)),
  );

  if (relationsToDelete.length) {
    try {
      yield* call(
        apiDeleteProjectAttributesClasses,
        {
          projectId,
        },
        relationsToDelete.map((relation, i) => ({
          ...relation,
          classOrder: i,
        })),
      );
      yield* put(deleteACManySuccess(relationsToDelete));
    } catch (error) {
      yield* put(
        processACRelationsFailure(
          getErrorMessage(error, 'Not able to delete relations'),
        ),
      );
      throw error; // throw so that the caller can process the error as well
    }
  }
}

export function* processACLabelClassRelations(
  labelClassId: string,
  relations: AttributeClassMapping[],
) {
  yield* updateACRelations(relations);
  // delete the ones that are in the state but are not in the multiselect
  const existingRelations: AttributeClassMapping[] = yield* select(
    ACRelationsForLabelClassSelector,
    labelClassId,
  );
  yield* deleteObsoleteACRelations(relations, existingRelations);
}

export function* processACRelations(
  attributeId: string,
  relations: AttributeClassMapping[],
) {
  // delete the ones that are in the state but are not in the multiselect
  const existingRelations: AttributeClassMapping[] = yield* select(
    ACRelationsForAttributeSelector,
    attributeId,
  );
  const relationsMap = yield* select(ACRelationsMapSelector);

  // preserve the class order if there is already such a relation in the state
  const relationsWithPreservedOrder = relations.map((r) => {
    const existingRelation = relationsMap[selectId(r)];
    if (existingRelation) {
      return {
        ...r,
        classOrder: existingRelation.classOrder,
      };
    }

    return r;
  });

  yield* call(updateACRelations, relationsWithPreservedOrder);
  yield* call(
    deleteObsoleteACRelations,
    relationsWithPreservedOrder,
    existingRelations,
  );
}

function* websocketACRelationUpdatedHandler({
  attributeId,
  classId,
  attributeOrder,
  classOrder,
  projectId,
}: WebsocketAttributeClassPayload & { type: string }) {
  const currentProjectId = yield* select(activeProjectIdSelector);
  if (currentProjectId && currentProjectId === projectId) {
    yield* put(
      updateACRelationByWebsocket({
        attributeId,
        attributeOrder,
        classId,
        classOrder,
      }),
    );
  }
}

function* websocketACRelationRemovedHandler({
  attributeId,
  classId,
  attributeOrder,
  classOrder,
  projectId,
}: WebsocketAttributeClassPayload & { type: string }) {
  const currentProjectId = yield* select(activeProjectIdSelector);
  if (currentProjectId && currentProjectId === projectId) {
    yield* put(
      deleteACRelationByWebsocket({
        attributeId,
        attributeOrder,
        classId,
        classOrder,
      }),
    );
  }
}

export function* ACRelationsSaga() {
  yield* takeEvery([initImageProject, setProjectId], listHandler);
  yield* takeEvery(ATTRIBUTE_CLASS_UPDATED, websocketACRelationUpdatedHandler);
  yield* takeEvery(ATTRIBUTE_CLASS_DELETED, websocketACRelationRemovedHandler);
}
