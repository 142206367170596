import { takeEvery, put } from 'typed-redux-saga';

import { notifyJobProgress } from '../../ws/ws.actions';
import { updateJobProgress } from './jobProgress.slice';

function* jobProgressHandler(action: ActionType<typeof notifyJobProgress>) {
  yield* put(updateJobProgress(action.payload));
}

export function* jobProgressSaga() {
  yield* takeEvery(notifyJobProgress, jobProgressHandler);
}
