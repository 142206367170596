import { createSelector } from '@reduxjs/toolkit';

import { LoadingStatus } from '../../../../../../constants/status';
import { modelStatusChecks } from '../models/modelStatus.constants';
import { imageViewSelector } from '../../imageView.selectors';
import { resultsAdapter } from './atomResult.slice';

const atomStateSelector = createSelector(
  imageViewSelector,
  (imageView) => imageView.tools.atom,
);

const atomResultPresentDataSelector = createSelector(
  atomStateSelector,
  (atomState) => atomState.result.data.present,
);
const selectors = resultsAdapter.getSelectors(atomResultPresentDataSelector);

export const atomResultLoadingStateSelector = createSelector(
  atomStateSelector,
  (atomState) => atomState.result.loadingState,
);

export const atomIsLoadingSelector = createSelector(
  atomResultLoadingStateSelector,
  (loadingState) => loadingState.status === LoadingStatus.InProgress,
);

export const atomDataSelector = selectors.selectAll;
export const atomDataByIdSelector = selectors.selectById;

const atomValuesStateSelector = createSelector(
  atomStateSelector,
  (atomState) => atomState.values,
);

export const atomPointsSelector = createSelector(
  atomValuesStateSelector,
  (valuesState) => valuesState.present.points,
);

export const atomVisibleAreaBboxSelector = createSelector(
  atomValuesStateSelector,
  (valuesState) => valuesState.present.visibleAreaBbox,
);

export const atomInputBboxSelector = createSelector(
  atomValuesStateSelector,
  (valuesState) => valuesState.present.inputBbox,
);

export const atomInputScribbleMaskSelector = createSelector(
  atomValuesStateSelector,
  (valuesState) => valuesState.present.inputScribbleMask,
);

export const atomInputScribbleBboxSelector = createSelector(
  atomValuesStateSelector,
  (valuesState) => valuesState.present.inputScribbleBbox,
);

export const atomModelSelector = createSelector(
  atomStateSelector,
  (valuesState) => valuesState.model,
);

export const atomModelIdSelector = createSelector(
  atomModelSelector,
  (model) => model.id,
);

export const atomModelStatusSelector = createSelector(
  atomModelSelector,
  (model) => model.status,
);

export const atomModelAvailableSelector = createSelector(
  atomModelIdSelector,
  (modelId) => typeof modelId === 'number',
);

export const atomModelLoadedSelector = createSelector(
  [atomModelAvailableSelector, atomModelStatusSelector],
  (available, status) =>
    !!(available && status && modelStatusChecks.isLoaded(status)),
);
