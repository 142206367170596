import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'typed-redux-saga';

import {
  apiDeleteProjectInference,
  apiLoadProjectInferences,
  apiMoveProjectInference,
} from '../../../../../api/requests/inferences';
import { getErrorMessage } from '../../../../../api/utils';
import { pluralize } from '../../../../../helpers/plural';
import { assertIsDefined } from '../../../../../helpers/asserts';
import { activeProjectIdSelector } from '../../project.selectors';
import {
  inferenceItemsFiltersSelector,
  inferenceItemsSelectedIdsSelector,
} from './inferences.selectors';
import {
  deleteSelectedInferences,
  inferenceActionSuccess,
  loadInferenceItems,
  loadInferenceItemsFailure,
  loadInferenceItemsSuccess,
  moveSelectedInferences,
} from './inferences.slice';
import { enqueueNotification } from '../../../ui/stackNotifications/stackNotifications.slice';

function* loadHandler() {
  const projectId = yield* select(activeProjectIdSelector);
  const { page, perPage, family } = yield* select(
    inferenceItemsFiltersSelector,
  );
  try {
    assertIsDefined(family);

    const { data } = yield* call(apiLoadProjectInferences, {
      projectId,
      params: {
        family,
        offset: (page - 1) * perPage,
        limit: perPage,
      },
    });
    yield* put(
      loadInferenceItemsSuccess({
        items: data.items,
        total: data.meta.total,
      }),
    );
  } catch (e) {
    yield* put(
      loadInferenceItemsFailure(
        getErrorMessage(e, 'Could not load inferences'),
      ),
    );
  }
}

function* moveHandler(action: ActionType<typeof moveSelectedInferences>) {
  const projectId = yield* select(activeProjectIdSelector);
  const selectedIds = yield* select(inferenceItemsSelectedIdsSelector);
  if (!selectedIds.length) {
    return;
  }

  const results = yield* all(
    selectedIds.map(function* (id) {
      try {
        yield* call(
          apiMoveProjectInference,
          { projectId, inferenceId: id },
          { datasetId: action.payload.datasetId },
        );

        return true;
      } catch {
        return false;
      }
    }),
  );

  const successCount = results.filter(Boolean).length;
  const errorCount = results.length - successCount;

  yield* put(
    enqueueNotification({
      message: `${pluralize(
        successCount,
        'image',
      )} moved to the selected dataset. ${
        errorCount
          ? `${pluralize(errorCount, 'image')} couldn't be processed`
          : ''
      }`,
      options: {
        variant: 'info',
        allowOutsideOfEditor: true,
      },
    }),
  );

  yield* put(inferenceActionSuccess());
  const { page, family } = yield* select(inferenceItemsFiltersSelector);
  assertIsDefined(family);
  yield* put(loadInferenceItems({ page, family }));
}

function* deleteHandler() {
  const projectId = yield* select(activeProjectIdSelector);
  const selectedIds = yield* select(inferenceItemsSelectedIdsSelector);
  if (!selectedIds.length) {
    return;
  }

  const results = yield* all(
    selectedIds.map(function* (id) {
      try {
        yield* call(apiDeleteProjectInference, { projectId, inferenceId: id });

        return true;
      } catch {
        return false;
      }
    }),
  );
  const successCount = results.filter(Boolean).length;
  const errorCount = results.length - successCount;

  yield* put(
    enqueueNotification({
      message: `${pluralize(successCount, 'image')} removed. ${
        errorCount
          ? `${pluralize(errorCount, 'image')} couldn't be processed`
          : ''
      }`,
      options: {
        variant: 'info',
        allowOutsideOfEditor: true,
      },
    }),
  );

  yield* put(inferenceActionSuccess());

  const { page, family } = yield* select(inferenceItemsFiltersSelector);

  assertIsDefined(family);

  yield* put(loadInferenceItems({ page, family }));
}

export function* inferenceItemsSaga() {
  yield* takeLatest(loadInferenceItems, loadHandler);
  yield* takeEvery(deleteSelectedInferences, deleteHandler);
  yield* takeEvery(moveSelectedInferences, moveHandler);
}
