import {
  ApiAttribute,
  ApiAttributeValueData,
  ApiLabelAttributeValue,
  ApiMultiLabelAttributeValue,
  ApiLoadProjectImageAttributesParams,
  ApiAttributePrediction,
} from '../schemas/attribute';
import { attributeValueDataMapper } from './attributeValue';
import { AttributeType } from '../constants/attribute';

export const attributeTypeLabels: Record<AttributeType, string> = {
  [AttributeType.String]: 'String',
  [AttributeType.Boolean]: 'Boolean',
  [AttributeType.Number]: 'Number',
  [AttributeType.Integer]: 'Integer',
  [AttributeType.Single]: 'Single Choice',
  [AttributeType.Multi]: 'Multiple Choice',
};

const fromBackend = ({ type, values, ...entry }: ApiAttribute) => ({
  ...entry,
  type: type as AttributeType,
  values: values?.map(attributeValueDataMapper.fromBackend),
});

export type Attribute = ReturnType<typeof fromBackend>;

export const attributeDataMapper = {
  fromBackend,
  toBackend({
    id,
    values,
    classCount,
    ...entry
  }: Partial<Attribute>): Partial<ApiAttribute> {
    return {
      ...entry,
      values: values?.map(attributeValueDataMapper.toBackend),
    };
  },
};

export type AttributePredictions = Record<string, string[]>;
export type AttributeValueData = ApiAttributeValueData;
export type LabelAttributeValue = ApiLabelAttributeValue;
export type MultiLabelAttributeValue = ApiMultiLabelAttributeValue;
export type AttributePrediction = ApiAttributePrediction;
export type LoadProjectImageAttributesParams =
  ApiLoadProjectImageAttributesParams;
