import { connectRouter } from 'connected-react-router';
import { persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { History } from 'history';
import { reducer as formReducer } from 'redux-form';

import { EDIT_PAYMENT_METHOD_FORM_NAME } from '../../constants/editPaymentMethodForm';
import { projectReducer } from './project/project.reducer';
import { uiReducer } from './ui/ui.reducer';
import { coreReducer } from './core/core.reducer';
import { commonFeaturesReducer } from './commonFeatures/commonFeatures.reducer';
import { sectionsReducer } from './sections/sections.reducer';

const uiPersistConfig: PersistConfig = {
  key: 'ui',
  storage,
  version: 2,
  blacklist: [
    'preloader',
    'calculations',
    'modals',
    'typedModals',
    'applicationOutdated',
    'errorRecovery',
    'permanentMessages',
    'stackNotifications',
    'controlledTooltip',
  ],
};

const formPersistConfig = {
  key: 'form',
  storage,
  version: 1,
  whitelist: [EDIT_PAYMENT_METHOD_FORM_NAME],
};

const rootReducers = {
  project: projectReducer,
  form: persistReducer(formPersistConfig, formReducer),
  ui: persistReducer(uiPersistConfig, uiReducer),
  core: coreReducer,
  commonFeatures: commonFeaturesReducer,
  sections: sectionsReducer,
};

export const constructStaticReducers = (history: History) => ({
  router: connectRouter(history),
  ...rootReducers,
});
