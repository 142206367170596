import { LoadingState } from '../../../../../utils/loadingState';
import { ModelFamilyStatus } from '../../../../../../api/constants/modelFamily';

export const MODEL_LABELING = 'labeling';
export const MODEL_TRAINING = 'training';
export const MODEL_TRAINED = 'trained';
export const MODEL_LOADING = 'loading';
export const MODEL_LOADED = 'loaded';
export const MODEL_FAILED = 'failed';
export const MODEL_QUEUED = 'queued'; // just for Video

export type ToolModel = {
  id: number | null;
  loadingState: LoadingState;
  status: ModelStatus | null;
  progress: number | null;
  showDot?: boolean;
};

export type ModelStatus =
  | typeof MODEL_LABELING
  | typeof MODEL_TRAINING
  | typeof MODEL_TRAINED
  | typeof MODEL_LOADING
  | typeof MODEL_LOADED
  | typeof MODEL_FAILED
  | typeof MODEL_QUEUED;

export const ModelFamilyStatusToModelStatusMap: Record<
  ModelFamilyStatus,
  ModelStatus
> = {
  [ModelFamilyStatus.Activated]: MODEL_LOADED,
  [ModelFamilyStatus.NotActivated]: MODEL_LABELING,
  [ModelFamilyStatus.Failed]: MODEL_FAILED,
  [ModelFamilyStatus.Training]: MODEL_TRAINING,
  [ModelFamilyStatus.Done]: MODEL_LOADED,
};

export const modelStatusChecks = {
  isLabeling: (status: ModelStatus) => status === MODEL_LABELING,
  isTraining: (status: ModelStatus) => status === MODEL_TRAINING,
  isTrained: (status: ModelStatus) => status === MODEL_TRAINED,
  isLoading: (status: ModelStatus) => status === MODEL_LOADING,
  isLoaded: (status: ModelStatus) => status === MODEL_LOADED,
  isFailed: (status: ModelStatus) => status === MODEL_FAILED,
  isQueued: (status: ModelStatus) => status === MODEL_QUEUED,
};

const statusTransitionMap: Record<ModelStatus, ModelStatus[]> = {
  [MODEL_LABELING]: [MODEL_QUEUED, MODEL_TRAINING],
  [MODEL_TRAINING]: [MODEL_LOADING, MODEL_LOADED],
  [MODEL_TRAINED]: [MODEL_LOADING, MODEL_LOADED],
  [MODEL_LOADING]: [MODEL_TRAINING, MODEL_LOADED],
  [MODEL_LOADED]: [],
  [MODEL_FAILED]: [MODEL_TRAINING, MODEL_LABELING, MODEL_LOADING, MODEL_LOADED],
  [MODEL_QUEUED]: [MODEL_TRAINING],
};

export const statusCheck = (
  newStatus: ModelStatus,
  currentStatus: ModelStatus | null,
) => {
  if (!currentStatus) {
    return newStatus;
  }
  if (
    newStatus &&
    statusTransitionMap[currentStatus] &&
    statusTransitionMap[currentStatus].includes(newStatus)
  ) {
    return newStatus;
  }

  return currentStatus;
};
