/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type TagGroup = {
  /**
   * Tag group ID
   */
  readonly id?: string;
  /**
   * Tag group name
   */
  name: string;
  /**
   * Tag group type
   */
  type: TagGroup.type;
  /**
   * Tag groups order
   */
  norder?: number;
};

export namespace TagGroup {

  /**
   * Tag group type
   */
  export enum type {
    SELECTION = 'SELECTION',
    MULTIPLE_SELECTION = 'MULTIPLE-SELECTION',
  }


}

