import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
  Update,
} from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import {
  CreateExportSessionData,
  ExportSession,
} from '../../../../../api/domainModels/export';
import { loadingStateBuilder } from '../../../../utils/loadingState';
import { setProjectId } from '../../../core/imageView/project/project.slice';

export const exportProjectFormName = 'exportProjectForm';

export const exportSessionsAdapter = createEntityAdapter<ExportSession>({
  sortComparer: (a, b) => (dayjs(a.createDate).isAfter(b.createDate) ? -1 : 1),
});
const initialState = exportSessionsAdapter.getInitialState({
  listLoadingState: loadingStateBuilder.initial(),
  itemLoadingState: loadingStateBuilder.initial(),
});

const { actions, reducer: exportSessionsReducer } = createSlice({
  name: 'exportSessions',
  initialState,
  reducers: {
    loadExportSessionsStart(state) {
      state.listLoadingState = loadingStateBuilder.inProgress();
    },
    loadExportSessionsSuccess(state, action: PayloadAction<ExportSession[]>) {
      state.listLoadingState = loadingStateBuilder.success();
      exportSessionsAdapter.setAll(state, action.payload);
    },
    loadExportSessionsFailure(state, action: PayloadAction<string>) {
      state.listLoadingState = loadingStateBuilder.failure(action.payload);
    },
    createExportSessionStart(
      _state,
      _action: PayloadAction<{
        projectId: string;
        data: CreateExportSessionData;
      }>,
    ) {},
    createExportSessionSuccess(state, action: PayloadAction<ExportSession>) {
      exportSessionsAdapter.addOne(state, action.payload);
    },
    removeExportSessionStart(state, _action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.inProgress();
    },
    removeExportSessionFailure(state) {
      state.itemLoadingState = loadingStateBuilder.failure();
    },
    removeExportSessionSuccess(state, action: PayloadAction<string>) {
      exportSessionsAdapter.removeOne(state, action.payload);
      state.itemLoadingState = loadingStateBuilder.success();
    },
    updateExportSession(state, action: PayloadAction<Update<ExportSession>>) {
      exportSessionsAdapter.updateOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setProjectId, () => initialState);
  },
});

export const {
  loadExportSessionsStart,
  loadExportSessionsSuccess,
  loadExportSessionsFailure,
  createExportSessionStart,
  createExportSessionSuccess,
  removeExportSessionStart,
  removeExportSessionFailure,
  removeExportSessionSuccess,
  updateExportSession,
} = actions;

export { exportSessionsReducer };
