import {
  createSlice,
  PayloadAction,
  createEntityAdapter,
  EntityState,
} from '@reduxjs/toolkit';

import {
  ExperimentParameter,
  ExperimentComponent,
  ExperimentTransformPreview,
} from '../../../../../../api/domainModels/modelPlayground';
import {
  loadingStateBuilder,
  LoadingState,
} from '../../../../../utils/loadingState';
import { setActiveExperimentId } from '../activeExperimentId/activeExperimentId.slice';
import { setEditedProjectId } from '../../../../sections/editedProject/project/project.slice';
import { PartitionName } from '../../../../../../api/constants/modelPlayground';

export type TransformsParameter = {
  id: string; // experiment component id
  data: ExperimentParameter[];
  loadingState: LoadingState;
};

export type AddTransformsFormValues = { transforms: string[] };

export const componentsAdapter = createEntityAdapter<ExperimentComponent>({
  sortComparer: (a, b) => ((a.norder || 0) > (b.norder || 0) ? 1 : -1),
});
export const parametersAdapter = createEntityAdapter<TransformsParameter>();
export const previewIdsAdapter = createEntityAdapter<string>({
  selectId: (id) => id,
});

type TransformsState = {
  components: EntityState<ExperimentComponent> & {
    loadingState: LoadingState;
    updateLoadingState: LoadingState;
  };
  parameters: EntityState<TransformsParameter> & {
    data: ExperimentParameter[];
    loadingState: LoadingState;
  };
  parametersUpdateLoadingState: LoadingState;
  preview: ExperimentTransformPreview | null;
  previewLoadingState: LoadingState;
  previewIds: EntityState<string>;
};

const initialState: TransformsState = {
  components: componentsAdapter.getInitialState({
    loadingState: loadingStateBuilder.initial(),
    updateLoadingState: loadingStateBuilder.initial(),
  }),
  parameters: parametersAdapter.getInitialState({
    data: [],
    loadingState: loadingStateBuilder.initial(),
  }),
  parametersUpdateLoadingState: loadingStateBuilder.initial(),
  preview: null,
  previewLoadingState: loadingStateBuilder.initial(),
  previewIds: previewIdsAdapter.getInitialState(),
};

const { actions, reducer: transformsReducer } = createSlice({
  name: 'transforms',
  initialState,
  reducers: {
    loadTransforms(state, _action: PayloadAction<PartitionName>) {
      state.components.loadingState =
        loadingStateBuilder.inProgress('Loading transforms');
      componentsAdapter.removeAll(state.components);
    },
    loadTransformsFailure(state, action: PayloadAction<string>) {
      state.components.loadingState = loadingStateBuilder.failure(
        action.payload,
      );
    },
    loadTransformsSuccess(state, action: PayloadAction<ExperimentComponent[]>) {
      state.components.loadingState = loadingStateBuilder.success();
      componentsAdapter.setAll(state.components, action.payload);
    },
    addExperimentTransforms(
      state,
      _action: PayloadAction<{
        transformsIds: string[];
        partition: PartitionName;
      }>,
    ) {
      state.components.updateLoadingState =
        loadingStateBuilder.inProgress('Adding transforms');
    },
    addExperimentTransformsFailure(state, action: PayloadAction<string>) {
      state.components.updateLoadingState = loadingStateBuilder.failure(
        action.payload,
      );
    },
    addExperimentTransformsSuccess(
      state,
      action: PayloadAction<ExperimentComponent[]>,
    ) {
      state.components.updateLoadingState = loadingStateBuilder.success();
      componentsAdapter.addMany(state.components, action.payload);
    },
    reorderExperimentTransforms(
      state,
      _action: PayloadAction<{
        id: string;
        oldOrder: number;
        newOrder: number;
        partition: PartitionName;
      }>,
    ) {
      state.components.updateLoadingState = loadingStateBuilder.inProgress(
        'Updating transforms',
      );
    },
    reorderExperimentTransformsFailure(state, action: PayloadAction<string>) {
      state.components.updateLoadingState = loadingStateBuilder.failure(
        action.payload,
      );
    },
    reorderExperimentTransformsSuccess(
      state,
      action: PayloadAction<ExperimentComponent[]>,
    ) {
      state.components.updateLoadingState = loadingStateBuilder.success();
      componentsAdapter.upsertMany(state.components, action.payload);
    },
    removeExperimentTransform(
      state,
      _action: PayloadAction<{
        component: ExperimentComponent;
        partition: PartitionName;
      }>,
    ) {
      state.components.updateLoadingState =
        loadingStateBuilder.inProgress('Removing transform');
    },
    removeExperimentTransformFailure(state, action: PayloadAction<string>) {
      state.components.updateLoadingState = loadingStateBuilder.failure(
        action.payload,
      );
    },
    removeExperimentTransformSuccess(
      state,
      action: PayloadAction<{ id: string; isSingleRepresentative: boolean }>,
    ) {
      state.components.updateLoadingState = loadingStateBuilder.success();
      const { id, isSingleRepresentative } = action.payload;
      const componentsEntities = state.components.entities;

      if (isSingleRepresentative) {
        // if there's a single item with this component id reset it's id instead of removing it from list
        componentsAdapter.updateOne(state.components, {
          id,
          changes: {
            // this unorthodox id change follows the `id: experimentComponentId || id` usage of mapped component
            id: componentsEntities[id]?.componentId,
            selected: false,
            experimentComponentId: null,
          },
        });
      } else componentsAdapter.removeOne(state.components, id);
    },
    loadTransformParameters(
      state,
      action: PayloadAction<{ id: string; partition: PartitionName }>,
    ) {
      const { id } = action.payload;
      const update = {
        id,
        data: [],
        loadingState: loadingStateBuilder.inProgress('Loading parameters'),
      };
      parametersAdapter.upsertOne(state.parameters, update);
    },
    loadTransformParametersFailure(
      state,
      action: PayloadAction<{ id: string; message: string }>,
    ) {
      const { id, message } = action.payload;
      const changes = {
        loadingState: loadingStateBuilder.failure(message),
      };
      const update = { id, changes };
      parametersAdapter.updateOne(state.parameters, update);
    },
    loadTransformParametersSuccess(
      state,
      action: PayloadAction<{ id: string; data: ExperimentParameter[] }>,
    ) {
      state.parameters.loadingState = loadingStateBuilder.success();
      const { id, data } = action.payload;
      const changes = {
        data,
        loadingState: loadingStateBuilder.success(),
      };
      const update = { id, changes };
      parametersAdapter.updateOne(state.parameters, update);
    },
    updateTransformParameter(
      state,
      _action: PayloadAction<{
        id: string; // component id
        partition: PartitionName;
        transformParameterId: string;
        value: any;
      }>,
    ) {
      state.parametersUpdateLoadingState =
        loadingStateBuilder.inProgress('Loading parameters');
    },
    updateTransformParameterFailure(state, action: PayloadAction<string>) {
      state.parametersUpdateLoadingState = loadingStateBuilder.failure(
        action.payload,
      );
    },
    updateTransformParameterSuccess(
      state,
      action: PayloadAction<{ id: string; parameter: ExperimentParameter }>,
    ) {
      state.parametersUpdateLoadingState = loadingStateBuilder.success();
      const { id, parameter } = action.payload;
      const data = state.parameters.entities[id]?.data.map((param) =>
        param.id === parameter.id ? parameter : param,
      );
      const update = { id, changes: { data } };
      parametersAdapter.updateOne(state.parameters, update);
    },
    updateTransformParameterResetLoadingState(state) {
      state.parametersUpdateLoadingState = loadingStateBuilder.success();
    },
    loadTransformsPreview(
      state,
      _action: PayloadAction<{
        partition: PartitionName;
        experimentComponentIds: string[];
        objectId?: string;
      }>,
    ) {
      state.previewLoadingState =
        loadingStateBuilder.inProgress('Loading transforms');
      state.preview = null;
    },
    loadTransformsPreviewFailure(state, action: PayloadAction<string>) {
      state.previewLoadingState = loadingStateBuilder.failure(action.payload);
    },
    loadTransformsPreviewSuccess(
      state,
      action: PayloadAction<ExperimentTransformPreview>,
    ) {
      state.previewLoadingState = loadingStateBuilder.success();
      state.preview = action.payload;
    },
    addPreviewId(state, action: PayloadAction<string>) {
      previewIdsAdapter.addOne(state.previewIds, action.payload);
    },
    removePreviewId(state, action: PayloadAction<string>) {
      previewIdsAdapter.removeOne(state.previewIds, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setActiveExperimentId, () => initialState);
    builder.addCase(setEditedProjectId, () => initialState);
  },
});

export { transformsReducer };
export const {
  loadTransforms,
  loadTransformsSuccess,
  loadTransformsFailure,
  addExperimentTransforms,
  addExperimentTransformsFailure,
  addExperimentTransformsSuccess,
  reorderExperimentTransforms,
  reorderExperimentTransformsFailure,
  reorderExperimentTransformsSuccess,
  removeExperimentTransform,
  removeExperimentTransformFailure,
  removeExperimentTransformSuccess,
  loadTransformParameters,
  loadTransformParametersSuccess,
  loadTransformParametersFailure,
  updateTransformParameter,
  updateTransformParameterSuccess,
  updateTransformParameterFailure,
  loadTransformsPreview,
  loadTransformsPreviewSuccess,
  loadTransformsPreviewFailure,
  addPreviewId,
  removePreviewId,
  updateTransformParameterResetLoadingState,
} = actions;
