import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createWhitelistFilter } from 'redux-persist-transform-filter';

import { annotationsVisualStateReducer } from './annotationsVisualState/annotationsVisualState.slice';
import { clipboardReducer } from './clipboard/clipboard.slice';
import { consensusScoringReducer } from './consensusScoring/consensusScoring.slice';
import { currentImageReducer } from './currentImage/currentImage.slice';
import { hiddenClassesReducer } from './hiddenClasses/hiddenClasses.slice';
import { hoveredLabelReducer } from './labels/hoveredLabel/hoveredLabel.slice';
import { imageGalleryReducer } from './imageGallery/imageGallery.reducer';
import { labelAttributeValuesReducer } from './labelAttributeValues/labelAttributeValues.slice';
import { labelClassesReducer } from './labelClasses/labelClasses.slice';
import { labelSyncReducer } from './labels/labelSync/labelSync.slice';
import { labelsReducer } from './labels/labels.slice';
import { multiLabelAttributeValuesReducer } from './multiLabelAttributeValues/multiLabelAttributeValues.slice';
import { panningReducer } from './panning/panning.slice';
import { persistedProjectImageReducer } from './persistedProjectImage/persistedProjectImage.slice';
import { projectReducer } from './project/project.slice';
import { scaleReducer } from './scale/scale.slice';
import { selectedLabelsReducer } from './labels/selectedLabels/selectedLabels.slice';
import { sidebarReducer } from './sidebar/sidebar.reducer';
import { toolsReducer } from './tools/tools.slice';
import { fingerInputReducer } from './fingerInput/fingerInput.slice';
import { toolbarActionTriggerReducer } from './toolbarActionTrigger/toolbarActionTrigger.slice';
import { uiReducer } from './ui/ui.slice';

const reducer = combineReducers({
  annotationsVisualState: annotationsVisualStateReducer,
  clipboard: clipboardReducer,
  consensusScoring: consensusScoringReducer,
  currentImage: currentImageReducer,
  hiddenClasses: hiddenClassesReducer,
  hoveredLabel: hoveredLabelReducer,
  imageGallery: imageGalleryReducer,
  labelAttributeValues: labelAttributeValuesReducer,
  labelClasses: labelClassesReducer,
  labelSync: labelSyncReducer,
  labels: labelsReducer,
  multiLabelAttributeValues: multiLabelAttributeValuesReducer,
  panning: panningReducer,
  persistedProjectImage: persistedProjectImageReducer,
  project: projectReducer,
  scale: scaleReducer,
  selectedLabels: selectedLabelsReducer,
  sidebar: sidebarReducer,
  tools: toolsReducer,
  fingerInput: fingerInputReducer,
  toolbarActionTrigger: toolbarActionTriggerReducer,
  ui: uiReducer,
});

const imageViewPersistConfig = {
  key: 'imageView',
  storage,
  version: 1,
  whitelist: ['persistedProjectImage', 'project', 'tools', 'sidebar'],
  transforms: [
    createWhitelistFilter('persistedProjectImage'),
    createWhitelistFilter('project', ['modelsNotificationStatus']),
    createWhitelistFilter('tools', [
      'imageTagsPrediction',
      'labelClassPrediction',
    ]),
    createWhitelistFilter('sidebar', ['labelAttributesPredictionToggle']),
  ],
};

export const imageViewReducer = persistReducer(imageViewPersistConfig, reducer);
