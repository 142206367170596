import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './filters.slice';
import { errorFinderSelector } from '../errorFinder.selectors';

export const labelsFiltersStateSelector = createSelector(
  errorFinderSelector,
  (errorFinder) => errorFinder.labelsFilters,
);

export const labelsFiltersDataSelector = createSelector(
  labelsFiltersStateSelector,
  (filters) => filters.data,
);

export const labelsFiltersCountSelector = createSelector(
  labelsFiltersStateSelector,
  (filters) => filters.activeCount,
);

export const currentActiveFiltersCountSelector = createSelector(
  labelsFiltersDataSelector,
  (filters) => {
    const { errorType, ...restFilters } = filters;
    let count = 0;
    for (const [key, value] of Object.entries(restFilters)) {
      const comparator = initialState.data[key as keyof typeof restFilters];
      count += value === comparator ? 0 : 1;
    }

    return count;
  },
);
