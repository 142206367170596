import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { loadingStateBuilder } from '../../../../../utils/loadingState';
import {
  ModelStatus,
  modelStatusChecks,
  statusCheck,
  ToolModel,
} from '../models/modelStatus.constants';

const initialState: ToolModel = {
  id: null,
  loadingState: loadingStateBuilder.initial(),
  status: null,
  progress: null,
};

const { actions, reducer: imageViewAtomModelReducer } = createSlice({
  name: 'imageViewAtomModel',
  initialState,
  reducers: {
    loadAtomModel(_state, _action: PayloadAction<string>) {},
    loadAtomModelError(state, action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.failure(action.payload);
    },
    loadAtomModelStart(state, action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.inProgress(action.payload);
    },
    loadAtomModelSuccess(state) {
      state.loadingState = loadingStateBuilder.success();
    },
    updateAtomModel(
      _state,
      _action: PayloadAction<{
        status: ModelStatus;
        progress: number;
        id: number;
        modelUseIE?: boolean;
      }>,
    ) {},
    updateAtomModelSuccess(
      state,
      action: PayloadAction<{
        id: number | null;
        status: ModelStatus;
        progress: number | null;
      }>,
    ) {
      const { id, status, progress } = action.payload;

      state.id = modelStatusChecks.isLoaded(status) ? id : state.id;
      state.status = statusCheck(status, state.status);
      state.progress = progress || 0;
    },
  },
});

export { imageViewAtomModelReducer };

export const {
  loadAtomModel,
  loadAtomModelStart,
  loadAtomModelSuccess,
  loadAtomModelError,
  updateAtomModel,
  updateAtomModelSuccess,
} = actions;
