export enum SidebarPanel {
  LabelClasses = 'labelClasses',
  LabelAttributes = 'labelAttributes',
  Segments = 'activities',
  ImageTags = 'imageTags',
  AICSRuns = 'aicsRuns',
}

export enum SidebarTab {
  Annotation = 'annotation',
  DataCleaning = 'dataCleaning',
}
