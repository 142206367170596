/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BBoxData } from './BBoxData';
import type { RLEMaskData } from './RLEMaskData';

export type SemanticReviewResult = {
  /**
   * Semantic Review Result ID
   */
  semanticResultId?: string | null;
  /**
   * Image ID
   */
  imageId: string;
  /**
   * Predicted class ID
   */
  predClassId: string;
  predBbox: BBoxData;
  predMask: RLEMaskData;
  /**
   * Area of the predicted mask
   */
  maskArea?: number;
  /**
   * Reference label ID
   */
  refLabelId?: string | null;
  /**
   * Label
   */
  label?: Record<string, any> | null;
  /**
   * Error type
   */
  errorType: SemanticReviewResult.errorType;
  /**
   * Whether the result is locked
   */
  isLocked?: boolean;
};

export namespace SemanticReviewResult {

  /**
   * Error type
   */
  export enum errorType {
    NOT_AN_ERROR = 'NOT_AN_ERROR',
    MISSING_AREA = 'MISSING_AREA',
    EXTRA_AREA = 'EXTRA_AREA',
  }


}

