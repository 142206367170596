import {
  ApiWorkspaceCollaborator,
  ApiWorkspaceCollaboratorParams,
} from '../schemas/workspaceCollaborator';

const fromBackend = ({
  userName: name,
  userEmail: email,
  userId: id,
  ...rest
}: ApiWorkspaceCollaborator) => ({
  ...rest,
  id,
  name,
  email,
  readOnly: false,
});

export type WorkspaceCollaborator = ReturnType<typeof fromBackend>;

export const workspaceCollaboratorDataMapper = {
  fromBackend,
};

export type WorkspaceCollaboratorParams = ApiWorkspaceCollaboratorParams;
