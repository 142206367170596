import { put, all, select, call, takeEvery } from 'typed-redux-saga';
import shortid from 'shortid';

import {
  apiGetUploadUrls,
  apiUploadImageToCloud,
  apiUploadImageData,
} from '../../../../../api/requests/uploadImages';
import { getErrorMessage } from '../../../../../api/utils';
import { IMAGES_UPLOAD_CHUNK } from '../../../../../constants/project';
import { readFileAsBinary } from '../../../../../util/file';
import { activeProjectIdSelector } from '../../../project/project.selectors';
import { enqueueNotification } from '../../../ui/stackNotifications/stackNotifications.slice';
import {
  uploadImage,
  uploadImageError,
  uploadImages,
  uploadImagesToSelectedDataset,
  uploadImageSuccess,
} from './imageUpload.slice';
import { selectedDatasetIdSelector } from '../selectedDataset/selectedDataset.selectors';
import { UploadUrl } from '../../../../../api/domainModels/uploadImages';
import { invalidateQueries } from '../../../../../api/api';
import { ImageService } from '../../../../../api/codegen';

function* uploadImageHandler(
  projectId: string,
  datasetId: string,
  image: File,
  uploadData: UploadUrl,
) {
  const imageId = shortid.generate();

  yield* put(
    uploadImage({
      id: imageId,
      name: image.name,
    }),
  );

  try {
    const binaryString = yield* call(readFileAsBinary, image);

    if (!(binaryString instanceof ArrayBuffer)) {
      throw new Error('Invalid file or something went wrong');
    }

    yield* call(apiUploadImageToCloud, {
      binaryString,
      uploadUrl: uploadData.url as string,
    });
    yield* call(apiUploadImageData, projectId, {
      uploadId: uploadData.id,
      datasetId,
      filename: image.name,
    });
    yield* put(uploadImageSuccess({ id: imageId }));
    invalidateQueries(ImageService.projectImagesList);
  } catch (error) {
    yield* put(
      uploadImageError({ id: imageId, message: getErrorMessage(error) }),
    );
    yield* put(
      enqueueNotification({
        message: getErrorMessage(error),
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
          error,
        },
      }),
    );
  }
}

function* processImageChunk(
  projectId: string,
  datasetId: string,
  images: File[],
) {
  try {
    const {
      data: { items: uploadUrls },
    } = yield* call(apiGetUploadUrls, {
      projectId,
      imagesCount: images.length,
    });

    yield* all(
      images.map((image, index) =>
        uploadImageHandler(projectId, datasetId, image, uploadUrls[index]),
      ),
    );

    return true;
  } catch {
    return false;
  }
}

function* uploadImagesHandler(action: ActionType<typeof uploadImages>) {
  const { projectId, images } = action.payload;
  let index = 0;

  const datasetId = yield* select(selectedDatasetIdSelector);

  do {
    if (datasetId) {
      const imagesArray = images.slice(index, index + IMAGES_UPLOAD_CHUNK);
      const isProcessed = yield* call(
        processImageChunk,
        projectId,
        datasetId,
        imagesArray,
      );
      if (isProcessed) {
        index += IMAGES_UPLOAD_CHUNK;
      }
    } else {
      yield* put(
        enqueueNotification({
          message:
            'The image upload process was canceled as another user deleted the selected dataset',
          options: {
            variant: 'error',
            allowOutsideOfEditor: true,
          },
        }),
      );

      break;
    }
  } while (index < images.length);
}

function* uploadImagesToSelectedDatasetHandler(
  action: ActionType<typeof uploadImagesToSelectedDataset>,
) {
  const { images } = action.payload;
  const datasetId = yield* select(selectedDatasetIdSelector);
  const projectId = yield* select(activeProjectIdSelector);

  if (datasetId) {
    yield* put(uploadImages({ projectId, datasetId, images }));
  }
}

export function* imageUploadSaga() {
  yield* takeEvery(uploadImages, uploadImagesHandler);
  yield* takeEvery(
    uploadImagesToSelectedDataset,
    uploadImagesToSelectedDatasetHandler,
  );
}
