import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SplitClassItems } from '../../../../../../api/domainModels/modelPlayground';
import { loadingStateBuilder } from '../../../../../utils/loadingState';
import { setActiveSplitId } from '../activeSplitId/activeSplitId.slice';
import { setEditedProjectId } from '../../../../sections/editedProject/project/project.slice';

const initialState = {
  items: [] as SplitClassItems,
  loadingState: loadingStateBuilder.initial(),
};
const { actions, reducer: summaryReducer } = createSlice({
  name: 'summary',
  initialState,
  reducers: {
    loadSplitSummary(state, _action: PayloadAction<{ splitId: string }>) {
      state.items = [];
      state.loadingState = loadingStateBuilder.inProgress();
    },
    loadSplitSummarySuccess(state, action: PayloadAction<SplitClassItems>) {
      state.items = action.payload;
      state.loadingState = loadingStateBuilder.success();
    },
    loadSplitSummaryFailure(state, action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.failure(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setActiveSplitId, () => initialState);
    builder.addCase(setEditedProjectId, () => initialState);
  },
});

export { summaryReducer };
export const {
  loadSplitSummary,
  loadSplitSummaryFailure,
  loadSplitSummarySuccess,
} = actions;
