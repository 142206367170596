import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Heuristic } from '../../../../api/domainModels/heuristic';
import { LoadingState, loadingStateBuilder } from '../../../utils/loadingState';
import { ModelFamily } from '../../../../api/constants/modelFamily';

export type HeuristicState = {
  data: Heuristic[]; // can't use an adapter since BE decided it's a great idea for ids not to be unique
  itemLoadingState: { loadingState: LoadingState; family: ModelFamily | null };
  listLoadingState: LoadingState;
};

const initialItemLoadingState = {
  loadingState: loadingStateBuilder.initial(),
  family: null,
};

const initialState: HeuristicState = {
  data: [],
  itemLoadingState: initialItemLoadingState,
  listLoadingState: loadingStateBuilder.initial(),
};

const { actions, reducer: heuristicsReducer } = createSlice({
  name: 'heuristics',
  initialState,
  reducers: {
    setHeuristic(
      state,
      action: PayloadAction<{ family: ModelFamily; id: string | null }>,
    ) {
      const { family } = action.payload;

      state.itemLoadingState = {
        loadingState: loadingStateBuilder.inProgress(),
        family,
      };
    },
    setHeuristicFailure(
      state,
      action: PayloadAction<{ message: string; id: string }>,
    ) {
      state.itemLoadingState = {
        ...state.itemLoadingState,
        loadingState: loadingStateBuilder.failure(action.payload.message),
      };
    },
    setHeuristicSuccess(
      state,
      action: PayloadAction<Pick<Heuristic, 'id' | 'modelFamily' | 'isActive'>>,
    ) {
      state.itemLoadingState = {
        ...state.itemLoadingState,
        loadingState: loadingStateBuilder.success(),
      };

      const { id, modelFamily, isActive } = action.payload;
      const isResetting = !id; // if id is null it was set to None, meaning we gotta reset all active states
      state.data = state.data.map((heuristic) => {
        if (heuristic.modelFamily === modelFamily) {
          if (isResetting) return { ...heuristic, isActive: false };

          return {
            ...heuristic,
            isActive: heuristic.id === id ? isActive : false,
          };
        }

        return heuristic;
      });
    },
    loadHeuristics(state) {
      state.listLoadingState = loadingStateBuilder.inProgress();
    },
    loadHeuristicsFailure(state, action: PayloadAction<string>) {
      state.listLoadingState = loadingStateBuilder.failure(action.payload);
      state.data = initialState.data;
    },
    loadHeuristicsSuccess(state, action: PayloadAction<Heuristic[]>) {
      state.listLoadingState = loadingStateBuilder.success();
      state.data = action.payload;
    },
    resetHeuristicItemLoadingState(state) {
      state.itemLoadingState = initialItemLoadingState;
    },
  },
});

export { heuristicsReducer };
export const {
  setHeuristic,
  setHeuristicFailure,
  setHeuristicSuccess,
  loadHeuristics,
  loadHeuristicsFailure,
  loadHeuristicsSuccess,
  resetHeuristicItemLoadingState,
} = actions;
