import { InfiniteData } from '@tanstack/react-query';

import { useApiInfiniteQuery, useApiQuery } from '../api';
import { OpReturn } from '../api.types';
import {
  Job,
  Notification,
  NotificationsService,
  ProjectService,
} from '../codegen';
import { subjectsWithJobFetchRequired } from '../../redux/state/project/operations/operations.saga';

const ITEMS_PER_PAGE = 20;

type ListReturn = OpReturn<typeof NotificationsService.getV1Notifications>;

export const useNotificationsInfiniteQuery = () =>
  useApiInfiniteQuery(getNotificationsInfiniteQuery, {
    getNextPageParam,
    loadingStateErrorMessage: 'Not able to load notifications',
  });

export const getNotificationsInfiniteQuery = ({
  pageParam = 0,
}: {
  pageParam?: number;
}) =>
  NotificationsService.getV1Notifications({
    limit: ITEMS_PER_PAGE,
    offset: pageParam * ITEMS_PER_PAGE,
  });

const getNextPageParam = ({ meta: { total, limit, offset } }: ListReturn) =>
  (total ?? 0) > offset + limit ? offset / limit + 1 : undefined;

export const combinePages = (pages?: InfiniteData<ListReturn>['pages']) =>
  pages?.reduce((acc, data) => [...acc, ...data.items], [] as Notification[]) ??
  [];

export const useNotificationsUnnoticedCount = () => {
  const { data } = useApiQuery(NotificationsService.getV1NotificationsStatus, {
    select: (data) => data.unnoticed ?? 0,
  });

  return data ?? 0;
};

export const useNotificationUrl = (
  notification: Pick<Notification, 'subject' | 'jobId'>,
) => {
  const isSubjectWithDownload = subjectsWithJobFetchRequired.includes(
    notification.subject,
  );
  const { data } = useApiQuery(ProjectService.getV1Jobs, {
    params: { jobId: notification.jobId ?? '' },
    enabled: !!notification.jobId && isSubjectWithDownload,
    meta: {
      errorMessage: "Couldn't process notification",
      notificationProps: { allowOutsideOfEditor: true },
    },
  });

  return isSubjectWithDownload && data?.status === Job.status.DONE
    ? (data?.meta?.url as string | undefined)
    : undefined;
};
