import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SidebarTab } from '../../../../../../@types/imageView/Sidebar.types';

const { actions, reducer: imageViewSidebarTabsReducer } = createSlice({
  name: 'imageViewSidebarTabs',
  initialState: {
    activeTab: SidebarTab.Annotation,
  },
  reducers: {
    setSidebarActiveTab(state, action: PayloadAction<SidebarTab>) {
      state.activeTab = action.payload;
    },
    resetActiveTab(state) {
      state.activeTab = SidebarTab.Annotation;
    },
  },
});

export { imageViewSidebarTabsReducer };
export const { setSidebarActiveTab, resetActiveTab } = actions;
