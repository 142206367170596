import { call, put, select, takeEvery, takeLatest } from 'typed-redux-saga';
import qs from 'query-string';
import { push } from 'connected-react-router';

import {
  apiDeleteProjectVideo,
  apiLoadVideos,
  apiPatchProjectVideo,
  apiUpdateVideoStatus,
} from '../../../../../api/requests/videos';
import { getErrorMessage } from '../../../../../api/utils';
import { pluralize } from '../../../../../helpers/plural';
import { removeDatasetSuccess } from '../../datasets/datasets.slice';
import { activeProjectIdSelector } from '../../project.selectors';
import {
  fileManagerVideosFiltersSelector,
  fileManagerVideosSelectedIdsSelector,
} from './videos.selectors';
import {
  deleteSelectedVideos,
  videoActionSuccess,
  loadVideos,
  loadVideosFailure,
  loadVideosSuccess,
  moveSelectedVideos,
  setEditedVideoId,
  updateVideo,
  updateVideoFailure,
  updateVideoSuccess,
  changeStatusSelectedVideos,
} from './videos.slice';
import { enqueueNotification } from '../../../ui/stackNotifications/stackNotifications.slice';
import { hideModals } from '../../../ui/modals/modals.slice';
import { NotificationVariant } from '../../../../../constants/notificationVariant';
import { ApiOperationParams, splitToChunks } from '../helpers';

function* loadHandler() {
  const projectId = yield* select(activeProjectIdSelector);
  const filters = yield* select(fileManagerVideosFiltersSelector);

  const allDatasetsSelected = !filters.datasetId;

  try {
    const { data } = yield* call(apiLoadVideos, {
      projectId,
      params: {
        datasetId: filters.datasetId,
        videoName: filters.videoName,
        activity: filters.activity,
        videoStatus: filters.videoStatus,
        offset: (filters.page - 1) * filters.perPage,
        limit: filters.perPage,
      },
    });
    yield* put(
      loadVideosSuccess({
        items: data.items,
        total: data.meta.total,
        allDatasetsSelected,
      }),
    );
  } catch (e) {
    yield* put(loadVideosFailure(getErrorMessage(e, 'Could not load videos')));
  }
}

function* moveHandler(action: ActionType<typeof moveSelectedVideos>) {
  const { datasetId } = action.payload;

  const projectId = yield* select(activeProjectIdSelector);
  const selectedVideosIds = yield* select(fileManagerVideosSelectedIdsSelector);
  if (!selectedVideosIds.length) {
    return;
  }

  const apiOperation = function* ({ projectId, id }: ApiOperationParams) {
    yield* call(apiPatchProjectVideo, { projectId, id }, { datasetId });
  };

  const successCount = yield* splitToChunks({
    projectId,
    videoIds: selectedVideosIds,
    apiOperation,
  });

  const errorCount = selectedVideosIds.length - successCount;

  const variant: NotificationVariant =
    successCount && !errorCount // just success
      ? 'success'
      : !successCount && errorCount // just error
      ? 'error'
      : 'info'; // both

  const successMessage = successCount
    ? `${pluralize(successCount, 'video')} moved to the selected dataset.`
    : '';

  const errorMessage = errorCount
    ? `${pluralize(errorCount, 'video')} couldn't be processed`
    : '';

  const message = `${successMessage} ${errorMessage}`;

  yield* put(
    enqueueNotification({
      message,
      options: { variant, allowOutsideOfEditor: true },
    }),
  );

  yield* put(videoActionSuccess());
  const filters = yield* select(fileManagerVideosFiltersSelector);
  yield* put(loadVideos(filters));
}

function* changeStatusSelectedVideosHandler(
  action: ActionType<typeof changeStatusSelectedVideos>,
) {
  const { status, videoId } = action.payload;

  const projectId = yield* select(activeProjectIdSelector);
  const selectedVideosIds = videoId
    ? [videoId]
    : yield* select(fileManagerVideosSelectedIdsSelector);

  if (!selectedVideosIds.length) {
    return;
  }

  const apiOperation = function* ({ projectId, id }: ApiOperationParams) {
    yield* call(apiUpdateVideoStatus, { projectId, id }, { status });
  };

  const successCount = yield* splitToChunks({
    projectId,
    videoIds: selectedVideosIds,
    apiOperation,
  });

  const errorCount = selectedVideosIds.length - successCount;

  const variant: NotificationVariant =
    successCount && !errorCount // just success
      ? 'success'
      : !successCount && errorCount // just error
      ? 'error'
      : 'info'; // both

  const successMessage = successCount
    ? `${pluralize(successCount, 'video')} changed status to ${status}.`
    : '';

  const errorMessage = errorCount
    ? `${pluralize(errorCount, 'video')} couldn't be processed`
    : '';

  const message = `${successMessage} ${errorMessage}`;

  yield* put(
    enqueueNotification({
      message,
      options: {
        variant,
        allowOutsideOfEditor: true,
      },
    }),
  );

  yield* put(videoActionSuccess());
  const filters = yield* select(fileManagerVideosFiltersSelector);
  yield* put(loadVideos(filters));
}

function* deleteHandler() {
  const projectId = yield* select(activeProjectIdSelector);
  const selectedVideosIds = yield* select(fileManagerVideosSelectedIdsSelector);
  if (!selectedVideosIds.length) {
    return;
  }

  const apiOperation = function* ({ projectId, id }: ApiOperationParams) {
    yield* call(apiDeleteProjectVideo, { projectId, id });
  };

  const successCount = yield* splitToChunks({
    projectId,
    videoIds: selectedVideosIds,
    apiOperation,
  });

  const errorCount = selectedVideosIds.length - successCount;

  const variant: NotificationVariant =
    successCount && !errorCount // just success
      ? 'success'
      : !successCount && errorCount // just error
      ? 'error'
      : 'info'; // both

  const successMessage = successCount
    ? `${pluralize(successCount, 'video')} removed.`
    : '';

  const errorMessage = errorCount
    ? `${pluralize(errorCount, 'video')} couldn't be processed`
    : '';

  const message = `${successMessage} ${errorMessage}`;

  yield* put(
    enqueueNotification({
      message,
      options: {
        allowOutsideOfEditor: true,
        variant,
      },
    }),
  );

  yield* put(videoActionSuccess());
  const filters = yield* select(fileManagerVideosFiltersSelector);
  yield* put(loadVideos(filters));
}

function* updateHandler(action: ActionType<typeof updateVideo>) {
  const projectId = yield* select(activeProjectIdSelector);
  const { id } = action.payload;
  const { filename } = action.payload;
  try {
    const { data } = yield* call(
      apiPatchProjectVideo,
      { projectId, id },
      { filename },
    );

    yield* put(updateVideoSuccess(data));
    yield* put(setEditedVideoId(null));
    yield* put(hideModals());
  } catch (error) {
    yield* put(
      updateVideoFailure(getErrorMessage(error, 'Unable to update video')),
    );
  }
}

function* removeDatasetSuccessHandler(
  action: ActionType<typeof removeDatasetSuccess>,
) {
  const { datasetId, changeUrlPath } = action.payload;

  if (changeUrlPath) {
    const { datasetId: searchDatasetId, ...filters } = yield* select(
      fileManagerVideosFiltersSelector,
    );

    const { page, ...search } = filters;

    yield* put(
      push({
        search: qs.stringify({
          ...search,
          datasetId:
            datasetId === searchDatasetId ? undefined : searchDatasetId,
        }),
      }),
    );
    yield* put(
      loadVideos({
        ...filters,
        datasetId:
          datasetId === searchDatasetId || !searchDatasetId
            ? undefined
            : searchDatasetId,
      }),
    );
  }
}

export function* fileManagerVideosSaga() {
  yield* takeLatest(loadVideos, loadHandler);
  yield* takeEvery(deleteSelectedVideos, deleteHandler);
  yield* takeEvery(moveSelectedVideos, moveHandler);
  yield* takeEvery(
    changeStatusSelectedVideos,
    changeStatusSelectedVideosHandler,
  );
  yield* takeEvery(updateVideo, updateHandler);
  yield* takeEvery(removeDatasetSuccess, removeDatasetSuccessHandler);
}
