import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  LoadingState,
  loadingStateBuilder,
} from '../../../../utils/loadingState';

const initialState: {
  selectedAssistantSchemaId?: string;
  itemLoadingState: LoadingState;
} = {
  selectedAssistantSchemaId: undefined,
  itemLoadingState: loadingStateBuilder.initial(),
};

const { actions, reducer: assistantKeypointSchemaIdReducer } = createSlice({
  name: 'assistantKeypointsSchemaId',
  initialState,
  reducers: {
    loadAssistantKeypointSchemaIdStart(state) {
      state.itemLoadingState = loadingStateBuilder.inProgress();
    },
    loadAssistantKeypointSchemaIdFailure(state) {
      state.itemLoadingState = loadingStateBuilder.failure();
    },
    loadAssistantKeypointSchemaIdSuccess(state, action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.success();
      state.selectedAssistantSchemaId = action.payload;
    },
    updateAssistantKeypointSchemaId(state, _action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.inProgress();
    },
    updateAssistantKeypointSchemaIdSuccess(
      state,
      action: PayloadAction<string>,
    ) {
      state.itemLoadingState = loadingStateBuilder.success();
      state.selectedAssistantSchemaId = action.payload;
    },
    updateAssistantKeypointSchemaIdFailure(state) {
      state.itemLoadingState = loadingStateBuilder.failure();
    },
  },
});

export const {
  loadAssistantKeypointSchemaIdStart,
  loadAssistantKeypointSchemaIdFailure,
  loadAssistantKeypointSchemaIdSuccess,
  updateAssistantKeypointSchemaId,
  updateAssistantKeypointSchemaIdSuccess,
  updateAssistantKeypointSchemaIdFailure,
} = actions;
export { assistantKeypointSchemaIdReducer };
