import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: Record<string, string> = {};
const { actions, reducer: persistedProjectImageReducer } = createSlice({
  name: 'persistedProjectImage',
  initialState,
  reducers: {
    persistImageId(
      state,
      action: PayloadAction<{ projectId: string; imageId: string }>,
    ) {
      const { projectId, imageId } = action.payload;

      state[projectId] = imageId;
    },
    clearPersistedImage(state, action: PayloadAction<{ projectId: string }>) {
      delete state[action.payload.projectId];
    },
  },
});

export const { persistImageId, clearPersistedImage } = actions;
export { persistedProjectImageReducer };
