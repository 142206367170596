import { call, put, takeEvery, select } from 'typed-redux-saga';

import { apiGetWidgetAttributerInferences } from '../../../../../../../api/requests/widgets';
import { getErrorMessage } from '../../../../../../../api/utils';
import { activeProjectIdSelector } from '../../../../../project/project.selectors';
import {
  loadAttributerInferences,
  loadAttributerInferencesSuccess,
  loadAttributerInferencesFailure,
  resetWidget,
} from './attributerInferences.slice';
import { dashboardActiveExperimentIdSelector } from '../../activeExperiment/activeExperiment.selectors';
import { handleError } from '../../../../../commonFeatures/errorHandler/errorHandler.actions';
import {
  attributerInferencesLimitSelector,
  attributerInferencesOffsetSelector,
} from './attributerInferences.selectors';
import { refetchWidgetData } from '../widgetsLiveUpdate/widgetsLiveUpdate.actions';
import { splitByIdSelector } from '../../../splits/data/data.selectors';
import { activeSplitIdSelector } from '../../../activeSplit/activeSplitId/activeSplitId.selectors';
import { setActiveExperimentId } from '../../activeExperiment/activeExperiment.slice';
import { ModelFamily } from '../../../../../../../api/constants/modelFamily';

function* loadDataHandler(
  action: ActionType<
    typeof loadAttributerInferences | typeof refetchWidgetData
  >,
) {
  const projectId = yield* select(activeProjectIdSelector);
  const experimentId = yield* select(dashboardActiveExperimentIdSelector);

  const activeSplitId = yield* select(activeSplitIdSelector);

  if (!projectId || !experimentId) return;

  if (action.type === refetchWidgetData.type && activeSplitId) {
    const activeSplit = yield* select((state: RootState) =>
      splitByIdSelector(state, activeSplitId),
    );

    if (!activeSplit) {
      return;
    }

    const { modelFamily } = activeSplit;

    if (modelFamily !== ModelFamily.Attributer) {
      return;
    }
  }

  const limit =
    action?.payload?.limit ||
    (yield* select(attributerInferencesLimitSelector));

  const offset =
    action?.payload?.offset === undefined
      ? yield* select(attributerInferencesOffsetSelector)
      : action.payload.offset;

  try {
    const { data } = yield* call(apiGetWidgetAttributerInferences, {
      projectId,
      experimentId,
      params: {
        ...(action?.payload || {}),
        limit,
        offset,
      },
    });
    const { items, meta } = data;

    yield* put(
      loadAttributerInferencesSuccess({
        meta,
        items: items.map((item) => ({
          id: `${item.imageId}-${item.labelId}-${item.iteration}`,
          ...item,
        })),
      }),
    );
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to fetch widget data');

    yield* put(loadAttributerInferencesFailure(message));

    yield* put(handleError({ message, error, allowOutsideOfEditor: true }));
  }
}

export function* setActiveExperimentIdHandler() {
  yield* put(resetWidget());
}

export function* attributerInferencesSaga() {
  yield* takeEvery(
    [loadAttributerInferences, refetchWidgetData],
    loadDataHandler,
  );
  yield* takeEvery(setActiveExperimentId, setActiveExperimentIdHandler);
}
