/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssistantModel } from '../models/AssistantModel';
import type { BBoxData } from '../models/BBoxData';
import type { BBoxDataNullable } from '../models/BBoxDataNullable';
import type { CoordinatesData } from '../models/CoordinatesData';
import type { CoordinatesDataNullable } from '../models/CoordinatesDataNullable';
import type { KeypointsData } from '../models/KeypointsData';
import type { RLEMaskData } from '../models/RLEMaskData';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { OmitReadonly } from '../core/utils/OmitReadonly';

export class ToolService {

  /**
   * Internal API used by inference engine to trigger AIJM refresh
   * @returns any OK
   * @throws ApiError
   */
  public static postV1ModelTrigger({
    requestBody,
  }: {
    requestBody: {
      /**
       * Model ID
       */
      model_id: number;
    },
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/model/trigger',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Check if box2mask model exists
   * @returns any Model status
   * @throws ApiError
   */
  public static getApiV10ProjectBoxToInstanceModel({
    projectId,
    modelId,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Model ID preferred by the user
     */
    modelId: number,
  }): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1.0/project/{project_id}/box_to_instance_model/{model_id}',
      path: {
        'project_id': projectId,
        'model_id': modelId,
      },
    });
  }

  /**
   * Check if classification model exists
   * @returns any Model status
   * @throws ApiError
   */
  public static getApiV10ProjectClassificationModel({
    projectId,
    modelId,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Model ID preferred by the user
     */
    modelId: number,
  }): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1.0/project/{project_id}/classification_model/{model_id}',
      path: {
        'project_id': projectId,
        'model_id': modelId,
      },
    });
  }

  /**
   * Check if activity recognition model exists
   * @returns any Model status
   * @throws ApiError
   */
  public static getApiV10ProjectActivityRecognitionModel({
    projectId,
    modelId,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Model ID preferred by the user
     */
    modelId: number,
  }): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1.0/project/{project_id}/activity_recognition_model/{model_id}',
      path: {
        'project_id': projectId,
        'model_id': modelId,
      },
    });
  }

  /**
   * Generates dummy model notification
   * @returns any OK
   * @throws ApiError
   */
  public static postApiV10ProjectDummyModelsNotification({
    projectId,
    requestBody,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    requestBody: {
      /**
       * Event type 60..65
       */
      event_type: number;
      /**
       * classifier, detector, segmentor, dextr
       */
      model_family?: string;
      /**
       * Model id
       */
      model_id?: number;
      /**
       * Progress for MODEL_LABELS_TO_START_TRAIN_PROGRESS and MODEL_TRAINING_PROGRESS
       */
      progress?: number;
    },
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1.0/project/{project_id}/dummy_models_notification',
      path: {
        'project_id': projectId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Check if atom model exists
   * @returns any Model status
   * @throws ApiError
   */
  public static getApiV10ProjectAtomModel({
    projectId,
    modelId,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Model ID preferred by the user
     */
    modelId: number,
  }): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1.0/project/{project_id}/atom_model/{model_id}',
      path: {
        'project_id': projectId,
        'model_id': modelId,
      },
    });
  }

  /**
   * Check if text-to-mask model exists
   * @returns any Model status
   * @throws ApiError
   */
  public static getApiV10ProjectTextToMaskModel({
    projectId,
    modelId,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Model ID preferred by the user
     */
    modelId: number,
  }): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1.0/project/{project_id}/text_to_mask_model/{model_id}',
      path: {
        'project_id': projectId,
        'model_id': modelId,
      },
    });
  }

  /**
   * Check if mask editor model(SAM) exists
   * @returns any Model status
   * @throws ApiError
   */
  public static getApiV10ProjectMaskEditorModel({
    projectId,
    modelId,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Model ID preferred by the user
     */
    modelId: number,
  }): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1.0/project/{project_id}/mask_editor_model/{model_id}',
      path: {
        'project_id': projectId,
        'model_id': modelId,
      },
    });
  }

  /**
   * Segments objects based on bounding boxes and returns masks
   * @returns any Returns masks for detected objects and regions
   * @throws ApiError
   */
  public static postApiV10ProjectImageBoxToInstance({
    projectId,
    imageId,
    requestBody,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Image ID
     */
    imageId: string,
    requestBody?: any,
  }): CancelablePromise<Array<{
    bbox?: BBoxData;
    /**
     * Label class id
     */
    classId?: string;
    /**
     * Label id
     */
    labelId?: string;
    polygon?: CoordinatesData;
    mask?: RLEMaskData;
  }>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1.0/project/{project_id}/image/{image_id}/box_to_instance',
      path: {
        'project_id': projectId,
        'image_id': imageId,
      },
      body: requestBody,
    });
  }

  /**
   * Returns the predicted lov_ids for a label
   * @returns any Returns the predicted lov_ids for attributes
   * @throws ApiError
   */
  public static postApiV10ProjectLabelAttributePrediction({
    projectId,
    labelId,
    requestBody,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Label ID of the selected label
     */
    labelId: string,
    requestBody: {
      /**
       * Model id that should be used for prediction
       */
      model_id?: number;
      /**
       * Predictions confidence threshold
       */
      confidence_threshold?: number;
    },
  }): CancelablePromise<Array<{
    /**
     * AttributeID of the predicted lov
     */
    attributeId?: string;
    /**
     * Predicted LovIDs for the attribute
     */
    lovIds?: Array<string>;
  }>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1.0/project/{project_id}/label/{label_id}/attribute_prediction',
      path: {
        'project_id': projectId,
        'label_id': labelId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Returns the predicted class_id for bounding box or polygon
   * @returns any Returns the predicted label_class id
   * @throws ApiError
   */
  public static postApiV10ProjectImageClassification({
    projectId,
    imageId,
    requestBody,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Image ID
     */
    imageId: string,
    requestBody: {
      bbox?: BBoxDataNullable;
      /**
       * Model id that should be used for prediction
       */
      model_id?: number;
      polygon?: CoordinatesDataNullable;
      keypoints?: CoordinatesDataNullable;
    },
  }): CancelablePromise<{
    /**
     * Label class id
     */
    classId?: string;
    /**
     * Probability of prediction
     */
    probability?: number;
    /**
     * Predicted probability scores for all classes
     */
    predictions?: Record<string, number>;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1.0/project/{project_id}/image/{image_id}/classification',
      path: {
        'project_id': projectId,
        'image_id': imageId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Segments an object based on charged points (positive and negative clicks) and returns a mask
   * @returns any Returns rle mask for detected object
   * @throws ApiError
   */
  public static postApiV10ProjectImageAtom({
    projectId,
    imageId,
    requestBody,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Image ID
     */
    imageId: string,
    requestBody: {
      /**
       * Which mask out of 3 to show
       */
      mask_size_level?: number;
      /**
       * Charged point coordinates [[w1, h1, c1], ...]
       */
      charged_points: Array<Array<number>> | null;
      bbox?: BBoxData;
      mask?: RLEMaskData;
      viewport?: BBoxDataNullable;
      /**
       * Whether to use multi-instance mode
       */
      multi_instance?: boolean;
    },
  }): CancelablePromise<Array<{
    bbox?: BBoxData;
    mask?: RLEMaskData;
  }>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1.0/project/{project_id}/image/{image_id}/atom',
      path: {
        'project_id': projectId,
        'image_id': imageId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Edits / refines a mask using SAM.
   * @returns any Returns rle mask for detected object
   * @throws ApiError
   */
  public static postApiV10ProjectImageMaskEditor({
    projectId,
    imageId,
    requestBody,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Image ID
     */
    imageId: string,
    requestBody: {
      mask: RLEMaskData;
      bbox: BBoxData;
      viewport?: BBoxDataNullable;
    },
  }): CancelablePromise<{
    bbox: BBoxData;
    mask: RLEMaskData;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1.0/project/{project_id}/image/{image_id}/mask_editor',
      path: {
        'project_id': projectId,
        'image_id': imageId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * segments objects based on text input.
   * @returns any Returns predicted masks
   * @throws ApiError
   */
  public static postApiV10ProjectImageTextToMask({
    projectId,
    imageId,
    requestBody,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Image ID
     */
    imageId: string,
    requestBody: {
      /**
       * class names
       */
      label: Array<string> | null;
      /**
       * Text prompt
       */
      prompt: string;
      /**
       * Confidence threshold
       */
      threshold?: number;
      /**
       * if set, returns semantic mask instead of instance masks.
       */
      semantic_mode?: boolean;
      viewport?: BBoxDataNullable;
    },
  }): CancelablePromise<Array<{
    bbox?: BBoxData;
    /**
     * Label class id
     */
    classId?: string | null;
    mask?: RLEMaskData;
  }>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1.0/project/{project_id}/image/{image_id}/text_to_mask',
      path: {
        'project_id': projectId,
        'image_id': imageId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Returns the masks for provided image
   * @returns any Returns predicted polygons and masks
   * @throws ApiError
   */
  public static postApiV10ProjectImageInstanceSegmentation({
    projectId,
    imageId,
    requestBody,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Image ID
     */
    imageId: string,
    requestBody: {
      /**
       * Predictions confidence threshold
       */
      confidence_threshold?: number;
      /**
       * Maximum number of detections
       */
      max_detections_per_image?: number;
      /**
       * Model id that should be used for prediction
       */
      model_id?: number;
      /**
       * Model id for classifier that should be used for class prediction
       */
      model_id_classifier?: number;
      /**
       * Threshold for mask confidence
       */
      masker_threshold?: number;
      viewport?: BBoxDataNullable;
      /**
       * Whether to chain the classifier model or not
       */
      use_classifier?: boolean;
      /**
       * Whether to use the instance segmentation predictions as a prompt for refinement or not
       */
      use_as_prompt?: boolean;
    },
  }): CancelablePromise<Array<{
    bbox?: BBoxData;
    /**
     * Label class id
     */
    classId?: string;
    mask?: RLEMaskData;
    /**
     * Probability of prediction
     */
    score?: number;
  }>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1.0/project/{project_id}/image/{image_id}/instance_segmentation',
      path: {
        'project_id': projectId,
        'image_id': imageId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Returns the bounding boxes for provided image
   * @returns any Returns predicted bounding boxes
   * @throws ApiError
   */
  public static postApiV10ProjectImageObjectDetection({
    projectId,
    imageId,
    requestBody,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Image ID
     */
    imageId: string,
    requestBody: {
      /**
       * Predictions confidence threshold
       */
      confidence_threshold?: number;
      /**
       * Maximum number of detections
       */
      max_detections_per_image?: number;
      /**
       * Model id that should be used for prediction
       */
      model_id?: number | null;
      /**
       * Model id for classifier that should be used for class prediction
       */
      model_id_classifier?: number;
      /**
       * Whether to chain the classifier model or not
       */
      use_classifier?: boolean;
    },
  }): CancelablePromise<Array<{
    bbox?: BBoxData;
    /**
     * Label class id
     */
    classId?: string;
    /**
     * Predictions confidence
     */
    score?: number;
  }>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1.0/project/{project_id}/image/{image_id}/object_detection',
      path: {
        'project_id': projectId,
        'image_id': imageId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Returns the keypoints for provided image
   * @returns any Returns predicted keypoints
   * @throws ApiError
   */
  public static postApiV10ProjectImageKeypointDetection({
    projectId,
    imageId,
    requestBody,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Image ID
     */
    imageId: string,
    requestBody: {
      /**
       * Confidence threshold for the whole object instance containing multiple keypoints
       */
      instance_confidence_threshold?: number;
      /**
       * Confidence threshold for individual keypoint detections
       */
      keypoint_confidence_threshold?: number;
      /**
       * Maximum number of instance detections
       */
      max_detections_per_image?: number;
      /**
       * Model id that should be used for prediction
       */
      model_id?: number;
      /**
       * Model id for classifier that should be used for class prediction
       */
      model_id_classifier?: number;
      /**
       * Whether to chain the classifier model or not
       */
      use_classifier?: boolean;
    },
  }): CancelablePromise<Array<{
    keypoints?: KeypointsData;
    keypointClassIds?: Array<string>;
    /**
     * Label class id
     */
    classId?: string;
    /**
     * Prediction confidence for whole instance
     */
    instanceScore?: number;
    keypointScores?: Array<number>;
  }>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1.0/project/{project_id}/image/{image_id}/keypoint_detection',
      path: {
        'project_id': projectId,
        'image_id': imageId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Returns the semantic segmentation predictions for provided image
   * @returns any Returns predicted masks
   * @throws ApiError
   */
  public static postApiV10ProjectImageSemanticSegmentation({
    projectId,
    imageId,
    requestBody,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Image ID
     */
    imageId: string,
    requestBody: {
      /**
       * Min size in pixels of the object
       */
      min_size?: number;
      /**
       * Model id that should be used for prediction
       */
      model_id?: number;
      /**
       * Whether to use the semantic segmentation predictions as a prompt for refinement or not
       */
      use_as_prompt?: boolean;
    },
  }): CancelablePromise<Array<{
    bbox?: BBoxData;
    /**
     * Label class id
     */
    classId?: string;
    mask?: RLEMaskData;
  }>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1.0/project/{project_id}/image/{image_id}/semantic_segmentation',
      path: {
        'project_id': projectId,
        'image_id': imageId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Returns the predicted tag_class_id for an image
   * @returns string Returns predicted image tags
   * @throws ApiError
   */
  public static postApiV10ProjectImageTagging({
    projectId,
    imageId,
    requestBody,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Image ID
     */
    imageId: string,
    requestBody: {
      /**
       * Model id that should be used for prediction
       */
      model_id?: number;
      /**
       * Confidence threshold for predictions
       */
      confidence_threshold?: number;
    },
  }): CancelablePromise<Array<string>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1.0/project/{project_id}/image/{image_id}/tagging',
      path: {
        'project_id': projectId,
        'image_id': imageId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Check if object segmentation model exists
   * @returns any Model status
   * @throws ApiError
   */
  public static getApiV10ProjectInstanceSegmentationModel({
    projectId,
    modelId,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Model ID preferred by the user
     */
    modelId: number,
  }): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1.0/project/{project_id}/instance_segmentation_model/{model_id}',
      path: {
        'project_id': projectId,
        'model_id': modelId,
      },
    });
  }

  /**
   * Check if object detection model exists
   * @returns any Model status
   * @throws ApiError
   */
  public static getApiV10ProjectObjectDetectionModel({
    projectId,
    modelId,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Model ID preferred by the user
     */
    modelId: number,
  }): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1.0/project/{project_id}/object_detection_model/{model_id}',
      path: {
        'project_id': projectId,
        'model_id': modelId,
      },
    });
  }

  /**
   * Check if keypoint detection model exists
   * @returns any Model status
   * @throws ApiError
   */
  public static getApiV10ProjectKeypointDetection({
    projectId,
    modelId,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Model ID preferred by the user
     */
    modelId: number,
  }): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1.0/project/{project_id}/keypoint_detection/{model_id}',
      path: {
        'project_id': projectId,
        'model_id': modelId,
      },
    });
  }

  /**
   * Check if semantic segmentation model exists
   * @returns any Model status
   * @throws ApiError
   */
  public static getApiV10ProjectSemanticSegmentation({
    projectId,
    modelId,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Model ID preferred by the user
     */
    modelId: number,
  }): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1.0/project/{project_id}/semantic_segmentation/{model_id}',
      path: {
        'project_id': projectId,
        'model_id': modelId,
      },
    });
  }

  /**
   * Check if image tagging model exists
   * @returns void
   * @throws ApiError
   */
  public static getApiV10ProjectTaggingModel({
    projectId,
    modelId,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Model ID preferred by the user
     */
    modelId: number,
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1.0/project/{project_id}/tagging_model/{model_id}',
      path: {
        'project_id': projectId,
        'model_id': modelId,
      },
    });
  }

  /**
   * Check if attribute prediction model exists
   * @returns any Model status
   * @throws ApiError
   */
  public static getApiV10ProjectAttribute({
    projectId,
    modelId,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Model ID preferred by the user
     */
    modelId: number,
  }): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1.0/project/{project_id}/attribute/{model_id}',
      path: {
        'project_id': projectId,
        'model_id': modelId,
      },
    });
  }

  /**
   * Returns the list of assisted models for specific family
   * @returns AssistantModel Assisted models
   * @throws ApiError
   */
  public static getAssistantModels({
    projectId,
    modelFamily,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Family of model
     */
    modelFamily: 'attributer' | 'classifier' | 'detector' | 'image-tagger' | 'segmentor' | 'semantic-segmentor' | 'box2mask' | 'dextr' | 'grabcut' | 'atom' | 'activity-recognizer' | 'txt2mask' | 'keypoint-detector',
  }): CancelablePromise<Array<AssistantModel>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/projects/{project_id}/assistant_models/{model_family}',
      path: {
        'project_id': projectId,
        'model_family': modelFamily,
      },
    });
  }

  /**
   * Set the model for AI tool
   * @returns any Assisted model
   * @throws ApiError
   */
  public static putAssistantModel({
    projectId,
    modelFamily,
    requestBody,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Family of model
     */
    modelFamily: 'attributer' | 'classifier' | 'detector' | 'image-tagger' | 'segmentor' | 'semantic-segmentor' | 'box2mask' | 'dextr' | 'grabcut' | 'atom' | 'activity-recognizer' | 'txt2mask' | 'keypoint-detector',
    requestBody: OmitReadonly<AssistantModel>,
  }): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/v1/projects/{project_id}/assistant_models/{model_family}',
      path: {
        'project_id': projectId,
        'model_family': modelFamily,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Triggers on demand default model training
   * @returns string Returns job ID for created split
   * @throws ApiError
   */
  public static postV1ProjectsOnDemandModel({
    projectId,
    modelFamily,
    requestBody,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Family of model
     */
    modelFamily: 'attributer' | 'classifier' | 'detector' | 'image-tagger' | 'segmentor' | 'semantic-segmentor' | 'activity-recognizer' | 'keypoint-detector',
    requestBody?: any,
  }): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/projects/{project_id}/on_demand_model/{model_family}',
      path: {
        'project_id': projectId,
        'model_family': modelFamily,
      },
      body: requestBody,
    });
  }

  /**
   * Returns score for provided score. Currently only used for tests!
   * @returns any Returns current model score
   * @throws ApiError
   */
  public static getV1ProjectsModelsQualityScores({
    projectId,
    modelId,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Model ID preferred by the user
     */
    modelId: number,
  }): CancelablePromise<{
    /**
     * Model id
     */
    modelId?: number;
    /**
     * Model scores from 0 - 1, higher better
     */
    modelScore?: number;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/projects/{project_id}/models/{model_id}/quality_scores',
      path: {
        'project_id': projectId,
        'model_id': modelId,
      },
    });
  }

}
