import { put, select, takeEvery } from 'typed-redux-saga';

import { apiLoadSplitClassSummaries } from '../../../../../../api/requests/modelPlayground';
import { getErrorMessage } from '../../../../../../api/utils';
import { activeProjectIdSelector } from '../../../../project/project.selectors';
import { loadAll } from '../../../../../utils/api';
import {
  loadSplitSummary,
  loadSplitSummaryFailure,
  loadSplitSummarySuccess,
} from './summary.slice';

function* summaryHandler(action: ActionType<typeof loadSplitSummary>) {
  const projectId = yield* select(activeProjectIdSelector);
  const { splitId } = action.payload;
  try {
    const items = yield* loadAll({
      params: {
        projectId,
        splitId,
      },
      apiHelper: apiLoadSplitClassSummaries,
    });
    yield* put(loadSplitSummarySuccess(items));
  } catch (e) {
    yield* put(
      loadSplitSummaryFailure(
        getErrorMessage(e, 'Not able to load split summary'),
      ),
    );
  }
}

export function* summarySaga() {
  yield* takeEvery(loadSplitSummary, summaryHandler);
}
