import { put, takeEvery } from 'typed-redux-saga';

import {
  checkFingerInputAllowed,
  setFingerInputAllowed,
} from './fingerInput.slice';
import { getLocalStorageItem } from '../../../../../helpers/localStorage';
import { ALLOW_FINGER_STORAGE_PREFIX } from '../../../../../helpers/localStorage/constants';

function* checkFingerInputAllowedHandler() {
  const allowFingerTouch = getLocalStorageItem<boolean>(
    ALLOW_FINGER_STORAGE_PREFIX,
    false,
  );

  if (allowFingerTouch) {
    yield* put(setFingerInputAllowed({ allowed: true }));
  }
}

export function* fingerInputSaga() {
  yield* takeEvery(checkFingerInputAllowed, checkFingerInputAllowedHandler);
}
