import axios from 'axios';

import { API_URL } from '../../constants/paths';
import { apiWrapper } from '../utils';
import { headers, transformRequest, transformResponse } from '../helpers';
import {
  ApiProjectCollaborator,
  ApiProjectCollaboratorParams,
} from '../schemas/projectCollaborator';

export const apiLoadProjectCollaborators = (projectId: string) =>
  apiWrapper(
    axios.request<ApiProjectCollaborator[]>({
      method: 'get',
      url: `${API_URL}/project/${projectId}/project_collaborators`,
      headers,
      transformResponse,
      transformRequest,
    }),
  );

export const apiAddProjectCollaborator = (
  projectId: string,
  { email, name, roleId }: ApiProjectCollaboratorParams,
) =>
  apiWrapper(
    axios({
      method: 'post',
      url: `${API_URL}/project/${projectId}/project_collaborator`,
      data: {
        collaborator_email: email,
        collaborator_name: name,
        project_id: projectId,
        role_id: roleId,
      },
    }),
  );

export const apiUpdateProjectCollaborator = (
  projectId: string,
  collaboratorId: string,
  { roleId }: Pick<ApiProjectCollaboratorParams, 'roleId'>,
) =>
  apiWrapper(
    axios({
      method: 'put',
      url: `${API_URL}/project/${projectId}/project_collaborator/${collaboratorId}`,
      data: {
        role_id: roleId,
      },
    }),
  );

export const apiDeleteProjectCollaborator = (
  projectId: string,
  collaboratorId: string,
) =>
  apiWrapper(
    axios({
      method: 'delete',
      url: `${API_URL}/project/${projectId}/project_collaborator/${collaboratorId}`,
    }),
  );
