import { createSelector } from '@reduxjs/toolkit';

import { adapter } from './projectUsers.slice';
import { projectSelector } from '../project.selectors';

export const projectUsersStateSelector = createSelector(
  projectSelector,
  (state) => state.projectUsers,
);
const selectors = adapter.getSelectors(projectUsersStateSelector);

export const projectUsersSelector = selectors.selectAll;
export const projectUserByIdSelector = selectors.selectById;
export const projectUsersMapSelector = selectors.selectEntities;

export const projectUsersAsOptionsSelector = createSelector(
  [projectUsersSelector],
  (projectUsers) =>
    projectUsers.map(({ id, username }) => ({
      key: id,
      value: id,
      text: username,
    })),
);
export const projectUsersAsOptionsWithAllSelector = createSelector(
  [projectUsersAsOptionsSelector],
  (options) => [
    {
      text: 'Everyone',
      key: 'everyone',
      value: null,
    },
    ...options,
  ],
);

export const projectUsersLoadingStateSelector = createSelector(
  projectUsersStateSelector,
  (state) => state.loadingState,
);
