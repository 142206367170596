import {
  createSlice,
  PayloadAction,
  createEntityAdapter,
  EntityState,
  Update,
} from '@reduxjs/toolkit';

import {
  ExperimentParameter,
  ExperimentComponent,
} from '../../../../../../api/domainModels/modelPlayground';
import {
  loadingStateBuilder,
  LoadingState,
} from '../../../../../utils/loadingState';
import { setActiveExperimentId } from '../activeExperimentId/activeExperimentId.slice';
import { setEditedProjectId } from '../../../../sections/editedProject/project/project.slice';

// TODO:: consider some sort of unification between components
// and parameters re models reducers
export const componentsAdapter = createEntityAdapter<ExperimentComponent>({
  sortComparer: (a, b) => (a.componentId < b.componentId ? 1 : -1),
});
export const parametersAdapter = createEntityAdapter<ExperimentParameter>();

type SchedulersState = {
  components: EntityState<ExperimentComponent> & {
    loadingState: LoadingState;
    updateLoadingState: LoadingState;
  };
  parameters: EntityState<ExperimentParameter> & {
    loadingState: LoadingState;
    updateLoadingState: LoadingState;
  };
};

const initialState: SchedulersState = {
  components: componentsAdapter.getInitialState({
    loadingState: loadingStateBuilder.initial(),
    updateLoadingState: loadingStateBuilder.initial(),
  }),
  parameters: parametersAdapter.getInitialState({
    loadingState: loadingStateBuilder.initial(),
    updateLoadingState: loadingStateBuilder.initial(),
  }),
};

const { actions, reducer: schedulersReducer } = createSlice({
  name: 'schedulers',
  initialState,
  reducers: {
    loadSchedulers(state) {
      state.components.loadingState =
        loadingStateBuilder.inProgress('Loading schedulers');
      componentsAdapter.removeAll(state.components);
      parametersAdapter.removeAll(state.parameters);
    },
    loadSchedulersFailure(state, action: PayloadAction<string>) {
      state.components.loadingState = loadingStateBuilder.failure(
        action.payload,
      );
    },
    loadSchedulersSuccess(state, action: PayloadAction<ExperimentComponent[]>) {
      state.components.loadingState = loadingStateBuilder.success();
      componentsAdapter.setAll(state.components, action.payload);
    },
    updateExperimentScheduler(state, _action: PayloadAction<string>) {
      state.components.updateLoadingState =
        loadingStateBuilder.inProgress('Updating scheduler');
    },
    updateExperimentSchedulerFailure(state, action: PayloadAction<string>) {
      state.components.updateLoadingState = loadingStateBuilder.failure(
        action.payload,
      );
    },
    updateExperimentSchedulerSuccess(
      state,
      action: PayloadAction<ExperimentComponent>,
    ) {
      // first remove the component that we selected to avoid iterating over it
      componentsAdapter.removeOne(state.components, action.payload.componentId);
      const componentsIds = state.components.ids;
      const componentsEntities = state.components.entities;
      // update remaining components by changing it's id's and nullifying it's experimentComponentIds
      componentsAdapter.updateMany(
        state.components,
        componentsIds.map((id) => ({
          id,
          changes: {
            // this unorthodox id change follows the `id: experimentComponentId || id` usage of mapped component
            id: componentsEntities[id]?.componentId,
            selected: false,
            experimentComponentId: null,
          },
        })),
      );
      // put back the selected component
      componentsAdapter.addOne(state.components, action.payload);
      state.components.updateLoadingState = loadingStateBuilder.success();
    },
    loadSchedulerParameters(state, _action: PayloadAction<string>) {
      state.parameters.loadingState =
        loadingStateBuilder.inProgress('Loading parameters');
      state.parameters = initialState.parameters;
    },
    loadSchedulerParametersFailure(state, action: PayloadAction<string>) {
      state.parameters.loadingState = loadingStateBuilder.failure(
        action.payload,
      );
    },
    loadSchedulerParametersSuccess(
      state,
      action: PayloadAction<ExperimentParameter[]>,
    ) {
      state.parameters.loadingState = loadingStateBuilder.success();
      parametersAdapter.setAll(state.parameters, action.payload);
    },
    updateSchedulerParameter(
      state,
      _action: PayloadAction<{
        schedulerParameterId: string;
        value: any;
      }>,
    ) {
      state.parameters.updateLoadingState =
        loadingStateBuilder.inProgress('Loading parameters');
    },
    updateSchedulerParameterFailure(state, action: PayloadAction<string>) {
      state.parameters.updateLoadingState = loadingStateBuilder.failure(
        action.payload,
      );
    },
    updateSchedulerParameterSuccess(
      state,
      action: PayloadAction<Update<ExperimentParameter>>,
    ) {
      state.parameters.updateLoadingState = loadingStateBuilder.success();
      parametersAdapter.updateOne(state.parameters, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setActiveExperimentId, () => initialState);
    builder.addCase(setEditedProjectId, () => initialState);
  },
});

export { schedulersReducer };
export const {
  loadSchedulers,
  loadSchedulersSuccess,
  loadSchedulersFailure,
  updateExperimentScheduler,
  updateExperimentSchedulerFailure,
  updateExperimentSchedulerSuccess,
  loadSchedulerParameters,
  loadSchedulerParametersSuccess,
  loadSchedulerParametersFailure,
  updateSchedulerParameter,
  updateSchedulerParameterSuccess,
  updateSchedulerParameterFailure,
} = actions;
