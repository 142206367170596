import { take, put, select, takeEvery, call } from 'typed-redux-saga';

import { experimentComponentDataMapper } from '../../../../../../api/domainModels/modelPlayground';
import {
  apiGetExperimentComponents,
  apiGetComponentParameters,
  apiGetArchitectureWeights,
  apiUpdateExperimentComponent,
  apiUpdateComponentParameter,
  apiUpdateArchitectureWeights,
  apiSavePretrainedWeight,
} from '../../../../../../api/requests/modelPlayground';
import { getErrorMessage } from '../../../../../../api/utils';
import { loadAll } from '../../../../../utils/api';
import { activeProjectIdSelector } from '../../../../project/project.selectors';
import { parseParameterValue } from '../parameters.helpers';
import {
  activeExperimentIdSelector,
  isActiveExperimentEditableSelector,
} from '../selectors';
import {
  selectedArchitectureIdSelector,
  selectedArchitectureComponentIdSelector,
  architectureParameterByIdSelector,
  architectureParametersForWeightsSelector,
  architectureParameterWeightsSelector,
  architectureParametersHaveWeightsSelector,
  architectureNestedParameterByIdSelector,
  architectureNestedParametersHaveWeightsSelector,
  architectureNestedParameterComponentIdSelector,
  architectureNestedParametersForWeightsSelector,
  architectureNestedParameterWeightsSelector,
  experimentArchitecturesUpdateLoadingStateSelector,
} from './architectures.selectors';
import {
  loadArchitectures,
  loadArchitecturesSuccess,
  loadArchitecturesFailure,
  updateExperimentArchitecture,
  updateExperimentArchitectureFailure,
  updateExperimentArchitectureSuccess,
  loadArchitectureParameters,
  loadArchitectureParametersSuccess,
  loadArchitectureParametersFailure,
  updateArchitectureParameter,
  updateArchitectureParameterSuccess,
  updateArchitectureParameterFailure,
  loadArchitectureNestedParameters,
  loadArchitectureNestedParametersSuccess,
  loadArchitectureNestedParametersFailure,
  updateArchitectureNestedParameter,
  updateArchitectureNestedParameterSuccess,
  updateArchitectureNestedParameterFailure,
  loadArchitectureWeights,
  loadArchitectureWeightsSuccess,
  loadArchitectureWeightsFailure,
  updateArchitectureWeights,
  updateArchitectureWeightsSuccess,
  updateArchitectureWeightsFailure,
  loadArchitectureNestedWeights,
  loadArchitectureNestedWeightsSuccess,
  loadArchitectureNestedWeightsFailure,
  updateArchitectureNestedWeights,
  updateArchitectureNestedWeightsSuccess,
  updateArchitectureNestedWeightsFailure,
  saveTrainedWeight,
  saveTrainedWeightSuccess,
  saveTrainedWeightFailure,
} from './architectures.slice';
import { statusChecks } from '../../../../../../constants/status';
import { experimentsByIdSelector } from '../../experiments/experimentsData/experimentsData.selectors';
import { enqueueNotification } from '../../../../ui/stackNotifications/stackNotifications.slice';
import { dashboardActiveExperimentIdSelector } from '../../dashboard/activeExperiment/activeExperiment.selectors';
import { hideModals } from '../../../../ui/modals/modals.slice';
import { ModelFamily } from '../../../../../../api/constants/modelFamily';
import {
  Component,
  ParameterType,
} from '../../../../../../api/constants/modelPlayground';

function* loadArchitecturesHandler() {
  try {
    const projectId = yield* select(activeProjectIdSelector);
    const experimentId = yield* select(activeExperimentIdSelector);

    if (!experimentId) return;

    const architectures = yield* loadAll({
      apiHelper: apiGetExperimentComponents,
      params: {
        projectId,
        experimentId,
        componentType: Component.Model,
      },
    });
    const isEditable = yield* select(isActiveExperimentEditableSelector);
    // as BE does not provide initially selected value for architecture
    // we need to make sure it either exists (user selected it) or preselect it for them
    // we'll be working under assumption that architecture should always be selected
    const hasArchitectureSelected = architectures.some((item) => item.selected);
    if (isEditable && architectures.length > 0 && !hasArchitectureSelected) {
      const architecturesIsSaving = statusChecks.isInProgress(
        (yield* select(experimentArchitecturesUpdateLoadingStateSelector))
          .status,
      );
      if (!architecturesIsSaving) {
        yield* put(updateExperimentArchitecture(architectures[0].id));
      }
    }
    yield* put(
      loadArchitecturesSuccess(
        architectures.map(experimentComponentDataMapper.fromBackend),
      ),
    );
  } catch (e) {
    yield* put(
      loadArchitecturesFailure(
        getErrorMessage(e, 'Not able to load architectures'),
      ),
    );
  }
}

function* updateArchitectureHandler(
  action: ActionType<typeof updateExperimentArchitecture>,
) {
  try {
    const projectId = yield* select(activeProjectIdSelector);
    const experimentId = yield* select(activeExperimentIdSelector);

    if (!experimentId) {
      return;
    }

    const componentType: Component = Component.Model;
    const params = { projectId, experimentId, componentType };
    const data = { architectureId: action.payload };
    const { data: response } = yield* call(() =>
      apiUpdateExperimentComponent(params, data),
    );
    yield* put(
      updateExperimentArchitectureSuccess(
        experimentComponentDataMapper.fromBackend(response),
      ),
    );
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to update architecture');

    yield* put(updateExperimentArchitectureFailure(message));
    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
          refresh: false,
        },
      }),
    );
  }
}

function* loadParametersHandler(
  action: ActionType<typeof loadArchitectureParameters>,
) {
  try {
    const projectId = yield* select(activeProjectIdSelector);
    const experimentId = yield* select(activeExperimentIdSelector);
    const componentId = action.payload;

    if (!experimentId) return;
    const componentType: Component = Component.Model;
    const parameters = yield* loadAll({
      apiHelper: apiGetComponentParameters,
      params: {
        projectId,
        experimentId,
        componentId,
        componentType,
      },
    });
    yield* put(loadArchitectureParametersSuccess(parameters));
  } catch (e) {
    yield* put(
      loadArchitectureParametersFailure(
        getErrorMessage(e, 'Not able to load parameters'),
      ),
    );
  }
}

function* loadNestedParametersHandler(
  action: ActionType<typeof loadArchitectureNestedParameters>,
) {
  try {
    const projectId = yield* select(activeProjectIdSelector);
    const experimentId = yield* select(activeExperimentIdSelector);
    const componentId = action.payload;

    if (!projectId || !experimentId) return;

    const componentType: Component = Component.Model;
    const parameters = yield* loadAll({
      apiHelper: apiGetComponentParameters,
      params: {
        projectId,
        experimentId,
        componentId,
        componentType,
      },
    });
    yield* put(loadArchitectureNestedParametersSuccess(parameters));
  } catch (e) {
    yield* put(
      loadArchitectureNestedParametersFailure(
        getErrorMessage(e, 'Not able to load parameters'),
      ),
    );
  }
}

function* updateParameterHandler(
  action: ActionType<typeof updateArchitectureParameter>,
) {
  try {
    const projectId = yield* select(activeProjectIdSelector);
    const experimentId = yield* select(activeExperimentIdSelector);
    const componentId = yield* select(selectedArchitectureIdSelector);

    if (!componentId || !experimentId) {
      return;
    }

    const componentType: Component = Component.Model;
    const params = { projectId, experimentId, componentId, componentType };
    const { architectureParameterId, value } = action.payload;
    const data = action.payload;
    const editedParameter = yield* select((state: RootState) =>
      architectureParameterByIdSelector(state, architectureParameterId),
    );

    if (editedParameter) {
      data.value = parseParameterValue(editedParameter, value);
    }

    const { data: response } = yield* call(
      apiUpdateComponentParameter,
      params,
      data,
    );
    yield* put(
      updateArchitectureParameterSuccess({
        changes: response,
        id: architectureParameterId,
      }),
    );
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to update parameter');

    yield* put(updateArchitectureParameterFailure(message));
    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
          refresh: false,
        },
      }),
    );
  }
}

function* updateNestedParameterHandler(
  action: ActionType<typeof updateArchitectureNestedParameter>,
) {
  try {
    const projectId = yield* select(activeProjectIdSelector);
    const experimentId = yield* select(activeExperimentIdSelector);

    if (!experimentId) {
      return;
    }

    const componentType: Component = Component.Model;
    const { componentId, ...data } = action.payload;
    const params = { projectId, experimentId, componentId, componentType };
    const { architectureParameterId, value } = action.payload;
    const editedParameter = yield* select((state: RootState) =>
      architectureNestedParameterByIdSelector(state, architectureParameterId),
    );
    if (editedParameter) {
      data.value = parseParameterValue(editedParameter, value);
    }

    const { data: response } = yield* call(
      apiUpdateComponentParameter,
      params,
      data,
    );
    yield* put(
      updateArchitectureNestedParameterSuccess({
        changes: response,
        id: architectureParameterId,
      }),
    );
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to update parameter');

    yield* put(updateArchitectureNestedParameterFailure(message));
    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
          refresh: false,
        },
      }),
    );
  }
}

function* reactWithLoadWeightsHandler(
  action?:
    | ActionType<typeof loadArchitectureParametersSuccess>
    | ActionType<typeof updateArchitectureParameterSuccess>,
) {
  // from initial loadArchitectureParameters or updateNestedArchitectureParameters => wait for nested weights refetch
  // from updateArchitectureParameter => check if updated one is Model type => wait for nested weights refetch
  //                                                                        => send existing nested weights (no refetch)
  let fullWeights =
    !action || action.type === loadArchitectureParametersSuccess.type;

  if (!fullWeights) {
    const { id: architectureParameterId } = (
      action as ActionType<typeof updateArchitectureParameterSuccess>
    ).payload;

    const editedParameter = yield* select((state: RootState) =>
      architectureParameterByIdSelector(
        state,
        architectureParameterId as string,
      ),
    );

    if (editedParameter?.type === ParameterType.Model) {
      fullWeights = true;
    }
  }

  const architectureHasWeightParameter = yield* select(
    architectureParametersHaveWeightsSelector,
  );

  const architectureId = yield* select(selectedArchitectureComponentIdSelector);

  if (architectureId && architectureHasWeightParameter) {
    yield* put(loadArchitectureWeights({ fullWeights }));
  }
}

function* reactWithLoadNestedWeightsHandler() {
  const architectureHasWeightParameter = yield* select(
    architectureNestedParametersHaveWeightsSelector,
  );
  const parameterId = yield* select(
    architectureNestedParameterComponentIdSelector,
  );

  if (parameterId && architectureHasWeightParameter)
    yield* put(loadArchitectureNestedWeights());
}

// Just for semseg model family we send full weights
function* reactWithFullLoadWeightsHandler() {
  const experimentId = yield* select(activeExperimentIdSelector);

  if (!experimentId) {
    return;
  }

  const experiment = yield* select((state: RootState) =>
    experimentsByIdSelector(state, experimentId),
  );

  if (experiment?.modelFamily !== ModelFamily.SemanticSegmentor) {
    return;
  }

  yield* reactWithLoadWeightsHandler();
}

function* loadWeightsHandler(
  action: ActionType<typeof loadArchitectureWeights>,
) {
  const { fullWeights } = action.payload;

  try {
    const projectId = yield* select(activeProjectIdSelector);
    const experimentId = yield* select(activeExperimentIdSelector);
    const architectureId = yield* select(
      selectedArchitectureComponentIdSelector,
    );

    if (!experimentId) {
      return;
    }

    const experiment = yield* select((state: RootState) =>
      experimentsByIdSelector(state, experimentId),
    );

    const paramsForWeights = yield* select(
      architectureParametersForWeightsSelector,
    );

    let data = [...paramsForWeights];

    // Just for semseg model family we send full weights
    if (experiment?.modelFamily === ModelFamily.SemanticSegmentor) {
      // we wait for nested (re)fetch
      if (fullWeights) {
        yield* take([
          loadArchitectureNestedWeightsSuccess,
          loadArchitectureNestedWeightsFailure,
        ]);
      }

      const nestedParamsForWeights = yield* select(
        architectureNestedParametersForWeightsSelector,
      );

      data = [...data, ...nestedParamsForWeights];
    }

    const weights = yield* loadAll({
      apiHelper: apiGetArchitectureWeights,
      params: {
        projectId,
        experimentId,
        architectureId,
      },
      requestData: data,
    });

    const isEditable = yield* select(isActiveExperimentEditableSelector);
    // as BE does not provide initially selected weight
    // we'll want to preselect it if there is no value set
    const weightParameter = yield* select(architectureParameterWeightsSelector);
    const hasWeightSelected = !!weightParameter?.selectedValue;
    const selectedValueMatchesItems =
      hasWeightSelected &&
      weights.some((weight) => weight.id === weightParameter?.selectedValue);

    if (
      isEditable &&
      (!hasWeightSelected || !selectedValueMatchesItems) &&
      !!weights[0]
    ) {
      yield* put(updateArchitectureWeights(weights[0].id));
    }
    yield* put(loadArchitectureWeightsSuccess(weights));
  } catch (e) {
    yield* put(
      loadArchitectureWeightsFailure(
        getErrorMessage(e, 'Not able to load weights'),
      ),
    );
  }
}

function* loadNestedWeightsHandler() {
  try {
    const projectId = yield* select(activeProjectIdSelector);
    const experimentId = yield* select(activeExperimentIdSelector);
    const architectureId = yield* select(
      architectureNestedParameterComponentIdSelector,
    );
    const data = yield* select(architectureNestedParametersForWeightsSelector);

    const weights = yield* loadAll({
      apiHelper: apiGetArchitectureWeights,
      params: {
        projectId,
        experimentId,
        architectureId,
      },
      requestData: data,
    });

    const isEditable = yield* select(isActiveExperimentEditableSelector);
    // as BE does not provide initially selected weight
    // we'll want to preselect it if there is no value set
    const weightParameter = yield* select(
      architectureNestedParameterWeightsSelector,
    );
    const hasWeightSelected = !!weightParameter?.selectedValue;
    const selectedValueMatchesItems =
      hasWeightSelected &&
      weights.some((weight) => weight.id === weightParameter?.selectedValue);

    if (
      isEditable &&
      (!hasWeightSelected || !selectedValueMatchesItems) &&
      !!weights[0]
    ) {
      yield* put(updateArchitectureNestedWeights(weights[0].id));
    }
    yield* put(loadArchitectureNestedWeightsSuccess(weights));
  } catch (e) {
    yield* put(
      loadArchitectureNestedWeightsFailure(
        getErrorMessage(e, 'Not able to load weights'),
      ),
    );
  }
}

function* updateWeightsHandler(
  action: ActionType<typeof updateArchitectureWeights>,
) {
  try {
    const weightId = action.payload;
    const projectId = yield* select(activeProjectIdSelector);
    const experimentId = yield* select(activeExperimentIdSelector);
    const architectureId = yield* select(selectedArchitectureIdSelector);

    if (!architectureId || !weightId || !experimentId) {
      return;
    }

    const params = { projectId, experimentId, architectureId };
    const weightParameter = yield* select(architectureParameterWeightsSelector);

    if (!weightParameter) return;

    const data = {
      architectureParameterId: weightParameter.id,
      weightId,
    };

    const { data: response } = yield* call(
      apiUpdateArchitectureWeights,
      params,
      data,
    );
    yield* put(
      updateArchitectureWeightsSuccess({
        changes: response,
        id: weightParameter.id,
      }),
    );
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to update weights');

    yield* put(updateArchitectureWeightsFailure(message));
    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
          refresh: false,
        },
      }),
    );
  }
}

function* updateNestedWeightsHandler(
  action: ActionType<typeof updateArchitectureNestedWeights>,
) {
  try {
    const projectId = yield* select(activeProjectIdSelector);
    const experimentId = yield* select(activeExperimentIdSelector);
    const architectureId = yield* select(
      architectureNestedParameterComponentIdSelector,
    );

    if (!architectureId || !experimentId) {
      return;
    }

    const params = { projectId, experimentId, architectureId };
    const weightParameter = yield* select(
      architectureNestedParameterWeightsSelector,
    );

    if (!weightParameter) return;

    const data = {
      architectureParameterId: weightParameter.id,
      weightId: action.payload,
    };
    const { data: response } = yield* call(
      apiUpdateArchitectureWeights,
      params,
      data,
    );
    yield* put(
      updateArchitectureNestedWeightsSuccess({
        changes: response,
        id: weightParameter.id,
      }),
    );
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to update weights');

    yield* put(updateArchitectureNestedWeightsFailure(message));
    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
          refresh: false,
        },
      }),
    );
  }
}

function* saveTrainedWeightHandler(
  action: ActionType<typeof saveTrainedWeight>,
) {
  const experimentId = yield* select(dashboardActiveExperimentIdSelector);
  const projectId = yield* select(activeProjectIdSelector);

  if (!experimentId || !projectId) return;

  try {
    yield* call(
      apiSavePretrainedWeight,
      {
        projectId,
        experimentId,
      },
      action.payload,
    );
    yield* put(saveTrainedWeightSuccess());
    yield* put(
      enqueueNotification({
        message: 'Trained weights have been saved successfully',
        options: { variant: 'success' },
      }),
    );
    yield* put(hideModals());
  } catch (error) {
    const errorMessage = getErrorMessage(error, 'Not able to save weight');

    yield* put(saveTrainedWeightFailure(errorMessage));
    yield* put(
      enqueueNotification({
        message: errorMessage,
        options: { variant: 'error' },
      }),
    );
  }
}

export function* architecturesSaga() {
  yield* takeEvery(loadArchitectures, loadArchitecturesHandler);
  yield* takeEvery(updateExperimentArchitecture, updateArchitectureHandler);
  yield* takeEvery(loadArchitectureParameters, loadParametersHandler);
  yield* takeEvery(
    loadArchitectureNestedParameters,
    loadNestedParametersHandler,
  );
  yield* takeEvery(
    loadArchitectureParametersSuccess,
    reactWithLoadWeightsHandler,
  );
  yield* takeEvery(
    updateArchitectureParameterSuccess,
    reactWithLoadWeightsHandler,
  );
  yield* takeEvery(
    loadArchitectureNestedParametersSuccess,
    reactWithLoadNestedWeightsHandler,
  );
  yield* takeEvery(
    updateArchitectureNestedParameterSuccess,
    reactWithLoadNestedWeightsHandler,
  );
  yield* takeEvery(
    updateArchitectureNestedParameterSuccess,
    reactWithFullLoadWeightsHandler,
  );
  yield* takeEvery(updateArchitectureParameter, updateParameterHandler);
  yield* takeEvery(
    updateArchitectureNestedParameter,
    updateNestedParameterHandler,
  );
  yield* takeEvery(loadArchitectureWeights, loadWeightsHandler);
  yield* takeEvery(updateArchitectureWeights, updateWeightsHandler);
  yield* takeEvery(loadArchitectureNestedWeights, loadNestedWeightsHandler);
  yield* takeEvery(updateArchitectureNestedWeights, updateNestedWeightsHandler);
  yield* takeEvery(saveTrainedWeight, saveTrainedWeightHandler);
}
