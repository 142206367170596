import { call, select } from 'typed-redux-saga';
import compose from 'lodash/fp/compose';

import { apiUpdateCustomer } from '../../../api/requests/payment';
import { EDIT_PAYMENT_METHOD_FORM_NAME } from '../../../constants/editPaymentMethodForm';
import { formSelector } from '../../state/root.selectors';

export function* saveBillingDetailsHandler(workspaceId: string) {
  const form = yield* select(
    compose((forms) => forms[EDIT_PAYMENT_METHOD_FORM_NAME], formSelector),
  );
  const customerInformation = {
    ...form.values,
  };

  delete customerInformation.subscription;

  Object.keys(form.values).forEach((key) => {
    if (typeof customerInformation[key] !== 'string') {
      customerInformation[key] = '';
    }
  });

  const response = yield* call(
    apiUpdateCustomer,
    workspaceId,
    customerInformation,
  );

  return response.data;
}
