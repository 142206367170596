/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Notification = {
  id: string;
  subject: Notification.subject;
  text: string;
  createdAt: string;
  noticedAt: string | null;
  readAt: string | null;
  url?: string | null;
  workspaceId?: string | null;
  projectId?: string | null;
  jobId?: string;
};

export namespace Notification {

  export enum subject {
    NOTIFICATION_SUBJECT_UNSPECIFIED = 'notification_subject_unspecified',
    NOTIFICATION_SUBJECT_WORKSPACE_PERMISSION_GRANTED = 'notification_subject_workspace_permission_granted',
    NOTIFICATION_SUBJECT_WORKSPACE_PERMISSION_CHANGED = 'notification_subject_workspace_permission_changed',
    NOTIFICATION_SUBJECT_WORKSPACE_PERMISSION_REVOKED = 'notification_subject_workspace_permission_revoked',
    NOTIFICATION_SUBJECT_PROJECT_EXPORTED = 'notification_subject_project_exported',
    NOTIFICATION_SUBJECT_PROJECT_EXPORT_FAILED = 'notification_subject_project_export_failed',
    NOTIFICATION_SUBJECT_PROJECT_EXPORT_STARTED = 'notification_subject_project_export_started',
    NOTIFICATION_SUBJECT_PROJECT_EXPORT_QUEUED = 'notification_subject_project_export_queued',
    NOTIFICATION_SUBJECT_PROJECT_COPIED = 'notification_subject_project_copied',
    NOTIFICATION_SUBJECT_PROJECT_COPYING_FAILED = 'notification_subject_project_copying_failed',
    NOTIFICATION_SUBJECT_PROJECT_COPYING_STARTED = 'notification_subject_project_copying_started',
    NOTIFICATION_SUBJECT_PROJECT_COPYING_QUEUED = 'notification_subject_project_copying_queued',
    NOTIFICATION_SUBJECT_REPORT_CREATED = 'notification_subject_report_created',
    NOTIFICATION_SUBJECT_REPORT_CREATION_FAILED = 'notification_subject_report_creation_failed',
    NOTIFICATION_SUBJECT_REPORT_CREATION_STARTED = 'notification_subject_report_creation_started',
    NOTIFICATION_SUBJECT_REPORT_CREATION_QUEUED = 'notification_subject_report_creation_queued',
    NOTIFICATION_SUBJECT_DATASPLIT_CREATED = 'notification_subject_datasplit_created',
    NOTIFICATION_SUBJECT_DATASPLIT_CREATION_FAILED = 'notification_subject_datasplit_creation_failed',
    NOTIFICATION_SUBJECT_DATASPLIT_PREPARING_FOLDS_STARTED = 'notification_subject_datasplit_preparing_folds_started',
    NOTIFICATION_SUBJECT_MODEL_EXPORT_STARTED = 'notification_subject_model_export_started',
    NOTIFICATION_SUBJECT_MODEL_EXPORTED = 'notification_subject_model_exported',
    NOTIFICATION_SUBJECT_MODEL_EXPORT_FAILED = 'notification_subject_model_export_failed',
    NOTIFICATION_SUBJECT_IMPORT_FILES_PARSED = 'notification_subject_import_files_parsed',
    NOTIFICATION_SUBJECT_IMPORT_FILES_PARSING_FAILED = 'notification_subject_import_files_parsing_failed',
    NOTIFICATION_SUBJECT_IMPORT_COMPLETED = 'notification_subject_import_completed',
    NOTIFICATION_SUBJECT_IMPORT_FAILED = 'notification_subject_import_failed',
    NOTIFICATION_SUBJECT_FRAME_IMPORT_SUCCEEDED = 'notification_subject_frame_import_succeeded',
    NOTIFICATION_SUBJECT_FRAME_IMPORT_FAILED = 'notification_subject_frame_import_failed',
    NOTIFICATION_SUBJECT_VIDEO_CUT_SUCCEEDED = 'notification_subject_video_cut_succeeded',
    NOTIFICATION_SUBJECT_VIDEO_CUT_FAILED = 'notification_subject_video_cut_failed',
    NOTIFICATION_SUBJECT_VIDEO_PROCESSED = 'notification_subject_video_processed',
    NOTIFICATION_SUBJECT_VIDEO_PROCESSING_FAILED = 'notification_subject_video_processing_failed',
    NOTIFICATION_SUBJECT_DATASET_STATUS_UPDATE_STARTED = 'notification_subject_dataset_status_update_started',
    NOTIFICATION_SUBJECT_DATASET_STATUS_UPDATED = 'notification_subject_dataset_status_updated',
    NOTIFICATION_SUBJECT_DATASET_STATUS_UPDATE_FAILED = 'notification_subject_dataset_status_update_failed',
    NOTIFICATION_SUBJECT_INITIAL_MODEL_CREATED = 'notification_subject_initial_model_created',
    NOTIFICATION_SUBJECT_INITIAL_MODEL_CREATION_FAILED = 'notification_subject_initial_model_creation_failed',
  }


}

