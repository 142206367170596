import { Reducer } from 'react';
import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { persistStore } from 'redux-persist';

import { rootSaga } from '../state/root.saga';
import { createSagaInjector } from './createSagaInjector';
import { enhancedHistory } from './enhancedHistory';
import { constructRootReducer } from './reducers';

const sagaMiddleware = createSagaMiddleware({
  onError: (error, info) => {
    if (process.env.JEST_WORKER_ID === undefined) {
      window.onerror
        ? window.onerror('saga error', undefined, undefined, undefined, error)
        : // eslint-disable-next-line no-console
          console.error('saga error', error, info);
    }
  },
});

const enhancers = [];
const middleware = [sagaMiddleware, routerMiddleware(enhancedHistory)];

const isDev = process.env.REACT_APP_SENTRY_ENV !== 'production';

if (isDev) {
  const { __REDUX_DEVTOOLS_EXTENSION__: reduxDevTools } = window as any;

  if (typeof reduxDevTools === 'function') {
    enhancers.push(
      reduxDevTools({
        maxAge: 200,
      }),
    );
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

export const configureStore = () => {
  const justStore = createStore(constructRootReducer(), composedEnhancers);
  const store = justStore as typeof justStore & { [k: string]: any };

  store.asyncReducers = {};

  store.injectReducer = (key: string, asyncReducer: Reducer<any, any>) => {
    store.asyncReducers[key] = asyncReducer;
    store.replaceReducer(constructRootReducer(store.asyncReducers));
  };

  store.unplugReducer = (key: string) => {
    delete store.asyncReducers[key];
    store.replaceReducer(constructRootReducer(store.asyncReducers));
  };
  const { injectSaga, unplugSaga } = createSagaInjector(
    sagaMiddleware.run,
    rootSaga,
  );

  store.injectSaga = injectSaga;
  store.unplugSaga = unplugSaga;

  const persistor = persistStore(store);

  return {
    store,
    persistor,
  };
};
