import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RunConfusionMatrix } from '../../../../../../api/domainModels/consensusScoring';
import {
  loadingStateBuilder,
  LoadingState,
} from '../../../../../utils/loadingState';

type ConfusionMatrixState = {
  data: RunConfusionMatrix[];
  loadingState: LoadingState;
};

const initialState: ConfusionMatrixState = {
  data: [],
  loadingState: loadingStateBuilder.initial(),
};

const { actions, reducer: confusionMatrixReducer } = createSlice({
  name: 'confusionMatrix',
  initialState,
  reducers: {
    loadConfusionMatrix(state, _action: PayloadAction<{ runId: string }>) {
      state.loadingState = loadingStateBuilder.inProgress();
      state.data = [];
    },
    loadConfusionMatrixSuccess(
      state,
      action: PayloadAction<RunConfusionMatrix[]>,
    ) {
      state.loadingState = loadingStateBuilder.success();
      state.data = action.payload;
    },
    loadConfusionMatrixFailure(state, action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.failure(action.payload);
    },
  },
});

export { confusionMatrixReducer };
export const {
  loadConfusionMatrix,
  loadConfusionMatrixSuccess,
  loadConfusionMatrixFailure,
} = actions;
