import axios from 'axios';

import {
  ApiWorkspaceCollaborator,
  ApiWorkspaceCollaboratorParams,
} from '../schemas/workspaceCollaborator';
import { API_URL } from '../../constants/paths';
import { apiWrapper } from '../utils';
import { transformResponse } from '../helpers';

export const apiLoadWorkspaceCollaborators = (workspaceId: string) =>
  apiWrapper(
    axios.request<ApiWorkspaceCollaborator[]>({
      method: 'get',
      transformResponse,
      url: `${API_URL}/workspace/${workspaceId}/ws_user`,
    }),
  );

export const apiAddWorkspaceCollaborator = (
  workspaceId: string,
  { email, name, roleId }: ApiWorkspaceCollaboratorParams,
) =>
  apiWrapper(
    axios({
      method: 'post',
      url: `${API_URL}/workspace/${workspaceId}/ws_user`,
      data: {
        user_email: email,
        user_name: name,
        workspace_id: workspaceId,
        role_id: roleId,
      },
    }),
  );

export const apiUpdateWorkspaceCollaborator = (
  workspaceId: string,
  collaboratorId: string,
  { roleId }: ApiWorkspaceCollaboratorParams,
) =>
  apiWrapper(
    axios({
      method: 'put',
      url: `${API_URL}/workspace/${workspaceId}/ws_user/${collaboratorId}`,
      data: {
        role_id: roleId,
      },
    }),
  );

export const apiDeleteWorkspaceCollaborator = (
  workspaceId: string,
  collaboratorId: string,
) =>
  apiWrapper(
    axios({
      method: 'delete',
      url: `${API_URL}/workspace/${workspaceId}/ws_user/${collaboratorId}`,
    }),
  );
