import { createSelector } from '@reduxjs/toolkit';

import { imageViewSelector } from '../../../imageView.selectors';

export const imageViewImageGalleryFiltersSelector = createSelector(
  imageViewSelector,
  (imageView) => imageView.imageGallery.bottomBar.filters,
);

export const imageViewImageGalleryFiltersDatasetSelector = createSelector(
  imageViewImageGalleryFiltersSelector,
  (filters) => filters.dataset,
);

export const imageViewImageGalleryFiltersStatusSelector = createSelector(
  imageViewImageGalleryFiltersSelector,
  (filters) => filters.status,
);

export const imageViewImageGalleryFiltersCSRunIdSelector = createSelector(
  imageViewImageGalleryFiltersSelector,
  (filters) => filters.csEfRunId,
);

export const imageViewImageGalleryFiltersCSUserActionSelector = createSelector(
  imageViewImageGalleryFiltersSelector,
  (filters) => filters.csUserAction,
);

export const imageViewImageGalleryFiltersCSErrorTypeSelector = createSelector(
  imageViewImageGalleryFiltersSelector,
  (filters) => filters.csErrorType,
);

export const imageViewImageGalleryFiltersSearchSelector = createSelector(
  imageViewImageGalleryFiltersSelector,
  (filters) => filters.search,
);

export const imageViewImageGalleryFiltersTagsSelector = createSelector(
  imageViewImageGalleryFiltersSelector,
  (filters) => filters.tags,
);

export const imageViewImageGalleryFiltersAldiSessionIdSelector = createSelector(
  imageViewImageGalleryFiltersSelector,
  (filters) => filters.aldiSessionId,
);
