import { call, put, takeEvery, select } from 'typed-redux-saga';

import { apiGetErrorFinderRunCreditsEstimation } from '../../../../../../api/requests/consensusScoring';
import { getErrorMessage } from '../../../../../../api/utils';
import { activeProjectIdSelector } from '../../../../project/project.selectors';
import {
  getRunCreditsEstimation,
  getRunCreditsEstimationSuccess,
  getRunCreditsEstimationFailure,
} from './credits.slice';
import { enqueueNotification } from '../../../../ui/stackNotifications/stackNotifications.slice';

function* loadHandler(action: ActionType<typeof getRunCreditsEstimation>) {
  try {
    const projectId = yield* select(activeProjectIdSelector);
    const { data } = yield* call(
      apiGetErrorFinderRunCreditsEstimation,
      {
        projectId,
      },
      action.payload,
    );
    yield* put(getRunCreditsEstimationSuccess(data));
  } catch (error) {
    const message = getErrorMessage(
      error,
      'Not able to load credits estimation',
    );

    yield* put(getRunCreditsEstimationFailure(message));
    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
        },
      }),
    );
  }
}

export function* runCreditsEstimationSaga() {
  yield* takeEvery(getRunCreditsEstimation, loadHandler);
}
