import {
  errorFinderActionTypeLabels,
  runTypes,
  runTypeLabels,
  errorTypeTypeLabels,
  ErrorType,
} from '../api/domainModels/consensusScoring';
import { ErrorFinderAction, RunType } from '../api/constants/consensusScoring';

const ALL_OPTION = {
  text: 'All',
  key: 'all',
  value: null,
};

const COMMON_ACTION_TYPES = [
  ErrorFinderAction.NotError,
  ErrorFinderAction.None,
];

const CL_ACTION_TYPES = [
  ErrorFinderAction.ChangedClass,
  ErrorFinderAction.ChangedManually,
  ...COMMON_ACTION_TYPES,
];

const mapErrorFinderActionTypes = (actionTypes: ErrorFinderAction[]) =>
  actionTypes.map((item) => ({
    text: errorFinderActionTypeLabels[item],
    key: item,
    value: item,
  }));

const CL_ACTION_TYPES_MISCLASSIFICATION_ERROR_TYPE = [
  ALL_OPTION,
  ...mapErrorFinderActionTypes(CL_ACTION_TYPES),
];

const OD_ACTION_TYPES_MISSING_LABEL_ERROR_TYPE = [
  ALL_OPTION,
  ...mapErrorFinderActionTypes([
    ErrorFinderAction.AddLabel,
    ErrorFinderAction.NotError,
    ErrorFinderAction.None,
  ]),
];

const OD_ACTION_TYPES_EXTRA_LABEL_ERROR_TYPE = [
  ALL_OPTION,
  ...mapErrorFinderActionTypes([
    ErrorFinderAction.DeleteLabel,
    ErrorFinderAction.NotError,
    ErrorFinderAction.None,
  ]),
];

const OD_ACTION_TYPES_LOW_IOU_ERROR_TYPE = [
  ALL_OPTION,
  ...mapErrorFinderActionTypes([
    ErrorFinderAction.ChangedShape,
    ErrorFinderAction.NotError,
    ErrorFinderAction.None,
  ]),
];

export const errorTypeToActionTypeMapper = (
  _errorType: ErrorType | undefined | null,
  isClassReview: boolean,
) => {
  // Currently CL Runs just have Missclassification error type so we can have populated UserActionType filter
  // when All option for CL errorTypes is selected
  const errorType = isClassReview ? ErrorType.Misclassification : _errorType;

  switch (errorType) {
    case ErrorType.MissingLabel:
      return OD_ACTION_TYPES_MISSING_LABEL_ERROR_TYPE;
    case ErrorType.ExtraLabel:
      return OD_ACTION_TYPES_EXTRA_LABEL_ERROR_TYPE;
    case ErrorType.LowIou:
      return OD_ACTION_TYPES_LOW_IOU_ERROR_TYPE;
    case ErrorType.Misclassification:
      return CL_ACTION_TYPES_MISCLASSIFICATION_ERROR_TYPE;
    default:
      return [];
  }
};

export const RUN_TYPES_LIST = runTypes.map((item) => ({
  text: runTypeLabels[item],
  key: item,
  value: item,
}));

export const RUN_TYPES_TOOLTIPS: Record<string, string> = {
  [RunType.ClassReview]: 'Find annotations with the wrong class',
  [RunType.ObjectReview]:
    'Find bounding boxes with the wrong shape, as well as artifacts and missed annotations',
  [RunType.InstanceReview]:
    'Find instances with the wrong shape, as well as artifacts and missed annotations',
  [RunType.TagReview]: 'Find missing, extra and wrong tags on images',
  [RunType.AttributeReview]:
    'Find missing, extra and wrong attributes on labels',
};

export const mapErrorTypes = (errorTypes: ErrorType[]) =>
  errorTypes.map((item) => ({
    text: errorTypeTypeLabels[item],
    key: item,
    value: item,
  }));

const CL_ERROR_TYPES = [ErrorType.Misclassification];
export const OD_ERROR_TYPES = [
  ErrorType.MissingLabel,
  ErrorType.ExtraLabel,
  ErrorType.LowIou,
];

const TAG_ERROR_TYPES = [
  ErrorType.Misclassification,
  ErrorType.MissingTag,
  ErrorType.ExtraTag,
];

const ATTRIBUTE_ERROR_TYPES = [
  ErrorType.Misclassification,
  ErrorType.MissingAttribute,
  ErrorType.ExtraAttribute,
];

const SEMANTIC_ERROR_TYPES = [ErrorType.MissingArea, ErrorType.ExtraArea];

export const CL_ERROR_TYPES_FILTER_LIST = [
  ALL_OPTION,
  ...mapErrorTypes(CL_ERROR_TYPES),
];

export const OD_ERROR_TYPES_FILTER_LIST = [
  ALL_OPTION,
  ...mapErrorTypes(OD_ERROR_TYPES),
];

export const TAG_ERROR_TYPES_FILTER_LIST = [
  ALL_OPTION,
  ...mapErrorTypes(TAG_ERROR_TYPES),
];

export const ATTRIBUTE_ERROR_TYPES_FILTER_LIST = [
  ALL_OPTION,
  ...mapErrorTypes(ATTRIBUTE_ERROR_TYPES),
];

export const SEMANTIC_ERROR_TYPES_FILTER_LIST = [
  ALL_OPTION,
  ...mapErrorTypes(SEMANTIC_ERROR_TYPES),
];

export const LABEL_UPDATED_SINCE_RUN_FILTER_LIST = [
  ALL_OPTION,
  {
    text: 'Yes',
    key: 'true',
    value: 'true',
  },
  {
    text: 'No',
    key: 'false',
    value: 'false',
  },
];

export const NON_ERROR_ACTIONS = [
  ErrorFinderAction.ChangedShape,
  ErrorFinderAction.AddLabel,
  ErrorFinderAction.DeleteLabel,
];

export const getActionForErrorType = (errorType: ErrorType) => {
  switch (errorType) {
    case ErrorType.MissingLabel:
      return ErrorFinderAction.AddLabel;
    case ErrorType.ExtraLabel:
      return ErrorFinderAction.DeleteLabel;
    case ErrorType.LowIou:
      return ErrorFinderAction.ChangedShape;
    default:
      return null;
  }
};

export const getActionLabels = (errorType: ErrorType) => {
  switch (errorType) {
    case ErrorType.MissingLabel:
      return {
        notError: 'Kept current',
        tookAction: 'Added label',
        unmarked: 'Add label',
        tooltipReject: 'Keep current',
        tooltipRejected: 'Kept current',
        modalButtonReject: 'Keep current',
        acceptActionAE: 'Accept. Add label',
        acceptedActionAE: 'Accepted. Added label',
        rejectActionAE: 'Reject. Keep current',
        rejectedActionAE: 'Rejected. Kept current',
      };
    case ErrorType.ExtraLabel:
      return {
        notError: 'Kept current',
        tookAction: 'Removed label',
        unmarked: 'Remove label',
        tooltipReject: 'Keep current',
        tooltipRejected: 'Kept current',
        modalButtonReject: 'Keep current',
        acceptActionAE: 'Accept. Remove label',
        acceptedActionAE: 'Accepted. Removed label',
        rejectActionAE: 'Reject. Keep current',
        rejectedActionAE: 'Rejected. Kept current',
      };
    case ErrorType.LowIou:
      return {
        notError: 'Kept current shape',
        tookAction: 'Shape updated',
        unmarked: 'Update shape',
        tooltipReject: 'Keep current shape',
        tooltipRejected: 'Kept current shape',
        modalButtonReject: 'Keep current shape',
        acceptActionAE: 'Accept. Change shape',
        acceptedActionAE: 'Accepted. Changed shape',
        rejectActionAE: 'Reject. Keep current shape',
        rejectedActionAE: 'Rejected. Kept current shape',
      };
    case ErrorType.MissingArea:
      return {
        notError: 'Kept current area',
        tookAction: 'Area added',
        unmarked: 'Add area',
        tooltipReject: 'Keep current area',
        tooltipRejected: 'Kept current area',
        modalButtonReject: 'Keep current area',
        acceptActionAE: 'Add area',
        acceptedActionAE: 'Accepted. Added area',
        rejectActionAE: 'Not an error',
        rejectedActionAE: 'Rejected. Kept current area',
      };
    case ErrorType.ExtraArea:
      return {
        notError: 'Kept current area',
        tookAction: 'Removed area',
        unmarked: 'Remove area',
        tooltipReject: 'Keep current area',
        tooltipRejected: 'Kept current area',
        modalButtonReject: 'Keep current area',
        acceptActionAE: 'Remove area',
        acceptedActionAE: 'Accepted. Removed area',
        rejectActionAE: 'Not an error',
        rejectedActionAE: 'Rejected. Kept current area',
      };
    default:
      return null;
  }
};

export const PREVIEW_MODE_PERCENTAGE = 0.1;
