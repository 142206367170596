/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DatasetStatistic } from '../models/DatasetStatistic';
import type { ImageStatus } from '../models/ImageStatus';
import type { Job } from '../models/Job';
import type { Job1 } from '../models/Job1';
import type { PaginationMeta } from '../models/PaginationMeta';
import type { Project1 } from '../models/Project1';
import type { Project2 } from '../models/Project2';
import type { VideoStatus } from '../models/VideoStatus';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { OmitReadonly } from '../core/utils/OmitReadonly';

export class ProjectService {

  /**
   * Returns the list of supported formats
   * @returns any Returns the url to exported data
   * @throws ApiError
   */
  public static getExportFormats({
    contentType,
  }: {
    /**
     * Export formats for the media type
     */
    contentType?: 'IMAGES' | 'VIDEOS',
  }): CancelablePromise<Array<{
    /**
     * Export format key
     */
    exportFormat?: string;
    /**
     * Export format name
     */
    name?: string;
  }>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1.0/export_formats',
      query: {
        'content_type': contentType,
      },
    });
  }

  /**
   * Return the supported labeling types
   * @returns any Successful read labeling types
   * @throws ApiError
   */
  public static getApiV10LabelingTypes(): CancelablePromise<Array<{
    name?: string;
    value?: string;
  }>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1.0/labeling_types',
    });
  }

  /**
   * Create a new project
   * @returns Project1 Returns an newly created project
   * @throws ApiError
   */
  public static postApiV10Project({
    requestBody,
  }: {
    requestBody: OmitReadonly<Project1>,
  }): CancelablePromise<Project1> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1.0/project',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Remove project
   * @returns Project1 Returns deleted project
   * @throws ApiError
   */
  public static deleteApiV10Project({
    projectId,
  }: {
    /**
     * Project ID
     */
    projectId: string,
  }): CancelablePromise<Project1> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v1.0/project/{project_id}',
      path: {
        'project_id': projectId,
      },
    });
  }

  /**
   * Return the project info
   * @returns any Successful read the project information
   * @throws ApiError
   */
  public static getApiV10Project({
    projectId,
  }: {
    /**
     * Project ID
     */
    projectId: string,
  }): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1.0/project/{project_id}',
      path: {
        'project_id': projectId,
      },
    });
  }

  /**
   * Edit project
   * @returns Project1 Returns an updated project
   * @throws ApiError
   */
  public static putApiV10Project({
    projectId,
    requestBody,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    requestBody: OmitReadonly<Project1>,
  }): CancelablePromise<Project1> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v1.0/project/{project_id}',
      path: {
        'project_id': projectId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Return the project summary
   * @returns any Successful read the project summary
   * @throws ApiError
   */
  public static getProjectSummary({
    projectId,
  }: {
    /**
     * Project ID
     */
    projectId: string,
  }): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1.0/project/{project_id}/summary',
      path: {
        'project_id': projectId,
      },
    });
  }

  /**
   * Return the list of active users
   * @returns any Successful read the list of projects users
   * @throws ApiError
   */
  public static getApiV10ProjectActiveUsers({
    projectId,
  }: {
    /**
     * Project ID
     */
    projectId: string,
  }): CancelablePromise<Array<{
    /**
     * Role id
     */
    roleId?: number;
    /**
     * User id
     */
    userId?: string;
    /**
     * Project collaborator id (User id)
     */
    username?: string;
    /**
     * HTML color for profile avatar
     */
    color?: string;
  }>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1.0/project/{project_id}/active_users',
      path: {
        'project_id': projectId,
      },
    });
  }

  /**
   * Return the project dataset/label class statistics
   * @returns DatasetStatistic Successful read dataset/label class statistics
   * @throws ApiError
   */
  public static getDatasetLabelStats({
    projectId,
  }: {
    /**
     * Project ID
     */
    projectId: string,
  }): CancelablePromise<Array<DatasetStatistic>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1.0/project/{project_id}/dataset_label_stats',
      path: {
        'project_id': projectId,
      },
    });
  }

  /**
   * Return the project settings
   * @returns any Returns an array of project settings
   * @throws ApiError
   */
  public static getApiV10ProjectProjectSetting({
    projectId,
  }: {
    /**
     * Project ID
     */
    projectId: string,
  }): CancelablePromise<Array<{
    /**
     * Setting name
     */
    settingName?: string;
    /**
     * Setting value
     */
    settingValue?: string;
  }>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1.0/project/{project_id}/project_setting',
      path: {
        'project_id': projectId,
      },
    });
  }

  /**
   * Return the setting value
   * @returns any Return the setting value
   * @throws ApiError
   */
  public static getApiV10ProjectProjectSetting1({
    projectId,
    settingName,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Project setting name
     */
    settingName: string,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1.0/project/{project_id}/project_setting/{setting_name}',
      path: {
        'project_id': projectId,
        'setting_name': settingName,
      },
    });
  }

  /**
   * Set setting
   * @returns any Return the setting value
   * @throws ApiError
   */
  public static postApiV10ProjectProjectSetting({
    projectId,
    settingName,
    requestBody,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Project setting name
     */
    settingName: string,
    requestBody: {
      /**
       * Setting value as json
       */
      setting_value: any;
    },
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1.0/project/{project_id}/project_setting/{setting_name}',
      path: {
        'project_id': projectId,
        'setting_name': settingName,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Change project workspace
   * @returns Project1 Returns an updated project
   * @throws ApiError
   */
  public static putApiV10ProjectWorkspace({
    projectId,
    requestBody,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    requestBody: {
      workspace_id: string;
    },
  }): CancelablePromise<Project1> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v1.0/project/{project_id}/workspace',
      path: {
        'project_id': projectId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Return the list of projects
   * @returns Project1 Successful read the list of project
   * @throws ApiError
   */
  public static getApiV10Projects(): CancelablePromise<Array<Project1>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1.0/projects',
    });
  }

  /**
   * Duplicate project
   * @returns Job Returns the job information
   * @throws ApiError
   */
  public static postV1ProjectsDuplicate({
    projectId,
    requestBody,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    requestBody: {
      /**
       * Name of the new project
       */
      name: string;
      /**
       * Whether labels need to be copied
       */
      copy_labels?: boolean;
      /**
       * ID of the workspace to copy to
       */
      workspace_id?: string;
    },
  }): CancelablePromise<Job> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/projects/{project_id}/duplicate',
      path: {
        'project_id': projectId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Fetch a job
   * @returns Job Returns the job information
   * @throws ApiError
   */
  public static getV1Jobs({
    jobId,
  }: {
    /**
     * Job ID
     */
    jobId: string,
  }): CancelablePromise<Job> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/jobs/{job_id}',
      path: {
        'job_id': jobId,
      },
    });
  }

  /**
   * Create a new project
   * @returns Project2 Returns the created project
   * @throws ApiError
   */
  public static projectsCreate({
    requestBody,
  }: {
    requestBody: OmitReadonly<Project2>,
  }): CancelablePromise<Project2> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/projects',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Fetch the list of projects
   * @returns any Successful read of the list of projects
   * @throws ApiError
   */
  public static projectsList({
    offset,
    limit,
    name,
  }: {
    /**
     * Offset for this paginated query
     */
    offset?: number,
    /**
     * Limit for this paginated query
     */
    limit?: number,
    /**
     * Filter projects which contain name (case-insensitive)
     */
    name?: string,
  }): CancelablePromise<{
    meta: PaginationMeta;
    items: Array<Project2>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/projects',
      query: {
        'offset': offset,
        'limit': limit,
        'name': name,
      },
    });
  }

  /**
   * Delete an existing project
   * @returns void
   * @throws ApiError
   */
  public static projectsDelete({
    projectId,
  }: {
    /**
     * Project ID
     */
    projectId: string,
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/v1/projects/{project_id}',
      path: {
        'project_id': projectId,
      },
    });
  }

  /**
   * Fetch existing project
   * @returns Project2 Successful read of project info
   * @throws ApiError
   */
  public static projectsGet({
    projectId,
  }: {
    /**
     * Project ID
     */
    projectId: string,
  }): CancelablePromise<Project2> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/projects/{project_id}',
      path: {
        'project_id': projectId,
      },
    });
  }

  /**
   * Edit an existing project
   * @returns Project2 Returns the updated project
   * @throws ApiError
   */
  public static projectsUpdate({
    projectId,
    requestBody,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    requestBody: OmitReadonly<Project2>,
  }): CancelablePromise<Project2> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/v1/projects/{project_id}',
      path: {
        'project_id': projectId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Initiates project export
   * @returns Job1 Returns export job information
   * @throws ApiError
   */
  public static postV1ProjectsExports({
    projectId,
    requestBody,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    requestBody: {
      /**
       * Export format. Videos projects only support json_v1.1
       */
      format: 'json_v1.1' | 'semantic_png' | 'json_coco' | 'pascal' | 'videos';
      /**
       * List of dataset IDs to be filtered
       */
      dataset_id?: Array<string>;
      /**
       * List of video statuses to be filtered. This is only in effect for video projects
       */
      video_status?: Array<VideoStatus>;
      /**
       * List of image statuses to be filtered. This is only in effect for image projects
       */
      image_status?: Array<ImageStatus>;
      /**
       * Whether to add signed URLs for publicly accessing images/videos
       */
      sign_urls?: boolean;
      /**
       * Exported filename
       */
      export_name?: string;
      /**
       * Semantic pixel encoding format
       */
      semantic_format?: 'gs_asc' | 'gs_desc' | 'class_color' | null;
      /**
       * The order in which the labels should be drawn. Only applicable for images
       */
      labels_order?: Array<'z_index' | 'class_type' | 'class_order'> | null;
    },
  }): CancelablePromise<Job1> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/projects/{project_id}/exports',
      path: {
        'project_id': projectId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Fetch a job
   * @returns Job1 Returns the job information
   * @throws ApiError
   */
  public static getV1ProjectsExports({
    projectId,
    exportId,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Export job ID
     */
    exportId: string,
  }): CancelablePromise<Job1> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/projects/{project_id}/exports/{export_id}',
      path: {
        'project_id': projectId,
        'export_id': exportId,
      },
    });
  }

}
