import { put, select, takeEvery } from 'typed-redux-saga';

import { hideLabelClass, hideSomeLabelClasses } from './hiddenClasses.slice';
import { deselectLabels } from '../labels/selectedLabels/selectedLabels.slice';
import { selectedLabelsSelector } from '../labels/selectedLabels/selectedLabels.selectors';

function* hideLabelClassHandler(
  action: ActionType<typeof hideLabelClass | typeof hideSomeLabelClasses>,
) {
  const LabelClassesId = Array.isArray(action.payload)
    ? action.payload
    : [action.payload];
  const selectedLabelsData = yield* select(selectedLabelsSelector);

  const labelsToExclude = selectedLabelsData
    .filter((selectedLabel) =>
      LabelClassesId.includes(selectedLabel.classId || ''),
    )
    .map((selectedLabel) => selectedLabel.id);

  yield* put(deselectLabels(labelsToExclude));
}

export function* hiddenClassIdsSaga() {
  yield* takeEvery(
    [hideLabelClass, hideSomeLabelClasses],
    hideLabelClassHandler,
  );
}
