import {
  createAction,
  createEntityAdapter,
  createSlice,
  PayloadAction,
  Update,
} from '@reduxjs/toolkit';
import undoable from 'redux-undo';
import shortid from 'shortid';

import { ImageLabel } from '../../../../../api/domainModels/imageLabel';
import { confirmImageId } from '../currentImage/currentImage.slice';

export const adapter = createEntityAdapter<ImageLabel>({
  sortComparer: (a, b) => (a.zIndex > b.zIndex ? 1 : -1),
});

type UpdatePayloadAction = PayloadAction<
  Array<Update<ImageLabel>>,
  string,
  {
    undoGroup?: 'move' | 'resize' | 'genericUpdate';
    selectPostCreation?: boolean;
  } | void
>;
const { reducer, actions } = createSlice({
  name: 'imageViewNewLabels',
  initialState: adapter.getInitialState(),
  reducers: {
    initializeLabels(state, action: PayloadAction<ImageLabel[]>) {
      adapter.setAll(state, action.payload);
    },
    addLabels(
      state,
      action: PayloadAction<Array<Partial<ImageLabel> & { id: string }>>,
    ) {
      const labels = Object.values(state.entities);
      const availableLabels = labels.filter(
        (label) => label && !label.isDeleted,
      );
      const { length } = availableLabels;
      const zIndex =
        length === 0 ? 0 : (availableLabels[length - 1]?.zIndex || 0) + 1;

      adapter.addMany(
        state,
        // @TODO: bully backend into better typing
        action.payload.map((label) => ({
          ...label,
          externalEventId: shortid(),
          createdOn: new Date().toISOString(),
          updatedOn: new Date().toISOString(),
          zIndex: label.zIndex ?? zIndex,
        })) as ImageLabel[],
      );
    },
    upsertLabel(state, action: PayloadAction<ImageLabel>) {
      adapter.upsertOne(state, action.payload);
    },
    deleteLabels(state, action: PayloadAction<Array<ImageLabel['id']>>) {
      adapter.updateMany(
        state,
        action.payload.map((id) => ({
          id,
          changes: {
            isDeleted: true,
            externalEventId: shortid(),
            updatedOn: new Date().toISOString(),
          },
        })),
      );
    },
    updateLabels: {
      reducer: (state, action: UpdatePayloadAction) => {
        adapter.updateMany(
          state,
          action.payload.map(({ id, changes }) => ({
            id,
            changes: {
              ...changes,
              externalEventId: shortid(),
              updatedOn: new Date().toISOString(),
            },
          })),
        );
      },
      prepare(
        payload: UpdatePayloadAction['payload'],
        meta: UpdatePayloadAction['meta'] | never,
      ) {
        return {
          payload,
          meta,
        };
      },
    },
  },
});

export const {
  initializeLabels,
  addLabels,
  deleteLabels,
  updateLabels,
  upsertLabel,
} = actions;

export const undoLabels = createAction('imageViewLabels/undoLabels');
export const redoLabels = createAction('imageViewLabels/redoLabels');

export const LABEL_MUTATING_ACTION_TYPES = [
  addLabels,
  deleteLabels,
  updateLabels,
  undoLabels,
  redoLabels,
].map((a) => a.type);

export const labelsReducer = undoable(reducer, {
  limit: 20,
  undoType: undoLabels.type,
  redoType: redoLabels.type,
  initTypes: ['@@redux-undo/INIT', confirmImageId.type],
  ignoreInitialState: true,
  clearHistoryType: initializeLabels.type,
  neverSkipReducer: true,
  groupBy: (action) => {
    if (updateLabels.match(action) && action.meta && action.meta.undoGroup) {
      return `${action.meta.undoGroup}::${action.payload
        .map(({ id }) => id)
        .join(',')}`;
    }

    return null;
  },
});
