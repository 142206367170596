/* eslint-disable no-bitwise,no-mixed-operators,function-paren-newline */
import camelCase from 'lodash/camelCase';

import { Project } from '../../api/domainModels/project';

export const camelToSnakeCase = (string: string) => {
  if (string === 'box2mask' || string === 'txt2mask') {
    return string;
  }

  return string.replace(/[0-9A-Z]/g, (match) => `_${match.toLowerCase()}`);
};

export const convertBackendObject = <T>(backendObject: T): T => {
  if (backendObject instanceof Array) {
    return backendObject.map(convertBackendObject) as T;
  }

  if (backendObject instanceof Object) {
    return Object.entries(backendObject).reduce((result, [key, value]) => {
      result[camelCase(key)] =
        typeof value === 'object' && value !== null
          ? convertBackendObject(value as T)
          : value;

      return result;
    }, {} as Record<string, unknown>) as T;
  }

  return backendObject as T;
};

export const convertToBackendObject = <T>(object: T): T => {
  if (object instanceof Array) {
    return object.map(convertToBackendObject) as T;
  }

  if (object instanceof Object) {
    return Object.entries(object).reduce((result, [key, value]) => {
      const newKey = camelToSnakeCase(key);
      result[newKey] = convertToBackendObject(value);

      return result;
    }, {} as Record<string, unknown>) as T;
  }

  return object;
};

export const convertToBackendParams = (
  backendParams: Record<
    string,
    string[] | number[] | string | number | null | undefined
  >,
) =>
  Object.entries(backendParams).reduce((result, [key, value]) => {
    const newKey = camelToSnakeCase(key);

    if (Array.isArray(value) && value.length) {
      result[newKey] = value.join(',');
    } else if (value) {
      result[newKey] = value as string | number | null | undefined;
    }

    return result;
  }, {} as Record<string, string | number | null | undefined>);

export const getProjectById = (projects: Project[], id: string) =>
  projects.find((project) => project.projectId === id);
