/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Project1 = {
  /**
   * Average video time
   */
  avgVideoTime?: string | null;
  createDate?: string;
  description?: string | null;
  domainId: number | null;
  labelingType?: Project1.labelingType | null;
  /**
   * Total number of images in AUTO-LABELLED status
   */
  numberOfAutoLabeledImages?: number;
  /**
   * Total number of in progress images
   */
  numberOfInProgressImages?: number;
  /**
   * Total number of labeled images
   */
  numberOfLabeledImages?: number;
  /**
   * Total number of labels
   */
  numberOfLabels?: number | null;
  /**
   * Total number of skipped images
   */
  numberOfSkippedImages?: number;
  /**
   * Total number of images in TO REVIEW status
   */
  numberOfToReviewImages?: number;
  ownerId?: string;
  projectId?: string;
  /**
   * Image ID that was used for thumbnail
   */
  projectImageId?: string | null;
  /**
   * Video ID that was used for thumbnail
   */
  projectVideoId?: string | null;
  projectName: string;
  /**
   * Total number of images in project
   */
  totalNumberOfImages?: number;
  /**
   * Workspace id that the project belongs to
   */
  workspaceId?: string;
  /**
   * Workspace name that  project is belongs to
   */
  workspaceName?: string | null;
  /**
   * Authentication key for updates subscription via websocket
   */
  wsAuthKey?: string;
  /**
   * Authentication key for cord collaboration
   */
  cordAuthKey?: string | null;
  isPro?: boolean;
  demoProject?: boolean;
  /**
   * Content type (images or videos)
   */
  contentType?: Project1.contentType;
  /**
   * Total number of videos in project
   */
  totalNumberOfVideos?: number;
  /**
   * Total number of in progress videos
   */
  numberOfInProgressVideos?: number;
  /**
   * Total number of new videos
   */
  numberOfNewVideos?: number;
  /**
   * Total number of skipped videos
   */
  numberOfSkippedVideos?: number;
  /**
   * Total number of labeled videos
   */
  numberOfLabeledVideos?: number;
  /**
   * Total number of to review videos
   */
  numberOfToReviewVideos?: number;
  /**
   * Total number of segments in project
   */
  numberOfSegments?: number;
  /**
   * Total number of activities in project
   */
  numberOfActivities?: number;
};

export namespace Project1 {

  export enum labelingType {
    OBJECT_DETECTION = 'OBJECT_DETECTION',
    OBJECT_SEGMENTATION = 'OBJECT_SEGMENTATION',
  }

  /**
   * Content type (images or videos)
   */
  export enum contentType {
    IMAGES = 'IMAGES',
    VIDEOS = 'VIDEOS',
  }


}

