import { call, put, select } from 'typed-redux-saga';
import { Action, Selector } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import { getErrorMessage } from '../../../../../../api/utils';
import { handleError } from '../../../../commonFeatures/errorHandler/errorHandler.actions';
import { activeProjectIdSelector } from '../../../../project/project.selectors';
import { ModelStatus, modelStatusChecks } from './modelStatus.constants';
import { ImageTool } from '../tools.constants';
import { advancedOptionsModalUseIESelector } from '../../../../sections/editedProject/advancedOptions/advancedOptions.selectors';
import { VideoTool } from '../../../videoView/tools/tools.constants';

export type LoadModelParams = {
  projectId: string;
  loadApiCall: (projectId: string) => Generator<unknown, AxiosResponse | void>;
  messages: {
    availability: string;
    error: string;
    progress: string;
  };
  toolId: ImageTool | VideoTool;
  modelIcon?: React.ComponentType;
  modelLoadStartAction: (message: string) => Action;
  modelLoadSuccessAction: () => Action;
  toolName: string;
  modelLoadErrorAction: (message: string) => Action;
};
export function* loadModelHandler(params: LoadModelParams) {
  const {
    loadApiCall,
    messages,
    projectId,
    modelLoadStartAction,
    modelLoadSuccessAction,
    modelLoadErrorAction,
  } = params;

  yield* put(modelLoadStartAction(messages.progress));

  try {
    yield* call(loadApiCall, projectId);
    yield* put(modelLoadSuccessAction());
  } catch (error) {
    const errorMessage = getErrorMessage(error, messages.error);

    yield* put(modelLoadErrorAction(errorMessage));
    yield* put(handleError({ message: errorMessage, error }));
  }
}

type ModelLoadedParams = {
  id: number;
  modelIdSelector: Selector<RootState, number | null>;
  modelLoadedAction?: () => Action;
  modelLoadedSelector: Selector<RootState, boolean>;
  progress: number;
  status: ModelStatus;
  modelUseIE?: boolean;
  updateAction: ({
    projectId,
    status,
    progress,
    id,
  }: {
    projectId?: string;
    status: ModelStatus;
    progress: number;
    id: number;
    modelUseIE?: boolean;
  }) => Action;
  updateModelIndicator?: (showDot: boolean) => Action;
};

export function* modelLoadedHandler({
  status,
  progress,
  id,
  updateAction,
  modelLoadedAction,
  modelLoadedSelector,
  modelIdSelector,
  updateModelIndicator,
  modelUseIE,
}: ModelLoadedParams) {
  const projectUseIE = yield* select(advancedOptionsModalUseIESelector);

  // Pre-trained models (Atom/BTI) are being migrated to Inference Engine service
  // Just for these models we are forwarding modelUseIE from their sagas (ws updates) to here
  // For the rest (OD/IS/SES/Class/Tag/Attributer models) modelUseIE is undefined and no need to check it
  // In the future rest could be (potentially) migrated to IE as well, so just forward modelUseIE from their sagas
  if (modelUseIE !== undefined && projectUseIE !== modelUseIE) {
    return;
  }

  yield* put(
    updateAction({
      status,
      progress,
      id,
      modelUseIE,
    }),
  );

  const loaded = yield* select(modelLoadedSelector);
  const modelId = yield* select(modelIdSelector);

  if (updateModelIndicator && (!loaded || modelId !== id)) {
    yield* put(updateModelIndicator(true));
  }

  if (modelLoadedAction) {
    yield* put(modelLoadedAction());
  }
}

type UpdateModelParams = {
  id: number;
  loadAction: (projectId: string) => Action;
  progress: number;
  status: ModelStatus;
  modelUseIE?: boolean;
  successAction: (data: {
    status: ModelStatus;
    progress: number | null;
    id: number | null;
  }) => Action;
};

export function* updateModelHandler({
  id,
  loadAction,
  progress,
  status,
  successAction,
  modelUseIE,
}: UpdateModelParams) {
  const projectId = yield* select(activeProjectIdSelector);
  if (!projectId) return;

  const projectUseIE = yield* select(advancedOptionsModalUseIESelector);

  // Pre-trained models (Atom/BTI) are being migrated to Inference Engine service
  // Just for these models we are forwarding modelUseIE from their sagas (ws updates) to here
  // For the rest (OD/IS/SES/Class/Tag/Attributer models) modelUseIE is undefined and no need to check it
  // In the future rest could be (potentially) migrated to IE as well, so just forward modelUseIE from their sagas
  if (modelUseIE !== undefined && projectUseIE !== modelUseIE) {
    return;
  }

  yield* put(
    successAction({
      status,
      progress: progress || null,
      id: modelStatusChecks.isLoaded(status) ? id : null,
    }),
  );

  if (modelStatusChecks.isTrained(status)) {
    yield* put(loadAction(projectId));
  }
}
