import { createSelector } from '@reduxjs/toolkit';

import {
  isFreeOrParkedPackage,
  isFreePackage,
} from '../../../../constants/packageFeatures';
import { workspacesListDataSelector } from '../projectList/workspaces/workspaces.selectors';
import { workspaceIdSelector } from '../../commonFeatures/workspaceId/workspaceId.selectors';
import { sectionsSelector } from '../sections.selectors';

export const managedWorkspaceDataSelector = createSelector(
  [workspacesListDataSelector, workspaceIdSelector],
  (workspaces, workspaceId) => {
    if (workspaces.length === 0) {
      return null;
    }

    const currentWorkspace = workspaces.find(
      (workspace) => workspace.workspaceId === workspaceId,
    );
    if (currentWorkspace instanceof Object) return currentWorkspace;

    return null;
  },
);
export const managedWorkspaceIsFreeSelector = createSelector(
  managedWorkspaceDataSelector,
  (data) => (data ? isFreePackage(data.subscription) : false),
);
export const managedWorkspaceIsPaidSelector = createSelector(
  managedWorkspaceDataSelector,
  (data) => (data ? !isFreeOrParkedPackage(data.subscription) : false),
);
export const managedWorkspaceSelector = createSelector(
  sectionsSelector,
  (sections) => sections.managedWorkspace,
);
