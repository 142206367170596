import RBush from 'rbush';
import { KonvaEventObject } from 'konva/lib/Node';

import { ImageLabel, KeypointsPoint } from '../../api/domainModels/imageLabel';
import { calculateBbox } from '../../api/domainModels/utils';

const getBboxForKeypointLabel = (keypoints: KeypointsPoint[]) =>
  calculateBbox(
    keypoints.map((keypoint) => [keypoint.x, keypoint.y]).flat(),
    true,
  );

const compareLabelsPosition = (a: ImageLabel, b: ImageLabel, index: 0 | 1) => {
  let aBbox = a.bbox;
  let bBbox = b.bbox;

  if (!aBbox && a.keypoints) {
    aBbox = getBboxForKeypointLabel(a.keypoints);
  }
  if (!bBbox && b.keypoints) {
    bBbox = getBboxForKeypointLabel(b.keypoints);
  }

  return aBbox[index] - bBbox[index];
};

export class MyRBush extends RBush<ImageLabel> {
  toBBox(label: ImageLabel) {
    let { bbox } = label;

    if (!bbox && label.keypoints) {
      bbox = getBboxForKeypointLabel(label.keypoints);
    }

    return { minX: bbox[0], minY: bbox[1], maxX: bbox[2], maxY: bbox[3] };
  }

  compareMinX(a: ImageLabel, b: ImageLabel) {
    return compareLabelsPosition(a, b, 0);
  }

  compareMinY(a: ImageLabel, b: ImageLabel) {
    return compareLabelsPosition(a, b, 1);
  }
}

export const isLeftMouseButtonPressed = (
  evt: KonvaEventObject<MouseEvent>['evt'],
) => evt.button === 0 && !evt.ctrlKey;

export const isRightMouseButtonPressed = (
  evt: KonvaEventObject<MouseEvent>['evt'],
) => evt.button === 2 && !evt.ctrlKey;

const IMAGE_DATA_FALLBACK_VALUE = { width: 1, height: 1 };

export const retrieveImageData = (i = 0) => window.imageData[i];

export const retrieveImageDataWithFallback = (i = 0) => {
  const data = retrieveImageData(i) || IMAGE_DATA_FALLBACK_VALUE;

  return { width: data.width, height: data.height };
};

export const retrieveObject = (id: number | null) => {
  if (id !== null) {
    return window.objectStorage[id];
  }
};
