import { put, select, takeEvery, all } from 'typed-redux-saga';
import qs from 'query-string';
import omit from 'lodash/omit';

import {
  applyFilters,
  applyMultipleFilters,
  setMultipleFilters,
  FiltersStateType,
  initializeFilters,
  resetFilters,
  setFilters,
  FilterType,
  setFiltersSilent,
} from './filters.slice';
import { resetProjectId, setProjectId } from '../../../project/project.slice';
import { activeProjectIdSelector } from '../../../../../project/project.selectors';
import { imageViewImageGalleryFiltersSelector } from './filters.selectors';
import { setUrlSearchParams } from '../../../../../commonFeatures/urlSearchParams/urlSearchParams.actions';
import { routerSelector } from '../../../../../root.selectors';
import {
  getLocalStorageItem,
  upsertLocalStorageItem,
} from '../../../../../../../helpers/localStorage';
import { FILTERS_STORAGE_PREFIX } from '../../../../../../../helpers/localStorage/constants';

type FiltersObject = {
  [projectId: string]: FiltersStateType | null;
};

export const getStoredFilters = (): FiltersObject | null => {
  const oldFiltersStorageValue = getLocalStorageItem<FiltersObject>(
    FILTERS_STORAGE_PREFIX,
    {},
  );

  return oldFiltersStorageValue || null;
};

const removeStoredFiltersForProject = (projectId: string) => {
  const filtersStorageValue = getLocalStorageItem<FiltersObject>(
    FILTERS_STORAGE_PREFIX,
    {},
  );

  const newFiltersData = omit(filtersStorageValue, projectId);

  if (newFiltersData) {
    upsertLocalStorageItem(FILTERS_STORAGE_PREFIX, newFiltersData);
  }
};

function* setProjectIdHandler() {
  const projectId = yield* select(activeProjectIdSelector);

  const {
    location: { search },
  } = yield* select(routerSelector);
  const urlFilters = omit(qs.parse(search), 'label');

  if (Object.keys(urlFilters).length) {
    removeStoredFiltersForProject(projectId);
    yield* put(resetFilters());
    yield* put(applyMultipleFilters(urlFilters));

    return;
  }

  const storedFilters = getStoredFilters();
  const projectStoredFilters = storedFilters ? storedFilters[projectId] : null;

  if (projectStoredFilters) {
    yield* put(setUrlSearchParams(projectStoredFilters));
    yield* put(initializeFilters(projectStoredFilters));
  } else {
    yield* put(resetFilters());
  }
}

function* applyFiltersHandler(action: ReturnType<typeof applyFilters>) {
  const filters = yield* select(imageViewImageGalleryFiltersSelector);
  const projectId = yield* select(activeProjectIdSelector);
  const { filter, value } = action.payload;

  // in case of changing active run - reset other filters
  const resetFilters =
    filter === FilterType.CSEFRunId && value !== null
      ? {
          [FilterType.CSErrorType]: null,
        }
      : {};

  const storedFilters = getStoredFilters();
  const storageValue = {
    ...storedFilters,
    [projectId]: {
      ...filters,
      [filter]: value,
      ...resetFilters,
    },
  };

  upsertLocalStorageItem(FILTERS_STORAGE_PREFIX, storageValue);

  yield* all(
    Object.keys(resetFilters).map(function* (key) {
      const filter = key as FilterType;

      yield* put(setFiltersSilent({ filter, value: null }));
    }),
  );

  yield* put(setFilters(action.payload));

  yield* put(
    setUrlSearchParams({
      ...filters,
      [filter]: value,
      ...resetFilters,
    } as any),
  );
}

function* applyMultipleFiltersHandler(
  action: ActionType<typeof applyMultipleFilters>,
) {
  const filters = Object.entries(action.payload)
    .filter(([, value]) => typeof value === 'string')
    .filter(([key]) =>
      Object.values(FilterType).includes(key as FilterType),
    ) as [FilterType, string][];

  yield* put(setMultipleFilters(filters));
}

function* resetProjectIdHandler() {
  yield* put(resetFilters());
}

export function* filtersSaga() {
  yield* takeEvery(setProjectId, setProjectIdHandler);
  yield* takeEvery(resetProjectId, resetProjectIdHandler);
  yield* takeEvery(applyFilters, applyFiltersHandler);
  yield* takeEvery(applyMultipleFilters, applyMultipleFiltersHandler);
}
