import { AnyAction } from '@reduxjs/toolkit';

// These constants have to be in sync with backend
export enum VideoTool {
  Default = 'default',
  SegmentPrediction = 'activity-recognizer',
}

export const VideoToolTitleMap: Record<VideoTool, string> = {
  [VideoTool.Default]: 'Default',
  [VideoTool.SegmentPrediction]: 'Segment predictor',
};

export type VideoToolName = keyof typeof VideoToolTitleMap;
export type SidebarTool = {
  name: VideoToolName;
  largeIcon?: boolean;
  customHandler?: () => AnyAction;
};

type SidebarGroup = {
  groupTitle: string;
  groupAvailabilityConditions?: ((state: RootState) => boolean)[];
  groupTools: Array<SidebarTool>;
};

export const GROUPED_TOOLS: SidebarGroup[] = [
  {
    groupTitle: 'AI assistants',
    groupTools: [
      {
        name: VideoTool.SegmentPrediction,
        largeIcon: true,
      },
    ],
  },
];
