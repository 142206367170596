import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import {
  WidgetClassificationInferencesItem,
  WidgetClassificationInferencesMeta,
  ClassificationInferencesParams,
} from '../../../../../../../api/domainModels/widgets';
import { loadingStateBuilder } from '../../../../../../utils/loadingState';
import { setEditedProjectId } from '../../../../../sections/editedProject/project/project.slice';

type WidgetClassificationInference = WidgetClassificationInferencesItem & {
  id: string;
};

export const itemsAdapter =
  createEntityAdapter<WidgetClassificationInference>();

const initialState = {
  loadingState: loadingStateBuilder.initial(),
  items: itemsAdapter.getInitialState(),
  meta: null as WidgetClassificationInferencesMeta | null,
  offset: 0,
  limit: 0,
};

const { actions, reducer: classificationInferencesReducer } = createSlice({
  name: 'widgets/classificationInferences',
  initialState,
  reducers: {
    loadClassificationInferences(
      state,
      action: PayloadAction<Partial<ClassificationInferencesParams>>,
    ) {
      state.loadingState = loadingStateBuilder.inProgress();
      if (action.payload.offset) {
        state.offset = action.payload.offset;
      }
      if (action.payload.limit) {
        state.limit = action.payload.limit;
      }
    },
    loadClassificationInferencesFailure(state, action: PayloadAction<string>) {
      itemsAdapter.removeAll(state.items);
      state.loadingState = loadingStateBuilder.failure(action.payload);
    },
    loadClassificationInferencesSuccess(
      state,
      action: PayloadAction<{
        items: WidgetClassificationInference[];
        meta: WidgetClassificationInferencesMeta;
      }>,
    ) {
      itemsAdapter.removeAll(state.items);
      const { items, meta } = action.payload;
      itemsAdapter.setAll(state.items, items);
      state.meta = meta;
      state.loadingState = loadingStateBuilder.success();
    },
    resetWidget() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setEditedProjectId, () => initialState);
  },
});

export { classificationInferencesReducer };
export const {
  loadClassificationInferences,
  loadClassificationInferencesSuccess,
  loadClassificationInferencesFailure,
  resetWidget,
} = actions;
