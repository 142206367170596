import {
  createSlice,
  PayloadAction,
  EntityState,
  createEntityAdapter,
} from '@reduxjs/toolkit';

import {
  LoadingState,
  loadingStateBuilder,
} from '../../../../../utils/loadingState';
import {
  ModelStatus,
  modelStatusChecks,
  statusCheck,
  ToolModel,
} from '../models/modelStatus.constants';
import {
  OBJECT_DETECTION_DEFAULT_CONFIDENCE,
  OBJECT_DETECTION_DEFAULT_MAX_DETECTIONS,
} from './objectDetection.constants';

export type DetectedObject = {
  bbox: [number, number, number, number];
  classId: string;
  id: string;
  score: number;
};
type ObjectDetectionState = {
  confidence: number;
  data: EntityState<DetectedObject> & {
    loadingState: LoadingState;
  };
  maxDetections: number;
  model: ToolModel;
};
export const adapter = createEntityAdapter<DetectedObject>();
const initialState: ObjectDetectionState = {
  data: adapter.getInitialState({
    loadingState: loadingStateBuilder.initial(),
  }),
  confidence: OBJECT_DETECTION_DEFAULT_CONFIDENCE,
  maxDetections: OBJECT_DETECTION_DEFAULT_MAX_DETECTIONS,
  model: {
    id: null,
    loadingState: loadingStateBuilder.initial(),
    status: null,
    progress: null,
    showDot: false,
  },
};
const { actions, reducer: objectDetectionReducer } = createSlice({
  name: 'imageViewObjectDetection',
  initialState,
  reducers: {
    addLabel(_state, _action: PayloadAction<string /* id */>) {},
    rejectLabel(state, action: PayloadAction<string /* id */>) {
      adapter.removeOne(state.data, action.payload);
    },
    confirmAddLabel(state, action: PayloadAction<string /* id */>) {
      adapter.removeOne(state.data, action.payload);
    },
    addObjectDetectionLabels() {},
    adjustConfidence(_state, _action: PayloadAction<boolean>) {},
    adjustMaxDetections(_state, _action: PayloadAction<boolean>) {},
    confirmMaxDetections(state, action: PayloadAction<number>) {
      state.maxDetections = action.payload;
    },
    loadObjectDetectionModel(_state, _action: PayloadAction<string>) {},
    loadObjectDetectionModelError(state, action: PayloadAction<string>) {
      state.model.loadingState = loadingStateBuilder.failure(action.payload);
    },
    loadObjectDetectionModelStart(state, action: PayloadAction<string>) {
      state.model.loadingState = loadingStateBuilder.inProgress(action.payload);
    },
    loadObjectDetectionModelSuccess(state) {
      state.model.loadingState = loadingStateBuilder.success();
    },
    loadObjects(state) {
      state.data.loadingState =
        loadingStateBuilder.inProgress('Predicting objects');
    },
    loadObjectsError(state, action: PayloadAction<string>) {
      state.data.loadingState = loadingStateBuilder.failure(action.payload);
    },
    loadObjectsSuccess(state, action: PayloadAction<DetectedObject[]>) {
      state.data.loadingState = loadingStateBuilder.success();
      adapter.setAll(state.data, action.payload);
    },
    resetData(state) {
      state.data = initialState.data;
    },
    resetModel(state) {
      state.model = initialState.model;
    },
    setConfidence(state, action: PayloadAction<number>) {
      state.confidence = action.payload;
    },
    resetConfidence(state) {
      state.confidence = initialState.confidence;
    },
    setMaxDetections(_state, _action: PayloadAction<number>) {},
    resetMaxDetections(_state) {},
    updateObjectDetectionModel(
      _state,
      _action: PayloadAction<{
        status: ModelStatus;
        progress: number;
        id: number;
        modelUseIE?: boolean;
      }>,
    ) {},
    updateObjectDetectionModelSuccess(
      state,
      action: PayloadAction<{
        id: number | null;
        status: ModelStatus;
        progress: number | null;
      }>,
    ) {
      const { id, status, progress } = action.payload;

      state.model = {
        ...state.model,
        id: modelStatusChecks.isLoaded(status) ? id : state.model.id,
        status: statusCheck(status, state.model.status),
        progress: progress || 0,
      };
    },
    updateObjectDetectionModelShowDot(state, action: PayloadAction<boolean>) {
      const showDot = action.payload;

      state.model = {
        ...state.model,
        showDot,
      };
    },
  },
});

export { objectDetectionReducer };
export const {
  addLabel,
  rejectLabel,
  confirmAddLabel,
  addObjectDetectionLabels,
  adjustConfidence,
  adjustMaxDetections,
  confirmMaxDetections,
  loadObjectDetectionModel,
  loadObjectDetectionModelError,
  loadObjectDetectionModelStart,
  loadObjectDetectionModelSuccess,
  loadObjects,
  loadObjectsError,
  loadObjectsSuccess,
  resetData,
  resetModel,
  setConfidence,
  resetConfidence,
  setMaxDetections,
  resetMaxDetections,
  updateObjectDetectionModel,
  updateObjectDetectionModelSuccess,
  updateObjectDetectionModelShowDot,
} = actions;
