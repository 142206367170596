import { AxiosResponse } from 'axios';
import { all, call, put, select, takeEvery } from 'typed-redux-saga';
import qs from 'query-string';

import { apiUpdateLabels } from '../../../../../api/requests/imageLabels';
import {
  apiLoadErrorLabels,
  apiChangeErrorLabelAction,
  apiLoadErrorLabelsBlobs,
} from '../../../../../api/requests/consensusScoring';
import {
  errorFinderLabelDataMapper,
  EnrichedErrorClLabel,
  EnrichedErrorOdIsLabel,
  ErrorType,
  ErrorClLabel,
  ErrorOdIsLabel,
  ErrorTagResult,
  ErrorAttributeResult,
  isClTypeLabel,
  isOdIsTypeLabel,
  LoadErrorLabelsResponse,
  LoadErrorLabelsParams,
} from '../../../../../api/domainModels/consensusScoring';
import { getErrorMessage } from '../../../../../api/utils';
import {
  isAttributeReviewRunType,
  isSemanticReviewRunType,
  isTagReviewRunType,
} from '../../../../../helpers/errorFinder';
import { activeProjectIdSelector } from '../../../project/project.selectors';
import {
  setErrorFinderResultsCount,
  initialState as paginationInitialState,
  setErrorFinderItemsPerPageInView,
  setErrorFinderCursor,
  setErrorFinderPage,
} from './pagination.slice';
import { labelsPaginationSelector } from './pagination.selectors';
import {
  loadErrorFinderLabels,
  loadErrorFinderLabelsSuccess,
  loadErrorFinderLabelsFailure,
  updateErrorFinderLabelSuccess,
  updateErrorFinderLabelFailure,
  changeErrorAction,
  assignErrorFinderLabelClass,
  resetLoadingState,
  skipLoadingErrorFinderLabels,
} from './labels.slice';
import {
  FiltersData,
  resetErrorFinderFiltersInView,
  setErrorFinderFilter,
  setErrorFinderActiveCount,
} from './filters.slice';
import { loadErrorFinderTagResultsSuccess } from './tagReview.slice';
import { currentActiveFiltersCountSelector } from './filters.selectors';
import { labelConfidenceByIdSelector } from './confidence.selectors';
import { routerSelector } from '../../../root.selectors';
import { updateTriggerData } from '../../../sections/editedProject/triggers/triggers.slice';
import { TriggerIds } from '../../../../../@types/editedProject/Trigger.types';
import { loadErrorFinderAttributeResultsSuccess } from './attributeReview.slice';
import {
  ErrorFinderAction,
  RunType,
} from '../../../../../api/constants/consensusScoring';
import { loadSubjectLocks } from '../../../commonFeatures/locks/locks.slice';
import {
  activeRunTypeSelector,
  activeRunIdSelector,
} from '../errorFinder.selectors';
import { loadErrorFinderSemanticResultsSuccess } from './semanticReview.slice';

export type ReviewPageParamsType = {
  itemsPerPage?: number;
  page?: number;
};

const PAGE_PARAMS_FIELDS: (keyof ReviewPageParamsType)[] = ['itemsPerPage'];
const isFilterParamKey = (key: string) =>
  !PAGE_PARAMS_FIELDS.includes(key as keyof ReviewPageParamsType);

function* getParams(
  _filters: Partial<
    FiltersData & ReviewPageParamsType & { customLimit?: number }
  >,
) {
  const filters = { ..._filters };
  const pagination = yield* select(labelsPaginationSelector);
  const limit =
    filters.customLimit || filters.itemsPerPage || pagination.itemsPerPage;

  const page = pagination.direction;
  const cursor = page
    ? page === 'NEXT'
      ? pagination.cursorNext
      : pagination.cursorPrev
    : null;

  PAGE_PARAMS_FIELDS.forEach((param) => {
    delete filters[param];
  });

  return {
    ...filters,
    page,
    cursor,
    limit,
  };
}

// TODO: refactor this handler and separate each endpoint into it's own handler
function* loadLabelsHandler(action: ActionType<typeof loadErrorFinderLabels>) {
  try {
    const {
      location: { search },
    } = yield* select(routerSelector);

    const { runType, runId, imageId, customLimit, customFilters } =
      action.payload;

    const filters: { [key: string]: any } = customFilters || qs.parse(search);
    const projectId = yield* select(activeProjectIdSelector);

    if (customLimit) {
      filters.limit = customLimit;
    }

    if (!projectId || !runId || !runType) {
      yield* put(resetLoadingState());

      return;
    }

    yield* put(loadSubjectLocks(projectId));

    const params = (yield* call(
      getParams,
      filters,
    )) as LoadErrorLabelsParams['params'];

    if (imageId) {
      params.imageId = imageId;
    }

    if (params.csErrorType) {
      params.errorType = params.csErrorType;
    }

    const { data }: AxiosResponse<LoadErrorLabelsResponse> = yield* call(
      apiLoadErrorLabels,
      {
        projectId,
        runId,
        runType,
        params,
      },
    );

    yield* put(
      setErrorFinderCursor({
        cursorNext: data.meta.cursorNext,
        cursorPrev: data.meta.cursorPrev,
      }),
    );

    const resultIdMapper: Record<
      RunType,
      | keyof ErrorClLabel
      | keyof ErrorOdIsLabel
      | keyof ErrorTagResult
      | keyof ErrorAttributeResult
    > = {
      [RunType.AttributeReview]: 'labelId',
      [RunType.TagReview]: 'imageId',
      [RunType.ClassReview]: 'id',
      [RunType.InstanceReview]: 'id',
      [RunType.ObjectReview]: 'id',
      [RunType.SemanticReview]: 'id',
    };
    const items = yield* all(
      data.items.map(function* (item) {
        try {
          const { data } = yield* apiLoadErrorLabelsBlobs({
            projectId,
            runId,
            runType,
            resultId: item[resultIdMapper[runType] as keyof typeof item],
          });

          return {
            ...item,
            ...data,
          };
        } catch {
          return item;
        }
      }),
    );

    yield* put(resetErrorFinderFiltersInView());
    yield* all(
      Object.keys(filters)
        .filter((key) => isFilterParamKey(key))
        .map((key) => put(setErrorFinderFilter({ [key]: filters[key] }))),
    );

    const itemsPerPage =
      typeof filters.itemsPerPage === 'string'
        ? parseInt(filters.itemsPerPage, 10)
        : paginationInitialState.itemsPerPage;
    const count = yield* select(currentActiveFiltersCountSelector);

    yield* put(setErrorFinderItemsPerPageInView(itemsPerPage));
    yield* put(setErrorFinderActiveCount(count));

    const mapper = errorFinderLabelDataMapper[runType];
    // @ts-ignore
    const convertedLabels = items.map(mapper.fromBackend);

    if (isTagReviewRunType(convertedLabels, runType)) {
      yield* put(loadErrorFinderTagResultsSuccess(convertedLabels));
      yield* put(skipLoadingErrorFinderLabels());
    } else if (isAttributeReviewRunType(convertedLabels, runType)) {
      yield* put(loadErrorFinderAttributeResultsSuccess(convertedLabels));
      yield* put(skipLoadingErrorFinderLabels());
    } else if (isSemanticReviewRunType(convertedLabels, runType)) {
      yield* put(loadErrorFinderSemanticResultsSuccess(convertedLabels));
      yield* put(skipLoadingErrorFinderLabels());
    } else {
      yield* put(loadErrorFinderLabelsSuccess(convertedLabels));
    }

    yield* put(setErrorFinderResultsCount({ count: items.length }));
  } catch (e) {
    yield* put(
      loadErrorFinderLabelsFailure(
        getErrorMessage(e, 'Not able to load labels'),
      ),
    );
  }
}

function* changeClLabelErrorActionHandler({
  label,
  efAction,
  runId,
  runType,
  classId,
}: {
  label: EnrichedErrorClLabel;
  efAction: ErrorFinderAction;
  runType: RunType;
  runId: string;
  classId?: string;
}) {
  try {
    const projectId = yield* select(activeProjectIdSelector);

    if (efAction === ErrorFinderAction.ChangedClass) {
      yield* all([
        apiChangeErrorLabelAction(
          {
            projectId,
            runId,
            runType,
            id: label.id,
          },
          { efAction },
        ),
        label.currentClassId !== label.predictedClassId
          ? apiUpdateLabels({
              projectId,
              imageId: label.imageId,
              labels: [
                { labelId: label.labelId, classId: label.predictedClassId },
              ],
            })
          : null,
      ]);
      const changes = {
        efAction,
        currentClassConfidence: label.predictedClassConfidence,
        currentClassId: classId || label.predictedClassId,
        currentClassName: label.predictedClassName,
        labelId: label.labelId, // for the sake of passing the id in annotation env context
      };
      yield* put(updateErrorFinderLabelSuccess({ id: label.id, changes }));
    } else {
      yield* call(
        apiChangeErrorLabelAction,
        {
          projectId,
          runId,
          runType,
          id: label.id,
        },
        { efAction },
      );
      yield* put(
        updateErrorFinderLabelSuccess({ id: label.id, changes: { efAction } }),
      );
    }
  } catch (e) {
    yield* put(
      updateErrorFinderLabelFailure({
        message: getErrorMessage(e, 'Not able to update label'),
        id: label.id,
      }),
    );
  }
}

function* changeOdIsLabelErrorActionHandler({
  label,
  efAction,
  runType,
  runId,
  classId,
}: {
  label: EnrichedErrorOdIsLabel;
  efAction: ErrorFinderAction;
  runType: RunType;
  runId: string;
  classId?: string;
}) {
  try {
    const projectId = yield* select(activeProjectIdSelector);
    const changes: Partial<EnrichedErrorOdIsLabel> = { efAction };
    if (
      [ErrorType.LowIou, ErrorType.MissingLabel, ErrorType.ExtraLabel].includes(
        label.errorType,
      )
    ) {
      if (
        [
          ErrorFinderAction.ChangedShape,
          ErrorFinderAction.AddLabel,
          ErrorFinderAction.DeleteLabel,
        ].includes(efAction)
      ) {
        // accepting a predicted new label - take predicted as true, clean predicted
        changes.thumbTrueBbox = label.thumbPredBbox;
        changes.thumbTrueMask = label.thumbPredMask;
        changes.thumbTruePolygon = label.thumbPredPolygon;
        changes.trueBbox = label.predBbox;
        changes.trueClassId = classId || label.predClassId;
        changes.trueLabelClass = label.predLabelClass;
        changes.trueMask = label.predMask;
        changes.truePolygon = label.predPolygon;
        changes.thumbPredBbox = null;
        changes.thumbPredMask = null;
        changes.thumbPredPolygon = null;
        changes.predBbox = null;
        changes.predMask = null;
        changes.predPolygon = null;
      }
      if (efAction === ErrorFinderAction.NotError) {
        // declining new predicted label - remove it
        changes.thumbPredBbox = null;
        changes.thumbPredMask = null;
        changes.thumbPredPolygon = null;
        changes.predBbox = null;
        changes.predMask = null;
        changes.predPolygon = null;
        // todo:: check what to do with predicted class and confidence
      }
    }
    // declining marking the label as extra or removing prediction - do nothing additional
    const { data } = yield* call(
      apiChangeErrorLabelAction,
      {
        projectId,
        runId,
        runType,
        id: label.id,
      },
      { efAction },
    );
    changes.labelId = data.labelId; // in case of ODIS we grab labelId from response (as it might have been label creation)
    yield* put(updateErrorFinderLabelSuccess({ id: label.id, changes }));
  } catch (e) {
    yield* put(
      updateErrorFinderLabelFailure({
        message: getErrorMessage(e, 'Not able to update label'),
        id: label.id,
      }),
    );
  }
}

function* changeErrorActionHandler(
  action: ActionType<typeof changeErrorAction>,
) {
  const { label, runType, runId, efAction, classId } = action.payload;

  yield* put(
    updateTriggerData({
      id: TriggerIds.DataCleaning,
      data: {
        hasUpdatedAiCSRunResults: true,
      },
    }),
  );

  if (isClTypeLabel(label))
    yield* call(changeClLabelErrorActionHandler, {
      label,
      runType,
      runId,
      efAction,
      classId,
    });
  if (isOdIsTypeLabel(label))
    yield* call(changeOdIsLabelErrorActionHandler, {
      label,
      runType,
      runId,
      efAction,
      classId,
    });
}

function* assignLabelClassHandler(
  action: ActionType<typeof assignErrorFinderLabelClass>,
) {
  const { label, classId, runType, runId } = action.payload;
  if (label.currentClassId === classId) return;
  try {
    const projectId = yield* select(activeProjectIdSelector);

    const efAction =
      label.predictedClassId === classId
        ? ErrorFinderAction.ChangedClass
        : ErrorFinderAction.ChangedManually;
    yield* all([
      apiChangeErrorLabelAction(
        {
          projectId,
          runId,
          runType,
          id: label.id,
        },
        { efAction },
      ),
      apiUpdateLabels({
        projectId,
        imageId: label.imageId,
        labels: [{ labelId: label.labelId, classId }],
      }),
    ]);
    const updatedClass = yield* select((state: RootState) =>
      labelConfidenceByIdSelector(state, classId),
    );
    const changes = {
      efAction,
      currentClassConfidence: updatedClass?.confidence,
      currentClassId: classId,
      currentClassName: updatedClass?.className,
    };
    yield* put(
      updateErrorFinderLabelSuccess({
        id: label.id,
        changes,
      }),
    );
  } catch (e) {
    yield* put(
      updateErrorFinderLabelFailure({
        message: getErrorMessage(e, 'Not able to update label class'),
        id: label.id,
      }),
    );
  }
}

function* handlePageChange() {
  const runType = yield* select(activeRunTypeSelector);
  const runId = yield* select(activeRunIdSelector);

  yield* put(loadErrorFinderLabels({ runType, runId }));
}

export function* labelsSaga() {
  yield* takeEvery(loadErrorFinderLabels, loadLabelsHandler);
  yield* takeEvery(setErrorFinderPage, handlePageChange);
  yield* takeEvery(changeErrorAction, changeErrorActionHandler);
  yield* takeEvery(assignErrorFinderLabelClass, assignLabelClassHandler);
}
