import axios from 'axios';

import {
  InferenceFeedback,
  ApiInferenceSummaryItem,
  ApiInferenceItem,
} from '../schemas/inferences';
import { apiWrapper } from '../utils';
import { NEW_API_URL } from '../../constants/paths';
import { InferenceFamily } from '../domainModels/modelFamily';
import {
  ApiPaginated,
  headers,
  transformRequest,
  transformResponse,
} from '../helpers';

export const apiLoadProjectInferenceSummary = (params: { projectId: string }) =>
  apiWrapper(
    axios.request<ApiPaginated<ApiInferenceSummaryItem[]>>({
      method: 'get',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/inferences-summary`,
    }),
  );

export const apiLoadProjectInferences = ({
  projectId,
  params,
}: {
  projectId: string;
  params: {
    offset?: number;
    limit?: number;
    family: InferenceFamily;
    feedback?: InferenceFeedback;
  };
}) =>
  apiWrapper(
    axios.request<ApiPaginated<ApiInferenceItem[]>>({
      method: 'get',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${projectId}/inferences`,
      params,
    }),
  );

export const apiDeleteProjectInference = (params: {
  projectId: string;
  inferenceId: string;
}) =>
  apiWrapper(
    axios.request<void>({
      method: 'delete',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/inferences/${params.inferenceId}`,
      data: {}, // empty body due to backend peculiarities
    }),
  );

export const apiMoveProjectInference = (
  params: {
    projectId: string;
    inferenceId: string;
  },
  data: { datasetId: string; includeInferences?: boolean },
) =>
  apiWrapper(
    axios.request<void>({
      method: 'post',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/inferences/${params.inferenceId}`,
      data,
    }),
  );
