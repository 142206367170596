import { put, call, takeEvery } from 'typed-redux-saga';
import { Action } from 'redux';

import {
  getNextMessage,
  passMessageToWorker,
  WS_WORKER,
} from '../../../workers/workerManager';
import { subscribeSubject, unsubscribeSubject } from './ws.actions';
import { WS_HANDLERS_REACT_QUERY } from './ws.handlers';

function* startWsCommunicationHandler() {
  while (true) {
    const action = (yield* call(getNextMessage, WS_WORKER)) as any as Action;
    if (action.type in WS_HANDLERS_REACT_QUERY) {
      const key = action.type as keyof typeof WS_HANDLERS_REACT_QUERY;
      WS_HANDLERS_REACT_QUERY[key](action);
    } else {
      yield* put(action);
    }
  }
}

function subscribeSubjectHandler(action: ActionType<typeof subscribeSubject>) {
  passMessageToWorker(WS_WORKER, action.payload);
}

function unsubscribeSubjectHandler(
  action: ActionType<typeof unsubscribeSubject>,
) {
  passMessageToWorker(WS_WORKER, { ...action.payload, id: '' });
}

function* wsSaga() {
  yield* takeEvery(subscribeSubject, subscribeSubjectHandler);
  yield* takeEvery(unsubscribeSubject, unsubscribeSubjectHandler);
}

export const wsSagas = [startWsCommunicationHandler, wsSaga];
