import { RunType } from '../api/constants/consensusScoring';
import {
  ErrorAttributeLabel,
  ErrorSemanticLabel,
  ErrorTagLabel,
} from '../api/domainModels/consensusScoring';

export function isTagReviewRunType(
  _convertedLabels: unknown,
  runType: RunType,
): _convertedLabels is ErrorTagLabel[] {
  return runType === RunType.TagReview;
}

export function isAttributeReviewRunType(
  _convertedLabels: unknown,
  runType: RunType,
): _convertedLabels is ErrorAttributeLabel[] {
  return runType === RunType.AttributeReview;
}

export function isSemanticReviewRunType(
  _convertedLabels: unknown,
  runType: RunType,
): _convertedLabels is ErrorSemanticLabel[] {
  return runType === RunType.SemanticReview;
}
