import { all, call, put, select, takeEvery } from 'typed-redux-saga';
import { PayloadAction } from '@reduxjs/toolkit';

import {
  loadModelHandler,
  modelLoadedHandler,
  updateModelHandler,
} from '../models/models.saga';
import {
  MODEL_MESSAGES,
  TEXT_PROMPT_FAMILY_NAME,
} from './textPrompt.constants';
import { ImageTool, ImageToolTitleMap } from '../tools.constants';
import {
  addLabels,
  addLabel,
  loadDetections,
  loadTextPromptModel,
  loadTextPromptModelError,
  loadTextPromptModelStart,
  loadTextPromptModelSuccess,
  loadTextPromptFailure,
  setThreshold,
  updateTextPromptModel,
  updateTextPromptModelSuccess,
  confirmAddLabel,
  confirmThreshold,
  loadTextPromptSuccess,
  toggleUseSemSeg,
  toggleUseViewport,
  setClassesInput,
  setTextInput,
  loadDetectionsStart,
  resetModel,
  resetTextPrompt,
  TextPromptMode,
} from './textPrompt.slice';
import {
  createModelChangePattern,
  ModelChangePayload,
} from '../models/models.constants';
import {
  textPromptThresholdSelector,
  textPromptModelIdSelector,
  textPromptDetectedDataSelector,
  textPromptModelLoadedSelector,
  textPromptUseViewportSelector,
  textPromptClassesSelector,
  textPromptTextInputSelector,
  textPromptModeSelector,
  textPromptUseSemSegSelector,
  textPromptVisibleAreaBboxSelector,
  textPromptLoadingStateSelector,
} from './textPrompt.selectors';
import { imageViewImageIdSelector } from '../../currentImage/currentImage.selectors';
import { getErrorMessage } from '../../../../../../api/utils';
import { addLabels as commonAddLabels } from '../../labels/labels.slice';
import { uuidv4 } from '../../../../../../util/uuidv4';
import { resetActiveTool, setActiveTool } from '../tools.slice';
import {
  TEXT_PROMPT_THRESHOLD_MIN_VALUE,
  TEXT_PROMPT_THRESHOLD_MAX_VALUE,
} from '../../../../../../constants/textPrompt';
import { activeProjectIdSelector } from '../../../../project/project.selectors';
import { handleError } from '../../../../commonFeatures/errorHandler/errorHandler.actions';
import { MODEL_LOADED, MODEL_UPDATED } from '../../../../ws/ws.constants';
import {
  apiLoadTextPrompt,
  apiLoadTextPromptModel,
} from '../../../../../../api/requests/projectTools';
import { LabelType } from '../../../../../../api/constants/label';
import { labelClassesSelector } from '../../../../project/annotationTaxonomy/labelClasses/labelClasses.selectors';
import { activeLabelClassIdSelector } from '../../labelClasses/labelClasses.selectors';
import {
  getResultFromWorker,
  MASK_WORKER,
  RLE_WORKER,
} from '../../../../../../workers/workerManager';
import { METHOD_RLE_TO_IMAGE_DATA } from '../../../../../../workers/rle/constants';
import { getHeight, getWidth } from '../../../../../../util/bbox';
import { METHOD_BORDER } from '../../../../../../workers/mask/constants';
import { releaseObject, storeObject } from '../../imageView.helpers';
import { addActiveToolEntityId } from '../activeToolData/activeToolData.slice';
import { resetProject } from '../../imageView.actions';
import { statusChecks } from '../../../../../../constants/status';
import { retrieveObject } from '../../../../../../helpers/imageView/data.helpers';
import { Bbox } from '../../../../../../@types/imageView/types';
import { textPromptFeature } from '../../../../../../util/features';

type RetrievedObject = {
  bbox: Bbox;
  border: string;
  classId: string;
  id: string;
  mask: number[];
};
function* loadTextPromptModelHandler() {
  const projectId = yield* select(activeProjectIdSelector);

  yield* call(loadModelHandler, {
    projectId,
    loadApiCall: apiLoadTextPromptModel,
    messages: MODEL_MESSAGES,
    toolId: ImageTool.TextPrompt,
    modelLoadStartAction: loadTextPromptModelStart,
    modelLoadSuccessAction: loadTextPromptModelSuccess,
    modelLoadErrorAction: loadTextPromptModelError,
    toolName: ImageToolTitleMap[ImageTool.TextPrompt],
  });
}

function* textPromptModelLoadedHandler(
  action: PayloadAction<ModelChangePayload>,
) {
  const { status, progress, id, modelUseIE } = action.payload;

  yield* call(modelLoadedHandler, {
    id,
    modelIdSelector: textPromptModelIdSelector,
    modelLoadedSelector: textPromptModelLoadedSelector,
    progress,
    status,
    modelUseIE,
    updateAction: updateTextPromptModel,
  });
}

function* textPromptModelUpdatedHandler(
  action: PayloadAction<ModelChangePayload>,
) {
  const { status, progress, id, modelUseIE } = action.payload;

  yield* put(
    updateTextPromptModel({
      status,
      progress,
      id,
      modelUseIE,
    }),
  );
}

function* updateTextPromptModelHandler(
  action: ActionType<typeof updateTextPromptModel>,
) {
  const { status, progress, id, modelUseIE } = action.payload;

  yield* call(updateModelHandler, {
    id,
    loadAction: loadTextPromptModel,
    progress,
    status,
    modelUseIE,
    successAction: updateTextPromptModelSuccess,
  });
}

function* loadDetectionsHandler() {
  const projectId = yield* select(activeProjectIdSelector);
  const imageId = yield* select(imageViewImageIdSelector);
  const threshold = yield* select(textPromptThresholdSelector);
  const useViewport = yield* select(textPromptUseViewportSelector);
  const modelId = yield* select(textPromptModelIdSelector);
  const classesPrompt = yield* select(textPromptClassesSelector);
  const prompt = yield* select(textPromptTextInputSelector);
  const mode = yield* select(textPromptModeSelector);
  const semanticMode = yield* select(textPromptUseSemSegSelector);
  const visibleAreaBbox = yield* select(textPromptVisibleAreaBboxSelector);
  const labelClasses = yield* select(labelClassesSelector);
  const activeClassId = yield* select(activeLabelClassIdSelector);

  if (!imageId || modelId === null) return;
  if (mode === TextPromptMode.Custom && prompt === '') return;
  if (mode === TextPromptMode.Classes && classesPrompt.length === 0) return;

  const label = labelClasses
    .filter((labelClass) => classesPrompt.includes(labelClass.id))
    .map((labelClass) =>
      labelClass.useDescriptionAsPrompt
        ? labelClass.description || labelClass.name
        : labelClass.name,
    );

  try {
    yield* put(loadDetectionsStart());

    const { data } = yield* call(apiLoadTextPrompt, projectId, imageId, {
      threshold: threshold / 100,
      viewport: useViewport ? visibleAreaBbox : undefined,
      label,
      prompt: mode === TextPromptMode.Custom ? prompt : '',
      semanticMode: mode === TextPromptMode.Custom ? semanticMode : undefined,
    });
    const imageData = yield* all(
      data.map((datum) =>
        getResultFromWorker(RLE_WORKER, {
          method: METHOD_RLE_TO_IMAGE_DATA,
          rle: {
            data: datum.mask,
            width: getWidth(datum.bbox),
            height: getHeight(datum.bbox),
          },
        }),
      ),
    );
    const borders = yield* all(
      imageData.map((datum: ImageData) =>
        getResultFromWorker(MASK_WORKER, {
          method: METHOD_BORDER,
          imageData: datum,
        }),
      ),
    );
    const objectsToStore = data.map((datum, index) => ({
      bbox: datum.bbox,
      border: borders[index],
      classId: datum.classId || activeClassId,
      id: uuidv4(),
      mask: datum.mask,
    }));
    const storedObjectIds: number[] = objectsToStore.map(storeObject);
    const objects = storedObjectIds.map((storedObjectId, index) => ({
      storedObjectId,
      labelId: objectsToStore[index].id,
    }));

    yield* all(storedObjectIds.map((id) => put(addActiveToolEntityId(id))));
    yield* put(loadTextPromptSuccess(objects));
  } catch (error) {
    const errorMessage = getErrorMessage(
      error,
      'Text prompt failed to detect objects',
    );

    yield* put(loadTextPromptFailure({ message: errorMessage }));
    yield* put(handleError({ message: errorMessage, error }));
  }
}

function* addLabelsHandler() {
  const loadingState = yield* select(textPromptLoadingStateSelector);

  if (statusChecks.isInProgress(loadingState.status)) {
    return;
  }

  const data = yield* select(textPromptDetectedDataSelector);
  const retrievedObjects: RetrievedObject[] = data.map((datum) =>
    retrieveObject(datum.storedObjectId),
  );

  if (retrievedObjects.length > 0) {
    yield* put(
      commonAddLabels(
        retrievedObjects.map((datum) => ({
          id: uuidv4(),
          classId: datum.classId,
          toolUsed: ImageTool.TextPrompt,
          type: LabelType.Mask,
          bbox: datum.bbox,
          mask: datum.mask,
        })),
      ),
    );

    yield* put(setActiveTool(ImageTool.Default));
  }
}

function* addLabelHandler(action: ActionType<typeof addLabel>) {
  const id = action.payload.storedObjectId;
  const retrievedObject: RetrievedObject = retrieveObject(id);

  if (!retrievedObject) return;

  yield* put(
    commonAddLabels([
      {
        id: uuidv4(),
        classId: retrievedObject.classId,
        toolUsed: ImageTool.TextPrompt,
        type: LabelType.Mask,
        bbox: retrievedObject.bbox,
        mask: retrievedObject.mask,
      },
    ]),
  );

  yield* put(confirmAddLabel({ labelId: retrievedObject.id }));
}

function* handleSetThreshold(action: ActionType<typeof setThreshold>) {
  let { threshold } = action.payload;

  if (threshold < TEXT_PROMPT_THRESHOLD_MIN_VALUE) {
    threshold = TEXT_PROMPT_THRESHOLD_MIN_VALUE;
  }
  if (threshold > TEXT_PROMPT_THRESHOLD_MAX_VALUE) {
    threshold = TEXT_PROMPT_THRESHOLD_MAX_VALUE;
  }

  yield* put(confirmThreshold({ threshold }));
  yield* put(loadDetections());
}

function* setActiveToolHandler() {
  yield* call(releaseObjects);
  yield* put(resetTextPrompt());
}

function* releaseObjects() {
  const objects = yield* select(textPromptDetectedDataSelector);

  objects.forEach((object) => releaseObject(object.storedObjectId));
}

function* resetProjectHandler() {
  yield* call(releaseObjects);
  yield* put(resetTextPrompt());
  yield* put(resetModel());
}

export function* textPromptSaga() {
  yield* takeEvery(setThreshold, handleSetThreshold);
  yield* takeEvery(
    [
      toggleUseSemSeg,
      toggleUseViewport,
      loadDetections,
      setClassesInput,
      setTextInput,
    ],
    loadDetectionsHandler,
  );
  if (textPromptFeature) {
    yield* takeEvery(loadTextPromptModel, loadTextPromptModelHandler);
  }
  yield* takeEvery(
    createModelChangePattern(TEXT_PROMPT_FAMILY_NAME, MODEL_LOADED),
    textPromptModelLoadedHandler,
  );
  yield* takeEvery(
    createModelChangePattern(TEXT_PROMPT_FAMILY_NAME, MODEL_UPDATED),
    textPromptModelUpdatedHandler,
  );
  yield* takeEvery(updateTextPromptModel, updateTextPromptModelHandler);
  yield* takeEvery(addLabels, addLabelsHandler);
  yield* takeEvery(addLabel, addLabelHandler);
  yield* takeEvery([setActiveTool, resetActiveTool], setActiveToolHandler);
  yield* takeEvery(resetProject, resetProjectHandler);
}
