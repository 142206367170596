import { put, select, takeEvery } from 'typed-redux-saga';

import {
  labelClassesMapSelector,
  labelClassesSelector,
  labelClassesIdsSelector,
  labelClassByIdSelector,
} from '../../../project/annotationTaxonomy/labelClasses/labelClasses.selectors';
import { loadLabelClassesSuccess } from '../../../project/annotationTaxonomy/labelClasses/labelClasses.slice';
import {
  activeLabelClassIdSelector,
  selectedLabelsClassesSelector,
} from './labelClasses.selectors';
import {
  selectActiveLabelClassId,
  selectNextLabelClass,
  selectPreviousLabelClass,
  setLabelClassToSelectedLabels,
} from './labelClasses.slice';
import { selectedLabelsCanChangeClassIdsSelector } from '../labels/selectedLabels/selectedLabels.selectors';
import { disableLabelClassPrediction } from '../tools/labelClassPrediction/labelClassPrediction.slice';
import { activeProjectIdSelector } from '../../../project/project.selectors';
import { updateLabels } from '../labels/labels.slice';
import { LabelClassType } from '../../../../../api/domainModels/labelClass';

function* loadLabelClassSuccessHandler(
  _action: ActionType<typeof loadLabelClassesSuccess>,
) {
  const selectedLabelClassId = yield* select(activeLabelClassIdSelector);
  const labelClassesMap = yield* select(labelClassesMapSelector);

  if (
    (selectedLabelClassId && !labelClassesMap[selectedLabelClassId]) ||
    !selectedLabelClassId
  ) {
    const labelClassesList = yield* select(labelClassesSelector);
    const selectedClass = labelClassesList[0];

    if (selectedClass) {
      yield* put(
        selectActiveLabelClassId({
          id: selectedClass.id,
          type: selectedClass.type,
        }),
      );
    }
  }
}

function* selectNextLabelClassHandler() {
  yield* selectLabelHandler({ delta: 1 });
}

function* selectPreviousLabelClassHandler() {
  yield* selectLabelHandler({ delta: -1 });
}

function* selectLabelHandler({ delta }: { delta: number }) {
  const activeLabelClassId = yield* select(activeLabelClassIdSelector);
  const labelClassIds = yield* select(labelClassesIdsSelector);
  const selectedLabelsClasses = yield* select(selectedLabelsClassesSelector);

  const relevantLabelClassId =
    selectedLabelsClasses.length === 1
      ? selectedLabelsClasses[0]
      : activeLabelClassId;
  let newLabelClassId: string | undefined;

  if (relevantLabelClassId) {
    const labelClassIdsLength = labelClassIds.length;
    const newLabelClassIndex =
      (labelClassIds.indexOf(relevantLabelClassId) +
        delta +
        labelClassIdsLength) %
      labelClassIdsLength;
    newLabelClassId = labelClassIds[newLabelClassIndex];
  }

  const selectedLabelsIds = yield* select((state: RootState) =>
    selectedLabelsCanChangeClassIdsSelector(state, newLabelClassId),
  );

  if (selectedLabelsClasses.length === 1) {
    const data = selectedLabelsIds.map((id) => ({
      id,
      changes: { classId: newLabelClassId },
    }));
    yield* put(updateLabels(data));
  } else if (
    !(selectedLabelsClasses.length > 0) &&
    activeLabelClassId &&
    newLabelClassId
  ) {
    const newLabelClass = yield* select(
      (state: RootState) =>
        newLabelClassId && labelClassByIdSelector(state, newLabelClassId),
    );

    if (newLabelClass) {
      yield* put(
        selectActiveLabelClassId({
          id: newLabelClassId,
          type: newLabelClass.type,
        }),
      );
    }
  }
}

function* setLabelClassToSelectedLabelsHandler(
  action: ActionType<typeof setLabelClassToSelectedLabels>,
) {
  const classId = action.payload;
  const selectedLabelsIds = yield* select((state: RootState) =>
    selectedLabelsCanChangeClassIdsSelector(state, classId),
  );

  if (selectedLabelsIds.length === 0) return;

  const data = selectedLabelsIds.map((id) => ({
    id,
    changes: { classId },
  }));
  yield* put(updateLabels(data));
}

function* selectActiveLabelClassIdHandler(
  action: ActionType<typeof selectActiveLabelClassId>,
) {
  const projectId = yield* select(activeProjectIdSelector);

  if (action.payload.type === LabelClassType.Background) {
    yield* put(disableLabelClassPrediction(projectId));
  }
}

export function* labelClassesSaga() {
  yield* takeEvery(loadLabelClassesSuccess, loadLabelClassSuccessHandler);
  yield* takeEvery(selectNextLabelClass, selectNextLabelClassHandler);
  yield* takeEvery(selectPreviousLabelClass, selectPreviousLabelClassHandler);
  yield* takeEvery(
    setLabelClassToSelectedLabels,
    setLabelClassToSelectedLabelsHandler,
  );
  yield* takeEvery(selectActiveLabelClassId, selectActiveLabelClassIdHandler);
}
