import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Role } from '../../../../../api/domainModels/role';
import { RoleParams } from '../../../../../api/domainModels/workspaceRoles';
import {
  LoadingState,
  loadingStateBuilder,
} from '../../../../utils/loadingState';
import { resetManagedWorkspaceId } from '../../../commonFeatures/workspaceId/workspaceId.slice';

export type RolePrivilegeValue = {
  roleId: number;
  value: boolean;
  originalValue?: boolean;
  readOnly: boolean;
};

export type PrivilegeState = {
  id: string;
  title: string;
  description: string;
  simple: boolean;
  values: { [key: number]: RolePrivilegeValue };
};

export type RolePrivilegesState = {
  group: string;
  simple: boolean;
  values: { [key: number]: RolePrivilegeValue };
  privileges: { [key: string]: PrivilegeState };
};

export type RolesState = {
  loadingState: LoadingState;
  updateLoadingState: LoadingState;
  actionLoadingState: LoadingState;
  rolePrivileges: {
    data: { [key: string]: RolePrivilegesState };
    loadingState: LoadingState;
  };
  data: Role[];
};

const initialState: RolesState = {
  loadingState: loadingStateBuilder.initial(),
  updateLoadingState: loadingStateBuilder.initial(),
  actionLoadingState: loadingStateBuilder.initial(),
  rolePrivileges: {
    data: {},
    loadingState: loadingStateBuilder.initial(),
  },
  data: [],
};

const { actions, reducer: rolesReducer } = createSlice({
  name: 'managedWorkspace/roles',
  initialState,
  reducers: {
    loadRoles: (state, _action: PayloadAction<{ workspaceId: string }>) => {
      state.loadingState = loadingStateBuilder.inProgress('Loading roles');
    },
    loadRolesFailure: (state, action: PayloadAction<string>) => {
      const message = action.payload;
      state.loadingState = loadingStateBuilder.failure(message);
    },
    loadRolesSuccess: (state, action: PayloadAction<Role[]>) => {
      state.loadingState = loadingStateBuilder.success();
      state.data = action.payload;
    },
    createRole: (
      state,
      _action: PayloadAction<{ workspaceId: string; params: RoleParams }>,
    ) => {
      state.actionLoadingState =
        loadingStateBuilder.inProgress('Creating role');
    },
    createRoleFailure: (state, action: PayloadAction<{ message: string }>) => {
      const { message } = action.payload;
      state.actionLoadingState = loadingStateBuilder.failure(message);
    },
    createRoleSuccess: (state, action: PayloadAction<Role>) => {
      state.actionLoadingState = loadingStateBuilder.success();
      state.data.push(action.payload);
    },
    updateRole: (
      state,
      _action: PayloadAction<{
        workspaceId: string;
        roleId: number;
        params: RoleParams;
      }>,
    ) => {
      state.updateLoadingState =
        loadingStateBuilder.inProgress('Updating role');
    },
    updateRoleFailure: (state, action: PayloadAction<{ message: string }>) => {
      const { message } = action.payload;
      state.updateLoadingState = loadingStateBuilder.failure(message);
    },
    updateRoleSuccess: (state, action: PayloadAction<Role>) => {
      state.updateLoadingState = loadingStateBuilder.success();
      const role = action.payload;
      const index = state.data.findIndex((r: any) => r.id === role.id);
      state.data[index] = role;
    },
    deleteRole: (
      state,
      _action: PayloadAction<{ workspaceId: string; roleId: number }>,
    ) => {
      state.actionLoadingState =
        loadingStateBuilder.inProgress('Deleting role');
    },
    deleteRoleFailure: (state, action: PayloadAction<{ message: string }>) => {
      const { message } = action.payload;
      state.actionLoadingState = loadingStateBuilder.failure(message);
    },
    deleteRoleSuccess: (state, action: PayloadAction<{ roleId: number }>) => {
      const { roleId } = action.payload;
      state.actionLoadingState = loadingStateBuilder.success();
      state.data = state.data.filter((role) => role.id !== roleId);
    },
    loadRolePrivileges: (
      state,
      _action: PayloadAction<{ workspaceId: string }>,
    ) => {
      state.rolePrivileges.loadingState = loadingStateBuilder.inProgress(
        'Loading role privileges',
      );
    },
    loadRolePrivilegesFailure: (
      state,
      action: PayloadAction<{ message: string }>,
    ) => {
      const { message } = action.payload;
      state.rolePrivileges.loadingState = loadingStateBuilder.failure(message);
    },
    loadRolePrivilegesSuccess: (state, action: PayloadAction<any>) => {
      state.rolePrivileges.loadingState = loadingStateBuilder.success();
      state.rolePrivileges.data = action.payload;
    },
    grantPrivileges: (
      state,
      _action: PayloadAction<{
        workspaceId: string;
        roleId: number;
        privilegeIds: string[];
      }>,
    ) => {},
    grantPrivilegesFailure: (
      state,
      action: PayloadAction<{ message: string }>,
    ) => {
      const { message } = action.payload;
      state.actionLoadingState = loadingStateBuilder.failure(message);
    },
    grantPrivilegesSuccess: (state, _action: PayloadAction) => {
      state.actionLoadingState = loadingStateBuilder.success();
    },
    revokePrivileges: (
      state,
      _action: PayloadAction<{
        workspaceId: string;
        roleId: number;
        privilegeIds: string[];
      }>,
    ) => {},
    revokePrivilegesFailure: (
      state,
      _action: PayloadAction<{ message: string }>,
    ) => {},
    revokePrivilegesSuccess: (
      state,
      _action: PayloadAction<{ workspaceId: string; roleId: number }>,
    ) => {},
    changeRolePrivilege: (
      state,
      _action: PayloadAction<{
        value: boolean;
        privilegeId: string;
        roleId: number;
        groupId: string;
      }>,
    ) => {},
    changeRolePrivilegeGroup: (
      state,
      _action: PayloadAction<{
        value: boolean;
        groupId: string;
        roleId: number;
      }>,
    ) => {},
    replaceRolePrivileges: (
      state,
      action: PayloadAction<{ [key: string]: RolePrivilegesState }>,
    ) => {
      state.rolePrivileges.loadingState = loadingStateBuilder.success();
      state.rolePrivileges.data = action.payload;
    },
    saveRolePrivilegesChanges: (state, _action: PayloadAction) => {},
    cancelRolePrivilegesChanges: (state, _action: PayloadAction) => {},
  },
  extraReducers: (builder) => {
    builder.addCase(resetManagedWorkspaceId, () => initialState);
  },
});

export const {
  loadRoles,
  loadRolesFailure,
  loadRolesSuccess,
  createRole,
  createRoleFailure,
  createRoleSuccess,
  updateRole,
  updateRoleFailure,
  updateRoleSuccess,
  deleteRole,
  deleteRoleFailure,
  deleteRoleSuccess,
  loadRolePrivileges,
  loadRolePrivilegesFailure,
  loadRolePrivilegesSuccess,
  replaceRolePrivileges,
  saveRolePrivilegesChanges,
  cancelRolePrivilegesChanges,
  grantPrivileges,
  grantPrivilegesFailure,
  grantPrivilegesSuccess,
  revokePrivileges,
  revokePrivilegesFailure,
  revokePrivilegesSuccess,
  changeRolePrivilege,
  changeRolePrivilegeGroup,
} = actions;

export { rolesReducer };
