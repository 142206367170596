/* eslint-disable no-continue */
/* eslint-disable no-console */
import produce, { castDraft } from 'immer';
import {
  atom,
  atomFamily,
  CallbackInterface,
  DefaultValue,
  isRecoilValue,
  RecoilState,
  RecoilValue,
  RecoilValueReadOnly,
  selector,
  selectorFamily,
  SerializableParam,
} from 'recoil';

export const buildEntityState = <T extends Entity>(
  items: T[],
  options?: {
    sortComparer?: (a: T, b: T) => number;
  },
): EntityState<T> => {
  const sortedItems = options?.sortComparer
    ? [...items].sort(options.sortComparer)
    : items;

  return sortedItems.reduce(
    (acc, curr) => {
      acc.ids.push(curr.id);
      acc.entities[curr.id] = curr;

      return acc;
    },
    {
      ids: [] as string[],
      entities: {} as Record<string, typeof items[number]>,
    },
  );
};

export type EntityState<T> = {
  readonly ids: string[];
  readonly entities: Record<string, T>;
};

export type Update<T> = { id: string; changes: Partial<T> };

type SortComparer<T> = (a: T, b: T) => number;
type SelectId<T> = (entity: T) => string;

type Entity = {
  id: string;
};

type RecoilEntityAdapterConfig<T extends Entity> = {
  name: string;
  initialState?: T[] | Promise<T[]> | RecoilState<T[]>;
  sortComparer?: SortComparer<T>;
  selectId?: SelectId<T>;
};

type RecoilEntityAdapterFamilyConfig<
  T extends Entity,
  P extends SerializableParam,
> = {
  name: string;
  paramState: RecoilValue<P>;
  initialState?:
    | ((param: P) => RecoilValueReadOnly<T[]>)
    | ((param: P) => T[])
    | ((param: P) => Promise<T[]>)
    | RecoilState<T[]>
    | T[];
  sortComparer?: SortComparer<T>;
  selectId?: SelectId<T>;
};

const createRootConfig = <T>(name: string, defaultRootValue: T) => ({
  key: `rea/${name}/root`,
  default: defaultRootValue,
});

const createApi = <T extends Entity, P extends SerializableParam>({
  root,
  sortComparer,
  selectId,
  name,
}: {
  root: RecoilState<EntityState<T>> | ((p: P) => RecoilState<EntityState<T>>);
  sortComparer?: SortComparer<T>;
  selectId: SelectId<T>;
  name: string;
}) => {
  type Param = P;

  const addOne = (
    root: RecoilState<EntityState<T>>,
    set: CallbackInterface['set'],
    entity: T,
  ) => {
    set(root, (rootState) =>
      produce(rootState, (draft) => {
        if (draft.entities[selectId(entity)]) {
          console.warn(
            `rea/${name}/addOne: entity with id ${selectId(
              entity,
            )} is already in store. Did you mean upsert/update?`,
          );

          return;
        }
        draft.ids.push(selectId(entity));
        draft.entities[selectId(entity)] = castDraft(entity);
        if (sortComparer) {
          draft.ids.sort((a, b) =>
            sortComparer(draft.entities[a] as T, draft.entities[b] as T),
          );
        }
      }),
    );
  };

  const addMany = (
    root: RecoilState<EntityState<T>>,
    set: CallbackInterface['set'],
    entities: T[],
  ) => {
    set(root, (rootState) =>
      produce(rootState, (draft) => {
        for (const entity of entities) {
          if (draft.entities[selectId(entity)]) {
            console.warn(
              `rea/${name}/addMany: entity with id ${selectId(
                entity,
              )} is already in store. Did you mean upsertMany/updateMany?`,
            );

            continue;
          }
          draft.ids.push(selectId(entity));
          draft.entities[selectId(entity)] = castDraft(entity);
        }
        if (sortComparer) {
          draft.ids.sort((a, b) =>
            sortComparer(draft.entities[a] as T, draft.entities[b] as T),
          );
        }
      }),
    );
  };

  const updateOne = (
    root: RecoilState<EntityState<T>>,
    set: CallbackInterface['set'],
    update: Update<T>,
  ) => {
    const { id, changes } = update;
    set(root, (rootState) =>
      produce(rootState, (draft) => {
        if (!draft.entities[id]) {
          console.warn(
            `rea/${name}/updateOne: entity with id ${id} is not in store. Did you mean upsert?`,
          );

          return;
        }
        draft.entities[id] = { ...draft.entities[id], ...changes };
        if (sortComparer) {
          draft.ids.sort((a, b) =>
            sortComparer(draft.entities[a] as T, draft.entities[b] as T),
          );
        }
      }),
    );
  };

  const updateMany = (
    root: RecoilState<EntityState<T>>,
    set: CallbackInterface['set'],
    updates: Update<T>[],
  ) => {
    set(root, (rootState) =>
      produce(rootState, (draft) => {
        for (const { id, changes } of updates) {
          if (!draft.entities[id]) {
            console.warn(
              `rea/${name}/updateMany: entity with id ${id} is not in store. Did you mean upsertMany?`,
            );

            continue;
          }
          draft.entities[id] = { ...draft.entities[id], ...changes };
        }
        if (sortComparer) {
          draft.ids.sort((a, b) =>
            sortComparer(draft.entities[a] as T, draft.entities[b] as T),
          );
        }
      }),
    );
  };

  const upsertOne = (
    root: RecoilState<EntityState<T>>,
    set: CallbackInterface['set'],
    update: Update<T>,
  ) => {
    const { id, changes } = update;

    set(root, (rootState) =>
      produce(rootState, (draft) => {
        if (!draft.entities[id]) {
          draft.ids.push(id);
        }
        draft.entities[id] = { ...draft.entities[id], ...changes };
        if (sortComparer) {
          draft.ids.sort((a, b) =>
            sortComparer(draft.entities[a] as T, draft.entities[b] as T),
          );
        }
      }),
    );
  };

  const upsertMany = (
    root: RecoilState<EntityState<T>>,
    set: CallbackInterface['set'],
    updates: Update<T>[],
  ) => {
    set(root, (rootState) =>
      produce(rootState, (draft) => {
        for (const { id, changes } of updates) {
          if (!draft.entities[id]) {
            draft.ids.push(id);
          }
          draft.entities[id] = { ...draft.entities[id], ...changes };
        }
        if (sortComparer) {
          draft.ids.sort((a, b) =>
            sortComparer(draft.entities[a] as T, draft.entities[b] as T),
          );
        }
      }),
    );
  };

  const removeOne = (
    root: RecoilState<EntityState<T>>,
    set: CallbackInterface['set'],
    id: string,
  ) => {
    set(root, (rootState) =>
      produce(rootState, (draft) => {
        if (!draft.entities[id]) {
          console.warn(
            `rea/${name}/removeOne: entity with id ${id} is not in store. What was the plan?`,
          );
        }
        delete draft.entities[id];
        draft.ids = draft.ids.filter((_id) => _id !== id);
      }),
    );
  };

  const removeMany = (
    root: RecoilState<EntityState<T>>,
    set: CallbackInterface['set'],
    ids: string[],
  ) => {
    set(root, (rootState) =>
      produce(rootState, (draft) => {
        for (const id of ids) {
          if (!draft.entities[id]) {
            console.warn(
              `rea/${name}/removeMany: entity with id ${id} is not in store. What was the plan?`,
            );
          }
          delete draft.entities[id];
        }
        draft.ids = draft.ids.filter((_id) => !ids.includes(_id));
      }),
    );
  };

  const setAll = (
    root: RecoilState<EntityState<T>>,
    set: CallbackInterface['set'],
    entities: T[],
  ) => {
    set(root, buildEntityState(entities));
  };

  return typeof root === 'function'
    ? {
        _type: 'multiple' as const,
        helpers: {
          addOne: (set: CallbackInterface['set'], param: Param, entity: T) => {
            addOne(root(param), set, entity);
          },
          addMany: (
            set: CallbackInterface['set'],
            param: Param,
            entities: T[],
          ) => {
            addMany(root(param), set, entities);
          },
          updateOne: (
            set: CallbackInterface['set'],
            param: Param,
            update: Update<T>,
          ) => {
            updateOne(root(param), set, update);
          },
          updateMany: (
            set: CallbackInterface['set'],
            param: Param,
            updates: Update<T>[],
          ) => {
            updateMany(root(param), set, updates);
          },
          upsertOne: (
            set: CallbackInterface['set'],
            param: Param,
            update: Update<T>,
          ) => {
            upsertOne(root(param), set, update);
          },
          upsertMany: (
            set: CallbackInterface['set'],
            param: Param,
            updates: Update<T>[],
          ) => {
            upsertMany(root(param), set, updates);
          },
          removeOne: (
            set: CallbackInterface['set'],
            param: Param,
            id: string,
          ) => {
            removeOne(root(param), set, id);
          },
          removeMany: (
            set: CallbackInterface['set'],
            param: Param,
            ids: string[],
          ) => {
            removeMany(root(param), set, ids);
          },
          setAll: (
            set: CallbackInterface['set'],
            param: Param,
            entities: T[],
          ) => {
            setAll(root(param), set, entities);
          },
        },
        selectById: selectorFamily({
          key: `rea/${name}/f/selectById`,
          get:
            ([param, id]: [p: Param, id: string]) =>
            ({ get }) =>
              get(root(param)).entities[id],
          set:
            ([param, id]: [param: Param, id: string]) =>
            ({ set }, value: DefaultValue | T | undefined) => {
              if (value instanceof DefaultValue) {
                console.warn(
                  `Did you mean to reset rea/${name}/f/one for id ${id} and param ${String(
                    param,
                  )} ?`,
                );

                return;
              }
              if (value === undefined) {
                removeOne(root(param), set, id);
              } else {
                upsertOne(root(param), set, { id, changes: value });
              }
            },
        }),
        selectByIdOrThrow: selectorFamily({
          key: `rea/${name}/selectByIdOrThrow`,
          get:
            ([param, id]: [p: Param, id: string]) =>
            ({ get }) => {
              const entity = get(root(param)).entities[id];
              if (!entity) {
                throw new Error(
                  `rea/${name}/f/selectByIdOrThrow: Entity id ${id} not found `,
                );
              }

              return entity;
            },
          set:
            ([param, id]: [p: Param, id: string]) =>
            ({ set }, value: DefaultValue | T) => {
              if (value instanceof DefaultValue) {
                console.warn(
                  `Did you mean to reset rea/${name}/one for id ${id} ?`,
                );

                return;
              }
              if (value === undefined) {
                removeOne(root(param), set, id);
              } else {
                upsertOne(root(param), set, { id, changes: value });
              }
            },
        }),
        selectAll: selectorFamily({
          key: `rea/${name}/f/selectAll`,
          get:
            (param: Param) =>
            ({ get }) => {
              const rootState = get(root(param));

              return rootState.ids.map((id) => rootState.entities[id]);
            },
          set:
            (param: Param) =>
            ({ set }, newValue: T[] | DefaultValue) => {
              if (Array.isArray(newValue)) {
                setAll(root(param), set, newValue);
              } else {
                set(root(param), newValue);
              }
            },
        }),
        selectTotal: selectorFamily({
          key: `rea/${name}/f/selectTotal`,
          get:
            (param: Param) =>
            ({ get }) => {
              const rootState = get(root(param));

              return rootState.ids.length;
            },
        }),
        selectIds: selectorFamily({
          key: `rea/${name}/f/selectIds`,
          get:
            (param: Param) =>
            ({ get }) => {
              const rootState = get(root(param));

              return rootState.ids;
            },
        }),
        selectEntities: selectorFamily({
          key: `rea/${name}/f/selectEntities`,
          get:
            (param: Param) =>
            ({ get }) => {
              const rootState = get(root(param));

              return rootState.entities;
            },
        }),
      }
    : {
        _type: 'single' as const,
        helpers: {
          addOne: (set: CallbackInterface['set'], entity: T) => {
            addOne(root, set, entity);
          },
          addMany: (set: CallbackInterface['set'], entities: T[]) => {
            addMany(root, set, entities);
          },
          updateOne: (set: CallbackInterface['set'], update: Update<T>) => {
            updateOne(root, set, update);
          },
          updateMany: (set: CallbackInterface['set'], updates: Update<T>[]) => {
            updateMany(root, set, updates);
          },
          upsertOne: (set: CallbackInterface['set'], update: Update<T>) => {
            upsertOne(root, set, update);
          },
          upsertMany: (set: CallbackInterface['set'], updates: Update<T>[]) => {
            upsertMany(root, set, updates);
          },
          removeOne: (set: CallbackInterface['set'], id: string) => {
            removeOne(root, set, id);
          },
          removeMany: (set: CallbackInterface['set'], ids: string[]) => {
            removeMany(root, set, ids);
          },
          setAll: (set: CallbackInterface['set'], entities: T[]) => {
            setAll(root, set, entities);
          },
        },
        selectById: selectorFamily({
          key: `rea/${name}/selectById`,
          get:
            (id: string) =>
            ({ get }) =>
              get(root).entities[id],
          set:
            (id: string) =>
            ({ set }, value: DefaultValue | T | undefined) => {
              if (value instanceof DefaultValue) {
                console.warn(
                  `Did you mean to reset rea/${name}/one for id ${id} ?`,
                );

                return;
              }
              if (value === undefined) {
                removeOne(root, set, id);
              } else {
                upsertOne(root, set, { id, changes: value });
              }
            },
        }),
        selectByIdOrThrow: selectorFamily({
          key: `rea/${name}/selectByIdOrThrow`,
          get:
            (id: string) =>
            ({ get }) => {
              const entity = get(root).entities[id];
              if (!entity) {
                throw new Error(
                  `rea/${name}/selectByIdOrThrow: Entity id ${id} not found `,
                );
              }

              return entity;
            },
          set:
            (id: string) =>
            ({ set }, value: DefaultValue | T) => {
              if (value instanceof DefaultValue) {
                console.warn(
                  `Did you mean to reset rea/${name}/one for id ${id} ?`,
                );

                return;
              }
              if (value === undefined) {
                removeOne(root, set, id);
              } else {
                upsertOne(root, set, { id, changes: value });
              }
            },
        }),
        selectByIdOrReturnDefault: selectorFamily({
          key: `rea/${name}/selectByIdOrReturnDefault`,
          get:
            ([id, defaultValue]: [string, any]) =>
            ({ get }) => {
              const entity = get(root).entities[id];

              return entity || defaultValue;
            },
        }),
        selectAll: selector({
          key: `rea/${name}/selectAll`,
          get: ({ get }) => {
            const rootState = get(root);

            return rootState.ids.map((id) => rootState.entities[id]);
          },
          set: ({ set }, newValue: T[] | DefaultValue) => {
            if (Array.isArray(newValue)) {
              setAll(root, set, newValue);
            } else {
              set(root, newValue);
            }
          },
        }),
        selectTotal: selector({
          key: `rea/${name}/selectTotal`,
          get: ({ get }) => {
            const rootState = get(root);

            return rootState.ids.length;
          },
        }),
        selectIds: selector({
          key: `rea/${name}/selectIds`,
          get: ({ get }) => {
            const rootState = get(root);

            return rootState.ids;
          },
        }),
        selectEntities: selector({
          key: `rea/${name}/selectEntities`,
          get: ({ get }) => {
            const rootState = get(root);

            return rootState.entities;
          },
        }),
      };
};

export const createRecoilEntityAdapterFamily = <
  T extends Entity,
  P extends SerializableParam = string,
>({
  name,
  initialState,
  selectId = (entity: T) => entity.id,
  sortComparer,
  paramState,
}: RecoilEntityAdapterFamilyConfig<T, P>) => {
  const buildState = (state: T[]) => buildEntityState(state, { sortComparer });
  let defaultRootValue:
    | RecoilValue<EntityState<T>>
    | EntityState<T>
    | ((param: P) => RecoilValue<EntityState<T>> | EntityState<T>) = buildState(
    [],
  );

  if (initialState) {
    if (isRecoilValue(initialState)) {
      defaultRootValue = selector({
        key: `rea/${name}/f/defaultValue/recoilValue`,
        get: ({ get }) => buildState(get(initialState)),
      });
    } else if (typeof initialState === 'function') {
      defaultRootValue = selectorFamily({
        key: `rea/${name}/f/defaultValue/recoilFamily`,
        get:
          (param: P) =>
          async ({ get }) => {
            const result = initialState(param);

            if (isRecoilValue(result)) {
              return buildState(get(result));
            }

            if (result instanceof Promise) {
              return buildState(await result);
            }

            return buildState(result);
          },
      });
    } else {
      defaultRootValue = buildState(initialState);
    }
  }
  const root = atomFamily({
    key: `rea/${name}/root`,
    default: defaultRootValue,
  });
  const currentRoot = selector({
    key: `rea/${name}/currentRoot`,
    get: ({ get }) => get(root(get(paramState))),
    set: ({ get, set }, value: EntityState<T> | DefaultValue) => {
      const param = get(paramState);
      set(root(param), value);
    },
  });

  const api = createApi<T, P>({
    selectId,
    name,
    root: currentRoot,
    sortComparer,
  });

  return {
    _root: root,
    _currentRoot: currentRoot,
    ...(api as Extract<typeof api, { _type: 'single' }>),
    members: createApi<T, P>({
      selectId,
      name,
      root,
      sortComparer,
    }) as Extract<typeof api, { _type: 'multiple' }>,
  };
};

export const createRecoilEntityAdapter = <T extends Entity>({
  name,
  initialState,
  selectId = (entity) => entity.id,
  sortComparer,
}: RecoilEntityAdapterConfig<T>) => {
  const buildState = (state: T[]) => buildEntityState(state, { sortComparer });
  let defaultRootValue: EntityState<T> | RecoilValue<EntityState<T>> =
    buildState([]);

  if (initialState) {
    if (isRecoilValue(initialState) || initialState instanceof Promise) {
      defaultRootValue = selector({
        key: `rea/${name}/initial`,
        get: async ({ get }) =>
          initialState instanceof Promise
            ? buildState(await initialState)
            : buildState(get(initialState)),
      });
    } else {
      defaultRootValue = buildState(initialState);
    }
  }

  const root = atom(createRootConfig(name, defaultRootValue));

  const api = createApi<T, any>({
    selectId,
    name,
    root,
    sortComparer,
  });

  return {
    _root: root,
    ...(api as Extract<typeof api, { _type: 'single' }>),
  };
};
