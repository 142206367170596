import { put, select, takeEvery, call } from 'typed-redux-saga';

import { experimentComponentDataMapper } from '../../../../../../api/domainModels/modelPlayground';
import {
  apiGetExperimentComponents,
  apiUpdateExperimentComponent,
  apiGetComponentParameters,
  apiUpdateComponentParameter,
} from '../../../../../../api/requests/modelPlayground';
import { getErrorMessage } from '../../../../../../api/utils';
import { loadAll } from '../../../../../utils/api';
import { activeProjectIdSelector } from '../../../../project/project.selectors';
import {
  activeExperimentIdSelector,
  isActiveExperimentEditableSelector,
} from '../selectors';
import { parseParameterValue } from '../parameters.helpers';
import {
  selectedLossIdSelector,
  lossParameterByIdSelector,
} from './losses.selectors';
import {
  loadLosses,
  loadLossesSuccess,
  loadLossesFailure,
  updateExperimentLoss,
  updateExperimentLossFailure,
  updateExperimentLossSuccess,
  loadLossParameters,
  loadLossParametersSuccess,
  loadLossParametersFailure,
  updateLossParameter,
  updateLossParameterSuccess,
  updateLossParameterFailure,
} from './losses.slice';
import { enqueueNotification } from '../../../../ui/stackNotifications/stackNotifications.slice';
import { Component } from '../../../../../../api/constants/modelPlayground';

function* loadLossesHandler(_action: ActionType<typeof loadLosses>) {
  try {
    const projectId = yield* select(activeProjectIdSelector);
    const experimentId = yield* select(activeExperimentIdSelector);
    const items = yield* loadAll({
      apiHelper: apiGetExperimentComponents,
      params: {
        projectId,
        experimentId,
        componentType: Component.Loss,
      },
    });
    const isEditable = yield* select(isActiveExperimentEditableSelector);
    // as BE does not provide initially selected value for loss
    // we need to make sure it either exists (user selected it) or preselect it for them
    // we'll be working under assumption that loss should always be selected
    const hasLossSelected = items.some((item) => item.selected);
    if (isEditable && items.length > 0 && !hasLossSelected)
      yield* put(updateExperimentLoss(items[0].id));
    yield* put(
      loadLossesSuccess(items.map(experimentComponentDataMapper.fromBackend)),
    );
  } catch (e) {
    yield* put(
      loadLossesFailure(getErrorMessage(e, 'Not able to load losses')),
    );
  }
}

function* updateLossHandler(action: ActionType<typeof updateExperimentLoss>) {
  try {
    const projectId = yield* select(activeProjectIdSelector);
    const experimentId = yield* select(activeExperimentIdSelector);

    if (!experimentId) {
      return;
    }

    const componentType: Component = Component.Loss;
    const params = { projectId, experimentId, componentType };
    const data = { lossId: action.payload };
    const { data: response } = yield* call(() =>
      apiUpdateExperimentComponent(params, data),
    );
    yield* put(
      updateExperimentLossSuccess(
        experimentComponentDataMapper.fromBackend(response),
      ),
    );
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to update loss');

    yield* put(updateExperimentLossFailure(message));
    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
          refresh: false,
        },
      }),
    );
  }
}

function* loadParametersHandler(action: ActionType<typeof loadLossParameters>) {
  try {
    const projectId = yield* select(activeProjectIdSelector);
    const experimentId = yield* select(activeExperimentIdSelector);
    const componentId = action.payload;
    const componentType: Component = Component.Loss;
    const items = yield* loadAll({
      apiHelper: apiGetComponentParameters,
      params: {
        projectId,
        experimentId,
        componentId,
        componentType,
      },
    });
    yield* put(loadLossParametersSuccess(items));
  } catch (e) {
    yield* put(
      loadLossParametersFailure(
        getErrorMessage(e, 'Not able to load parameters'),
      ),
    );
  }
}

function* updateParameterHandler(
  action: ActionType<typeof updateLossParameter>,
) {
  try {
    const projectId = yield* select(activeProjectIdSelector);
    const experimentId = yield* select(activeExperimentIdSelector);
    const componentId = yield* select(selectedLossIdSelector);

    if (!componentId || !experimentId) return;

    const componentType: Component = Component.Loss;
    const params = { projectId, experimentId, componentId, componentType };
    const { lossParameterId, value } = action.payload;
    const data = action.payload;
    const editedParameter = yield* select((state: RootState) =>
      lossParameterByIdSelector(state, lossParameterId),
    );

    if (editedParameter) {
      data.value = parseParameterValue(editedParameter, value);
    }

    const { data: response } = yield* call(
      apiUpdateComponentParameter,
      params,
      data,
    );
    yield* put(
      updateLossParameterSuccess({
        changes: response,
        id: lossParameterId,
      }),
    );
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to update parameter');

    yield* put(updateLossParameterFailure(message));
    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
          refresh: false,
        },
      }),
    );
  }
}

export function* lossesSaga() {
  yield* takeEvery(loadLosses, loadLossesHandler);
  yield* takeEvery(updateExperimentLoss, updateLossHandler);
  yield* takeEvery(loadLossParameters, loadParametersHandler);
  yield* takeEvery(updateLossParameter, updateParameterHandler);
}
