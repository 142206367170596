import { architecturesSaga } from './architectures/architectures.saga';
import { solversSaga } from './solvers/solvers.saga';
import { schedulersSaga } from './schedulers/schedulers.saga';
import { lossesSaga } from './losses/losses.saga';
import { metricsSaga } from './metrics/metrics.saga';
import { regimensSaga } from './regimens/regimens.saga';
import { runsSaga } from './runs/runs.saga';
import { transformsSaga } from './transforms/transforms.saga';
import { SSLSaga } from './SSL/SSL.saga';

export const activeExperimentSagas = [
  architecturesSaga,
  solversSaga,
  schedulersSaga,
  lossesSaga,
  metricsSaga,
  regimensSaga,
  runsSaga,
  transformsSaga,
  SSLSaga,
];
