import { NewToolControlledTooltip } from '../components/imageView/components/controlledTooltip/wrappers/NewTool';
import { ImageTool } from '../redux/state/core/imageView/tools/tools.constants';
import { BasicControlledTooltip } from '../components/imageView/components/controlledTooltip/wrappers/Basic';
import { ControlledTooltipId } from './controlledTooltip';

const FIFTEEN_DAYS_IN_MS = 1296000000;
export const CONTROLLED_TOOLTIP_ENTRIES: {
  [key in ControlledTooltipId]: {
    content: React.FC<{ children: React.ReactElement }>;
    keepAlive?: number;
  };
} = {
  [ControlledTooltipId.NewToolAtom]: {
    content: ({ children }) => (
      <NewToolControlledTooltip
        id={ControlledTooltipId.NewToolAtom}
        toolId={ImageTool.Atom}
        placement="right"
        title="Atom"
        imgSrc="/assets/tool-tooltips/atom-tooltip.gif"
        content="Use Atom to quickly create a precise instance. Place a point in the middle of an object, scribble on the object or draw a box around an object to indicate the instance. After the mask is created you add positive or negative points or scribbles to perfect the annotation before you save it."
      >
        {children}
      </NewToolControlledTooltip>
    ),
    keepAlive: FIFTEEN_DAYS_IN_MS,
  },
  [ControlledTooltipId.KeypointsClassWithNoSchema]: {
    content: ({ children }) => (
      <BasicControlledTooltip
        timeout={5000}
        placement="left"
        arrow
        id={ControlledTooltipId.KeypointsClassWithNoSchema}
        title="You have no keypoints assigned to this label class"
      >
        {children}
      </BasicControlledTooltip>
    ),
  },
  [ControlledTooltipId.SemanticClassWarning]: {
    content: ({ children }) => (
      <BasicControlledTooltip
        timeout={5000}
        placement="left"
        arrow
        id={ControlledTooltipId.SemanticClassWarning}
        title="You can't use keypoints with semantic class"
      >
        {children}
      </BasicControlledTooltip>
    ),
  },
};
