import axios from 'axios';

import { apiWrapper } from '../utils';
import { API_URL } from '../../constants/paths';

export const apiLoadWorkspaceCreditBalance = (workspaceId: string) =>
  apiWrapper(
    axios({
      method: 'get',
      url: `${API_URL}/workspace/${workspaceId}/credit_balance`,
    }),
  );
