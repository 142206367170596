import { call, put, select, takeEvery } from 'typed-redux-saga';

import {
  loadAssistantKeypointSchemaIdFailure,
  loadAssistantKeypointSchemaIdSuccess,
  loadAssistantKeypointSchemaIdStart,
  updateAssistantKeypointSchemaIdSuccess,
  updateAssistantKeypointSchemaIdFailure,
  updateAssistantKeypointSchemaId,
} from './assistantKeypointSchemaId.slice';
import { activeProjectIdSelector } from '../../project.selectors';
import { initImageProject } from '../../../sections/editedProject/project/project.slice';
import { setProjectId } from '../../../core/imageView/project/project.slice';
import {
  apiLoadAssistantKeypointSchema,
  apiUpdateAssistantKeypointSchema,
} from '../../../../../api/requests/keypoints';
import { getErrorMessage } from '../../../../../api/utils';
import { hideModals } from '../../../ui/modals/modals.slice';
import { enqueueNotification } from '../../../ui/stackNotifications/stackNotifications.slice';
import { removeKeypointsSchemaSuccess } from '../keypointsSchemas/keypointsSchemas.slice';

function* loadAssistantKeypointSchemaIdHandler() {
  const projectId = yield* select(activeProjectIdSelector);

  yield* put(loadAssistantKeypointSchemaIdStart());

  try {
    const response = yield* call(apiLoadAssistantKeypointSchema, {
      projectId,
    });
    const schemaId = response.data?.id;
    if (schemaId) {
      yield* put(loadAssistantKeypointSchemaIdSuccess(schemaId));
    }
  } catch (error) {
    yield* put(
      enqueueNotification({
        message: getErrorMessage(
          error,
          'Not able to load assistant keypoint schema',
        ),
        options: { variant: 'error', error, allowOutsideOfEditor: true },
      }),
    );
    yield* put(loadAssistantKeypointSchemaIdFailure());
  }
}

function* updateAssistantKeypointSchemaIdHandler(
  action: ActionType<typeof updateAssistantKeypointSchemaId>,
) {
  const projectId = yield* select(activeProjectIdSelector);

  try {
    yield* call(
      apiUpdateAssistantKeypointSchema,
      { projectId },
      { keypointSchemaId: action.payload },
    );

    yield* put(updateAssistantKeypointSchemaIdSuccess(action.payload));

    yield* put(hideModals());
  } catch (error) {
    yield* put(
      enqueueNotification({
        message: getErrorMessage(
          error,
          'Not able to update the assistant keypoint schema',
        ),
        options: { variant: 'error', error, allowOutsideOfEditor: true },
      }),
    );
    yield* put(updateAssistantKeypointSchemaIdFailure());
  }
}

export function* assistantKeypointSchemaIdSaga() {
  yield* takeEvery(
    [initImageProject, setProjectId, removeKeypointsSchemaSuccess],
    loadAssistantKeypointSchemaIdHandler,
  );
  yield* takeEvery(
    updateAssistantKeypointSchemaId,
    updateAssistantKeypointSchemaIdHandler,
  );
}
