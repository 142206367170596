/* eslint-disable camelcase */
import axios from 'axios';

import {
  ApiSaveAsTemplateFormValues,
  ApiDatasplit,
  ApiExperiment,
  ApiExperimentComponent,
  ApiExperimentParameter,
  ApiParameterValue,
  ApiTransformPreview,
  ApiExperimentRun,
  ApiExperimentTemplate,
  ApiModelExportJob,
  ApiExperimentConfusionMatrixIteration,
  ApiLoadDatasplitsParams,
  ApiLoadDatasplitsResponse,
  ApiCreateDatasplitParams,
  ApiCreateSplitData,
  ApiCreateNestedDatasplitParams,
  ApiCreateNestedSplitData,
  ApiDeleteDatasplitParams,
  ApiLoadTemplatesParams,
  ApiLoadTemplatesResponse,
  ApiRemoveTemplateParams,
  ApiAddTemplateParams,
  ApiCreateExperimentParams,
  ApiCreateExperimentOnDemandParams,
  ApiLoadExperimentsParams,
  ApiLoadExperimentsResponse,
  ApiLoadExperimentExportFormatsParams,
  ApiLoadExperimentExportFormatsResponse,
  ApiLoadExperimentExportFormatOptionsParams,
  ApiLoadExperimentExportFormatOptionsResponse,
  ApiInitiateExperimentExportParams,
  ApiExperimentExportPayload,
  ApiLoadExperimentParams,
  ApiUpdateExperimentParams,
  ApiDeleteExperimentParams,
  ApiCopyExperimentParams,
  ApiGetExperimentComponentsParams,
  ApiGetComponentsResponse,
  ApiAddExperimentComponentParams,
  ApiUpdateExperimentComponentParams,
  ApiDeleteExperimentComponentParams,
  ApiGetSSLParametersResponse,
  ApiGetSSLParametersParams,
  ApiUpdateSSLParameterParams,
  ApiUpdateSSLParameterData,
  ApiUpdateSSLParameterResponse,
  ApiGetComponentParametersParams,
  ApiGetParametersResponse,
  ApiUpdateComponentParameterParams,
  ApiLoadExperimentConfusionMatrixParams,
  ApiGetArchitectureWeightsParams,
  ApiGetWeightsResponse,
  ApiUpdateArchitectureWeightsParams,
  ApiGetExperimentRunsParams,
  ApiGetExperimentRunsResponse,
  ApiGetTransformsPreviewParams,
  ApiStartExperimentRunParams,
  ApiStopTrainingRunParams,
  ApiLoadRestartExperimentTrainingParams,
  ApiLoadRestartExperimentTrainingResponse,
  ApiRestartExperimentTrainingParams,
  ApiRestartExperimentTrainingData,
  ApiSplitClassSummariesParams,
  ApiSplitClassSummariesResponse,
  ApiSplitPartitionObjectsParams,
  ApiSplitPartitionObjectsResponse,
  ApiSplitPartitionsParams,
  ApiSplitPartitionsResponse,
  ApiSplitStrategiesParams,
  ApiSplitStrategiesResponse,
  ApiSplitObjectsNumParams,
  ApiSplitObjectsNumResponse,
  ApiLoadExperimentComparisonParams,
  ApiLoadExperimentComparisonResponse,
  ApiSaveWeightParams,
  ApiSaveTrainedWeightValues,
} from '../schemas/modelPlayground';
import { apiWrapper } from '../utils';
import { NEW_API_URL, API_URL_V2 } from '../../constants/paths';
import {
  ApiDeleteResponse,
  headers,
  transformRequest,
  transformResponse,
} from '../helpers';
import { ImageStatus } from '../domainModels/imageStatus';
import { convertToBackendObject } from '../../redux/utils/backendParams';
import { ModelFamily } from '../constants/modelFamily';
import { Component } from '../constants/modelPlayground';

// Radio casts value to string so we do conversion

export const componentApiSlug: Record<Component, string> = {
  [Component.Model]: 'architectures',
  [Component.Scheduler]: 'schedulers',
  [Component.Solver]: 'solvers',
  [Component.Loss]: 'losses',
  [Component.Metric]: 'metrics',
  [Component.Transform]: 'transforms',
  [Component.Regimen]: 'training_regimens',
  [Component.SSL]: 'semi_supervised_parameters',
};

export const apiLoadDatasplits = (params: ApiLoadDatasplitsParams) =>
  apiWrapper(
    axios.request<ApiLoadDatasplitsResponse>({
      method: 'get',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/splits`,
      params: {
        offset: params.offset,
        limit: params.limit,
      },
    }),
  );

export const apiCreateDatasplit = (
  params: ApiCreateDatasplitParams,
  data: ApiCreateSplitData,
) =>
  apiWrapper(
    axios.request<ApiDatasplit>({
      method: 'post',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/splits`,
      data,
    }),
  );

export const apiCreateNestedDatasplit = (
  params: ApiCreateNestedDatasplitParams,
  data: ApiCreateNestedSplitData,
) =>
  apiWrapper(
    axios.request<ApiDatasplit>({
      method: 'post',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/splits/${params.splitId}`,
      data,
    }),
  );

export const apiDeleteDatasplit = (params: ApiDeleteDatasplitParams) =>
  apiWrapper(
    axios.request<ApiDeleteResponse>({
      method: 'delete',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/splits/${params.splitId}`,
    }),
  );

export const apiLoadDatasplit = (params: ApiDeleteDatasplitParams) =>
  apiWrapper(
    axios.request<ApiDatasplit>({
      method: 'get',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/splits/${params.splitId}`,
    }),
  );

export const apiLoadTemplates = (params: ApiLoadTemplatesParams) =>
  apiWrapper(
    axios.request<ApiLoadTemplatesResponse>({
      method: 'get',
      headers,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/templates`,
      params: convertToBackendObject(params.params),
    }),
  );

export const apiDeleteTemplate = (params: ApiRemoveTemplateParams) =>
  apiWrapper(
    axios.request<void>({
      method: 'delete',
      headers,
      url: `${NEW_API_URL}/projects/${params.projectId}/templates/${params.templateId}`,
    }),
  );

export const apiSaveAsTemplate = (
  params: ApiAddTemplateParams,
  data: ApiSaveAsTemplateFormValues,
) =>
  apiWrapper(
    axios.request<ApiExperimentTemplate>({
      method: 'post',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/experiments/${params.experimentId}/save_as_template`,
      data,
    }),
  );

export const apiCreateExperiment = (
  params: ApiCreateExperimentParams,
  data: Partial<ApiExperiment>,
) =>
  apiWrapper(
    axios.request<ApiExperiment>({
      method: 'post',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/experiments`,
      data,
    }),
  );

export const apiCreateExperimentOnDemand = (
  params: ApiCreateExperimentOnDemandParams,
  data: Partial<ApiExperiment>,
) =>
  apiWrapper(
    axios.request<ApiExperiment>({
      method: 'post',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/on_demand_model/${params.modelFamily}`,
      data,
    }),
  );

export const apiLoadExperiments = (params: ApiLoadExperimentsParams) =>
  apiWrapper(
    axios.request<ApiLoadExperimentsResponse>({
      method: 'get',
      headers,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/experiments`,
      params: convertToBackendObject(params.params),
    }),
  );

export const apiLoadExperimentExportFormats = (
  params: ApiLoadExperimentExportFormatsParams,
) =>
  apiWrapper(
    axios.request<ApiLoadExperimentExportFormatsResponse>({
      method: 'get',
      headers,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/experiments/${params.experimentId}/export_formats`,
      params: convertToBackendObject(params.params),
    }),
  );

export const apiLoadExperimentExportFormatOptions = (
  params: ApiLoadExperimentExportFormatOptionsParams,
) =>
  apiWrapper(
    axios.request<ApiLoadExperimentExportFormatOptionsResponse>({
      method: 'get',
      headers,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/experiments/${params.experimentId}/export_options/${params.exportFormatId}`,
      params: convertToBackendObject(params.params),
    }),
  );

export const apiInitiateExperimentExport = (
  params: ApiInitiateExperimentExportParams,
  data: ApiExperimentExportPayload,
) =>
  apiWrapper(
    axios.request<ApiModelExportJob>({
      method: 'post',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/experiments/${params.experimentId}/exports/${params.format}`,
      data,
    }),
  );

export const apiLoadExperiment = (params: ApiLoadExperimentParams) =>
  apiWrapper(
    axios.request<ApiExperiment>({
      method: 'get',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/experiments/${params.experimentId}`,
    }),
  );

export const apiUpdateExperiment = (
  params: ApiUpdateExperimentParams,
  data: Partial<ApiExperiment>,
) =>
  apiWrapper(
    axios.request<ApiExperiment>({
      method: 'put',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/experiments/${params.experimentId}`,
      data,
    }),
  );

export const apiDeleteExperiment = (params: ApiDeleteExperimentParams) =>
  apiWrapper(
    axios.request<ApiDeleteResponse>({
      method: 'delete',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/experiments/${params.experimentId}`,
    }),
  );

export const apiCopyExperiment = (
  params: ApiCopyExperimentParams,
  data: Partial<ApiExperiment>,
) =>
  apiWrapper(
    axios.request<ApiExperiment>({
      method: 'post',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/experiments/${params.experimentId}/copy`,
      data,
    }),
  );

export const apiGetExperimentComponents = (
  params: ApiGetExperimentComponentsParams,
) =>
  apiWrapper(
    axios.request<ApiGetComponentsResponse>({
      method: 'get',
      headers,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/experiments/${
        params.experimentId
      }/${componentApiSlug[params.componentType]}`,
      params: convertToBackendObject(params.params),
    }),
  );

export const apiAddExperimentComponent = (
  params: ApiAddExperimentComponentParams,
  data: {
    [key: string]: string | number;
  }[],
) =>
  apiWrapper(
    axios.request<ApiExperimentComponent[]>({
      method: 'post',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/experiments/${
        params.experimentId
      }/${componentApiSlug[params.componentType]}`,
      params: convertToBackendObject(params.params),
      data,
    }),
  );

export const apiUpdateExperimentComponent = <
  T extends
    | {
        [key: string]: string | number;
      }
    | {
        [key: string]: string | number;
      }[],
>(
  params: ApiUpdateExperimentComponentParams,
  data: T,
) =>
  apiWrapper(
    axios.request<
      T extends Array<any> ? ApiExperimentComponent[] : ApiExperimentComponent
    >({
      method: 'put',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/experiments/${
        params.experimentId
      }/${componentApiSlug[params.componentType]}`,
      params: convertToBackendObject(params.params),
      data,
    }),
  );

export const apiDeleteExperimentComponent = (
  params: ApiDeleteExperimentComponentParams,
) =>
  apiWrapper(
    axios.request<ApiDeleteResponse>({
      method: 'delete',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/experiments/${
        params.experimentId
      }/${componentApiSlug[params.componentType]}`,
      params: convertToBackendObject(params.params),
    }),
  );

export const apiGetSSLParameters = (params: ApiGetSSLParametersParams) =>
  apiWrapper(
    axios.request<ApiGetSSLParametersResponse>({
      method: 'get',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/experiments/${params.experimentId}/semi_supervised_parameters`,
    }),
  );

export const apiUpdateSSLParameter = (
  params: ApiUpdateSSLParameterParams,
  data: ApiUpdateSSLParameterData,
) =>
  apiWrapper(
    axios.request<ApiUpdateSSLParameterResponse>({
      method: 'put',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/experiments/${params.experimentId}/semi_supervised_parameters`,
      data,
    }),
  );

export const apiGetComponentParameters = (
  params: ApiGetComponentParametersParams,
) =>
  apiWrapper(
    axios.request<ApiGetParametersResponse>({
      method: 'get',
      headers,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/experiments/${
        params.experimentId
      }/${componentApiSlug[params.componentType]}/${
        params.componentId
      }/parameters`,
      params: convertToBackendObject(params.params),
    }),
  );

export const apiUpdateComponentParameter = (
  params: ApiUpdateComponentParameterParams,
  data: {
    architectureParameterId?: string;
    lossParameterId?: string;
    metricParameterId?: string;
    schedulerParameterId?: string;
    solverParameterId?: string;
    transformParameterId?: string;
    regimenId?: string;
    value: ApiParameterValue['value'];
  },
) =>
  apiWrapper(
    axios.request<ApiExperimentParameter>({
      method: 'put',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/experiments/${
        params.experimentId
      }/${componentApiSlug[params.componentType]}/${
        params.componentId
      }/parameters`,
      params: convertToBackendObject(params.params),
      data,
    }),
  );

export const apiLoadExperimentConfusionMatrixIterationsRequest = (
  params: ApiLoadExperimentConfusionMatrixParams,
) =>
  apiWrapper(
    axios.request<ApiExperimentConfusionMatrixIteration>({
      method: 'get',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/widgets/${params.projectId}/experiments/${
        params.experimentId
      }/classification_confusion_matrix?iteration_id=${
        params.iterationId || ''
      }`,
    }),
  );

export const apiGetArchitectureWeights = (
  params: ApiGetArchitectureWeightsParams,
  data: {
    parameterId: string;
    value: ApiParameterValue['value'];
  }[],
) =>
  apiWrapper(
    axios.request<ApiGetWeightsResponse>({
      method: 'post',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/experiments/${params.experimentId}/architectures/${params.architectureId}/weights`,
      data,
      params: convertToBackendObject(params.params),
    }),
  );

export const apiUpdateArchitectureWeights = (
  params: ApiUpdateArchitectureWeightsParams,
  data: {
    architectureParameterId: string;
    weightId: string;
  },
) =>
  apiWrapper(
    axios.request<ApiExperimentParameter>({
      method: 'put',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/experiments/${params.experimentId}/architectures/${params.architectureId}/weights`,
      data,
    }),
  );

export const apiGetExperimentRuns = (params: ApiGetExperimentRunsParams) =>
  apiWrapper(
    axios.request<ApiGetExperimentRunsResponse>({
      method: 'get',
      headers,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/experiments/${params.experimentId}/runs`,
      params: convertToBackendObject(params.params),
    }),
  );

export const apiGetTransformsPreview = (
  params: ApiGetTransformsPreviewParams,
  data: {
    experimentComponentIds: string[];
    objectId?: string;
  },
) =>
  apiWrapper(
    axios.request<ApiTransformPreview>({
      method: 'post',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/experiments/${params.experimentId}/transform_previews`,
      params: convertToBackendObject(params.params),
      data,
    }),
  );

export const apiStartExperimentRun = (params: ApiStartExperimentRunParams) =>
  apiWrapper(
    axios.request<ApiExperimentRun>({
      method: 'post',
      headers,
      transformRequest,
      transformResponse,
      url: `${API_URL_V2}/projects/${params.projectId}/experiments/${params.experimentId}/runs`,
    }),
  );

export const apiStopTrainingRun = (params: ApiStopTrainingRunParams) =>
  apiWrapper(
    axios.request<ApiDeleteResponse>({
      method: 'delete',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/training/${params.projectId}/run/${params.trainJobId}`,
      data: null,
    }),
  );

export const apiLoadRestartExperimentParameters = (
  params: ApiLoadRestartExperimentTrainingParams,
) =>
  apiWrapper(
    axios.request<ApiLoadRestartExperimentTrainingResponse>({
      method: 'get',
      headers,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/experiments/${params.experimentId}/resume`,
    }),
  );

export const apiRestartExperimentRun = (
  params: ApiRestartExperimentTrainingParams,
  data: { parameters: ApiRestartExperimentTrainingData },
) =>
  apiWrapper(
    axios.request<ApiExperimentRun>({
      method: 'post',
      headers,
      transformRequest,
      transformResponse,
      url: `${API_URL_V2}/projects/${params.projectId}/experiments/${params.experimentId}/resume`,
      data,
    }),
  );

export const apiLoadSplitClassSummaries = (
  params: ApiSplitClassSummariesParams,
) =>
  apiWrapper(
    axios.request<ApiSplitClassSummariesResponse>({
      method: 'get',
      headers,
      transformResponse,
      params: convertToBackendObject(params.params),
      url: `${NEW_API_URL}/projects/${params.projectId}/splits/${params.splitId}/class_summaries`,
    }),
  );

export const apiLoadSplitPartitionObjects = (
  params: ApiSplitPartitionObjectsParams,
) =>
  apiWrapper(
    axios.request<ApiSplitPartitionObjectsResponse>({
      method: 'get',
      headers,
      transformResponse,
      params: convertToBackendObject(params.params),
      url: `${NEW_API_URL}/projects/${params.projectId}/splits/${params.splitId}/partitions/${params.partitionId}/objects`,
    }),
  );

export const apiLoadSplitPartitions = (params: ApiSplitPartitionsParams) =>
  apiWrapper(
    axios.request<ApiSplitPartitionsResponse>({
      method: 'get',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/splits/${params.splitId}/partitions`,
    }),
  );

export const apiLoadSplitStrategies = (params: ApiSplitStrategiesParams) =>
  apiWrapper(
    axios.request<ApiSplitStrategiesResponse>({
      method: 'get',
      headers,
      transformRequest,
      url: `${NEW_API_URL}/projects/${params.projectId}/split_strategies`,
    }),
  );

export const apiLoadSplitObjectsNum = (
  params: ApiSplitObjectsNumParams,
  data: {
    datasetIds: string[];
    modelFamily: ModelFamily;
    imageStatuses: ImageStatus[];
    tagClassIds: string[];
    labelClassIds: string[];
    parentSplitId?: string;
  },
) =>
  apiWrapper(
    axios.request<ApiSplitObjectsNumResponse>({
      method: 'post',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/splits_objects_num`,
      data,
    }),
  );

export const apiLoadExperimentComparison = (
  params: ApiLoadExperimentComparisonParams,
  data: { experimentIds: string[] },
) =>
  apiWrapper(
    axios.request<ApiLoadExperimentComparisonResponse>({
      method: 'post',
      headers,
      transformRequest,
      transformResponse,
      data,
      url: `${NEW_API_URL}/projects/${params.projectId}/experiment_comparison`,
    }),
  );

export const apiSavePretrainedWeight = (
  params: ApiSaveWeightParams,
  data: ApiSaveTrainedWeightValues,
) =>
  apiWrapper(
    axios.request({
      method: 'post',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/experiments/${params.experimentId}/save_as_pretrained`,
      data,
    }),
  );
