import { put, select, takeEvery } from 'typed-redux-saga';

import {
  labelClassKeypointsSchemaClassesSelector,
  labelClassKeypointsSchemaSelector,
} from '../../../../../project/annotationTaxonomy/annotationTaxonomy.selectors';
import {
  acceptKeypointsResult,
  addKeypoint,
} from '../keypointsValues/keypointsValues.slice';
import {
  disableOcclusion,
  skipActiveClass,
  setKeypointsClassIndex,
} from './keypointsEditingState.slice';
import {
  activeKeypointsLabelClassSelector,
  currentKeypointsClassIndexSelector,
} from './keypointsEditingState.selectors';

function* addKeypointHandler() {
  const activeClass = yield* select(activeKeypointsLabelClassSelector);
  const keypointsClasses = yield* select((state: RootState) =>
    labelClassKeypointsSchemaClassesSelector(state, activeClass?.id),
  );
  const activeClassIndex = yield* select(currentKeypointsClassIndexSelector);
  const schema = yield* select((state: RootState) =>
    labelClassKeypointsSchemaSelector(state, activeClass?.id),
  );

  if (activeClassIndex === null) return;

  const activeKeypointsClass = keypointsClasses[activeClassIndex];

  if (activeKeypointsClass) {
    if (
      keypointsClasses.length !== activeClassIndex + 1 &&
      activeKeypointsClass.maxPoints === 1
    ) {
      yield* put(setKeypointsClassIndex({ index: activeClassIndex + 1 }));
    } else if (
      keypointsClasses.length === activeClassIndex + 1 &&
      schema?.type === 'pose'
    ) {
      yield* put(acceptKeypointsResult());
    }
  }

  yield* put(disableOcclusion());
}

function* skipActiveClassHandler() {
  const activeClass = yield* select(activeKeypointsLabelClassSelector);
  const keypointsClasses = yield* select((state: RootState) =>
    labelClassKeypointsSchemaClassesSelector(state, activeClass?.id),
  );
  const activeClassIndex = yield* select(currentKeypointsClassIndexSelector);

  if (activeClassIndex === null) return;

  const activeKeypointsClass = keypointsClasses[activeClassIndex];

  if (activeKeypointsClass) {
    if (keypointsClasses.length !== activeClassIndex + 1) {
      yield* put(setKeypointsClassIndex({ index: activeClassIndex + 1 }));
    } else if (keypointsClasses.length === activeClassIndex + 1) {
      yield* put(setKeypointsClassIndex({ index: 0 }));
    }
  }
}

export function* keypointsEditingStateSaga() {
  yield* takeEvery(addKeypoint, addKeypointHandler);
  yield* takeEvery(skipActiveClass, skipActiveClassHandler);
}
