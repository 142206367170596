import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LOCATION_CHANGE } from 'connected-react-router';

import { LabelClassType } from '../../../../api/domainModels/labelClass';
import { ModelFamily } from '../../../../api/constants/modelFamily';
import { PartitionName } from '../../../../api/constants/modelPlayground';
import { CustomerBillingStatus } from '../../../../api/domainModels/payment';

export type ModalPropsConfig = {
  addTransforms: { partition: PartitionName };
  aiCSErrorAttributeDetails: undefined;
  aiCSErrorDetails: undefined;
  aiCSErrorTagDetails: undefined;
  aldiActiveHeuristics: undefined;
  apiSecretKey: { id: string };
  archiveProject: { projectId: string };
  archiveWorkspace: { workspaceId: string };
  bulkChangeStatus: { isImageProject: boolean; datasetId?: string };
  cancelImport: undefined;
  changeExperimentColor: { experimentId: string };
  confirmToggleSAM: { useSAM: boolean };
  confirmNewAtomStrategy: undefined;
  createAICSRun: { id?: string };
  createAldiOnDemandRank: undefined;
  createApiKey: { serviceAccountId: string };
  createDataset: { projectId: string };
  createExperiment: { modelFamily: ModelFamily };
  createExperimentOnDemand: { modelFamily?: ModelFamily };
  createExplainabilityRun: undefined;
  createExternalStorage: undefined;
  createLabelClass: { semanticClassId: string };
  createProjectExport: { projectId: string; isVideoView?: boolean };
  createProjectRole: undefined;
  createServiceAccount: undefined;
  createSplit: undefined;
  createWebhook: undefined;
  createWorkspaceRole: undefined;
  deleteAICSRun: { id: string };
  deleteActivity: { activityId: string };
  deleteApiKey: { id: string };
  deleteAttribute: { attributeId: string };
  deleteDataset: { id: string; changeUrlPath?: boolean };
  deleteExperiment: { id: string };
  deleteExplainabilityRun: { explainabilityRunId: string };
  deleteExportSession: { id: string };
  deleteExternalStorage: { credentialId: string };
  deleteHeuristicSession: { sessionId: string };
  deleteImportSession: { id: string };
  deleteKeypointsSchema: { schemaId: string };
  deleteLabelClass: { labelClassId: string };
  deleteProjectCollaborator: { userId: string };
  deleteProjectRole: { roleId: number };
  deleteServiceAccount: { id: string };
  deleteSplit: { id: string; parentSplitId?: string };
  deleteTagClass: { tagClassId: string };
  deleteTagGroup: { tagGroupId: string };
  deleteTemplate: { templateId: string };
  deleteWebhook: { id: string };
  deleteWorkspaceCollaborator: { userId: string };
  deleteWorkspaceRole: { roleId: number };
  downgradeWorkspacePlan: { planName: string };
  editApiKey: { id: string };
  editDataset: { id: string; showTabs: boolean };
  editExternalStorage: { credentialId: string };
  editImage: { imageId: string };
  editProjectCollaborator: { userId: string };
  editProjectRole: { roleId: number };
  editServiceAccount: { id: string };
  editVideo: { videoId: string };
  editWebhook: { id: string };
  editWorkspaceCollaborator: { userId: string };
  editWorkspaceRole: { roleId: number };
  explainabilityRunResultOverview: undefined;
  extendSplit: { splitId: string };
  importAnnotations: undefined;
  moveProject: { projectId: string; projectName: string; workspaceId: string };
  newProject: { workspaceId?: string; isConvertToImagesProject?: boolean };
  newWorkspace: undefined;
  projectListEditPaymentMethod: undefined;
  projectListPurchaseCompleted: {
    isDowngrade: boolean;
    subscription: CustomerBillingStatus['subscription'];
  };
  projectListUpgradePlanConfirmation: undefined;
  projectReport: { id: string };
  renameImage: undefined;
  restartExperimentTraining: { id: string };
  saveAsTemplate: { experimentId: string };
  saveTrainedWeights: undefined;
  startExperimentFromAIAssistantsSuccess: undefined;
  stopExperiment: { id: string };
  stopExplainabilityRun: { explainabilityRunId: string };
  threeDSecure: { redirectUrl: string };
  toolsSettings: undefined;
  upgradeWorkspacePlan: undefined;
  uploadFromExternalStorage: undefined;
  uploadImages: undefined;
  uploadVideos: undefined;
  upsertActivity: { activityId?: string };
  upsertAttribute: { attributeId?: string; preselectedClassIds?: string[] };
  upsertKeypointsSchema: {
    schemaId?: string;
  };
  upsertLabelClass: {
    labelClassId?: string;
    type?: LabelClassType;
    preLabelCreation?: boolean; // when attempting to create label but there're no classes created
  };
  upsertTagClass: { tagClassId?: string; tagGroupId?: string };
  upsertTagGroup: { tagGroupId?: string };
  userData: undefined;
  warModal: undefined;
  webhookDetails: { id: string };
  workspaceEditPaymentMethod: {
    isSubscribing?: boolean;
    showPayment?: boolean;
  };
  workspacePurchaseCompleted: {
    isCancelingDowngrade?: boolean;
    isDowngrade: boolean;
    subscription: CustomerBillingStatus['subscription'];
  };
  workspaceUpgradePlanConfirmation: { planName: string };
};

type ModalsState =
  | {
      [K in keyof ModalPropsConfig]: undefined extends ModalPropsConfig[K]
        ? { modalName: K; modalProps?: ModalPropsConfig[K] }
        : {
            modalName: K;
            modalProps: ModalPropsConfig[K];
          };
    }[keyof ModalPropsConfig]
  | null;

const { actions, reducer: typedModalsReducer } = createSlice({
  name: 'typedModals',
  initialState: null as ModalsState | null,
  reducers: {
    showModal(_, action: PayloadAction<ModalsState>) {
      return action.payload;
    },
    hideModals() {
      return null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(LOCATION_CHANGE, () => null);
  },
});

export { typedModalsReducer };
export const { showModal, hideModals } = actions;
