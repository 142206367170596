import { put, select, takeEvery } from 'typed-redux-saga';

import { liveUpdateEnabledSelector } from './widgetsOptions.selectors';
import { setLiveUpdate, initializeLiveUpdate } from './widgetsOptions.slice';
import {
  getLocalStorageItem,
  upsertLocalStorageItem,
} from '../../../../../../../helpers/localStorage';
import { LIVE_UPDATE_TOGGLE_STORAGE_PREFIX } from '../../../../../../../helpers/localStorage/constants';

function* setLiveUpdateHandler() {
  const liveUpdatesEnabled = yield* select(liveUpdateEnabledSelector);

  upsertLocalStorageItem(LIVE_UPDATE_TOGGLE_STORAGE_PREFIX, liveUpdatesEnabled);
}

function* initializeLiveUpdateHandler() {
  const liveUpdateEnabledStorageValue = getLocalStorageItem<boolean>(
    LIVE_UPDATE_TOGGLE_STORAGE_PREFIX,
    false,
  );

  yield* put(setLiveUpdate(!!liveUpdateEnabledStorageValue));
}

export function* widgetOptionsSaga() {
  yield* takeEvery(setLiveUpdate, setLiveUpdateHandler);
  yield* takeEvery(initializeLiveUpdate, initializeLiveUpdateHandler);
}
