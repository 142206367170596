import { createSelector } from '@reduxjs/toolkit';

const stackNotificationsSelector = (state: RootState) =>
  state.ui.stackNotifications;

export const stackNotificationsActiveDataSelector = createSelector(
  stackNotificationsSelector,
  (state) =>
    state.notifications.filter((notification) => !notification.dismissed),
);
export const stackNotificationsDataSelector = createSelector(
  stackNotificationsSelector,
  (state) => state.notifications,
);
export const stackNotificationsExpandedSelector = createSelector(
  stackNotificationsSelector,
  (state) => state.expanded,
);
