import { call, put, select, takeEvery } from 'typed-redux-saga';

import { apiLoadCreditsUsage } from '../../../../../api/requests/payment';
import { getErrorMessage } from '../../../../../api/utils';
import {
  loadInvoices,
  loadInvoicesFailure,
  loadInvoicesSuccess,
} from './invoices.slice';
import { workspaceIdSelector } from '../../../commonFeatures/workspaceId/workspaceId.selectors';

function* loadInvoicesHandler() {
  const workspaceId = yield* select(workspaceIdSelector);

  if (!workspaceId) return;

  try {
    const creditsUsage = yield* call(apiLoadCreditsUsage, workspaceId);

    yield* put(
      loadInvoicesSuccess({
        creditsUsage: creditsUsage.data,
      }),
    );
  } catch (error) {
    yield* put(
      loadInvoicesFailure(getErrorMessage(error, 'Not able to load invoices')),
    );
  }
}

export function* invoicesSaga() {
  yield* takeEvery(loadInvoices, loadInvoicesHandler);
}
