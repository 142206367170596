import { call, put, takeEvery } from 'typed-redux-saga';

import {
  apiLoadWorkspace,
  apiUpdateWorkspace,
} from '../../../../../api/requests/workspace';
import { getErrorMessage } from '../../../../../api/utils';
import {
  loadWorkspace,
  updateWorkspace,
  loadWorkspaceFailure,
  loadWorkspaceSuccess,
  updateWorkspaceFailure,
  updateWorkspaceSuccess,
} from './basics.slice';
import { loadWorkspaces } from '../../projectList/workspaces/workspaces.slice';

function* loadWorkspaceHandler(action: ActionType<typeof loadWorkspace>) {
  const workspaceId = action.payload;

  try {
    const { data: workspace } = yield* call(apiLoadWorkspace, { workspaceId });

    yield* put(loadWorkspaceSuccess(workspace));
  } catch (error) {
    yield* put(
      loadWorkspaceFailure(
        getErrorMessage(error, 'Not able to load workspace'),
      ),
    );
  }
}

function* updateWorkspaceHandler(action: ActionType<typeof updateWorkspace>) {
  const { workspaceId, ...data } = action.payload;

  if (!workspaceId) return;

  try {
    const { data: workspace } = yield* call(
      apiUpdateWorkspace,
      { workspaceId },
      data,
    );

    yield* put(loadWorkspaces());
    yield* put(updateWorkspaceSuccess(workspace));
  } catch (error) {
    yield* put(
      updateWorkspaceFailure(
        getErrorMessage(error, 'Not able to update workspace'),
      ),
    );
  }
}

export function* basicsSaga() {
  yield* takeEvery(updateWorkspace, updateWorkspaceHandler);
  yield* takeEvery(loadWorkspace, loadWorkspaceHandler);
}
