import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const { actions, reducer: fingerInputReducer } = createSlice({
  name: 'fingerInput',
  initialState: {
    allowed: false,
  },
  reducers: {
    toggleFingerInputAllowed(state) {
      state.allowed = !state.allowed;
    },
    setFingerInputAllowed(state, action: PayloadAction<{ allowed: boolean }>) {
      state.allowed = action.payload.allowed;
    },
    checkFingerInputAllowed() {},
  },
});

export { fingerInputReducer };
export const {
  toggleFingerInputAllowed,
  setFingerInputAllowed,
  checkFingerInputAllowed,
} = actions;
