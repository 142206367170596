import { createSelector } from '@reduxjs/toolkit';

import { authUserIdSelector } from '../auth/auth.selectors';
import { adapter } from './locks.slice';
import { projectUsersSelector } from '../../project/projectUsers/projectUsers.selectors';
import { commonFeaturesSelector } from '../commonFeatures.selectors';

const locksStateSelector = createSelector(
  commonFeaturesSelector,
  (commonFeatures) => commonFeatures.locks,
);
const selectors = adapter.getSelectors(locksStateSelector);

export const subjectLocksSelector = selectors.selectAll;
export const subjectLocksMapSelector = selectors.selectEntities;
export const subjectLockByIdSelector = selectors.selectById;

export const subjectLocksLoadedSelector = createSelector(
  locksStateSelector,
  (state) => state.loaded,
);

export const isSubjectLockedByIdSelector = createSelector(
  [
    (state: RootState) => state,
    (_: RootState, subjectId: string | null) => subjectId,
    authUserIdSelector,
  ],
  (state, subjectId, currentUserId) => {
    if (!subjectId) return false;

    const lock = subjectLockByIdSelector(state, subjectId);

    if (!lock) return false;

    return lock?.lockedById !== currentUserId;
  },
);

export const isSubjectLockedByCurrentUserSelector = createSelector(
  [
    (state: RootState) => state,
    (_: RootState, subjectId: string) => subjectId,
    authUserIdSelector,
  ],
  (state, subjectId, currentUserId) => {
    const lock = subjectLockByIdSelector(state, subjectId);

    if (!lock) return false;

    return lock?.lockedById === currentUserId;
  },
);

export const activeUsersSelector = createSelector(
  [projectUsersSelector, subjectLocksSelector],
  (users, locks) => {
    if (!locks) return [];

    const usersIdsFromLocks = locks.map((lock) => lock.lockedById);

    return users.filter((user) => usersIdsFromLocks.includes(user.id));
  },
);
