import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import undoable from 'redux-undo';

import { Bbox } from '../../../../../../@types/imageView/types';

export type AtomPoint = [number, number, 1 | -1];
type AtomValuesState = {
  points: AtomPoint[];
  visibleAreaBbox: Bbox;
  inputBbox: Bbox;
  inputScribbleMask: number[] | null;
  inputScribbleBbox: Bbox;
};

const initialState: AtomValuesState = {
  points: [],
  visibleAreaBbox: [0, 0, 0, 0],
  inputBbox: [0, 0, 0, 0],
  inputScribbleMask: null,
  inputScribbleBbox: [0, 0, 0, 0],
};

const name = 'imageViewAtomValues';
const { actions, reducer } = createSlice({
  name,
  initialState,
  reducers: {
    addAtomPoint(
      state,
      action: PayloadAction<{ point: AtomPoint; visibleAreaBbox: Bbox }>,
    ) {
      state.points.push(action.payload.point);
      state.visibleAreaBbox = action.payload.visibleAreaBbox;
    },
    removeLastAtomPoint(state) {
      state.points.pop();
    },
    moveAtomPoint(
      state,
      action: PayloadAction<{
        index: number;
        position: AtomPoint;
        visibleAreaBbox: Bbox;
      }>,
    ) {
      state.points[action.payload.index] = action.payload.position;
    },
    clearAtomValues() {
      return initialState;
    },
    undoAtom() {},
    redoAtom() {},
    clearAtomHistory() {},
    addAtomBbox(
      state,
      action: PayloadAction<{ bbox: Bbox; visibleAreaBbox: Bbox }>,
    ) {
      state.inputBbox = action.payload.bbox;
      state.visibleAreaBbox = action.payload.visibleAreaBbox;
    },
    addAtomScribble(
      state,
      action: PayloadAction<{
        mask: number[];
        bbox: Bbox;
        visibleAreaBbox: Bbox;
      }>,
    ) {
      state.inputScribbleMask = action.payload.mask;
      state.inputScribbleBbox = action.payload.bbox;
      state.visibleAreaBbox = action.payload.visibleAreaBbox;
    },
  },
});

export const {
  addAtomPoint,
  moveAtomPoint,
  removeLastAtomPoint,
  clearAtomValues,
  undoAtom,
  redoAtom,
  clearAtomHistory,
  addAtomBbox,
  addAtomScribble,
} = actions;

export const imageViewAtomValuesReducer = undoable(reducer, {
  undoType: undoAtom.type,
  redoType: redoAtom.type,
  clearHistoryType: clearAtomHistory.type,
});
