/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PaginationMetaV2 = {
  /**
   * Name of the retrieved object
   */
  object: string;
  /**
   * Limit for this paginated result
   */
  limit: number;
  /**
   * Page navigation direction (NEXT or PREV)
   */
  page?: PaginationMetaV2.page;
  /**
   * Cursor to fetch next page
   */
  cursorNext?: string | null;
  /**
   * Cursor to fetch previous page
   */
  cursorPrev?: string | null;
};

export namespace PaginationMetaV2 {

  /**
   * Page navigation direction (NEXT or PREV)
   */
  export enum page {
    NEXT = 'NEXT',
    PREV = 'PREV',
  }


}

