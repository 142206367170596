/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ImageStatus } from './ImageStatus';

export type Image1 = {
  /**
   * Dataset ID
   */
  datasetId?: string;
  /**
   * Name of the dataset
   */
  datasetName?: string;
  /**
   * Image height
   */
  height?: number | null;
  /**
   * Image ID
   */
  id?: string;
  /**
   * Format of the image (JPEG, PNG, TIFF, etc.).
   */
  format?: string | null;
  /**
   * Image mode
   */
  mode?: string | null;
  /**
   * Image filename
   */
  name?: string;
  /**
   * Image health status
   */
  healthStatus?: Image1.healthStatus;
  status?: ImageStatus;
  /**
   * Whether this image is locked by another user
   */
  isLocked?: boolean;
  /**
   * Publicly accessible image URL
   */
  publicUrl?: string;
  /**
   * URL to thumbnail
   */
  thumbnailUrl?: string;
  /**
   * Image upload date
   */
  uploadedOn?: string;
  /**
   * Last image update timestamp
   */
  updateDate?: string | null;
  /**
   * User who edited the image last
   */
  updatedBy?: string | null;
  /**
   * Internal image location path
   */
  url?: string | null;
  /**
   * Image width
   */
  width?: number | null;
  /**
   * Stores an external ID, used for mapping.
   */
  externalId?: string | null;
};

export namespace Image1 {

  /**
   * Image health status
   */
  export enum healthStatus {
    OK = 'OK',
    ERROR = 'ERROR',
    NO_META = 'NO_META',
    UNHEALTHY = 'UNHEALTHY',
  }


}

