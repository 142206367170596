import { put, select, takeEvery } from 'typed-redux-saga';

import { loadAllFilteredExperiments } from '../experiments/experimentsData/experimentsData.slice';
import { activeExperimentSaga } from './activeExperiment/activeExperiment.saga';
import { loadMatchingExperimentsAndTemplates } from './dashboard.actions';
import { widgetsSagas } from './widgets/widgets.saga';
import { loadTemplates } from '../experiments/templates/templates.slice';
import { activeSplitSelector } from '../activeSplit/activeSplitId/activeSplitId.selectors';

function* loadMatchingExperimentsAndTemplatesHandler() {
  // TODO:: consider moving dashboard under an active split
  const activeSplit = yield* select(activeSplitSelector);

  if (!activeSplit) return;

  const { modelFamily, id } = activeSplit;
  const filters = { modelFamily, splitId: id };

  yield* put(loadAllFilteredExperiments(filters));
  yield* put(loadTemplates({ modelFamily }));
}

function* dashboardSaga() {
  yield* takeEvery(
    loadMatchingExperimentsAndTemplates,
    loadMatchingExperimentsAndTemplatesHandler,
  );
}

export const dashboardSagas = [
  activeExperimentSaga,
  dashboardSaga,
  ...widgetsSagas,
];
