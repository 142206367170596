/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AttributeLOV1 } from './AttributeLOV1';

export type Attribute1 = {
  /**
   * Attribute ID
   */
  readonly id?: string | null;
  /**
   * Attribute name
   */
  name: string;
  /**
   * Attribute type
   */
  type: Attribute1.type;
  /**
   * Default value
   */
  default?: string | null;
  /**
   * Attribute description
   */
  description?: string | null;
  /**
   * Min value for int and float type
   */
  min?: number | null;
  /**
   * Max value for int and float type
   */
  max?: number | null;
  /**
   * Attribute ordering
   */
  norder?: number;
  /**
   * Number of classes that this attribute is assigned to
   */
  readonly classCount?: number;
  /**
   * List of possible values for attributes of type SELECTION or MULTIPLE-SELECTION
   */
  values?: Array<AttributeLOV1> | null;
};

export namespace Attribute1 {

  /**
   * Attribute type
   */
  export enum type {
    SELECTION = 'SELECTION',
    MULTIPLE_SELECTION = 'MULTIPLE-SELECTION',
    TEXT = 'TEXT',
    INT = 'INT',
    FLOAT = 'FLOAT',
    BOOL = 'BOOL',
  }


}

