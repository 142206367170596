import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
  Update,
} from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import {
  AutomatedLabelingJob,
  AutomatedLabelingEstimation,
} from '../../../../api/domainModels/automatedLabeling';
import { loadingStateBuilder } from '../../../utils/loadingState';
import { ModelFamilyStatus } from '../../../../api/codegen';

export type StartAutomatedLabelingFormValues = {
  modelId: number;
  datasetId?: string;
  confidenceThreshold: number;
  numImages?: number;
  updateNoPredictions?: boolean;
};

export const jobsAdapter = createEntityAdapter<AutomatedLabelingJob>({
  selectId: ({ jobId }) => jobId,
  sortComparer: (a, b) => (dayjs(a.startDate).isAfter(b.startDate) ? -1 : 1),
});

const initialState = {
  jobs: jobsAdapter.getInitialState({
    listLoadingState: loadingStateBuilder.initial(),
    startLoadingState: loadingStateBuilder.initial(),
  }),
  estimation: {
    listLoadingState: loadingStateBuilder.initial(),
    data: {
      avgLabelsPerImage: 0,
      estimatedCost: 0,
      unlabeledImages: 0,
    } as AutomatedLabelingEstimation,
  },
};

const { actions, reducer: automatedLabelingReducer } = createSlice({
  name: 'automatedLabeling',
  initialState,
  reducers: {
    loadAutomatedLabelingJobsStart(
      state,
      _action: PayloadAction<{ projectId: string }>,
    ) {
      state.jobs.listLoadingState = loadingStateBuilder.inProgress();
    },
    loadAutomatedLabelingJobsSuccess(
      state,
      action: PayloadAction<AutomatedLabelingJob[]>,
    ) {
      state.jobs.listLoadingState = loadingStateBuilder.success();
      state.jobs.startLoadingState = loadingStateBuilder.initial();
      jobsAdapter.setAll(state.jobs, action.payload);
    },
    loadAutomatedLabelingJobsFailure(state, action: PayloadAction<string>) {
      state.jobs.listLoadingState = loadingStateBuilder.failure(action.payload);
    },
    updateAutomatedLabelingJobStatusFromWs(
      _,
      _action: PayloadAction<{ projectId: string }>,
    ) {},
    updateAutomatedLabelingJobProgressFromWs(
      _,
      _action: PayloadAction<AutomatedLabelingJob & { projectId: string }>,
    ) {},
    updateAutomatedLabelingJob(
      state,
      action: PayloadAction<Update<AutomatedLabelingJob>>,
    ) {
      jobsAdapter.updateOne(state.jobs, action.payload);
    },
    loadAutomatedLabelingEstimationStart(
      state,
      _action: PayloadAction<{
        modelId: number;
        datasetId?: string;
        quantity?: number;
      }>,
    ) {
      state.estimation.listLoadingState = loadingStateBuilder.inProgress();
    },
    loadAutomatedLabelingEstimationSuccess(
      state,
      action: PayloadAction<AutomatedLabelingEstimation>,
    ) {
      state.estimation.listLoadingState = loadingStateBuilder.success();
      state.estimation.data = action.payload;
    },
    loadAutomatedLabelingEstimationFailure(
      state,
      action: PayloadAction<string>,
    ) {
      state.estimation.listLoadingState = loadingStateBuilder.failure(
        action.payload,
      );
    },
    startAutomatedLabelingJobStart(
      state,
      _action: PayloadAction<
        StartAutomatedLabelingFormValues & {
          modelFamily?: ModelFamilyStatus.modelFamily;
        }
      >,
    ) {
      state.jobs.startLoadingState = loadingStateBuilder.inProgress();
    },
    startAutomatedLabelingJobSuccess(
      state,
      action: PayloadAction<AutomatedLabelingJob>,
    ) {
      state.jobs.startLoadingState = loadingStateBuilder.success();
      jobsAdapter.addOne(state.jobs, action.payload);
    },
    startAutomatedLabelingJobFailure(state, action: PayloadAction<string>) {
      state.jobs.startLoadingState = loadingStateBuilder.failure(
        action.payload,
      );
    },
    resetAutomatedLabelingLoadingStates(state) {
      state.jobs.listLoadingState = loadingStateBuilder.initial();
      state.estimation.listLoadingState = loadingStateBuilder.initial();
    },
  },
});

export { automatedLabelingReducer };

export const {
  loadAutomatedLabelingJobsStart,
  loadAutomatedLabelingJobsSuccess,
  loadAutomatedLabelingJobsFailure,
  updateAutomatedLabelingJobStatusFromWs,
  updateAutomatedLabelingJobProgressFromWs,
  updateAutomatedLabelingJob,
  loadAutomatedLabelingEstimationStart,
  loadAutomatedLabelingEstimationSuccess,
  loadAutomatedLabelingEstimationFailure,
  startAutomatedLabelingJobStart,
  startAutomatedLabelingJobSuccess,
  startAutomatedLabelingJobFailure,
  resetAutomatedLabelingLoadingStates,
} = actions;
