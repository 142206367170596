import { call, put, takeEvery } from 'typed-redux-saga';

import { apiLoadProject } from '../../../../api/requests/project';
import { transformResponse } from '../../../../api/helpers';
import { getErrorMessage } from '../../../../api/utils';
import { subscribeSubject } from '../../ws/ws.actions';
import {
  loadProjectInfo,
  loadProjectInfoFailure,
  loadProjectInfoSuccess,
} from './projectInfo.slice';
import { setWorkspaceId } from '../../commonFeatures/workspaceId/workspaceId.slice';

function* loadProjectInfoHandler({
  payload,
}: ActionType<typeof loadProjectInfo>) {
  const { projectId, eventsList } = payload;

  try {
    const response = yield* call(apiLoadProject, { projectId });
    const project = transformResponse(JSON.stringify(response.data));

    if (!project) {
      throw new Error('Project not found');
    }
    yield* put(
      subscribeSubject({
        identifier: 'project',
        id: project.projectId,
        authKey: project.wsAuthKey,
        eventsList,
      }),
    );
    yield* put(loadProjectInfoSuccess(project));
    yield* put(setWorkspaceId({ workspaceId: project.workspaceId }));
  } catch (error) {
    yield* put(
      loadProjectInfoFailure(
        getErrorMessage(error, `Not able to find project with id ${projectId}`),
      ),
    );
  }
}

export function* projectInfoSaga() {
  yield* takeEvery(loadProjectInfo, loadProjectInfoHandler);
}
