import { createSelector } from '@reduxjs/toolkit';

import { statusChecks } from '../../../../../../constants/status';
import { adapter } from './data.slice';
import { Datasplit } from '../../../../../../api/domainModels/modelPlayground';
import { modelPlaygroundSelector } from '../../modelPlayground.selectors';

export const splitsStateSelector = createSelector(
  modelPlaygroundSelector,
  (modelPlayground) => modelPlayground.splits.data,
);

const selectors = adapter.getSelectors(splitsStateSelector);

export const splitsSelector = selectors.selectAll;
export const splitByIdSelector = selectors.selectById;
export const splitsMapSelector = selectors.selectEntities;

export const combinedSplitsSelector = createSelector(
  splitsSelector,
  (splits) => {
    const nestedSplits = splits.reduce(
      (splits, split) => [...splits, ...(split.children || [])],
      [] as Datasplit[],
    );

    return [...splits, ...nestedSplits];
  },
);
export const combinedSplitsByIdSelector = createSelector(
  (_: RootState, splitId: string) => splitId,
  combinedSplitsSelector,
  (splitId, splits) => splits.find((split) => split.id === splitId),
);
export const splitsListLoadingStateSelector = createSelector(
  splitsStateSelector,
  (state) => state.loadingState,
);
export const splitsItemLoadingStateSelector = createSelector(
  splitsStateSelector,
  (state) => state.itemLoadingState,
);

export const splitsItemIsSavingSelector = createSelector(
  splitsStateSelector,
  (state) => statusChecks.isInProgress(state.itemLoadingState.status),
);
