import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { ErrorLabelConfidence } from '../../../../../api/domainModels/consensusScoring';
import { loadingStateBuilder } from '../../../../utils/loadingState';

export const adapter = createEntityAdapter<ErrorLabelConfidence>({
  selectId: (item) => item.classId,
});

const { actions, reducer: labelConfidenceReducer } = createSlice({
  name: 'labelConfidence',
  initialState: adapter.getInitialState({
    loadingState: loadingStateBuilder.initial(),
  }),
  reducers: {
    loadLabelConfidence(state, _action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.inProgress();
      adapter.removeAll(state);
    },
    loadLabelConfidenceSuccess(
      state,
      action: PayloadAction<ErrorLabelConfidence[]>,
    ) {
      state.loadingState = loadingStateBuilder.success();
      adapter.setAll(state, action.payload);
    },
    loadLabelConfidenceFailure(state, action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.failure(action.payload);
      adapter.setAll(state, []);
    },
  },
});

export { labelConfidenceReducer };
export const {
  loadLabelConfidence,
  loadLabelConfidenceSuccess,
  loadLabelConfidenceFailure,
} = actions;
