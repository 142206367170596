import { combineReducers } from 'redux';

import { classificationInferencesReducer } from './classificationInferences/classificationInferences.slice';
import { experimentsMetricsReducer } from './experimentsMetrics/experimentsMetrics.slice';
import { experimentComparisonReducer } from './experimentComparison/experimentComparison.slice';
import { confusionMatrixReducer } from './confusionMatrix/confusionMatrix.slice';
import { runningTimeReducer } from './runningTime/runningTime.slice';
import { inferenceTimeReducer } from './inferenceTime/inferenceTime.slice';
import { metricsPerIterationReducer } from './metricsPerIteration/metricsPerIteration.slice';
import { bestPerformingReducer } from './bestPerforming/bestPerforming.slice';
import { multipleGpuConsumptionReducer } from './multipleGpuConsumption/multipleGpuConsumption.slice';
import { widgetsOptionsReducer } from './widgetsOptions/widgetsOptions.slice';
import { taggerInferencesReducer } from './taggerInferences/taggerInferences.slice';
import { attributerInferencesReducer } from './attributerInferences/attributerInferences.slice';
import { detectorInferencesReducer } from './detectorInferences/detectorInferences.slice';
import { segmentorInferencesReducer } from './segmentorInferences/segmentorInferences.slice';
import { semanticInferencesReducer } from './semanticInferences/semanticInferences.slice';
import { trainParamsVisualizationReducer } from './trainParamsVisualization/trainParamsVisualization.slice';
import { explainabilityReducer } from './explainability/explainability.slice';

export const widgetsReducer = combineReducers({
  experimentsMetrics: experimentsMetricsReducer,
  classificationInferences: classificationInferencesReducer,
  confusionMatrix: confusionMatrixReducer,
  experimentComparison: experimentComparisonReducer,
  runTime: runningTimeReducer,
  inferenceTime: inferenceTimeReducer,
  metricsPerIteration: metricsPerIterationReducer,
  bestPerforming: bestPerformingReducer,
  multipleGpuConsumption: multipleGpuConsumptionReducer,
  widgetsOptions: widgetsOptionsReducer,
  taggerInferences: taggerInferencesReducer,
  attributerInferences: attributerInferencesReducer,
  detectorInferences: detectorInferencesReducer,
  segmentorInferences: segmentorInferencesReducer,
  semanticInferences: semanticInferencesReducer,
  trainParamsVisualization: trainParamsVisualizationReducer,
  explainability: explainabilityReducer,
});
