import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AttributePredictions } from '../../../../../../api/domainModels/attribute';
import {
  LoadingState,
  loadingStateBuilder,
} from '../../../../../utils/loadingState';
import {
  ModelStatus,
  modelStatusChecks,
  statusCheck,
  ToolModel,
} from '../models/modelStatus.constants';

type AttributesPredictionState = {
  model: ToolModel;
  data: { predictions: AttributePredictions } & { loadingState: LoadingState };
  threshold: number;
};
const initialState: AttributesPredictionState = {
  model: {
    id: null,
    loadingState: loadingStateBuilder.initial(),
    status: null,
    progress: null,
    showDot: false,
  },
  data: {
    predictions: {},
    loadingState: loadingStateBuilder.initial(),
  },
  threshold: 0.5,
};
const { actions, reducer: labelAttributesPredictionReducer } = createSlice({
  name: 'imageViewAttributesPrediction',
  initialState,
  reducers: {
    loadAttributesPredictionModel(_state, _action: PayloadAction<string>) {},
    loadAttributesPredictionModelError(state, action: PayloadAction<string>) {
      state.model.loadingState = loadingStateBuilder.failure(action.payload);
    },
    loadAttributesPredictionModelStart(state, action: PayloadAction<string>) {
      state.model.loadingState = loadingStateBuilder.inProgress(action.payload);
    },
    loadAttributesPredictionModelSuccess(
      state,
      action: PayloadAction<
        | {
            id: number | null;
            status: ModelStatus;
            progress: number | null;
          }
        | undefined
      >,
    ) {
      state.model.loadingState = loadingStateBuilder.success();

      if (!action.payload) {
        return state;
      }

      const { id, status, progress } = action.payload;

      state.model = {
        ...state.model,
        id,
        status,
        progress: progress || 0,
      };
    },
    loadAttributesPrediction() {},
    loadAttributesPredictionError(state, action: PayloadAction<string>) {
      state.data.loadingState = loadingStateBuilder.failure(action.payload);
    },
    loadAttributesPredictionStart(state) {
      state.data.predictions = {};
      state.data.loadingState = loadingStateBuilder.inProgress();
    },
    loadAttributesPredictionSuccess(
      state,
      action: PayloadAction<AttributePredictions>,
    ) {
      state.data.loadingState = loadingStateBuilder.success();
      state.data.predictions = action.payload;
    },
    updateAttributesPredictionModel(
      _state,
      _action: PayloadAction<{
        status: ModelStatus;
        progress: number;
        id: number;
        modelUseIE?: boolean;
      }>,
    ) {},
    updateAttributesPredictionModelSuccess(
      state,
      action: PayloadAction<{
        id: number | null;
        status: ModelStatus;
        progress: number | null;
      }>,
    ) {
      const { id, status, progress } = action.payload;

      state.model = {
        ...state.model,
        id: modelStatusChecks.isLoaded(status) ? id : state.model.id,
        status: statusCheck(status, state.model.status),
        progress: progress || 0,
      };
    },
    resetData(state) {
      state.data = initialState.data;
    },
    resetModel(state) {
      state.model = initialState.model;
    },
    updateAttributesPredictionModelShowDot(
      state,
      action: PayloadAction<boolean>,
    ) {
      const showDot = action.payload;

      state.model = {
        ...state.model,
        showDot,
      };
    },
    setAttributePredictionThreshold(
      state,
      action: PayloadAction<{ threshold: number }>,
    ) {
      state.threshold = action.payload.threshold;
    },
  },
});

export const {
  loadAttributesPrediction,
  loadAttributesPredictionError,
  loadAttributesPredictionModel,
  loadAttributesPredictionModelError,
  loadAttributesPredictionModelStart,
  loadAttributesPredictionModelSuccess,
  loadAttributesPredictionStart,
  loadAttributesPredictionSuccess,
  resetData,
  resetModel,
  setAttributePredictionThreshold,
  updateAttributesPredictionModel,
  updateAttributesPredictionModelShowDot,
  updateAttributesPredictionModelSuccess,
} = actions;
export { labelAttributesPredictionReducer };
