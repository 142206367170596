import { createSelector } from '@reduxjs/toolkit';

import {
  managedWorkspaceIsFreeSelector,
  managedWorkspaceSelector,
} from '../managedWorkspace.selectors';
import { WORKSPACE_OWNER_ROLE_ID } from '../managedWorkspace.constants';

export const managedWorkspaceRolesSelector = createSelector(
  managedWorkspaceSelector,
  (managedWorkspace) => managedWorkspace.roles,
);

export const managedWorkspaceRolesNeedsSaveSelector = createSelector(
  managedWorkspaceRolesSelector,
  (roles) => {
    const rolePrivilegeGroups = roles.rolePrivileges.data;

    return (
      rolePrivilegeGroups instanceof Object &&
      Object.values(rolePrivilegeGroups).some(({ privileges }) =>
        Object.values(privileges).some(({ values }) =>
          Object.values(values).some(
            ({ value, originalValue }) => value !== originalValue,
          ),
        ),
      )
    );
  },
);

export const managedWorkspaceRolePrivilegesSelector = createSelector(
  managedWorkspaceRolesSelector,
  (roles) => roles.rolePrivileges,
);

export const managedWorkspaceRolePrivilegesDataSelector = createSelector(
  managedWorkspaceRolesSelector,
  (roles) => roles.rolePrivileges.data,
);

export const managedWorkspaceRolesDataSelector = createSelector(
  managedWorkspaceRolesSelector,
  (roles) => roles.data,
);

export const managedWorkspaceRolesUpdateLoadingStateSelector = createSelector(
  managedWorkspaceRolesSelector,
  (roles) => roles.updateLoadingState,
);

export const managedWorkspaceRolesActionLoadingStateSelector = createSelector(
  managedWorkspaceRolesSelector,
  (roles) => roles.actionLoadingState,
);

export const managedWorkspaceRolesAsOptionsSelector = createSelector(
  managedWorkspaceRolesDataSelector,
  managedWorkspaceIsFreeSelector,
  (roles, isFree) => {
    if (!roles) {
      return [];
    }

    return roles.map(({ id, name }) => ({
      key: id,
      value: id,
      text: name,
      disabled: isFree && id !== WORKSPACE_OWNER_ROLE_ID,
    }));
  },
);

export const managedWorkspaceRolesLoadingStateSelector = createSelector(
  managedWorkspaceRolesSelector,
  (roles) => roles.loadingState,
);

export const managedWorkspaceRoleColorsSelector = createSelector(
  managedWorkspaceRolesSelector,
  (roles) => (roles?.data || []).map(({ color }) => color),
);

export const managedWorkspaceRoleNameByIdSelector = createSelector(
  managedWorkspaceRolesDataSelector,
  (_: RootState, id: number) => id,
  (roles, id) =>
    roles?.length
      ? (roles.find((role: any) => role.id === id)?.name as string)
      : undefined,
);
