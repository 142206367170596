import axios from 'axios';

import {
  ApiImportSession,
  ApiImportFile,
  ApiImportLabelClass,
  ApiLoadImportSessionParams,
  ApiLoadApiImportSession,
  ApiCreateImportSessionParams,
  ApiUpdateImportSessionParams,
  ApiDeleteImportSessionParams,
  ApiFinalizeImportSessionParams,
  ApiFinalizeImportSessionData,
  ApiLoadImportedFilesParams,
  ApiLoadApiImportFile,
  ApiDeleteImportedFileParams,
  ApiUploadImportFileParams,
  ApiImportUploadUrlsParams,
  ApiImportUploadToCloudParams,
  ApiParseImportedFilesParams,
  ApiParseLogsImportSessionParams,
  ApiLoadApiImportFileParseLog,
  ApiLoadAllClassesForMatchingParams,
  ApiLoadAllApiImportLabelClass,
  ApiLoadClassForMatchingParams,
  ApiUpdateClassForMatchingParams,
} from '../schemas/imports';
import { NEW_API_URL } from '../../constants/paths';
import { apiWrapper } from '../utils';
import {
  headers,
  transformResponse,
  transformRequest,
  ApiDeleteResponse,
} from '../helpers';
import { ImportSessionFormValues } from '../domainModels/imports';

export const apiLoadImportSessions = (params: ApiLoadImportSessionParams) =>
  apiWrapper(
    axios.request<ApiLoadApiImportSession>({
      headers,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/import_sessions`,
      params: {
        limit: params.limit,
        offset: params.offset,
      },
    }),
  );

export const apiCreateImportSession = (
  params: ApiCreateImportSessionParams,
  data: ImportSessionFormValues,
) =>
  apiWrapper(
    axios.request<ApiImportSession>({
      method: 'post',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/import_sessions`,
      data,
    }),
  );

export const apiUpdateImportSession = (
  params: ApiUpdateImportSessionParams,
  data: ImportSessionFormValues,
) =>
  apiWrapper(
    axios.request<ApiImportSession>({
      method: 'put',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/import_sessions/${params.sessionId}`,
      data,
    }),
  );

export const apiDeleteImportSession = (params: ApiDeleteImportSessionParams) =>
  apiWrapper(
    axios.request<ApiDeleteResponse>({
      method: 'delete',
      headers,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/import_sessions/${params.sessionId}`,
      data: null,
    }),
  );

export const apiFinalizeImportSession = (
  params: ApiFinalizeImportSessionParams,
  data: ApiFinalizeImportSessionData,
) =>
  apiWrapper(
    axios.request<{ id: string }>({
      method: 'post',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/import_sessions/${params.sessionId}/import`,
      data,
    }),
  );

export const apiLoadImportedFiles = (params: ApiLoadImportedFilesParams) =>
  apiWrapper(
    axios.request<ApiLoadApiImportFile>({
      headers,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/import_sessions/${params.sessionId}/files`,
      params: {
        limit: params.limit,
        offset: params.offset,
      },
    }),
  );

export const apiDeleteImportedFile = (params: ApiDeleteImportedFileParams) =>
  apiWrapper(
    axios.request<ApiDeleteResponse>({
      method: 'delete',
      headers,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/import_sessions/${params.sessionId}/files/${params.fileId}`,
    }),
  );

export const apiUploadImportFile = (params: ApiUploadImportFileParams) => {
  const { projectId, sessionId, ...data } = params;

  return apiWrapper(
    axios.request<ApiImportFile>({
      method: 'post',
      url: `${NEW_API_URL}/projects/${projectId}/import_sessions/${sessionId}/files`,
      data,
      headers,
      transformRequest,
      transformResponse,
    }),
  );
};

export const apiGetImportUploadsUrls = (params: ApiImportUploadUrlsParams) =>
  apiWrapper(
    axios({
      method: 'post',
      url: `${NEW_API_URL}/projects/${params.projectId}/import_uploads`,
      data: { count: params.filesCount },
    }),
  );

export const apiUploadFileToCloud = (params: ApiImportUploadToCloudParams) =>
  apiWrapper(
    axios({
      method: 'put',
      withCredentials: false,
      url: params.uploadUrl,
      data: params.fileBinary,
      headers: {
        'Content-Type': 'image/*',
      },
    }),
  );

export const apiParseImportedFiles = (params: ApiParseImportedFilesParams) =>
  apiWrapper(
    axios.request<{ id: string }>({
      method: 'post',
      headers,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/import_sessions/${params.sessionId}/parse`,
    }),
  );

export const apiLoadParseLogsForImportSession = (
  params: ApiParseLogsImportSessionParams,
) =>
  apiWrapper(
    axios.request<ApiLoadApiImportFileParseLog>({
      headers,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/import_sessions/${params.sessionId}/parse_logs`,
      params: {
        limit: params.limit,
        offset: params.offset,
      },
    }),
  );

export const apiLoadAllImportLabelClassesForMatching = (
  params: ApiLoadAllClassesForMatchingParams,
) =>
  apiWrapper(
    axios.request<ApiLoadAllApiImportLabelClass>({
      headers,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/import_sessions/${params.sessionId}/classes`,
      params: {
        limit: params.limit,
        offset: params.offset,
      },
    }),
  );

export const apiLoadImportLabelClassForMatching = (
  params: ApiLoadClassForMatchingParams,
) =>
  apiWrapper(
    axios.request<ApiImportLabelClass>({
      headers,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/import_sessions/${params.sessionId}/classes/${params.classId}`,
    }),
  );

export const apiUpdateImportLabelClassForMatching = (
  params: ApiUpdateClassForMatchingParams,
  data: Partial<ApiImportLabelClass>,
) =>
  apiWrapper(
    axios.request<ApiImportLabelClass>({
      method: 'put',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/import_sessions/${params.sessionId}/classes/${params.classId}`,
      data,
    }),
  );
