import { all, call, put, select, takeEvery } from 'typed-redux-saga';
import { PayloadAction } from '@reduxjs/toolkit';

import { statusChecks } from '../../../../../../constants/status';
import { advancedOptionsDefaultLabelTypeSelector } from '../../../../sections/editedProject/advancedOptions/advancedOptions.selectors';
import { getErrorMessage } from '../../../../../../api/utils';
import {
  getResultFromWorker,
  MASK_WORKER,
  RLE_WORKER,
  TerminationError,
} from '../../../../../../workers/workerManager';
import { METHOD_RLE_TO_IMAGE_DATA } from '../../../../../../workers/rle/constants';
import { METHOD_BORDER } from '../../../../../../workers/mask/constants';
import { getHeight, getWidth } from '../../../../../../util/bbox';
import { handleError } from '../../../../commonFeatures/errorHandler/errorHandler.actions';
import { apiLoadBoxToInstanceModel } from '../../../../../../api/requests/projectTools';
import {
  loadBoxToInstance,
  convertSelected,
  convertAll,
  loadBoxToInstanceFailure,
  loadBoxToInstanceSuccess,
  loadBoxToInstanceModel,
  updateBoxToInstanceModel,
  loadBoxToInstanceModelStart,
  updateBoxToInstanceModelSuccess,
  loadBoxToInstanceModelSuccess,
  loadBoxToInstanceModelFailure,
  convertSuccess,
  resetModel,
  resetObjects,
} from './boxToInstance.slice';
import {
  boxToInstanceLoadingStateSelector,
  boxToInstanceModelIdSelector,
  boxToInstanceModelLoadedSelector,
  boxToInstanceObjectsSelector,
} from './boxToInstance.selectors';
import { activeProjectIdSelector } from '../../../../project/project.selectors';
import {
  createModelChangePattern,
  ModelChangePayload,
} from '../models/models.constants';
import {
  BOX_TO_INSTANCE_FAMILY_NAME,
  MODEL_MESSAGES,
} from './boxToInstance.constants';
import { MODEL_LOADED, MODEL_UPDATED } from '../../../../ws/ws.constants';
import {
  updateModelHandler,
  modelLoadedHandler,
  loadModelHandler,
} from '../models/models.saga';
import { imageViewImageIdSelector } from '../../currentImage/currentImage.selectors';
import { apiCreateProjectImageBoxToInstance } from '../../../../../../api/requests/boxToInstance';
import { ImageLabel } from '../../../../../../api/domainModels/imageLabel';
import { boxToInstanceDataMapper } from '../../../../../../api/domainModels/boxToInstance';
import { resetProject } from '../../imageView.actions';
import { resetActiveTool, setActiveTool } from '../tools.slice';
import { updateLabels } from '../../labels/labels.slice';
import { ImageTool, ImageToolTitleMap } from '../tools.constants';
import { addActiveToolEntityId } from '../activeToolData/activeToolData.slice';
import { LabelType } from '../../../../../../api/constants/label';
import { Polygon } from '../../../../../../@types/imageView/types';
import { retrieveObject } from '../../../../../../helpers/imageView/data.helpers';
import { releaseObject, storeObject } from '../../imageView.helpers';
import { convertMaskLabelToPolygon } from '../../labels/conversions';
import { enqueueNotification } from '../../../../ui/stackNotifications/stackNotifications.slice';

type RetrievedObject = Partial<ImageLabel> & {
  id: string;
  bbox: ImageLabel['bbox'];
  mask: ImageLabel['mask'];
};
const createMaskObject = (retrievedObject: RetrievedObject) => ({
  id: retrievedObject.id,
  changes: {
    toolUsed: ImageTool.BoxToInstance,
    mask: retrievedObject.mask,
    bbox: retrievedObject.bbox,
    type: LabelType.Mask,
  },
});
const createPolygonObject = (
  retrievedObject: RetrievedObject,
  polygon: Polygon,
) => ({
  id: retrievedObject.id,
  changes: {
    bbox: retrievedObject.bbox,
    toolUsed: ImageTool.BoxToInstance,
    polygon,
    type: LabelType.Polygon,
  },
});

function* loadBoxToInstanceHandler() {
  const projectId = yield* select(activeProjectIdSelector);
  const imageId = yield* select(imageViewImageIdSelector);

  if (!imageId) {
    return;
  }

  try {
    const { data } = yield* call(apiCreateProjectImageBoxToInstance, {
      projectId,
      imageId,
    });

    const mappedData = data.map(boxToInstanceDataMapper.fromBackend);
    const imageData = yield* all(
      mappedData.map((datum) =>
        getResultFromWorker(RLE_WORKER, {
          method: METHOD_RLE_TO_IMAGE_DATA,
          rle: {
            data: datum.mask,
            width: getWidth(datum.bbox),
            height: getHeight(datum.bbox),
          },
        }),
      ),
    );
    const borders = yield* all(
      imageData.map((datum: ImageData) =>
        getResultFromWorker(MASK_WORKER, {
          method: METHOD_BORDER,
          imageData: datum,
        }),
      ),
    );
    const objectsToStore = mappedData.map((datum, index) => ({
      bbox: datum.bbox,
      border: borders[index],
      classId: datum.classId,
      id: datum.id,
      mask: datum.mask,
    }));
    const storedObjectIds: number[] = objectsToStore.map(storeObject);
    const objects = storedObjectIds.map((storedObjectId, index) => ({
      storedObjectId,
      labelId: objectsToStore[index].id,
    }));

    yield* all(storedObjectIds.map((id) => put(addActiveToolEntityId(id))));
    yield* put(loadBoxToInstanceSuccess(objects));
  } catch (error) {
    const message = getErrorMessage(
      error,
      'Not able to load box to instance model',
    );

    if (!(error instanceof TerminationError)) {
      yield* put(handleError({ message, error }));
    } else {
      yield* put(
        enqueueNotification({
          message,
          options: {
            variant: 'error',
            error,
          },
        }),
      );
    }

    yield* put(loadBoxToInstanceFailure(message));
  }
}

function* convertSelectedHandler(action: ActionType<typeof convertSelected>) {
  const loadingState = yield* select(boxToInstanceLoadingStateSelector);

  if (statusChecks.isInProgress(loadingState.status)) {
    return;
  }

  const retrievedObject: RetrievedObject = retrieveObject(action.payload);
  const defaultLabelType = yield* select(
    advancedOptionsDefaultLabelTypeSelector,
  );
  const objectsToUpdate = [] as any[];

  if (defaultLabelType === LabelType.Polygon) {
    const polygon: Polygon = yield* convertMaskLabelToPolygon(retrievedObject);

    objectsToUpdate.push(createPolygonObject(retrievedObject, polygon));
  } else {
    objectsToUpdate.push(createMaskObject(retrievedObject));
  }

  yield* put(updateLabels(objectsToUpdate));
  yield* put(convertSuccess(objectsToUpdate.map((o) => o.id)));
}

function* convertAllHandler() {
  const loadingState = yield* select(boxToInstanceLoadingStateSelector);

  if (statusChecks.isInProgress(loadingState.status)) {
    return;
  }

  const objects = yield* select(boxToInstanceObjectsSelector);
  const defaultLabelType = yield* select(
    advancedOptionsDefaultLabelTypeSelector,
  );
  const objectsToUpdate = [] as any[];

  for (const object of objects) {
    const retrievedObject: RetrievedObject = retrieveObject(
      object.storedObjectId,
    );

    if (defaultLabelType === LabelType.Polygon) {
      const polygon: Polygon = yield* convertMaskLabelToPolygon(
        retrievedObject,
      );

      objectsToUpdate.push({
        id: retrievedObject.id,
        changes: {
          polygon,
          toolUsed: ImageTool.BoxToInstance,
        },
      });
    } else {
      objectsToUpdate.push(createMaskObject(retrievedObject));
    }
  }

  yield* put(updateLabels(objectsToUpdate));
  yield* put(convertSuccess(objectsToUpdate.map((o) => o.id)));
  yield* put(setActiveTool(ImageTool.Default));
}

function* boxToInstanceModelLoadedHandler(
  action: PayloadAction<ModelChangePayload>,
) {
  const { status, progress, id, modelUseIE } = action.payload;

  yield* call(modelLoadedHandler, {
    id,
    modelIdSelector: boxToInstanceModelIdSelector,
    modelLoadedSelector: boxToInstanceModelLoadedSelector,
    progress,
    status,
    modelUseIE,
    updateAction: updateBoxToInstanceModel,
  });
}

function* boxToInstanceModelUpdatedHandler(
  action: PayloadAction<ModelChangePayload>,
) {
  const { status, progress, id, modelUseIE } = action.payload;

  yield* put(
    updateBoxToInstanceModel({
      status,
      progress,
      id,
      modelUseIE,
    }),
  );
}

function* updateBoxToInstanceModelHandler(
  action: ActionType<typeof updateBoxToInstanceModel>,
) {
  const { status, progress, id, modelUseIE } = action.payload;

  yield* call(updateModelHandler, {
    id,
    loadAction: loadBoxToInstanceModel,
    progress,
    status,
    modelUseIE,
    successAction: updateBoxToInstanceModelSuccess,
  });
}

function* loadBoxToInstanceModelHandler() {
  const projectId = yield* select(activeProjectIdSelector);

  yield* call(loadModelHandler, {
    projectId,
    loadApiCall: apiLoadBoxToInstanceModel,
    messages: MODEL_MESSAGES,
    toolId: ImageTool.BoxToInstance,
    modelLoadStartAction: loadBoxToInstanceModelStart,
    modelLoadSuccessAction: loadBoxToInstanceModelSuccess,
    modelLoadErrorAction: loadBoxToInstanceModelFailure,
    toolName: ImageToolTitleMap[ImageTool.InstanceSegmentation],
  });
}

function* releaseObjects() {
  const objects = yield* select(boxToInstanceObjectsSelector);

  objects.forEach((object) => releaseObject(object.storedObjectId));
}

function* resetProjectHandler() {
  yield* call(releaseObjects);
  yield* put(resetObjects());
  yield* put(resetModel());
}

function* setActiveToolHandler() {
  yield* call(releaseObjects);
  yield* put(resetObjects());
}

export function* boxToInstanceSaga() {
  yield* takeEvery(loadBoxToInstance, loadBoxToInstanceHandler);
  yield* takeEvery(convertSelected, convertSelectedHandler);
  yield* takeEvery(convertAll, convertAllHandler);
  yield* takeEvery(
    createModelChangePattern(BOX_TO_INSTANCE_FAMILY_NAME, MODEL_LOADED),
    boxToInstanceModelLoadedHandler,
  );
  yield* takeEvery(
    createModelChangePattern(BOX_TO_INSTANCE_FAMILY_NAME, MODEL_UPDATED),
    boxToInstanceModelUpdatedHandler,
  );
  yield* takeEvery(updateBoxToInstanceModel, updateBoxToInstanceModelHandler);
  yield* takeEvery(loadBoxToInstanceModel, loadBoxToInstanceModelHandler);
  yield* takeEvery(resetProject, resetProjectHandler);
  yield* takeEvery([setActiveTool, resetActiveTool], setActiveToolHandler);
}
