import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OptionsObject, SnackbarKey, VariantType } from 'notistack';
import shortid from 'shortid';

export const NO_PAGE_ACCESS_MESSAGE =
  'You have no access to this page, contact the project owner.';

export type StackNotification = {
  message: string;
  key: SnackbarKey;
  dismissed?: boolean;
  options: Omit<OptionsObject, 'defaultValue' | 'TransitionProps'> & {
    variant: Extract<VariantType, 'error' | 'info' | 'success'>;
    error?: any;
    icon?: string;
    allowOutsideOfEditor?: boolean;
    refresh?: boolean;
  };
};

const initialState: { notifications: StackNotification[]; expanded: boolean } =
  {
    notifications: [],
    expanded: false,
  };
const { actions, reducer: stackNotificationsReducer } = createSlice({
  name: 'stackNotifications',
  initialState,
  reducers: {
    enqueueNotification: {
      reducer(state, action: PayloadAction<StackNotification>) {
        state.notifications.push(action.payload);
      },
      prepare(payload: Omit<StackNotification, 'key'>) {
        return {
          payload: {
            ...payload,
            key: shortid(),
          },
        };
      },
    },
    closeNotification(
      state,
      action: PayloadAction<{ dismissAll?: boolean; key?: SnackbarKey }>,
    ) {
      state.notifications = state.notifications.map((notification) =>
        (action.payload.dismissAll &&
          notification.message !== NO_PAGE_ACCESS_MESSAGE) ||
        notification.key === action.payload.key
          ? { ...notification, dismissed: true }
          : { ...notification },
      );
    },
    removeNotification(state, action: PayloadAction<SnackbarKey>) {
      state.notifications = state.notifications.filter(
        (notification) => notification.key !== action.payload,
      );
    },
    collapseContainer(state) {
      state.expanded = false;
    },
    expandContainer(state) {
      state.expanded = true;
    },
  },
});

export const {
  enqueueNotification,
  closeNotification,
  removeNotification,
  collapseContainer,
  expandContainer,
} = actions;
export { stackNotificationsReducer };
