import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import {
  WidgetExperimentMetric,
  WidgetExperimentMetricItem,
} from '../../../../../../../api/domainModels/widgets';
import { loadingStateBuilder } from '../../../../../../utils/loadingState';
import { setEditedProjectId } from '../../../../../sections/editedProject/project/project.slice';

export const itemsAdapter = createEntityAdapter<WidgetExperimentMetricItem>({
  selectId: (item) => item.experimentId,
  sortComparer: (a, b) => (dayjs(a.createdAt).isAfter(b.createdAt) ? -1 : 1),
});
export const metricsAdapter = createEntityAdapter<WidgetExperimentMetric>();

const initialState = {
  loadingState: loadingStateBuilder.initial(),
  items: itemsAdapter.getInitialState(),
  metrics: metricsAdapter.getInitialState(),
};

const { actions, reducer: experimentsMetricsReducer } = createSlice({
  name: 'widgets/experimentsMetrics',
  initialState,
  reducers: {
    loadExperimentsMetrics(state, _action: PayloadAction<string[]>) {
      state.loadingState = loadingStateBuilder.inProgress();
      itemsAdapter.removeAll(state.items);
      metricsAdapter.removeAll(state.metrics);
    },
    loadExperimentsMetricsFailure(state, action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.failure(action.payload);
    },
    loadExperimentsMetricsSuccess(
      state,
      action: PayloadAction<{
        items: WidgetExperimentMetricItem[];
        metrics: WidgetExperimentMetric[];
      }>,
    ) {
      state.loadingState = loadingStateBuilder.success();

      const { items, metrics } = action.payload;
      itemsAdapter.setAll(state.items, items);
      metricsAdapter.setAll(state.metrics, metrics);
    },
    skipLoadingExperimentsMetrics(state) {
      state.loadingState = loadingStateBuilder.success();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setEditedProjectId, () => initialState);
  },
});

export { experimentsMetricsReducer };
export const {
  loadExperimentsMetrics,
  loadExperimentsMetricsSuccess,
  loadExperimentsMetricsFailure,
  skipLoadingExperimentsMetrics,
} = actions;
