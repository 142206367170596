import { createSelector } from '@reduxjs/toolkit';

import { attributesMapSelector } from './attributes/attributes.selectors';
import {
  keypointsSchemasKeypointsClassesSelector,
  keypointsSchemasMapSelector,
} from './keypointsSchemas/keypointsSchemas.selectors';
import { labelClassesMapSelector } from './labelClasses/labelClasses.selectors';
import {
  ACRelationsForAttributeSelector,
  ACRelationsForLabelClassSelector,
} from './ACRelations/ACRelations.selectors';
import {
  KCRelationsForKeypointsSelector,
  KCRelationsForLabelClassSelector,
} from './KCRelations/KCRelations.selectors';
import { Attribute } from '../../../../api/domainModels/attribute';
import { LabelClass } from '../../../../api/domainModels/labelClass';
import { KeypointSchema } from '../../../../api/domainModels/keypoints';

export const labelClassAttributesSelector = createSelector(
  ACRelationsForLabelClassSelector,
  attributesMapSelector,
  (relations, attributesMap) =>
    relations
      .map((r) => attributesMap[r.attributeId])
      .filter((r): r is Attribute => !!r),
);

export const labelClassAttributesCountSelector = createSelector(
  labelClassAttributesSelector,
  (attributes) => attributes.length,
);

export const attributeLabelClassesSelector = createSelector(
  ACRelationsForAttributeSelector,
  labelClassesMapSelector,
  (relations, labelClassesMap) =>
    relations
      .map((r) => labelClassesMap[r.classId])
      .filter((r): r is LabelClass => !!r),
);

export const attributeLabelClassesCountSelector = createSelector(
  attributeLabelClassesSelector,
  (labelClasses) => labelClasses.length,
);

export const labelClassKeypointsSchemasSelector = createSelector(
  KCRelationsForLabelClassSelector,
  keypointsSchemasMapSelector,
  (relations, keypointSchemasMap) =>
    relations
      .map((r) => keypointSchemasMap[r.keypointSchemaId])
      .filter((r): r is KeypointSchema => !!r),
);

export const labelClassKeypointsSchemasCountSelector = createSelector(
  labelClassKeypointsSchemasSelector,
  (keypoints) => keypoints.length,
);

// we can have only 1 schema attached to label class
export const labelClassKeypointsSchemaSelector = createSelector(
  labelClassKeypointsSchemasSelector,
  (schemas) => schemas?.[0],
);

export const labelClassKeypointsSchemaClassesSelector = createSelector(
  labelClassKeypointsSchemaSelector,
  (schema) =>
    [...(schema?.keypointClasses || [])].sort((a, b) =>
      (a.norder ?? 0) < (b.norder ?? 0) ? -1 : 1,
    ),
);

export const labelClassKeypointsSchemaClassByIdSelector = createSelector(
  (state: RootState, id: string | undefined) => id,
  keypointsSchemasKeypointsClassesSelector,
  (id, keypointsClasses) => keypointsClasses.find((kc) => kc.id === id),
);

export const keypointsSchemasLabelClassesSelector = createSelector(
  KCRelationsForKeypointsSelector,
  labelClassesMapSelector,
  (relations, labelClassesMap) =>
    relations
      .map((r) => labelClassesMap[r.labelClassId])
      .filter((r): r is LabelClass => !!r),
);

export const keypointsSchemasLabelClassesCountSelector = createSelector(
  keypointsSchemasLabelClassesSelector,
  (labelClasses) => labelClasses.length,
);
