import {
  take,
  takeEvery,
  put,
  select,
  takeLatest,
  all,
  call,
  SagaGenerator,
} from 'typed-redux-saga';
import { getSearch, push, replace } from 'connected-react-router';

import { currentImageLockSaga } from './lock/lock.saga';
import {
  setImageStatus,
  setImageStatusSuccess,
  loadImage,
  loadImageError,
  loadImageStart,
  loadImageSuccess,
  setImageId,
  confirmImageId,
  resetImageState,
  setImageToDoneAndGoNext,
  notifyIfImageIsLocked,
} from './currentImage.slice';
import {
  apiUpdateImageStatus,
  apiLoadImage,
  apiLoadImages,
} from '../../../../../api/requests/projectImages';
import { handleError } from '../../../commonFeatures/errorHandler/errorHandler.actions';
import { getErrorMessage } from '../../../../../api/utils';
import {
  imageViewImageIdSelector,
  imageViewCurrentImageStatusSelector,
  imageViewCurrentImageImageObjectIdSelector,
} from './currentImage.selectors';
import { getMaskOptimisationMultiplier } from '../../../../../util/maskTools';
import {
  getResultFromWorker,
  IMAGE_DATA_WORKER,
  TerminationError,
} from '../../../../../workers/workerManager';
import { METHOD_SCALE_IMAGE_DATA } from '../../../../../workers/imageData/constants';
import { scaleImageData } from '../../../../../workers/imageData/utils';
import { lockImage, unlockImage } from './lock/lock.actions';
import {
  imageViewCurrentImageLockSelector,
  imageViewIsCurrentImageLockedSelector,
} from './lock/lock.selectors';
import { imagesDataMapper } from '../../../../../api/domainModels/images';
import {
  addTagsToImageSuccess,
  loadTagClassesForImage,
} from '../project/imageTags/imageTags.slice';
import { activeProjectIdSelector } from '../../../project/project.selectors';
import { checkIsImageValid } from '../imageGallery/imageGallery.saga';
import { projectByIdSelector } from '../../../sections/projectList/projects/projects.selectors';
import {
  loadProjectInfoFailure,
  loadProjectInfoSuccess,
} from '../../../project/projectInfo/projectInfo.slice';
import { TOOLS_TO_RESET_ON_PROJECT_RESET } from '../../../../../constants/tools';
import { activeToolIdSelector } from '../tools/tools.selectors';
import { setActiveTool } from '../tools/tools.slice';
import { ImageTool } from '../tools/tools.constants';
import { persistedProjectImageByIdSelector } from '../persistedProjectImage/persistedProjectImage.selectors';
import { initializeLabelsStart } from '../labels/labelSync/labelSync.slice';
import { IMAGE_VIEW_PATHNAME_SEGMENT } from '../imageView.util';
import { resetProject } from '../imageView.actions';
import { loadImageTagsPrediction } from '../tools/imageTagsPrediction/imageTagsPrediction.slice';
import { addLabels, deleteLabels, updateLabels } from '../labels/labels.slice';
import {
  imageDataFromUrl,
  releaseObject,
  saveImageData,
  storeObject,
} from '../imageView.helpers';
import { ImageStatus } from '../../../../../api/domainModels/imageStatus';
import { goToNextImage } from '../imageNavigation/imageNavigation.actions';
import { consensusScoringRunTypeSelector } from '../consensusScoring/consensusScoring.selectors';
import { loadErrorFinderLabels } from '../../errorFinder/labels/labels.slice';
import { imageViewImageGalleryFiltersCSRunIdSelector } from '../imageGallery/bottomBar/filters/filters.selectors';
import { hideCSContextMenu } from '../consensusScoring/consensusScoring.slice';
import {
  loadSubjectLocksSuccess,
  loadSubjectLocksError,
} from '../../../commonFeatures/locks/locks.slice';
import {
  subjectLocksLoadedSelector,
  subjectLockByIdSelector,
} from '../../../commonFeatures/locks/locks.selectors';
import { projectUserByIdSelector } from '../../../project/projectUsers/projectUsers.selectors';
import {
  closeNotification,
  enqueueNotification,
} from '../../../ui/stackNotifications/stackNotifications.slice';
import { Project } from '../../../../../api/domainModels/project';
import { semanticReviewMaxNumberOfErrorsSelector } from '../../errorFinder/labels/semanticReview.selectors';
import { RunType } from '../../../../../api/constants/consensusScoring';

export function* processLockData(projectId: string, imageId: string) {
  const locksLoaded = yield* select(subjectLocksLoadedSelector);
  // we need to account for initial load of loadImageLocks, just once
  if (!locksLoaded) {
    const action = yield* take([
      loadSubjectLocksSuccess,
      loadSubjectLocksError,
    ]);

    if (action.type === loadSubjectLocksError.type) {
      return;
    }
  }

  const imageLock = yield* select(subjectLockByIdSelector, imageId);

  if (!imageLock) {
    yield* put(lockImage({ projectId, imageId }));
  }
}

function* getScaledImageData(imageData: ImageData, scale: number) {
  const oscSupport =
    typeof OffscreenCanvas !== 'undefined' &&
    typeof OffscreenCanvasRenderingContext2D !== 'undefined';
  let scaledImageData;

  if (oscSupport) {
    scaledImageData = yield* getResultFromWorker(IMAGE_DATA_WORKER, {
      method: METHOD_SCALE_IMAGE_DATA,
      imageData,
      scale,
    });
  } else {
    scaledImageData = scaleImageData({
      imageData,
      scale,
      canvasFactory: (...dimensions: [number, number]) => {
        const canvas = document.createElement('canvas');
        [canvas.width, canvas.height] = dimensions;

        return canvas;
      },
    });
  }

  return scaledImageData;
}

function* setImageStatusHandler(action: ActionType<typeof setImageStatus>) {
  const status = action.payload;
  const projectId = yield* select(activeProjectIdSelector);
  const imageId = yield* select(imageViewImageIdSelector);

  if (!imageId) {
    return;
  }

  const currentImageStatus = yield* select(imageViewCurrentImageStatusSelector);

  if (currentImageStatus !== status) {
    try {
      yield* call(apiUpdateImageStatus, projectId, imageId, status);
      yield* put(
        setImageStatusSuccess({
          imageId,
          status,
        }),
      );
    } catch (error) {
      const errorMessage = getErrorMessage(
        error,
        'Not able to update project image',
      );

      yield* put(handleError({ message: errorMessage, error }));
    }
  }
}

function* setImageToDoneAndGoNextHandler() {
  const status = ImageStatus.Done;
  const projectId = yield* select(activeProjectIdSelector);
  const imageId = yield* select(imageViewImageIdSelector);

  if (!imageId) {
    return;
  }

  const currentImageStatus = yield* select(imageViewCurrentImageStatusSelector);

  if (currentImageStatus !== status) {
    try {
      yield* call(apiUpdateImageStatus, projectId, imageId, status);
      yield* put(
        setImageStatusSuccess({
          imageId,
          status,
        }),
      );
      yield put(goToNextImage());
    } catch (error) {
      const errorMessage = getErrorMessage(
        error,
        'Not able to update project image',
      );

      yield* put(handleError({ message: errorMessage, error }));
    }
  } else {
    yield put(goToNextImage());
  }
}

function* setImageStatusOnActionHandler() {
  const currentImageStatus = yield* select(imageViewCurrentImageStatusSelector);

  if (
    currentImageStatus &&
    [ImageStatus.Skipped, ImageStatus.New].includes(currentImageStatus)
  ) {
    yield* put(setImageStatus(ImageStatus.InProgress));
  }
}

function* loadImageHandler(action: ActionType<typeof loadImage>) {
  const { projectId, imageId } = action.payload;
  const currentImageImageObjectId = yield* select(
    imageViewCurrentImageImageObjectIdSelector,
  );
  yield* put(loadImageStart('Loading image'));

  try {
    const response = yield* call(apiLoadImage, projectId, imageId);
    const properties = imagesDataMapper.fromBackend(response.data);

    const { imageData, imageObject } = yield imageDataFromUrl(
      properties.publicUrl,
    );
    const imageObjectId = storeObject(imageObject);
    saveImageData(imageData);
    if (
      currentImageImageObjectId !== null &&
      currentImageImageObjectId !== imageObjectId
    ) {
      releaseObject(currentImageImageObjectId);
    }
    yield* put(
      loadImageSuccess({
        imageObjectId,
        properties,
      }),
    );
    yield* processLockData(projectId, imageId);

    const maskOptimizationMultiplier = getMaskOptimisationMultiplier([
      imageObject.width,
      imageObject.height,
    ]);
    const scaledImageData = yield* getScaledImageData(
      imageData,
      maskOptimizationMultiplier,
    );
    saveImageData(scaledImageData, 1);
  } catch (error) {
    const message = getErrorMessage(error, 'The image failed to load');

    if (!(error instanceof TerminationError)) {
      yield* put(handleError({ message, error }));
    } else {
      yield* put(
        enqueueNotification({
          message,
          options: {
            variant: 'error',
            error,
          },
        }),
      );
    }

    yield* put(loadImageError(message));
  }
}

function* processInvalidImage(projectId: string) {
  try {
    // load the image information for the first image
    const params = {
      projectId,
      limit: 1,
    };

    const response = yield* call(apiLoadImages, params);
    const { items } = response.data;

    if (items instanceof Array && items.length > 0) {
      const defaultImageId = items[0].id;

      return yield* put(
        replace(
          `/projects/${projectId}/${IMAGE_VIEW_PATHNAME_SEGMENT}/${defaultImageId}`,
        ),
      );
    }

    throw new Error('No images are available for the project');
  } catch (error) {
    // yield* put(loadImageError(error));
    // throw new Error(error);
    // TODO:: make it show up in annotation env instead of "loading" in ImageViewContainer
  }
}

function* processImageId(imageId: string | undefined) {
  // todo @V2Debt deal with optional label
  const projectId = yield* select(activeProjectIdSelector);
  const persistedImageId = yield* select((state: RootState) =>
    persistedProjectImageByIdSelector(state, projectId),
  );

  let isImageValid = false;
  let fetchedProjectImageId = null;

  if (imageId) {
    isImageValid = yield* checkIsImageValid(projectId, imageId);

    if (isImageValid) {
      return imageId;
    }
  }

  if (persistedImageId) {
    isImageValid = yield* checkIsImageValid(projectId, persistedImageId);

    if (isImageValid) {
      return persistedImageId;
    }
  }

  let project = yield* select((state: RootState) =>
    projectByIdSelector(state, projectId),
  );

  if (!project || !project?.projectImageId) {
    const loadProjectAction: ActionType<
      typeof loadProjectInfoSuccess | typeof loadProjectInfoFailure
    > = yield* take([loadProjectInfoSuccess, loadProjectInfoFailure]);

    if (loadProjectAction.type === loadProjectInfoFailure.type) return null;
    if (loadProjectAction.type === loadProjectInfoSuccess.type) {
      project = loadProjectAction.payload as Project;
    }
  }

  if (project?.projectImageId) {
    const { projectImageId } = project;

    isImageValid = yield* checkIsImageValid(projectId, projectImageId);

    fetchedProjectImageId = projectImageId;
  }

  if (!isImageValid) {
    return yield* processInvalidImage(projectId);
  }

  return fetchedProjectImageId;
}

function* setImageIdHandler(action: ActionType<typeof setImageId>) {
  const search = yield* select(getSearch);
  const { imageId, labelIdToSet } = action.payload;
  const currentImageId = yield* select(imageViewImageIdSelector);
  const projectId = yield* select(activeProjectIdSelector);
  const activeToolId = yield* select(activeToolIdSelector);
  const runId = yield* select(imageViewImageGalleryFiltersCSRunIdSelector);
  const runType = yield* select(consensusScoringRunTypeSelector);

  const processedImageId = yield* call(processImageId, imageId);

  if (!processedImageId) {
    return;
  }

  if (processedImageId !== imageId) {
    if (imageId) {
      yield* put(
        push(
          `/projects/${projectId}/${IMAGE_VIEW_PATHNAME_SEGMENT}/${processedImageId}${search}`,
        ),
      );
    } else {
      yield* put(
        replace(
          `/projects/${projectId}/${IMAGE_VIEW_PATHNAME_SEGMENT}/${processedImageId}${search}`,
        ),
      );
    }

    return;
  }

  yield* put(closeNotification({ dismissAll: true }));

  const primaryEffects: SagaGenerator<any>[] = [
    put(confirmImageId(imageId)),
    put(initializeLabelsStart({ labelIdToSet })),
    put(loadTagClassesForImage({ projectId, imageId })),
    put(loadImage({ projectId, imageId })),
  ];

  if (TOOLS_TO_RESET_ON_PROJECT_RESET.includes(activeToolId as ImageTool)) {
    primaryEffects.unshift(put(setActiveTool(ImageTool.Default)));
  }

  if (runId && imageId) {
    const maxNumberOfErrors = yield* select(
      semanticReviewMaxNumberOfErrorsSelector,
    );

    const params = {
      runId,
      runType,
      imageId: processedImageId,
      customLimit: 10000,
      customFilters: {
        ...(runType === RunType.SemanticReview ? { maxNumberOfErrors } : {}),
      },
    };

    const search = yield* select(getSearch);

    yield* put(
      push(
        `/projects/${projectId}/${IMAGE_VIEW_PATHNAME_SEGMENT}/${processedImageId}${search}`,
      ),
    );

    primaryEffects.unshift(put(loadErrorFinderLabels(params)));
    primaryEffects.unshift(put(hideCSContextMenu()));
  }

  if (runId === null) {
    yield* put(
      push(
        `/projects/${projectId}/${IMAGE_VIEW_PATHNAME_SEGMENT}/${processedImageId}${search}`,
      ),
    );
  }

  if (currentImageId) {
    primaryEffects.unshift(
      put(
        unlockImage({
          imageId: currentImageId,
          projectId,
        }),
      ),
    );
  }

  yield* all(primaryEffects);

  const secondaryEffects = [
    //   put(persistLastImageId(projectId, imageId)),
    put(loadImageTagsPrediction()),
    //   ...getModelLoadingEffectsForNewImage(projectId),
    // ^ why would we need to load attributes on new image?
  ];
  //
  yield* all(secondaryEffects);
}

function* resetHandler() {
  yield* put(resetImageState());
}

function* notifyIfImageIsLockedHandler() {
  const imageLocked = yield* select(imageViewIsCurrentImageLockedSelector);

  if (imageLocked) {
    const lock = yield* select(imageViewCurrentImageLockSelector);

    if (!lock) {
      return;
    }

    const user = yield* select((state: RootState) =>
      projectUserByIdSelector(state, lock.lockedById),
    );

    if (user) {
      const { username } = user;
      const message = `This image is currently locked by ${username}`;

      yield* put(
        enqueueNotification({
          message,
          options: {
            variant: 'info',
          },
        }),
      );
    }
  }
}

function* currentImageSaga() {
  yield* takeEvery(notifyIfImageIsLocked, notifyIfImageIsLockedHandler);
  yield* takeEvery(setImageStatus, setImageStatusHandler);
  yield* takeEvery(setImageToDoneAndGoNext, setImageToDoneAndGoNextHandler);
  yield* takeLatest(loadImage, loadImageHandler);
  yield* takeLatest(setImageId, setImageIdHandler);
  yield* takeEvery(resetProject, resetHandler);
  yield* takeLatest(
    [addTagsToImageSuccess, addLabels, deleteLabels, updateLabels],
    setImageStatusOnActionHandler,
  );
}

export const currentImageSagas = [currentImageLockSaga, currentImageSaga];
