export enum Feature {
  SentryModal = 'SENTRY_MODAL',
  LogWebsocket = 'LOG_WEBSOCKET',
  DisableVersionCheck = 'DISABLE_VERSION_CHECK',
  DisableResolutionCheck = 'DISABLE_RESOLUTION_CHECK',
  QuickFeatureToggler = 'QUICK_FEATURE_TOGGLER',
  WidgetsLogScale = 'WIDGETS_LOG_SCALE',
  UserDataModal = 'USER_DATA_MODAL',
  ABGroup = 'AB_GROUP',
  FingerTouchInput = 'FINGER_TOUCH_INPUT',
  NestedSplits = 'NESTED_SPLITS',
  Keypoints = 'KEYPOINTS',
  IncreasedImportLimit = 'INCREASED_IMPORT_LIMIT',
  KeypointsDetection = 'KEYPOINTS_DETECTION',
  SamMode = 'SAM_MODE',
  TextPrompt = 'TEXT_PROMPT',
  MultipleInstance = 'MULTIPLE_INSTANCE',
}
