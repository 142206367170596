import { createSelector } from '@reduxjs/toolkit';
import lodash from 'lodash';

import { editedKeypointsLabelSelector } from '../../../labels/selectedLabels/selectedLabels.selectors';
import { imageViewSelector } from '../../../imageView.selectors';
import { getAvailableKeypoints } from '../../../../../../../util/keypoints';

export const keypointsValuesSelector = createSelector(
  imageViewSelector,
  (imageView) => imageView.tools.keypoints.values,
);
export const keypointsValuesPointsSelector = createSelector(
  keypointsValuesSelector,
  (state) => state.present.points,
);
export const keypointsValuesPointsCountSelector = createSelector(
  keypointsValuesPointsSelector,
  (points) => points.length,
);
export const keypointsValuesPointByIdSelector = createSelector(
  (state: RootState, id: string | null) => id,
  keypointsValuesPointsSelector,
  (id, points) => (id ? points.find((point) => point.id === id) : null),
);
export const keypointsValuesEditedLabelChangedSelector = createSelector(
  keypointsValuesPointsSelector,
  editedKeypointsLabelSelector,
  (keypoints, label) => {
    const editedKeypoints = getAvailableKeypoints(keypoints);
    const labelKeypoints = getAvailableKeypoints(label?.keypoints);

    if (labelKeypoints.length !== editedKeypoints.length) return true;

    return !lodash(labelKeypoints)
      .differenceWith(editedKeypoints, lodash.isEqual)
      .isEmpty();
  },
);
