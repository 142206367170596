import { call, put, takeEvery } from 'typed-redux-saga';

import {
  loadWebhookEventsStart,
  loadWebhookEventsSuccess,
  loadWebhookEventsFailure,
} from './webhookEvents.slice';
import { apiLoadProjectWebhookEvents } from '../../../../../api/requests/webhook';
import { getErrorMessage } from '../../../../../api/utils';
import { enqueueNotification } from '../../../ui/stackNotifications/stackNotifications.slice';

function* loadWorkspaceEventsHandler() {
  try {
    const { data } = yield* call(apiLoadProjectWebhookEvents);

    yield* put(loadWebhookEventsSuccess(data));
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to load webhook events');

    yield* put(loadWebhookEventsFailure(message));
    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
        },
      }),
    );
  }
}

export function* webhookEventsSaga() {
  yield* takeEvery(loadWebhookEventsStart, loadWorkspaceEventsHandler);
}
