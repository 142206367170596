import {
  put,
  select,
  takeLatest,
  all,
  takeEvery,
  call,
  debounce,
} from 'typed-redux-saga';
import { PayloadAction } from '@reduxjs/toolkit';

import {
  setMinSize,
  loadMasks,
  loadMasksSuccess,
  loadMasksFailure,
  loadSemanticSegmentationModel,
  updateSemanticSegmentationModel,
  loadSemanticSegmentationModelSuccess,
  loadSemanticSegmentationModelStart,
  loadSemanticSegmentationModelFailure,
  adjustMinSize,
  addLabelsFromProposedMasks,
  confirmAddLabelFromProposedObject,
  updateSemanticSegmentationModelSuccess,
  addLabelFromProposedMask,
  resetData,
  resetModel,
  confirmMinSize,
  updateSemanticSegmentationModelShowDot,
  toggleUseSAM,
  resetMinSize,
} from './semanticSegmentation.slice';
import { getErrorMessage } from '../../../../../../api/utils';
import {
  apiLoadSemanticSegmentationModel,
  apiLoadSemanticSegmentationMask,
} from '../../../../../../api/requests/projectTools';
import {
  semanticSegmentationMinSizeSelector,
  semanticSegmentationModelIdSelector,
  semanticSegmentationPredictedDataSelector,
  semanticSegmentationModelLoadedSelector,
  semanticSegmentationPredictedDataByIdSelector,
  semanticSegmentationUseSAMSelector,
} from './semanticSegmentation.selectors';
import {
  MAX_MASK_SIZE,
  MIN_MASK_SIZE,
} from '../../../../../../constants/tools';
import { getMaskDataUpdate } from '../../../../../atoms/maskDataUpdate.saga';
import {
  getResultFromWorker,
  MASK_WORKER,
  TerminationError,
} from '../../../../../../workers/workerManager';
import { METHOD_BORDER } from '../../../../../../workers/mask/constants';
import { handleError } from '../../../../commonFeatures/errorHandler/errorHandler.actions';
import { activeProjectIdSelector } from '../../../../project/project.selectors';
import { imageViewImageIdSelector } from '../../currentImage/currentImage.selectors';
import { resetActiveTool, setActiveTool } from '../tools.slice';
import { ImageTool, ImageToolTitleMap } from '../tools.constants';
import {
  createModelChangePattern,
  ModelChangePayload,
} from '../models/models.constants';
import { MODEL_LOADED, MODEL_UPDATED } from '../../../../ws/ws.constants';
import {
  MODEL_MESSAGES,
  SEMANTIC_SEGMENTATION_FAMILY_NAME,
  SEMANTIC_SEGMENTATION_DEFAULT_Z_INDEX,
  SEMANTIC_SEGMENTATION_DEFAULT_MIN_SIZE,
} from './semanticSegmentation.constants';
import {
  loadModelHandler,
  modelLoadedHandler,
  updateModelHandler,
} from '../models/models.saga';
import { adjustNumValue, createLabel } from '../../imageView.util';
import { resetProject } from '../../imageView.actions';
import { addLabels } from '../../labels/labels.slice';
import { addActiveToolEntityId } from '../activeToolData/activeToolData.slice';
import { ImageLabel } from '../../../../../../api/domainModels/imageLabel';
import { advancedOptionsDefaultLabelTypeSelector } from '../../../../sections/editedProject/advancedOptions/advancedOptions.selectors';
import { Polygon } from '../../../../../../@types/imageView/types';
import { LabelType } from '../../../../../../api/constants/label';
import { retrieveObject } from '../../../../../../helpers/imageView/data.helpers';
import { storeObject } from '../../imageView.helpers';
import { convertMaskLabelToPolygon } from '../../labels/conversions';
import { enqueueNotification } from '../../../../ui/stackNotifications/stackNotifications.slice';
import { uuidv4 } from '../../../../../../util/uuidv4';

interface PartialImageLabel extends Partial<ImageLabel> {
  id: string;
}

const createMaskObject = (
  label: Pick<ImageLabel, 'classId' | 'bbox' | 'mask'>,
): PartialImageLabel => ({
  ...label,
  id: uuidv4(),
  toolUsed: ImageTool.SemanticSegmentation,
  type: LabelType.Mask,
});
const createPolygonObject = (
  label: Pick<ImageLabel, 'classId' | 'bbox' | 'polygon'>,
): PartialImageLabel => ({
  ...label,
  id: uuidv4(),
  toolUsed: ImageTool.SemanticSegmentation,
  type: LabelType.Polygon,
});
// todo @V2debt type API
function* adjustMinSizeHandler(action: ActionType<typeof adjustMinSize>) {
  const value = yield* select(semanticSegmentationMinSizeSelector);
  const newValue = adjustNumValue({
    increment: action.payload,
    value,
    maxValue: MAX_MASK_SIZE,
  });

  if (newValue) {
    yield* put(setMinSize(newValue));
  }
}

function* labelGenerator(label: ImageLabel) {
  const { imageDataId } = yield* getMaskDataUpdate(label);
  const borderData = yield* getResultFromWorker(MASK_WORKER, {
    method: METHOD_BORDER,
    imageData: retrieveObject(imageDataId),
  });
  const borderDataId = storeObject(borderData);

  yield* all(
    [imageDataId, borderDataId].map((id) => put(addActiveToolEntityId(id))),
  );

  return {
    ...label,
    borderDataId,
    imageDataId,
  };
}

function* loadMasksHandler() {
  const projectId = yield* select(activeProjectIdSelector);
  const imageId = yield* select(imageViewImageIdSelector);
  const modelId = yield* select(semanticSegmentationModelIdSelector);
  const minSize = yield* select(semanticSegmentationMinSizeSelector);
  const useSAM = yield* select(semanticSegmentationUseSAMSelector);

  const params = {
    modelId,
    minSize,
    useAsPrompt: useSAM,
  };

  try {
    const response = yield* call(
      apiLoadSemanticSegmentationMask,
      projectId,
      imageId,
      params,
    );

    const objects = response.data.map(createLabel) as ImageLabel[];
    const payload = yield* all(objects.map(labelGenerator));
    yield* put(loadMasksSuccess(payload));
  } catch (error) {
    const message = getErrorMessage(
      error,
      'Semantic segmentation failed to predict masks',
    );

    if (!(error instanceof TerminationError)) {
      yield* put(handleError({ message, error }));
    } else {
      yield* put(
        enqueueNotification({
          message,
          options: {
            variant: 'error',
            error,
          },
        }),
      );
    }

    yield* put(loadMasksFailure(message));
  }
}

function* addLabelFromProposedMaskHandler(
  action: ActionType<typeof addLabelFromProposedMask>,
) {
  const data = yield* select((state: RootState) =>
    semanticSegmentationPredictedDataByIdSelector(state, action.payload),
  );
  const defaultLabelType = yield* select(
    advancedOptionsDefaultLabelTypeSelector,
  );

  if (data) {
    const { classId, mask, bbox } = data;
    let labelToAdd;

    if (defaultLabelType === LabelType.Polygon) {
      const polygon: Polygon = yield* convertMaskLabelToPolygon({
        bbox,
        mask,
      });

      labelToAdd = createPolygonObject({ polygon, classId, bbox });
    } else {
      labelToAdd = createMaskObject({ classId, mask, bbox });
    }
    yield* put(
      addLabels([
        { ...labelToAdd, zIndex: SEMANTIC_SEGMENTATION_DEFAULT_Z_INDEX },
      ]),
    );
    yield* put(confirmAddLabelFromProposedObject(action.payload));
  }
}

function* addLabelsFromProposedMasksHandler() {
  const data = yield* select(semanticSegmentationPredictedDataSelector);
  const defaultLabelType = yield* select(
    advancedOptionsDefaultLabelTypeSelector,
  );

  if (data.length > 0) {
    const labelsToAdd = [] as any[];

    if (defaultLabelType === LabelType.Polygon) {
      for (const entry of data) {
        const { bbox, mask, classId } = entry;
        const polygon: Polygon = yield* convertMaskLabelToPolygon({
          bbox,
          mask,
        });

        labelsToAdd.push({
          ...createPolygonObject({ polygon, classId, bbox }),
          zIndex: SEMANTIC_SEGMENTATION_DEFAULT_Z_INDEX,
        });
      }
    } else {
      for (const entry of data) {
        const { bbox, mask, classId } = entry;

        labelsToAdd.push({
          ...createMaskObject({ bbox, mask, classId }),
          zIndex: SEMANTIC_SEGMENTATION_DEFAULT_Z_INDEX,
        });
      }
    }

    yield* put(addLabels(labelsToAdd));

    yield* put(setActiveTool(ImageTool.Default));
  }
}

function* setMinSizeHandler(action: ActionType<typeof setMinSize>) {
  const currentMinSize = yield* select(semanticSegmentationMinSizeSelector);
  let confirmed = action.payload;

  if (action.payload > MAX_MASK_SIZE) {
    confirmed = MAX_MASK_SIZE;
  }

  if (action.payload < MIN_MASK_SIZE) {
    confirmed = MIN_MASK_SIZE;
  }

  if (confirmed !== currentMinSize) {
    yield* put(confirmMinSize(confirmed));
    yield* put(loadMasks());
  }
}

function* resetMinSizeHandler(_action: ActionType<typeof resetMinSize>) {
  yield* put(setMinSize(SEMANTIC_SEGMENTATION_DEFAULT_MIN_SIZE));
}

function* semanticSegmentationModelLoadedHandler(
  action: PayloadAction<ModelChangePayload>,
) {
  const { status, progress, id } = action.payload;

  yield* call(modelLoadedHandler, {
    id,
    modelIdSelector: semanticSegmentationModelIdSelector,
    modelLoadedSelector: semanticSegmentationModelLoadedSelector,
    progress,
    status,
    updateAction: updateSemanticSegmentationModel,
    updateModelIndicator: updateSemanticSegmentationModelShowDot,
  });
}

function* semanticSegmentationModelUpdatedHandler(
  action: PayloadAction<ModelChangePayload>,
) {
  const { status, progress, id } = action.payload;

  yield* put(
    updateSemanticSegmentationModel({
      status,
      progress,
      id,
    }),
  );
}

function* updateSemanticSegmentationModelHandler(
  action: ActionType<typeof updateSemanticSegmentationModel>,
) {
  const { status, progress, id } = action.payload;

  yield* call(updateModelHandler, {
    id,
    loadAction: loadSemanticSegmentationModel,
    progress,
    status,
    successAction: updateSemanticSegmentationModelSuccess,
  });
}

function* loadSemanticSegmentationModelHandler() {
  const projectId = yield* select(activeProjectIdSelector);

  yield* call(loadModelHandler, {
    projectId,
    loadApiCall: apiLoadSemanticSegmentationModel,
    messages: MODEL_MESSAGES,
    toolId: ImageTool.SemanticSegmentation,
    modelLoadStartAction: loadSemanticSegmentationModelStart,
    modelLoadSuccessAction: loadSemanticSegmentationModelSuccess,
    modelLoadErrorAction: loadSemanticSegmentationModelFailure,
    toolName: ImageToolTitleMap[ImageTool.SemanticSegmentation],
  });
}

function* resetProjectHandler() {
  yield* put(resetData());
  yield* put(resetModel());
}

function* setActiveToolHandler() {
  yield* put(resetData());
}

function* toggleUseSAMHandler() {
  yield* put(loadMasks());
}

export function* semanticSegmentationSaga() {
  yield* debounce(1000, loadMasks, loadMasksHandler);
  yield* takeEvery(toggleUseSAM, toggleUseSAMHandler);
  yield* takeLatest(adjustMinSize, adjustMinSizeHandler);
  yield* takeLatest(setMinSize, setMinSizeHandler);
  yield* takeLatest(resetMinSize, resetMinSizeHandler);
  yield* takeEvery(
    addLabelsFromProposedMasks,
    addLabelsFromProposedMasksHandler,
  );
  yield* takeEvery(addLabelFromProposedMask, addLabelFromProposedMaskHandler);
  yield* takeEvery(
    createModelChangePattern(SEMANTIC_SEGMENTATION_FAMILY_NAME, MODEL_LOADED),
    semanticSegmentationModelLoadedHandler,
  );
  yield* takeEvery(
    createModelChangePattern(SEMANTIC_SEGMENTATION_FAMILY_NAME, MODEL_UPDATED),
    semanticSegmentationModelUpdatedHandler,
  );
  yield* takeEvery(
    updateSemanticSegmentationModel,
    updateSemanticSegmentationModelHandler,
  );
  yield* takeEvery(
    loadSemanticSegmentationModel,
    loadSemanticSegmentationModelHandler,
  );
  yield* takeEvery(resetProject, resetProjectHandler);
  yield* takeEvery([setActiveTool, resetActiveTool], setActiveToolHandler);
}
