/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AutomatedLabelingJob = {
  /**
   * UUID of the automated labeling job
   */
  id?: string;
  /**
   * Project under which automated labeling was activated
   */
  projectId?: string;
  /**
   * Indicates status of the job
   */
  status?: AutomatedLabelingJob.status;
  /**
   * Automated labeling progress
   */
  progress?: number;
  /**
   * Time stamp of job start
   */
  startedOn?: string;
  /**
   * Time stamp of job end
   */
  completedOn?: string | null;
  /**
   * Number of processed images
   */
  imagesProcessed?: number | null;
  /**
   * Model Playground experiment that was used for automated labeling
   */
  experimentId?: string | null;
  /**
   * User ID
   */
  userId?: string;
};

export namespace AutomatedLabelingJob {

  /**
   * Indicates status of the job
   */
  export enum status {
    INIT = 'INIT',
    RUNNING = 'RUNNING',
    FAILED = 'FAILED',
    DONE = 'DONE',
  }


}

