export const VAT_STATUS_UNSPECIFIED = 'unspecified';
export const VAT_STATUS_PENDING = 'pending';
export const VAT_STATUS_INVALID = 'invalid';
export const VAT_STATUS_VALID = 'valid';
export const VAT_STATUS_COUNTRY_MISMATCH = 'country_mismatch';

export const VAT_STATUSES_MAP = {
  [VAT_STATUS_UNSPECIFIED]: 'unspecified',
  [VAT_STATUS_PENDING]: 'pending',
  [VAT_STATUS_INVALID]: 'invalid',
  [VAT_STATUS_VALID]: 'valid',
  [VAT_STATUS_COUNTRY_MISMATCH]: 'country mismatch',
} as const;

export type VATStatus = keyof typeof VAT_STATUSES_MAP;
