import axios from 'axios';

import {
  ApiProjectUser,
  ApiLoadProjectUsersParams,
} from '../schemas/projectUsers';
import { apiWrapper } from '../utils';
import { API_URL } from '../../constants/paths';
import { headers, transformRequest, transformResponse } from '../helpers';

export const apiLoadProjectUsers = (params: ApiLoadProjectUsersParams) =>
  apiWrapper(
    axios.request<ApiProjectUser[]>({
      method: 'get',
      headers,
      transformRequest,
      transformResponse,
      url: `${API_URL}/project/${params.projectId}/active_users`,
    }),
  );
