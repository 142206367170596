import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { WidgetTaggerInferencesItem } from '../../../../../../../api/domainModels/widgets';
import { ApiPaginationMeta } from '../../../../../../../api/helpers';
import { loadingStateBuilder } from '../../../../../../utils/loadingState';
import { setEditedProjectId } from '../../../../../sections/editedProject/project/project.slice';

type WidgetTaggerInference = WidgetTaggerInferencesItem & { id: string };

export const itemsAdapter = createEntityAdapter<WidgetTaggerInference>();

const initialState = {
  loadingState: loadingStateBuilder.initial(),
  items: itemsAdapter.getInitialState(),
  meta: null as ApiPaginationMeta | null,
  offset: 0,
  limit: 3,
};

const { actions, reducer: taggerInferencesReducer } = createSlice({
  name: 'widgets/taggerInferences',
  initialState,
  reducers: {
    loadTaggerInferences(
      state,
      action: PayloadAction<{
        iterationId?: number;
        limit?: number;
        offset?: number;
      }>,
    ) {
      state.loadingState = loadingStateBuilder.inProgress();
      if (action.payload.offset) {
        state.offset = action.payload.offset;
      }
      if (action.payload.limit) {
        state.limit = action.payload.limit;
      }
      itemsAdapter.removeAll(state.items);
    },
    loadTaggerInferencesFailure(state, action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.failure(action.payload);
    },
    loadTaggerInferencesSuccess(
      state,
      action: PayloadAction<{
        items: WidgetTaggerInference[];
        meta: ApiPaginationMeta;
      }>,
    ) {
      state.loadingState = loadingStateBuilder.success();
      const { items, meta } = action.payload;
      itemsAdapter.setAll(state.items, items);
      state.meta = meta;
    },
    resetWidget() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setEditedProjectId, () => initialState);
  },
});

export { taggerInferencesReducer };
export const {
  loadTaggerInferences,
  loadTaggerInferencesSuccess,
  loadTaggerInferencesFailure,
  resetWidget,
} = actions;
