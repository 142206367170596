import { put, select, takeEvery, call } from 'typed-redux-saga';

import { experimentComponentDataMapper } from '../../../../../../api/domainModels/modelPlayground';
import {
  apiGetExperimentComponents,
  apiGetComponentParameters,
  apiUpdateExperimentComponent,
  apiUpdateComponentParameter,
  apiLoadRestartExperimentParameters,
} from '../../../../../../api/requests/modelPlayground';
import { getErrorMessage } from '../../../../../../api/utils';
import { loadAll } from '../../../../../utils/api';
import { activeProjectIdSelector } from '../../../../project/project.selectors';
import { parseParameterValue } from '../parameters.helpers';
import {
  activeExperimentIdSelector,
  isActiveExperimentEditableSelector,
} from '../selectors';
import {
  selectedRegimenIdSelector,
  regimenParameterByIdSelector,
} from './regimens.selectors';
import {
  loadRegimens,
  loadRegimensSuccess,
  loadRegimensFailure,
  updateExperimentRegimen,
  updateExperimentRegimenFailure,
  updateExperimentRegimenSuccess,
  loadRegimenParameters,
  loadRegimenParametersSuccess,
  loadRegimenParametersFailure,
  updateRegimenParameter,
  updateRegimenParameterSuccess,
  updateRegimenParameterFailure,
  loadRestartExperimentParameters,
  loadRestartExperimentParametersSuccess,
  loadRestartExperimentParametersFailure,
} from './regimens.slice';
import { loadTrainingParamsVisualization } from '../../dashboard/widgets/trainParamsVisualization/trainParamsVisualization.slice';
import { enqueueNotification } from '../../../../ui/stackNotifications/stackNotifications.slice';
import { Component } from '../../../../../../api/constants/modelPlayground';

function* loadRegimensHandler() {
  try {
    const projectId = yield* select(activeProjectIdSelector);
    const experimentId = yield* select(activeExperimentIdSelector);
    const items = yield* loadAll({
      apiHelper: apiGetExperimentComponents,
      params: {
        projectId,
        experimentId,
        componentType: Component.Regimen,
      },
    });
    const isEditable = yield* select(isActiveExperimentEditableSelector);
    // as BE does not provide initially selected value for regimen
    // we need to make sure it either exists (user selected it) or preselect it for them
    // we'll be working under assumption that regimen should always be selected
    const hasRegimenSelected = items.some((item) => item.selected);
    if (isEditable && items.length > 0 && !hasRegimenSelected)
      yield* put(updateExperimentRegimen(items[0].id));
    yield* put(
      loadRegimensSuccess(items.map(experimentComponentDataMapper.fromBackend)),
    );
  } catch (e) {
    yield* put(
      loadRegimensFailure(getErrorMessage(e, 'Not able to load regimens')),
    );
  }
}

function* updateRegimenHandler(
  action: ActionType<typeof updateExperimentRegimen>,
) {
  try {
    const projectId = yield* select(activeProjectIdSelector);
    const experimentId = yield* select(activeExperimentIdSelector);

    if (!experimentId) {
      return;
    }

    const componentType: Component = Component.Regimen;
    const params = { projectId, experimentId, componentType };
    const data = { regimenId: action.payload };
    const { data: response } = yield* call(() =>
      apiUpdateExperimentComponent(params, data),
    );
    yield* put(
      updateExperimentRegimenSuccess(
        experimentComponentDataMapper.fromBackend(response),
      ),
    );
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to update regimen');

    yield* put(updateExperimentRegimenFailure(message));
    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
          refresh: false,
        },
      }),
    );
  }
}

function* loadParametersHandler(
  action: ActionType<typeof loadRegimenParameters>,
) {
  try {
    const projectId = yield* select(activeProjectIdSelector);
    const experimentId = yield* select(activeExperimentIdSelector);
    const componentId = action.payload;
    const componentType: Component = Component.Regimen;
    const items = yield* loadAll({
      apiHelper: apiGetComponentParameters,
      params: {
        projectId,
        experimentId,
        componentId,
        componentType,
      },
    });
    yield* put(loadRegimenParametersSuccess(items));
  } catch (e) {
    yield* put(
      loadRegimenParametersFailure(
        getErrorMessage(e, 'Not able to load parameters'),
      ),
    );
  }
}

function* updateParameterHandler(
  action: ActionType<typeof updateRegimenParameter>,
) {
  try {
    const projectId = yield* select(activeProjectIdSelector);
    const experimentId = yield* select(activeExperimentIdSelector);
    const componentId = yield* select(selectedRegimenIdSelector);

    if (!componentId || !experimentId) {
      return;
    }

    const componentType: Component = Component.Regimen;
    const params = { projectId, experimentId, componentId, componentType };
    const { trainingParameterId, value } = action.payload;
    const data = action.payload;
    const editedParameter = yield* select((state: RootState) =>
      regimenParameterByIdSelector(state, trainingParameterId),
    );
    if (editedParameter) {
      data.value = parseParameterValue(editedParameter, value);
    }
    const { data: response } = yield* call(
      apiUpdateComponentParameter,
      params,
      data,
    );
    yield* put(
      updateRegimenParameterSuccess({
        changes: response,
        id: trainingParameterId,
      }),
    );
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to update parameter');

    yield* put(updateRegimenParameterFailure(message));
    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
          refresh: false,
        },
      }),
    );
  }
}

function* loadRestartExperimentParametersHandler(
  action: ActionType<typeof loadRestartExperimentParameters>,
) {
  const experimentId = action.payload;
  const projectId = yield* select(activeProjectIdSelector);

  try {
    const parameters = yield* loadAll({
      apiHelper: apiLoadRestartExperimentParameters,
      params: {
        projectId,
        experimentId,
      },
    });
    yield* put(loadRestartExperimentParametersSuccess(parameters));
  } catch (e) {
    yield* put(
      loadRestartExperimentParametersFailure(
        getErrorMessage(e, 'Not able to load restart experiment parameters'),
      ),
    );
  }
}

function* loadTrainingParamsVisualizationHandler() {
  yield* put(loadTrainingParamsVisualization());
}

export function* regimensSaga() {
  yield* takeEvery(loadRegimens, loadRegimensHandler);
  yield* takeEvery(updateExperimentRegimen, updateRegimenHandler);
  yield* takeEvery(loadRegimenParameters, loadParametersHandler);
  yield* takeEvery(updateRegimenParameter, updateParameterHandler);
  yield* takeEvery(
    loadRestartExperimentParameters,
    loadRestartExperimentParametersHandler,
  );
  yield* takeEvery(
    [updateRegimenParameterSuccess, updateExperimentRegimenSuccess],
    loadTrainingParamsVisualizationHandler,
  );
}
