import { UseQueryOptions } from '@tanstack/react-query';

import {
  getQueryData,
  invalidateQueries,
  setQueryData,
  useApiQuery,
} from '../api';
import {
  Dataset,
  DatasetService,
  ImageService,
  VideoService,
} from '../codegen';
import { getCount, mapItems, removeItemWithId } from '../api.helpers';
import { OpReturn } from '../api.types';
import { useActiveProjectId } from '../../hooks/urlParamsHooks';

type ListReturn = OpReturn<typeof DatasetService.getDatasets>;

const useDatasetsQuery = <TData = ListReturn>({
  projectId,
  ...options
}: UseQueryOptions<ListReturn, Error, TData> & { projectId: string }) =>
  useApiQuery(DatasetService.getDatasets, {
    params: { projectId },
    enabled: !!projectId,
    loadingStateErrorMessage: 'Not able to load the datasets',
    ...options,
  });

type UseDatasetsParams = {
  projectId: string;
  suspense?: boolean;
};

export const useDatasets = ({ projectId, suspense }: UseDatasetsParams) => {
  const { data: datasetsData } = useDatasetsQuery({ projectId, suspense });

  return datasetsData?.items ?? [];
};

const toOptions = mapItems(({ id, name }: Dataset) => ({
  key: id,
  text: name,
  value: id,
}));

export const useDatasetsAsOptions = ({
  projectId,
  suspense,
}: UseDatasetsParams) => {
  const { data } = useDatasetsQuery({ projectId, suspense, select: toOptions });

  return data ?? [];
};

export const useDatasetsLength = ({
  projectId,
  suspense,
}: UseDatasetsParams) => {
  const { data } = useDatasetsQuery({ projectId, suspense, select: getCount });

  return data ?? 0;
};

export const useDatasetsListLoadingState = (projectId: string) => {
  const { loadingState } = useDatasetsQuery({ projectId });

  return loadingState;
};

type UseActiveProjectDatasetsParams = Pick<UseDatasetsParams, 'suspense'>;

export const useActiveProjectDatasets = (
  props?: UseActiveProjectDatasetsParams,
) => {
  const projectId = useActiveProjectId();

  return useDatasets({ projectId, suspense: props?.suspense });
};

export const useActiveProjectDatasetsLength = (
  props?: UseActiveProjectDatasetsParams,
) => {
  const projectId = useActiveProjectId();

  return useDatasetsLength({ projectId, suspense: props?.suspense });
};

export const useActiveProjectDatasetsAsOptions = (
  props?: UseActiveProjectDatasetsParams,
) => {
  const projectId = useActiveProjectId();

  return useDatasetsAsOptions({ projectId, suspense: props?.suspense });
};

export const useActiveProjectDatasetsAsOptionsWithAll = (
  props?: UseActiveProjectDatasetsParams,
) => {
  const datasetsOptions = useActiveProjectDatasetsAsOptions(props);

  return [{ text: 'All', key: 'all', value: null }, ...datasetsOptions];
};

export const useActiveProjectDatasetsListLoadingState = () => {
  const projectId = useActiveProjectId();

  return useDatasetsListLoadingState(projectId);
};

// WS update
export const removeDatasetWS = (params: { projectId: string; id: string }) => {
  const { projectId, id } = params;
  const prevData = getQueryData(DatasetService.getDatasets, { projectId });

  // Only perform WS update and side effects if dataset is in the cache
  if (prevData && prevData.items.find((d) => d.id === id)) {
    removeDatasetFromQuery(params);
    invalidateQueries(ImageService.projectImagesList);
    invalidateQueries(VideoService.videosList);
  }
};

export const removeDatasetFromQuery = ({
  projectId,
  id,
}: {
  projectId: string;
  id: string;
}) => {
  setQueryData(DatasetService.getDatasets, {
    params: { projectId },
    updater: removeItemWithId(id),
  });
};
