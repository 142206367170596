import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';

import {
  LoadingState,
  loadingStateBuilder,
} from '../../../../../utils/loadingState';
import {
  ModelStatus,
  modelStatusChecks,
  statusCheck,
  ToolModel,
} from '../models/modelStatus.constants';
import { ImageLabel } from '../../../../../../api/domainModels/imageLabel';
import { SEMANTIC_SEGMENTATION_DEFAULT_MIN_SIZE } from './semanticSegmentation.constants';

type SemanticSegmentationState = {
  data: EntityState<ImageLabel> & {
    loadingState: LoadingState;
  };
  minSize: number;
  model: ToolModel;
  useSAM: boolean;
};
export const adapter = createEntityAdapter<ImageLabel>();
const initialState: SemanticSegmentationState = {
  data: adapter.getInitialState({
    loadingState: loadingStateBuilder.initial(),
  }),
  minSize: SEMANTIC_SEGMENTATION_DEFAULT_MIN_SIZE,
  model: {
    id: null,
    loadingState: loadingStateBuilder.initial(),
    status: null,
    progress: null,
    showDot: false,
  },
  useSAM: false,
};
const name = 'semanticSegmentation';
const { actions, reducer: semanticSegmentationReducer } = createSlice({
  name,
  initialState,
  reducers: {
    addLabelFromProposedMask(_state, _action: PayloadAction<string>) {},
    rejectLabel(state, action: PayloadAction<string>) {
      adapter.removeOne(state.data, action.payload);
    },
    confirmAddLabelFromProposedObject(
      state,
      action: PayloadAction<string /* id */>,
    ) {
      adapter.removeOne(state.data, action.payload);
    },
    addLabelsFromProposedMasks() {},
    adjustMinSize(_state, _action: PayloadAction<boolean>) {},
    loadMasks(state) {
      state.data.loadingState =
        loadingStateBuilder.inProgress('Predicting masks');
    },
    loadMasksFailure(state, action: PayloadAction<string>) {
      state.data.loadingState = loadingStateBuilder.failure(action.payload);
    },
    loadMasksSuccess(state, action: PayloadAction<ImageLabel[]>) {
      state.data.loadingState = loadingStateBuilder.success();
      adapter.setAll(state.data, action.payload);
    },
    loadSemanticSegmentationModel(_state, _action: PayloadAction<string>) {},
    loadSemanticSegmentationModelFailure(state, action: PayloadAction<string>) {
      state.model.loadingState = loadingStateBuilder.failure(action.payload);
    },
    loadSemanticSegmentationModelStart(state, action: PayloadAction<string>) {
      state.model.loadingState = loadingStateBuilder.inProgress(action.payload);
    },
    loadSemanticSegmentationModelSuccess(state) {
      state.model.loadingState = loadingStateBuilder.success();
    },
    resetData(state) {
      state.data = initialState.data;
    },
    resetModel(state) {
      state.model = initialState.model;
    },
    setMinSize(_state, _action: PayloadAction<number>) {},
    resetMinSize(_state) {},
    confirmMinSize(state, action: PayloadAction<number>) {
      state.minSize = action.payload;
    },
    updateSemanticSegmentationModel(
      _state,
      _action: PayloadAction<{
        status: ModelStatus;
        progress: number;
        id: number;
        modelUseIE?: boolean;
      }>,
    ) {},
    updateSemanticSegmentationModelSuccess(
      state,
      action: PayloadAction<{
        id: number | null;
        status: ModelStatus;
        progress: number | null;
      }>,
    ) {
      const { id, status, progress } = action.payload;

      state.model = {
        ...state.model,
        id: modelStatusChecks.isLoaded(status) ? id : state.model.id,
        status: statusCheck(status, state.model.status),
        progress: progress || 0,
      };
    },
    updateSemanticSegmentationModelShowDot(
      state,
      action: PayloadAction<boolean>,
    ) {
      const showDot = action.payload;

      state.model = {
        ...state.model,
        showDot,
      };
    },
    toggleUseSAM(state) {
      state.useSAM = !state.useSAM;
    },
  },
});

export const {
  addLabelFromProposedMask,
  rejectLabel,
  confirmAddLabelFromProposedObject,
  addLabelsFromProposedMasks,
  adjustMinSize,
  confirmMinSize,
  loadMasks,
  loadMasksFailure,
  loadMasksSuccess,
  loadSemanticSegmentationModel,
  loadSemanticSegmentationModelFailure,
  loadSemanticSegmentationModelStart,
  loadSemanticSegmentationModelSuccess,
  resetData,
  resetModel,
  setMinSize,
  resetMinSize,
  updateSemanticSegmentationModel,
  updateSemanticSegmentationModelSuccess,
  updateSemanticSegmentationModelShowDot,
  toggleUseSAM,
} = actions;

export { semanticSegmentationReducer };
