export const getMaskOptimisationMultiplier = ([width, height]: [
  number,
  number,
]) => {
  const area = width * height;

  if (area < 800 * 800) {
    return 1;
  }

  if (area > 10000 * 10000) {
    return 0.1;
  }

  return 0.2;
};
