import {
  createSlice,
  PayloadAction,
  createEntityAdapter,
  EntityState,
  Update,
} from '@reduxjs/toolkit';

import {
  ExperimentParameter,
  ExperimentComponent,
} from '../../../../../../api/domainModels/modelPlayground';
import {
  loadingStateBuilder,
  LoadingState,
} from '../../../../../utils/loadingState';
import { setActiveExperimentId } from '../activeExperimentId/activeExperimentId.slice';
import { setEditedProjectId } from '../../../../sections/editedProject/project/project.slice';

export const componentsAdapter = createEntityAdapter<ExperimentComponent>({
  sortComparer: (a, b) => (a.componentId < b.componentId ? 1 : -1),
});
export const parametersAdapter = createEntityAdapter<ExperimentParameter>();

type SolversState = {
  components: EntityState<ExperimentComponent> & {
    loadingState: LoadingState;
    updateLoadingState: LoadingState;
  };
  parameters: EntityState<ExperimentParameter> & {
    loadingState: LoadingState;
    updateLoadingState: LoadingState;
  };
};

const initialState: SolversState = {
  components: componentsAdapter.getInitialState({
    loadingState: loadingStateBuilder.initial(),
    updateLoadingState: loadingStateBuilder.initial(),
  }),
  parameters: parametersAdapter.getInitialState({
    loadingState: loadingStateBuilder.initial(),
    updateLoadingState: loadingStateBuilder.initial(),
  }),
};

const { actions, reducer: solversReducer } = createSlice({
  name: 'solvers',
  initialState,
  reducers: {
    loadSolvers(state) {
      state.components.loadingState =
        loadingStateBuilder.inProgress('Loading solvers');
      componentsAdapter.removeAll(state.components);
      parametersAdapter.removeAll(state.parameters);
    },
    loadSolversFailure(state, action: PayloadAction<string>) {
      state.components.loadingState = loadingStateBuilder.failure(
        action.payload,
      );
    },
    loadSolversSuccess(state, action: PayloadAction<ExperimentComponent[]>) {
      state.components.loadingState = loadingStateBuilder.success();
      componentsAdapter.setAll(state.components, action.payload);
    },
    updateExperimentSolver(state, _action: PayloadAction<string>) {
      state.components.updateLoadingState =
        loadingStateBuilder.inProgress('Updating solver');
    },
    updateExperimentSolverFailure(state, action: PayloadAction<string>) {
      state.components.updateLoadingState = loadingStateBuilder.failure(
        action.payload,
      );
    },
    updateExperimentSolverSuccess(
      state,
      action: PayloadAction<ExperimentComponent>,
    ) {
      // first remove the component that we selected to avoid iterating over it
      componentsAdapter.removeOne(state.components, action.payload.componentId);
      const componentsIds = state.components.ids;
      const componentsEntities = state.components.entities;
      // update remaining components by changing it's id's and nullifying it's experimentComponentIds
      componentsAdapter.updateMany(
        state.components,
        componentsIds.map((id) => ({
          id,
          changes: {
            // this unorthodox id change follows the `id: experimentComponentId || id` usage of mapped component
            id: componentsEntities[id]?.componentId,
            selected: false,
            experimentComponentId: null,
          },
        })),
      );
      // put back the selected component
      componentsAdapter.addOne(state.components, action.payload);
      state.components.updateLoadingState = loadingStateBuilder.success();
    },
    loadSolverParameters(state, _action: PayloadAction<string>) {
      state.parameters.loadingState =
        loadingStateBuilder.inProgress('Loading parameters');
      state.parameters = initialState.parameters;
    },
    loadSolverParametersFailure(state, action: PayloadAction<string>) {
      state.parameters.loadingState = loadingStateBuilder.failure(
        action.payload,
      );
    },
    loadSolverParametersSuccess(
      state,
      action: PayloadAction<ExperimentParameter[]>,
    ) {
      state.parameters.loadingState = loadingStateBuilder.success();
      parametersAdapter.setAll(state.parameters, action.payload);
    },
    updateSolverParameter(
      state,
      _action: PayloadAction<{
        solverParameterId: string;
        value: any;
      }>,
    ) {
      state.parameters.updateLoadingState =
        loadingStateBuilder.inProgress('Loading parameters');
    },
    updateSolverParameterFailure(state, action: PayloadAction<string>) {
      state.parameters.updateLoadingState = loadingStateBuilder.failure(
        action.payload,
      );
    },
    updateSolverParameterSuccess(
      state,
      action: PayloadAction<Update<ExperimentParameter>>,
    ) {
      state.parameters.updateLoadingState = loadingStateBuilder.success();
      parametersAdapter.updateOne(state.parameters, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setActiveExperimentId, () => initialState);
    builder.addCase(setEditedProjectId, () => initialState);
  },
});

export { solversReducer };
export const {
  loadSolvers,
  loadSolversSuccess,
  loadSolversFailure,
  updateExperimentSolver,
  updateExperimentSolverFailure,
  updateExperimentSolverSuccess,
  loadSolverParameters,
  loadSolverParametersSuccess,
  loadSolverParametersFailure,
  updateSolverParameter,
  updateSolverParameterSuccess,
  updateSolverParameterFailure,
} = actions;
