import { createSlice } from '@reduxjs/toolkit';

export type ApplicationOutdatedState = boolean;
const initialState: ApplicationOutdatedState = false;
const { actions, reducer: applicationOutdatedReducer } = createSlice({
  name: 'applicationOutdated',
  initialState,
  reducers: {
    setApplicationOutdated() {
      return true;
    },
  },
});

export { applicationOutdatedReducer };
export const { setApplicationOutdated } = actions;
