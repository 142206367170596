import axios from 'axios';

import {
  ApiLoadWorkspaceStripePortalUrlParams,
  ApiLoadWorkspaceStripePortalUrlResponse,
} from '../schemas/stripe';
import { NEW_API_URL } from '../../constants/paths';
import { apiWrapper } from '../utils';
import { headers, transformRequest, transformResponse } from '../helpers';

export const apiLoadWorkspaceStripePortalUrl = (
  params: ApiLoadWorkspaceStripePortalUrlParams,
) =>
  apiWrapper(
    axios.request<ApiLoadWorkspaceStripePortalUrlResponse>({
      method: 'get',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/billing/subjects/${params.workspaceId}/portal`,
    }),
  );
