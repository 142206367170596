import { combineReducers } from 'redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { imageTagsReducer } from './imageTags/imageTags.slice';

type ProjectState = {
  projectId: string | null;
};
const initialState: ProjectState = {
  projectId: null,
};
const { actions, reducer: projectParamsReducer } = createSlice({
  name: 'imageViewProject',
  initialState,
  reducers: {
    setProjectId(state, action: PayloadAction<{ id: string }>) {
      state.projectId = action.payload.id;
    },
    resetProjectId(state) {
      state.projectId = null;
    },
    projectSelectedSuccess() {},
    projectSelectedError() {},
  },
});

export const {
  setProjectId,
  resetProjectId,
  projectSelectedSuccess,
  projectSelectedError,
} = actions;

export const projectReducer = combineReducers({
  projectParams: projectParamsReducer,
  imageTags: imageTagsReducer,
});
