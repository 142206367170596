import { all, put, select } from 'typed-redux-saga';

import {
  getResultFromWorker,
  MASK_WORKER,
  RLE_WORKER,
} from '../../../../../workers/workerManager';
import {
  METHOD_IMAGE_DATA_TO_RLE,
  METHOD_RLE_TO_IMAGE_DATA,
} from '../../../../../workers/rle/constants';
import {
  METHOD_GET_POLYGONS,
  METHOD_IMAGE_DATA_TO_SELECTION_MASK,
} from '../../../../../workers/mask/constants';
import { ImageLabel } from '../../../../../api/domainModels/imageLabel';
import { getCombinedLabelsImageData } from '../../../../../util/canvas';
import { getPointsBoundingBox } from '../../../../../util/polygon';
import { imageViewCurrentImagePropertiesSelector } from '../currentImage/currentImage.selectors';
import { Bbox } from '../../../../../@types/imageView/types';
import { getHeight, getWidth } from '../../../../../util/bbox';
import { getErrorMessage } from '../../../../../api/utils';
import { handleError } from '../../../commonFeatures/errorHandler/errorHandler.actions';
import { enqueueNotification } from '../../../ui/stackNotifications/stackNotifications.slice';

const AREA_TO_RETAIN = 0.995;
const ACCEPTABLE_SQ_PIXEL_LOSS = 100;

export function* convertMaskLabelToPolygon({
  bbox,
  mask: rleData,
}: {
  bbox: ImageLabel['bbox'];
  mask: ImageLabel['mask'];
}) {
  if (!bbox) return;

  const width = bbox[2] - bbox[0];
  const height = bbox[3] - bbox[1];

  const imageData = yield* getResultFromWorker(RLE_WORKER, {
    method: METHOD_RLE_TO_IMAGE_DATA,
    rle: {
      data: rleData,
      width,
      height,
    },
  });

  const result = yield* getResultFromWorker(MASK_WORKER, {
    method: METHOD_IMAGE_DATA_TO_SELECTION_MASK,
    imageData,
  });

  const mask = result[0];

  if (!mask) return;

  const polygons = yield* getResultFromWorker(MASK_WORKER, {
    method: METHOD_GET_POLYGONS,
    mask,
    options: {
      retainedArea: AREA_TO_RETAIN,
      acceptableSquarePixelLoss: ACCEPTABLE_SQ_PIXEL_LOSS,
    },
  });

  if (polygons.length === 0 || polygons[0].points.length < 3) {
    const message = 'Unable to convert mask to polygon: no data found';

    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
        },
      }),
    );

    throw new Error(message);
  }

  return polygons[0].points.map(([x, y]: [number, number]) => [
    bbox[0] + x,
    bbox[1] + y,
  ]);
}

export function* convertPolygonLabelToMask({
  points,
}: {
  points: ImageLabel['polygon'];
}) {
  const dimensions = yield* select(imageViewCurrentImagePropertiesSelector);

  if (!dimensions) {
    const errorMessage = 'Could not load image dimensions';

    throw new Error(errorMessage);
  }

  const canvasFactory = () => {
    const canvas = document.createElement('canvas');
    canvas.width = dimensions.width;
    canvas.height = dimensions.height;

    return canvas;
  };

  const imageData = getCombinedLabelsImageData(
    [{ points }],
    canvasFactory,
    dimensions.width,
    dimensions.height,
  );
  const bbox = getPointsBoundingBox(points);

  if (!bbox) {
    const errorMessage = 'Unable to convert polygon to mask: no data found';

    throw new Error(errorMessage);
  }

  const rle = yield* getResultFromWorker(RLE_WORKER, {
    method: METHOD_IMAGE_DATA_TO_RLE,
    imageData,
    bounds: bbox,
  });

  if (rle.data.length === 0) {
    const errorMessage = 'Unable to convert polygon to mask: no data found';

    throw new Error(errorMessage);
  }

  return {
    bbox,
    points: null,
    mask: rle.data,
  };
}

export function* convertPolygonLabelsMultiToMask({
  pointsList,
}: {
  pointsList: ImageLabel['polygon'][];
}) {
  const dimensions = yield* select(imageViewCurrentImagePropertiesSelector);

  if (!dimensions) {
    const errorMessage = 'Unable to get image dimensions';

    throw new Error(errorMessage);
  }

  const canvasFactory = () => {
    const canvas = document.createElement('canvas');
    canvas.width = dimensions.width;
    canvas.height = dimensions.height;

    return canvas;
  };

  const imageData = getCombinedLabelsImageData(
    pointsList.map((points) => ({ points })),
    canvasFactory,
    dimensions.width,
    dimensions.height,
  );
  const bbox = getPointsBoundingBox(pointsList.flat());

  if (!bbox) {
    const errorMessage = 'Unable to convert polygons to mask: no data found';

    throw new Error(errorMessage);
  }

  const rle = yield* getResultFromWorker(RLE_WORKER, {
    method: METHOD_IMAGE_DATA_TO_RLE,
    imageData,
    bounds: bbox,
  });

  if (rle.data.length === 0) {
    const errorMessage = 'Unable to convert polygons to mask: no data found';

    throw new Error(errorMessage);
  }

  return {
    bbox: bbox as Bbox,
    points: null,
    mask: rle.data,
  };
}

export function* convertMaskLabelsMultiToMask({
  labelsList,
}: {
  labelsList: ImageLabel[];
}) {
  const dimensions = yield* select(imageViewCurrentImagePropertiesSelector);

  if (!dimensions) {
    const errorMessage = 'Unable to get image dimensions';

    throw new Error(errorMessage);
  }

  const canvasFactory = () => {
    const canvas = document.createElement('canvas');
    canvas.width = dimensions.width;
    canvas.height = dimensions.height;

    return canvas;
  };
  try {
    const imageDataList: ImageData[] = yield* all(
      labelsList.map((l) =>
        getResultFromWorker(RLE_WORKER, {
          method: METHOD_RLE_TO_IMAGE_DATA,
          rle: {
            data: l.mask,
            width: getWidth(l.bbox),
            height: getHeight(l.bbox),
          },
        }),
      ),
    );
    const labelsData = imageDataList.map((imageData, index) => ({
      imageData,
      bbox: labelsList[index].bbox,
      points: labelsList[index].mask,
    }));
    const imageData = getCombinedLabelsImageData(
      labelsData,
      canvasFactory,
      dimensions.width,
      dimensions.height,
    );
    const bbox = getPointsBoundingBox(
      labelsList.map((label) => label.bbox).flat(),
    );

    if (!bbox) {
      const errorMessage = 'Unable to create mask: no data found';

      throw new Error(errorMessage);
    }

    const rle = yield* getResultFromWorker(RLE_WORKER, {
      method: METHOD_IMAGE_DATA_TO_RLE,
      imageData,
      bounds: bbox,
    });

    if (rle.data.length === 0) {
      const errorMessage = 'Unable to create mask: no data found';

      throw new Error(errorMessage);
    }

    return {
      bbox: bbox as Bbox,
      points: null,
      mask: rle.data,
    };
  } catch (error) {
    const errorMessage = getErrorMessage(error, '');
    yield* put(handleError({ message: errorMessage, error }));
  }
}
