import { select, takeEvery } from 'typed-redux-saga';

import { getQueryData, invalidateQueries } from '../../../../api/api';
import { ModelService, ToolService } from '../../../../api/codegen';
import { ATOM_FAMILY_NAME } from '../../core/imageView/tools/atom/constants';
import { BOX_TO_INSTANCE_FAMILY_NAME } from '../../core/imageView/tools/boxToInstance/boxToInstance.constants';
import { MODEL_LOADED as MODEL_STATUS_LOADED } from '../../core/imageView/tools/models/modelStatus.constants';
import { TEXT_PROMPT_FAMILY_NAME } from '../../core/imageView/tools/textPrompt/textPrompt.constants';
import { activeProjectIdSelector } from '../../project/project.selectors';
import { MODEL_LOADED, MODEL_UPDATED } from '../../ws/ws.constants';

function* statusChangeHandler(action: {
  type: typeof MODEL_LOADED | typeof MODEL_UPDATED;
  payload: {
    status: string;
    family: string;
    projectId: string;
    id: number;
  };
}) {
  const { id, family, projectId, status } = action.payload;

  const activeProjectId = yield* select(activeProjectIdSelector);
  if (projectId !== activeProjectId) return;

  const shouldSkipModels = [
    BOX_TO_INSTANCE_FAMILY_NAME,
    ATOM_FAMILY_NAME,
    TEXT_PROMPT_FAMILY_NAME,
  ].includes(family);
  if (shouldSkipModels) return;

  if (status === MODEL_STATUS_LOADED && family) {
    invalidateQueries(ToolService.getV1ProjectsModelsQualityScores, {
      projectId,
      modelId: id,
    });

    const modelFamiliesData = getQueryData(
      ModelService.getV1ProjectsModelFamilies,
      { projectId },
    );
    if (!modelFamiliesData?.some((status) => status.modelId === id)) {
      invalidateQueries(ModelService.getV1ProjectsModelFamilies);
    }
  }
}

// Did not remove this saga handler since MODEL_LOADED is used in many sagas
// All MODEL_LOADED need to be replaced at the same time
export function* modelQualitySaga() {
  yield* takeEvery([MODEL_LOADED, MODEL_UPDATED], statusChangeHandler);
}
