import { createSelector } from '@reduxjs/toolkit';

import {
  PackageName,
  isFreePackage,
} from '../../../../../constants/packageFeatures';
import { packageFeaturesPlansSelector } from '../../../commonFeatures/packageFeatures/packageFeatures.selectors';
import {
  managedWorkspaceDataSelector,
  managedWorkspaceSelector,
} from '../managedWorkspace.selectors';
import { VAT_STATUS_UNSPECIFIED } from '../../../../../components/VATValidation/constants';

const managedWorkspacePaymentsSelector = createSelector(
  managedWorkspaceSelector,
  (managedWorkspace) => managedWorkspace.payments,
);
export const managedWorkspaceSaveLoadingStateSelector = createSelector(
  managedWorkspacePaymentsSelector,
  (payments) => payments.saveLoadingState,
);
export const managedWorkspaceSubscriptionSelector = createSelector(
  managedWorkspacePaymentsSelector,
  (payments) => payments.data?.subscription,
);
export const managedWorkspaceSubscriptionExpirationDateSelector =
  createSelector(managedWorkspaceSubscriptionSelector, (subscription) => {
    if (
      subscription?.currentPeriodEndsAt &&
      typeof subscription.currentPeriodEndsAt === 'string'
    ) {
      return new Date(subscription.currentPeriodEndsAt);
    }
  });
export const managedWorkspaceUpdatePlanLoadingStateSelector = createSelector(
  managedWorkspacePaymentsSelector,
  (payments) => payments.updatePlanLoadingState,
);
export const managedWorkspaceCurrentPlanNameBillingSelector = createSelector(
  managedWorkspacePaymentsSelector,
  (payments) => payments.data?.subscription.planCurrentPeriod,
);
export const managedWorkspaceCurrentPlanExpireDateSelector = createSelector(
  managedWorkspacePaymentsSelector,
  (payments) => payments.data?.subscription.currentPeriodEndsAt,
);
export const managedWorkspacePaymentsLoadingStateSelector = createSelector(
  managedWorkspacePaymentsSelector,
  (payments) => payments.loadingState,
);
export const managedWorkspaceCurrentPlanSelector = createSelector(
  [
    packageFeaturesPlansSelector,
    managedWorkspaceDataSelector,
    managedWorkspaceCurrentPlanNameBillingSelector,
  ],
  (plans, workspace, currentPlanName) => {
    // for free workspace we don't use billing data because of BE limitations it will be "parked"
    const planName =
      currentPlanName === PackageName.Parked &&
      isFreePackage(workspace?.subscription)
        ? workspace?.subscription
        : currentPlanName;

    return plans.find(({ name }) => name === planName);
  },
);

export const managedWorkspaceBillingSelector = createSelector(
  managedWorkspacePaymentsSelector,
  (payments) => payments?.data?.billing,
);
export const managedWorkspaceVATValidationStatusSelector = createSelector(
  managedWorkspacePaymentsSelector,
  (payments) => payments?.data?.vatValidationStatus || VAT_STATUS_UNSPECIFIED,
);
