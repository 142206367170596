import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
  EntityState,
} from '@reduxjs/toolkit';

import {
  LoadingState,
  loadingStateBuilder,
} from '../../../../../utils/loadingState';
import {
  ModelStatus,
  modelStatusChecks,
  statusCheck,
  ToolModel,
} from '../models/modelStatus.constants';

type BoxToInstanceObject = {
  storedObjectId: number;
  labelId: string;
};
export const adapter = createEntityAdapter<BoxToInstanceObject>({
  selectId: (object) => object.labelId,
});

type BoxToInstanceState = {
  model: ToolModel;
  loadingState: LoadingState;
  objects: EntityState<BoxToInstanceObject>;
};
const initialState: BoxToInstanceState = {
  model: {
    id: null,
    loadingState: loadingStateBuilder.initial(),
    status: null,
    progress: null,
  },
  loadingState: loadingStateBuilder.initial(),
  objects: adapter.getInitialState(),
};
const { actions, reducer: boxToInstanceReducer } = createSlice({
  name: 'boxToInstance',
  initialState,
  reducers: {
    convertAll() {},
    convertSelected(_state, _action: PayloadAction<number>) {},
    rejectSelected(state, action: PayloadAction<string>) {
      adapter.removeOne(state.objects, action.payload);
    },
    convertSuccess(state, action: PayloadAction<string[]>) {
      adapter.removeMany(state.objects, action.payload);
    },
    loadBoxToInstance(state) {
      const loadingMessage =
        state.objects.ids.length === 0 ? 'Applying box to instance' : '';

      state.loadingState = loadingStateBuilder.inProgress(loadingMessage);
    },
    loadBoxToInstanceFailure(state, action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.failure(action.payload);
    },
    loadBoxToInstanceSuccess(
      state,
      action: PayloadAction<BoxToInstanceObject[]>,
    ) {
      adapter.setAll(state.objects, action.payload);
      state.loadingState = loadingStateBuilder.success();
    },
    loadBoxToInstanceModel(_state, _action: PayloadAction<string>) {},
    loadBoxToInstanceModelFailure(state, action: PayloadAction<string>) {
      state.model.loadingState = loadingStateBuilder.failure(action.payload);
    },
    loadBoxToInstanceModelStart(state, action: PayloadAction<string>) {
      state.model.loadingState = loadingStateBuilder.inProgress(action.payload);
    },
    loadBoxToInstanceModelSuccess(state) {
      state.model.loadingState = loadingStateBuilder.success();
    },
    resetObjects(state) {
      state.objects = initialState.objects;
    },
    resetModel(state) {
      state.model = initialState.model;
    },
    updateBoxToInstanceModel(
      _state,
      _action: PayloadAction<{
        status: ModelStatus;
        progress: number;
        id: number;
        modelUseIE?: boolean;
      }>,
    ) {},
    updateBoxToInstanceModelSuccess(
      state,
      action: PayloadAction<{
        id: number | null;
        status: ModelStatus;
        progress: number | null;
      }>,
    ) {
      const { id, status, progress } = action.payload;

      state.model = {
        ...state.model,
        id: modelStatusChecks.isLoaded(status) ? id : state.model.id,
        status: statusCheck(status, state.model.status),
        progress: progress || 0,
      };
    },
  },
});

export const {
  convertAll,
  convertSelected,
  rejectSelected,
  convertSuccess,
  loadBoxToInstance,
  loadBoxToInstanceFailure,
  loadBoxToInstanceSuccess,
  loadBoxToInstanceModel,
  loadBoxToInstanceModelFailure,
  loadBoxToInstanceModelStart,
  loadBoxToInstanceModelSuccess,
  resetModel,
  resetObjects,
  updateBoxToInstanceModel,
  updateBoxToInstanceModelSuccess,
} = actions;

export { boxToInstanceReducer };
