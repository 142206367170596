import {
  createSlice,
  PayloadAction,
  createEntityAdapter,
  EntityState,
  Update,
} from '@reduxjs/toolkit';

import {
  ExperimentParameter,
  ExperimentComponent,
  ExperimentWeight,
  SaveTrainedWeightValues,
} from '../../../../../../api/domainModels/modelPlayground';
import {
  loadingStateBuilder,
  LoadingState,
} from '../../../../../utils/loadingState';
import { setActiveExperimentId } from '../activeExperimentId/activeExperimentId.slice';
import { setEditedProjectId } from '../../../../sections/editedProject/project/project.slice';

export const componentsAdapter = createEntityAdapter<ExperimentComponent>({
  sortComparer: (a, b) => (a.componentId < b.componentId ? 1 : -1),
});
export const parametersAdapter = createEntityAdapter<ExperimentParameter>();
export const nestedParametersAdapter =
  createEntityAdapter<ExperimentParameter>();

type ArchitecturesState = {
  components: EntityState<ExperimentComponent> & {
    loadingState: LoadingState;
    updateLoadingState: LoadingState;
  };
  parameters: EntityState<ExperimentParameter> & {
    loadingState: LoadingState;
    updateLoadingState: LoadingState;
  };
  nestedParameters: EntityState<ExperimentParameter> & {
    loadingState: LoadingState;
    updateLoadingState: LoadingState;
  };
  weights: ExperimentWeight[];
  weightsLoadingState: LoadingState;
  weightsUpdateLoadingState: LoadingState;
  nestedWeights: ExperimentWeight[];
  nestedWeightsLoadingState: LoadingState;
  nestedWeightsUpdateLoadingState: LoadingState;
  saveWeightsLoadingState: LoadingState;
};

const initialState: ArchitecturesState = {
  components: componentsAdapter.getInitialState({
    loadingState: loadingStateBuilder.initial(),
    updateLoadingState: loadingStateBuilder.initial(),
  }),
  parameters: parametersAdapter.getInitialState({
    loadingState: loadingStateBuilder.initial(),
    updateLoadingState: loadingStateBuilder.initial(),
  }),
  nestedParameters: nestedParametersAdapter.getInitialState({
    loadingState: loadingStateBuilder.initial(),
    updateLoadingState: loadingStateBuilder.initial(),
  }),
  saveWeightsLoadingState: loadingStateBuilder.initial(),
  weights: [],
  weightsLoadingState: loadingStateBuilder.initial(),
  weightsUpdateLoadingState: loadingStateBuilder.initial(),
  nestedWeights: [],
  nestedWeightsLoadingState: loadingStateBuilder.initial(),
  nestedWeightsUpdateLoadingState: loadingStateBuilder.initial(),
};

const { actions, reducer: architecturesReducer } = createSlice({
  name: 'architectures',
  initialState,
  reducers: {
    loadArchitectures(state) {
      state.components.loadingState = loadingStateBuilder.inProgress(
        'Loading architectures',
      );
      componentsAdapter.removeAll(state.components);
      parametersAdapter.removeAll(state.parameters);
    },
    loadArchitecturesFailure(state, action: PayloadAction<string>) {
      state.components.loadingState = loadingStateBuilder.failure(
        action.payload,
      );
    },
    loadArchitecturesSuccess(
      state,
      action: PayloadAction<ExperimentComponent[]>,
    ) {
      state.components.loadingState = loadingStateBuilder.success();
      componentsAdapter.setAll(state.components, action.payload);
    },
    updateExperimentArchitecture(state, _action: PayloadAction<string>) {
      state.components.updateLoadingState = loadingStateBuilder.inProgress(
        'Updating architecture',
      );
    },
    updateExperimentArchitectureFailure(state, action: PayloadAction<string>) {
      state.components.updateLoadingState = loadingStateBuilder.failure(
        action.payload,
      );
    },
    updateExperimentArchitectureSuccess(
      state,
      action: PayloadAction<ExperimentComponent>,
    ) {
      // first remove the component that we selected to avoid iterating over it
      componentsAdapter.removeOne(state.components, action.payload.componentId);
      const componentsIds = state.components.ids;
      const componentsEntities = state.components.entities;
      // update remaining components by changing it's id's and nullifying it's experimentComponentIds
      componentsAdapter.updateMany(
        state.components,
        componentsIds.map((id) => ({
          id,
          changes: {
            // this unorthodox id change follows the `id: experimentComponentId || id` usage of mapped component
            id: componentsEntities[id]?.componentId,
            selected: false,
            experimentComponentId: null,
          },
        })),
      );
      // put back the selected component
      componentsAdapter.addOne(state.components, action.payload);
      state.components.updateLoadingState = loadingStateBuilder.success();
    },
    loadArchitectureParameters(state, _action: PayloadAction<string>) {
      state.parameters.loadingState =
        loadingStateBuilder.inProgress('Loading parameters');
      parametersAdapter.removeAll(state.parameters);
    },
    loadArchitectureParametersFailure(state, action: PayloadAction<string>) {
      state.parameters.loadingState = loadingStateBuilder.failure(
        action.payload,
      );
    },
    loadArchitectureParametersSuccess(
      state,
      action: PayloadAction<ExperimentParameter[]>,
    ) {
      state.parameters.loadingState = loadingStateBuilder.success();
      parametersAdapter.setAll(state.parameters, action.payload);
    },
    updateArchitectureParameter(
      state,
      _action: PayloadAction<{
        architectureParameterId: string;
        value: any;
      }>,
    ) {
      state.parameters.updateLoadingState =
        loadingStateBuilder.inProgress('Loading parameters');
    },
    updateArchitectureParameterFailure(state, action: PayloadAction<string>) {
      state.parameters.updateLoadingState = loadingStateBuilder.failure(
        action.payload,
      );
    },
    updateArchitectureParameterSuccess(
      state,
      action: PayloadAction<Update<ExperimentParameter>>,
    ) {
      state.parameters.updateLoadingState = loadingStateBuilder.success();
      parametersAdapter.updateOne(state.parameters, action.payload);
    },
    loadArchitectureNestedParameters(state, _action: PayloadAction<string>) {
      state.nestedParameters.loadingState =
        loadingStateBuilder.inProgress('Loading parameters');
      parametersAdapter.removeAll(state.nestedParameters);
    },
    loadArchitectureNestedParametersFailure(
      state,
      action: PayloadAction<string>,
    ) {
      state.nestedParameters.loadingState = loadingStateBuilder.failure(
        action.payload,
      );
    },
    loadArchitectureNestedParametersSuccess(
      state,
      action: PayloadAction<ExperimentParameter[]>,
    ) {
      state.nestedParameters.loadingState = loadingStateBuilder.success();
      parametersAdapter.setAll(state.nestedParameters, action.payload);
    },
    updateArchitectureNestedParameter(
      state,
      _action: PayloadAction<{
        componentId: string;
        architectureParameterId: string;
        value: any;
      }>,
    ) {
      state.nestedParameters.updateLoadingState =
        loadingStateBuilder.inProgress('Loading parameters');
    },
    updateArchitectureNestedParameterFailure(
      state,
      action: PayloadAction<string>,
    ) {
      state.nestedParameters.updateLoadingState = loadingStateBuilder.failure(
        action.payload,
      );
    },
    updateArchitectureNestedParameterSuccess(
      state,
      action: PayloadAction<Update<ExperimentParameter>>,
    ) {
      state.nestedParameters.updateLoadingState = loadingStateBuilder.success();
      parametersAdapter.updateOne(state.nestedParameters, action.payload);
    },
    loadArchitectureWeights(
      state,
      _action: PayloadAction<{
        fullWeights: boolean;
      }>,
    ) {
      state.weightsLoadingState =
        loadingStateBuilder.inProgress('Loading weights');
      state.weights = [];
    },
    loadArchitectureWeightsFailure(state, action: PayloadAction<string>) {
      state.weightsLoadingState = loadingStateBuilder.failure(action.payload);
    },
    loadArchitectureWeightsSuccess(
      state,
      action: PayloadAction<ExperimentWeight[]>,
    ) {
      state.weightsLoadingState = loadingStateBuilder.success();
      state.weights = action.payload;
    },
    updateArchitectureWeights(state, _action: PayloadAction<string>) {
      state.weightsUpdateLoadingState =
        loadingStateBuilder.inProgress('Loading weights');
    },
    updateArchitectureWeightsFailure(state, action: PayloadAction<string>) {
      state.weightsUpdateLoadingState = loadingStateBuilder.failure(
        action.payload,
      );
    },
    updateArchitectureWeightsSuccess(
      state,
      action: PayloadAction<Update<ExperimentParameter>>,
    ) {
      state.weightsUpdateLoadingState = loadingStateBuilder.success();
      parametersAdapter.updateOne(state.parameters, action.payload);
    },
    loadArchitectureNestedWeights(state) {
      state.nestedWeightsLoadingState =
        loadingStateBuilder.inProgress('Loading weights');
      state.nestedWeights = [];
    },
    loadArchitectureNestedWeightsFailure(state, action: PayloadAction<string>) {
      state.nestedWeightsLoadingState = loadingStateBuilder.failure(
        action.payload,
      );
    },
    loadArchitectureNestedWeightsSuccess(
      state,
      action: PayloadAction<ExperimentWeight[]>,
    ) {
      state.nestedWeightsLoadingState = loadingStateBuilder.success();
      state.nestedWeights = action.payload;
    },
    updateArchitectureNestedWeights(state, _action: PayloadAction<string>) {
      state.nestedWeightsUpdateLoadingState =
        loadingStateBuilder.inProgress('Loading weights');
    },
    updateArchitectureNestedWeightsFailure(
      state,
      action: PayloadAction<string>,
    ) {
      state.nestedWeightsUpdateLoadingState = loadingStateBuilder.failure(
        action.payload,
      );
    },
    updateArchitectureNestedWeightsSuccess(
      state,
      action: PayloadAction<Update<ExperimentParameter>>,
    ) {
      state.nestedWeightsUpdateLoadingState = loadingStateBuilder.success();
      nestedParametersAdapter.updateOne(state.nestedParameters, action.payload);
    },
    saveTrainedWeight(state, _action: PayloadAction<SaveTrainedWeightValues>) {
      state.saveWeightsLoadingState = loadingStateBuilder.inProgress();
    },
    saveTrainedWeightFailure(state, action: PayloadAction<string>) {
      state.saveWeightsLoadingState = loadingStateBuilder.failure(
        action.payload,
      );
    },
    saveTrainedWeightSuccess(state) {
      state.saveWeightsLoadingState = loadingStateBuilder.success();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setActiveExperimentId, () => initialState);
    builder.addCase(setEditedProjectId, () => initialState);
  },
});

export { architecturesReducer };
export const {
  loadArchitectures,
  loadArchitecturesSuccess,
  loadArchitecturesFailure,
  updateExperimentArchitecture,
  updateExperimentArchitectureFailure,
  updateExperimentArchitectureSuccess,
  loadArchitectureParameters,
  loadArchitectureParametersSuccess,
  loadArchitectureParametersFailure,
  updateArchitectureParameter,
  updateArchitectureParameterSuccess,
  updateArchitectureParameterFailure,
  loadArchitectureNestedParameters,
  loadArchitectureNestedParametersSuccess,
  loadArchitectureNestedParametersFailure,
  updateArchitectureNestedParameter,
  updateArchitectureNestedParameterSuccess,
  updateArchitectureNestedParameterFailure,
  loadArchitectureWeights,
  loadArchitectureWeightsSuccess,
  loadArchitectureWeightsFailure,
  updateArchitectureWeights,
  updateArchitectureWeightsSuccess,
  updateArchitectureWeightsFailure,
  loadArchitectureNestedWeights,
  loadArchitectureNestedWeightsSuccess,
  loadArchitectureNestedWeightsFailure,
  updateArchitectureNestedWeights,
  updateArchitectureNestedWeightsSuccess,
  updateArchitectureNestedWeightsFailure,
  saveTrainedWeight,
  saveTrainedWeightFailure,
  saveTrainedWeightSuccess,
} = actions;
