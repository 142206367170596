import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  LoadingState,
  loadingStateBuilder,
} from '../../../../../utils/loadingState';
import {
  ModelStatus,
  modelStatusChecks,
  statusCheck,
  ToolModel,
} from '../models/modelStatus.constants';
import { ImageLabel } from '../../../../../../api/domainModels/imageLabel';

type LabelClassPredictionState = {
  model: ToolModel;
  loadingState: LoadingState;
  data: Record<string, boolean>;
};
const initialState: LabelClassPredictionState = {
  model: {
    id: null,
    loadingState: loadingStateBuilder.initial(),
    status: null,
    progress: null,
    showDot: false,
  },
  loadingState: loadingStateBuilder.initial(),
  data: {},
};
const { actions, reducer: labelClassPredictionReducer } = createSlice({
  name: 'labelClassPrediction',
  initialState,
  reducers: {
    disableLabelClassPrediction(state, action: PayloadAction<string>) {
      state.data[action.payload] = false;
    },
    loadLabelClassPredictionStart(state) {
      state.loadingState = loadingStateBuilder.inProgress();
    },
    loadLabelClassPredictionFailure(state, action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.failure(action.payload);
    },
    loadLabelClassPredictionSuccess(state) {
      state.loadingState = loadingStateBuilder.success();
    },
    loadLabelClassPredictionModel(_state, _action: PayloadAction<string>) {},
    loadLabelClassPredictionModelFailure(state, action: PayloadAction<string>) {
      state.model.loadingState = loadingStateBuilder.failure(action.payload);
    },
    loadLabelClassPredictionModelStart(state, action: PayloadAction<string>) {
      state.model.loadingState = loadingStateBuilder.inProgress(action.payload);
    },
    loadLabelClassPredictionModelSuccess(
      state,
      action: PayloadAction<
        | {
            id: number | null;
            status: ModelStatus;
            progress: number | null;
          }
        | undefined
      >,
    ) {
      state.model.loadingState = loadingStateBuilder.success();

      if (!action.payload) {
        return state;
      }

      const { id, status, progress } = action.payload;

      state.model = {
        ...state.model,
        id,
        status,
        progress: progress || 0,
      };
    },
    resetLoadingState(state) {
      state.loadingState = initialState.loadingState;
    },
    resetModel(state) {
      state.model = initialState.model;
    },
    shouldPerformPrediction(
      _state,
      _action: PayloadAction<Array<Partial<ImageLabel> & { id: string }>>,
    ) {},
    toggleLabelClassPrediction(state, action: PayloadAction<string>) {
      state.data[action.payload] = !state.data[action.payload];
    },
    updateLabelClassPredictionModel(
      _state,
      _action: PayloadAction<{
        status: ModelStatus;
        progress: number;
        id: number;
        modelUseIE?: boolean;
      }>,
    ) {},
    updateLabelClassPredictionModelSuccess(
      state,
      action: PayloadAction<{
        id: number | null;
        status: ModelStatus;
        progress: number | null;
      }>,
    ) {
      const { id, status, progress } = action.payload;

      state.model = {
        ...state.model,
        id: modelStatusChecks.isLoaded(status) ? id : state.model.id,
        status: statusCheck(status, state.model.status),
        progress: progress || 0,
      };
    },
    updateLabelClassPredictionModelShowDot(
      state,
      action: PayloadAction<boolean>,
    ) {
      const showDot = action.payload;

      state.model = {
        ...state.model,
        showDot,
      };
    },
  },
});

export const {
  disableLabelClassPrediction,
  loadLabelClassPredictionStart,
  loadLabelClassPredictionFailure,
  loadLabelClassPredictionSuccess,
  loadLabelClassPredictionModel,
  loadLabelClassPredictionModelFailure,
  loadLabelClassPredictionModelStart,
  loadLabelClassPredictionModelSuccess,
  resetLoadingState,
  resetModel,
  shouldPerformPrediction,
  toggleLabelClassPrediction,
  updateLabelClassPredictionModel,
  updateLabelClassPredictionModelSuccess,
  updateLabelClassPredictionModelShowDot,
} = actions;
export { labelClassPredictionReducer };
