import { select, put, takeEvery } from 'typed-redux-saga';

import {
  setExpandedSidePanels,
  toggleSidebarPanel,
} from './expandedPanels.slice';
import { expandedPanelsSelector } from './expandedPanels.selectors';

function* toggleSidebarPanelHandler(
  action: ActionType<typeof toggleSidebarPanel>,
) {
  const { id, activeTab } = action.payload;

  if (!id || !activeTab) return;

  const expandedPanels = yield* select(expandedPanelsSelector);
  const adding = !expandedPanels[activeTab].includes(id);

  const panels = adding
    ? [...expandedPanels[activeTab], id]
    : expandedPanels[activeTab].filter((i) => i !== id);

  yield* put(setExpandedSidePanels({ expandedPanels: panels, activeTab }));
}

export function* expandedPanelsSaga() {
  yield* takeEvery(toggleSidebarPanel, toggleSidebarPanelHandler);
}
