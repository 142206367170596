import axios from 'axios';

import {
  ApiExportSession,
  ApiCreateExportSessionData,
  ApiLoadExportSessionsParams,
  ApiLoadExportSessionsResponse,
} from '../schemas/export';
import { apiWrapper } from '../utils';
import { NEW_API_URL } from '../../constants/paths';
import { headers, transformRequest, transformResponse } from '../helpers';

export const apiCreateExportSession = (
  projectId: string,
  data: ApiCreateExportSessionData,
) =>
  apiWrapper(
    axios.request<ApiExportSession>({
      url: `${NEW_API_URL}/projects/${projectId}/export_sessions`,
      method: 'post',
      headers,
      transformResponse,
      transformRequest,
      data,
    }),
  );

export const apiLoadExportSessions = ({
  projectId,
}: ApiLoadExportSessionsParams) =>
  apiWrapper(
    axios.request<ApiLoadExportSessionsResponse>({
      url: `${NEW_API_URL}/projects/${projectId}/export_sessions`,
      method: 'get',
      headers,
      transformResponse,
    }),
  );

export const apiDeleteExportSession = (projectId: string, sessionId: string) =>
  apiWrapper(
    axios.request({
      url: `${NEW_API_URL}/projects/${projectId}/export_sessions/${sessionId}`,
      method: 'delete',
    }),
  );
