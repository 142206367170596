import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';

import {
  LoadingState,
  loadingStateBuilder,
} from '../../../../../utils/loadingState';
import {
  ModelStatus,
  modelStatusChecks,
  statusCheck,
  ToolModel,
} from '../models/modelStatus.constants';
import { ImageLabel } from '../../../../../../api/domainModels/imageLabel';
import { Bbox } from '../../../../../../@types/imageView/types';
import {
  INSTANCE_SEGMENTATION_DEFAULT_CONFIDENCE,
  INSTANCE_SEGMENTATION_DEFAULT_MASKER_THRESHOLD,
  INSTANCE_SEGMENTATION_DEFAULT_MAX_DETECTIONS,
} from './instanceSegmentation.constants';

type InstanceSegmentationState = {
  data: EntityState<ImageLabel> & {
    loadingState: LoadingState;
  };
  model: ToolModel;
  confidence: number;
  maskerThreshold: number;
  maxDetections: number;
  useSAM: boolean;
};
export const adapter = createEntityAdapter<ImageLabel>();
const initialState: InstanceSegmentationState = {
  data: adapter.getInitialState({
    loadingState: loadingStateBuilder.initial(),
  }),
  model: {
    id: null,
    loadingState: loadingStateBuilder.initial(),
    status: null,
    progress: null,
    showDot: false,
  },
  confidence: INSTANCE_SEGMENTATION_DEFAULT_CONFIDENCE,
  maskerThreshold: INSTANCE_SEGMENTATION_DEFAULT_MASKER_THRESHOLD,
  maxDetections: INSTANCE_SEGMENTATION_DEFAULT_MAX_DETECTIONS,
  useSAM: false,
};
const { actions, reducer: instanceSegmentationReducer } = createSlice({
  name: 'instanceSegmentation',
  initialState,
  reducers: {
    addLabelFromProposedObject(_state, _action: PayloadAction<string>) {},
    rejectLabel(state, action: PayloadAction<string>) {
      adapter.removeOne(state.data, action.payload);
    },
    confirmAddLabelFromProposedObject(
      state,
      action: PayloadAction<string /* id */>,
    ) {
      adapter.removeOne(state.data, action.payload);
    },
    addLabelsFromProposedObjects() {},
    adjustConfidence(
      _state,
      _action: PayloadAction<{ increment: boolean; visibleAreaBbox: Bbox }>,
    ) {},
    adjustMaxDetections(
      _state,
      _action: PayloadAction<{ increment: boolean; visibleAreaBbox: Bbox }>,
    ) {},
    confirmMaxDetections(state, action: PayloadAction<number>) {
      state.maxDetections = action.payload;
    },
    loadObjects(state, _action: PayloadAction<Bbox>) {
      state.data.loadingState =
        loadingStateBuilder.inProgress('Predicting masks');
    },
    loadObjectsFailure(state, action: PayloadAction<string>) {
      state.data.loadingState = loadingStateBuilder.failure(action.payload);
    },
    loadObjectsSuccess(state, action: PayloadAction<ImageLabel[]>) {
      state.data.loadingState = loadingStateBuilder.success();
      adapter.setAll(state.data, action.payload);
    },
    loadInstanceSegmentationModel(_state, _action: PayloadAction<string>) {},
    loadInstanceSegmentationModelFailure(state, action: PayloadAction<string>) {
      state.model.loadingState = loadingStateBuilder.failure(action.payload);
    },
    loadInstanceSegmentationModelStart(state, action: PayloadAction<string>) {
      state.model.loadingState = loadingStateBuilder.inProgress(action.payload);
    },
    loadInstanceSegmentationModelSuccess(state) {
      state.model.loadingState = loadingStateBuilder.success();
    },
    resetData(state) {
      state.data = initialState.data;
    },
    resetModel(state) {
      state.model = initialState.model;
    },
    setConfidence(
      state,
      action: PayloadAction<{ confidence: number; visibleAreaBbox: Bbox }>,
    ) {
      state.confidence = action.payload.confidence;
    },
    resetConfidence(state, _action: PayloadAction<{ visibleAreaBbox: Bbox }>) {
      state.confidence = initialState.confidence;
    },
    setMaskerThreshold(
      state,
      action: PayloadAction<{ threshold: number; visibleAreaBbox: Bbox }>,
    ) {
      state.maskerThreshold = action.payload.threshold;
    },
    resetMaskerThreshold(
      state,
      _action: PayloadAction<{ visibleAreaBbox: Bbox }>,
    ) {
      state.maskerThreshold = initialState.maskerThreshold;
    },
    setMaxDetections(
      _state,
      _action: PayloadAction<{ maxDetections: number; visibleAreaBbox: Bbox }>,
    ) {},
    resetMaxDetections(
      _state,
      _action: PayloadAction<{ visibleAreaBbox: Bbox }>,
    ) {},
    updateInstanceSegmentationModel(
      _state,
      _action: PayloadAction<{
        status: ModelStatus;
        progress: number;
        id: number;
        modelUseIE?: boolean;
      }>,
    ) {},
    updateInstanceSegmentationModelSuccess(
      state,
      action: PayloadAction<{
        id: number | null;
        status: ModelStatus;
        progress: number | null;
      }>,
    ) {
      const { id, status, progress } = action.payload;

      state.model = {
        ...state.model,
        id: modelStatusChecks.isLoaded(status) ? id : state.model.id,
        status: statusCheck(status, state.model.status),
        progress: progress || 0,
      };
    },
    updateInstanceSegmentationModelShowDot(
      state,
      action: PayloadAction<boolean>,
    ) {
      const showDot = action.payload;

      state.model = {
        ...state.model,
        showDot,
      };
    },
    toggleUseSAM(state, _action: PayloadAction<{ visibleAreaBbox: Bbox }>) {
      state.useSAM = !state.useSAM;
    },
  },
});

export { instanceSegmentationReducer };
export const {
  addLabelFromProposedObject,
  rejectLabel,
  confirmAddLabelFromProposedObject,
  addLabelsFromProposedObjects,
  adjustConfidence,
  adjustMaxDetections,
  confirmMaxDetections,
  loadObjects,
  loadObjectsFailure,
  loadObjectsSuccess,
  loadInstanceSegmentationModel,
  loadInstanceSegmentationModelFailure,
  loadInstanceSegmentationModelStart,
  loadInstanceSegmentationModelSuccess,
  resetData,
  resetModel,
  setConfidence,
  resetConfidence,
  setMaskerThreshold,
  resetMaskerThreshold,
  setMaxDetections,
  resetMaxDetections,
  updateInstanceSegmentationModel,
  updateInstanceSegmentationModelSuccess,
  updateInstanceSegmentationModelShowDot,
  toggleUseSAM,
} = actions;
