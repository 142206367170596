import { createSelector } from '@reduxjs/toolkit';

import { adapter } from './runs.slice';
import { errorFinderSelector } from '../errorFinder.selectors';

export const runsStateSelector = createSelector(
  errorFinderSelector,
  (errorFinder) => errorFinder.runs,
);

const selectors = adapter.getSelectors(runsStateSelector);

export const runsSelector = selectors.selectAll;
export const runsByIdSelector = selectors.selectById;
export const runsMapSelector = selectors.selectEntities;

export const runsListLoadingStateSelector = createSelector(
  runsStateSelector,
  (state) => state.listLoadingState,
);
export const runsItemLoadingStateSelector = createSelector(
  runsStateSelector,
  (state) => state.itemLoadingState,
);
