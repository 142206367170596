import axios from 'axios';

import { ApiAggEntity, ApiKpi } from '../schemas/projectReportEntities';
import { NEW_API_URL } from '../../constants/paths';
import { apiWrapper } from '../utils';
import { headers, transformRequest, transformResponse } from '../helpers';

export const apiLoadReportsAggEntities = () =>
  apiWrapper(
    axios.request<ApiAggEntity[]>({
      method: 'get',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/reports/agg_entities`,
    }),
  );

export const apiLoadReportsKpis = () =>
  apiWrapper(
    axios.request<ApiKpi[]>({
      method: 'get',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/reports/kpis`,
    }),
  );
