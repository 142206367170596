/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Split partition to fetch the metrics for (train/test/validation)
 */
export enum PartitionName {
  TRAIN = 'train',
  VALIDATION = 'validation',
  TEST = 'test',
}
