import { matchPath } from 'react-router-dom';

import { IMAGE_VIEW_PATHNAME_SEGMENT } from '../redux/state/core/imageView/imageView.util';

export const getIsEditor = (pathname: string) =>
  matchPath(pathname, {
    path: `/projects/:projectId/${IMAGE_VIEW_PATHNAME_SEGMENT}/:imageId`,
    exact: true,
    strict: true,
  });

export const getIsMPDashboard = (pathname: string) =>
  !!matchPath(pathname, {
    path: '/projects/:projectId/playground/splits/:splitId/dashboard',
    exact: true,
    strict: true,
  });

export const getProjectAnnotationEnvironmentPath = ({
  projectId,
  isVideo = false,
}: {
  projectId: string;
  isVideo?: boolean;
}) =>
  isVideo
    ? `/projects/${projectId}/videos`
    : `/projects/${projectId}/${IMAGE_VIEW_PATHNAME_SEGMENT}`;
