/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type RankingSession = {
  /**
   * Image Ranking session ID
   */
  sessionId?: string;
  /**
   * Heuristic ID for the heuristic used in the ranking session
   */
  heuristicId?: string;
  /**
   * Name of the heuristic used in the ranking session
   */
  heuristicName?: string;
  /**
   * Model family used in the ranking session
   */
  modelFamily?: string;
  /**
   * Status of the ranking session
   */
  status?: RankingSession.status;
  /**
   * Origin of the ranking job (user or ai-assistant)
   */
  origin?: string;
  /**
   * Name given to the ranking session
   */
  sessionName?: string | null;
  /**
   * Dataset IDs for datasets to use in ranking
   */
  datasetIds?: Array<string>;
  /**
   * User ID
   */
  userId?: string | null;
  /**
   * Date of ranking creation
   */
  startTime?: string;
  /**
   * Error message if ranking session failed
   */
  errorMessage?: string | null;
};

export namespace RankingSession {

  /**
   * Status of the ranking session
   */
  export enum status {
    INIT = 'INIT',
    RUNNING = 'RUNNING',
    DONE = 'DONE',
    FAILED = 'FAILED',
  }


}

