import { all, call } from 'typed-redux-saga';

const CHUNK_LENGTH = 10;

export type ApiOperationParams = {
  id: string; // imageId | videoId
  projectId: string;
};

type ImageChunksParams = {
  projectId: string;
  imageIds: string[];
  apiOperation: (params: ApiOperationParams) => Generator<any, void>;
};

type VideoChunksParams = {
  projectId: string;
  videoIds: string[];
  apiOperation: (params: ApiOperationParams) => Generator<any, void>;
};

const isImageChunksParams = (
  params: ImageChunksParams | VideoChunksParams,
): params is ImageChunksParams =>
  (params as ImageChunksParams).imageIds !== undefined;

function* processChunk(params: ImageChunksParams | VideoChunksParams) {
  const isImage = isImageChunksParams(params);
  const { projectId, apiOperation } = params;

  const ids = isImage ? params.imageIds : params.videoIds;

  const processed = yield* all(
    ids.map(function* (id) {
      try {
        yield* call(apiOperation, { projectId, id });

        return true;
      } catch {
        return false;
      }
    }),
  );

  return processed.filter(Boolean).length;
}

export function* splitToChunks(params: ImageChunksParams | VideoChunksParams) {
  const isImage = isImageChunksParams(params);

  const selectedIds = isImage ? params.imageIds : params.videoIds;

  let index = 0;
  let completed = 0;

  do {
    const chunkIds = selectedIds.slice(index, index + CHUNK_LENGTH);

    const payload = isImage
      ? ({ ...params, imageIds: chunkIds } as ImageChunksParams)
      : ({ ...params, videoIds: chunkIds } as VideoChunksParams);

    const processed = yield* processChunk(payload);

    index += CHUNK_LENGTH;
    completed += processed;
  } while (index < selectedIds.length);

  return completed;
}
