import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { setEditedProjectId } from '../../../../sections/editedProject/project/project.slice';

const initialState = {
  id: null as null | string,
};

const { actions, reducer: activeExperimentReducer } = createSlice({
  name: 'modelPlaygroundDashboardActiveExperiment',
  initialState,
  reducers: {
    setActiveExperimentId(state, action: PayloadAction<string | null>) {
      state.id = action.payload;
    },
    goToDashboard(
      _state,
      _action: PayloadAction<{ splitId: string; experimentId: string }>,
    ) {},
  },
  extraReducers: (builder) => {
    builder.addCase(setEditedProjectId, () => initialState);
  },
});

export const { setActiveExperimentId, goToDashboard } = actions;
export { activeExperimentReducer };
