import { Bbox } from '../@types/imageView/types';

export const getCombinedLabelsImageData = (
  labels: {
    imageData?: ImageData;
    bbox?: Bbox;
    points: [number, number][] | number[] | null;
  }[],
  canvasFactory: () => HTMLCanvasElement,
  width: number,
  height: number,
) => {
  const dest = canvasFactory();

  const destContext = dest.getContext('2d');

  if (destContext) {
    destContext.fillStyle = '#ffffff';

    labels.forEach(({ imageData, bbox, points }) => {
      const src = canvasFactory();
      const srcContext = src.getContext('2d');
      if (imageData instanceof ImageData) {
        if (srcContext && bbox) {
          srcContext.putImageData(imageData, bbox[0], bbox[1]);
        }
        destContext.drawImage(src, 0, 0);
      } else if (points instanceof Array && points.length > 2) {
        destContext.beginPath();
        // @ts-expect-error
        points.forEach(([x, y], index) => {
          if (index === 0) {
            destContext.moveTo(x, y);
          } else {
            destContext.lineTo(x, y);
          }
        });
        destContext.fill();
      } else if (bbox instanceof Array) {
        destContext.rect(
          bbox[0],
          bbox[1],
          bbox[2] - bbox[0],
          bbox[3] - bbox[1],
        );
        destContext.fill();
      }
    });

    return destContext.getImageData(0, 0, width, height);
  }
};

export const getPathImageData = (canvas: HTMLCanvasElement, path: string) => {
  const ctx = canvas.getContext('2d');

  if (ctx) {
    ctx.fillStyle = '#ffffff';
    ctx.fill(new Path2D(path));

    return ctx.getImageData(0, 0, canvas.width, canvas.height);
  }
};
