import { put, takeEvery, select } from 'typed-redux-saga';

import {
  loadWebhookLogsStart,
  loadWebhookLogsSuccess,
  loadWebhookLogsFailure,
} from './webhookLogs.slice';
import { apiLoadProjectWebhookLogs } from '../../../../../api/requests/webhook';
import { getErrorMessage } from '../../../../../api/utils';
import { activeProjectIdSelector } from '../../../project/project.selectors';
import { loadAll } from '../../../../utils/api';
import { webhookLogsDataMapper } from '../../../../../api/domainModels/webhook';
import { enqueueNotification } from '../../../ui/stackNotifications/stackNotifications.slice';

function* loadWorkspaceLogsHandler(
  action: ActionType<typeof loadWebhookLogsStart>,
) {
  const projectId = yield* select(activeProjectIdSelector);
  if (!projectId) return;
  const webhookId = action.payload;

  try {
    const items = yield* loadAll({
      apiHelper: apiLoadProjectWebhookLogs,
      params: {
        projectId,
        webhookId,
      },
    });

    yield* put(
      loadWebhookLogsSuccess({
        id: webhookId,
        items: items.map(webhookLogsDataMapper.fromBackend),
      }),
    );
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to load webhook logs');

    yield* put(loadWebhookLogsFailure(message));
    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
        },
      }),
    );
  }
}

export function* webhookLogsSaga() {
  yield* takeEvery(loadWebhookLogsStart, loadWorkspaceLogsHandler);
}
