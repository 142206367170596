import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
  EntityState,
} from '@reduxjs/toolkit';

import { PredictedTag } from '../../../../../../api/domainModels/imageTag';
import {
  LoadingState,
  loadingStateBuilder,
} from '../../../../../utils/loadingState';
import {
  ModelStatus,
  modelStatusChecks,
  statusCheck,
  ToolModel,
} from '../models/modelStatus.constants';

export const adapter = createEntityAdapter<PredictedTag>({
  selectId: (PredictedImageTag) => PredictedImageTag.classId,
});
type ImageTagsPredictionState = {
  model: ToolModel;
  data: EntityState<PredictedTag> & { loadingState: LoadingState };
  threshold: number;
};
const initialState: ImageTagsPredictionState = {
  model: {
    id: null,
    loadingState: loadingStateBuilder.initial(),
    status: null,
    progress: null,
    showDot: false,
  },
  data: adapter.getInitialState({
    loadingState: loadingStateBuilder.initial(),
  }),
  threshold: 0.5,
};
const { actions, reducer: imageTagsPredictionReducer } = createSlice({
  name: 'imageViewImageTagsPrediction',
  initialState,
  reducers: {
    loadImageTagsPredictionModel(_state, _action: PayloadAction<string>) {},
    loadImageTagsPredictionModelError(state, action: PayloadAction<string>) {
      state.model.loadingState = loadingStateBuilder.failure(action.payload);
    },
    loadImageTagsPredictionModelStart(state, action: PayloadAction<string>) {
      state.model.loadingState = loadingStateBuilder.inProgress(action.payload);
    },
    loadImageTagsPredictionModelSuccess(
      state,
      action: PayloadAction<
        | {
            id: number | null;
            status: ModelStatus;
            progress: number | null;
          }
        | undefined
      >,
    ) {
      state.model.loadingState = loadingStateBuilder.success();

      if (!action.payload) {
        return state;
      }

      const { id, status, progress } = action.payload;

      state.model = {
        ...state.model,
        id,
        status,
        progress: progress || 0,
      };
    },
    loadImageTagsPrediction() {},
    loadImageTagsPredictionError(state, action: PayloadAction<string>) {
      state.data.loadingState = loadingStateBuilder.failure(action.payload);
    },
    loadImageTagsPredictionStart(state) {
      state.data.loadingState = loadingStateBuilder.inProgress();
    },
    loadImageTagsPredictionSuccess(
      state,
      action: PayloadAction<PredictedTag[]>,
    ) {
      state.data.loadingState = loadingStateBuilder.success();
      adapter.setAll(state.data, action.payload);
    },
    updateImageTagsPredictionModel(
      _state,
      _action: PayloadAction<{
        status: ModelStatus;
        progress: number;
        id: number;
        modelUseIE?: boolean;
      }>,
    ) {},
    updateImageTagsPredictionModelSuccess(
      state,
      action: PayloadAction<{
        id: number | null;
        status: ModelStatus;
        progress: number | null;
      }>,
    ) {
      const { id, status, progress } = action.payload;

      state.model = {
        ...state.model,
        id: modelStatusChecks.isLoaded(status) ? id : state.model.id,
        status: statusCheck(status, state.model.status),
        progress: progress || 0,
      };
    },
    setImageTagsPredictionThreshold(state, action: PayloadAction<number>) {
      state.threshold = action.payload;
    },
    resetData(state) {
      state.data = initialState.data;
    },
    resetModel(state) {
      state.model = initialState.model;
    },
    updateImageTagsPredictionModelShowDot(
      state,
      action: PayloadAction<boolean>,
    ) {
      const showDot = action.payload;

      state.model = {
        ...state.model,
        showDot,
      };
    },
  },
});

export const {
  loadImageTagsPredictionModel,
  updateImageTagsPredictionModelSuccess,
  loadImageTagsPredictionModelError,
  loadImageTagsPredictionModelStart,
  loadImageTagsPredictionModelSuccess,
  loadImageTagsPrediction,
  loadImageTagsPredictionError,
  loadImageTagsPredictionStart,
  loadImageTagsPredictionSuccess,
  updateImageTagsPredictionModel,
  setImageTagsPredictionThreshold,
  resetData,
  resetModel,
  updateImageTagsPredictionModelShowDot,
} = actions;
export { imageTagsPredictionReducer };
