import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  ErrorAttributeLabel,
  ErrorType,
} from '../../../../../api/domainModels/consensusScoring';
import { AttributeType } from '../../../../../api/constants/attribute';
import { ErrorFinderAction } from '../../../../../api/constants/consensusScoring';

type AttributeReviewState = {
  data: {
    [key: string]: ErrorAttributeLabel;
  };
};

const initialState: AttributeReviewState = {
  data: {},
};

const { actions, reducer: attributeReviewReducer } = createSlice({
  name: 'attributeReview',
  initialState,
  reducers: {
    loadErrorFinderAttributeResultsSuccess(
      state,
      action: PayloadAction<ErrorAttributeLabel[]>,
    ) {
      state.data = action.payload.reduce(
        (acc, item) => ({ ...acc, [item.id]: item }),
        {},
      );
    },
    removeExistingAttributeFromLabel(
      _state,
      _action: PayloadAction<{
        imageId: string;
        attributeId: string;
        labelId: string;
        attributeToRemoveId: string;
      }>,
    ) {},
    removeExistingAttributeFromLabelSuccess(
      state,
      action: PayloadAction<{
        imageId: string;
        attributeId: string;
        labelId: string;
        attributeToRemoveId: string;
      }>,
    ) {
      const { imageId, labelId, attributeId, attributeToRemoveId } =
        action.payload;

      const dataId = `${imageId}-${labelId}`;

      delete state.data[dataId].items[attributeId].existingAttributes[
        attributeToRemoveId
      ];
    },
    addNewAttributeToLabel(
      _state,
      _action: PayloadAction<{
        imageId: string;
        attributeId: string;
        labelId: string;
        attributeToAddId: string;
        labeledBy: string;
      }>,
    ) {},
    addNewAttributeToLabelSuccess(
      state,
      action: PayloadAction<{
        imageId: string;
        attributeId: string;
        attributeName: string;
        attributeType: AttributeType;
        labelId: string;
        id: string;
        valueId: string;
        value: string;
        attributeToAddId: string;
        labeledBy: string;
      }>,
    ) {
      const {
        imageId,
        labelId,
        attributeId,
        attributeName,
        attributeType,
        attributeToAddId,
        ...attribute
      } = action.payload;

      const dataId = `${imageId}-${labelId}`;

      state.data[dataId].items = {
        ...state.data[dataId].items,
        [attributeId]: {
          attributeId,
          attributeName,
          attributeType,
          errorAttributes:
            state.data[dataId].items[attributeId]?.errorAttributes || {},
          existingAttributes: {
            ...(state.data[dataId].items[attributeId]?.existingAttributes ||
              {}),
            [attribute.valueId]: attribute,
          },
        },
      };
    },
    changeAttributeReviewErrorAction(
      _state,
      _action: PayloadAction<{
        imageId: string;
        runId: string | null;
        labelId: string;
        reviewedBy: string;
        efAction: ErrorFinderAction;
        errorType: ErrorType;
        attributeId: string;
        efAttributeId: string;
        curValueId?: string | null;
        predValueId?: string | null;
      }>,
    ) {},
    changeAttributeReviewErrorActionSuccess(
      state,
      action: PayloadAction<{
        runId: string;
        imageId: string;
        labelId: string;
        reviewedBy: string;
        efAction: ErrorFinderAction;
        errorType: ErrorType;
        attributeId: string;
        efAttributeId: string;
        curValueId?: string | null;
        predValueId?: string | null;
      }>,
    ) {
      const {
        imageId,
        labelId,
        efAction,
        attributeId,
        efAttributeId,
        reviewedBy,
      } = action.payload;

      const dataId = `${imageId}-${labelId}`;

      state.data[dataId].items[attributeId].errorAttributes[
        efAttributeId
      ].efAction = efAction;
      state.data[dataId].items[attributeId].errorAttributes[
        efAttributeId
      ].reviewedBy = reviewedBy;
    },
    changeAllAttributeReviewErrorActions(
      _state,
      _action: PayloadAction<{
        imageId: string;
        runId: string | null;
        labelId: string;
        reviewedBy: string;
        efAction: ErrorFinderAction;
      }>,
    ) {},
    changeAllAttributeReviewErrorActionsSuccess(
      state,
      action: PayloadAction<{
        runId: string;
        imageId: string;
        labelId: string;
        reviewedBy: string;
        efAction: ErrorFinderAction;
      }>,
    ) {
      const {
        imageId,
        labelId,
        efAction,

        reviewedBy,
      } = action.payload;

      const dataId = `${imageId}-${labelId}`;

      const attributeImageItemsData = state.data[dataId].items;

      const attributeImageItemsNewData = Object.keys(
        attributeImageItemsData,
      ).reduce((acc, attributeItemKey) => {
        const { errorAttributes, ...rest } =
          attributeImageItemsData[attributeItemKey];

        const newErrorAttributes = Object.keys(errorAttributes).reduce(
          (acc, errorAttributeKey) => {
            const errorAttribute = errorAttributes[errorAttributeKey];

            const errorAttributeEfAction = errorAttribute.efAction
              ? errorAttribute.efAction
              : efAction;

            return {
              ...acc,
              [errorAttributeKey]: {
                ...errorAttribute,
                efAction: errorAttributeEfAction,
                reviewedBy,
              },
            };
          },
          {},
        );

        return {
          ...acc,
          [attributeItemKey]: {
            ...rest,
            errorAttributes: newErrorAttributes,
          },
        };
      }, {});

      state.data[dataId].items = attributeImageItemsNewData;
    },
    updateErrorAttribute(
      _state,
      _action: PayloadAction<{
        runId: string | null;
        imageId: string;
        attributeId: string;
        labelId: string;
        efAttributeId: string;
        curValueId: string | null;
        attributeToAddId: string;
        currentUserId: string;
      }>,
    ) {},
  },
});

export { attributeReviewReducer };
export const {
  loadErrorFinderAttributeResultsSuccess,
  removeExistingAttributeFromLabel,
  removeExistingAttributeFromLabelSuccess,
  addNewAttributeToLabel,
  addNewAttributeToLabelSuccess,
  changeAttributeReviewErrorAction,
  changeAttributeReviewErrorActionSuccess,
  changeAllAttributeReviewErrorActions,
  changeAllAttributeReviewErrorActionsSuccess,
  updateErrorAttribute,
} = actions;
