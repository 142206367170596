import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import {
  WidgetDetectorInferencesItem,
  DetectorInferencesParams,
} from '../../../../../../../api/domainModels/widgets';
import { ApiPaginationMeta } from '../../../../../../../api/helpers';
import { loadingStateBuilder } from '../../../../../../utils/loadingState';
import { setEditedProjectId } from '../../../../../sections/editedProject/project/project.slice';

type WidgetDetectorInference = WidgetDetectorInferencesItem & { id: string };

export const itemsAdapter = createEntityAdapter<WidgetDetectorInference>();

const initialState = {
  loadingState: loadingStateBuilder.initial(),
  items: itemsAdapter.getInitialState(),
  meta: null as ApiPaginationMeta | null,
  offset: 0,
  limit: 2,
  opacity: 0.5,
};

const { actions, reducer: detectorInferencesReducer } = createSlice({
  name: 'widgets/detectorInferences',
  initialState,
  reducers: {
    loadDetectorInferences(
      state,
      action: PayloadAction<Partial<DetectorInferencesParams>>,
    ) {
      state.loadingState = loadingStateBuilder.inProgress();
      if (action.payload.offset) {
        state.offset = action.payload.offset;
      }
      if (action.payload.limit) {
        state.limit = action.payload.limit;
      }
    },
    loadDetectorInferencesFailure(state, action: PayloadAction<string>) {
      itemsAdapter.removeAll(state.items);
      state.loadingState = loadingStateBuilder.failure(action.payload);
    },
    loadDetectorInferencesSuccess(
      state,
      action: PayloadAction<{
        items: WidgetDetectorInference[];
        meta: ApiPaginationMeta;
      }>,
    ) {
      itemsAdapter.removeAll(state.items);
      state.loadingState = loadingStateBuilder.success();
      const { items, meta } = action.payload;
      itemsAdapter.setAll(state.items, items);
      state.meta = meta;
    },
    setDetectorInferenceOpacity(
      state,
      action: PayloadAction<{ opacity: number }>,
    ) {
      state.opacity = action.payload.opacity;
    },
    resetWidget() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setEditedProjectId, () => initialState);
  },
});

export { detectorInferencesReducer };
export const {
  loadDetectorInferences,
  loadDetectorInferencesSuccess,
  loadDetectorInferencesFailure,
  setDetectorInferenceOpacity,
  resetWidget,
} = actions;
