import { call, put, select, takeEvery } from 'typed-redux-saga';

import {
  addKeypointsSchema,
  addKeypointsSchemaFailure,
  addKeypointsSchemaSuccess,
  loadKeypointsSchemasFailure,
  loadKeypointsSchemasSuccess,
  loadKeypointsSchemasStart,
  removeKeypointsSchema,
  removeKeypointsSchemaFailure,
  removeKeypointsSchemaSuccess,
  updateKeypointsSchemaOrder,
  updateKeypointSchemaSuccess,
  updateKeypointSchemaFailure,
  updateKeypointSchema,
} from './keypointsSchemas.slice';
import { activeProjectIdSelector } from '../../project.selectors';
import { initImageProject } from '../../../sections/editedProject/project/project.slice';
import { setProjectId } from '../../../core/imageView/project/project.slice';
import {
  apiAddKeypointsSchema,
  apiDeleteKeypointSchema,
  apiLoadKeypointsSchemas,
  apiUpdateKeypointSchema,
} from '../../../../../api/requests/keypoints';
import { getErrorMessage } from '../../../../../api/utils';
import { hideModals, showModal } from '../../../ui/modals/modals.slice';
import {
  keypointsSchemaByIdSelector,
  keypointsSchemasSelector,
} from './keypointsSchemas.selectors';
import { keypointsSchemasDataMapper } from '../../../../../api/domainModels/keypoints';
import { getNewOrder } from '../../../../../util/norder';
import { processKCRelations } from '../KCRelations/KCRelations.saga';
import { loadKCRelations } from '../KCRelations/KCRelations.slice';
import { enqueueNotification } from '../../../ui/stackNotifications/stackNotifications.slice';
import { updateAssistantKeypointSchemaId } from '../assistantKeypointSchemaId/assistantKeypointSchemaId.slice';
import { assistantKeypointSchemaIdSelector } from '../assistantKeypointSchemaId/assistantKeypointSchemaId.selectors';
import { ModelService } from '../../../../../api/codegen';
import { invalidateQueries } from '../../../../../api/api';

function* loadKeypointsHandler() {
  const projectId = yield* select(activeProjectIdSelector);

  yield* put(loadKeypointsSchemasStart());

  try {
    const response = yield* call(apiLoadKeypointsSchemas, { projectId });

    yield* put(loadKeypointsSchemasSuccess(response.data));
  } catch (error) {
    yield* put(
      enqueueNotification({
        message: getErrorMessage(error, 'Not able to load keypoint schemas'),
        options: { variant: 'error', error, allowOutsideOfEditor: true },
      }),
    );
    yield* put(loadKeypointsSchemasFailure());
  }
}

function* addKeypointsSchemaHandler(
  action: ActionType<typeof addKeypointsSchema>,
) {
  const projectId = yield* select(activeProjectIdSelector);

  try {
    const result = yield* call(
      apiAddKeypointsSchema,
      { projectId },
      keypointsSchemasDataMapper.toBackend(action.payload),
    );

    try {
      yield* processKCRelations(
        result.data.id,
        action.payload.labelClasses.map((labelClassId, index) => ({
          labelClassId,
          keypointSchemaId: result.data.id,
          labelClassOrder: index,
        })),
      );
      if (action.payload.isDefault) {
        yield* put(updateAssistantKeypointSchemaId(result.data.id));
      }

      if (action.payload.templateId) {
        yield* put(
          showModal({
            modalName: 'upsertKeypointsSchema',
            modalProps: {
              schemaId: result.data.id,
            },
          }),
        );
      } else {
        yield* put(hideModals());
      }
    } catch (error) {
      // do nothing, error processed in that saga. We only catch to not hide the modal
    } finally {
      invalidateQueries(ModelService.getV1ProjectsModelFamilies);
      yield* put(addKeypointsSchemaSuccess(result.data));
    }
  } catch (error) {
    yield* put(
      enqueueNotification({
        message: getErrorMessage(error, 'Not able to add keypoints schema'),
        options: { variant: 'error', error, allowOutsideOfEditor: true },
      }),
    );
    yield* put(addKeypointsSchemaFailure());
  }
}

function* removeKeypointsSchemaHandler(
  action: ActionType<typeof removeKeypointsSchema>,
) {
  const projectId = yield* select(activeProjectIdSelector);
  const { schemaId } = action.payload;

  try {
    yield* call(apiDeleteKeypointSchema, {
      projectId,
      schemaId,
    });
    yield* put(removeKeypointsSchemaSuccess({ schemaId }));
    yield* put(loadKCRelations({ id: projectId }));
    yield* put(hideModals());
    invalidateQueries(ModelService.getV1ProjectsModelFamilies);
  } catch (error) {
    yield* put(
      enqueueNotification({
        message: getErrorMessage(
          error,
          'Not able to remove the keypoint schema',
        ),
        options: { variant: 'error', error, allowOutsideOfEditor: true },
      }),
    );
    yield* put(removeKeypointsSchemaFailure());
  }
}

function* updateKeypointsSchemaOrderHandler(
  action: ActionType<typeof updateKeypointsSchemaOrder>,
) {
  const projectId = yield* select(activeProjectIdSelector);
  const { schemaId, index } = action.payload;
  const schema = yield* select((state: RootState) =>
    keypointsSchemaByIdSelector(state, schemaId),
  );
  if (!schema) {
    // eslint-disable-next-line no-console
    console.log('failed schema reorder, label not found for id ', schemaId);

    return;
  }
  const schemas = yield* select(keypointsSchemasSelector);

  const newOrder = getNewOrder(schemas, index);

  if (newOrder !== null) {
    try {
      yield* call(
        apiUpdateKeypointSchema,
        {
          projectId,
          schemaId,
        },
        keypointsSchemasDataMapper.toBackend({
          ...schema,
          norder: newOrder,
        }),
      );
      yield* put(
        updateKeypointSchemaSuccess({
          changes: { norder: newOrder },
          id: schemaId,
        }),
      );
    } catch (error) {
      yield* put(
        enqueueNotification({
          message: getErrorMessage(
            error,
            'Not able to update keypoints schema order',
          ),
          options: { variant: 'error', error, allowOutsideOfEditor: true },
        }),
      );
      yield* put(updateKeypointSchemaFailure());
    }
  }
}

function* updateKeypointSchemaHandler(
  action: ActionType<typeof updateKeypointSchema>,
) {
  const projectId = yield* select(activeProjectIdSelector);
  const { schemaId, ...values } = action.payload;

  try {
    const { data } = yield* call(
      apiUpdateKeypointSchema,
      {
        projectId,
        schemaId,
      },
      keypointsSchemasDataMapper.toBackend(values),
    );
    try {
      yield* call(
        processKCRelations,
        schemaId,
        action.payload.labelClasses.map((labelClassId, index) => ({
          labelClassId,
          keypointSchemaId: schemaId,
          labelClassOrder: index,
        })),
      );
      const currentAssistantId = yield* select(
        assistantKeypointSchemaIdSelector,
      );
      if (values.isDefault && currentAssistantId !== schemaId) {
        yield* put(updateAssistantKeypointSchemaId(schemaId));
      }

      yield* put(hideModals());
    } catch (error) {
      // do nothing, error processed in that saga. We only catch to not hide the modal
    } finally {
      yield* put(
        updateKeypointSchemaSuccess({
          changes: data,
          id: schemaId,
        }),
      );
    }
  } catch (error) {
    yield* put(
      enqueueNotification({
        message: getErrorMessage(
          error,
          'Not able to update the keypoint schema',
        ),
        options: { variant: 'error', error, allowOutsideOfEditor: true },
      }),
    );
    yield* put(updateKeypointSchemaFailure());
  }
}

export function* keypointsSchemasSaga() {
  yield* takeEvery([initImageProject, setProjectId], loadKeypointsHandler);
  yield* takeEvery(addKeypointsSchema, addKeypointsSchemaHandler);
  yield* takeEvery(removeKeypointsSchema, removeKeypointsSchemaHandler);
  yield* takeEvery(updateKeypointSchema, updateKeypointSchemaHandler);
  yield* takeEvery(
    updateKeypointsSchemaOrder,
    updateKeypointsSchemaOrderHandler,
  );
}
