import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { WebhookEvent } from '../../../../../api/domainModels/webhook';
import { loadingStateBuilder } from '../../../../utils/loadingState';
import { setProjectId } from '../../../core/imageView/project/project.slice';

export const adapter = createEntityAdapter<WebhookEvent>({
  sortComparer: (a, b) => (a.id < b.id ? 1 : -1),
});
const initialState = adapter.getInitialState({
  listLoadingState: loadingStateBuilder.initial(),
  itemLoadingState: loadingStateBuilder.initial(),
});

const { actions, reducer: webhookEventsReducer } = createSlice({
  name: 'webhookEvents',
  initialState,
  reducers: {
    loadWebhookEventsStart(state) {
      state.listLoadingState = loadingStateBuilder.inProgress();
    },
    loadWebhookEventsSuccess(state, action: PayloadAction<WebhookEvent[]>) {
      state.listLoadingState = loadingStateBuilder.success();
      adapter.setAll(state, action.payload);
    },
    loadWebhookEventsFailure(state, action: PayloadAction<string>) {
      state.listLoadingState = loadingStateBuilder.failure(action.payload);
      adapter.setAll(state, []);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setProjectId, () => initialState);
  },
});

export { webhookEventsReducer };
export const {
  loadWebhookEventsStart,
  loadWebhookEventsSuccess,
  loadWebhookEventsFailure,
} = actions;
