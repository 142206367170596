import {
  createSlice,
  PayloadAction,
  createEntityAdapter,
  EntityState,
} from '@reduxjs/toolkit';

import {
  ExperimentParameter,
  ExperimentComponent,
} from '../../../../../../api/domainModels/modelPlayground';
import {
  loadingStateBuilder,
  LoadingState,
} from '../../../../../utils/loadingState';
import { setActiveExperimentId } from '../activeExperimentId/activeExperimentId.slice';
import { setEditedProjectId } from '../../../../sections/editedProject/project/project.slice';

type MetricParameter = {
  id: string; // experiment component id
  data: ExperimentParameter[];
  loadingState: LoadingState;
};

export const componentsAdapter = createEntityAdapter<ExperimentComponent>();
export const parametersAdapter = createEntityAdapter<MetricParameter>();

type MetricsState = {
  components: EntityState<ExperimentComponent> & {
    loadingState: LoadingState;
    updateLoadingState: LoadingState;
  };
  parameters: EntityState<MetricParameter> & {
    data: ExperimentParameter[];
    loadingState: LoadingState;
  };
  parametersUpdateLoadingState: LoadingState;
};

const initialState: MetricsState = {
  components: componentsAdapter.getInitialState({
    loadingState: loadingStateBuilder.initial(),
    updateLoadingState: loadingStateBuilder.initial(),
  }),
  parameters: parametersAdapter.getInitialState({
    data: [],
    loadingState: loadingStateBuilder.initial(),
  }),
  parametersUpdateLoadingState: loadingStateBuilder.initial(),
};

const { actions, reducer: metricsReducer } = createSlice({
  name: 'metrics',
  initialState,
  reducers: {
    loadMetrics(state) {
      state.components.loadingState =
        loadingStateBuilder.inProgress('Loading metrics');
      componentsAdapter.removeAll(state.components);
      parametersAdapter.removeAll(state.parameters);
    },
    loadMetricsFailure(state, action: PayloadAction<string>) {
      state.components.loadingState = loadingStateBuilder.failure(
        action.payload,
      );
    },
    loadMetricsSuccess(state, action: PayloadAction<ExperimentComponent[]>) {
      state.components.loadingState = loadingStateBuilder.success();
      componentsAdapter.setAll(state.components, action.payload);
    },
    updateExperimentMetric(state, _action: PayloadAction<string[]>) {
      state.components.updateLoadingState =
        loadingStateBuilder.inProgress('Updating metric');
    },
    updateExperimentMetricFailure(state, action: PayloadAction<string>) {
      state.components.updateLoadingState = loadingStateBuilder.failure(
        action.payload,
      );
    },
    updateExperimentMetricSuccess(
      state,
      action: PayloadAction<ExperimentComponent[]>,
    ) {
      const componentsIds = state.components.ids;
      const componentsEntities = state.components.entities;
      componentsAdapter.updateMany(
        state.components,
        componentsIds.map((id) => {
          const componentUpdated = action.payload.find(
            (component) =>
              component.componentId === componentsEntities[id]?.componentId,
          );

          const changes = componentUpdated || {
            selected: false,
            experimentComponentId: null,
            norder: null,
          };

          return {
            id,
            changes,
          };
        }),
      );
      state.components.updateLoadingState = loadingStateBuilder.success();
    },
    loadMetricParameters(state, action: PayloadAction<string>) {
      const id = action.payload;
      const update = {
        id,
        data: [],
        loadingState: loadingStateBuilder.inProgress('Loading parameters'),
      };
      parametersAdapter.upsertOne(state.parameters, update);
    },
    loadMetricParametersFailure(
      state,
      action: PayloadAction<{ id: string; message: string }>,
    ) {
      const { id, message } = action.payload;
      const changes = {
        loadingState: loadingStateBuilder.failure(message),
      };
      const update = { id, changes };
      parametersAdapter.updateOne(state.parameters, update);
    },
    loadMetricParametersSuccess(
      state,
      action: PayloadAction<{ id: string; data: ExperimentParameter[] }>,
    ) {
      state.parameters.loadingState = loadingStateBuilder.success();
      const { id, data } = action.payload;
      const changes = {
        data,
        loadingState: loadingStateBuilder.success(),
      };
      const update = { id, changes };
      parametersAdapter.updateOne(state.parameters, update);
    },
    updateMetricParameter(
      state,
      _action: PayloadAction<{
        id: string; // component id
        metricParameterId: string;
        value: any;
      }>,
    ) {
      state.parametersUpdateLoadingState =
        loadingStateBuilder.inProgress('Loading parameters');
    },
    updateMetricParameterFailure(state, action: PayloadAction<string>) {
      state.parametersUpdateLoadingState = loadingStateBuilder.failure(
        action.payload,
      );
    },
    updateMetricParameterSuccess(
      state,
      action: PayloadAction<{ id: string; parameter: ExperimentParameter }>,
    ) {
      state.parametersUpdateLoadingState = loadingStateBuilder.success();
      const { id, parameter } = action.payload;
      const data = state.parameters.entities[id]?.data.map((param) =>
        param.id === parameter.id ? parameter : param,
      );
      const update = { id, changes: { data } };
      parametersAdapter.updateOne(state.parameters, update);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setActiveExperimentId, () => initialState);
    builder.addCase(setEditedProjectId, () => initialState);
  },
});

export { metricsReducer };
export const {
  loadMetrics,
  loadMetricsSuccess,
  loadMetricsFailure,
  updateExperimentMetric,
  updateExperimentMetricFailure,
  updateExperimentMetricSuccess,
  loadMetricParameters,
  loadMetricParametersSuccess,
  loadMetricParametersFailure,
  updateMetricParameter,
  updateMetricParameterSuccess,
  updateMetricParameterFailure,
} = actions;
