import { attributesSaga } from './attributes/attributes.saga';
import { keypointsSchemasSaga } from './keypointsSchemas/keypointsSchemas.saga';
import { keypointsTemplatesSaga } from './keypointsTemplates/keypointsTemplates.saga';
import { labelClassesSaga } from './labelClasses/labelClasses.saga';
import { ACRelationsSaga } from './ACRelations/ACRelations.saga';
import { KCRelationsSaga } from './KCRelations/KCRelations.saga';
import { tagClassesSaga } from './tagClasses/tagClasses.saga';
import { tagGroupsSaga } from './tagGroups/tagGroups.saga';
import { assistantKeypointSchemaIdSaga } from './assistantKeypointSchemaId/assistantKeypointSchemaId.saga';

export const annotationTaxonomySagas = [
  attributesSaga,
  keypointsSchemasSaga,
  keypointsTemplatesSaga,
  assistantKeypointSchemaIdSaga,
  labelClassesSaga,
  ACRelationsSaga,
  KCRelationsSaga,
  tagClassesSaga,
  tagGroupsSaga,
];
