import { call, put, debounce, select, takeEvery, take } from 'typed-redux-saga';

import {
  updateMultiLabelAttributeValue,
  updateMultiLabelAttributeValueFailure,
  loadMultiLabelAttributeValues,
  loadMultiLabelAttributeValuesSuccess,
  loadMultiLabelAttributeValuesFailure,
  updateMultiLabelAttributeValueSuccess,
} from './multiLabelAttributeValues.slice';
import { activeProjectIdSelector } from '../../../project/project.selectors';
import { imageViewImageIdSelector } from '../currentImage/currentImage.selectors';
import { selectedLabelsIdsSelector } from '../labels/selectedLabels/selectedLabels.selectors';
import { labelSyncSavingSelector } from '../labels/labelSync/labelSync.selectors';
import { syncLabelsSuccess } from '../labels/labelSync/labelSync.slice';
import {
  apiLoadProjectImageMultiLabelAttributes,
  apiUpdateProjectImageBulkLabelAttributes,
} from '../../../../../api/requests/attribute';
import { getErrorMessage } from '../../../../../api/utils';
import {
  setSelectedLabelsIds,
  toggleLabelSelection,
} from '../labels/selectedLabels/selectedLabels.slice';

function* updateManyHandler(
  action: ActionType<typeof updateMultiLabelAttributeValue>,
) {
  const { data, selectedLabelIds } = action.payload;

  try {
    const projectId = yield* select(activeProjectIdSelector);
    const imageId = yield* select(imageViewImageIdSelector);

    if (!imageId) {
      return;
    }

    const { data: responseData } = yield* call(
      apiUpdateProjectImageBulkLabelAttributes,
      {
        projectId,
        imageId,
      },
      selectedLabelIds.map((labelId) => ({
        id: data.id,
        labelId,
        value: data.value,
      })),
    );

    yield* put(updateMultiLabelAttributeValueSuccess(responseData));
  } catch (error) {
    yield* put(
      updateMultiLabelAttributeValueFailure(
        getErrorMessage(error, 'Could not update image label attribute'),
      ),
    );
  }
}

function* setSelectedLabelsIdsHandler() {
  const selectedLabelIds = yield* select(selectedLabelsIdsSelector);

  if (selectedLabelIds.length > 1) {
    yield* put(loadMultiLabelAttributeValues());
  }
}

function* loadMultiLabelAttributeValuesHandler() {
  const selectedLabelIds = yield* select(selectedLabelsIdsSelector);
  const projectId: string = yield* select(activeProjectIdSelector);
  const imageId = (yield* select(imageViewImageIdSelector)) as string;

  const isLabelSyncSaving = yield* select(labelSyncSavingSelector);

  // if labelSync is in progress -> wait for success
  if (isLabelSyncSaving) {
    yield* take(syncLabelsSuccess);
  }

  try {
    const { data } = yield* call(
      apiLoadProjectImageMultiLabelAttributes,
      { projectId, imageId },
      selectedLabelIds,
    );

    yield* put(loadMultiLabelAttributeValuesSuccess(data));
  } catch (error) {
    yield* put(
      loadMultiLabelAttributeValuesFailure(
        getErrorMessage(error, 'Could not load commonFeatures attribute value'),
      ),
    );
  }
}

export function* multiLabelAttributesValuesSaga() {
  yield* takeEvery(updateMultiLabelAttributeValue, updateManyHandler);
  yield* debounce(
    1000,
    [toggleLabelSelection, setSelectedLabelsIds],
    setSelectedLabelsIdsHandler,
  );
  yield* takeEvery(
    loadMultiLabelAttributeValues,
    loadMultiLabelAttributeValuesHandler,
  );
}
