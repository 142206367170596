import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  Experiment,
  ExperimentConfusionMatrixClass,
  ExperimentConfusionMatrixIteration,
} from '../../../../../../../api/domainModels/modelPlayground';
import {
  LoadingState,
  loadingStateBuilder,
} from '../../../../../../utils/loadingState';
import { ConfusionMatrixSeries } from '../../../../../../../@types/widgets/confusionMatrix';
import { setEditedProjectId } from '../../../../../sections/editedProject/project/project.slice';

type InitialState = {
  loadingState: LoadingState;
  iterations: Record<
    Experiment['id'],
    {
      classes: ExperimentConfusionMatrixClass[];
      data: ConfusionMatrixSeries[];
    }
  >;
};
const initialState: InitialState = {
  loadingState: loadingStateBuilder.initial(),
  iterations: {},
};

const { actions, reducer: confusionMatrixReducer } = createSlice({
  name: 'widgets/confusionMatrixIterations',
  initialState,
  reducers: {
    loadExperimentConfusionMatrixIterations() {},
    loadExperimentConfusionMatrixIterationsStart(state) {
      state.loadingState = loadingStateBuilder.inProgress();
    },
    loadExperimentConfusionMatrixIterationsFailure(
      state,
      action: PayloadAction<string>,
    ) {
      state.loadingState = loadingStateBuilder.failure(action.payload);
    },
    loadExperimentConfusionMatrixIterationsSuccess: {
      prepare({
        iteration,
        experimentId,
      }: {
        iteration: ExperimentConfusionMatrixIteration;
        experimentId: Experiment['id'];
      }) {
        const { classes } = iteration;

        const data = classes?.map((cl, index) => ({
          classId: cl.id,
          name: cl.name,
          data: classes.map((cl, i) => ({
            classId: cl.id,
            x: cl.name,
            y: iteration.values[index][i],
          })),
        }));

        return {
          payload: {
            experimentId,
            classes,
            data,
          },
        };
      },
      reducer(
        state,
        action: PayloadAction<{
          experimentId: Experiment['id'];
          classes: ExperimentConfusionMatrixClass[];
          data: any;
        }>,
      ) {
        state.loadingState = loadingStateBuilder.success();
        state.iterations[action.payload.experimentId] = {
          data: action.payload.data,
          classes: action.payload.classes,
        };
      },
    },
    resetWidget() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setEditedProjectId, () => initialState);
  },
});

export { confusionMatrixReducer };
export const {
  loadExperimentConfusionMatrixIterations,
  loadExperimentConfusionMatrixIterationsStart,
  loadExperimentConfusionMatrixIterationsFailure,
  loadExperimentConfusionMatrixIterationsSuccess,
  resetWidget,
} = actions;
