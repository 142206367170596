// source: websockets/downstream.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var app_notifications_rpc_v1_service_pb = require('../app/notifications/rpc/v1/service_pb.js');
goog.object.extend(proto, app_notifications_rpc_v1_service_pb);
var common_tracing_pb = require('../common/tracing_pb.js');
goog.object.extend(proto, common_tracing_pb);
goog.exportSymbol('proto.websockets.Aldi', null, global);
goog.exportSymbol('proto.websockets.Attribute', null, global);
goog.exportSymbol('proto.websockets.AttributeClass', null, global);
goog.exportSymbol('proto.websockets.AttributeLOV', null, global);
goog.exportSymbol('proto.websockets.Dataset', null, global);
goog.exportSymbol('proto.websockets.DisplayInfo', null, global);
goog.exportSymbol('proto.websockets.EFRun', null, global);
goog.exportSymbol('proto.websockets.Experiment', null, global);
goog.exportSymbol('proto.websockets.Export', null, global);
goog.exportSymbol('proto.websockets.FrameImportSession', null, global);
goog.exportSymbol('proto.websockets.Heuristic', null, global);
goog.exportSymbol('proto.websockets.Image', null, global);
goog.exportSymbol('proto.websockets.Import', null, global);
goog.exportSymbol('proto.websockets.Job', null, global);
goog.exportSymbol('proto.websockets.JobFamily', null, global);
goog.exportSymbol('proto.websockets.Label', null, global);
goog.exportSymbol('proto.websockets.LabelClass', null, global);
goog.exportSymbol('proto.websockets.Lock', null, global);
goog.exportSymbol('proto.websockets.LockStatus', null, global);
goog.exportSymbol('proto.websockets.Model', null, global);
goog.exportSymbol('proto.websockets.ModelFamily', null, global);
goog.exportSymbol('proto.websockets.Notification', null, global);
goog.exportSymbol('proto.websockets.NotificationEvent', null, global);
goog.exportSymbol('proto.websockets.PredictedSegment', null, global);
goog.exportSymbol('proto.websockets.Project', null, global);
goog.exportSymbol('proto.websockets.ProjectLabelingType', null, global);
goog.exportSymbol('proto.websockets.Split', null, global);
goog.exportSymbol('proto.websockets.SplitCounts', null, global);
goog.exportSymbol('proto.websockets.TagClass', null, global);
goog.exportSymbol('proto.websockets.TrainStatus', null, global);
goog.exportSymbol('proto.websockets.Training', null, global);
goog.exportSymbol('proto.websockets.TrainingMetric', null, global);
goog.exportSymbol('proto.websockets.TrainingMetric.ValueCase', null, global);
goog.exportSymbol('proto.websockets.UserJob', null, global);
goog.exportSymbol('proto.websockets.Video', null, global);
goog.exportSymbol('proto.websockets.VideoCutSession', null, global);
goog.exportSymbol('proto.websockets.VideoSegment', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.websockets.Notification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, proto.websockets.Notification.repeatedFields_, null);
};
goog.inherits(proto.websockets.Notification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.websockets.Notification.displayName = 'proto.websockets.Notification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.websockets.VideoCutSession = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.websockets.VideoCutSession, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.websockets.VideoCutSession.displayName = 'proto.websockets.VideoCutSession';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.websockets.FrameImportSession = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.websockets.FrameImportSession, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.websockets.FrameImportSession.displayName = 'proto.websockets.FrameImportSession';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.websockets.TrainingMetric = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.websockets.TrainingMetric.oneofGroups_);
};
goog.inherits(proto.websockets.TrainingMetric, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.websockets.TrainingMetric.displayName = 'proto.websockets.TrainingMetric';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.websockets.Experiment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.websockets.Experiment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.websockets.Experiment.displayName = 'proto.websockets.Experiment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.websockets.Training = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.websockets.Training, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.websockets.Training.displayName = 'proto.websockets.Training';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.websockets.Split = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.websockets.Split, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.websockets.Split.displayName = 'proto.websockets.Split';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.websockets.SplitCounts = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.websockets.SplitCounts, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.websockets.SplitCounts.displayName = 'proto.websockets.SplitCounts';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.websockets.DisplayInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.websockets.DisplayInfo.repeatedFields_, null);
};
goog.inherits(proto.websockets.DisplayInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.websockets.DisplayInfo.displayName = 'proto.websockets.DisplayInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.websockets.TagClass = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.websockets.TagClass, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.websockets.TagClass.displayName = 'proto.websockets.TagClass';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.websockets.Image = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.websockets.Image, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.websockets.Image.displayName = 'proto.websockets.Image';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.websockets.Video = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.websockets.Video.repeatedFields_, null);
};
goog.inherits(proto.websockets.Video, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.websockets.Video.displayName = 'proto.websockets.Video';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.websockets.VideoSegment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.websockets.VideoSegment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.websockets.VideoSegment.displayName = 'proto.websockets.VideoSegment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.websockets.PredictedSegment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.websockets.PredictedSegment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.websockets.PredictedSegment.displayName = 'proto.websockets.PredictedSegment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.websockets.Lock = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.websockets.Lock, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.websockets.Lock.displayName = 'proto.websockets.Lock';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.websockets.Label = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.websockets.Label, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.websockets.Label.displayName = 'proto.websockets.Label';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.websockets.Dataset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.websockets.Dataset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.websockets.Dataset.displayName = 'proto.websockets.Dataset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.websockets.LabelClass = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.websockets.LabelClass, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.websockets.LabelClass.displayName = 'proto.websockets.LabelClass';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.websockets.Model = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.websockets.Model, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.websockets.Model.displayName = 'proto.websockets.Model';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.websockets.Job = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.websockets.Job, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.websockets.Job.displayName = 'proto.websockets.Job';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.websockets.Project = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.websockets.Project, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.websockets.Project.displayName = 'proto.websockets.Project';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.websockets.Attribute = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.websockets.Attribute.repeatedFields_, null);
};
goog.inherits(proto.websockets.Attribute, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.websockets.Attribute.displayName = 'proto.websockets.Attribute';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.websockets.AttributeClass = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.websockets.AttributeClass, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.websockets.AttributeClass.displayName = 'proto.websockets.AttributeClass';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.websockets.AttributeLOV = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.websockets.AttributeLOV, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.websockets.AttributeLOV.displayName = 'proto.websockets.AttributeLOV';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.websockets.Export = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.websockets.Export, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.websockets.Export.displayName = 'proto.websockets.Export';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.websockets.EFRun = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.websockets.EFRun, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.websockets.EFRun.displayName = 'proto.websockets.EFRun';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.websockets.UserJob = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.websockets.UserJob, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.websockets.UserJob.displayName = 'proto.websockets.UserJob';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.websockets.Import = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.websockets.Import, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.websockets.Import.displayName = 'proto.websockets.Import';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.websockets.Aldi = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.websockets.Aldi, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.websockets.Aldi.displayName = 'proto.websockets.Aldi';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.websockets.Heuristic = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.websockets.Heuristic, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.websockets.Heuristic.displayName = 'proto.websockets.Heuristic';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.websockets.Notification.repeatedFields_ = [1,32,42];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.websockets.Notification.prototype.toObject = function(opt_includeInstance) {
  return proto.websockets.Notification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.websockets.Notification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.Notification.toObject = function(includeInstance, msg) {
  var f, obj = {
    subjectsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    user: jspb.Message.getFieldWithDefault(msg, 2, ""),
    event: jspb.Message.getFieldWithDefault(msg, 10, 0),
    image: (f = msg.getImage()) && proto.websockets.Image.toObject(includeInstance, f),
    label: (f = msg.getLabel()) && proto.websockets.Label.toObject(includeInstance, f),
    dataset: (f = msg.getDataset()) && proto.websockets.Dataset.toObject(includeInstance, f),
    labelClass: (f = msg.getLabelClass()) && proto.websockets.LabelClass.toObject(includeInstance, f),
    model: (f = msg.getModel()) && proto.websockets.Model.toObject(includeInstance, f),
    project: (f = msg.getProject()) && proto.websockets.Project.toObject(includeInstance, f),
    job: (f = msg.getJob()) && proto.websockets.Job.toObject(includeInstance, f),
    tagClass: (f = msg.getTagClass()) && proto.websockets.TagClass.toObject(includeInstance, f),
    attribute: (f = msg.getAttribute()) && proto.websockets.Attribute.toObject(includeInstance, f),
    attributeClass: (f = msg.getAttributeClass()) && proto.websockets.AttributeClass.toObject(includeInstance, f),
    attributeLov: (f = msg.getAttributeLov()) && proto.websockets.AttributeLOV.toObject(includeInstance, f),
    pb_export: (f = msg.getExport()) && proto.websockets.Export.toObject(includeInstance, f),
    locksList: jspb.Message.toObjectList(msg.getLocksList(),
    proto.websockets.Lock.toObject, includeInstance),
    notification: (f = msg.getNotification()) && app_notifications_rpc_v1_service_pb.Notification.toObject(includeInstance, f),
    efRun: (f = msg.getEfRun()) && proto.websockets.EFRun.toObject(includeInstance, f),
    userJob: (f = msg.getUserJob()) && proto.websockets.UserJob.toObject(includeInstance, f),
    video: (f = msg.getVideo()) && proto.websockets.Video.toObject(includeInstance, f),
    split: (f = msg.getSplit()) && proto.websockets.Split.toObject(includeInstance, f),
    training: (f = msg.getTraining()) && proto.websockets.Training.toObject(includeInstance, f),
    trainingMetric: (f = msg.getTrainingMetric()) && proto.websockets.TrainingMetric.toObject(includeInstance, f),
    importJob: (f = msg.getImportJob()) && proto.websockets.Import.toObject(includeInstance, f),
    labelClassIdsList: (f = jspb.Message.getRepeatedField(msg, 42)) == null ? undefined : f,
    aldi: (f = msg.getAldi()) && proto.websockets.Aldi.toObject(includeInstance, f),
    videoCutSession: (f = msg.getVideoCutSession()) && proto.websockets.VideoCutSession.toObject(includeInstance, f),
    frameImportSession: (f = msg.getFrameImportSession()) && proto.websockets.FrameImportSession.toObject(includeInstance, f),
    experiment: (f = msg.getExperiment()) && proto.websockets.Experiment.toObject(includeInstance, f),
    trace: (f = msg.getTrace()) && common_tracing_pb.Trace.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.websockets.Notification}
 */
proto.websockets.Notification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.websockets.Notification;
  return proto.websockets.Notification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.websockets.Notification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.websockets.Notification}
 */
proto.websockets.Notification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addSubjects(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUser(value);
      break;
    case 10:
      var value = /** @type {!proto.websockets.NotificationEvent} */ (reader.readEnum());
      msg.setEvent(value);
      break;
    case 20:
      var value = new proto.websockets.Image;
      reader.readMessage(value,proto.websockets.Image.deserializeBinaryFromReader);
      msg.setImage(value);
      break;
    case 21:
      var value = new proto.websockets.Label;
      reader.readMessage(value,proto.websockets.Label.deserializeBinaryFromReader);
      msg.setLabel(value);
      break;
    case 22:
      var value = new proto.websockets.Dataset;
      reader.readMessage(value,proto.websockets.Dataset.deserializeBinaryFromReader);
      msg.setDataset(value);
      break;
    case 23:
      var value = new proto.websockets.LabelClass;
      reader.readMessage(value,proto.websockets.LabelClass.deserializeBinaryFromReader);
      msg.setLabelClass(value);
      break;
    case 24:
      var value = new proto.websockets.Model;
      reader.readMessage(value,proto.websockets.Model.deserializeBinaryFromReader);
      msg.setModel(value);
      break;
    case 25:
      var value = new proto.websockets.Project;
      reader.readMessage(value,proto.websockets.Project.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 26:
      var value = new proto.websockets.Job;
      reader.readMessage(value,proto.websockets.Job.deserializeBinaryFromReader);
      msg.setJob(value);
      break;
    case 27:
      var value = new proto.websockets.TagClass;
      reader.readMessage(value,proto.websockets.TagClass.deserializeBinaryFromReader);
      msg.setTagClass(value);
      break;
    case 28:
      var value = new proto.websockets.Attribute;
      reader.readMessage(value,proto.websockets.Attribute.deserializeBinaryFromReader);
      msg.setAttribute(value);
      break;
    case 29:
      var value = new proto.websockets.AttributeClass;
      reader.readMessage(value,proto.websockets.AttributeClass.deserializeBinaryFromReader);
      msg.setAttributeClass(value);
      break;
    case 30:
      var value = new proto.websockets.AttributeLOV;
      reader.readMessage(value,proto.websockets.AttributeLOV.deserializeBinaryFromReader);
      msg.setAttributeLov(value);
      break;
    case 31:
      var value = new proto.websockets.Export;
      reader.readMessage(value,proto.websockets.Export.deserializeBinaryFromReader);
      msg.setExport(value);
      break;
    case 32:
      var value = new proto.websockets.Lock;
      reader.readMessage(value,proto.websockets.Lock.deserializeBinaryFromReader);
      msg.addLocks(value);
      break;
    case 33:
      var value = new app_notifications_rpc_v1_service_pb.Notification;
      reader.readMessage(value,app_notifications_rpc_v1_service_pb.Notification.deserializeBinaryFromReader);
      msg.setNotification(value);
      break;
    case 34:
      var value = new proto.websockets.EFRun;
      reader.readMessage(value,proto.websockets.EFRun.deserializeBinaryFromReader);
      msg.setEfRun(value);
      break;
    case 35:
      var value = new proto.websockets.UserJob;
      reader.readMessage(value,proto.websockets.UserJob.deserializeBinaryFromReader);
      msg.setUserJob(value);
      break;
    case 37:
      var value = new proto.websockets.Video;
      reader.readMessage(value,proto.websockets.Video.deserializeBinaryFromReader);
      msg.setVideo(value);
      break;
    case 38:
      var value = new proto.websockets.Split;
      reader.readMessage(value,proto.websockets.Split.deserializeBinaryFromReader);
      msg.setSplit(value);
      break;
    case 39:
      var value = new proto.websockets.Training;
      reader.readMessage(value,proto.websockets.Training.deserializeBinaryFromReader);
      msg.setTraining(value);
      break;
    case 40:
      var value = new proto.websockets.TrainingMetric;
      reader.readMessage(value,proto.websockets.TrainingMetric.deserializeBinaryFromReader);
      msg.setTrainingMetric(value);
      break;
    case 41:
      var value = new proto.websockets.Import;
      reader.readMessage(value,proto.websockets.Import.deserializeBinaryFromReader);
      msg.setImportJob(value);
      break;
    case 42:
      var value = /** @type {string} */ (reader.readString());
      msg.addLabelClassIds(value);
      break;
    case 44:
      var value = new proto.websockets.Aldi;
      reader.readMessage(value,proto.websockets.Aldi.deserializeBinaryFromReader);
      msg.setAldi(value);
      break;
    case 45:
      var value = new proto.websockets.VideoCutSession;
      reader.readMessage(value,proto.websockets.VideoCutSession.deserializeBinaryFromReader);
      msg.setVideoCutSession(value);
      break;
    case 46:
      var value = new proto.websockets.FrameImportSession;
      reader.readMessage(value,proto.websockets.FrameImportSession.deserializeBinaryFromReader);
      msg.setFrameImportSession(value);
      break;
    case 50:
      var value = new proto.websockets.Experiment;
      reader.readMessage(value,proto.websockets.Experiment.deserializeBinaryFromReader);
      msg.setExperiment(value);
      break;
    case 9999:
      var value = new common_tracing_pb.Trace;
      reader.readMessage(value,common_tracing_pb.Trace.deserializeBinaryFromReader);
      msg.setTrace(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.websockets.Notification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.websockets.Notification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.websockets.Notification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.Notification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubjectsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getUser();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEvent();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getImage();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.websockets.Image.serializeBinaryToWriter
    );
  }
  f = message.getLabel();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.websockets.Label.serializeBinaryToWriter
    );
  }
  f = message.getDataset();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.websockets.Dataset.serializeBinaryToWriter
    );
  }
  f = message.getLabelClass();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      proto.websockets.LabelClass.serializeBinaryToWriter
    );
  }
  f = message.getModel();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      proto.websockets.Model.serializeBinaryToWriter
    );
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      proto.websockets.Project.serializeBinaryToWriter
    );
  }
  f = message.getJob();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      proto.websockets.Job.serializeBinaryToWriter
    );
  }
  f = message.getTagClass();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      proto.websockets.TagClass.serializeBinaryToWriter
    );
  }
  f = message.getAttribute();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      proto.websockets.Attribute.serializeBinaryToWriter
    );
  }
  f = message.getAttributeClass();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      proto.websockets.AttributeClass.serializeBinaryToWriter
    );
  }
  f = message.getAttributeLov();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      proto.websockets.AttributeLOV.serializeBinaryToWriter
    );
  }
  f = message.getExport();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      proto.websockets.Export.serializeBinaryToWriter
    );
  }
  f = message.getLocksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      32,
      f,
      proto.websockets.Lock.serializeBinaryToWriter
    );
  }
  f = message.getNotification();
  if (f != null) {
    writer.writeMessage(
      33,
      f,
      app_notifications_rpc_v1_service_pb.Notification.serializeBinaryToWriter
    );
  }
  f = message.getEfRun();
  if (f != null) {
    writer.writeMessage(
      34,
      f,
      proto.websockets.EFRun.serializeBinaryToWriter
    );
  }
  f = message.getUserJob();
  if (f != null) {
    writer.writeMessage(
      35,
      f,
      proto.websockets.UserJob.serializeBinaryToWriter
    );
  }
  f = message.getVideo();
  if (f != null) {
    writer.writeMessage(
      37,
      f,
      proto.websockets.Video.serializeBinaryToWriter
    );
  }
  f = message.getSplit();
  if (f != null) {
    writer.writeMessage(
      38,
      f,
      proto.websockets.Split.serializeBinaryToWriter
    );
  }
  f = message.getTraining();
  if (f != null) {
    writer.writeMessage(
      39,
      f,
      proto.websockets.Training.serializeBinaryToWriter
    );
  }
  f = message.getTrainingMetric();
  if (f != null) {
    writer.writeMessage(
      40,
      f,
      proto.websockets.TrainingMetric.serializeBinaryToWriter
    );
  }
  f = message.getImportJob();
  if (f != null) {
    writer.writeMessage(
      41,
      f,
      proto.websockets.Import.serializeBinaryToWriter
    );
  }
  f = message.getLabelClassIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      42,
      f
    );
  }
  f = message.getAldi();
  if (f != null) {
    writer.writeMessage(
      44,
      f,
      proto.websockets.Aldi.serializeBinaryToWriter
    );
  }
  f = message.getVideoCutSession();
  if (f != null) {
    writer.writeMessage(
      45,
      f,
      proto.websockets.VideoCutSession.serializeBinaryToWriter
    );
  }
  f = message.getFrameImportSession();
  if (f != null) {
    writer.writeMessage(
      46,
      f,
      proto.websockets.FrameImportSession.serializeBinaryToWriter
    );
  }
  f = message.getExperiment();
  if (f != null) {
    writer.writeMessage(
      50,
      f,
      proto.websockets.Experiment.serializeBinaryToWriter
    );
  }
  f = message.getTrace();
  if (f != null) {
    writer.writeMessage(
      9999,
      f,
      common_tracing_pb.Trace.serializeBinaryToWriter
    );
  }
};


/**
 * repeated string subjects = 1;
 * @return {!Array<string>}
 */
proto.websockets.Notification.prototype.getSubjectsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.websockets.Notification} returns this
 */
proto.websockets.Notification.prototype.setSubjectsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.websockets.Notification} returns this
 */
proto.websockets.Notification.prototype.addSubjects = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.websockets.Notification} returns this
 */
proto.websockets.Notification.prototype.clearSubjectsList = function() {
  return this.setSubjectsList([]);
};


/**
 * optional string user = 2;
 * @return {string}
 */
proto.websockets.Notification.prototype.getUser = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.Notification} returns this
 */
proto.websockets.Notification.prototype.setUser = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional NotificationEvent event = 10;
 * @return {!proto.websockets.NotificationEvent}
 */
proto.websockets.Notification.prototype.getEvent = function() {
  return /** @type {!proto.websockets.NotificationEvent} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.websockets.NotificationEvent} value
 * @return {!proto.websockets.Notification} returns this
 */
proto.websockets.Notification.prototype.setEvent = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional Image image = 20;
 * @return {?proto.websockets.Image}
 */
proto.websockets.Notification.prototype.getImage = function() {
  return /** @type{?proto.websockets.Image} */ (
    jspb.Message.getWrapperField(this, proto.websockets.Image, 20));
};


/**
 * @param {?proto.websockets.Image|undefined} value
 * @return {!proto.websockets.Notification} returns this
*/
proto.websockets.Notification.prototype.setImage = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.websockets.Notification} returns this
 */
proto.websockets.Notification.prototype.clearImage = function() {
  return this.setImage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.websockets.Notification.prototype.hasImage = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional Label label = 21;
 * @return {?proto.websockets.Label}
 */
proto.websockets.Notification.prototype.getLabel = function() {
  return /** @type{?proto.websockets.Label} */ (
    jspb.Message.getWrapperField(this, proto.websockets.Label, 21));
};


/**
 * @param {?proto.websockets.Label|undefined} value
 * @return {!proto.websockets.Notification} returns this
*/
proto.websockets.Notification.prototype.setLabel = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.websockets.Notification} returns this
 */
proto.websockets.Notification.prototype.clearLabel = function() {
  return this.setLabel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.websockets.Notification.prototype.hasLabel = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional Dataset dataset = 22;
 * @return {?proto.websockets.Dataset}
 */
proto.websockets.Notification.prototype.getDataset = function() {
  return /** @type{?proto.websockets.Dataset} */ (
    jspb.Message.getWrapperField(this, proto.websockets.Dataset, 22));
};


/**
 * @param {?proto.websockets.Dataset|undefined} value
 * @return {!proto.websockets.Notification} returns this
*/
proto.websockets.Notification.prototype.setDataset = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.websockets.Notification} returns this
 */
proto.websockets.Notification.prototype.clearDataset = function() {
  return this.setDataset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.websockets.Notification.prototype.hasDataset = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional LabelClass label_class = 23;
 * @return {?proto.websockets.LabelClass}
 */
proto.websockets.Notification.prototype.getLabelClass = function() {
  return /** @type{?proto.websockets.LabelClass} */ (
    jspb.Message.getWrapperField(this, proto.websockets.LabelClass, 23));
};


/**
 * @param {?proto.websockets.LabelClass|undefined} value
 * @return {!proto.websockets.Notification} returns this
*/
proto.websockets.Notification.prototype.setLabelClass = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.websockets.Notification} returns this
 */
proto.websockets.Notification.prototype.clearLabelClass = function() {
  return this.setLabelClass(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.websockets.Notification.prototype.hasLabelClass = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional Model model = 24;
 * @return {?proto.websockets.Model}
 */
proto.websockets.Notification.prototype.getModel = function() {
  return /** @type{?proto.websockets.Model} */ (
    jspb.Message.getWrapperField(this, proto.websockets.Model, 24));
};


/**
 * @param {?proto.websockets.Model|undefined} value
 * @return {!proto.websockets.Notification} returns this
*/
proto.websockets.Notification.prototype.setModel = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.websockets.Notification} returns this
 */
proto.websockets.Notification.prototype.clearModel = function() {
  return this.setModel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.websockets.Notification.prototype.hasModel = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional Project project = 25;
 * @return {?proto.websockets.Project}
 */
proto.websockets.Notification.prototype.getProject = function() {
  return /** @type{?proto.websockets.Project} */ (
    jspb.Message.getWrapperField(this, proto.websockets.Project, 25));
};


/**
 * @param {?proto.websockets.Project|undefined} value
 * @return {!proto.websockets.Notification} returns this
*/
proto.websockets.Notification.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.websockets.Notification} returns this
 */
proto.websockets.Notification.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.websockets.Notification.prototype.hasProject = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional Job job = 26;
 * @return {?proto.websockets.Job}
 */
proto.websockets.Notification.prototype.getJob = function() {
  return /** @type{?proto.websockets.Job} */ (
    jspb.Message.getWrapperField(this, proto.websockets.Job, 26));
};


/**
 * @param {?proto.websockets.Job|undefined} value
 * @return {!proto.websockets.Notification} returns this
*/
proto.websockets.Notification.prototype.setJob = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.websockets.Notification} returns this
 */
proto.websockets.Notification.prototype.clearJob = function() {
  return this.setJob(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.websockets.Notification.prototype.hasJob = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional TagClass tag_class = 27;
 * @return {?proto.websockets.TagClass}
 */
proto.websockets.Notification.prototype.getTagClass = function() {
  return /** @type{?proto.websockets.TagClass} */ (
    jspb.Message.getWrapperField(this, proto.websockets.TagClass, 27));
};


/**
 * @param {?proto.websockets.TagClass|undefined} value
 * @return {!proto.websockets.Notification} returns this
*/
proto.websockets.Notification.prototype.setTagClass = function(value) {
  return jspb.Message.setWrapperField(this, 27, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.websockets.Notification} returns this
 */
proto.websockets.Notification.prototype.clearTagClass = function() {
  return this.setTagClass(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.websockets.Notification.prototype.hasTagClass = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional Attribute attribute = 28;
 * @return {?proto.websockets.Attribute}
 */
proto.websockets.Notification.prototype.getAttribute = function() {
  return /** @type{?proto.websockets.Attribute} */ (
    jspb.Message.getWrapperField(this, proto.websockets.Attribute, 28));
};


/**
 * @param {?proto.websockets.Attribute|undefined} value
 * @return {!proto.websockets.Notification} returns this
*/
proto.websockets.Notification.prototype.setAttribute = function(value) {
  return jspb.Message.setWrapperField(this, 28, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.websockets.Notification} returns this
 */
proto.websockets.Notification.prototype.clearAttribute = function() {
  return this.setAttribute(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.websockets.Notification.prototype.hasAttribute = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional AttributeClass attribute_class = 29;
 * @return {?proto.websockets.AttributeClass}
 */
proto.websockets.Notification.prototype.getAttributeClass = function() {
  return /** @type{?proto.websockets.AttributeClass} */ (
    jspb.Message.getWrapperField(this, proto.websockets.AttributeClass, 29));
};


/**
 * @param {?proto.websockets.AttributeClass|undefined} value
 * @return {!proto.websockets.Notification} returns this
*/
proto.websockets.Notification.prototype.setAttributeClass = function(value) {
  return jspb.Message.setWrapperField(this, 29, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.websockets.Notification} returns this
 */
proto.websockets.Notification.prototype.clearAttributeClass = function() {
  return this.setAttributeClass(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.websockets.Notification.prototype.hasAttributeClass = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional AttributeLOV attribute_lov = 30;
 * @return {?proto.websockets.AttributeLOV}
 */
proto.websockets.Notification.prototype.getAttributeLov = function() {
  return /** @type{?proto.websockets.AttributeLOV} */ (
    jspb.Message.getWrapperField(this, proto.websockets.AttributeLOV, 30));
};


/**
 * @param {?proto.websockets.AttributeLOV|undefined} value
 * @return {!proto.websockets.Notification} returns this
*/
proto.websockets.Notification.prototype.setAttributeLov = function(value) {
  return jspb.Message.setWrapperField(this, 30, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.websockets.Notification} returns this
 */
proto.websockets.Notification.prototype.clearAttributeLov = function() {
  return this.setAttributeLov(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.websockets.Notification.prototype.hasAttributeLov = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional Export export = 31;
 * @return {?proto.websockets.Export}
 */
proto.websockets.Notification.prototype.getExport = function() {
  return /** @type{?proto.websockets.Export} */ (
    jspb.Message.getWrapperField(this, proto.websockets.Export, 31));
};


/**
 * @param {?proto.websockets.Export|undefined} value
 * @return {!proto.websockets.Notification} returns this
*/
proto.websockets.Notification.prototype.setExport = function(value) {
  return jspb.Message.setWrapperField(this, 31, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.websockets.Notification} returns this
 */
proto.websockets.Notification.prototype.clearExport = function() {
  return this.setExport(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.websockets.Notification.prototype.hasExport = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * repeated Lock locks = 32;
 * @return {!Array<!proto.websockets.Lock>}
 */
proto.websockets.Notification.prototype.getLocksList = function() {
  return /** @type{!Array<!proto.websockets.Lock>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.websockets.Lock, 32));
};


/**
 * @param {!Array<!proto.websockets.Lock>} value
 * @return {!proto.websockets.Notification} returns this
*/
proto.websockets.Notification.prototype.setLocksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 32, value);
};


/**
 * @param {!proto.websockets.Lock=} opt_value
 * @param {number=} opt_index
 * @return {!proto.websockets.Lock}
 */
proto.websockets.Notification.prototype.addLocks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 32, opt_value, proto.websockets.Lock, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.websockets.Notification} returns this
 */
proto.websockets.Notification.prototype.clearLocksList = function() {
  return this.setLocksList([]);
};


/**
 * optional app.notifications.rpc.v1.Notification notification = 33;
 * @return {?proto.app.notifications.rpc.v1.Notification}
 */
proto.websockets.Notification.prototype.getNotification = function() {
  return /** @type{?proto.app.notifications.rpc.v1.Notification} */ (
    jspb.Message.getWrapperField(this, app_notifications_rpc_v1_service_pb.Notification, 33));
};


/**
 * @param {?proto.app.notifications.rpc.v1.Notification|undefined} value
 * @return {!proto.websockets.Notification} returns this
*/
proto.websockets.Notification.prototype.setNotification = function(value) {
  return jspb.Message.setWrapperField(this, 33, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.websockets.Notification} returns this
 */
proto.websockets.Notification.prototype.clearNotification = function() {
  return this.setNotification(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.websockets.Notification.prototype.hasNotification = function() {
  return jspb.Message.getField(this, 33) != null;
};


/**
 * optional EFRun ef_run = 34;
 * @return {?proto.websockets.EFRun}
 */
proto.websockets.Notification.prototype.getEfRun = function() {
  return /** @type{?proto.websockets.EFRun} */ (
    jspb.Message.getWrapperField(this, proto.websockets.EFRun, 34));
};


/**
 * @param {?proto.websockets.EFRun|undefined} value
 * @return {!proto.websockets.Notification} returns this
*/
proto.websockets.Notification.prototype.setEfRun = function(value) {
  return jspb.Message.setWrapperField(this, 34, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.websockets.Notification} returns this
 */
proto.websockets.Notification.prototype.clearEfRun = function() {
  return this.setEfRun(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.websockets.Notification.prototype.hasEfRun = function() {
  return jspb.Message.getField(this, 34) != null;
};


/**
 * optional UserJob user_job = 35;
 * @return {?proto.websockets.UserJob}
 */
proto.websockets.Notification.prototype.getUserJob = function() {
  return /** @type{?proto.websockets.UserJob} */ (
    jspb.Message.getWrapperField(this, proto.websockets.UserJob, 35));
};


/**
 * @param {?proto.websockets.UserJob|undefined} value
 * @return {!proto.websockets.Notification} returns this
*/
proto.websockets.Notification.prototype.setUserJob = function(value) {
  return jspb.Message.setWrapperField(this, 35, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.websockets.Notification} returns this
 */
proto.websockets.Notification.prototype.clearUserJob = function() {
  return this.setUserJob(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.websockets.Notification.prototype.hasUserJob = function() {
  return jspb.Message.getField(this, 35) != null;
};


/**
 * optional Video video = 37;
 * @return {?proto.websockets.Video}
 */
proto.websockets.Notification.prototype.getVideo = function() {
  return /** @type{?proto.websockets.Video} */ (
    jspb.Message.getWrapperField(this, proto.websockets.Video, 37));
};


/**
 * @param {?proto.websockets.Video|undefined} value
 * @return {!proto.websockets.Notification} returns this
*/
proto.websockets.Notification.prototype.setVideo = function(value) {
  return jspb.Message.setWrapperField(this, 37, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.websockets.Notification} returns this
 */
proto.websockets.Notification.prototype.clearVideo = function() {
  return this.setVideo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.websockets.Notification.prototype.hasVideo = function() {
  return jspb.Message.getField(this, 37) != null;
};


/**
 * optional Split split = 38;
 * @return {?proto.websockets.Split}
 */
proto.websockets.Notification.prototype.getSplit = function() {
  return /** @type{?proto.websockets.Split} */ (
    jspb.Message.getWrapperField(this, proto.websockets.Split, 38));
};


/**
 * @param {?proto.websockets.Split|undefined} value
 * @return {!proto.websockets.Notification} returns this
*/
proto.websockets.Notification.prototype.setSplit = function(value) {
  return jspb.Message.setWrapperField(this, 38, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.websockets.Notification} returns this
 */
proto.websockets.Notification.prototype.clearSplit = function() {
  return this.setSplit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.websockets.Notification.prototype.hasSplit = function() {
  return jspb.Message.getField(this, 38) != null;
};


/**
 * optional Training training = 39;
 * @return {?proto.websockets.Training}
 */
proto.websockets.Notification.prototype.getTraining = function() {
  return /** @type{?proto.websockets.Training} */ (
    jspb.Message.getWrapperField(this, proto.websockets.Training, 39));
};


/**
 * @param {?proto.websockets.Training|undefined} value
 * @return {!proto.websockets.Notification} returns this
*/
proto.websockets.Notification.prototype.setTraining = function(value) {
  return jspb.Message.setWrapperField(this, 39, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.websockets.Notification} returns this
 */
proto.websockets.Notification.prototype.clearTraining = function() {
  return this.setTraining(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.websockets.Notification.prototype.hasTraining = function() {
  return jspb.Message.getField(this, 39) != null;
};


/**
 * optional TrainingMetric training_metric = 40;
 * @return {?proto.websockets.TrainingMetric}
 */
proto.websockets.Notification.prototype.getTrainingMetric = function() {
  return /** @type{?proto.websockets.TrainingMetric} */ (
    jspb.Message.getWrapperField(this, proto.websockets.TrainingMetric, 40));
};


/**
 * @param {?proto.websockets.TrainingMetric|undefined} value
 * @return {!proto.websockets.Notification} returns this
*/
proto.websockets.Notification.prototype.setTrainingMetric = function(value) {
  return jspb.Message.setWrapperField(this, 40, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.websockets.Notification} returns this
 */
proto.websockets.Notification.prototype.clearTrainingMetric = function() {
  return this.setTrainingMetric(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.websockets.Notification.prototype.hasTrainingMetric = function() {
  return jspb.Message.getField(this, 40) != null;
};


/**
 * optional Import import_job = 41;
 * @return {?proto.websockets.Import}
 */
proto.websockets.Notification.prototype.getImportJob = function() {
  return /** @type{?proto.websockets.Import} */ (
    jspb.Message.getWrapperField(this, proto.websockets.Import, 41));
};


/**
 * @param {?proto.websockets.Import|undefined} value
 * @return {!proto.websockets.Notification} returns this
*/
proto.websockets.Notification.prototype.setImportJob = function(value) {
  return jspb.Message.setWrapperField(this, 41, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.websockets.Notification} returns this
 */
proto.websockets.Notification.prototype.clearImportJob = function() {
  return this.setImportJob(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.websockets.Notification.prototype.hasImportJob = function() {
  return jspb.Message.getField(this, 41) != null;
};


/**
 * repeated string label_class_ids = 42;
 * @return {!Array<string>}
 */
proto.websockets.Notification.prototype.getLabelClassIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 42));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.websockets.Notification} returns this
 */
proto.websockets.Notification.prototype.setLabelClassIdsList = function(value) {
  return jspb.Message.setField(this, 42, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.websockets.Notification} returns this
 */
proto.websockets.Notification.prototype.addLabelClassIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 42, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.websockets.Notification} returns this
 */
proto.websockets.Notification.prototype.clearLabelClassIdsList = function() {
  return this.setLabelClassIdsList([]);
};


/**
 * optional Aldi aldi = 44;
 * @return {?proto.websockets.Aldi}
 */
proto.websockets.Notification.prototype.getAldi = function() {
  return /** @type{?proto.websockets.Aldi} */ (
    jspb.Message.getWrapperField(this, proto.websockets.Aldi, 44));
};


/**
 * @param {?proto.websockets.Aldi|undefined} value
 * @return {!proto.websockets.Notification} returns this
*/
proto.websockets.Notification.prototype.setAldi = function(value) {
  return jspb.Message.setWrapperField(this, 44, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.websockets.Notification} returns this
 */
proto.websockets.Notification.prototype.clearAldi = function() {
  return this.setAldi(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.websockets.Notification.prototype.hasAldi = function() {
  return jspb.Message.getField(this, 44) != null;
};


/**
 * optional VideoCutSession video_cut_session = 45;
 * @return {?proto.websockets.VideoCutSession}
 */
proto.websockets.Notification.prototype.getVideoCutSession = function() {
  return /** @type{?proto.websockets.VideoCutSession} */ (
    jspb.Message.getWrapperField(this, proto.websockets.VideoCutSession, 45));
};


/**
 * @param {?proto.websockets.VideoCutSession|undefined} value
 * @return {!proto.websockets.Notification} returns this
*/
proto.websockets.Notification.prototype.setVideoCutSession = function(value) {
  return jspb.Message.setWrapperField(this, 45, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.websockets.Notification} returns this
 */
proto.websockets.Notification.prototype.clearVideoCutSession = function() {
  return this.setVideoCutSession(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.websockets.Notification.prototype.hasVideoCutSession = function() {
  return jspb.Message.getField(this, 45) != null;
};


/**
 * optional FrameImportSession frame_import_session = 46;
 * @return {?proto.websockets.FrameImportSession}
 */
proto.websockets.Notification.prototype.getFrameImportSession = function() {
  return /** @type{?proto.websockets.FrameImportSession} */ (
    jspb.Message.getWrapperField(this, proto.websockets.FrameImportSession, 46));
};


/**
 * @param {?proto.websockets.FrameImportSession|undefined} value
 * @return {!proto.websockets.Notification} returns this
*/
proto.websockets.Notification.prototype.setFrameImportSession = function(value) {
  return jspb.Message.setWrapperField(this, 46, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.websockets.Notification} returns this
 */
proto.websockets.Notification.prototype.clearFrameImportSession = function() {
  return this.setFrameImportSession(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.websockets.Notification.prototype.hasFrameImportSession = function() {
  return jspb.Message.getField(this, 46) != null;
};


/**
 * optional Experiment experiment = 50;
 * @return {?proto.websockets.Experiment}
 */
proto.websockets.Notification.prototype.getExperiment = function() {
  return /** @type{?proto.websockets.Experiment} */ (
    jspb.Message.getWrapperField(this, proto.websockets.Experiment, 50));
};


/**
 * @param {?proto.websockets.Experiment|undefined} value
 * @return {!proto.websockets.Notification} returns this
*/
proto.websockets.Notification.prototype.setExperiment = function(value) {
  return jspb.Message.setWrapperField(this, 50, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.websockets.Notification} returns this
 */
proto.websockets.Notification.prototype.clearExperiment = function() {
  return this.setExperiment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.websockets.Notification.prototype.hasExperiment = function() {
  return jspb.Message.getField(this, 50) != null;
};


/**
 * optional common.Trace trace = 9999;
 * @return {?proto.common.Trace}
 */
proto.websockets.Notification.prototype.getTrace = function() {
  return /** @type{?proto.common.Trace} */ (
    jspb.Message.getWrapperField(this, common_tracing_pb.Trace, 9999));
};


/**
 * @param {?proto.common.Trace|undefined} value
 * @return {!proto.websockets.Notification} returns this
*/
proto.websockets.Notification.prototype.setTrace = function(value) {
  return jspb.Message.setWrapperField(this, 9999, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.websockets.Notification} returns this
 */
proto.websockets.Notification.prototype.clearTrace = function() {
  return this.setTrace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.websockets.Notification.prototype.hasTrace = function() {
  return jspb.Message.getField(this, 9999) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.websockets.VideoCutSession.prototype.toObject = function(opt_includeInstance) {
  return proto.websockets.VideoCutSession.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.websockets.VideoCutSession} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.VideoCutSession.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    importedImages: jspb.Message.getFieldWithDefault(msg, 2, 0),
    completedOn: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.websockets.VideoCutSession}
 */
proto.websockets.VideoCutSession.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.websockets.VideoCutSession;
  return proto.websockets.VideoCutSession.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.websockets.VideoCutSession} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.websockets.VideoCutSession}
 */
proto.websockets.VideoCutSession.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setImportedImages(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCompletedOn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.websockets.VideoCutSession.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.websockets.VideoCutSession.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.websockets.VideoCutSession} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.VideoCutSession.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getImportedImages();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCompletedOn();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.websockets.VideoCutSession.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.VideoCutSession} returns this
 */
proto.websockets.VideoCutSession.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 imported_images = 2;
 * @return {number}
 */
proto.websockets.VideoCutSession.prototype.getImportedImages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.websockets.VideoCutSession} returns this
 */
proto.websockets.VideoCutSession.prototype.setImportedImages = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 completed_on = 3;
 * @return {number}
 */
proto.websockets.VideoCutSession.prototype.getCompletedOn = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.websockets.VideoCutSession} returns this
 */
proto.websockets.VideoCutSession.prototype.setCompletedOn = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.websockets.FrameImportSession.prototype.toObject = function(opt_includeInstance) {
  return proto.websockets.FrameImportSession.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.websockets.FrameImportSession} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.FrameImportSession.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    importedImages: jspb.Message.getFieldWithDefault(msg, 2, 0),
    completedOn: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.websockets.FrameImportSession}
 */
proto.websockets.FrameImportSession.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.websockets.FrameImportSession;
  return proto.websockets.FrameImportSession.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.websockets.FrameImportSession} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.websockets.FrameImportSession}
 */
proto.websockets.FrameImportSession.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setImportedImages(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCompletedOn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.websockets.FrameImportSession.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.websockets.FrameImportSession.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.websockets.FrameImportSession} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.FrameImportSession.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getImportedImages();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCompletedOn();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.websockets.FrameImportSession.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.FrameImportSession} returns this
 */
proto.websockets.FrameImportSession.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 imported_images = 2;
 * @return {number}
 */
proto.websockets.FrameImportSession.prototype.getImportedImages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.websockets.FrameImportSession} returns this
 */
proto.websockets.FrameImportSession.prototype.setImportedImages = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 completed_on = 3;
 * @return {number}
 */
proto.websockets.FrameImportSession.prototype.getCompletedOn = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.websockets.FrameImportSession} returns this
 */
proto.websockets.FrameImportSession.prototype.setCompletedOn = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.websockets.TrainingMetric.oneofGroups_ = [[5,6,9]];

/**
 * @enum {number}
 */
proto.websockets.TrainingMetric.ValueCase = {
  VALUE_NOT_SET: 0,
  TRAIN_VALUE: 5,
  VALIDATION_VALUE: 6,
  TEST_VALUE: 9
};

/**
 * @return {proto.websockets.TrainingMetric.ValueCase}
 */
proto.websockets.TrainingMetric.prototype.getValueCase = function() {
  return /** @type {proto.websockets.TrainingMetric.ValueCase} */(jspb.Message.computeOneofCase(this, proto.websockets.TrainingMetric.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.websockets.TrainingMetric.prototype.toObject = function(opt_includeInstance) {
  return proto.websockets.TrainingMetric.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.websockets.TrainingMetric} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.TrainingMetric.toObject = function(includeInstance, msg) {
  var f, obj = {
    runId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    metricName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    index: jspb.Message.getFieldWithDefault(msg, 3, 0),
    bestIteration: jspb.Message.getFieldWithDefault(msg, 4, 0),
    trainValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    validationValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    testValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    timestamp: jspb.Message.getFieldWithDefault(msg, 7, 0),
    gpuConsumed: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.websockets.TrainingMetric}
 */
proto.websockets.TrainingMetric.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.websockets.TrainingMetric;
  return proto.websockets.TrainingMetric.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.websockets.TrainingMetric} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.websockets.TrainingMetric}
 */
proto.websockets.TrainingMetric.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMetricName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIndex(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBestIteration(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTrainValue(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValidationValue(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTestValue(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setGpuConsumed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.websockets.TrainingMetric.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.websockets.TrainingMetric.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.websockets.TrainingMetric} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.TrainingMetric.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRunId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMetricName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIndex();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getBestIteration();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getGpuConsumed();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
};


/**
 * optional string run_id = 1;
 * @return {string}
 */
proto.websockets.TrainingMetric.prototype.getRunId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.TrainingMetric} returns this
 */
proto.websockets.TrainingMetric.prototype.setRunId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string metric_name = 2;
 * @return {string}
 */
proto.websockets.TrainingMetric.prototype.getMetricName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.TrainingMetric} returns this
 */
proto.websockets.TrainingMetric.prototype.setMetricName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 index = 3;
 * @return {number}
 */
proto.websockets.TrainingMetric.prototype.getIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.websockets.TrainingMetric} returns this
 */
proto.websockets.TrainingMetric.prototype.setIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 best_iteration = 4;
 * @return {number}
 */
proto.websockets.TrainingMetric.prototype.getBestIteration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.websockets.TrainingMetric} returns this
 */
proto.websockets.TrainingMetric.prototype.setBestIteration = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional double train_value = 5;
 * @return {number}
 */
proto.websockets.TrainingMetric.prototype.getTrainValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.websockets.TrainingMetric} returns this
 */
proto.websockets.TrainingMetric.prototype.setTrainValue = function(value) {
  return jspb.Message.setOneofField(this, 5, proto.websockets.TrainingMetric.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.websockets.TrainingMetric} returns this
 */
proto.websockets.TrainingMetric.prototype.clearTrainValue = function() {
  return jspb.Message.setOneofField(this, 5, proto.websockets.TrainingMetric.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.websockets.TrainingMetric.prototype.hasTrainValue = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional double validation_value = 6;
 * @return {number}
 */
proto.websockets.TrainingMetric.prototype.getValidationValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.websockets.TrainingMetric} returns this
 */
proto.websockets.TrainingMetric.prototype.setValidationValue = function(value) {
  return jspb.Message.setOneofField(this, 6, proto.websockets.TrainingMetric.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.websockets.TrainingMetric} returns this
 */
proto.websockets.TrainingMetric.prototype.clearValidationValue = function() {
  return jspb.Message.setOneofField(this, 6, proto.websockets.TrainingMetric.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.websockets.TrainingMetric.prototype.hasValidationValue = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional double test_value = 9;
 * @return {number}
 */
proto.websockets.TrainingMetric.prototype.getTestValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.websockets.TrainingMetric} returns this
 */
proto.websockets.TrainingMetric.prototype.setTestValue = function(value) {
  return jspb.Message.setOneofField(this, 9, proto.websockets.TrainingMetric.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.websockets.TrainingMetric} returns this
 */
proto.websockets.TrainingMetric.prototype.clearTestValue = function() {
  return jspb.Message.setOneofField(this, 9, proto.websockets.TrainingMetric.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.websockets.TrainingMetric.prototype.hasTestValue = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional int64 timestamp = 7;
 * @return {number}
 */
proto.websockets.TrainingMetric.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.websockets.TrainingMetric} returns this
 */
proto.websockets.TrainingMetric.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional double gpu_consumed = 8;
 * @return {number}
 */
proto.websockets.TrainingMetric.prototype.getGpuConsumed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.websockets.TrainingMetric} returns this
 */
proto.websockets.TrainingMetric.prototype.setGpuConsumed = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.websockets.Experiment.prototype.toObject = function(opt_includeInstance) {
  return proto.websockets.Experiment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.websockets.Experiment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.Experiment.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.websockets.Experiment}
 */
proto.websockets.Experiment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.websockets.Experiment;
  return proto.websockets.Experiment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.websockets.Experiment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.websockets.Experiment}
 */
proto.websockets.Experiment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.websockets.Experiment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.websockets.Experiment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.websockets.Experiment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.Experiment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.websockets.Experiment.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.Experiment} returns this
 */
proto.websockets.Experiment.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.websockets.Experiment.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.Experiment} returns this
 */
proto.websockets.Experiment.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.websockets.Training.prototype.toObject = function(opt_includeInstance) {
  return proto.websockets.Training.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.websockets.Training} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.Training.toObject = function(includeInstance, msg) {
  var f, obj = {
    runId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    progress: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    errorCode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    errorMessage: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.websockets.Training}
 */
proto.websockets.Training.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.websockets.Training;
  return proto.websockets.Training.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.websockets.Training} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.websockets.Training}
 */
proto.websockets.Training.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunId(value);
      break;
    case 2:
      var value = /** @type {!proto.websockets.TrainStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setProgress(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.websockets.Training.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.websockets.Training.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.websockets.Training} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.Training.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRunId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getProgress();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getErrorMessage();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string run_id = 1;
 * @return {string}
 */
proto.websockets.Training.prototype.getRunId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.Training} returns this
 */
proto.websockets.Training.prototype.setRunId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional TrainStatus status = 2;
 * @return {!proto.websockets.TrainStatus}
 */
proto.websockets.Training.prototype.getStatus = function() {
  return /** @type {!proto.websockets.TrainStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.websockets.TrainStatus} value
 * @return {!proto.websockets.Training} returns this
 */
proto.websockets.Training.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional double progress = 3;
 * @return {number}
 */
proto.websockets.Training.prototype.getProgress = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.websockets.Training} returns this
 */
proto.websockets.Training.prototype.setProgress = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional string error_code = 4;
 * @return {string}
 */
proto.websockets.Training.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.Training} returns this
 */
proto.websockets.Training.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string error_message = 5;
 * @return {string}
 */
proto.websockets.Training.prototype.getErrorMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.Training} returns this
 */
proto.websockets.Training.prototype.setErrorMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.websockets.Split.prototype.toObject = function(opt_includeInstance) {
  return proto.websockets.Split.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.websockets.Split} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.Split.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    mode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    numFolds: jspb.Message.getFieldWithDefault(msg, 5, 0),
    creatorName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    jobId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    createdOn: jspb.Message.getFieldWithDefault(msg, 8, ""),
    completedOn: jspb.Message.getFieldWithDefault(msg, 9, ""),
    status: jspb.Message.getFieldWithDefault(msg, 10, ""),
    counts: (f = msg.getCounts()) && proto.websockets.SplitCounts.toObject(includeInstance, f),
    displayInfo: (f = msg.getDisplayInfo()) && proto.websockets.DisplayInfo.toObject(includeInstance, f),
    modelFamily: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.websockets.Split}
 */
proto.websockets.Split.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.websockets.Split;
  return proto.websockets.Split.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.websockets.Split} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.websockets.Split}
 */
proto.websockets.Split.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMode(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumFolds(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatorName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setJobId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedOn(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompletedOn(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 11:
      var value = new proto.websockets.SplitCounts;
      reader.readMessage(value,proto.websockets.SplitCounts.deserializeBinaryFromReader);
      msg.setCounts(value);
      break;
    case 12:
      var value = new proto.websockets.DisplayInfo;
      reader.readMessage(value,proto.websockets.DisplayInfo.deserializeBinaryFromReader);
      msg.setDisplayInfo(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelFamily(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.websockets.Split.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.websockets.Split.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.websockets.Split} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.Split.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getNumFolds();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getCreatorName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getJobId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCreatedOn();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCompletedOn();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCounts();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.websockets.SplitCounts.serializeBinaryToWriter
    );
  }
  f = message.getDisplayInfo();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.websockets.DisplayInfo.serializeBinaryToWriter
    );
  }
  f = message.getModelFamily();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.websockets.Split.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.Split} returns this
 */
proto.websockets.Split.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.websockets.Split.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.Split} returns this
 */
proto.websockets.Split.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.websockets.Split.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.Split} returns this
 */
proto.websockets.Split.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string mode = 4;
 * @return {string}
 */
proto.websockets.Split.prototype.getMode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.Split} returns this
 */
proto.websockets.Split.prototype.setMode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 num_folds = 5;
 * @return {number}
 */
proto.websockets.Split.prototype.getNumFolds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.websockets.Split} returns this
 */
proto.websockets.Split.prototype.setNumFolds = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string creator_name = 6;
 * @return {string}
 */
proto.websockets.Split.prototype.getCreatorName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.Split} returns this
 */
proto.websockets.Split.prototype.setCreatorName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string job_id = 7;
 * @return {string}
 */
proto.websockets.Split.prototype.getJobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.Split} returns this
 */
proto.websockets.Split.prototype.setJobId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string created_on = 8;
 * @return {string}
 */
proto.websockets.Split.prototype.getCreatedOn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.Split} returns this
 */
proto.websockets.Split.prototype.setCreatedOn = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string completed_on = 9;
 * @return {string}
 */
proto.websockets.Split.prototype.getCompletedOn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.Split} returns this
 */
proto.websockets.Split.prototype.setCompletedOn = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string status = 10;
 * @return {string}
 */
proto.websockets.Split.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.Split} returns this
 */
proto.websockets.Split.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional SplitCounts counts = 11;
 * @return {?proto.websockets.SplitCounts}
 */
proto.websockets.Split.prototype.getCounts = function() {
  return /** @type{?proto.websockets.SplitCounts} */ (
    jspb.Message.getWrapperField(this, proto.websockets.SplitCounts, 11));
};


/**
 * @param {?proto.websockets.SplitCounts|undefined} value
 * @return {!proto.websockets.Split} returns this
*/
proto.websockets.Split.prototype.setCounts = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.websockets.Split} returns this
 */
proto.websockets.Split.prototype.clearCounts = function() {
  return this.setCounts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.websockets.Split.prototype.hasCounts = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional DisplayInfo display_info = 12;
 * @return {?proto.websockets.DisplayInfo}
 */
proto.websockets.Split.prototype.getDisplayInfo = function() {
  return /** @type{?proto.websockets.DisplayInfo} */ (
    jspb.Message.getWrapperField(this, proto.websockets.DisplayInfo, 12));
};


/**
 * @param {?proto.websockets.DisplayInfo|undefined} value
 * @return {!proto.websockets.Split} returns this
*/
proto.websockets.Split.prototype.setDisplayInfo = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.websockets.Split} returns this
 */
proto.websockets.Split.prototype.clearDisplayInfo = function() {
  return this.setDisplayInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.websockets.Split.prototype.hasDisplayInfo = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string model_family = 13;
 * @return {string}
 */
proto.websockets.Split.prototype.getModelFamily = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.Split} returns this
 */
proto.websockets.Split.prototype.setModelFamily = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.websockets.SplitCounts.prototype.toObject = function(opt_includeInstance) {
  return proto.websockets.SplitCounts.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.websockets.SplitCounts} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.SplitCounts.toObject = function(includeInstance, msg) {
  var f, obj = {
    train: jspb.Message.getFieldWithDefault(msg, 1, 0),
    validation: jspb.Message.getFieldWithDefault(msg, 2, 0),
    test: jspb.Message.getFieldWithDefault(msg, 3, 0),
    notEmpty: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.websockets.SplitCounts}
 */
proto.websockets.SplitCounts.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.websockets.SplitCounts;
  return proto.websockets.SplitCounts.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.websockets.SplitCounts} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.websockets.SplitCounts}
 */
proto.websockets.SplitCounts.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTrain(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setValidation(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTest(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNotEmpty(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.websockets.SplitCounts.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.websockets.SplitCounts.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.websockets.SplitCounts} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.SplitCounts.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrain();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getValidation();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTest();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getNotEmpty();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional int32 train = 1;
 * @return {number}
 */
proto.websockets.SplitCounts.prototype.getTrain = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.websockets.SplitCounts} returns this
 */
proto.websockets.SplitCounts.prototype.setTrain = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 validation = 2;
 * @return {number}
 */
proto.websockets.SplitCounts.prototype.getValidation = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.websockets.SplitCounts} returns this
 */
proto.websockets.SplitCounts.prototype.setValidation = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 test = 3;
 * @return {number}
 */
proto.websockets.SplitCounts.prototype.getTest = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.websockets.SplitCounts} returns this
 */
proto.websockets.SplitCounts.prototype.setTest = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool not_empty = 4;
 * @return {boolean}
 */
proto.websockets.SplitCounts.prototype.getNotEmpty = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.websockets.SplitCounts} returns this
 */
proto.websockets.SplitCounts.prototype.setNotEmpty = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.websockets.DisplayInfo.repeatedFields_ = [1,2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.websockets.DisplayInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.websockets.DisplayInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.websockets.DisplayInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.DisplayInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    datasetsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    imageStatusesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    classesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    tagClassesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    expCountsMap: (f = msg.getExpCountsMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.websockets.DisplayInfo}
 */
proto.websockets.DisplayInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.websockets.DisplayInfo;
  return proto.websockets.DisplayInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.websockets.DisplayInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.websockets.DisplayInfo}
 */
proto.websockets.DisplayInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addDatasets(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addImageStatuses(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addClasses(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addTagClasses(value);
      break;
    case 5:
      var value = msg.getExpCountsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readInt32, null, "", 0);
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.websockets.DisplayInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.websockets.DisplayInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.websockets.DisplayInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.DisplayInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDatasetsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getImageStatusesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getClassesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getTagClassesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getExpCountsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeInt32);
  }
};


/**
 * repeated string datasets = 1;
 * @return {!Array<string>}
 */
proto.websockets.DisplayInfo.prototype.getDatasetsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.websockets.DisplayInfo} returns this
 */
proto.websockets.DisplayInfo.prototype.setDatasetsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.websockets.DisplayInfo} returns this
 */
proto.websockets.DisplayInfo.prototype.addDatasets = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.websockets.DisplayInfo} returns this
 */
proto.websockets.DisplayInfo.prototype.clearDatasetsList = function() {
  return this.setDatasetsList([]);
};


/**
 * repeated string image_statuses = 2;
 * @return {!Array<string>}
 */
proto.websockets.DisplayInfo.prototype.getImageStatusesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.websockets.DisplayInfo} returns this
 */
proto.websockets.DisplayInfo.prototype.setImageStatusesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.websockets.DisplayInfo} returns this
 */
proto.websockets.DisplayInfo.prototype.addImageStatuses = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.websockets.DisplayInfo} returns this
 */
proto.websockets.DisplayInfo.prototype.clearImageStatusesList = function() {
  return this.setImageStatusesList([]);
};


/**
 * repeated string classes = 3;
 * @return {!Array<string>}
 */
proto.websockets.DisplayInfo.prototype.getClassesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.websockets.DisplayInfo} returns this
 */
proto.websockets.DisplayInfo.prototype.setClassesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.websockets.DisplayInfo} returns this
 */
proto.websockets.DisplayInfo.prototype.addClasses = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.websockets.DisplayInfo} returns this
 */
proto.websockets.DisplayInfo.prototype.clearClassesList = function() {
  return this.setClassesList([]);
};


/**
 * repeated string tag_classes = 4;
 * @return {!Array<string>}
 */
proto.websockets.DisplayInfo.prototype.getTagClassesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.websockets.DisplayInfo} returns this
 */
proto.websockets.DisplayInfo.prototype.setTagClassesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.websockets.DisplayInfo} returns this
 */
proto.websockets.DisplayInfo.prototype.addTagClasses = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.websockets.DisplayInfo} returns this
 */
proto.websockets.DisplayInfo.prototype.clearTagClassesList = function() {
  return this.setTagClassesList([]);
};


/**
 * map<string, int32> exp_counts = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,number>}
 */
proto.websockets.DisplayInfo.prototype.getExpCountsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,number>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.websockets.DisplayInfo} returns this
 */
proto.websockets.DisplayInfo.prototype.clearExpCountsMap = function() {
  this.getExpCountsMap().clear();
  return this;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.websockets.TagClass.prototype.toObject = function(opt_includeInstance) {
  return proto.websockets.TagClass.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.websockets.TagClass} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.TagClass.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tagString: jspb.Message.getFieldWithDefault(msg, 2, ""),
    groupId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    groupName: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.websockets.TagClass}
 */
proto.websockets.TagClass.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.websockets.TagClass;
  return proto.websockets.TagClass.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.websockets.TagClass} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.websockets.TagClass}
 */
proto.websockets.TagClass.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTagString(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.websockets.TagClass.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.websockets.TagClass.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.websockets.TagClass} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.TagClass.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTagString();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getGroupName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.websockets.TagClass.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.TagClass} returns this
 */
proto.websockets.TagClass.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tag_string = 2;
 * @return {string}
 */
proto.websockets.TagClass.prototype.getTagString = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.TagClass} returns this
 */
proto.websockets.TagClass.prototype.setTagString = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string group_id = 3;
 * @return {string}
 */
proto.websockets.TagClass.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.TagClass} returns this
 */
proto.websockets.TagClass.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string group_name = 4;
 * @return {string}
 */
proto.websockets.TagClass.prototype.getGroupName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.TagClass} returns this
 */
proto.websockets.TagClass.prototype.setGroupName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.websockets.Image.prototype.toObject = function(opt_includeInstance) {
  return proto.websockets.Image.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.websockets.Image} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.Image.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    datasetId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    filename: jspb.Message.getFieldWithDefault(msg, 3, ""),
    status: jspb.Message.getFieldWithDefault(msg, 4, ""),
    url: jspb.Message.getFieldWithDefault(msg, 5, ""),
    lock: (f = msg.getLock()) && proto.websockets.Lock.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.websockets.Image}
 */
proto.websockets.Image.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.websockets.Image;
  return proto.websockets.Image.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.websockets.Image} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.websockets.Image}
 */
proto.websockets.Image.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatasetId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilename(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 7:
      var value = new proto.websockets.Lock;
      reader.readMessage(value,proto.websockets.Lock.deserializeBinaryFromReader);
      msg.setLock(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.websockets.Image.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.websockets.Image.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.websockets.Image} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.Image.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDatasetId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFilename();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLock();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.websockets.Lock.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.websockets.Image.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.Image} returns this
 */
proto.websockets.Image.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string dataset_id = 2;
 * @return {string}
 */
proto.websockets.Image.prototype.getDatasetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.Image} returns this
 */
proto.websockets.Image.prototype.setDatasetId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string filename = 3;
 * @return {string}
 */
proto.websockets.Image.prototype.getFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.Image} returns this
 */
proto.websockets.Image.prototype.setFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string status = 4;
 * @return {string}
 */
proto.websockets.Image.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.Image} returns this
 */
proto.websockets.Image.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string url = 5;
 * @return {string}
 */
proto.websockets.Image.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.Image} returns this
 */
proto.websockets.Image.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional Lock lock = 7;
 * @return {?proto.websockets.Lock}
 */
proto.websockets.Image.prototype.getLock = function() {
  return /** @type{?proto.websockets.Lock} */ (
    jspb.Message.getWrapperField(this, proto.websockets.Lock, 7));
};


/**
 * @param {?proto.websockets.Lock|undefined} value
 * @return {!proto.websockets.Image} returns this
*/
proto.websockets.Image.prototype.setLock = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.websockets.Image} returns this
 */
proto.websockets.Image.prototype.clearLock = function() {
  return this.setLock(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.websockets.Image.prototype.hasLock = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.websockets.Video.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.websockets.Video.prototype.toObject = function(opt_includeInstance) {
  return proto.websockets.Video.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.websockets.Video} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.Video.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    datasetId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    filename: jspb.Message.getFieldWithDefault(msg, 3, ""),
    progress: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    durationMs: jspb.Message.getFieldWithDefault(msg, 6, 0),
    status: jspb.Message.getFieldWithDefault(msg, 7, ""),
    segment: (f = msg.getSegment()) && proto.websockets.VideoSegment.toObject(includeInstance, f),
    predictionList: jspb.Message.toObjectList(msg.getPredictionList(),
    proto.websockets.PredictedSegment.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.websockets.Video}
 */
proto.websockets.Video.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.websockets.Video;
  return proto.websockets.Video.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.websockets.Video} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.websockets.Video}
 */
proto.websockets.Video.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatasetId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilename(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setProgress(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setDurationMs(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 8:
      var value = new proto.websockets.VideoSegment;
      reader.readMessage(value,proto.websockets.VideoSegment.deserializeBinaryFromReader);
      msg.setSegment(value);
      break;
    case 9:
      var value = new proto.websockets.PredictedSegment;
      reader.readMessage(value,proto.websockets.PredictedSegment.deserializeBinaryFromReader);
      msg.addPrediction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.websockets.Video.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.websockets.Video.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.websockets.Video} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.Video.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDatasetId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFilename();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProgress();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getDurationMs();
  if (f !== 0) {
    writer.writeUint64(
      6,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSegment();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.websockets.VideoSegment.serializeBinaryToWriter
    );
  }
  f = message.getPredictionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.websockets.PredictedSegment.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.websockets.Video.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.Video} returns this
 */
proto.websockets.Video.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string dataset_id = 2;
 * @return {string}
 */
proto.websockets.Video.prototype.getDatasetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.Video} returns this
 */
proto.websockets.Video.prototype.setDatasetId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string filename = 3;
 * @return {string}
 */
proto.websockets.Video.prototype.getFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.Video} returns this
 */
proto.websockets.Video.prototype.setFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double progress = 5;
 * @return {number}
 */
proto.websockets.Video.prototype.getProgress = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.websockets.Video} returns this
 */
proto.websockets.Video.prototype.setProgress = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional uint64 duration_ms = 6;
 * @return {number}
 */
proto.websockets.Video.prototype.getDurationMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.websockets.Video} returns this
 */
proto.websockets.Video.prototype.setDurationMs = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string status = 7;
 * @return {string}
 */
proto.websockets.Video.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.Video} returns this
 */
proto.websockets.Video.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional VideoSegment segment = 8;
 * @return {?proto.websockets.VideoSegment}
 */
proto.websockets.Video.prototype.getSegment = function() {
  return /** @type{?proto.websockets.VideoSegment} */ (
    jspb.Message.getWrapperField(this, proto.websockets.VideoSegment, 8));
};


/**
 * @param {?proto.websockets.VideoSegment|undefined} value
 * @return {!proto.websockets.Video} returns this
*/
proto.websockets.Video.prototype.setSegment = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.websockets.Video} returns this
 */
proto.websockets.Video.prototype.clearSegment = function() {
  return this.setSegment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.websockets.Video.prototype.hasSegment = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated PredictedSegment prediction = 9;
 * @return {!Array<!proto.websockets.PredictedSegment>}
 */
proto.websockets.Video.prototype.getPredictionList = function() {
  return /** @type{!Array<!proto.websockets.PredictedSegment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.websockets.PredictedSegment, 9));
};


/**
 * @param {!Array<!proto.websockets.PredictedSegment>} value
 * @return {!proto.websockets.Video} returns this
*/
proto.websockets.Video.prototype.setPredictionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.websockets.PredictedSegment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.websockets.PredictedSegment}
 */
proto.websockets.Video.prototype.addPrediction = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.websockets.PredictedSegment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.websockets.Video} returns this
 */
proto.websockets.Video.prototype.clearPredictionList = function() {
  return this.setPredictionList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.websockets.VideoSegment.prototype.toObject = function(opt_includeInstance) {
  return proto.websockets.VideoSegment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.websockets.VideoSegment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.VideoSegment.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.websockets.VideoSegment}
 */
proto.websockets.VideoSegment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.websockets.VideoSegment;
  return proto.websockets.VideoSegment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.websockets.VideoSegment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.websockets.VideoSegment}
 */
proto.websockets.VideoSegment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.websockets.VideoSegment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.websockets.VideoSegment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.websockets.VideoSegment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.VideoSegment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.websockets.VideoSegment.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.VideoSegment} returns this
 */
proto.websockets.VideoSegment.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.websockets.PredictedSegment.prototype.toObject = function(opt_includeInstance) {
  return proto.websockets.PredictedSegment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.websockets.PredictedSegment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.PredictedSegment.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    activityTypeId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    startTimeMs: jspb.Message.getFieldWithDefault(msg, 3, 0),
    endTimeMs: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.websockets.PredictedSegment}
 */
proto.websockets.PredictedSegment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.websockets.PredictedSegment;
  return proto.websockets.PredictedSegment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.websockets.PredictedSegment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.websockets.PredictedSegment}
 */
proto.websockets.PredictedSegment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityTypeId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setStartTimeMs(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setEndTimeMs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.websockets.PredictedSegment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.websockets.PredictedSegment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.websockets.PredictedSegment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.PredictedSegment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getActivityTypeId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStartTimeMs();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getEndTimeMs();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.websockets.PredictedSegment.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.PredictedSegment} returns this
 */
proto.websockets.PredictedSegment.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string activity_type_id = 2;
 * @return {string}
 */
proto.websockets.PredictedSegment.prototype.getActivityTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.PredictedSegment} returns this
 */
proto.websockets.PredictedSegment.prototype.setActivityTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint64 start_time_ms = 3;
 * @return {number}
 */
proto.websockets.PredictedSegment.prototype.getStartTimeMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.websockets.PredictedSegment} returns this
 */
proto.websockets.PredictedSegment.prototype.setStartTimeMs = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 end_time_ms = 4;
 * @return {number}
 */
proto.websockets.PredictedSegment.prototype.getEndTimeMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.websockets.PredictedSegment} returns this
 */
proto.websockets.PredictedSegment.prototype.setEndTimeMs = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.websockets.Lock.prototype.toObject = function(opt_includeInstance) {
  return proto.websockets.Lock.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.websockets.Lock} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.Lock.toObject = function(includeInstance, msg) {
  var f, obj = {
    guid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    owner: jspb.Message.getFieldWithDefault(msg, 3, ""),
    subject: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.websockets.Lock}
 */
proto.websockets.Lock.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.websockets.Lock;
  return proto.websockets.Lock.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.websockets.Lock} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.websockets.Lock}
 */
proto.websockets.Lock.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGuid(value);
      break;
    case 2:
      var value = /** @type {!proto.websockets.LockStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwner(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.websockets.Lock.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.websockets.Lock.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.websockets.Lock} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.Lock.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getOwner();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSubject();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string guid = 1;
 * @return {string}
 */
proto.websockets.Lock.prototype.getGuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.Lock} returns this
 */
proto.websockets.Lock.prototype.setGuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional LockStatus status = 2;
 * @return {!proto.websockets.LockStatus}
 */
proto.websockets.Lock.prototype.getStatus = function() {
  return /** @type {!proto.websockets.LockStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.websockets.LockStatus} value
 * @return {!proto.websockets.Lock} returns this
 */
proto.websockets.Lock.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string owner = 3;
 * @return {string}
 */
proto.websockets.Lock.prototype.getOwner = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.Lock} returns this
 */
proto.websockets.Lock.prototype.setOwner = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string subject = 4;
 * @return {string}
 */
proto.websockets.Lock.prototype.getSubject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.Lock} returns this
 */
proto.websockets.Lock.prototype.setSubject = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.websockets.Label.prototype.toObject = function(opt_includeInstance) {
  return proto.websockets.Label.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.websockets.Label} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.Label.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.websockets.Label}
 */
proto.websockets.Label.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.websockets.Label;
  return proto.websockets.Label.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.websockets.Label} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.websockets.Label}
 */
proto.websockets.Label.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.websockets.Label.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.websockets.Label.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.websockets.Label} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.Label.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.websockets.Label.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.Label} returns this
 */
proto.websockets.Label.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.websockets.Dataset.prototype.toObject = function(opt_includeInstance) {
  return proto.websockets.Dataset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.websockets.Dataset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.Dataset.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.websockets.Dataset}
 */
proto.websockets.Dataset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.websockets.Dataset;
  return proto.websockets.Dataset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.websockets.Dataset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.websockets.Dataset}
 */
proto.websockets.Dataset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.websockets.Dataset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.websockets.Dataset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.websockets.Dataset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.Dataset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.websockets.Dataset.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.Dataset} returns this
 */
proto.websockets.Dataset.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.websockets.Dataset.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.Dataset} returns this
 */
proto.websockets.Dataset.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.websockets.LabelClass.prototype.toObject = function(opt_includeInstance) {
  return proto.websockets.LabelClass.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.websockets.LabelClass} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.LabelClass.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    color: jspb.Message.getFieldWithDefault(msg, 3, ""),
    classType: jspb.Message.getFieldWithDefault(msg, 4, ""),
    order: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.websockets.LabelClass}
 */
proto.websockets.LabelClass.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.websockets.LabelClass;
  return proto.websockets.LabelClass.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.websockets.LabelClass} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.websockets.LabelClass}
 */
proto.websockets.LabelClass.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setClassType(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.websockets.LabelClass.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.websockets.LabelClass.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.websockets.LabelClass} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.LabelClass.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getClassType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.websockets.LabelClass.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.LabelClass} returns this
 */
proto.websockets.LabelClass.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.websockets.LabelClass.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.LabelClass} returns this
 */
proto.websockets.LabelClass.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string color = 3;
 * @return {string}
 */
proto.websockets.LabelClass.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.LabelClass} returns this
 */
proto.websockets.LabelClass.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string class_type = 4;
 * @return {string}
 */
proto.websockets.LabelClass.prototype.getClassType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.LabelClass} returns this
 */
proto.websockets.LabelClass.prototype.setClassType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional double order = 5;
 * @return {number}
 */
proto.websockets.LabelClass.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.websockets.LabelClass} returns this
 */
proto.websockets.LabelClass.prototype.setOrder = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.websockets.Model.prototype.toObject = function(opt_includeInstance) {
  return proto.websockets.Model.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.websockets.Model} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.Model.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    family: jspb.Message.getFieldWithDefault(msg, 2, 0),
    progress: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    existingObjects: jspb.Message.getFieldWithDefault(msg, 4, 0),
    requiredObjects: jspb.Message.getFieldWithDefault(msg, 5, 0),
    finishHimActivation: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    origin: jspb.Message.getFieldWithDefault(msg, 7, ""),
    isfirsttraining: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    useie: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.websockets.Model}
 */
proto.websockets.Model.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.websockets.Model;
  return proto.websockets.Model.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.websockets.Model} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.websockets.Model}
 */
proto.websockets.Model.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.websockets.ModelFamily} */ (reader.readEnum());
      msg.setFamily(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setProgress(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setExistingObjects(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRequiredObjects(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFinishHimActivation(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrigin(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsfirsttraining(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseie(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.websockets.Model.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.websockets.Model.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.websockets.Model} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.Model.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getFamily();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getProgress();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getExistingObjects();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getRequiredObjects();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getFinishHimActivation();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getOrigin();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getIsfirsttraining();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getUseie();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.websockets.Model.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.websockets.Model} returns this
 */
proto.websockets.Model.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional ModelFamily family = 2;
 * @return {!proto.websockets.ModelFamily}
 */
proto.websockets.Model.prototype.getFamily = function() {
  return /** @type {!proto.websockets.ModelFamily} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.websockets.ModelFamily} value
 * @return {!proto.websockets.Model} returns this
 */
proto.websockets.Model.prototype.setFamily = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional double progress = 3;
 * @return {number}
 */
proto.websockets.Model.prototype.getProgress = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.websockets.Model} returns this
 */
proto.websockets.Model.prototype.setProgress = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional uint32 existing_objects = 4;
 * @return {number}
 */
proto.websockets.Model.prototype.getExistingObjects = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.websockets.Model} returns this
 */
proto.websockets.Model.prototype.setExistingObjects = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 required_objects = 5;
 * @return {number}
 */
proto.websockets.Model.prototype.getRequiredObjects = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.websockets.Model} returns this
 */
proto.websockets.Model.prototype.setRequiredObjects = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bool finish_him_activation = 6;
 * @return {boolean}
 */
proto.websockets.Model.prototype.getFinishHimActivation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.websockets.Model} returns this
 */
proto.websockets.Model.prototype.setFinishHimActivation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string origin = 7;
 * @return {string}
 */
proto.websockets.Model.prototype.getOrigin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.Model} returns this
 */
proto.websockets.Model.prototype.setOrigin = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool isFirstTraining = 8;
 * @return {boolean}
 */
proto.websockets.Model.prototype.getIsfirsttraining = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.websockets.Model} returns this
 */
proto.websockets.Model.prototype.setIsfirsttraining = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool useIE = 9;
 * @return {boolean}
 */
proto.websockets.Model.prototype.getUseie = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.websockets.Model} returns this
 */
proto.websockets.Model.prototype.setUseie = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.websockets.Job.prototype.toObject = function(opt_includeInstance) {
  return proto.websockets.Job.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.websockets.Job} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.Job.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    family: jspb.Message.getFieldWithDefault(msg, 2, 0),
    progress: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.websockets.Job}
 */
proto.websockets.Job.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.websockets.Job;
  return proto.websockets.Job.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.websockets.Job} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.websockets.Job}
 */
proto.websockets.Job.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.websockets.JobFamily} */ (reader.readEnum());
      msg.setFamily(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setProgress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.websockets.Job.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.websockets.Job.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.websockets.Job} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.Job.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFamily();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getProgress();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.websockets.Job.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.Job} returns this
 */
proto.websockets.Job.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional JobFamily family = 2;
 * @return {!proto.websockets.JobFamily}
 */
proto.websockets.Job.prototype.getFamily = function() {
  return /** @type {!proto.websockets.JobFamily} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.websockets.JobFamily} value
 * @return {!proto.websockets.Job} returns this
 */
proto.websockets.Job.prototype.setFamily = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional double progress = 3;
 * @return {number}
 */
proto.websockets.Job.prototype.getProgress = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.websockets.Job} returns this
 */
proto.websockets.Job.prototype.setProgress = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.websockets.Project.prototype.toObject = function(opt_includeInstance) {
  return proto.websockets.Project.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.websockets.Project} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.Project.toObject = function(includeInstance, msg) {
  var f, obj = {
    labelingType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    creditsLeft: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    id: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.websockets.Project}
 */
proto.websockets.Project.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.websockets.Project;
  return proto.websockets.Project.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.websockets.Project} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.websockets.Project}
 */
proto.websockets.Project.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.websockets.ProjectLabelingType} */ (reader.readEnum());
      msg.setLabelingType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCreditsLeft(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.websockets.Project.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.websockets.Project.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.websockets.Project} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.Project.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabelingType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCreditsLeft();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional ProjectLabelingType labeling_type = 1;
 * @return {!proto.websockets.ProjectLabelingType}
 */
proto.websockets.Project.prototype.getLabelingType = function() {
  return /** @type {!proto.websockets.ProjectLabelingType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.websockets.ProjectLabelingType} value
 * @return {!proto.websockets.Project} returns this
 */
proto.websockets.Project.prototype.setLabelingType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional double credits_left = 2;
 * @return {number}
 */
proto.websockets.Project.prototype.getCreditsLeft = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.websockets.Project} returns this
 */
proto.websockets.Project.prototype.setCreditsLeft = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string id = 3;
 * @return {string}
 */
proto.websockets.Project.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.Project} returns this
 */
proto.websockets.Project.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.websockets.Attribute.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.websockets.Attribute.prototype.toObject = function(opt_includeInstance) {
  return proto.websockets.Attribute.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.websockets.Attribute} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.Attribute.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    order: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    lovList: jspb.Message.toObjectList(msg.getLovList(),
    proto.websockets.AttributeLOV.toObject, includeInstance),
    description: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.websockets.Attribute}
 */
proto.websockets.Attribute.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.websockets.Attribute;
  return proto.websockets.Attribute.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.websockets.Attribute} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.websockets.Attribute}
 */
proto.websockets.Attribute.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOrder(value);
      break;
    case 5:
      var value = new proto.websockets.AttributeLOV;
      reader.readMessage(value,proto.websockets.AttributeLOV.deserializeBinaryFromReader);
      msg.addLov(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.websockets.Attribute.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.websockets.Attribute.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.websockets.Attribute} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.Attribute.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getLovList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.websockets.AttributeLOV.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.websockets.Attribute.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.Attribute} returns this
 */
proto.websockets.Attribute.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.websockets.Attribute.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.Attribute} returns this
 */
proto.websockets.Attribute.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.websockets.Attribute.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.Attribute} returns this
 */
proto.websockets.Attribute.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double order = 4;
 * @return {number}
 */
proto.websockets.Attribute.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.websockets.Attribute} returns this
 */
proto.websockets.Attribute.prototype.setOrder = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * repeated AttributeLOV lov = 5;
 * @return {!Array<!proto.websockets.AttributeLOV>}
 */
proto.websockets.Attribute.prototype.getLovList = function() {
  return /** @type{!Array<!proto.websockets.AttributeLOV>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.websockets.AttributeLOV, 5));
};


/**
 * @param {!Array<!proto.websockets.AttributeLOV>} value
 * @return {!proto.websockets.Attribute} returns this
*/
proto.websockets.Attribute.prototype.setLovList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.websockets.AttributeLOV=} opt_value
 * @param {number=} opt_index
 * @return {!proto.websockets.AttributeLOV}
 */
proto.websockets.Attribute.prototype.addLov = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.websockets.AttributeLOV, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.websockets.Attribute} returns this
 */
proto.websockets.Attribute.prototype.clearLovList = function() {
  return this.setLovList([]);
};


/**
 * optional string description = 6;
 * @return {string}
 */
proto.websockets.Attribute.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.Attribute} returns this
 */
proto.websockets.Attribute.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.websockets.AttributeClass.prototype.toObject = function(opt_includeInstance) {
  return proto.websockets.AttributeClass.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.websockets.AttributeClass} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.AttributeClass.toObject = function(includeInstance, msg) {
  var f, obj = {
    attributeId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    classId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    attributeOrder: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    classOrder: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.websockets.AttributeClass}
 */
proto.websockets.AttributeClass.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.websockets.AttributeClass;
  return proto.websockets.AttributeClass.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.websockets.AttributeClass} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.websockets.AttributeClass}
 */
proto.websockets.AttributeClass.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttributeId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setClassId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAttributeOrder(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setClassOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.websockets.AttributeClass.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.websockets.AttributeClass.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.websockets.AttributeClass} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.AttributeClass.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAttributeId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getClassId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAttributeOrder();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getClassOrder();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional string attribute_id = 1;
 * @return {string}
 */
proto.websockets.AttributeClass.prototype.getAttributeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.AttributeClass} returns this
 */
proto.websockets.AttributeClass.prototype.setAttributeId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string class_id = 2;
 * @return {string}
 */
proto.websockets.AttributeClass.prototype.getClassId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.AttributeClass} returns this
 */
proto.websockets.AttributeClass.prototype.setClassId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double attribute_order = 3;
 * @return {number}
 */
proto.websockets.AttributeClass.prototype.getAttributeOrder = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.websockets.AttributeClass} returns this
 */
proto.websockets.AttributeClass.prototype.setAttributeOrder = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double class_order = 4;
 * @return {number}
 */
proto.websockets.AttributeClass.prototype.getClassOrder = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.websockets.AttributeClass} returns this
 */
proto.websockets.AttributeClass.prototype.setClassOrder = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.websockets.AttributeLOV.prototype.toObject = function(opt_includeInstance) {
  return proto.websockets.AttributeLOV.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.websockets.AttributeLOV} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.AttributeLOV.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.websockets.AttributeLOV}
 */
proto.websockets.AttributeLOV.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.websockets.AttributeLOV;
  return proto.websockets.AttributeLOV.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.websockets.AttributeLOV} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.websockets.AttributeLOV}
 */
proto.websockets.AttributeLOV.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.websockets.AttributeLOV.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.websockets.AttributeLOV.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.websockets.AttributeLOV} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.AttributeLOV.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.websockets.AttributeLOV.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.AttributeLOV} returns this
 */
proto.websockets.AttributeLOV.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.websockets.AttributeLOV.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.AttributeLOV} returns this
 */
proto.websockets.AttributeLOV.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.websockets.Export.prototype.toObject = function(opt_includeInstance) {
  return proto.websockets.Export.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.websockets.Export} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.Export.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, ""),
    step: jspb.Message.getFieldWithDefault(msg, 3, ""),
    exportedFileUrl: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.websockets.Export}
 */
proto.websockets.Export.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.websockets.Export;
  return proto.websockets.Export.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.websockets.Export} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.websockets.Export}
 */
proto.websockets.Export.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStep(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setExportedFileUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.websockets.Export.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.websockets.Export.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.websockets.Export} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.Export.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStep();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getExportedFileUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.websockets.Export.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.Export} returns this
 */
proto.websockets.Export.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string status = 2;
 * @return {string}
 */
proto.websockets.Export.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.Export} returns this
 */
proto.websockets.Export.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string step = 3;
 * @return {string}
 */
proto.websockets.Export.prototype.getStep = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.Export} returns this
 */
proto.websockets.Export.prototype.setStep = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string exported_file_url = 4;
 * @return {string}
 */
proto.websockets.Export.prototype.getExportedFileUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.Export} returns this
 */
proto.websockets.Export.prototype.setExportedFileUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.websockets.EFRun.prototype.toObject = function(opt_includeInstance) {
  return proto.websockets.EFRun.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.websockets.EFRun} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.EFRun.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    progress: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    eta: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.websockets.EFRun}
 */
proto.websockets.EFRun.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.websockets.EFRun;
  return proto.websockets.EFRun.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.websockets.EFRun} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.websockets.EFRun}
 */
proto.websockets.EFRun.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setProgress(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEta(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.websockets.EFRun.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.websockets.EFRun.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.websockets.EFRun} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.EFRun.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProgress();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getEta();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.websockets.EFRun.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.EFRun} returns this
 */
proto.websockets.EFRun.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double progress = 2;
 * @return {number}
 */
proto.websockets.EFRun.prototype.getProgress = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.websockets.EFRun} returns this
 */
proto.websockets.EFRun.prototype.setProgress = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional uint32 eta = 3;
 * @return {number}
 */
proto.websockets.EFRun.prototype.getEta = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.websockets.EFRun} returns this
 */
proto.websockets.EFRun.prototype.setEta = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.websockets.UserJob.prototype.toObject = function(opt_includeInstance) {
  return proto.websockets.UserJob.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.websockets.UserJob} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.UserJob.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    progress: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.websockets.UserJob}
 */
proto.websockets.UserJob.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.websockets.UserJob;
  return proto.websockets.UserJob.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.websockets.UserJob} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.websockets.UserJob}
 */
proto.websockets.UserJob.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setProgress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.websockets.UserJob.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.websockets.UserJob.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.websockets.UserJob} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.UserJob.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProgress();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.websockets.UserJob.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.UserJob} returns this
 */
proto.websockets.UserJob.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double progress = 2;
 * @return {number}
 */
proto.websockets.UserJob.prototype.getProgress = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.websockets.UserJob} returns this
 */
proto.websockets.UserJob.prototype.setProgress = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.websockets.Import.prototype.toObject = function(opt_includeInstance) {
  return proto.websockets.Import.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.websockets.Import} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.Import.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fileId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, ""),
    step: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.websockets.Import}
 */
proto.websockets.Import.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.websockets.Import;
  return proto.websockets.Import.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.websockets.Import} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.websockets.Import}
 */
proto.websockets.Import.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStep(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.websockets.Import.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.websockets.Import.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.websockets.Import} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.Import.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFileId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStep();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.websockets.Import.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.Import} returns this
 */
proto.websockets.Import.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string file_id = 2;
 * @return {string}
 */
proto.websockets.Import.prototype.getFileId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.Import} returns this
 */
proto.websockets.Import.prototype.setFileId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string status = 3;
 * @return {string}
 */
proto.websockets.Import.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.Import} returns this
 */
proto.websockets.Import.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string step = 4;
 * @return {string}
 */
proto.websockets.Import.prototype.getStep = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.Import} returns this
 */
proto.websockets.Import.prototype.setStep = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.websockets.Aldi.prototype.toObject = function(opt_includeInstance) {
  return proto.websockets.Aldi.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.websockets.Aldi} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.Aldi.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    heuristic: (f = msg.getHeuristic()) && proto.websockets.Heuristic.toObject(includeInstance, f),
    modelFamily: jspb.Message.getFieldWithDefault(msg, 3, ""),
    progress: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    name: jspb.Message.getFieldWithDefault(msg, 5, ""),
    origin: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.websockets.Aldi}
 */
proto.websockets.Aldi.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.websockets.Aldi;
  return proto.websockets.Aldi.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.websockets.Aldi} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.websockets.Aldi}
 */
proto.websockets.Aldi.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.websockets.Heuristic;
      reader.readMessage(value,proto.websockets.Heuristic.deserializeBinaryFromReader);
      msg.setHeuristic(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelFamily(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setProgress(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrigin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.websockets.Aldi.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.websockets.Aldi.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.websockets.Aldi} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.Aldi.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHeuristic();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.websockets.Heuristic.serializeBinaryToWriter
    );
  }
  f = message.getModelFamily();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProgress();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOrigin();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.websockets.Aldi.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.Aldi} returns this
 */
proto.websockets.Aldi.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Heuristic heuristic = 2;
 * @return {?proto.websockets.Heuristic}
 */
proto.websockets.Aldi.prototype.getHeuristic = function() {
  return /** @type{?proto.websockets.Heuristic} */ (
    jspb.Message.getWrapperField(this, proto.websockets.Heuristic, 2));
};


/**
 * @param {?proto.websockets.Heuristic|undefined} value
 * @return {!proto.websockets.Aldi} returns this
*/
proto.websockets.Aldi.prototype.setHeuristic = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.websockets.Aldi} returns this
 */
proto.websockets.Aldi.prototype.clearHeuristic = function() {
  return this.setHeuristic(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.websockets.Aldi.prototype.hasHeuristic = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string model_family = 3;
 * @return {string}
 */
proto.websockets.Aldi.prototype.getModelFamily = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.Aldi} returns this
 */
proto.websockets.Aldi.prototype.setModelFamily = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double progress = 4;
 * @return {number}
 */
proto.websockets.Aldi.prototype.getProgress = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.websockets.Aldi} returns this
 */
proto.websockets.Aldi.prototype.setProgress = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional string name = 5;
 * @return {string}
 */
proto.websockets.Aldi.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.Aldi} returns this
 */
proto.websockets.Aldi.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string origin = 6;
 * @return {string}
 */
proto.websockets.Aldi.prototype.getOrigin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.Aldi} returns this
 */
proto.websockets.Aldi.prototype.setOrigin = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.websockets.Heuristic.prototype.toObject = function(opt_includeInstance) {
  return proto.websockets.Heuristic.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.websockets.Heuristic} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.Heuristic.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.websockets.Heuristic}
 */
proto.websockets.Heuristic.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.websockets.Heuristic;
  return proto.websockets.Heuristic.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.websockets.Heuristic} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.websockets.Heuristic}
 */
proto.websockets.Heuristic.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.websockets.Heuristic.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.websockets.Heuristic.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.websockets.Heuristic} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.websockets.Heuristic.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.websockets.Heuristic.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.Heuristic} returns this
 */
proto.websockets.Heuristic.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.websockets.Heuristic.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.websockets.Heuristic} returns this
 */
proto.websockets.Heuristic.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.websockets.NotificationEvent = {
  UNKNOWN_EVENT: 0,
  NOOP: 1,
  SUBJECT_SELECTED: 5,
  SUBJECT_SELECTION_FAILED: 6,
  LABEL_UPDATED: 11,
  LABEL_DELETED: 12,
  PROJECT_UPDATED: 22,
  PROJECT_DELETED: 23,
  PROJECT_WORKSPACE_CHANGED: 25,
  LABEL_CLASS_UPDATED: 30,
  LABEL_CLASS_MULTIPLE_UPDATED: 31,
  LABEL_CLASS_DELETED: 32,
  DATASET_DELETED: 40,
  DATASET_MULTIPLE_UPDATED: 41,
  IMAGE_UPDATED: 50,
  MODEL_LABELS_TO_START_TRAIN_PROGRESS: 60,
  MODEL_TRAINING_STARTED: 61,
  MODEL_TRAINING_PROGRESS: 62,
  MODEL_TRAINED: 63,
  MODEL_LOADING_STARTED: 64,
  MODEL_LOADED: 65,
  MODEL_LOADING_FAILED: 66,
  MODEL_TRAINING_FAILED: 67,
  MODEL_TRAINING_QUEUED: 68,
  JOB_RUNNING: 70,
  JOB_ENDED: 71,
  JOB_FAILED: 72,
  JOB_CANCELED: 73,
  TAG_CLASS_ADDED: 80,
  TAG_CLASS_DELETED: 81,
  TAG_CLASS_MODIFIED: 82,
  ATTRIBUTE_UPDATED: 91,
  ATTRIBUTE_DELETED: 92,
  ATTRIBUTE_CLASS_UPDATED: 100,
  ATTRIBUTE_CLASS_DELETED: 101,
  NOTIFICATION_SENT: 120,
  EF_RUN_FAILED: 130,
  EF_RUN_TRAINING_STARTED: 131,
  EF_RUN_TRAINING_PROGRESS: 132,
  EF_RUN_TRAINED: 133,
  EF_RUN_PREDICTING_STARTED: 134,
  EF_RUN_PREDICTING_PROGRESS: 135,
  EF_RUN_PREDICTED: 136,
  JOB_PROGRESS: 140,
  VIDEO_ACTIVITY_FAILED: 155,
  VIDEO_ACTIVITY_COMPLETED: 156,
  VIDEO_ACTIVITY_PAUSED: 157,
  VIDEO_ACTIVITY_PROGRESS: 158,
  VIDEO_ACTIVITY_PREDICTED: 159,
  VIDEO_UPDATED: 160,
  VIDEO_PROGRESS: 161,
  VIDEO_PROCESSED: 162,
  VIDEO_THUMBNAIL_GENERATION_COMPLETED: 163,
  VIDEO_SEGMENT_PROCESSED: 164,
  VIDEO_STATUS_UPDATED: 165,
  DATASPLIT_CREATED: 170,
  DATASPLIT_CREATION_FAILED: 171,
  DATASPLIT_PREPARING_FOLDS_STARTED: 172,
  LOCKS_UPDATED: 180,
  TRAINING_STATUS_UPDATE: 190,
  TRAINING_METRICS_UPDATE: 191,
  TRAINING_GPU_STATS_UPDATE: 192,
  TRAINING_INFERENCES_UPDATE: 193,
  IMPORT_FILE_STATUS: 200,
  IMPORT_SESSION_STATUS: 201,
  EXPERIMENT_VALIDATION_STARTED: 210,
  EXPERIMENT_VALIDATION_SUCCESSFUL: 211,
  EXPERIMENT_VALIDATION_FAILED: 212,
  ALDI_RANKING_STARTED: 310,
  ALDI_RANKING_SUCCESSFUL: 311,
  ALDI_RANKING_FAILED: 312,
  ALDI_RANKING_PROGRESS: 313,
  VIDEO_CUT_SUCCESSFUL: 410,
  VIDEO_CUT_FAILED: 411,
  FRAME_IMPORTS_SUCCESSFUL: 510,
  FRAME_IMPORTS_FAILED: 511
};

/**
 * @enum {number}
 */
proto.websockets.LockStatus = {
  NOT_SPECIFIED: 0,
  UNLOCKED: 1,
  LOCKED: 2
};

/**
 * @enum {number}
 */
proto.websockets.JobFamily = {
  UNKNOWN_JOB_FAMILY: 0,
  FINISH_HIM: 1,
  REPORTER: 2,
  EXPLAINABILITY: 3
};

/**
 * @enum {number}
 */
proto.websockets.ModelFamily = {
  UNKNOWN_FAMILY: 0,
  CLASSIFIER: 1,
  DETECTOR: 2,
  SEGMENTOR: 3,
  DEXTR: 4,
  SEMANTIC_SEGMENTOR: 5,
  IMAGE_TAGGER: 6,
  GRABCUT: 7,
  BOX2MASK: 8,
  ATTRIBUTER: 10,
  ACTIVITY_RECOGNIZER: 11,
  ATOM: 12,
  KEYPOINT_DETECTOR: 13,
  TXT2MASK: 14
};

/**
 * @enum {number}
 */
proto.websockets.ProjectLabelingType = {
  UNKNOWN_LABELING_TYPE: 0,
  OBJECT_DETECTION: 1,
  OBJECT_SEGMENTATION: 2,
  SEMANTIC_SEGMENTATION: 3,
  IMAGE_TAGGING: 4,
  ACTIVITY_RECOGNITION: 5
};

/**
 * @enum {number}
 */
proto.websockets.TrainStatus = {
  UNKNOWN_STATUS: 0,
  SCHEDULED: 1,
  TRAINING: 2,
  SUCCEEDED: 3,
  FAILED: 4
};

goog.object.extend(exports, proto.websockets);
