import { storeObject } from '../state/core/imageView/imageView.helpers';
import { getResultFromWorker, RLE_WORKER } from '../../workers/workerManager';
import { METHOD_RLE_TO_IMAGE_DATA } from '../../workers/rle/constants';
import { Label } from '../../api/domainModels/imageLabel';

export function* getMaskDataUpdate(label: {
  id: string;
  bbox: number[] | null;
  mask: Label['mask'];
}) {
  if (!label.bbox) {
    throw new Error('Unable to process mask');
  }

  const [x1, y1, x2, y2] = label.bbox;
  const data = label.mask;
  const width = x2 - x1;
  const height = y2 - y1;

  if (!data || !width || !height) {
    throw new Error('Unable to process mask');
  }

  const imageData = yield* getResultFromWorker(RLE_WORKER, {
    method: METHOD_RLE_TO_IMAGE_DATA,
    rle: {
      data,
      width,
      height,
    },
  });
  const imageDataId = storeObject(imageData);

  return {
    id: label.id,
    imageDataId,
    imageData,
  };
}
