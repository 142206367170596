import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';

import {
  ModelStatus,
  modelStatusChecks,
  statusCheck,
  ToolModel,
} from '../models/modelStatus.constants';
import {
  LoadingState,
  loadingStateBuilder,
} from '../../../../../utils/loadingState';
import { Bbox } from '../../../../../../@types/imageView/types';

export enum TextPromptMode {
  Classes = 'classes',
  Custom = 'custom',
}
type TextPromptItem = {
  storedObjectId: number;
  labelId: string;
};
type TextPromptState = {
  model: ToolModel;
  data: EntityState<TextPromptItem> & {
    loadingState: LoadingState;
  };
  threshold: number;
  useViewport: boolean;
  mode: TextPromptMode;
  useSemSeg: boolean;
  classes: string[];
  textInput: string;
  visibleAreaBbox: Bbox;
};
export const adapter = createEntityAdapter<TextPromptItem>({
  selectId: (object) => object.labelId,
});
const initialState: TextPromptState = {
  model: {
    id: null,
    loadingState: loadingStateBuilder.initial(),
    status: null,
    progress: null,
  },
  data: adapter.getInitialState({
    loadingState: loadingStateBuilder.initial(),
  }),
  threshold: 50,
  useViewport: false,
  mode: TextPromptMode.Classes,
  useSemSeg: false,
  classes: [],
  textInput: '',
  visibleAreaBbox: [0, 0, 0, 0],
};

const { actions, reducer: textPromptReducer } = createSlice({
  name: 'imageViewTextPrompt',
  initialState,
  reducers: {
    loadTextPromptModel(_state, _action: PayloadAction<string>) {},
    loadTextPromptModelError(state, action: PayloadAction<string>) {
      state.model.loadingState = loadingStateBuilder.failure(action.payload);
    },
    loadTextPromptModelStart(state, action: PayloadAction<string>) {
      state.model.loadingState = loadingStateBuilder.inProgress(action.payload);
    },
    loadTextPromptModelSuccess(state) {
      state.model.loadingState = loadingStateBuilder.success();
    },
    updateTextPromptModel(
      _state,
      _action: PayloadAction<{
        status: ModelStatus;
        progress: number;
        id: number;
        modelUseIE?: boolean;
      }>,
    ) {},
    updateTextPromptModelSuccess(
      state,
      action: PayloadAction<{
        id: number | null;
        status: ModelStatus;
        progress: number | null;
      }>,
    ) {
      const { id, status, progress } = action.payload;

      state.model.id = modelStatusChecks.isLoaded(status) ? id : state.model.id;
      state.model.status = statusCheck(status, state.model.status);
      state.model.progress = progress || 0;
    },
    updateTextPromptModelShowDot(state, action: PayloadAction<boolean>) {
      const showDot = action.payload;

      state.model = {
        ...state.model,
        showDot,
      };
    },
    setThreshold(state, _action: PayloadAction<{ threshold: number }>) {},
    loadDetections() {},
    loadDetectionsStart(state) {
      state.data.loadingState = loadingStateBuilder.inProgress();
      adapter.removeAll(state.data);
    },
    loadTextPromptFailure(state, action: PayloadAction<{ message: string }>) {
      state.data.loadingState = loadingStateBuilder.failure(
        action.payload.message,
      );
    },
    loadTextPromptSuccess(state, action: PayloadAction<TextPromptItem[]>) {
      state.data.loadingState = loadingStateBuilder.success();
      adapter.setAll(state.data, action.payload);
    },
    addLabels() {},
    addLabel(state, _action: PayloadAction<{ storedObjectId: number }>) {},
    confirmAddLabel(state, action: PayloadAction<{ labelId: string }>) {
      adapter.removeOne(state.data, action.payload.labelId);
    },
    confirmThreshold(state, action: PayloadAction<{ threshold: number }>) {
      state.threshold = action.payload.threshold;
    },
    resetTextPromptData(state) {
      state.data = initialState.data;
    },
    resetTextPrompt(state) {
      return { ...initialState, model: state.model };
    },
    setTextPromptMode(state, action: PayloadAction<{ mode: TextPromptMode }>) {
      state.mode = action.payload.mode;
      state.classes = initialState.classes;
      state.textInput = initialState.textInput;
      state.data = initialState.data;
    },
    toggleUseSemSeg(state) {
      state.useSemSeg = !state.useSemSeg;
    },
    toggleUseViewport(state) {
      state.useViewport = !state.useViewport;
    },
    setTextInput(
      state,
      action: PayloadAction<{ textInput: string; visibleAreaBbox: Bbox }>,
    ) {
      state.textInput = action.payload.textInput;
      state.visibleAreaBbox = action.payload.visibleAreaBbox;
    },
    setClassesInput(
      state,
      action: PayloadAction<{ classes: string[]; visibleAreaBbox: Bbox }>,
    ) {
      state.classes = action.payload.classes;
      state.visibleAreaBbox = action.payload.visibleAreaBbox;
    },
    rejectSelected(state, action: PayloadAction<{ labelId: string }>) {
      adapter.removeOne(state.data, action.payload.labelId);
    },
    resetModel(state) {
      state.model = initialState.model;
    },
  },
});

export { textPromptReducer };

export const {
  addLabel,
  addLabels,
  confirmAddLabel,
  loadDetections,
  loadDetectionsStart,
  loadTextPromptModel,
  loadTextPromptModelError,
  loadTextPromptModelStart,
  loadTextPromptModelSuccess,
  loadTextPromptFailure,
  loadTextPromptSuccess,
  setThreshold,
  confirmThreshold,
  updateTextPromptModel,
  updateTextPromptModelShowDot,
  updateTextPromptModelSuccess,
  resetTextPromptData,
  resetTextPrompt,
  setTextPromptMode,
  toggleUseSemSeg,
  toggleUseViewport,
  setTextInput,
  setClassesInput,
  rejectSelected,
  resetModel,
} = actions;
