import { PROJECT_OWNER_ROLE_ID } from '../../redux/state/sections/editedProject/editedProject.constants';
import { ApiRole } from './role';
import { ApiProjectRoleParams } from '../schemas/projectRoles';

const fromBackend = ({ roleId, roleName, color }: ApiRole) => ({
  id: roleId,
  name: roleName,
  color,
  readOnly: roleId === PROJECT_OWNER_ROLE_ID,
});

export type ProjectRole = ReturnType<typeof fromBackend>;
export const editedProjectRoleDataMapper = {
  fromBackend,
};

export type ProjectRoleParams = ApiProjectRoleParams;
export type GenericRoleFormValues = ProjectRoleParams;
