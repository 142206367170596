import axios from 'axios';

import {
  ApiGetAdvancedOptionParams,
  ApiSaveAdvancedOptionParams,
} from '../schemas/advancedOptions';
import { API_URL } from '../../constants/paths';
import {
  AdvancedOption,
  AdvancedOptionApiSetting,
} from '../domainModels/advancedOptions';
import { apiWrapper } from '../utils';
import { headers, transformRequest, transformResponse } from '../helpers';

export const apiGetAdvancedOption = (params: ApiGetAdvancedOptionParams) =>
  apiWrapper(
    axios.request<AdvancedOption>({
      headers,
      transformRequest,
      transformResponse,
      url: `${API_URL}/project/${params.projectId}/project_setting/${params.optionKey}`,
    }),
  );

export const apiSaveAdvancedOption = (
  params: ApiSaveAdvancedOptionParams,
  data: AdvancedOptionApiSetting,
) =>
  apiWrapper(
    axios.request<AdvancedOption>({
      method: 'post',
      headers,
      transformRequest,
      transformResponse,
      url: `${API_URL}/project/${params.projectId}/project_setting/${params.optionKey}`,
      data,
    }),
  );
