/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LossPerTrainObject } from '../models/LossPerTrainObject';
import type { ModelFamilyStatus } from '../models/ModelFamilyStatus';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { OmitReadonly } from '../core/utils/OmitReadonly';

export class ModelService {

  /**
   * Return the list of statuses for every model family
   * @returns ModelFamilyStatus Successful read the status of each model
   * @throws ApiError
   */
  public static getV1ProjectsModelFamilies({
    projectId,
  }: {
    /**
     * Project ID
     */
    projectId: string,
  }): CancelablePromise<Array<ModelFamilyStatus>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/projects/{project_id}/model_families',
      path: {
        'project_id': projectId,
      },
    });
  }

  /**
   * Return the list of metric values
   * @returns LossPerTrainObject Successfully read a set of metrics for a particular model
   * @throws ApiError
   */
  public static getV1ProjectsModelFamiliesLossPerTrainObjects({
    projectId,
    modelFamily,
    metricName,
    keypointSchemaId,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Family of model
     */
    modelFamily: 'attributer' | 'classifier' | 'detector' | 'image-tagger' | 'segmentor' | 'semantic-segmentor' | 'activity-recognizer' | 'keypoint-detector',
    /**
     * Name of the metric to fetch
     */
    metricName?: string | null,
    /**
     * Keypoint schema ID if model family is keypoint detector
     */
    keypointSchemaId?: string | null,
  }): CancelablePromise<Array<LossPerTrainObject>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/projects/{project_id}/model_families/{model_family}/loss_per_train_objects',
      path: {
        'project_id': projectId,
        'model_family': modelFamily,
      },
      query: {
        'metric_name': metricName,
        'keypoint_schema_id': keypointSchemaId,
      },
    });
  }

}
