import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';

import { WidgetMetricsPerIteration } from '../../../../../../../api/domainModels/widgets';
import { Experiment } from '../../../../../../../api/domainModels/modelPlayground';
import {
  LoadingState,
  loadingStateBuilder,
} from '../../../../../../utils/loadingState';
import { setEditedProjectId } from '../../../../../sections/editedProject/project/project.slice';
import { RunMetric } from '../../../../../../../api/constants/widgets';

type MetricsState = EntityState<WidgetMetricsPerIteration> & {
  loadingState: LoadingState;
  availableMetrics: RunMetric[] | null;
};
type MetricFromWebsocket = {
  id: string; // experimentId
  title: RunMetric;
  data: {
    index: number;
    validationValue?: number;
    validationTimestamp?: string;
    trainValue?: number;
    trainTimestamp?: string;
  };
};

export const adapter = createEntityAdapter<WidgetMetricsPerIteration>({
  selectId: (item) => item.experimentId,
});

const initialState: MetricsState = adapter.getInitialState({
  availableMetrics: null,
  loadingState: loadingStateBuilder.initial(),
});

const { actions, reducer: metricsPerIterationReducer } = createSlice({
  name: 'widgets/metricsPerIteration',
  initialState,
  reducers: {
    loadMetricsPerIteration(state, _action: PayloadAction<Experiment['id'][]>) {
      state.loadingState = loadingStateBuilder.inProgress();
      adapter.setAll(state, []);
      state.availableMetrics = null;
    },
    reloadMetricsPerIteration(
      _state,
      _action: PayloadAction<Experiment['id'][]>,
    ) {},
    loadMetricsPerIterationFailure(state, action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.failure(action.payload);
    },
    loadMetricsPerIterationSuccess(
      state,
      action: PayloadAction<WidgetMetricsPerIteration[]>,
    ) {
      state.loadingState = loadingStateBuilder.success();
      const availableMetrics: RunMetric[] = [];
      action.payload.forEach((item) =>
        availableMetrics.push(...(Object.keys(item.metrics) as RunMetric[])),
      );
      state.availableMetrics = [
        ...new Set([...availableMetrics].sort((a, b) => a.localeCompare(b))),
      ];
      adapter.setAll(state, action.payload);
    },
    requestUpdateTrainingMetricFromWebsocket(
      _state,
      _action: PayloadAction<MetricFromWebsocket>,
    ) {},
    updateTrainingMetricFromWebsocket: {
      reducer(state, action: PayloadAction<MetricFromWebsocket>) {
        const { id, title, data } = action.payload;
        const metricsMap = state.entities;
        const metric = metricsMap[id];
        if (!metric) return;
        if (!state.availableMetrics) state.availableMetrics = [title];
        else if (!state.availableMetrics.find((metric) => metric === title)) {
          state.availableMetrics = [...state.availableMetrics, title];
        }

        let metricsToUpdate = metric?.metrics;
        if (!metricsToUpdate || Object.keys(metricsToUpdate).length === 0) {
          metricsToUpdate = { [title]: [] };
        }
        const metricToUpdate = metricsToUpdate[title] ?? [];
        const lastItem = metricToUpdate[metricToUpdate.length - 1];
        const lastItemIndexMatch = lastItem
          ? lastItem.index === data.index
          : false; // train and validate come in separately, to avoid more entries than indexes
        if (lastItemIndexMatch) {
          metricToUpdate.pop();
          metricToUpdate.push({ ...lastItem, ...data });
        } else {
          metricToUpdate.push(data);
        }
        metricsToUpdate[title] = metricToUpdate;

        const changes = {
          metrics: metricsToUpdate,
        };
        const update = { id, changes };

        adapter.updateOne(state, update);
      },
      prepare(payload) {
        return {
          payload,
          meta: {
            noLogging: true,
          },
        };
      },
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setEditedProjectId, () => initialState);
  },
});

export { metricsPerIterationReducer };
export const {
  loadMetricsPerIteration,
  loadMetricsPerIterationSuccess,
  loadMetricsPerIterationFailure,
  requestUpdateTrainingMetricFromWebsocket,
  reloadMetricsPerIteration,
  updateTrainingMetricFromWebsocket,
} = actions;
