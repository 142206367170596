import { createSelector } from '@reduxjs/toolkit';

import { isFreePackage } from '../../../../../constants/packageFeatures';
import { workspacesListAdapter } from './workspaces.slice';
import { projectListSelector } from '../projectList.selectors';
import { projectListDataSelector } from '../projects/projects.selectors';

export const workspacesListStateSelector = createSelector(
  projectListSelector,
  (projectList) => projectList.workspaces,
);
const selectors = workspacesListAdapter.getSelectors(
  workspacesListStateSelector,
);

export const workspacesListDataSelector = selectors.selectAll;
export const workspacesListMapSelector = selectors.selectEntities;
export const workspacesListByIdSelector = selectors.selectById;
export const workspacesListLoadingStateSelector = createSelector(
  workspacesListStateSelector,
  (state) => state.loadingState,
);
export const archiveWorkspaceLoadingStateSelector = createSelector(
  workspacesListStateSelector,
  (state) => state.archiveWorkspaceLoadingState,
);
export const workspacesListNonFreeWorkspacesSelector = createSelector(
  workspacesListDataSelector,
  (workspaces) =>
    workspaces.filter(({ subscription }) => !isFreePackage(subscription)),
);
export const workspacesListHasFreeWorkspaceSelector = createSelector(
  workspacesListDataSelector,
  (workspaces) =>
    !!workspaces.find(({ subscription }) => isFreePackage(subscription)),
);
export const workspacesListFreeWorkspaceIdSelector = createSelector(
  workspacesListStateSelector,
  (state) => state.freeWorkspace?.workspaceId,
);
export const workspacesListWorkspaceNameSelector = createSelector(
  [
    workspacesListDataSelector,
    (state: RootState, workspaceId: string) => workspaceId,
  ],
  (workspaces, workspaceId) =>
    workspaces.find((workspace) => workspace.workspaceId === workspaceId)
      ?.workspaceName || '',
);
export const workspacesListWorkspacesLoadedSelector = createSelector(
  workspacesListStateSelector,
  (state) => state.workspacesLoaded,
);

export const workspaceSearchStringSelector = createSelector(
  workspacesListStateSelector,
  (state) => state.workspaceSearchString,
);

const projectComparator = (a: any, b: any) => a.projectId - b.projectId;

const workspaceComparator = (a: any, b: any) => {
  if (a.name !== b.name) {
    return a.name - b.name;
  }

  return a.id - b.id;
};

export const workspaceDataSelector = createSelector(
  [projectListDataSelector, workspacesListDataSelector],
  (projectsData, workspacesData) => {
    if (projectsData instanceof Array && workspacesData.length > 0) {
      return workspacesData
        .sort(workspaceComparator)
        .map(
          ({
            workspaceId,
            workspaceName,
            description,
            backgroundColor,
            userRole,
            subscription,
            subscriptionTitle,
            parkedOn,
          }) => ({
            workspaceId,
            workspaceName,
            description,
            userRole,
            backgroundColor,
            subscription,
            subscriptionTitle,
            parkedOn,
            projects: projectsData
              .filter((project) => project.workspaceId === workspaceId)
              .sort(projectComparator),
          }),
        );
    }

    return [];
  },
);

export const sharedProjectsSelector = createSelector(
  projectListDataSelector,
  workspacesListDataSelector,
  (projects, workspaces) => {
    const wsIds = workspaces.map((ws) => ws.workspaceId);

    return projects.filter(({ workspaceId }) => !wsIds.includes(workspaceId));
  },
);
