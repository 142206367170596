import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  ModelStatus,
  ToolModel,
  modelStatusChecks,
  statusCheck,
} from '../../../imageView/tools/models/modelStatus.constants';
import { loadingStateBuilder } from '../../../../../utils/loadingState';

type SegmentPredictionState = {
  model: ToolModel;
};

const initialState: SegmentPredictionState = {
  model: {
    id: null,
    loadingState: loadingStateBuilder.initial(),
    status: null,
    progress: null,
    showDot: false,
  },
};

const { actions, reducer: segmentPredictionReducer } = createSlice({
  name: 'videoViewSegmentPrediction',
  initialState,
  reducers: {
    loadSegmentPredictorModel(_state, _action: PayloadAction<string>) {},
    loadSegmentPredictorModelError(state, action: PayloadAction<string>) {
      state.model.loadingState = loadingStateBuilder.failure(action.payload);
    },
    loadSegmentPredictorModelStart(state, action: PayloadAction<string>) {
      state.model.loadingState = loadingStateBuilder.inProgress(action.payload);
    },
    loadSegmentPredictorModelSuccess(state) {
      state.model.loadingState = loadingStateBuilder.success();
    },
    resetModel(state) {
      state.model = initialState.model;
    },
    updateSegmentPredictorModel(
      _state,
      _action: PayloadAction<{
        status: ModelStatus;
        progress: number;
        id: number;
        modelUseIE?: boolean;
      }>,
    ) {},
    updateSegmentPredictorModelSuccess(
      state,
      action: PayloadAction<{
        id: number | null;
        status: ModelStatus;
        progress: number | null;
      }>,
    ) {
      const { id, status, progress } = action.payload;

      state.model = {
        ...state.model,
        id: modelStatusChecks.isLoaded(status) ? id : state.model.id,
        status: statusCheck(status, state.model.status),
        progress: progress || 0,
      };
    },
    updateSegmentPredictorModelShowDot(state, action: PayloadAction<boolean>) {
      const showDot = action.payload;

      state.model = {
        ...state.model,
        showDot,
      };
    },
  },
});

export { segmentPredictionReducer };
export const {
  resetModel,
  loadSegmentPredictorModel,
  loadSegmentPredictorModelError,
  loadSegmentPredictorModelStart,
  loadSegmentPredictorModelSuccess,
  updateSegmentPredictorModel,
  updateSegmentPredictorModelSuccess,
  updateSegmentPredictorModelShowDot,
} = actions;
