import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import {
  LoadingState,
  loadingStateBuilder,
} from '../../../../../utils/loadingState';
import { setProjectId } from '../../../../core/imageView/project/project.slice';

export type UploadFile = {
  fileName: string;
  loadingState: LoadingState;
};

type UploadFileError = UploadFile & { errorMessage: string };

export const adapter = createEntityAdapter<UploadFile>({
  selectId: (file) => file.fileName,
});
const initialState = adapter.getInitialState({
  listLoadingState: loadingStateBuilder.initial(),
  itemLoadingState: loadingStateBuilder.initial(),
});

const { actions, reducer: importFileReducer } = createSlice({
  name: 'importFile',
  initialState,
  reducers: {
    uploadImportFilesStart(state, _action: PayloadAction<{ files: any[] }>) {
      state.listLoadingState = loadingStateBuilder.inProgress();
    },
    uploadImportFileStart(state, action: PayloadAction<UploadFile>) {
      state.listLoadingState = loadingStateBuilder.inProgress();
      adapter.addOne(state, action.payload);
    },
    uploadImportFileSuccess(state, action: PayloadAction<UploadFile>) {
      state.listLoadingState = loadingStateBuilder.success();
      adapter.upsertOne(state, action.payload);
    },
    uploadImportFileFailure(state, action: PayloadAction<UploadFileError>) {
      const { errorMessage, ...uploadFile } = action.payload;
      state.listLoadingState = loadingStateBuilder.failure(errorMessage);
      adapter.upsertOne(state, uploadFile);
    },
    removeUploadFile(state, action: PayloadAction<string>) {
      adapter.removeOne(state, action.payload);
    },
    resetUploadFiles(state) {
      state.listLoadingState = loadingStateBuilder.initial();
      adapter.removeAll(state);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setProjectId, () => initialState);
  },
});

export { importFileReducer };
export const {
  uploadImportFilesStart,
  uploadImportFileStart,
  uploadImportFileSuccess,
  uploadImportFileFailure,
  removeUploadFile,
  resetUploadFiles,
} = actions;
