// https://github.com/stripe/stripe-payments-demo/blob/master/public/javascripts/payments.js

import {
  CardElement,
  IbanElement,
  IdealBankElement,
} from 'react-stripe-elements';

import { style } from '../containers/modals/common/helpers/editPaymentMethod/elements';

export enum PaymentMethod {
  AchCreditTransfer = 'ach_credit_transfer',
  AliPay = 'alipay',
  BanContact = 'bancontact',
  Card = 'card',
  Eps = 'eps',
  Ideal = 'ideal',
  GiroPay = 'giropay',
  Multibanco = 'multibanco',
  SEPA = 'sepa_debit',
  Sofort = 'sofort',
  WeChat = 'wechat',
}

export enum FlowType {
  None = 'none',
  Redirect = 'redirect',
  Receiver = 'receiver',
}

export const PaymentMethodsInformation = [
  {
    id: PaymentMethod.Card,
    title: 'Card',
    flow: 'none',
    stripeElement: 'card',
  },
  {
    id: PaymentMethod.AchCreditTransfer,
    title: 'Bank Transfer',
    flow: 'receiver',
    countries: ['US'],
    currencies: ['usd'],
    hidden: true,
  },
  {
    id: PaymentMethod.AliPay,
    title: 'Alipay',
    flow: 'redirect',
    countries: ['CN', 'HK', 'SG', 'JP'],
    currencies: ['aud', 'cad', 'eur', 'gbp', 'hkd', 'jpy', 'nzd', 'sgd', 'usd'],
    hidden: true,
  },
  {
    id: PaymentMethod.BanContact,
    title: 'Bancontact',
    flow: 'redirect',
    countries: ['BE'],
    currencies: ['eur'],
    hidden: true,
  },
  {
    id: PaymentMethod.Eps,
    title: 'EPS',
    flow: 'redirect',
    countries: ['AT'],
    currencies: ['eur'],
    hidden: true,
  },
  {
    id: PaymentMethod.Ideal,
    title: 'iDEAL',
    flow: 'redirect',
    countries: ['NL'],
    currencies: ['eur'],
    hidden: true,
  },
  {
    id: PaymentMethod.GiroPay,
    title: 'Giropay',
    flow: 'redirect',
    countries: ['DE'],
    currencies: ['eur'],
    hidden: true,
  },
  {
    id: PaymentMethod.Multibanco,
    title: 'Multibanco',
    flow: 'receiver',
    countries: ['PT'],
    currencies: ['eur'],
    hidden: true,
  },
  {
    id: PaymentMethod.SEPA,
    title: 'SEPA Direct Debit',
    flow: 'none',
    currencies: ['eur'],
    stripeElement: 'iban',
    notice:
      "By providing your IBAN and confirming this payment, you're authorizing Payments Demo and Stripe, our payment provider, to send instructions to your bank to debit your account. You're entitled to a refund under the terms and conditions of your agreement with your bank.",
  },
  {
    id: PaymentMethod.Sofort,
    title: 'SOFORT',
    flow: 'redirect',
    countries: ['DE', 'AT'],
    currencies: ['eur'],
    hidden: true,
  },
  {
    id: PaymentMethod.WeChat,
    title: 'WeChat',
    flow: 'none',
    countries: ['CN', 'HK', 'SG', 'JP'],
    currencies: ['aud', 'cad', 'eur', 'gbp', 'hkd', 'jpy', 'nzd', 'sgd', 'usd'],
    notice: 'Click the button below to generate a QR code for WeChat.',
    hidden: true,
  },
];

export const ElementsMap = {
  [PaymentMethod.Card]: {
    element: CardElement,
    props: {
      style,
      hidePostalCode: true,
    },
  },
  [PaymentMethod.SEPA]: {
    element: IbanElement,
    props: {
      style,
      supportedCountries: ['SEPA'],
    },
  },
  [PaymentMethod.Ideal]: {
    element: IdealBankElement,
    props: {
      style: {
        ...style,
        base: {
          ...style.base,
          padding: '10px 15px',
        },
      },
    },
  },
};
