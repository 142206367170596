import { combineReducers } from 'redux';

import { typedModalsReducer } from './modals/modals.slice';
import { applicationOutdatedReducer } from './applicationOutdated/applicationOutdated.slice';
import { permanentMessagesReducer } from './permanentMessages/permanentMessages.slice';
import { stackNotificationsReducer } from './stackNotifications/stackNotifications.slice';
import { expandedPanelsReducer } from './expandedPanels/expandedPanels.slice';
import { controlledTooltipReducer } from './controlledTooltip/controlledTooltip.slice';

export const uiReducer = combineReducers({
  typedModals: typedModalsReducer,
  expandedPanels: expandedPanelsReducer,
  applicationOutdated: applicationOutdatedReducer,
  permanentMessages: permanentMessagesReducer,
  stackNotifications: stackNotificationsReducer,
  controlledTooltip: controlledTooltipReducer,
});
