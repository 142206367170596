/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type LabelAttributeValue = {
  /**
   * Attribute Identifier
   */
  id?: string;
  /**
   * Label Identifier
   */
  labelId?: string;
  /**
   * Attribute Name
   */
  name?: string;
  /**
   * Type of the attribute
   */
  type?: LabelAttributeValue.type;
  /**
   * Attribute description
   */
  description?: string | null;
  /**
   * Value of the attribute
   */
  value: (string | number | boolean | Array<string>) | null;
  /**
   * Predicted value of the attribute
   */
  predictedValues?: (string | Array<string>);
};

export namespace LabelAttributeValue {

  /**
   * Type of the attribute
   */
  export enum type {
    SELECTION = 'SELECTION',
    MULTIPLE_SELECTION = 'MULTIPLE-SELECTION',
    TEXT = 'TEXT',
    INT = 'INT',
    FLOAT = 'FLOAT',
    BOOL = 'BOOL',
  }


}

