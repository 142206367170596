import { call, put, select, takeEvery } from 'typed-redux-saga';

import { WebsocketKeypointsClassPayload } from '../../../../../api/domainModels/websocketTypes';
import { getErrorMessage } from '../../../../../api/utils';
import { initImageProject } from '../../../sections/editedProject/project/project.slice';
import {
  KEYPOINT_SCHEMA_DELETED,
  KEYPOINT_SCHEMA_UPDATED,
} from '../../../ws/ws.constants';
import { activeProjectIdSelector } from '../../project.selectors';
import {
  deleteKCManySuccess,
  deleteKCRelationByWebsocket,
  loadKCRelations,
  loadKCRelationsFailure,
  loadKCRelationsSuccess,
  processKCRelationsFailure,
  processKCRelationsStart,
  selectId,
  updateKCManySuccess,
  updateKCRelationByWebsocket,
} from './KCRelations.slice';
import {
  KCRelationsMapSelector,
  KCRelationsForKeypointsSelector,
} from './KCRelations.selectors';
import { setProjectId } from '../../../core/imageView/project/project.slice';
import {
  apiCreateProjectKeypointsClasses,
  apiDeleteProjectKeypointsClasses,
  apiLoadProjectKeypointsClasses,
} from '../../../../../api/requests/keypointsClasses';
import { KeypointsClassMapping } from '../../../../../api/domainModels/keypointsClasses';

function* listHandler(
  action: ActionType<
    typeof initImageProject | typeof setProjectId | typeof loadKCRelations
  >,
) {
  const projectId = action.payload.id;

  try {
    const { data } = yield* call(apiLoadProjectKeypointsClasses, {
      projectId,
    });
    yield* put(loadKCRelationsSuccess(data));
  } catch (error) {
    yield* put(
      loadKCRelationsFailure(
        getErrorMessage(error, 'Not able to reload relations'),
      ),
    );
  }
}

function* updateKCRelations(relations: KeypointsClassMapping[]) {
  const projectId = yield* select(activeProjectIdSelector);
  if (relations.length) {
    yield* put(processKCRelationsStart());
    // insert/update the ones we got in the multiselect
    try {
      const { data } = yield* call(
        apiCreateProjectKeypointsClasses,
        {
          projectId,
        },
        relations,
      );
      yield* put(updateKCManySuccess(data));
    } catch (error) {
      yield* put(
        processKCRelationsFailure(
          getErrorMessage(error, 'Not able to update relations'),
        ),
      );
      throw error; // throw so that the caller can process the error as well
    }
  }
}

function* deleteObsoleteKCRelations(
  relations: KeypointsClassMapping[],
  existingRelations: KeypointsClassMapping[],
) {
  const projectId = yield* select(activeProjectIdSelector);
  const newRelationsIds = relations.map(selectId);
  const relationsToDelete = existingRelations.filter(
    (r) => !newRelationsIds.includes(selectId(r)),
  );

  if (relationsToDelete.length) {
    try {
      yield* call(
        apiDeleteProjectKeypointsClasses,
        {
          projectId,
        },
        relationsToDelete.map((relation, i) => ({
          ...relation,
          labelClassOrder: i,
        })),
      );
      yield* put(deleteKCManySuccess(relationsToDelete));
    } catch (error) {
      yield* put(
        processKCRelationsFailure(
          getErrorMessage(error, 'Not able to delete relations'),
        ),
      );
      throw error; // throw so that the caller can process the error as well
    }
  }
}

export function* processKCRelations(
  keypointSchemaId: string,
  relations: KeypointsClassMapping[],
) {
  // delete the ones that are in the state but are not in the multiselect
  const existingRelations: KeypointsClassMapping[] = yield* select(
    KCRelationsForKeypointsSelector,
    keypointSchemaId,
  );
  const relationsMap = yield* select(KCRelationsMapSelector);
  // preserve the class order if there is already such a relation in the state
  const relationsWithPreservedOrder = relations.map((r) => {
    const existingRelation = relationsMap[selectId(r)];
    if (existingRelation) {
      return {
        ...r,
        labelClassOrder: existingRelation.labelClassOrder,
      };
    }

    return r;
  });

  yield* updateKCRelations(relationsWithPreservedOrder);
  yield* deleteObsoleteKCRelations(
    relationsWithPreservedOrder,
    existingRelations,
  );
}

function* websocketKCRelationUpdatedHandler({
  keypointSchemaId,
  labelClassId,
  labelClassOrder,
  projectId,
}: WebsocketKeypointsClassPayload & { type: string }) {
  const currentProjectId = yield* select(activeProjectIdSelector);
  if (currentProjectId && currentProjectId === projectId) {
    yield* put(
      updateKCRelationByWebsocket({
        keypointSchemaId,
        labelClassId,
        labelClassOrder,
      }),
    );
  }
}

function* websocketKCRelationRemovedHandler({
  keypointSchemaId,
  labelClassId,
  labelClassOrder,
  projectId,
}: WebsocketKeypointsClassPayload & { type: string }) {
  const currentProjectId = yield* select(activeProjectIdSelector);
  if (currentProjectId && currentProjectId === projectId) {
    yield* put(
      deleteKCRelationByWebsocket({
        keypointSchemaId,
        labelClassId,
        labelClassOrder,
      }),
    );
  }
}

export function* KCRelationsSaga() {
  yield* takeEvery(
    [initImageProject, setProjectId, loadKCRelations],
    listHandler,
  );
  yield* takeEvery(KEYPOINT_SCHEMA_UPDATED, websocketKCRelationUpdatedHandler);
  yield* takeEvery(KEYPOINT_SCHEMA_DELETED, websocketKCRelationRemovedHandler);
}
