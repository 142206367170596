import axios from 'axios';

import {
  ApiImageTag,
  ApiLoadImageTagsParams,
  ApiLoadImageTagsResponse,
  ApiAddTagToImageParams,
  ApiRemoveTagFromImageParams,
} from '../schemas/imageTags';
import { apiWrapper } from '../utils';
import { NEW_API_URL } from '../../constants/paths';
import {
  ApiDeleteResponse,
  headers,
  transformRequest,
  transformResponse,
} from '../helpers';

export const apiLoadImageTags = (params: ApiLoadImageTagsParams) =>
  apiWrapper(
    axios.request<ApiLoadImageTagsResponse>({
      method: 'get',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/images/${params.imageId}/tags`,
    }),
  );

export const apiAddTagToImage = (
  params: ApiAddTagToImageParams,
  data: Partial<ApiImageTag & { predicted?: boolean }>[],
) =>
  apiWrapper(
    axios.request<ApiImageTag[]>({
      method: 'post',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/images/${params.imageId}/tags`,
      data,
    }),
  );

export const apiRemoveTagFromImage = (
  params: ApiRemoveTagFromImageParams,
  data: Partial<ApiImageTag>[],
) =>
  apiWrapper(
    axios.request<ApiDeleteResponse>({
      method: 'delete',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/images/${params.imageId}/tags`,
      data,
    }),
  );
