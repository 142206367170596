import { LoadingStatus } from '../../constants/status';

export type LoadingState = {
  status: LoadingStatus;
  message?: string;
  params?: any;
};

export const loadingStateBuilder = {
  initial(message?: string): LoadingState {
    return {
      status: LoadingStatus.Initial,
      message,
    };
  },
  inProgress(message?: string): LoadingState {
    return {
      status: LoadingStatus.InProgress,
      message,
    };
  },
  success(message?: string): LoadingState {
    return {
      status: LoadingStatus.Success,
      message,
    };
  },
  failure(message = 'An error occurred', params?: any): LoadingState {
    return {
      status: LoadingStatus.Failure,
      message,
      params,
    };
  },
};
