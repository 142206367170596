import { createSelector } from '@reduxjs/toolkit';

import { errorFinderSelector } from '../errorFinder.selectors';

export const labelsPaginationSelector = createSelector(
  errorFinderSelector,
  (errorFinder) => errorFinder.labelsPagination,
);

export const labelsResultsCountSelector = createSelector(
  labelsPaginationSelector,
  (pagination) => pagination.resultsCount,
);

export const labelsItemsPerPageSelector = createSelector(
  labelsPaginationSelector,
  (pagination) => pagination.itemsPerPage,
);

export const labelsPageSelector = createSelector(
  labelsPaginationSelector,
  (pagination) => pagination.page,
);
