import { combineReducers } from 'redux';

import { architecturesReducer } from './architectures/architectures.slice';
import { solversReducer } from './solvers/solvers.slice';
import { schedulersReducer } from './schedulers/schedulers.slice';
import { lossesReducer } from './losses/losses.slice';
import { metricsReducer } from './metrics/metrics.slice';
import { regimensReducer } from './regimens/regimens.slice';
import { experimentRunsReducer } from './runs/runs.slice';
import { transformsReducer } from './transforms/transforms.slice';
import { SSLReducer } from './SSL/SSL.slice';
import { activeExperimentIdReducer } from './activeExperimentId/activeExperimentId.slice';

export const activeExperimentReducer = combineReducers({
  architectures: architecturesReducer,
  solvers: solversReducer,
  schedulers: schedulersReducer,
  losses: lossesReducer,
  metrics: metricsReducer,
  regimens: regimensReducer,
  runs: experimentRunsReducer,
  transforms: transformsReducer,
  activeExperimentId: activeExperimentIdReducer,
  SSL: SSLReducer,
});
