import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import {
  ExperimentComparisonItem,
  ExperimentComparisonParameterDefinition,
} from '../../../../../../../api/domainModels/modelPlayground';
import { loadingStateBuilder } from '../../../../../../utils/loadingState';
import { setEditedProjectId } from '../../../../../sections/editedProject/project/project.slice';

export const experimentComparisonItemsAdapter =
  createEntityAdapter<ExperimentComparisonItem>({
    sortComparer: (a, b) => (dayjs(a.createdAt).isAfter(b.createdAt) ? -1 : 1),
  });
const initialState = experimentComparisonItemsAdapter.getInitialState({
  loadingState: loadingStateBuilder.initial(),
  parameterDefinitions: [] as ExperimentComparisonParameterDefinition[],
});

const { actions, reducer: experimentComparisonReducer } = createSlice({
  name: 'widgets/experimentComparison',
  initialState,
  reducers: {
    loadExperimentComparisonStart(state, _action: PayloadAction<string[]>) {
      state.loadingState = loadingStateBuilder.inProgress();
      experimentComparisonItemsAdapter.removeAll(state);
      state.parameterDefinitions = [];
    },
    loadExperimentComparisonFailure(state, action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.failure(action.payload);
    },
    loadExperimentComparisonSuccess(
      state,
      action: PayloadAction<{
        experiments: ExperimentComparisonItem[];
        parameterDefinitions: ExperimentComparisonParameterDefinition[];
      }>,
    ) {
      state.loadingState = loadingStateBuilder.success();
      experimentComparisonItemsAdapter.setAll(
        state,
        action.payload.experiments,
      );
      state.parameterDefinitions = action.payload.parameterDefinitions;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setEditedProjectId, () => initialState);
  },
});

export { experimentComparisonReducer };
export const {
  loadExperimentComparisonStart,
  loadExperimentComparisonSuccess,
  loadExperimentComparisonFailure,
} = actions;
