import axios from 'axios';

import {
  ApiExternalBucketFormValues,
  ApiExternalBucket,
  ApiLoadExternalBucketCredentialsResponse,
  ApiPreviewExternalBucketContentResponse,
} from '../schemas/externalBuckets';
import { apiWrapper } from '../utils';
import { headers, transformResponse, transformRequest } from '../helpers';
import { NEW_API_URL } from '../../constants/paths';
import { convertToBackendObject } from '../../redux/utils/backendParams';
import { ApiWorkspace } from '../schemas/workspace';

export const apiLoadAllExternalBucketsCredentials = (params: {
  workspaceId: ApiWorkspace['workspaceId'];
}) =>
  apiWrapper(
    axios.request<ApiLoadExternalBucketCredentialsResponse>({
      method: 'get',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/buckets/${params.workspaceId}/credentials`,
    }),
  );

export const apiDeleteExternalBucketCredentials = (params: {
  workspaceId: ApiWorkspace['workspaceId'];
  credentialId: string;
}) =>
  apiWrapper(
    axios.request<void>({
      method: 'delete',
      headers,
      url: `${NEW_API_URL}/buckets/${params.workspaceId}/credentials/${params.credentialId}`,
    }),
  );

export const apiAddExternalBucketCredentials = (
  params: { workspaceId: ApiWorkspace['workspaceId'] },
  data: ApiExternalBucketFormValues,
) => {
  const { bucketS3, ...restData } = data;

  return apiWrapper(
    axios.request<ApiExternalBucket>({
      method: 'post',
      headers,
      transformResponse,
      url: `${NEW_API_URL}/buckets/${params.workspaceId}/credentials`,
      data: {
        ...convertToBackendObject(restData),
        ...(bucketS3 ? { bucket_s3: bucketS3 } : {}),
      },
    }),
  );
};

export const apiUpdateExternalBucketCredentials = (
  params: { workspaceId: ApiWorkspace['workspaceId']; credentialId: string },
  data: ApiExternalBucketFormValues,
) => {
  const { bucketS3, ...restData } = data;

  return apiWrapper(
    axios.request<ApiExternalBucket>({
      method: 'put',
      headers,
      transformResponse,
      url: `${NEW_API_URL}/buckets/${params.workspaceId}/credentials/${params.credentialId}`,
      data: {
        ...convertToBackendObject(restData),
        ...(bucketS3 ? { bucket_s3: bucketS3 } : {}),
      },
    }),
  );
};

export const apiPreviewExternalBucketContent = (params: {
  workspaceId: ApiWorkspace['workspaceId'];
  bucketId: string;
  prefix?: string;
}) =>
  apiWrapper(
    axios.request<ApiPreviewExternalBucketContentResponse>({
      method: 'get',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/buckets/${params.workspaceId}/credentials/${params.bucketId}/preview`,
      params: {
        prefix: params.prefix || null,
      },
    }),
  );

export const apiUploadFromExternalBucket = (
  params: { workspaceId: ApiWorkspace['workspaceId']; bucketId: string },
  data: { copy: boolean; datasetId: string; prefix?: string },
) =>
  apiWrapper(
    axios.request<ApiExternalBucket>({
      method: 'post',
      headers,
      transformResponse,
      transformRequest,
      url: `${NEW_API_URL}/buckets/${params.workspaceId}/credentials/${params.bucketId}/import`,
      data,
    }),
  );
