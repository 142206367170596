import axios from 'axios';

import {
  ApiLabel,
  ApiLoadProjectImageLabelsParams,
  ApiLoadProjectImageLabelsResponse,
  ApiLoadProjectLabelParams,
  ApiSyncProjectImageLabelsParams,
  ApiSyncProjectImageLabelsResponse,
  ApiDeleteLabelsParams,
  ApiUpdateLabelsParams,
} from '../schemas/imageLabels';
import { apiWrapper } from '../utils';
import { API_URL, NEW_API_URL } from '../../constants/paths';
import { headers, transformRequest, transformResponse } from '../helpers';

export const apiLoadProjectImageLabels = (
  params: ApiLoadProjectImageLabelsParams,
) =>
  apiWrapper(
    axios.request<ApiLoadProjectImageLabelsResponse>({
      method: 'get',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/images/${params.imageId}/labels`,
      params: {
        offset: params.offset,
        limit: params.limit,
        include_border_mask: true,
        include_errors: true,
      },
    }),
  );

export const apiLoadProjectLabel = (params: ApiLoadProjectLabelParams) =>
  apiWrapper(
    axios.request<ApiLabel>({
      method: 'get',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/labels/${params.labelId}`,
      params: {
        include_border_mask: true,
      },
    }),
  );

export const apiSyncProjectImageLabels = (
  params: ApiSyncProjectImageLabelsParams,
  data: Array<Partial<ApiLabel>>,
) =>
  apiWrapper(
    axios.request<ApiSyncProjectImageLabelsResponse>({
      method: 'put',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/images/${params.imageId}/labels_sync?include_border_mask=true&include_errors=true`,
      data,
    }),
  );

export const apiDeleteLabels = (params: ApiDeleteLabelsParams) =>
  apiWrapper(
    axios.request({
      method: 'delete',
      headers,
      transformRequest,
      url: `${API_URL}/project/${params.projectId}/image/${params.imageId}/label`,
      data: {
        labels: params.labels,
      },
    }),
  );

export const apiUpdateLabels = (params: ApiUpdateLabelsParams) =>
  apiWrapper(
    axios.request({
      method: 'put',
      headers,
      transformRequest,
      transformResponse,
      url: `${API_URL}/project/${params.projectId}/image/${params.imageId}/label`,
      data: {
        labels: params.labels,
      },
    }),
  );
