import {
  Action,
  createSlice,
  Dictionary,
  PayloadAction,
} from '@reduxjs/toolkit';

import { ImageLabel } from '../../../../../../api/domainModels/imageLabel';
import { SyncStatus } from '../../../../../../api/domainModels/syncStatus';
import { LABEL_MUTATING_ACTION_TYPES } from '../labels.slice';

const initialState: {
  lastSavedLabels: Dictionary<ImageLabel>;
  stage: SyncStatus;
  lastSavedAt: null | string;
  errorMessage: string;
} = {
  lastSavedLabels: {},
  stage: 'initializing',
  lastSavedAt: null,
  errorMessage: '',
};
const { reducer: labelSyncReducer, actions } = createSlice({
  name: 'imageViewLabelSync',
  initialState,
  reducers: {
    initializeLabelsStart(
      state,
      _action: PayloadAction<{ labelIdToSet?: string }>,
    ) {
      state.stage = 'initializing';
      state.lastSavedAt = null;
      state.errorMessage = '';
    },
    initializeLabelsFailure(state, action: PayloadAction<string>) {
      state.stage = 'initializationFailure';
      state.errorMessage = action.payload;
    },
    initializeLabelsSuccess(
      state,
      action: PayloadAction<Dictionary<ImageLabel>>,
    ) {
      state.lastSavedLabels = action.payload;
      state.lastSavedAt = new Date().toISOString();
      state.stage = 'initialized';
      state.errorMessage = '';
    },
    syncLabelsRetry(state) {
      state.errorMessage = '';
    },
    syncLabelsStart(state) {
      state.stage = 'saving';
      state.errorMessage = '';
    },
    syncLabelsFailure(state, action: PayloadAction<string>) {
      state.stage = 'savingFailure';
      state.errorMessage = action.payload;
    },
    syncLabelsSuccess(state, action: PayloadAction<Dictionary<ImageLabel>>) {
      state.lastSavedLabels = action.payload;
      state.errorMessage = '';
      state.lastSavedAt = new Date().toISOString();
      // if the labels were mutated during save, they are still dirty
      if (state.stage === 'saving') {
        state.stage = 'saved';
      }
    },
  },
  extraReducers: (builder) =>
    builder.addMatcher(
      (action): action is Action =>
        LABEL_MUTATING_ACTION_TYPES.includes(action.type),
      (state) => {
        state.stage = 'dirty';
      },
    ),
});

export { labelSyncReducer };

export const {
  initializeLabelsStart,
  initializeLabelsSuccess,
  initializeLabelsFailure,
  syncLabelsRetry,
  syncLabelsFailure,
  syncLabelsStart,
  syncLabelsSuccess,
} = actions;
