import { combineReducers } from 'redux';

import { summaryReducer } from './summary/summary.slice';
import { partitionsReducer } from './partitions/partitions.slice';
import { splitResultsReducer } from './results/results.slice';
import { activeSplitIdReducer } from './activeSplitId/activeSplitId.slice';

export const activeSplitReducer = combineReducers({
  summary: summaryReducer,
  partitions: partitionsReducer,
  results: splitResultsReducer,
  activeSplitId: activeSplitIdReducer,
});
