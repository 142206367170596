import { createSelector } from '@reduxjs/toolkit';

import { apiKeysAdapter } from './apiKeys.slice';
import { managedWorkspaceSelector } from '../managedWorkspace.selectors';

const apiKeysStateSelector = createSelector(
  managedWorkspaceSelector,
  (managedWorkspace) => managedWorkspace.apiKeys,
);

const selectors = apiKeysAdapter.getSelectors(apiKeysStateSelector);

export const apiKeysByServiceAccountIdSelector = createSelector(
  selectors.selectAll,
  (_: RootState, serviceAccountId: string) => serviceAccountId,
  (apiKeys, serviceAccountId) =>
    apiKeys.filter((ak) => ak.serviceAccountId === serviceAccountId),
);
export const apiKeysIdsByServiceAccountIdSelector = createSelector(
  apiKeysByServiceAccountIdSelector,
  (apiKeys) => apiKeys.map((ak) => ak.id),
);
export const apiKeyByIdSelector = selectors.selectById;
export const apiKeysItemLoadingStateSelector = createSelector(
  apiKeysStateSelector,
  (state) => state.itemLoadingState,
);
