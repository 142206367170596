import { call, put, takeEvery } from 'typed-redux-saga';

import { getErrorMessage } from '../../../../../api/utils';
import {
  apiAddWorkspaceCollaborator,
  apiDeleteWorkspaceCollaborator,
  apiLoadWorkspaceCollaborators,
  apiUpdateWorkspaceCollaborator,
} from '../../../../../api/requests/workspaceCollaborator';
import { hideModals } from '../../../ui/modals/modals.slice';
import {
  loadWorkspaceCollaborators,
  addWorkspaceCollaborator,
  addWorkspaceCollaboratorSuccess,
  deleteWorkspaceCollaborator,
  deleteWorkspaceCollaboratorSuccess,
  updateWorkspaceCollaborator,
  updateWorkspaceCollaboratorSuccess,
  loadWorkspaceCollaboratorsSuccess,
  loadWorkspaceCollaboratorsFailure,
  addWorkspaceCollaboratorFailure,
  deleteWorkspaceCollaboratorFailure,
  updateWorkspaceCollaboratorFailure,
} from './collaborators.slice';
import { enqueueNotification } from '../../../ui/stackNotifications/stackNotifications.slice';
import { workspaceCollaboratorDataMapper } from '../../../../../api/domainModels/workspaceCollaborator';

function* loadCollaboratorsHandler(
  action: ActionType<
    | typeof loadWorkspaceCollaborators
    | typeof addWorkspaceCollaboratorSuccess
    | typeof updateWorkspaceCollaboratorSuccess
    | typeof deleteWorkspaceCollaboratorSuccess
  >,
) {
  const workspaceId = action.payload;

  try {
    const response = yield* call(apiLoadWorkspaceCollaborators, workspaceId);
    const data = response.data.map(workspaceCollaboratorDataMapper.fromBackend);
    yield* put(loadWorkspaceCollaboratorsSuccess(data));
  } catch (error) {
    yield* put(
      loadWorkspaceCollaboratorsFailure(
        getErrorMessage(error, 'Not able to fetch users'),
      ),
    );
  }
}

function* addWorkspaceCollaboratorHandler(
  action: ActionType<typeof addWorkspaceCollaborator>,
) {
  const { workspaceId, params } = action.payload;

  try {
    yield* call(apiAddWorkspaceCollaborator, workspaceId, params);
    yield* put(addWorkspaceCollaboratorSuccess(workspaceId));
    yield* put(
      enqueueNotification({
        message: `An invite has been sent to ${params.email}`,
        options: { variant: 'success', allowOutsideOfEditor: true },
      }),
    );
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to add a collaborator');

    yield* put(addWorkspaceCollaboratorFailure(message));
    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          error,
          allowOutsideOfEditor: true,
        },
      }),
    );
  }
}

function* deleteWorkspaceCollaboratorHandler(
  action: ActionType<typeof deleteWorkspaceCollaborator>,
) {
  const { workspaceId, userId } = action.payload;

  try {
    yield* call(apiDeleteWorkspaceCollaborator, workspaceId, userId);
    yield* put(deleteWorkspaceCollaboratorSuccess(workspaceId));
    yield* put(hideModals());
  } catch (error) {
    yield* put(
      deleteWorkspaceCollaboratorFailure(
        getErrorMessage(error, 'Not able to delete this user'),
      ),
    );
  }
}

function* updateWorkspaceCollaboratorHandler(
  action: ActionType<typeof updateWorkspaceCollaborator>,
) {
  const { workspaceId, userId, params } = action.payload;

  try {
    yield* call(apiUpdateWorkspaceCollaborator, workspaceId, userId, params);
    yield* put(updateWorkspaceCollaboratorSuccess(workspaceId));
    yield* put(hideModals());
  } catch (error) {
    yield* put(
      updateWorkspaceCollaboratorFailure(
        getErrorMessage(error, 'Not able to update this user'),
      ),
    );
  }
}

export function* managedWorkspaceCollaboratorsSaga() {
  yield* takeEvery(
    [
      loadWorkspaceCollaborators,
      deleteWorkspaceCollaboratorSuccess,
      addWorkspaceCollaboratorSuccess,
      updateWorkspaceCollaboratorSuccess,
    ],
    loadCollaboratorsHandler,
  );
  yield* takeEvery(addWorkspaceCollaborator, addWorkspaceCollaboratorHandler);
  yield* takeEvery(
    deleteWorkspaceCollaborator,
    deleteWorkspaceCollaboratorHandler,
  );
  yield* takeEvery(
    updateWorkspaceCollaborator,
    updateWorkspaceCollaboratorHandler,
  );
}
