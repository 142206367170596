import { call, put, select, takeEvery } from 'typed-redux-saga';

import {
  resetWebhooksLoadingState,
  loadWebhooksStart,
  loadWebhooksSuccess,
  loadWebhooksFailure,
  addWebhookStart,
  addWebhookSuccess,
  addWebhookFailure,
  updateWebhookStart,
  updateWebhookSuccess,
  updateWebhookFailure,
  removeWebhookStart,
  removeWebhookSuccess,
  removeWebhookFailure,
} from './webhooks.slice';
import { loadWebhookEventsStart } from './webhookEvents.slice';
import { activeProjectIdSelector } from '../../../project/project.selectors';
import {
  apiCreateProjectWebhook,
  apiLoadProjectWebhooks,
  apiUpdateProjectWebhook,
  apiDeleteProjectWebhook,
} from '../../../../../api/requests/webhook';
import { hideModals } from '../../../ui/modals/modals.slice';
import { getErrorMessage } from '../../../../../api/utils';
import { loadAll } from '../../../../utils/api';
import { enqueueNotification } from '../../../ui/stackNotifications/stackNotifications.slice';

function* listHandler() {
  const projectId = yield* select(activeProjectIdSelector);
  if (!projectId) return;

  yield* put(loadWebhookEventsStart());

  try {
    const items = yield* loadAll({
      apiHelper: apiLoadProjectWebhooks,
      params: {
        projectId,
      },
    });

    yield* put(loadWebhooksSuccess(items));
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to load webhooks.');

    yield* put(loadWebhooksFailure(message));
    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
        },
      }),
    );
  }
}

function* addHandler(action: ActionType<typeof addWebhookStart>) {
  const projectId = yield* select(activeProjectIdSelector);
  const values = action.payload;

  try {
    const { data } = yield* call(
      apiCreateProjectWebhook,
      {
        projectId,
      },
      values,
    );

    yield* put(addWebhookSuccess(data));
    yield* put(hideModals());
    yield* put(resetWebhooksLoadingState());
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to create a webhook.');

    yield* put(addWebhookFailure(message));
    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
        },
      }),
    );
  }
}

function* updateHandler(action: ActionType<typeof updateWebhookStart>) {
  const projectId = yield* select(activeProjectIdSelector);
  const { id, ...values } = action.payload;

  try {
    const { data } = yield* call(
      apiUpdateProjectWebhook,
      {
        projectId,
        webhookId: id,
      },
      values,
    );

    yield* put(
      updateWebhookSuccess({
        id,
        changes: data,
      }),
    );

    yield* put(hideModals());
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to update the webhook.');

    yield* put(updateWebhookFailure(message));
    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
        },
      }),
    );
  }
}

function* removeHandler(action: ActionType<typeof removeWebhookStart>) {
  const projectId = yield* select(activeProjectIdSelector);
  const webhookId = action.payload;

  try {
    yield* call(apiDeleteProjectWebhook, { projectId, webhookId });
    yield* put(removeWebhookSuccess(webhookId));
    yield* put(hideModals());
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to delete the webhook.');

    yield* put(removeWebhookFailure(message));
    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
        },
      }),
    );
  }
}

export function* webhooksSaga() {
  yield* takeEvery(loadWebhooksStart, listHandler);
  yield* takeEvery(addWebhookStart, addHandler);
  yield* takeEvery(updateWebhookStart, updateHandler);
  yield* takeEvery(removeWebhookStart, removeHandler);
}
