import { put, takeEvery, call } from 'typed-redux-saga';

import {
  apiLoadAllExternalBucketsCredentials,
  apiDeleteExternalBucketCredentials,
  apiAddExternalBucketCredentials,
  apiUpdateExternalBucketCredentials,
  apiPreviewExternalBucketContent,
  apiUploadFromExternalBucket,
} from '../../../../../api/requests/externalBuckets';
import { getErrorMessage } from '../../../../../api/utils';
import { handleError } from '../../../commonFeatures/errorHandler/errorHandler.actions';
import { loadAll } from '../../../../utils/api';
import { hideModals } from '../../../ui/modals/modals.slice';
import {
  loadAllExternalBucketsCredentials,
  loadExternalBucketsCredentialsSuccess,
  loadExternalBucketsCredentialsFailure,
  deleteExternalBucketCredentials,
  deleteExternalBucketCredentialSuccess,
  deleteExternalBucketCredentialFailure,
  addExternalBucketCredentials,
  addExternalBucketCredentialsSuccess,
  addExternalBucketCredentialsFailure,
  updateExternalBucketCredentials,
  updateExternalBucketCredentialsSuccess,
  updateExternalBucketCredentialsFailure,
  previewExternalBucketContent,
  previewExternalBucketContentSuccess,
  previewExternalBucketContentFailure,
  uploadFromExternalBucket,
  uploadFromExternalBucketSuccess,
  uploadFromExternalBucketFailure,
  resetExternalBucketState,
} from './externalBuckets.slice';

function* loadAllHandler(
  action: ActionType<typeof loadAllExternalBucketsCredentials>,
) {
  try {
    const { workspaceId } = action.payload;
    const items = yield* loadAll({
      params: { workspaceId },
      apiHelper: apiLoadAllExternalBucketsCredentials,
    });

    yield* put(loadExternalBucketsCredentialsSuccess(items));
  } catch (e) {
    yield* put(
      loadExternalBucketsCredentialsFailure(
        getErrorMessage(e, 'Not able to load external buckets credentials'),
      ),
    );
  }
}

function* deleteHandler(
  action: ActionType<typeof deleteExternalBucketCredentials>,
) {
  try {
    const { workspaceId, credentialId } = action.payload;

    yield* call(apiDeleteExternalBucketCredentials, {
      workspaceId,
      credentialId,
    });

    yield* put(deleteExternalBucketCredentialSuccess(credentialId));

    yield* put(hideModals());
  } catch (e) {
    const message = getErrorMessage(
      e,
      'Not able to delete external buckets credential',
    );

    yield* put(deleteExternalBucketCredentialFailure(message));

    yield* put(handleError({ message, allowOutsideOfEditor: true }));
  }
}

function* addHandler(action: ActionType<typeof addExternalBucketCredentials>) {
  try {
    const { workspaceId, ...values } = action.payload;

    const { data } = yield* call(
      apiAddExternalBucketCredentials,
      { workspaceId },
      values,
    );

    yield* put(addExternalBucketCredentialsSuccess(data));
    yield* put(hideModals());
  } catch (e) {
    const message = getErrorMessage(
      e,
      'Not able to add external bucket credentials',
    );

    yield* put(addExternalBucketCredentialsFailure(message));

    yield* put(handleError({ message, allowOutsideOfEditor: true }));
  }
}

function* updateHandler(
  action: ActionType<typeof updateExternalBucketCredentials>,
) {
  try {
    const { workspaceId, credentialId, ...values } = action.payload;

    const { data } = yield* call(
      apiUpdateExternalBucketCredentials,
      { workspaceId, credentialId },
      values,
    );

    yield* put(
      updateExternalBucketCredentialsSuccess({
        id: credentialId,
        changes: data,
      }),
    );
    yield* put(hideModals());
  } catch (e) {
    const message = getErrorMessage(
      e,
      'Not able to update external bucket credentials',
    );

    yield* put(updateExternalBucketCredentialsFailure(message));

    yield* put(handleError({ message, allowOutsideOfEditor: true }));
  }
}

function* previewHandler(
  action: ActionType<typeof previewExternalBucketContent>,
) {
  try {
    const { workspaceId, bucketId, prefix } = action.payload;
    const { data } = yield* call(apiPreviewExternalBucketContent, {
      workspaceId,
      bucketId,
      prefix,
    });

    yield* put(previewExternalBucketContentSuccess(data));
  } catch (e) {
    const message = getErrorMessage(
      e,
      'Not able to load external bucket preview content',
    );

    yield* put(previewExternalBucketContentFailure(message));

    yield* put(handleError({ message, allowOutsideOfEditor: true }));
  }
}

function* uploadHandler(action: ActionType<typeof uploadFromExternalBucket>) {
  try {
    const { workspaceId, bucketId, ...values } = action.payload;
    yield* call(
      apiUploadFromExternalBucket,
      {
        workspaceId,
        bucketId,
      },
      values,
    );

    yield* put(uploadFromExternalBucketSuccess());
    yield* put(hideModals());
  } catch (e) {
    const message = getErrorMessage(
      e,
      'Not able to import from external bucket',
    );

    yield* put(uploadFromExternalBucketFailure(message));

    yield* put(handleError({ message, allowOutsideOfEditor: true }));
  }
}

function* hideModalsHandler() {
  yield* put(resetExternalBucketState());
}

export function* externalBucketsSaga() {
  yield* takeEvery(loadAllExternalBucketsCredentials, loadAllHandler);
  yield* takeEvery(addExternalBucketCredentials, addHandler);
  yield* takeEvery(deleteExternalBucketCredentials, deleteHandler);
  yield* takeEvery(updateExternalBucketCredentials, updateHandler);
  yield* takeEvery(previewExternalBucketContent, previewHandler);
  yield* takeEvery(uploadFromExternalBucket, uploadHandler);
  yield* takeEvery(hideModals, hideModalsHandler);
}
