const STEP = 1024;
const FILE_SIZES = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

export const readFileAsBinary = (file: File) =>
  new Promise<string | ArrayBuffer | null>((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.readAsArrayBuffer(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
export const formatFileSize = (bytes: number, decimals = 2) => {
  if (!bytes) return '0 Bytes';

  const power = Math.floor(Math.log(bytes) / Math.log(STEP));

  return `${parseFloat((bytes / STEP ** power).toFixed(decimals))} ${
    FILE_SIZES[power]
  }`;
};
