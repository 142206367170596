import { combineReducers } from 'redux';

import { authReducer } from './auth/auth.slice';
import { locksReducer } from './locks/locks.slice';
import { workspaceIdReducer } from './workspaceId/workspaceId.slice';
import { packageFeaturesReducer } from './packageFeatures/packageFeatures.slice';
import { paymentsReducer } from './payments/payments.slice';

export const commonFeaturesReducer = combineReducers({
  auth: authReducer,
  locks: locksReducer,
  workspaceId: workspaceIdReducer,
  packageFeatures: packageFeaturesReducer,
  payments: paymentsReducer,
});
