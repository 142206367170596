import { call, debounce, put, select, takeEvery } from 'typed-redux-saga';

import { apiSyncProjectImageLabels } from '../../../../../../api/requests/imageLabels';
import { getErrorMessage } from '../../../../../../api/utils';
import { activeProjectIdSelector } from '../../../../project/project.selectors';
import { imageViewImageIdSelector } from '../../currentImage/currentImage.selectors';
import {
  imageLabelsMapSelector,
  imageLabelsSelector,
} from '../labels.selectors';
import { labelSyncLastSavedLabelsAtSelector } from './labelSync.selectors';
import {
  syncLabelsFailure,
  syncLabelsRetry,
  syncLabelsStart,
  syncLabelsSuccess,
} from './labelSync.slice';
import { LABEL_MUTATING_ACTION_TYPES } from '../labels.slice';
import {
  ImageLabel,
  Label,
} from '../../../../../../api/domainModels/imageLabel';
import { handleError } from '../../../../commonFeatures/errorHandler/errorHandler.actions';

const stripReadOnlyFields = ({
  createdOn,
  updatedOn,
  imageId,
  labeledBy,
  transactionId,
  ...rest
}: ImageLabel) => rest;

function* syncHandler() {
  yield* put(syncLabelsStart());
  try {
    const presentLabels = yield* select(imageLabelsSelector);
    const presentLabelsMap = yield* select(imageLabelsMapSelector);
    const savedLabelsMap = yield* select(labelSyncLastSavedLabelsAtSelector);

    const savedLabels = (Object.values(savedLabelsMap) as ImageLabel[]).map(
      stripReadOnlyFields,
    );

    let dirtyLabels = presentLabels
      .map(stripReadOnlyFields)
      .filter(
        ({ id, externalEventId }) =>
          !savedLabelsMap[id] ||
          externalEventId !== savedLabelsMap[id]?.externalEventId,
      );

    dirtyLabels = [
      ...dirtyLabels,
      ...savedLabels
        .filter(
          (label): label is ImageLabel => !label || !presentLabelsMap[label.id],
        )
        .map((label) => ({
          ...label,
          isDeleted: true,
        })),
    ];

    if (dirtyLabels.length > 0) {
      const params = {
        projectId: (yield* select(activeProjectIdSelector)) as string,
        imageId: (yield* select(imageViewImageIdSelector)) as string,
      };
      yield* call(
        apiSyncProjectImageLabels,
        params,
        dirtyLabels as Array<Partial<Label>>,
      );
    }
    yield* put(syncLabelsSuccess(presentLabelsMap));
  } catch (error) {
    const message = getErrorMessage(error, 'Sync went wrong');

    yield* put(syncLabelsFailure(message));

    yield* put(handleError({ error, message, allowOutsideOfEditor: true }));
  }
}

export function* labelSyncSaga() {
  yield* takeEvery(syncLabelsRetry, syncHandler);
  yield* debounce(1000, LABEL_MUTATING_ACTION_TYPES, syncHandler);
}
