import { call, put, select, takeEvery } from 'typed-redux-saga';

import { apiLoadExperimentComparison } from '../../../../../../../api/requests/modelPlayground';
import { getErrorMessage } from '../../../../../../../api/utils';
import { activeProjectIdSelector } from '../../../../../project/project.selectors';
import {
  loadExperimentComparisonFailure,
  loadExperimentComparisonStart,
  loadExperimentComparisonSuccess,
} from './experimentComparison.slice';
import { enqueueNotification } from '../../../../../ui/stackNotifications/stackNotifications.slice';

function* loadExperimentComparisonStartHandler(
  action: ActionType<typeof loadExperimentComparisonStart>,
) {
  const projectId = yield* select(activeProjectIdSelector);
  const experimentIds = action.payload;

  try {
    const { data } = yield* call(
      apiLoadExperimentComparison,
      {
        projectId,
      },
      { experimentIds },
    );

    // need to sort included parameters according to the parameters declaration
    const sortMap: Record<string, number> = {};
    data.parameters.forEach((param, i) => {
      sortMap[param.id] = i;
    });
    yield* put(
      loadExperimentComparisonSuccess({
        experiments: data.experiments.map((exp) => ({
          ...exp,
          parameters: [...exp.parameters].sort(
            (p1, p2) => sortMap[p1.id] - sortMap[p2.id],
          ),
        })),
        parameterDefinitions: data.parameters,
      }),
    );
  } catch (error) {
    const message = getErrorMessage(
      error,
      'Not able to fetch experiment comparison',
    );

    yield* put(loadExperimentComparisonFailure(message));
    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
          refresh: false,
        },
      }),
    );
  }
}

export function* experimentComparisonSaga() {
  yield* takeEvery(
    loadExperimentComparisonStart,
    loadExperimentComparisonStartHandler,
  );
}
