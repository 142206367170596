import { createSelector } from '@reduxjs/toolkit';

import { adapter } from './experimentsData.slice';
import { modelPlaygroundSelector } from '../../modelPlayground.selectors';

export const experimentsStateSelector = createSelector(
  modelPlaygroundSelector,
  (modelPlayground) => modelPlayground.experiments.data,
);

const selectors = adapter.getSelectors(experimentsStateSelector);

export const experimentsSelector = selectors.selectAll;
export const experimentsIdsSelector = selectors.selectIds as (
  state: RootState,
) => string[];
export const experimentsByIdSelector = selectors.selectById;
export const experimentsCountSelector = selectors.selectTotal;

export const experimentsListLoadingStateSelector = createSelector(
  experimentsStateSelector,
  (state) => state.loadingState,
);

export const experimentsItemLoadingStateSelector = createSelector(
  experimentsStateSelector,
  (state) => state.itemLoadingState,
);

export const experimentsColorsSelector = createSelector(
  experimentsSelector,
  (experiments) => experiments.map((experiment) => experiment.color),
);

export const completedExperimentsSelector = createSelector(
  experimentsSelector,
  (experiments) =>
    experiments.filter((experiment) => experiment.status === 'DONE'),
);

export const experimentExportFormatsSelector = createSelector(
  experimentsStateSelector,
  (experiments) => experiments.exportFormats,
);

export const experimentExportFormatsLoadingStateSelector = createSelector(
  experimentsStateSelector,
  (experiments) => experiments.exportFormatsLoadingState,
);

export const experimentExportFormatOptionsSelector = createSelector(
  experimentsStateSelector,
  (experiments) => experiments.exportFormatOptions,
);

export const experimentExportFormatOptionsLoadingStateSelector = createSelector(
  experimentsStateSelector,
  (experiments) => experiments.exportFormatOptionsLoadingState,
);

export const experimentExportLoadingStateSelector = createSelector(
  experimentsStateSelector,
  (experiments) => experiments.experimentExportLoadingState,
);

export const experimentByRunIdSelector = (state: RootState, runId: string) => {
  const experiments = experimentsSelector(state);

  return experiments.find((experiment) => experiment.lastRunId === runId);
};
