import { ModelFamily } from '../../../../../../api/constants/modelFamily';

export const MODEL_MESSAGES = {
  availability:
    'Instance segmentation (I) is now available. You can select it in the toolbar.',
  progress: 'Loading instance segmentation model',
  error: 'Not able load instance segmentation model',
};
export const INSTANCE_SEGMENTATION_FAMILY_NAME = ModelFamily.Segmentor;
export const INSTANCE_SEGMENTATION_DEFAULT_Z_INDEX = 1;
export const INSTANCE_SEGMENTATION_DEFAULT_CONFIDENCE = 50;
export const INSTANCE_SEGMENTATION_DEFAULT_MAX_DETECTIONS = 100;
export const INSTANCE_SEGMENTATION_DEFAULT_MASKER_THRESHOLD = 0.5;
