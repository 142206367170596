import React, { useCallback } from 'react';
import {
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {
  Close as CloseIcon,
  ArrowForward as ArrowForwardIcon,
} from '@mui/icons-material';
import MuiTooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useDispatch } from 'react-redux';
import { TooltipProps } from '@mui/material/Tooltip/Tooltip';

import { hideControlledTooltip } from '../../../../../redux/state/ui/controlledTooltip/controlledTooltip.slice';
import {
  ControlledTooltipId,
  SEEN_VALUE,
} from '../../../../../constants/controlledTooltip';
import { ImageTool } from '../../../../../redux/state/core/imageView/tools/tools.constants';
import { trySetActiveTool } from '../../../../../redux/state/core/imageView/tools/tools.slice';

type NewToolControlledTooltipProps = Omit<TooltipProps, 'title'> & {
  imgSrc: string;
  content: string;
  title: string;
  id: ControlledTooltipId;
  toolId: ImageTool;
};
export const NewToolControlledTooltip: React.FC<NewToolControlledTooltipProps> =
  ({ children, imgSrc, content, title, id, toolId, ...rest }) => {
    const theme = useTheme();
    const dispatch = useDispatch();

    const handleClose = useCallback(() => {
      dispatch(hideControlledTooltip({ id }));
    }, [dispatch, id]);

    const handleTry = useCallback(() => {
      dispatch(trySetActiveTool(toolId));
      handleClose();
      localStorage.setItem(id, SEEN_VALUE);
    }, [dispatch, handleClose, id, toolId]);

    return (
      <MuiTooltip
        open
        {...rest}
        title={
          <Stack spacing={1}>
            <Stack>
              <IconButton
                onClick={handleClose}
                sx={{
                  position: 'relative',
                  marginLeft: 'auto',
                }}
                size="small"
              >
                <CloseIcon />
              </IconButton>
            </Stack>
            <img
              src={imgSrc}
              alt={`${title} example`}
              width="100%"
              style={{ marginTop: 0 }}
            />
            <Box p={2}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography
                  variant="h5"
                  sx={{ mb: '0!important', fontWeight: 'bold!important' }}
                >
                  {title}
                </Typography>
                <Stack
                  sx={{
                    bgcolor: 'common.purple',
                    px: 2,
                    py: 0.5,
                    borderRadius: 4,
                  }}
                >
                  New feature
                </Stack>
              </Stack>
              <Typography mt={2}>{content}</Typography>
              <Button
                color="secondary"
                sx={{ mt: 2, px: 4 }}
                onClick={handleTry}
              >
                Try it <ArrowForwardIcon sx={{ ml: 1 }} />
              </Button>
            </Box>
          </Stack>
        }
        PopperProps={{
          sx: {
            [`& .${tooltipClasses.tooltip}`]: {
              border: '1px solid',
              borderColor: theme.palette.common.purple,
              borderRadius: 1,
              p: 0,
              minWidth: 350,
            },
          },
        }}
      >
        {children}
      </MuiTooltip>
    );
  };
