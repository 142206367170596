import {
  ApiCreateExportSessionData,
  ApiExportSession,
} from '../schemas/export';

export type CreateExportSessionData = Omit<
  ApiCreateExportSessionData,
  'exportName'
> & { name: string };
const exportSessionsFromBackend = ({
  sessionId,
  exportName,
  ...rest
}: ApiExportSession) => ({
  id: sessionId,
  name: exportName,
  ...rest,
});
export type ExportSession = ReturnType<typeof exportSessionsFromBackend>;

const createExportSessionsToBackend = ({
  name,
  ...rest
}: CreateExportSessionData) => ({
  exportName: name,
  ...rest,
});

export const exportSessionsDataMapper = {
  fromBackend: exportSessionsFromBackend,
};
export const createExportSessionDataMapper = {
  toBackend: createExportSessionsToBackend,
};
