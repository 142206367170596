import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { setEditedProjectId } from '../../../../../sections/editedProject/project/project.slice';

const initialState = {
  liveUpdateEnabled: true,
};
const { actions, reducer: widgetsOptionsReducer } = createSlice({
  name: 'modelPlayground/widgetsOptions',
  initialState,
  reducers: {
    setLiveUpdate(state, action: PayloadAction<boolean>) {
      state.liveUpdateEnabled = action.payload;
    },
    initializeLiveUpdate() {},
  },
  extraReducers: (builder) => {
    builder.addCase(setEditedProjectId, () => initialState);
  },
});

export const { setLiveUpdate, initializeLiveUpdate } = actions;

export { widgetsOptionsReducer };
