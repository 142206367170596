import { createSelector } from '@reduxjs/toolkit';

import { AggEntity } from '../../../../api/domainModels/projectReportEntities';
import { projectSelector } from '../project.selectors';

const projectOperationsStateSelector = createSelector(
  projectSelector,
  (state) => state.operations,
);

export const projectOperationsReportEntitiesLoadingStateSelector =
  createSelector(
    projectOperationsStateSelector,
    (projectOperationsState) =>
      projectOperationsState.reportEntities.loadingState,
  );

export const projectOperationsAggEntitiesSelector = createSelector(
  projectOperationsStateSelector,
  (projectOperationsState) => projectOperationsState.reportEntities.aggEntities,
);

const projectOperationsKpisSelector = createSelector(
  projectOperationsStateSelector,
  (projectOperationsState) => projectOperationsState.reportEntities.kpis,
);

const projectOperationsKpisByAggEntitySelector = createSelector(
  projectOperationsKpisSelector,
  (_state: RootState, aggEntity: AggEntity['value']) => aggEntity,
  (kpis, aggEntity) => kpis.filter((kpi) => kpi.aggEntity === aggEntity),
);

export const projectOperationsKpisByAggEntityAsOptionsSelector = createSelector(
  projectOperationsKpisByAggEntitySelector,
  (kpis) => kpis.map((kpi) => ({ text: kpi.name, value: kpi.value })),
);
