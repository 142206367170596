import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { ImageTag } from '../../../../../../api/domainModels/imageTag';
import { loadingStateBuilder } from '../../../../../utils/loadingState';

export const activeTagsAdapter = createEntityAdapter<ImageTag>();

const { actions, reducer: imageTagsReducer } = createSlice({
  name: 'imageTags',
  initialState: {
    listLoadingState: loadingStateBuilder.initial(),
    itemLoadingState: loadingStateBuilder.initial(),
    activeTags: activeTagsAdapter.getInitialState(),
  },
  reducers: {
    addBatchPredictedTagsToImage(
      state,
      _action: PayloadAction<Partial<ImageTag>[]>,
    ) {
      state.itemLoadingState = loadingStateBuilder.inProgress();
    },
    addTagToImage(
      state,
      _action: PayloadAction<{ id: string; predicted?: boolean }>,
    ) {
      state.itemLoadingState = loadingStateBuilder.inProgress();
    },
    addTagsToImageSuccess(state, action: PayloadAction<ImageTag[]>) {
      state.itemLoadingState = loadingStateBuilder.success();
      activeTagsAdapter.addMany(state.activeTags, action.payload);
    },
    addTagsToImageFailure(state, action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.failure(action.payload);
    },
    loadTagClassesForImage(
      state,
      _action: PayloadAction<{
        imageId: string;
        projectId: string;
      }>,
    ) {
      state.listLoadingState =
        loadingStateBuilder.inProgress('Loading image tags');
    },
    loadTagClassesForImageError(state, action: PayloadAction<string>) {
      state.listLoadingState = loadingStateBuilder.failure(action.payload);
    },
    loadTagClassesForImageSuccess(state, action: PayloadAction<ImageTag[]>) {
      activeTagsAdapter.setAll(state.activeTags, action.payload);
      state.listLoadingState = loadingStateBuilder.success();
    },
    removeTagFromImage(
      state,
      _action: PayloadAction<{ classId: string; tagId: string }>,
    ) {
      state.itemLoadingState = loadingStateBuilder.inProgress();
    },
    removeTagFromImageSuccess(state, action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.success();
      activeTagsAdapter.removeOne(state.activeTags, action.payload);
    },
    removeTagFromImageFailure(state, action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.failure(action.payload);
    },
  },
});

export const {
  addBatchPredictedTagsToImage,
  addTagToImage,
  addTagsToImageSuccess,
  addTagsToImageFailure,
  loadTagClassesForImage,
  loadTagClassesForImageError,
  loadTagClassesForImageSuccess,
  removeTagFromImage,
  removeTagFromImageSuccess,
  removeTagFromImageFailure,
} = actions;
export { imageTagsReducer };
