import { createSelector } from '@reduxjs/toolkit';

import { widgetsSelector } from '../widgets.selectors';
import { itemsAdapter } from './taggerInferences.slice';

const taggerInferencesSelector = createSelector(
  widgetsSelector,
  (widgets) => widgets.taggerInferences,
);

export const taggerInferencesItemsStateSelector = createSelector(
  taggerInferencesSelector,
  ({ items }) => items,
);

const itemsSelectors = itemsAdapter.getSelectors(
  taggerInferencesItemsStateSelector,
);

export const taggerInferencesItemsSelector = itemsSelectors.selectAll;

export const taggerInferencesMetaSelector = createSelector(
  taggerInferencesSelector,
  ({ meta }) => meta,
);

export const taggerInferencesMetaTotalSelector = createSelector(
  taggerInferencesMetaSelector,
  (meta) => meta?.total || 0,
);

export const taggerInferencesLimitSelector = createSelector(
  taggerInferencesSelector,
  ({ limit }) => limit,
);

export const taggerInferencesOffsetSelector = createSelector(
  taggerInferencesSelector,
  ({ offset }) => offset,
);
