import { put, select, takeEvery, call } from 'typed-redux-saga';

import { getErrorMessage } from '../../../../../../api/utils';
import { handleError } from '../../../../commonFeatures/errorHandler/errorHandler.actions';
import {
  loadTagClassesForImage,
  addTagToImage,
  removeTagFromImage,
  loadTagClassesForImageError,
  addTagsToImageSuccess,
  addTagsToImageFailure,
  removeTagFromImageSuccess,
  removeTagFromImageFailure,
  loadTagClassesForImageSuccess,
  addBatchPredictedTagsToImage,
} from './imageTags.slice';
import { imageViewImageIdSelector } from '../../currentImage/currentImage.selectors';
import { activeProjectIdSelector } from '../../../../project/project.selectors';
import { loadAll } from '../../../../../utils/api';
import {
  apiLoadImageTags,
  apiAddTagToImage,
  apiRemoveTagFromImage,
} from '../../../../../../api/requests/imageTags';
import { imageTagDataMapper } from '../../../../../../api/domainModels/imageTag';
import {
  addNewTagToImageSuccess,
  changeTagReviewErrorActionSuccess,
  removeExistingTagFromImageSuccess,
} from '../../../errorFinder/labels/tagReview.slice';
import { ErrorType } from '../../../../../../api/domainModels/consensusScoring';
import { ErrorFinderAction } from '../../../../../../api/constants/consensusScoring';
import { imageViewActiveTagsSelector } from './imageTags.selectors';
import { tagClassesSelector } from '../../../../project/annotationTaxonomy/tagClasses/tagClasses.selectors';

function* loadTagClassesForImageHandler(
  action: ActionType<typeof loadTagClassesForImage>,
) {
  const { projectId, imageId } = action.payload;

  try {
    const data = yield* loadAll({
      apiHelper: apiLoadImageTags,
      params: {
        projectId,
        imageId,
      },
      fromBackend: imageTagDataMapper.fromBackend,
    });

    yield* put(loadTagClassesForImageSuccess(data));
  } catch (error) {
    yield* put(
      loadTagClassesForImageError(
        getErrorMessage(error, 'Not able to fetch image tags'),
      ),
    );
  }
}

function* addTagToImageHandler(action: ActionType<typeof addTagToImage>) {
  const projectId = yield* select(activeProjectIdSelector);
  const imageId = yield* select(imageViewImageIdSelector);
  const { id, predicted } = action.payload;

  if (!imageId) return;

  try {
    const tag = { ...imageTagDataMapper.toBackend({ classId: id }), predicted };
    const { data } = yield* call(apiAddTagToImage, { projectId, imageId }, [
      tag,
    ]);
    const newTag = imageTagDataMapper.fromBackend(data[0]);

    yield* put(addTagsToImageSuccess([newTag]));
  } catch (error) {
    const errorMessage = getErrorMessage(error, 'Not able to add tag to image');
    yield* put(addTagsToImageFailure(errorMessage));
    yield* put(handleError({ message: errorMessage, error }));
  }
}

function* addBatchPredictedTagsToImageHandler(
  action: ActionType<typeof addBatchPredictedTagsToImage>,
) {
  const projectId = yield* select(activeProjectIdSelector);
  const imageId = yield* select(imageViewImageIdSelector);

  const tagsToAdd = action.payload.map(({ classId }) => ({
    ...imageTagDataMapper.toBackend({ classId }),
    predicted: true,
  }));

  if (!imageId) return;

  try {
    const { data } = yield* call(
      apiAddTagToImage,
      { projectId, imageId },
      tagsToAdd,
    );

    yield* put(addTagsToImageSuccess(data.map(imageTagDataMapper.fromBackend)));
  } catch (error) {
    const errorMessage = getErrorMessage(error, 'Not able to add tag to image');
    yield* put(addTagsToImageFailure(errorMessage));
    yield* put(handleError({ message: errorMessage, error }));
  }
}

function* removeTagFromImageHandler(
  action: ActionType<typeof removeTagFromImage>,
) {
  const projectId = yield* select(activeProjectIdSelector);
  const imageId = yield* select(imageViewImageIdSelector);
  const { classId, tagId } = action.payload;

  if (!imageId) return;

  try {
    yield* call(
      apiRemoveTagFromImage,
      {
        projectId,
        imageId,
      },
      [imageTagDataMapper.toBackend({ classId })],
    );

    yield* put(removeTagFromImageSuccess(tagId));
  } catch (error) {
    const errorMessage = getErrorMessage(
      error,
      'Not able to remove tag from image',
    );
    yield* put(removeTagFromImageFailure(errorMessage));
    yield* put(handleError({ message: errorMessage, error }));
  }
}

function* changeTagReviewErrorActionSuccessHandler(
  action: ActionType<typeof changeTagReviewErrorActionSuccess>,
) {
  const { errorType, efAction, curTagId, predTagId } = action.payload;

  if (
    curTagId &&
    [ErrorType.ExtraTag, ErrorType.Misclassification].includes(errorType) &&
    [ErrorFinderAction.Accept, ErrorFinderAction.ChangedManually].includes(
      efAction,
    )
  ) {
    yield* call(removeExistingImageTagSuccessHandler, curTagId);
  }

  if (
    predTagId &&
    [ErrorType.MissingTag, ErrorType.Misclassification].includes(errorType) &&
    [ErrorFinderAction.Accept, ErrorFinderAction.ChangedManually].includes(
      efAction,
    )
  ) {
    yield* call(addNewImageTagSuccessHandler, predTagId);
  }
}

function* removeExistingTagFromImageSuccessHandler(
  action: ActionType<typeof removeExistingTagFromImageSuccess>,
) {
  const { tagClassId } = action.payload;

  yield* call(removeExistingImageTagSuccessHandler, tagClassId);
}

function* addNewTagToImageSuccessHandler(
  action: ActionType<typeof addNewTagToImageSuccess>,
) {
  const { tagClassId } = action.payload;

  yield* call(addNewImageTagSuccessHandler, tagClassId);
}

function* addNewImageTagSuccessHandler(newTagClassId: string) {
  const tagClasses = yield* select(tagClassesSelector);

  const tagClass = tagClasses.find(({ id }) => id === newTagClassId);

  if (tagClass) {
    yield* put(
      addTagsToImageSuccess([
        {
          id: tagClass.id,
          name: tagClass.name,
          classId: tagClass.id,
        },
      ]),
    );
  }
}

function* removeExistingImageTagSuccessHandler(existingTagClassId: string) {
  const activeTags = yield* select(imageViewActiveTagsSelector);

  const currentTag = activeTags.find(
    ({ classId }) => classId === existingTagClassId,
  );

  if (currentTag) {
    yield* put(removeTagFromImageSuccess(currentTag.id));
  }
}

export function* imageTagsSaga() {
  yield* takeEvery(loadTagClassesForImage, loadTagClassesForImageHandler);
  yield* takeEvery(addTagToImage, addTagToImageHandler);
  yield* takeEvery(
    addBatchPredictedTagsToImage,
    addBatchPredictedTagsToImageHandler,
  );
  yield* takeEvery(removeTagFromImage, removeTagFromImageHandler);
  yield* takeEvery(
    changeTagReviewErrorActionSuccess,
    changeTagReviewErrorActionSuccessHandler,
  );
  yield* takeEvery(
    removeExistingTagFromImageSuccess,
    removeExistingTagFromImageSuccessHandler,
  );
  yield* takeEvery(addNewTagToImageSuccess, addNewTagToImageSuccessHandler);
}
