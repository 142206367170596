import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';

import { queryClient } from './ApiProvider';
import {
  InfiniteQueryOpBaseType,
  InfiniteQueryUpdater,
  OpBaseType,
  OpReturn,
  Params,
  QueryUpdater,
  UseApiInfiniteQueryProps,
  UseApiMutationProps,
  UseApiMutationReturn,
  UseApiQueryProps,
} from './api.types';
import { buildLoadingState, getKey } from './api.helpers';

export const useApiQuery = <
  Op extends OpBaseType,
  TQueryFnData = OpReturn<Op>,
  TError = unknown,
  TData = TQueryFnData,
>(
  operation: Op,
  {
    params = {},
    loadingStateErrorMessage,
    ...options
  }: UseApiQueryProps<Op, TQueryFnData, TError, TData> = {},
) => {
  const queryResult = useQuery({
    queryKey: getKey(operation, params),
    queryFn: () => operation(params),
    ...options,
  });

  return {
    ...queryResult,
    loadingState: buildLoadingState(queryResult, loadingStateErrorMessage),
  };
};

export const useApiInfiniteQuery = <
  Op extends InfiniteQueryOpBaseType,
  TQueryFnData = OpReturn<Op>,
  TError = unknown,
  TData = TQueryFnData,
>(
  operation: Op,
  {
    loadingStateErrorMessage,
    ...options
  }: UseApiInfiniteQueryProps<Op, TQueryFnData, TError, TData> = {},
) => {
  const queryResult = useInfiniteQuery({
    queryKey: getKey(operation),
    queryFn: operation,
    ...options,
  });

  return {
    ...queryResult,
    loadingState: buildLoadingState(queryResult, loadingStateErrorMessage),
  };
};

export const useApiMutation = <Op extends OpBaseType>(
  operation: Op,
  { loadingStateErrorMessage, ...options }: UseApiMutationProps<Op> = {},
): UseApiMutationReturn<Op> => {
  const mutationResult = useMutation({
    mutationKey: getKey(operation),
    mutationFn: operation,
    ...options,
  });

  const loadingState = buildLoadingState(
    mutationResult,
    loadingStateErrorMessage,
  );

  return [mutationResult.mutateAsync, mutationResult, loadingState];
};

// Without *params* this will invalidate all queries related to the operation
export const invalidateQueries = <Op extends OpBaseType>(
  operation: Op,
  params?: Params<Op>,
) => queryClient.invalidateQueries({ queryKey: getKey(operation, params) });

export const removeQueries = <Op extends OpBaseType>(operation: Op) =>
  queryClient.removeQueries({ queryKey: getKey(operation) });

export const setQueryData = <Op extends OpBaseType>(
  operation: Op,
  { params = {}, updater }: { params?: Params<Op>; updater: QueryUpdater<Op> },
) => queryClient.setQueryData(getKey(operation, params), updater);

export const setInfiniteQueryData = <Op extends OpBaseType>(
  operation: Op,
  {
    params = {},
    updater,
  }: { params?: Params<Op>; updater: InfiniteQueryUpdater<Op> },
) => queryClient.setQueryData(getKey(operation, params), updater);

export const getQueryData = <Op extends OpBaseType>(
  operation: Op,
  params?: Params<Op>,
) => queryClient.getQueryData<OpReturn<Op>>(getKey(operation, params));

export const prefetchQuery = <Op extends OpBaseType>(
  operation: Op,
  params?: Params<Op>,
) => queryClient.prefetchQuery({ queryKey: getKey(operation, params) });
