import { createSlice, combineReducers } from '@reduxjs/toolkit';

import { imageViewBottomBarFiltersReducer } from './filters/filters.slice';

const { actions, reducer } = createSlice({
  name: 'imageViewImageGalleryBottomBarState',
  initialState: {
    expanded: true,
  },
  reducers: {
    toggleBottomBar(state) {
      state.expanded = !state.expanded;
    },
    hideBottomBar(state) {
      state.expanded = false;
    },
    showBottomBar(state) {
      state.expanded = true;
    },
  },
});

export const { toggleBottomBar, hideBottomBar, showBottomBar } = actions;

export const bottomBarReducer = combineReducers({
  state: reducer,
  filters: imageViewBottomBarFiltersReducer,
});
