/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AttributeLOV } from './AttributeLOV';

export type Attribute = {
  /**
   * Attribute ID
   */
  readonly attributeId?: (string | number);
  /**
   * Attribute name
   */
  attributeName: string;
  /**
   * Attribute type
   */
  attributeType: Attribute.attributeType;
  /**
   * Default value
   */
  defaultValue?: string | null;
  /**
   * Attribute description
   */
  description?: string | null;
  /**
   * Min value for int and float type
   */
  minValue?: number | null;
  /**
   * Min value for int and float type
   */
  maxValue?: number | null;
  /**
   * Attribute order
   */
  norder?: number;
  /**
   * Number of classes that this attribute is assigned to
   */
  readonly classNum?: number;
  /**
   * List of possible values for attributes of type SELECTION or MULTIPLE-SELECTION
   */
  listOfValues?: Array<AttributeLOV> | null;
};

export namespace Attribute {

  /**
   * Attribute type
   */
  export enum attributeType {
    SELECTION = 'SELECTION',
    MULTIPLE_SELECTION = 'MULTIPLE-SELECTION',
    TEXT = 'TEXT',
    INT = 'INT',
    FLOAT = 'FLOAT',
    BOOL = 'BOOL',
  }


}

