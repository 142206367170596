/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type InferenceSummary = {
  /**
   * Model family
   */
  family?: InferenceSummary.family;
  /**
   * Count of images used for inference
   */
  images?: number;
};

export namespace InferenceSummary {

  /**
   * Model family
   */
  export enum family {
    CLASSIFIER = 'CLASSIFIER',
    ATTRIBUTER = 'ATTRIBUTER',
    DETECTOR = 'DETECTOR',
    SEGMENTOR = 'SEGMENTOR',
    SEMANTIC_SEGMENTOR = 'SEMANTIC_SEGMENTOR',
    TAGGER = 'TAGGER',
  }


}

