import { put, takeEvery, select } from 'typed-redux-saga';
import { getRecoil } from 'recoil-nexus';

import { nullableVideoIdState } from '../../../../../recoil/state/video';
import { segmentPredictionSaga } from './segmentPrediction/segmentPrediction.saga';
import { setActiveTool, trySetActiveTool } from './tools.slice';
import { isSubjectLockedByIdSelector } from '../../../commonFeatures/locks/locks.selectors';
import {
  MODEL_TOOLS,
  ModelTool,
  modelToolStatePropertiesSelector,
} from '../../imageView/tools/models/models.selectors';

function* trySetActiveToolHandler(action: ActionType<typeof trySetActiveTool>) {
  const toolId = action.payload;

  const currentVideoId = getRecoil(nullableVideoIdState);

  const isVideoLocked = yield* select((state: RootState) =>
    isSubjectLockedByIdSelector(state, currentVideoId),
  );

  if (isVideoLocked) return;

  if (MODEL_TOOLS.includes(toolId)) {
    const status = yield* select((state: RootState) =>
      modelToolStatePropertiesSelector(state, toolId as ModelTool),
    );
    if (status?.available) yield* put(setActiveTool(toolId));
  }
}

function* toolsSaga() {
  yield* takeEvery(trySetActiveTool, trySetActiveToolHandler);
}

export const toolSagas = [toolsSaga, segmentPredictionSaga];
