/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BatchMeta } from '../models/BatchMeta';
import type { PaginationMeta } from '../models/PaginationMeta';
import type { PredictedActivity } from '../models/PredictedActivity';
import type { Video } from '../models/Video';
import type { VideoExternalUploadRequest } from '../models/VideoExternalUploadRequest';
import type { VideoMoveRenameRequest } from '../models/VideoMoveRenameRequest';
import type { VideoStatus } from '../models/VideoStatus';
import type { VideoUploadRequest } from '../models/VideoUploadRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { OmitReadonly } from '../core/utils/OmitReadonly';

export class VideoService {

  /**
   * Fetch the list of predicted activity
   * @returns any Successful read of the list of predicted activity
   * @throws ApiError
   */
  public static getV1ProjectsVideosActivityPredictions({
    projectId,
    videoId,
    offset,
    limit,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Video ID
     */
    videoId: string,
    /**
     * Offset for this paginated query
     */
    offset?: number,
    /**
     * Limit for this paginated query
     */
    limit?: number,
  }): CancelablePromise<{
    meta: PaginationMeta;
    items: Array<PredictedActivity>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/projects/{project_id}/videos/{video_id}/activity-predictions',
      path: {
        'project_id': projectId,
        'video_id': videoId,
      },
      query: {
        'offset': offset,
        'limit': limit,
      },
    });
  }

  /**
   * Trigger state of activity recognition job
   * @returns any Job status after successful trigger
   * @throws ApiError
   */
  public static postV1ProjectsVideosActivityPredictions({
    projectId,
    videoId,
    jobAction,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Video ID
     */
    videoId: string,
    jobAction: 'status' | 'start' | 'pause',
  }): CancelablePromise<{
    status?: 'INIT' | 'RUNNING' | 'FAILED' | 'DONE' | 'PAUSED';
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/projects/{project_id}/videos/{video_id}/activity-predictions/{job_action}',
      path: {
        'project_id': projectId,
        'video_id': videoId,
        'job_action': jobAction,
      },
    });
  }

  /**
   * Delete an existing activity prediction
   * @returns void
   * @throws ApiError
   */
  public static deleteV1ProjectsVideosActivityPredictions({
    projectId,
    videoId,
    predictionId,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Video ID
     */
    videoId: string,
    /**
     * Prediction ID
     */
    predictionId: string,
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/v1/projects/{project_id}/videos/{video_id}/activity-predictions/{prediction_id}',
      path: {
        'project_id': projectId,
        'video_id': videoId,
        'prediction_id': predictionId,
      },
    });
  }

  /**
   * Generate signed URLs for video uploads.
   * @returns any Returns a list of upload IDs and signed URLs for uploading videos.
   * @throws ApiError
   */
  public static videosUrl({
    projectId,
    requestBody,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    requestBody?: {
      /**
       * The number of signed URLs to generate for upload.
       */
      count: number;
    },
  }): CancelablePromise<{
    meta?: BatchMeta;
    items?: Array<{
      id?: string;
      url?: string;
    }>;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/projects/{project_id}/video-uploads',
      path: {
        'project_id': projectId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Fetch the list of videos
   * @returns any Successful read of the list of videos
   * @throws ApiError
   */
  public static videosList({
    projectId,
    offset,
    limit,
    datasetId,
    videoStatus,
    videoName,
    healthStatus,
    activity,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Offset for this paginated query
     */
    offset?: number,
    /**
     * Limit for this paginated query
     */
    limit?: number,
    /**
     * Dataset ID
     */
    datasetId?: string,
    /**
     * Status of the video
     */
    videoStatus?: Array<VideoStatus>,
    /**
     * Video name filter
     */
    videoName?: string,
    /**
     * Health status of the video
     */
    healthStatus?: Array<'OK' | 'ERROR' | 'UNHEALTHY' | 'PROCESSING'>,
    /**
     * Video which has a segment of the given activity
     */
    activity?: Array<string>,
  }): CancelablePromise<{
    meta: PaginationMeta;
    items: Array<Video>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/projects/{project_id}/videos',
      path: {
        'project_id': projectId,
      },
      query: {
        'offset': offset,
        'limit': limit,
        'dataset_id': datasetId,
        'video_status': videoStatus,
        'video_name': videoName,
        'health_status': healthStatus,
        'activity': activity,
      },
    });
  }

  /**
   * Import video to project
   * @returns Video Returns the uploaded video metadata
   * @throws ApiError
   */
  public static videosUpload({
    projectId,
    requestBody,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    requestBody?: (VideoUploadRequest | VideoExternalUploadRequest),
  }): CancelablePromise<Video> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/projects/{project_id}/videos',
      path: {
        'project_id': projectId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Fetch video metadata
   * @returns Video Successful read of video properties
   * @throws ApiError
   */
  public static videosGet({
    projectId,
    videoId,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Video ID
     */
    videoId: string,
  }): CancelablePromise<Video> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/projects/{project_id}/videos/{video_id}',
      path: {
        'project_id': projectId,
        'video_id': videoId,
      },
    });
  }

  /**
   * Move or rename an existing video
   * @returns Video Returns the video metadata
   * @throws ApiError
   */
  public static patchV1ProjectsVideos({
    projectId,
    videoId,
    requestBody,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Video ID
     */
    videoId: string,
    requestBody?: OmitReadonly<VideoMoveRenameRequest>,
  }): CancelablePromise<Video> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/v1/projects/{project_id}/videos/{video_id}',
      path: {
        'project_id': projectId,
        'video_id': videoId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Deletes video
   * @returns void
   * @throws ApiError
   */
  public static deleteV1ProjectsVideos({
    projectId,
    videoId,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Video ID
     */
    videoId: string,
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/v1/projects/{project_id}/videos/{video_id}',
      path: {
        'project_id': projectId,
        'video_id': videoId,
      },
    });
  }

  /**
   * Accepts thumbnail generation for video or returns the thumbnail if it's already been generated
   * @returns binary Rendered thumbnail
   * @returns any The request for thumbnail regeneration has been processed
   * @throws ApiError
   */
  public static getV1ProjectsVideosThumbnail({
    projectId,
    videoId,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Video ID
     */
    videoId: string,
  }): CancelablePromise<Blob | any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/projects/{project_id}/videos/{video_id}/thumbnail',
      path: {
        'project_id': projectId,
        'video_id': videoId,
      },
    });
  }

  /**
   * Fetch HLS playlist of the video
   * @returns string Successful read of HLS playlist
   * @throws ApiError
   */
  public static videosPlaylistGet({
    projectId,
    videoId,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Video ID
     */
    videoId: string,
  }): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/projects/{project_id}/videos/{video_id}/playlist',
      path: {
        'project_id': projectId,
        'video_id': videoId,
      },
    });
  }

  /**
   * Set video status
   * @returns void
   * @throws ApiError
   */
  public static putV1ProjectsVideosStatus({
    projectId,
    videoId,
    requestBody,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Video ID
     */
    videoId: string,
    requestBody?: {
      status: VideoStatus;
    },
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/v1/projects/{project_id}/videos/{video_id}/status',
      path: {
        'project_id': projectId,
        'video_id': videoId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
