import { call, put, takeEvery, select } from 'typed-redux-saga';

import { apiGetWidgetSegmentorInferences } from '../../../../../../../api/requests/widgets';
import { getErrorMessage } from '../../../../../../../api/utils';
import { activeProjectIdSelector } from '../../../../../project/project.selectors';
import {
  loadSegmentorInferences,
  loadSegmentorInferencesSuccess,
  loadSegmentorInferencesFailure,
  resetWidget,
} from './segmentorInferences.slice';
import { dashboardActiveExperimentIdSelector } from '../../activeExperiment/activeExperiment.selectors';
import { handleError } from '../../../../../commonFeatures/errorHandler/errorHandler.actions';
import {
  segmentorInferencesLimitSelector,
  segmentorInferencesOffsetSelector,
} from './segmentorInferences.selectors';
import { refetchWidgetData } from '../widgetsLiveUpdate/widgetsLiveUpdate.actions';
import { splitByIdSelector } from '../../../splits/data/data.selectors';
import { activeSplitIdSelector } from '../../../activeSplit/activeSplitId/activeSplitId.selectors';
import { setActiveExperimentId } from '../../activeExperiment/activeExperiment.slice';
import { ModelFamily } from '../../../../../../../api/constants/modelFamily';

function* loadSegmentorInferencesHandler(
  action: ActionType<typeof loadSegmentorInferences | typeof refetchWidgetData>,
) {
  const projectId = yield* select(activeProjectIdSelector);
  const experimentId = yield* select(dashboardActiveExperimentIdSelector);
  const activeSplitId = yield* select(activeSplitIdSelector);

  if (!projectId || !experimentId) return;

  if (action.type === refetchWidgetData.type && activeSplitId) {
    const activeSplit = yield* select((state: RootState) =>
      splitByIdSelector(state, activeSplitId),
    );

    if (!activeSplit) {
      return;
    }

    const { modelFamily } = activeSplit;

    if (modelFamily !== ModelFamily.Segmentor) {
      return;
    }
  }

  const limit =
    action?.payload?.limit || (yield* select(segmentorInferencesLimitSelector));
  const offset =
    action?.payload?.offset === undefined
      ? yield* select(segmentorInferencesOffsetSelector)
      : action?.payload?.offset;

  try {
    const { data } = yield* call(apiGetWidgetSegmentorInferences, {
      projectId,
      experimentId,
      params: { ...(action?.payload || {}), limit, offset },
    });
    const { items, meta } = data;

    yield* put(
      loadSegmentorInferencesSuccess({
        meta,
        items: items.map((item, index) => ({
          id: `${item.imageId}-${item.iteration}-${index}`,
          ...item,
        })),
      }),
    );
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to fetch widget data');

    yield* put(loadSegmentorInferencesFailure(message));

    yield* put(handleError({ message, error, allowOutsideOfEditor: true }));
  }
}

export function* setActiveExperimentIdHandler() {
  yield* put(resetWidget());
}

export function* segmentorInferencesSaga() {
  yield* takeEvery(
    [loadSegmentorInferences, refetchWidgetData],
    loadSegmentorInferencesHandler,
  );
  yield* takeEvery(setActiveExperimentId, setActiveExperimentIdHandler);
}
