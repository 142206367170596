import axios from 'axios';

import { API_URL } from '../../constants/paths';
import { apiWrapper } from '../utils';
import { headers, transformRequest, transformResponse } from '../helpers';
import { ApiRole } from '../domainModels/role';
import { ApiProjectRoleParams } from '../schemas/projectRoles';

export const apiLoadProjectRoles = (projectId: string) =>
  apiWrapper(
    axios.request<ApiRole[]>({
      method: 'get',
      url: `${API_URL}/project/${projectId}/roles`,
      headers,
      transformRequest,
      transformResponse,
    }),
  );

export const apiCreateProjectRole = (
  projectId: string,
  { name, color }: ApiProjectRoleParams,
) =>
  apiWrapper(
    axios.request<ApiRole>({
      method: 'post',
      url: `${API_URL}/project/${projectId}/role`,
      data: {
        role_name: name,
        color,
      },
      headers,
      transformRequest,
      transformResponse,
    }),
  );

export const apiUpdateProjectRole = (
  projectId: string,
  roleId: number,
  { name, color }: ApiProjectRoleParams,
) =>
  apiWrapper(
    axios.request<ApiRole>({
      method: 'put',
      url: `${API_URL}/project/${projectId}/role/${roleId}`,
      data: {
        role_name: name,
        color,
      },
      headers,
      transformRequest,
      transformResponse,
    }),
  );

export const apiDeleteProjectRole = (projectId: string, roleId: number) =>
  apiWrapper(
    axios.request<ApiRole>({
      method: 'delete',
      url: `${API_URL}/project/${projectId}/role/${roleId}`,
      headers,
      transformRequest,
      transformResponse,
    }),
  );
