import { createSelector } from '@reduxjs/toolkit';

import { widgetsSelector } from '../widgets.selectors';

const widgetsOptionsSelector = createSelector(
  widgetsSelector,
  (widgets) => widgets.widgetsOptions,
);

export const liveUpdateEnabledSelector = createSelector(
  widgetsOptionsSelector,
  (widgetsOptions) => widgetsOptions.liveUpdateEnabled,
);
