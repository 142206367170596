import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { setEditedProjectId } from '../../../../sections/editedProject/project/project.slice';

const initialState = { id: '' };
const { actions, reducer: activeSplitIdReducer } = createSlice({
  name: 'activeSplit/id',
  initialState,
  reducers: {
    setActiveSplitId: (state, action: PayloadAction<{ id: string }>) => {
      state.id = action.payload.id;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setEditedProjectId, () => initialState);
  },
});

export { activeSplitIdReducer };
export const { setActiveSplitId } = actions;
