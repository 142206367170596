import { createSelector } from '@reduxjs/toolkit';

import { componentsAdapter, parametersAdapter } from './regimens.slice';
import { modelPlaygroundSelector } from '../../modelPlayground.selectors';

export const experimentRegimensStateSelector = createSelector(
  modelPlaygroundSelector,
  (modelPlayground) => modelPlayground.activeExperiment.regimens,
);

export const experimentRegimensComponentsStateSelector = createSelector(
  experimentRegimensStateSelector,
  (state) => state.components,
);

const componentsSelectors = componentsAdapter.getSelectors(
  experimentRegimensComponentsStateSelector,
);
export const experimentRegimensDataSelector = componentsSelectors.selectAll;

export const selectedRegimenSelector = createSelector(
  experimentRegimensDataSelector,
  (regimens) => regimens.find((regimen) => regimen.selected),
);
export const selectedRegimenIdSelector = createSelector(
  selectedRegimenSelector,
  (selectedRegimen) => selectedRegimen?.id,
);
export const selectedRegimenComponentIdSelector = createSelector(
  selectedRegimenSelector,
  (selectedRegimen) => selectedRegimen?.componentId,
);
export const experimentRegimensLoadingStateSelector = createSelector(
  experimentRegimensComponentsStateSelector,
  (state) => state.loadingState,
);
export const experimentRegimensUpdateLoadingStateSelector = createSelector(
  experimentRegimensComponentsStateSelector,
  (state) => state.updateLoadingState,
);

export const regimenParametersStateSelector = createSelector(
  experimentRegimensStateSelector,
  (state) => state.parameters,
);

const parametersSelectors = parametersAdapter.getSelectors(
  regimenParametersStateSelector,
);
export const regimenParametersSelector = parametersSelectors.selectAll;

export const regimenParametersLoadingStateSelector = createSelector(
  regimenParametersStateSelector,
  (state) => state.loadingState,
);
export const regimenParametersUpdateLoadingStateSelector = createSelector(
  regimenParametersStateSelector,
  (state) => state.updateLoadingState,
);
export const regimenParameterByIdSelector = (state: RootState, id: string) => {
  const parameters = regimenParametersSelector(state);

  return parameters.find((parameter) => parameter.id === id);
};
