import { createAction } from '@reduxjs/toolkit';

export const lockImage = createAction<{ projectId: string; imageId: string }>(
  'imageViewCurrentImageLock/lockImage',
);
export const unlockImage = createAction<{
  imageId: string;
  projectId: string;
}>('imageViewCurrentImageLock/unlockImage');

export const renewLock = createAction(
  'imageViewCurrentImageLock/renewLock',
  () => ({ payload: undefined, meta: { noLogging: true } }),
);
