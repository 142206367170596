import { select, put, takeEvery } from 'typed-redux-saga';

import {
  loadTriggerDataFromLocalStorage,
  loadTriggerDataFromLocalStorageSuccess,
  updateTriggerStatus,
  updateTriggerStatusSuccess,
  updateTriggerData,
} from './triggers.slice';
import {
  TriggerData,
  TriggerIds,
  TriggerStatus,
  TriggerStatusRaw,
} from '../../../../../@types/editedProject/Trigger.types';
import { activeProjectIdSelector } from '../../../project/project.selectors';
import { triggerPrivileges } from '../../../../../constants/trigger';
import {
  getLocalStorageItem,
  upsertLocalStorageItem,
} from '../../../../../helpers/localStorage';
import {
  getTriggerDataKey,
  getTriggerStatusKey,
} from '../../../../../helpers/localStorage/types';

const mapRawStatusToStatus = (fromLocalStorage: TriggerStatusRaw | null) => {
  switch (fromLocalStorage) {
    case null: {
      return TriggerStatus.Pending;
    }
    case TriggerStatusRaw.Visible: {
      return TriggerStatus.Visible;
    }
    case TriggerStatusRaw.Hidden: {
      return TriggerStatus.Hidden;
    }
    case TriggerStatusRaw.Completed: {
      return TriggerStatus.Completed;
    }
    default: {
      return TriggerStatus.Pending;
    }
  }
};

const mapStatusToRawStatus = (status: TriggerStatus) => {
  switch (status) {
    case TriggerStatus.Visible: {
      return TriggerStatusRaw.Visible;
    }
    case TriggerStatus.Hidden: {
      return TriggerStatusRaw.Hidden;
    }
    case TriggerStatus.Completed: {
      return TriggerStatusRaw.Completed;
    }
    default: {
      return TriggerStatusRaw.Visible;
    }
  }
};

function* loadTriggerDataFromLocalStorageHandler() {
  const projectId = yield* select(activeProjectIdSelector);

  const items = Object.keys(triggerPrivileges).map((id) => {
    const triggerId = id as TriggerIds;

    const triggerStatusKey = getTriggerStatusKey(triggerId, projectId);
    const rawStatus = getLocalStorageItem<TriggerStatusRaw>(
      triggerStatusKey,
      null,
    );

    const status = mapRawStatusToStatus(rawStatus);

    const triggerDataKey = getTriggerDataKey(triggerId, projectId);
    const data = getLocalStorageItem(triggerDataKey, undefined) as
      | TriggerData
      | undefined;

    return { id: triggerId, status, data };
  });

  yield* put(loadTriggerDataFromLocalStorageSuccess(items));
}

function* updateTriggerStatusHandler(
  action: ActionType<typeof updateTriggerStatus>,
) {
  const { id, status } = action.payload;
  const projectId = yield* select(activeProjectIdSelector);

  const triggerId = id as TriggerIds;

  const triggerStatusKey = getTriggerStatusKey(triggerId, projectId);
  const rawStatus = mapStatusToRawStatus(status);
  upsertLocalStorageItem(triggerStatusKey, rawStatus);

  yield* put(
    updateTriggerStatusSuccess({ id: triggerId, changes: { status } }),
  );
}

function* updateTriggerDataHandler(
  action: ActionType<typeof updateTriggerData>,
) {
  const { id, data } = action.payload;
  const projectId = yield* select(activeProjectIdSelector);

  const triggerId = id as TriggerIds;
  const triggerDataKey = getTriggerDataKey(triggerId, projectId);

  const existingData = getLocalStorageItem<TriggerData>(
    triggerDataKey,
    undefined,
  );

  const dataToWrite = { ...(existingData || {}), ...data };

  upsertLocalStorageItem(triggerDataKey, dataToWrite);
}

export function* triggersSaga() {
  yield* takeEvery(
    loadTriggerDataFromLocalStorage,
    loadTriggerDataFromLocalStorageHandler,
  );
  yield* takeEvery(updateTriggerStatus, updateTriggerStatusHandler);
  yield* takeEvery(updateTriggerData, updateTriggerDataHandler);
}
