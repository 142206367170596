/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type MetricMeta = {
  /**
   * Metric ID
   */
  id?: string;
  /**
   * Human readable name
   */
  title?: string;
  /**
   * What is better, max or min value
   */
  comparisonMode?: MetricMeta.comparisonMode;
  /**
   * Metrics datatype
   */
  datatype?: MetricMeta.datatype;
  /**
   * How the metric should be formatted
   */
  format?: MetricMeta.format | null;
};

export namespace MetricMeta {

  /**
   * What is better, max or min value
   */
  export enum comparisonMode {
    MAXIMUM = 'maximum',
    MINIMUM = 'minimum',
  }

  /**
   * Metrics datatype
   */
  export enum datatype {
    NUMBER = 'number',
    INTEGER = 'integer',
  }

  /**
   * How the metric should be formatted
   */
  export enum format {
    PERCENT = 'percent',
    MILLISECOND = 'millisecond',
    BYTE = 'byte',
  }


}

