import { LOCATION_CHANGE } from 'connected-react-router';
import { put, takeEvery } from 'typed-redux-saga';

import { closeNotification } from './stackNotifications.slice';

function* locationChangeHandler() {
  yield* put(closeNotification({ dismissAll: true }));
}

export function* stackNotifications() {
  yield* takeEvery(LOCATION_CHANGE, locationChangeHandler);
}
