import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLeading,
} from 'typed-redux-saga';

import {
  apiLoadReportsAggEntities,
  apiLoadReportsKpis,
} from '../../../../api/requests/projectReportEntities';
import { apiCreateProjectReport } from '../../../../api/requests/project';
import {
  loadProjectOperationsReportEntitiesSuccess,
  loadProjectOperationsReportEntitiesFailure,
  loadProjectOperationsReportEntities,
  triggerProjectReport,
} from './operations.slice';
import { projectOperationsReportEntitiesLoadingStateSelector } from './operations.selectors';
import { getErrorMessage } from '../../../../api/utils';
import { getProjectById } from '../../../utils/backendParams';
import { LoadingState } from '../../../utils/loadingState';
import { LoadingStatus } from '../../../../constants/status';
import {
  projectByIdSelector,
  projectListDataSelector,
} from '../../sections/projectList/projects/projects.selectors';
import { handleError } from '../../commonFeatures/errorHandler/errorHandler.actions';
import { enqueueNotification } from '../../ui/stackNotifications/stackNotifications.slice';
import { NOTIFICATION_SENT } from '../../ws/ws.constants';
import { invalidateQueries } from '../../../../api/api';
import { Notification, NotificationsService } from '../../../../api/codegen';
import { WebsocketNotificationPayload } from '../../../../api/domainModels/websocketTypes';

type OperationsConfigType = Partial<
  Record<
    Notification.subject,
    { failure?: boolean; jobFetchRequired?: boolean }
  >
>;

const processableOperationsConfig: OperationsConfigType = {
  [Notification.subject.NOTIFICATION_SUBJECT_MODEL_EXPORTED]: {
    jobFetchRequired: true,
  },
  [Notification.subject.NOTIFICATION_SUBJECT_PROJECT_EXPORTED]: {
    jobFetchRequired: true,
  },
  [Notification.subject.NOTIFICATION_SUBJECT_PROJECT_EXPORT_FAILED]: {
    failure: true,
  },
  [Notification.subject.NOTIFICATION_SUBJECT_PROJECT_EXPORT_STARTED]: {},
  [Notification.subject.NOTIFICATION_SUBJECT_REPORT_CREATION_STARTED]: {},
  [Notification.subject.NOTIFICATION_SUBJECT_PROJECT_EXPORT_QUEUED]: {},
  [Notification.subject.NOTIFICATION_SUBJECT_PROJECT_COPYING_QUEUED]: {},
  [Notification.subject.NOTIFICATION_SUBJECT_REPORT_CREATED]: {
    jobFetchRequired: true,
  },
  [Notification.subject.NOTIFICATION_SUBJECT_REPORT_CREATION_FAILED]: {
    failure: true,
  },
  [Notification.subject.NOTIFICATION_SUBJECT_PROJECT_COPIED]: {
    failure: false,
  },
  [Notification.subject.NOTIFICATION_SUBJECT_PROJECT_COPYING_FAILED]: {
    failure: true,
  },
  [Notification.subject.NOTIFICATION_SUBJECT_DATASET_STATUS_UPDATE_STARTED]: {
    jobFetchRequired: true,
  },
};

const processableOperations = Object.keys(
  processableOperationsConfig,
) as Notification.subject[];

export const subjectsWithJobFetchRequired = Object.keys(
  processableOperationsConfig,
).filter(
  (key) =>
    !!processableOperationsConfig[
      key as keyof typeof processableOperationsConfig
    ]?.jobFetchRequired,
);

function* triggerProjectReportHandler({
  payload: { projectId, ...rest },
}: ActionType<typeof triggerProjectReport>) {
  try {
    yield* call(
      apiCreateProjectReport,
      {
        projectId,
      },
      rest,
    );
    const project = yield* select((state: RootState) =>
      projectByIdSelector(state, projectId),
    );

    if (!project) {
      return;
    }

    yield* put(
      enqueueNotification({
        message: `Your report for project ${project.projectName} is being created. The progress information is available in the notification center.`,
        options: {
          variant: 'success',
          allowOutsideOfEditor: true,
        },
      }),
    );
  } catch (error) {
    const errorMessage = getErrorMessage(
      error,
      'Not able to get create report',
    );

    yield* put(
      handleError({
        message: errorMessage,
        allowOutsideOfEditor: true,
        error,
      }),
    );
  }
}

function* addNotificationFromWebsocketHandler({
  payload: notification,
}: WebsocketNotificationPayload & { type: string }) {
  invalidateQueries(NotificationsService.getV1NotificationsStatus);

  if (
    !processableOperations.includes(notification.subject) ||
    !notification.projectId
  ) {
    return;
  }

  const projects = yield* select(projectListDataSelector);
  const project = getProjectById(projects, notification.projectId);

  if (!project) {
    return;
  }

  const successMessages: Partial<Record<Notification.subject, string>> = {
    [Notification.subject
      .NOTIFICATION_SUBJECT_PROJECT_EXPORTED]: `Your export for project ${project?.projectName} is now available for download in the notification center`,
    [Notification.subject
      .NOTIFICATION_SUBJECT_REPORT_CREATED]: `Your report for project ${project?.projectName} is now available for download in the notification center`,
  };

  if (processableOperationsConfig[notification.subject]?.failure) {
    if (notification.text) {
      yield* put(
        enqueueNotification({
          message: notification.text,
          options: {
            variant: 'error',
            allowOutsideOfEditor: true,
            refresh: false,
          },
        }),
      );
    }
  } else if (notification.jobId) {
    const message = successMessages[notification.subject] || notification.text;
    if (message) {
      yield* put(
        enqueueNotification({
          message,
          options: {
            variant: 'success',
            allowOutsideOfEditor: true,
          },
        }),
      );
    }
  }
}

function* loadProjectOperationsReportEntitiesHandler() {
  const loadingState: LoadingState = yield* select(
    projectOperationsReportEntitiesLoadingStateSelector,
  );

  if (loadingState.status !== LoadingStatus.Success) {
    try {
      const { aggEntitiesResponse, kpisResponse } = yield* all({
        aggEntitiesResponse: call(apiLoadReportsAggEntities),
        kpisResponse: call(apiLoadReportsKpis),
      });

      yield* put(
        loadProjectOperationsReportEntitiesSuccess({
          aggEntities: aggEntitiesResponse.data,
          kpis: kpisResponse.data,
        }),
      );
    } catch (e) {
      loadProjectOperationsReportEntitiesFailure(
        getErrorMessage(e, 'Not able to load the report config'),
      );
    }
  }
}

export function* projectOperationsSaga() {
  yield* takeEvery(triggerProjectReport, triggerProjectReportHandler);
  yield* takeEvery(NOTIFICATION_SENT, addNotificationFromWebsocketHandler);
  yield* takeLeading(
    loadProjectOperationsReportEntities,
    loadProjectOperationsReportEntitiesHandler,
  );
}
