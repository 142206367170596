import {
  createSlice,
  PayloadAction,
  createEntityAdapter,
} from '@reduxjs/toolkit';

import { LabelClassConfidenceHist } from '../../../../../../api/domainModels/consensusScoring';
import { loadingStateBuilder } from '../../../../../utils/loadingState';

export type HistogramType = 'classConfidence' | 'classIou';

type LabelClassHistogram = {
  id: HistogramType;
  data: LabelClassConfidenceHist;
};

export enum Histogram {
  Confidence = 'classConfidence',
  Iou = 'classIou',
}

export const adapter = createEntityAdapter<LabelClassHistogram>({});
const { actions, reducer: labelClassConfidenceHistReducer } = createSlice({
  name: 'labelClassConfidenceHist',
  initialState: adapter.getInitialState({
    confidenceLoadingState: loadingStateBuilder.initial(),
    iouLoadingState: loadingStateBuilder.initial(),
  }),
  reducers: {
    loadLabelClassConfidenceHist(state, _action: PayloadAction<string | null>) {
      state.confidenceLoadingState = loadingStateBuilder.inProgress();
    },
    loadLabelClassIouHist(state, _action: PayloadAction<string | null>) {
      state.iouLoadingState = loadingStateBuilder.inProgress();
    },
    loadHist(
      _state,
      _action: PayloadAction<{
        labelClassId: string | null;
        type: HistogramType;
      }>,
    ) {},
    loadHistSuccess(state, action: PayloadAction<LabelClassHistogram>) {
      const { id } = action.payload;

      if (id === Histogram.Confidence) {
        state.confidenceLoadingState = loadingStateBuilder.success();
      } else {
        state.iouLoadingState = loadingStateBuilder.success();
      }

      adapter.upsertOne(state, action.payload);
    },
    loadHistFailure(
      state,
      action: PayloadAction<{ id: HistogramType; message: string }>,
    ) {
      const { id, message } = action.payload;

      if (id === Histogram.Confidence) {
        state.confidenceLoadingState = loadingStateBuilder.failure(message);
      } else {
        state.iouLoadingState = loadingStateBuilder.failure(message);
      }
    },
  },
});

export { labelClassConfidenceHistReducer };
export const {
  loadHist,
  loadLabelClassConfidenceHist,
  loadLabelClassIouHist,
  loadHistSuccess,
  loadHistFailure,
} = actions;
