import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  EnrichedErrorAttributeLabel,
  EnrichedErrorClLabel,
  EnrichedErrorOdIsLabel,
  EnrichedErrorSemanticLabel,
} from '../../../../../api/domainModels/consensusScoring';

const initialState: {
  enabled: boolean;
  activeLabel:
    | EnrichedErrorClLabel
    | EnrichedErrorOdIsLabel
    | EnrichedErrorAttributeLabel
    | EnrichedErrorSemanticLabel
    | null;
  contextMenu: {
    visible: boolean;
    showActions: boolean;
    top: number;
    left: number;
  };
} = {
  enabled: true,
  activeLabel: null,
  contextMenu: {
    visible: false,
    showActions: false,
    top: 0,
    left: 0,
  },
};
const { actions, reducer: consensusScoringReducer } = createSlice({
  name: 'consensusScoring',
  initialState,
  reducers: {
    showCSContextMenu(
      state,
      action: PayloadAction<{
        top: number;
        left: number;
      }>,
    ) {
      state.contextMenu = {
        ...state.contextMenu,
        ...action.payload,
        visible: true,
      };
    },
    showCSContextMenuActions(state) {
      state.contextMenu.showActions = true;
    },
    hideCSContextMenu(state) {
      state.contextMenu = initialState.contextMenu;
      state.activeLabel = initialState.activeLabel;
    },
    setConsensusScoringEnabled(state, action: PayloadAction<boolean>) {
      state.enabled = action.payload;
    },
    setActiveLabel(
      state,
      action: PayloadAction<typeof initialState.activeLabel>,
    ) {
      state.activeLabel = action.payload;
    },
    loadCSData() {},
  },
});

export const {
  showCSContextMenu,
  showCSContextMenuActions,
  hideCSContextMenu,
  setConsensusScoringEnabled,
  setActiveLabel,
  loadCSData,
} = actions;

export { consensusScoringReducer };
