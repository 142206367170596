import { createSelector } from '@reduxjs/toolkit';

import { widgetsSelector } from '../widgets.selectors';
import {
  methodsAdapter,
  runsAdapter,
  resultsAdapter,
} from './explainability.slice';
import { ExplainabilityRunJobStatus } from '../../../../../../../api/constants/widgets';

const explainabilityStateSelector = createSelector(
  widgetsSelector,
  (widgets) => widgets.explainability,
);

const explainabilityRunsStateSelector = createSelector(
  explainabilityStateSelector,
  (explainability) => explainability.runs,
);

const explainabilityMethodsStateSelector = createSelector(
  explainabilityStateSelector,
  (explainability) => explainability.methods,
);

const explainabilityRunResultsStateSelector = createSelector(
  explainabilityStateSelector,
  (explainability) => explainability.results,
);

export const explainabilityShowSaliencyStateSelector = createSelector(
  explainabilityStateSelector,
  (explainability) => explainability.showSaliency,
);

const runSelectors = runsAdapter.getSelectors(explainabilityRunsStateSelector);

export const explainabilityRunsListLoadingStateSelector = createSelector(
  explainabilityRunsStateSelector,
  (state) => state.listLoadingState,
);

export const explainabilityRunsItemLoadingStateSelector = createSelector(
  explainabilityRunsStateSelector,
  (state) => state.itemLoadingState,
);

export const explainabilityRunsSelector = runSelectors.selectAll;
export const explainabilityRunByIdSelector = runSelectors.selectById;

export const explainabilityFinishedRunsSelector = createSelector(
  explainabilityRunsSelector,
  (allRuns) =>
    allRuns.filter(({ status }) =>
      [
        ExplainabilityRunJobStatus.Done,
        ExplainabilityRunJobStatus.Failed,
      ].includes(status),
    ),
);

export const explainabilityFinishedRunsWithResultsSelector = createSelector(
  explainabilityFinishedRunsSelector,
  (finishedRuns) => finishedRuns.filter(({ numObjects }) => numObjects > 0),
);

export const explainabilityFinishedRunsWithResultsAsOptionsSelector =
  createSelector(explainabilityFinishedRunsWithResultsSelector, (runs) =>
    runs.map(({ name, id }) => ({ key: id, value: id, text: name })),
  );

const resultsSelectors = resultsAdapter.getSelectors(
  explainabilityRunResultsStateSelector,
);

export const explainabilityRunResultsSelector = resultsSelectors.selectAll;
export const explainabilityRunResultsIdsSelector =
  resultsSelectors.selectIds as (state: RootState) => string[];
export const explainabilityRunResultByIdSelector = resultsSelectors.selectById;

export const explainabilityRunResultsListLoadingStateSelector = createSelector(
  explainabilityRunResultsStateSelector,
  (state) => state.listLoadingState,
);

export const explainabilityRunSelectedResultIdSelector = createSelector(
  explainabilityRunResultsStateSelector,
  (state) => state.selectedResultId,
);

export const explainabilityRunSelectedResultIndex = createSelector(
  [
    explainabilityRunSelectedResultIdSelector,
    explainabilityRunResultsIdsSelector,
  ],
  (currentResultId, resultsIds) => {
    if (!currentResultId) return -1;

    return resultsIds.findIndex((id) => id === currentResultId);
  },
);

export const explainabilityRunNextResultAvailableSelector = createSelector(
  [explainabilityRunSelectedResultIndex, explainabilityRunResultsIdsSelector],
  (currentResultIndex, resultsIds) => {
    if (currentResultIndex === -1) return false;

    return (
      currentResultIndex >= 0 && currentResultIndex < resultsIds.length - 1
    );
  },
);

export const explainabilityRunNextResultIdSelector = createSelector(
  [explainabilityRunSelectedResultIndex, explainabilityRunResultsIdsSelector],
  (currentResultIndex, resultsIds) => resultsIds[currentResultIndex + 1],
);

export const explainabilityRunPreviousResultAvailableSelector = createSelector(
  [explainabilityRunSelectedResultIndex],
  (currentResultIndex) => currentResultIndex > 0,
);

export const explainabilityRunPreviousResultIdSelector = createSelector(
  [explainabilityRunSelectedResultIndex, explainabilityRunResultsIdsSelector],
  (currentResultIndex, resultsIds) => resultsIds[currentResultIndex - 1],
);

export const explainabilityRunResultsMetaStateSelector = createSelector(
  explainabilityRunResultsStateSelector,
  (state) => state.meta,
);

const methodsSelectors = methodsAdapter.getSelectors(
  explainabilityMethodsStateSelector,
);

export const explainabilityMethodsListLoadingStateSelector = createSelector(
  explainabilityMethodsStateSelector,
  (state) => state.listLoadingState,
);

export const explainabilityMethodsSelector = methodsSelectors.selectAll;

export const explainabilityMethodsAsOptionsSelector = createSelector(
  explainabilityMethodsSelector,
  (allMethods) =>
    allMethods.map(({ id, name, wikiUrl }) => ({
      id,
      value: id,
      text: name,
      wikiUrl,
    })),
);
