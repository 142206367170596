import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
  Update,
} from '@reduxjs/toolkit';

import { loadingStateBuilder } from '../../../../utils/loadingState';
import {
  KeypointSchema,
  UpsertKeypointsSchemaFormValues,
} from '../../../../../api/domainModels/keypoints';
import { hideModals } from '../../../ui/modals/modals.slice';

export const keypointsSchemasAdapter = createEntityAdapter<KeypointSchema>({
  sortComparer: (a, b) => ((a.norder ?? 0) > (b.norder ?? 0) ? 1 : -1),
});
const initialState = keypointsSchemasAdapter.getInitialState({
  itemLoadingState: loadingStateBuilder.initial(),
  listLoadingState: loadingStateBuilder.initial(),
});

const { actions, reducer: keypointsSchemasReducer } = createSlice({
  name: 'keypoints',
  initialState,
  reducers: {
    loadKeypointsSchemasStart(state) {
      state.listLoadingState = loadingStateBuilder.inProgress();
    },
    loadKeypointsSchemasFailure(state) {
      keypointsSchemasAdapter.setAll(state, []);
    },
    loadKeypointsSchemasSuccess(
      state,
      action: PayloadAction<KeypointSchema[]>,
    ) {
      state.listLoadingState = loadingStateBuilder.success();
      keypointsSchemasAdapter.setAll(state, action.payload);
    },
    addKeypointsSchema(
      state,
      _action: PayloadAction<UpsertKeypointsSchemaFormValues>,
    ) {
      state.itemLoadingState = loadingStateBuilder.inProgress();
    },
    addKeypointsSchemaFailure(state) {
      state.itemLoadingState = loadingStateBuilder.failure();
    },
    addKeypointsSchemaSuccess(state, action: PayloadAction<KeypointSchema>) {
      state.itemLoadingState = loadingStateBuilder.success();
      keypointsSchemasAdapter.addOne(state, action.payload);
    },
    removeKeypointsSchema(state, _action: PayloadAction<{ schemaId: string }>) {
      state.itemLoadingState = loadingStateBuilder.inProgress();
    },
    removeKeypointsSchemaSuccess(
      state,
      action: PayloadAction<{ schemaId: string }>,
    ) {
      state.itemLoadingState = loadingStateBuilder.success();
      keypointsSchemasAdapter.removeOne(state, action.payload.schemaId);
    },
    removeKeypointsSchemaFailure(state) {
      state.itemLoadingState = loadingStateBuilder.failure();
    },
    updateKeypointsSchemaOrder(
      _state,
      _action: PayloadAction<{
        schemaId: string;
        index: number;
      }>,
    ) {},
    updateKeypointSchema(
      state,
      _action: PayloadAction<
        UpsertKeypointsSchemaFormValues & {
          schemaId: string;
        }
      >,
    ) {
      state.itemLoadingState = loadingStateBuilder.inProgress();
    },
    updateKeypointSchemaSuccess(
      state,
      action: PayloadAction<Update<KeypointSchema>>,
    ) {
      state.itemLoadingState = loadingStateBuilder.success();
      keypointsSchemasAdapter.updateOne(state, action.payload);
    },
    updateKeypointSchemaFailure(state) {
      state.itemLoadingState = loadingStateBuilder.failure();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(hideModals, (state) => {
      state.itemLoadingState = loadingStateBuilder.initial();
    });
  },
});

export const {
  loadKeypointsSchemasStart,
  loadKeypointsSchemasFailure,
  loadKeypointsSchemasSuccess,
  addKeypointsSchema,
  addKeypointsSchemaFailure,
  addKeypointsSchemaSuccess,
  removeKeypointsSchema,
  removeKeypointsSchemaSuccess,
  removeKeypointsSchemaFailure,
  updateKeypointsSchemaOrder,
  updateKeypointSchema,
  updateKeypointSchemaSuccess,
  updateKeypointSchemaFailure,
} = actions;
export { keypointsSchemasReducer };
