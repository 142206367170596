import {
  put,
  select,
  takeEvery,
  takeLatest,
  all,
  SagaGenerator,
  call,
} from 'typed-redux-saga';

import { experimentComponentDataMapper } from '../../../../../../api/domainModels/modelPlayground';
import {
  apiGetExperimentComponents,
  apiUpdateExperimentComponent,
  apiGetComponentParameters,
  apiUpdateComponentParameter,
  apiDeleteExperimentComponent,
  apiAddExperimentComponent,
  apiGetTransformsPreview,
} from '../../../../../../api/requests/modelPlayground';
import { getErrorMessage } from '../../../../../../api/utils';
import { loadAll } from '../../../../../utils/api';
import { activeProjectIdSelector } from '../../../../project/project.selectors';
import { hideModals } from '../../../../ui/modals/modals.slice';
import { activeExperimentIdSelector } from '../selectors';
import { parseParameterValue } from '../parameters.helpers';
import {
  transformParameterByIdSelector,
  transformsMaxOrderSelector,
  selectedTransformsSelector,
  experimentTransformsDataSelector,
} from './transforms.selectors';
import {
  loadTransforms,
  loadTransformsSuccess,
  loadTransformsFailure,
  addExperimentTransforms,
  addExperimentTransformsFailure,
  addExperimentTransformsSuccess,
  reorderExperimentTransforms,
  reorderExperimentTransformsSuccess,
  reorderExperimentTransformsFailure,
  removeExperimentTransform,
  removeExperimentTransformFailure,
  removeExperimentTransformSuccess,
  loadTransformParameters,
  loadTransformParametersSuccess,
  loadTransformParametersFailure,
  updateTransformParameter,
  updateTransformParameterSuccess,
  updateTransformParameterFailure,
  loadTransformsPreview,
  loadTransformsPreviewSuccess,
  loadTransformsPreviewFailure,
  updateTransformParameterResetLoadingState,
  addPreviewId,
} from './transforms.slice';
import { isParameterValid } from '../../../../../../containers/modelPlayground/experiment/sections/helpers';
import { enqueueNotification } from '../../../../ui/stackNotifications/stackNotifications.slice';
import { Component } from '../../../../../../api/constants/modelPlayground';

function* loadTransformsHandler(action: ActionType<typeof loadTransforms>) {
  try {
    const projectId = yield* select(activeProjectIdSelector);
    const experimentId = yield* select(activeExperimentIdSelector);
    const partitionName = action.payload;
    const items = yield* loadAll({
      apiHelper: apiGetExperimentComponents,
      params: {
        projectId,
        experimentId,
        componentType: Component.Transform,
        params: {
          partitionName,
        },
      },
    });
    const data = items.map(experimentComponentDataMapper.fromBackend);
    yield* put(loadTransformsSuccess(data));
    const parametersToFetchCalls: SagaGenerator<any>[] = [];
    data.forEach((transform) => {
      if (transform.selected) {
        const id = transform.experimentComponentId || transform.id;
        parametersToFetchCalls.push(
          put(
            loadTransformParameters({
              id,
              partition: partitionName,
            }),
          ),
        );
      }
    });
    yield* all(parametersToFetchCalls);
  } catch (e) {
    yield* put(
      loadTransformsFailure(getErrorMessage(e, 'Not able to load transforms')),
    );
  }
}

function* addTransformsHandler(
  action: ActionType<typeof addExperimentTransforms>,
) {
  const { transformsIds, partition } = action.payload;
  try {
    const projectId = yield* select(activeProjectIdSelector);
    const experimentId = yield* select(activeExperimentIdSelector);

    if (!experimentId) {
      return;
    }

    const componentType: Component = Component.Transform;
    const params = {
      projectId,
      experimentId,
      componentType,
      params: { partitionName: partition },
    };
    const currentMaxOrder = yield* select(transformsMaxOrderSelector);
    const data = transformsIds.map((id, index) => ({
      norder: currentMaxOrder + 1 + index,
      transformId: id,
    }));
    const { data: response } = yield* call(
      apiAddExperimentComponent,
      params,
      data,
    );
    yield* put(
      addExperimentTransformsSuccess(
        response.map(experimentComponentDataMapper.fromBackend),
      ),
    );
    yield* put(hideModals());
    const parametersToFetchCalls: SagaGenerator<any>[] = [];
    response.forEach((transform) => {
      if (transform.selected)
        parametersToFetchCalls.push(
          put(
            loadTransformParameters({
              id: transform.experimentComponentId || transform.id,
              partition,
            }),
          ),
        );
    });
    yield* all(parametersToFetchCalls);
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to add transform');

    yield* put(addExperimentTransformsFailure(message));
    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
          refresh: false,
        },
      }),
    );
  }
}

function* reorderTransformsHandler(
  action: ActionType<typeof reorderExperimentTransforms>,
) {
  const { id, newOrder, oldOrder, partition } = action.payload;
  try {
    const projectId = yield* select(activeProjectIdSelector);
    const experimentId = yield* select(activeExperimentIdSelector);

    if (!experimentId) {
      return;
    }

    const componentType: Component = Component.Transform;
    const params = {
      projectId,
      experimentId,
      componentType,
      params: { partitionName: partition },
    };
    const moreThan = newOrder < oldOrder ? newOrder : oldOrder;
    const lessThan = newOrder < oldOrder ? oldOrder : newOrder;
    const transforms = yield* select(selectedTransformsSelector);
    const transformsToUpdateOrder = transforms.filter(
      (transform) =>
        transform.norder &&
        transform.norder >= moreThan &&
        transform.norder &&
        transform.norder <= lessThan,
    );
    if (transformsToUpdateOrder.length === 0) return;
    const data = transformsToUpdateOrder.map((transform) => ({
      experimentComponentId: transform.experimentComponentId || transform.id,
      norder:
        transform.id === id
          ? newOrder
          : (transform.norder || 0) + (newOrder < oldOrder ? 1 : -1),
    }));
    const { data: response } = yield* call(() =>
      apiUpdateExperimentComponent(params, data),
    );
    yield* put(
      reorderExperimentTransformsSuccess(
        response.map(experimentComponentDataMapper.fromBackend),
      ),
    );
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to update transform');

    yield* put(reorderExperimentTransformsFailure(message));
    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
          refresh: false,
        },
      }),
    );
  }
}

function* removeTransformHandler(
  action: ActionType<typeof removeExperimentTransform>,
) {
  try {
    const { component, partition } = action.payload;
    const projectId = yield* select(activeProjectIdSelector);
    const experimentId = yield* select(activeExperimentIdSelector);

    if (!experimentId) {
      return;
    }

    const componentType: Component = Component.Transform;
    const params = {
      projectId,
      experimentId,
      componentType,
      params: {
        partitionName: partition,
        experimentComponentId: component.experimentComponentId || component.id,
      },
    };
    yield* call(apiDeleteExperimentComponent, params);

    const transforms = yield* select(experimentTransformsDataSelector);
    const isSingleRepresentative =
      transforms.filter(
        (transform) => transform.componentId === component.componentId,
      ).length === 1; // if there's a single item with this component id reset it's id instead of removing it from list

    yield* put(
      removeExperimentTransformSuccess({
        id: component.id,
        isSingleRepresentative,
      }),
    );
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to remove transform');

    yield* put(removeExperimentTransformFailure(message));
    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
          refresh: false,
        },
      }),
    );
  }
}

function* loadParametersHandler(
  action: ActionType<typeof loadTransformParameters>,
) {
  const { id, partition } = action.payload;
  try {
    const projectId = yield* select(activeProjectIdSelector);
    const experimentId = yield* select(activeExperimentIdSelector);

    if (!experimentId) {
      return;
    }

    const componentType: Component = Component.Transform;
    const items = yield* loadAll({
      apiHelper: apiGetComponentParameters,
      params: {
        projectId,
        experimentId,
        componentId: id,
        componentType,
        params: {
          partitionName: partition,
        },
      },
    });
    yield* put(loadTransformParametersSuccess({ id, data: items }));
    if (items.every((item) => isParameterValid(item))) {
      yield* put(addPreviewId(id));
    }
  } catch (e) {
    yield* put(
      loadTransformParametersFailure({
        id,
        message: getErrorMessage(e, 'Not able to load parameters'),
      }),
    );
  }
}

function* updateParameterHandler(
  action: ActionType<typeof updateTransformParameter>,
) {
  const { id, partition, transformParameterId, value } = action.payload;
  try {
    const projectId = yield* select(activeProjectIdSelector);
    const experimentId = yield* select(activeExperimentIdSelector);

    if (!experimentId) {
      return;
    }

    const componentType: Component = Component.Transform;
    const params = {
      projectId,
      experimentId,
      componentId: id,
      componentType,
      params: { partitionName: partition },
    };
    const data = { transformParameterId, value };
    const editedParameter = yield* select((state: RootState) =>
      transformParameterByIdSelector(state, id, transformParameterId),
    );

    if (editedParameter) {
      if (!isParameterValid({ ...editedParameter, selectedValue: value })) {
        yield put(updateTransformParameterResetLoadingState());

        return;
      }
      data.value = parseParameterValue(editedParameter, value);
    }
    const { data: response } = yield* call(
      apiUpdateComponentParameter,
      params,
      data,
    );

    yield* put(
      updateTransformParameterSuccess({
        parameter: response,
        id,
      }),
    );
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to update parameter');

    yield* put(updateTransformParameterFailure(message));
    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
          refresh: false,
        },
      }),
    );
  }
}

function* loadPreviewHandler(action: ActionType<typeof loadTransformsPreview>) {
  try {
    const projectId: string = yield* select(activeProjectIdSelector);
    const experimentId = yield* select(activeExperimentIdSelector);
    const { partition, experimentComponentIds, objectId } = action.payload;

    if (!experimentId) {
      return;
    }

    const { data } = yield* call(
      apiGetTransformsPreview,
      {
        projectId,
        experimentId,
        params: {
          partitionName: partition,
        },
      },
      {
        experimentComponentIds,
        objectId,
      },
    );
    yield* put(loadTransformsPreviewSuccess(data));
  } catch (e) {
    yield* put(
      loadTransformsPreviewFailure(
        getErrorMessage(e, 'Not able to load preview'),
      ),
    );
  }
}

export function* transformsSaga() {
  yield* takeEvery(loadTransforms, loadTransformsHandler);
  yield* takeEvery(addExperimentTransforms, addTransformsHandler);
  yield* takeEvery(reorderExperimentTransforms, reorderTransformsHandler);
  yield* takeEvery(removeExperimentTransform, removeTransformHandler);
  yield* takeEvery(loadTransformParameters, loadParametersHandler);
  yield* takeEvery(updateTransformParameter, updateParameterHandler);
  yield* takeLatest(loadTransformsPreview, loadPreviewHandler);
}
