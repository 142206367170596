import { put } from 'typed-redux-saga';

import { getErrorMessage } from '../api/utils';
import { retrieveObject } from '../helpers/imageView/data.helpers';
import { storeObject } from '../redux/state/core/imageView/imageView.helpers';
import { getMaskDataUpdate } from '../redux/atoms/maskDataUpdate.saga';
import { METHOD_BORDER } from '../workers/mask/constants';
import {
  getResultFromWorker,
  MASK_WORKER,
  TerminationError,
} from '../workers/workerManager';
import { enqueueNotification } from '../redux/state/ui/stackNotifications/stackNotifications.slice';
import { LabelType } from '../api/constants/label';

export function* labelGenerator(label: any, usedObjects: any) {
  let result = label;

  if (label.type === LabelType.Mask) {
    let borderDataId;
    let borderData;
    let imageDataId;
    let imageData;

    if (usedObjects[label.id]) {
      const usedObject = usedObjects[label.id];
      const [iId, bId] = usedObject;
      imageDataId = iId;
      borderDataId = bId;
      imageData = retrieveObject(imageDataId);
      borderData = retrieveObject(borderDataId);
    } else {
      try {
        const maskData = yield* getMaskDataUpdate(label);
        imageDataId = maskData.imageDataId;
        imageData = maskData.imageData;
        borderData = yield* getResultFromWorker(MASK_WORKER, {
          method: METHOD_BORDER,
          imageData,
        });
        borderDataId = storeObject(borderData);
      } catch (error) {
        const message = getErrorMessage(error, 'Unable to process label');

        if (!(error instanceof TerminationError)) {
          throw new Error(message);
        } else {
          yield* put(
            enqueueNotification({
              message,
              options: {
                variant: 'error',
                error,
              },
            }),
          );
        }
      }
    }

    result = {
      ...label,
      borderDataId,
      borderData,
      imageDataId,
      imageData,
    };

    usedObjects[label.id] = [imageDataId, borderDataId];
  }

  return result;
}
