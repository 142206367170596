import { put, takeEvery } from 'typed-redux-saga';

import { loadExperiments } from '../experimentsData/experimentsData.slice';
import { setExperimentsPage } from './experimentsPagination.slice';

function* setPageHandler() {
  yield* put(loadExperiments());
}

export function* experimentPaginationSaga() {
  yield* takeEvery(setExperimentsPage, setPageHandler);
}
