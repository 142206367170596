import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider, ReactReduxContext } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import axios from 'axios';
import 'array-flat-polyfill';
import {
  CssBaseline,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material';
import { Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ModalProvider } from 'react-modal-hook';
import { RecoilRoot } from 'recoil';
import RecoilNexus from 'recoil-nexus';

import { setupSentry } from './setupSentry';
import { enhancedHistory, persistor, store } from './app/setup';
import { LazyApp as App } from './app/lazyApp';
import { theme } from './theme';
import { GlobalStyle } from './globalStyle';
import { AnalyticsContextProvider } from './analytics/AnalyticsContext';
import { StackNotificationsContainer } from './components/stackNotificationsContainer/StackNotificationsContainer';
import { passMessageToWorker, WS_WORKER } from './workers/workerManager';
import { WEBSOCKETS_URL } from './constants/paths';
import { ApiProvider, injectStoreApiProvider } from './api/ApiProvider';
import { injectStoreApiRequest } from './api/apiRequest.utils';
import './css/index.css';

// Needed to dispatch actions outside components with React Query
injectStoreApiProvider(store);
injectStoreApiRequest(store);

axios.defaults.withCredentials = true;

setupSentry();
passMessageToWorker(WS_WORKER, { initUrl: WEBSOCKETS_URL });

// Start with mocked endpoints
if (process.env.REACT_APP_USE_MSW_MOCKS === 'true') {
  (async () => {
    const { worker } = await import('./api/apiMocks/browser');
    worker.start({ onUnhandledRequest: 'bypass' });
  })();
}

const rootElement = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(rootElement!);

root.render(
  <Provider context={ReactReduxContext} store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter context={ReactReduxContext} history={enhancedHistory}>
        <RecoilRoot>
          <AnalyticsContextProvider>
            <QueryParamProvider ReactRouterRoute={Route}>
              <ApiProvider>
                <StyledEngineProvider injectFirst>
                  <ThemeProvider theme={theme}>
                    <ModalProvider>
                      <RecoilNexus />
                      <CssBaseline />
                      <GlobalStyle />
                      <App />
                      <StackNotificationsContainer />
                    </ModalProvider>
                  </ThemeProvider>
                </StyledEngineProvider>
              </ApiProvider>
            </QueryParamProvider>
          </AnalyticsContextProvider>
        </RecoilRoot>
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
);
