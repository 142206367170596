import { call, put, select, takeEvery } from 'typed-redux-saga';

import { apiKeyDataMapper } from '../../../../../api/domainModels/apiKey';
import {
  apiCreateProfileApiKey,
  apiDeleteProfileApiKey,
  apiUpdateProfileApiKey,
} from '../../../../../api/requests/apiKey';
import { getErrorMessage } from '../../../../../api/utils';
import { hideModals, showModal } from '../../../ui/modals/modals.slice';
import {
  addApiKeyFailure,
  addApiKey,
  addApiKeySuccess,
  removeApiKeyFailure,
  removeApiKeyStart,
  removeApiKeySuccess,
  resetApiKeysLoadingState,
  updateApiKey,
  updateApiKeySuccess,
} from './apiKeys.slice';
import { apiKeyByIdSelector } from './apiKeys.selectors';

function* hideModalsHandler() {
  yield* put(resetApiKeysLoadingState());
}

function* addHandler(action: ActionType<typeof addApiKey>) {
  try {
    const { serviceAccountId, ...values } = action.payload;
    const { data } = yield* call(
      apiCreateProfileApiKey,
      {
        profileId: serviceAccountId,
      },
      apiKeyDataMapper.toBackend(values),
    );
    const newKey = apiKeyDataMapper.fromBackend(data);
    yield* put(addApiKeySuccess(newKey));
    yield* put(
      showModal({
        modalName: 'apiSecretKey',
        modalProps: {
          id: newKey.id,
        },
      }),
    );
  } catch (e) {
    yield* put(
      addApiKeyFailure({
        message: getErrorMessage(e, 'Not able to create the api key'),
      }),
    );
  }
}

function* updateHandler(action: ActionType<typeof updateApiKey>) {
  try {
    const { id, serviceAccountId, ...values } = action.payload;
    const { data } = yield* call(
      apiUpdateProfileApiKey,
      {
        apiKeyId: id,
        profileId: serviceAccountId,
      },
      apiKeyDataMapper.toBackend(values),
    );
    yield* put(
      updateApiKeySuccess({
        id,
        changes: apiKeyDataMapper.fromBackend(data),
      }),
    );
    yield* put(hideModals());
  } catch (e) {
    yield* put(
      addApiKeyFailure({
        message: getErrorMessage(e, 'Not able to update the api key'),
      }),
    );
  }
}

function* removeHandler(action: ActionType<typeof removeApiKeyStart>) {
  const id = action.payload;
  const apiKey = yield* select((state: RootState) =>
    apiKeyByIdSelector(state, id),
  );
  if (apiKey) {
    try {
      yield* call(apiDeleteProfileApiKey, {
        apiKeyId: id,
        profileId: apiKey.serviceAccountId,
      });
      yield* put(removeApiKeySuccess(id));
      yield* put(hideModals());
    } catch (e) {
      yield* put(
        removeApiKeyFailure(
          getErrorMessage(e, 'Not able to remove the api key'),
        ),
      );
    }
  }
}

export function* apiKeysSaga() {
  yield* takeEvery(hideModals, hideModalsHandler);
  yield* takeEvery(addApiKey, addHandler);
  yield* takeEvery(updateApiKey, updateHandler);
  yield* takeEvery(removeApiKeyStart, removeHandler);
}
