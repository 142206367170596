import { put, takeEvery } from 'typed-redux-saga';

import { handleError } from './errorHandler.actions';
import { enqueueNotification } from '../../ui/stackNotifications/stackNotifications.slice';

function* errorHandler(action: ActionType<typeof handleError>) {
  const {
    error,
    message,
    allowOutsideOfEditor = false,
    refresh,
  } = action.payload;

  yield* put(
    enqueueNotification({
      message,
      options: {
        variant: 'error',
        allowOutsideOfEditor,
        refresh,
        error,
      },
    }),
  );
}

export function* errorHandlerSaga() {
  yield* takeEvery(handleError, errorHandler);
}
