import { all, call } from 'typed-redux-saga';

import { projectSagas } from './project/project.sagas';
import { uiSagas } from './ui/ui.sagas';
import { wsSagas } from './ws/ws.sagas';
import { coreSagas } from './core/core.sagas';
import { commonFeaturesSagas } from './commonFeatures/commonFeatures.saga';
import { sectionsSaga } from './sections/sections.saga';

const allSagas = [
  ...projectSagas,
  ...uiSagas,
  ...wsSagas,
  ...coreSagas,
  ...commonFeaturesSagas,
  ...sectionsSaga,
];

export function* rootSaga() {
  yield* all(allSagas.map((saga) => call(saga)));
}
