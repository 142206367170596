import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
  Update,
} from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import chroma from 'chroma-js';

import {
  Experiment,
  ExperimentConfusionMatrixIteration,
  ExperimentExportFormat,
  ExperimentParameter,
  ModelExportJob,
} from '../../../../../../api/domainModels/modelPlayground';
import {
  LoadingState,
  loadingStateBuilder,
} from '../../../../../utils/loadingState';
import { setEditedProjectId } from '../../../../sections/editedProject/project/project.slice';
import { ModelFamily } from '../../../../../../api/constants/modelFamily';

export type ExperimentFormValues = Partial<
  Pick<Experiment, 'name' | 'color' | 'templateId'>
>;

export type FiltersData = {
  modelFamily?: ModelFamily | null;
  splitId?: string | null;
};

export const adapter = createEntityAdapter<Experiment>({
  sortComparer: (a, b) => (dayjs(a.createdAt).isAfter(b.createdAt) ? -1 : 1),
});
type InitialState = {
  loadingState: LoadingState;
  itemLoadingState: LoadingState;

  experimentExportLoadingState: LoadingState;

  exportFormatsLoadingState: LoadingState;
  exportFormats: ExperimentExportFormat[];
  exportFormatOptionsLoadingState: LoadingState;
  exportFormatOptions: ExperimentParameter[];

  confusionMatrixLoadingState: LoadingState;
  confusionMatrix: Record<Experiment['id'], ExperimentConfusionMatrixIteration>;
};
const initialState: InitialState = {
  loadingState: loadingStateBuilder.initial(),
  itemLoadingState: loadingStateBuilder.initial(),

  experimentExportLoadingState: loadingStateBuilder.initial(),

  exportFormatsLoadingState: loadingStateBuilder.initial(),
  exportFormats: [],
  exportFormatOptionsLoadingState: loadingStateBuilder.initial(),
  exportFormatOptions: [],

  confusionMatrixLoadingState: loadingStateBuilder.initial(),
  confusionMatrix: {},
};

const { actions, reducer: experimentsDataReducer } = createSlice({
  name: 'experimentsData',
  initialState: adapter.getInitialState(initialState),
  reducers: {
    loadExperiments(state) {
      state.loadingState = loadingStateBuilder.inProgress();
      adapter.removeAll(state);
    },
    loadAllFilteredExperiments(state, _action: PayloadAction<FiltersData>) {
      state.loadingState = loadingStateBuilder.inProgress();
    },
    loadExperimentsSuccess: {
      reducer(state, action: PayloadAction<Experiment[]>) {
        state.loadingState = loadingStateBuilder.success();
        adapter.setAll(state, action.payload);
      },
      prepare(payload: Experiment[]) {
        return {
          payload: payload.map((experiment) => ({
            ...experiment,
            color: experiment.color || chroma.random().hex(),
          })),
        };
      },
    },
    loadExperimentsFailure(state, action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.failure(action.payload);
      adapter.setAll(state, []);
    },
    loadExperiment(state, _action: PayloadAction<{ id: string }>) {
      state.itemLoadingState = loadingStateBuilder.inProgress();
    },
    loadExperimentSuccess: {
      reducer(state, action: PayloadAction<Experiment>) {
        state.itemLoadingState = loadingStateBuilder.success();
        adapter.upsertOne(state, action.payload);
      },
      prepare(payload: Experiment) {
        return {
          payload: {
            ...payload,
            color: payload.color || chroma.random().hex(),
          },
        };
      },
    },
    loadExperimentExportFormats(
      state,
      _action: PayloadAction<{ experimentId: Experiment['id'] | undefined }>,
    ) {
      state.exportFormatsLoadingState = loadingStateBuilder.inProgress();
      state.exportFormats = [];
    },
    loadExperimentExportFormatsFailure(state, action: PayloadAction<string>) {
      state.exportFormatsLoadingState = loadingStateBuilder.failure(
        action.payload,
      );
      state.exportFormats = [];
    },
    loadExperimentExportFormatsSuccess(
      state,
      action: PayloadAction<ExperimentExportFormat[]>,
    ) {
      state.exportFormatsLoadingState = loadingStateBuilder.success();
      state.exportFormats = action.payload;
    },
    loadExperimentExportFormatOptions(
      state,
      _action: PayloadAction<{ experimentId: string; exportFormatId: string }>,
    ) {
      state.exportFormatOptionsLoadingState = loadingStateBuilder.inProgress();
      state.exportFormatOptions = [];
    },
    loadExperimentExportFormatOptionsFailure(
      state,
      action: PayloadAction<string>,
    ) {
      state.exportFormatOptionsLoadingState = loadingStateBuilder.failure(
        action.payload,
      );
      state.exportFormatOptions = [];
    },
    loadExperimentExportFormatOptionsSuccess(
      state,
      action: PayloadAction<ExperimentParameter[]>,
    ) {
      state.exportFormatOptionsLoadingState = loadingStateBuilder.success();
      state.exportFormatOptions = action.payload;
    },
    experimentExport(
      state,
      _action: PayloadAction<{
        experimentId: Experiment['id'];
        format: ExperimentExportFormat['id'];
        exportFormatOptions: { [key: string]: string | number | boolean };
      }>,
    ) {
      state.experimentExportLoadingState = loadingStateBuilder.inProgress();
    },
    experimentExportFailure(state, action: PayloadAction<string>) {
      state.experimentExportLoadingState = loadingStateBuilder.failure(
        action.payload,
      );
    },
    experimentExportSuccess(state, _action: PayloadAction<ModelExportJob>) {
      state.experimentExportLoadingState = loadingStateBuilder.success();
    },
    loadExperimentFailure(state, action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.failure(action.payload);
    },
    createExperiment(
      state,
      _action: PayloadAction<{
        data: ExperimentFormValues;
        modelFamily: ModelFamily;
      }>,
    ) {
      state.itemLoadingState = loadingStateBuilder.inProgress();
    },
    createExperimentSuccess(state, action: PayloadAction<Experiment>) {
      state.itemLoadingState = loadingStateBuilder.success();
      adapter.addOne(state, action.payload);
    },
    createExperimentFailure(state, action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.failure(action.payload);
    },
    createExperimentOnDemand(
      state,
      _action: PayloadAction<{ modelFamily: ModelFamily; templateId?: string }>,
    ) {},
    removeExperiment(state, _action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.inProgress();
    },
    removeExperimentFailure(state, action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.failure(action.payload);
    },
    removeExperimentSuccess(state, action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.success();
      adapter.removeOne(state, action.payload);
    },
    updateExperiment(
      state,
      _action: PayloadAction<ExperimentFormValues & { id: string }>,
    ) {
      state.itemLoadingState = loadingStateBuilder.inProgress();
    },
    updateExperimentFailure(state, action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.failure(action.payload);
    },
    updateExperimentSuccess(state, action: PayloadAction<Update<Experiment>>) {
      state.itemLoadingState = loadingStateBuilder.success();
      adapter.updateOne(state, action.payload);
    },

    copyExperiment(
      _state,
      _action: PayloadAction<{
        experimentId: string;
        name: string;
        color: string;
      }>,
    ) {},
    copyExperimentSuccess(state, action: PayloadAction<Experiment>) {
      adapter.addOne(state, action.payload);
    },
    stopExperiment(
      _state,
      _action: PayloadAction<{
        trainJobId: string;
      }>,
    ) {},
    modifyExperiment(state, action: PayloadAction<Update<Experiment>>) {
      adapter.updateOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setEditedProjectId, () =>
      adapter.getInitialState(initialState),
    );
  },
});

export { experimentsDataReducer };
export const {
  loadExperiments,
  loadAllFilteredExperiments,
  loadExperimentsSuccess,
  loadExperimentsFailure,
  loadExperiment,
  loadExperimentSuccess,
  loadExperimentFailure,

  loadExperimentExportFormats,
  loadExperimentExportFormatsFailure,
  loadExperimentExportFormatsSuccess,
  loadExperimentExportFormatOptions,
  loadExperimentExportFormatOptionsFailure,
  loadExperimentExportFormatOptionsSuccess,

  experimentExport,
  experimentExportFailure,
  experimentExportSuccess,

  createExperiment,
  createExperimentSuccess,
  createExperimentFailure,
  createExperimentOnDemand,
  removeExperiment,
  removeExperimentFailure,
  removeExperimentSuccess,
  updateExperiment,
  updateExperimentFailure,
  updateExperimentSuccess,
  copyExperiment,
  copyExperimentSuccess,
  stopExperiment,

  modifyExperiment,
} = actions;
