/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ArtifactEntry = {
  /**
   * Returns unique Artifact ID
   */
  id?: string;
  /**
   * Returns current status
   */
  status?: ArtifactEntry.status;
  /**
   * Returns version
   */
  version?: number;
  /**
   * Returns unique Deployment ID in Inference Engine cluster
   */
  deploymentId?: string;
  /**
   * Returns fail reason
   */
  reason?: string;
  /**
   * Returns access URL to model in Inference engine cluster
   */
  url?: string | null;
};

export namespace ArtifactEntry {

  /**
   * Returns current status
   */
  export enum status {
    NEW = 'NEW',
    VALIDATE = 'VALIDATE',
    BUILD_STARTED = 'BUILD_STARTED',
    BUILDED = 'BUILDED',
    DEPLOY_STARTED = 'DEPLOY_STARTED',
    DEPLOYED = 'DEPLOYED',
    UN_DEPLOYED = 'UN_DEPLOYED',
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED',
  }


}

