import { put, select, takeEvery, call } from 'typed-redux-saga';

import {
  apiLoadTemplates,
  apiSaveAsTemplate,
  apiDeleteTemplate,
} from '../../../../../../api/requests/modelPlayground';
import { getErrorMessage } from '../../../../../../api/utils';
import { activeProjectIdSelector } from '../../../../project/project.selectors';
import { loadAll } from '../../../../../utils/api';
import { hideModals } from '../../../../ui/modals/modals.slice';
import {
  loadTemplates,
  loadTemplatesSuccess,
  loadTemplatesFailure,
  addTemplate,
  addTemplateSuccess,
  addTemplateFailure,
  removeTemplate,
  removeTemplateSuccess,
  removeTemplateFailure,
} from './templates.slice';

function* loadHandler(action: ActionType<typeof loadTemplates>) {
  try {
    const projectId = yield* select(activeProjectIdSelector);
    const params = action.payload;

    const templates = yield* loadAll({
      apiHelper: apiLoadTemplates,
      params: {
        projectId,
        params,
      },
    });
    yield* put(loadTemplatesSuccess(templates));
  } catch (error) {
    const errorMessage = getErrorMessage(error, 'Not able to load templates');
    yield* put(loadTemplatesFailure(errorMessage));
  }
}

function* saveAsTemplateHandler(action: ActionType<typeof addTemplate>) {
  try {
    const projectId = yield* select(activeProjectIdSelector);
    const { experimentId, ...payload } = action.payload;

    const { data } = yield* call(
      apiSaveAsTemplate,
      {
        projectId,
        experimentId,
      },
      payload,
    );

    yield* put(addTemplateSuccess(data));
    yield* put(hideModals());
  } catch (error) {
    const errorMessage = getErrorMessage(error, 'Not able to save template');

    yield* put(addTemplateFailure(errorMessage));
  }
}

function* removeHandler(action: ActionType<typeof removeTemplate>) {
  try {
    const projectId = yield* select(activeProjectIdSelector);
    const templateId = action.payload;

    yield* call(apiDeleteTemplate, {
      projectId,
      templateId,
    });
    yield* put(removeTemplateSuccess(templateId));
    yield* put(hideModals());
  } catch (error) {
    const errorMessage = getErrorMessage(error, 'Not able to delete template');
    yield* put(removeTemplateFailure(errorMessage));
  }
}

export function* templatesSaga() {
  yield* takeEvery(loadTemplates, loadHandler);
  yield* takeEvery(addTemplate, saveAsTemplateHandler);
  yield* takeEvery(removeTemplate, removeHandler);
}
