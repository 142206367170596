import { combineReducers, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { VideoTool } from './tools.constants';
import { segmentPredictionReducer } from './segmentPrediction/segmentPrediction.slice';

const initialState: {
  toolId: VideoTool;
} = {
  toolId: VideoTool.Default,
};

const { reducer, actions } = createSlice({
  name: 'videoViewTools',
  initialState,
  reducers: {
    setActiveTool(state, action: PayloadAction<VideoTool>) {
      state.toolId = action.payload;
    },
    resetActiveTool(state) {
      state.toolId = VideoTool.Default;
    },
    trySetActiveTool(_state, _action: PayloadAction<VideoTool>) {},
  },
});

export const videoViewToolsReducer = combineReducers({
  activeTool: reducer,
  segmentPrediction: segmentPredictionReducer,
});

export const { setActiveTool, resetActiveTool, trySetActiveTool } = actions;
