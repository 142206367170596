import { put, select, takeEvery, call, all } from 'typed-redux-saga';

import { apiKeyDataMapper } from '../../../../../api/domainModels/apiKey';
import {
  apiCreateWorkspaceServiceAccount,
  apiLoadWorkspaceServiceAccounts,
  apiDeleteWorkspaceServiceAccount,
  apiUpdateWorkspaceServiceAccount,
} from '../../../../../api/requests/serviceAccount';
import { getErrorMessage } from '../../../../../api/utils';
import { hideModals } from '../../../ui/modals/modals.slice';
import { loadRoles } from '../roles/roles.slice';
import { addMultipleApiKeys } from './apiKeys.slice';
import {
  addServiceAccountFailure,
  addServiceAccountStart,
  addServiceAccountSuccess,
  loadServiceAccountsFailure,
  loadServiceAccountsStart,
  loadServiceAccountsSuccess,
  removeServiceAccountFailure,
  removeServiceAccountStart,
  removeServiceAccountSuccess,
  resetServiceAccountsLoadingState,
  updateServiceAccountFailure,
  updateServiceAccountStart,
  updateServiceAccountSuccess,
} from './serviceAccounts.slice';
import { workspaceIdSelector } from '../../../commonFeatures/workspaceId/workspaceId.selectors';
import { deleteWorkspaceCollaboratorSuccess } from '../collaborators/collaborators.slice';
import { setManagedWorkspaceId } from '../../../commonFeatures/workspaceId/workspaceId.slice';
import { apiLoadProfileApiKeys } from '../../../../../api/requests/apiKey';
import { handleError } from '../../../commonFeatures/errorHandler/errorHandler.actions';

function* loadServiceAccountApiKeys(profileId: string) {
  try {
    const { data } = yield* call(apiLoadProfileApiKeys, { profileId });

    yield* put(addMultipleApiKeys(data.map(apiKeyDataMapper.fromBackend)));
  } catch (error) {
    const errorMessage = getErrorMessage(error, 'Unable to load api keys');

    yield* put(handleError({ message: errorMessage, error }));
  }
}

function* listHandler(action: ActionType<typeof setManagedWorkspaceId>) {
  const { workspaceId } = action.payload;

  yield* put(loadRoles({ workspaceId }));
  yield* put(loadServiceAccountsStart());

  try {
    const {
      data: { items },
    } = yield* call(apiLoadWorkspaceServiceAccounts, {
      workspaceId,
    });

    yield* put(loadServiceAccountsSuccess(items));

    yield* all(items.map((item) => loadServiceAccountApiKeys(item.id)));
  } catch (e) {
    yield* put(
      loadServiceAccountsFailure(
        getErrorMessage(e, 'Not able to load service accounts'),
      ),
    );
  }
}

function* addHandler(action: ActionType<typeof addServiceAccountStart>) {
  const workspaceId = yield* select(workspaceIdSelector);

  try {
    const { data } = yield* call(
      apiCreateWorkspaceServiceAccount,
      {
        workspaceId,
      },
      action.payload,
    );
    yield* put(addServiceAccountSuccess(data));
    yield* put(hideModals());
  } catch (e) {
    yield* put(
      addServiceAccountFailure(
        getErrorMessage(e, 'Not able to create a service account'),
      ),
    );
  }
}

function* updateHandler(action: ActionType<typeof updateServiceAccountStart>) {
  const workspaceId = yield* select(workspaceIdSelector);
  const { id, ...values } = action.payload;

  if (!workspaceId || !id) return;

  try {
    const { data } = yield* call(
      apiUpdateWorkspaceServiceAccount,
      {
        workspaceId,
        profileId: id,
      },
      values,
    );
    yield* put(
      updateServiceAccountSuccess({
        id,
        changes: data,
      }),
    );
    yield* put(hideModals());
  } catch (e) {
    yield* put(
      updateServiceAccountFailure(
        getErrorMessage(e, 'Not able to update the service account'),
      ),
    );
  }
}

function* removeHandler(action: ActionType<typeof removeServiceAccountStart>) {
  const workspaceId = yield* select(workspaceIdSelector);
  const profileId = action.payload;

  if (!workspaceId || !profileId) return;

  try {
    yield* call(apiDeleteWorkspaceServiceAccount, {
      workspaceId,
      profileId,
    });
    yield* put(removeServiceAccountSuccess(profileId));
    yield* put(hideModals());
  } catch (e) {
    yield* put(
      removeServiceAccountFailure(
        getErrorMessage(e, 'Not able to remove the service account'),
      ),
    );
  }
}

function* collaboratorSyncHandler(
  action: ActionType<typeof deleteWorkspaceCollaboratorSuccess>,
) {
  yield* put(removeServiceAccountSuccess(action.payload));
}

function* hideModalsHandler() {
  yield* put(resetServiceAccountsLoadingState());
}

export function* serviceAccountsSaga() {
  yield* takeEvery(setManagedWorkspaceId, listHandler);
  yield* takeEvery(hideModals, hideModalsHandler);
  yield* takeEvery(addServiceAccountStart, addHandler);
  yield* takeEvery(updateServiceAccountStart, updateHandler);
  yield* takeEvery(removeServiceAccountStart, removeHandler);
  yield* takeEvery(deleteWorkspaceCollaboratorSuccess, collaboratorSyncHandler);
}
