import axios from 'axios';

import { apiWrapper } from '../utils';
import { headers, transformRequest, transformResponse } from '../helpers';
import { API_URL } from '../../constants/paths';
import {
  ApiGetMaskEditorPredictionData,
  ApiGetMaskEditorPredictionParams,
  ApiGetMaskEditorPredictionResponse,
} from '../schemas/maskEditorPrediction';

export const apiGetMaskEditorPrediction = (
  params: ApiGetMaskEditorPredictionParams,
  data: ApiGetMaskEditorPredictionData,
) =>
  apiWrapper(
    axios.request<ApiGetMaskEditorPredictionResponse>({
      method: 'post',
      headers,
      transformRequest,
      transformResponse,
      url: `${API_URL}/project/${params.projectId}/image/${params.imageId}/mask_editor`,
      data,
    }),
  );
