import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { RunErrorsPerClass } from '../../../../../../api/domainModels/consensusScoring';
import { loadingStateBuilder } from '../../../../../utils/loadingState';

export const adapter = createEntityAdapter<RunErrorsPerClass>({
  selectId: (item) => item.currentClassId, // TODO: BE to change
});

const { actions, reducer: runErrorsPerClassReducer } = createSlice({
  name: 'runErrorsPerClass',
  initialState: adapter.getInitialState({
    loadingState: loadingStateBuilder.initial(),
  }),
  reducers: {
    loadErrorsPerClass(state, _action: PayloadAction<{ runId: string }>) {
      state.loadingState = loadingStateBuilder.inProgress();
      adapter.removeAll(state);
    },
    loadErrorsPerClassSuccess(
      state,
      action: PayloadAction<RunErrorsPerClass[]>,
    ) {
      state.loadingState = loadingStateBuilder.success();
      adapter.setAll(state, action.payload);
    },
    loadErrorsPerClassFailure(state, action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.failure(action.payload);
      adapter.setAll(state, []);
    },
  },
});

export { runErrorsPerClassReducer };
export const {
  loadErrorsPerClass,
  loadErrorsPerClassSuccess,
  loadErrorsPerClassFailure,
} = actions;
