import { call, put, select, takeEvery } from 'typed-redux-saga';

import {
  LabelClass,
  labelClassDataMapper,
} from '../../../../../api/domainModels/labelClass';
import {
  apiCreateProjectLabelClass,
  apiDeleteProjectLabelClass,
  apiLoadProjectLabelClasses,
  apiUpdateProjectLabelClass,
} from '../../../../../api/requests/labelClass';
import { WebsocketLabelClassUpdatedPayload } from '../../../../../api/domainModels/websocketTypes';
import { getErrorMessage } from '../../../../../api/utils';
import { setProjectId } from '../../../core/imageView/project/project.slice';
import { initImageProject } from '../../../sections/editedProject/project/project.slice';
import { activeImportSessionIdSelector } from '../../../sections/editedProject/imports/session/importSession.selectors';
import { loadAll } from '../../../../utils/api';
import { hideModals, showModal } from '../../../ui/modals/modals.slice';
import {
  LABEL_CLASS_DELETED,
  LABEL_CLASS_UPDATED,
} from '../../../ws/ws.constants';
import { activeProjectIdSelector } from '../../project.selectors';
import { activeLabelClassIdSelector } from '../../../core/imageView/labelClasses/labelClasses.selectors';
import {
  selectActiveLabelClassId,
  resetActiveLabelClassId,
  selectNextLabelClass,
} from '../../../core/imageView/labelClasses/labelClasses.slice';
import { resetActiveTool } from '../../../core/imageView/tools/tools.slice';
import {
  addLabelClass,
  addLabelClassFailure,
  addLabelClassSuccess,
  loadLabelClassesFailure,
  loadLabelClassesStart,
  loadLabelClassesSuccess,
  removeLabelClass,
  removeLabelClassFailure,
  removeLabelClassFromWebsockets,
  removeLabelClassSuccess,
  resetLabelClassLoadingState,
  updateLabelClass,
  updateLabelClassFailure,
  updateLabelClassOrder,
  updateLabelClassSuccess,
  upsertLabelClassFromWebsockets,
  reloadLabelClasses,
} from './labelClasses.slice';
import {
  labelClassByIdSelector,
  labelClassesSelector,
  labelClassesIdsSelector,
} from './labelClasses.selectors';
import { processACLabelClassRelations } from '../ACRelations/ACRelations.saga';
import { getNewOrder } from '../../../../../util/norder';

const stripReadOnlyFields = ({ id, ...rest }: LabelClass) => rest;

function* listHandler(
  action: ActionType<
    typeof initImageProject | typeof setProjectId | typeof reloadLabelClasses
  >,
) {
  const projectId = action.payload.id;

  yield* put(loadLabelClassesStart());

  try {
    const labelClasses = yield* loadAll({
      apiHelper: apiLoadProjectLabelClasses,
      fromBackend: labelClassDataMapper.fromBackend,
      params: {
        projectId,
      },
    });

    yield* put(loadLabelClassesSuccess(labelClasses));
  } catch (e) {
    yield* put(
      loadLabelClassesFailure(
        getErrorMessage(e, 'Not able to load label classes'),
      ),
    );
  }
}

function* addClassHandler(action: ActionType<typeof addLabelClass>) {
  const values = action.payload;

  const projectId = yield* select(activeProjectIdSelector);
  const activeImportSessionId = yield* select(activeImportSessionIdSelector);
  const activeLabelClassId = yield* select(activeLabelClassIdSelector);

  try {
    const { data } = yield* call(
      apiCreateProjectLabelClass,
      {
        projectId,
      },
      labelClassDataMapper.toBackend(values),
    );
    const newLabelClass = labelClassDataMapper.fromBackend(data);

    try {
      yield* processACLabelClassRelations(
        newLabelClass.id,
        values.attributes.map((attributeId, index) => ({
          attributeId,
          classId: newLabelClass.id,
          classOrder: index,
          attributeOrder: 0,
        })),
      );

      yield* put(hideModals());
    } catch (e) {
      // do nothing, error processed in that saga. We only catch to not hide the modal
    } finally {
      // keep here to preserve loading state during relations update
      yield* put(addLabelClassSuccess(newLabelClass));

      // In case of adding from Import session => return to that Import session
      if (activeImportSessionId) {
        yield* put(
          showModal({
            modalName: 'importAnnotations',
          }),
        );
      }

      //  Make fresh created class as active one - in case theres no selected active one already
      if (!activeLabelClassId) {
        yield* put(
          selectActiveLabelClassId({
            id: newLabelClass.id,
            type: newLabelClass.type,
          }),
        );
      }
    }
  } catch (e) {
    yield* put(
      addLabelClassFailure(
        getErrorMessage(e, 'Not able to create a label class'),
      ),
    );
  }
}

function* updateClassHandler(action: ActionType<typeof updateLabelClass>) {
  const projectId = yield* select(activeProjectIdSelector);
  const { id } = action.payload;

  try {
    const { data } = yield* call(
      apiUpdateProjectLabelClass,
      {
        projectId,
        labelClassId: id,
      },
      labelClassDataMapper.toBackend(stripReadOnlyFields(action.payload)),
    );

    try {
      yield* processACLabelClassRelations(
        id,
        action.payload.attributes.map((attributeId, index) => ({
          attributeId,
          classId: id,
          classOrder: index,
          attributeOrder: 0,
        })),
      );
      yield* put(hideModals());
    } catch (e) {
      // do nothing, error processed in that saga. We only catch to not hide the modal
    } finally {
      // keep here to preserve loading state during relations update
      yield* put(
        updateLabelClassSuccess({
          changes: labelClassDataMapper.fromBackend(data),
          id,
        }),
      );
    }
  } catch (e) {
    yield* put(
      updateLabelClassFailure(
        getErrorMessage(e, 'Not able to update the label class'),
      ),
    );
  }
}

function* removeClassHandler(action: ActionType<typeof removeLabelClass>) {
  const projectId = yield* select(activeProjectIdSelector);
  const id = action.payload;
  try {
    yield* call(apiDeleteProjectLabelClass, {
      projectId,
      labelClassId: id,
    });
    yield* put(removeLabelClassSuccess(id));
    yield* put(hideModals());
  } catch (e) {
    yield* put(
      removeLabelClassFailure(
        getErrorMessage(e, 'Not able to remove the label class'),
      ),
    );
  }
}

function* removeClassSuccessHandler(
  action: ActionType<typeof removeLabelClassSuccess>,
) {
  const removedClassId = action.payload;
  const activeLabelClassId = yield* select(activeLabelClassIdSelector);
  const labelClassesIds = yield* select(labelClassesIdsSelector);

  if (removedClassId === activeLabelClassId) {
    // this was last one => set active one as null and reset active tool
    if (labelClassesIds.length === 0) {
      yield* put(resetActiveLabelClassId());
      yield* put(resetActiveTool());
    } else {
      yield* put(selectNextLabelClass());
    }
  }
}

function* reorderClassHandler(
  action: ActionType<typeof updateLabelClassOrder>,
) {
  const projectId = yield* select(activeProjectIdSelector);
  const { id, classType, index } = action.payload;
  const labelClass = yield* select((state: RootState) =>
    labelClassByIdSelector(state, id),
  );
  if (!labelClass) {
    // eslint-disable-next-line no-console
    console.log('failed label class reorder, label not found for id ', id);

    return;
  }
  // here we're calculating the new norder as the median between
  // the two label classes' norders we're dropping the dragged label class in
  const labelClasses = yield* select(labelClassesSelector);
  const labelClassesOfMatchingType = labelClasses.filter(
    (lc) => lc.type === classType,
  );
  const newOrder = getNewOrder(labelClassesOfMatchingType, index);

  if (newOrder !== null) {
    try {
      yield* call(
        apiUpdateProjectLabelClass,
        {
          projectId,
          labelClassId: id,
        },
        labelClassDataMapper.toBackend({
          ...stripReadOnlyFields(labelClass),
          type: classType,
          norder: newOrder,
        }),
      );
      yield* put(
        updateLabelClassSuccess({
          changes: { norder: newOrder, type: classType },
          id,
        }),
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('failed label class reorder', e);
    }
  }
}

function* websocketClassUpdatedHandler({
  id,
  color,
  name,
  classType,
  projectId,
  order,
}: WebsocketLabelClassUpdatedPayload & { type: string }) {
  const currentProjectId = yield* select(activeProjectIdSelector);
  if (currentProjectId && currentProjectId === projectId) {
    yield* put(
      upsertLabelClassFromWebsockets({
        id,
        color,
        name,
        type: classType,
        norder: order,
      }),
    );
  }
}

function* websocketClassRemovedHandler({
  id,
  projectId,
}: WebsocketLabelClassUpdatedPayload & { type: string }) {
  const currentProjectId = yield* select(activeProjectIdSelector);
  if (currentProjectId && currentProjectId === projectId) {
    yield* put(removeLabelClassFromWebsockets(id));
  }
}

function* hideModalsHandler() {
  yield* put(resetLabelClassLoadingState());
}

export function* labelClassesSaga() {
  yield* takeEvery(
    [initImageProject, setProjectId, reloadLabelClasses],
    listHandler,
  );
  yield* takeEvery(addLabelClass, addClassHandler);
  yield* takeEvery(updateLabelClass, updateClassHandler);
  yield* takeEvery(removeLabelClass, removeClassHandler);
  yield* takeEvery(removeLabelClassSuccess, removeClassSuccessHandler);
  yield* takeEvery(updateLabelClassOrder, reorderClassHandler);
  yield* takeEvery(hideModals, hideModalsHandler);
  yield* takeEvery(LABEL_CLASS_UPDATED, websocketClassUpdatedHandler);
  yield* takeEvery(LABEL_CLASS_DELETED, websocketClassRemovedHandler);
}
