import { put, select, takeEvery, call } from 'typed-redux-saga';
import { PayloadAction } from '@reduxjs/toolkit';

import { apiLoadAttributesPredictionModel } from '../../../../../../api/requests/projectTools'; // TODO:: move
import { apiGetProjectImageLabelAttributePrediction } from '../../../../../../api/requests/attribute';
import {
  AttributePredictions,
  AttributePrediction,
} from '../../../../../../api/domainModels/attribute';
import { getErrorMessage } from '../../../../../../api/utils';
import { handleError } from '../../../../commonFeatures/errorHandler/errorHandler.actions';
import {
  loadAttributesPredictionModel,
  loadAttributesPrediction,
  loadAttributesPredictionStart,
  loadAttributesPredictionSuccess,
  loadAttributesPredictionError,
  loadAttributesPredictionModelStart,
  loadAttributesPredictionModelSuccess,
  loadAttributesPredictionModelError,
  updateAttributesPredictionModel,
  updateAttributesPredictionModelSuccess,
  resetData,
  resetModel,
  updateAttributesPredictionModelShowDot,
  setAttributePredictionThreshold,
} from './labelAttributesPrediction.slice';
import {
  loadModelHandler,
  modelLoadedHandler,
  updateModelHandler,
} from '../models/models.saga';
import { toggleAttributesPrediction } from '../../sidebar/labelAttributesPredictionToggle/labelAttributesPredictionToggle.slice';
import { imageViewIsAttributesPredictionToggledSelector } from '../../sidebar/labelAttributesPredictionToggle/labelAttributesPredictionToggle.selectors';
import { MODEL_LOADED, MODEL_UPDATED } from '../../../../ws/ws.constants';
import { resetProject } from '../../imageView.actions';
import { activeProjectIdSelector } from '../../../../project/project.selectors';
import { selectedLabelsIdsSelector } from '../../labels/selectedLabels/selectedLabels.selectors';
import { labelSyncPreventsNavigationSelector } from '../../labels/labelSync/labelSync.selectors';
import {
  createModelChangePattern,
  ModelChangePayload,
} from '../models/models.constants';
import {
  attributesPredictionModelIdSelector,
  attributesPredictionModelLoadedSelector,
  attributesPredictionThresholdSelector,
} from './labelAttributesPrediction.selectors';
import {
  MODEL_MESSAGES,
  ATTRIBUTES_PREDICTION_FAMILY_NAME,
} from './labelAttributesPrediction.constants';
import { ImageTool, ImageToolTitleMap } from '../tools.constants';

function* loadAttributesPredictionsHandler() {
  const projectId = yield* select(activeProjectIdSelector);
  const isPredictionEnabled = yield* select(
    imageViewIsAttributesPredictionToggledSelector,
  );
  const selectedLabelIds: string[] = yield* select(selectedLabelsIdsSelector);
  const modelId = yield* select(attributesPredictionModelIdSelector);
  const syncLock = yield* select(labelSyncPreventsNavigationSelector); // prediction happens for single selected label, sync is generally not needed but for a case of label paste/duplicate where label has an id on FE before it has an id on BE
  const confidenceThreshold = yield* select(
    attributesPredictionThresholdSelector,
  );

  if (
    syncLock ||
    !isPredictionEnabled ||
    selectedLabelIds.length !== 1 ||
    !modelId
  ) {
    return;
  }

  yield* put(loadAttributesPredictionStart());
  const labelId: string = selectedLabelIds[0];

  try {
    const params = { projectId, labelId, modelId, confidenceThreshold };
    const { data } = yield* call(
      apiGetProjectImageLabelAttributePrediction,
      params,
    );
    const payload = data.reduce(
      (
        acc: AttributePredictions,
        { attributeId, lovIds }: AttributePrediction,
      ) => {
        acc[attributeId] = lovIds;

        return acc;
      },
      {},
    );

    yield* put(loadAttributesPredictionSuccess(payload));
  } catch (error) {
    const errorMessage = getErrorMessage(
      error,
      'Not able to get attribute predictions',
    );

    yield* put(loadAttributesPredictionError(errorMessage));
    yield* put(handleError({ message: errorMessage, error }));
  }
}

function* loadAttributesPredictionModelHandler() {
  const projectId = yield* select(activeProjectIdSelector);

  yield* call(loadModelHandler, {
    projectId,
    loadApiCall: apiLoadAttributesPredictionModel,
    messages: MODEL_MESSAGES,
    toolId: ImageTool.AttributesPrediction,
    modelLoadStartAction: loadAttributesPredictionModelStart,
    modelLoadSuccessAction: loadAttributesPredictionModelSuccess,
    modelLoadErrorAction: loadAttributesPredictionModelError,
    toolName: ImageToolTitleMap[ImageTool.AttributesPrediction],
  });
}

function* attributesPredictionModelLoadedHandler(
  action: PayloadAction<ModelChangePayload>,
) {
  const { status, progress, id } = action.payload;

  yield* call(modelLoadedHandler, {
    id,
    modelIdSelector: attributesPredictionModelIdSelector,
    modelLoadedSelector: attributesPredictionModelLoadedSelector,
    progress,
    status,
    updateAction: updateAttributesPredictionModel,
    modelLoadedAction: loadAttributesPrediction,
    updateModelIndicator: updateAttributesPredictionModelShowDot,
  });
}

function* attributesPredictionModelUpdatedHandler(
  action: PayloadAction<ModelChangePayload>,
) {
  const { status, progress, id } = action.payload;

  yield* put(
    updateAttributesPredictionModel({
      status,
      progress,
      id,
    }),
  );
}

function* updateAttributesPredictionModelHandler(
  action: ActionType<typeof updateAttributesPredictionModel>,
) {
  const { status, progress, id } = action.payload;

  yield* call(updateModelHandler, {
    id,
    loadAction: loadAttributesPredictionModel,
    progress,
    status,
    successAction: updateAttributesPredictionModelSuccess,
  });
}

function* resetProjectHandler() {
  yield* put(resetData());
  yield* put(resetModel());
}

export function* attributesPredictionSaga() {
  yield* takeEvery(
    loadAttributesPredictionModel,
    loadAttributesPredictionModelHandler,
  );
  yield* takeEvery(
    [
      toggleAttributesPrediction,
      loadAttributesPrediction,
      setAttributePredictionThreshold,
    ],
    loadAttributesPredictionsHandler,
  );
  yield* takeEvery(
    createModelChangePattern(ATTRIBUTES_PREDICTION_FAMILY_NAME, MODEL_LOADED),
    attributesPredictionModelLoadedHandler,
  );
  yield* takeEvery(
    createModelChangePattern(ATTRIBUTES_PREDICTION_FAMILY_NAME, MODEL_UPDATED),
    attributesPredictionModelUpdatedHandler,
  );
  yield* takeEvery(
    updateAttributesPredictionModel,
    updateAttributesPredictionModelHandler,
  );
  yield* takeEvery(resetProject, resetProjectHandler);
}
