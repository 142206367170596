/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Family of model
 */
export enum ModelFamilyPath1 {
  ATTRIBUTER = 'attributer',
  CLASSIFIER = 'classifier',
  DETECTOR = 'detector',
  IMAGE_TAGGER = 'image-tagger',
  SEGMENTOR = 'segmentor',
  SEMANTIC_SEGMENTOR = 'semantic-segmentor',
  BOX2MASK = 'box2mask',
  DEXTR = 'dextr',
  GRABCUT = 'grabcut',
  ATOM = 'atom',
  ACTIVITY_RECOGNIZER = 'activity-recognizer',
  TXT2MASK = 'txt2mask',
  KEYPOINT_DETECTOR = 'keypoint-detector',
}
