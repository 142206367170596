import cloneDeep from 'lodash/cloneDeep';
import flatten from 'lodash/flatten';
import groupBy from 'lodash/groupBy';
import partition from 'lodash/partition';
import uniq from 'lodash/uniq';
import { all, call, put, select, takeEvery } from 'typed-redux-saga';

import { getErrorMessage } from '../../../../../api/utils';
import {
  apiLoadWorkspaceRolePrivileges,
  apiGrantWorkspacePrivileges,
  apiRevokeWorkspacePrivileges,
} from '../../../../../api/requests/workspacePrivileges';
import {
  cancelRolePrivilegesChanges,
  changeRolePrivilege,
  changeRolePrivilegeGroup,
  createRoleSuccess,
  deleteRoleSuccess,
  grantPrivileges,
  grantPrivilegesFailure,
  grantPrivilegesSuccess,
  loadRolePrivileges,
  loadRolePrivilegesFailure,
  loadRolePrivilegesSuccess,
  replaceRolePrivileges,
  revokePrivileges,
  revokePrivilegesFailure,
  revokePrivilegesSuccess,
  saveRolePrivilegesChanges,
  RolePrivilegeValue,
} from './roles.slice';
import { workspacePrivilegeTableStructure } from './privileges';
import { managedWorkspaceRolePrivilegesDataSelector } from './roles.selectors';
import { workspaceIdSelector } from '../../../commonFeatures/workspaceId/workspaceId.selectors';
import { WorkspaceRolePrivilege } from '../../../../../api/domainModels/workspacePrivileges';

const getSharedValue = (values: any) =>
  uniq(values).length === 1 ? values[0] : undefined;

const getGroupValues = (groupMaps: any, setOriginalValues = false) => {
  const roleIds = [...new Set(flatten(groupMaps.map(Object.keys)))] as number[];

  return roleIds.reduce((acc, roleId) => {
    const item: RolePrivilegeValue = {
      roleId,
      value: getSharedValue(groupMaps.map((entry: any) => entry[roleId].value)),
      readOnly: getSharedValue(
        groupMaps.map((entry: any) => entry[roleId].readOnly),
      ),
    };

    if (setOriginalValues) {
      item.originalValue = item.value;
    }

    acc[roleId] = item;

    return acc;
  }, {} as { [key: number]: RolePrivilegeValue });
};

type PreProcessEntry = {
  privilegeId: string;
  roleId: number;
  value?: boolean;
  originalValue: boolean;
  readOnly: boolean;
};

// TODO: HT-5645 Remove these conversions
const convertToIamPrivilegeId = (id: string) =>
  id.toUpperCase().replaceAll('.', '_');

const convertToOldPrivilegeId = (id: string) =>
  id.toLowerCase().replace('_', '.');

const preProcessEntries = (rawEntries: WorkspaceRolePrivilege[]) => {
  const convertEntry = ({
    privilegeId,
    roleId,
    hasGrant,
    readonly,
  }: WorkspaceRolePrivilege): PreProcessEntry => ({
    privilegeId: convertToIamPrivilegeId(privilegeId),
    roleId,
    value: hasGrant,
    originalValue: hasGrant,
    readOnly: readonly,
  });

  return rawEntries.map(convertEntry);
};

function* loadRolePrivilegesHandler(
  action: ActionType<typeof loadRolePrivileges>,
) {
  const { workspaceId } = action.payload;

  try {
    const response = yield* call(apiLoadWorkspaceRolePrivileges, workspaceId);
    const allEntries = preProcessEntries(response.data);
    const privilegeEntriesMap = groupBy(allEntries, 'privilegeId');
    const payload = workspacePrivilegeTableStructure.reduce(
      (groups, groupStructure) => {
        const simple = groupStructure.privileges.length === 1;
        const convertEntriesToRolePrivilegeMap = (entries: PreProcessEntry[]) =>
          entries.reduce((acc, entry) => {
            // keeping roleId here because of it number type, to avoid conversions from/to string
            acc[entry.roleId] = {
              roleId: entry.roleId,
              value: !!entry.value,
              originalValue: entry.originalValue,
              readOnly: entry.readOnly,
            };

            return acc;
          }, {} as Record<string, RolePrivilegeValue>);

        const mapper = (privilege: {
          id: string;
          title: string;
          description: string;
        }) =>
          convertEntriesToRolePrivilegeMap(privilegeEntriesMap[privilege.id]);
        const privileges = groupStructure.privileges.map(mapper);

        // values field contains a map roleId: { value, originalValue }
        groups[groupStructure.id] = {
          group: groupStructure.title,
          simple,
          values: getGroupValues(privileges, true),
          privileges: groupStructure.privileges.reduce((acc, privilege) => {
            acc[privilege.id] = {
              ...privilege,
              simple,
              values: mapper(privilege),
            };

            return acc;
          }, {} as { [key: string]: any }),
        };

        return groups;
      },
      {} as { [key: string]: any },
    );

    yield* put(loadRolePrivilegesSuccess(payload));
  } catch (error) {
    yield* put(
      loadRolePrivilegesFailure({
        message: getErrorMessage(error, 'Not able to fetch role privileges'),
      }),
    );
  }
}

function* grantPrivilegesHandler(action: ActionType<typeof grantPrivileges>) {
  const { workspaceId, roleId, privilegeIds } = action.payload;
  const oldPrivilegeIds = privilegeIds.map((id) => convertToOldPrivilegeId(id));
  try {
    yield* call(
      apiGrantWorkspacePrivileges,
      workspaceId,
      roleId,
      oldPrivilegeIds,
    );
    yield* put(grantPrivilegesSuccess());
  } catch (error) {
    yield* put(
      grantPrivilegesFailure({
        message: getErrorMessage(error, 'Not able to grant the privileges'),
      }),
    );
  }
}

export function* revokePrivilegesHandler(
  action: ActionType<typeof revokePrivileges>,
) {
  const { workspaceId, roleId, privilegeIds } = action.payload;
  const oldPrivilegeIds = privilegeIds.map((id) => convertToOldPrivilegeId(id));

  try {
    yield* call(
      apiRevokeWorkspacePrivileges,
      workspaceId,
      roleId,
      oldPrivilegeIds,
    );

    yield* put(
      revokePrivilegesSuccess({
        workspaceId,
        roleId,
      }),
    );
  } catch (error) {
    yield* put(
      revokePrivilegesFailure({
        message: getErrorMessage(error, 'Not able to revoke the privileges'),
      }),
    );
  }
}

function* changeRolePrivilegeHandler(
  action: ActionType<typeof changeRolePrivilege>,
) {
  const { groupId, roleId, privilegeId, value } = action.payload;
  const groupedPrivileges = cloneDeep(
    yield* select(managedWorkspaceRolePrivilegesDataSelector),
  );

  const currentGroup = groupedPrivileges[groupId];
  const currentGroupRoleValues = currentGroup.values[roleId];

  currentGroup.privileges[privilegeId].values[roleId].value = value;

  currentGroupRoleValues.value = getGroupValues(
    Object.values(currentGroup.privileges).map(({ values }) => values) as any,
  )[roleId].value as boolean;

  yield* put(replaceRolePrivileges(groupedPrivileges));
}

function* cancelRolePrivilegesChangesHandler() {
  const rolePrivileges = cloneDeep(
    yield* select(managedWorkspaceRolePrivilegesDataSelector),
  );

  Object.values(rolePrivileges).forEach(
    ({ privileges, values: groupValues }) => {
      Object.values(groupValues).forEach((entry) => {
        entry.value = entry.originalValue || false;
      });

      Object.values(privileges).forEach(({ values }) => {
        Object.values(values).forEach((entry) => {
          entry.value = entry.originalValue || false;
        });
      });
    },
  );

  yield* put(replaceRolePrivileges(rolePrivileges));
}

interface Changes {
  roleId: number;
  privilegeId: string;
  value: boolean;
}

function* confirmRolePrivilegesChanges() {
  const rolePrivileges = cloneDeep(
    yield* select(managedWorkspaceRolePrivilegesDataSelector),
  );

  Object.values(rolePrivileges).forEach(
    ({ privileges, values: groupValues }) => {
      Object.values(groupValues).forEach((entry) => {
        entry.originalValue = entry.value;
      });

      Object.values(privileges).forEach(({ values }) => {
        Object.values(values).forEach((entry) => {
          entry.originalValue = entry.value;
        });
      });
    },
  );

  yield* put(replaceRolePrivileges(rolePrivileges));
}

export function* saveRolePrivilegesChangesHandler() {
  const rolePrivileges = yield* select(
    managedWorkspaceRolePrivilegesDataSelector,
  );

  const workspaceId = yield* select(workspaceIdSelector);

  if (!workspaceId) return;

  const changes: Changes[] = [];

  Object.values(rolePrivileges).forEach(({ privileges }) => {
    Object.entries(privileges).forEach(([id, { values }]) => {
      // we're not using the key of role->value map to avoid conversion, because role id is integer

      Object.values(values).forEach(({ roleId, value, originalValue }) => {
        if (value !== originalValue) {
          changes.push({
            roleId,
            privilegeId: id,
            value,
          });
        }
      });
    });
  });

  // it split array into two, one where values equal to true, another one - to false
  // useful because we have separate grant and revoke endpoints

  const promises = partition(changes, 'value').map(
    (grantsOrRevokes, index: number) => {
      const action = [grantPrivileges, revokePrivileges][index];

      return Object.entries(groupBy(grantsOrRevokes, 'roleId')).map(
        ([roleId, roleGrantsOrRevokes]) =>
          put(
            action({
              workspaceId,
              roleId: Number(roleId),
              privilegeIds: roleGrantsOrRevokes.map(
                ({ privilegeId }) => privilegeId,
              ),
            }),
          ),
      );
    },
  );

  yield* all(flatten(promises));

  yield* confirmRolePrivilegesChanges();
}

function* changeRolePrivilegeGroupHandler(
  action: ActionType<typeof changeRolePrivilegeGroup>,
) {
  const { groupId, roleId, value } = action.payload;
  const groupedPrivileges = cloneDeep(
    yield* select(managedWorkspaceRolePrivilegesDataSelector),
  );

  const currentGroup = groupedPrivileges[groupId];
  const currentGroupRole = currentGroup.values[roleId];
  const singlePrivileges = groupedPrivileges[groupId].privileges;

  // we set all the atomic values first and then check what the group value is

  Object.values(singlePrivileges).forEach(({ values }) => {
    if (!values[roleId].readOnly) {
      values[roleId].value = value;
    }
  });

  // it might happen that we can't write some of the privileges, so we're not able to change all the values
  currentGroupRole.value = getSharedValue(
    Object.values(singlePrivileges).map(({ values }) => values[roleId].value),
  );

  yield* put(replaceRolePrivileges(groupedPrivileges));
}

function* deleteRoleSuccessHandler(
  action: ActionType<typeof deleteRoleSuccess>,
) {
  const { roleId } = action.payload;
  const rolePrivileges = cloneDeep(
    yield* select(managedWorkspaceRolePrivilegesDataSelector),
  );

  Object.values(rolePrivileges).forEach(
    ({ privileges, values: groupValues }) => {
      delete groupValues[roleId];

      Object.values(privileges).forEach(({ values }) => {
        delete values[roleId];
      });
    },
  );
  yield* put(replaceRolePrivileges(rolePrivileges));
}

function* createRoleSuccessHandler(
  action: ActionType<typeof createRoleSuccess>,
) {
  const { payload } = action;
  const rolePrivileges = cloneDeep(
    yield* select(managedWorkspaceRolePrivilegesDataSelector),
  );

  Object.values(rolePrivileges).forEach(
    ({ privileges, values: groupValues }: any) => {
      groupValues[payload.id] = {
        value: false,
        originalValue: false,
        readOnly: false,
      };

      Object.values(privileges).forEach(({ values }: any) => {
        values[payload.id] = {
          roleId: payload.id,
          value: false,
          originalValue: false,
          readOnly: false,
        };
      });
    },
  );
  yield* put(replaceRolePrivileges(rolePrivileges));
}

export function* privilegesSaga() {
  yield* takeEvery(loadRolePrivileges, loadRolePrivilegesHandler);
  yield* takeEvery(grantPrivileges, grantPrivilegesHandler);
  yield* takeEvery(revokePrivileges, revokePrivilegesHandler);
  yield* takeEvery(changeRolePrivilege, changeRolePrivilegeHandler);
  yield* takeEvery(changeRolePrivilegeGroup, changeRolePrivilegeGroupHandler);
  yield* takeEvery(deleteRoleSuccess, deleteRoleSuccessHandler);
  yield* takeEvery(createRoleSuccess, createRoleSuccessHandler);
  yield* takeEvery(
    cancelRolePrivilegesChanges,
    cancelRolePrivilegesChangesHandler,
  );
  yield* takeEvery(saveRolePrivilegesChanges, saveRolePrivilegesChangesHandler);
}
