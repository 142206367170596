import { getLabelType } from './utils';
import {
  ApiErrorFinderRun,
  ApiErrorFinderRunCreditsEstimation,
  ApiErrorFinderRunLockedResults,
  ApiLabelConfidence,
  ApiRunErrorsPerClass,
  ApiLabelClassConfidenceHist,
  ApiRunConfusionMatrix,
  ApiErrorTagResultError,
  ApiErrorTagResultTags,
  ApiErrorAttributeResultError,
  ApiErrorAttributeResultAttribute,
  ApiLoadErrorLabelsResponse,
  ApiErrorClLabelCombined,
  ApiErrorOdIsLabelCombined,
  ApiErrorAttributeResultCombined,
  ApiErrorTagResultCombined,
  ApiLoadErrorLabelsParams,
  ApiLoadErrorLabelsCombinedResponse,
  ApiErrorTagResult,
  ApiErrorAttributeResult,
  ApiErrorSemanticResultCombined,
} from '../schemas/consensusScoring';
import { LabelClass } from './labelClass';
import {
  DEFAULT_GROUP_ID,
  DEFAULT_GROUP_NAME,
  DEFAULT_GROUP_TYPE,
} from './tagGroup';
import { ErrorFinderAction, RunType } from '../constants/consensusScoring';
import { ModelFamily } from '../constants/modelFamily';
import { Label } from './imageLabel';

export enum RunStatus {
  Initial = 'INIT',
  Predicting = 'PREDICTING',
  Running = 'TRAINING',
  Failed = 'FAILED',
  Done = 'DONE',
  PreparingFolds = 'PREPARING_FOLDS',
}

export enum ErrorType {
  Misclassification = 'MISCLASSIFICATION',
  LowIou = 'LOW_IOU',
  MissingLabel = 'MISSING_LABEL',
  ExtraLabel = 'EXTRA_LABEL',
  MissingTag = 'MISSING_TAG',
  ExtraTag = 'EXTRA_TAG',
  MissingAttribute = 'MISSING_ATTRIBUTE',
  ExtraAttribute = 'EXTRA_ATTRIBUTE',
  MissingArea = 'MISSING_AREA',
  ExtraArea = 'EXTRA_AREA',
}

export const runStatusLabels: Record<RunStatus, string> = {
  [RunStatus.Initial]: 'Initializing',
  [RunStatus.Predicting]: 'Predicting',
  [RunStatus.Running]: 'Running',
  [RunStatus.Failed]: 'Failed',
  [RunStatus.Done]: 'Complete',
  [RunStatus.PreparingFolds]: 'Preparing folds',
};

export const runTypeLabels: Record<RunType, string> = {
  [RunType.ClassReview]: 'Class review',
  [RunType.ObjectReview]: 'Object detection review',
  [RunType.InstanceReview]: 'Instance segmentation review',
  [RunType.TagReview]: 'Tag review',
  [RunType.AttributeReview]: 'Attribute review',
  [RunType.SemanticReview]: 'Semantic segmentation review',
};

export const runTypeToModelFamily: Record<RunType, ModelFamily> = {
  [RunType.ClassReview]: ModelFamily.Classifier,
  [RunType.ObjectReview]: ModelFamily.Detector,
  [RunType.InstanceReview]: ModelFamily.Segmentor,
  [RunType.TagReview]: ModelFamily.ImageTagger,
  [RunType.AttributeReview]: ModelFamily.Attributer,
  [RunType.SemanticReview]: ModelFamily.SemanticSegmentor,
};

export const errorFinderActionTypeLabels: Record<ErrorFinderAction, string> = {
  [ErrorFinderAction.NotError]: 'Rejected prediction',
  [ErrorFinderAction.ChangedClass]: 'Accepted prediction',
  [ErrorFinderAction.ChangedShape]: 'Accepted prediction',
  [ErrorFinderAction.AddLabel]: 'Added label',
  [ErrorFinderAction.DeleteLabel]: 'Removed label',
  [ErrorFinderAction.ChangedManually]: 'Changed manually',
  [ErrorFinderAction.Accept]: 'Accepted',
  [ErrorFinderAction.None]: 'Not reviewed',
};

export const errorTypeTypeLabels: Record<ErrorType, string> = {
  [ErrorType.Misclassification]: 'Misclassification',
  [ErrorType.LowIou]: 'Low Iou',
  [ErrorType.MissingLabel]: 'Missing label',
  [ErrorType.ExtraLabel]: 'Extra label',
  [ErrorType.MissingTag]: 'Missing tag',
  [ErrorType.ExtraTag]: 'Extra tag',
  [ErrorType.MissingAttribute]: 'Missing attribute',
  [ErrorType.ExtraAttribute]: 'Extra attribute',
  [ErrorType.MissingArea]: 'Missing area',
  [ErrorType.ExtraArea]: 'Extra area',
};

export const runTypes = Object.values(RunType);

const fromBackendRun = ({ status, type, ...entry }: ApiErrorFinderRun) => ({
  status: status as RunStatus,
  type: type as RunType,
  ...entry,
});

export type ErrorFinderRun = ReturnType<typeof fromBackendRun>;

export const errorFinderRunDataMapper = {
  fromBackend: fromBackendRun,
};

const fromBackendClLabel = ({
  bbox, // original
  mask, // original
  polygon, // original
  thumbBbox, // scaled
  thumbMask, // scaled
  thumbPolygon, // scaled
  aicsAction,
  errorType,
  ...entry
}: ApiErrorClLabelCombined) => ({
  bbox: thumbBbox,
  mask: thumbMask,
  points: thumbPolygon,
  trueBbox: bbox,
  trueMask: mask,
  truePoints: polygon,
  type: getLabelType({ polygon: thumbPolygon, mask: thumbMask } as {
    polygon: Label['polygon'];
    mask: Label['mask'];
  }),
  efAction: aicsAction as ErrorFinderAction | null,
  errorType: errorType as ErrorType,
  ...entry,
});

const fromBackendOdIsLabel = ({
  thumbTruePolygon,
  thumbPredPolygon,
  predPolygon,
  truePolygon,
  aicsAction,
  errorType,
  ...entry
}: ApiErrorOdIsLabelCombined) => ({
  thumbTruePolygon: Array.isArray(thumbTruePolygon)
    ? thumbTruePolygon.flat()
    : thumbTruePolygon,
  thumbPredPolygon: Array.isArray(thumbPredPolygon)
    ? thumbPredPolygon.flat()
    : thumbPredPolygon,
  predPolygon: Array.isArray(predPolygon) ? predPolygon.flat() : predPolygon,
  truePolygon: Array.isArray(truePolygon) ? truePolygon.flat() : truePolygon,
  efAction: aicsAction as ErrorFinderAction | null,
  errorType: errorType as ErrorType,
  ...entry,
});

const fromBackendTagReviewLabel = ({
  errors,
  tags,
  ...rest
}: ApiErrorTagResultCombined) => ({
  ...rest,
  id: rest.imageId,
  items: groupTagsByTagGroupId(errors, tags),
});

const fromBackendAttributeReviewLabel = ({
  errors,
  attributes,
  bbox, // original
  mask, // original
  polygon, // original,
  thumbBbox, // scaled
  thumbPolygon, // scaled
  thumbMask, // scaled
  ...rest
}: ApiErrorAttributeResultCombined) => ({
  ...rest,
  id: `${rest.imageId}-${rest.labelId}`,
  items: groupAttributesByAttributeId(errors, attributes),
  type: getLabelType({ polygon, mask } as {
    polygon: Label['polygon'];
    mask: Label['mask'];
  }),
  bbox: thumbBbox,
  mask: thumbMask,
  points: Array.isArray(thumbPolygon) ? thumbPolygon.flat() : thumbPolygon,
  trueBbox: bbox,
  trueMask: mask,
  truePoints: Array.isArray(polygon) ? polygon.flat() : polygon,
});

const fromBackendSemanticLabel = ({
  aicsAction,
  errorType,
  predBbox,
  predMask,
  ...rest
}: ApiErrorSemanticResultCombined) => ({
  ...rest,
  errorType: errorType as ErrorType,
  efAction: aicsAction as ErrorFinderAction | null,
  bbox: predBbox,
  mask: predMask,
  labelId: rest.refLabelId,
  type: getLabelType({ mask: predMask } as {
    polygon: Label['polygon'];
    mask: Label['mask'];
  }),
});

export type ErrorClLabel = ReturnType<typeof fromBackendClLabel>;
export type ErrorTagLabel = ReturnType<typeof fromBackendTagReviewLabel>;

export type EnrichedErrorClLabel = ErrorClLabel & {
  labelClass?: LabelClass;
};

export type ErrorOdIsLabel = ReturnType<typeof fromBackendOdIsLabel>;

export type EnrichedErrorOdIsLabel = ErrorOdIsLabel & {
  predLabelClass?: LabelClass | null;
  trueLabelClass?: LabelClass | null;
};

export type ErrorAttributeLabel = ReturnType<
  typeof fromBackendAttributeReviewLabel
>;

export type EnrichedErrorAttributeLabel = ErrorAttributeLabel & {
  labelClass?: LabelClass;
};

export type ErrorSemanticLabel = ReturnType<typeof fromBackendSemanticLabel>;

export type EnrichedErrorSemanticLabel = ErrorSemanticLabel & {
  labelClass?: LabelClass;
};

export const errorFinderLabelDataMapper = {
  [RunType.ClassReview]: {
    fromBackend: fromBackendClLabel,
  },
  [RunType.ObjectReview]: {
    fromBackend: fromBackendOdIsLabel,
  },
  [RunType.InstanceReview]: {
    fromBackend: fromBackendOdIsLabel,
  },
  [RunType.TagReview]: {
    fromBackend: fromBackendTagReviewLabel,
  },
  [RunType.AttributeReview]: {
    fromBackend: fromBackendAttributeReviewLabel,
  },
  [RunType.SemanticReview]: {
    fromBackend: fromBackendSemanticLabel,
  },
};

export type ErrorLabelConfidence = ApiLabelConfidence;
export type RunErrorsPerClass = ApiRunErrorsPerClass;
export type LabelClassConfidenceHist = ApiLabelClassConfidenceHist;
export type RunConfusionMatrix = ApiRunConfusionMatrix;
export type RunCreditsEstimation = ApiErrorFinderRunCreditsEstimation;
export type RunLockedResults = ApiErrorFinderRunLockedResults;

export function isClTypeLabel(
  label:
    | EnrichedErrorClLabel
    | EnrichedErrorOdIsLabel
    | ErrorTagLabel
    | ErrorAttributeLabel
    | EnrichedErrorSemanticLabel,
): label is EnrichedErrorClLabel {
  return label
    ? (label as EnrichedErrorClLabel).predictedClassId !== undefined
    : false;
}

export function isOdIsTypeLabel(
  label:
    | EnrichedErrorClLabel
    | EnrichedErrorOdIsLabel
    | ErrorTagLabel
    | ErrorAttributeLabel
    | EnrichedErrorSemanticLabel,
): label is EnrichedErrorOdIsLabel {
  return label
    ? (label as EnrichedErrorOdIsLabel).predBbox !== undefined
    : false;
}

export function isTagTypeLabel(
  label:
    | EnrichedErrorClLabel
    | EnrichedErrorOdIsLabel
    | ErrorTagLabel
    | ErrorAttributeLabel
    | EnrichedErrorSemanticLabel,
): label is ErrorTagLabel {
  if (!label) return false;

  const errorTagLabel = label as ErrorTagLabel;

  return (
    typeof errorTagLabel.items !== 'undefined' &&
    Object.keys(errorTagLabel.items).length > 0 &&
    Object.keys(errorTagLabel.items).some(
      (key) =>
        typeof errorTagLabel.items[key].errorTags !== 'undefined' ||
        typeof errorTagLabel.items[key].existingTags !== 'undefined',
    )
  );
}

export function isAttributeTypeLabel(
  label:
    | EnrichedErrorClLabel
    | EnrichedErrorOdIsLabel
    | ErrorTagLabel
    | ErrorAttributeLabel
    | EnrichedErrorSemanticLabel,
): label is ErrorAttributeLabel {
  if (!label) return false;

  const errorAttributeLabel = label as ErrorAttributeLabel;

  return (
    typeof errorAttributeLabel.items !== 'undefined' &&
    Object.keys(errorAttributeLabel.items).length > 0 &&
    Object.keys(errorAttributeLabel.items).some(
      (key) =>
        typeof errorAttributeLabel.items[key].errorAttributes !== 'undefined' ||
        typeof errorAttributeLabel.items[key].errorAttributes !== 'undefined',
    )
  );
}

export function isSemanticTypeLabel(
  label:
    | EnrichedErrorClLabel
    | EnrichedErrorOdIsLabel
    | ErrorTagLabel
    | ErrorAttributeLabel
    | EnrichedErrorSemanticLabel,
): label is EnrichedErrorSemanticLabel {
  return label
    ? (label as EnrichedErrorSemanticLabel).maskArea !== undefined
    : false;
}

type ApiErrorTagResultTagGroup = {
  tagGroupId: string;
  tagGroupName: string;
  tagGroupType: string;
};

type ApiErrorTagResultErrorTag = {
  id: string;
  curTagId: string | null;
  curTagName: string | null;
  predTagId: string | null;
  predTagName: string | null;
  confidence: number;
  errorType: ErrorType;
  efAction: ErrorFinderAction | null;
  labeledBy: string;
  reviewedBy: string | null;
};

type ApiErrorTagResultExistingTag = {
  id: string;
  tagClassId: string;
  tagClassName: string;
  labeledBy: string;
};

type ApiErrorTagResultTagGroupedMap = {
  [key: string]: ApiErrorTagResultTagGroup & {
    tags: {
      [key: string]: ApiErrorTagResultExistingTag;
    };
  };
};

type ApiErrorTagResultErrorGroupedMap = {
  [key: string]: ApiErrorTagResultTagGroup & {
    tags: {
      [key: string]: ApiErrorTagResultErrorTag;
    };
  };
};

const groupTagReviewsErrorsByTagGroupId = (errors: ApiErrorTagResultError[]) =>
  errors.reduce((acc, error) => {
    const {
      tagGroupId,
      tagGroupName,
      tagGroupType,
      aicsTagId,
      errorType,
      aicsAction,
      ...rest
    } = error;

    const groupId = tagGroupId || DEFAULT_GROUP_ID;
    const groupName = tagGroupName || DEFAULT_GROUP_NAME;
    const groupType = tagGroupType || DEFAULT_GROUP_TYPE;

    const existing = acc[groupId];

    if (existing) {
      return {
        ...acc,
        [groupId]: {
          ...existing,
          tags: {
            ...existing.tags,
            [aicsTagId]: {
              ...rest,
              id: aicsTagId,
              errorType: errorType as ErrorType,
              efAction: aicsAction as ErrorFinderAction | null,
            },
          },
        },
      };
    }

    return {
      ...acc,
      [groupId]: {
        tagGroupId: groupId,
        tagGroupName: groupName,
        tagGroupType: groupType,
        tags: {
          [aicsTagId]: {
            ...rest,
            id: aicsTagId,
            errorType: errorType as ErrorType,
            efAction: aicsAction as ErrorFinderAction | null,
          },
        },
      },
    };
  }, {} as ApiErrorTagResultErrorGroupedMap);

const groupTagReviewsTagsByTagGroupId = (tags: ApiErrorTagResultTags[]) =>
  tags.reduce((acc, tag) => {
    const { tagGroupId, tagGroupName, tagGroupType, tagId, ...rest } = tag;

    const groupId = tagGroupId || DEFAULT_GROUP_ID;
    const groupName = tagGroupName || DEFAULT_GROUP_NAME;
    const groupType = tagGroupType || DEFAULT_GROUP_TYPE;

    const existing = acc[groupId];

    if (existing) {
      return {
        ...acc,
        [groupId]: {
          ...existing,
          tags: {
            ...existing.tags,
            [rest.tagClassId]: {
              id: tagId,
              ...rest,
            },
          },
        },
      };
    }

    return {
      ...acc,
      [groupId]: {
        tagGroupId: groupId,
        tagGroupName: groupName,
        tagGroupType: groupType,
        tags: {
          [rest.tagClassId]: {
            id: tagId,
            ...rest,
          },
        },
      },
    };
  }, {} as ApiErrorTagResultTagGroupedMap);

export type ErrorTagLabelTagsGrouped = ApiErrorTagResultTagGroup & {
  errorTags: { [key: string]: ApiErrorTagResultErrorTag };
  existingTags: { [key: string]: ApiErrorTagResultExistingTag };
};

type ErrorTagLabelTagsGroupedMap = {
  [key: string]: ErrorTagLabelTagsGrouped;
};

const groupTagsByTagGroupId = (
  errors: ApiErrorTagResultError[],
  tags: ApiErrorTagResultTags[] | null,
) => {
  const parsedErrors = groupTagReviewsErrorsByTagGroupId(errors);
  const parsedTags = groupTagReviewsTagsByTagGroupId(tags || []);

  const ids = Object.keys(parsedErrors).concat(Object.keys(parsedTags));
  const uniqueIds = [...new Set(ids)];

  return uniqueIds.reduce((acc, id) => {
    const { tagGroupId, tagGroupName, tagGroupType } =
      parsedErrors[id] || parsedTags[id];

    return {
      ...acc,
      [id]: {
        tagGroupId,
        tagGroupName,
        tagGroupType,
        errorTags: parsedErrors[id]?.tags || {},
        existingTags: parsedTags[id]?.tags || {},
      },
    };
  }, {} as ErrorTagLabelTagsGroupedMap);
};

type ApiErrorAttributeResultAttributeGroup = {
  attributeId: string;
  attributeName: string;
  attributeType: string;
};

type ApiErrorAttributeResultErrorAttribute = {
  id: string;
  predValueId: string | null;
  predValue: string | null;
  curValueId: string | null;
  curValue: string | null;
  confidence: number;
  errorType: ErrorType;
  efAction: ErrorFinderAction | null;
  labeledBy: string;
  reviewedBy: string | null;
};

type ApiErrorAttributeResultExistingAttribute = {
  id: string;
  value: string;
  valueId: string;
  labeledBy: string;
};

export type ErrorAttributeLabelAttributesGrouped =
  ApiErrorAttributeResultAttributeGroup & {
    errorAttributes: { [key: string]: ApiErrorAttributeResultErrorAttribute };
    existingAttributes: {
      [key: string]: ApiErrorAttributeResultExistingAttribute;
    };
  };

type ErrorAttributeLabelAttributesGroupedMap = {
  [key: string]: ErrorAttributeLabelAttributesGrouped;
};

const groupAttributesByAttributeId = (
  errors: ApiErrorAttributeResultError[],
  attributes: ApiErrorAttributeResultAttribute[] | null,
) => {
  const parsedErrors = groupAttributeReviewsErrorsByAttributeId(errors);
  const parsedAttributes = groupAttributeReviewsAttributesByAttributeId(
    attributes || [],
  );

  const ids = Object.keys(parsedErrors).concat(Object.keys(parsedAttributes));
  const uniqueIds = [...new Set(ids)];

  return uniqueIds.reduce((acc, id) => {
    const { attributeId, attributeName, attributeType } =
      parsedErrors[id] || parsedAttributes[id];

    return {
      ...acc,
      [id]: {
        attributeId,
        attributeName,
        attributeType,
        errorAttributes: parsedErrors[id]?.attributes || {},
        existingAttributes: parsedAttributes[id]?.attributes || {},
      },
    };
  }, {} as ErrorAttributeLabelAttributesGroupedMap);
};

type ApiErrorAttributeResultErrorGroupedMap = {
  [key: string]: ApiErrorAttributeResultAttributeGroup & {
    attributes: {
      [key: string]: ApiErrorAttributeResultErrorAttribute;
    };
  };
};

const groupAttributeReviewsErrorsByAttributeId = (
  errors: ApiErrorAttributeResultError[],
) =>
  errors.reduce((acc, error) => {
    const {
      attributeId,
      attributeName,
      attributeType,
      aicsAttributeId,
      errorType,
      aicsAction,
      ...rest
    } = error;

    const existing = acc[attributeId];

    if (existing) {
      return {
        ...acc,
        [attributeId]: {
          ...existing,
          attributes: {
            ...existing.attributes,
            [aicsAttributeId]: {
              ...rest,
              id: aicsAttributeId,
              errorType: errorType as ErrorType,
              efAction: aicsAction as ErrorFinderAction | null,
            },
          },
        },
      };
    }

    return {
      ...acc,
      [attributeId]: {
        attributeId,
        attributeName,
        attributeType,
        attributes: {
          [aicsAttributeId]: {
            ...rest,
            id: aicsAttributeId,
            errorType: errorType as ErrorType,
            efAction: aicsAction as ErrorFinderAction | null,
          },
        },
      },
    };
  }, {} as ApiErrorAttributeResultErrorGroupedMap);

type ApiErrorAttributeResultAttributeGroupedMap = {
  [key: string]: ApiErrorAttributeResultAttributeGroup & {
    attributes: {
      [key: string]: ApiErrorAttributeResultExistingAttribute;
    };
  };
};

const groupAttributeReviewsAttributesByAttributeId = (
  attributes: ApiErrorAttributeResultAttribute[],
) =>
  attributes.reduce((acc, attribute) => {
    const {
      attributeId,
      attributeName,
      attributeType,
      labelAttributeId,
      ...rest
    } = attribute;

    const existing = acc[attributeId];

    if (existing) {
      return {
        ...acc,
        [attributeId]: {
          ...existing,
          attributes: {
            ...existing.attributes,
            [rest.valueId]: {
              id: labelAttributeId,
              ...rest,
            },
          },
        },
      };
    }

    return {
      ...acc,
      [attributeId]: {
        attributeId,
        attributeName,
        attributeType,
        attributes: {
          [rest.valueId]: {
            id: labelAttributeId,
            ...rest,
          },
        },
      },
    };
  }, {} as ApiErrorAttributeResultAttributeGroupedMap);

export type ErrorTagResultErrorTag = ApiErrorTagResultErrorTag;
export type ErrorTagResultExistingTag = ApiErrorTagResultExistingTag;

export type ErrorAttributeResultErrorAttribute =
  ApiErrorAttributeResultErrorAttribute;
export type ErrorAttributeResultExistingAttribute =
  ApiErrorAttributeResultExistingAttribute;
export type LoadErrorLabelsResponse = ApiLoadErrorLabelsResponse;
export type LoadErrorLabelsCombinedResponse =
  ApiLoadErrorLabelsCombinedResponse;
export type LoadErrorLabelsParams = ApiLoadErrorLabelsParams;

export type ErrorTagResult = ApiErrorTagResult;
export type ErrorAttributeResult = ApiErrorAttributeResult;
