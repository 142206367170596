import { createSelector } from '@reduxjs/toolkit';

import { fileManagerImageAdapter } from './images.slice';
import { projectSelector } from '../../project.selectors';

const baseStateSelector = createSelector(
  projectSelector,
  (state) => state.fileManager.images,
);

const selectors = fileManagerImageAdapter.getSelectors(baseStateSelector);

export const fileManagerImagesSelector = selectors.selectAll;
export const fileManagerImagesMapSelector = selectors.selectEntities;
export const fileManagerImagesTotalOnPageSelector = selectors.selectTotal;

export const fileManagerImagesLoadingStateSelector = createSelector(
  baseStateSelector,
  (state) => state.loadingState,
);

export const fileManagerImagesActionLoadingStateSelector = createSelector(
  baseStateSelector,
  (state) => state.actionLoadingState,
);

export const fileManagerImagesFiltersSelector = createSelector(
  baseStateSelector,
  (state) => state.filters,
);

export const fileManagerSelectedDatasetsTotalFoundImagesSelector =
  createSelector(
    baseStateSelector,
    (state) => state.selectedDatasetTotalImages,
  );

export const fileManagerAllDatasetsTotalFoundImagesSelector = createSelector(
  baseStateSelector,
  (state) => state.allDatasetsTotalImages,
);

export const fileManagerImagesSelectedIdsSelector = createSelector(
  baseStateSelector,
  (state) => state.selectedIds,
);
