import { put, takeEvery, select, takeLatest, take } from 'typed-redux-saga';

import { setImageId } from '../currentImage/currentImage.slice';
import { imageViewIsCurrentImageLockedSelector } from '../currentImage/lock/lock.selectors';
import {
  MODEL_TOOLS,
  ModelTool,
  modelToolStatePropertiesSelector,
} from './models/models.selectors';
import {
  activeToolIdSelector,
  areGuidesVisibleSelector,
  isTrackpadPanningEnabledSelector,
} from './tools.selectors';
import {
  trySetActiveTool,
  setActiveTool,
  resetActiveTool,
  setGuidesVisibility,
  toggleGuides,
  toggleTrackpadPanning,
  setTrackpadPanningEnabled,
  setZoomSpeed,
} from './tools.slice';
import { ImageTool } from './tools.constants';
import { objectDetectionSaga } from './objectDetection/objectDetection.saga';
import { imageTagsPredictionSaga } from './imageTagsPrediction/imageTagsPrediction.saga';
import { attributesPredictionSaga } from './labelAttributesPrediction/labelAttributesPrediction.saga';
import { semanticSegmentationSaga } from './semanticSegmentation/semanticSegmentation.saga';
import { instanceSegmentationSaga } from './instanceSegmentation/instanceSegmentation.saga';
import { labelClassPredictionSaga } from './labelClassPrediction/labelClassPrediction.saga';
import { automatedLabelingSaga } from './automatedLabeling/automatedLabeling.saga';
import { boxToInstanceSaga } from './boxToInstance/boxToInstance.saga';
import { drawingSaga } from './drawing/drawing.saga';
import { activeToolDataSaga } from './activeToolData/activeToolData.saga';
import { disablePanning, enablePanning } from '../panning/panning.slice';
import { resetSelection } from '../labels/selectedLabels/selectedLabels.slice';
import { DEFAULT_ZOOM_SPEED } from '../../../../../constants/imageView';
import { atomSagas } from './atom/atom.saga';
import { activeLabelClassIdSelector } from '../labelClasses/labelClasses.selectors';
import { showModal } from '../../../ui/modals/modals.slice';
import { addLabelClassSuccess } from '../../../project/annotationTaxonomy/labelClasses/labelClasses.slice';
import { keypointsSagas } from './keypoints/keypoints.sagas';
import { keypointsDetectionSaga } from './keypointsDetection/keypointsDetection.saga';
import { textPromptSaga } from './textPrompt/textPrompt.saga';
import {
  getLocalStorageItem,
  upsertLocalStorageItem,
} from '../../../../../helpers/localStorage';
import {
  GUIDES_STORAGE_PREFIX,
  TRACKPAD_PANNING_STORAGE_PREFIX,
  ZOOM_SPEED_STORAGE_PREFIX,
} from '../../../../../helpers/localStorage/constants';

function* trySetActiveToolHandler(action: ActionType<typeof trySetActiveTool>) {
  const toolId = action.payload;

  if (toolId === ImageTool.Pan) {
    yield* put(enablePanning());
  } else {
    yield* put(disablePanning());
  }

  const isImageLocked = yield* select(imageViewIsCurrentImageLockedSelector);

  if (isImageLocked) return;

  if (MODEL_TOOLS.includes(toolId)) {
    yield* put(resetSelection());

    const status = yield* select((state: RootState) =>
      modelToolStatePropertiesSelector(state, toolId as ModelTool),
    );

    if (status?.available) yield* put(setActiveTool(toolId));

    return;
  }
  if (toolId !== ImageTool.Default && toolId !== ImageTool.Pan) {
    const activeLabelClassId = yield* select(activeLabelClassIdSelector);

    if (!activeLabelClassId) {
      yield* put(
        showModal({
          modalName: 'upsertLabelClass',
          modalProps: { preLabelCreation: true },
        }),
      );

      yield* take(addLabelClassSuccess);
    }
  }

  yield* put(setActiveTool(toolId));
}

function* setImageIdHandler() {
  const activeTool = yield* select(activeToolIdSelector);

  if (MODEL_TOOLS.includes(activeTool)) {
    yield* put(resetActiveTool());
  }
}

function* toggleGuidesHandler() {
  const areGuidesVisible = yield* select(areGuidesVisibleSelector);

  yield* put(setGuidesVisibility(!areGuidesVisible));

  upsertLocalStorageItem(GUIDES_STORAGE_PREFIX, !areGuidesVisible);
}

function* toggleTrackpadPanningHandler() {
  const isTrackpadPanningEnabled = yield* select(
    isTrackpadPanningEnabledSelector,
  );

  yield* put(setTrackpadPanningEnabled(!isTrackpadPanningEnabled));

  upsertLocalStorageItem(
    TRACKPAD_PANNING_STORAGE_PREFIX,
    !isTrackpadPanningEnabled,
  );
}

function setZoomSpeedHandler(action: ActionType<typeof setZoomSpeed>) {
  const zoomSpeed = action.payload;

  upsertLocalStorageItem(ZOOM_SPEED_STORAGE_PREFIX, zoomSpeed);
}

function* initializeToolPreferences() {
  const guidesStorageValue = getLocalStorageItem<boolean>(
    GUIDES_STORAGE_PREFIX,
    false,
  );

  if (guidesStorageValue) {
    yield* put(setGuidesVisibility(guidesStorageValue));
  }

  const trackpadEnabledStorageValue = getLocalStorageItem<boolean>(
    TRACKPAD_PANNING_STORAGE_PREFIX,
    false,
  );

  if (trackpadEnabledStorageValue) {
    yield* put(setTrackpadPanningEnabled(trackpadEnabledStorageValue));
  }

  const zoomSpeedStorageValue = getLocalStorageItem<number>(
    ZOOM_SPEED_STORAGE_PREFIX,
    DEFAULT_ZOOM_SPEED,
  );

  if (zoomSpeedStorageValue) {
    yield* put(setZoomSpeed(zoomSpeedStorageValue));
  }
}

function* toolsSaga() {
  yield* takeEvery(trySetActiveTool, trySetActiveToolHandler);
  yield* takeLatest(setImageId, setImageIdHandler);
  yield* takeLatest(setImageId, initializeToolPreferences);
  yield* takeLatest(toggleGuides, toggleGuidesHandler);
  yield* takeLatest(toggleTrackpadPanning, toggleTrackpadPanningHandler);
  yield* takeLatest(setZoomSpeed, setZoomSpeedHandler);
}

export const toolSagas = [
  toolsSaga,
  objectDetectionSaga,
  imageTagsPredictionSaga,
  attributesPredictionSaga,
  semanticSegmentationSaga,
  instanceSegmentationSaga,
  labelClassPredictionSaga,
  boxToInstanceSaga,
  drawingSaga,
  activeToolDataSaga,
  automatedLabelingSaga,
  keypointsDetectionSaga,
  textPromptSaga,
  ...atomSagas,
  ...keypointsSagas,
];
