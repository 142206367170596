import { select, put, takeEvery, call } from 'typed-redux-saga';

import { convertToImagesDataMapper } from '../../../../../api/domainModels/convertToImages';
import {
  apiLoadConvertToImageSessions,
  apiAddConvertToImageSession,
} from '../../../../../api/requests/convertToImages';
import { getErrorMessage } from '../../../../../api/utils';
import { activeProjectIdSelector } from '../../../project/project.selectors';
import { loadAll } from '../../../../utils/api';
import {
  loadConvertToImagesSessionsStart,
  loadConvertToImagesSessionsSuccess,
  loadConvertToImagesSessionsFailure,
  addConvertToImagesSessionStart,
  addConvertToImagesSessionSuccess,
  addConvertToImagesSessionFailure,
  updateConvertToImagesSessionFromWebsocket,
  updateConvertToImagesSession,
} from './convertToImages.slice';
import { enqueueNotification } from '../../../ui/stackNotifications/stackNotifications.slice';

function* loadAllConvertToImagesSessionsHandler() {
  const projectId = yield* select(activeProjectIdSelector);

  try {
    const data = yield* loadAll({
      apiHelper: apiLoadConvertToImageSessions,
      fromBackend: convertToImagesDataMapper.fromBackend,
      params: {
        projectId,
      },
    });
    yield* put(loadConvertToImagesSessionsSuccess(data));
  } catch (e) {
    yield* put(
      loadConvertToImagesSessionsFailure(
        getErrorMessage(e, 'Not able to load convert to images sessions'),
      ),
    );
  }
}

function* initiateNewSessionHandler(
  action: ActionType<typeof addConvertToImagesSessionStart>,
) {
  const projectId = yield* select(activeProjectIdSelector);
  const values = action.payload;

  try {
    const { data } = yield* call(
      apiAddConvertToImageSession,
      {
        projectId,
      },
      values,
    );
    yield* put(
      addConvertToImagesSessionSuccess(
        convertToImagesDataMapper.fromBackend(data),
      ),
    );

    yield* put(
      enqueueNotification({
        message: 'Created conversion to images successfully',
        options: {
          variant: 'success',
          allowOutsideOfEditor: true,
        },
      }),
    );
  } catch (e) {
    yield* put(
      addConvertToImagesSessionFailure(
        getErrorMessage(e, 'Not able to add convert to images session'),
      ),
    );
  }
}

function* updateFromWebsocketHandler(
  action: ActionType<typeof updateConvertToImagesSessionFromWebsocket>,
) {
  const { projectId, id, completedOn, ...session } = action.payload;

  // BE is returning completedOn in timestamp in second so we do convestion here
  const completedOnString = new Date(completedOn * 1000).toISOString();

  const activeProjectId = yield* select(activeProjectIdSelector);

  if (activeProjectId !== projectId) return;

  yield* put(
    updateConvertToImagesSession({
      id,
      changes: { ...session, completedOn: completedOnString },
    }),
  );
}

export function* convertToImagesSaga() {
  yield* takeEvery(
    loadConvertToImagesSessionsStart,
    loadAllConvertToImagesSessionsHandler,
  );
  yield* takeEvery(addConvertToImagesSessionStart, initiateNewSessionHandler);
  yield* takeEvery(
    updateConvertToImagesSessionFromWebsocket,
    updateFromWebsocketHandler,
  );
}
