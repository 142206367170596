import { createSelector } from '@reduxjs/toolkit';

import { imageViewSelector } from '../../imageView.selectors';

export const drawingStateSelector = createSelector(
  imageViewSelector,
  (imageView) => imageView.tools.drawing,
);

export const brushSizeSelector = createSelector(
  drawingStateSelector,
  (drawingState) => drawingState.brushSize,
);

export const maskExistsSelector = createSelector(
  drawingStateSelector,
  (drawingState) => drawingState.maskExists,
);

export const editedMaskHasChangesSelector = createSelector(
  drawingStateSelector,
  (drawingState) => drawingState.editedMaskHasChanges,
);

export const overpaintingSelector = createSelector(
  drawingStateSelector,
  (drawingState) => drawingState.overpainting,
);

export const useSAMSelector = createSelector(
  drawingStateSelector,
  (drawingState) => drawingState.useSAM,
);

export const SAMLabelDataSelector = createSelector(
  drawingStateSelector,
  (drawingState) => drawingState.SAMLabelData,
);

export const SAMLoadingStateSelector = createSelector(
  drawingStateSelector,
  (drawingState) => drawingState.SAMLoadingState,
);

export const toolSelector = createSelector(
  drawingStateSelector,
  (drawingState) => drawingState.tool,
);
