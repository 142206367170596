import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ErrorType } from '../../../../../api/domainModels/consensusScoring';
import { ErrorFinderAction } from '../../../../../api/constants/consensusScoring';

export type FiltersData = {
  currentClassId?: string | null;
  predictedClassId?: string | null;
  datasetId?: string | null;
  imageStatus?: string | null;
  aicsAction?: ErrorFinderAction | null;
  errorType?: ErrorType | null;
  updatedSinceRun?: string | null;
  maxIou?: string | null;
  labelerId?: string | null;
  reviewerId?: string | null;
  minArea?: number | null;
  maxNumberOfErrors?: number | null;
};

type FiltersState = {
  data: FiltersData;
  activeCount: number;
};

export const initialState: FiltersState = {
  data: {
    currentClassId: null,
    predictedClassId: null,
    datasetId: null,
    imageStatus: null,
    aicsAction: null,
    errorType: null,
    updatedSinceRun: null,
    maxIou: null,
    labelerId: null,
    reviewerId: null,
  },
  activeCount: 0,
};

const { actions, reducer: errorFinderFiltersReducer } = createSlice({
  name: 'errorFinderFilters',
  initialState,
  reducers: {
    setErrorFinderActiveCount(state, action: PayloadAction<number>) {
      state.activeCount = action.payload;
    },
    setErrorFinderFilter(state, action: PayloadAction<FiltersData>) {
      state.data = { ...state.data, ...action.payload };
    },
    resetErrorFinderFilters(state) {
      //  we dont reset errorType because its not set in Filters but from Tabs
      const {
        data: { errorType: _errorType, ...initialDataWithoutErrorType },
      } = initialState;
      const { errorType } = state.data;

      return {
        ...initialState,
        data: { ...initialDataWithoutErrorType, errorType },
      };
    },
    resetErrorFinderFiltersInView() {
      // same, as resetEfFilters, but it will not clean url filters
      return initialState;
    },
    applyErrorFinderFilters() {},
  },
});

export { errorFinderFiltersReducer };
export const {
  setErrorFinderActiveCount,
  setErrorFinderFilter,
  applyErrorFinderFilters,
  resetErrorFinderFilters,
  resetErrorFinderFiltersInView,
} = actions;
