export enum Component {
  Model = 'MODEL',
  Scheduler = 'SCHEDULER',
  Solver = 'SOLVER',
  Loss = 'LOSS',
  Metric = 'METRIC',
  Transform = 'TRANSFORMS',
  Regimen = 'TRAIN',
  SSL = 'SSL',
}

export enum ParameterType {
  String = 'string',
  Model = 'model',
  Boolean = 'boolean',
  Integer = 'integer',
  Number = 'number',
  Array = 'array',
  Weights = 'weights',
  Metric = 'metric',
}

export enum SplitMode {
  Random = 'random',
  Stratification = 'stratify',
  Datasets = 'datasets',
}

export enum PartitionName {
  Test = 'test',
  Train = 'train',
  Validation = 'validation',
}

export enum SplitKind {
  Custom = 'custom',
}

export enum ExperimentRunErrorCode {
  Internal = 'INTERNAL_ERROR',
}
