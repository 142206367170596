// source: app/notifications/rpc/v1/service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var common_tracing_pb = require('../../../../common/tracing_pb.js');
goog.object.extend(proto, common_tracing_pb);
goog.exportSymbol('proto.app.notifications.rpc.v1.Notification', null, global);
goog.exportSymbol('proto.app.notifications.rpc.v1.NotificationOrder', null, global);
goog.exportSymbol('proto.app.notifications.rpc.v1.NotificationSubject', null, global);
goog.exportSymbol('proto.app.notifications.rpc.v1.NotificationsInbox', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.notifications.rpc.v1.NotificationOrder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, proto.app.notifications.rpc.v1.NotificationOrder.repeatedFields_, null);
};
goog.inherits(proto.app.notifications.rpc.v1.NotificationOrder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.notifications.rpc.v1.NotificationOrder.displayName = 'proto.app.notifications.rpc.v1.NotificationOrder';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.notifications.rpc.v1.NotificationsInbox = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.app.notifications.rpc.v1.NotificationsInbox.repeatedFields_, null);
};
goog.inherits(proto.app.notifications.rpc.v1.NotificationsInbox, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.notifications.rpc.v1.NotificationsInbox.displayName = 'proto.app.notifications.rpc.v1.NotificationsInbox';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.app.notifications.rpc.v1.Notification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.app.notifications.rpc.v1.Notification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.app.notifications.rpc.v1.Notification.displayName = 'proto.app.notifications.rpc.v1.Notification';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.notifications.rpc.v1.NotificationOrder.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.notifications.rpc.v1.NotificationOrder.prototype.toObject = function(opt_includeInstance) {
  return proto.app.notifications.rpc.v1.NotificationOrder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.notifications.rpc.v1.NotificationOrder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.notifications.rpc.v1.NotificationOrder.toObject = function(includeInstance, msg) {
  var f, obj = {
    recipientsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    notification: (f = msg.getNotification()) && proto.app.notifications.rpc.v1.Notification.toObject(includeInstance, f),
    trace: (f = msg.getTrace()) && common_tracing_pb.Trace.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.notifications.rpc.v1.NotificationOrder}
 */
proto.app.notifications.rpc.v1.NotificationOrder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.notifications.rpc.v1.NotificationOrder;
  return proto.app.notifications.rpc.v1.NotificationOrder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.notifications.rpc.v1.NotificationOrder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.notifications.rpc.v1.NotificationOrder}
 */
proto.app.notifications.rpc.v1.NotificationOrder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addRecipients(value);
      break;
    case 2:
      var value = new proto.app.notifications.rpc.v1.Notification;
      reader.readMessage(value,proto.app.notifications.rpc.v1.Notification.deserializeBinaryFromReader);
      msg.setNotification(value);
      break;
    case 9999:
      var value = new common_tracing_pb.Trace;
      reader.readMessage(value,common_tracing_pb.Trace.deserializeBinaryFromReader);
      msg.setTrace(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.notifications.rpc.v1.NotificationOrder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.notifications.rpc.v1.NotificationOrder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.notifications.rpc.v1.NotificationOrder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.notifications.rpc.v1.NotificationOrder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecipientsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getNotification();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.app.notifications.rpc.v1.Notification.serializeBinaryToWriter
    );
  }
  f = message.getTrace();
  if (f != null) {
    writer.writeMessage(
      9999,
      f,
      common_tracing_pb.Trace.serializeBinaryToWriter
    );
  }
};


/**
 * repeated string recipients = 1;
 * @return {!Array<string>}
 */
proto.app.notifications.rpc.v1.NotificationOrder.prototype.getRecipientsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.app.notifications.rpc.v1.NotificationOrder} returns this
 */
proto.app.notifications.rpc.v1.NotificationOrder.prototype.setRecipientsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.app.notifications.rpc.v1.NotificationOrder} returns this
 */
proto.app.notifications.rpc.v1.NotificationOrder.prototype.addRecipients = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.notifications.rpc.v1.NotificationOrder} returns this
 */
proto.app.notifications.rpc.v1.NotificationOrder.prototype.clearRecipientsList = function() {
  return this.setRecipientsList([]);
};


/**
 * optional Notification notification = 2;
 * @return {?proto.app.notifications.rpc.v1.Notification}
 */
proto.app.notifications.rpc.v1.NotificationOrder.prototype.getNotification = function() {
  return /** @type{?proto.app.notifications.rpc.v1.Notification} */ (
    jspb.Message.getWrapperField(this, proto.app.notifications.rpc.v1.Notification, 2));
};


/**
 * @param {?proto.app.notifications.rpc.v1.Notification|undefined} value
 * @return {!proto.app.notifications.rpc.v1.NotificationOrder} returns this
*/
proto.app.notifications.rpc.v1.NotificationOrder.prototype.setNotification = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.notifications.rpc.v1.NotificationOrder} returns this
 */
proto.app.notifications.rpc.v1.NotificationOrder.prototype.clearNotification = function() {
  return this.setNotification(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.notifications.rpc.v1.NotificationOrder.prototype.hasNotification = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional common.Trace trace = 9999;
 * @return {?proto.common.Trace}
 */
proto.app.notifications.rpc.v1.NotificationOrder.prototype.getTrace = function() {
  return /** @type{?proto.common.Trace} */ (
    jspb.Message.getWrapperField(this, common_tracing_pb.Trace, 9999));
};


/**
 * @param {?proto.common.Trace|undefined} value
 * @return {!proto.app.notifications.rpc.v1.NotificationOrder} returns this
*/
proto.app.notifications.rpc.v1.NotificationOrder.prototype.setTrace = function(value) {
  return jspb.Message.setWrapperField(this, 9999, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.notifications.rpc.v1.NotificationOrder} returns this
 */
proto.app.notifications.rpc.v1.NotificationOrder.prototype.clearTrace = function() {
  return this.setTrace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.notifications.rpc.v1.NotificationOrder.prototype.hasTrace = function() {
  return jspb.Message.getField(this, 9999) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.app.notifications.rpc.v1.NotificationsInbox.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.notifications.rpc.v1.NotificationsInbox.prototype.toObject = function(opt_includeInstance) {
  return proto.app.notifications.rpc.v1.NotificationsInbox.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.notifications.rpc.v1.NotificationsInbox} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.notifications.rpc.v1.NotificationsInbox.toObject = function(includeInstance, msg) {
  var f, obj = {
    notificationsList: jspb.Message.toObjectList(msg.getNotificationsList(),
    proto.app.notifications.rpc.v1.Notification.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.notifications.rpc.v1.NotificationsInbox}
 */
proto.app.notifications.rpc.v1.NotificationsInbox.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.notifications.rpc.v1.NotificationsInbox;
  return proto.app.notifications.rpc.v1.NotificationsInbox.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.notifications.rpc.v1.NotificationsInbox} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.notifications.rpc.v1.NotificationsInbox}
 */
proto.app.notifications.rpc.v1.NotificationsInbox.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.app.notifications.rpc.v1.Notification;
      reader.readMessage(value,proto.app.notifications.rpc.v1.Notification.deserializeBinaryFromReader);
      msg.addNotifications(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.notifications.rpc.v1.NotificationsInbox.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.notifications.rpc.v1.NotificationsInbox.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.notifications.rpc.v1.NotificationsInbox} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.notifications.rpc.v1.NotificationsInbox.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNotificationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.app.notifications.rpc.v1.Notification.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Notification notifications = 1;
 * @return {!Array<!proto.app.notifications.rpc.v1.Notification>}
 */
proto.app.notifications.rpc.v1.NotificationsInbox.prototype.getNotificationsList = function() {
  return /** @type{!Array<!proto.app.notifications.rpc.v1.Notification>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.app.notifications.rpc.v1.Notification, 1));
};


/**
 * @param {!Array<!proto.app.notifications.rpc.v1.Notification>} value
 * @return {!proto.app.notifications.rpc.v1.NotificationsInbox} returns this
*/
proto.app.notifications.rpc.v1.NotificationsInbox.prototype.setNotificationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.app.notifications.rpc.v1.Notification=} opt_value
 * @param {number=} opt_index
 * @return {!proto.app.notifications.rpc.v1.Notification}
 */
proto.app.notifications.rpc.v1.NotificationsInbox.prototype.addNotifications = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.app.notifications.rpc.v1.Notification, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.app.notifications.rpc.v1.NotificationsInbox} returns this
 */
proto.app.notifications.rpc.v1.NotificationsInbox.prototype.clearNotificationsList = function() {
  return this.setNotificationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.app.notifications.rpc.v1.Notification.prototype.toObject = function(opt_includeInstance) {
  return proto.app.notifications.rpc.v1.Notification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.app.notifications.rpc.v1.Notification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.notifications.rpc.v1.Notification.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subject: jspb.Message.getFieldWithDefault(msg, 2, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 3, 0),
    noticedAt: jspb.Message.getFieldWithDefault(msg, 4, 0),
    readAt: jspb.Message.getFieldWithDefault(msg, 5, 0),
    text: jspb.Message.getFieldWithDefault(msg, 20, ""),
    workspaceId: jspb.Message.getFieldWithDefault(msg, 21, ""),
    projectId: jspb.Message.getFieldWithDefault(msg, 22, ""),
    jobId: jspb.Message.getFieldWithDefault(msg, 23, ""),
    trace: (f = msg.getTrace()) && common_tracing_pb.Trace.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.app.notifications.rpc.v1.Notification}
 */
proto.app.notifications.rpc.v1.Notification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.app.notifications.rpc.v1.Notification;
  return proto.app.notifications.rpc.v1.Notification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.app.notifications.rpc.v1.Notification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.app.notifications.rpc.v1.Notification}
 */
proto.app.notifications.rpc.v1.Notification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.app.notifications.rpc.v1.NotificationSubject} */ (reader.readEnum());
      msg.setSubject(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNoticedAt(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReadAt(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkspaceId(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectId(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setJobId(value);
      break;
    case 9999:
      var value = new common_tracing_pb.Trace;
      reader.readMessage(value,common_tracing_pb.Trace.deserializeBinaryFromReader);
      msg.setTrace(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.app.notifications.rpc.v1.Notification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.app.notifications.rpc.v1.Notification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.app.notifications.rpc.v1.Notification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.app.notifications.rpc.v1.Notification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubject();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getNoticedAt();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getReadAt();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getWorkspaceId();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getProjectId();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getJobId();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getTrace();
  if (f != null) {
    writer.writeMessage(
      9999,
      f,
      common_tracing_pb.Trace.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.app.notifications.rpc.v1.Notification.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.notifications.rpc.v1.Notification} returns this
 */
proto.app.notifications.rpc.v1.Notification.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional NotificationSubject subject = 2;
 * @return {!proto.app.notifications.rpc.v1.NotificationSubject}
 */
proto.app.notifications.rpc.v1.Notification.prototype.getSubject = function() {
  return /** @type {!proto.app.notifications.rpc.v1.NotificationSubject} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.app.notifications.rpc.v1.NotificationSubject} value
 * @return {!proto.app.notifications.rpc.v1.Notification} returns this
 */
proto.app.notifications.rpc.v1.Notification.prototype.setSubject = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int64 created_at = 3;
 * @return {number}
 */
proto.app.notifications.rpc.v1.Notification.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.notifications.rpc.v1.Notification} returns this
 */
proto.app.notifications.rpc.v1.Notification.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 noticed_at = 4;
 * @return {number}
 */
proto.app.notifications.rpc.v1.Notification.prototype.getNoticedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.notifications.rpc.v1.Notification} returns this
 */
proto.app.notifications.rpc.v1.Notification.prototype.setNoticedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 read_at = 5;
 * @return {number}
 */
proto.app.notifications.rpc.v1.Notification.prototype.getReadAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.app.notifications.rpc.v1.Notification} returns this
 */
proto.app.notifications.rpc.v1.Notification.prototype.setReadAt = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string text = 20;
 * @return {string}
 */
proto.app.notifications.rpc.v1.Notification.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.notifications.rpc.v1.Notification} returns this
 */
proto.app.notifications.rpc.v1.Notification.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string workspace_id = 21;
 * @return {string}
 */
proto.app.notifications.rpc.v1.Notification.prototype.getWorkspaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.notifications.rpc.v1.Notification} returns this
 */
proto.app.notifications.rpc.v1.Notification.prototype.setWorkspaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string project_id = 22;
 * @return {string}
 */
proto.app.notifications.rpc.v1.Notification.prototype.getProjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.notifications.rpc.v1.Notification} returns this
 */
proto.app.notifications.rpc.v1.Notification.prototype.setProjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string job_id = 23;
 * @return {string}
 */
proto.app.notifications.rpc.v1.Notification.prototype.getJobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.app.notifications.rpc.v1.Notification} returns this
 */
proto.app.notifications.rpc.v1.Notification.prototype.setJobId = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional common.Trace trace = 9999;
 * @return {?proto.common.Trace}
 */
proto.app.notifications.rpc.v1.Notification.prototype.getTrace = function() {
  return /** @type{?proto.common.Trace} */ (
    jspb.Message.getWrapperField(this, common_tracing_pb.Trace, 9999));
};


/**
 * @param {?proto.common.Trace|undefined} value
 * @return {!proto.app.notifications.rpc.v1.Notification} returns this
*/
proto.app.notifications.rpc.v1.Notification.prototype.setTrace = function(value) {
  return jspb.Message.setWrapperField(this, 9999, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.app.notifications.rpc.v1.Notification} returns this
 */
proto.app.notifications.rpc.v1.Notification.prototype.clearTrace = function() {
  return this.setTrace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.app.notifications.rpc.v1.Notification.prototype.hasTrace = function() {
  return jspb.Message.getField(this, 9999) != null;
};


/**
 * @enum {number}
 */
proto.app.notifications.rpc.v1.NotificationSubject = {
  NOTIFICATION_SUBJECT_UNSPECIFIED: 0,
  NOTIFICATION_SUBJECT_WORKSPACE_PERMISSION_GRANTED: 10,
  NOTIFICATION_SUBJECT_WORKSPACE_PERMISSION_CHANGED: 11,
  NOTIFICATION_SUBJECT_WORKSPACE_PERMISSION_REVOKED: 12,
  NOTIFICATION_SUBJECT_PROJECT_EXPORTED: 100,
  NOTIFICATION_SUBJECT_PROJECT_EXPORT_FAILED: 101,
  NOTIFICATION_SUBJECT_PROJECT_EXPORT_STARTED: 102,
  NOTIFICATION_SUBJECT_PROJECT_EXPORT_QUEUED: 103,
  NOTIFICATION_SUBJECT_PROJECT_COPIED: 110,
  NOTIFICATION_SUBJECT_PROJECT_COPYING_FAILED: 111,
  NOTIFICATION_SUBJECT_PROJECT_COPYING_STARTED: 112,
  NOTIFICATION_SUBJECT_PROJECT_COPYING_QUEUED: 113,
  NOTIFICATION_SUBJECT_REPORT_CREATED: 120,
  NOTIFICATION_SUBJECT_REPORT_CREATION_FAILED: 121,
  NOTIFICATION_SUBJECT_REPORT_CREATION_STARTED: 122,
  NOTIFICATION_SUBJECT_REPORT_CREATION_QUEUED: 123,
  NOTIFICATION_SUBJECT_DATASPLIT_CREATED: 130,
  NOTIFICATION_SUBJECT_DATASPLIT_CREATION_FAILED: 131,
  NOTIFICATION_SUBJECT_DATASPLIT_PREPARING_FOLDS_STARTED: 132,
  NOTIFICATION_SUBJECT_MODEL_EXPORT_STARTED: 140,
  NOTIFICATION_SUBJECT_MODEL_EXPORTED: 141,
  NOTIFICATION_SUBJECT_MODEL_EXPORT_FAILED: 142,
  NOTIFICATION_SUBJECT_IMPORT_FILES_PARSED: 150,
  NOTIFICATION_SUBJECT_IMPORT_FILES_PARSING_FAILED: 151,
  NOTIFICATION_SUBJECT_IMPORT_COMPLETED: 160,
  NOTIFICATION_SUBJECT_IMPORT_FAILED: 161,
  NOTIFICATION_SUBJECT_FRAME_IMPORT_SUCCEEDED: 170,
  NOTIFICATION_SUBJECT_FRAME_IMPORT_FAILED: 171,
  NOTIFICATION_SUBJECT_VIDEO_CUT_SUCCEEDED: 180,
  NOTIFICATION_SUBJECT_VIDEO_CUT_FAILED: 181,
  NOTIFICATION_SUBJECT_VIDEO_PROCESSED: 190,
  NOTIFICATION_SUBJECT_VIDEO_PROCESSING_FAILED: 191,
  NOTIFICATION_SUBJECT_DATASET_STATUS_UPDATE_STARTED: 200,
  NOTIFICATION_SUBJECT_DATASET_STATUS_UPDATED: 201,
  NOTIFICATION_SUBJECT_DATASET_STATUS_UPDATE_FAILED: 202,
  NOTIFICATION_SUBJECT_INITIAL_MODEL_CREATED: 203,
  NOTIFICATION_SUBJECT_INITIAL_MODEL_CREATION_FAILED: 204
};

goog.object.extend(exports, proto.app.notifications.rpc.v1);
