import {
  createEntityAdapter,
  createSlice,
  EntityId,
  PayloadAction,
} from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { WidgetInferenceTime } from '../../../../../../../api/domainModels/widgets';
import { Experiment } from '../../../../../../../api/domainModels/modelPlayground';
import { loadingStateBuilder } from '../../../../../../utils/loadingState';
import { setEditedProjectId } from '../../../../../sections/editedProject/project/project.slice';

export const adapter = createEntityAdapter<WidgetInferenceTime>({
  selectId: (item) => item.experimentId,
  sortComparer: (a, b) => (dayjs(a.createdAt).isAfter(b.createdAt) ? -1 : 1),
});
const initialState = adapter.getInitialState({
  loadingState: loadingStateBuilder.initial(),
});

const { actions, reducer: inferenceTimeReducer } = createSlice({
  name: 'widgets/inferenceTime',
  initialState,
  reducers: {
    loadInferenceTime(state, _action: PayloadAction<Experiment['id'][]>) {
      state.loadingState = loadingStateBuilder.inProgress();
    },
    loadInferenceTimeFailure(state, action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.failure(action.payload);
      adapter.setAll(state, []);
    },
    loadInferenceTimeSuccess(
      state,
      action: PayloadAction<WidgetInferenceTime[]>,
    ) {
      state.loadingState = loadingStateBuilder.success();

      adapter.setAll(state, action.payload);
    },
    updateMetricsColor(
      state,
      action: PayloadAction<{ id: EntityId; color: string }>,
    ) {
      const { id, color } = action.payload;
      adapter.updateOne(state, { changes: { color }, id });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setEditedProjectId, () => initialState);
  },
});

export { inferenceTimeReducer };
export const {
  loadInferenceTime,
  loadInferenceTimeSuccess,
  loadInferenceTimeFailure,
  updateMetricsColor,
} = actions;
