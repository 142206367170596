import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type HoveredLabel = {
  id: string;
  classId: string;
  zIndex: number;
};

const { actions, reducer: hoveredLabelReducer } = createSlice({
  name: 'hoveredLabel',
  initialState: null as HoveredLabel | null,
  reducers: {
    setHoveredLabel(_state, action: PayloadAction<HoveredLabel>) {
      return action.payload;
    },
    resetHoveredLabel() {
      return null;
    },
  },
});

export const { setHoveredLabel, resetHoveredLabel } = actions;
export { hoveredLabelReducer };
