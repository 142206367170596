import { experimentsMetricsSaga } from './experimentsMetrics/experimentsMetrics.saga';
import { classificationInferenceSaga } from './classificationInferences/classificationInferences.saga';
import { experimentComparisonSaga } from './experimentComparison/experimentComparison.saga';
import { confusionMatrixSaga } from './confusionMatrix/confusionMatrix.saga';
import { runningTimeSaga } from './runningTime/runningTime.saga';
import { inferenceTimeSaga } from './inferenceTime/inferenceTime.saga';
import { metricsPerIterationSaga } from './metricsPerIteration/metricsPerIteration.saga';
import { bestPerformingSaga } from './bestPerforming/bestPerforming.saga';
import { multipleGpuConsumptionSaga } from './multipleGpuConsumption/multipleGpuConsumption.saga';
import { widgetsLiveUpdateSaga } from './widgetsLiveUpdate/widgetsLiveUpdate.saga';
import { widgetOptionsSaga } from './widgetsOptions/widgetOptions.saga';
import { taggerInferencesSaga } from './taggerInferences/taggerInferences.saga';
import { attributerInferencesSaga } from './attributerInferences/attributerInferences.saga';
import { detectorInferencesSaga } from './detectorInferences/detectorInferences.saga';
import { segmentorInferencesSaga } from './segmentorInferences/segmentorInferences.saga';
import { semanticInferencesSaga } from './semanticInferences/semanticInferences.saga';
import { trainParamsVisualizationSaga } from './trainParamsVisualization/trainParamsVisualization.saga';
import { explainabilitySaga } from './explainability/explainability.saga';

export const widgetsSagas = [
  experimentsMetricsSaga,
  classificationInferenceSaga,
  experimentComparisonSaga,
  confusionMatrixSaga,
  runningTimeSaga,
  inferenceTimeSaga,
  metricsPerIterationSaga,
  bestPerformingSaga,
  multipleGpuConsumptionSaga,
  widgetsLiveUpdateSaga,
  widgetOptionsSaga,
  taggerInferencesSaga,
  attributerInferencesSaga,
  detectorInferencesSaga,
  segmentorInferencesSaga,
  semanticInferencesSaga,
  trainParamsVisualizationSaga,
  explainabilitySaga,
];
