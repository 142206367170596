import { createSelector } from '@reduxjs/toolkit';

import { widgetsSelector } from '../widgets.selectors';
import { itemsAdapter } from './attributerInferences.slice';

const attributerInferencesSelector = createSelector(
  widgetsSelector,
  (widgets) => widgets.attributerInferences,
);

const itemsSelectors = itemsAdapter.getSelectors(attributerInferencesSelector);

export const attributerInferencesItemsSelector = itemsSelectors.selectAll;

export const attributerInferencesMetaSelector = createSelector(
  attributerInferencesSelector,
  ({ meta }) => meta,
);

export const attributerInferencesMetaTotalSelector = createSelector(
  attributerInferencesMetaSelector,
  (meta) => meta?.total || 0,
);

export const attributerInferencesLimitSelector = createSelector(
  attributerInferencesSelector,
  ({ limit }) => limit,
);

export const attributerInferencesOffsetSelector = createSelector(
  attributerInferencesSelector,
  ({ offset }) => offset,
);

export const attributerInferencesOpacitySelector = createSelector(
  attributerInferencesSelector,
  ({ opacity }) => opacity,
);
