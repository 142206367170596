import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { setEditedProjectId } from '../../../../sections/editedProject/project/project.slice';

const initialState = {
  id: null as string | null,
};

const { actions, reducer: activeExperimentIdReducer } = createSlice({
  name: 'activeExperimentId',
  initialState,
  reducers: {
    setActiveExperimentId(state, action: PayloadAction<{ id: string }>) {
      state.id = action.payload.id;
    },
    resetActiveExperimentId(state) {
      state.id = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setEditedProjectId, () => initialState);
  },
});

export const { setActiveExperimentId, resetActiveExperimentId } = actions;
export { activeExperimentIdReducer };
