import { createSelector } from '@reduxjs/toolkit';

import { imageViewSelector } from '../../imageView.selectors';

const labelSyncStateSelector = createSelector(
  imageViewSelector,
  (imageView) => imageView.labelSync,
);

export const labelSyncStageSelector = createSelector(
  labelSyncStateSelector,
  (syncState) => syncState.stage,
);

export const labelSyncLastSavedAtSelector = createSelector(
  labelSyncStateSelector,
  (syncState) => syncState.lastSavedAt,
);

export const labelSyncLastSavedLabelsAtSelector = createSelector(
  labelSyncStateSelector,
  (syncState) => syncState.lastSavedLabels,
);

export const labelSyncPreventsNavigationSelector = createSelector(
  labelSyncStageSelector,
  (stage) => stage === 'dirty' || stage === 'saving',
);

export const labelSyncSavingSelector = createSelector(
  labelSyncStageSelector,
  (stage) => stage === 'saving',
);

export const labelSyncFailedSelector = createSelector(
  labelSyncStageSelector,
  (stage) => stage === 'savingFailure',
);

export const labelSyncErrorMessageSelector = createSelector(
  labelSyncStateSelector,
  (state) => state.errorMessage,
);
