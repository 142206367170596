import { createSelector } from '@reduxjs/toolkit';

import { imageViewSelector } from '../imageView.selectors';

const clipboardStateSelector = createSelector(
  imageViewSelector,
  (imageView) => imageView.clipboard,
);

export const clipboardDataSelector = createSelector(
  clipboardStateSelector,
  (clipboard) => clipboard.data,
);

export const clipboardSourceImageIdSelector = createSelector(
  clipboardStateSelector,
  (clipboard) => clipboard.sourceImageId,
);
