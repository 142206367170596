/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Type of error
 */
export enum SemSegErrorTypeQuery {
  EXTRA_AREA = 'EXTRA_AREA',
  MISSING_AREA = 'MISSING_AREA',
}
