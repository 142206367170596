import { runsSaga } from './runs/runs.saga';
import { runsPaginationSaga } from './runs/pagination/pagination.saga';
import { runCreditsEstimationSaga } from './runs/credits/credits.saga';
import { runLockedResultsSaga } from './runs/lockedResults/lockedResults.saga';
import { runErrorsPerClassSaga } from './runs/summary/errorsPerClass.saga';
import { runHistogramSaga } from './runs/summary/histogram.saga';
import { runConfusionMatrixSaga } from './runs/summary/confusionMatrix.saga';
import { labelsSaga } from './labels/labels.saga';
import { attributeReviewSaga } from './labels/attributeReview.saga';
import { labelsFiltersSaga } from './labels/filters.saga';
import { labelConfidenceSaga } from './labels/confidence.saga';
import { tagReviewSaga } from './labels/tagReview.saga';
import { semanticReviewSaga } from './labels/semanticReview.saga';

export const errorFinderSagas = [
  runsSaga,
  runsPaginationSaga,
  runCreditsEstimationSaga,
  runLockedResultsSaga,
  runErrorsPerClassSaga,
  runHistogramSaga,
  runConfusionMatrixSaga,
  labelsSaga,
  tagReviewSaga,
  attributeReviewSaga,
  semanticReviewSaga,
  labelsFiltersSaga,
  labelConfidenceSaga,
];
