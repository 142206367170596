import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Bbox } from '../../../../../@types/imageView/types';

const initialState = {
  zoom: 1,
  viewport: {
    width: 0,
    height: 0,
  },
  fit: true,
  visibleAreaBbox: [0, 0, 0, 0] as Bbox,
};

const { actions, reducer: scaleReducer } = createSlice({
  name: 'imageViewScale',
  initialState,
  reducers: {
    // todo @RA check if this scale is correct with notices
    setScale(state, action: PayloadAction<number>) {
      state.fit = false;
      state.zoom = action.payload;
    },
    setViewportSize(
      state,
      action: PayloadAction<{ width: number; height: number }>,
    ) {
      state.viewport = action.payload;
      state.fit = false;
    },
  },
});

export const { setScale, setViewportSize } = actions;
export { scaleReducer };
