import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { WidgetAttributerInferencesItem } from '../../../../../../../api/domainModels/widgets';
import { ApiPaginationMeta } from '../../../../../../../api/helpers';
import { loadingStateBuilder } from '../../../../../../utils/loadingState';
import { setEditedProjectId } from '../../../../../sections/editedProject/project/project.slice';

type WidgetAttributerInference = WidgetAttributerInferencesItem & {
  id: string;
};

export const itemsAdapter = createEntityAdapter<WidgetAttributerInference>();

const initialState = itemsAdapter.getInitialState({
  loadingState: loadingStateBuilder.initial(),
  meta: null as ApiPaginationMeta | null,
  offset: 0,
  limit: 2,
  opacity: 0.5,
});

const { actions, reducer: attributerInferencesReducer } = createSlice({
  name: 'widgets/attributerInferences',
  initialState,
  reducers: {
    loadAttributerInferences(
      state,
      action: PayloadAction<{
        iterationId?: number;
        limit?: number;
        offset?: number;
      }>,
    ) {
      state.loadingState = loadingStateBuilder.inProgress();
      if (action.payload.offset) {
        state.offset = action.payload.offset;
      }
      if (action.payload.limit) {
        state.limit = action.payload.limit;
      }
    },
    loadAttributerInferencesFailure(state, action: PayloadAction<string>) {
      itemsAdapter.removeAll(state);
      state.loadingState = loadingStateBuilder.failure(action.payload);
    },
    loadAttributerInferencesSuccess(
      state,
      action: PayloadAction<{
        items: WidgetAttributerInference[];
        meta: ApiPaginationMeta;
      }>,
    ) {
      itemsAdapter.removeAll(state);
      state.loadingState = loadingStateBuilder.success();
      const { items, meta } = action.payload;
      itemsAdapter.setAll(state, items);
      state.meta = meta;
    },
    setAttributerInferenceOpacity(
      state,
      action: PayloadAction<{ opacity: number }>,
    ) {
      state.opacity = action.payload.opacity;
    },
    resetWidget() {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setEditedProjectId, () => initialState);
  },
});

export { attributerInferencesReducer };
export const {
  loadAttributerInferences,
  loadAttributerInferencesSuccess,
  loadAttributerInferencesFailure,
  setAttributerInferenceOpacity,
  resetWidget,
} = actions;
