import {
  put,
  select,
  takeEvery,
  call,
  all,
  SagaGenerator,
} from 'typed-redux-saga';

import { experimentComponentDataMapper } from '../../../../../../api/domainModels/modelPlayground';
import {
  apiGetExperimentComponents,
  apiUpdateExperimentComponent,
  apiGetComponentParameters,
  apiUpdateComponentParameter,
} from '../../../../../../api/requests/modelPlayground';
import { getErrorMessage } from '../../../../../../api/utils';
import { loadAll } from '../../../../../utils/api';
import { activeProjectIdSelector } from '../../../../project/project.selectors';
import {
  activeExperimentIdSelector,
  isActiveExperimentEditableSelector,
} from '../selectors';
import { parseParameterValue } from '../parameters.helpers';
import {
  experimentMetricsUpdateLoadingStateSelector,
  metricParameterByIdSelector,
} from './metrics.selectors';
import {
  loadMetrics,
  loadMetricsSuccess,
  loadMetricsFailure,
  updateExperimentMetric,
  updateExperimentMetricFailure,
  updateExperimentMetricSuccess,
  loadMetricParameters,
  loadMetricParametersSuccess,
  loadMetricParametersFailure,
  updateMetricParameter,
  updateMetricParameterSuccess,
  updateMetricParameterFailure,
} from './metrics.slice';
import { statusChecks } from '../../../../../../constants/status';
import { enqueueNotification } from '../../../../ui/stackNotifications/stackNotifications.slice';
import { Component } from '../../../../../../api/constants/modelPlayground';

function* loadMetricsHandler() {
  try {
    const projectId = yield* select(activeProjectIdSelector);
    const experimentId = yield* select(activeExperimentIdSelector);
    const items = yield* loadAll({
      apiHelper: apiGetExperimentComponents,
      params: {
        projectId,
        experimentId,
        componentType: Component.Metric,
      },
    });
    const isEditable = yield* select(isActiveExperimentEditableSelector);
    // as BE does not provide initially selected value for metric
    // we need to make sure it either exists (user selected it) or preselect it for them
    // we'll be working under assumption that metric should always be selected
    const hasMetricSelected = items.some((item) => item.selected);
    const updateMetricLoadingState = yield* select(
      experimentMetricsUpdateLoadingStateSelector,
    );
    const isMetricUpdating = statusChecks.isInProgress(
      updateMetricLoadingState.status,
    ); // handling an edge case of a component having multiple metrics parameters without having it set

    if (
      !isMetricUpdating &&
      isEditable &&
      items.length > 0 &&
      !hasMetricSelected
    ) {
      const frozenItemsIds = items
        .filter((item) => item.frozen)
        .map((item) => item.id);

      yield* put(updateExperimentMetric(frozenItemsIds));
    }
    const data = items.map(experimentComponentDataMapper.fromBackend);
    yield* put(loadMetricsSuccess(data));
    const parametersToFetchCalls: SagaGenerator<any>[] = [];
    data.forEach((metric) => {
      if (metric.selected) {
        const id = metric.experimentComponentId || metric.id;
        parametersToFetchCalls.push(put(loadMetricParameters(id)));
      }
    });
    yield* all(parametersToFetchCalls);
  } catch (e) {
    yield* put(
      loadMetricsFailure(getErrorMessage(e, 'Not able to load metrics')),
    );
  }
}

function* updateMetricHandler(
  action: ActionType<typeof updateExperimentMetric>,
) {
  try {
    const projectId = yield* select(activeProjectIdSelector);
    const experimentId = yield* select(activeExperimentIdSelector);

    if (!experimentId) {
      return;
    }

    const componentType: Component = Component.Metric;
    const params = { projectId, experimentId, componentType };
    const data = action.payload.map((metricId, index) => ({
      metricId,
      norder: index,
    }));
    const { data: response } = yield* call(() =>
      apiUpdateExperimentComponent(params, data),
    );
    yield* put(
      updateExperimentMetricSuccess(
        response.map(experimentComponentDataMapper.fromBackend),
      ),
    );
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to update metric');

    yield* put(updateExperimentMetricFailure(message));
    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
          refresh: false,
        },
      }),
    );
  }
}

function* loadParametersHandler(
  action: ActionType<typeof loadMetricParameters>,
) {
  const id = action.payload;
  try {
    const projectId = yield* select(activeProjectIdSelector);
    const experimentId = yield* select(activeExperimentIdSelector);
    const componentType: Component = Component.Metric;
    const items = yield* loadAll({
      apiHelper: apiGetComponentParameters,
      params: {
        projectId,
        experimentId,
        componentId: id,
        componentType,
      },
    });

    yield* put(loadMetricParametersSuccess({ id, data: items }));
  } catch (error) {
    yield* put(
      loadMetricParametersFailure({
        id,
        message: getErrorMessage(error, 'Not able to load parameters'),
      }),
    );
  }
}

function* updateParameterHandler(
  action: ActionType<typeof updateMetricParameter>,
) {
  const { id, metricParameterId, value } = action.payload;
  try {
    const projectId = yield* select(activeProjectIdSelector);
    const experimentId = yield* select(activeExperimentIdSelector);

    if (!experimentId) {
      return;
    }

    const componentType: Component = Component.Metric;
    const params = { projectId, experimentId, componentId: id, componentType };
    const data = { metricParameterId, value };
    const editedParameter = yield* select((state: RootState) =>
      metricParameterByIdSelector(state, id, metricParameterId),
    );
    if (editedParameter) {
      data.value = parseParameterValue(editedParameter, value);
    }
    const { data: response } = yield* call(
      apiUpdateComponentParameter,
      params,
      data,
    );

    yield* put(
      updateMetricParameterSuccess({
        parameter: response,
        id,
      }),
    );
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to update parameter');

    yield* put(updateMetricParameterFailure(message));
    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
          refresh: false,
        },
      }),
    );
  }
}

export function* metricsSaga() {
  yield* takeEvery(loadMetrics, loadMetricsHandler);
  yield* takeEvery(updateExperimentMetric, updateMetricHandler);
  yield* takeEvery(loadMetricParameters, loadParametersHandler);
  yield* takeEvery(updateMetricParameter, updateParameterHandler);
}
