import axios from 'axios';

import {
  ApiProject,
  ApiLoadProjectParams,
  ApiUpdateProjectParams,
  ApiArchiveProjectParams,
  ApiDuplicateProjectParams,
  ApiDuplicateProjectData,
  ApiMoveProjectToWorkspaceParams,
  ApiMoveProjectToWorkspaceData,
  ApiCreateProjectReportsParams,
  ApiCreateProjectReportsData,
  ApiDuplicateProjectJob,
} from '../schemas/project';
import { ErrorParams, apiWrapper, recoilApiWrapper } from '../utils';
import { API_URL, NEW_API_URL } from '../../constants/paths';
import { headers, transformRequest, transformResponse } from '../helpers';

export const apiLoadProjects = () =>
  apiWrapper(
    axios.request<ApiProject[]>({
      method: 'get',
      headers,
      transformRequest,
      transformResponse,
      url: `${API_URL}/projects`,
    }),
  );

export const apiLoadProject = (params: ApiLoadProjectParams) =>
  apiWrapper(
    axios.request<ApiProject>({
      method: 'get',
      headers,
      transformRequest,
      transformResponse,
      url: `${API_URL}/project/${params.projectId}`,
    }),
  );

type ApiLoadVideoProjectResponse = {
  id: string;
  name: string;
  workspaceId: string;
};

export const apiLoadProjectRecoil = (
  params: ApiLoadProjectParams,
  errorParams: ErrorParams,
) =>
  recoilApiWrapper(
    axios.request<ApiLoadVideoProjectResponse>({
      method: 'get',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}`,
    }),
    errorParams,
  );

export const apiCreateProject = (data: Partial<ApiProject>) =>
  apiWrapper(
    axios.request<ApiProject>({
      method: 'post',
      headers,
      transformRequest,
      transformResponse,
      url: `${API_URL}/project`,
      data,
    }),
  );

export const apiUpdateProject = (
  params: ApiUpdateProjectParams,
  data: Partial<ApiProject>,
) =>
  apiWrapper(
    axios.request<ApiProject>({
      method: 'put',
      headers,
      transformRequest,
      transformResponse,
      url: `${API_URL}/project/${params.projectId}`,
      data,
    }),
  );

export const apiArchiveProject = (params: ApiArchiveProjectParams) =>
  apiWrapper(
    axios.request<ApiProject>({
      method: 'delete',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}`,
    }),
  );

export const apiDuplicateProject = (
  params: ApiDuplicateProjectParams,
  data: ApiDuplicateProjectData,
) =>
  apiWrapper(
    axios.request<ApiDuplicateProjectJob>({
      method: 'post',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/duplicate`,
      data,
    }),
  );

export const apiMoveProjectToWorkspace = (
  params: ApiMoveProjectToWorkspaceParams,
  data: ApiMoveProjectToWorkspaceData,
) =>
  apiWrapper(
    axios.request<ApiProject>({
      method: 'put',
      headers,
      transformRequest,
      transformResponse,
      url: `${API_URL}/project/${params.projectId}/workspace`,
      data,
    }),
  );

export const apiCreateProjectReport = (
  params: ApiCreateProjectReportsParams,
  data: ApiCreateProjectReportsData,
) =>
  apiWrapper(
    axios.request<any>({
      method: 'post',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/reports`,
      data,
    }),
  );
