import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { ImageStatus } from '../../../../../api/domainModels/imageStatus';
import { Image } from '../../../../../api/domainModels/images';
import {
  LoadingState,
  loadingStateBuilder,
} from '../../../../utils/loadingState';

export const fileManagerImageAdapter = createEntityAdapter<Image>();
export const FILE_MANAGER_PER_PAGE = 100;

export type FileManagerImageFilters = {
  datasetId: string[];
  imageStatus: ImageStatus[];
  imageName: string;
  imageTags: string[];
  page: number;
  perPage: number;
};

const defaultFilters: FileManagerImageFilters = {
  datasetId: [],
  imageStatus: [],
  imageName: '',
  imageTags: [],
  page: 1,
  perPage: FILE_MANAGER_PER_PAGE,
};

export type FileManagerImagesState = {
  loadingState: LoadingState;
  actionLoadingState: LoadingState;
  editedImageId: string | null;
  filters: FileManagerImageFilters;
  allDatasetsTotalImages: number | null;
  selectedDatasetTotalImages: number | null;
  selectedIds: string[];
};

const { actions, reducer: imagesReducer } = createSlice({
  name: 'fileManager/images',
  initialState: fileManagerImageAdapter.getInitialState<FileManagerImagesState>(
    {
      loadingState: loadingStateBuilder.initial(),
      actionLoadingState: loadingStateBuilder.initial(),
      editedImageId: null,
      filters: defaultFilters,
      allDatasetsTotalImages: null,
      selectedDatasetTotalImages: null,
      selectedIds: [],
    },
  ),
  reducers: {
    loadImages(
      state,
      { payload = defaultFilters }: PayloadAction<FileManagerImageFilters>,
    ) {
      fileManagerImageAdapter.setAll(state, []);
      state.selectedIds = [];
      state.filters = payload;
      state.loadingState = loadingStateBuilder.inProgress();
    },
    loadImagesSuccess(
      state,
      action: PayloadAction<{
        items: Image[];
        total: number;
        allDatasetsSelected: boolean;
      }>,
    ) {
      const { items, total, allDatasetsSelected } = action.payload;

      state.loadingState = loadingStateBuilder.success();
      fileManagerImageAdapter.setAll(state, items);

      state.selectedDatasetTotalImages = total;

      if (allDatasetsSelected) {
        state.allDatasetsTotalImages = total;
      }
    },
    loadImagesFailure(state, { payload }: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.failure(payload);
    },
    setSelectedImageIds(state, { payload }: PayloadAction<string[]>) {
      state.selectedIds = payload;
    },
    deleteSelectedImages(state) {
      if (state.selectedIds.length) {
        state.actionLoadingState = loadingStateBuilder.inProgress();
      }
    },
    moveSelectedImages(state, _action: PayloadAction<{ datasetId: string }>) {
      if (state.selectedIds.length) {
        state.actionLoadingState = loadingStateBuilder.inProgress();
      }
    },
    changeStatusSelectedImages(
      state,
      action: PayloadAction<{
        status: ImageStatus;
        datasetId?: string;
        imageId?: string;
      }>,
    ) {
      if (state.selectedIds.length && !action.payload.datasetId) {
        state.actionLoadingState = loadingStateBuilder.inProgress();
      }
    },
    imageActionSuccess(state) {
      state.actionLoadingState = loadingStateBuilder.success();
      state.selectedIds = [];
    },
    setEditedImageId(state, { payload }: PayloadAction<string | null>) {
      state.editedImageId = payload;
    },
    updateImage(
      state,
      _action: PayloadAction<{ id: string; filename: string }>,
    ) {
      state.actionLoadingState = loadingStateBuilder.inProgress();
    },
    updateImageSuccess(state, action: PayloadAction<Image>) {
      state.actionLoadingState = loadingStateBuilder.success();
      fileManagerImageAdapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      });
    },
    clearImages(state) {
      fileManagerImageAdapter.removeAll(state);
    },
    updateImageFailure(state, action: PayloadAction<string>) {
      state.actionLoadingState = loadingStateBuilder.failure(action.payload);
    },
  },
});

export { imagesReducer };
export const {
  loadImages,
  loadImagesFailure,
  loadImagesSuccess,
  setSelectedImageIds,
  deleteSelectedImages,
  moveSelectedImages,
  changeStatusSelectedImages,
  imageActionSuccess,
  updateImage,
  updateImageSuccess,
  updateImageFailure,
  clearImages,
} = actions;
