import axios from 'axios';

import {
  ApiDeleteProjectKeypointsClassesParams,
  ApiLoadProjectKeypointsClassesParams,
  ApiCreateProjectKeypointsClassesParams,
  ApiKeypointsClassMapping,
} from '../schemas/keypointsClasses';
import { NEW_API_URL } from '../../constants/paths';
import { apiWrapper } from '../utils';
import { headers, transformRequest, transformResponse } from '../helpers';

export const apiDeleteProjectKeypointsClasses = (
  params: ApiDeleteProjectKeypointsClassesParams,
  data: Partial<ApiKeypointsClassMapping[]>,
) =>
  apiWrapper(
    axios.request<ApiKeypointsClassMapping[]>({
      method: 'delete',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/keypoint_schemas_classes`,
      data,
    }),
  );

export const apiLoadProjectKeypointsClasses = (
  params: ApiLoadProjectKeypointsClassesParams,
) =>
  apiWrapper(
    axios.request<ApiKeypointsClassMapping[]>({
      method: 'get',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/keypoint_schemas_classes`,
      params: {
        offset: params.offset,
        limit: params.limit,
      },
    }),
  );

export const apiCreateProjectKeypointsClasses = (
  params: ApiCreateProjectKeypointsClassesParams,
  data: Partial<ApiKeypointsClassMapping[]>,
) =>
  apiWrapper(
    axios.request<ApiKeypointsClassMapping[]>({
      method: 'put',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/keypoint_schemas_classes`,
      data,
    }),
  );
