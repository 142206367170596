import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { resetKeypoints } from '../keypoints.actions';

type KeypointsValuesState = {
  isSelecting: boolean;
  occlusionActive: boolean;
  hoveredPointId: string | null;
  selectedPointId: string | null;
  currentKeypointsClassIndex: number;
};
const initialState: KeypointsValuesState = {
  isSelecting: false,
  occlusionActive: false,
  hoveredPointId: null,
  selectedPointId: null,
  currentKeypointsClassIndex: 0,
};
const { actions, reducer: keypointsEditingStateReducer } = createSlice({
  name: 'keypointsEditingState',
  initialState,
  reducers: {
    setKeypointsClassIndex(state, action: PayloadAction<{ index: number }>) {
      state.currentKeypointsClassIndex = action.payload.index;
    },
    toggleKeypointsSelection(state) {
      state.isSelecting = !state.isSelecting;
      state.occlusionActive = false;
      state.selectedPointId = null;
    },
    toggleOcclusion(state) {
      state.occlusionActive = !state.occlusionActive;
    },
    disableOcclusion(state) {
      state.occlusionActive = false;
    },
    setHoveredKeypointId(state, action: PayloadAction<{ id: string }>) {
      state.hoveredPointId = action.payload.id;
    },
    resetHoveredKeypointId(state) {
      state.hoveredPointId = null;
    },
    setSelectedPoint(
      state,
      action: PayloadAction<{ id: string; classIndex: number }>,
    ) {
      state.selectedPointId = action.payload.id;
      state.currentKeypointsClassIndex =
        action.payload.classIndex === -1 ? 0 : action.payload.classIndex;
      state.isSelecting = true;
    },
    resetSelectedPointId(state) {
      state.selectedPointId = null;
    },
    skipActiveClass() {},
  },
  extraReducers: (builder) => {
    builder.addCase(resetKeypoints, () => initialState);
  },
});
export { keypointsEditingStateReducer };
export const {
  toggleKeypointsSelection,
  toggleOcclusion,
  setHoveredKeypointId,
  resetHoveredKeypointId,
  setSelectedPoint,
  resetSelectedPointId,
  skipActiveClass,
  disableOcclusion,
  setKeypointsClassIndex,
} = actions;
