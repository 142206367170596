import { createSelector } from '@reduxjs/toolkit';

import { statusChecks } from '../../../../../constants/status';
import {
  editedProjectIsProSelector,
  editedProjectSelector,
} from '../editedProject.selectors';
import { PROJECT_OWNER_ROLE_ID } from '../editedProject.constants';

export const editedProjectRolesSelector = createSelector(
  editedProjectSelector,
  (editedProject) => editedProject.roles,
);

export const editedProjectRolesAsOptionsSelector = createSelector(
  editedProjectRolesSelector,
  editedProjectIsProSelector,
  (roles, isPro: boolean) => {
    if (statusChecks.isSuccess(roles.loadingState.status)) {
      return roles.data.map(({ id, name }) => ({
        key: id,
        value: id,
        text: name,
        disabled: !isPro && id !== PROJECT_OWNER_ROLE_ID,
      }));
    }

    return null;
  },
);

export const editedProjectRolesDataSelector = createSelector(
  editedProjectRolesSelector,
  (roles) => roles.data,
);

export const editedProjectRolesUpdateLoadingStateSelector = createSelector(
  editedProjectRolesSelector,
  (roles) => roles.updateLoadingState,
);

export const editedProjectRolesPrivilegesSelector = createSelector(
  editedProjectRolesSelector,
  (roles) => roles.rolePrivileges,
);

export const editedProjectRolesActionLoadingStateSelector = createSelector(
  editedProjectRolesSelector,
  (roles) => roles.actionLoadingState,
);

export const editedProjectRolesPrivilegesDataSelector = createSelector(
  editedProjectRolesSelector,
  (roles) => roles.rolePrivileges.data,
);

export const editedProjectRolesLoadingStateSelector = createSelector(
  editedProjectRolesSelector,
  (roles) => roles.loadingState,
);

export const editedProjectRolesColorsSelector = createSelector(
  editedProjectRolesSelector,
  (roles) => (roles?.data || []).map(({ color }) => color),
);

export const editedProjectRolesNeedsSaveSelector = createSelector(
  editedProjectRolesSelector,
  (roles) => {
    const rolePrivilegeGroups = roles.rolePrivileges.data;

    return (
      rolePrivilegeGroups instanceof Object &&
      Object.values(rolePrivilegeGroups).some(({ privileges }) =>
        Object.values(privileges).some(({ values }) =>
          Object.values(values).some(
            ({ value, originalValue }) => value !== originalValue,
          ),
        ),
      )
    );
  },
);
