import { importSessionSaga } from './session/importSession.saga';
import { importSessionsSaga } from './sessions/importSessions.saga';
import { importFileSaga } from './files/importFiles.saga';
import { importUploadSaga } from './uploads/importUploads.saga';
import { importParseLogsSaga } from './parseLogs/importParseLogs.saga';
import { importLabelClassSaga } from './labelClass/importLabelClass.saga';

export const importsSagas = [
  importSessionSaga,
  importSessionsSaga,
  importFileSaga,
  importUploadSaga,
  importParseLogsSaga,
  importLabelClassSaga,
];
