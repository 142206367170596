import {
  convertBackendObject,
  convertToBackendObject,
} from '../redux/utils/backendParams';

export const transformResponse = (data: string) =>
  data ? convertBackendObject(JSON.parse(data)) : data;

export const transformRequest = [
  (data: any) => JSON.stringify(convertToBackendObject(data)),
];

export const headers = {
  'Content-Type': 'application/json;charset=UTF-8',
};

export type ApiDeleteResponse = {
  deleted: boolean;
  id: string;
  object: string;
};

export type ApiPaginationMeta = {
  limit: number;
  object: string;
  offset: number;
  searchParameters: {
    [key: string]: any;
  };
  total: number;
};

export type ApiKeysetPaginationDirection = 'NEXT' | 'PREV';

export type ApiKeysetPaginationMeta = {
  cursorNext: string;
  cursorPrev: string;
  limit: number;
  object: string;
  page: ApiKeysetPaginationDirection;
};

export type ApiPaginated<T> = {
  meta: ApiPaginationMeta;
  items: T;
};

export type ApiKeysetPaginated<T> = {
  meta: ApiKeysetPaginationMeta;
  items: T[];
};

export type ApiKeysetParams<T> = {
  page?: ApiKeysetPaginationDirection;
  cursor?: string;
  limit?: number;
  searchParameters?: {
    [key: string]: any;
  };
} & T;
