import { createSelector } from '@reduxjs/toolkit';

import { relationsAdapter } from './ACRelations.slice';

const relationsStateSelector = (state: RootState) =>
  state.project.annotationTaxonomy.ACRelations;

export const ACRelationsLoadingStateSelector = createSelector(
  relationsStateSelector,
  (state) => state.loadingState,
);

const selectors = relationsAdapter.getSelectors(relationsStateSelector);
const relationsSelector = selectors.selectAll;

export const ACRelationsMapSelector = selectors.selectEntities;
export const ACRelationsForLabelClassSelector = createSelector(
  relationsSelector,
  (_: RootState, labelClassId: string) => labelClassId,
  (relations, labelClassId) =>
    relations
      .filter((r) => r.classId === labelClassId)
      .sort((a, b) => (a.classOrder < b.classOrder ? -1 : 1)),
);
export const ACRelationsForAttributeSelector = createSelector(
  relationsSelector,
  (_: RootState, attributeId: string) => attributeId,
  (relations, attributeId) =>
    relations.filter((r) => r.attributeId === attributeId),
);
