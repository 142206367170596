import { generateFullHex } from '../../helpers/colors';
import { ApiLabelClass } from '../schemas/labelClass';

export enum LabelClassType {
  Background = 'background',
  Object = 'object',
}

export const labelClassTypes = [
  LabelClassType.Object,
  LabelClassType.Background,
] as const;

export const labelClassTypesLabel = {
  [LabelClassType.Object]: 'Object',
  [LabelClassType.Background]: 'Semantic',
};

const fromBackend = ({ color, ...entry }: ApiLabelClass) => ({
  ...entry,
  color: generateFullHex(color) as string,
});

export type LabelClass = ReturnType<typeof fromBackend>;

export const labelClassDataMapper = {
  fromBackend,
  toBackend(entry: Partial<LabelClass>): Partial<ApiLabelClass> {
    return {
      ...entry,
    };
  },
};
