import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SplitStrategy } from '../../../../../../api/domainModels/modelPlayground';
import { loadingStateBuilder } from '../../../../../utils/loadingState';
import { setEditedProjectId } from '../../../../sections/editedProject/project/project.slice';

const initialState = {
  loadingState: loadingStateBuilder.initial(),
  items: [] as SplitStrategy[],
};
const { actions, reducer: strategiesReducer } = createSlice({
  name: 'strategies',
  initialState,
  reducers: {
    loadSplitStrategiesStart(state) {
      state.loadingState = loadingStateBuilder.inProgress();
      state.items = [];
    },
    loadSplitStrategiesFailure(state, action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.failure(action.payload);
    },
    loadSplitStrategiesSuccess(state, action: PayloadAction<SplitStrategy[]>) {
      state.loadingState = loadingStateBuilder.success();
      state.items = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setEditedProjectId, () => initialState);
  },
});

export { strategiesReducer };
export const {
  loadSplitStrategiesStart,
  loadSplitStrategiesFailure,
  loadSplitStrategiesSuccess,
} = actions;
