import axios from 'axios';

import {
  ApiVideo,
  ApiLoadVideosResponse,
  ApiLoadVideosParams,
  ApiUpdateVideoStatusParams,
} from '../schemas/videos';
import { ErrorParams, apiWrapper, recoilApiWrapper } from '../utils';
import { NEW_API_URL } from '../../constants/paths';
import { headers, transformRequest, transformResponse } from '../helpers';
import { convertToBackendParams } from '../../redux/utils/backendParams';
import { VideoStatus } from '../constants/videos';

export const apiLoadVideos = ({ projectId, params }: ApiLoadVideosParams) =>
  apiWrapper(
    axios.request<ApiLoadVideosResponse>({
      method: 'get',
      url: `${NEW_API_URL}/projects/${projectId}/videos`,
      headers,
      transformRequest,
      transformResponse,
      params: convertToBackendParams(params || {}),
    }),
  );

export const apiLoadVideosRecoil = (
  requestParams: {
    params: ApiLoadVideosParams;
  },
  errorParams: ErrorParams,
) => {
  const {
    params: { projectId, params, pagination },
  } = requestParams;

  return recoilApiWrapper(
    axios.request<ApiLoadVideosResponse>({
      method: 'get',
      url: `${NEW_API_URL}/projects/${projectId}/videos`,
      headers,
      transformRequest,
      transformResponse,
      params: convertToBackendParams({ ...params, ...pagination }),
    }),
    errorParams,
  );
};

export const apiDeleteProjectVideo = (params: {
  projectId: string;
  id: string; // videoId
}) =>
  apiWrapper(
    axios.request<void>({
      method: 'delete',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/videos/${params.id}`,
      data: {}, // empty body due to backend peculiarities
    }),
  );

export const apiPatchProjectVideo = (
  params: {
    projectId: string;
    id: string; // videoId
  },
  data: Partial<{ datasetId: string; filename: string }>,
) =>
  apiWrapper(
    axios.request<ApiVideo>({
      method: 'patch',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/videos/${params.id}`,
      data,
    }),
  );

export const apiUpdateVideoStatus = (
  params: {
    projectId: string;
    id: string; // videoId
  },
  data: { status: VideoStatus },
) =>
  apiWrapper(
    axios.request<void>({
      method: 'put',
      url: `${NEW_API_URL}/projects/${params.projectId}/videos/${params.id}/status`,
      headers,
      transformRequest,
      transformResponse,
      data,
    }),
  );

export const apiUpdateVideoStatusRecoil = (
  { params, data }: ApiUpdateVideoStatusParams,
  errorParams: ErrorParams,
) =>
  recoilApiWrapper(
    axios.request<void>({
      method: 'put',
      url: `${NEW_API_URL}/projects/${params.projectId}/videos/${params.id}/status`,
      headers,
      transformRequest,
      transformResponse,
      data,
    }),
    errorParams,
  );
