import {
  DefaultValue,
  atom,
  atomFamily,
  selector,
  selectorFamily,
} from 'recoil';

import { createRecoilEntityAdapterFamily } from '../../helpers/recoil/entityAdapter';
import { VideoFilters, Video } from '../../api/domainModels/video';
import { apiLoadVideosRecoil } from '../../api/requests/videos';
import { nullableProjectIdState } from './project';
import { NEW_API_URL } from '../../constants/paths';
import { VideoAvailability } from '../../api/constants/videos';
import { loadAll } from '../helpers';
import { INITIAL_VIDEO_FILTERS } from '../../containers/videoView/constants';

export const nullableVideoIdState = atom<string | null>({
  key: 'video/nullableVideoId',
  default: null,
});

export const videoAvailabilityState = atomFamily<
  VideoAvailability,
  string | null
>({
  key: 'video/availability',
  default: (_id) => VideoAvailability.Unset,
});

export const videoFiltersState = atom({
  key: 'video/filters',
  default: INITIAL_VIDEO_FILTERS,
});

export const videoFilterVideoNameState = selector<string | undefined>({
  key: 'video/filters/videoName',
  get: ({ get }) => get(videoFiltersState).videoName,
  set: ({ get, set }, value) => {
    set(videoFiltersState, {
      ...get(videoFiltersState),
      videoName: value,
    } as VideoFilters);
  },
});

export const videoFilterDatasetIdState = selector<string | undefined>({
  key: 'video/filters/datasetId',
  get: ({ get }) => get(videoFiltersState).datasetId,
  set: ({ get, set }, value) => {
    set(videoFiltersState, {
      ...get(videoFiltersState),
      datasetId: value,
    } as VideoFilters);

    set(nullableVideoIdState, null);
  },
});

export const videoFilterActivityState = selector<string | undefined>({
  key: 'video/filters/activity',
  get: ({ get }) => {
    const activities = get(videoFiltersState).activity;

    // currently the Activity filter is not multiselect
    // But API expects array of activities
    return activities ? activities[0] : undefined;
  },
  set: ({ get, set }, value) => {
    set(videoFiltersState, {
      ...get(videoFiltersState),
      activity: value ? [value] : undefined,
    } as VideoFilters);

    set(nullableVideoIdState, null);
  },
});

export const videoFilterVideoStatusState = selector<string | undefined>({
  key: 'video/filters/videoStatus',
  get: ({ get }) => {
    const statuses = get(videoFiltersState).videoStatus;

    // currently the Status filter is not multiselect
    // But API expects array of statuses
    return statuses ? statuses[0] : undefined;
  },
  set: ({ get, set }, value) => {
    set(videoFiltersState, {
      ...get(videoFiltersState),
      videoStatus: value ? [value] : undefined,
    } as VideoFilters);

    set(nullableVideoIdState, null);
  },
});

export const videosParamsState = selector({
  key: 'video/params',
  get: async ({ get }) => {
    const projectId = get(nullableProjectIdState);
    const filters = get(videoFiltersState);

    return { projectId, ...filters };
  },
});

export const videosCoil = createRecoilEntityAdapterFamily<
  Video,
  VideoFilters & { projectId: string | null }
>({
  name: 'video/videos',
  paramState: videosParamsState,
  initialState: async ({ projectId, ...params }) => {
    if (!projectId) return [];

    try {
      const data = await loadAll({
        apiHelper: apiLoadVideosRecoil,
        requestParams: { projectId, params },
        errorParams: { errorMessage: "Couldn't load videos" },
      });

      return data;
    } catch {
      return [];
    }
  },
});

export const totalVideosState = selector({
  key: 'video/videos/total',
  get: ({ get }) => get(videosCoil.selectTotal),
});

export const currentVideoPlaylistUrlState = selector({
  key: 'video/current/playlistUrl',
  get: ({ get }) => {
    const projectId = get(nullableProjectIdState);
    const videoId = get(nullableVideoIdState);

    return `${NEW_API_URL}/projects/${projectId}/videos/${videoId}/playlist`;
  },
});

export const currentVideoState = selector({
  key: 'video/current/item',
  get: ({ get }) => {
    const videoId = get(nullableVideoIdState);

    if (!videoId) return null;

    return get(videosCoil.selectByIdOrReturnDefault([videoId, {}]));
  },
});

export const currentVideoStatusState = selector({
  key: 'video/current/itemStatus',
  get: ({ get }) => {
    const video = get(currentVideoState);

    return video?.status || null;
  },
});

export const currentVideoIndexState = selector({
  key: 'video/current/index',
  get: ({ get }) => {
    const videoId = get(nullableVideoIdState);
    const videoIds = get(videosCoil.selectIds);

    if (!videoId) return null;

    return videoIds.indexOf(videoId);
  },
});

export const previousVideoState = selector({
  key: 'video/current/previous',
  get: ({ get }) => {
    const videoId = get(nullableVideoIdState);
    const videoIds = get(videosCoil.selectIds);

    if (!videoId) return null;

    const currentIndex = videoIds.indexOf(videoId);
    if (currentIndex <= 0) {
      return null;
    }

    return get(videosCoil.selectByIdOrThrow(videoIds[currentIndex - 1]));
  },
});

export const nextVideoState = selector({
  key: 'video/current/next',
  get: ({ get }) => {
    const videoId = get(nullableVideoIdState);
    const videoIds = get(videosCoil.selectIds);

    if (!videoId) return null;

    const currentIndex = videoIds.indexOf(videoId);
    if (currentIndex === -1 || currentIndex === videoIds.length - 1) {
      return null;
    }

    return get(videosCoil.selectByIdOrThrow(videoIds[currentIndex + 1]));
  },
});

const videosLimitsState = atomFamily<[number, number], string | null>({
  key: 'video/list/limits',
  default: selectorFamily({
    key: 'video/list/limits/default',
    get:
      (id: string | null) =>
      ({ get }) => {
        if (!id) return [0, 0];

        const video = get(videosCoil.selectById(id));

        if (!video) {
          return [0, 0];
        }

        return [0, video.durationMs];
      },
  }),
});

export const currentVideoLimitsState = selector({
  key: 'video/current/item/limits',
  get: ({ get }) => {
    const videoId = get(nullableVideoIdState);

    return get(videosLimitsState(videoId));
  },
  set: ({ get, set }, value: [number, number] | DefaultValue) => {
    const videoId = get(nullableVideoIdState);

    set(videosLimitsState(videoId), value);
  },
});

const videosCurrentTimeState = atomFamily<number, string | null>({
  key: 'video/list/currentTime',
  default: (_id) => 0,
});

export const currentVideoCurrentTimeState = selector({
  key: 'video/current/item/currentTime',
  get: ({ get }) => {
    const videoId = get(nullableVideoIdState);

    return get(videosCurrentTimeState(videoId));
  },
  set: ({ get, set }, value: number | DefaultValue) => {
    const videoId = get(nullableVideoIdState);

    set(videosCurrentTimeState(videoId), value);
  },
});

type PlayState = { isPlaying: boolean };

const videosCurrentPlayState = atomFamily<PlayState, string | null>({
  key: 'video/list/play',
  default: (_id) => ({ isPlaying: false }),
});

export const currentVideoPlayState = selector<PlayState>({
  key: 'video/current/item/play',
  get: ({ get }) => {
    const videoId = get(nullableVideoIdState);

    return get(videosCurrentPlayState(videoId));
  },
  set: ({ get, set }, value) => {
    const videoId = get(nullableVideoIdState);

    set(videosCurrentPlayState(videoId), value);
  },
});
