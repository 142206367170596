import { ApiTagClass } from '../schemas/tagClass';
import { DEFAULT_GROUP_ID, DEFAULT_GROUP_NAME } from './tagGroup';

const fromBackend = ({ tagGroupId, tagGroupName, ...entry }: ApiTagClass) => ({
  ...entry,
  groupId: tagGroupId || DEFAULT_GROUP_ID,
  groupName: tagGroupName || DEFAULT_GROUP_NAME,
});

const toBackend = ({ groupId, groupName, ...entry }: Partial<TagClass>) => ({
  ...entry,
  tagGroupId: groupId !== DEFAULT_GROUP_ID ? groupId : null,
});

export type TagClass = ReturnType<typeof fromBackend>;

export const tagClassDataMapper = {
  fromBackend,
  toBackend,
};
