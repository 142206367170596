import { createSelector } from '@reduxjs/toolkit';

import { inferenceItemAdapter } from './inferences.slice';
import { projectSelector } from '../../project.selectors';

const baseStateSelector = createSelector(
  projectSelector,
  (state) => state.inferenceMonitoring.inferences,
);

const selectors = inferenceItemAdapter.getSelectors(baseStateSelector);

export const inferenceItemsSelector = selectors.selectAll;
export const inferenceItemsTotalOnPageSelector = selectors.selectTotal;

export const inferenceItemsLoadingStateSelector = createSelector(
  baseStateSelector,
  (state) => state.loadingState,
);

export const inferenceItemsActionLoadingStateSelector = createSelector(
  baseStateSelector,
  (state) => state.actionLoadingState,
);

export const inferenceItemsFiltersSelector = createSelector(
  baseStateSelector,
  (state) => state.filters,
);
export const inferenceItemsTotalFoundSelector = createSelector(
  baseStateSelector,
  (state) => state.total,
);
export const inferenceItemsSelectedIdsSelector = createSelector(
  baseStateSelector,
  (state) => state.selectedIds,
);
