import { combineReducers } from 'redux';

import { attributesReducer } from './attributes/attributes.slice';
import { labelClassesReducer } from './labelClasses/labelClasses.slice';
import { ACRelationsReducer } from './ACRelations/ACRelations.slice';
import { KCRelationsReducer } from './KCRelations/KCRelations.slice';
import { tagGroupsReducer } from './tagGroups/tagGroups.slice';
import { tagClassesReducer } from './tagClasses/tagClasses.slice';
import { keypointsSchemasReducer } from './keypointsSchemas/keypointsSchemas.slice';
import { assistantKeypointSchemaIdReducer } from './assistantKeypointSchemaId/assistantKeypointSchemaId.slice';
import { keypointsTemplatesReducer } from './keypointsTemplates/keypointsTemplates.slice';

export const annotationTaxonomyReducer = combineReducers({
  labelClasses: labelClassesReducer,
  attributes: attributesReducer,
  ACRelations: ACRelationsReducer,
  KCRelations: KCRelationsReducer,
  tagGroups: tagGroupsReducer,
  tagClasses: tagClassesReducer,
  keypointsSchemas: keypointsSchemasReducer,
  keypointsTemplates: keypointsTemplatesReducer,
  assistantKeypointSchemaId: assistantKeypointSchemaIdReducer,
});
