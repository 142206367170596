/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ImageStatus } from './ImageStatus';

export type EFRun = {
  /**
   * Error Finder Run identifier
   */
  readonly id?: string;
  /**
   * Run name
   */
  name: string;
  /**
   * Error message
   */
  errorMessage?: string;
  /**
   * Run status
   */
  status?: EFRun.status;
  /**
   * Label class IDs for label classes to use in run
   */
  labelClassIds?: Array<string> | null;
  /**
   * User identifier that created the run
   */
  createdBy?: string;
  /**
   * User that created the run
   */
  creatorName?: string | null;
  /**
   * Run start date time
   */
  readonly createdOn?: string;
  /**
   * Run end date time
   */
  readonly endedOn?: string | null;
  /**
   * Run type
   */
  type?: EFRun.type;
  /**
   * Number of images checked
   */
  imagesChecked?: number;
  /**
   * Number of labels checked
   */
  labelsChecked?: number;
  /**
   * Number of errors found
   */
  errorsFound?: number | null;
  /**
   * Number of accepted suggestions
   */
  accepted?: number | null;
  /**
   * Number of rejected suggestions
   */
  rejected?: number | null;
  /**
   * Number of not reviewed suggestions
   */
  notReviewed?: number | null;
  /**
   * Percentage of accepted suggestions among reviewed
   */
  acceptanceRate?: number | null;
  /**
   * Percentage of errors
   */
  errorRate?: number | null;
  /**
   * Percentage of true errors among reviewed
   */
  trueErrorRate?: number | null;
  /**
   * list of dataset identifiers that should be reviewed
   */
  datasetsIds?: Array<string> | null;
  /**
   * list of image statuses that should be reviewed
   */
  imageStatus?: Array<ImageStatus> | null;
  /**
   * True if models need to be retrained
   */
  needsRetrain?: boolean | null;
  /**
   * The id of a template to use for models
   */
  templateId?: string | null;
  /**
   * The name of a template to use for models
   */
  templateName?: string | null;
  /**
   * Percentage of the labels used for preview
   */
  previewPcnt?: number | null;
  /**
   * Max IOU value for which results will be stored
   */
  maxIou?: number | null;
  /**
   * The size of images chunk to process at a time
   */
  chunkSize?: number | null;
  /**
   * Select a start date and time to retrieve data starting from that point
   */
  startDatetime?: string | null;
  /**
   * Select an end date and time to retrieve data until that point
   */
  endDatetime?: string | null;
  /**
   * Number of missing labels found (for INSTANCE_REVIEW and OBJECT_REVIEW runs only)
   */
  missingLabels?: number | null;
  /**
   * Number of extra labels found (for INSTANCE_REVIEW and OBJECT_REVIEW runs only)
   */
  extraLabels?: number | null;
  /**
   * Number of low iou labels found (for INSTANCE_REVIEW and OBJECT_REVIEW runs only)
   */
  lowIouLabels?: number | null;
};

export namespace EFRun {

  /**
   * Run status
   */
  export enum status {
    INIT = 'INIT',
    PREDICTING = 'PREDICTING',
    FAILED = 'FAILED',
    DONE = 'DONE',
    TRAINING = 'TRAINING',
    PREPARING_FOLDS = 'PREPARING_FOLDS',
  }

  /**
   * Run type
   */
  export enum type {
    CLASS_REVIEW = 'CLASS_REVIEW',
    INSTANCE_REVIEW = 'INSTANCE_REVIEW',
    OBJECT_REVIEW = 'OBJECT_REVIEW',
    TAG_REVIEW = 'TAG_REVIEW',
    ATTRIBUTE_REVIEW = 'ATTRIBUTE_REVIEW',
    SEMANTIC_REVIEW = 'SEMANTIC_REVIEW',
  }


}

