import { ApiLabel } from '../schemas/imageLabels';
import { getLabelType, getBbox } from './utils';

export type Label = ApiLabel;

const fromBackendLabel = ({
  updatedOn,
  createdOn,
  polygon,
  mask,
  bbox,
  borderMask,
  keypoints,
  ...entry
}: ApiLabel) => ({
  updatedOn: (updatedOn || createdOn) as string,
  createdOn,
  polygon,
  mask,
  keypoints: keypoints?.map(
    ({
      createDate,
      createdBy,
      updatedBy,
      updateDate,
      imageId,
      labelId,
      norder,
      ...rest
    }) => rest,
  ),
  borderData: borderMask,
  type: getLabelType({ polygon, mask, keypoints }),
  ...entry,
  bbox: getBbox(bbox, mask, polygon, keypoints),
});

export type ImageLabel = ReturnType<typeof fromBackendLabel>;
export type KeypointsPoint = NonNullable<
  ReturnType<typeof fromBackendLabel>['keypoints']
>[number];
export type EnrichedImageLabel = ImageLabel & {
  imageData?: ImageData;
  imageDataId?: number;
};

export const imageLabelDataMapper = {
  fromBackend: fromBackendLabel,
};
