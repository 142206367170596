import { replace } from 'connected-react-router';
import { all, put, takeEvery } from 'typed-redux-saga';

import { loadPackageFeatures } from '../../commonFeatures/packageFeatures/packageFeatures.slice';
import { apiKeysSaga } from './apiAccounts/apiKeys.saga';
import { serviceAccountsSaga } from './apiAccounts/serviceAccounts.saga';
import { basicsSaga } from './basics/basics.saga';
import { managedWorkspaceCollaboratorsSaga } from './collaborators/collaborators.saga';
import { invoicesSaga } from './invoices/invoices.sagas';
import { managedWorkspacePaymentSaga } from './payments/payments.saga';
import { privilegesSaga } from './roles/rolePrivileges.sagas';
import { rolesSaga } from './roles/roles.sagas';
import { loadWorkspaces } from '../projectList/workspaces/workspaces.slice';
import { externalBucketsSaga } from './externalBuckets/externalBuckets.saga';
import { loadWorkspaceCredits } from './credits/credits.slice';
import { creditsSaga } from './credits/credits.saga';
import { stripeSaga } from './stripe/stripe.saga';
import { setManagedWorkspaceId } from '../../commonFeatures/workspaceId/workspaceId.slice';

function* setManagedWorkspaceIdHandler({
  payload,
}: ActionType<typeof setManagedWorkspaceId>) {
  // special cases
  if (!payload.workspaceId) {
    // means invalid workspace id
    // TODO redirect to default workspace
    return yield* put(replace('/workspaces'));
  }
  yield* all([
    put(loadWorkspaceCredits(payload.workspaceId)),
    put(loadWorkspaces()),
    put(loadPackageFeatures()),
  ]);
}

function* managedWorkspaceSaga() {
  yield* takeEvery(setManagedWorkspaceId, setManagedWorkspaceIdHandler);
}

export const managedWorkspaceSagas = [
  invoicesSaga,
  privilegesSaga,
  rolesSaga,
  managedWorkspaceSaga,
  basicsSaga,
  creditsSaga,
  managedWorkspaceCollaboratorsSaga,
  managedWorkspacePaymentSaga,
  serviceAccountsSaga,
  apiKeysSaga,
  externalBucketsSaga,
  stripeSaga,
];
