import { call, put, select, takeEvery } from 'typed-redux-saga';

import {
  changeSemanticReviewErrorAction,
  changeSemanticReviewErrorActionSuccess,
  setMaxNumberOfErrors,
  setPredictedClassId,
} from './semanticReview.slice';
import { activeProjectIdSelector } from '../../../project/project.selectors';
import { imageViewImageGalleryFiltersCSRunIdSelector } from '../../imageView/imageGallery/bottomBar/filters/filters.selectors';
import { apiChangeSemanticErrorResultAction } from '../../../../../api/requests/consensusScoring';
import { getErrorMessage } from '../../../../../api/utils';
import { handleError } from '../../../commonFeatures/errorHandler/errorHandler.actions';
import { loadErrorFinderLabels } from './labels.slice';
import { consensusScoringRunTypeSelector } from '../../imageView/consensusScoring/consensusScoring.selectors';
import { imageViewImageIdSelector } from '../../imageView/currentImage/currentImage.selectors';
import {
  semanticReviewMaxNumberOfErrorsSelector,
  semanticReviewPredictedClassIdSelector,
} from './semanticReview.selectors';
import { imageLabelDataMapper } from '../../../../../api/domainModels/imageLabel';
import { upsertLabel } from '../../imageView/labels/labels.slice';

function* changeTagReviewErrorActionHandler(
  action: ActionType<typeof changeSemanticReviewErrorAction>,
) {
  const { runId: aicsRunId, ...payload } = action.payload;
  const { resultId, efAction } = payload;

  const projectId = yield* select(activeProjectIdSelector);

  // if not forwarded from AICS page then maybe from AE filters
  const runId =
    aicsRunId || (yield* select(imageViewImageGalleryFiltersCSRunIdSelector));

  if (!projectId || !runId) return;

  try {
    const {
      data: { label: labelRaw },
    } = yield* call(
      apiChangeSemanticErrorResultAction,
      { projectId, runId, resultId },
      { efAction },
    );

    if (labelRaw) {
      const label = imageLabelDataMapper.fromBackend(labelRaw);

      yield* put(upsertLabel(label));
    }

    yield* put(
      changeSemanticReviewErrorActionSuccess({
        id: resultId,
        changes: { efAction },
      }),
    );
  } catch (error) {
    const message = getErrorMessage(
      error,
      'Not able to update semantic results',
    );

    yield* put(handleError({ error, message }));
  }
}

function* handlePredictedClassIdChange(
  _action: ActionType<typeof setPredictedClassId | typeof setMaxNumberOfErrors>,
) {
  const runId = yield* select(imageViewImageGalleryFiltersCSRunIdSelector);
  const runType = yield* select(consensusScoringRunTypeSelector);
  const imageId = yield* select(imageViewImageIdSelector);
  const predictedClassId = yield* select(
    semanticReviewPredictedClassIdSelector,
  );
  const maxNumberOfErrors = yield* select(
    semanticReviewMaxNumberOfErrorsSelector,
  );

  if (!runId || !runType || !imageId) return;

  yield* put(
    loadErrorFinderLabels({
      runType,
      runId,
      imageId,
      customFilters: {
        predictedClassId,
        maxNumberOfErrors,
      },
    }),
  );
}

export function* semanticReviewSaga() {
  yield* takeEvery(
    changeSemanticReviewErrorAction,
    changeTagReviewErrorActionHandler,
  );
  yield* takeEvery(
    [setPredictedClassId, setMaxNumberOfErrors],
    handlePredictedClassIdChange,
  );
}
