import { createSelector } from '@reduxjs/toolkit';

import { imageViewSelector } from '../../imageView.selectors';

const imageGalleryItemsSelector = createSelector(
  imageViewSelector,
  (imageView) => imageView.imageGallery.imageGalleryItems,
);

export const imageGalleryItemsTotalSelector = createSelector(
  imageGalleryItemsSelector,
  (imageGalleryItems) => imageGalleryItems.total,
);

export const imageGalleryItemsLoadingStateSelector = createSelector(
  imageGalleryItemsSelector,
  (imageGalleryItems) => imageGalleryItems.loadingState,
);

export const imageGalleryItemsDataSelector = createSelector(
  imageGalleryItemsSelector,
  (imageGalleryItems) => imageGalleryItems.data,
);
