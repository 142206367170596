import axios from 'axios';

import {
  ApiAutomatedLabelingJob,
  ApiAutomatedLabelingEstimation,
} from '../schemas/automatedLabeling';
import { apiWrapper } from '../utils';
import { API_URL } from '../../constants/paths';
import { headers, transformRequest, transformResponse } from '../helpers';

export const apiLoadAutomatedLabelingJobs = (params: { projectId: string }) =>
  apiWrapper(
    axios.request<ApiAutomatedLabelingJob[]>({
      method: 'get',
      headers,
      transformRequest,
      transformResponse,
      url: `${API_URL}/project/${params.projectId}/finish_him`,
    }),
  );

export const apiStartAutomatedLabelingJob = (
  params: {
    projectId: string;
    modelId: number;
  },
  data: {
    datasetId?: string;
    confidenceThreshold: number;
    numImages?: number;
    externalEventId: string;
    updateNoPredictions?: boolean;
  },
) =>
  apiWrapper(
    axios.request<{ jobId: string }>({
      method: 'post',
      headers,
      transformRequest,
      transformResponse,
      url: `${API_URL}/project/${params.projectId}/finish_him/models/${params.modelId}`,
      data,
    }),
  );

export const apiLoadAutomatedLabelingEstimation = (
  params: { projectId: string },
  data: { modelId: number; datasetId?: string; quantity?: number },
) =>
  apiWrapper(
    axios.request<ApiAutomatedLabelingEstimation>({
      method: 'post',
      headers,
      transformResponse,
      transformRequest,
      url: `${API_URL}/project/${params.projectId}/finish_him/credits`,
      data,
    }),
  );
