import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { loadingStateBuilder } from '../../../../utils/loadingState';
import { KeypointsClassMapping } from '../../../../../api/domainModels/keypointsClasses';

export const selectId = (
  kcm: Pick<KeypointsClassMapping, 'labelClassId' | 'keypointSchemaId'>,
) => `${kcm.keypointSchemaId}-${kcm.labelClassId}`;

export const relationsAdapter = createEntityAdapter<KeypointsClassMapping>({
  selectId,
});

const { actions, reducer: KCRelationsReducer } = createSlice({
  name: 'KCRelations',
  initialState: relationsAdapter.getInitialState({
    loadingState: loadingStateBuilder.initial(),
  }),
  reducers: {
    loadKCRelations(state, _action: PayloadAction<{ id: string }>) {
      state.loadingState = loadingStateBuilder.inProgress();
    },
    loadKCRelationsSuccess(
      state,
      action: PayloadAction<KeypointsClassMapping[]>,
    ) {
      state.loadingState = loadingStateBuilder.success();
      relationsAdapter.setAll(state, action.payload);
    },
    loadKCRelationsFailure(state, action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.failure(action.payload);
      relationsAdapter.setAll(state, []);
    },
    processKCRelationsStart(state) {
      state.loadingState = loadingStateBuilder.inProgress();
    },
    processKCRelationsFailure(state, action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.failure(action.payload);
    },
    updateKCManySuccess(state, action: PayloadAction<KeypointsClassMapping[]>) {
      state.loadingState = loadingStateBuilder.success();
      relationsAdapter.upsertMany(state, action.payload);
    },
    updateKCRelationByWebsocket(
      state,
      action: PayloadAction<KeypointsClassMapping>,
    ) {
      relationsAdapter.upsertOne(state, action.payload);
    },
    deleteKCManySuccess(state, action: PayloadAction<KeypointsClassMapping[]>) {
      state.loadingState = loadingStateBuilder.success();
      relationsAdapter.removeMany(state, action.payload.map(selectId));
    },
    deleteKCRelationByWebsocket(
      state,
      action: PayloadAction<KeypointsClassMapping>,
    ) {
      relationsAdapter.removeOne(state, selectId(action.payload));
    },
  },
});

export { KCRelationsReducer };
export const {
  loadKCRelations,
  loadKCRelationsFailure,
  loadKCRelationsSuccess,
  processKCRelationsStart,
  processKCRelationsFailure,
  updateKCManySuccess,
  deleteKCManySuccess,
  updateKCRelationByWebsocket,
  deleteKCRelationByWebsocket,
} = actions;
