import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { ProjectUser } from '../../../../api/domainModels/projectUsers';
import { loadingStateBuilder } from '../../../utils/loadingState';

export const adapter = createEntityAdapter<ProjectUser>();

const { actions, reducer: projectUsersReducer } = createSlice({
  name: 'projectUsers',
  initialState: adapter.getInitialState({
    loadingState: loadingStateBuilder.initial(),
  }),
  reducers: {
    loadProjectUsersStart(state) {
      state.loadingState = loadingStateBuilder.inProgress();
      adapter.removeAll(state);
    },
    loadProjectUsersSuccess(state, action: PayloadAction<ProjectUser[]>) {
      state.loadingState = loadingStateBuilder.success();
      adapter.setAll(state, action.payload);
    },
    loadProjectUsersFailure(state, action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.failure(action.payload);
      adapter.setAll(state, []);
    },
  },
});

export { projectUsersReducer };
export const {
  loadProjectUsersStart,
  loadProjectUsersSuccess,
  loadProjectUsersFailure,
} = actions;
