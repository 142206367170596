import { ApiImage } from '../schemas/images';

const fromBackend = ({ imageId, ...image }: ApiImage) => ({
  ...image,
  id: imageId,
  reload: false, // TODO:: check if this is relevant
});
export type Image = ReturnType<typeof fromBackend>;

export const imagesDataMapper = {
  fromBackend,
};
