import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  LoadingState,
  loadingStateBuilder,
} from '../../../../../utils/loadingState';
import { Image } from '../../../../../../api/domainModels/images';

type ImageViewImageGalleryItemsState = {
  loadingState: LoadingState;
  total: number;
  // @RA would be good to replace indexes Record to slice
  data: Record<string, Image> | null;
};
const initialState: ImageViewImageGalleryItemsState = {
  loadingState: loadingStateBuilder.initial(),
  total: 0,
  data: null,
};
const { actions, reducer: imageViewImageGalleryItemsReducer } = createSlice({
  name: 'imageViewImageGalleryItems',
  initialState,
  reducers: {
    clearGallery: {
      reducer(
        state,
        _action: PayloadAction<void, string, { noLogging: boolean }>,
      ) {
        state.total = initialState.total;
        state.data = initialState.data;
      },
      prepare() {
        return {
          payload: undefined,
          meta: {
            noLogging: true,
          },
        };
      },
    },
    invalidateGallery(state) {
      state.total = initialState.total;
      state.data = initialState.data;
    },
    loadGalleryImages: {
      reducer(
        _state,
        _action: PayloadAction<{ offset: number; loadLimit: number }>,
      ) {},
      prepare(payload) {
        return {
          payload,
          meta: {
            noLogging: true,
          },
        };
      },
    },
    loadGalleryImagesError(state, action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.failure(action.payload);
    },
    loadGalleryImagesStart(state, action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.inProgress(action.payload);
    },
    loadGalleryImagesSuccess(
      state,
      action: PayloadAction<{
        total: number;
        itemsMap: Record<number, Image>;
        items: Image[];
      }>,
    ) {
      state.total = action.payload.total;
      state.data = { ...state.data, ...action.payload.itemsMap };
      state.loadingState = loadingStateBuilder.success();
    },
    resetTotal(state) {
      state.total = initialState.total;
    },
    requestGalleryImageLoad: {
      reducer(
        _state,
        _action: PayloadAction<number, string, { noLogging: boolean }>,
      ) {},
      prepare(payload) {
        return {
          payload,
          meta: {
            noLogging: true,
          },
        };
      },
    },
    updateGalleryImage(
      state,
      action: PayloadAction<{
        offset: string;
        image: Image;
      }>,
    ) {
      if (state.data) {
        state.data[action.payload.offset] = action.payload.image;
      }
    },
    removeGalleryImage(state, action: PayloadAction<{ offset: number }>) {
      state.total -= 1;

      if (state.data) {
        state.data = [
          ...Object.values(state.data).slice(0, action.payload.offset),
          ...Object.values(state.data).slice(action.payload.offset),
        ].reduce((acc, val, index) => {
          acc[index.toString()] = val;

          return acc;
        }, {} as Record<string, Image>);
      }
    },
    reset() {
      return initialState;
    },
  },
});

export { imageViewImageGalleryItemsReducer };
export const {
  clearGallery,
  invalidateGallery,
  loadGalleryImages,
  loadGalleryImagesError,
  loadGalleryImagesStart,
  loadGalleryImagesSuccess,
  resetTotal,
  requestGalleryImageLoad,
  updateGalleryImage,
  removeGalleryImage,
  reset,
} = actions;
