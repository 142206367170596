import { combineReducers, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { objectDetectionReducer } from './objectDetection/objectDetection.slice';
import { atomReducer } from './atom/atom.reducer';
import { drawingReducer } from './drawing/drawing.slice';
import { imageTagsPredictionReducer } from './imageTagsPrediction/imageTagsPrediction.slice';
import { labelAttributesPredictionReducer } from './labelAttributesPrediction/labelAttributesPrediction.slice';
import { semanticSegmentationReducer } from './semanticSegmentation/semanticSegmentation.slice';
import { instanceSegmentationReducer } from './instanceSegmentation/instanceSegmentation.slice';
import { labelClassPredictionReducer } from './labelClassPrediction/labelClassPrediction.slice';
import { boxToInstanceReducer } from './boxToInstance/boxToInstance.slice';
import { activeToolDataReducer } from './activeToolData/activeToolData.slice';
import { automatedLabelingReducer } from './automatedLabeling/automatedLabeling.slice';
import { keypointsReducer } from './keypoints/keypoints.reducer';
import { ImageTool } from './tools.constants';
import { DEFAULT_ZOOM_SPEED } from '../../../../../constants/imageView';
import { keypointsDetectionReducer } from './keypointsDetection/keypointsDetection.slice';
import { textPromptReducer } from './textPrompt/textPrompt.slice';

const initialState: {
  toolId: ImageTool;
  areGuidesVisible: boolean;
  isTrackpadPanningEnabled: boolean;
  zoomSpeed: number;
} = {
  toolId: ImageTool.Default,
  areGuidesVisible: true,
  isTrackpadPanningEnabled: false,
  zoomSpeed: DEFAULT_ZOOM_SPEED,
};
const { reducer, actions } = createSlice({
  name: 'imageViewTools',
  initialState,
  reducers: {
    setActiveTool(state, action: PayloadAction<ImageTool>) {
      state.toolId = action.payload;
    },
    resetActiveTool(state) {
      state.toolId = ImageTool.Default;
    },
    trySetActiveTool(_state, _action: PayloadAction<ImageTool>) {},
    toggleGuides() {},
    toggleTrackpadPanning() {},
    setGuidesVisibility(state, action: PayloadAction<boolean>) {
      state.areGuidesVisible = action.payload;
    },
    setTrackpadPanningEnabled(state, action: PayloadAction<boolean>) {
      state.isTrackpadPanningEnabled = action.payload;
    },
    setZoomSpeed(state, action: PayloadAction<number>) {
      state.zoomSpeed = action.payload;
    },
  },
});

export const toolsReducer = combineReducers({
  activeTool: reducer,
  activeToolData: activeToolDataReducer,
  objectDetection: objectDetectionReducer,
  drawing: drawingReducer,
  atom: atomReducer,
  imageTagsPrediction: imageTagsPredictionReducer,
  labelAttributesPrediction: labelAttributesPredictionReducer,
  semanticSegmentation: semanticSegmentationReducer,
  instanceSegmentation: instanceSegmentationReducer,
  labelClassPrediction: labelClassPredictionReducer,
  boxToInstance: boxToInstanceReducer,
  automatedLabeling: automatedLabelingReducer,
  keypoints: keypointsReducer,
  keypointsDetection: keypointsDetectionReducer,
  textPrompt: textPromptReducer,
});
export const {
  setActiveTool,
  resetActiveTool,
  trySetActiveTool,
  toggleGuides,
  toggleTrackpadPanning,
  setGuidesVisibility,
  setTrackpadPanningEnabled,
  setZoomSpeed,
} = actions;
