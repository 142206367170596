import { AxiosError, AxiosPromise, AxiosResponse } from 'axios';
import { call, put } from 'typed-redux-saga';
import { addBreadcrumb, captureMessage, setTag } from '@sentry/react';

import { logout } from '../redux/state/commonFeatures/auth/auth.slice';
import { setApplicationOutdated } from '../redux/state/ui/applicationOutdated/applicationOutdated.slice';
import { disableVersionCheckFeature } from '../util/features';
import {
  PermanentMessageTypes,
  setPermanentMessage,
} from '../redux/state/ui/permanentMessages/permanentMessages.slice';
import { getIsOutOfCreditsError } from '../util/errors';

declare global {
  interface Window {
    REACT_APP_VERSION: string;
    REACT_APP_SENTRY_ENV: string;
    REACT_APP_SENTRY_DSN: string;
    REACT_APP_GOOGLE_CLIENT_ID: string;
    REACT_APP_STRIPE_KEY: string;
    REACT_APP_SEGMENT_KEY: string;
    REACT_APP_AMPLIFY_API_URL: string;
    REACT_APP_AMPLIFY_API_KEY: string;
  }
}

export function isAxiosError<T>(error: any): error is AxiosError<T> {
  return (error as AxiosError).isAxiosError !== undefined;
}

const isNotAuthorized = <T>(response: AxiosResponse<T>) =>
  response.status === 401;

export function* apiWrapper<T>(promise: AxiosPromise<T>) {
  try {
    const result = yield* call(() => promise);
    addBreadcrumb({
      timestamp: Date.now(),
      data: {
        url: result.config.url,
        method: result.config.method,
        status_code: result.status,
        request_id: result.headers['x-request-id'],
        version: result.headers['x-version'],
      },
    });
    if (!result.headers['x-version']) {
      const regex = /\bapi\b.*\bhasty.ai\b/;
      const regexCheck = regex.exec(result.request.responseURL);
      if (regexCheck && regexCheck[0]) {
        yield* put(
          setPermanentMessage({
            id: PermanentMessageTypes.Proxy,
            message:
              'You seem to be using a proxy. It may cause application malfunction',
          }),
        );
      }
    }

    if (
      result.headers['x-version'] &&
      result.headers['x-version'] !== window.REACT_APP_VERSION &&
      process.env.REACT_APP_OUTDATED_CHECK !== '0' &&
      !disableVersionCheckFeature
    ) {
      yield* put(setApplicationOutdated());
    }

    return result;
  } catch (error) {
    if (isAxiosError<T>(error)) {
      const res = error.response;

      const requestId = res?.headers['x-request-id'];
      const projectId =
        new RegExp('projects\\/(.*?)\\/').exec(error.config.url || '')?.[1] ||
        undefined;

      setTag('request_id', requestId);
      setTag('project_id', projectId);

      addBreadcrumb({
        timestamp: Date.now(),
        data: {
          url: error.config.url,
          method: error.config.method,
          status_code: res?.status,
          request_id: requestId,
          project_id: projectId,
          version: res?.headers?.['x-version'],
        },
      });
      captureMessage(error?.message, {
        level: 'warning',
      });
      if (error.response && isNotAuthorized(error.response)) {
        // case of non-authorized request
        yield* put(logout({ skipRedirect: true, logoutTime: +new Date() }));
      }
    }

    throw error;
  }
}

export type ErrorParams = {
  errorMessage?: string;
  hideToastError?: boolean;
};

export async function recoilApiWrapper<T>(
  promise: AxiosPromise<T>,
  { hideToastError, errorMessage }: ErrorParams = {
    hideToastError: false,
  },
) {
  try {
    const result = await promise;
    addBreadcrumb({
      timestamp: Date.now(),
      data: {
        url: result.config.url,
        method: result.config.method,
        status_code: result.status,
        request_id: result.headers['x-request-id'],
        version: result.headers['x-version'],
      },
    });
    if (!result.headers['x-version']) {
      const regex = /\bapi\b.*\bhasty.ai\b/;
      const regexCheck = regex.exec(result.request.responseURL);
      if (regexCheck && regexCheck[0]) {
        // state.dispatch(
        //   setPermanentMessage({
        //     id: PermanentMessageTypes.Proxy,
        //     message:
        //       'You seem to be using a proxy. It may cause application malfunction',
        //   }),
        // );
      }
    }

    if (
      result.headers['x-version'] &&
      result.headers['x-version'] !== window.REACT_APP_VERSION &&
      process.env.REACT_APP_OUTDATED_CHECK !== '0' &&
      !disableVersionCheckFeature
    ) {
      // state.dispatch(setApplicationOutdated());
    }

    return result;
  } catch (error) {
    if (isAxiosError<T>(error)) {
      const res = error.response;
      addBreadcrumb({
        timestamp: Date.now(),
        data: {
          url: error.config.url,
          method: error.config.method,
          status_code: res?.status,
          request_id: res?.headers?.['x-request-id'],
          version: res?.headers?.['x-version'],
        },
      });
      captureMessage(error?.message, {
        level: 'warning',
      });
      if (error.response && isNotAuthorized(error.response)) {
        // case of non-authorized request
        // state.dispatch(logout({ skipRedirect: true }));
      }
    }

    if (!hideToastError) {
      // todo @important fix in separate PR, big scope, circular dependency in this file
      // notifications.error({
      //   message: getErrorMessage(error, errorMessage),
      //   error,
      // });
    }

    throw error;
  }
}

export const getErrorMessage = (error: any, defaultMessage?: string) => {
  let message = 'Unknown error occurred';
  const responseData = error?.response?.data;
  const responseDataCode = responseData?.code;
  const errorRequestId = error.response?.headers?.['x-request-id'];
  const outOfCreditsError = getIsOutOfCreditsError(error);

  // special case, message is empty on backend side, needs to be fixed whenever BE will have a capacity
  if (outOfCreditsError) {
    message = 'Not enough credits';
  } else if (typeof error === 'string') {
    message = error;
  } else if (responseData && typeof responseDataCode === 'string') {
    message =
      error.response.data.message ||
      error.response.data.details ||
      defaultMessage;
  } else if (error.message) {
    message = error.message;
  } else if (message && defaultMessage) {
    message = `${defaultMessage}: ${message}`;
  } else {
    message = message || (defaultMessage as string);
  }
  if (errorRequestId && !outOfCreditsError) {
    message = `${message} (ID: ${errorRequestId})`;
  }

  return message;
};
