import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import {
  LoadingState,
  loadingStateBuilder,
} from '../../../../utils/loadingState';
import { uploadVideoFrames } from '../frameImports/frameImports.slice';
import { setProjectId } from '../../../core/imageView/project/project.slice';
import { invalidateQueries } from '../../../../../api/api';
import { VideoService } from '../../../../../api/codegen';

export const VIDEO_CHUNK_SIZE = 1024 * 1024 * 10; // ~10mb or 256 * 1024 * 40

type VideoUploadState = {
  totalToUpload: number;
};

export const adapter = createEntityAdapter<{
  id: string;
  name: string;
  loadingState: LoadingState;
  fileSize: number;
  uploadedSize: number;
}>();

const initialState = adapter.getInitialState({
  totalToUpload: 0,
  uploadedVideos: null,
} as VideoUploadState);

const { actions, reducer: videoUploadReducer } = createSlice({
  name: 'videoViewUpload',
  initialState,
  reducers: {
    uploadVideos(
      state,
      action: PayloadAction<{
        files: File[];
        onUploadProgress: (id: string) => (event: ProgressEvent) => void;
      }>,
    ) {
      state.totalToUpload = action.payload.files.length;
    },
    uploadVideoFailure(
      state,
      action: PayloadAction<{ id: string; message: string }>,
    ) {
      const { id, message } = action.payload;

      adapter.updateOne(state, {
        id,
        changes: {
          loadingState: loadingStateBuilder.failure(message),
        },
      });
    },
    uploadVideoStart(
      state,
      action: PayloadAction<{ id: string; name: string; fileSize: number }>,
    ) {
      adapter.addOne(state, {
        ...action.payload,
        uploadedSize: 0,
        loadingState: loadingStateBuilder.inProgress(),
      });
    },
    uploadVideoSuccess(state, action: PayloadAction<string>) {
      adapter.updateOne(state, {
        id: action.payload,
        changes: {
          loadingState: loadingStateBuilder.success(),
        },
      });
      invalidateQueries(VideoService.videosList);
    },
    resetVideosUpload(state) {
      state.totalToUpload = initialState.totalToUpload;
      adapter.removeAll(state);
    },
    updateVideoUploadProgress(
      state,
      action: PayloadAction<{ id: string; progress: number }>,
    ) {
      const { id, progress } = action.payload;

      const existingVideo = state.entities[id];

      const uploadedSize = existingVideo?.uploadedSize || 0;

      if (uploadedSize < VIDEO_CHUNK_SIZE) {
        adapter.updateOne(state, { id, changes: { uploadedSize: progress } });
      } else {
        const noOfChunks = Math.floor(uploadedSize / VIDEO_CHUNK_SIZE);

        adapter.updateOne(state, {
          id,
          changes: { uploadedSize: noOfChunks * VIDEO_CHUNK_SIZE + progress },
        });
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(uploadVideoFrames, (state, action) => {
      state.totalToUpload = action.payload.files.length;
    });
    builder.addCase(setProjectId, () => initialState);
  },
});

export { videoUploadReducer };
export const {
  uploadVideos,
  uploadVideoFailure,
  uploadVideoSuccess,
  uploadVideoStart,
  resetVideosUpload,
  updateVideoUploadProgress,
} = actions;
