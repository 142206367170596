import { createSelector } from '@reduxjs/toolkit';

import { imageViewSelector } from '../../imageView.selectors';
import { modelStatusChecks } from '../models/modelStatus.constants';

const attributesPredictionSelector = createSelector(
  imageViewSelector,
  (imageView) => imageView.tools.labelAttributesPrediction,
);
const attributesPredictionDataSelector = createSelector(
  attributesPredictionSelector,
  (attributesPrediction) => attributesPrediction.data,
);
export const attributesPredictionsSelector = createSelector(
  attributesPredictionDataSelector,
  (predictionData) => predictionData.predictions,
);
export const attributesPredictionModelStatusSelector = createSelector(
  attributesPredictionSelector,
  (attributesPrediction) => attributesPrediction.model.status,
);
export const attributesPredictionModelIdSelector = createSelector(
  attributesPredictionSelector,
  (attributesPrediction) => attributesPrediction.model.id,
);
export const attributesPredictionThresholdSelector = createSelector(
  attributesPredictionSelector,
  (attributesPrediction) => attributesPrediction.threshold,
);
export const attributesPredictionModelAvailableSelector = createSelector(
  attributesPredictionModelIdSelector,
  (modelId) => typeof modelId === 'number',
);
export const attributesPredictionModelLoadedSelector = createSelector(
  [
    attributesPredictionModelAvailableSelector,
    attributesPredictionModelStatusSelector,
  ],
  (available, status) =>
    !!(available && status && modelStatusChecks.isLoaded(status)),
);
