import { call, put, select, takeEvery, take } from 'typed-redux-saga';

import { apiChangeTagErrorResultAction } from '../../../../../api/requests/consensusScoring';
import { activeProjectIdSelector } from '../../../project/project.selectors';
import {
  changeTagReviewErrorAction,
  changeTagReviewErrorActionSuccess,
  addNewTagToImage,
  addNewTagToImageSuccess,
  removeExistingTagFromImage,
  removeExistingTagFromImageSuccess,
  updateErrorTag,
  changeAllTagReviewErrorActionsSuccess,
  changeAllTagReviewErrorActions,
} from './tagReview.slice';
import { updateTriggerData } from '../../../sections/editedProject/triggers/triggers.slice';
import { TriggerIds } from '../../../../../@types/editedProject/Trigger.types';
import { handleError } from '../../../commonFeatures/errorHandler/errorHandler.actions';
import { getErrorMessage } from '../../../../../api/utils';
import {
  apiAddTagToImage,
  apiRemoveTagFromImage,
} from '../../../../../api/requests/imageTags';
import { tagGroupsMapWithDefaultSelector } from '../../../project/annotationTaxonomy/tagGroups/tagGroups.selectors';
import { ErrorFinderAction } from '../../../../../api/constants/consensusScoring';
import { imageViewImageGalleryFiltersCSRunIdSelector } from '../../imageView/imageGallery/bottomBar/filters/filters.selectors';
import { ErrorType } from '../../../../../api/domainModels/consensusScoring';

function* changeTagReviewErrorActionHandler(
  action: ActionType<typeof changeTagReviewErrorAction>,
) {
  const { runId: aicsRunId, ...payload } = action.payload;
  const { imageId, efAction, efTagId } = payload;

  const projectId = yield* select(activeProjectIdSelector);

  // if not forwarded from AICS page then maybe from AE filters
  const runId =
    aicsRunId || (yield* select(imageViewImageGalleryFiltersCSRunIdSelector));

  if (!projectId || !runId) return;

  yield* put(
    updateTriggerData({
      id: TriggerIds.DataCleaning,
      data: {
        hasUpdatedAiCSRunResults: true,
      },
    }),
  );

  try {
    yield* call(
      apiChangeTagErrorResultAction,
      { projectId, runId, imageId },
      { efAction, efTagId },
    );

    yield* put(changeTagReviewErrorActionSuccess({ ...payload, runId }));
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to update tag results');

    yield* put(handleError({ error, message }));
  }
}

function* changeAllTagReviewErrorActionHandler(
  action: ActionType<typeof changeAllTagReviewErrorActions>,
) {
  const { runId: aicsRunId, ...payload } = action.payload;
  const { imageId, efAction } = payload;

  const projectId = yield* select(activeProjectIdSelector);

  // if not forwarded from AICS page then maybe from AE filters
  const runId =
    aicsRunId || (yield* select(imageViewImageGalleryFiltersCSRunIdSelector));

  if (!projectId || !runId) return;

  yield* put(
    updateTriggerData({
      id: TriggerIds.DataCleaning,
      data: {
        hasUpdatedAiCSRunResults: true,
      },
    }),
  );

  try {
    yield* call(
      apiChangeTagErrorResultAction,
      { projectId, runId, imageId },
      { efAction },
    );

    yield* put(changeAllTagReviewErrorActionsSuccess({ ...payload, runId }));
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to update tag results');

    yield* put(handleError({ error, message }));
  }
}

function* addNewTagToImageHandler(action: ActionType<typeof addNewTagToImage>) {
  const projectId = yield* select(activeProjectIdSelector);
  const { tagClassId, imageId, tagGroupId } = action.payload;

  const tagGroupsMap = yield* select(tagGroupsMapWithDefaultSelector);

  const tagGroup = tagGroupsMap[tagGroupId];

  if (!tagGroup) return;

  try {
    const { data } = yield* call(
      apiAddTagToImage,
      {
        projectId,
        imageId,
      },
      [{ tagClassId }],
    );

    yield* put(
      addNewTagToImageSuccess({
        ...action.payload,
        ...data[0],
        tagGroupName: tagGroup.name,
        tagGroupType: tagGroup.type,
      }),
    );
  } catch (error) {
    const errorMessage = getErrorMessage(error, 'Not able to add tag to image');
    yield* put(handleError({ message: errorMessage, error }));
  }
}

function* removeExistingTagFromImageHandler(
  action: ActionType<typeof removeExistingTagFromImage>,
) {
  const projectId = yield* select(activeProjectIdSelector);
  const { tagClassId, imageId } = action.payload;

  try {
    yield* call(
      apiRemoveTagFromImage,
      {
        projectId,
        imageId,
      },
      [{ tagClassId }],
    );

    yield* put(removeExistingTagFromImageSuccess(action.payload));
  } catch (error) {
    const errorMessage = getErrorMessage(
      error,
      'Not able to remove tag from image',
    );
    yield* put(handleError({ message: errorMessage, error }));
  }
}

function* updateErrorTagHandler(action: ActionType<typeof updateErrorTag>) {
  const {
    runId: aicsRunId,
    imageId,
    tagGroupId,
    efTagId,
    curTagId,
    newTagClassId,
    currentUserId,
  } = action.payload;

  // if not forwarded from AICS page then maybe from AE filters
  const runId =
    aicsRunId || (yield* select(imageViewImageGalleryFiltersCSRunIdSelector));

  if (!runId) return;

  if (curTagId) {
    yield* put(
      removeExistingTagFromImage({
        imageId,
        tagClassId: curTagId,
        tagGroupId,
      }),
    );

    yield* take(removeExistingTagFromImageSuccess);
  }

  yield* put(
    addNewTagToImage({
      imageId,
      tagClassId: newTagClassId,
      tagGroupId,
      labeledBy: currentUserId,
    }),
  );

  yield* put(
    changeTagReviewErrorAction({
      runId,
      imageId,
      tagGroupId,
      efTagId,
      efAction: ErrorFinderAction.ChangedManually,
      reviewedBy: currentUserId,
      errorType: ErrorType.Misclassification,
      curTagId,
      predTagId: newTagClassId,
    }),
  );
}

export function* tagReviewSaga() {
  yield* takeEvery(
    changeTagReviewErrorAction,
    changeTagReviewErrorActionHandler,
  );
  yield* takeEvery(
    changeAllTagReviewErrorActions,
    changeAllTagReviewErrorActionHandler,
  );
  yield* takeEvery(addNewTagToImage, addNewTagToImageHandler);
  yield* takeEvery(
    removeExistingTagFromImage,
    removeExistingTagFromImageHandler,
  );
  yield* takeEvery(updateErrorTag, updateErrorTagHandler);
}
