import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { WebhookLog } from '../../../../../api/domainModels/webhook';
import { loadingStateBuilder } from '../../../../utils/loadingState';
import { setProjectId } from '../../../core/imageView/project/project.slice';

export const adapter = createEntityAdapter<WebhookLog>({});
const initialState = adapter.getInitialState({
  listLoadingState: loadingStateBuilder.initial(),
});

const { actions, reducer: webhookLogsReducer } = createSlice({
  name: 'webhookLogs',
  initialState,
  reducers: {
    loadWebhookLogsStart(state, _action: PayloadAction<string>) {
      state.listLoadingState = loadingStateBuilder.inProgress();
    },
    loadWebhookLogsSuccess(state, action: PayloadAction<WebhookLog>) {
      state.listLoadingState = loadingStateBuilder.success();
      adapter.upsertOne(state, action.payload);
    },
    loadWebhookLogsFailure(state, action: PayloadAction<string>) {
      state.listLoadingState = loadingStateBuilder.failure(action.payload);
      adapter.setAll(state, []);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setProjectId, () => initialState);
  },
});

export { webhookLogsReducer };
export const {
  loadWebhookLogsStart,
  loadWebhookLogsSuccess,
  loadWebhookLogsFailure,
} = actions;
