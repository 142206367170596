/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Dataset } from '../models/Dataset';
import type { Job } from '../models/Job';
import type { PaginationMeta } from '../models/PaginationMeta';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { OmitReadonly } from '../core/utils/OmitReadonly';

export class DatasetService {

  /**
   * Fetch existing datasets
   * @returns any Successful read of the list of datasets
   * @throws ApiError
   */
  public static getDatasets({
    projectId,
    offset,
    limit,
    name,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Offset for this paginated query
     */
    offset?: number,
    /**
     * Limit for this paginated query
     */
    limit?: number,
    /**
     * Filter datasets which contain name (case-insensitive)
     */
    name?: string,
  }): CancelablePromise<{
    meta: PaginationMeta;
    items: Array<Dataset>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/projects/{project_id}/datasets',
      path: {
        'project_id': projectId,
      },
      query: {
        'offset': offset,
        'limit': limit,
        'name': name,
      },
    });
  }

  /**
   * Create a new dataset
   * @returns Dataset Returns the newly created dataset
   * @throws ApiError
   */
  public static postDataset({
    projectId,
    requestBody,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    requestBody: OmitReadonly<Dataset>,
  }): CancelablePromise<Dataset> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/projects/{project_id}/datasets',
      path: {
        'project_id': projectId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Fetch dataset by id
   * @returns Dataset Successful read of the dataset
   * @throws ApiError
   */
  public static getDataset({
    projectId,
    datasetId,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Dataset ID
     */
    datasetId: string,
  }): CancelablePromise<Dataset> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/projects/{project_id}/datasets/{dataset_id}',
      path: {
        'project_id': projectId,
        'dataset_id': datasetId,
      },
    });
  }

  /**
   * Delete an existing dataset
   * @returns void
   * @throws ApiError
   */
  public static deleteDataset({
    projectId,
    datasetId,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Dataset ID
     */
    datasetId: string,
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/v1/projects/{project_id}/datasets/{dataset_id}',
      path: {
        'project_id': projectId,
        'dataset_id': datasetId,
      },
    });
  }

  /**
   * Edit an existing dataset
   * @returns Dataset Returns the edited dataset
   * @throws ApiError
   */
  public static putDataset({
    projectId,
    datasetId,
    requestBody,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Dataset ID
     */
    datasetId: string,
    requestBody: OmitReadonly<Dataset>,
  }): CancelablePromise<Dataset> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/v1/projects/{project_id}/datasets/{dataset_id}',
      path: {
        'project_id': projectId,
        'dataset_id': datasetId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Update dataset images status
   * @returns Job Returns the job id performing the status update
   * @throws ApiError
   */
  public static datasetsStatusUpdate({
    projectId,
    datasetId,
    requestBody,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Dataset ID
     */
    datasetId: string,
    requestBody: {
      /**
       * Dataset images status
       */
      status: 'NEW' | 'IN PROGRESS' | 'TO REVIEW' | 'DONE' | 'SKIPPED';
    },
  }): CancelablePromise<Job> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/v2/datasets/projects/{project_id}/datasets/{dataset_id}/images/status',
      path: {
        'project_id': projectId,
        'dataset_id': datasetId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
