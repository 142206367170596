import styled from 'styled-components';
import MuiTooltip, {
  TooltipProps,
  tooltipClasses,
} from '@mui/material/Tooltip';

type BasicTooltipProps = {
  size?: 'medium' | 'small';
} & TooltipProps;

export const BasicTooltip = styled(
  ({ className, size, ...props }: BasicTooltipProps) => (
    <MuiTooltip {...props} arrow classes={{ popper: className }} />
  ),
)(({ theme: { spacing }, size = 'medium' }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: spacing(size === 'medium' ? 2 : 1),
  },
}));
