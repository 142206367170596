import { createSelector } from '@reduxjs/toolkit';

import { imageViewSelector } from '../../imageView.selectors';
import { modelStatusChecks } from '../models/modelStatus.constants';
import { adapter } from './boxToInstance.slice';

const boxToInstanceSelector = createSelector(
  imageViewSelector,
  (imageView) => imageView.tools.boxToInstance,
);

const boxToInstanceObjectsEntitySelector = createSelector(
  boxToInstanceSelector,
  (boxToInstance) => boxToInstance.objects,
);

const selectors = adapter.getSelectors(boxToInstanceObjectsEntitySelector);

export const boxToInstanceObjectsSelector = selectors.selectAll;

export const boxToInstanceItemsCountSelector = selectors.selectTotal;

export const boxToInstanceLoadingStateSelector = createSelector(
  boxToInstanceSelector,
  (boxToInstance) => boxToInstance.loadingState,
);

export const boxToInstanceModelSelector = createSelector(
  boxToInstanceSelector,
  (boxToInstance) => boxToInstance.model,
);

export const boxToInstanceModelIdSelector = createSelector(
  boxToInstanceModelSelector,
  (model) => model.id,
);

export const boxToInstanceModelStatusSelector = createSelector(
  boxToInstanceModelSelector,
  (model) => model.status,
);

export const boxToInstanceModelAvailableSelector = createSelector(
  boxToInstanceModelIdSelector,
  (modelId) => typeof modelId === 'number',
);

export const boxToInstanceModelLoadedSelector = createSelector(
  [boxToInstanceModelAvailableSelector, boxToInstanceModelStatusSelector],
  (available, status) =>
    !!(available && status && modelStatusChecks.isLoaded(status)),
);
