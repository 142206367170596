/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BBoxDataNullable } from '../models/BBoxDataNullable';
import type { CoordinatesDataNullable } from '../models/CoordinatesDataNullable';
import type { KeypointClass1 } from '../models/KeypointClass1';
import type { KeypointLabel } from '../models/KeypointLabel';
import type { MRLabel1 } from '../models/MRLabel1';
import type { MRSegment } from '../models/MRSegment';
import type { PaginationMetaV2 } from '../models/PaginationMetaV2';
import type { RLEMaskDataNullable } from '../models/RLEMaskDataNullable';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { OmitReadonly } from '../core/utils/OmitReadonly';

export class ManualReviewService {

  /**
   * Fetch video segments for project
   * @returns any Successful read of video segments
   * @throws ApiError
   */
  public static manualReviewSegmentsList({
    projectId,
    page,
    cursor,
    limit,
    datasetId,
    videoStatus,
    videoName,
    activityId,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Page to fetch (NEXT or PREV)
     */
    page?: 'NEXT' | 'PREV',
    /**
     * Cursor for this paginated query
     */
    cursor?: string,
    /**
     * Limit for this paginated query
     */
    limit?: number,
    /**
     * Dataset ID
     */
    datasetId?: string,
    /**
     * Status of the video
     */
    videoStatus?: 'NEW' | 'IN PROGRESS' | 'DONE' | 'SKIPPED' | 'TO REVIEW' | 'AUTO-LABELLED',
    /**
     * Video name filter
     */
    videoName?: string,
    /**
     * Activity ID filter
     */
    activityId?: string,
  }): CancelablePromise<{
    meta: PaginationMetaV2;
    items: Array<MRSegment>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v2/manual_review/projects/{project_id}/video_segments',
      path: {
        'project_id': projectId,
      },
      query: {
        'page': page,
        'cursor': cursor,
        'limit': limit,
        'dataset_id': datasetId,
        'video_status': videoStatus,
        'video_name': videoName,
        'activity_id': activityId,
      },
    });
  }

  /**
   * Fetch labels for project
   * @returns any Successful read of labels
   * @throws ApiError
   */
  public static manualReviewLabelsList({
    projectId,
    page,
    cursor,
    limit,
    labelClassId,
    labelerId,
    datasetId,
    imageStatus,
    imageName,
    labelAuthorId,
    attributeId,
    attributeValue,
    boolAttributeValue,
    createdSince,
    updatedSince,
    noAttributeId,
    keypointSchemaId,
    withoutKeypointClassId,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Page to fetch (NEXT or PREV)
     */
    page?: 'NEXT' | 'PREV',
    /**
     * Cursor for this paginated query
     */
    cursor?: string,
    /**
     * Limit for this paginated query
     */
    limit?: number,
    /**
     * Label class ID
     */
    labelClassId?: string,
    /**
     * ID of the user that modified a label last
     */
    labelerId?: string,
    /**
     * Dataset ID
     */
    datasetId?: string,
    /**
     * Image status
     */
    imageStatus?: string,
    /**
     * Image name filter
     */
    imageName?: string,
    /**
     * ID of the user that creates label
     */
    labelAuthorId?: string,
    /**
     * ID of the attribute to filter by (requries attrubute value)
     */
    attributeId?: string,
    /**
     * ID of the attribute value to filter by (requries attrubute id)
     */
    attributeValue?: string,
    /**
     * Boolean filter for attributes of type BOOL
     */
    boolAttributeValue?: boolean,
    /**
     * Datetime interval to filter labels by creation date (1h, 30m, 3d etc)
     */
    createdSince?: string,
    /**
     * Datetime interval to filter labels by update date (1h, 30m, 3d etc)
     */
    updatedSince?: string,
    /**
     * ID of the attribute that are not specified for a label
     */
    noAttributeId?: string,
    /**
     * ID of the keypoint schema to filter by
     */
    keypointSchemaId?: string,
    /**
     * ID of the keypoint class id
     */
    withoutKeypointClassId?: string,
  }): CancelablePromise<{
    meta: PaginationMetaV2;
    items: Array<MRLabel1>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v2/manual_review/projects/{project_id}/labels',
      path: {
        'project_id': projectId,
      },
      query: {
        'page': page,
        'cursor': cursor,
        'limit': limit,
        'label_class_id': labelClassId,
        'labeler_id': labelerId,
        'dataset_id': datasetId,
        'image_status': imageStatus,
        'image_name': imageName,
        'label_author_id': labelAuthorId,
        'attribute_id': attributeId,
        'attribute_value': attributeValue,
        'bool_attribute_value': boolAttributeValue,
        'created_since': createdSince,
        'updated_since': updatedSince,
        'no_attribute_id': noAttributeId,
        'keypoint_schema_id': keypointSchemaId,
        'without_keypoint_class_id': withoutKeypointClassId,
      },
    });
  }

  /**
   * Fetch label data for a label id
   * @returns any Successful read of label data
   * @throws ApiError
   */
  public static manualReviewLabelsBlob({
    projectId,
    labelId,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Label ID
     */
    labelId: string,
  }): CancelablePromise<{
    bbox?: BBoxDataNullable;
    mask?: RLEMaskDataNullable;
    polygon?: CoordinatesDataNullable;
    /**
     * Keypoint schema name
     */
    keypointSchemaName?: string | null;
    keypointClasses?: Array<KeypointClass1> | null;
    keypointLabels?: Array<KeypointLabel> | null;
    thumbPolygon?: CoordinatesDataNullable;
    thumbBbox?: BBoxDataNullable;
    thumbMask?: RLEMaskDataNullable;
    thumbKeypoints?: CoordinatesDataNullable;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v2/manual_review/projects/{project_id}/labels/{label_id}/blobs',
      path: {
        'project_id': projectId,
        'label_id': labelId,
      },
    });
  }

}
