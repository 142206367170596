import { call, put, select, takeEvery, take } from 'typed-redux-saga';

import { apiChangeAttributeErrorResultAction } from '../../../../../api/requests/consensusScoring';
import { activeProjectIdSelector } from '../../../project/project.selectors';
import {
  changeAttributeReviewErrorAction,
  changeAttributeReviewErrorActionSuccess,
  addNewAttributeToLabel,
  addNewAttributeToLabelSuccess,
  removeExistingAttributeFromLabel,
  removeExistingAttributeFromLabelSuccess,
  updateErrorAttribute,
  changeAllAttributeReviewErrorActions,
  changeAllAttributeReviewErrorActionsSuccess,
} from './attributeReview.slice';
import { updateTriggerData } from '../../../sections/editedProject/triggers/triggers.slice';
import { TriggerIds } from '../../../../../@types/editedProject/Trigger.types';
import { handleError } from '../../../commonFeatures/errorHandler/errorHandler.actions';
import { getErrorMessage } from '../../../../../api/utils';
import {
  apiLoadProjectImageLabelAttributes,
  apiUpdateProjectImageLabelAttribute,
} from '../../../../../api/requests/attribute';
import { attributesMapSelector } from '../../../project/annotationTaxonomy/attributes/attributes.selectors';
import { AttributeType } from '../../../../../api/constants/attribute';
import { ErrorFinderAction } from '../../../../../api/constants/consensusScoring';
import { imageViewImageGalleryFiltersCSRunIdSelector } from '../../imageView/imageGallery/bottomBar/filters/filters.selectors';
import { ErrorType } from '../../../../../api/domainModels/consensusScoring';

function* acceptOrRejectHandler(
  action: ActionType<typeof changeAttributeReviewErrorAction>,
) {
  const { runId: aicsRunId, ...payload } = action.payload;
  const { labelId, efAction, efAttributeId } = payload;

  const projectId = yield* select(activeProjectIdSelector);

  // if not forwarded from AICS page then maybe from AE filters
  const runId =
    aicsRunId || (yield* select(imageViewImageGalleryFiltersCSRunIdSelector));

  if (!projectId || !runId) return;

  yield* put(
    updateTriggerData({
      id: TriggerIds.DataCleaning,
      data: {
        hasUpdatedAiCSRunResults: true,
      },
    }),
  );

  try {
    yield* call(
      apiChangeAttributeErrorResultAction,
      { projectId, runId, labelId },
      { efAction, efAttributeId },
    );

    yield* put(changeAttributeReviewErrorActionSuccess({ ...payload, runId }));
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to update tag results');

    yield* put(handleError({ error, message }));
  }
}

function* acceptOrRejectAllHandler(
  action: ActionType<typeof changeAllAttributeReviewErrorActions>,
) {
  const { runId: aicsRunId, ...payload } = action.payload;
  const { labelId, efAction } = payload;

  const projectId = yield* select(activeProjectIdSelector);

  // if not forwarded from AICS page then maybe from AE filters
  const runId =
    aicsRunId || (yield* select(imageViewImageGalleryFiltersCSRunIdSelector));

  if (!projectId || !runId) return;

  yield* put(
    updateTriggerData({
      id: TriggerIds.DataCleaning,
      data: {
        hasUpdatedAiCSRunResults: true,
      },
    }),
  );

  try {
    yield* call(
      apiChangeAttributeErrorResultAction,
      { projectId, runId, labelId },
      { efAction },
    );

    yield* put(
      changeAllAttributeReviewErrorActionsSuccess({ ...payload, runId }),
    );
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to update tag results');

    yield* put(handleError({ error, message }));
  }
}

function* addNewAttributeHandler(
  action: ActionType<typeof addNewAttributeToLabel>,
) {
  const { labelId, attributeId, attributeToAddId } = action.payload;

  const projectId = yield* select(activeProjectIdSelector);
  const attributesMap = yield* select(attributesMapSelector);

  const attribute = attributesMap[attributeId];
  const attributeToAdd = attribute?.values.find(
    (item) => item.id === attributeToAddId,
  );

  if (!attribute || !attributeToAdd) return;

  try {
    const { data: getAttributesData } = yield* call(
      apiLoadProjectImageLabelAttributes,
      {
        projectId,
        labelId,
      },
    );

    const attributeData = getAttributesData.items.find(
      (item) => item.id === attributeId,
    );

    const data = attributeData
      ? {
          ...attributeData,
          value: Array.isArray(attributeData.value)
            ? [...attributeData.value, attributeToAddId]
            : attributeToAddId,
        }
      : {
          id: attribute.id,
          name: attribute.name,
          type: attribute.type,
          value:
            attribute.type === AttributeType.Multi
              ? [attributeToAddId]
              : attributeToAddId,
        };

    yield* call(
      apiUpdateProjectImageLabelAttribute,
      {
        projectId,
        attributeId,
        labelId,
      },
      data,
    );

    yield* put(
      addNewAttributeToLabelSuccess({
        ...action.payload,
        attributeName: attribute.name,
        attributeType: attribute.type,
        id: attributeToAdd.id,
        valueId: attributeToAdd.id,
        value: attributeToAdd.name,
      }),
    );
  } catch (error) {
    const errorMessage = getErrorMessage(
      error,
      'Not able to add attribute to label',
    );
    yield* put(handleError({ message: errorMessage, error }));
  }
}

function* removeExistingAttributeHandler(
  action: ActionType<typeof removeExistingAttributeFromLabel>,
) {
  const { labelId, attributeId, attributeToRemoveId } = action.payload;

  const projectId = yield* select(activeProjectIdSelector);

  try {
    const { data: getAttributesData } = yield* call(
      apiLoadProjectImageLabelAttributes,
      {
        projectId,
        labelId,
      },
    );

    const attribute = getAttributesData.items.find(
      (item) => item.id === attributeId,
    );

    if (!attribute) return;

    const data = {
      ...attribute,
      value:
        attribute.type === AttributeType.Multi
          ? (attribute.value as string[])?.filter(
              (item) => item !== attributeToRemoveId,
            )
          : null,
    };

    yield* call(
      apiUpdateProjectImageLabelAttribute,
      {
        projectId,
        attributeId,
        labelId,
      },
      data,
    );

    yield* put(removeExistingAttributeFromLabelSuccess(action.payload));
  } catch (error) {
    const errorMessage = getErrorMessage(
      error,
      'Not able to remove attribute from label',
    );
    yield* put(handleError({ message: errorMessage, error }));
  }
}

function* updateErrorAttributeHandler(
  action: ActionType<typeof updateErrorAttribute>,
) {
  const {
    runId: aicsRunId,
    imageId,
    attributeId,
    labelId,
    efAttributeId,
    curValueId,
    attributeToAddId,
    currentUserId,
  } = action.payload;

  // if not forwarded from AICS page then maybe from AE filters
  const runId =
    aicsRunId || (yield* select(imageViewImageGalleryFiltersCSRunIdSelector));

  if (!runId) return;

  if (curValueId) {
    yield* put(
      removeExistingAttributeFromLabel({
        imageId,
        attributeId,
        attributeToRemoveId: curValueId,
        labelId,
      }),
    );

    yield* take(removeExistingAttributeFromLabelSuccess);
  }

  yield* put(
    addNewAttributeToLabel({
      imageId,
      attributeId,
      attributeToAddId,
      labelId,
      labeledBy: currentUserId,
    }),
  );

  yield* put(
    changeAttributeReviewErrorAction({
      runId,
      imageId,
      attributeId,
      efAttributeId,
      labelId,
      efAction: ErrorFinderAction.ChangedManually,
      errorType: ErrorType.Misclassification,
      reviewedBy: currentUserId,
      curValueId,
      predValueId: attributeToAddId,
    }),
  );
}

export function* attributeReviewSaga() {
  yield* takeEvery(changeAttributeReviewErrorAction, acceptOrRejectHandler);
  yield* takeEvery(
    changeAllAttributeReviewErrorActions,
    acceptOrRejectAllHandler,
  );
  yield* takeEvery(addNewAttributeToLabel, addNewAttributeHandler);
  yield* takeEvery(
    removeExistingAttributeFromLabel,
    removeExistingAttributeHandler,
  );
  yield* takeEvery(updateErrorAttribute, updateErrorAttributeHandler);
}
