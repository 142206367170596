import { select, put, takeEvery, call, all } from 'typed-redux-saga';
import pick from 'lodash/pick';

import {
  apiLoadAllImportLabelClassesForMatching,
  apiLoadImportLabelClassForMatching,
  apiUpdateImportLabelClassForMatching,
} from '../../../../../../api/requests/imports';
import { getErrorMessage } from '../../../../../../api/utils';
import { handleError } from '../../../../commonFeatures/errorHandler/errorHandler.actions';
import { activeProjectIdSelector } from '../../../../project/project.selectors';
import { loadAll } from '../../../../../utils/api';
import {
  loadAllLabelClassesStart,
  loadAllLabelClassesSuccess,
  loadAllLabelClassesFailure,
  loadLabelClassStart,
  loadLabelClassSuccess,
  loadLabelClassFailure,
  updateLabelClassStart,
  updateLabelClassSuccess,
  updateLabelClassFailure,
  finishMatchingLabelClassesStart,
} from './importLabelClass.slice';
import { activeImportSessionIdSelector } from '../session/importSession.selectors';
import {
  ImportLabelClass,
  importLabelClassDataMapper,
} from '../../../../../../api/domainModels/imports';
import { labelGenerator } from '../../../../../../util/label';
import { clearObjectStorage } from '../../../../core/imageView/imageView.helpers';
import { notMatchedImportLabelClassesSelector } from './importLabelClass.selectors';

type usedObjectsType = { [key: string]: [number, number] };
let usedObjects: usedObjectsType = {};

function* loadAllHandler(action: ActionType<typeof loadAllLabelClassesStart>) {
  const { sessionId: sId, projectId: pId } = action.payload;
  const projectId = pId || (yield* select(activeProjectIdSelector));
  const sessionId = sId || (yield* select(activeImportSessionIdSelector));

  try {
    const items = yield* loadAll({
      apiHelper: apiLoadAllImportLabelClassesForMatching,
      fromBackend: importLabelClassDataMapper.fromBackend,
      params: {
        projectId,
        sessionId,
      },
    });

    const parsedItems: ImportLabelClass[] = yield* all(
      items.map((item) => labelGenerator(item, usedObjects)),
    );

    yield* put(loadAllLabelClassesSuccess(parsedItems));

    clearObjectStorage();
    usedObjects = {};
  } catch (error) {
    const errorMessage = getErrorMessage(
      error,
      'Not able to load all matching classes.',
    );

    yield* put(loadAllLabelClassesFailure(errorMessage));
    yield* put(
      handleError({ message: errorMessage, error, allowOutsideOfEditor: true }),
    );
  }
}

function* loadOneHandler(action: ActionType<typeof loadLabelClassStart>) {
  const projectId = yield* select(activeProjectIdSelector);
  const sessionId = yield* select(activeImportSessionIdSelector);
  const classId = action.payload;

  if (!sessionId) return;

  try {
    const { data } = yield* call(apiLoadImportLabelClassForMatching, {
      projectId,
      sessionId,
      classId,
    });

    const mappedData = importLabelClassDataMapper.fromBackend(data);

    const item = yield* labelGenerator(mappedData, usedObjects);

    yield* put(loadLabelClassSuccess(item));

    clearObjectStorage();
    usedObjects = {};
  } catch (error) {
    const errorMessage = getErrorMessage(
      error,
      'Not able to load matching class.',
    );

    yield* put(loadLabelClassFailure(errorMessage));
    yield* put(
      handleError({ message: errorMessage, error, allowOutsideOfEditor: true }),
    );
  }
}

function* updateHandler(action: ActionType<typeof updateLabelClassStart>) {
  const projectId = yield* select(activeProjectIdSelector);
  const sessionId = yield* select(activeImportSessionIdSelector);
  const labelClass = action.payload;

  if (!sessionId) return;

  const labelClassUpdate = pick(labelClass, [
    'imageId',
    'classId',
    'className',
    'ignoreClass',
    'color',
    'refClassId',
  ]);

  try {
    const { data } = yield* call(
      apiUpdateImportLabelClassForMatching,
      {
        projectId,
        sessionId,
        classId: labelClassUpdate.classId,
      },
      labelClassUpdate,
    );

    const item = yield* labelGenerator(
      importLabelClassDataMapper.fromBackend(data),
      usedObjects,
    );

    yield* put(
      updateLabelClassSuccess({
        id: labelClassUpdate.classId,
        changes: item,
      }),
    );

    clearObjectStorage();
    usedObjects = {};
  } catch (error) {
    const errorMessage = getErrorMessage(
      error,
      'Not able to update the matching class.',
    );

    yield* put(updateLabelClassFailure(errorMessage));
    yield* put(
      handleError({ message: errorMessage, error, allowOutsideOfEditor: true }),
    );
  }
}

const PROCESS_CHUNK = 5;
function* processUpdateChunk(labelClasses: ImportLabelClass[]) {
  try {
    yield* all(
      labelClasses.map((labelClass) =>
        put(updateLabelClassStart({ ...labelClass, ignoreClass: true })),
      ),
    );

    return true;
  } catch {
    return false;
  }
}

function* ignoreClassesHandler(
  action: ActionType<typeof finishMatchingLabelClassesStart>,
) {
  const { onNextStep } = action.payload;
  const notMatchedLabelClasses = yield* select(
    notMatchedImportLabelClassesSelector,
  );

  let index = 0;
  do {
    const labelClasses = notMatchedLabelClasses.slice(
      index,
      index + PROCESS_CHUNK,
    );

    const isProcessed = yield* call(processUpdateChunk, labelClasses);

    if (isProcessed) {
      index += PROCESS_CHUNK;
    }
  } while (index < notMatchedLabelClasses.length);

  onNextStep();
}

export function* importLabelClassSaga() {
  yield* takeEvery(loadAllLabelClassesStart, loadAllHandler);
  yield* takeEvery(loadLabelClassStart, loadOneHandler);
  yield* takeEvery(updateLabelClassStart, updateHandler);
  yield* takeEvery(finishMatchingLabelClassesStart, ignoreClassesHandler);
}
