import { takeEvery, delay, select, call, put } from 'typed-redux-saga';

import { apiLoadSplitObjectsNum } from '../../../../../../api/requests/modelPlayground';
import { getErrorMessage } from '../../../../../../api/utils';
import { activeProjectIdSelector } from '../../../../project/project.selectors';
import {
  loadNewSplitCounters,
  loadNewSplitCountersFailure,
  loadNewSplitCountersSuccess,
} from './newSplitCounters.slice';
import { newSplitCountersMapper } from '../splits.types';

function* loadHandler(action: ActionType<typeof loadNewSplitCounters>) {
  yield* delay(500);

  const projectId = yield* select(activeProjectIdSelector);
  const {
    id,
    datasetIds,
    imageStatuses,
    modelFamily,
    labelClassIds,
    tagClassIds,
    parentSplitId,
  } = action.payload;

  try {
    const { data } = yield* call(
      apiLoadSplitObjectsNum,
      {
        projectId,
      },
      {
        datasetIds,
        imageStatuses,
        modelFamily,
        labelClassIds,
        tagClassIds,
        parentSplitId,
      },
    );

    yield* put(
      loadNewSplitCountersSuccess({
        id,
        changes: {
          ...newSplitCountersMapper.fromBackend(data),
        },
      }),
    );
  } catch (e) {
    const message = getErrorMessage(e, 'Could not load split numbers');

    yield* put(
      loadNewSplitCountersFailure({
        id,
        changes: {
          message,
        },
      }),
    );
  }
}

export function* newSplitCountersSaga() {
  yield* takeEvery(loadNewSplitCounters, loadHandler);
}
