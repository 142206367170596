import { useParams } from 'react-router-dom';

export const useActiveProjectId = () => {
  const { projectId = '' } = useParams<{ projectId: string }>();

  return projectId;
};

export const useActiveWorkspaceId = () => {
  const { workspaceId = '' } = useParams<{ workspaceId: string }>();

  return workspaceId;
};
