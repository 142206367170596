import { call, put, takeEvery } from 'typed-redux-saga';

import { getErrorMessage } from '../../../../../api/utils';
import {
  apiLoadProjectRoles,
  apiCreateProjectRole,
  apiUpdateProjectRole,
  apiDeleteProjectRole,
} from '../../../../../api/requests/projectRoles';
import { hideModals } from '../../../ui/modals/modals.slice';
import { editedProjectRoleDataMapper } from '../../../../../api/domainModels/projectRoles';
import { enqueueNotification } from '../../../ui/stackNotifications/stackNotifications.slice';
import {
  createRole,
  createRoleFailure,
  createRoleSuccess,
  deleteRole,
  deleteRoleFailure,
  deleteRoleSuccess,
  loadRoles,
  loadRolesFailure,
  loadRolesSuccess,
  updateRole,
  updateRoleFailure,
  updateRoleSuccess,
} from './roles.slice';

function* loadRolesHandler(action: ActionType<typeof loadRoles>) {
  const { projectId } = action.payload;

  try {
    const response = yield* call(apiLoadProjectRoles, projectId);
    const payload = response.data.map(editedProjectRoleDataMapper.fromBackend);

    yield* put(loadRolesSuccess(payload));
  } catch (error) {
    yield* put(
      loadRolesFailure(
        getErrorMessage(error, 'Not able to fetch project roles'),
      ),
    );
  }
}

function* createRoleHandler(action: ActionType<typeof createRole>) {
  const { projectId, params } = action.payload;

  try {
    const response = yield* call(apiCreateProjectRole, projectId, params);
    const payload = editedProjectRoleDataMapper.fromBackend(response.data);

    yield* put(createRoleSuccess(payload));
    yield* put(hideModals());
  } catch (error) {
    yield* put(
      createRoleFailure(getErrorMessage(error, 'Not able to create a role')),
    );
  }
}

function* updateRoleHandler(action: ActionType<typeof updateRole>) {
  const { projectId, roleId, params } = action.payload;
  try {
    const response = yield* call(
      apiUpdateProjectRole,
      projectId,
      roleId,
      params,
    );
    const payload = editedProjectRoleDataMapper.fromBackend(response.data);

    yield* put(updateRoleSuccess(payload));
    yield* put(hideModals());
  } catch (error) {
    yield* put(
      updateRoleFailure(getErrorMessage(error, 'Not able to update the role')),
    );
  }
}

function* deleteRoleHandler(action: ActionType<typeof deleteRole>) {
  const { projectId, roleId } = action.payload;

  try {
    yield* call(apiDeleteProjectRole, projectId, roleId);
    yield* put(deleteRoleSuccess({ roleId }));
    yield* put(hideModals());
  } catch (error) {
    yield* put(
      deleteRoleFailure(getErrorMessage(error, 'Not able to delete the role')),
    );
    yield* put(
      enqueueNotification({
        message: getErrorMessage(error, 'Not able to delete the role'),
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
        },
      }),
    );
  }
}

export function* rolesSaga() {
  yield* takeEvery(loadRoles, loadRolesHandler);
  yield* takeEvery(createRole, createRoleHandler);
  yield* takeEvery(updateRole, updateRoleHandler);
  yield* takeEvery(deleteRole, deleteRoleHandler);
}
