import styled, { css } from 'styled-components';

const getPluralNoun = (noun: string, plural?: string) => plural || `${noun}s`;

export const pluralize = (
  count: number,
  noun: string,
  {
    plural,
    noForZero,
    firstCapital,
    showCountString = true,
  }: {
    plural?: string;
    firstCapital?: boolean;
    noForZero?: boolean;
    showCountString?: boolean;
  } = {},
) => {
  let countString = String(count);
  const suffix = count === 1 ? noun : getPluralNoun(noun, plural);

  if (count === 0 && noForZero) {
    countString = 'no';
  }

  const ret = showCountString ? [countString, suffix].join(' ') : suffix;

  if (firstCapital) {
    return ret[0].toUpperCase() + ret.slice(1);
  }

  return ret;
};

export const Shortener = styled.div<{
  maxWidth?: string;
  expandable?: string;
  unsetLineHeight?: boolean;
}>`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${(props) =>
    !props.unsetLineHeight &&
    css`
      line-height: 1.25;
    `}

  ${(props) =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth};
    `}

  ${(props) =>
    props.expandable !== 'false' &&
    css`
      &:active,
      &:hover {
        overflow-x: visible;
        & > span {
          position: relative;
          background: ${props.theme.palette.background.menu} !important;
          border: 1px solid ${props.theme.palette.neutral.main} !important;
          padding: 0 2px;
          margin-left: 4px;
        }
      }
    `}
`;
