import { call, select } from 'typed-redux-saga';
import compose from 'lodash/fp/compose';

import { getErrorMessage } from '../../../api/utils';
import { convertBillingValues } from './util';
import { PaymentMethodsInformation } from '../../../constants/paymentMethodsInformation';
import { EDIT_PAYMENT_METHOD_FORM_NAME } from '../../../constants/editPaymentMethodForm';
import { formSelector } from '../../state/root.selectors';
import { apiUpdatePaymentMethod } from '../../../api/requests/payment';
import { InjectedStripeProps } from '../../../constants/stripe';
import { chosenPaymentTypeSelector } from '../../state/commonFeatures/payments/payments.selectors';

// TODO: This should come from Stripe package, seems like bad typing inside of Stipe
type StripePaymentMethodType = 'card' | 'ideal' | 'sepa_debit';

function* generateStripePaymentMethodHandler(
  stripe: InjectedStripeProps['stripe'],
  elements: InjectedStripeProps['elements'],
  billingValues: Record<string, string>,
) {
  if (!elements || !stripe) return;

  const chosenPaymentMethodType = yield* select(chosenPaymentTypeSelector);

  if (!chosenPaymentMethodType) return;

  const stripeArguments: stripe.PaymentMethodData = {
    type: chosenPaymentMethodType,
    billing_details: convertBillingValues(billingValues),
  };

  const method = PaymentMethodsInformation.find(
    ({ id }) => id === chosenPaymentMethodType,
  );
  const elementKey = ((method && method.stripeElement) ||
    chosenPaymentMethodType) as stripe.elements.elementsType;

  if (chosenPaymentMethodType) {
    stripeArguments[chosenPaymentMethodType as StripePaymentMethodType] =
      elements.getElement(elementKey) || undefined;
  }
  // is working but doesn't pass type check
  const response: stripe.PaymentMethodResponse =
    yield stripe.createPaymentMethod(stripeArguments);

  if (response.error) {
    throw new Error(response.error.message);
  }
  if (!response.paymentMethod) {
    throw new Error("Stripe response didn't return payment method");
  }

  return response.paymentMethod.id;
}

export function* savePaymentOptionHandler(
  stripe: InjectedStripeProps['stripe'],
  elements: InjectedStripeProps['elements'],
  workspaceId: string,
) {
  const form = yield* select(
    compose((forms) => forms[EDIT_PAYMENT_METHOD_FORM_NAME], formSelector),
  );

  try {
    const paymentMethod = yield* call(
      generateStripePaymentMethodHandler,
      stripe,
      elements,
      form.values,
    );
    const response = yield* call(
      apiUpdatePaymentMethod,
      workspaceId,
      paymentMethod,
    );

    return response.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
}
