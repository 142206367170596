import {
  ApiKeypointClass,
  ApiKeypointConnection,
  ApiKeypointSchema,
  ApiKeypointSkeletonTemplate,
  ApiKeypointTemplate,
  ApiUpsertKeypointsSchemaFormValues,
  ApiUpsertKeypointClass,
} from '../schemas/keypoints';
import { KeypointsPoint } from './imageLabel';

export type KeypointClass = ApiKeypointClass;
export type UpsertKeypointClass = ApiUpsertKeypointClass;
export type KeypointSchema = ApiKeypointSchema;
export type KeypointTemplate = ApiKeypointTemplate;
export type KeypointConnection = ApiKeypointConnection;

export const keypointsSchemasDataMapper = {
  toBackend({
    createDate,
    createdBy,
    projectId,
    updateDate,
    updatedBy,
    ...schema
  }: Partial<ApiKeypointSchema>) {
    return {
      ...schema,
      keypointClasses: schema.keypointClasses?.map(
        ({
          createDate,
          createdBy,
          schemaId,
          updateDate,
          updatedBy,
          ...rest
        }) => ({
          ...rest,
        }),
      ),
    } as UpsertKeypointsSchemaFormValues;
  },
};

export const mapToSchemaSkeleton = ({
  id,
  keypointClassTemplateIdA,
  keypointClassTemplateIdB,
  color,
}: ApiKeypointSkeletonTemplate): KeypointConnection => ({
  id,
  keypointClassIdA: keypointClassTemplateIdA,
  keypointClassIdB: keypointClassTemplateIdB,
  color,
});

export type UpsertKeypointsSchemaFormValues =
  ApiUpsertKeypointsSchemaFormValues & {
    labelClasses: string[];
    isDefault: boolean;
  };

export type MinimalKeypoint = Pick<
  KeypointsPoint,
  'keypointClassId' | 'x' | 'y'
>;

export const mapToMinimalKeypoint = ({
  id,
  editorX,
  editorY,
}: KeypointClass): MinimalKeypoint => ({
  keypointClassId: id,
  x: editorX ?? 0,
  y: editorY ?? 0,
});
