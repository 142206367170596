export const PackageFeature = {
  ImageUpload: 'Image Hosting',
  VideoUpload: 'Video Hosting',
  ObjectDetection: 'Object Detection Assistant',
  InstanceSegmentation: 'Instance Segmentation Assistant',
  SemanticSegmentation: 'Semantic Segmentation Assistant',
  BoxToInstance: 'Box to Instance Assistant',
  ModelPlayground: 'Model Playground training',
  Attributer: 'Attribute Assistant',
  Aldi: 'Active Learning Rank On Demand',
  KeypointsDetection: 'Keypoints Detection Assistant',
};

export enum PackageName {
  Free = 'free',
  Parked = 'parked',
  Trial = 'trial',
  MonthlySmall = 'monthly_small',
  MonthlyMedium = 'monthly_medium',
  MonthlyLarge = 'monthly_large',
}

export const enterprisePlanName = 'enterprise' as const;
export const selfServicePlanName = 'selfService' as const;

const PACKAGE_NAMES_LIST = Object.values(PackageName);

const FREE_PACKAGES = [PackageName.Trial, PackageName.Free];

function packageIncludes<T extends PackageName[]>(
  packageNames: T,
  name: string | undefined,
): name is T[number] {
  return name ? packageNames.includes(name as T[number]) : false;
}

export const isValidPackage = (name: string | undefined): name is PackageName =>
  packageIncludes(PACKAGE_NAMES_LIST, name);

export const isFreePackage = (name: string | undefined) =>
  packageIncludes(FREE_PACKAGES, name);

export const isFreeOrParkedPackage = (name: string | undefined) =>
  packageIncludes([...FREE_PACKAGES, PackageName.Parked], name);
