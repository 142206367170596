import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PACKAGE_TYPE_SUBSCRIPTION } from './packageFeatures.constants';
import { LoadingState, loadingStateBuilder } from '../../../utils/loadingState';
import {
  Package,
  PackageWithPrice,
  Feature,
  Plan,
} from '../../../../api/domainModels/packageFeatures';
import { Price } from '../../../../api/domainModels/billing';

type MappedPrices = { [key: string]: number };

const mapPlans = ({
  packages,
  features,
  prices,
}: {
  packages: Package[];
  features: Feature[];
  prices: MappedPrices;
}) =>
  packages
    .filter((pkg) => pkg.type === PACKAGE_TYPE_SUBSCRIPTION)
    .map((pkg) => ({
      ...pkg,
      price: (prices[pkg.name] || 0) / 100,
      features: features
        .filter(({ id }) => pkg.featureIds.includes(id))
        .map(({ name }) => name),
    }));

const mapPrices = ({ plans }: { plans: Price[] }) => {
  const prices: MappedPrices = {};

  plans.reduce((acc, price) => {
    acc[price.name] = price.amount;

    return acc;
  }, prices);

  return prices;
};

type PackageFeaturesState = {
  packages: PackageWithPrice[];
  plans: Plan[];
  features: Feature[];
  loadingState: LoadingState;
};
const initialState: PackageFeaturesState = {
  packages: [],
  plans: [],
  features: [],
  loadingState: loadingStateBuilder.initial(),
};
const { actions, reducer: packageFeaturesReducer } = createSlice({
  name: 'packageFeatures',
  initialState,
  reducers: {
    loadPackageFeatures(state) {
      state.loadingState = loadingStateBuilder.inProgress(
        'Loading payment plans',
      );
    },
    loadPackageFeaturesFailure(state, action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.failure(action.payload);
    },
    loadPackageFeaturesSuccess(
      state,
      action: PayloadAction<{
        features: Feature[];
        packages: Package[];
        plans: Price[];
      }>,
    ) {
      const prices = mapPrices(action.payload);
      state.loadingState = loadingStateBuilder.success();
      state.plans = mapPlans({ ...action.payload, prices });
      state.features = action.payload.features;
    },
  },
});

export { packageFeaturesReducer };
export const {
  loadPackageFeatures,
  loadPackageFeaturesFailure,
  loadPackageFeaturesSuccess,
} = actions;
