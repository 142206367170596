import { createSelector } from '@reduxjs/toolkit';

import { imageViewSelector } from '../imageView.selectors';
import {
  runsListLoadingStateSelector,
  runsSelector,
} from '../../errorFinder/runs/runs.selectors';
import { statusChecks } from '../../../../../constants/status';
import { imageViewImageGalleryFiltersCSRunIdSelector } from '../imageGallery/bottomBar/filters/filters.selectors';
import { RunStatus } from '../../../../../api/domainModels/consensusScoring';
import { RunType } from '../../../../../api/constants/consensusScoring';

const consensusScoringSelector = createSelector(
  imageViewSelector,
  (imageView) => imageView.consensusScoring,
);
export const consensusScoringContextMenuSelector = createSelector(
  consensusScoringSelector,
  (consensusScoring) => consensusScoring.contextMenu,
);
export const consensusScoringEnabledSelector = createSelector(
  consensusScoringSelector,
  (consensusScoring) => consensusScoring.enabled,
);
export const consensusScoringActiveLabelSelector = createSelector(
  consensusScoringSelector,
  (consensusScoring) => consensusScoring.activeLabel,
);
export const consensusScoringRunsAsOptionsSelector = createSelector(
  [runsListLoadingStateSelector, runsSelector],
  (loadingState, runs) => {
    if (statusChecks.isSuccess(loadingState.status)) {
      return runs.map(({ id, name, status }) => ({
        key: id,
        text: name,
        value: id,
        disabled: status !== RunStatus.Done,
      }));
    }

    return [];
  },
);
export const consensusScoringRunTypeSelector = createSelector(
  [
    runsListLoadingStateSelector,
    runsSelector,
    imageViewImageGalleryFiltersCSRunIdSelector,
  ],
  (loadingState, runs, runId) => {
    if (statusChecks.isSuccess(loadingState.status)) {
      const result = runs.find((run) => run.id === runId);

      return result ? result.type : null;
    }

    return null;
  },
);
export const consensusScoringRunNameSelector = createSelector(
  [
    runsListLoadingStateSelector,
    runsSelector,
    imageViewImageGalleryFiltersCSRunIdSelector,
  ],
  (loadingState, runs, runId) => {
    if (statusChecks.isSuccess(loadingState.status)) {
      const result = runs.find((run) => run.id === runId);

      return result ? result.name : null;
    }

    return null;
  },
);
export const consensusScoringIsClassReviewRunTypeSelector = createSelector(
  consensusScoringRunTypeSelector,
  (runType) => runType === RunType.ClassReview,
);
export const consensusScoringIsOdIsReviewRunTypeSelector = createSelector(
  consensusScoringRunTypeSelector,
  (runType) =>
    runType === RunType.ObjectReview || runType === RunType.InstanceReview,
);

export const consensusScoringIsTagReviewRunTypeSelector = createSelector(
  consensusScoringRunTypeSelector,
  (runType) => runType === RunType.TagReview,
);

export const consensusScoringIsAttributeReviewRunTypeSelector = createSelector(
  consensusScoringRunTypeSelector,
  (runType) => runType === RunType.AttributeReview,
);

export const consensusScoringIsSemanticReviewRunTypeSelector = createSelector(
  consensusScoringRunTypeSelector,
  (runType) => runType === RunType.SemanticReview,
);
