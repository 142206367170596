import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  ErrorTagLabel,
  ErrorType,
} from '../../../../../api/domainModels/consensusScoring';
import { ErrorFinderAction } from '../../../../../api/constants/consensusScoring';

type TagReviewState = {
  data: {
    [key: string]: ErrorTagLabel;
  };
};

const initialState: TagReviewState = {
  data: {},
};

const { actions, reducer: tagReviewReducer } = createSlice({
  name: 'tagsReview',
  initialState,
  reducers: {
    loadErrorFinderTagResultsSuccess(
      state,
      action: PayloadAction<ErrorTagLabel[]>,
    ) {
      state.data = action.payload.reduce(
        (acc, item) => ({ ...acc, [item.id]: item }),
        {},
      );
    },
    removeExistingTagFromImage(
      _state,
      _action: PayloadAction<{
        imageId: string;
        tagGroupId: string;
        tagClassId: string;
      }>,
    ) {},
    removeExistingTagFromImageSuccess(
      state,
      action: PayloadAction<{
        imageId: string;
        tagGroupId: string;
        tagClassId: string;
      }>,
    ) {
      const { imageId, tagGroupId, tagClassId } = action.payload;

      delete state.data[imageId].items[tagGroupId].existingTags[tagClassId];
    },
    addNewTagToImage(
      _state,
      _action: PayloadAction<{
        imageId: string;
        tagGroupId: string;
        tagClassId: string;
        labeledBy: string;
      }>,
    ) {},
    addNewTagToImageSuccess(
      state,
      action: PayloadAction<{
        imageId: string;
        tagGroupId: string;
        tagGroupName: string;
        tagGroupType: string;
        id: string;
        tagClassId: string;
        tagClassName: string;
        labeledBy: string;
      }>,
    ) {
      const { imageId, tagGroupId, tagGroupName, tagGroupType, ...tag } =
        action.payload;

      state.data[imageId].items = {
        ...state.data[imageId].items,
        [tagGroupId]: {
          tagGroupId,
          tagGroupName,
          tagGroupType,
          errorTags: state.data[imageId].items[tagGroupId]?.errorTags || {},
          existingTags: {
            ...(state.data[imageId].items[tagGroupId]?.existingTags || {}),
            [tag.tagClassId]: tag,
          },
        },
      };
    },
    changeTagReviewErrorAction(
      _state,
      _action: PayloadAction<{
        imageId: string;
        runId: string | null;
        efAction: ErrorFinderAction;
        errorType: ErrorType;
        reviewedBy: string;
        tagGroupId: string;
        efTagId: string;
        curTagId?: string | null;
        predTagId?: string | null;
      }>,
    ) {},
    changeTagReviewErrorActionSuccess(
      state,
      action: PayloadAction<{
        runId: string;
        imageId: string;
        efAction: ErrorFinderAction;
        errorType: ErrorType;
        reviewedBy: string;
        tagGroupId: string;
        efTagId: string;
        curTagId?: string | null;
        predTagId?: string | null;
      }>,
    ) {
      const { imageId, efAction, tagGroupId, efTagId, reviewedBy } =
        action.payload;

      state.data[imageId].items[tagGroupId].errorTags[efTagId].efAction =
        efAction;
      state.data[imageId].items[tagGroupId].errorTags[efTagId].reviewedBy =
        reviewedBy;
    },
    changeAllTagReviewErrorActions(
      _state,
      _action: PayloadAction<{
        imageId: string;
        runId: string | null;
        efAction: ErrorFinderAction;
        reviewedBy: string;
      }>,
    ) {},
    changeAllTagReviewErrorActionsSuccess(
      state,
      action: PayloadAction<{
        imageId: string;
        runId: string | null;
        efAction: ErrorFinderAction;
        reviewedBy: string;
      }>,
    ) {
      const { imageId, efAction, reviewedBy } = action.payload;

      const tagImageItemsData = state.data[imageId].items;

      const tagImageItemsNewData = Object.keys(tagImageItemsData).reduce(
        (acc, tagItemKey) => {
          const { errorTags, ...rest } = tagImageItemsData[tagItemKey];

          const newErorTags = Object.keys(errorTags).reduce(
            (acc, errorTagKey) => {
              const errorTag = errorTags[errorTagKey];

              const errorTagEfAction = errorTag.efAction
                ? errorTag.efAction
                : efAction;

              return {
                ...acc,
                [errorTagKey]: {
                  ...errorTag,
                  efAction: errorTagEfAction,
                  reviewedBy,
                },
              };
            },
            {},
          );

          return {
            ...acc,
            [tagItemKey]: { ...rest, errorTags: newErorTags },
          };
        },
        {},
      );

      state.data[imageId].items = tagImageItemsNewData;
    },
    updateErrorTag(
      _state,
      _action: PayloadAction<{
        runId: string | null;
        imageId: string;
        tagGroupId: string;
        efTagId: string;
        curTagId: string | null;
        newTagClassId: string;
        currentUserId: string;
      }>,
    ) {},
  },
});

export { tagReviewReducer };
export const {
  loadErrorFinderTagResultsSuccess,
  changeTagReviewErrorAction,
  changeTagReviewErrorActionSuccess,
  changeAllTagReviewErrorActions,
  changeAllTagReviewErrorActionsSuccess,
  addNewTagToImage,
  addNewTagToImageSuccess,
  removeExistingTagFromImage,
  removeExistingTagFromImageSuccess,
  updateErrorTag,
} = actions;
