import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
  Update,
} from '@reduxjs/toolkit';

import { Attribute } from '../../../../../api/domainModels/attribute';
import { loadingStateBuilder } from '../../../../utils/loadingState';

export type UpdateAttributeFormValues = Pick<
  Attribute,
  'name' | 'type' | 'description' | 'values' | 'id'
> & { labelClasses: string[] };

export type AddAttributeFormValues = Pick<
  Attribute,
  'name' | 'type' | 'description' | 'values'
> & { labelClasses: string[] };

export const attributesAdapter = createEntityAdapter<Attribute>({
  sortComparer: (a, b) => (a.norder > b.norder ? 1 : -1),
});

const initialState = attributesAdapter.getInitialState({
  itemLoadingState: loadingStateBuilder.initial(),
  listLoadingState: loadingStateBuilder.initial(),
});

const { actions, reducer: attributesReducer } = createSlice({
  name: 'attributes',
  initialState,
  reducers: {
    addAttribute(state, _action: PayloadAction<AddAttributeFormValues>) {
      state.itemLoadingState = loadingStateBuilder.inProgress();
    },
    addAttributeFailure(state, action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.failure(action.payload);
    },
    addAttributeSuccess(state, action: PayloadAction<Attribute>) {
      state.itemLoadingState = loadingStateBuilder.success();
      attributesAdapter.addOne(state, action.payload);
    },
    updateAttribute(
      state,
      _action: PayloadAction<UpdateAttributeFormValues & { id: string }>,
    ) {
      state.itemLoadingState = loadingStateBuilder.inProgress();
    },

    updateAttributeFailure(state, action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.failure(action.payload);
    },
    updateAttributeOrder(
      _state,
      _action: PayloadAction<{
        id: string;
        index: number;
      }>,
    ) {},
    updateAttributeSuccess(state, action: PayloadAction<Update<Attribute>>) {
      state.itemLoadingState = loadingStateBuilder.success();
      attributesAdapter.updateOne(state, action.payload);
    },
    upsertAttributeFromWebsockets(state, action: PayloadAction<Attribute>) {
      attributesAdapter.upsertOne(state, action.payload);
    },
    removeAttribute(state, _action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.inProgress();
    },
    removeAttributeFailure(state, action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.failure(action.payload);
    },
    removeAttributeSuccess(state, action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.success();
      attributesAdapter.removeOne(state, action.payload);
    },
    removeAttributeFromWebsockets(state, action: PayloadAction<string>) {
      attributesAdapter.removeOne(state, action.payload);
    },
    loadAttributesStart(state) {
      state.listLoadingState = loadingStateBuilder.inProgress();
    },
    loadAttributesFailure(state, action: PayloadAction<string>) {
      state.listLoadingState = loadingStateBuilder.failure(action.payload);
      attributesAdapter.setAll(state, []);
    },
    loadAttributesSuccess(state, action: PayloadAction<Attribute[]>) {
      state.listLoadingState = loadingStateBuilder.success();
      attributesAdapter.setAll(state, action.payload);
    },
    resetAttributeLoadingState(state) {
      state.itemLoadingState = loadingStateBuilder.initial();
    },
  },
});

export { attributesReducer };
export const {
  addAttribute,
  addAttributeFailure,
  addAttributeSuccess,
  updateAttribute,
  updateAttributeFailure,
  updateAttributeSuccess,
  removeAttribute,
  removeAttributeFailure,
  removeAttributeSuccess,
  loadAttributesStart,
  loadAttributesFailure,
  loadAttributesSuccess,
  resetAttributeLoadingState,
  updateAttributeOrder,
  upsertAttributeFromWebsockets,
  removeAttributeFromWebsockets,
} = actions;
