import React from 'react';
import { Theme, CircularProgress, Box } from '@mui/material';
import { SxProps } from '@mui/system';

import { LoadingStatus } from '../../constants/status';
import { LoadingState } from '../../redux/utils/loadingState';
import { useDelayedProgressStatus } from '../../hooks/useDelayedProgressStatus';
import { ErrorIndicator, ErrorIndicatorProps } from './error/ErrorIndicator';

export type LoadingIndicatorProps = {
  message?: string;
  styleProps?: SxProps<Theme>;
};
export const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
  message,
  styleProps = {},
}) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <CircularProgress
      size={20}
      sx={{ mr: 0.5, maxWidth: 20, maxHeight: 20, ...styleProps }}
    />
    {message}
  </Box>
);

type SuspenseProps<RenderProps extends object> = {
  loadingState: LoadingState;
  render?: (props: RenderProps) => React.ReactNode | Element;
  errorComponent?: React.ComponentType<ErrorIndicatorProps>;
  loadingComponent?: React.ComponentType<LoadingIndicatorProps>;
  renderProps?: RenderProps;
  loadingStyleProps?: SxProps<Theme>;
  errorStyleProps?: SxProps<Theme>;
  ignoredStatuses?: LoadingStatus[];
  immediate?: boolean;
  hideInProgressMessage?: boolean;
  tiny?: boolean;
};

const DEFAULT_RENDER_PROPS = {};
const DEFAULT_LOADING_STYLE_PROPS = {};
const DEFAULT_ERROR_STYLE_PROPS = {};
const DEFAULT_IGNORE_STATUSES = [] as LoadingStatus[];

export const Suspense = <T extends object>({
  loadingState: { status: rawStatus, message },
  render,
  errorComponent: RenderedErrorComponent = ErrorIndicator,
  loadingComponent: RenderedLoadingComponent = LoadingIndicator,
  renderProps = DEFAULT_RENDER_PROPS as T,
  loadingStyleProps = DEFAULT_LOADING_STYLE_PROPS,
  errorStyleProps = DEFAULT_ERROR_STYLE_PROPS,
  ignoredStatuses = DEFAULT_IGNORE_STATUSES,
  immediate = false,
  hideInProgressMessage = false,
  tiny = false,
}: SuspenseProps<T>): React.ReactElement | null => {
  const delayedStatus = useDelayedProgressStatus(rawStatus);
  const status = immediate ? rawStatus : delayedStatus;
  const isStatusIgnored = ignoredStatuses.includes(status);

  if (isStatusIgnored) {
    return null;
  }

  switch (status) {
    case LoadingStatus.InProgress:
      return (
        <RenderedLoadingComponent
          message={hideInProgressMessage ? undefined : message}
          styleProps={loadingStyleProps}
        />
      );
    case LoadingStatus.Failure:
      return (
        <RenderedErrorComponent
          message={message}
          styleProps={errorStyleProps}
          tiny={tiny}
        />
      );
    case LoadingStatus.Success:
      if (render) {
        return <>{render(renderProps)}</>;
      }

      return null;

    default:
      return null;
  }
};
