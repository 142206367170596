import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  hotkeysVisible: false,
  filters: {
    brightness: 0,
    contrast: 0,
    invert: false,
    visible: false,
    applied: false,
  },
};

const { actions, reducer: uiReducer } = createSlice({
  name: 'imageView/ui',
  initialState,
  reducers: {
    toggleHotkeysVisible: (state) => {
      state.hotkeysVisible = !state.hotkeysVisible;
    },
    hideHotkeys: (state) => {
      state.hotkeysVisible = false;
    },
    setBrightness: (state, action: PayloadAction<{ brightness: number }>) => {
      state.filters.brightness = action.payload.brightness;
      state.filters.visible = true;
      state.filters.applied = true;
    },
    setContrast: (state, action: PayloadAction<{ contrast: number }>) => {
      state.filters.contrast = action.payload.contrast;
      state.filters.visible = true;
      state.filters.applied = true;
    },
    setInvert: (state, action: PayloadAction<{ invert: boolean }>) => {
      state.filters.invert = action.payload.invert;
      state.filters.visible = true;
      state.filters.applied = true;
    },
    resetFilters(state) {
      state.filters = initialState.filters;
    },
    toggleFiltersVisibility(state) {
      state.filters.visible = !state.filters.visible;
    },
  },
});
export const {
  toggleHotkeysVisible,
  hideHotkeys,
  setBrightness,
  setContrast,
  setInvert,
  resetFilters,
  toggleFiltersVisibility,
} = actions;
export { uiReducer };
