import { combineReducers } from 'redux';

import { splitsDataReducer } from './data/data.slice';
import { newSplitCountersReducer } from './newSplitCounters/newSplitCounters.slice';
import { strategiesReducer } from './strategies/strategies.slice';

export const splitsReducer = combineReducers({
  data: splitsDataReducer,
  counters: newSplitCountersReducer,
  strategies: strategiesReducer,
});
