import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type PaginationState = {
  page: number;
  itemsPerPage: number;
  total: number;
};
const initialState: PaginationState = {
  page: 1,
  itemsPerPage: 100,
  total: 0,
};

const { actions, reducer: runsPaginationReducer } = createSlice({
  name: 'runsPagination',
  initialState,
  reducers: {
    setRunsPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setRunsTotal(state, action: PayloadAction<number>) {
      state.total = action.payload;
    },
    setItemsPerPage(state, action: PayloadAction<number>) {
      state.page = 1;
      state.itemsPerPage = action.payload;
    },
  },
});

export { runsPaginationReducer };

export const { setRunsPage, setRunsTotal, setItemsPerPage } = actions;
