import axios from 'axios';

import {
  ApiCreateSegmentsParams,
  ApiDeleteSegmentParams,
  ApiLoadSegmentsParams,
  ApiLoadSegmentsResponse,
  ApiUpdateSegmentParams,
  ApiVideoSegment,
} from '../schemas/segment';
import { ErrorParams, recoilApiWrapper } from '../utils';
import { NEW_API_URL } from '../../constants/paths';
import { headers, transformRequest, transformResponse } from '../helpers';

export const apiLoadSegments = (
  { params }: ApiLoadSegmentsParams,
  errorParams: ErrorParams,
) =>
  recoilApiWrapper(
    axios.request<ApiLoadSegmentsResponse>({
      method: 'get',
      url: `${NEW_API_URL}/projects/${params.projectId}/videos/${params.videoId}/segments`,
      params: params.pagination,
      headers,
      transformRequest,
      transformResponse,
    }),
    errorParams,
  );

export const apiCreateSegment = (
  { params, data }: ApiCreateSegmentsParams,
  errorParams: ErrorParams,
) =>
  recoilApiWrapper(
    axios.request<ApiVideoSegment>({
      method: 'post',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/videos/${params.videoId}/segments?replace_overlap=${params.replaceOverlap}`,
      data,
    }),
    errorParams,
  );

export const apiUpdateSegment = (
  { params, data }: ApiUpdateSegmentParams,
  errorParams: ErrorParams,
) =>
  recoilApiWrapper(
    axios.request<ApiVideoSegment>({
      method: 'put',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/segments/${params.segmentId}`,
      data,
    }),
    errorParams,
  );

// DELETE /v1/projects/{project_id}/videos/{videoId}/segments/{segmentId}

export const apiDeleteSegment = (
  { params }: ApiDeleteSegmentParams,
  errorParams: ErrorParams,
) =>
  recoilApiWrapper(
    axios.request({
      method: 'delete',
      url: `${NEW_API_URL}/projects/${params.projectId}/segments/${params.id}`,
      headers,
      transformRequest,
      transformResponse,
    }),
    errorParams,
  );
