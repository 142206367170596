/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Split = {
  /**
   * Split ID
   */
  id?: string;
  /**
   * Name given to the split by user
   */
  name?: string;
  /**
   * Type of split (e.g. based on images or label type)
   */
  type?: string;
  /**
   * Method of splitting the data (e.g. random, stratified)
   */
  mode?: string;
  numFolds?: number;
  /**
   * Model family
   */
  modelFamily?: string;
  /**
   * Split kind (e.g. assistant, error_finder, custom)
   */
  kind?: string;
  /**
   * Whether to use unlabeled images
   */
  useUnlabeledImages?: boolean;
  creatorName?: string;
  /**
   * Job ID
   */
  jobId?: string;
  /**
   * Parent split ID
   */
  parentSplitId?: string | null;
  /**
   * Method for creating the nested split
   */
  nestingMethod?: Split.nestingMethod | null;
  createOn?: string;
  completedOn?: string | null;
  /**
   * Status of split creation job
   */
  status?: string;
  /**
   * Error message in case of failure
   */
  errorMessage?: string | null;
  /**
   * Number of experiments that use this split
   */
  numExperiments?: number;
  /**
   * Counts for each partition. Can be anything
   */
  counts?: Record<string, any> | null;
  /**
   * Additional information about splits to be displayed
   */
  displayInfo?: Record<string, any>;
  children?: Array<Record<string, any>> | null;
};

export namespace Split {

  /**
   * Method for creating the nested split
   */
  export enum nestingMethod {
    ADD_TRAINING_ONLY = 'add_training_only',
    ENRICH_ALL = 'enrich_all',
  }


}

