/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Type of error
 */
export enum TagsErrorTypeQuery {
  NOT_AN_ERROR = 'NOT_AN_ERROR',
  MISCLASSIFICATION = 'MISCLASSIFICATION',
  MISSING_TAG = 'MISSING_TAG',
  EXTRA_TAG = 'EXTRA_TAG',
}
