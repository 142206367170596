import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import {
  ExperimentTemplate,
  SaveAsTemplateFormValues,
} from '../../../../../../api/domainModels/modelPlayground';
import { loadingStateBuilder } from '../../../../../utils/loadingState';
import { hideModals } from '../../../../ui/modals/modals.slice';
import { setEditedProjectId } from '../../../../sections/editedProject/project/project.slice';
import { ModelFamily } from '../../../../../../api/constants/modelFamily';

export type TemplateFilters = {
  modelFamily?: ModelFamily | null;
  isForEf?: boolean;
};

export const adapter = createEntityAdapter<ExperimentTemplate>();

const initialState = adapter.getInitialState({
  listLoadingState: loadingStateBuilder.initial(),
  itemLoadingState: loadingStateBuilder.initial(),
});
const { actions, reducer: templatesReducer } = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    loadTemplates(state, _action: PayloadAction<TemplateFilters>) {
      state.listLoadingState = loadingStateBuilder.inProgress();
      adapter.removeAll(state);
    },
    loadTemplatesSuccess(state, action: PayloadAction<ExperimentTemplate[]>) {
      state.listLoadingState = loadingStateBuilder.success();
      adapter.setAll(state, action.payload);
    },
    loadTemplatesFailure(state, action: PayloadAction<string>) {
      state.listLoadingState = loadingStateBuilder.failure(action.payload);
    },
    addTemplate(
      state,
      _action: PayloadAction<
        SaveAsTemplateFormValues & { experimentId: string }
      >,
    ) {
      state.itemLoadingState = loadingStateBuilder.inProgress();
    },
    addTemplateSuccess(state, action: PayloadAction<ExperimentTemplate>) {
      state.itemLoadingState = loadingStateBuilder.success();
      adapter.addOne(state, action.payload);
    },
    addTemplateFailure(state, action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.failure(action.payload);
    },
    removeTemplate(state, _action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.inProgress();
    },
    removeTemplateSuccess(state, action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.success();
      adapter.removeOne(state, action.payload);
    },
    removeTemplateFailure(state, action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.failure(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(hideModals, (state) => {
      state.itemLoadingState = loadingStateBuilder.initial();
    });
    builder.addCase(setEditedProjectId, () => initialState);
  },
});

export { templatesReducer };
export const {
  loadTemplates,
  loadTemplatesSuccess,
  loadTemplatesFailure,
  addTemplate,
  addTemplateSuccess,
  addTemplateFailure,
  removeTemplate,
  removeTemplateSuccess,
  removeTemplateFailure,
} = actions;
