import { createSelector } from '@reduxjs/toolkit';

import { modelStatusChecks, ModelStatus } from './modelStatus.constants';
import { ImageTool } from '../tools.constants';
import { VideoTool } from '../../../videoView/tools/tools.constants';

export const MODEL_TOOLS = [
  ImageTool.ObjectDetection,
  ImageTool.Atom,
  ImageTool.ImageTagsPrediction,
  ImageTool.AttributesPrediction,
  ImageTool.SemanticSegmentation,
  ImageTool.InstanceSegmentation,
  ImageTool.BoxToInstance,
  ImageTool.KeypointsDetection,
  ImageTool.TextPrompt,
  VideoTool.SegmentPrediction,
];

export type ModelTool =
  | typeof ImageTool.ObjectDetection
  | typeof ImageTool.ImageTagsPrediction
  | typeof ImageTool.AttributesPrediction
  | typeof ImageTool.SemanticSegmentation
  | typeof ImageTool.InstanceSegmentation
  | typeof ImageTool.LabelClassification
  | typeof ImageTool.BoxToInstance
  | typeof ImageTool.Atom
  | typeof ImageTool.AutomatedLabeling
  | typeof ImageTool.KeypointsDetection
  | typeof ImageTool.TextPrompt
  | typeof VideoTool.SegmentPrediction;

export type ModelToolStatus = {
  available: boolean;
  loading: boolean;
  loaded: boolean;
  labeling: boolean;
  training: boolean;
  failed: boolean;
  queued: boolean;
  status: ModelStatus;
  progress: number;
  showDot: boolean;
};

export const modelsMapSelector = (state: RootState) => ({
  [ImageTool.ObjectDetection]: state.core.imageView.tools.objectDetection.model,
  [ImageTool.ImageTagsPrediction]:
    state.core.imageView.tools.imageTagsPrediction.model,
  [ImageTool.AttributesPrediction]:
    state.core.imageView.tools.labelAttributesPrediction.model,
  [ImageTool.SemanticSegmentation]:
    state.core.imageView.tools.semanticSegmentation.model,
  [ImageTool.InstanceSegmentation]:
    state.core.imageView.tools.instanceSegmentation.model,
  [ImageTool.LabelClassification]:
    state.core.imageView.tools.labelClassPrediction.model,
  [ImageTool.BoxToInstance]: state.core.imageView.tools.boxToInstance.model,
  [ImageTool.Atom]: state.core.imageView.tools.atom.model,
  [ImageTool.AutomatedLabeling]:
    state.core.imageView.tools.automatedLabeling.model,
  [ImageTool.KeypointsDetection]:
    state.core.imageView.tools.keypointsDetection.model,
  [ImageTool.TextPrompt]: state.core.imageView.tools.textPrompt.model,
  [VideoTool.SegmentPrediction]:
    state.core.videoView.tools.segmentPrediction.model,
});

const modelByToolIdSelector = (
  state: RootState,
  toolName: ModelTool | undefined,
) => {
  const modelsMap = modelsMapSelector(state);

  return toolName ? modelsMap[toolName] : null;
};

export const modelToolStatePropertiesSelector = createSelector(
  modelByToolIdSelector,
  (model) => {
    if (!model) return null;

    const available = typeof model.id === 'number';
    const loading = model.status
      ? modelStatusChecks.isLoading(model.status)
      : null;
    const loaded = model.status
      ? modelStatusChecks.isLoaded(model.status)
      : null;
    const labeling = model.status
      ? modelStatusChecks.isLabeling(model.status)
      : null;
    const training = model.status
      ? modelStatusChecks.isTraining(model.status)
      : null;
    const failed = model.status
      ? modelStatusChecks.isFailed(model.status)
      : null;
    const queued = model.status
      ? modelStatusChecks.isQueued(model.status)
      : null;
    const status = model?.status;
    const progress = model?.progress ? model.progress * 100 : 0;
    const showDot = model?.showDot ? model.showDot : false;

    return {
      available,
      loading,
      loaded,
      labeling,
      training,
      failed,
      status,
      progress,
      showDot,
      queued,
    } as ModelToolStatus;
  },
);
