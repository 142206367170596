import {
  createEntityAdapter,
  createSlice,
  EntityId,
  PayloadAction,
} from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { WidgetRunningTime } from '../../../../../../../api/domainModels/widgets';
import { Experiment } from '../../../../../../../api/domainModels/modelPlayground';
import { loadingStateBuilder } from '../../../../../../utils/loadingState';
import { setEditedProjectId } from '../../../../../sections/editedProject/project/project.slice';

export const adapter = createEntityAdapter<WidgetRunningTime>({
  selectId: (item) => item.experimentId,
  sortComparer: (a, b) => (dayjs(a.createdAt).isAfter(b.createdAt) ? -1 : 1),
});
const initialState = adapter.getInitialState({
  loadingState: loadingStateBuilder.initial(),
});

const { actions, reducer: runningTimeReducer } = createSlice({
  name: 'widgets/runningTime',
  initialState,
  reducers: {
    loadRunningTime(state, _action: PayloadAction<Experiment['id'][]>) {
      state.loadingState = loadingStateBuilder.inProgress();
    },
    loadRunTimeFailure(state, action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.failure(action.payload);
      adapter.setAll(state, []);
    },
    loadRunTimeSuccess(state, action: PayloadAction<WidgetRunningTime[]>) {
      state.loadingState = loadingStateBuilder.success();

      adapter.setAll(state, action.payload);
    },
    updateMetricsColor(
      state,
      action: PayloadAction<{ id: EntityId; color: string }>,
    ) {
      const { id, color } = action.payload;
      adapter.updateOne(state, { changes: { color }, id });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setEditedProjectId, () => initialState);
  },
});

export { runningTimeReducer };
export const {
  loadRunningTime,
  loadRunTimeSuccess,
  loadRunTimeFailure,
  updateMetricsColor,
} = actions;
