import { call, put, select, takeEvery } from 'typed-redux-saga';
import { push } from 'connected-react-router';

import {
  setEditedProjectId,
  resetEditedProjectId,
  loadProject,
  loadProjectFailure,
  loadProjectSuccess,
  initImageProject,
  initVideoProject,
  goToAutomatedLabeling,
} from './project/project.slice';
import { apiLoadProject } from '../../../../api/requests/project';
import { getErrorMessage } from '../../../../api/utils';
import { imageUploadSaga } from './imageUpload/imageUpload.saga';
import { rolesSaga } from './roles/roles.sagas';
import { privilegesSaga } from './roles/privileges.sagas';
import { editedProjectCollaboratorsSaga } from './collaborators/collaborators.saga';
import { loadPackageFeatures } from '../../commonFeatures/packageFeatures/packageFeatures.slice';
import { subscribeSubject, unsubscribeSubject } from '../../ws/ws.actions';
import { activeProjectIdSelector } from '../../project/project.selectors';
import { handleError } from '../../commonFeatures/errorHandler/errorHandler.actions';
import { videoUploadSaga } from './videoUpload/videoUpload.saga';
import { frameImportsSaga } from './frameImports/frameImports.saga';
import { webhookEventsSaga } from './webhooks/webhookEvents.saga';
import { webhookLogsSaga } from './webhooks/webhookLogs.saga';
import { webhooksSaga } from './webhooks/webhooks.saga';
import { importsSagas } from './imports/imports.sagas';
import {
  EDIT_IMAGE_PROJECT,
  EDIT_VIDEO_PROJECT,
} from '../../ws/ws.eventsLists';
import { triggersSaga } from './triggers/triggers.saga';
import { convertToImagesSaga } from './convertToImages/convertToImages.saga';
import { exportSessionsSaga } from './exportSessions/exportSessions.saga';
import { setWorkspaceId } from '../../commonFeatures/workspaceId/workspaceId.slice';
import { advancedOptionsSaga } from './advancedOptions/advancedOptions.saga';
import { projectSaga } from './project/project.saga';
import { Project1 } from '../../../../api/codegen/models/Project1';

function* loadProjectHandler(action: ActionType<typeof loadProject>) {
  const { id, overrideEventsList } = action.payload;
  try {
    const response = yield* call(apiLoadProject, { projectId: id });
    const project = response.data;

    if (!project) {
      throw new Error('Project not found');
    }

    const isImagesProject = project.contentType === Project1.contentType.IMAGES;
    const isVideosProject = project.contentType === Project1.contentType.VIDEOS;

    if (isImagesProject) yield* put(initImageProject({ id }));
    if (isVideosProject) yield* put(initVideoProject({ id }));

    yield* put(loadProjectSuccess(project));
    yield* put(setWorkspaceId({ workspaceId: project.workspaceId }));

    // forward just specific events or image/video default as fallback
    const eventsList =
      overrideEventsList ||
      (isImagesProject ? EDIT_IMAGE_PROJECT : EDIT_VIDEO_PROJECT);

    yield* put(
      subscribeSubject({
        identifier: 'project',
        id,
        authKey: project.wsAuthKey,
        eventsList,
      }),
    );

    return loadProjectSuccess.type;
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to fetch project');

    yield* put(loadProjectFailure(message));
    yield* put(handleError({ message, error, allowOutsideOfEditor: true }));

    return loadProjectFailure.type;
  }
}

function* setEditedProjectIdHandler({
  payload,
}: ActionType<typeof setEditedProjectId>) {
  const { id, overrideEventsList } = payload;
  if (id) {
    const projectLoadingStatus = yield* loadProjectHandler(
      loadProject({ id, overrideEventsList }),
    );
    if (projectLoadingStatus === loadProjectSuccess.type) {
      yield* put(loadPackageFeatures());
    }
  }
}

function* resetEditedProjectIdHandler() {
  yield* put(
    unsubscribeSubject({
      identifier: 'project',
    }),
  );
}

function* goToAutomatedLabelingHandler() {
  const projectId = yield* select(activeProjectIdSelector);
  yield* put(push(`/projects/${projectId}/edit/automated-labeling`));
}

function* editedProjectSaga() {
  yield* takeEvery(setEditedProjectId, setEditedProjectIdHandler);
  yield* takeEvery(loadProject, loadProjectHandler);
  yield* takeEvery(resetEditedProjectId, resetEditedProjectIdHandler);
  yield* takeEvery(goToAutomatedLabeling, goToAutomatedLabelingHandler);
}

export const editedProjectSagas = [
  imageUploadSaga,
  rolesSaga,
  privilegesSaga,
  editedProjectCollaboratorsSaga,
  videoUploadSaga,
  webhooksSaga,
  webhookEventsSaga,
  webhookLogsSaga,
  frameImportsSaga,
  editedProjectSaga,
  triggersSaga,
  convertToImagesSaga,
  exportSessionsSaga,
  advancedOptionsSaga,
  projectSaga,
  ...importsSagas,
];
