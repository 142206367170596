import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
  Update,
} from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { loadingStateBuilder } from '../../../../utils/loadingState';
import { ErrorFinderRun } from '../../../../../api/domainModels/consensusScoring';

export type ErrorFinderRunFormValues = Pick<
  ErrorFinderRun,
  | 'name'
  | 'type'
  | 'datasetsIds'
  | 'labelClassIds'
  | 'imageStatus'
  | 'previewPcnt'
  | 'startDatetime'
  | 'endDatetime'
> & {
  needsRetrain: boolean;
  previewMode: boolean;
  templateId: string | null;
  dateRange?: [Date, Date];
};

export type ErrorFinderRunFormValuesInner = Omit<
  ErrorFinderRunFormValues,
  'dateRange'
> & {
  dateRange?: string[];
};

export type EnrichedErrorFinderRun = ErrorFinderRun & {
  progress?: number;
  eta?: number;
  training?: boolean;
};
export const adapter = createEntityAdapter<EnrichedErrorFinderRun>({
  sortComparer: (a, b) => (dayjs(a.createdOn).isAfter(b.createdOn) ? -1 : 1),
});

const { actions, reducer: errorFinderRunsReducer } = createSlice({
  name: 'errorFinderRuns',
  initialState: adapter.getInitialState({
    listLoadingState: loadingStateBuilder.initial(),
    itemLoadingState: loadingStateBuilder.initial(),
  }),
  reducers: {
    resetListLoadingState(state) {
      state.listLoadingState = loadingStateBuilder.initial();
    },
    resetItemLoadingState(state) {
      state.itemLoadingState = loadingStateBuilder.initial();
    },
    loadRuns(
      state,
      _action: PayloadAction<
        | {
            itemsPerPage?: number;
            page?: number;
          }
        | undefined
      >,
    ) {
      state.listLoadingState = loadingStateBuilder.inProgress();
      adapter.removeAll(state);
    },
    loadRunsSuccess(state, action: PayloadAction<ErrorFinderRun[]>) {
      state.listLoadingState = loadingStateBuilder.success();
      adapter.setAll(state, action.payload);
    },
    loadRunsFailure(state, action: PayloadAction<string>) {
      state.listLoadingState = loadingStateBuilder.failure(action.payload);
      adapter.setAll(state, []);
    },
    loadRun(
      state,
      _action: PayloadAction<{ id: string; persistRunType?: boolean }>,
    ) {
      state.listLoadingState = loadingStateBuilder.inProgress();
    },
    loadRunSuccess(state, action: PayloadAction<ErrorFinderRun>) {
      state.listLoadingState = loadingStateBuilder.success();
      adapter.upsertOne(state, action.payload);
    },
    loadRunFailure(state, action: PayloadAction<string>) {
      state.listLoadingState = loadingStateBuilder.failure(action.payload);
    },
    createRun(state, _action: PayloadAction<ErrorFinderRunFormValues>) {
      state.itemLoadingState = loadingStateBuilder.inProgress();
    },
    createRunSuccess(state, action: PayloadAction<ErrorFinderRun>) {
      state.itemLoadingState = loadingStateBuilder.success();
      adapter.addOne(state, action.payload);
    },
    createRunFailure(state, action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.failure(action.payload);
    },
    removeRun(state, _action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.inProgress();
    },
    removeRunFailure(state, action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.failure(action.payload);
    },
    removeRunSuccess(state, action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.success();
      adapter.removeOne(state, action.payload);
    },
    updateRunSuccess(
      state,
      action: PayloadAction<Update<EnrichedErrorFinderRun>>,
    ) {
      adapter.updateOne(state, action.payload);
    },
  },
});

export { errorFinderRunsReducer };
export const {
  resetListLoadingState,
  resetItemLoadingState,
  loadRuns,
  loadRunsSuccess,
  loadRunsFailure,
  loadRun,
  loadRunSuccess,
  loadRunFailure,
  createRun,
  createRunSuccess,
  createRunFailure,
  removeRun,
  removeRunFailure,
  removeRunSuccess,
  updateRunSuccess,
} = actions;
