import { createSelector } from '@reduxjs/toolkit';

import { attributesAdapter } from './attributes.slice';
import { AttributeType } from '../../../../../api/constants/attribute';
import { Attribute } from '../../../../../api/domainModels/attribute';

const attributesStateSelector = (state: RootState) =>
  state.project.annotationTaxonomy.attributes;
const selectors = attributesAdapter.getSelectors(attributesStateSelector);

export const attributesSelector = selectors.selectAll;
export const attributeByIdSelector = selectors.selectById;
export const attributesMapSelector = selectors.selectEntities;
export const attributesTotalSelector = selectors.selectTotal;

export const attributesListLoadingStateSelector = createSelector(
  attributesStateSelector,
  (state) => state.listLoadingState,
);
export const attributesItemLoadingStateSelector = createSelector(
  attributesStateSelector,
  (state) => state.itemLoadingState,
);

export const attributesAsOptionsSelector = createSelector(
  attributesSelector,
  (attributes) => {
    const filteredAttrs = attributes.filter(
      ({ type }) =>
        type === AttributeType.Multi ||
        type === AttributeType.Single ||
        type === AttributeType.Boolean,
    );
    const options = filteredAttrs.map(({ id, name }) => ({
      key: id,
      value: id as string | null,
      text: name,
    }));

    const all = {
      text: 'All',
      key: 'all',
      value: null,
    };
    options.unshift(all);

    return options;
  },
);

export const getAttributeValuesAsOptions = (attribute?: Attribute) => {
  if (!attribute) return [];

  const values =
    attribute.type === AttributeType.Boolean
      ? [
          { name: 'True', id: 'true' },
          { name: 'False', id: 'false' },
        ]
      : attribute.values;

  return values?.map(({ id, name }) => ({
    key: id,
    value: id,
    text: name,
  }));
};
