import { VideoFilters } from '../../api/domainModels/video';

export const UPSERT_SEGMENT_STACK_KEY = 'video/upsertSegmentTask';
export const DELETE_SEGMENT_STACK_KEY = 'video/deleteSegmentTask';

export const UPSERT_ACTIVITY_STACK_KEY = 'video/upsertActivityTask';
export const DELETE_ACTIVITY_STACK_KEY = 'video/deleteActivityTask';

export const UPDATE_VIDEO_STACK_KEY = 'video/updateVideo';

export const REFETCH_SEGMENT_PREDICTIONS_STACK_KEY =
  'video/segmentPredictions/refetch';

export const UPDATE_SEGMENT_PREDICTION_JOB_STACK_KEY =
  'video/segmentPredictionJob/action';

export const ACCEPT_SEGMENT_PREDICTION_STACK_KEY =
  'video/segmentPredictions/prediction/accept';
export const REJECT_SEGMENT_PREDICTION_STACK_KEY =
  'video/segmentPredictions/prediction/reject';

export enum Panel {
  Annotation = 'annotation',
}

export const GALLERY_DRAWER_HEIGHT = 190;

export const INITIAL_VOLUME = 0.5;
export const INITIAL_VIDEO_FILTERS: VideoFilters = {
  videoName: '',
};

export const INITIAL_PLAYBACK_SPEED = 1;
export const INITIAL_SEEK_BY_TIME_IN_SEC = 1; // in secs
export const TRACK_WIDTH_PROVIDER_CLASS_NAME =
  'hasty-video-track-width-provider';

export const VIDEO_TRACK_ACTIVITIES_WIDTH = 100;

export const VIDEO_SINGLE_ACTIVITY_TRACK_HEIGHT = 20;

export enum zIndexPosition {
  Low = 1,
  MediumLow = 2,
  Medium = 3,
  MediumHigh = 4,
  High = 5,
  Top = 10,
}
