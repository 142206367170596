import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  WorkspaceCollaborator,
  WorkspaceCollaboratorParams,
} from '../../../../../api/domainModels/workspaceCollaborator';
import {
  LoadingState,
  loadingStateBuilder,
} from '../../../../utils/loadingState';
import { resetManagedWorkspaceId } from '../../../commonFeatures/workspaceId/workspaceId.slice';

type CollaboratorsState = {
  loadingState: LoadingState;
  updateLoadingState: LoadingState;
  actionLoadingState: LoadingState;
  data: WorkspaceCollaborator[];
};

const initialState: CollaboratorsState = {
  loadingState: loadingStateBuilder.initial(),
  updateLoadingState: loadingStateBuilder.initial(),
  actionLoadingState: loadingStateBuilder.initial(),
  data: [],
};

const { actions, reducer: collaboratorsReducer } = createSlice({
  name: 'managedWorkspace/collaborators',
  initialState,
  reducers: {
    loadWorkspaceCollaborators(state, _action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.inProgress(
        'Loading Collaborators',
      );
    },
    loadWorkspaceCollaboratorsSuccess(
      state,
      action: PayloadAction<WorkspaceCollaborator[]>,
    ) {
      state.loadingState = loadingStateBuilder.success();
      state.data = action.payload;
    },
    loadWorkspaceCollaboratorsFailure(state, action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.failure(action.payload);
    },
    addWorkspaceCollaborator(
      state,
      _action: PayloadAction<{
        workspaceId: string;
        params: WorkspaceCollaboratorParams;
      }>,
    ) {
      state.actionLoadingState = loadingStateBuilder.inProgress(
        'Adding Collaborator',
      );
    },
    addWorkspaceCollaboratorSuccess(state, _action: PayloadAction<string>) {
      state.actionLoadingState = loadingStateBuilder.success();
    },
    addWorkspaceCollaboratorFailure(state, action: PayloadAction<string>) {
      state.actionLoadingState = loadingStateBuilder.failure(action.payload);
    },
    updateWorkspaceCollaborator(
      state,
      _action: PayloadAction<{
        workspaceId: string;
        userId: string;
        params: WorkspaceCollaboratorParams;
      }>,
    ) {
      state.updateLoadingState = loadingStateBuilder.inProgress(
        'Updating Collaborator',
      );
    },
    updateWorkspaceCollaboratorSuccess(state, _action: PayloadAction<string>) {
      state.updateLoadingState = loadingStateBuilder.success();
    },
    updateWorkspaceCollaboratorFailure(state, action: PayloadAction<string>) {
      state.updateLoadingState = loadingStateBuilder.failure(action.payload);
    },
    deleteWorkspaceCollaborator(
      state,
      _action: PayloadAction<{ workspaceId: string; userId: string }>,
    ) {
      state.actionLoadingState = loadingStateBuilder.inProgress(
        'Deleting Collaborator',
      );
    },
    deleteWorkspaceCollaboratorSuccess(state, _action: PayloadAction<string>) {
      state.actionLoadingState = loadingStateBuilder.success();
    },
    deleteWorkspaceCollaboratorFailure(state, action: PayloadAction<string>) {
      state.actionLoadingState = loadingStateBuilder.failure(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetManagedWorkspaceId, () => initialState);
  },
});

export const {
  loadWorkspaceCollaborators,
  loadWorkspaceCollaboratorsFailure,
  loadWorkspaceCollaboratorsSuccess,
  addWorkspaceCollaborator,
  addWorkspaceCollaboratorFailure,
  addWorkspaceCollaboratorSuccess,
  updateWorkspaceCollaborator,
  updateWorkspaceCollaboratorFailure,
  updateWorkspaceCollaboratorSuccess,
  deleteWorkspaceCollaborator,
  deleteWorkspaceCollaboratorFailure,
  deleteWorkspaceCollaboratorSuccess,
} = actions;

export { collaboratorsReducer };
