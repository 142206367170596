import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Badge,
  Box,
  Button,
  badgeClasses,
  collapseClasses,
} from '@mui/material';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import { useDispatch, useSelector } from 'react-redux';
import last from 'lodash/last';

import {
  stackNotificationsActiveDataSelector,
  stackNotificationsExpandedSelector,
} from '../../redux/state/ui/stackNotifications/stackNotifications.selectors';
import {
  closeNotification,
  collapseContainer,
  expandContainer,
} from '../../redux/state/ui/stackNotifications/stackNotifications.slice';
import {
  DEFAULT_HIDE_TIME,
  DEFAULT_NOTIFICATION_PADDING,
  MOUSE_LEAVE_HIDE_TIME,
  SNACKBAR_CONTAINER_CLASSNAME,
  STACK_NOTIFICATIONS_CONTAINER_ID,
  STACK_NOTIFICATION_WIDTH,
} from '../../constants/stackNotifications';

export const StackNotificationsContainer = () => {
  const dispatch = useDispatch();
  const timeoutRef = useRef<number | undefined>();
  const containerRef = useRef();
  const [mouseIn, setMouseIn] = useState(false);
  const [lastItemHeight, setLastItemHeight] = useState(0);

  const expanded = useSelector(stackNotificationsExpandedSelector);
  const notifications = useSelector(stackNotificationsActiveDataSelector);

  const handleMouseEnter = useCallback(() => {
    clearTimeout(timeoutRef.current);
    dispatch(expandContainer());
    setMouseIn(true);
  }, [dispatch]);

  const handleMouseLeave = useCallback(() => {
    timeoutRef.current = setTimeout(() => {
      dispatch(collapseContainer());
    }, MOUSE_LEAVE_HIDE_TIME);
    setMouseIn(false);
  }, [dispatch]);

  const adjustNodesVisibility = useCallback(
    (notificationNodes: HTMLElement[]) => {
      if (expanded) {
        notificationNodes.forEach((node) => {
          node.style.height = 'unset';
          node.style.overflow = 'unset';
        });
      } else {
        notificationNodes.forEach((node) => {
          node.style.height = '0px';
          node.style.overflow = 'hidden';
        });
      }
    },
    [expanded],
  );

  const mutationCallback: MutationCallback = useCallback(
    (mutationList) => {
      // Use traditional 'for loops' for IE 11
      for (const mutation of mutationList) {
        if (mutation.type === 'childList') {
          if (
            (mutation.addedNodes?.[0] as HTMLElement)?.classList?.contains(
              SNACKBAR_CONTAINER_CLASSNAME,
            )
          ) {
            mutation.target.addEventListener('mouseenter', handleMouseEnter);
            mutation.target.addEventListener('mouseleave', handleMouseLeave);
          } else if (
            (mutation.target as HTMLElement).classList.contains(
              SNACKBAR_CONTAINER_CLASSNAME,
            )
          ) {
            const notificationNodes = Array.from(
              (mutation.target as HTMLElement).children,
            ) as HTMLElement[];
            const lastNode = last(notificationNodes);

            if (lastNode) {
              setLastItemHeight(
                lastNode.clientHeight - DEFAULT_NOTIFICATION_PADDING,
              );
            }

            if (!expanded) {
              adjustNodesVisibility(notificationNodes.slice(0, -1));
            }

            clearTimeout(timeoutRef.current);
            timeoutRef.current = setTimeout(() => {
              dispatch(collapseContainer());
            }, DEFAULT_HIDE_TIME);
          }
        }
      }
    },
    [
      adjustNodesVisibility,
      dispatch,
      expanded,
      handleMouseEnter,
      handleMouseLeave,
    ],
  );

  useEffect(
    () => () => {
      const notificationsContainer = document.querySelector(
        `.${SNACKBAR_CONTAINER_CLASSNAME}`,
      );
      if (notificationsContainer) {
        notificationsContainer.removeEventListener(
          'mouseenter',
          handleMouseEnter,
        );
        notificationsContainer.removeEventListener(
          'mouseleave',
          handleMouseLeave,
        );
      }
    },
    [handleMouseEnter, handleMouseLeave],
  );

  useEffect(() => {
    const targetNode = containerRef.current;

    if (!containerRef.current) return;

    const config = { childList: true, subtree: true };
    const observer = new MutationObserver(mutationCallback);

    if (targetNode) {
      observer.observe(targetNode, config);
    }

    return () => {
      observer.disconnect();
    };
  }, [
    adjustNodesVisibility,
    dispatch,
    expanded,
    handleMouseEnter,
    handleMouseLeave,
    mouseIn,
    mutationCallback,
  ]);

  useEffect(() => {
    const notificationsContainer = document.querySelector(
      `.${SNACKBAR_CONTAINER_CLASSNAME}`,
    );

    if (!notificationsContainer) {
      return;
    }

    const notificationNodes = Array.from(notificationsContainer.children).slice(
      0,
      -1,
    ) as HTMLElement[];

    adjustNodesVisibility(notificationNodes);
  }, [adjustNodesVisibility, expanded]);

  const notificationsCount =
    notifications.length > 1 && !expanded
      ? `+${notifications.length - 1}`
      : undefined;
  const showClearAll = notifications.length > 1 && !expanded;

  return (
    <Box sx={{ position: 'absolute', left: 20, bottom: 20 }}>
      {showClearAll && (
        <Button
          sx={{
            position: 'absolute',
            top: -15,
            left: STACK_NOTIFICATION_WIDTH - 16,
            zIndex: 1500,
            borderRadius: '50%',
            p: 1,
            minWidth: 0,
            m: 0,
          }}
          onClick={() => {
            dispatch(closeNotification({ dismissAll: true }));
          }}
        >
          <ClearAllIcon />
        </Button>
      )}
      <Badge
        badgeContent={notificationsCount}
        color="primary"
        sx={{
          height: lastItemHeight,
          [`.${badgeClasses.badge}`]: { zIndex: 1500 },
        }}
      >
        <Box
          ref={containerRef}
          id={STACK_NOTIFICATIONS_CONTAINER_ID}
          sx={{
            [`.${collapseClasses.wrapper}`]: { padding: '2px 0!important' },
          }}
        />
      </Badge>
    </Box>
  );
};
