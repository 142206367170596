import { call, put, takeEvery } from 'typed-redux-saga';

import { transformResponse } from '../../../../../api/helpers';
import { getErrorMessage } from '../../../../../api/utils';
import { apiLoadWorkspaceCreditBalance } from '../../../../../api/requests/credits';
import {
  loadWorkspaceCredits,
  loadWorkspaceCreditSuccess,
  loadWorkspaceCreditFailure,
} from './credits.slice';

export function* loadWorkspaceCreditsHandler(
  action: ActionType<typeof loadWorkspaceCredits>,
) {
  try {
    const { data } = yield* call(apiLoadWorkspaceCreditBalance, action.payload);

    const { creditsLeft, creditsUsed } = transformResponse(
      JSON.stringify(data),
    );

    yield* put(loadWorkspaceCreditSuccess({ creditsLeft, creditsUsed }));
  } catch (error) {
    yield* put(
      loadWorkspaceCreditFailure({
        message: getErrorMessage(error, 'Not able to load credits'),
      }),
    );
  }
}

export function* creditsSaga() {
  yield* takeEvery(loadWorkspaceCredits, loadWorkspaceCreditsHandler);
}
