import { createSelector } from '@reduxjs/toolkit';

import { imageViewSelector } from '../../imageView.selectors';
import { modelStatusChecks } from '../models/modelStatus.constants';
import { adapter } from './instanceSegmentation.slice';

const instanceSegmentationSelector = createSelector(
  imageViewSelector,
  (imageView) => imageView.tools.instanceSegmentation,
);
const instanceSegmentationDataSelector = createSelector(
  instanceSegmentationSelector,
  (instanceSegmentation) => instanceSegmentation.data,
);
const instanceSegmentationDataSelectors = adapter.getSelectors(
  instanceSegmentationDataSelector,
);

export const instanceSegmentationPredictedDataSelector =
  instanceSegmentationDataSelectors.selectAll;

export const instanceSegmentationPredictedDataByIdSelector =
  instanceSegmentationDataSelectors.selectById;

export const instanceSegmentationPredictedDataCountSelector =
  instanceSegmentationDataSelectors.selectTotal;

export const instanceSegmentationLoadingStateSelector = createSelector(
  instanceSegmentationSelector,
  (instanceSegmentation) => instanceSegmentation.data.loadingState,
);

export const instanceSegmentationModelSelector = createSelector(
  instanceSegmentationSelector,
  (instanceSegmentation) => instanceSegmentation.model,
);

export const instanceSegmentationConfidenceSelector = createSelector(
  instanceSegmentationSelector,
  (instanceSegmentation) => instanceSegmentation.confidence,
);

export const instanceSegmentationMaskerThresholdSelector = createSelector(
  instanceSegmentationSelector,
  (instanceSegmentation) => instanceSegmentation.maskerThreshold,
);

export const instanceSegmentationMaxDetectionsSelector = createSelector(
  instanceSegmentationSelector,
  (instanceSegmentation) => instanceSegmentation.maxDetections,
);

export const instanceSegmentationModelIdSelector = createSelector(
  instanceSegmentationModelSelector,
  (model) => model.id,
);

export const instanceSegmentationModelStatusSelector = createSelector(
  instanceSegmentationModelSelector,
  (model) => model.status,
);

export const instanceSegmentationModelAvailableSelector = createSelector(
  instanceSegmentationModelIdSelector,
  (modelId) => typeof modelId === 'number',
);

export const instanceSegmentationModelLoadedSelector = createSelector(
  [
    instanceSegmentationModelAvailableSelector,
    instanceSegmentationModelStatusSelector,
  ],
  (available, status) =>
    !!(available && status && modelStatusChecks.isLoaded(status)),
);

export const instanceSegmentationUseSAMSelector = createSelector(
  instanceSegmentationSelector,
  (instanceSegmentation) => instanceSegmentation.useSAM,
);
