import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const { actions, reducer: labelClassesReducer } = createSlice({
  name: 'imageViewLabelClasses',
  initialState: {
    activeLabelClassId: null as string | null,
    hoveredLabelClassId: null as string | null,
  },
  reducers: {
    selectActiveLabelClassId(
      state,
      action: PayloadAction<{ id: string; type: string }>,
    ) {
      state.activeLabelClassId = action.payload.id;
    },
    selectNextLabelClass() {},
    selectPreviousLabelClass() {},
    setLabelClassToSelectedLabels(_state, _action: PayloadAction<string>) {},
    setHoveredLabelClassId(state, action: PayloadAction<string>) {
      state.hoveredLabelClassId = action.payload;
    },
    resetActiveLabelClassId(state) {
      state.activeLabelClassId = null;
    },
    resetHoveredLabelClassId(state) {
      state.hoveredLabelClassId = null;
    },
  },
});

export const {
  selectActiveLabelClassId,
  selectNextLabelClass,
  selectPreviousLabelClass,
  setLabelClassToSelectedLabels,
  setHoveredLabelClassId,
  resetActiveLabelClassId,
  resetHoveredLabelClassId,
} = actions;
export { labelClassesReducer };
