/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { LabelKeypoint } from './LabelKeypoint';

export type LabelSync = {
  /**
   * Bounding box coordinate [left, top, right, bottom]
   */
  bbox?: Array<number> | null;
  /**
   * Label class
   */
  classId?: string | null;
  readonly createdOn?: string;
  /**
   * Event ID from frontend, if any.
   */
  externalEventId?: string | null;
  readonly imageId?: string;
  id?: string;
  /**
   * RLE encoded mask [pixel index, length, pixel index, length]
   */
  mask?: Array<number> | null;
  /**
   * Polygon coordinates [[x0, y0], [x1, y1], ... ,[x0, y0]]
   */
  polygon?: Array<Array<number>> | null;
  /**
   * Tool that was used to create label
   */
  toolUsed?: LabelSync.toolUsed | null;
  readonly updatedOn?: string | null;
  /**
   * Labeler ID
   */
  readonly labeledBy?: string;
  /**
   * Label hierarchy level
   */
  zIndex?: number | null;
  isDeleted?: boolean | null;
  /**
   * Calculated border mask by border and mask fields
   */
  borderMask?: string | null;
  /**
   * External ID
   */
  externalId?: string | null;
  /**
   * Label ID from each it was copied
   */
  copiedFrom?: string | null;
  /**
   * List of keypoints
   */
  keypoints?: Array<LabelKeypoint> | null;
};

export namespace LabelSync {

  /**
   * Tool that was used to create label
   */
  export enum toolUsed {
    BBOX = 'bbox',
    POLYGON = 'polygon',
    CONTOUR = 'contour',
    MAGIC_WAND = 'magic_wand',
    BRUSH = 'brush',
    GRABCUT = 'grabcut',
    DEXTR = 'dextr',
    OBJECT_DETECTION = 'object_detection',
    INSTANCE_SEGMENTATION = 'instance_segmentation',
    SEMANTIC_SEGMENTATION = 'semantic_segmentation',
    IMPORT = 'import',
    COPY = 'copy',
    ATOM = 'atom',
    ERROR_FINDER = 'error_finder',
    FINISH_HIM = 'finish_him',
    API = 'api',
    DUPLICATED = 'duplicated',
    BOX2MASK = 'box2mask',
    KEYPOINTS = 'keypoints',
    TEXT_TO_MASK = 'text_to_mask',
    KEYPOINT_DETECTION = 'keypoint_detection',
  }


}

