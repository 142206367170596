import { createSelector } from '@reduxjs/toolkit';

import { experimentsByIdSelector } from '../../experiments/experimentsData/experimentsData.selectors';
import { modelPlaygroundSelector } from '../../modelPlayground.selectors';

export const activeExperimentSelector = createSelector(
  modelPlaygroundSelector,
  (modelPlayground) => modelPlayground.dashboard.activeExperiment,
);
export const dashboardActiveExperimentIdSelector = createSelector(
  activeExperimentSelector,
  (activeExperiment) => activeExperiment.id,
);
export const dashboardActiveExperimentSelector = (state: RootState) => {
  const id = dashboardActiveExperimentIdSelector(state);

  if (!id) return null;

  return experimentsByIdSelector(state, id);
};
