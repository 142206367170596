import { createSelector } from '@reduxjs/toolkit';

import { widgetsSelector } from '../widgets.selectors';
import { itemsAdapter } from './segmentorInferences.slice';

const segmentorInferencesSelector = createSelector(
  widgetsSelector,
  (widgets) => widgets.segmentorInferences,
);

export const segmentorInferencesItemsStateSelector = createSelector(
  segmentorInferencesSelector,
  ({ items }) => items,
);

const itemsSelectors = itemsAdapter.getSelectors(
  segmentorInferencesItemsStateSelector,
);

export const segmentorInferencesItemsSelector = itemsSelectors.selectAll;

export const segmentorInferencesMetaSelector = createSelector(
  segmentorInferencesSelector,
  ({ meta }) => meta,
);

export const segmentorInferencesMetaTotalSelector = createSelector(
  segmentorInferencesMetaSelector,
  (meta) => meta?.total || 0,
);

export const segmentorInferencesLimitSelector = createSelector(
  segmentorInferencesSelector,
  ({ limit }) => limit,
);

export const segmentorInferencesOffsetSelector = createSelector(
  segmentorInferencesSelector,
  ({ offset }) => offset,
);

export const segmentorInferencesOpacitySelector = createSelector(
  segmentorInferencesSelector,
  ({ opacity }) => opacity,
);
