import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import undoable from 'redux-undo';

import { clearAtomHistory, redoAtom, undoAtom } from '../atomValues.slice';
import { ATOM_DEFAULT_MASK_SIZE_LEVEL } from '../constants';

type Strategy = 'points' | 'scribble' | 'box' | 'multiInstance';
const initialState: {
  maskSizeLevel: number;
  useViewport: boolean;
  strategy: Strategy;
  nextStrategy: Strategy | null;
  brushSize: number;
} = {
  maskSizeLevel: ATOM_DEFAULT_MASK_SIZE_LEVEL,
  useViewport: false,
  strategy: 'points',
  nextStrategy: null,
  brushSize: 9,
};

const { actions, reducer } = createSlice({
  name: 'imageViewAtomEditingState',
  initialState,
  reducers: {
    toggleUseViewport(state) {
      state.useViewport = !state.useViewport;
    },
    setNextStrategy(state, action: PayloadAction<{ strategy: Strategy }>) {
      state.nextStrategy = action.payload.strategy;
    },
    setMaskSizeLevel(state, action: PayloadAction<{ level: number }>) {
      state.maskSizeLevel = action.payload.level;
    },
    resetMaskSizeLevel(state) {
      state.maskSizeLevel = initialState.maskSizeLevel;
    },
    confirmNextStrategy(state, _action: PayloadAction<{ neverShow: boolean }>) {
      state.strategy = state.nextStrategy as Strategy;
      state.nextStrategy = null;
    },
    resetNextStrategy(state) {
      state.nextStrategy = null;
    },
    increaseMaskSizeLevel(state) {
      state.maskSizeLevel += 1;
    },
    decreaseMaskSizeLevel(state) {
      state.maskSizeLevel -= 1;
    },
    setAtomBrushSize(state, action: PayloadAction<number>) {
      state.brushSize = action.payload;
    },
  },
});

export const {
  confirmNextStrategy,
  toggleUseViewport,
  setNextStrategy,
  setMaskSizeLevel,
  resetNextStrategy,
  increaseMaskSizeLevel,
  decreaseMaskSizeLevel,
  setAtomBrushSize,
  resetMaskSizeLevel,
} = actions;
export const atomEditingStateReducer = undoable(reducer, {
  undoType: undoAtom.type,
  redoType: redoAtom.type,
  clearHistoryType: clearAtomHistory.type,
});
