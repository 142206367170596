import {
  ImportFileParseLog,
  ParseFileStatus,
  PARSE_GENERAL_ERROR,
} from '../../../../../../api/domainModels/imports';
import { ImportFileParseLogs, ParseLog } from './importParseLogs.slice';
import { pluralize } from '../../../../../../helpers/plural';

export const MISSING_IMAGES_WARNING = 'Failed to match images';

const IMAGE_STATIC = 'Image ';
// Available image formats from: /src/components/forms/imagesForm/UploadBox.js#L9-L18
const IMAGE_EXTENSIONS = '(jpe?g?|png|tiff?|svg|bmp|webp)';
const IMAGE_REGEX = new RegExp(`${IMAGE_STATIC}(.*).${IMAGE_EXTENSIONS}`, 'gi');

export const groupParseLogsByLogType = (parseLogs: ParseLog[]) => {
  if (parseLogs.length === 1) return parseLogs;

  const missingImagesError = parseLogs.find(
    ({ logMessage }) => logMessage === MISSING_IMAGES_WARNING,
  );

  if (missingImagesError) {
    const missingImagesErrors = parseLogs.filter(
      ({ logMessage }) => logMessage !== 'Failed to match images',
    );

    const imageNames = missingImagesErrors.reduce((acc, item: ParseLog) => {
      const { logMessage } = item;

      if (logMessage.startsWith('Image')) {
        const imageString = logMessage.match(IMAGE_REGEX); // matches "Image ${imageName}.${imageExtension}"

        if (imageString) {
          const imageName = imageString[0].slice(IMAGE_STATIC.length); // removes "Image " part

          return [...acc, imageName];
        }

        return acc;
      }

      return acc;
    }, [] as string[]);

    const logMessage = `Missing ${pluralize(imageNames.length, 'image', {
      showCountString: false,
    })}`;

    return [
      {
        ...missingImagesError,
        logMessage,
        logs: [...new Set(imageNames)],
      },
      ...parseLogs.filter(
        ({ logMessage }) => logMessage !== MISSING_IMAGES_WARNING,
      ),
    ];
  }

  return parseLogs;
};

export const groupParseLogsByFileId = (parseLogs: ImportFileParseLog[]) =>
  parseLogs.reduce((acc, item) => {
    const { refObjectId, ...rest } = item;

    const logSeverity = rest.logSeverity as ParseFileStatus;

    const objectToInsert = {
      logId: rest.importLogId,
      logDate: rest.logDate,
      logSeverity,
      logMessage: rest.msg,
    };

    const fileId = refObjectId || PARSE_GENERAL_ERROR;

    const fileLogs = acc.find((item) => item.id === fileId);
    if (!fileLogs) {
      return [...acc, { id: fileId, [logSeverity]: [objectToInsert] }];
    }

    const prevLogs = [
      ...(fileLogs[logSeverity] ? (fileLogs[logSeverity] as ParseLog[]) : []),
    ];

    const logsToInsert = {
      ...fileLogs,
      [logSeverity]: [...prevLogs, objectToInsert],
    };

    return [...acc.filter((item) => item.id !== fileId), logsToInsert];
  }, [] as ImportFileParseLogs[]);
