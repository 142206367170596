import { createSelector } from '@reduxjs/toolkit';

import { imageViewSelector } from '../../imageView.selectors';
import { adapter } from './semanticSegmentation.slice';
import { modelStatusChecks } from '../models/modelStatus.constants';

const semanticSegmentationSelector = createSelector(
  imageViewSelector,
  (imageView) => imageView.tools.semanticSegmentation,
);
const semanticSegmentationDataSelector = createSelector(
  semanticSegmentationSelector,
  (semanticSegmentation) => semanticSegmentation.data,
);
const semanticSegmentationDataSelectors = adapter.getSelectors(
  semanticSegmentationDataSelector,
);

export const semanticSegmentationPredictedDataSelector =
  semanticSegmentationDataSelectors.selectAll;

export const semanticSegmentationPredictedDataByIdSelector =
  semanticSegmentationDataSelectors.selectById;

export const semanticSegmentationPredictedDataCountSelector =
  semanticSegmentationDataSelectors.selectTotal;

export const semanticSegmentationLoadingStateSelector = createSelector(
  semanticSegmentationSelector,
  (semanticSegmentation) => semanticSegmentation.data.loadingState,
);

export const semanticSegmentationMinSizeSelector = createSelector(
  semanticSegmentationSelector,
  (semanticSegmentation) => semanticSegmentation.minSize,
);

export const semanticSegmentationModelSelector = createSelector(
  semanticSegmentationSelector,
  (semanticSegmentation) => semanticSegmentation.model,
);

export const semanticSegmentationModelIdSelector = createSelector(
  semanticSegmentationModelSelector,
  (model) => model.id,
);

export const semanticSegmentationModelStatusSelector = createSelector(
  semanticSegmentationModelSelector,
  (model) => model.status,
);

export const semanticSegmentationModelAvailableSelector = createSelector(
  semanticSegmentationModelIdSelector,
  (modelId) => typeof modelId === 'number',
);

export const semanticSegmentationModelLoadedSelector = createSelector(
  [
    semanticSegmentationModelAvailableSelector,
    semanticSegmentationModelStatusSelector,
  ],
  (available, status) =>
    !!(available && status && modelStatusChecks.isLoaded(status)),
);

export const semanticSegmentationUseSAMSelector = createSelector(
  semanticSegmentationSelector,
  (semanticSegmentation) => semanticSegmentation.useSAM,
);
