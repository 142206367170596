import { combineReducers } from 'redux';

import { importSessionReducer } from './session/importSession.slice';
import { importSessionsReducer } from './sessions/importSessions.slice';
import { importFilesReducer } from './files/importFiles.slice';
import { importFileReducer } from './uploads/importUploads.slice';
import { importFilesParseLogsReducer } from './parseLogs/importParseLogs.slice';
import { importLabelClassReducer } from './labelClass/importLabelClass.slice';

export const importsReducer = combineReducers({
  activeSession: importSessionReducer,
  sessions: importSessionsReducer,
  files: importFilesReducer,
  uploads: importFileReducer,
  parseLogs: importFilesParseLogsReducer,
  labelClasses: importLabelClassReducer,
});
