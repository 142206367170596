import { call, put, select, takeEvery, takeLatest } from 'typed-redux-saga';
import { PayloadAction } from '@reduxjs/toolkit';

import {
  apiLoadSubjectLocks,
  apiLockSubject,
  apiUnlockSubject,
} from '../../../../api/requests/locks';
import { getErrorMessage } from '../../../../api/utils';
import { Lock } from '../../../../api/domainModels/lock';
import { handleError } from '../errorHandler/errorHandler.actions';
import {
  loadSubjectLocks,
  subjectLockedSuccess,
  loadSubjectLocksSuccess,
  loadSubjectLocksError,
  subjectUnlockedSuccess,
  unlockSubject,
  lockSubject,
  renewSubjectLock,
} from './locks.slice';
import { activeProjectIdSelector } from '../../project/project.selectors';
import { SUBJECT_LOCKED, SUBJECT_UNLOCKED } from '../../ws/ws.constants';
import { uuidv4 } from '../../../../util/uuidv4';

export function* loadSubjectLocksHandler(
  action: ActionType<typeof loadSubjectLocks>,
) {
  try {
    const subjectId = action.payload;
    const { data } = yield* call(apiLoadSubjectLocks, subjectId);

    yield* put(loadSubjectLocksSuccess(data));
  } catch (error) {
    const errorMessage = getErrorMessage(error, 'Not able to fetch locks');

    yield* put(loadSubjectLocksError(errorMessage));
    yield* put(handleError({ message: errorMessage, error }));
  }
}

function* subjectLockedHandler(
  action: PayloadAction<{
    data: Lock[];
    projectId: string;
  }>,
) {
  // TODO:: either drop the ids comparison use better approach
  const projectId = yield* select(activeProjectIdSelector);

  if (projectId === action.payload.projectId) {
    yield* put(subjectLockedSuccess(action.payload.data));
  }
}

function* subjectUnlockedHandler(
  action: PayloadAction<{
    data: Lock[];
    projectId: string;
  }>,
) {
  // TODO:: either drop the ids comparison use better approach
  const projectId = yield* select(activeProjectIdSelector);

  if (projectId === action.payload.projectId) {
    yield* put(subjectUnlockedSuccess(action.payload.data));
  }
}

function* unlockSubjectHandler(action: ActionType<typeof unlockSubject>) {
  const { projectId, lock } = action.payload;

  try {
    const { lockGuid, subjectId } = lock;
    yield* call(apiUnlockSubject, { projectId }, { lockGuid, subjectId });
  } catch (error) {
    const errorMessage = getErrorMessage(error, 'Not able to unlock an item');

    yield* put(
      handleError({
        message: errorMessage,
        error,
      }),
    );
  }
}

function* lockSubjectHandler(action: ActionType<typeof lockSubject>) {
  const { projectId, subjectId } = action.payload;
  const lockGuid = uuidv4();

  try {
    const payload = {
      subjectId,
      lockGuid,
    };

    yield* call(apiLockSubject, { projectId }, payload);
  } catch (error) {
    const errorMessage = getErrorMessage(error, 'Not able to lock an item');

    yield* put(
      handleError({
        message: errorMessage,
        error,
      }),
    );
  }
}

function* renewSubjectLockHandler(action: ActionType<typeof renewSubjectLock>) {
  const { projectId, lock } = action.payload;

  try {
    const { lockGuid, subjectId } = lock;

    yield* call(apiLockSubject, { projectId }, { lockGuid, subjectId });
  } catch (error) {
    const errorMessage = getErrorMessage(error, 'Not able to lock an item');

    yield* put(
      handleError({
        message: errorMessage,
        error,
      }),
    );
  }
}

export function* locksSaga() {
  yield* takeEvery(loadSubjectLocks, loadSubjectLocksHandler);
  yield* takeEvery(SUBJECT_LOCKED, subjectLockedHandler);
  yield* takeEvery(SUBJECT_UNLOCKED, subjectUnlockedHandler);
  yield* takeLatest(lockSubject, lockSubjectHandler);
  yield* takeLatest(renewSubjectLock, renewSubjectLockHandler);
  yield* takeEvery(unlockSubject, unlockSubjectHandler);
}
