import { all, put, takeEvery, select, take, call } from 'typed-redux-saga';
import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import { PayloadAction } from '@reduxjs/toolkit';

import { ErrorType } from '../../../../../api/domainModels/consensusScoring';
import { routerSelector } from '../../../root.selectors';
import { setUrlSearchParams } from '../../../commonFeatures/urlSearchParams/urlSearchParams.actions';
import { activeProjectIdSelector } from '../../../project/project.selectors';
import { setEditedProjectId } from '../../../sections/editedProject/project/project.slice';
import {
  activeRunIdSelector,
  activeRunTypeSelector,
  isClassReviewRunTypeSelector,
  isOdIsReviewRunTypeSelector,
} from '../errorFinder.selectors';
import {
  loadErrorFinderLabels,
  loadErrorFinderLabelsInitial,
} from './labels.slice';
import {
  resetErrorFinderPage,
  initialState as paginationInitialState,
  setErrorFinderItemsPerPage,
} from './pagination.slice';
import { labelsPaginationSelector } from './pagination.selectors';
import {
  FiltersData,
  applyErrorFinderFilters,
  resetErrorFinderFilters,
} from './filters.slice';
import { labelsFiltersDataSelector } from './filters.selectors';
import { setActiveRunId } from '../errorFinder.slice';

function* filtersHandler() {
  const runType = yield* select(activeRunTypeSelector);
  const runId = yield* select(activeRunIdSelector);

  yield* put(resetErrorFinderPage());
  yield* put(loadErrorFinderLabels({ runType, runId }));
}

function* applyErrorFinderParamsHandler() {
  const filters = yield* select(labelsFiltersDataSelector);
  const pagination = yield* select(labelsPaginationSelector);

  const params = {
    ...filters,
  } as any;

  if (pagination.itemsPerPage !== paginationInitialState.itemsPerPage) {
    params.itemsPerPage = pagination.itemsPerPage;
  }

  yield* put(setUrlSearchParams(params));
}

function* urlSearchParamsChangeHandler(
  action: LocationChangeAction<{ from?: string }>,
) {
  if (action.payload.location?.state?.from) {
    return;
  }

  const projectId = yield* select(activeProjectIdSelector);
  const runId = yield* select(activeRunIdSelector);

  if (!projectId) {
    yield* take(setEditedProjectId);
  }

  if (!runId) {
    yield* take(setActiveRunId);
  }

  if (!projectId && !runId) {
    yield* all([take(setEditedProjectId), take(setActiveRunId)]);
  }

  yield* call(filtersHandler);
}

function* resetErrorFinderFiltersHandler(
  _action: ActionType<typeof resetErrorFinderFilters>,
) {
  const filtersData = yield* select(labelsFiltersDataSelector);

  //  we dont reset errorType beacuse its not set in Filters but from Tabs
  const params: Record<string, string | number> = {
    errorType: filtersData.errorType as string,
  };

  const pagination = yield* select(labelsPaginationSelector);

  if (pagination.itemsPerPage !== paginationInitialState.itemsPerPage) {
    params.itemsPerPage = pagination.itemsPerPage;
  }

  yield* put(setUrlSearchParams(params));
}

function* applyErrorFinderFiltersHandler() {
  yield* call(applyErrorFinderParamsHandler);
}

function* setErrorFinderItemsPerPageHandler() {
  yield* call(applyErrorFinderParamsHandler);
}

function* loadErrorFinderLabelsInitialHandler() {
  const {
    location: { search },
  } = yield* select(routerSelector);
  const runType = yield* select(activeRunTypeSelector);
  const runId = yield* select(activeRunIdSelector);
  const isClassReview: boolean = yield* select(isClassReviewRunTypeSelector);
  const isOdIsReview: boolean = yield* select(isOdIsReviewRunTypeSelector);

  if (!search) {
    const params: FiltersData = {};
    if (isClassReview) {
      params.errorType = ErrorType.Misclassification;
    }
    if (isOdIsReview) {
      params.errorType = ErrorType.MissingLabel;
    }

    yield* put(setUrlSearchParams(params as Record<string, string>));
  } else {
    yield* put(loadErrorFinderLabels({ runType, runId }));
  }
}

export function* labelsFiltersSaga() {
  yield* takeEvery(applyErrorFinderFilters, applyErrorFinderFiltersHandler);
  yield* takeEvery(
    setErrorFinderItemsPerPage,
    setErrorFinderItemsPerPageHandler,
  );
  yield* takeEvery(resetErrorFinderFilters, resetErrorFinderFiltersHandler);
  yield* takeEvery(
    (action: PayloadAction<any> | LocationChangeAction) =>
      action.type === LOCATION_CHANGE &&
      action.payload.location.pathname.includes('/review/runs'),
    urlSearchParamsChangeHandler,
  );
  yield* takeEvery(
    loadErrorFinderLabelsInitial,
    loadErrorFinderLabelsInitialHandler,
  );
}
