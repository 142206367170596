import cloneDeep from 'lodash/cloneDeep';
import flatten from 'lodash/flatten';
import groupBy from 'lodash/groupBy';
import partition from 'lodash/partition';
import uniq from 'lodash/uniq';
import { all, call, put, select, takeEvery } from 'typed-redux-saga';

import { getErrorMessage } from '../../../../../api/utils';
import {
  apiLoadProjectRolePrivileges,
  apiGrantProjectPrivileges,
  apiRevokeProjectPrivileges,
} from '../../../../../api/requests/projectPrivileges';
import {
  cancelRolePrivilegesChanges,
  changeRolePrivilege,
  changeRolePrivilegeGroup,
  createRoleSuccess,
  deleteRoleSuccess,
  grantPrivileges,
  grantPrivilegesFailure,
  grantPrivilegesSuccess,
  loadRolePrivileges,
  loadRolePrivilegesFailure,
  loadRolePrivilegesSuccess,
  replaceRolePrivileges,
  revokePrivileges,
  revokePrivilegesFailure,
  revokePrivilegesSuccess,
  saveRolePrivilegesChanges,
  RolePrivilegesState,
  RolePrivilegeValue,
  PrivilegeState,
} from './roles.slice';
import {
  Privileges,
  ProjectPrivileges,
  projectPrivilegeTableStructure,
} from './privileges';
import { editedProjectRolesPrivilegesDataSelector } from './roles.selectors';
import { activeProjectIdSelector } from '../../../project/project.selectors';
import { PROJECT_OWNER_ROLE_ID } from '../editedProject.constants';
import { ProjectRolePrivilege } from '../../../../../api/domainModels/projectPrivileges';

const getSharedValue = <T>(values: T[]): T | undefined =>
  uniq(values).length === 1 ? values[0] : undefined;

const getGroupValues = (
  groupMaps: Record<number, RolePrivilegeValue>[],
  setOriginalValues = false,
) => {
  const roleIds = [...new Set(flatten(groupMaps.map(Object.keys)))];

  return roleIds.reduce((acc, id) => {
    const roleId = Number(id);

    const item: RolePrivilegeValue = {
      roleId,
      value: getSharedValue(
        groupMaps.map(
          (entry: Record<number, RolePrivilegeValue>) => entry[roleId].value,
        ),
      ),
      readOnly: !!getSharedValue(
        groupMaps.map(
          (entry: Record<number, RolePrivilegeValue>) => entry[roleId].readOnly,
        ),
      ),
    };

    if (setOriginalValues) {
      item.originalValue = item.value;
    }

    acc[roleId] = item;

    return acc;
  }, {} as Record<number, RolePrivilegeValue>);
};

type PreProcessEntry = {
  privilegeId: string;
  roleId: number;
  value?: boolean;
  originalValue: boolean;
  readOnly: boolean;
};

// TODO: HT-5645 Remove these conversions
const convertToIamPrivilegeId = (id: string) =>
  id.toUpperCase().replaceAll('.', '_');

const convertToOldPrivilegeId = (id: string) =>
  ['ERROR_FINDER', 'MODEL_PLAYGROUND'].includes(id)
    ? id.toLowerCase()
    : id.toLowerCase().replace('_', '.');

const preProcessEntries = (rawEntries: ProjectRolePrivilege[]) => {
  const convertEntry = ({
    privilegeId,
    roleId,
    hasGrant,
    readonly,
  }: ProjectRolePrivilege): PreProcessEntry => ({
    privilegeId: convertToIamPrivilegeId(privilegeId),
    roleId,
    value: hasGrant,
    originalValue: hasGrant,
    readOnly: readonly,
  });

  const entries = rawEntries.map(convertEntry);

  const privilegeIds = [
    ...new Set(entries.map((entry) => entry.privilegeId)),
    ProjectPrivileges.Manage.ManageRoles,
  ];

  // owner permissions are non-editable
  // project owner contains all the privileges with a custom role
  const projectOwnerEntries = privilegeIds.map((privilegeId) => ({
    privilegeId,
    roleId: PROJECT_OWNER_ROLE_ID,
    value: true,
    originalValue: true,
    readOnly: true,
  }));

  entries.push(...projectOwnerEntries);

  return entries;
};

export function* loadRolePrivilegesHandler(
  action: ActionType<typeof loadRolePrivileges>,
) {
  const { projectId } = action.payload;

  try {
    const response = yield* call(apiLoadProjectRolePrivileges, projectId);
    const allEntries = preProcessEntries(response.data);

    const privilegeEntriesMap = groupBy(allEntries, 'privilegeId');

    const payload = projectPrivilegeTableStructure.reduce(
      (groups, groupStructure) => {
        const simple = groupStructure.privileges.length === 1;

        const convertEntriesToRolePrivilegeMap = (entries: PreProcessEntry[]) =>
          entries.reduce((acc, entry) => {
            // keeping roleId here because of it number type, to avoid conversions from/to string
            acc[entry.roleId] = {
              roleId: entry.roleId,
              value: entry.value,
              originalValue: entry.originalValue,
              readOnly: entry.readOnly,
            };

            return acc;
          }, {} as Record<string, RolePrivilegeValue>);

        const mapper = (privilege: Privileges['privileges'][0]) =>
          convertEntriesToRolePrivilegeMap(privilegeEntriesMap[privilege.id]);
        const privileges = groupStructure.privileges.map(mapper);

        // values field contains a map roleId: { value, originalValue }
        groups[groupStructure.id] = {
          group: groupStructure.title,
          simple,
          values: getGroupValues(privileges, true),
          privileges: groupStructure.privileges.reduce((acc, privilege) => {
            acc[privilege.id] = {
              ...privilege,
              simple,
              values: mapper(privilege),
            };

            return acc;
          }, {} as Record<string, PrivilegeState>),
        };

        return groups;
      },
      {} as Record<string, RolePrivilegesState>,
    );

    yield* put(loadRolePrivilegesSuccess(payload));
  } catch (error) {
    yield* put(
      loadRolePrivilegesFailure(
        getErrorMessage(error, 'Not able to fetch role privileges'),
      ),
    );
  }
}

export function* grantPrivilegesHandler(
  action: ActionType<typeof grantPrivileges>,
) {
  const { projectId, roleId, privilegeIds } = action.payload;
  const oldPrivilegeIds = privilegeIds.map((id) => convertToOldPrivilegeId(id));

  try {
    yield* call(apiGrantProjectPrivileges, projectId, roleId, oldPrivilegeIds);

    yield* put(grantPrivilegesSuccess());
  } catch (error) {
    yield* put(
      grantPrivilegesFailure(
        getErrorMessage(error, 'Not able to grant the privileges'),
      ),
    );
  }
}

export function* revokePrivilegesHandler(
  action: ActionType<typeof revokePrivileges>,
) {
  const { projectId, roleId, privilegeIds } = action.payload;
  const oldPrivilegeIds = privilegeIds.map((id) => convertToOldPrivilegeId(id));

  try {
    yield* call(apiRevokeProjectPrivileges, projectId, roleId, oldPrivilegeIds);

    yield* put(
      revokePrivilegesSuccess({
        projectId,
        roleId,
      }),
    );
  } catch (error) {
    yield* put(
      revokePrivilegesFailure(
        getErrorMessage(error, 'Not able to revoke the privileges'),
      ),
    );
  }
}

export function* changeRolePrivilegeHandler(
  action: ActionType<typeof changeRolePrivilege>,
) {
  const { groupId, roleId, privilegeId, value } = action.payload;

  const groupedPrivileges = cloneDeep(
    yield* select(editedProjectRolesPrivilegesDataSelector),
  );

  const currentGroup = groupedPrivileges[groupId];
  const currentGroupRoleValues = currentGroup.values[roleId];

  currentGroup.privileges[privilegeId].values[roleId].value = value;

  currentGroupRoleValues.value = getGroupValues(
    Object.values(currentGroup.privileges).map(({ values }) => values),
  )[roleId].value;

  yield* put(replaceRolePrivileges(groupedPrivileges));
}

export function* cancelRolePrivilegesChangesHandler() {
  const rolePrivileges = cloneDeep(
    yield* select(editedProjectRolesPrivilegesDataSelector),
  );

  Object.values(rolePrivileges).forEach(
    ({ privileges, values: groupValues }) => {
      Object.values(groupValues).forEach((entry) => {
        entry.value = entry.originalValue;
      });

      Object.values(privileges).forEach(({ values }) => {
        Object.values(values).forEach((entry) => {
          entry.value = entry.originalValue;
        });
      });
    },
  );

  yield* put(replaceRolePrivileges(rolePrivileges));
}

export function* confirmRolePrivilegesChanges() {
  const rolePrivileges = cloneDeep(
    yield* select(editedProjectRolesPrivilegesDataSelector),
  );

  Object.values(rolePrivileges).forEach(
    ({ privileges, values: groupValues }) => {
      Object.values(groupValues).forEach((entry) => {
        entry.originalValue = entry.value;
      });

      Object.values(privileges).forEach(({ values }) => {
        Object.values(values).forEach((entry) => {
          entry.originalValue = entry.value;
        });
      });
    },
  );

  yield* put(replaceRolePrivileges(rolePrivileges));
}

export function* saveRolePrivilegesChangesHandler() {
  const rolePrivileges = yield* select(
    editedProjectRolesPrivilegesDataSelector,
  );

  const projectId = yield* select(activeProjectIdSelector);

  interface PrivilegeChange {
    roleId: number;
    privilegeId: string;
    value?: boolean;
  }

  const changes: PrivilegeChange[] = [];

  Object.values(rolePrivileges).forEach(({ privileges }) => {
    Object.entries(privileges).forEach(([id, { values }]) => {
      // we're not using the key of role->value map to avoid conversion, because role id is integer

      Object.values(values).forEach(({ roleId, value, originalValue }) => {
        if (value !== originalValue) {
          changes.push({
            roleId,
            privilegeId: id,
            value,
          });
        }
      });
    });
  });

  // it split array into two, one where values equal to true, another one - to false
  // useful because we have separate grant and revoke endpoints

  const promises = partition(changes, 'value').map(
    (grantsOrRevokes, index: number) => {
      const action = [grantPrivileges, revokePrivileges][index];

      return Object.entries(groupBy(grantsOrRevokes, 'roleId')).map(
        ([roleId, roleGrantsOrRevokes]) =>
          put(
            action({
              projectId,
              roleId: Number(roleId),
              privilegeIds: roleGrantsOrRevokes.map(
                ({ privilegeId }) => privilegeId,
              ),
            }),
          ),
      );
    },
  );

  yield* all(flatten(promises));

  yield* confirmRolePrivilegesChanges();
}

export function* changeRolePrivilegeGroupHandler(
  action: ActionType<typeof changeRolePrivilegeGroup>,
) {
  const { groupId, roleId, value } = action.payload;
  const groupedPrivileges = cloneDeep(
    yield* select(editedProjectRolesPrivilegesDataSelector),
  );

  const currentGroup = groupedPrivileges[groupId];
  const currentGroupRole = currentGroup.values[roleId];
  const singlePrivileges = groupedPrivileges[groupId].privileges;

  // we set all the atomic values first and then check what the group value is
  Object.values(singlePrivileges).forEach(({ values }) => {
    if (!values[roleId].readOnly) {
      values[roleId].value = value;
    }
  });

  // it might happen that we can't write some of the privileges, so we're not able to change all the values
  currentGroupRole.value = getSharedValue(
    Object.values(singlePrivileges).map(({ values }) => values[roleId].value),
  );

  yield* put(replaceRolePrivileges(groupedPrivileges));
}

export function* deleteRoleSuccessHandler(
  action: ActionType<typeof deleteRoleSuccess>,
) {
  const { roleId } = action.payload;
  const rolePrivileges = cloneDeep(
    yield* select(editedProjectRolesPrivilegesDataSelector),
  );

  Object.values(rolePrivileges).forEach(
    ({ privileges, values: groupValues }) => {
      delete groupValues[roleId];
      Object.values(privileges).forEach(({ values }) => {
        delete values[roleId];
      });
    },
  );
  yield* put(replaceRolePrivileges(rolePrivileges));
}

export function* createRoleSuccessHandler(
  action: ActionType<typeof createRoleSuccess>,
) {
  const { payload } = action;
  const rolePrivileges = cloneDeep(
    yield* select(editedProjectRolesPrivilegesDataSelector),
  );

  Object.values(rolePrivileges).forEach(
    ({ privileges, values: groupValues }) => {
      // Since the 'Manage' role should always be readonly
      // we have to get the readOnly value from first item
      // to make "Manage Roles" of the new role readOnly
      const readOnly = Object.values(groupValues)[1]?.readOnly || false;
      groupValues[payload.id] = {
        roleId: payload.id,
        value: false,
        originalValue: false,
        readOnly,
      };
      Object.values(privileges).forEach(({ values }) => {
        // Since the 'Manage' role should always be readonly
        // we have to get the readOnly value from first item
        // to make "Manage Roles" of the new role readOnly
        const readOnly = Object.values(values)[1]?.readOnly || false;

        values[payload.id] = {
          roleId: payload.id,
          value: false,
          originalValue: false,
          readOnly,
        };
      });
    },
  );
  yield* put(replaceRolePrivileges(rolePrivileges));
}

export function* privilegesSaga() {
  yield* takeEvery(loadRolePrivileges, loadRolePrivilegesHandler);
  yield* takeEvery(grantPrivileges, grantPrivilegesHandler);
  yield* takeEvery(revokePrivileges, revokePrivilegesHandler);
  yield* takeEvery(changeRolePrivilege, changeRolePrivilegeHandler);
  yield* takeEvery(changeRolePrivilegeGroup, changeRolePrivilegeGroupHandler);
  yield* takeEvery(deleteRoleSuccess, deleteRoleSuccessHandler);
  yield* takeEvery(createRoleSuccess, createRoleSuccessHandler);
  yield* takeEvery(
    cancelRolePrivilegesChanges,
    cancelRolePrivilegesChangesHandler,
  );
  yield* takeEvery(saveRolePrivilegesChanges, saveRolePrivilegesChangesHandler);
}
