import axios from 'axios';

import {
  ApiServiceAccount,
  ApiCreateWorkspaceServiceAccountParams,
  ApiDeleteWorkspaceServiceAccountParams,
  ApiUpdateWorkspaceServiceAccountParams,
  ApiLoadWorkspaceServiceAccountsParams,
  ApiLoadWorkspaceServiceAccountsResponse,
} from '../schemas/serviceAccount';
import { NEW_API_URL } from '../../constants/paths';
import { apiWrapper } from '../utils';
import { headers, transformRequest, transformResponse } from '../helpers';

export const apiCreateWorkspaceServiceAccount = (
  params: ApiCreateWorkspaceServiceAccountParams,
  data: Partial<ApiServiceAccount>,
) =>
  apiWrapper(
    axios.request<ApiServiceAccount>({
      method: 'post',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/workspaces/${params.workspaceId}/service_accounts`,
      data,
    }),
  );

export const apiDeleteWorkspaceServiceAccount = (
  params: ApiDeleteWorkspaceServiceAccountParams,
) =>
  apiWrapper(
    axios.request<ApiServiceAccount>({
      method: 'delete',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/workspaces/${params.workspaceId}/service_accounts/${params.profileId}`,
    }),
  );

export const apiUpdateWorkspaceServiceAccount = (
  params: ApiUpdateWorkspaceServiceAccountParams,
  data: Partial<ApiServiceAccount>,
) =>
  apiWrapper(
    axios.request<ApiServiceAccount>({
      method: 'put',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/workspaces/${params.workspaceId}/service_accounts/${params.profileId}`,
      data,
    }),
  );

export const apiLoadWorkspaceServiceAccounts = (
  params: ApiLoadWorkspaceServiceAccountsParams,
) =>
  apiWrapper(
    axios.request<ApiLoadWorkspaceServiceAccountsResponse>({
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/workspaces/${params.workspaceId}/service_accounts`,
    }),
  );
