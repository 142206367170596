import { createSelector } from '@reduxjs/toolkit';

import { exportSessionsAdapter } from './exportSessions.slice';
import { editedProjectSelector } from '../editedProject.selectors';

export { exportSessionsAdapter } from './exportSessions.slice';

const exportSessionStateSelector = createSelector(
  editedProjectSelector,
  (editedProject) => editedProject.exportSessions,
);
const selectors = exportSessionsAdapter.getSelectors(
  exportSessionStateSelector,
);

export const exportSessionsSelector = selectors.selectAll;
export const exportSessionByIdSelector = selectors.selectById;
export const exportSessionListLoadingStateSelector = createSelector(
  exportSessionStateSelector,
  (state) => state.listLoadingState,
);
export const exportSessionItemLoadingStateSelector = createSelector(
  exportSessionStateSelector,
  (state) => state.itemLoadingState,
);
