import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import undoable from 'redux-undo';
import { combineReducers } from 'redux';

import { loadingStateBuilder } from '../../../../../utils/loadingState';
import { Bbox } from '../../../../../../@types/imageView/types';
import { clearAtomHistory, redoAtom, undoAtom } from './atomValues.slice';

const name = 'imageViewAtomResult';

export const resultsAdapter = createEntityAdapter<{
  borderDataId: number;
  maskId: number;
  bbox: Bbox;
  id: string;
}>();
const { reducer: dataReducer, actions: dataActions } = createSlice({
  name,
  initialState: resultsAdapter.getInitialState(),
  reducers: {
    loadAtomResultSuccess(
      state,
      action: PayloadAction<
        {
          borderDataId: number;
          maskId: number;
          bbox: Bbox;
          id: string;
        }[]
      >,
    ) {
      resultsAdapter.removeAll(state);
      resultsAdapter.addMany(state, action.payload);
    },
    loadAtomResultsSuccess(
      state,
      action: PayloadAction<
        {
          borderDataId: number;
          maskId: number;
          bbox: Bbox;
          id: string;
        }[]
      >,
    ) {
      resultsAdapter.addMany(state, action.payload);
    },
    resetAtomResult(state) {
      resultsAdapter.removeAll(state);
    },
    acceptAtomResult(_state, _action: PayloadAction<{ id: string }>) {},
    acceptAtomResults() {},
    rejectAtomResult(state, action: PayloadAction<{ id: string }>) {
      resultsAdapter.removeOne(state, action.payload.id);
    },
  },
});

export const {
  loadAtomResultSuccess,
  loadAtomResultsSuccess,
  resetAtomResult,
  acceptAtomResult,
  acceptAtomResults,
  rejectAtomResult,
} = dataActions;

const { reducer: loadingStateReducer, actions: loadingStateActions } =
  createSlice({
    name,
    initialState: loadingStateBuilder.initial(),
    reducers: {
      loadAtomResultStart() {
        return loadingStateBuilder.inProgress('Applying Atom');
      },
      loadAtomResultFailure(_state, action: PayloadAction<string>) {
        return loadingStateBuilder.failure(action.payload);
      },
    },
    extraReducers: (builder) => {
      builder.addCase(resetAtomResult, () => loadingStateBuilder.initial());
      builder.addCase(loadAtomResultSuccess, () =>
        loadingStateBuilder.success(),
      );
    },
  });

const undoableDataReducer = undoable(dataReducer, {
  undoType: undoAtom.type,
  redoType: redoAtom.type,
  clearHistoryType: clearAtomHistory.type,
});

export const atomResultReducer = combineReducers({
  data: undoableDataReducer,
  loadingState: loadingStateReducer,
});

export const { loadAtomResultFailure, loadAtomResultStart } =
  loadingStateActions;
