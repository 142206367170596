import axios from 'axios';

import {
  ApiUploadImageToCloudParams,
  ApiUploadImageDataParams,
  ApiGetUploadUrlsParams,
  ApiUploadUrl,
} from '../schemas/uploadImages';
import { apiWrapper } from '../utils';
import { NEW_API_URL } from '../../constants/paths';
import { headers, transformRequest, transformResponse } from '../helpers';

export const apiUploadImageToCloud = ({
  binaryString,
  uploadUrl,
}: ApiUploadImageToCloudParams) =>
  apiWrapper(
    axios({
      method: 'put',
      withCredentials: false,
      url: uploadUrl,
      data: binaryString,
      headers: {
        'Content-Type': 'image/*',
      },
    }),
  );

export const apiUploadImageData = (
  projectId: string,
  { uploadId, datasetId, filename }: ApiUploadImageDataParams,
) =>
  apiWrapper(
    axios({
      method: 'post',
      url: `${NEW_API_URL}/projects/${projectId}/images`,
      headers,
      transformRequest,
      transformResponse,
      data: {
        uploadId,
        datasetId,
        filename,
      },
    }),
  );

export const apiGetUploadUrls = ({
  projectId,
  imagesCount,
}: ApiGetUploadUrlsParams) =>
  apiWrapper(
    axios.request<{ items: ApiUploadUrl[] }>({
      method: 'post',
      url: `${NEW_API_URL}/projects/${projectId}/image_uploads`,
      data: { count: imagesCount },
    }),
  );
