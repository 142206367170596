import { put, select, takeEvery, call } from 'typed-redux-saga';

import {
  apiLoadHeuristics,
  apiSetHeuristic,
} from '../../../../api/requests/heuristic';
import { getErrorMessage } from '../../../../api/utils';
import { setProjectId } from '../../core/imageView/project/project.slice';
import { initImageProject } from '../../sections/editedProject/project/project.slice';
import { activeProjectIdSelector } from '../project.selectors';
import {
  loadHeuristicsFailure,
  loadHeuristics,
  loadHeuristicsSuccess,
  setHeuristic,
  setHeuristicFailure,
  setHeuristicSuccess,
} from './heuristics.slice';

function* listHandler(
  action: ActionType<typeof initImageProject | typeof setProjectId>,
) {
  const projectId = action.payload.id;

  yield* put(loadHeuristics());

  try {
    const { data } = yield* call(apiLoadHeuristics, {
      projectId,
    });

    yield* put(loadHeuristicsSuccess(data));
  } catch (error) {
    yield* put(
      loadHeuristicsFailure(
        getErrorMessage(error, 'Not able to load heuristics'),
      ),
    );
  }
}

function* setFamilyHeuristicHandler(action: ActionType<typeof setHeuristic>) {
  const { family, id } = action.payload;
  const projectId = yield* select(activeProjectIdSelector);

  try {
    const { data } = yield* call(apiSetHeuristic, {
      projectId,
      family,
      heuristicId: id, // null for resetting to None
    });

    const { heuristicId, isActive } = data;

    yield* put(
      setHeuristicSuccess({
        modelFamily: family,
        isActive,
        id: heuristicId,
      }),
    );
  } catch (e) {
    if (id) {
      yield* put(
        setHeuristicFailure({
          message: getErrorMessage(e, 'Not able to update the heuristic'),
          id,
        }),
      );
    }
  }
}

export function* heuristicsSaga() {
  yield* takeEvery([initImageProject, setProjectId], listHandler);
  yield* takeEvery(setHeuristic, setFamilyHeuristicHandler);
}
