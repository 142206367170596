import { call, put, select, takeEvery, takeLatest } from 'typed-redux-saga';
import qs from 'query-string';
import { push } from 'connected-react-router';

import {
  apiDeleteProjectImage,
  apiLoadProjectImages,
  apiPatchProjectImage,
} from '../../../../../api/requests/images';
import { getErrorMessage } from '../../../../../api/utils';
import { pluralize } from '../../../../../helpers/plural';
import { removeDatasetSuccess } from '../../datasets/datasets.slice';
import { activeProjectIdSelector } from '../../project.selectors';
import {
  fileManagerImagesFiltersSelector,
  fileManagerImagesSelectedIdsSelector,
} from './images.selectors';
import {
  changeStatusSelectedImages,
  deleteSelectedImages,
  imageActionSuccess,
  loadImages,
  loadImagesFailure,
  loadImagesSuccess,
  moveSelectedImages,
  updateImage,
  updateImageFailure,
  updateImageSuccess,
} from './images.slice';
import { enqueueNotification } from '../../../ui/stackNotifications/stackNotifications.slice';
import { hideModals } from '../../../ui/modals/modals.slice';
import { imagesDataMapper } from '../../../../../api/domainModels/images';
import { NotificationVariant } from '../../../../../constants/notificationVariant';
import { ApiOperationParams, splitToChunks } from '../helpers';
import {
  apiUpdateAllDatasetImagesStatus,
  apiUpdateImageStatus,
} from '../../../../../api/requests/projectImages';
import { WebsocketNotificationPayload } from '../../../../../api/domainModels/websocketTypes';
import { NOTIFICATION_SENT } from '../../../ws/ws.constants';
import { Notification } from '../../../../../api/codegen';

function* loadHandler() {
  const projectId = yield* select(activeProjectIdSelector);
  const filters = yield* select(fileManagerImagesFiltersSelector);

  const allDatasetsSelected = filters.datasetId.length === 0;

  try {
    const { data } = yield* call(apiLoadProjectImages, {
      projectId,
      params: {
        datasetId: filters.datasetId,
        imageName: filters.imageName,
        imageStatus: filters.imageStatus,
        imageTags: filters.imageTags,
        offset: (filters.page - 1) * filters.perPage,
        limit: filters.perPage,
      },
    });

    yield* put(
      loadImagesSuccess({
        items: data.items.map(imagesDataMapper.fromBackend),
        total: data.meta.total,
        allDatasetsSelected,
      }),
    );
  } catch (e) {
    yield* put(loadImagesFailure(getErrorMessage(e, 'Could not load images')));
  }
}

function* moveHandler(action: ActionType<typeof moveSelectedImages>) {
  const { datasetId } = action.payload;

  const projectId = yield* select(activeProjectIdSelector);
  const selectedImagesIds = yield* select(fileManagerImagesSelectedIdsSelector);

  if (!selectedImagesIds.length) {
    return;
  }

  const apiOperation = function* ({ projectId, id }: ApiOperationParams) {
    yield* call(apiPatchProjectImage, { projectId, id }, { datasetId });
  };

  const successCount = yield* splitToChunks({
    projectId,
    imageIds: selectedImagesIds,
    apiOperation,
  });

  const errorCount = selectedImagesIds.length - successCount;

  const variant: NotificationVariant =
    successCount && !errorCount // just success
      ? 'success'
      : !successCount && errorCount // just error
      ? 'error'
      : 'info'; // both

  const successMessage = successCount
    ? `${pluralize(successCount, 'image')} moved to the selected dataset.`
    : '';

  const errorMessage = errorCount
    ? `${pluralize(errorCount, 'image')} couldn't be processed`
    : '';

  const message = `${successMessage} ${errorMessage}`;

  yield* put(
    enqueueNotification({
      message,
      options: {
        variant,
        allowOutsideOfEditor: true,
      },
    }),
  );
  yield* put(imageActionSuccess());
  const filters = yield* select(fileManagerImagesFiltersSelector);
  yield* put(loadImages(filters));
}

function* changeStatusSelectedImagesHandler(
  action: ActionType<typeof changeStatusSelectedImages>,
) {
  const { status, datasetId, imageId } = action.payload;

  const projectId = yield* select(activeProjectIdSelector);

  if (datasetId) {
    yield* call(
      apiUpdateAllDatasetImagesStatus,
      { projectId, datasetId },
      { status },
    );

    return;
  }

  const selectedImagesIds = imageId
    ? [imageId]
    : yield* select(fileManagerImagesSelectedIdsSelector);

  if (!selectedImagesIds.length) {
    return;
  }

  const apiOperation = function* ({ projectId, id }: ApiOperationParams) {
    yield* call(apiUpdateImageStatus, projectId, id, status);
  };

  const successCount = yield* splitToChunks({
    projectId,
    imageIds: selectedImagesIds,
    apiOperation,
  });

  const errorCount = selectedImagesIds.length - successCount;

  const variant: NotificationVariant =
    successCount && !errorCount // just success
      ? 'success'
      : !successCount && errorCount // just error
      ? 'error'
      : 'info'; // both

  const successMessage = successCount
    ? `${pluralize(successCount, 'image')} changed status to ${status}.`
    : '';

  const errorMessage = errorCount
    ? `${pluralize(errorCount, 'image')} couldn't be processed`
    : '';

  const message = `${successMessage} ${errorMessage}`;

  yield* put(
    enqueueNotification({
      message,
      options: {
        variant,
        allowOutsideOfEditor: true,
      },
    }),
  );
  yield* put(imageActionSuccess());
  const filters = yield* select(fileManagerImagesFiltersSelector);
  yield* put(loadImages(filters));
}

function* deleteHandler() {
  const projectId = yield* select(activeProjectIdSelector);
  const selectedImagesIds = yield* select(fileManagerImagesSelectedIdsSelector);

  if (!selectedImagesIds.length) {
    return;
  }

  const apiOperation = function* ({ projectId, id }: ApiOperationParams) {
    yield* call(apiDeleteProjectImage, { projectId, id });
  };

  const successCount = yield* splitToChunks({
    projectId,
    imageIds: selectedImagesIds,
    apiOperation,
  });

  const errorCount = selectedImagesIds.length - successCount;

  const variant: NotificationVariant =
    successCount && !errorCount // just success
      ? 'success'
      : !successCount && errorCount // just error
      ? 'error'
      : 'info'; // both

  const successMessage = successCount
    ? `${pluralize(successCount, 'image')} removed.`
    : '';

  const errorMessage = errorCount
    ? `${pluralize(errorCount, 'image')} couldn't be processed`
    : '';

  const message = `${successMessage} ${errorMessage}`;

  yield* put(
    enqueueNotification({
      message,
      options: {
        allowOutsideOfEditor: true,
        variant,
      },
    }),
  );

  yield* put(imageActionSuccess());
  const filters = yield* select(fileManagerImagesFiltersSelector);
  yield* put(loadImages(filters));
}

function* updateHandler(action: ActionType<typeof updateImage>) {
  const projectId = yield* select(activeProjectIdSelector);
  const { id } = action.payload;
  const { filename } = action.payload;
  try {
    const { data } = yield* call(
      apiPatchProjectImage,
      { projectId, id },
      { filename },
    );

    yield* put(updateImageSuccess(imagesDataMapper.fromBackend(data)));
    yield* put(hideModals());
  } catch (error) {
    yield* put(
      updateImageFailure(getErrorMessage(error, 'Unable to update image')),
    );
  }
}

function* removeDatasetSuccessHandler(
  action: ActionType<typeof removeDatasetSuccess>,
) {
  const { datasetId, changeUrlPath } = action.payload;

  if (changeUrlPath) {
    const {
      datasetId: [searchDatasetId],
      ...filters
    } = yield* select(fileManagerImagesFiltersSelector);

    const { page, ...search } = filters;

    yield* put(
      push({
        search: qs.stringify({
          ...search,
          datasetId: datasetId === searchDatasetId ? [''] : [searchDatasetId],
        }),
      }),
    );
    yield* put(
      loadImages({
        ...filters,
        datasetId:
          datasetId === searchDatasetId || !searchDatasetId
            ? []
            : [searchDatasetId],
      }),
    );
  }
}

function* notificationHandler({
  payload,
}: WebsocketNotificationPayload & { type: string }) {
  const projectId = yield* select(activeProjectIdSelector);

  const { subject, projectId: notificationProjectId } = payload;

  if (
    notificationProjectId === projectId &&
    subject === Notification.subject.NOTIFICATION_SUBJECT_DATASET_STATUS_UPDATED
  ) {
    const filters = yield* select(fileManagerImagesFiltersSelector);
    yield* put(loadImages(filters));
  }
}

export function* fileManagerImagesSaga() {
  yield* takeLatest(loadImages, loadHandler);
  yield* takeEvery(deleteSelectedImages, deleteHandler);
  yield* takeEvery(moveSelectedImages, moveHandler);
  yield* takeEvery(
    changeStatusSelectedImages,
    changeStatusSelectedImagesHandler,
  );
  yield* takeEvery(updateImage, updateHandler);
  yield* takeEvery(removeDatasetSuccess, removeDatasetSuccessHandler);
  yield* takeEvery(NOTIFICATION_SENT, notificationHandler);
}
