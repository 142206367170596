import {
  ApiSplitPartitionObject,
  ApiSplitStrategy,
  ApiSplitClassItems,
  ApiDatasplit,
  ApiExperiment,
  ApiExperimentComponent,
  ApiExperimentParameter,
  ApiParameterValue,
  ApiExperimentWeight,
  ApiTransformPreview,
  ApiExperimentRun,
  ApiExperimentTemplate,
  ApiSaveTrainedWeightValues,
  ApiExperimentStatus,
  ApiRunStatus,
  ApiExperimentConfusionMatrixClass,
  ApiExperimentExportFormat,
  ApiExperimentComparisonParameterValue,
  ApiExperimentConfusionMatrixIteration,
  ApiExperimentComparisonItem,
  ApiExperimentComparisonParameterDefinition,
  ApiSplitPartition,
  ApiAttributeLovDistribution,
  ApiImageTagClassDistribution,
  ApiLabelClassDistribution,
  ApiModelExportJob,
  ApiCreateSplitData,
  ApiSplitObjectsNumResponse,
  ApiRestartExperimentTrainingData,
  ApiSaveAsTemplateFormValues,
  ApiSaveAsTemplateInnerFormValues,
} from '../schemas/modelPlayground';
import { ParameterType } from '../constants/modelPlayground';

export enum RegimenParameterConfigKey {
  EvalPeriod = 'EVAL_PERIOD',
  Iterations = 'ITERATIONS',
  Metric = 'METRIC',
}

export enum RegimenParameterConfigPath {
  Patience = 'TRAIN.EARLY_STOPPING.METRIC',
}

export const customParameterTypes = [
  ParameterType.Model,
  ParameterType.Weights,
  ParameterType.Metric,
];

export type ExperimentStatus = ApiExperimentStatus;
export type ExperimentStatusFilterOptions = ApiExperimentStatus | 'ALL';

export const nonComparableStatuses: ApiExperimentStatus[] = ['NEW'];

export const experimentStatusLabel: Record<ApiExperimentStatus, string> = {
  DONE: 'Done',
  FAILED: 'Failed',
  RUNNING: 'Running',
  INIT: 'Initialized',
  CANCELED: 'Canceled',
  NEW: 'Not validated',
  // Commeting for Now, Will add it back one BE work is done.
  // QUEUED: 'Queued',
};

export const experimentStatusFilterLabelAsOptions: Record<
  ExperimentStatusFilterOptions,
  string
> = {
  ALL: 'All',
  ...experimentStatusLabel,
};

export const experimentStatusLabelColorMui: Record<
  ApiExperimentStatus,
  string
> = {
  NEW: 'primary.contrastText',
  INIT: 'primary.contrastText',
  RUNNING: 'warning.main',
  CANCELED: 'error.main',
  FAILED: 'error.main',
  DONE: 'success.main',
  // Commeting for Now, Will add it back one BE work is done.
  // QUEUED: 'warning.main',
};

const fromBackendExperimentComponent = ({
  id,
  experimentComponentId,
  ...entry
}: ApiExperimentComponent) => ({
  id: experimentComponentId || id,
  experimentComponentId,
  componentId: id,
  ...entry,
});

export const experimentComponentDataMapper = {
  fromBackend: fromBackendExperimentComponent,
};

export type Datasplit = ApiDatasplit;
export type Experiment = ApiExperiment;
export type ExperimentExportFormat = ApiExperimentExportFormat;
export type ExperimentComponent = ReturnType<
  typeof fromBackendExperimentComponent
>;
export type ExperimentParameter = ApiExperimentParameter;
export type ExperimentParameterValue = ApiParameterValue;
export type ExperimentWeight = ApiExperimentWeight;
export type ExperimentTransformPreview = ApiTransformPreview;
export type ExperimentRun = ApiExperimentRun;
export type ExperimentTemplate = ApiExperimentTemplate;
export type SplitPartitionObject = ApiSplitPartitionObject;
export type SplitStrategy = ApiSplitStrategy;
export type SplitObjectsNumResponse = ApiSplitObjectsNumResponse;
export type RestartExperimentTrainingData = ApiRestartExperimentTrainingData;
export type ExperimentComparisonItem = ApiExperimentComparisonItem;
export type SaveTrainedWeightValues = ApiSaveTrainedWeightValues;
export type ExperimentConfusionMatrixClass = ApiExperimentConfusionMatrixClass;
export type ExperimentComparisonParameterDefinition =
  ApiExperimentComparisonParameterDefinition;
export type ExperimentComparisonParameterValue =
  ApiExperimentComparisonParameterValue;
export type ExperimentConfusionMatrixIteration =
  ApiExperimentConfusionMatrixIteration;
export type SplitClassItems = ApiSplitClassItems;
export type SplitPartition = ApiSplitPartition;
export type AttributeLovDistribution = ApiAttributeLovDistribution;
export type ImageTagClassDistribution = ApiImageTagClassDistribution;
export type LabelClassDistribution = ApiLabelClassDistribution;
export type ModelExportJob = ApiModelExportJob;
export type CreateSplitData = ApiCreateSplitData;
export type RunStatus = ApiRunStatus;
export type SaveAsTemplateFormValues = ApiSaveAsTemplateFormValues;
export type SaveAsTemplateInnerFormValues = ApiSaveAsTemplateInnerFormValues;
