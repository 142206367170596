// SUBJECT
export const SUBJECT_SELECTED = 'NOTIFICATION/SUBJECT_SELECTED';
export const SUBJECT_SELECTION_FAILED = 'NOTIFICATION/SUBJECT_SELECTION_FAILED';

// LOCKS
export const SUBJECT_LOCKED = 'SUBJECT/LOCKED';
export const SUBJECT_UNLOCKED = 'SUBJECT/UNLOCKED';

// IMAGES
export const IMAGE_UPDATED = 'NOTIFICATION/IMAGE_UPDATED';

// MODELS
export const MODEL_UPDATED = 'NOTIFICATION/MODEL_UPDATED';
export const MODEL_LOADED = 'NOTIFICATION/MODEL_LOADED';

// LABEL CLASSES
export const LABEL_CLASS_UPDATED = 'NOTIFICATION/LABEL_CLASS_UPDATED';
export const LABEL_CLASS_DELETED = 'NOTIFICATION/LABEL_CLASS_DELETED';

// ATTRIBUTES
export const ATTRIBUTE_UPDATED = 'NOTIFICATION/ATTRIBUTE_UPDATED';
export const ATTRIBUTE_DELETED = 'NOTIFICATION/ATTRIBUTE_DELETED';

// RELATIONS
export const ATTRIBUTE_CLASS_UPDATED = 'NOTIFICATION/ATTRIBUTE_CLASS_UPDATED';
export const ATTRIBUTE_CLASS_DELETED = 'NOTIFICATION/ATTRIBUTE_CLASS_DELETED';

export const KEYPOINT_SCHEMA_UPDATED = 'NOTIFICATION/KEYPOINT_SCHEMA_UPDATED';
export const KEYPOINT_SCHEMA_DELETED = 'NOTIFICATION/KEYPOINT_SCHEMA_DELETED';

// DATASETS
export const DATASET_DELETED = 'NOTIFICATION/DATASET_DELETED';

// TAG CLASSES
export const TAG_CLASS_ADDED = 'NOTIFICATION/TAG_CLASS_ADDED';
export const TAG_CLASS_DELETED = 'NOTIFICATION/TAG_CLASS_DELETED';
export const TAG_CLASS_MODIFIED = 'NOTIFICATION/TAG_CLASS_MODIFIED';

// NOTIFICATIONS
export const NOTIFICATION_SENT = 'NOTIFICATION/NOTIFICATION_SENT';

// ERROR FINDER
export const EF_RUN_STARTED = 'NOTIFICATION/EF_RUN_STARTED';
export const EF_RUN_TRAINING_STARTED = 'NOTIFICATION/EF_RUN_TRAINING_STARTED';
export const EF_RUN_TRAINING_PROGRESS = 'NOTIFICATION/EF_RUN_TRAINING_PROGRESS';
export const EF_RUN_DONE = 'NOTIFICATION/EF_RUN_DONE';
export const EF_RUN_FAILED = 'NOTIFICATION/EF_RUN_FAILED';
export const UPDATE_WS_RUN_PROGRESS = 'NOTIFICATION/EF_RUN_PROGRESS';

// IMPORTS
export const IMPORT_COMPLETED = 'NOTIFICATION/IMPORT_COMPLETED';
export const IMPORT_FAILED = 'NOTIFICATION/IMPORT_COMPLETED';
export const IMPORT_FILES_PARSED = 'NOTIFICATION/IMPORT_FILES_PARSED';
export const IMPORT_FILES_PARSING_FAILED =
  'NOTIFICATION/IMPORT_FILES_PARSING_FAILED';
