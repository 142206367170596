import { createSelector } from '@reduxjs/toolkit';

import { adapter } from './importFiles.slice';
import { ImportFile } from '../../../../../../api/domainModels/imports';
import { importParseLogsSelector } from '../parseLogs/importParseLogs.selectors';
import { ImportFileStatus } from '../../../../../../api/constants/import';
import { editedProjectSelector } from '../../editedProject.selectors';

const getFilesNames = (files: ImportFile[]) =>
  files.map((file) => file.fileName);

const getUniqFilesStatuses = (files: ImportFile[]) =>
  files.reduce((acc, file) => {
    const { fileStatus } = file;
    if (acc.includes(fileStatus)) {
      return acc;
    }

    return [...acc, fileStatus];
  }, [] as ImportFileStatus[]);

const importFilesStateSelector = createSelector(
  editedProjectSelector,
  (editedProject) => editedProject.imports.files,
);

const selectors = adapter.getSelectors(importFilesStateSelector);

export const importFilesSelector = selectors.selectAll;

export const importFilesIdsSelector = selectors.selectIds as (
  state: RootState,
) => string[];

export const importFileByIdSelector = selectors.selectById;

export const importFilesListLoadingStateSelector = createSelector(
  importFilesStateSelector,
  (state) => state.listLoadingState,
);

export const importFilesItemLoadingStateSelector = createSelector(
  importFilesStateSelector,
  (state) => state.itemLoadingState,
);

export const importFilesHasPngUploadedSelector = createSelector(
  importFilesSelector,
  importParseLogsSelector,
  (files, importParseLogs) => {
    if (files.length === 0) return false;

    return files.some((file) => {
      const { id: fileId, fileName } = file;

      const hasParseLogs = importParseLogs.find(
        ({ id: parsedFileId }) => parsedFileId === fileId,
      );

      return !hasParseLogs && fileName.endsWith('.png');
    });
  },
);

export const importFilesHasJsonUploadedSelector = createSelector(
  importFilesSelector,
  (files) => {
    if (files.length === 0) return false;
    const filesNames = getFilesNames(files);

    return filesNames.some(
      (fileName) => fileName && fileName.endsWith('.json'),
    );
  },
);

export const importedFilesCumulativeStatusSelector = createSelector(
  importFilesSelector,
  (files) => {
    const uniqStatuses = getUniqFilesStatuses(files);

    if (uniqStatuses.includes(ImportFileStatus.Parsing))
      return ImportFileStatus.Parsing;

    if (uniqStatuses.includes(ImportFileStatus.Uploaded))
      return ImportFileStatus.Uploaded;

    if (uniqStatuses.includes(ImportFileStatus.Failed))
      return ImportFileStatus.Failed;

    return ImportFileStatus.Processed;
  },
);
