/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Video status
 */
export enum VideoStatus {
  NEW = 'NEW',
  IN_PROGRESS = 'IN PROGRESS',
  TO_REVIEW = 'TO REVIEW',
  DONE = 'DONE',
  SKIPPED = 'SKIPPED',
  COMPLETED = 'COMPLETED',
}
