import { createSelector } from '@reduxjs/toolkit';

import { modelStatusChecks } from '../models/modelStatus.constants';
import { adapter } from './keypointsDetection.slice';
import { statusChecks } from '../../../../../../constants/status';
import { imageViewSelector } from '../../imageView.selectors';

const keypointsDetectionStateSelector = createSelector(
  imageViewSelector,
  (imageView) => imageView.tools.keypointsDetection,
);

export const keypointsDetectionDataSelector = createSelector(
  keypointsDetectionStateSelector,
  (keypointsDetection) => keypointsDetection.data,
);

const keypointsDetectionDataSelectors = adapter.getSelectors(
  keypointsDetectionDataSelector,
);

export const keypointsDetectionPredictedDataCountSelector =
  keypointsDetectionDataSelectors.selectTotal;

export const keypointsDetectionDetectedDataSelector =
  keypointsDetectionDataSelectors.selectAll;

export const keypointsDetectionDetectedDataByIdSelector =
  keypointsDetectionDataSelectors.selectById;

export const keypointsDetectionModelSelector = createSelector(
  keypointsDetectionStateSelector,
  (valuesState) => valuesState.model,
);

export const keypointsDetectionHasDetectionsSelector = createSelector(
  keypointsDetectionPredictedDataCountSelector,
  (count) => count > 0,
);

export const keypointsDetectionModelIdSelector = createSelector(
  keypointsDetectionModelSelector,
  (model) => model.id,
);

export const keypointsDetectionModelStatusSelector = createSelector(
  keypointsDetectionModelSelector,
  (model) => model.status,
);

export const keypointsDetectionModelAvailableSelector = createSelector(
  keypointsDetectionModelIdSelector,
  (modelId) => typeof modelId === 'number',
);

export const keypointsModelLoadedSelector = createSelector(
  [
    keypointsDetectionModelAvailableSelector,
    keypointsDetectionModelStatusSelector,
  ],
  (available, status) =>
    !!(available && status && modelStatusChecks.isLoaded(status)),
);

export const keypointsDetectionLoadingStateSelector = createSelector(
  keypointsDetectionDataSelector,
  (data) => data.loadingState,
);

export const keypointsDetectionIsLoadingSelector = createSelector(
  keypointsDetectionLoadingStateSelector,
  (loadingState) => statusChecks.isInProgress(loadingState.status),
);

export const keypointsDetectionInstanceThresholdSelector = createSelector(
  keypointsDetectionStateSelector,
  (state) => state.instanceThreshold,
);

export const keypointsDetectionKeypointThresholdSelector = createSelector(
  keypointsDetectionStateSelector,
  (state) => state.keypointThreshold,
);

export const keypointsDetectionMaxDetectionsSelector = createSelector(
  keypointsDetectionStateSelector,
  (state) => state.maxDetections,
);
