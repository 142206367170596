import { DefaultValue, atom, selector, selectorFamily } from 'recoil';

import { createRecoilEntityAdapterFamily } from '../../helpers/recoil/entityAdapter';
import {
  VideoPredictedSegment,
  VideoSegment,
} from '../../api/domainModels/video';
import { apiLoadSegments } from '../../api/requests/segment';
import { nullableProjectIdState } from './project';
import { nullableVideoIdState } from './video';
import { selectedActivityState } from './activity';
import { formatVideoMarker } from '../../containers/videoView/controls/helpers';
import { localStorageEffect } from '../../helpers/recoil/effects';
import { VIDEO_COPIED_SEGMENT_STORAGE_PREFIX } from '../../helpers/localStorage/constants';

export const segmentsCoil = createRecoilEntityAdapterFamily<
  VideoSegment,
  [string | null, string | null]
>({
  name: 'video/segments',
  paramState: selector({
    key: 'segmentsParam',
    get: ({ get }) => [get(nullableProjectIdState), get(nullableVideoIdState)],
  }),
  sortComparer: (a, b) => a.startTimeMs - b.startTimeMs,
  initialState: async ([projectId, videoId]) => {
    if (!projectId || !videoId) return [];

    try {
      const { data } = await apiLoadSegments(
        { params: { projectId, videoId } },
        { errorMessage: "Couldn't load segments" },
      );

      return data.items;
    } catch {
      return [];
    }
  },
});

export const selectedSegmentIdState = atom({
  key: 'video/segments/selectedId',
  default: null as string | null,
});

export const selectedSegmentState = selector<VideoSegment | null>({
  key: 'video/segments/selected',
  get: ({ get }) => {
    const id = get(selectedSegmentIdState);

    return id ? get(segmentsCoil.selectById(id)) || null : null;
  },
  set: ({ set }, value: DefaultValue | VideoSegment | null) => {
    if (value && 'id' in value) {
      set(selectedSegmentIdState, value.id);
    } else {
      set(selectedSegmentIdState, null);
    }
  },
});

export const selectedSegmentForLoopIdState = atom({
  key: 'video/segments/selectedForLoopId',
  default: null as string | null,
});

export const selectedSegmentForLoopState = selector<VideoSegment | null>({
  key: 'video/segments/selectedForLoop',
  get: ({ get }) => {
    const id = get(selectedSegmentForLoopIdState);

    return id ? get(segmentsCoil.selectById(id)) || null : null;
  },
  set: ({ set }, value: DefaultValue | VideoSegment | null) => {
    if (value && 'id' in value) {
      set(selectedSegmentForLoopIdState, value.id);
    } else {
      set(selectedSegmentForLoopIdState, null);
    }
  },
});

export const copiedSegmentIdState = atom<string | null>({
  key: 'video/segments/copiedId',
  default: null,
  effects_UNSTABLE: [
    localStorageEffect<string | null>(VIDEO_COPIED_SEGMENT_STORAGE_PREFIX),
  ],
});

export const copiedSegmentState = selector<VideoSegment | null>({
  key: 'video/segments/copied',
  get: ({ get }) => {
    const id = get(copiedSegmentIdState);

    return id ? get(segmentsCoil.selectById(id)) || null : null;
  },
  set: ({ set }, value: DefaultValue | VideoSegment | null) => {
    if (value && 'id' in value) {
      set(copiedSegmentIdState, value.id);
    } else {
      set(copiedSegmentIdState, null);
    }
  },
});

const editedSegmentIdState = atom<string | null>({
  key: 'video/segments/editedId',
  default: null,
});

export const editedSegmentState = selector<VideoSegment | null>({
  key: 'video/segments/editedSegment',
  get: ({ get }) => {
    const segmentId = get(editedSegmentIdState);

    if (!segmentId) {
      return null;
    }

    return get(segmentsCoil.selectById(segmentId)) || null;
  },
  set: ({ set }, value) => {
    set(editedSegmentIdState, value && 'id' in value ? value.id : null);
  },
});

export const hoveredSegmentIdState = atom({
  key: 'video/segments/hoveredId',
  default: null as string | null,
});

export const hoveredSegmentState = selector({
  key: 'video/segments/hovered',
  get: ({ get }) => {
    const id = get(hoveredSegmentIdState);

    if (!id) return null;
    const segment = get(segmentsCoil.selectById(id));

    return segment || null;
  },
  set: ({ set }, value: DefaultValue | VideoSegment | null) => {
    if (value && 'id' in value) {
      set(hoveredSegmentIdState, value.id);
    } else {
      set(hoveredSegmentIdState, null);
    }
  },
});

const toBeDeletedSegmentIdState = atom<string | null>({
  key: 'video/segments/toBeDeletedId',
  default: null,
});

export const toBeDeletedSegmentState = selector<VideoSegment | null>({
  key: 'video/segments/toBeDeletedSegment',
  get: ({ get }) => {
    const activityId = get(toBeDeletedSegmentIdState);

    if (!activityId) {
      return null;
    }

    return get(segmentsCoil.selectById(activityId)) || null;
  },
  set: ({ set }, value) => {
    set(toBeDeletedSegmentIdState, value && 'id' in value ? value.id : null);
  },
});

export const segmentsByActivityIdState = selectorFamily({
  key: 'currentVideo/segments/byActivityId',
  get:
    (activityId: string) =>
    ({ get }) => {
      const segments = get(segmentsCoil.selectAll);

      return segments.filter((segment) => segment.activities[0] === activityId);
    },
});

export const segmentByIdPrevSiblingState = selectorFamily({
  key: 'currentVideo/segments/byIdPrevSibling',
  get:
    (id: string) =>
    ({ get }) => {
      const segment = get(segmentsCoil.selectByIdOrThrow(id));
      const segments = get(segmentsByActivityIdState(segment.activities[0]));
      const index = segments.findIndex((s) => s.id === id);
      if (index === 0) {
        return null;
      }

      return segments[index - 1];
    },
});
export const segmentByIdNextSiblingState = selectorFamily({
  key: 'currentVideo/segments/byIdNextSibling',
  get:
    (id: string) =>
    ({ get }) => {
      const segment = get(segmentsCoil.selectByIdOrThrow(id));
      const segments = get(segmentsByActivityIdState(segment.activities[0]));
      const index = segments.findIndex((s) => s.id === id);
      if (index === segments.length - 1) {
        return null;
      }

      return segments[index + 1];
    },
});

export const segmentCreationLimitsState = atom({
  key: 'currentVideo/segments/segmentCreationLimits',
  default: [0, 0],
});

export const segmentCreationInProgressState = atom({
  key: 'currentVideo/segments/segmentCreationInProgress',
  default: false,
});

export const segmentCreationSortedLimitsState = selector({
  key: 'currentVideo/segments/segmentCreationSortedLimits',
  get: ({ get }) => {
    const limits = get(segmentCreationLimitsState);

    return [...limits].sort((a, b) => a - b);
  },
  set: ({ set }, value: number[] | DefaultValue) => {
    set(segmentCreationLimitsState, Array.isArray(value) ? value : [0, 0]);
  },
});

const calculatePossibleStartTimeMs = (
  _start: number,
  _end: number,
  segments: VideoSegment[],
) => {
  let start = _start;
  let segment: VideoSegment | undefined;
  do {
    if (start > _end) {
      return null;
    }
    segment = segments.find(
      // eslint-disable-next-line no-loop-func
      (s) => s.startTimeMs <= start && s.endTimeMs >= start,
    );
    if (!segment) {
      return start;
    }
    start = segment.endTimeMs + 1;
  } while (segment);

  return null;
};
const calculatePossibleEndTimeMs = (
  _start: number,
  _end: number,
  _segments: VideoSegment[],
) => {
  let end = _end;
  const segments = _segments
    .filter(
      (s) =>
        (s.startTimeMs >= _start && s.startTimeMs <= end) ||
        (s.endTimeMs >= _start && s.endTimeMs <= end),
    )
    .sort((s0, s1) => s0.startTimeMs - s1.startTimeMs);
  do {
    if (_start > end) {
      return null;
    }

    if (!segments.length) {
      return end;
    }
    end = segments[segments.length - 1].startTimeMs - 1;
    segments.pop();
    if (!segments.length) {
      return end;
    }
  } while (segments.length);

  return null;
};

export const segmentCreationPossibleLimitsState = selectorFamily({
  key: 'currentVideo/segments/segmentCreationPossibleLimits',
  get:
    (activityId: string) =>
    ({ get }) => {
      const [segmentCreationStart, segmentCreationEnd] = get(
        segmentCreationSortedLimitsState,
      );
      const segments = get(segmentsByActivityIdState(activityId));
      const startTimeMs = calculatePossibleStartTimeMs(
        segmentCreationStart,
        segmentCreationEnd,
        segments,
      );
      if (startTimeMs === null) {
        return null;
      }
      const endTimeMs = calculatePossibleEndTimeMs(
        startTimeMs,
        segmentCreationEnd,
        segments,
      );
      if (endTimeMs === null) {
        return endTimeMs;
      }

      return [startTimeMs, endTimeMs];
    },
});

export const currentActivitySegmentCreationPossibleLimitsState = selector({
  key: 'currentVideo/segments/segmentCreationPossibleLimits/current',
  get: ({ get }) => {
    const activity = get(selectedActivityState);
    if (!activity) {
      return null;
    }

    return segmentCreationPossibleLimitsState(activity.id);
  },
});

export const segmentCreationValidityState = selector<{
  type: 'valid' | 'invalid';
  message: string;
}>({
  key: 'currentVideo/segments/segmentCreationValidity',
  get: ({ get }) => {
    const activity = get(selectedActivityState);
    if (!activity) {
      return {
        type: 'invalid',
        message: 'Please select an activity.',
      };
    }
    const possibleLimits = get(segmentCreationPossibleLimitsState(activity.id));
    if (possibleLimits === null) {
      return {
        type: 'invalid',
        message: 'Same activities segments can’t overlap',
      };
    }

    if (possibleLimits[0] === possibleLimits[1]) {
      return {
        type: 'invalid',
        message: 'The selected interval is too short for a segment.',
      };
    }

    return {
      type: 'valid',
      message: `Create a segment of ${formatVideoMarker(
        possibleLimits[1] - possibleLimits[0],
        true,
      )}.`,
    };
  },
});

export const overlappedSegmentsByPredictedSegmentState = selectorFamily({
  key: 'currentVideo/segments/overlappedByPredictedSegment',
  get:
    (predictedSegment: VideoPredictedSegment) =>
    ({ get }) => {
      const segments = get(segmentsCoil.selectAll);

      return segments.reduce<VideoSegment[]>((prev, segment) => {
        if (
          segment.activities.includes(predictedSegment.activityTypeId) &&
          predictedSegment.startTimeMs <= segment.endTimeMs &&
          predictedSegment.endTimeMs >= segment.startTimeMs
        ) {
          return [...prev, segment];
        }

        return prev;
      }, []);
    },
});
