import { ModelFamily } from '../../../../../../api/constants/modelFamily';

export const MODEL_MESSAGES = {
  availability:
    'Object detection (J) is now available. You can select it in the toolbar.',
  progress: 'Loading object detection model',
  error: 'Not able to load object detection model',
};
export const OBJECT_DETECTION_FAMILY_NAME = ModelFamily.Detector;
export const OBJECT_DETECTION_MAX_OBJECTS_TO_SHOW = 1000;
export const OBJECT_DETECTION_MIN_OBJECTS_TO_SHOW = 0;
export const OBJECT_DETECTION_DEFAULT_Z_INDEX = 1;
export const OBJECT_DETECTION_DEFAULT_MAX_DETECTIONS = 100;
export const OBJECT_DETECTION_DEFAULT_CONFIDENCE = 30;
