import axios from 'axios';

import {
  ApiWorkspace,
  ApiLoadWorkspaceParams,
  ApiCreateWorkspaceData,
  ApiUpdateWorkspaceParams,
  ApiArchiveWorkspaceParams,
} from '../schemas/workspace';
import { apiWrapper } from '../utils';
import { API_URL } from '../../constants/paths';
import { headers, transformRequest, transformResponse } from '../helpers';

export const apiLoadWorkspaces = () =>
  apiWrapper(
    axios.request<ApiWorkspace[]>({
      method: 'get',
      headers,
      transformRequest,
      transformResponse,
      url: `${API_URL}/workspaces`,
    }),
  );

export const apiLoadWorkspace = (params: ApiLoadWorkspaceParams) =>
  apiWrapper(
    axios.request<ApiWorkspace>({
      method: 'get',
      headers,
      transformRequest,
      transformResponse,
      url: `${API_URL}/workspaces/${params.workspaceId}`,
    }),
  );

export const apiCreateWorkspace = (data: ApiCreateWorkspaceData) =>
  apiWrapper(
    axios.request<ApiWorkspace>({
      method: 'post',
      url: `${API_URL}/workspaces`,
      data,
      headers,
      transformResponse,
      transformRequest,
    }),
  );

export const apiUpdateWorkspace = (
  params: ApiUpdateWorkspaceParams,
  data: Partial<ApiWorkspace>,
) =>
  apiWrapper(
    axios.request<ApiWorkspace>({
      method: 'put',
      headers,
      transformRequest,
      transformResponse,
      url: `${API_URL}/workspaces/${params.workspaceId}`,
      data,
    }),
  );

export const apiArchiveWorkspace = (params: ApiArchiveWorkspaceParams) =>
  apiWrapper(
    axios.request({
      method: 'delete',
      url: `${API_URL}/workspaces/${params.workspaceId}`,
      headers,
    }),
  );
