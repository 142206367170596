import { select, takeEvery, put, takeLatest, call } from 'typed-redux-saga';

import { ImageLabel } from '../../../../../../api/domainModels/imageLabel';
import { LabelType } from '../../../../../../api/constants/label';
import {
  maxImageLabelZIndexSelector,
  newestLabelIdSelector,
  availableLabelsIdsSelector,
  minImageLabelZIndexSelector,
} from '../labels.selectors';
import { addLabels, deleteLabels, updateLabels } from '../labels.slice';
import {
  selectedLabelsCanBeBroughtToFrontSelector,
  selectedLabelsCanBeSentToBackSelector,
  selectedLabelsIdsSelector,
  selectedLabelsSelector,
  selectionContextMenuOptionsSelector,
  nextLabelIdToSelectedSelector,
  previousLabelIdToSelectedSelector,
} from './selectedLabels.selectors';
import {
  setSelectedLabelsIds,
  selectAllLabels,
  selectLastLabel,
  selectNextLabel,
  selectPreviousLabel,
  bringToFront,
  sendToBack,
  hideContextMenu,
  convertShape,
  mergePolygonsIntoSingleMask,
  resetSelection,
  mergeMasksIntoSingleMask,
} from './selectedLabels.slice';
import {
  convertMaskLabelToPolygon,
  convertPolygonLabelToMask,
  convertPolygonLabelsMultiToMask,
  convertMaskLabelsMultiToMask,
} from '../conversions';
import { setActiveTool } from '../../tools/tools.slice';
import { getErrorMessage } from '../../../../../../api/utils';
import { setImageId } from '../../currentImage/currentImage.slice';
import { consensusScoringEnabledSelector } from '../../consensusScoring/consensusScoring.selectors';
import { enqueueNotification } from '../../../../ui/stackNotifications/stackNotifications.slice';
import { uuidv4 } from '../../../../../../util/uuidv4';

function* syncDeletedLabels(action: ActionType<typeof deleteLabels>) {
  const selectedIds: string[] = yield* select(selectedLabelsIdsSelector);
  yield* put(
    setSelectedLabelsIds(
      selectedIds.filter((id) => !action.payload.includes(id)),
    ),
  );
}

function* zIndexHandler(direction: 'back' | 'front') {
  const selectedLabels: ImageLabel[] = yield* select(selectedLabelsSelector);
  const maxIndex: number = yield* select(maxImageLabelZIndexSelector);
  const minIndex: number = yield* select(minImageLabelZIndexSelector);

  const isEnabled =
    direction === 'back'
      ? yield* select(selectedLabelsCanBeSentToBackSelector)
      : yield* select(selectedLabelsCanBeBroughtToFrontSelector);

  if (!isEnabled) {
    return;
  }

  yield* put(
    updateLabels(
      selectedLabels.map(({ id, bbox, mask, polygon, classId }) => ({
        id,
        changes: {
          bbox,
          mask,
          polygon,
          classId,
          zIndex: direction === 'back' ? minIndex - 1 : maxIndex + 1,
        },
      })),
    ),
  );
}

function* syncWithContextMenu(action: ActionType<typeof setSelectedLabelsIds>) {
  const { visible } = yield* select(selectionContextMenuOptionsSelector);

  if (visible && action.payload.length === 0) {
    yield* put(hideContextMenu());
  }
}

// TODO:: AICS if labels selected and AICS toggled, deselect?
function* selectAllLabelsHandler() {
  const consensusScoringEnabled = yield* select(
    consensusScoringEnabledSelector,
  );
  if (consensusScoringEnabled) return;

  const labelIds: string[] = yield* select(availableLabelsIdsSelector);
  yield* put(setSelectedLabelsIds(labelIds));
}

function* selectLastLabelHandler(_action: ActionType<typeof selectLastLabel>) {
  const labelId = yield* select(newestLabelIdSelector);
  if (!labelId) return;
  yield* put(setSelectedLabelsIds([labelId]));
}

function* selectNextLabelHandler() {
  const nextLabelId = yield* select(nextLabelIdToSelectedSelector);
  if (!nextLabelId) return;

  yield* put(setSelectedLabelsIds([nextLabelId]));
}

function* selectPreviousLabelHandler() {
  const previousLabelId = yield* select(previousLabelIdToSelectedSelector);

  yield* put(setSelectedLabelsIds([previousLabelId]));
}
/**
 * Does Polygon <-> Mask conversion
 */
function* convertShapeHandler(action: ActionType<typeof convertShape>) {
  const { labels } = action.payload;

  const convertedLabels = [];

  try {
    for (const label of labels) {
      let conversionResult = {};

      const isMaskLabel = label.type === LabelType.Mask;
      const isPolygonLabel = label.type === LabelType.Polygon;

      // If label's current type is polygon
      // conversion to mask was requested
      if (isPolygonLabel) {
        // Do Polygon -> Mask conversion
        const { mask, bbox } = yield* convertPolygonLabelToMask({
          points: label.polygon,
        });

        // Reset polygon points, and add
        // both mask and bounding box points
        conversionResult = {
          mask,
          bbox,
          polygon: null,
          type: LabelType.Mask,
        };
        // If label's current type is mask, conversion to polygon was requested
      } else if (isMaskLabel) {
        // Do Mask -> Polygon conversion
        const points = yield* convertMaskLabelToPolygon({
          bbox: label.bbox,
          mask: label.mask,
        });

        // Reset points of other shapes
        // and only add polygon points
        conversionResult = {
          mask: null,
          borderData: null,
          bbox: label.bbox,
          polygon: points,
          type: LabelType.Polygon,
        };
      }

      // Collect updates
      convertedLabels.push({
        id: label.id,
        changes: conversionResult,
      });
    }

    if (convertedLabels.length) {
      yield* put(updateLabels(convertedLabels));
    }
  } catch (error) {
    const message = getErrorMessage(error, 'Unable to convert shape');

    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          error,
        },
      }),
    );
  }
}

function* mergeIntoSingleMaskHandler(
  action: ActionType<
    typeof mergePolygonsIntoSingleMask | typeof mergeMasksIntoSingleMask
  >,
) {
  const { labels } = action.payload;

  try {
    const { mask, bbox }: any =
      action.type === mergePolygonsIntoSingleMask.type
        ? yield* call(convertPolygonLabelsMultiToMask, {
            pointsList: labels.map((label) => label.polygon),
          })
        : yield* call(convertMaskLabelsMultiToMask, {
            labelsList: labels,
          });

    yield* put(deleteLabels(labels.map((l) => l.id)));
    const id = uuidv4();
    yield* put(
      addLabels([
        {
          id,
          mask,
          bbox,
          polygon: null,
          type: LabelType.Mask,
          classId: labels.find((l) => l.classId)?.classId, // pick the first non-null label class
        },
      ]),
    );
    yield* put(setSelectedLabelsIds([id]));
  } catch (error) {
    const message = getErrorMessage(error, 'Unable to convert shape');

    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          error,
        },
      }),
    );
  }
}

function* setActiveToolHandler() {
  yield* put(hideContextMenu());
}

function* setImageIdHandler() {
  yield* put(resetSelection());
}

export function* selectedLabelsSaga() {
  yield* takeEvery(deleteLabels, syncDeletedLabels);
  yield* takeEvery(bringToFront, zIndexHandler, 'front');
  yield* takeEvery(sendToBack, zIndexHandler, 'back');
  yield* takeEvery(setSelectedLabelsIds, syncWithContextMenu);
  yield* takeEvery(selectAllLabels, selectAllLabelsHandler);
  yield* takeEvery(selectLastLabel, selectLastLabelHandler);
  yield* takeEvery(selectNextLabel, selectNextLabelHandler);
  yield* takeEvery(selectPreviousLabel, selectPreviousLabelHandler);
  yield* takeLatest(setActiveTool, setActiveToolHandler);
  yield* takeLatest(convertShape, convertShapeHandler);
  yield* takeLatest(
    [mergePolygonsIntoSingleMask.type, mergeMasksIntoSingleMask.type],
    mergeIntoSingleMaskHandler,
  );
  yield* takeLatest(setImageId, setImageIdHandler);
}
