import { createSelector } from '@reduxjs/toolkit';
import groupBy from 'lodash/groupBy';

import { tagClassesAdapter } from './tagClasses.slice';

const tagClassesStateSelector = (state: RootState) =>
  state.project.annotationTaxonomy.tagClasses;
const selectors = tagClassesAdapter.getSelectors(tagClassesStateSelector);

export const tagClassesTotalSelector = selectors.selectTotal;
export const tagClassesSelector = selectors.selectAll;
export const tagClassByIdSelector = selectors.selectById;
export const tagClassesMapSelector = selectors.selectEntities;

export const tagClassesListLoadingStateSelector = createSelector(
  tagClassesStateSelector,
  (state) => state.listLoadingState,
);
export const tagClassesItemLoadingStateSelector = createSelector(
  tagClassesStateSelector,
  (state) => state.itemLoadingState,
);

export const tagClassesGroupedMapSelector = createSelector(
  tagClassesSelector,
  (classes) => groupBy(classes, 'groupId'),
);
