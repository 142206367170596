import { Action } from '@reduxjs/toolkit';

import { goToAutomatedLabeling } from '../../../sections/editedProject/project/project.slice';
import { ATOM_DOCS } from '../../../../../constants/paths';

// These constants have to be in sync with backend
export enum ImageTool {
  Pan = 'pan',
  Selection = 'selection',
  InstanceSegmentation = 'instance_segmentation',
  SemanticSegmentation = 'semantic_segmentation',
  BoxToInstance = 'box2mask',
  Polygon = 'polygon',
  Bbox = 'bbox',
  Drawing = 'brush',
  AutomatedLabeling = 'automated_labeling',
  Atom = 'atom',
  Copy = 'copy',
  Default = 'selection',
  ObjectDetection = 'object_detection',
  LabelClassification = 'label_classification',
  ImageTagsPrediction = 'image_tags_prediction',
  AttributesPrediction = 'attributes_prediction',
  Keypoints = 'keypoints',
  KeypointsDetection = 'keypoint_detection',
  TextPrompt = 'text_to_mask',
}

export type ImageViewTool =
  | typeof ImageTool.Pan
  | typeof ImageTool.Selection
  | typeof ImageTool.ObjectDetection
  | typeof ImageTool.InstanceSegmentation
  | typeof ImageTool.SemanticSegmentation
  | typeof ImageTool.BoxToInstance
  | typeof ImageTool.Polygon
  | typeof ImageTool.Bbox
  | typeof ImageTool.Drawing
  | typeof ImageTool.AutomatedLabeling
  | typeof ImageTool.Atom
  | typeof ImageTool.Keypoints
  | typeof ImageTool.KeypointsDetection
  | typeof ImageTool.TextPrompt;

export const ImageToolTitleMap: Record<ImageTool, string> = {
  [ImageTool.Pan]: 'Pan',
  [ImageTool.Selection]: 'Selection',
  [ImageTool.ObjectDetection]: 'Object Detection',
  [ImageTool.InstanceSegmentation]: 'Instance Segmentation',
  [ImageTool.SemanticSegmentation]: 'Semantic Segmentation',
  [ImageTool.BoxToInstance]: 'Box to instance',
  [ImageTool.Polygon]: 'Polygon',
  [ImageTool.Bbox]: 'Bounding Box',
  [ImageTool.Drawing]: 'Brush',
  [ImageTool.AutomatedLabeling]: 'Automated labeling',
  [ImageTool.Atom]: 'Atom',
  [ImageTool.ImageTagsPrediction]: 'Tag class prediction',
  [ImageTool.AttributesPrediction]: 'Attributes prediction',
  [ImageTool.LabelClassification]: 'Label Classification',
  [ImageTool.Keypoints]: 'Keypoints',
  [ImageTool.KeypointsDetection]: 'Keypoints detection',
  [ImageTool.TextPrompt]: 'Text prompt',
  [ImageTool.Copy]: '',
};

export type ImageToolName = keyof typeof ImageToolTitleMap;
export type SidebarTool = {
  name: ImageToolName;
  largeIcon?: boolean;
  customHandler?: () => Action;
  learnMoreUrl?: string;
};
type SidebarGroup = {
  groupTitle: string;
  groupTools: Array<SidebarTool>;
};

export const SERVICES_GROUP_TITLE = 'Services';
export const GROUPED_TOOLS: SidebarGroup[] = [
  {
    groupTitle: '',
    groupTools: [
      {
        name: ImageTool.Pan,
      },
      {
        name: ImageTool.Selection,
      },
    ],
  },
  {
    groupTitle: SERVICES_GROUP_TITLE,
    groupTools: [
      {
        name: ImageTool.AutomatedLabeling,
        largeIcon: true,
        customHandler: goToAutomatedLabeling,
      },
    ],
  },
  {
    groupTitle: 'Prompts',
    groupTools: [
      {
        name: ImageTool.Atom,
        largeIcon: true,
        learnMoreUrl: ATOM_DOCS,
      },
      { name: ImageTool.TextPrompt, largeIcon: true },
      {
        name: ImageTool.BoxToInstance,
        largeIcon: true,
      },
    ],
  },
  {
    groupTitle: 'AI assistants',
    groupTools: [
      {
        name: ImageTool.ObjectDetection,
        largeIcon: true,
      },
      {
        name: ImageTool.InstanceSegmentation,
        largeIcon: true,
      },
      {
        name: ImageTool.SemanticSegmentation,
        largeIcon: true,
      },
      { name: ImageTool.KeypointsDetection, largeIcon: true },
    ],
  },
  {
    groupTitle: 'Manual Tools',
    groupTools: [
      { name: ImageTool.Keypoints, largeIcon: true },
      {
        name: ImageTool.Polygon,
      },
      {
        name: ImageTool.Bbox,
      },
      {
        name: ImageTool.Drawing,
      },
    ],
  },
];

// add more keys here for other cases, then make sure to check them in the global hotkeys handler
export const TOOL_HOTKEY_OVERRIDES: Partial<
  Record<
    ImageToolName,
    {
      undoRedo?: boolean;
      escape?: boolean;
      e?: boolean;
      b?: boolean;
      q?: boolean;
    }
  >
> = {
  [ImageTool.Atom]: {
    undoRedo: true,
    escape: true,
  },
  [ImageTool.Polygon]: {
    undoRedo: true,
    escape: true,
  },
  [ImageTool.Drawing]: {
    e: true,
    b: true,
    undoRedo: true,
    escape: true,
  },
  [ImageTool.Bbox]: { escape: true },
  [ImageTool.Keypoints]: { escape: true, undoRedo: true, q: true },
};
