import axios from 'axios';

import {
  ApiAPIKey,
  ApiCreateProfileApiKeyParams,
  ApiDeleteProfileApiKeyParams,
  ApiUpdateProfileApiKeyParams,
  ApiLoadProfileApiKeysParams,
} from '../schemas/apiKey';
import { API_URL } from '../../constants/paths';
import { apiWrapper } from '../utils';
import { headers, transformRequest, transformResponse } from '../helpers';

export const apiCreateProfileApiKey = (
  params: ApiCreateProfileApiKeyParams,
  data: Partial<ApiAPIKey>,
) =>
  apiWrapper(
    axios.request<ApiAPIKey>({
      method: 'post',
      headers,
      transformRequest,
      transformResponse,
      url: `${API_URL}/profile/${params.profileId}/api_key`,
      data,
    }),
  );

export const apiDeleteProfileApiKey = (params: ApiDeleteProfileApiKeyParams) =>
  apiWrapper(
    axios.request<ApiAPIKey>({
      method: 'delete',
      headers,
      transformRequest,
      transformResponse,
      url: `${API_URL}/profile/${params.profileId}/api_key/${params.apiKeyId}`,
    }),
  );

export const apiUpdateProfileApiKey = (
  params: ApiUpdateProfileApiKeyParams,
  data: Partial<ApiAPIKey>,
) =>
  apiWrapper(
    axios.request<ApiAPIKey>({
      method: 'put',
      headers,
      transformRequest,
      transformResponse,
      url: `${API_URL}/profile/${params.profileId}/api_key/${params.apiKeyId}`,
      data,
    }),
  );

export const apiLoadProfileApiKeys = (params: ApiLoadProfileApiKeysParams) =>
  apiWrapper(
    axios.request<ApiAPIKey[]>({
      headers,
      transformRequest,
      transformResponse,
      url: `${API_URL}/profile/${params.profileId}/api_keys`,
    }),
  );
