import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { loadingStateBuilder } from '../../../../../utils/loadingState';
import {
  ModelStatus,
  modelStatusChecks,
  ToolModel,
} from '../models/modelStatus.constants';

type AutomatedLabelingState = {
  model: ToolModel;
};

export type UpdateAutomatedLabelingModel = {
  id: number | null;
  status: ModelStatus;
  progress: number | null;
};

// we derive this state from OD/IS/SES/Tagger models (if any activated => AL is activated)
// no real load model from BE like other tools
const initialState: AutomatedLabelingState = {
  model: {
    id: null,
    loadingState: loadingStateBuilder.initial(),
    status: null,
    progress: null,
  },
};

export const { actions, reducer } = createSlice({
  name: 'automatedLabeling',
  initialState,
  reducers: {
    updateAutomatedLabelingModelFromAssistantStatuses(
      state,
      action: PayloadAction<UpdateAutomatedLabelingModel[]>,
    ) {
      state.model.loadingState = loadingStateBuilder.success();

      const assistantStatuses = action.payload;

      const model = assistantStatuses.reduce(
        (acc, { id, status }) => ({
          ...acc,
          ...(modelStatusChecks.isLoaded(status) ? { id, status } : {}),
        }),
        {
          id: null,
          status: null,
          progress: 0,
        } as ToolModel,
      );

      state.model = {
        ...state.model,
        ...model,
      };
    },
    updateAutomatedLabelingModelFromToolModels(
      state,
      action: PayloadAction<UpdateAutomatedLabelingModel>,
    ) {
      const model = action.payload;

      state.model = {
        ...state.model,
        ...(modelStatusChecks.isLoaded(model.status)
          ? { ...model, loadingState: loadingStateBuilder.success() }
          : {}),
      };
    },
    resetModelState(state) {
      state.model = initialState.model;
    },
  },
});

export const {
  updateAutomatedLabelingModelFromToolModels,
  updateAutomatedLabelingModelFromAssistantStatuses,
  resetModelState,
} = actions;
export const automatedLabelingReducer = reducer;
