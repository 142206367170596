import { createSelector } from '@reduxjs/toolkit';

import { commonFeaturesSelector } from '../commonFeatures.selectors';

export const authSelector = createSelector(
  commonFeaturesSelector,
  (commonFeatures) => commonFeatures.auth,
);

export const authProfileUpdateLoadingStateSelector = createSelector(
  authSelector,
  (auth) => auth.profileUpdateLoadingState,
);

export const authProfileLoadingStateSelector = createSelector(
  authSelector,
  (auth) => auth.profileLoadingState,
);

export const authUsernameSelector = createSelector(
  authSelector,
  (auth) => auth.username,
);

export const authAcceptTermsSelector = createSelector(
  authSelector,
  (auth) => auth.acceptTerms,
);

export const authUserIdSelector = createSelector(
  authSelector,
  (auth) => auth.userId,
);

export const authSettingsSelector = createSelector(
  authSelector,
  (auth) => auth.settings,
);

export const authLoadingStateSelector = createSelector(
  authSelector,
  (auth) => auth.loadingState,
);

export const authFeaturesSelector = createSelector(
  authSelector,
  (auth) => auth.features,
);

export const authFeaturesLoadingStateSelector = createSelector(
  authSelector,
  (auth) => auth.updateFeaturesLoadingState,
);

export const authEmailSelector = createSelector(
  authSelector,
  (auth) => auth.email,
);

export const authLogoutTimeSelector = createSelector(
  authSelector,
  (auth) => auth.logoutTime,
);

export const authABGroupSelector = createSelector(
  authSelector,
  (auth) => auth.abGroup,
);

export const authLookupsSelector = createSelector(
  authSelector,
  (auth) => auth.lookups,
);
