import axios from 'axios';

import {
  ApiLabelClass,
  ApiDeleteProjectLabelClassParams,
  ApiUpdateProjectLabelClassParams,
  ApiLoadProjectLabelClassesParams,
  ApiLoadProjectLabelClassesResponse,
  ApiCreateProjectLabelClassParams,
} from '../schemas/labelClass';
import { apiWrapper } from '../utils';
import { NEW_API_URL } from '../../constants/paths';
import { headers, transformRequest, transformResponse } from '../helpers';

export const apiDeleteProjectLabelClass = (
  params: ApiDeleteProjectLabelClassParams,
) =>
  apiWrapper(
    axios.request<ApiLabelClass>({
      method: 'delete',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/label_classes/${params.labelClassId}`,
    }),
  );

export const apiUpdateProjectLabelClass = (
  params: ApiUpdateProjectLabelClassParams,
  data: Partial<ApiLabelClass>,
) =>
  apiWrapper(
    axios.request<ApiLabelClass>({
      method: 'put',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/label_classes/${params.labelClassId}`,
      data,
    }),
  );

export const apiLoadProjectLabelClasses = (
  params: ApiLoadProjectLabelClassesParams,
) =>
  apiWrapper(
    axios.request<ApiLoadProjectLabelClassesResponse>({
      method: 'get',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/label_classes`,
      params: {
        offset: params.offset,
        limit: params.limit,
      },
    }),
  );

export const apiCreateProjectLabelClass = (
  params: ApiCreateProjectLabelClassParams,
  data: Partial<ApiLabelClass>,
) =>
  apiWrapper(
    axios.request<ApiLabelClass>({
      method: 'post',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/label_classes`,
      data,
    }),
  );
