import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ImageLabel } from '../../../../../api/domainModels/imageLabel';

const { actions, reducer: clipboardReducer } = createSlice({
  name: 'imageViewClipboard',
  initialState: {
    data: [] as Array<Partial<ImageLabel>>,
    sourceImageId: null as string | null,
  },
  reducers: {
    setClipboard(
      state,
      action: PayloadAction<{
        data: Array<Partial<ImageLabel>>;
        imageId: string;
      }>,
    ) {
      const { data, imageId } = action.payload;
      state.data = data;
      state.sourceImageId = imageId;
    },
    cutSelectedLabels(_state, _action: PayloadAction<{ imageId: string }>) {},
    copySelectedLabels(_state, _action: PayloadAction<{ imageId: string }>) {},
    duplicateSelectedLabels(
      _state,
      _action: PayloadAction<{
        imageWidth: number;
        imageHeight: number;
      }>,
    ) {},
    pasteLabelsFromClipboard(
      _state,
      _action: PayloadAction<{
        imageId: string;
        imageWidth: number;
        imageHeight: number;
      }>,
    ) {},
  },
});

export const {
  setClipboard,
  cutSelectedLabels,
  copySelectedLabels,
  duplicateSelectedLabels,
  pasteLabelsFromClipboard,
} = actions;
export { clipboardReducer };
