import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const { actions, reducer: imageViewImageAttributesPredictionToggleReducer } =
  createSlice({
    name: 'imageViewImageAttributesPredictionToggle',
    initialState: {} as Record<string /* projectId */, boolean>,
    reducers: {
      toggleAttributesPrediction(
        state,
        action: PayloadAction<string /* projectId */>,
      ) {
        const currentValue = state[action.payload];

        state[action.payload] = !currentValue;
      },
    },
  });

export { imageViewImageAttributesPredictionToggleReducer };
export const { toggleAttributesPrediction } = actions;
