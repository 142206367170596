import { combineReducers } from 'redux';

import { projectUsersReducer } from './projectUsers/projectUsers.slice';
import { annotationTaxonomyReducer } from './annotationTaxonomy/annotationTaxonomy.reducer';
import { projectOperationsReducer } from './operations/operations.slice';
import { projectInfoReducer } from './projectInfo/projectInfo.slice';
import { inferenceMonitoringReducer } from './inferenceMonitoring/inferenceMonitoring.reducer';
import { fileManagerReducer } from './fileManager/fileManager.reducer';
import { heuristicsReducer } from './heuristics/heuristics.slice';
import { heuristicsRankingJobsReducer } from './heuristicsRankingJobs/heuristicsRankingJobs.slice';
import { automatedLabelingReducer } from './automatedLabeling/automatedLabeling.slice';
import { jobProgressReducer } from './jobProgress/jobProgress.slice';

export const projectReducer = combineReducers({
  projectInfo: projectInfoReducer,
  projectUsers: projectUsersReducer,
  annotationTaxonomy: annotationTaxonomyReducer,
  operations: projectOperationsReducer,
  inferenceMonitoring: inferenceMonitoringReducer,
  fileManager: fileManagerReducer,
  heuristics: heuristicsReducer,
  heuristicsRankingJobs: heuristicsRankingJobsReducer,
  automatedLabeling: automatedLabelingReducer,
  jobProgress: jobProgressReducer,
});
