import { SagaGenerator, all, call } from 'typed-redux-saga';
import { AxiosResponse } from 'axios';

import {
  ApiKeysetPaginated,
  ApiKeysetParams,
  ApiPaginated,
} from '../../api/helpers';

export function loadAll<R>({
  params = {},
  requestData = {},
  fromBackend,
  apiHelper,
}: {
  params?: object;
  requestData?: object;
  fromBackend: (...args: any) => R;
  apiHelper: (...args: any) => any;
}): Generator<unknown, R[]>;

// eslint-disable-next-line no-redeclare
export function loadAll<R>({
  params = {},
  requestData = {},
  apiHelper,
}: {
  params?: object;
  requestData?: object;
  apiHelper: (...args: any) => Generator<any, AxiosResponse<ApiPaginated<R[]>>>;
}): Generator<unknown, R[]>;

// eslint-disable-next-line no-redeclare
export function* loadAll<R>({
  params = {},
  requestData = {},
  fromBackend = (entity: any) => entity,
  apiHelper,
}: {
  params?: object;
  requestData?: object;
  fromBackend?: (...args: any) => R;
  apiHelper: (
    ...args: any
  ) => Generator<any, AxiosResponse<ApiPaginated<unknown[]>>>;
}) {
  const response = yield* call(apiHelper, params, requestData);
  const { data } = response;

  const entities = [...data.items.map(fromBackend)];

  const { limit, total } = data.meta;
  const additionalEffects: SagaGenerator<
    AxiosResponse<ApiPaginated<unknown[]>>
  >[] = [];
  for (let i = 1; i <= Math.ceil(total / limit) - 1; i++) {
    additionalEffects.push(
      call(
        apiHelper,
        {
          ...params,
          offset: limit * i,
          limit,
        },
        requestData,
      ),
    );
  }
  const responses = yield* all(additionalEffects);
  const additionalEntities: R[] = responses
    .map((additionalResponse) => {
      const { data } = additionalResponse;

      return data.items.map(fromBackend);
    })
    .flat();

  return [...entities, ...additionalEntities];
}

export function* loadAllKeysetPagination<R>(
  fn: (...args: any) => any,
  arg: ApiKeysetParams<object>,
) {
  const items: R[] = [];
  let params: Partial<ApiKeysetParams<object>> = {};

  let hasNextPage = true;
  while (hasNextPage) {
    const args = [{ ...arg, ...params }] as Parameters<(...args: any) => any>;

    const response = yield* call(fn, ...args);

    const { data } = response as { data: ApiKeysetPaginated<R> };

    hasNextPage = data.items.length >= data.meta.limit;
    items.push(...data.items);
    params = {
      page: 'NEXT',
      cursor: data.meta.cursorNext,
      limit: data.meta.limit,
    };
  }

  return items;
}

export type ProcessFileParams = {
  projectId: string;
  sessionId: string;
  files: any[];
};

type SplitUploadsToChunksParams = ProcessFileParams & {
  chunkLength: number;
  processFileChunk: (params: ProcessFileParams) => Generator<unknown, boolean>;
};

export function* splitUploadsToChunks(params: SplitUploadsToChunksParams) {
  const { projectId, sessionId, files, processFileChunk, chunkLength } = params;

  let index = 0;

  do {
    const filesArray = files.slice(index, index + chunkLength);

    const isProcessed = yield* call(processFileChunk, {
      projectId,
      sessionId,
      files: filesArray,
    });

    if (isProcessed) {
      index += chunkLength;
    }
  } while (index < files.length);
}
