import { createSelector } from '@reduxjs/toolkit';

import { routerSelector } from '../../root.selectors';
import { sectionsSelector } from '../sections.selectors';

export const editedProjectSelector = createSelector(
  sectionsSelector,
  (sections) => sections.editedProject,
);

export const editedProjectDataSelector = createSelector(
  editedProjectSelector,
  (editedProject) => editedProject.project,
);

export const editedProjectContentTypeSelector = createSelector(
  editedProjectSelector,
  (editedProject) => editedProject.project.data?.contentType,
);

export const editedProjectIsProSelector = createSelector(
  editedProjectSelector,
  (editedProject) => !!editedProject.project.data?.isPro,
);
export const editedProjectNameSelector = createSelector(
  editedProjectSelector,
  (editedProject) => editedProject.project.data?.projectName,
);

export const editedProjectUpdateLoadingStateSelector = createSelector(
  editedProjectSelector,
  (editedProject) => editedProject.project.updateLoadingState,
);

export const editedProjectProjectLoadingStateSelector = createSelector(
  editedProjectSelector,
  (editedProject) => editedProject.project.loadingState,
);

export const editedProjectProjectDataSelector = createSelector(
  editedProjectSelector,
  (editedProject) => editedProject.project.data,
);

export const isProjectCreationSelector = (state: RootState) =>
  !!routerSelector(state).location.pathname.match(/create$/);
