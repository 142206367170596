import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
  Update,
} from '@reduxjs/toolkit';

import { loadingStateBuilder } from '../../../../../utils/loadingState';
import { ExperimentParameter } from '../../../../../../api/domainModels/modelPlayground';
import { setActiveExperimentId } from '../activeExperimentId/activeExperimentId.slice';
import { setEditedProjectId } from '../../../../sections/editedProject/project/project.slice';

export const parametersAdapter = createEntityAdapter<ExperimentParameter>();
const initialState = {
  parameters: parametersAdapter.getInitialState({
    loadingState: loadingStateBuilder.initial(),
    updateLoadingState: loadingStateBuilder.initial(),
  }),
  sslTabAvailable: false,
};

const { actions, reducer: SSLReducer } = createSlice({
  name: 'SSL',
  initialState,
  reducers: {
    updateSSLParameter(
      state,
      _action: PayloadAction<{
        semiSupervisedParameterId: string;
        value: number;
      }>,
    ) {
      state.parameters.updateLoadingState = loadingStateBuilder.inProgress();
    },
    updateSSLParameterFailure(state) {
      state.parameters.updateLoadingState = loadingStateBuilder.failure();
    },
    updateSSLParameterSuccess(
      state,
      action: PayloadAction<Update<ExperimentParameter>>,
    ) {
      state.parameters.updateLoadingState = loadingStateBuilder.success();
      parametersAdapter.updateOne(state.parameters, action.payload);
    },
    loadSSLParameters(state) {
      state.parameters.loadingState = loadingStateBuilder.inProgress();
    },
    loadSSLParametersFailure(state, action: PayloadAction<string>) {
      state.parameters.loadingState = loadingStateBuilder.failure(
        action.payload,
      );
    },
    loadSSLParametersSuccess(
      state,
      action: PayloadAction<ExperimentParameter[]>,
    ) {
      state.parameters.loadingState = loadingStateBuilder.success();
      parametersAdapter.setAll(state.parameters, action.payload);
    },
    setSSLTabAvailable(state, action: PayloadAction<boolean>) {
      state.sslTabAvailable = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setActiveExperimentId, () => initialState);
    builder.addCase(setEditedProjectId, () => initialState);
  },
});

export const {
  updateSSLParameter,
  updateSSLParameterFailure,
  updateSSLParameterSuccess,
  loadSSLParameters,
  loadSSLParametersFailure,
  loadSSLParametersSuccess,
  setSSLTabAvailable,
} = actions;
export { SSLReducer };
