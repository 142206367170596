import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { WidgetTrainingParamsVisualization } from '../../../../../../../api/domainModels/widgets';
import { loadingStateBuilder } from '../../../../../../utils/loadingState';
import { setEditedProjectId } from '../../../../../sections/editedProject/project/project.slice';

const itemsInitialState: WidgetTrainingParamsVisualization = {
  trainData: {
    x: [],
    y: [],
  },
  validationData: {
    x: [],
    y: [],
  },
  patience: {
    x0: 0,
    x1: 0,
  },
};
const initialState = {
  loadingState: loadingStateBuilder.initial(),
  items: itemsInitialState,
};

const { actions, reducer: trainParamsVisualizationReducer } = createSlice({
  name: 'widgets/trainParamsVisualization',
  initialState,
  reducers: {
    loadTrainingParamsVisualization(state) {
      state.loadingState = loadingStateBuilder.inProgress();
    },
    loadTrainingParamsVisualizationFailure(
      state,
      action: PayloadAction<string>,
    ) {
      state.loadingState = loadingStateBuilder.failure(action.payload);
      state.items = itemsInitialState;
    },
    loadTrainingParamsVisualizationSuccess(
      state,
      action: PayloadAction<WidgetTrainingParamsVisualization>,
    ) {
      state.loadingState = loadingStateBuilder.success();
      state.items = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setEditedProjectId, () => initialState);
  },
});

export { trainParamsVisualizationReducer };
export const {
  loadTrainingParamsVisualization,
  loadTrainingParamsVisualizationFailure,
  loadTrainingParamsVisualizationSuccess,
} = actions;
