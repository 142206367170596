import { ApiAPIKey } from '../schemas/apiKey';

const fromBackend = ({
  apiKeyId,
  apiKeyName,
  profileId,
  ...entry
}: ApiAPIKey) => ({
  id: apiKeyId,
  name: apiKeyName,
  serviceAccountId: profileId,
  ...entry,
});

export type ApiKey = ReturnType<typeof fromBackend>;

const toBackend = ({
  id,
  name,
  serviceAccountId,
  ...entry
}: Partial<ApiKey>): Partial<ApiAPIKey> => ({
  apiKeyId: id,
  apiKeyName: name,
  profileId: serviceAccountId,
  ...entry,
});

export const apiKeyDataMapper = {
  toBackend,
  fromBackend,
};
