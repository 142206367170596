import { ApiProjectUser } from '../schemas/projectUsers';

const fromBackend = ({ userId, ...entry }: ApiProjectUser) => ({
  id: userId as string,
  ...entry,
});

export type ProjectUser = ReturnType<typeof fromBackend>;

export const projectUsersDataMapper = {
  fromBackend,
};
