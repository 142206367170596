import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
  Update,
} from '@reduxjs/toolkit';

import { TagGroup } from '../../../../../api/domainModels/tagGroup';
import { loadingStateBuilder } from '../../../../utils/loadingState';

export type UpdateTagGroupFormValues = Pick<TagGroup, 'name' | 'type' | 'id'>;
export type AddTagGroupFormValues = Pick<TagGroup, 'name' | 'type'>;

export const tagGroupsAdapter = createEntityAdapter<TagGroup>();

const initialState = tagGroupsAdapter.getInitialState({
  itemLoadingState: loadingStateBuilder.initial(),
  listLoadingState: loadingStateBuilder.initial(),
});

const { actions, reducer: tagGroupsReducer } = createSlice({
  name: 'tagGroups',
  initialState,
  reducers: {
    addTagGroup(state, _action: PayloadAction<AddTagGroupFormValues>) {
      state.itemLoadingState = loadingStateBuilder.inProgress();
    },
    addTagGroupFailure(state, action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.failure(action.payload);
    },
    addTagGroupSuccess(state, action: PayloadAction<TagGroup>) {
      state.itemLoadingState = loadingStateBuilder.success();
      tagGroupsAdapter.addOne(state, action.payload);
    },
    updateTagGroup(state, _action: PayloadAction<UpdateTagGroupFormValues>) {
      state.itemLoadingState = loadingStateBuilder.inProgress();
    },
    updateTagGroupFailure(
      state,
      action: PayloadAction<{ message: string; id: string }>,
    ) {
      state.itemLoadingState = loadingStateBuilder.failure(
        action.payload.message,
      );
    },
    updateTagGroupSuccess(state, action: PayloadAction<Update<TagGroup>>) {
      state.itemLoadingState = loadingStateBuilder.success();
      tagGroupsAdapter.updateOne(state, action.payload);
    },
    removeTagGroup(state, _action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.inProgress();
    },
    removeTagGroupFailure(state, action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.failure(action.payload);
    },
    removeTagGroupSuccess(state, action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.success();
      tagGroupsAdapter.removeOne(state, action.payload);
    },
    loadTagGroupsStart(state) {
      state.listLoadingState = loadingStateBuilder.inProgress();
    },
    loadTagGroupsFailure(state, action: PayloadAction<string>) {
      state.listLoadingState = loadingStateBuilder.failure(action.payload);
      tagGroupsAdapter.setAll(state, []);
    },
    loadTagGroupsSuccess(state, action: PayloadAction<TagGroup[]>) {
      state.listLoadingState = loadingStateBuilder.success();
      tagGroupsAdapter.setAll(state, action.payload);
    },
    resetTagGroupLoadingState(state) {
      state.itemLoadingState = loadingStateBuilder.initial();
    },
  },
});

export { tagGroupsReducer };
export const {
  addTagGroup,
  addTagGroupFailure,
  addTagGroupSuccess,
  updateTagGroup,
  updateTagGroupFailure,
  updateTagGroupSuccess,
  removeTagGroup,
  removeTagGroupFailure,
  removeTagGroupSuccess,
  loadTagGroupsStart,
  loadTagGroupsFailure,
  loadTagGroupsSuccess,
  resetTagGroupLoadingState,
} = actions;
