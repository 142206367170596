import axios from 'axios';

import { ApiImage } from '../schemas/images';
import { apiWrapper } from '../utils';
import { NEW_API_URL } from '../../constants/paths';
import {
  ApiPaginated,
  headers,
  transformRequest,
  transformResponse,
} from '../helpers';
import { ImageStatus } from '../domainModels/imageStatus';
import { convertToBackendParams } from '../../redux/utils/backendParams';

export const apiLoadProjectImages = ({
  projectId,
  params,
}: {
  projectId: string;
  params: {
    offset?: number;
    limit?: number;
    datasetId?: string[];
    imageStatus?: ImageStatus[];
    imageName?: string;
    imageTags?: string[];
  };
}) =>
  apiWrapper(
    axios.request<ApiPaginated<ApiImage[]>>({
      method: 'get',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${projectId}/images`,
      params: convertToBackendParams(params),
    }),
  );

export const apiDeleteProjectImage = (params: {
  projectId: string;
  id: string; // imageId
}) =>
  apiWrapper(
    axios.request<void>({
      method: 'delete',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/images/${params.id}`,
      data: {}, // empty body due to backend peculiarities
    }),
  );

export const apiPatchProjectImage = (
  params: {
    projectId: string;
    id: string; // imageId
  },
  data: Partial<{ datasetId: string; filename: string }>,
) =>
  apiWrapper(
    axios.request<ApiImage>({
      method: 'patch',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/images/${params.id}`,
      data,
    }),
  );
