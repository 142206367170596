import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
  Update,
} from '@reduxjs/toolkit';

import {
  ErrorClLabel,
  ErrorOdIsLabel,
  EnrichedErrorClLabel,
  EnrichedErrorOdIsLabel,
} from '../../../../../api/domainModels/consensusScoring';
import { loadingStateBuilder } from '../../../../utils/loadingState';
import { FiltersData } from './filters.slice';
import {
  ErrorFinderAction,
  RunType,
} from '../../../../../api/constants/consensusScoring';

export const adapter = createEntityAdapter<ErrorClLabel | ErrorOdIsLabel>();

const { actions, reducer: labelsReducer } = createSlice({
  name: 'labels',
  initialState: adapter.getInitialState({
    loadingState: loadingStateBuilder.initial(),
    itemLoadingState: loadingStateBuilder.initial(),
  }),
  reducers: {
    resetLoadingState(state) {
      state.loadingState = loadingStateBuilder.initial();
    },
    skipLoadingErrorFinderLabels(state) {
      state.loadingState = loadingStateBuilder.success();
    },
    loadErrorFinderLabels(
      state,
      _action: PayloadAction<{
        runId: string;
        runType: RunType | null;
        imageId?: string;
        customLimit?: number;
        customFilters?: FiltersData;
      }>,
    ) {
      state.loadingState = loadingStateBuilder.inProgress();
      adapter.removeAll(state);
    },
    loadErrorFinderLabelsInitial() {},
    loadErrorFinderLabelsSuccess(
      state,
      action: PayloadAction<EnrichedErrorClLabel[] | EnrichedErrorOdIsLabel[]>,
    ) {
      state.loadingState = loadingStateBuilder.success();
      adapter.setAll(state, action.payload);
    },
    loadErrorFinderLabelsFailure(state, action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.failure(action.payload);
      adapter.setAll(state, []);
    },
    updateErrorFinderLabelFailure(
      state,
      action: PayloadAction<{ message: string; id: string }>,
    ) {
      const { message, id } = action.payload;
      state.itemLoadingState = loadingStateBuilder.failure(message, {
        id,
      });
    },
    updateErrorFinderLabelSuccess(
      state,
      action: PayloadAction<
        Update<EnrichedErrorClLabel | EnrichedErrorOdIsLabel>
      >,
    ) {
      state.itemLoadingState = loadingStateBuilder.success();
      adapter.updateOne(state, action.payload);
    },
    changeErrorAction(
      state,
      _action: PayloadAction<{
        label: EnrichedErrorClLabel | EnrichedErrorOdIsLabel;
        efAction: ErrorFinderAction;
        runType: RunType;
        runId: string;
        classId?: string;
      }>,
    ) {
      state.itemLoadingState = loadingStateBuilder.inProgress();
    },
    assignErrorFinderLabelClass(
      state,
      _action: PayloadAction<{
        label: {
          id: string;
          labelId: string;
          currentClassId: string;
          predictedClassId: string;
          imageId: string;
          errorType: string;
        };
        classId: string;
        runType: RunType;
        runId: string;
      }>,
    ) {
      state.itemLoadingState = loadingStateBuilder.inProgress();
    },
  },
});

export { labelsReducer };
export const {
  resetLoadingState,
  skipLoadingErrorFinderLabels,
  loadErrorFinderLabels,
  loadErrorFinderLabelsInitial,
  loadErrorFinderLabelsSuccess,
  loadErrorFinderLabelsFailure,
  updateErrorFinderLabelFailure,
  updateErrorFinderLabelSuccess,
  changeErrorAction,
  assignErrorFinderLabelClass,
} = actions;
