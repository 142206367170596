import { call, fork, put, select, take, takeEvery } from 'typed-redux-saga';
import { Task } from '@redux-saga/types';

import {
  clearGallery,
  invalidateGallery,
  loadGalleryImages,
  requestGalleryImageLoad,
  loadGalleryImagesSuccess,
  loadGalleryImagesError,
  resetTotal,
  loadGalleryImagesStart,
  updateGalleryImage,
  reset,
} from './imageGalleryItems.slice';
import { retrieveImageOffset } from '../imageGallery.saga';
import { apiLoadImages } from '../../../../../../api/requests/projectImages';
import { getErrorMessage } from '../../../../../../api/utils';
import { imageGalleryItemsDataSelector } from './imageGalleryItems.selectors';
import {
  imageViewCurrentImageLoadLimitSelector,
  imageViewImageIdSelector,
} from '../../currentImage/currentImage.selectors';
import {
  setImageId,
  setImageOffset,
  setImageStatusSuccess,
} from '../../currentImage/currentImage.slice';
import {
  imagesDataMapper,
  Image,
} from '../../../../../../api/domainModels/images';
import {
  imageViewImageGalleryFiltersStatusSelector,
  imageViewImageGalleryFiltersSearchSelector,
  imageViewImageGalleryFiltersDatasetSelector,
  imageViewImageGalleryFiltersTagsSelector,
  imageViewImageGalleryFiltersCSRunIdSelector,
  imageViewImageGalleryFiltersCSUserActionSelector,
  imageViewImageGalleryFiltersCSErrorTypeSelector,
  imageViewImageGalleryFiltersAldiSessionIdSelector,
} from '../bottomBar/filters/filters.selectors';
import { activeProjectIdSelector } from '../../../../project/project.selectors';
import { resetProject } from '../../imageView.actions';
import { EfUserAction } from '../../../../../../constants/consensusScoring';
import { ErrorType } from '../../../../../../api/domainModels/consensusScoring';

function* clearGalleryHandler() {
  // need to set the image to the first in filter, if it has changed
  const imageId = yield* select(imageViewImageIdSelector);

  yield* put(loadGalleryImages(0));
  const action = yield* take([
    loadGalleryImagesSuccess,
    loadGalleryImagesError,
  ]);

  if (loadGalleryImagesSuccess.match(action)) {
    const { items } = action.payload;

    if (items instanceof Array && items.length > 0) {
      const newImage = items[0];

      if (newImage.id !== imageId) {
        yield* put(setImageId({ imageId: newImage.id }));
      }
    }
  }
}

function* invalidateGalleryHandler() {
  const offset = yield* retrieveImageOffset();

  yield* put(resetTotal());

  if (offset !== null) {
    yield* put(setImageOffset(offset));
    yield* put(loadGalleryImages(offset));
  } else {
    yield* put(loadGalleryImages(0));
  }
}

const runningLoadGalleryTasks: Record<string, Task> = {};

function* loadGalleryImagesHandler(
  action: ActionType<typeof loadGalleryImages>,
) {
  const projectId = yield* select(activeProjectIdSelector);
  const limit = yield* select(imageViewCurrentImageLoadLimitSelector);
  const offset = action.payload;

  if (!projectId) {
    return;
  }
  const datasetId = yield* select(imageViewImageGalleryFiltersDatasetSelector);
  const imageStatus = yield* select(imageViewImageGalleryFiltersStatusSelector);
  const searchTerm = yield* select(imageViewImageGalleryFiltersSearchSelector);
  const csEfRunId = yield* select(imageViewImageGalleryFiltersCSRunIdSelector);
  const csUserAction = yield* select(
    imageViewImageGalleryFiltersCSUserActionSelector,
  );
  const csErrorType = yield* select(
    imageViewImageGalleryFiltersCSErrorTypeSelector,
  );
  const aldiSessionId = yield* select(
    imageViewImageGalleryFiltersAldiSessionIdSelector,
  );
  const imageTags = yield* select(imageViewImageGalleryFiltersTagsSelector);
  const galleryData = yield* select(imageGalleryItemsDataSelector);

  // now check if the image gallery has this image already. if not – load
  if (galleryData instanceof Object && galleryData[offset] instanceof Object) {
    return;
  }

  const params = {
    projectId,
    offset,
    limit,
    datasetId,
    imageStatus,
    searchTerm,
    imageTags,
    csEfRunId,
    csUserAction: csUserAction as EfUserAction,
    csErrorType: csErrorType as ErrorType,
    aldiSessionId,
  };

  const runningTaskKey = JSON.stringify(params);

  if (!runningLoadGalleryTasks[runningTaskKey]) {
    runningLoadGalleryTasks[runningTaskKey] = yield* fork(
      function* inlineImageLoad() {
        yield* put(loadGalleryImagesStart('Loading image gallery'));

        try {
          const response: any = yield* call(apiLoadImages, params);
          const { data } = response;

          data.items = data.items.map(imagesDataMapper.fromBackend);
          data.offset = offset;
          data.itemsMap = data.items.reduce(
            (acc: Record<number, Image>, image: Image, index: number) => {
              acc[offset + index] = image;

              return acc;
            },
            {},
          );

          yield* put(loadGalleryImagesSuccess(data));
        } catch (error) {
          yield* put(
            loadGalleryImagesError(
              getErrorMessage(error, 'Not able to load image gallery'),
            ),
          );
        } finally {
          delete runningLoadGalleryTasks[runningTaskKey];
        }
      },
    );
  }
}

function* requestGalleryImageLoadHandler(
  action: ActionType<typeof requestGalleryImageLoad>,
) {
  const imageOffset = action.payload;
  const limit = yield* select(imageViewCurrentImageLoadLimitSelector);
  const pageOffset = imageOffset - (imageOffset % limit);

  yield* put(loadGalleryImages(pageOffset));
}

function* setImageStatusSuccessHandler(
  action: ActionType<typeof setImageStatusSuccess>,
) {
  const data = yield* select(imageGalleryItemsDataSelector);

  if (!data) return;

  const entry = Object.entries(data).find(
    ([_offset, image]) => image.id === action.payload.imageId,
  );

  if (entry) {
    const [offset, image] = entry;

    if (image.imageStatus !== action.payload.status) {
      yield* put(
        updateGalleryImage({
          image: {
            ...image,
            imageStatus: action.payload.status,
          },
          offset,
        }),
      );
    }
  }
}

function* resetHandler() {
  yield* put(reset());
}

export function* imageGalleryItemsSaga() {
  yield* takeEvery(clearGallery, clearGalleryHandler);
  yield* takeEvery(resetProject, resetHandler);
  yield* takeEvery(invalidateGallery, invalidateGalleryHandler);
  yield* takeEvery(loadGalleryImages, loadGalleryImagesHandler);
  yield* takeEvery(requestGalleryImageLoad, requestGalleryImageLoadHandler);
  yield* takeEvery(setImageStatusSuccess, setImageStatusSuccessHandler);
}
