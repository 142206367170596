import { put, takeLatest, select, takeEvery, take } from 'typed-redux-saga';

import { getErrorMessage } from '../../../../../../api/utils';
import { handleError } from '../../../../commonFeatures/errorHandler/errorHandler.actions';
import { lockImage, renewLock, unlockImage } from './lock.actions';
import { resetProject } from '../../imageView.actions';
import { activeProjectIdSelector } from '../../../../project/project.selectors';
import {
  lockSubject,
  renewSubjectLock,
  unlockSubject,
} from '../../../../commonFeatures/locks/locks.slice';
import {
  isSubjectLockedByIdSelector,
  subjectLockByIdSelector,
} from '../../../../commonFeatures/locks/locks.selectors';
import { imageViewCurrentImageLockSelector } from './lock.selectors';

function* unlockImageHandler(action: ActionType<typeof unlockImage>) {
  const { imageId, projectId } = action.payload;
  const lockedByAnotherUser = yield* select(
    isSubjectLockedByIdSelector,
    imageId,
  );
  const lock = yield* select(subjectLockByIdSelector, imageId);

  if (lockedByAnotherUser || !lock) {
    return false;
  }

  try {
    yield* put(unlockSubject({ projectId, lock }));
  } catch (error) {
    const errorMessage = getErrorMessage(error, 'Not able to unlock image');

    yield* put(
      handleError({
        message: errorMessage,
        error,
      }),
    );
  }
}

function* lockImageHandler(action: ActionType<typeof lockImage>) {
  const { projectId, imageId } = action.payload;

  try {
    yield* put(lockSubject({ projectId, subjectId: imageId }));

    yield* take(resetProject); // TODO:: wtf is this supposed to do? unlock image on reset project?

    const isLockedByCurrentUser = yield* select(
      isSubjectLockedByIdSelector,
      imageId,
    );

    if (isLockedByCurrentUser) {
      // TODO:: this is never true?
      yield* put(
        unlockImage({
          imageId,
          projectId,
        }),
      );
    }
  } catch (error) {
    const errorMessage = getErrorMessage(error, 'Not able to lock image');

    yield* put(
      handleError({
        message: errorMessage,
        error,
      }),
    );
  }
}

function* renewImageLockHandler() {
  const projectId = yield* select(activeProjectIdSelector);
  const lock = yield* select(imageViewCurrentImageLockSelector);

  if (!projectId || !lock) {
    return;
  }

  try {
    yield* put(renewSubjectLock({ projectId, lock }));
  } catch (error) {
    const errorMessage = getErrorMessage(error, 'Not able to lock image');

    yield* put(
      handleError({
        message: errorMessage,
        error,
      }),
    );
  }
}

export function* currentImageLockSaga() {
  yield* takeLatest(lockImage, lockImageHandler);
  yield* takeLatest(renewLock, renewImageLockHandler);
  yield* takeEvery(unlockImage, unlockImageHandler);
}
