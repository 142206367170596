import { put, takeEvery } from 'typed-redux-saga';
import dayjs from 'dayjs';

import {
  requestShowControlledTooltip,
  showControlledTooltip,
} from './controlledTooltip.slice';
import { SEEN_VALUE } from '../../../../constants/controlledTooltip';
import { CONTROLLED_TOOLTIP_ENTRIES } from '../../../../constants/controlledTooltipEntries';

function* requestShowControlledTooltipHandler(
  action: ActionType<typeof requestShowControlledTooltip>,
) {
  if (localStorage.getItem(action.payload.id) === SEEN_VALUE) {
    return;
  }

  const entry = CONTROLLED_TOOLTIP_ENTRIES[action.payload.id];

  if (entry?.keepAlive) {
    const storedData = localStorage.getItem(action.payload.id);

    if (storedData === SEEN_VALUE) {
      return;
    }

    if (!storedData) {
      localStorage.setItem(action.payload.id, dayjs().toISOString());
    } else if (+dayjs() - +dayjs(storedData) > entry.keepAlive) {
      localStorage.setItem(action.payload.id, SEEN_VALUE);

      return;
    }
  }

  yield* put(showControlledTooltip(action.payload));
}

export function* controlledTooltipSaga() {
  yield* takeEvery(
    requestShowControlledTooltip,
    requestShowControlledTooltipHandler,
  );
}
