import { ApiAttributeValue } from '../schemas/attribute';

const fromBackend = ({ id, value }: ApiAttributeValue) => ({
  id,
  name: value,
});

export type AttributeValue = ReturnType<typeof fromBackend>;
const toBackend = ({ id, name }: AttributeValue): ApiAttributeValue => ({
  id,
  value: name,
});

export const attributeValueDataMapper = {
  fromBackend,
  toBackend,
};
