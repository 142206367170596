import { all, put, takeEvery, take } from 'typed-redux-saga';
import { push } from 'connected-react-router';

import { setVideoViewProjectId } from './videoViewProject.slice';
import { loadProjectInfo } from '../../../project/projectInfo/projectInfo.slice';
import { ANNOTATE_VIDEO_PROJECT } from '../../../ws/ws.eventsLists';
import { loadPackageFeatures } from '../../../commonFeatures/packageFeatures/packageFeatures.slice';
import { loadSubjectLocks } from '../../../commonFeatures/locks/locks.slice';
import {
  projectSelectedError,
  projectSelectedSuccess,
} from '../../imageView/project/project.slice';
import { loadSegmentPredictorModel } from '../tools/segmentPrediction/segmentPrediction.slice';

function* setVideoViewProjectIdHandler(
  action: ActionType<typeof setVideoViewProjectId>,
) {
  const projectId = action.payload.id;

  if (!projectId) {
    yield* put(push('/workspaces'));
  }

  yield* all([
    put(
      loadProjectInfo({
        projectId,
        eventsList: ANNOTATE_VIDEO_PROJECT,
      }),
    ),
    put(loadPackageFeatures()),
    put(loadSubjectLocks(projectId)),
  ]);

  yield* take([projectSelectedSuccess, projectSelectedError]); // wait for project registration on websockets before requesting models / finish him status

  const secondaryEffects = [put(loadSegmentPredictorModel(projectId))];

  yield* all(secondaryEffects);
}

export function* videoViewProjectSaga() {
  yield* takeEvery(setVideoViewProjectId, setVideoViewProjectIdHandler);
}
