import { combineReducers } from 'redux';

import { projectsReducer } from './projects/projects.slice';
import { workspacesReducer } from './workspaces/workspaces.slice';
import { createWorkspaceReducer } from './createWorkspace/createWorkspace.slice';

export const projectListReducer = combineReducers({
  projects: projectsReducer,
  workspaces: workspacesReducer,
  createWorkspace: createWorkspaceReducer,
});
