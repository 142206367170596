import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import {
  AdvancedOption,
  AdvancedOptionApiType,
} from '../../../../../api/domainModels/advancedOptions';
import { loadingStateBuilder } from '../../../../utils/loadingState';

export const adapter = createEntityAdapter<AdvancedOption>({
  selectId: (option) => Object.keys(option)[0],
});

const { actions, reducer: advancedOptionsReducer } = createSlice({
  name: 'advancedOptions',
  initialState: adapter.getInitialState({
    loadingState: loadingStateBuilder.initial(),
    saveLoadingState: loadingStateBuilder.initial(),
  }),
  reducers: {
    loadAdvancedOptionStart(
      state,
      _action: PayloadAction<{
        projectId: string;
        optionKey: AdvancedOptionApiType;
      }>,
    ) {
      state.loadingState = loadingStateBuilder.inProgress();
      adapter.removeAll(state);
    },
    loadAdvancedOptionFailure(state, action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.failure(action.payload);
    },
    loadAdvancedOptionSuccess(
      state,
      action: PayloadAction<AdvancedOption | null>,
    ) {
      state.loadingState = loadingStateBuilder.success();
      if (action.payload !== null) adapter.upsertOne(state, action.payload);
    },
    saveAdvancedOptionStart(
      state,
      _action: PayloadAction<{
        projectId: string;
        optionKey: AdvancedOptionApiType;
        value: string | boolean;
      }>,
    ) {
      state.saveLoadingState = loadingStateBuilder.inProgress();
    },
    saveAdvancedOptionFailure(state, action: PayloadAction<string>) {
      state.saveLoadingState = loadingStateBuilder.failure(action.payload);
    },
    saveAdvancedOptionSuccess(state, action: PayloadAction<AdvancedOption>) {
      state.saveLoadingState = loadingStateBuilder.success();
      adapter.upsertOne(state, action.payload);
    },
  },
});

export { advancedOptionsReducer };
export const {
  loadAdvancedOptionFailure,
  loadAdvancedOptionStart,
  loadAdvancedOptionSuccess,
  saveAdvancedOptionFailure,
  saveAdvancedOptionStart,
  saveAdvancedOptionSuccess,
} = actions;
