import { ApiImageTag } from '../schemas/imageTags';

const imageTagFromBackend = ({
  tagClassId,
  tagClassName,
  ...entry
}: ApiImageTag) => ({
  ...entry,
  classId: tagClassId,
  name: tagClassName,
});

const imageTagToBackend = ({ classId, name, ...entry }: Partial<ImageTag>) => ({
  ...entry,
  tagClassId: classId,
  tagClassName: name,
});

export type ImageTag = ReturnType<typeof imageTagFromBackend>;

export const imageTagDataMapper = {
  fromBackend: imageTagFromBackend,
  toBackend: imageTagToBackend,
};

const predictedTagFromBackend = (tag: string) => ({
  id: tag,
  classId: tag,
  predicted: true,
});

export type PredictedTag = ReturnType<typeof predictedTagFromBackend>;

export interface PredictedImageTag extends ImageTag, PredictedTag {
  groupId: string;
}

export const predictedImageTagDataMapper = {
  fromBackend: predictedTagFromBackend,
};
