import { put, select, takeEvery, call, take } from 'typed-redux-saga';
import { PayloadAction } from '@reduxjs/toolkit';

import {
  apiLoadImageTagsPrediction,
  apiLoadImageTagsPredictionModel,
} from '../../../../../../api/requests/projectTools';
import { getErrorMessage } from '../../../../../../api/utils';
import { statusChecks } from '../../../../../../constants/status';
import { handleError } from '../../../../commonFeatures/errorHandler/errorHandler.actions';
import { imageViewImageIdSelector } from '../../currentImage/currentImage.selectors';
import {
  loadImageTagsPredictionModel,
  loadImageTagsPrediction,
  loadImageTagsPredictionStart,
  loadImageTagsPredictionSuccess,
  loadImageTagsPredictionModelStart,
  loadImageTagsPredictionModelSuccess,
  loadImageTagsPredictionModelError,
  updateImageTagsPredictionModel,
  updateImageTagsPredictionModelSuccess,
  loadImageTagsPredictionError,
  setImageTagsPredictionThreshold,
  resetData,
  resetModel,
  updateImageTagsPredictionModelShowDot,
} from './imageTagsPrediction.slice';
import {
  loadModelHandler,
  modelLoadedHandler,
  updateModelHandler,
} from '../models/models.saga';
import { toggleTagClassPrediction } from '../../sidebar/imageTagsPredictionToggle/imageTagsPredictionToggle.slice';
import {
  imageTagsPredictionModelIdSelector,
  imageTagsPredictionModelLoadedSelector,
  imageTagsPredictionThresholdSelector,
} from './imageTagsPrediction.selectors';
import {
  MODEL_MESSAGES,
  IMAGE_TAGS_PREDICTION_FAMILY_NAME,
} from './imageTagsPrediction.constants';
import { MODEL_LOADED, MODEL_UPDATED } from '../../../../ws/ws.constants';
import { resetProject } from '../../imageView.actions';
import { activeProjectIdSelector } from '../../../../project/project.selectors';
import {
  createModelChangePattern,
  ModelChangePayload,
} from '../models/models.constants';
import { imageViewIsImageTagsPredictionToggledSelector } from '../../sidebar/imageTagsPredictionToggle/imageTagsPredictionToggle.selectors';
import { tagClassesListLoadingStateSelector } from '../../../../project/annotationTaxonomy/tagClasses/tagClasses.selectors';
import { loadTagClassesSuccess } from '../../../../project/annotationTaxonomy/tagClasses/tagClasses.slice';
import { predictedImageTagDataMapper } from '../../../../../../api/domainModels/imageTag';
import { ImageTool, ImageToolTitleMap } from '../tools.constants';

function* loadImageTagsPredictionsHandler() {
  const projectId = yield* select(activeProjectIdSelector);
  const isPredictionEnabled = yield* select(
    imageViewIsImageTagsPredictionToggledSelector,
  );
  const imageId = yield* select(imageViewImageIdSelector);
  const modelId = yield* select(imageTagsPredictionModelIdSelector);
  const threshold = yield* select(imageTagsPredictionThresholdSelector);

  if (!isPredictionEnabled || !imageId || !modelId) {
    // triggered also on model loaded, when opening project with prediction toggled on image id might not be there yet
    return;
  }

  yield* put(loadImageTagsPredictionStart());

  const tagPoolStatus = yield* select(tagClassesListLoadingStateSelector);
  if (!statusChecks.isSuccess(tagPoolStatus.status)) {
    yield* take(loadTagClassesSuccess);
  }

  try {
    const response = yield* call(
      apiLoadImageTagsPrediction,
      projectId,
      imageId,
      modelId,
      threshold,
    );
    const payload = response.data.map(predictedImageTagDataMapper.fromBackend);

    yield* put(loadImageTagsPredictionSuccess(payload));

    return payload;
  } catch (error) {
    const errorMessage = getErrorMessage(
      error,
      'Not able to get tag class predictions',
    );

    yield* put(loadImageTagsPredictionError(errorMessage));
    yield* put(handleError({ message: errorMessage, error }));

    return false;
  }
}

function* loadImageTagsPredictionModelHandler() {
  const projectId = yield* select(activeProjectIdSelector);

  yield* call(loadModelHandler, {
    projectId,
    loadApiCall: apiLoadImageTagsPredictionModel,
    messages: MODEL_MESSAGES,
    toolId: ImageTool.ImageTagsPrediction,
    modelLoadStartAction: loadImageTagsPredictionModelStart,
    modelLoadSuccessAction: loadImageTagsPredictionModelSuccess,
    modelLoadErrorAction: loadImageTagsPredictionModelError,
    toolName: ImageToolTitleMap[ImageTool.ImageTagsPrediction],
  });
}

function* imageTagsPredictionModelLoadedHandler(
  action: PayloadAction<ModelChangePayload>,
) {
  const { status, progress, id } = action.payload;

  yield* call(modelLoadedHandler, {
    id,
    modelIdSelector: imageTagsPredictionModelIdSelector,
    modelLoadedSelector: imageTagsPredictionModelLoadedSelector,
    progress,
    status,
    updateAction: updateImageTagsPredictionModel,
    modelLoadedAction: loadImageTagsPrediction,
    updateModelIndicator: updateImageTagsPredictionModelShowDot,
  });
}

function* imageTagsPredictionModelUpdatedHandler(
  action: PayloadAction<ModelChangePayload>,
) {
  const { status, progress, id } = action.payload;

  yield* put(
    updateImageTagsPredictionModel({
      status,
      progress,
      id,
    }),
  );
}

function* updateImageTagsPredictionModelHandler(
  action: ActionType<typeof updateImageTagsPredictionModel>,
) {
  const { status, progress, id } = action.payload;

  yield* call(updateModelHandler, {
    id,
    loadAction: loadImageTagsPredictionModel,
    progress,
    status,
    successAction: updateImageTagsPredictionModelSuccess,
  });
}

function* resetProjectHandler() {
  yield* put(resetData());
  yield* put(resetModel());
}

export function* imageTagsPredictionSaga() {
  yield* takeEvery(
    loadImageTagsPredictionModel,
    loadImageTagsPredictionModelHandler,
  );
  yield* takeEvery(
    [
      toggleTagClassPrediction,
      loadImageTagsPrediction,
      setImageTagsPredictionThreshold,
    ],
    loadImageTagsPredictionsHandler,
  );
  yield* takeEvery(
    createModelChangePattern(IMAGE_TAGS_PREDICTION_FAMILY_NAME, MODEL_LOADED),
    imageTagsPredictionModelLoadedHandler,
  );
  yield* takeEvery(
    createModelChangePattern(IMAGE_TAGS_PREDICTION_FAMILY_NAME, MODEL_UPDATED),
    imageTagsPredictionModelUpdatedHandler,
  );
  yield* takeEvery(
    updateImageTagsPredictionModel,
    updateImageTagsPredictionModelHandler,
  );
  yield* takeEvery(resetProject, resetProjectHandler);
}
