import { combineReducers } from 'redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { errorFinderRunsReducer } from './runs/runs.slice';
import { runsPaginationReducer } from './runs/pagination/pagination.slice';
import { runCreditsEstimationReducer } from './runs/credits/credits.slice';
import { lockedResultsReducer } from './runs/lockedResults/lockedResults.slice';
import { runErrorsPerClassReducer } from './runs/summary/errorsPerClass.slice';
import { labelClassConfidenceHistReducer } from './runs/summary/histogram.slice';
import { confusionMatrixReducer } from './runs/summary/confusionMatrix.slice';
import { labelsReducer } from './labels/labels.slice';
import { efPaginationReducer } from './labels/pagination.slice';
import { errorFinderFiltersReducer } from './labels/filters.slice';
import { labelConfidenceReducer } from './labels/confidence.slice';
import { tagReviewReducer } from './labels/tagReview.slice';
import { attributeReviewReducer } from './labels/attributeReview.slice';
import { RunType } from '../../../../api/constants/consensusScoring';
import { semanticReviewReducer } from './labels/semanticReview.slice';

const initialState: {
  currentLabelId: string;
  activeRun: {
    id: string;
    type: RunType | null;
  };
  opacity: number;
  predictedOpacity: number;
} = {
  currentLabelId: '',
  activeRun: {
    id: '',
    type: null,
  },
  opacity: 0.4,
  predictedOpacity: 0.4,
};
const { actions, reducer: generalReducer } = createSlice({
  name: 'errorFinder',
  initialState,
  reducers: {
    setActiveRunId(state, action: PayloadAction<{ runId: string }>) {
      state.activeRun.id = action.payload.runId;
    },
    setActiveRunType(
      state,
      action: PayloadAction<{ runType: RunType | null }>,
    ) {
      state.activeRun.type = action.payload.runType;
    },
    setPredictedOpacity(state, action: PayloadAction<{ opacity: number }>) {
      state.predictedOpacity = action.payload.opacity;
    },
    setOpacity(state, action: PayloadAction<{ opacity: number }>) {
      state.opacity = action.payload.opacity;
    },
    setCurrentLabelId(state, action: PayloadAction<{ labelId: string }>) {
      state.currentLabelId = action.payload.labelId;
    },
  },
});

export const {
  setActiveRunId,
  setActiveRunType,
  setPredictedOpacity,
  setOpacity,
  setCurrentLabelId,
} = actions;

export const errorFinderReducer = combineReducers({
  runs: errorFinderRunsReducer,
  runsPagination: runsPaginationReducer,
  runCreditsEstimation: runCreditsEstimationReducer,
  runLockedResults: lockedResultsReducer,
  runErrorsPerClass: runErrorsPerClassReducer,
  labelHist: labelClassConfidenceHistReducer,
  confusionMatrix: confusionMatrixReducer,
  labels: labelsReducer,
  tagReview: tagReviewReducer,
  attributeReview: attributeReviewReducer,
  semanticReview: semanticReviewReducer,
  labelsPagination: efPaginationReducer,
  labelsFilters: errorFinderFiltersReducer,
  currentLabelConfidence: labelConfidenceReducer,
  general: generalReducer,
});
