import {
  ApiConvertToImageSessionApi,
  ApiConvertToImagesSessionFormValues,
  ApiConvertToImagesSessionFormValuesInner,
} from '../schemas/convertToImages';

export const JOB_STATUS_LABELS = {
  DONE: 'Complete',
  FAILED: 'Failed',
  INIT: 'Initializing',
  RUNNING: 'Running',
};

export const JOB_STATUS_COLORS = {
  DONE: 'success.main',
  FAILED: 'error.main',
  INIT: 'warning.main',
  RUNNING: 'warning.main',
};

const convertToImagesFromBackend = ({
  meta: {
    destDatasetId,
    destProjectId,
    duration,
    frames,
    prefix,
    importedImages,
  },
  ...convertToImages
}: ApiConvertToImageSessionApi) => ({
  ...convertToImages,
  destDatasetId,
  destProjectId,
  duration,
  frames,
  prefix,
  importedImages,
});

export type ConvertToImageSession = ReturnType<
  typeof convertToImagesFromBackend
>;

export const convertToImagesDataMapper = {
  fromBackend: convertToImagesFromBackend,
};

export type ConvertToImagesSessionFormValuesInner =
  ApiConvertToImagesSessionFormValuesInner;
export type ConvertToImagesSessionFormValues =
  ApiConvertToImagesSessionFormValues;
