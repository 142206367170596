import { createSelector } from '@reduxjs/toolkit';

import { widgetsSelector } from '../widgets.selectors';
import { itemsAdapter } from './detectorInferences.slice';

const detectorInferencesSelector = createSelector(
  widgetsSelector,
  (widgets) => widgets.detectorInferences,
);

export const detectorInferencesItemsStateSelector = createSelector(
  detectorInferencesSelector,
  ({ items }) => items,
);

const itemsSelectors = itemsAdapter.getSelectors(
  detectorInferencesItemsStateSelector,
);

export const detectorInferencesItemsSelector = itemsSelectors.selectAll;

export const detectorInferencesMetaSelector = createSelector(
  detectorInferencesSelector,
  ({ meta }) => meta,
);

export const detectorInferencesMetaTotalSelector = createSelector(
  detectorInferencesMetaSelector,
  (meta) => meta?.total || 0,
);

export const detectorInferencesLimitSelector = createSelector(
  detectorInferencesSelector,
  ({ limit }) => limit,
);

export const detectorInferencesOffsetSelector = createSelector(
  detectorInferencesSelector,
  ({ offset }) => offset,
);

export const detectorInferencesOpacitySelector = createSelector(
  detectorInferencesSelector,
  ({ opacity }) => opacity,
);
