import { atom, selector } from 'recoil';

import { createRecoilEntityAdapterFamily } from '../../helpers/recoil/entityAdapter';
import { nullableProjectIdState } from './project';
import { apiLoadActivities } from '../../api/requests/activity';
import { VideoActivity } from '../../api/domainModels/video';

export const activitiesCoil = createRecoilEntityAdapterFamily<
  VideoActivity,
  string | null
>({
  name: 'video/activities',
  paramState: nullableProjectIdState,
  sortComparer: (a, b) =>
    new Date(a.createdAt).valueOf() - new Date(b.createdAt).valueOf(),
  initialState: async (projectId) => {
    if (!projectId) return [];

    try {
      const { data } = await apiLoadActivities(
        { params: { projectId } },
        {
          errorMessage: "Couldn't load activities",
        },
      );

      return data.items;
    } catch {
      return [];
    }
  },
});

export const activitiesColorsState = selector({
  key: 'video/activities/colors',
  get: ({ get }) => get(activitiesCoil.selectAll).map((a) => a.color),
});

export const activitiesAsOptionsWithAllSelector = selector({
  key: 'video/activities/asOptionsWithAll',
  get: ({ get }) => [
    {
      text: 'All',
      key: 'all',
      value: null,
    },
    ...get(activitiesCoil.selectAll).map((activity) => ({
      text: activity.name,
      key: activity.id,
      value: activity.id,
    })),
  ],
});

export const selectedActivityIdState = atom<string | null>({
  key: 'video/activities/selectedId',
  default: null,
});

export const selectedActivityState = selector<VideoActivity | null>({
  key: 'video/activities/selectedActivity',
  get: ({ get }) => {
    const activityId = get(selectedActivityIdState);
    if (activityId) {
      const activity = get(activitiesCoil.selectById(activityId));
      if (activity) {
        return activity;
      }
    }

    const activity = get(activitiesCoil.selectAll)[0];

    return activity || null;
  },
  set: ({ set }, value) => {
    set(selectedActivityIdState, value && 'id' in value ? value.id : null);
  },
});

const editedActivityIdState = atom<string | null>({
  key: 'video/activities/editedId',
  default: null,
});

export const editedActivityState = selector<VideoActivity | null>({
  key: 'video/activities/editedActivity',
  get: ({ get }) => {
    const activityId = get(editedActivityIdState);

    if (!activityId) {
      return null;
    }

    return get(activitiesCoil.selectById(activityId)) || null;
  },
  set: ({ set }, value) => {
    set(editedActivityIdState, value && 'id' in value ? value.id : null);
  },
});

const toBeDeletedActivityIdState = atom<string | null>({
  key: 'video/activities/toBeDeletedId',
  default: null,
});

export const toBeDeletedActivityState = selector<VideoActivity | null>({
  key: 'video/activities/toBeDeletedActivity',
  get: ({ get }) => {
    const activityId = get(toBeDeletedActivityIdState);

    if (!activityId) {
      return null;
    }

    return get(activitiesCoil.selectById(activityId)) || null;
  },
  set: ({ set }, value) => {
    set(toBeDeletedActivityIdState, value && 'id' in value ? value.id : null);
  },
});
