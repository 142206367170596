import { put, select, takeEvery, call } from 'typed-redux-saga';

import { experimentComponentDataMapper } from '../../../../../../api/domainModels/modelPlayground';
import {
  apiGetExperimentComponents,
  apiUpdateExperimentComponent,
  apiGetComponentParameters,
  apiUpdateComponentParameter,
} from '../../../../../../api/requests/modelPlayground';
import { getErrorMessage } from '../../../../../../api/utils';
import { loadAll } from '../../../../../utils/api';
import { activeProjectIdSelector } from '../../../../project/project.selectors';
import {
  activeExperimentIdSelector,
  isActiveExperimentEditableSelector,
} from '../selectors';
import { parseParameterValue } from '../parameters.helpers';
import {
  selectedSolverIdSelector,
  solverParameterByIdSelector,
} from './solvers.selectors';
import {
  loadSolvers,
  loadSolversSuccess,
  loadSolversFailure,
  updateExperimentSolver,
  updateExperimentSolverFailure,
  updateExperimentSolverSuccess,
  loadSolverParameters,
  loadSolverParametersSuccess,
  loadSolverParametersFailure,
  updateSolverParameter,
  updateSolverParameterSuccess,
  updateSolverParameterFailure,
} from './solvers.slice';
import { enqueueNotification } from '../../../../ui/stackNotifications/stackNotifications.slice';
import { Component } from '../../../../../../api/constants/modelPlayground';

function* loadSolversHandler(_action: ActionType<typeof loadSolvers>) {
  try {
    const projectId = yield* select(activeProjectIdSelector);
    const experimentId = yield* select(activeExperimentIdSelector);
    const items = yield* loadAll({
      apiHelper: apiGetExperimentComponents,
      params: {
        projectId,
        experimentId,
        componentType: Component.Solver,
      },
    });
    const isEditable = yield* select(isActiveExperimentEditableSelector);
    // as BE does not provide initially selected value for solver
    // we need to make sure it either exists (user selected it) or preselect it for them
    // we'll be working under assumption that solver should always be selected
    const hasSolverSelected = items.some((item) => item.selected);
    if (isEditable && items.length > 0 && !hasSolverSelected)
      yield* put(updateExperimentSolver(items[0].id));
    yield* put(
      loadSolversSuccess(items.map(experimentComponentDataMapper.fromBackend)),
    );
  } catch (error) {
    yield* put(
      loadSolversFailure(getErrorMessage(error, 'Not able to load solvers')),
    );
  }
}

function* updateSolverHandler(
  action: ActionType<typeof updateExperimentSolver>,
) {
  try {
    const projectId = yield* select(activeProjectIdSelector);
    const experimentId = yield* select(activeExperimentIdSelector);

    if (!experimentId) {
      return;
    }

    const componentType: Component = Component.Solver;
    const params = { projectId, experimentId, componentType };
    const data = { solverId: action.payload };
    const { data: response } = yield* call(() =>
      apiUpdateExperimentComponent(params, data),
    );
    yield* put(
      updateExperimentSolverSuccess(
        experimentComponentDataMapper.fromBackend(response),
      ),
    );
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to update solver');

    yield* put(updateExperimentSolverFailure(message));
    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
          refresh: false,
        },
      }),
    );
  }
}

function* loadParametersHandler(
  action: ActionType<typeof loadSolverParameters>,
) {
  try {
    const projectId = yield* select(activeProjectIdSelector);
    const experimentId = yield* select(activeExperimentIdSelector);
    const componentId = action.payload;
    const componentType: Component = Component.Solver;
    const items = yield* loadAll({
      apiHelper: apiGetComponentParameters,
      params: {
        projectId,
        experimentId,
        componentId,
        componentType,
      },
    });
    yield* put(loadSolverParametersSuccess(items));
  } catch (e) {
    yield* put(
      loadSolverParametersFailure(
        getErrorMessage(e, 'Not able to load parameters'),
      ),
    );
  }
}

function* updateParameterHandler(
  action: ActionType<typeof updateSolverParameter>,
) {
  try {
    const projectId = yield* select(activeProjectIdSelector);
    const experimentId = yield* select(activeExperimentIdSelector);
    const componentId = yield* select(selectedSolverIdSelector);
    const componentType: Component = Component.Solver;

    if (!componentId || !experimentId) return;

    const params = { projectId, experimentId, componentId, componentType };
    const { solverParameterId, value } = action.payload;
    const data = action.payload;
    const editedParameter = yield* select((state: RootState) =>
      solverParameterByIdSelector(state, solverParameterId),
    );
    if (editedParameter) {
      data.value = parseParameterValue(editedParameter, value);
    }
    const { data: response } = yield* call(
      apiUpdateComponentParameter,
      params,
      data,
    );
    yield* put(
      updateSolverParameterSuccess({
        changes: response,
        id: solverParameterId,
      }),
    );
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to update parameter');

    yield* put(updateSolverParameterFailure(message));
    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,

          refresh: false,
        },
      }),
    );
  }
}

export function* solversSaga() {
  yield* takeEvery(loadSolvers, loadSolversHandler);
  yield* takeEvery(updateExperimentSolver, updateSolverHandler);
  yield* takeEvery(loadSolverParameters, loadParametersHandler);
  yield* takeEvery(updateSolverParameter, updateParameterHandler);
}
