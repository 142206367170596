import { createSelector } from '@reduxjs/toolkit';

import { imageViewImageIdSelector } from '../currentImage.selectors';
import {
  subjectLockByIdSelector,
  isSubjectLockedByIdSelector,
} from '../../../../commonFeatures/locks/locks.selectors';

export const imageViewCurrentImageLockSelector = createSelector(
  [(state: RootState) => state, imageViewImageIdSelector],
  (state, imageId) => {
    if (!imageId) return false;

    return subjectLockByIdSelector(state, imageId);
  },
);

export const imageViewIsCurrentImageLockedSelector = createSelector(
  [(state: RootState) => state, imageViewImageIdSelector],
  (state, imageId) => {
    if (!imageId) return false;

    return isSubjectLockedByIdSelector(state, imageId);
  },
);

export const imageViewIsCurrentImageEditableSelector = createSelector(
  imageViewIsCurrentImageLockedSelector,
  (locked) => !locked,
);
