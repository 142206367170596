export const FEATURE_STORAGE_PREFIX = 'EXPERIMENTAL_FEATURE';
export const ALLOW_FINGER_STORAGE_PREFIX = 'ALLOW_FINGER_INPUT';
export const SUPPRESS_SIZE_WARNING_STORAGE_PREFIX = 'SUPPRESS_SIZE_WARNING';
export const FILTERS_STORAGE_PREFIX = 'gallery_filters';
export const ZOOM_SPEED_STORAGE_PREFIX = 'iv2settings/zoomSpeed';
export const GUIDES_STORAGE_PREFIX = 'iv2settings/guides';
export const TRACKPAD_PANNING_STORAGE_PREFIX = 'iv2settings/trackpadPanning';
export const HIDE_ATOM_STRATEGY_WARNING_STORAGE_PREFIX =
  'iv2settings/hideAtomStrategyWarning';
export const HIDE_DISABLE_SAM_WARNING_STORAGE_PREFIX =
  'iv2settings/hideDisableSAMWarning';
export const LIVE_UPDATE_TOGGLE_STORAGE_PREFIX = 'mpsettings/liveUpdate';

export const VIDEO_GALLERY_STORAGE_PREFIX = 'video/ui/galleryOpen';
export const VIDEO_HOTKEYS_PANEL_STORAGE_PREFIX = 'video/ui/hotkeysPanelOpen';
export const VIDEO_SIDEBAR_STORAGE_PREFIX = 'video/ui/sidebarExpanded';
export const VIDEO_SHOW_ZOOM_STORAGE_PREFIX = 'video/ui/showZoom';
export const VIDEO_VOLUME_STORAGE_PREFIX = 'video/volume';
export const VIDEO_PREV_VOLUME_STORAGE_PREFIX = 'video/volume/prev';
export const VIDEO_PLAYBACK_SPEED_STORAGE_PREFIX = 'video/playbackSpeed';
export const VIDEO_SEEK_TIME_STORAGE_PREFIX = 'video/seekByTime';
export const VIDEO_COPIED_SEGMENT_STORAGE_PREFIX = 'video/segments/copiedId';
export const VIDEO_NO_ACCEPT_PREDICTION_STORAGE_PREFIX =
  'video/ui/noAcceptPredictionWarningProjectsListState';
