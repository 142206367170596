import { call, put, takeEvery } from 'typed-redux-saga';

import { getErrorMessage } from '../../../../../api/utils';
import {
  apiLoadWorkspaceRoles,
  apiCreateWorkspaceRole,
  apiUpdateWorkspaceRole,
  apiDeleteWorkspaceRole,
} from '../../../../../api/requests/workspaceRoles';
import { hideModals } from '../../../ui/modals/modals.slice';
import { workspaceRoleDataMapper } from '../../../../../api/domainModels/workspaceRoles';
import { enqueueNotification } from '../../../ui/stackNotifications/stackNotifications.slice';
import {
  createRole,
  createRoleFailure,
  createRoleSuccess,
  deleteRole,
  deleteRoleFailure,
  deleteRoleSuccess,
  loadRoles,
  loadRolesFailure,
  loadRolesSuccess,
  updateRole,
  updateRoleFailure,
  updateRoleSuccess,
} from './roles.slice';

function* loadRolesHandler(action: ActionType<typeof loadRoles>) {
  const { workspaceId } = action.payload;

  try {
    const response = yield* call(apiLoadWorkspaceRoles, workspaceId);
    const payload = response.data.map(workspaceRoleDataMapper.fromBackend);

    yield* put(loadRolesSuccess(payload));
  } catch (error) {
    yield* put(
      loadRolesFailure(
        getErrorMessage(error, 'Not able to fetch workspace roles'),
      ),
    );
  }
}

function* createRoleHandler(action: ActionType<typeof createRole>) {
  const { workspaceId, params } = action.payload;

  try {
    const response = yield* call(apiCreateWorkspaceRole, workspaceId, params);
    const payload = workspaceRoleDataMapper.fromBackend(response.data);

    yield* put(createRoleSuccess(payload));
    yield* put(hideModals());
  } catch (error) {
    yield* put(
      createRoleFailure({
        message: getErrorMessage(error, 'Not able to create a role'),
      }),
    );
  }
}

function* updateRoleHandler(action: ActionType<typeof updateRole>) {
  const { workspaceId, roleId, params } = action.payload;
  try {
    const response = yield* call(
      apiUpdateWorkspaceRole,
      workspaceId,
      roleId,
      params,
    );
    const payload = workspaceRoleDataMapper.fromBackend(response.data);

    yield* put(updateRoleSuccess(payload));
    yield* put(hideModals());
  } catch (error) {
    yield* put(
      updateRoleFailure({
        message: getErrorMessage(error, 'Not able to update the role'),
      }),
    );
  }
}

function* deleteRoleHandler(action: ActionType<typeof deleteRole>) {
  const { workspaceId, roleId } = action.payload;

  try {
    yield* call(apiDeleteWorkspaceRole, workspaceId, roleId);
    yield* put(hideModals());
    yield* put(deleteRoleSuccess({ roleId }));
  } catch (error) {
    yield* put(
      deleteRoleFailure({
        message: getErrorMessage(error, 'Not able to delete the role'),
      }),
    );
    yield* put(
      enqueueNotification({
        message: getErrorMessage(error, 'Not able to delete the role'),
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
        },
      }),
    );
  }
}

export function* rolesSaga() {
  yield* takeEvery(loadRoles, loadRolesHandler);
  yield* takeEvery(createRole, createRoleHandler);
  yield* takeEvery(updateRole, updateRoleHandler);
  yield* takeEvery(deleteRole, deleteRoleHandler);
}
