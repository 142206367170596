import { put, select, takeEvery } from 'typed-redux-saga';

import { updateRunFromWebsocket } from '../../../activeExperiment/runs/runs.slice';
import { liveUpdateEnabledSelector } from '../widgetsOptions/widgetsOptions.selectors';
import { refetchWidgetData } from './widgetsLiveUpdate.actions';
import { experimentByRunIdSelector } from '../../../experiments/experimentsData/experimentsData.selectors';
import { dashboardActiveExperimentIdSelector } from '../../activeExperiment/activeExperiment.selectors';
import { routerSelector } from '../../../../../root.selectors';
import { getIsMPDashboard } from '../../../../../../../util/paths';

function* updateRunFromWebsocketHandler(
  action: ActionType<typeof updateRunFromWebsocket>,
) {
  const experiment = yield* select((state: RootState) =>
    experimentByRunIdSelector(state, action.payload.id),
  );

  // Updated experiment is not in focused split, no need for live update
  if (!experiment) return;

  const { id: updatedExperimentId } = experiment;
  const activeExperimentId = yield* select(dashboardActiveExperimentIdSelector);
  const liveUpdateEnabled = yield* select(liveUpdateEnabledSelector);

  const {
    location: { pathname },
  } = yield* select(routerSelector);

  const isMPDashboard = getIsMPDashboard(pathname);

  if (
    liveUpdateEnabled &&
    isMPDashboard &&
    (!activeExperimentId || activeExperimentId === updatedExperimentId)
  ) {
    yield* put(refetchWidgetData());
  }
}

export function* widgetsLiveUpdateSaga() {
  yield takeEvery(updateRunFromWebsocket, updateRunFromWebsocketHandler);
}
