import { combineReducers } from 'redux';

import { collaboratorsReducer } from './collaborators/collaborators.slice';
import { imageUploadReducer } from './imageUpload/imageUpload.slice';
import { videoUploadReducer } from './videoUpload/videoUpload.slice';
import { projectReducer } from './project/project.slice';
import { rolesReducer } from './roles/roles.slice';
import { webhooksReducer } from './webhooks/webhooks.slice';
import { webhookEventsReducer } from './webhooks/webhookEvents.slice';
import { webhookLogsReducer } from './webhooks/webhookLogs.slice';
import { selectedDatasetReducer } from './selectedDataset/selectedDataset.slice';
import { frameImportsReducer } from './frameImports/frameImports.slice';
import { importsReducer } from './imports/imports.reducer';
import { triggersReducer } from './triggers/triggers.slice';
import { convertToImagesReducer } from './convertToImages/convertToImages.slice';
import { exportSessionsReducer } from './exportSessions/exportSessions.slice';
import { advancedOptionsReducer } from './advancedOptions/advancedOptions.slice';

export const editedProjectReducer = combineReducers({
  collaborators: collaboratorsReducer,
  imageUpload: imageUploadReducer,
  videoUpload: videoUploadReducer,
  selectedDataset: selectedDatasetReducer,
  project: projectReducer,
  roles: rolesReducer,
  webhooks: combineReducers({
    data: webhooksReducer,
    events: webhookEventsReducer,
    logs: webhookLogsReducer,
  }),
  frameImports: frameImportsReducer,
  imports: importsReducer,
  triggers: triggersReducer,
  convertToImages: convertToImagesReducer,
  exportSessions: exportSessionsReducer,
  advancedOptions: advancedOptionsReducer,
});
