import { put, select, takeEvery } from 'typed-redux-saga';
import { push } from 'connected-react-router';

import { activeProjectIdSelector } from '../../../../project/project.selectors';
import { setActiveExperimentId, goToDashboard } from './activeExperiment.slice';

function* goToDashboardHandler(action: ActionType<typeof goToDashboard>) {
  const projectId = yield* select(activeProjectIdSelector);

  if (projectId) {
    const { splitId, experimentId } = action.payload;
    yield* put(
      push(`/projects/${projectId}/playground/splits/${splitId}/dashboard`),
    );
    yield* put(setActiveExperimentId(experimentId));
  }
}

export function* activeExperimentSaga() {
  yield* takeEvery(goToDashboard, goToDashboardHandler);
}
