import { put, select, takeEvery } from 'typed-redux-saga';

import { confirmNextStrategy, setNextStrategy } from './atomEditingState.slice';
import { showModal } from '../../../../../ui/modals/modals.slice';
import { clearAtomValues } from '../atomValues.slice';
import { resetAtomResult } from '../atomResult.slice';
import { atomDataSelector } from '../atom.selectors';
import {
  getLocalStorageItem,
  upsertLocalStorageItem,
} from '../../../../../../../helpers/localStorage';
import { HIDE_ATOM_STRATEGY_WARNING_STORAGE_PREFIX } from '../../../../../../../helpers/localStorage/constants';

function* setNextStrategyHandler() {
  // Checking if data for prediction exists
  const hasPrediction = (yield* select(atomDataSelector)).length;
  const suppressWarning = getLocalStorageItem<boolean>(
    HIDE_ATOM_STRATEGY_WARNING_STORAGE_PREFIX,
    false,
  );

  if (!hasPrediction) {
    yield* put(confirmNextStrategy({ neverShow: !!suppressWarning }));
  } else if (suppressWarning) {
    yield* put(confirmNextStrategy({ neverShow: true }));
  } else {
    yield* put(showModal({ modalName: 'confirmNewAtomStrategy' }));
  }
}

function* confirmNextStrategyHandler(
  action: ActionType<typeof confirmNextStrategy>,
) {
  const suppressWarning = getLocalStorageItem<boolean>(
    HIDE_ATOM_STRATEGY_WARNING_STORAGE_PREFIX,
    false,
  );

  if (action.payload.neverShow && !suppressWarning) {
    upsertLocalStorageItem(HIDE_ATOM_STRATEGY_WARNING_STORAGE_PREFIX, true);
  }

  yield* put(resetAtomResult());
  yield* put(clearAtomValues());
}

export function* atomEditingStateSaga() {
  yield* takeEvery(setNextStrategy, setNextStrategyHandler);
  yield* takeEvery(confirmNextStrategy, confirmNextStrategyHandler);
}
