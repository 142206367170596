import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { nonComparableStatuses } from '../../../../../../api/domainModels/modelPlayground';
import { loadExperimentsSuccess } from '../experimentsData/experimentsData.slice';
import { MAX_VISIBLE_EXPERIMENTS } from './hiddenExperiments.constants';
import { setEditedProjectId } from '../../../../sections/editedProject/project/project.slice';

export const adapter = createEntityAdapter<string>({
  selectId: (id) => id,
});
const initialState = adapter.getInitialState();

const { reducer: hiddenExperimentsReducer, actions } = createSlice({
  name: 'modelPlaygroundHiddenExperiments',
  initialState,
  reducers: {
    hideExperiment(state, action: PayloadAction<string>) {
      adapter.addOne(state, action.payload);
    },
    showExperiment(state, action: PayloadAction<string>) {
      adapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadExperimentsSuccess, (state, { payload }) => {
      let visibleExperimentCount = 0;

      const hiddenExperiments = payload.reduce<string[]>(
        (accumulator, experiment) => {
          if (
            nonComparableStatuses.includes(experiment.status) ||
            visibleExperimentCount >= MAX_VISIBLE_EXPERIMENTS
          ) {
            return [...accumulator, experiment.id];
          }

          visibleExperimentCount++;

          return accumulator;
        },
        [],
      );

      adapter.addMany(state, hiddenExperiments);
    });
    builder.addCase(setEditedProjectId, () => initialState);
  },
});

export { hiddenExperimentsReducer };
export const { hideExperiment, showExperiment } = actions;
