/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Status of the video
 */
export enum VideoStatusQuery1 {
  NEW = 'NEW',
  IN_PROGRESS = 'IN PROGRESS',
  DONE = 'DONE',
  SKIPPED = 'SKIPPED',
  TO_REVIEW = 'TO REVIEW',
  AUTO_LABELLED = 'AUTO-LABELLED',
}
