/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BatchIDRequest } from '../models/BatchIDRequest';
import type { BatchMeta } from '../models/BatchMeta';
import type { Label } from '../models/Label';
import type { Label1 } from '../models/Label1';
import type { LabelAttributeValue } from '../models/LabelAttributeValue';
import type { LabelBatchRequest } from '../models/LabelBatchRequest';
import type { LabelSync } from '../models/LabelSync';
import type { MRLabel } from '../models/MRLabel';
import type { PaginationMeta } from '../models/PaginationMeta';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { OmitReadonly } from '../core/utils/OmitReadonly';

export class LabelService {

  /**
   * Delete multiple labels
   * @returns Label Returns removed labels
   * @throws ApiError
   */
  public static deleteApiV10ProjectImageLabel({
    projectId,
    imageId,
    requestBody,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Image ID
     */
    imageId: string,
    requestBody: LabelBatchRequest,
  }): CancelablePromise<Array<Label>> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v1.0/project/{project_id}/image/{image_id}/label',
      path: {
        'project_id': projectId,
        'image_id': imageId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Create a label or multiple labels
   * @returns any Returns an newly created label
   * @throws ApiError
   */
  public static postApiV10ProjectImageLabel({
    projectId,
    imageId,
    requestBody,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Image ID
     */
    imageId: string,
    requestBody: (Label | LabelBatchRequest),
  }): CancelablePromise<(Label | Array<Label>)> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1.0/project/{project_id}/image/{image_id}/label',
      path: {
        'project_id': projectId,
        'image_id': imageId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Edit multiple labels
   * @returns Label Returns an updated labels
   * @throws ApiError
   */
  public static putApiV10ProjectImageLabel({
    projectId,
    imageId,
    requestBody,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Image ID
     */
    imageId: string,
    requestBody: OmitReadonly<LabelBatchRequest>,
  }): CancelablePromise<Array<Label>> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v1.0/project/{project_id}/image/{image_id}/label',
      path: {
        'project_id': projectId,
        'image_id': imageId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Update multiple labels in batch
   * @returns any Returns the updated labels
   * @throws ApiError
   */
  public static labelsSync({
    projectId,
    imageId,
    requestBody,
    includeBorderMask,
    includeErrors,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Image ID
     */
    imageId: string,
    requestBody: Array<LabelSync>,
    /**
     * Boolean filter for calculete border mask
     */
    includeBorderMask?: boolean,
    /**
     * Whether to return labels with errors
     */
    includeErrors?: boolean,
  }): CancelablePromise<{
    meta: BatchMeta;
    items: Array<LabelSync>;
  }> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/v1/projects/{project_id}/images/{image_id}/labels_sync',
      path: {
        'project_id': projectId,
        'image_id': imageId,
      },
      query: {
        'include_border_mask': includeBorderMask,
        'include_errors': includeErrors,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Fetch labels for project
   * @returns any Successful read of labels
   * @throws ApiError
   */
  public static projectsLabelsList({
    projectId,
    offset,
    limit,
    labelClassId,
    labelerId,
    datasetId,
    imageStatus,
    imageName,
    labelAuthorId,
    attributeId,
    attributeValue,
    boolAttributeValue,
    createdSince,
    updatedSince,
    noAttributeId,
    keypointSchemaId,
    addKeypointData,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Offset for this paginated query
     */
    offset?: number,
    /**
     * Limit for this paginated query
     */
    limit?: number,
    /**
     * Label class ID
     */
    labelClassId?: string,
    /**
     * ID of the user that modified a label last
     */
    labelerId?: string,
    /**
     * Dataset ID
     */
    datasetId?: string,
    /**
     * Image status
     */
    imageStatus?: string,
    /**
     * Image name filtering
     */
    imageName?: string,
    /**
     * ID of the user that creates label
     */
    labelAuthorId?: string,
    /**
     * ID of the attribute to filter by (requries attrubute value)
     */
    attributeId?: string,
    /**
     * ID of the attribute value to filter by (requries attrubute id)
     */
    attributeValue?: string,
    /**
     * Boolean filter for attributes of type BOOL
     */
    boolAttributeValue?: boolean,
    /**
     * Datetime interval to filter labels by creation date (1h, 30m, 3d etc)
     */
    createdSince?: string,
    /**
     * Datetime interval to filter labels by update date (1h, 30m, 3d etc)
     */
    updatedSince?: string,
    /**
     * ID of the attribute that are not specified for a label
     */
    noAttributeId?: string,
    /**
     * ID of the keypoint schema to filter by
     */
    keypointSchemaId?: string,
    /**
     * Whether to aggregate and join keypoint class data per label.
     */
    addKeypointData?: boolean,
  }): CancelablePromise<{
    meta: PaginationMeta;
    items: Array<MRLabel>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/projects/{project_id}/labels',
      path: {
        'project_id': projectId,
      },
      query: {
        'offset': offset,
        'limit': limit,
        'label_class_id': labelClassId,
        'labeler_id': labelerId,
        'dataset_id': datasetId,
        'image_status': imageStatus,
        'image_name': imageName,
        'label_author_id': labelAuthorId,
        'attribute_id': attributeId,
        'attribute_value': attributeValue,
        'bool_attribute_value': boolAttributeValue,
        'created_since': createdSince,
        'updated_since': updatedSince,
        'no_attribute_id': noAttributeId,
        'keypoint_schema_id': keypointSchemaId,
        'add_keypoint_data': addKeypointData,
      },
    });
  }

  /**
   * Fetch label metadata
   * @returns Label1 Successful read of label properties
   * @throws ApiError
   */
  public static labelsGet({
    projectId,
    labelId,
    includeBorderMask,
    includeErrors,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Label ID
     */
    labelId: string,
    /**
     * Boolean filter for calculete border mask
     */
    includeBorderMask?: boolean,
    /**
     * Whether to return labels with errors
     */
    includeErrors?: boolean,
  }): CancelablePromise<Label1> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/projects/{project_id}/labels/{label_id}',
      path: {
        'project_id': projectId,
        'label_id': labelId,
      },
      query: {
        'include_border_mask': includeBorderMask,
        'include_errors': includeErrors,
      },
    });
  }

  /**
   * Fetch labels for the specified image
   * @returns any Successful read of labels
   * @throws ApiError
   */
  public static labelsList({
    projectId,
    imageId,
    offset,
    limit,
    includeBorderMask,
    includeErrors,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Image ID
     */
    imageId: string,
    /**
     * Offset for this paginated query
     */
    offset?: number,
    /**
     * Limit for this paginated query
     */
    limit?: number,
    /**
     * Boolean filter for calculete border mask
     */
    includeBorderMask?: boolean,
    /**
     * Whether to return labels with errors
     */
    includeErrors?: boolean,
  }): CancelablePromise<{
    meta: PaginationMeta;
    items: Array<Label1>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/projects/{project_id}/images/{image_id}/labels',
      path: {
        'project_id': projectId,
        'image_id': imageId,
      },
      query: {
        'offset': offset,
        'limit': limit,
        'include_border_mask': includeBorderMask,
        'include_errors': includeErrors,
      },
    });
  }

  /**
   * Create multiple labels in batch
   * @returns any Returns newly created labels
   * @throws ApiError
   */
  public static labelsCreate({
    projectId,
    imageId,
    requestBody,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Image ID
     */
    imageId: string,
    requestBody: Array<Label1>,
  }): CancelablePromise<{
    meta: BatchMeta;
    items: Array<Label1>;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/projects/{project_id}/images/{image_id}/labels',
      path: {
        'project_id': projectId,
        'image_id': imageId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Update multiple labels in batch
   * @returns any Returns the updated labels
   * @throws ApiError
   */
  public static labelsUpdate({
    projectId,
    imageId,
    requestBody,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Image ID
     */
    imageId: string,
    requestBody: Array<Label1>,
  }): CancelablePromise<{
    meta: BatchMeta;
    items: Array<Label1>;
  }> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/v1/projects/{project_id}/images/{image_id}/labels',
      path: {
        'project_id': projectId,
        'image_id': imageId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Delete multiple labels in batch
   * @returns void
   * @throws ApiError
   */
  public static labelsDelete({
    projectId,
    imageId,
    requestBody,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Image ID
     */
    imageId: string,
    requestBody: BatchIDRequest,
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/v1/projects/{project_id}/images/{image_id}/labels',
      path: {
        'project_id': projectId,
        'image_id': imageId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Fetch label attributes and values
   * @returns any Successful read of attributes and values
   * @throws ApiError
   */
  public static labelsAttributesList({
    projectId,
    labelId,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Label ID
     */
    labelId: string,
  }): CancelablePromise<{
    meta: BatchMeta;
    items: Array<LabelAttributeValue>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/projects/{project_id}/labels/{label_id}/attributes',
      path: {
        'project_id': projectId,
        'label_id': labelId,
      },
    });
  }

  /**
   * Set attribute value for label
   * @returns LabelAttributeValue Returns the attribute value
   * @throws ApiError
   */
  public static labelsAttributesCreate({
    projectId,
    labelId,
    attributeId,
    requestBody,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Label ID
     */
    labelId: string,
    /**
     * Attribute ID
     */
    attributeId: string,
    requestBody: OmitReadonly<LabelAttributeValue>,
  }): CancelablePromise<LabelAttributeValue> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/projects/{project_id}/labels/{label_id}/attributes/{attribute_id}',
      path: {
        'project_id': projectId,
        'label_id': labelId,
        'attribute_id': attributeId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
