import { createSelector } from '@reduxjs/toolkit';
import groupBy from 'lodash/groupBy';

import {
  LabelClass,
  LabelClassType,
} from '../../../../../api/domainModels/labelClass';
import { labelClassesAdapter } from './labelClasses.slice';
import { KCRelationsSelector } from '../KCRelations/KCRelations.selectors';

const labelClassesStateSelector = (state: RootState) =>
  state.project.annotationTaxonomy.labelClasses;

const selectors = labelClassesAdapter.getSelectors(labelClassesStateSelector);
export const labelClassesSelector = selectors.selectAll;
export const labelClassesIdsSelector = selectors.selectIds as (
  state: RootState,
) => LabelClass['id'][];
export const labelClassByIdSelector = selectors.selectById;
export const labelClassesMapSelector = selectors.selectEntities;

export const filteredLabelClassesSelector = createSelector(
  [labelClassesSelector, (_: RootState, filter: string) => filter],
  (labelClasses, filter) => {
    if (filter) {
      return labelClasses.filter((labelClass) =>
        labelClass.name.toLowerCase().includes(filter.toLowerCase()),
      );
    }

    return labelClasses;
  },
);
export const filteredLabelClassesGroupedByClassTypeSelector = createSelector(
  filteredLabelClassesSelector,
  (labelClasses) => groupBy(labelClasses, 'type'),
);
export const labelClassesGroupedByClassTypeSelector = createSelector(
  labelClassesSelector,
  (labelClasses) => groupBy(labelClasses, 'type'),
);
export const objectLabelClassesSelector = createSelector(
  labelClassesSelector,
  (labelClasses) =>
    labelClasses.filter(
      (labelClass) => labelClass.type === LabelClassType.Object,
    ),
);
export const semanticLabelClassesSelector = createSelector(
  labelClassesSelector,
  (labelClasses) =>
    labelClasses.filter(
      (labelClass) => labelClass.type === LabelClassType.Background,
    ),
);
export const labelClassesColorsSelector = createSelector(
  labelClassesSelector,
  (labelClasses) => labelClasses.map((lc) => lc.color),
);
export const labelClassesListLoadingStateSelector = createSelector(
  labelClassesStateSelector,
  (state) => state.listLoadingState,
);
export const labelClassesItemLoadingStateSelector = createSelector(
  labelClassesStateSelector,
  (state) => state.itemLoadingState,
);

export const labelClassesAsOptionsSelector = createSelector(
  [labelClassesSelector],
  (labelClasses) =>
    labelClasses.map(({ id, name, color }) => ({
      key: id,
      value: id,
      text: name,
      color,
    })),
);

export const objectLabelClassesAsOptionsSelector = createSelector(
  [objectLabelClassesSelector],
  (labelClasses) =>
    labelClasses.map(({ id, name, color }) => ({
      key: id,
      value: id,
      text: name,
      color,
    })),
);

export const semanticLabelClassesAsOptionsSelector = createSelector(
  [semanticLabelClassesSelector],
  (labelClasses) =>
    labelClasses.map(({ id, name, color }) => ({
      key: id,
      value: id,
      text: name,
      color,
    })),
);

export const labelClassesAsOptionsWithAllSelector = createSelector(
  [labelClassesAsOptionsSelector],
  (options) => [
    {
      text: 'All',
      key: 'all',
      value: null,
      color: null,
    },
    ...options,
  ],
);

export const objectLabelClassesAsOptionsWithAllSelector = createSelector(
  [objectLabelClassesAsOptionsSelector],
  (options) => [
    {
      text: 'All',
      key: 'all',
      value: null,
      color: null,
    },
    ...options,
  ],
);

export const semanticLabelClassesAsOptionsWithAllSelector = createSelector(
  [semanticLabelClassesAsOptionsSelector],
  (options) => [
    {
      text: 'All',
      key: 'all',
      value: null,
      color: null,
    },
    ...options,
  ],
);

export const labelClassesWithKeypointsSchemasAsOptionsWithAllSelector =
  createSelector(
    [labelClassesAsOptionsWithAllSelector, KCRelationsSelector],
    (options, KCRelations) => {
      const labelClassesWithKeypointsSchemas = KCRelations.map(
        ({ labelClassId }) => labelClassId,
      );

      return options.filter(
        ({ value }) =>
          value === null || labelClassesWithKeypointsSchemas.includes(value),
      );
    },
  );
