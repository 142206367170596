import axios from 'axios';

import { API_URL, API_URL_V2 } from '../../constants/paths';
import { apiWrapper } from '../utils';
import {
  IMAGES_LOAD_START,
  IMAGES_PRELOAD_THRESHOLD,
} from '../../constants/project';
import { headers, transformRequest, transformResponse } from '../helpers';
import { ImageStatus } from '../domainModels/imageStatus';
import { convertToBackendParams } from '../../redux/utils/backendParams';
import { ApiImage } from '../schemas/images';
import { ErrorType } from '../domainModels/consensusScoring';
import { EfUserAction } from '../../constants/consensusScoring';
import { ApiFilterParams } from '../schemas/projectImages';

const getFilterParams = ({
  datasetId,
  imageStatus,
  searchTerm,
  imageTags,
  csEfRunId,
  csUserAction,
  csErrorType,
  aldiSessionId,
}: {
  datasetId?: string | null;
  imageStatus?: string | null;
  searchTerm?: string | null;
  imageTags?: string | null;
  csEfRunId?: string | null;
  csUserAction?: EfUserAction | null;
  csErrorType?: ErrorType | null;
  aldiSessionId?: string | null;
}) => {
  const result: ApiFilterParams = {};

  if (datasetId) result.filterByDataset = datasetId;
  if (imageStatus) result.filterByImageStatus = imageStatus;
  if (searchTerm) result.searchByImageName = searchTerm;
  if (imageTags) result.filterByImageTags = imageTags;
  if (csEfRunId) result.filterByEfRun = csEfRunId;
  if (csUserAction) result.filterByEfUserAction = csUserAction;
  if (csErrorType) result.filterByEfErrorType = csErrorType;
  if (aldiSessionId) result.filterByAldiSession = aldiSessionId;

  return result;
};

export const apiLoadImages = ({
  projectId,
  offset = IMAGES_LOAD_START,
  limit = IMAGES_PRELOAD_THRESHOLD,
  datasetId,
  imageStatus,
  searchTerm,
  imageTags,
  csEfRunId,
  csUserAction,
  csErrorType,
  aldiSessionId,
}: {
  projectId: string;
  offset?: number;
  limit?: number;
  datasetId?: string | null;
  imageStatus?: string | null;
  searchTerm?: string | null;
  imageTags?: string | null;
  csEfRunId?: string | null;
  csUserAction?: EfUserAction | null;
  csErrorType?: ErrorType | null;
  aldiSessionId?: string | null;
}) => {
  const params = {
    offset,
    limit,
    ...getFilterParams({
      datasetId,
      imageStatus,
      searchTerm,
      imageTags,
      csEfRunId,
      csUserAction,
      csErrorType,
      aldiSessionId,
    }),
  };

  const source = axios.CancelToken.source();
  const axiosPromise = axios.request<{
    items?: Array<ApiImage>;
    itemsPerPage?: number;
    total: number; // can be undefined in swagger
  }>({
    method: 'get',
    url: `${API_URL}/project/${projectId}/images`,
    cancelToken: source.token,
    headers,
    transformResponse,
    transformRequest,
    params: convertToBackendParams(params),
  });

  return apiWrapper(axiosPromise);
};

export const apiLoadImage = (projectId: string, imageId: string) =>
  apiWrapper(
    axios.request<ApiImage>({
      method: 'get',
      headers,
      transformResponse,
      transformRequest,
      url: `${API_URL}/project/${projectId}/image/${imageId}`,
    }),
  );

export const apiImageOffset = (
  projectId: string,
  imageId: string,
  datasetId?: string | null,
  imageStatus?: string | null,
  imageTags?: string | null,
  searchTerm?: string | null,
  csEfRunId?: string | null,
  csUserAction?: EfUserAction | null,
  aldiSessionId?: string | null,
) => {
  const params = getFilterParams({
    datasetId,
    imageStatus,
    searchTerm,
    imageTags,
    csEfRunId,
    csUserAction,
    aldiSessionId,
  });

  return apiWrapper(
    axios.request<{
      imageId: string;
      offset: number;
    }>({
      method: 'get',
      url: `${API_URL}/project/${projectId}/image/${imageId}/offset`,
      headers,
      transformResponse,
      transformRequest,
      params: convertToBackendParams(params),
    }),
  );
};

export const apiUpdateImageStatus = (
  projectId: string,
  imageId: string,
  status: ImageStatus,
) =>
  apiWrapper(
    axios({
      method: 'post',
      url: `${API_URL}/project/${projectId}/image/${imageId}/status/${status}`,
    }),
  );

export const apiUpdateAllDatasetImagesStatus = (
  params: { projectId: string; datasetId: string },
  data: { status: ImageStatus },
) =>
  apiWrapper(
    axios.request({
      method: 'put',
      url: `${API_URL_V2}/datasets/projects/${params.projectId}/datasets/${params.datasetId}/images/status`,
      headers,
      transformResponse,
      transformRequest,
      data,
    }),
  );
