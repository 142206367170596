import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

export const adapter = createEntityAdapter<string>({
  selectId: (id) => id,
});
const { actions, reducer: hiddenClassesReducer } = createSlice({
  name: 'imageViewHiddenClasses',
  initialState: adapter.getInitialState(),
  reducers: {
    hideLabelClass(state, action: PayloadAction<string>) {
      adapter.addOne(state, action.payload);
    },
    showLabelClass(state, action: PayloadAction<string>) {
      adapter.removeOne(state, action.payload);
    },
    hideSomeLabelClasses(state, action: PayloadAction<string[]>) {
      adapter.addMany(state, action.payload);
    },
    showSomeLabelClasses(state, action: PayloadAction<string[]>) {
      adapter.removeMany(state, action.payload);
    },
  },
});

export const {
  hideLabelClass,
  showLabelClass,
  hideSomeLabelClasses,
  showSomeLabelClasses,
} = actions;
export { hiddenClassesReducer };
