import { takeEvery, put, select } from 'typed-redux-saga';
import qs from 'query-string';
import { push } from 'connected-react-router';

import {
  setUrlSearchParams,
  resetUrlSearchParams,
} from './urlSearchParams.actions';
import { routerSelector } from '../../root.selectors';

function* setUrlSearchParamsHandler(
  action: ActionType<typeof setUrlSearchParams>,
) {
  const entries = Object.entries(action.payload).filter(
    ([, value]) => value !== null && value !== undefined && value !== '',
  );
  const filters: Record<string, any> = {};
  for (const [, val] of Object.entries(entries)) {
    // eslint-disable-next-line prefer-destructuring
    filters[val[0]] = val[1];
  }
  const searchString = qs.stringify(filters);

  yield* put(push({ search: searchString }));
}

function* resetUrlSearchParamsHandler(
  action: ActionType<typeof resetUrlSearchParams>,
) {
  const {
    location: { search },
  } = yield* select(routerSelector);
  const omitProps = action.payload;

  if (search !== '' && !omitProps) {
    yield* put(
      push({
        search: '',
      }),
    );
  }

  if (omitProps) {
    const parsedParams = qs.parse(search);
    const newParams = Object.entries(parsedParams).filter(([key]) =>
      omitProps.includes(key),
    );

    const searchString: Record<string, any> = {};
    for (const [, val] of Object.entries(newParams)) {
      // eslint-disable-next-line prefer-destructuring
      searchString[val[0]] = val[1];
    }
    yield* put(
      push({
        search: qs.stringify(searchString),
      }),
    );
  }
}

export function* urlSearchParamsSaga() {
  yield* takeEvery(setUrlSearchParams, setUrlSearchParamsHandler);
  yield* takeEvery(resetUrlSearchParams, resetUrlSearchParamsHandler);
}
