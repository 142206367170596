import { LabelType } from '../constants/label';
import { Bbox } from '../../@types/imageView/types';
import { ApiLabel as Label } from '../schemas/imageLabels';

export const getLabelType = ({
  polygon,
  mask,
  keypoints,
}: {
  polygon: number[] | [number, number][] | null;
  mask: Label['mask'];
  keypoints?: Label['keypoints'];
}) => {
  if (mask) return LabelType.Mask;
  if (keypoints) return LabelType.Keypoints;

  return polygon ? LabelType.Polygon : LabelType.Bbox;
};

export const calculateBbox = (points: number[], returnStrictBbox?: boolean) => {
  const yValues = points.filter((e, i) => i % 2);
  const xValues = points.filter((e, i) => !(i % 2));
  const xMin = Math.min(...xValues);
  const xMax = Math.max(...xValues);
  const yMin = Math.min(...yValues);
  const yMax = Math.max(...yValues);

  if (returnStrictBbox)
    return [
      xMin,
      yMin,
      xMin === xMax ? xMax + 1 : xMax,
      yMin === yMax ? yMax + 1 : yMax,
    ] as Bbox;

  return [xMin, yMin, xMax, yMax] as Bbox;
};

export const getBbox = (
  bbox: Label['bbox'],
  mask: Label['mask'],
  polygon: Label['polygon'],
  keypoints: Label['keypoints'],
): Bbox => {
  if (bbox) {
    return bbox;
  }

  if (keypoints) {
    return calculateBbox(
      keypoints.map((keypoint) => [keypoint.x, keypoint.y]).flat(),
      true,
    );
  }

  if (mask) {
    return calculateBbox(mask);
  }

  if (polygon) {
    return calculateBbox(polygon.flat());
  }

  return [0, 0, 0, 0];
};

export const uniqueItems = <T>(list: T[], keyFn: (item: T) => any): number =>
  list.reduce((resultSet, item) => resultSet.add(keyFn(item)), new Set()).size;
