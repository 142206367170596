import { call, put, select, takeEvery } from 'typed-redux-saga';

import {
  createExportSessionDataMapper,
  exportSessionsDataMapper,
} from '../../../../../api/domainModels/export';
import {
  apiCreateExportSession,
  apiDeleteExportSession,
  apiLoadExportSessions,
} from '../../../../../api/requests/export';
import { getErrorMessage } from '../../../../../api/utils';
import { handleError } from '../../../commonFeatures/errorHandler/errorHandler.actions';
import { activeProjectIdSelector } from '../../../project/project.selectors';
import { loadAll } from '../../../../utils/api';
import { hideModals } from '../../../ui/modals/modals.slice';
import {
  createExportSessionStart,
  createExportSessionSuccess,
  loadExportSessionsFailure,
  loadExportSessionsStart,
  loadExportSessionsSuccess,
  removeExportSessionFailure,
  removeExportSessionStart,
  removeExportSessionSuccess,
  updateExportSession,
} from './exportSessions.slice';
import { NOTIFICATION_SENT } from '../../../ws/ws.constants';
import { WebsocketNotificationPayload } from '../../../../../api/domainModels/websocketTypes';
import { exportSessionsSelector } from './exportSessions.selectors';
import { ExportSessionStatus } from '../../../../../api/constants/export';
import { Notification } from '../../../../../api/codegen';

function* loadExportSessionsHandler() {
  const projectId = yield* select(activeProjectIdSelector);
  if (!projectId) return;

  try {
    const items = yield* loadAll({
      apiHelper: apiLoadExportSessions,
      params: { projectId },
      fromBackend: exportSessionsDataMapper.fromBackend,
    });

    yield* put(loadExportSessionsSuccess(items));
  } catch (error) {
    const errorMessage = getErrorMessage(
      error,
      'Not able to load export sessions.',
    );

    yield* put(loadExportSessionsFailure(errorMessage));
    yield* put(
      handleError({ message: errorMessage, error, allowOutsideOfEditor: true }),
    );
  }
}

function* createExportSessionStartHandler(
  action: ActionType<typeof createExportSessionStart>,
) {
  const { data, projectId } = action.payload;

  try {
    const { data: exportSession } = yield* call(
      apiCreateExportSession,
      projectId,
      createExportSessionDataMapper.toBackend(data),
    );

    yield* put(
      createExportSessionSuccess(
        exportSessionsDataMapper.fromBackend(exportSession),
      ),
    );
  } catch (error) {
    const errorMessage = getErrorMessage(
      error,
      'Not able to create export session.',
    );

    yield* put(
      handleError({ message: errorMessage, error, allowOutsideOfEditor: true }),
    );
  }
}

function* removeExportSessionStartHandler(
  action: ActionType<typeof removeExportSessionStart>,
) {
  const projectId = yield* select(activeProjectIdSelector);
  const sessionId = action.payload;

  try {
    yield* call(apiDeleteExportSession, projectId, sessionId);
    yield* put(removeExportSessionSuccess(sessionId));
    yield* put(hideModals());
  } catch (error) {
    const errorMessage = getErrorMessage(
      error,
      'Not able to delete the export session.',
    );

    yield* put(removeExportSessionFailure());
    yield* put(
      handleError({ message: errorMessage, error, allowOutsideOfEditor: true }),
    );
  }
}

function* notificationHandler({
  payload,
}: WebsocketNotificationPayload & { type: string }) {
  const exportSessions = yield* select(exportSessionsSelector);

  if (
    payload?.subject ===
      Notification.subject.NOTIFICATION_SUBJECT_PROJECT_EXPORTED ||
    payload?.subject ===
      Notification.subject.NOTIFICATION_SUBJECT_PROJECT_EXPORT_FAILED
  ) {
    const exportSession = exportSessions.find(
      (session) => payload?.jobId === session.exportJobId,
    );

    if (exportSession) {
      let status;

      switch (payload?.subject) {
        case Notification.subject.NOTIFICATION_SUBJECT_PROJECT_EXPORTED:
          status = ExportSessionStatus.Exported;
          break;
        case Notification.subject.NOTIFICATION_SUBJECT_PROJECT_EXPORT_FAILED:
          status = ExportSessionStatus.Failed;
          break;
        default:
          status = ExportSessionStatus.New;
      }

      yield* put(
        updateExportSession({
          id: exportSession.id,
          changes: {
            status,
          },
        }),
      );
    }
  }
}

export function* exportSessionsSaga() {
  yield* takeEvery(loadExportSessionsStart, loadExportSessionsHandler);
  yield* takeEvery(createExportSessionStart, createExportSessionStartHandler);
  yield* takeEvery(removeExportSessionStart, removeExportSessionStartHandler);
  yield* takeEvery(NOTIFICATION_SENT, notificationHandler);
}
