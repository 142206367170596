export enum HeuristicRankingOrigin {
  AutoGenerated = 'ai-assistant',
  OnDemand = 'user',
}

export enum HeuriscticRankingJobStatus {
  Initial = 'INIT',
  Running = 'RUNNING',
  Failed = 'FAILED',
  Done = 'DONE',
}
