import { createSelector } from '@reduxjs/toolkit';

import { adapter } from './confidence.slice';
import { errorFinderSelector } from '../errorFinder.selectors';

export const labelConfidenceStateSelector = createSelector(
  errorFinderSelector,
  (errorFinder) => errorFinder.currentLabelConfidence,
);
const selectors = adapter.getSelectors(labelConfidenceStateSelector);

export const labelConfidenceSelector = selectors.selectAll;
export const labelConfidenceByIdSelector = selectors.selectById;

export const labelConfidenceLoadingStateSelector = createSelector(
  labelConfidenceStateSelector,
  (state) => state.loadingState,
);
