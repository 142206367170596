import { call, put, takeEvery, select } from 'typed-redux-saga';

import {
  apiLoadWidgetExperimentsMetrics,
  apiLoadWidgetExperimentsMetricsMeta,
} from '../../../../../../../api/requests/widgets';
import { getErrorMessage } from '../../../../../../../api/utils';
import { activeProjectIdSelector } from '../../../../../project/project.selectors';
import {
  loadExperimentsMetrics,
  loadExperimentsMetricsSuccess,
  loadExperimentsMetricsFailure,
} from './experimentsMetrics.slice';
import {
  widgetExperimentMetricMetaMapper,
  widgetExperimentMetricMapper,
  WidgetExperimentMetricItem,
  WidgetExperimentMetric,
} from '../../../../../../../api/domainModels/widgets';
import { enqueueNotification } from '../../../../../ui/stackNotifications/stackNotifications.slice';

type MetricMetaItem = Record<string, WidgetExperimentMetric>;

const appendMissingMetricsMeta = (
  meta: WidgetExperimentMetric[],
  items: WidgetExperimentMetricItem[],
) => {
  const lossMeta = meta.find(({ id }) => id === 'trainLoss');

  if (!lossMeta) return meta; // for ts satifsaction

  const metricsMetaObject = items.reduce((metaAcc, item) => {
    const experimentMetricsMeta = Object.keys(item.metrics).reduce(
      (experimentAcc, metricKey) => {
        const [metricName, metricType] = metricKey.split('_');

        return {
          ...experimentAcc,
          [metricKey]: {
            ...lossMeta,
            id: metricKey,
            name: `Best ${metricName} (${metricType})`,
          },
        };
      },
      {} as MetricMetaItem,
    );

    return { ...metaAcc, ...experimentMetricsMeta };
  }, {} as MetricMetaItem);

  const metricsMeta = Object.values(metricsMetaObject);

  const nonLossMeta = meta.filter(
    ({ id }) => !['trainLoss', 'valLoss'].includes(id),
  );

  return [...nonLossMeta, ...metricsMeta];
};

function* loadExperimentsMetricsHandler(
  action: ActionType<typeof loadExperimentsMetrics>,
) {
  const projectId = yield* select(activeProjectIdSelector);
  const experimentIds = action.payload;

  try {
    const { data } = yield* call(
      apiLoadWidgetExperimentsMetrics,
      {
        projectId,
      },
      { experimentIds },
    );
    const { data: metaData } = yield* call(
      apiLoadWidgetExperimentsMetricsMeta,
      {
        projectId,
      },
    );
    const { items: rawItems } = data;
    const { meta: rawMeta } = metaData;

    const items = rawItems.map(widgetExperimentMetricMapper.fromBackend);
    const meta = rawMeta.map(widgetExperimentMetricMetaMapper.fromBackend);

    const metrics = appendMissingMetricsMeta(meta, items);

    yield* put(loadExperimentsMetricsSuccess({ items, metrics }));
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to fetch widget data');

    yield* put(loadExperimentsMetricsFailure(message));
    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
          refresh: false,
        },
      }),
    );
  }
}

export function* experimentsMetricsSaga() {
  yield* takeEvery(loadExperimentsMetrics, loadExperimentsMetricsHandler);
}
