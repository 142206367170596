/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type BucketCredentialsRead = {
  /**
   * Credentials unique identifier
   */
  credentialsId?: string;
  /**
   * Any human-readable name for credentials
   */
  description: string;
  /**
   * Cloud provider
   */
  cloudProvider: BucketCredentialsRead.cloudProvider;
  /**
   * When the credential was created
   */
  createdAt?: string;
  /**
   * When the credential was updated for the last time
   */
  updateAt?: string;
};

export namespace BucketCredentialsRead {

  /**
   * Cloud provider
   */
  export enum cloudProvider {
    S3 = 's3',
    GCS = 'gcs',
    AZ = 'az',
    DUMMY = 'dummy',
  }


}

