import axios from 'axios';

import { ApiRoleParams } from '../schemas/workspaceRoles';
import { API_URL } from '../../constants/paths';
import { ApiRole } from '../domainModels/role';
import { apiWrapper } from '../utils';
import { headers, transformRequest, transformResponse } from '../helpers';

export const apiLoadWorkspaceRoles = (workspaceId: string) =>
  apiWrapper(
    axios.request<ApiRole[]>({
      method: 'get',
      url: `${API_URL}/workspace/${workspaceId}/roles`,
      headers,
      transformRequest,
      transformResponse,
    }),
  );

export const apiCreateWorkspaceRole = (
  workspaceId: string,
  { name, color }: ApiRoleParams,
) =>
  apiWrapper(
    axios.request<ApiRole>({
      method: 'post',
      url: `${API_URL}/workspace/${workspaceId}/role`,
      data: {
        role_name: name,
        color,
      },
      headers,
      transformRequest,
      transformResponse,
    }),
  );

export const apiUpdateWorkspaceRole = (
  workspaceId: string,
  roleId: number,
  { name, color }: ApiRoleParams,
) =>
  apiWrapper(
    axios.request<ApiRole>({
      method: 'put',
      url: `${API_URL}/workspace/${workspaceId}/role/${roleId}`,
      data: {
        role_name: name,
        color,
      },
      headers,
      transformRequest,
      transformResponse,
    }),
  );

export const apiDeleteWorkspaceRole = (workspaceId: string, roleId: number) =>
  apiWrapper(
    axios.request<ApiRole>({
      method: 'delete',
      url: `${API_URL}/workspace/${workspaceId}/role/${roleId}`,
      headers,
      transformRequest,
      transformResponse,
    }),
  );
