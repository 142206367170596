import { call, put, select, takeEvery } from 'typed-redux-saga';

import {
  loadKeypointsTemplatesFailure,
  loadKeypointsTemplatesSuccess,
  loadKeypointsTemplatesStart,
} from './keypointsTemplates.slice';
import { activeProjectIdSelector } from '../../project.selectors';
import { initImageProject } from '../../../sections/editedProject/project/project.slice';
import { setProjectId } from '../../../core/imageView/project/project.slice';
import { apiLoadKeypointsTemplates } from '../../../../../api/requests/keypoints';
import { getErrorMessage } from '../../../../../api/utils';
import { enqueueNotification } from '../../../ui/stackNotifications/stackNotifications.slice';

function* loadKeypointsTemplatesHandler() {
  const projectId = yield* select(activeProjectIdSelector);

  yield* put(loadKeypointsTemplatesStart());

  try {
    const response = yield* call(apiLoadKeypointsTemplates, { projectId });

    yield* put(loadKeypointsTemplatesSuccess(response.data));
  } catch (error) {
    enqueueNotification({
      message: getErrorMessage(error, 'Not able to load keypoint templates'),
      options: { variant: 'error', error, allowOutsideOfEditor: true },
    });
    yield* put(loadKeypointsTemplatesFailure());
  }
}

export function* keypointsTemplatesSaga() {
  yield* takeEvery(
    [initImageProject, setProjectId],
    loadKeypointsTemplatesHandler,
  );
}
