import { put, select, takeEvery } from 'typed-redux-saga';
import { PayloadAction } from '@reduxjs/toolkit';

import { keypointsEditingStateSaga } from './keypointsEditingState/keypointsEditingState.saga';
import { keypointsValuesSaga } from './keypointsValues/keypointsValues.saga';
import { selectActiveLabelClassId } from '../../labelClasses/labelClasses.slice';
import { resetKeypoints } from './keypoints.actions';
import { setActiveTool } from '../tools.slice';
import { editedKeypointsLabelSelector } from '../../labels/selectedLabels/selectedLabels.selectors';
import { setImageId } from '../../currentImage/currentImage.slice';
import { redoLabels, undoLabels } from '../../labels/labels.slice';
import { keypointsValuesSelector } from './keypointsValues/keypointsValues.selectors';
import {
  ActionTrigger,
  triggerAction,
} from '../../toolbarActionTrigger/toolbarActionTrigger.slice';

function* selectActiveLabelClassIdHandler() {
  const editedKeypointsLabel = yield* select(editedKeypointsLabelSelector);

  if (!editedKeypointsLabel) {
    yield* put(resetKeypoints());
  }
}

function* resetHandler() {
  yield* put(resetKeypoints());
}

// We have to manually triggered undoLabels and redoLabels
// Since we're creating labels after confirming a keypoints
// But we override ImageView hotkeys at this point
// https://hasty.atlassian.net/browse/HT-4870
function* undoRedoLabelsHandler({ payload }: PayloadAction<ActionTrigger>) {
  const keypointsValues = yield* select(keypointsValuesSelector);

  if (payload === ActionTrigger.Undo) {
    const isLabelsCreatedInPast =
      keypointsValues.past.length > 0 &&
      keypointsValues.present.points.length === 0;

    if (isLabelsCreatedInPast) {
      yield* put(undoLabels());
    }
  }

  if (payload === ActionTrigger.Redo) {
    const isLabelsCreatedInFuture =
      keypointsValues.future.length > 0 &&
      keypointsValues.future[0].points.length === 0 &&
      keypointsValues.present.points.length > 0;

    if (isLabelsCreatedInFuture) {
      yield* put(redoLabels());
    }
  }
}

function* keypointsSaga() {
  yield* takeEvery(selectActiveLabelClassId, selectActiveLabelClassIdHandler);
  yield* takeEvery([setActiveTool, setImageId], resetHandler);
  yield* takeEvery(triggerAction, undoRedoLabelsHandler);
}

export const keypointsSagas = [
  keypointsSaga,
  keypointsEditingStateSaga,
  keypointsValuesSaga,
];
