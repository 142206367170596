import { useEffect, useRef, useState } from 'react';

import { LoadingStatus, statusChecks } from '../constants/status';

const TIMEOUT = 300;

export const useDelayedProgressStatus = (status: LoadingStatus) => {
  const [savedStatus, setSavedStatus] = useState<LoadingStatus>(
    LoadingStatus.Initial,
  );
  const timeoutRef = useRef<number | null>(null);
  let result = savedStatus;

  useEffect(
    () => () => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
    },
    [],
  );

  if (status !== savedStatus) {
    const isInProgress = statusChecks.isInProgress(status);
    const wasInProgress = statusChecks.isInProgress(savedStatus);
    const changedToProgress = isInProgress && !wasInProgress;

    if (changedToProgress) {
      if (!timeoutRef.current) {
        // in case we've just switched to progress, change state only after a timeout
        timeoutRef.current = setTimeout(() => {
          setSavedStatus(status);
        }, TIMEOUT);
      }

      result = LoadingStatus.Initial;
    } else {
      setSavedStatus(status);
      result = status;
    }

    if (wasInProgress && !isInProgress && timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  }

  return result;
};
