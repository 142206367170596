import { combineReducers } from 'redux';

import { editedProjectReducer } from './editedProject/editedProject.reducer';
import { managedWorkspaceReducer } from './managedWorkspace/managedWorkspace.reducer';
import { projectListReducer } from './projectList/projectList.reducer';

export const sectionsReducer = combineReducers({
  editedProject: editedProjectReducer,
  managedWorkspace: managedWorkspaceReducer,
  projectList: projectListReducer,
});
