export enum VideoStatus {
  New = 'NEW',
  InProgress = 'IN PROGRESS',
  ToReview = 'TO REVIEW',
  Done = 'DONE',
  Skipped = 'SKIPPED',
  AutoLabeled = 'AUTO-LABELLED', // for ts satisfaction to be aligned with ImageStatus
}

export enum VideoHealthStatus {
  OK = 'OK',
  Error = 'ERROR',
  Unhealthy = 'UNHEALTHY',
  Processing = 'PROCESSING',
}

export enum VideoSegmentPredictionJobStatus {
  Pending = 'PENDING', // this is just on FE (BE sends null when no job so far)
  Init = 'INIT',
  Running = 'RUNNING',
  Paused = 'PAUSED',
  Done = 'DONE',
  Failed = 'FAILED',
}

export enum VideoSegmentPredictionJobAction {
  Start = 'start',
  Pause = 'pause',
  Status = 'status',
}

export enum VideoAvailability {
  Available = 'available',
  Unavailable = 'unavailable',
  Unset = 'unset',
}
