import { takeEvery, put, select } from 'typed-redux-saga';

import {
  cleanupActiveToolState,
  requestCleanupActiveToolEntities,
} from './activeToolData.slice';
import { activeToolDataSelector } from './activeToolData.selectors';
import { setActiveTool, resetActiveTool } from '../tools.slice';
import { setImageId } from '../../currentImage/currentImage.slice';
import { releaseObject } from '../../imageView.helpers';

function* requestCleanupActiveToolEntitiesHandler() {
  const toolData = yield* select(activeToolDataSelector);

  toolData.forEach(releaseObject);

  yield* put(cleanupActiveToolState());
}

export function* activeToolDataSaga() {
  yield* takeEvery(
    [setActiveTool, resetActiveTool, setImageId],
    requestCleanupActiveToolEntitiesHandler,
  );
  yield* takeEvery(
    requestCleanupActiveToolEntities,
    requestCleanupActiveToolEntitiesHandler,
  );
}
