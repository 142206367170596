import { PackageName } from '../../constants/packageFeatures';
import { ApiFeature, ApiPackage } from '../schemas/packageFeatures';

const packageFromBackend = ({ name, ...rest }: ApiPackage) => ({
  ...rest,
  name: name as PackageName,
});

export const packageDataMapper = {
  fromBackend: packageFromBackend,
};

export type Package = ReturnType<typeof packageFromBackend>;
export type PackageWithPrice = Package & { price: number };
export type Feature = ApiFeature;
export type Plan = PackageWithPrice & {
  features: string[];
};
