import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ControlledTooltipId } from '../../../../constants/controlledTooltip';

const initialState = {
  ids: [] as ControlledTooltipId[],
};
const { actions, reducer: controlledTooltipReducer } = createSlice({
  name: 'controlledTooltip',
  initialState,
  reducers: {
    showControlledTooltip(
      state,
      action: PayloadAction<{
        id: ControlledTooltipId;
      }>,
    ) {
      state.ids.push(action.payload.id);
    },
    requestShowControlledTooltip(
      _state,
      _action: PayloadAction<{
        id: ControlledTooltipId;
      }>,
    ) {},
    hideControlledTooltip(
      state,
      action: PayloadAction<{
        id: ControlledTooltipId;
      }>,
    ) {
      state.ids = state.ids.filter((id) => id !== action.payload.id);
    },
    hideAllControlledTooltips(state) {
      state.ids = initialState.ids;
    },
  },
});
export const {
  requestShowControlledTooltip,
  showControlledTooltip,
  hideControlledTooltip,
  hideAllControlledTooltips,
} = actions;
export { controlledTooltipReducer };
