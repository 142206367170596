import { createSelector } from '@reduxjs/toolkit';

import { imageViewSelector } from '../imageView.selectors';

const persistedProjectImageSelector = createSelector(
  imageViewSelector,
  (imageView) => imageView.persistedProjectImage,
);

export const persistedProjectImageByIdSelector = createSelector(
  [
    (_: RootState, projectId: string) => projectId,
    persistedProjectImageSelector,
  ],
  (projectId, persistedProjectImages) => persistedProjectImages[projectId],
);
