import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { loadingStateBuilder } from '../../../../utils/loadingState';
import { updateMultiLabelAttributeValueSuccess } from '../multiLabelAttributeValues/multiLabelAttributeValues.slice';
import { LabelAttributeValue } from '../../../../../api/domainModels/attribute';
import { ImageLabel } from '../../../../../api/domainModels/imageLabel';

export const selectId = (lav: Pick<LabelAttributeValue, 'id' | 'labelId'>) =>
  `${lav.labelId}:${lav.id}`;

export const labelAttributeValuesAdapter =
  createEntityAdapter<LabelAttributeValue>({
    selectId,
  });

const { actions, reducer: labelAttributeValuesReducer } = createSlice({
  name: 'imageView/labelAttributeValues',
  initialState: labelAttributeValuesAdapter.getInitialState({
    loadingState: loadingStateBuilder.initial(),
    updateLoadingState: loadingStateBuilder.initial(),
  }),
  reducers: {
    loadLabelAttributeValuesStart(state) {
      labelAttributeValuesAdapter.setAll(state, []);
      state.loadingState = loadingStateBuilder.inProgress(
        'Loading attribute values',
      );
    },
    loadLabelAttributeValuesSuccess(
      state,
      action: PayloadAction<LabelAttributeValue[]>,
    ) {
      labelAttributeValuesAdapter.setAll(state, action.payload);
      state.loadingState = loadingStateBuilder.success();
    },
    loadLabelAttributeValuesFailure(state, action: PayloadAction<string>) {
      labelAttributeValuesAdapter.setAll(state, []);
      state.loadingState = loadingStateBuilder.failure(action.payload);
    },
    updateLabelAttributeValueStart(
      state,
      _action: PayloadAction<
        LabelAttributeValue & { predictedValues?: string }
      >,
    ) {
      state.updateLoadingState = loadingStateBuilder.initial();
    },
    updateLabelAttributeValueSuccess(
      state,
      action: PayloadAction<LabelAttributeValue>,
    ) {
      state.updateLoadingState = loadingStateBuilder.success();
      labelAttributeValuesAdapter.upsertOne(state, action.payload);
    },
    updateLabelAttributeValueFailure(state, action: PayloadAction<string>) {
      state.updateLoadingState = loadingStateBuilder.failure(action.payload);
    },
    addLabelAttributeValues(
      _state,
      _action: PayloadAction<Array<Partial<ImageLabel> & { id: string }>>,
    ) {},
    addLabelAttributeValuesSuccess(
      state,
      action: PayloadAction<LabelAttributeValue[]>,
    ) {
      labelAttributeValuesAdapter.upsertMany(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateMultiLabelAttributeValueSuccess, (state, action) => {
      labelAttributeValuesAdapter.upsertMany(state, action.payload);
    });
  },
});

export const {
  loadLabelAttributeValuesFailure,
  loadLabelAttributeValuesStart,
  loadLabelAttributeValuesSuccess,
  updateLabelAttributeValueFailure,
  updateLabelAttributeValueStart,
  updateLabelAttributeValueSuccess,
  addLabelAttributeValues,
  addLabelAttributeValuesSuccess,
} = actions;
export { labelAttributeValuesReducer };
