import { put, select, takeEvery, call } from 'typed-redux-saga';

import { apiLoadWorkspaceStripePortalUrl } from '../../../../../api/requests/stripe';
import { getErrorMessage } from '../../../../../api/utils';
import {
  loadStripePortalUrlAndOpen,
  loadStripePortalUrlSuccess,
  loadStripePortalUrlFailure,
} from './stripe.slice';
import { workspaceIdSelector } from '../../../commonFeatures/workspaceId/workspaceId.selectors';
import {
  stripePortalUrlDataSelector,
  stripePortaUrlWorkspaceSelector,
} from './stripe.selectors';

function* loadStripePortalUrlAndOpenHandler(
  _action: ActionType<typeof loadStripePortalUrlAndOpen>,
) {
  const workspaceId = yield* select(workspaceIdSelector);

  if (!workspaceId) return;

  const alreadyFetchedPortalUrl = yield* select(stripePortalUrlDataSelector);
  const alreadyFetchedForWorkspaceId = yield* select(
    stripePortaUrlWorkspaceSelector,
  );

  if (alreadyFetchedForWorkspaceId === workspaceId && alreadyFetchedPortalUrl) {
    yield* put(
      loadStripePortalUrlSuccess({ url: alreadyFetchedPortalUrl, workspaceId }),
    );

    window.open(alreadyFetchedPortalUrl, '_blank');

    return;
  }

  try {
    const {
      data: { url },
    } = yield* call(apiLoadWorkspaceStripePortalUrl, { workspaceId });

    yield* put(loadStripePortalUrlSuccess({ url, workspaceId }));

    window.open(url, '_blank');
  } catch (e) {
    yield* put(
      loadStripePortalUrlFailure(
        getErrorMessage(e, 'Not able to load workspace stripe portal url'),
      ),
    );
  }
}

export function* stripeSaga() {
  yield* takeEvery(
    loadStripePortalUrlAndOpen,
    loadStripePortalUrlAndOpenHandler,
  );
}
