import {
  createSlice,
  PayloadAction,
  createEntityAdapter,
  EntityState,
  Update,
} from '@reduxjs/toolkit';

import {
  ExperimentParameter,
  ExperimentComponent,
} from '../../../../../../api/domainModels/modelPlayground';
import {
  loadingStateBuilder,
  LoadingState,
} from '../../../../../utils/loadingState';
import { setActiveExperimentId } from '../activeExperimentId/activeExperimentId.slice';
import { setEditedProjectId } from '../../../../sections/editedProject/project/project.slice';

export const componentsAdapter = createEntityAdapter<ExperimentComponent>({
  sortComparer: (a, b) => (a.componentId < b.componentId ? 1 : -1),
});
export const parametersAdapter = createEntityAdapter<ExperimentParameter>();

type RegimensState = {
  components: EntityState<ExperimentComponent> & {
    loadingState: LoadingState;
    updateLoadingState: LoadingState;
  };
  parameters: EntityState<ExperimentParameter> & {
    loadingState: LoadingState;
    updateLoadingState: LoadingState;
  };
};

const initialState: RegimensState = {
  components: componentsAdapter.getInitialState({
    loadingState: loadingStateBuilder.initial(),
    updateLoadingState: loadingStateBuilder.initial(),
  }),
  parameters: parametersAdapter.getInitialState({
    loadingState: loadingStateBuilder.initial(),
    updateLoadingState: loadingStateBuilder.initial(),
  }),
};

const { actions, reducer: regimensReducer } = createSlice({
  name: 'regimens',
  initialState,
  reducers: {
    loadRegimens(state) {
      state.components.loadingState =
        loadingStateBuilder.inProgress('Loading regimens');
      componentsAdapter.removeAll(state.components);
      parametersAdapter.removeAll(state.parameters);
    },
    loadRegimensFailure(state, action: PayloadAction<string>) {
      state.components.loadingState = loadingStateBuilder.failure(
        action.payload,
      );
    },
    loadRegimensSuccess(state, action: PayloadAction<ExperimentComponent[]>) {
      state.components.loadingState = loadingStateBuilder.success();
      componentsAdapter.setAll(state.components, action.payload);
    },
    updateExperimentRegimen(state, _action: PayloadAction<string>) {
      state.components.updateLoadingState =
        loadingStateBuilder.inProgress('Updating regimen');
    },
    updateExperimentRegimenFailure(state, action: PayloadAction<string>) {
      state.components.updateLoadingState = loadingStateBuilder.failure(
        action.payload,
      );
    },
    updateExperimentRegimenSuccess(
      state,
      action: PayloadAction<ExperimentComponent>,
    ) {
      // first remove the component that we selected to avoid iterating over it
      componentsAdapter.removeOne(state.components, action.payload.componentId);
      const componentsIds = state.components.ids;
      const componentsEntities = state.components.entities;
      // update remaining components by changing it's id's and nullifying it's experimentComponentIds
      componentsAdapter.updateMany(
        state.components,
        componentsIds.map((id) => ({
          id,
          changes: {
            // this unorthodox id change follows the `id: experimentComponentId || id` usage of mapped component
            id: componentsEntities[id]?.componentId,
            selected: false,
            experimentComponentId: null,
          },
        })),
      );
      // put back the selected component
      componentsAdapter.addOne(state.components, action.payload);
      state.components.updateLoadingState = loadingStateBuilder.success();
    },
    loadRegimenParameters(state, _action: PayloadAction<string>) {
      state.parameters.loadingState =
        loadingStateBuilder.inProgress('Loading parameters');
      parametersAdapter.removeAll(state.parameters);
    },
    loadRegimenParametersFailure(state, action: PayloadAction<string>) {
      state.parameters.loadingState = loadingStateBuilder.failure(
        action.payload,
      );
    },
    loadRegimenParametersSuccess(
      state,
      action: PayloadAction<ExperimentParameter[]>,
    ) {
      state.parameters.loadingState = loadingStateBuilder.success();
      parametersAdapter.setAll(state.parameters, action.payload);
    },
    updateRegimenParameter(
      state,
      _action: PayloadAction<{
        trainingParameterId: string;
        value: any;
      }>,
    ) {
      state.parameters.updateLoadingState =
        loadingStateBuilder.inProgress('Loading parameters');
    },
    updateRegimenParameterFailure(state, action: PayloadAction<string>) {
      state.parameters.updateLoadingState = loadingStateBuilder.failure(
        action.payload,
      );
    },
    updateRegimenParameterSuccess(
      state,
      action: PayloadAction<Update<ExperimentParameter>>,
    ) {
      state.parameters.updateLoadingState = loadingStateBuilder.success();
      parametersAdapter.updateOne(state.parameters, action.payload);
    },
    loadRestartExperimentParameters(state, _action: PayloadAction<string>) {
      state.parameters.loadingState =
        loadingStateBuilder.inProgress('Loading parameters');
      parametersAdapter.removeAll(state.parameters);
    },
    loadRestartExperimentParametersFailure(
      state,
      action: PayloadAction<string>,
    ) {
      state.parameters.loadingState = loadingStateBuilder.failure(
        action.payload,
      );
    },
    loadRestartExperimentParametersSuccess(
      state,
      action: PayloadAction<ExperimentParameter[]>,
    ) {
      state.parameters.loadingState = loadingStateBuilder.success();
      parametersAdapter.setAll(state.parameters, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setActiveExperimentId, () => initialState);
    builder.addCase(setEditedProjectId, () => initialState);
  },
});

export { regimensReducer };
export const {
  loadRegimens,
  loadRegimensSuccess,
  loadRegimensFailure,
  updateExperimentRegimen,
  updateExperimentRegimenFailure,
  updateExperimentRegimenSuccess,
  loadRegimenParameters,
  loadRegimenParametersSuccess,
  loadRegimenParametersFailure,
  updateRegimenParameter,
  updateRegimenParameterSuccess,
  updateRegimenParameterFailure,
  loadRestartExperimentParameters,
  loadRestartExperimentParametersSuccess,
  loadRestartExperimentParametersFailure,
} = actions;
