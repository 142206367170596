import { all, call, takeEvery, put, select } from 'typed-redux-saga';

import {
  uploadVideos,
  uploadVideoFailure,
  uploadVideoStart,
  uploadVideoSuccess,
  VIDEO_CHUNK_SIZE,
} from './videoUpload.slice';
import {
  apiUploadVideoToCloud,
  apiUploadVideoChunksToCloud,
  apiUploadVideoToBackend,
} from '../../../../../api/requests/videoUpload';
import { selectedDatasetIdSelector } from '../selectedDataset/selectedDataset.selectors';
import { getErrorMessage } from '../../../../../api/utils';
import { readFileAsBinary } from '../../../../../util/file';
import { handleError } from '../../../commonFeatures/errorHandler/errorHandler.actions';
import { apiLoadVideoUploadData } from '../../../../../api/requests/frameImports';
import { activeProjectIdSelector } from '../../../project/project.selectors';
import { enqueueNotification } from '../../../ui/stackNotifications/stackNotifications.slice';

type VideoFileUpload = {
  datasetId: string;
  projectId: string;
  file: File;
  uploadData: { id: string; url: string };
  shouldUploadVideoToBackend?: boolean;
  onUploadProgress?: (id: string) => (event: ProgressEvent) => void;
};

function* uploadVideoToCloud({
  projectId,
  datasetId,
  file,
  uploadData,
  shouldUploadVideoToBackend,
  onUploadProgress,
}: VideoFileUpload) {
  const binaryString = yield* call(readFileAsBinary, file);

  yield* call(apiUploadVideoToCloud, {
    uploadData,
    binaryString,
    onUploadProgress: onUploadProgress
      ? onUploadProgress(uploadData.id)
      : () => {},
  });

  if (shouldUploadVideoToBackend) {
    yield* call(
      apiUploadVideoToBackend,
      {
        projectId,
      },
      { datasetId, uploadId: uploadData.id, filename: file.name },
    );
  }
}

function* uploadVideoChunksToCloud({
  projectId,
  datasetId,
  file,
  uploadData,
  shouldUploadVideoToBackend,
  onUploadProgress,
}: VideoFileUpload) {
  let start = 0;

  const numberOfChunks = Math.ceil(file.size / VIDEO_CHUNK_SIZE);

  for (let i = 0; i < numberOfChunks; i++) {
    const end = Math.min(start + VIDEO_CHUNK_SIZE, file.size);

    yield* call(apiUploadVideoChunksToCloud, {
      url: uploadData.url,
      data: file.slice(start, end),
      bytes: { start, end, total: file.size },
      onUploadProgress: onUploadProgress
        ? onUploadProgress(uploadData.id)
        : () => {},
    });

    start += end - start;
  }

  if (shouldUploadVideoToBackend) {
    yield* call(
      apiUploadVideoToBackend,
      {
        projectId,
      },
      { datasetId, uploadId: uploadData.id, filename: file.name },
    );
  }
}

function* uploadFile(props: VideoFileUpload) {
  const { file } = props;

  if (file.size < VIDEO_CHUNK_SIZE) {
    return yield* uploadVideoToCloud(props);
  }

  return yield* uploadVideoChunksToCloud(props);
}

export function* uploadVideoFile(props: VideoFileUpload) {
  const {
    uploadData: { id },
    file: { name, size },
  } = props;

  yield* put(
    uploadVideoStart({
      id,
      name,
      fileSize: size,
    }),
  );

  try {
    yield* call(uploadFile, props);

    yield* put(uploadVideoSuccess(id));
    yield* put(
      enqueueNotification({
        message: `Video ${name} has been uploaded`,
        options: {
          variant: 'success',
          allowOutsideOfEditor: true,
        },
      }),
    );

    return true;
  } catch (error) {
    const message = getErrorMessage(
      error,
      `Video ${name} uploading has failed`,
    );

    yield* put(uploadVideoFailure({ id, message }));

    yield* put(handleError({ message, allowOutsideOfEditor: true, error }));

    return false;
  }
}

export function* uploadVideosHandler(action: ActionType<typeof uploadVideos>) {
  const projectId = yield* select(activeProjectIdSelector);
  const datasetId = yield* select(selectedDatasetIdSelector);

  const { files, onUploadProgress } = action.payload;
  const totalFiles = files.length;

  if (totalFiles === 0) return;

  const uploadDataItems = (yield* call(apiLoadVideoUploadData, {
    projectId,
    data: { count: totalFiles },
  })).data.items;

  if (datasetId) {
    yield* all(
      files.map((file, index) =>
        uploadVideoFile({
          uploadData: uploadDataItems[index],
          file,
          projectId,
          datasetId,
          shouldUploadVideoToBackend: true,
          onUploadProgress,
        }),
      ),
    );
  }
}

export function* videoUploadSaga() {
  yield* takeEvery(uploadVideos, uploadVideosHandler);
}
