import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { loadingStateBuilder } from '../../../../utils/loadingState';
import { KeypointTemplate } from '../../../../../api/domainModels/keypoints';
import { hideModals } from '../../../ui/modals/modals.slice';

export const keypointsTemplatesAdapter =
  createEntityAdapter<KeypointTemplate>();
const initialState = keypointsTemplatesAdapter.getInitialState({
  itemLoadingState: loadingStateBuilder.initial(),
  listLoadingState: loadingStateBuilder.initial(),
});

const { actions, reducer: keypointsTemplatesReducer } = createSlice({
  name: 'keypointsTemplates',
  initialState,
  reducers: {
    loadKeypointsTemplatesStart(state) {
      state.listLoadingState = loadingStateBuilder.inProgress();
    },
    loadKeypointsTemplatesFailure(state) {
      keypointsTemplatesAdapter.setAll(state, []);
    },
    loadKeypointsTemplatesSuccess(
      state,
      action: PayloadAction<KeypointTemplate[]>,
    ) {
      state.listLoadingState = loadingStateBuilder.success();
      keypointsTemplatesAdapter.setAll(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(hideModals, (state) => {
      state.itemLoadingState = loadingStateBuilder.initial();
    });
  },
});

export const {
  loadKeypointsTemplatesStart,
  loadKeypointsTemplatesFailure,
  loadKeypointsTemplatesSuccess,
} = actions;
export { keypointsTemplatesReducer };
