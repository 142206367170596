import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
  Update,
} from '@reduxjs/toolkit';

import { loadingStateBuilder } from '../../../../utils/loadingState';
import { Project } from '../../../../../api/domainModels/project';

export const projectListAdapter = createEntityAdapter<Project>({
  selectId: (item) => item.projectId,
});

const initialState = projectListAdapter.getInitialState({
  loadingState: loadingStateBuilder.initial(),
  archiveProjectLoadingState: loadingStateBuilder.initial(),
});

const { actions, reducer: projectsReducer } = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    addProjectToList(state, action: PayloadAction<Project>) {
      projectListAdapter.addOne(state, action.payload);
    },
    archiveProject(state, _action: PayloadAction<string>) {
      state.archiveProjectLoadingState = loadingStateBuilder.inProgress();
    },
    archiveProjectError(state, action: PayloadAction<string>) {
      state.archiveProjectLoadingState = loadingStateBuilder.failure(
        action.payload,
      );
    },
    archiveProjectSuccess(state, action: PayloadAction<string>) {
      projectListAdapter.removeOne(state, action.payload);
      state.archiveProjectLoadingState = loadingStateBuilder.success();
    },
    duplicateProject(
      _state,
      _action: PayloadAction<{ projectId: string; copyLabels?: boolean }>,
    ) {},
    loadProjects(state) {
      state.loadingState = loadingStateBuilder.inProgress();
    },
    loadProjectsError(state, action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.failure(action.payload);
    },
    loadProjectsSuccess(state, action: PayloadAction<Project[]>) {
      state.loadingState = loadingStateBuilder.success();
      projectListAdapter.setAll(state, action.payload);
    },
    moveProjectToWorkspace(
      _state,
      _action: PayloadAction<{ projectId: string; workspaceId: string }>,
    ) {},
    updateProjectInList(state, action: PayloadAction<Update<Project>>) {
      projectListAdapter.updateOne(state, action.payload);
    },
    projectUpdated(_state, _action: PayloadAction<string>) {},
  },
});

export { projectsReducer };
export const {
  addProjectToList,
  archiveProject,
  archiveProjectError,
  archiveProjectSuccess,
  duplicateProject,
  loadProjects,
  loadProjectsError,
  loadProjectsSuccess,
  moveProjectToWorkspace,
  updateProjectInList,
  projectUpdated,
} = actions;
