// eslint-disable-next-line no-restricted-globals
const { host, origin, protocol } = self.location;
const isSecure = protocol === 'https:';

// Derive API and WS endpoints from `window.location`.
const apiHost = host.replace(/^app\b/, 'api');
const apiOrigin = origin.replace(host, apiHost);
const wsHost = host.replace(/^app\b/, 'ws');
const envApiUrl = process.env.REACT_APP_API_URL as string;
const newEnvApiUrl = process.env.REACT_APP_API_URL_NEW as string;
const envApiV2Url = process.env.REACT_APP_API_URL_V2 as string;
const envApiUrlBase = process.env.REACT_APP_API_URL_BASE as string;
const envWsUrl = process.env.REACT_APP_WEBSOCKETS_URL as string;
const isTest = process.env.NODE_ENV === 'test';

// If endpoints are set in environment, then override the derived ones.
export const API_URL = isTest
  ? `${apiOrigin}/api/v1.0`
  : envApiUrl ?? `${apiOrigin}/api/v1.0`;
export const NEW_API_URL = isTest
  ? `${apiOrigin}/v1`
  : newEnvApiUrl ?? `${apiOrigin}/v1`;
export const API_URL_V2 = isTest
  ? `${apiOrigin}/v2`
  : envApiV2Url ?? `${apiOrigin}/v2`;
export const API_URL_BASE = isTest ? apiOrigin : envApiUrlBase ?? apiOrigin;
export const WEBSOCKETS_URL =
  envWsUrl ?? `${isSecure ? 'wss' : 'ws'}://${wsHost}/`;
export const AMPLIFY_API_URL =
  typeof window === 'undefined'
    ? ''
    : process.env.REACT_APP_AMPLIFY_API_URL ?? window.REACT_APP_AMPLIFY_API_URL;

export const SUPPORT_EMAIL = 'support@hasty.ai';
export const CONTACT_US_EMAIL = 'herbert@hasty.ai';
export const SUPPORT_DOCUMENTATION = 'https://hasty.ai/docs/userdocs';
export const SUPPORT_SSL = `${SUPPORT_DOCUMENTATION}/semi-supervised-learning/semi-supervised-learning-in-hasty`;
export const SUPPORT_WIKI = 'https://hasty.ai/docs/mp-wiki';
export const TERMS_AND_CONDITIONS = 'https://app.hasty.ai/terms';
export const SUPPORT_BOOK_CALL =
  'https://calendly.com/tobias_sr/30min?utm_source=website&utm_medium=book-a-call&utm_campaign=tobias';
export const YOUTUBE_CHANNEL =
  'https://www.youtube.com/channel/UCu3gg77pTk-EpQV_z4AcYvQ';
export const SUPPORT_HOTKEYS = `${SUPPORT_DOCUMENTATION}/basics/hotkeys-shortlist`;
export const QUALITY_CONTROL_URL = 'https://hasty.ai/quality-control';

export const API_DOCS = 'https://docs.hasty.ai/reference';
export const EXPORT_ANNOTATIONS_API_DOCS = `${SUPPORT_DOCUMENTATION}/projects/exporting-data`;
export const GET_IN_TOUCH_LINK =
  'https://calendly.com/d/cmg-hyq-w5d/hasty-demo';
export const EXPLAINABILITY_DOCUMENTATION =
  'https://hasty.ai/docs/userdocs/model-playground/explainability-and-saliency-maps';

export const AICS_IMPROVE_RESULTS_WIKI =
  'https://hasty.ai/docs/userdocs/quality-assurance-and-control/error-finder/how-to-improve-your-ai-consensus-scoring-results#how-to-improve-your-ai-consensus-scoring-results';

export const PRICING_WEBSITE = 'https://hasty.ai/pricing';
export const PRICING_BOOK_A_CALL = 'https://hasty.ai/book-demo';
export const PRIVACY_POLICY = 'https://hasty.ai/privacy';

export const HELP_PORTAL =
  'https://hasty.atlassian.net/servicedesk/customer/portals';

export const UPLOAD_IMAGES_DOCS =
  'https://hasty.ai/docs/userdocs/projects/creating-and-editing-a-project/upload-images';
export const FILE_MANAGER_DOCS =
  'https://hasty.ai/docs/userdocs/projects/creating-and-editing-a-project/file-manager';
export const TAXONOMY_DOCS =
  'https://hasty.ai/docs/userdocs/projects/creating-and-editing-a-project/taxonomy';
export const IMPORT_ANNOTATIONS_DOCS =
  'https://hasty.ai/docs/userdocs/projects/creating-and-editing-a-project/import-annotations';
export const PROJECT_SUMMARY_DOCS =
  'https://hasty.ai/docs/userdocs/projects/creating-and-editing-a-project/project-summary';
export const AI_ASSISTANT_STATUS_DOCS =
  'https://hasty.ai/docs/userdocs/projects/ai-assistants-status-overview';
export const AUTOMATED_LABELING_DOCS =
  'https://hasty.ai/docs/userdocs/projects/creating-and-editing-a-project/automated-labeling';
export const ACTIVE_LEARNING_DOCS =
  'https://hasty.ai/docs/userdocs/active-learning/active-learning-in-hasty';
export const ACTIVE_LEARNING_TUTORIAL_DOCS =
  'https://hasty.ai/docs/userdocs/active-learning/how-to-use-active-learning-in-hasty';
export const MANUAL_REVIEW_DOCS =
  'https://hasty.ai/docs/userdocs/quality-assurance-and-control/manual-review';
export const AI_CONSENSUS_SCORING_DOCS =
  'https://hasty.ai/docs/userdocs/quality-assurance-and-control/error-finder';
export const INFERENCE_MONITORING_DOCS =
  'https://docs.hasty.ai/reference/inferenceattribute_predictionresult';
export const MODEL_PLAYGROUND_DOCS = 'https://hasty.ai/model-playground';
export const PROJECT_BASICS_DOCS =
  'https://hasty.ai/docs/userdocs/projects/creating-and-editing-a-project/basics';
export const USERS_AND_ROLES_DOCS =
  'https://hasty.ai/docs/userdocs/projects/creating-and-editing-a-project/users-and-roles';
export const SEMANTIC_SEGEMENTATION_DOCS =
  'https://hasty.ai/docs/userdocs/model-playground/torchscript-sample-inference-scripts/semantic-segmentation';
export const WEBHOOK_DOCS =
  'https://hasty.ai/docs/userdocs/projects/creating-and-editing-a-project/webhooks';
export const MP_SPLITS_DOCS =
  'https://hasty.ai/docs/userdocs/model-playground/data-split/creating-a-new-split';
export const MP_EXPERIMENT_DOCS =
  'https://hasty.ai/docs/userdocs/model-playground/creating-an-experiment';
export const MP_EXPORT_DOCS =
  'https://hasty.ai/docs/userdocs/model-playground/model-exports';
export const MP_DEPLOY_DOCS =
  'https://hasty.ai/docs/userdocs/model-playground/model-exports/deploy';
export const ALDI_LEARN_MORE =
  'https://hasty.ai/docs/userdocs/active-learning/how-to-use-active-learning-in-hasty';
export const KEYPOINTS_DOCS =
  'https://hasty.ai/docs/userdocs/keypoints/how-to-work-with-keypoints-in-hasty';
export const ATOM_DOCS =
  'https://hasty.ai/docs/userdocs/annotation-environment/manual-and-semi-automated-tools/atom-segmenter';

export const ASSISTANTS_DOCS =
  'https://hasty.ai/docs/userdocs/annotation-environment/when-to-use-which-tool';

export const VIDEO_VIEW_PATHNAME_SEGMENT = 'videos';

export const getVideoThumbnail = ({
  projectId,
  videoId,
  size = 360,
}: {
  projectId: string;
  videoId: string;
  size?: number;
}) =>
  `${NEW_API_URL}/projects/${projectId}/videos/${videoId}/thumbnail?size=${size}`;

export const getImageThumbnail = ({
  projectId,
  imageId,
  size = 360,
  crop,
}: {
  projectId: string;
  imageId: string;
  size?: number;
  crop?: [number, number, number, number] | null;
}) => {
  let url = `${NEW_API_URL}/thumbnails/${projectId}/thumb/${imageId}?size=${size}`;

  if (crop) {
    url += `&crop=${crop}`;
  }

  return url;
};
