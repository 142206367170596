import {
  InfiniteData,
  QueryKey,
  UseInfiniteQueryResult,
  UseMutationResult,
  UseQueryResult,
} from '@tanstack/react-query';
import { ComponentType, Suspense } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';

import { LoadingIndicator } from '../components/suspense/Suspense';
import { ErrorFallback } from '../components/suspense/error/ErrorIndicator';
import { LoadingState, loadingStateBuilder } from '../redux/utils/loadingState';
import { OpBaseType, PaginationReturn, Params } from './api.types';
import { PaginationMeta } from './codegen';
import { uniqueFunctionId } from './uniqueFunctionId';
import { getErrorMessage } from './utils';

export const getKey = <Op extends OpBaseType>(
  operation: Op,
  params?: Params<Op>,
): QueryKey =>
  params
    ? [uniqueFunctionId(operation), params]
    : [uniqueFunctionId(operation)];

/**
 * To use our current <Suspense/> component we build the LoadingState based on the result.
 * React Query also supports real React Suspense logic by setting `suspense: true` in the query options.
 * <SuspenseAndErrorFallback/> can then be used for easy setup of suspense and error boundaries.
 */
export const buildLoadingState = (
  result: UseQueryResult | UseMutationResult | UseInfiniteQueryResult,
  loadingStateErrorMessage?: string,
) => {
  const { isLoading, isSuccess, isError, error } = result;
  const errorMessage = error
    ? getErrorMessage(error, loadingStateErrorMessage)
    : '';

  return getLoadingState(isLoading, isSuccess, isError, errorMessage);
};

const getLoadingState = (
  isLoading: boolean,
  isSuccess: boolean,
  isError: boolean,
  errorMessage?: string,
): LoadingState => {
  if (isLoading) return loadingStateBuilder.inProgress();
  if (isSuccess) return loadingStateBuilder.success();
  if (isError) return loadingStateBuilder.failure(errorMessage);

  return loadingStateBuilder.initial();
};

const updateMetaTotal = (meta: PaginationMeta, add: number) => ({
  ...meta,
  total: meta.total ? meta.total + add : undefined,
});

/** Updater function for optimistic updates of paginated lists */
export const addItem =
  <T extends { readonly id?: string }>(item: T) =>
  (oldData: PaginationReturn<T>) => {
    if (!oldData) return undefined;
    const updatedItems = [...oldData.items, item];

    return { items: updatedItems, meta: updateMetaTotal(oldData.meta, 1) };
  };

/** Updater function for optimistic updates of paginated lists */
export const removeItemWithId =
  <T extends { readonly id?: string }>(id: string) =>
  (oldData: PaginationReturn<T>) => {
    if (!oldData) return undefined;
    const updatedItems = oldData.items.filter((item) => item.id !== id);
    const diff = updatedItems.length - oldData.items.length;

    return { items: updatedItems, meta: updateMetaTotal(oldData.meta, diff) };
  };

/** Updater function for optimistic updates of paginated lists */
export const updateItem =
  <T extends { readonly id?: string }>(
    item: T,
    sortFn?: (a: Partial<T>, b: Partial<T>) => number,
  ) =>
  (oldData: PaginationReturn<T>) => {
    if (!oldData) return undefined;
    const updatedItems = oldData.items.map((oldItem) =>
      oldItem.id === item.id ? item : oldItem,
    );

    return {
      items: sortFn ? updatedItems.sort(sortFn) : updatedItems,
      meta: oldData.meta,
    };
  };

/** Updater function for optimistic updates of paginated lists in Infinite Queries */
export const updateInfiniteDataItems =
  <T extends { readonly id?: string }>(itemsMap: (item: T) => T) =>
  (oldData: InfiniteData<NonNullable<PaginationReturn<T>>> | undefined) => ({
    pages:
      oldData?.pages.map((page) => ({
        ...page,
        items: page.items.map(itemsMap),
      })) ?? [],
    pageParams: oldData?.pageParams ?? [],
  });

/** Map items in paginated response */
export const mapItems =
  <T extends { readonly id?: string }, S>(mapper: (el: T) => S) =>
  (data: NonNullable<PaginationReturn<T>>) =>
    data.items.map(mapper);

/** Get item count from paginated response */
export const getCount = <T extends { readonly id: string }>(
  data: NonNullable<PaginationReturn<T>>,
) => data.meta.total ?? data.items.length;

export const sortByNorder = <T extends { norder?: number | null }>(
  a: T,
  b: T,
) => ((a.norder ?? 0) > (b.norder ?? 0) ? 1 : -1);

/** Wrapper component to apply Suspense fallback and ErrorBoundary when using React Query with suspense */
export const SuspenseAndErrorFallback = ({
  children,
  Fallback = LoadingIndicator,
  Error = ErrorFallback,
}: {
  children: React.ReactNode;
  Fallback?: ComponentType;
  Error?: ComponentType<FallbackProps>;
}) => (
  <ErrorBoundary FallbackComponent={Error}>
    <Suspense fallback={<Fallback />}>{children}</Suspense>
  </ErrorBoundary>
);
