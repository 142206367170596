import { createSelector } from '@reduxjs/toolkit';

import { managedWorkspaceSelector } from '../managedWorkspace.selectors';

const stripeStateSelector = createSelector(
  managedWorkspaceSelector,
  (managedWorkspace) => managedWorkspace.stripe,
);

export const stripePortalUrlDataSelector = createSelector(
  stripeStateSelector,
  (stripe) => stripe.portalUrl,
);

export const stripePortaUrlWorkspaceSelector = createSelector(
  stripeStateSelector,
  (stripe) => stripe.workspaceId,
);

export const stripePortalUrlLoadingStateSelector = createSelector(
  stripeStateSelector,
  (stripe) => stripe.loadingState,
);
