/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { KeypointClassTemplate } from './KeypointClassTemplate';
import type { KeypointSkeletonTemplate } from './KeypointSkeletonTemplate';

export type KeypointSchemaTemplate = {
  /**
   * Keypoint schema template ID
   */
  id?: string;
  /**
   * User ID
   */
  userId?: string | null;
  /**
   * Project ID
   */
  projectId?: string | null;
  /**
   * Workspace ID
   */
  workspaceId?: string | null;
  /**
   * Name of the keypoint schema template
   */
  name?: string;
  /**
   * Description of the keypoint schema template
   */
  description?: string | null;
  /**
   * Keypoint schema template type
   */
  type?: KeypointSchemaTemplate.type;
  /**
   * User who created the keypoint schema template
   */
  readonly createdBy?: string | null;
  /**
   * Time that keypoint schema template was created
   */
  readonly createDate?: string;
  /**
   * User who updated the keypoint schema template
   */
  readonly updatedBy?: string | null;
  /**
   * Time that keypoint schema template was last edited
   */
  readonly updateDate?: string | null;
  /**
   * ID of the model playground template associated with the keypoint schema template
   */
  mpTemplateId?: string | null;
  /**
   * List of keypoint classes for this keypoint schema template
   */
  keypointClasses?: Array<KeypointClassTemplate> | null;
  /**
   * List of connections between keypoint classes for this keypoint schema template
   */
  keypointSkeleton?: KeypointSkeletonTemplate | null;
};

export namespace KeypointSchemaTemplate {

  /**
   * Keypoint schema template type
   */
  export enum type {
    POSE = 'pose',
    POINTS = 'points',
  }


}

