import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type PaymentsState = {
  chosenPaymentType: string | null;
};

const initialState: PaymentsState = {
  chosenPaymentType: null,
};

const { actions, reducer: paymentsReducer } = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    setChosenPaymentType(state, action: PayloadAction<string | null>) {
      state.chosenPaymentType = action.payload;
    },
  },
});

export const { setChosenPaymentType } = actions;

export { paymentsReducer };
