import { combineReducers } from 'redux';

import { imageViewImageTagsPredictionToggleReducer } from './imageTagsPredictionToggle/imageTagsPredictionToggle.slice';
import { imageViewImageAttributesPredictionToggleReducer } from './labelAttributesPredictionToggle/labelAttributesPredictionToggle.slice';
import { imageViewSidebarTabsReducer } from './tabs/sidebarTabs.slice';

export const sidebarReducer = combineReducers({
  imageTagsPredictionToggle: imageViewImageTagsPredictionToggleReducer,
  labelAttributesPredictionToggle:
    imageViewImageAttributesPredictionToggleReducer,
  sidebarTabs: imageViewSidebarTabsReducer,
});
