import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { InjectedStripeProps } from '../../../../../constants/stripe';
import {
  LoadingState,
  loadingStateBuilder,
} from '../../../../utils/loadingState';
import { resetManagedWorkspaceId } from '../../../commonFeatures/workspaceId/workspaceId.slice';
import { CustomerBillingStatus } from '../../../../../api/domainModels/payment';

export type PaymentsType = {
  loadingState: LoadingState;
  saveLoadingState: LoadingState;
  updatePlanLoadingState: LoadingState;
  deletePaymentProviderLoadingState: LoadingState;
  data: CustomerBillingStatus | null;
};

const initialState: PaymentsType = {
  loadingState: loadingStateBuilder.initial(),
  saveLoadingState: loadingStateBuilder.initial(),
  updatePlanLoadingState: loadingStateBuilder.initial(),
  deletePaymentProviderLoadingState: loadingStateBuilder.initial(),
  data: null,
};

const { actions, reducer: paymentsReducer } = createSlice({
  name: 'managedWorkspace/payments',
  initialState,
  reducers: {
    loadCustomer: (state, _action: PayloadAction<string>) => {
      state.loadingState = loadingStateBuilder.inProgress('Loading customer');
    },
    loadCustomerFailure: (state, action: PayloadAction<string>) => {
      state.loadingState = loadingStateBuilder.failure(action.payload);
    },
    loadCustomerSuccess: (
      state,
      action: PayloadAction<CustomerBillingStatus>,
    ) => {
      state.loadingState = loadingStateBuilder.success();
      state.data = action.payload;
    },
    savePaymentInformation: (
      state,
      _action: PayloadAction<{
        stripeRef: InjectedStripeProps['stripe'];
        elementsRef: InjectedStripeProps['elements'];
        workspaceId: string;
        entityName: string;
      }>,
    ) => {
      state.saveLoadingState = loadingStateBuilder.inProgress(
        'Saving payment information',
      );
    },
    savePaymentInformationFailure: (state, action: PayloadAction<string>) => {
      const message = action.payload;
      state.saveLoadingState = loadingStateBuilder.failure(message);
    },
    savePaymentInformationSuccess: (state, _action: PayloadAction) => {
      state.saveLoadingState = loadingStateBuilder.success();
    },
    saveBillingDetails: (state, _action: PayloadAction<string>) => {
      state.saveLoadingState = loadingStateBuilder.inProgress(
        'Saving billing details',
      );
    },
    saveBillingDetailsFailure: (state, _action: PayloadAction<string>) => {
      const message = _action.payload;
      state.saveLoadingState = loadingStateBuilder.failure(message);
    },
    saveBillingDetailsSuccess: (state) => {
      state.saveLoadingState = loadingStateBuilder.success();
    },
    updatePaymentPlan: (
      state,
      _action: PayloadAction<{
        targetPlanName: string;
        workspaceId: string;
        currentPlanName?: string;
        scheduleUpgrade?: boolean;
        coupon?: string;
      }>,
    ) => {
      state.updatePlanLoadingState =
        loadingStateBuilder.inProgress('Updating plan');
    },
    updatePaymentPlanFailure(state, action: PayloadAction<string>) {
      const message = action.payload;
      state.updatePlanLoadingState = loadingStateBuilder.failure(message);
    },
    updatePaymentPlanSuccess(
      state,
      action: PayloadAction<{
        workspaceId: string;
        isCancelingDowngrade: boolean;
        targetPlanName: string;
        customer: CustomerBillingStatus;
      }>,
    ) {
      state.updatePlanLoadingState = loadingStateBuilder.success();
      state.data = action.payload.customer;
    },
    threeDSecureSuccess: (
      state,
      _action: PayloadAction<{ setupIntentId: string; pathname: string }>,
    ) => {},
    threeDSecureError: (
      state,
      _action: PayloadAction<{ message: string }>,
    ) => {},
    requestUpgradeToPlanModal: (state, _action: PayloadAction<string>) => {},
  },
  extraReducers: (builder) => {
    builder.addCase(resetManagedWorkspaceId, () => initialState);
  },
});

export const {
  loadCustomer,
  loadCustomerFailure,
  loadCustomerSuccess,
  savePaymentInformation,
  savePaymentInformationFailure,
  savePaymentInformationSuccess,
  saveBillingDetails,
  saveBillingDetailsFailure,
  saveBillingDetailsSuccess,
  updatePaymentPlan,
  updatePaymentPlanFailure,
  updatePaymentPlanSuccess,
  threeDSecureSuccess,
  threeDSecureError,
  requestUpgradeToPlanModal,
} = actions;

export { paymentsReducer };
