import { ModelFamily } from '../../../../../api/constants/modelFamily';

const BYTES_IN_GB = 1073741274;

export type WidgetsLayout<P> = {
  items: {
    key: string;
    component: React.FC<P>;
    columns: GridColumn;
    modelFamilies?: ModelFamily[];
  }[];
}[];
export type GridColumn = 1 | 2 | 3 | 4;
export const WIDGET_PADDING = 15;
export const MAX_CAPACITY_GB = 16;
export const MAX_RAM_BYTES = MAX_CAPACITY_GB * BYTES_IN_GB;
export const SCROLLBAR_HEIGHT = 5;
export const ROW_HEIGHT = 100;
export const METRICS_PER_ROW = 2;
export const EXPERIMENT_PICKER_ITEM_WIDTH = 230;
export const PICKER_TOP_OFFSET = 250; // offset from top of Viewport to start of ScrollWrapper
