import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
  Update,
} from '@reduxjs/toolkit';

import { TagClass } from '../../../../../api/domainModels/tagClass';
import { loadingStateBuilder } from '../../../../utils/loadingState';

export type UpdateTagClassFormValues = Pick<
  TagClass,
  'name' | 'groupId' | 'id'
>;
export type AddTagClassFormValues = Pick<TagClass, 'name' | 'groupId'>;

export const tagClassesAdapter = createEntityAdapter<TagClass>();

const initialState = tagClassesAdapter.getInitialState({
  itemLoadingState: loadingStateBuilder.initial(),
  listLoadingState: loadingStateBuilder.initial(),
});

const { actions, reducer: tagClassesReducer } = createSlice({
  name: 'tagClasses',
  initialState,
  reducers: {
    addTagClass(state, _action: PayloadAction<AddTagClassFormValues>) {
      state.itemLoadingState = loadingStateBuilder.inProgress();
    },
    addTagClassFailure(state, action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.failure(action.payload);
    },
    addTagClassSuccess(state, action: PayloadAction<TagClass>) {
      state.itemLoadingState = loadingStateBuilder.success();
      tagClassesAdapter.addOne(state, action.payload);
    },
    updateTagClass(
      state,
      _action: PayloadAction<{ id: string; name: string }>,
    ) {
      state.itemLoadingState = loadingStateBuilder.inProgress();
    },
    upsertTagClassFromWebsockets(state, action: PayloadAction<TagClass>) {
      tagClassesAdapter.upsertOne(state, action.payload);
    },
    updateTagClassFailure(
      state,
      action: PayloadAction<{ message: string; id: string }>,
    ) {
      state.itemLoadingState = loadingStateBuilder.failure(
        action.payload.message,
      );
    },
    updateTagClassSuccess(state, action: PayloadAction<Update<TagClass>>) {
      state.itemLoadingState = loadingStateBuilder.success();
      tagClassesAdapter.updateOne(state, action.payload);
    },
    removeTagClass(state, _action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.inProgress();
    },
    removeTagClassFailure(state, action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.failure(action.payload);
    },
    removeTagClassSuccess(state, action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.success();
      tagClassesAdapter.removeOne(state, action.payload);
    },
    removeTagClassFromWebsockets(state, action: PayloadAction<string>) {
      tagClassesAdapter.removeOne(state, action.payload);
    },
    loadTagClassesStart(state) {
      state.listLoadingState = loadingStateBuilder.inProgress();
    },
    loadTagClassesFailure(state, action: PayloadAction<string>) {
      state.listLoadingState = loadingStateBuilder.failure(action.payload);
      tagClassesAdapter.setAll(state, []);
    },
    loadTagClassesSuccess(state, action: PayloadAction<TagClass[]>) {
      state.listLoadingState = loadingStateBuilder.success();
      tagClassesAdapter.setAll(state, action.payload);
    },
    resetTagClassLoadingState(state) {
      state.itemLoadingState = loadingStateBuilder.initial();
    },
  },
});

export { tagClassesReducer };
export const {
  addTagClass,
  addTagClassFailure,
  addTagClassSuccess,
  updateTagClass,
  updateTagClassFailure,
  updateTagClassSuccess,
  removeTagClass,
  removeTagClassFailure,
  removeTagClassSuccess,
  loadTagClassesStart,
  loadTagClassesFailure,
  loadTagClassesSuccess,
  resetTagClassLoadingState,
  upsertTagClassFromWebsockets,
  removeTagClassFromWebsockets,
} = actions;
