import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
  Update,
} from '@reduxjs/toolkit';

import { ApiKey } from '../../../../../api/domainModels/apiKey';
import { loadingStateBuilder } from '../../../../utils/loadingState';
import { resetManagedWorkspaceId } from '../../../commonFeatures/workspaceId/workspaceId.slice';

export type ApiKeyFormValues = Pick<ApiKey, 'name' | 'isActive'>;

export const apiKeysAdapter = createEntityAdapter<ApiKey>();
const initialState = apiKeysAdapter.getInitialState({
  itemLoadingState: loadingStateBuilder.initial(),
});

const { actions, reducer: apiKeysReducer } = createSlice({
  name: 'apiKeys',
  initialState,
  reducers: {
    resetApiKeysLoadingState(state) {
      state.itemLoadingState = loadingStateBuilder.initial();
    },
    addApiKey(
      state,
      _action: PayloadAction<ApiKeyFormValues & { serviceAccountId: string }>,
    ) {
      state.itemLoadingState = loadingStateBuilder.inProgress();
    },
    addApiKeyFailure(state, action: PayloadAction<{ message: string }>) {
      state.itemLoadingState = loadingStateBuilder.failure(
        action.payload.message,
      );
      apiKeysAdapter.setAll(state, []);
    },
    addApiKeySuccess(state, action: PayloadAction<ApiKey>) {
      state.itemLoadingState = loadingStateBuilder.success();
      apiKeysAdapter.addOne(state, action.payload);
    },
    updateApiKey(
      state,
      _action: PayloadAction<
        ApiKeyFormValues & { id: string; serviceAccountId: string }
      >,
    ) {
      state.itemLoadingState = loadingStateBuilder.inProgress();
    },
    updateApiKeySuccess(state, action: PayloadAction<Update<ApiKey>>) {
      state.itemLoadingState = loadingStateBuilder.success();
      apiKeysAdapter.updateOne(state, action.payload);
    },
    removeApiKeyStart(state, _action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.inProgress();
    },
    removeApiKeyFailure(state, action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.failure(action.payload);
    },
    removeApiKeySuccess(state, action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.success();
      apiKeysAdapter.removeOne(state, action.payload);
    },
    addMultipleApiKeys(state, action: PayloadAction<ApiKey[]>) {
      apiKeysAdapter.addMany(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetManagedWorkspaceId, () => initialState);
  },
});

export { apiKeysReducer };
export const {
  addApiKeyFailure,
  addApiKey,
  addApiKeySuccess,
  removeApiKeyFailure,
  removeApiKeyStart,
  removeApiKeySuccess,
  resetApiKeysLoadingState,
  updateApiKey,
  updateApiKeySuccess,
  addMultipleApiKeys,
} = actions;
