const stripOfEmptyStringValues = (object: Record<string, string>) =>
  Object.entries(object).reduce((result, [key, value]) => {
    if (value !== '') {
      result[key] = value;
    }

    return result;
  }, {} as Record<string, string>);

export const convertBillingValues = (billingValues: Record<string, string>) => {
  const result: Record<string, string | Record<string, string>> =
    stripOfEmptyStringValues({
      name: billingValues.name,
      email: billingValues.email,
      phone: billingValues.phone,
    });

  result.address = stripOfEmptyStringValues({
    city: billingValues.city,
    country: billingValues.country,
    line1: billingValues.addressLine1,
    line2: billingValues.addressLine2,
    postal_code: billingValues.postalCode,
    state: billingValues.state,
  });

  return result;
};

const invalidCouponApiError = 'Invalid coupon code';
const invalidCouponReduxError = 'Invalid promotion coupon';
const invalidCouponErrors = [invalidCouponApiError, invalidCouponReduxError];

export const isErrorInvalidCoupon = (errorMessage: string | undefined) =>
  errorMessage &&
  invalidCouponErrors.some((error) =>
    errorMessage.match(new RegExp(error, 'i')),
  );
