import { createSelector } from '@reduxjs/toolkit';
import orderBy from 'lodash/fp/orderBy';

import { ImageLabel } from '../../../../../api/domainModels/imageLabel';
import { adapter } from './labels.slice';
import { imageViewSelector } from '../imageView.selectors';

const labelsSelector = createSelector(
  imageViewSelector,
  (imageView) => imageView.labels,
);
const presentLabelsSelector = createSelector(
  labelsSelector,
  (labels) => labels.present,
);
const selectors = adapter.getSelectors(presentLabelsSelector);

export const imageLabelsSelector = selectors.selectAll;
export const imageLabelsMapSelector = selectors.selectEntities;
export const imageLabelsIdsSelector = selectors.selectIds as (
  state: RootState,
) => string[];
export const imageLabelsCountSelector = selectors.selectTotal;

export const newestLabelIdSelector = createSelector(
  imageLabelsSelector,
  (imageLabels) => {
    if (imageLabels.length === 0) return null;

    const fromNewestSortedLabels: Partial<ImageLabel>[] =
      orderBy('updatedOn')('desc')(imageLabels);

    return fromNewestSortedLabels[0].id;
  },
);

export const maxImageLabelZIndexSelector = createSelector(
  imageLabelsSelector,
  (imageLabels) => {
    const { length } = imageLabels;
    if (length) {
      return imageLabels[length - 1].zIndex;
    }

    return 0;
  },
);

export const minImageLabelZIndexSelector = createSelector(
  imageLabelsSelector,
  (imageLabels) => {
    const { length } = imageLabels;
    if (length) {
      return imageLabels[0].zIndex;
    }

    return 0;
  },
);

export const allLabelsHaveSameZIndexSelector = createSelector(
  imageLabelsSelector,
  (imageLabels) => {
    const indices = imageLabels.map((label) => label.zIndex);

    return [...new Set(indices)].length === 1;
  },
);

export const availableLabelsSelector = createSelector(
  imageLabelsSelector,
  (imageLabels) => imageLabels.filter((label) => !label.isDeleted),
);

export const availableLabelsIdsSelector = createSelector(
  availableLabelsSelector,
  (labels) => labels.map((label) => label.id),
);

export const imageLabelsHavePastSelector = createSelector(
  labelsSelector,
  (labels) => !!labels.past.length,
);
export const imageLabelsHaveFutureSelector = createSelector(
  labelsSelector,
  (labels) => !!labels.future.length,
);
export const imageLabelsIdsByClassIdSelector = createSelector(
  (_: RootState, labelClassId: string) => labelClassId,
  availableLabelsSelector,
  (labelClassId, labels) =>
    labels.reduce((acc, label) => {
      if (label.classId === labelClassId) {
        acc.push(label.id);
      }

      return acc;
    }, [] as string[]),
);
export const imageKeypointsLabelsCountByClassIdSelector = createSelector(
  (_: RootState, labelClassId: string) => labelClassId,
  availableLabelsSelector,
  (labelClassId, labels) =>
    labels.filter((label) => label.classId === labelClassId && label.keypoints)
      .length,
);
