import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-use';

import { projectNameSelector } from '../redux/state/project/projectInfo/projectInfo.selectors';
import { activeProjectIdSelector } from '../redux/state/project/project.selectors';
import { editedProjectNameSelector } from '../redux/state/sections/editedProject/editedProject.selectors';

export const AnalyticsContext = createContext<{
  identify: (
    event: string,
    properties?: object,
    options?: SegmentAnalytics.SegmentOpts,
  ) => void;
  track: (
    event: string,
    properties?: object,
    options?: SegmentAnalytics.SegmentOpts,
  ) => void;
  page: (
    name?: string,
    properties?: object,
    options?: SegmentAnalytics.SegmentOpts,
    callback?: () => void,
  ) => void;
}>({
  track: () => {},
  identify: () => {},
  page: () => {},
});

const page = (
  name?: string,
  properties?: object,
  options?: SegmentAnalytics.SegmentOpts,
  callback?: () => void,
) => {
  if (window.analytics) {
    window.analytics.page(name, properties, options, callback);
  }
};

export const AnalyticsContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { pathname } = useLocation();
  const projectId = useSelector(activeProjectIdSelector);
  const projectName = useSelector(projectNameSelector);
  const editedProjectName = useSelector(editedProjectNameSelector);
  const track = useCallback(
    (
      event: string,
      _properties?: any,
      options?: SegmentAnalytics.SegmentOpts,
    ) => {
      const properties = { ..._properties };
      if (window.analytics) {
        if (pathname?.startsWith('/projects')) {
          if (!properties.projectId) {
            properties.projectId = projectId;
          }
          if (!properties.projectName) {
            properties.projectName = projectName || editedProjectName;
          }
        }
        window.analytics.track(event, properties, options);
      }
    },
    [editedProjectName, pathname, projectId, projectName],
  );

  const identify = useCallback(
    (
      event: string,
      _properties?: any,
      options?: SegmentAnalytics.SegmentOpts,
    ) => {
      const properties = { ..._properties };
      if (window.analytics) {
        if (pathname?.startsWith('/projects')) {
          if (!properties.projectId) {
            properties.projectId = projectId;
          }
          if (!properties.projectName) {
            properties.projectName = projectName || editedProjectName;
          }
        }
        window.analytics.identify(event, properties, options);
      }
    },
    [editedProjectName, pathname, projectId, projectName],
  );

  const value = useMemo(() => ({ track, page, identify }), [track, identify]);

  return (
    <AnalyticsContext.Provider value={value}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalytics = () => useContext(AnalyticsContext);
