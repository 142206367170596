import { createSelector } from '@reduxjs/toolkit';

import { labelClassAttributesSelector } from '../../../project/annotationTaxonomy/annotationTaxonomy.selectors';
import { hoveredLabelSelector } from '../labels/hoveredLabel/hoveredLabel.selectors';
import {
  selectedLabelsClassIdsSelector,
  selectedLabelsIdsSelector,
} from '../labels/selectedLabels/selectedLabels.selectors';
import {
  labelAttributeValuesAdapter,
  selectId,
} from './labelAttributeValues.slice';
import { LabelAttributeValue } from '../../../../../api/domainModels/attribute';
import { imageViewSelector } from '../imageView.selectors';

const labelAttributeValuesStateSelector = createSelector(
  imageViewSelector,
  (imageView) => imageView.labelAttributeValues,
);

const selectors = labelAttributeValuesAdapter.getSelectors(
  labelAttributeValuesStateSelector,
);

export const labelAttributeValuesSelector = selectors.selectAll;
export const labelAttributeValuesMapSelector = selectors.selectEntities;

const selectedLabelClassAttributesSelector = createSelector(
  [selectedLabelsClassIdsSelector, (state) => state],
  (classIds, state) => {
    if (classIds.length !== 1) {
      return [];
    }

    return labelClassAttributesSelector(state, classIds[0]);
  },
);

export const selectedSingleLabelAttributeValuesSelector = createSelector(
  [
    selectedLabelsIdsSelector,
    labelAttributeValuesSelector,
    selectedLabelClassAttributesSelector,
  ],
  (selectedLabelIds, labelAttributeValues, attributes) => {
    const values = selectedLabelIds[0]
      ? labelAttributeValues
          .filter((lav) => lav.labelId === selectedLabelIds[0])
          .reduce((acc, curr) => {
            acc[curr.id] = curr;

            return acc;
          }, {} as Record<string, LabelAttributeValue>)
      : {};

    return attributes
      .sort((a, b) => (a.norder > b.norder ? 1 : -1))
      .map(
        (attr) =>
          values[attr.id] || {
            id: attr.id,
            labelId: selectedLabelIds[0],
            name: attr.name,
            description: attr.description,
            type: attr.type,
            value: null,
          },
      );
  },
);

export const labelAttributeValuesLoadingStateSelector = createSelector(
  labelAttributeValuesStateSelector,
  (state) => state.loadingState,
);

const stringifyValue = (value: LabelAttributeValue['value']) => {
  if (Array.isArray(value)) {
    return [...value].sort().toString();
  }
  if (value) {
    return value.toString();
  }

  return null;
};

export const selectedLabelsCommonValueSelector = createSelector(
  (_: RootState, attributeId: string) => attributeId,
  selectedLabelsIdsSelector,
  labelAttributeValuesMapSelector,
  (attributeId, selectedLabelIds, singleLabelValuesMap) => {
    const firstLabelId = selectedLabelIds[0];
    if (!firstLabelId) {
      return null;
    }

    const valueObjId = selectId({
      id: attributeId,
      labelId: firstLabelId,
    });
    const valueObj = singleLabelValuesMap[valueObjId];

    if (!valueObj) {
      return null;
    }

    const valueToCheckAgainst = stringifyValue(valueObj.value);

    const allAreTheSame = selectedLabelIds.every((selectedLabelId) => {
      const valueObjId = selectId({
        id: attributeId,
        labelId: selectedLabelId,
      });
      const valueObj = singleLabelValuesMap[valueObjId];
      if (!valueObj) {
        return false;
      }

      return stringifyValue(valueObj.value) === valueToCheckAgainst;
    });

    if (allAreTheSame) {
      return valueObj.value;
    }

    return null;
  },
);

export const hoveredLabelAttributeValuesSelector = createSelector(
  [hoveredLabelSelector, labelAttributeValuesSelector],
  (hoveredLabel, labelAttributeValues) =>
    labelAttributeValues.filter(
      (labelAttributeValues) =>
        labelAttributeValues &&
        labelAttributeValues.labelId === hoveredLabel?.id,
    ),
);

export const attributeValuesByLabelIdSelector = (labelId: string) =>
  createSelector([labelAttributeValuesSelector], (labelAttributeValues) =>
    labelAttributeValues.filter(
      (labelAttributeValue) =>
        labelAttributeValue && labelId === labelAttributeValue.labelId,
    ),
  );
