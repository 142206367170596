import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
  Update,
} from '@reduxjs/toolkit';

import { ErrorSemanticLabel } from '../../../../../api/domainModels/consensusScoring';
import { ErrorFinderAction } from '../../../../../api/constants/consensusScoring';

export const adapter = createEntityAdapter<ErrorSemanticLabel>();

const DEFAULT_MAX_DETECTIONS = 100;

const { actions, reducer: semanticReviewReducer } = createSlice({
  name: 'semanticReview',
  initialState: {
    data: adapter.getInitialState(),
    maxNumberOfErrors: DEFAULT_MAX_DETECTIONS,
    predictedClassId: null as string | null,
  },
  reducers: {
    loadErrorFinderSemanticResultsSuccess(
      state,
      action: PayloadAction<ErrorSemanticLabel[]>,
    ) {
      adapter.setAll(state.data, action.payload);
    },
    setMaxNumberOfErrors(state, action: PayloadAction<number>) {
      state.maxNumberOfErrors = action.payload;
    },
    setPredictedClassId(state, action: PayloadAction<string | null>) {
      state.predictedClassId = action.payload;
    },
    changeSemanticReviewErrorAction(
      _state,
      _action: PayloadAction<{
        runId: string | null;
        resultId: string;
        efAction: ErrorFinderAction;
      }>,
    ) {},

    changeSemanticReviewErrorActionSuccess(
      state,
      action: PayloadAction<Update<ErrorSemanticLabel>>,
    ) {
      adapter.updateOne(state.data, action.payload);
    },
  },
});

export { semanticReviewReducer };
export const {
  loadErrorFinderSemanticResultsSuccess,
  changeSemanticReviewErrorAction,
  changeSemanticReviewErrorActionSuccess,
  setMaxNumberOfErrors,
  setPredictedClassId,
} = actions;
