import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  LoadingState,
  loadingStateBuilder,
} from '../../../../../utils/loadingState';

type DrawingState = {
  brushSize: number;
  overpainting: boolean;
  tool: 'brush' | 'eraser';
  useSAM: boolean;
  maskExists: boolean;
  editedMaskHasChanges: boolean;
  SAMLabelData: {
    mask: number[] | null;
    bbox: [number, number, number, number] | null;
    borderData: string | null;
  };
  SAMLoadingState: LoadingState;
};

const initialState: DrawingState = {
  brushSize: 9,
  overpainting: true,
  tool: 'brush',
  useSAM: false,
  maskExists: false,
  editedMaskHasChanges: false,
  SAMLabelData: {
    mask: null,
    bbox: null,
    borderData: null,
  },
  SAMLoadingState: loadingStateBuilder.initial(),
};

const { actions, reducer: drawingReducer } = createSlice({
  name: 'imageViewDrawing',
  initialState,
  reducers: {
    setBrushSize(state, action: PayloadAction<number>) {
      state.brushSize = action.payload;
    },
    setMaskExists(state, action: PayloadAction<boolean>) {
      state.maskExists = action.payload;
    },
    setEditedMaskHasChanges(state, action: PayloadAction<boolean>) {
      state.editedMaskHasChanges = action.payload;
    },
    toggleOverpainting(state) {
      state.overpainting = !state.overpainting;
    },
    setBrushToolWithEraser() {},
    setBrushToolWithBrush() {},
    setEraser(state) {
      state.tool = 'eraser';
    },
    setBrush(state) {
      state.tool = 'brush';
    },
    setUseSAM(state, _action: PayloadAction<{ useSAM: boolean }>) {},
    confirmSetUseSAM(
      state,
      action: PayloadAction<{ useSAM: boolean; neverShow?: boolean }>,
    ) {
      state.useSAM = action.payload.useSAM;
    },
    getSAMResult(state) {
      state.SAMLoadingState = loadingStateBuilder.inProgress();
    },
    setSAMLabelData(
      state,
      action: PayloadAction<DrawingState['SAMLabelData']>,
    ) {
      state.SAMLabelData = action.payload;
      state.SAMLoadingState = loadingStateBuilder.success();
    },
    getSAMResultFailure(state) {
      state.SAMLoadingState = loadingStateBuilder.failure();
    },
    resetSAMResult(state) {
      state.SAMLabelData = initialState.SAMLabelData;
      state.SAMLoadingState = initialState.SAMLoadingState;
    },
  },
});

export { drawingReducer };
export const {
  setBrushSize,
  setMaskExists,
  setEditedMaskHasChanges,
  toggleOverpainting,
  setEraser,
  setBrush,
  setBrushToolWithEraser,
  setBrushToolWithBrush,
  setUseSAM,
  confirmSetUseSAM,
  getSAMResult,
  setSAMLabelData,
  getSAMResultFailure,
  resetSAMResult,
} = actions;
