import { put, takeEvery, call, select } from 'typed-redux-saga';

import {
  apiLoadExplainabityRuns,
  apiAddExplainabilityRun,
  apiLoadExplainabityMethods,
  apiDeleteExplainabilityRun,
  apiLoadExplainabilityRunResults,
  apiStopExplainabilityRun,
} from '../../../../../../../api/requests/widgets';
import { getErrorMessage } from '../../../../../../../api/utils';
import { activeProjectIdSelector } from '../../../../../project/project.selectors';
import { loadAll } from '../../../../../../utils/api';
import { hideModals } from '../../../../../ui/modals/modals.slice';
import { dashboardActiveExperimentIdSelector } from '../../activeExperiment/activeExperiment.selectors';
import {
  explainabilityRunNextResultAvailableSelector,
  explainabilityRunNextResultIdSelector,
  explainabilityRunPreviousResultAvailableSelector,
  explainabilityRunPreviousResultIdSelector,
} from './explainability.selectors';
import {
  loadExplainabilityRunsStart,
  loadExplainabilityRunsSuccess,
  loadExplainabilityRunsFailure,
  addExplainabilityRunStart,
  addExplainabilityRunSuccess,
  addExplainabilityRunFailure,
  removeExplainabilityRunStart,
  removeExplainabilityRunSuccess,
  removeExplainabilityRunFailure,
  updateExplainabilityRunJobProgressFromWs,
  updateExplainabilityRunJobStatusFromWs,
  updateExplainabilityRun,
  loadExplainabilityMethodsStart,
  loadExplainabilityMethodsSuccess,
  loadExplainabilityMethodsFailure,
  resetRunItemLoadingState,
  loadExplainabilityRunResultsStart,
  loadExplainabilityRunResultsSuccess,
  loadExplainabilityRunResultsFailure,
  setSelectedResultId,
  goToNextResult,
  goToPreviousResult,
  stopExplainabilityRunStart,
  stopExplainabilityRunSuccess,
  stopExplainabilityRunFailure,
} from './explainability.slice';
import { ExplainabilityRunJobStatus } from '../../../../../../../api/constants/widgets';

function* loadRunsHandler(
  action: ActionType<typeof loadExplainabilityRunsStart>,
) {
  const { projectId, experimentId } = action.payload;

  try {
    const data = yield* loadAll({
      apiHelper: apiLoadExplainabityRuns,
      params: {
        projectId,
        experimentId,
      },
    });

    yield* put(loadExplainabilityRunsSuccess(data));
  } catch (error) {
    const message = getErrorMessage(
      error,
      'Not able to fetch explainability widget data',
    );

    yield* put(loadExplainabilityRunsFailure(message));
  }
}

function* loadRunResultsHandler(
  action: ActionType<typeof loadExplainabilityRunResultsStart>,
) {
  try {
    const { data } = yield* call(
      apiLoadExplainabilityRunResults,
      action.payload,
    );

    yield* put(loadExplainabilityRunResultsSuccess(data));
  } catch (error) {
    const message = getErrorMessage(
      error,
      'Not able to fetch explainability run results',
    );

    yield* put(loadExplainabilityRunResultsFailure(message));
  }
}

function* addRunHandler(action: ActionType<typeof addExplainabilityRunStart>) {
  const { projectId, experimentId, ...values } = action.payload;

  try {
    const { data } = yield* call(
      apiAddExplainabilityRun,
      { projectId, experimentId },
      values,
    );

    yield* put(addExplainabilityRunSuccess(data));
    yield* put(hideModals());
  } catch (error) {
    const message = getErrorMessage(
      error,
      'Not able to add explainability run',
    );

    yield* put(addExplainabilityRunFailure(message));
  }
}

function* removeRunHandler(
  action: ActionType<typeof removeExplainabilityRunStart>,
) {
  const { projectId, experimentId, explainabilityRunId } = action.payload;

  try {
    yield* call(apiDeleteExplainabilityRun, {
      projectId,
      experimentId,
      explainabilityRunId,
    });

    yield* put(removeExplainabilityRunSuccess(explainabilityRunId));
    yield* put(hideModals());
  } catch (error) {
    const message = getErrorMessage(
      error,
      'Not able to delete explainability run',
    );

    yield* put(removeExplainabilityRunFailure(message));
  }
}

function* stopRunHandler(
  action: ActionType<typeof stopExplainabilityRunStart>,
) {
  const { projectId, experimentId, explainabilityRunId } = action.payload;

  try {
    yield* call(apiStopExplainabilityRun, {
      projectId,
      experimentId,
      explainabilityRunId,
    });

    yield* put(stopExplainabilityRunSuccess(explainabilityRunId));
    yield* put(hideModals());
  } catch (error) {
    const message = getErrorMessage(
      error,
      'Not able to stop explainability run',
    );

    yield* put(stopExplainabilityRunFailure(message));
  }
}

function* updateRunJobProgressFromWsHandler(
  action: ActionType<typeof updateExplainabilityRunJobProgressFromWs>,
) {
  const { jobId, projectId, experimentId } = action.payload;

  const currentProjectId = yield* select(activeProjectIdSelector);

  if (projectId !== currentProjectId) return;

  const currentExperimentId = yield* select(
    dashboardActiveExperimentIdSelector,
  );

  if (experimentId !== currentExperimentId) return;

  yield* put(
    updateExplainabilityRun({
      id: jobId,
      changes: { status: ExplainabilityRunJobStatus.Running },
    }),
  );
}

function* updateRunJobStatusFromWsHandler(
  action: ActionType<typeof updateExplainabilityRunJobStatusFromWs>,
) {
  const { projectId, experimentId } = action.payload;

  const currentProjectId = yield* select(activeProjectIdSelector);

  if (projectId !== currentProjectId) return;

  const currentExperimentId = yield* select(
    dashboardActiveExperimentIdSelector,
  );

  if (experimentId !== currentExperimentId) return;

  yield* put(loadExplainabilityRunsStart({ experimentId, projectId }));
}

function* loadMethodsHandler(
  action: ActionType<typeof loadExplainabilityMethodsStart>,
) {
  const { projectId, experimentId } = action.payload;

  try {
    const data = yield* loadAll({
      apiHelper: apiLoadExplainabityMethods,
      params: {
        projectId,
        experimentId,
      },
    });

    yield* put(loadExplainabilityMethodsSuccess(data));
  } catch (error) {
    const message = getErrorMessage(
      error,
      'Not able to fetch explainability methods data',
    );

    yield* put(loadExplainabilityMethodsFailure(message));
  }
}

function* hideModalsHandler() {
  yield* put(resetRunItemLoadingState());
  yield* put(setSelectedResultId(null));
}

function* goToNextResultHandler() {
  const isNextResultAvailable = yield* select(
    explainabilityRunNextResultAvailableSelector,
  );

  if (!isNextResultAvailable) return;

  const nextResultId = yield* select(explainabilityRunNextResultIdSelector);
  yield* put(setSelectedResultId(nextResultId));
}

function* goToPreviousResultHandler() {
  const isPreviousResultAvailable = yield* select(
    explainabilityRunPreviousResultAvailableSelector,
  );

  if (!isPreviousResultAvailable) return;

  const previousResultId = yield* select(
    explainabilityRunPreviousResultIdSelector,
  );
  yield* put(setSelectedResultId(previousResultId));
}

export function* explainabilitySaga() {
  yield* takeEvery(loadExplainabilityRunsStart, loadRunsHandler);
  yield* takeEvery(loadExplainabilityRunResultsStart, loadRunResultsHandler);
  yield* takeEvery(addExplainabilityRunStart, addRunHandler);
  yield* takeEvery(removeExplainabilityRunStart, removeRunHandler);
  yield* takeEvery(stopExplainabilityRunStart, stopRunHandler);
  yield* takeEvery(
    updateExplainabilityRunJobProgressFromWs,
    updateRunJobProgressFromWsHandler,
  );
  yield* takeEvery(
    updateExplainabilityRunJobStatusFromWs,
    updateRunJobStatusFromWsHandler,
  );
  yield* takeEvery(loadExplainabilityMethodsStart, loadMethodsHandler);
  yield* takeEvery(hideModals, hideModalsHandler);
  yield* takeEvery(goToNextResult, goToNextResultHandler);
  yield* takeEvery(goToPreviousResult, goToPreviousResultHandler);
}
