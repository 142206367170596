import { createSelector } from '@reduxjs/toolkit';

import { modelStatusChecks } from '../models/modelStatus.constants';
import { adapter } from './textPrompt.slice';
import { statusChecks } from '../../../../../../constants/status';
import { imageViewSelector } from '../../imageView.selectors';

const textPromptStateSelector = createSelector(
  imageViewSelector,
  (imageView) => imageView.tools.textPrompt,
);

export const textPromptDataSelector = createSelector(
  textPromptStateSelector,
  (textPrompt) => textPrompt.data,
);

const textPromptDataSelectors = adapter.getSelectors(textPromptDataSelector);

export const textPromptPredictedDataCountSelector =
  textPromptDataSelectors.selectTotal;

export const textPromptDetectedDataSelector = textPromptDataSelectors.selectAll;

export const textPromptDetectedDataByIdSelector =
  textPromptDataSelectors.selectById;

export const textPromptModelSelector = createSelector(
  textPromptStateSelector,
  (valuesState) => valuesState.model,
);

export const textPromptHasDetectionsSelector = createSelector(
  textPromptPredictedDataCountSelector,
  (count) => count > 0,
);

export const textPromptModelIdSelector = createSelector(
  textPromptModelSelector,
  (model) => model.id,
);

export const textPromptModelStatusSelector = createSelector(
  textPromptModelSelector,
  (model) => model.status,
);

export const textPromptModelAvailableSelector = createSelector(
  textPromptModelIdSelector,
  (modelId) => typeof modelId === 'number',
);

export const textPromptModelLoadedSelector = createSelector(
  [textPromptModelAvailableSelector, textPromptModelStatusSelector],
  (available, status) =>
    !!(available && status && modelStatusChecks.isLoaded(status)),
);

export const textPromptLoadingStateSelector = createSelector(
  textPromptDataSelector,
  (data) => data.loadingState,
);

export const textPromptIsLoadingSelector = createSelector(
  textPromptLoadingStateSelector,
  (loadingState) => statusChecks.isInProgress(loadingState.status),
);

export const textPromptThresholdSelector = createSelector(
  textPromptStateSelector,
  (state) => state.threshold,
);

export const textPromptUseViewportSelector = createSelector(
  textPromptStateSelector,
  (state) => state.useViewport,
);

export const textPromptModeSelector = createSelector(
  textPromptStateSelector,
  (state) => state.mode,
);

export const textPromptUseSemSegSelector = createSelector(
  textPromptStateSelector,
  (state) => state.useSemSeg,
);

export const textPromptClassesSelector = createSelector(
  textPromptStateSelector,
  (state) => state.classes,
);

export const textPromptTextInputSelector = createSelector(
  textPromptStateSelector,
  (state) => state.textInput,
);

export const textPromptVisibleAreaBboxSelector = createSelector(
  textPromptStateSelector,
  (state) => state.visibleAreaBbox,
);
