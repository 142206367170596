import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Project } from '../../../../api/domainModels/project';
import { LoadingState, loadingStateBuilder } from '../../../utils/loadingState';

export type ProjectInfoState = {
  loadingState: LoadingState;
  data: Project | null;
};

const { actions, reducer: projectInfoReducer } = createSlice({
  name: 'project/projectInfo',
  initialState: {
    loadingState: loadingStateBuilder.initial(),
    data: null,
  } as ProjectInfoState,
  reducers: {
    loadProjectInfo(
      state,
      _action: PayloadAction<{ projectId: string; eventsList: string[] }>,
    ) {
      state.loadingState = loadingStateBuilder.inProgress();
    },
    loadProjectInfoSuccess(state, action: PayloadAction<Project>) {
      state.loadingState = loadingStateBuilder.success();
      state.data = action.payload;
    },
    loadProjectInfoFailure(state, action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.failure(action.payload);
    },
  },
});

export { projectInfoReducer };
export const {
  loadProjectInfo,
  loadProjectInfoSuccess,
  loadProjectInfoFailure,
} = actions;
