/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Model = {
  /**
   * Number of existed labels (or images)
   */
  existedObjects?: number;
  /**
   * Model identifier
   */
  modelId?: number;
  /**
   * Training or labeling progress from 0 to 1
   */
  progress?: number;
  /**
   * Number of labels (or images) required to train a new model
   */
  requiredObjects?: number;
  /**
   * Model status
   */
  status?: Model.status;
};

export namespace Model {

  /**
   * Model status
   */
  export enum status {
    LABELING = 'labeling',
    TRAINING = 'training',
    LOADING = 'loading',
    LOADED = 'loaded',
    FAILED = 'failed',
  }


}

