import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  LoadingState,
  loadingStateBuilder,
} from '../../../../utils/loadingState';
import { Workspace } from '../../../../../api/domainModels/workspace';
import { resetManagedWorkspaceId } from '../../../commonFeatures/workspaceId/workspaceId.slice';

export type BasicsState = {
  loadingState: LoadingState;
  updateLoadingState: LoadingState;
  data: Workspace | null;
};

const initialState: BasicsState = {
  loadingState: loadingStateBuilder.initial(),
  updateLoadingState: loadingStateBuilder.initial(),
  data: null,
};

const { actions, reducer: basicsReducer } = createSlice({
  name: 'managedWorkspace/basics',
  initialState,
  reducers: {
    loadWorkspace(state, _action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.inProgress('Loading workspace');
    },
    loadWorkspaceSuccess(state, action: PayloadAction<Workspace>) {
      state.loadingState = loadingStateBuilder.success();
      state.data = action.payload;
    },
    loadWorkspaceFailure(state, action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.failure(action.payload);
    },
    updateWorkspace(state, _action: PayloadAction<Partial<Workspace>>) {
      state.updateLoadingState =
        loadingStateBuilder.inProgress('Updating workspace');
    },
    updateWorkspaceSuccess(state, action: PayloadAction<Workspace>) {
      state.updateLoadingState = loadingStateBuilder.success();
      state.data = action.payload;
    },
    updateWorkspaceFailure(state, action: PayloadAction<string>) {
      state.updateLoadingState = loadingStateBuilder.failure(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetManagedWorkspaceId, () => initialState);
  },
});

export const {
  loadWorkspace,
  loadWorkspaceSuccess,
  loadWorkspaceFailure,
  updateWorkspace,
  updateWorkspaceSuccess,
  updateWorkspaceFailure,
} = actions;
export { basicsReducer };
