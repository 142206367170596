/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { APIKey } from '../models/APIKey';
import type { LookupItem } from '../models/LookupItem';
import type { Profile } from '../models/Profile';
import type { ProjectCollaborator } from '../models/ProjectCollaborator';
import type { Role } from '../models/Role';
import type { Tracking } from '../models/Tracking';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { OmitReadonly } from '../core/utils/OmitReadonly';

export class AuthService {

  /**
   * Google Cloud Sign-in
   * @returns any Returns user info along with a cookie named `ui_last_state`
   * @throws ApiError
   */
  public static postV1GcsigninWeb({
    formData,
  }: {
    formData?: {
      /**
       * Google ID token
       */
      idtoken: string;
    },
  }): CancelablePromise<{
    /**
     * Whether the user has accepted terms and conditions
     */
    acceptTerms?: boolean;
    /**
     * User ID
     */
    userId?: string;
    /**
     * Username
     */
    username?: string | null;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/gcsignin-web',
      formData: formData,
      mediaType: 'application/x-www-form-urlencoded',
    });
  }

  /**
   * Google Cloud Sign-in
   * @returns any Returns user info along with a cookie named `ui_last_state`
   * @throws ApiError
   */
  public static postV1GcsigninIos({
    formData,
  }: {
    formData?: {
      /**
       * Google ID token
       */
      idtoken: string;
    },
  }): CancelablePromise<{
    /**
     * Whether the user has accepted terms and conditions
     */
    acceptTerms?: boolean;
    /**
     * User ID
     */
    userId?: string;
    /**
     * Username
     */
    username?: string | null;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/gcsignin-ios',
      formData: formData,
      mediaType: 'application/x-www-form-urlencoded',
    });
  }

  /**
   * Get user account
   * @returns Profile Successfuly reads user account
   * @throws ApiError
   */
  public static getApiV10Profile(): CancelablePromise<Profile> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1.0/profile',
    });
  }

  /**
   * Delete user account
   * @returns any Successful deletion of user account
   * @throws ApiError
   */
  public static deleteApiV10Profile(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v1.0/profile',
    });
  }

  /**
   * Update user account
   * @returns Profile Successful update user account
   * @throws ApiError
   */
  public static putApiV10Profile({
    requestBody,
  }: {
    requestBody?: OmitReadonly<Profile>,
  }): CancelablePromise<Profile> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v1.0/profile',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Generate API Key for specific profile
   * @returns APIKey Created API Key
   * @throws ApiError
   */
  public static postApiV10ProfileApiKey({
    profileId,
    requestBody,
  }: {
    /**
     * User or service account ID
     */
    profileId: string,
    requestBody: OmitReadonly<APIKey>,
  }): CancelablePromise<APIKey> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1.0/profile/{profile_id}/api_key',
      path: {
        'profile_id': profileId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Delete API Key
   * @returns APIKey Deleted API Key
   * @throws ApiError
   */
  public static deleteApiV10ProfileApiKey({
    profileId,
    apiKeyId,
  }: {
    /**
     * User or service account ID
     */
    profileId: string,
    /**
     * API Key ID
     */
    apiKeyId: string,
  }): CancelablePromise<APIKey> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v1.0/profile/{profile_id}/api_key/{api_key_id}',
      path: {
        'profile_id': profileId,
        'api_key_id': apiKeyId,
      },
    });
  }

  /**
   * Edit API Key
   * @returns APIKey Edited API Key
   * @throws ApiError
   */
  public static putApiV10ProfileApiKey({
    profileId,
    apiKeyId,
    requestBody,
  }: {
    /**
     * User or service account ID
     */
    profileId: string,
    /**
     * API Key ID
     */
    apiKeyId: string,
    requestBody: OmitReadonly<APIKey>,
  }): CancelablePromise<APIKey> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v1.0/profile/{profile_id}/api_key/{api_key_id}',
      path: {
        'profile_id': profileId,
        'api_key_id': apiKeyId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Returns API Keys for specific profile
   * @returns APIKey Successful read the list of API Keys
   * @throws ApiError
   */
  public static getApiV10ProfileApiKeys({
    profileId,
  }: {
    /**
     * User or service account ID
     */
    profileId: string,
  }): CancelablePromise<Array<APIKey>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1.0/profile/{profile_id}/api_keys',
      path: {
        'profile_id': profileId,
      },
    });
  }

  /**
   * Add projects collaborator
   * @returns ProjectCollaborator Returns a newly created record
   * @throws ApiError
   */
  public static postApiV10ProjectProjectCollaborator({
    projectId,
    requestBody,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    requestBody: OmitReadonly<ProjectCollaborator>,
  }): CancelablePromise<ProjectCollaborator> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1.0/project/{project_id}/project_collaborator',
      path: {
        'project_id': projectId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Delete project collaborator
   * @returns ProjectCollaborator Returns deleted project collaborator
   * @throws ApiError
   */
  public static deleteApiV10ProjectProjectCollaborator({
    projectId,
    projectCollaboratorId,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    projectCollaboratorId: string,
  }): CancelablePromise<ProjectCollaborator> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v1.0/project/{project_id}/project_collaborator/{project_collaborator_id}',
      path: {
        'project_id': projectId,
        'project_collaborator_id': projectCollaboratorId,
      },
    });
  }

  /**
   * Edit project collaborator
   * @returns ProjectCollaborator Returns edited project collaborator
   * @throws ApiError
   */
  public static putApiV10ProjectProjectCollaborator({
    projectId,
    projectCollaboratorId,
    requestBody,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    projectCollaboratorId: string,
    requestBody: {
      /**
       * Role id
       */
      role_id: number;
    },
  }): CancelablePromise<ProjectCollaborator> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v1.0/project/{project_id}/project_collaborator/{project_collaborator_id}',
      path: {
        'project_id': projectId,
        'project_collaborator_id': projectCollaboratorId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Return the list of project collaborators
   * @returns ProjectCollaborator Successful read the list of projects collaborators
   * @throws ApiError
   */
  public static getApiV10ProjectProjectCollaborators({
    projectId,
  }: {
    /**
     * Project ID
     */
    projectId: string,
  }): CancelablePromise<Array<ProjectCollaborator>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1.0/project/{project_id}/project_collaborators',
      path: {
        'project_id': projectId,
      },
    });
  }

  /**
   * Create role
   * @returns Role Returns an newly created dataset
   * @throws ApiError
   */
  public static postApiV10ProjectRole({
    projectId,
    requestBody,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    requestBody: OmitReadonly<Role>,
  }): CancelablePromise<Role> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1.0/project/{project_id}/role',
      path: {
        'project_id': projectId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Delete role
   * @returns Role Returns removed role
   * @throws ApiError
   */
  public static deleteApiV10ProjectRole({
    projectId,
    roleId,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Role ID
     */
    roleId: number,
  }): CancelablePromise<Role> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v1.0/project/{project_id}/role/{role_id}',
      path: {
        'project_id': projectId,
        'role_id': roleId,
      },
    });
  }

  /**
   * Edit role
   * @returns Role Returns an updated role
   * @throws ApiError
   */
  public static putApiV10ProjectRole({
    projectId,
    roleId,
    requestBody,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Role ID
     */
    roleId: number,
    requestBody: OmitReadonly<Role>,
  }): CancelablePromise<Role> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v1.0/project/{project_id}/role/{role_id}',
      path: {
        'project_id': projectId,
        'role_id': roleId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Grant privilege to role
   * @returns any Returns the list or privileges granted to the role
   * @throws ApiError
   */
  public static postApiV10ProjectRoleGrant({
    projectId,
    roleId,
    requestBody,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Role ID
     */
    roleId: number,
    requestBody: {
      /**
       * Array of privileges
       */
      privilege_ids?: Array<string>;
    },
  }): CancelablePromise<Array<{
    /**
     * Privilege id
     */
    privilegeId?: string;
    /**
     * Role id
     */
    roleId?: number;
  }>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1.0/project/{project_id}/role/{role_id}/grant',
      path: {
        'project_id': projectId,
        'role_id': roleId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Revoke privileges from role
   * @returns string Returns the list of privilege IDs revoked from the role
   * @throws ApiError
   */
  public static postApiV10ProjectRoleRevoke({
    projectId,
    roleId,
    requestBody,
  }: {
    /**
     * Project ID
     */
    projectId: string,
    /**
     * Role ID
     */
    roleId: number,
    requestBody: {
      /**
       * Array of privileges
       */
      privilege_ids?: Array<string>;
    },
  }): CancelablePromise<Array<string>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1.0/project/{project_id}/role/{role_id}/revoke',
      path: {
        'project_id': projectId,
        'role_id': roleId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Return the list of roles privileges
   * @returns any Successful read the list of roles privileges
   * @throws ApiError
   */
  public static getApiV10ProjectRolePrivileges({
    projectId,
  }: {
    /**
     * Project ID
     */
    projectId: string,
  }): CancelablePromise<Array<{
    color: string;
    /**
     * If role has this privilege
     */
    hasGrant: boolean;
    privilegeDescription: string;
    privilegeId?: string;
    privilegeName: string;
    roleId?: number;
    roleName: string;
  }>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1.0/project/{project_id}/role_privileges',
      path: {
        'project_id': projectId,
      },
    });
  }

  /**
   * Return the list of roles
   * @returns Role Successful read the list of roles
   * @throws ApiError
   */
  public static getApiV10ProjectRoles({
    projectId,
  }: {
    /**
     * Project ID
     */
    projectId: string,
  }): CancelablePromise<Array<Role>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1.0/project/{project_id}/roles',
      path: {
        'project_id': projectId,
      },
    });
  }

  /**
   * Send auth email
   * @returns any Accepted email request
   * @throws ApiError
   */
  public static postApiV10TokenAuthEmailRequest({
    requestBody,
  }: {
    requestBody: {
      /**
       * Email address of the receiver
       */
      email: string;
    },
  }): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1.0/token-auth/email-request',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Sign in with a token
   * @returns any Returns user info along with a cookie named `ui_last_state`
   * @throws ApiError
   */
  public static postApiV10TokenAuthEnter({
    requestBody,
  }: {
    requestBody: (Tracking & {
      /**
       * Whether sample project creation should be skipped
       */
      skip_sample_project?: boolean;
      /**
       * Whether welcome mail sending should be skipped
       */
      skip_welcome_mail?: boolean;
      /**
       * Token
       */
      token: string;
    }),
  }): CancelablePromise<{
    /**
     * True if user accepted terms and conditions
     */
    acceptTerms?: boolean;
    /**
     * User id
     */
    userId?: string;
    /**
     * Username
     */
    username?: string | null;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1.0/token-auth/enter',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Return the list of all lookups
   * @returns LookupItem Successful read the list of all lookups
   * @throws ApiError
   */
  public static getV1Lookups(): CancelablePromise<Array<LookupItem>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/lookups',
    });
  }

  /**
   * Privileges to a particular subject
   * @returns any List of privileges to a particular subject
   * @throws ApiError
   */
  public static getV1AuthSubjects({
    subject,
  }: {
    /**
     * Subject UUID
     */
    subject: string,
  }): CancelablePromise<{
    privileges?: Array<string>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/auth/subjects/{subject}',
      path: {
        'subject': subject,
      },
    });
  }

  /**
   * Logout
   * @returns void
   * @throws ApiError
   */
  public static deleteV1AuthSession(): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/v1/auth/session',
    });
  }

  /**
   * Logout all sessions
   * @returns void
   * @throws ApiError
   */
  public static deleteV1AuthSessions(): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/v1/auth/sessions',
    });
  }

}
