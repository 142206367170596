import { createSelector } from '@reduxjs/toolkit';

import { labelClassKeypointsSchemaClassesSelector } from '../../../../../project/annotationTaxonomy/annotationTaxonomy.selectors';
import {
  keypointsValuesPointByIdSelector,
  keypointsValuesPointsSelector,
} from '../keypointsValues/keypointsValues.selectors';
import { activeLabelClassSelector } from '../../../labelClasses/labelClasses.selectors';
import { labelClassByIdSelector } from '../../../../../project/annotationTaxonomy/labelClasses/labelClasses.selectors';
import { editedKeypointsLabelSelector } from '../../../labels/selectedLabels/selectedLabels.selectors';
import { imageViewSelector } from '../../../imageView.selectors';

const keypointsEditingStateSelector = createSelector(
  imageViewSelector,
  (imageView) => imageView.tools.keypoints.editingState,
);

export const currentKeypointsClassIndexSelector = createSelector(
  keypointsEditingStateSelector,
  (state) => state.currentKeypointsClassIndex,
);

export const keypointsEditingStateCurrentKeypointsClassSelector =
  createSelector(
    currentKeypointsClassIndexSelector,
    labelClassKeypointsSchemaClassesSelector,
    (index, classes) => {
      if (!classes.length || index === null) return null;

      return classes[index];
    },
  );
export const keypointsEditingStateCurrentKeypointsClassIdSelector =
  createSelector(
    keypointsEditingStateCurrentKeypointsClassSelector,
    (keypointsClass) => keypointsClass?.id,
  );
export const keypointsEditingStateCanCreatePointSelector = createSelector(
  keypointsEditingStateCurrentKeypointsClassSelector,
  keypointsValuesPointsSelector,
  (keypointsClass, points) => {
    if (!keypointsClass) return false;
    if (keypointsClass.maxPoints > 1) {
      return true;
    }

    return !points.some((point) => point.keypointClassId === keypointsClass.id);
  },
);
export const keypointsEditingStateIsSelectingSelector = createSelector(
  keypointsEditingStateSelector,
  (state) => state.isSelecting,
);
export const keypointsEditingStateOcclusionActiveSelector = createSelector(
  keypointsEditingStateSelector,
  (state) => state.occlusionActive,
);
export const keypointsEditingStateHoveredPointIdSelector = createSelector(
  keypointsEditingStateSelector,
  (state) => state.hoveredPointId,
);
export const keypointsEditingStateSelectedPointIdSelector = createSelector(
  keypointsEditingStateSelector,
  (state) => state.selectedPointId,
);
export const keypointsEditingStateSelectedPointOccludedSelector =
  createSelector(
    (state: RootState) => state,
    keypointsEditingStateSelectedPointIdSelector,
    (state, selectedPointId) => {
      const selectedPoint = keypointsValuesPointByIdSelector(
        state,
        selectedPointId,
      );

      return selectedPoint ? !selectedPoint?.visible : false;
    },
  );
export const activeKeypointsLabelClassSelector = createSelector(
  (state: RootState) => state,
  activeLabelClassSelector,
  editedKeypointsLabelSelector,
  (state, activeLabelClass, selectedLabel) =>
    (selectedLabel?.classId
      ? labelClassByIdSelector(state, selectedLabel.classId)
      : activeLabelClass) || undefined,
);
