import {
  createSlice,
  PayloadAction,
  createEntityAdapter,
  Update,
} from '@reduxjs/toolkit';

import {
  LoadingState,
  loadingStateBuilder,
} from '../../../../../utils/loadingState';
import { createSplitSuccess } from '../data/data.slice';
import { SplitCounterItem, SplitFormValues } from '../splits.types';
import { setEditedProjectId } from '../../../../sections/editedProject/project/project.slice';

export type SplitCounterId =
  | 'datasetIds'
  | 'trainDatasetIds'
  | 'testDatasetIds'
  | 'valDatasetIds';

type SplitCounter = SplitCounterItem & {
  id: SplitCounterId;
  loadingState: LoadingState;
};

export const adapter = createEntityAdapter<SplitCounter>();

const { actions, reducer: newSplitCountersReducer } = createSlice({
  name: 'mp/splits/newSplitCounters',
  initialState: adapter.getInitialState(),
  reducers: {
    resetNewSplitCounters(state) {
      adapter.removeAll(state);
    },
    loadNewSplitCounters(
      state,
      action: PayloadAction<
        Pick<
          SplitFormValues,
          | 'datasetIds'
          | 'imageStatuses'
          | 'modelFamily'
          | 'labelClassIds'
          | 'tagClassIds'
        > & { id: SplitCounterId; parentSplitId?: string }
      >,
    ) {
      adapter.upsertOne(state, {
        id: action.payload.id,
        objectCount: 0,
        valid: false,
        loadingState: loadingStateBuilder.inProgress(),
      });
    },
    loadNewSplitCountersSuccess(
      state,
      action: PayloadAction<Update<SplitCounter>>,
    ) {
      const { id, changes } = action.payload;

      adapter.updateOne(state, {
        id,
        changes: {
          ...changes,
          loadingState: loadingStateBuilder.success(),
        },
      });
    },
    loadNewSplitCountersFailure(
      state,
      action: PayloadAction<Update<SplitCounter & { message: string }>>,
    ) {
      const {
        id,
        changes: { message },
      } = action.payload;

      adapter.updateOne(state, {
        id,
        changes: {
          loadingState: loadingStateBuilder.failure(message),
        },
      });
    },
    setSplitCountersToZero(
      state,
      action: PayloadAction<{ id: SplitCounterId }>,
    ) {
      adapter.upsertOne(state, {
        id: action.payload.id,
        objectCount: 0,
        loadingState: loadingStateBuilder.initial(),
        valid: false,
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createSplitSuccess, (state) => {
      adapter.removeAll(state);
    });
    builder.addCase(setEditedProjectId, () => adapter.getInitialState());
  },
});

export { newSplitCountersReducer };
export const {
  resetNewSplitCounters,
  loadNewSplitCounters,
  loadNewSplitCountersFailure,
  loadNewSplitCountersSuccess,
  setSplitCountersToZero,
} = actions;
