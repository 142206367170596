/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Replicas } from './Replicas';

export type ArtifactUploadRequest = {
  replicas: Replicas;
  /**
   * Future name of deployment
   */
  deploymentName: string;
  /**
   * Future version. The field contain integer in string format
   */
  version: string;
  /**
   * Brief description with explanation
   */
  description?: string;
  /**
   * Mode to process requests
   */
  mode?: ArtifactUploadRequest.mode;
  /**
   * Parameters for model without a clear scheme
   */
  modelParams?: Record<string, any>;
  /**
   * Request rate limit per minute
   */
  rateLimit?: number;
  /**
   * Resources to model
   */
  resources?: {
    /**
     * GPU memory required (in MB)
     */
    gpuMemoryMb?: number;
  };
  scaling?: {
    /**
     * Strategy for autoscaling
     */
    strategy?: ArtifactUploadRequest.strategy;
    /**
     * Number of pending requests
     */
    numberRequests?: number;
  } | null;
};

export namespace ArtifactUploadRequest {

  /**
   * Mode to process requests
   */
  export enum mode {
    REALTIME = 'realtime',
    BATCH = 'batch',
  }

  /**
   * Strategy for autoscaling
   */
  export enum strategy {
    NUM_REQUESTS = 'num_requests',
  }


}

