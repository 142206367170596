/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Job = {
  /**
   * Job ID
   */
  readonly id: string;
  /**
   * Type of the job
   */
  readonly type: Job.type;
  /**
   * Status of the job
   */
  readonly status: Job.status;
  /**
   * Initiated user ID
   */
  readonly startedBy: string;
  /**
   * Associated project ID (if any)
   */
  readonly projectId?: string;
  readonly startedOn: string;
  readonly completedOn?: string | null;
  /**
   * Optional metadata for this job
   */
  readonly meta?: Record<string, any> | null;
};

export namespace Job {

  /**
   * Type of the job
   */
  export enum type {
    EXPORT_PROJECT = 'EXPORT_PROJECT',
    COPY_PROJECT = 'COPY_PROJECT',
    CREATE_REPORT = 'CREATE_REPORT',
    CREATE_DATASPLIT = 'CREATE_DATASPLIT',
    EXPORT_MODEL = 'EXPORT_MODEL',
    PARSE_IMPORT_FILES = 'PARSE_IMPORT_FILES',
    EXTRACT_FRAMES = 'EXTRACT_FRAMES',
    IMPORT_LABELS = 'IMPORT_LABELS',
    VIDEO_CUT_SESSION = 'VIDEO_CUT_SESSION',
    EXPLAINABILITY = 'EXPLAINABILITY',
    DATASET_STATUS_UPDATE = 'DATASET_STATUS_UPDATE',
  }

  /**
   * Status of the job
   */
  export enum status {
    INIT = 'INIT',
    RUNNING = 'RUNNING',
    FAILED = 'FAILED',
    DONE = 'DONE',
    CANCELED = 'CANCELED',
  }


}

