import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const { actions, reducer: manualReviewReducer } = createSlice({
  name: 'manualReviewReducer',
  initialState: {
    opacity: 0.4,
  },
  reducers: {
    setOpacity(state, action: PayloadAction<{ opacity: number }>) {
      state.opacity = action.payload.opacity;
    },
  },
});

export const { setOpacity } = actions;
export { manualReviewReducer };
