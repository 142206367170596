import { createSelector } from '@reduxjs/toolkit';

import { imageViewSelector } from '../../imageView.selectors';
import { activeProjectIdSelector } from '../../../../project/project.selectors';

const imageViewImageTagsPredictionToggleSelector = createSelector(
  imageViewSelector,
  (imageView) => imageView.sidebar.imageTagsPredictionToggle,
);

export const imageViewIsImageTagsPredictionToggledSelector = createSelector(
  activeProjectIdSelector,
  imageViewImageTagsPredictionToggleSelector,
  (projectId, predictionState) => !!predictionState[projectId],
);
