import { put, select, takeEvery, call } from 'typed-redux-saga';

import {
  apiCreateDatasplit,
  apiDeleteDatasplit,
  apiLoadDatasplits,
  apiLoadDatasplit,
  apiCreateNestedDatasplit,
} from '../../../../../../api/requests/modelPlayground';
import { getErrorMessage } from '../../../../../../api/utils';
import { handleError } from '../../../../commonFeatures/errorHandler/errorHandler.actions';
import { activeProjectIdSelector } from '../../../../project/project.selectors';
import { loadAll } from '../../../../../utils/api';
import { hideModals } from '../../../../ui/modals/modals.slice';
import {
  addSplit,
  addSplitFromWebsocket,
  createNestedSplit,
  createSplit,
  createSplitFailure,
  createSplitSuccess,
  loadSplit,
  loadSplitFailure,
  loadSplits,
  loadSplitsFailure,
  loadSplitsSuccess,
  loadSplitSuccess,
  removeNestedSplitSuccess,
  removeSplit,
  removeSplitFailure,
  removeSplitSuccess,
} from './data.slice';
import { enqueueNotification } from '../../../../ui/stackNotifications/stackNotifications.slice';

function* loadAllHandler() {
  try {
    const projectId = yield* select(activeProjectIdSelector);

    if (!projectId) return;

    const splits = yield* loadAll({
      apiHelper: apiLoadDatasplits,
      params: {
        projectId,
      },
    });
    yield* put(loadSplitsSuccess(splits));
  } catch (error) {
    const errorMessage = getErrorMessage(error, 'Not able to load splits');
    yield* put(loadSplitsFailure(errorMessage));
    yield* put(
      handleError({
        message: errorMessage,
        allowOutsideOfEditor: true,
        error,
      }),
    );
  }
}
function* loadOneHandler(action: ActionType<typeof loadSplit>) {
  try {
    const projectId = yield* select(activeProjectIdSelector);
    const { splitId } = action.payload;

    if (!projectId || !splitId) return; // accounting for delay between project id set in router and setEditedProjectId

    const { data } = yield* call(apiLoadDatasplit, {
      projectId,
      splitId,
    });

    yield* put(loadSplitSuccess(data));
  } catch (error) {
    const errorMessage = getErrorMessage(error, 'Not able to load split');
    yield* put(loadSplitFailure(errorMessage));
    yield* put(
      handleError({
        message: errorMessage,
        allowOutsideOfEditor: true,
        error,
      }),
    );
  }
}

function* createHandler(action: ActionType<typeof createSplit>) {
  const projectId = yield* select(activeProjectIdSelector);
  const payload = {
    ...action.payload,
    testFraction: action.payload.testFraction / 100,
    trainFraction: action.payload.trainFraction / 100,
    valFraction: action.payload.valFraction / 100,
    unlabeledFraction: (action.payload.unlabeledFraction || 0) / 100,
  };

  try {
    yield* call(
      apiCreateDatasplit,
      {
        projectId,
      },
      payload,
    );

    yield* put(createSplitSuccess());
    yield* put(
      enqueueNotification({
        message: 'Split creation has been scheduled',
        options: {
          variant: 'success',
          allowOutsideOfEditor: true,
        },
      }),
    );
    yield* put(hideModals());
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to create the split');

    yield* put(createSplitFailure(message));
    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
          refresh: false,
        },
      }),
    );
  }
}

function* createNestedSplitHandler(
  action: ActionType<typeof createNestedSplit>,
) {
  const projectId = yield* select(activeProjectIdSelector);
  const payload = {
    ...action.payload.data,
    trainFraction: action.payload.data.trainFraction / 100,
  };

  try {
    yield* call(
      apiCreateNestedDatasplit,
      {
        projectId,
        splitId: action.payload.params.splitId,
      },
      payload,
    );

    yield* put(createSplitSuccess());
    yield* put(
      enqueueNotification({
        message: 'Split creation has been scheduled',
        options: {
          variant: 'success',
          allowOutsideOfEditor: true,
        },
      }),
    );
    yield* put(hideModals());
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to create the split');

    yield* put(createSplitFailure(message));
    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
          refresh: false,
        },
      }),
    );
  }
}

function* removeHandler(action: ActionType<typeof removeSplit>) {
  const { splitId, parentSplitId } = action.payload;
  const projectId = yield* select(activeProjectIdSelector);

  try {
    yield* call(apiDeleteDatasplit, {
      projectId,
      splitId,
    });
    yield* put(removeSplitSuccess(splitId));
    yield* put(hideModals());

    if (parentSplitId) {
      yield* put(removeNestedSplitSuccess({ splitId, parentSplitId }));
    }
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to remove split');

    yield* put(removeSplitFailure(message));
    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
          refresh: false,
        },
      }),
    );
  } finally {
    yield* put(hideModals());
  }
}

function* syncSplitCreation(action: ActionType<typeof addSplitFromWebsocket>) {
  const projectId = yield* select(activeProjectIdSelector);
  // todo @nested get diff between nested and not nested

  if (projectId && projectId === action.payload.projectId) {
    yield* put(addSplit({ ...action.payload.split, numExperiments: 0 }));
  }
}

export function* dataSaga() {
  yield* takeEvery(loadSplits, loadAllHandler);
  yield* takeEvery(loadSplit, loadOneHandler);
  yield* takeEvery(createSplit, createHandler);
  yield* takeEvery(createNestedSplit, createNestedSplitHandler);
  yield* takeEvery(removeSplit, removeHandler);
  yield* takeEvery(addSplitFromWebsocket, syncSplitCreation);
}
