import { createSelector } from '@reduxjs/toolkit';

import { statusChecks } from '../../../../../../constants/status';
import { UploadFile, adapter } from './importUploads.slice';
import { editedProjectSelector } from '../../editedProject.selectors';

const getUploadStatuses = (uploads: UploadFile[]) =>
  uploads.map((upload) => upload.loadingState.status);

const importUploadsStateSelector = createSelector(
  editedProjectSelector,
  (editedProject) => editedProject.imports.uploads,
);

const selectors = adapter.getSelectors(importUploadsStateSelector);

export const importUploadsSelector = selectors.selectAll;

const importsUploadStatusesSelector = createSelector(
  importUploadsSelector,
  (uploads) => getUploadStatuses(uploads),
);

export const importUploadsTotalCountSelector = createSelector(
  importUploadsSelector,
  (data) => data.length,
);

const importUploadsErrorCountSelector = createSelector(
  importsUploadStatusesSelector,
  (uploadStatuses) => uploadStatuses.filter(statusChecks.isError).length,
);

export const importUploadsSuccessCountSelector = createSelector(
  importsUploadStatusesSelector,
  (uploadStatuses) => uploadStatuses.filter(statusChecks.isSuccess).length,
);

export const importUploadsFinishedCountSelector = createSelector(
  [importUploadsSuccessCountSelector, importUploadsErrorCountSelector],
  (successCount, errorCount) => successCount + errorCount,
);

export const isImportUploadsFinishedSelector = createSelector(
  [importUploadsSelector, importUploadsFinishedCountSelector],
  (uploads, uploadFinishedCount) => uploads.length === uploadFinishedCount,
);
