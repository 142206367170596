import axios from 'axios';

import { NEW_API_URL } from '../../constants/paths';
import { apiWrapper } from '../utils';
import { headers, transformRequest, transformResponse } from '../helpers';
import { ApiLock, ApiLockSubjectParams } from '../schemas/locks';

export const apiLockSubject = (
  params: ApiLockSubjectParams,
  data: Partial<ApiLock>,
) =>
  apiWrapper(
    axios.request({
      method: 'post',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/locks/${params.projectId}`,
      data,
    }),
  );

export const apiUnlockSubject = (
  params: ApiLockSubjectParams,
  data: Partial<ApiLock>,
) =>
  apiWrapper(
    axios.request({
      method: 'delete',
      headers,
      transformRequest,
      url: `${NEW_API_URL}/locks/${params.projectId}`,
      data,
    }),
  );

export const apiLoadSubjectLocks = (projectId: string) =>
  apiWrapper(
    axios.request<ApiLock[]>({
      method: 'get',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/locks/${projectId}`,
    }),
  );
