import axios from 'axios';

import { API_URL } from '../../constants/paths';
import { apiWrapper } from '../utils';
import { headers, transformRequest, transformResponse } from '../helpers';
import { ImageLabel } from '../domainModels/imageLabel';
import {
  ApiGetObjectDetectionObjectsResponse,
  ApiLoadInstanceSegmentationObjectsResponse,
  ApiLoadSemanticSegmentationMaskResponse,
} from '../domainModels/projectTools';
import {
  ApiInstanceSegmentationParams,
  ApiObjectDetectionParams,
} from '../schemas/projectTools';
import {
  ApiKeypointsDetection,
  ApiKeypointsDetectionParams,
} from '../schemas/keypointsDetection';
import {
  ApiLoadTextPromptParams,
  ApiTextPromptResult,
} from '../schemas/textPrompt';
import { Polygon } from '../../@types/imageView/types';

export const apiLoadAtomModel = (projectId: string, modelId = 0) =>
  apiWrapper(
    axios.request<Record<string, any>>({
      method: 'get',
      url: `${API_URL}/project/${projectId}/atom_model/${modelId}`,
    }),
  );

export const apiLoadClassificationModel = (projectId: string, modelId = 0) =>
  apiWrapper(
    axios.request<Record<string, any>>({
      method: 'get',
      url: `${API_URL}/project/${projectId}/classification_model/${modelId}`,
    }),
  );

export const apiLoadClassPrediction = (
  projectId: string,
  imageId: string | null,
  modelId: number | null,
  params: Partial<ImageLabel>,
  keypointsCoordinatesData: Polygon | null,
) =>
  apiWrapper(
    axios.request<{
      classId: string; // can be undefined in swagger
      probability?: number;
      predictions?: Record<string, number>;
    }>({
      method: 'post',
      url: `${API_URL}/project/${projectId}/image/${imageId}/classification`,
      data: {
        bbox: params.bbox,
        polygon: params.points || params.polygon,
        model_id: modelId,
        keypoints: keypointsCoordinatesData,
      },
      headers,
      transformResponse,
      transformRequest,
    }),
  );

export const apiLoadObjectDetectionModel = (projectId: string, modelId = 0) =>
  apiWrapper(
    axios.request<Record<string, any>>({
      method: 'get',
      url: `${API_URL}/project/${projectId}/object_detection_model/${modelId}`,
    }),
  );

export const apiGetObjectDetectionObjects = (
  projectId: string,
  imageId: string | null,
  params: ApiObjectDetectionParams,
) =>
  apiWrapper(
    axios.request<ApiGetObjectDetectionObjectsResponse>({
      method: 'post',
      url: `${API_URL}/project/${projectId}/image/${imageId}/object_detection`,
      data: {
        use_classifier: params.useClassifier,
        confidence_threshold: params.confidence,
        model_id: params.modelId,
        max_detections_per_image: params.maxDetections,
      },
      headers,
      transformResponse,
      transformRequest,
    }),
  );

export const apiLoadInstanceSegmentationModel = (
  projectId: string,
  modelId = 0,
) =>
  apiWrapper(
    axios.request<Record<string, any>>({
      method: 'get',
      url: `${API_URL}/project/${projectId}/instance_segmentation_model/${modelId}`,
    }),
  );

export const apiLoadInstanceSegmentationObjects = (
  projectId: string,
  imageId: string | null,
  params: ApiInstanceSegmentationParams,
) =>
  apiWrapper(
    axios.request<ApiLoadInstanceSegmentationObjectsResponse>({
      method: 'post',
      url: `${API_URL}/project/${projectId}/image/${imageId}/instance_segmentation`,
      data: params,
      headers,
      transformResponse,
      transformRequest,
    }),
  );

export const apiLoadSemanticSegmentationModel = (
  projectId: string,
  modelId = 0,
) =>
  apiWrapper(
    axios.request<Record<string, any>>({
      method: 'get',
      url: `${API_URL}/project/${projectId}/semantic_segmentation/${modelId}`,
    }),
  );

export const apiLoadSemanticSegmentationMask = (
  projectId: string,
  imageId: string | null,
  params: { modelId: number | null; minSize: number; useAsPrompt: boolean },
) =>
  apiWrapper(
    axios.request<ApiLoadSemanticSegmentationMaskResponse>({
      method: 'post',
      url: `${API_URL}/project/${projectId}/image/${imageId}/semantic_segmentation`,
      data: params,
      headers,
      transformResponse,
      transformRequest,
    }),
  );

export const apiLoadImageTagsPredictionModel = (
  projectId: string,
  modelId = 0,
) =>
  apiWrapper(
    axios.request<void>({
      method: 'get',
      url: `${API_URL}/project/${projectId}/tagging_model/${modelId}`,
    }),
  );

export const apiLoadImageTagsPrediction = (
  projectId: string,
  imageId: string | null,
  modelId: number,
  threshold: number,
) =>
  apiWrapper(
    axios.request<string[]>({
      method: 'post',
      url: `${API_URL}/project/${projectId}/image/${imageId}/tagging`,
      data: {
        model_id: modelId,
        confidence_threshold: threshold,
      },
      headers,
      transformResponse,
      transformRequest,
    }),
  );

export const apiLoadBoxToInstanceModel = (projectId: string, modelId = 0) =>
  apiWrapper(
    axios.request<Record<string, any>>({
      method: 'get',
      url: `${API_URL}/project/${projectId}/box_to_instance_model/${modelId}`,
    }),
  );

export const apiLoadAttributesPredictionModel = (
  projectId: string,
  modelId = 0,
) =>
  apiWrapper(
    axios.request<Record<string, any>>({
      method: 'get',
      url: `${API_URL}/project/${projectId}/attribute/${modelId}`,
    }),
  );

export const apiLoadKeypointsDetectionModel = (
  projectId: string,
  modelId = 0,
) =>
  apiWrapper(
    axios.request<Record<string, any>>({
      method: 'get',
      url: `${API_URL}/project/${projectId}/keypoint_detection/${modelId}`,
    }),
  );

export const apiLoadKeypointsDetection = (
  projectId: string,
  imageId: string,
  params: ApiKeypointsDetectionParams,
) =>
  apiWrapper(
    axios.request<ApiKeypointsDetection[]>({
      method: 'post',
      url: `${API_URL}/project/${projectId}/image/${imageId}/keypoint_detection`,
      data: params,
      headers,
      transformResponse,
      transformRequest,
    }),
  );

export const apiLoadSegmentPredictorModel = (projectId: string, modelId = 0) =>
  apiWrapper(
    axios.request<Record<string, any>>({
      method: 'get',
      url: `${API_URL}/project/${projectId}/activity_recognition_model/${modelId}`,
    }),
  );

export const apiLoadTextPromptModel = (projectId: string, modelId = 0) =>
  apiWrapper(
    axios.request<Record<string, any>>({
      method: 'get',
      url: `${API_URL}/project/${projectId}/text_to_mask_model/${modelId}`,
    }),
  );

export const apiLoadTextPrompt = (
  projectId: string,
  imageId: string,
  params: ApiLoadTextPromptParams,
) =>
  apiWrapper(
    axios.request<ApiTextPromptResult[]>({
      method: 'post',
      url: `${API_URL}/project/${projectId}/image/${imageId}/text_to_mask`,
      data: params,
      headers,
      transformResponse,
      transformRequest,
    }),
  );
