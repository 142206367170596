import { put, select, takeEvery, call } from 'typed-redux-saga';

import {
  apiLoadProjectTagGroups,
  apiCreateProjectTagGroup,
  apiUpdateProjectTagGroup,
  apiDeleteProjectTagGroup,
} from '../../../../../api/requests/tagGroup';
import { getErrorMessage } from '../../../../../api/utils';
import { handleError } from '../../../commonFeatures/errorHandler/errorHandler.actions';
import { setProjectId } from '../../../core/imageView/project/project.slice';
import { initImageProject } from '../../../sections/editedProject/project/project.slice';
import { loadAll } from '../../../../utils/api';
import { hideModals } from '../../../ui/modals/modals.slice';
import { activeProjectIdSelector } from '../../project.selectors';
import {
  addTagGroup,
  addTagGroupFailure,
  addTagGroupSuccess,
  loadTagGroupsFailure,
  loadTagGroupsStart,
  loadTagGroupsSuccess,
  removeTagGroup,
  removeTagGroupFailure,
  removeTagGroupSuccess,
  resetTagGroupLoadingState,
  updateTagGroup,
  updateTagGroupFailure,
  updateTagGroupSuccess,
} from './tagGroups.slice';

function* listHandler(
  action: ActionType<typeof initImageProject | typeof setProjectId>,
) {
  const projectId = action.payload.id;

  yield* put(loadTagGroupsStart());

  try {
    const data = yield* loadAll({
      apiHelper: apiLoadProjectTagGroups,
      params: {
        projectId,
      },
    });

    yield* put(loadTagGroupsSuccess(data));
  } catch (e) {
    yield* put(
      loadTagGroupsFailure(getErrorMessage(e, 'Not able to load tag groups')),
    );
  }
}

function* addTagGroupHandler(action: ActionType<typeof addTagGroup>) {
  const projectId = yield* select(activeProjectIdSelector);

  try {
    const { data } = yield* call(
      apiCreateProjectTagGroup,
      { projectId },
      action.payload,
    );

    yield* put(addTagGroupSuccess(data));
    yield* put(hideModals());
  } catch (e) {
    yield* put(
      addTagGroupFailure(getErrorMessage(e, 'Not able to create a tag group')),
    );
  }
}

function* updateTagGroupHandler(action: ActionType<typeof updateTagGroup>) {
  const { id, ...values } = action.payload;

  const projectId = yield* select(activeProjectIdSelector);

  try {
    const { data } = yield* call(
      apiUpdateProjectTagGroup,
      { projectId, tagGroupId: id },
      values,
    );

    yield* put(
      updateTagGroupSuccess({
        changes: data,
        id,
      }),
    );
    yield* put(hideModals());
  } catch (e) {
    yield* put(
      updateTagGroupFailure({
        message: getErrorMessage(e, 'Not able to update the tag group'),
        id,
      }),
    );
  }
}

function* removeTagGroupHandler(action: ActionType<typeof removeTagGroup>) {
  const projectId = yield* select(activeProjectIdSelector);
  const id = action.payload;

  try {
    yield* call(apiDeleteProjectTagGroup, {
      projectId,
      tagGroupId: id,
    });
    yield* put(removeTagGroupSuccess(id));
    yield* put(hideModals());
  } catch (error) {
    const errorMessage = getErrorMessage(
      error,
      'Not able to remove the tag group',
    );
    yield* put(removeTagGroupFailure(errorMessage));
    yield* put(handleError({ message: errorMessage, error }));
  }
}

function* hideModalsHandler() {
  yield* put(resetTagGroupLoadingState());
}

export function* tagGroupsSaga() {
  yield* takeEvery([initImageProject, setProjectId], listHandler);
  yield* takeEvery(addTagGroup, addTagGroupHandler);
  yield* takeEvery(updateTagGroup, updateTagGroupHandler);
  yield* takeEvery(removeTagGroup, removeTagGroupHandler);
  yield* takeEvery(hideModals, hideModalsHandler);
}
