import {
  ApiImportSession,
  ApiImportFile,
  ApiImportFileParseLog,
  ApiImportLabelClass,
} from '../schemas/imports';
import { getLabelType } from './utils';
import { ImportFileStatus } from '../constants/import';

export const PARSE_GENERAL_ERROR = 'GENERAL_ERROR';

export enum ParseFileStatus {
  Error = 'ERROR',
  Warning = 'WARNING',
}

export const importFileStatusLabels: Record<ImportFileStatus, string> = {
  [ImportFileStatus.Uploaded]: 'Uploaded',
  [ImportFileStatus.Processed]: 'Processed',
  [ImportFileStatus.Failed]: 'Failed',
  [ImportFileStatus.Parsing]: 'Parsing...',
};

const importLabelClassFromBackend = ({
  polygon: points,
  ...rest
}: ApiImportLabelClass) => ({
  ...rest,
  id: `${rest.imageId}/${rest.classId}`, // for generating imageData, we need to regenerate imageData in case of loading new image example
  labelClass: {
    color: rest.color,
  },
  type: getLabelType({ polygon: points, mask: rest.mask }),
  classExampleRefetched: new Date().valueOf().toString(),
});

export type ImportSession = ApiImportSession;
export type ImportFile = ApiImportFile;
export type ImportFileParseLog = ApiImportFileParseLog;
export type ImportLabelClass = ReturnType<
  typeof importLabelClassFromBackend
> & {
  borderDataId?: string;
  borderData?: string;
  imageDataId?: string;
  imageData?: string;
};

export const importLabelClassDataMapper = {
  fromBackend: importLabelClassFromBackend,
};

export type ImportSessionFormValues = Pick<ImportSession, 'name'>;
