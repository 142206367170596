// TODO: TS dept
import axios from 'axios';

import { headers, transformRequest, transformResponse } from '../helpers';
import { apiWrapper } from '../utils';
import { API_URL, NEW_API_URL } from '../../constants/paths';
import {
  ApiCustomerBillingStatus,
  ApiUpdatePaymentPlanPayload,
} from '../schemas/payment';

export const apiUpdateCustomer = (workspaceId: string, data: unknown) =>
  apiWrapper(
    axios.request({
      method: 'put',
      url: `${NEW_API_URL}/billing/subjects/${workspaceId}/customer`,
      data,
      headers,
      transformResponse,
      transformRequest,
    }),
  );

export const apiUpdatePaymentMethod = (workspaceId: string, data: unknown) =>
  apiWrapper(
    axios.request<{ redirectUrl: string }>({
      method: 'put',
      url: `${NEW_API_URL}/billing/subjects/${workspaceId}/payment_method`,
      data: {
        payment_method: data,
        return_url: `${window.location.protocol}//${window.location.host}/3dscomplete`,
      },
      headers,
      transformResponse,
      transformRequest,
    }),
  );

export const apiLoadCustomer = (workspaceId: string) =>
  apiWrapper<ApiCustomerBillingStatus>(
    axios({
      method: 'get',
      url: `${NEW_API_URL}/billing/subjects/${workspaceId}/customer`,
      transformResponse,
    }),
  );

export const apiUpdatePaymentPlan = ({
  workspaceId,
  targetPlanName,
  scheduleUpgrade,
  coupon,
}: ApiUpdatePaymentPlanPayload) =>
  apiWrapper(
    axios({
      method: 'put',
      url: `${API_URL}/workspace/${workspaceId}/plan`,
      data: {
        plan: targetPlanName,
        schedule_upgrade: scheduleUpgrade,
        coupon,
      },
    }),
  );

export const apiLoadCreditsUsage = (workspaceId: string) =>
  apiWrapper(
    axios.request({
      method: 'get',
      url: `${API_URL}/workspace/${workspaceId}/credits_usage`,
      headers,
      transformResponse,
      transformRequest,
    }),
  );

// Loads information if 3DS operation was successful or no. setupIntentId is
// taken from redirect url in stripe modal
export const apiLoadIntentStatus = (setupIntentId: string) =>
  apiWrapper(
    axios({
      method: 'get',
      url: `${NEW_API_URL}/billing/setup-intents/${setupIntentId}`,
    }),
  );
