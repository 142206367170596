import { createSelector } from '@reduxjs/toolkit';

import {
  componentsAdapter,
  nestedParametersAdapter,
  parametersAdapter,
} from './architectures.slice';
import { ParameterType } from '../../../../../../api/constants/modelPlayground';
import { modelPlaygroundSelector } from '../../modelPlayground.selectors';

export const experimentArchitecturesStateSelector = createSelector(
  modelPlaygroundSelector,
  (modelPlayground) => modelPlayground.activeExperiment.architectures,
);

export const experimentArchitecturesComponentsStateSelector = createSelector(
  experimentArchitecturesStateSelector,
  (state) => state.components,
);

const componentsSelectors = componentsAdapter.getSelectors(
  experimentArchitecturesComponentsStateSelector,
);
export const experimentArchitecturesDataSelector =
  componentsSelectors.selectAll;

export const selectedArchitectureSelector = createSelector(
  experimentArchitecturesDataSelector,
  (architectures) =>
    architectures.find((architecture) => architecture.selected),
);
export const selectedArchitectureIdSelector = createSelector(
  selectedArchitectureSelector,
  (selectedArchitecture) => selectedArchitecture?.id,
);
export const selectedArchitectureComponentIdSelector = createSelector(
  selectedArchitectureSelector,
  (selectedArchitecture) => selectedArchitecture?.componentId,
);
export const experimentArchitecturesLoadingStateSelector = createSelector(
  experimentArchitecturesComponentsStateSelector,
  (state) => state.loadingState,
);
export const experimentArchitecturesUpdateLoadingStateSelector = createSelector(
  experimentArchitecturesComponentsStateSelector,
  (state) => state.updateLoadingState,
);

export const architectureParametersStateSelector = createSelector(
  experimentArchitecturesStateSelector,
  (state) => state.parameters,
);

const parametersSelectors = parametersAdapter.getSelectors(
  architectureParametersStateSelector,
);
export const architectureParametersSelector = parametersSelectors.selectAll;

export const architectureParametersLoadingStateSelector = createSelector(
  architectureParametersStateSelector,
  (state) => state.loadingState,
);
export const architectureParametersUpdateLoadingStateSelector = createSelector(
  architectureParametersStateSelector,
  (state) => state.updateLoadingState,
);
export const architectureParameterByIdSelector = (
  state: RootState,
  id: string,
) => {
  const parameters = architectureParametersSelector(state);

  return parameters.find((parameter) => parameter.id === id);
};
export const architectureParameterWeightsSelector = createSelector(
  architectureParametersSelector,
  (parameters) => parameters.find((parameter) => parameter.type === 'weights'),
);
export const architectureNestedParameterComponentIdSelector = createSelector(
  architectureParametersSelector,
  (parameters) =>
    parameters.find((parameter) => parameter.type === 'model')
      ?.experimentParameterId,
);

export const architectureParametersHaveWeightsSelector = createSelector(
  architectureParameterWeightsSelector,
  (parameter) => !!parameter,
);
export const architectureParametersForWeightsSelector = createSelector(
  architectureParametersSelector,
  (parameters) =>
    parameters
      .filter((parameter) => parameter.type !== ParameterType.Weights)
      .map((parameter) => ({
        parameterId: parameter.id,
        value: parameter.selectedValue,
      })),
);

export const architectureNestedParametersStateSelector = createSelector(
  experimentArchitecturesStateSelector,
  (state) => state.nestedParameters,
);

const nestedParametersSelectors = nestedParametersAdapter.getSelectors(
  architectureNestedParametersStateSelector,
);
export const architectureNestedParametersSelector =
  nestedParametersSelectors.selectAll;

export const architectureNestedParametersLoadingStateSelector = createSelector(
  architectureNestedParametersStateSelector,
  (state) => state.loadingState,
);
export const architectureNestedParametersUpdateLoadingStateSelector =
  createSelector(
    architectureNestedParametersStateSelector,
    (state) => state.updateLoadingState,
  );
export const architectureNestedParameterByIdSelector = (
  state: RootState,
  id: string,
) => {
  const parameters = architectureNestedParametersSelector(state);

  return parameters.find((parameter) => parameter.id === id);
};

export const architectureNestedParameterWeightsSelector = createSelector(
  architectureNestedParametersSelector,
  (parameters) => parameters.find((parameter) => parameter.type === 'weights'),
);
export const architectureNestedParametersHaveWeightsSelector = createSelector(
  architectureNestedParameterWeightsSelector,
  (parameter) => !!parameter,
);
export const architectureNestedParametersForWeightsSelector = createSelector(
  architectureNestedParametersSelector,
  (parameters) =>
    parameters
      .filter((parameter) => parameter.type !== ParameterType.Weights)
      .map((parameter) => ({
        parameterId: parameter.id,
        value: parameter.selectedValue,
      })),
);

export const architectureWeightsSelector = createSelector(
  experimentArchitecturesStateSelector,
  (state) => state.weights,
);

export const architectureWeightsLoadingStateSelector = createSelector(
  experimentArchitecturesStateSelector,
  (state) => state.weightsLoadingState,
);
export const architectureWeightsUpdateLoadingStateSelector = createSelector(
  experimentArchitecturesStateSelector,
  (state) => state.weightsUpdateLoadingState,
);

export const architectureNestedWeightsSelector = createSelector(
  experimentArchitecturesStateSelector,
  (state) => state.nestedWeights,
);

export const architectureNestedWeightsLoadingStateSelector = createSelector(
  experimentArchitecturesStateSelector,
  (state) => state.nestedWeightsLoadingState,
);
export const architectureNestedWeightsUpdateLoadingStateSelector =
  createSelector(
    experimentArchitecturesStateSelector,
    (state) => state.nestedWeightsUpdateLoadingState,
  );
export const saveNestedWeightsLoadingStateSelector = createSelector(
  experimentArchitecturesStateSelector,
  (state) => state.saveWeightsLoadingState,
);
