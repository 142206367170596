import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { InferenceFamily } from '../../../../../api/domainModels/modelFamily';
import { InferenceItem } from '../../../../../api/domainModels/inferences';
import {
  LoadingState,
  loadingStateBuilder,
} from '../../../../utils/loadingState';

export const inferenceItemAdapter = createEntityAdapter<InferenceItem>();

type InferenceState = {
  loadingState: LoadingState;
  actionLoadingState: LoadingState;
  filters: {
    family: InferenceFamily | null;
    page: number;
    perPage: number;
  };
  total: number | null;
  selectedIds: string[];
};

const initialState: InferenceState = {
  loadingState: loadingStateBuilder.initial(),
  actionLoadingState: loadingStateBuilder.initial(),
  filters: {
    family: null,
    page: 1,
    perPage: 20,
  },
  total: null,
  selectedIds: [],
};

const { actions, reducer: inferencesReducer } = createSlice({
  name: 'inferenceMonitoring/inferences',
  initialState: inferenceItemAdapter.getInitialState(initialState),
  reducers: {
    loadInferenceItems(
      state,
      { payload }: PayloadAction<{ page: number; family: InferenceFamily }>,
    ) {
      inferenceItemAdapter.setAll(state, []);
      state.selectedIds = [];
      state.filters.page = payload.page;
      state.filters.family = payload.family;
      state.loadingState = loadingStateBuilder.inProgress();
    },
    loadInferenceItemsSuccess(
      state,
      { payload }: PayloadAction<{ items: InferenceItem[]; total: number }>,
    ) {
      inferenceItemAdapter.setAll(state, payload.items);
      state.total = payload.total;
      state.loadingState = loadingStateBuilder.success();
    },
    loadInferenceItemsFailure(state, { payload }: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.failure(payload);
    },
    setSelectedInferenceIds(state, { payload }: PayloadAction<string[]>) {
      state.selectedIds = payload;
    },
    deleteSelectedInferences(state) {
      if (state.selectedIds.length) {
        state.actionLoadingState = loadingStateBuilder.inProgress();
      }
    },
    moveSelectedInferences(
      state,
      _action: PayloadAction<{ datasetId: string }>,
    ) {
      if (state.selectedIds.length) {
        state.actionLoadingState = loadingStateBuilder.inProgress();
      }
    },
    inferenceActionSuccess(state) {
      state.actionLoadingState = loadingStateBuilder.success();
      state.selectedIds = [];
    },
  },
});

export { inferencesReducer };
export const {
  loadInferenceItems,
  loadInferenceItemsFailure,
  loadInferenceItemsSuccess,
  setSelectedInferenceIds,
  deleteSelectedInferences,
  moveSelectedInferences,
  inferenceActionSuccess,
} = actions;
