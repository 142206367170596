import { AtomEffect } from 'recoil';

import { getLocalStorageItem, upsertLocalStorageItem } from '../localStorage';
import {
  LocalStorageValue,
  VideoViewLocalStorageKey,
} from '../localStorage/types';

export const localStorageEffect =
  <T>(key: VideoViewLocalStorageKey): AtomEffect<T> =>
  ({ setSelf, onSet }) => {
    const savedValue = getLocalStorageItem(key) as T | null;

    if (savedValue != null) {
      setSelf(savedValue);
    }

    onSet((newValue) => {
      upsertLocalStorageItem(key, newValue as LocalStorageValue);
    });
  };
