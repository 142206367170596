import Konva from 'konva';
import loadImage from 'blueimp-load-image';

import {
  retrieveImageDataWithFallback,
  retrieveObject,
} from '../../../../helpers/imageView/data.helpers';

declare global {
  interface Window {
    imageData: ImageData[] | null[];
    objectStorage: any[];
  }
}

window.imageData = [null, null];
// add first element, so no stored element will get a 0 index – and bool coercion comparison could be
// performed with ids safely
window.objectStorage = [null];

type LabelToEnrich = {
  imageDataId?: number;
  borderDataId?: number;
};
type EnrichedLabel<T> = T & {
  imageData?: ImageData;
  borderData?: number[];
};
export const enrichLabel = <T extends LabelToEnrich>(
  label: T,
): EnrichedLabel<T> => {
  const result: EnrichedLabel<T> = { ...label };

  if (label.imageDataId) {
    result.imageData = retrieveObject(label.imageDataId);
  }
  // TODO:: clean this up when BE returns border for predictions
  if (!('borderData' in label) && label.borderDataId) {
    result.borderData = retrieveObject(label.borderDataId);
  }

  return result;
};

export const releaseObject = (id: number | null) => {
  if (id !== null) {
    window.objectStorage[id] = undefined;
  }
};

export const imageDataFromImage = (image: any) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = image.width;
  canvas.height = image.height;

  if (!ctx) return;

  ctx.drawImage(image, 0, 0);

  return ctx.getImageData(0, 0, image.width, image.height);
};

export const enrichLabels = <T extends LabelToEnrich>(labels: T[]) =>
  labels.map((label) => enrichLabel<T>(label));

/**
 * Restricts canvas panning by checking if the
 * requested panning position exceeds maximal
 * allowed or is less than minimal allowed
 * @param stage - Konva stage
 * @param position - new X and Y position to check if allowed
 */
export const getClampedPosition = (
  stage: Konva.Stage,
  { x, y }: { x: number; y: number },
) => {
  let newX = x;
  let newY = y;

  const scale = stage.scale();

  if (scale === undefined) return { x: newX, y: newY };

  // Width of visible area
  const viewportWidth = stage.width();
  // Height of visible area
  const viewportHeight = stage.height();

  const viewportRatio = viewportWidth / viewportHeight;
  const imageData = retrieveImageDataWithFallback();
  const imageRatio = imageData.width / imageData.height;

  const [imageWidth, imageHeight] =
    viewportRatio > imageRatio
      ? [imageData.width * (viewportHeight / imageData.height), viewportHeight]
      : [viewportWidth, imageData.height * (viewportWidth / imageData.width)];

  // Empty gap from top and left.
  // E.g: For horizontal image -> offsetX === 0 && offsetY > 0
  //      For vertical image -> offsetY === 0 && offsetX > 0
  const [offsetX, offsetY] = [
    (viewportWidth - imageWidth) / 2,
    (viewportHeight - imageHeight) / 2,
  ];

  // Amount of allowed pixels to go outside
  const ALLOWED_PAN_THRESHOLD = 25;

  // Amount of allowed pixels to go outside from left and top
  const minXY = ALLOWED_PAN_THRESHOLD;

  // End point of horizontal drag/scroll
  const maxX =
    -viewportWidth * (scale.x - 1) +
    offsetX * 2 * scale.x -
    ALLOWED_PAN_THRESHOLD;

  // End point of vertical drag/scroll
  const maxY =
    -viewportHeight * (scale.y - 1) +
    offsetY * 2 * scale.y -
    ALLOWED_PAN_THRESHOLD;

  // If horizontally zoomed in enough and there's
  // a space from left and right to pan, apply
  // the position but keep it within the limit
  if (scale.x > viewportRatio / imageRatio) {
    // Restrict panning if it goes to far from the left (left of the image)
    if (newX > minXY) {
      newX = minXY;
    } else if (newX < maxX) {
      // Restrict panning if it goes to far from the right (right of the image)
      newX = maxX;
    }
  } else {
    // If horizontally wasn't zoomed in enough to pan,
    // keep it in the center horizontally
    newX = (viewportWidth - imageWidth * scale.x) / 2;
  }

  // If vertically zoomed in enough and there's
  // a space from top and bottom to pan, apply
  // the position but keep it within the limit
  if (scale.y > imageRatio / viewportRatio) {
    // Restrict panning if it goes to far from the top (start of the image)
    if (newY > minXY) {
      newY = minXY;
    } else if (newY < maxY) {
      // Restrict panning if it goes to far from the bottom (end of the image)
      newY = maxY;
    }
  } else {
    // If vertically wasn't zoomed in enough to pan,
    // keep it in the middle vertically
    newY = (viewportHeight - imageHeight * scale.y) / 2;
  }

  return {
    x: newX,
    y: newY,
  };
};

export const saveImageData = (data: any, i = 0) => {
  window.imageData[i] = data;
};

export const storeObject = (obj: any) => {
  const id = window.objectStorage.length;
  window.objectStorage.push(obj);

  return id;
};

export const imageDataFromUrl = async (url: string) => {
  try {
    // https://github.com/blueimp/JavaScript-Load-Image#orientation
    // Setting orientation to 1 enables the canvas and meta options if
    // the browser does support automatic image orientation (to allow reset of the orientation).
    const result = await loadImage(url, {
      canvas: true,
      // @ts-expect-error
      crossOrigin: true,
      orientation: 1,
    });

    if ('image' in result) {
      return {
        imageData: imageDataFromImage(result.image),
        imageObject: result.image,
      };
    }
    throw new Error();
  } catch (e) {
    throw new Error('Unable to load image.');
  }
};

export const clearObjectStorage = () => {
  window.objectStorage.forEach((_: any, index: number) => releaseObject(index));
  window.imageData = [null, null];
  window.objectStorage.splice(1, window.objectStorage.length - 1);
};
