import { createSelector } from '@reduxjs/toolkit';

import {
  HeuriscticRankingJobStatus,
  HeuristicRankingOrigin,
} from '../../../../api/constants/heuristic';
import { ModelFamily } from '../../../../api/constants/modelFamily';
import { adapter } from './heuristicsRankingJobs.slice';
import { projectSelector } from '../project.selectors';

export const ModelFamilyToShortText = {
  [ModelFamily.Detector]: 'OD',
  [ModelFamily.Segmentor]: 'IS',
  [ModelFamily.SemanticSegmentor]: 'SES',
};

const heuristicsRankingJobsStateSelector = createSelector(
  projectSelector,
  (state) => state.heuristicsRankingJobs,
);

const selectors = adapter.getSelectors(heuristicsRankingJobsStateSelector);

export const heuristicsRankingJobsSelector = selectors.selectAll;
export const heuristicsRankingJobByIdSelector = selectors.selectById;

export const heuristicsRankingJobsPendingRankingJobSelector = createSelector(
  heuristicsRankingJobsStateSelector,
  (state) => state.pendingRankingJob,
);

export const heuristicsRankingJobsHasPendingRankingJobSelector = createSelector(
  heuristicsRankingJobsPendingRankingJobSelector,
  (pendingRankingJob) => !!pendingRankingJob,
);

export const heuristicsRankingJobsHasNewRankingJobIndicatorSelector =
  createSelector(
    heuristicsRankingJobsStateSelector,
    (state) => state.isNewRankingJobAvailableIndicator,
  );

export const heuristicsRankingJobsIsFilterPrefilledIndicatorSelector =
  createSelector(
    heuristicsRankingJobsStateSelector,
    (state) => state.isFilterPrefilledIndicator,
  );

export const heuristicsRankingJobsListLoadingStateSelector = createSelector(
  heuristicsRankingJobsStateSelector,
  (state) => state.listLoadingState,
);
export const heuristicsRankingJobsItemLoadingStateSelector = createSelector(
  heuristicsRankingJobsStateSelector,
  (state) => state.itemLoadingState,
);

export const heuristicsRunningRankingJobsSelector = createSelector(
  heuristicsRankingJobsSelector,
  (rankingJobs) =>
    rankingJobs.filter(({ status }) =>
      [
        HeuriscticRankingJobStatus.Initial,
        HeuriscticRankingJobStatus.Running,
      ].includes(status),
    ),
);

export const heuristicsDoneRankingJobsSelector = createSelector(
  heuristicsRankingJobsSelector,
  (rankingJobs) =>
    rankingJobs.filter(
      ({ status }) => status === HeuriscticRankingJobStatus.Done,
    ),
);

export const heuristicsOnDemandRankingJobsSelector = createSelector(
  heuristicsRankingJobsSelector,
  (rankingJobs) =>
    rankingJobs.filter(
      ({ origin }) => origin === HeuristicRankingOrigin.OnDemand,
    ),
);

export const heuristicsRankingJobsAsOptionsSelector = createSelector(
  heuristicsDoneRankingJobsSelector,
  (doneRankingJobs) => {
    if (!doneRankingJobs.length) return [];

    const heuristicOptions = doneRankingJobs
      .map(({ sessionId, sessionName, heuristicName, modelFamily, origin }) => {
        const isOnDemand = origin === HeuristicRankingOrigin.OnDemand;

        const name = isOnDemand ? sessionName : 'Auto-generated Hasty rank';
        const text = `${ModelFamilyToShortText[modelFamily]} ${heuristicName} - ${name}`;

        return {
          id: sessionId,
          value: sessionId,
          group:
            origin === HeuristicRankingOrigin.OnDemand
              ? 'Ranks on demand'
              : 'Auto generated ranks',
          text,
        };
      })
      .sort((a, b) => -b.group.localeCompare(a.group));

    return [
      ...heuristicOptions,
      {
        id: 'no_rank',
        value: null,
        text: 'No rank (Default sorting)',
        group: 'Other sorting',
      },
    ];
  },
);
