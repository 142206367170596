import { all, put, takeEvery, take, select } from 'typed-redux-saga';
import { push } from 'connected-react-router';

import { loadProjectInfo } from '../../../project/projectInfo/projectInfo.slice';
import {
  projectSelectedError,
  projectSelectedSuccess,
  setProjectId,
} from './project.slice';
import { imageTagsSaga } from './imageTags/imageTags.saga';
import { loadPackageFeatures } from '../../../commonFeatures/packageFeatures/packageFeatures.slice';
import { resetProject } from '../imageView.actions';
import { AdvancedOptionApiType } from '../../../../../api/domainModels/advancedOptions';
import { loadAdvancedOptionStart } from '../../../sections/editedProject/advancedOptions/advancedOptions.slice';
import { unsubscribeSubject } from '../../../ws/ws.actions';
import { ANNOTATE_IMAGE_PROJECT } from '../../../ws/ws.eventsLists';
import { loadAtomModel } from '../tools/atom/atomModel.slice';
import { loadBoxToInstanceModel } from '../tools/boxToInstance/boxToInstance.slice';
import { loadImageTagsPredictionModel } from '../tools/imageTagsPrediction/imageTagsPrediction.slice';
import { loadInstanceSegmentationModel } from '../tools/instanceSegmentation/instanceSegmentation.slice';
import { loadAttributesPredictionModel } from '../tools/labelAttributesPrediction/labelAttributesPrediction.slice';
import { loadLabelClassPredictionModel } from '../tools/labelClassPrediction/labelClassPrediction.slice';
import { loadObjectDetectionModel } from '../tools/objectDetection/objectDetection.slice';
import { loadSemanticSegmentationModel } from '../tools/semanticSegmentation/semanticSegmentation.slice';
import { loadSubjectLocks } from '../../../commonFeatures/locks/locks.slice';
import { activeProjectIdSelector } from '../../../project/project.selectors';
import {
  SUBJECT_SELECTED,
  SUBJECT_SELECTION_FAILED,
} from '../../../ws/ws.constants';
import { loadKeypointsDetectionModel } from '../tools/keypointsDetection/keypointsDetection.slice';
import { loadTextPromptModel } from '../tools/textPrompt/textPrompt.slice';

type WebsocketSubjectSelectedPayload = {
  subjectId: string;
};

function* setProjectIdHandler(action: ActionType<typeof setProjectId>) {
  if (!action.payload.id) {
    yield* put(push('/workspaces'));
  }

  const projectId = action.payload.id;

  // @V2Debt finish this
  yield* all([
    put(resetProject()),
    put(
      loadProjectInfo({
        projectId,
        eventsList: ANNOTATE_IMAGE_PROJECT,
      }),
    ),
    put(loadPackageFeatures()),
    put(loadSubjectLocks(projectId)),
    put(
      loadAdvancedOptionStart({
        projectId,
        optionKey: AdvancedOptionApiType.AutomatedToolModeSettingKey,
      }),
    ),
    put(
      loadAdvancedOptionStart({
        projectId,
        optionKey: AdvancedOptionApiType.ModelUseIE,
      }),
    ),
  ]);

  yield* take([projectSelectedSuccess, projectSelectedError]); // wait for project registration on websockets before requesting models / finish him status

  const secondaryEffects = [
    put(loadAtomModel(projectId)),
    put(loadBoxToInstanceModel(projectId)),
    put(loadImageTagsPredictionModel(projectId)),
    put(loadInstanceSegmentationModel(projectId)),
    put(loadAttributesPredictionModel(projectId)),
    put(loadLabelClassPredictionModel(projectId)),
    put(loadObjectDetectionModel(projectId)),
    put(loadSemanticSegmentationModel(projectId)),
    put(loadKeypointsDetectionModel(projectId)),
    put(loadTextPromptModel(projectId)),
  ];

  yield* all(secondaryEffects);
}

function* resetProjectHandler(_action: ActionType<typeof resetProject>) {
  yield* put(unsubscribeSubject({ identifier: 'project' }));
}

function* websocketSubjectSelectedHandler({
  subjectId,
}: WebsocketSubjectSelectedPayload & { type: string }) {
  const projectId = yield* select(activeProjectIdSelector);

  if (subjectId === projectId) {
    yield* put(projectSelectedSuccess());
  }
}

function* websocketSubjectionFailedHandler({
  subjectId,
}: WebsocketSubjectSelectedPayload & { type: string }) {
  const projectId = yield* select(activeProjectIdSelector);

  if (subjectId === projectId) {
    yield* put(projectSelectedError());
  }
}

function* projectSaga() {
  yield* takeEvery(setProjectId, setProjectIdHandler);
  yield* takeEvery(resetProject, resetProjectHandler);
  yield* takeEvery(SUBJECT_SELECTED, websocketSubjectSelectedHandler);
  yield* takeEvery(SUBJECT_SELECTION_FAILED, websocketSubjectionFailedHandler);
}

export const projectSagas = [imageTagsSaga, projectSaga];
