import { put, takeEvery, select, call } from 'typed-redux-saga';

import { apiLoadLabelConfidence } from '../../../../../api/requests/consensusScoring';
import { getErrorMessage } from '../../../../../api/utils';
import { activeProjectIdSelector } from '../../../project/project.selectors';
import { activeRunIdSelector } from '../errorFinder.selectors';
import {
  loadLabelConfidence,
  loadLabelConfidenceFailure,
  loadLabelConfidenceSuccess,
} from './confidence.slice';
import { enqueueNotification } from '../../../ui/stackNotifications/stackNotifications.slice';

function* loadHandler(action: ActionType<typeof loadLabelConfidence>) {
  try {
    const labelId = action.payload;
    const projectId = yield* select(activeProjectIdSelector);
    const runId = yield* select(activeRunIdSelector);
    const { data } = yield* call(apiLoadLabelConfidence, {
      projectId,
      runId,
      labelId,
    });
    yield* put(loadLabelConfidenceSuccess(data));
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to load label confidence');

    yield* put(loadLabelConfidenceFailure(message));
    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
        },
      }),
    );
  }
}

export function* labelConfidenceSaga() {
  yield* takeEvery(loadLabelConfidence, loadHandler);
}
