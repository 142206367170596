import { put, takeEvery, call } from 'typed-redux-saga';

import { apiLoadPackageFeatures } from '../../../../api/requests/packageFeatures';
import { apiLoadBillingPrices } from '../../../../api/requests/billing';
import { getErrorMessage } from '../../../../api/utils';
import {
  loadPackageFeatures,
  loadPackageFeaturesFailure,
  loadPackageFeaturesSuccess,
} from './packageFeatures.slice';
import { packageDataMapper } from '../../../../api/domainModels/packageFeatures';

function* loadPackageFeaturesHandler() {
  try {
    const {
      data: { features, packages },
    } = yield* call(apiLoadPackageFeatures);
    const { data: prices } = yield* call(apiLoadBillingPrices);

    const parsedPackages = packages.map(packageDataMapper.fromBackend);

    yield* put(
      loadPackageFeaturesSuccess({
        features,
        packages: parsedPackages,
        ...prices,
      }),
    );
  } catch (error) {
    yield* put(
      loadPackageFeaturesFailure(
        getErrorMessage(error, 'Not able to fetch payment plans'),
      ),
    );
  }
}

export function* packageFeaturesSaga() {
  yield* takeEvery(loadPackageFeatures, loadPackageFeaturesHandler);
}
