export const getPointsBoundingBox = (
  points: ([number, number] | null)[] | number[] | null,
) => {
  if (!points) {
    return null;
  }

  const flattened = points.length > 0 && typeof points[0] === 'number';
  let xValues;
  let yValues;

  if (flattened) {
    xValues = (points as number[]).filter((point, index) => index % 2 === 0);
    yValues = (points as number[]).filter((point, index) => index % 2 === 1);
  } else {
    xValues = points.map((point) => (point as [number, number])[0]);
    yValues = points.map((point) => (point as [number, number])[1]);
  }

  return points.length >= 0
    ? [
        Math.min(...xValues),
        Math.min(...yValues),
        Math.max(...xValues),
        Math.max(...yValues),
      ]
    : null;
};
