/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Model family query
 */
export enum ModelFamilyQuery1 {
  CLASSIFIER = 'CLASSIFIER',
  ATTRIBUTER = 'ATTRIBUTER',
  DETECTOR = 'DETECTOR',
  SEGMENTOR = 'SEGMENTOR',
  SEMANTIC_SEGMENTOR = 'SEMANTIC_SEGMENTOR',
  TAGGER = 'TAGGER',
}
