import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
  Update,
} from '@reduxjs/toolkit';

import { Webhook } from '../../../../../api/domainModels/webhook';
import { loadingStateBuilder } from '../../../../utils/loadingState';
import { setProjectId } from '../../../core/imageView/project/project.slice';

export type WebhookFormValues = Pick<
  Webhook,
  'name' | 'disabled' | 'url' | 'events' | 'description'
>;

export const adapter = createEntityAdapter<Webhook>({
  sortComparer: (a, b) => (a.id < b.id ? 1 : -1),
});
const initialState = adapter.getInitialState({
  listLoadingState: loadingStateBuilder.initial(),
  itemLoadingState: loadingStateBuilder.initial(),
});

const { actions, reducer: webhooksReducer } = createSlice({
  name: 'webhooks',
  initialState,
  reducers: {
    resetWebhooksLoadingState(state) {
      state.itemLoadingState = loadingStateBuilder.initial();
    },
    loadWebhooksStart(state) {
      state.listLoadingState = loadingStateBuilder.inProgress();
    },
    loadWebhooksSuccess(state, action: PayloadAction<Webhook[]>) {
      state.listLoadingState = loadingStateBuilder.success();
      adapter.setAll(state, action.payload);
    },
    loadWebhooksFailure(state, action: PayloadAction<string>) {
      state.listLoadingState = loadingStateBuilder.failure(action.payload);
      adapter.setAll(state, []);
    },
    addWebhookStart(state, _action: PayloadAction<WebhookFormValues>) {
      state.itemLoadingState = loadingStateBuilder.inProgress();
    },
    addWebhookFailure(state, action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.failure(action.payload);
    },
    addWebhookSuccess(state, action: PayloadAction<Webhook>) {
      state.itemLoadingState = loadingStateBuilder.success();
      adapter.addOne(state, action.payload);
    },
    updateWebhookStart(
      state,
      _action: PayloadAction<WebhookFormValues & { id: string }>,
    ) {
      state.itemLoadingState = loadingStateBuilder.inProgress();
    },
    updateWebhookFailure(state, action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.failure(action.payload);
    },
    updateWebhookSuccess(state, action: PayloadAction<Update<Webhook>>) {
      state.itemLoadingState = loadingStateBuilder.success();
      adapter.updateOne(state, action.payload);
    },
    removeWebhookStart(state, _action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.inProgress();
    },
    removeWebhookFailure(state, action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.failure(action.payload);
    },
    removeWebhookSuccess(state, action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.success();
      adapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setProjectId, () => initialState);
  },
});

export { webhooksReducer };
export const {
  resetWebhooksLoadingState,
  loadWebhooksStart,
  loadWebhooksSuccess,
  loadWebhooksFailure,
  addWebhookStart,
  addWebhookSuccess,
  addWebhookFailure,
  updateWebhookStart,
  updateWebhookSuccess,
  updateWebhookFailure,
  removeWebhookStart,
  removeWebhookSuccess,
  removeWebhookFailure,
} = actions;
