import { call, put, takeEvery, select } from 'typed-redux-saga';

import { apiLoadMetricsPerIteration } from '../../../../../../../api/requests/widgets';
import { getErrorMessage } from '../../../../../../../api/utils';
import { activeProjectIdSelector } from '../../../../../project/project.selectors';
import {
  loadMetricsPerIteration,
  loadMetricsPerIterationSuccess,
  loadMetricsPerIterationFailure,
  requestUpdateTrainingMetricFromWebsocket,
  updateTrainingMetricFromWebsocket,
  reloadMetricsPerIteration,
} from './metricsPerIteration.slice';
import {
  widgetMetricsPerIterationMapper,
  WidgetExperimentMetricMappedItem,
} from '../../../../../../../api/domainModels/widgets';
import { liveUpdateEnabledSelector } from '../widgetsOptions/widgetsOptions.selectors';
import { experimentByRunIdSelector } from '../../../experiments/experimentsData/experimentsData.selectors';
import { dashboardActiveExperimentIdSelector } from '../../activeExperiment/activeExperiment.selectors';
import { enqueueNotification } from '../../../../../ui/stackNotifications/stackNotifications.slice';
import { RunMetric } from '../../../../../../../api/constants/widgets';

function* loadDataHandler(action: ActionType<typeof loadMetricsPerIteration>) {
  const projectId = yield* select(activeProjectIdSelector);
  const experimentIds = action.payload;

  try {
    const { data } = yield* call(
      apiLoadMetricsPerIteration,
      {
        projectId,
      },
      { experimentIds },
    );

    const items = data.items
      .map(widgetMetricsPerIterationMapper.fromBackend)
      .reduce((mappedMetric, item) => {
        const existingItem = mappedMetric.find(
          (i) => i.experimentId === item.experimentId,
        );

        if (!existingItem) return [...mappedMetric, item];

        const newMetrics = Object.keys(existingItem.metrics).reduce(
          (metrics, metric) => ({
            ...metrics,
            [metric]: [
              ...(existingItem?.metrics?.[metric as RunMetric] || []),
              ...(item?.metrics?.[metric as RunMetric] || []),
            ],
          }),
          {},
        );

        const newMappedMetric = mappedMetric.filter(
          (i) => i.experimentId !== item.experimentId,
        );

        return [...newMappedMetric, { ...item, metrics: newMetrics }];
      }, [] as WidgetExperimentMetricMappedItem[]);

    yield* put(loadMetricsPerIterationSuccess(items));
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to fetch widget data');

    yield* put(loadMetricsPerIterationFailure(message));
    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
          refresh: false,
        },
      }),
    );
  }
}

function* requestUpdateTrainingMetricFromWebsocketHandler(
  action: ActionType<typeof requestUpdateTrainingMetricFromWebsocket>,
) {
  const liveUpdateEnabled = yield* select(liveUpdateEnabledSelector);
  const experiment = yield* select((state: RootState) =>
    experimentByRunIdSelector(state, action.payload.id),
  );
  const activeExperimentId = yield* select(dashboardActiveExperimentIdSelector);
  if (
    liveUpdateEnabled &&
    (experiment?.id === activeExperimentId || activeExperimentId === null)
  ) {
    yield* put(
      updateTrainingMetricFromWebsocket({
        ...action.payload,
        id: experiment?.id,
      }),
    );
  }
}

export function* metricsPerIterationSaga() {
  yield* takeEvery(
    [loadMetricsPerIteration, reloadMetricsPerIteration],
    loadDataHandler,
  );
  yield* takeEvery(
    requestUpdateTrainingMetricFromWebsocket,
    requestUpdateTrainingMetricFromWebsocketHandler,
  );
}
