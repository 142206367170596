import {
  call,
  put,
  select,
  takeLatest,
  takeEvery,
  debounce,
} from 'typed-redux-saga';
import { PayloadAction } from '@reduxjs/toolkit';

import {
  apiGetObjectDetectionObjects,
  apiLoadObjectDetectionModel,
} from '../../../../../../api/requests/projectTools';
import { getErrorMessage } from '../../../../../../api/utils';
import { handleError } from '../../../../commonFeatures/errorHandler/errorHandler.actions';
import {
  addLabel,
  confirmAddLabel,
  addObjectDetectionLabels,
  adjustConfidence,
  adjustMaxDetections,
  confirmMaxDetections,
  loadObjectDetectionModelError,
  loadObjectDetectionModelStart,
  loadObjectDetectionModelSuccess,
  loadObjects,
  loadObjectsError,
  loadObjectsSuccess,
  resetData,
  resetModel,
  setConfidence,
  setMaxDetections,
  updateObjectDetectionModel,
  updateObjectDetectionModelSuccess,
  loadObjectDetectionModel,
  updateObjectDetectionModelShowDot,
  resetMaxDetections,
  resetConfidence,
} from './objectDetection.slice';
import {
  objectDetectionModelIdSelector,
  objectDetectionConfidenceSelector,
  objectDetectionMaxDetectionsSelector,
  objectDetectionModelLoadedSelector,
  objectDetectionPredictedDataSelector,
  objectDetectionPredictedDataByIdSelector,
} from './objectDetection.selectors';
import { labelClassPredictionEnabledSelector } from '../labelClassPrediction/labelClassPrediction.selectors';
import {
  loadModelHandler,
  modelLoadedHandler,
  updateModelHandler,
} from '../models/models.saga';
import { MODEL_LOADED, MODEL_UPDATED } from '../../../../ws/ws.constants';
import {
  MODEL_MESSAGES,
  OBJECT_DETECTION_FAMILY_NAME,
  OBJECT_DETECTION_MAX_OBJECTS_TO_SHOW,
  OBJECT_DETECTION_MIN_OBJECTS_TO_SHOW,
  OBJECT_DETECTION_DEFAULT_Z_INDEX,
  OBJECT_DETECTION_DEFAULT_MAX_DETECTIONS,
} from './objectDetection.constants';
import { resetProject } from '../../imageView.actions';
import { imageViewImageIdSelector } from '../../currentImage/currentImage.selectors';
import { activeProjectIdSelector } from '../../../../project/project.selectors';
import {
  createModelChangePattern,
  ModelChangePayload,
} from '../models/models.constants';
import { adjustNumValue, assignId } from '../../imageView.util';
import { resetActiveTool, setActiveTool } from '../tools.slice';
import { addLabels } from '../../labels/labels.slice';
import { ImageTool, ImageToolTitleMap } from '../tools.constants';
import { uuidv4 } from '../../../../../../util/uuidv4';

function* adjustConfidenceHandler(action: ActionType<typeof adjustConfidence>) {
  const value = yield* select(objectDetectionConfidenceSelector);
  const newValue = adjustNumValue({ increment: action.payload, value });

  if (newValue) {
    yield* put(setConfidence(newValue));
  }
}

function* adjustMaxDetectionsHandler(
  action: ActionType<typeof adjustMaxDetections>,
) {
  const value = yield* select(objectDetectionMaxDetectionsSelector);
  const newValue = adjustNumValue({
    increment: action.payload,
    value,
    maxValue: OBJECT_DETECTION_MAX_OBJECTS_TO_SHOW,
  });

  if (newValue) {
    yield* put(setMaxDetections(newValue));
  }
}

function* loadObjectsHandler() {
  const projectId = yield* select(activeProjectIdSelector);
  const imageId = yield* select(imageViewImageIdSelector);
  const confidence = yield* select(objectDetectionConfidenceSelector);
  const modelId = yield* select(objectDetectionModelIdSelector);
  const useClassifier = yield* select((state) =>
    labelClassPredictionEnabledSelector(state, projectId),
  );
  const maxDetections = yield* select(objectDetectionMaxDetectionsSelector);

  const params = {
    confidence: confidence / 100,
    modelId,
    maxDetections,
    useClassifier,
  };

  try {
    const response = yield* call(
      apiGetObjectDetectionObjects,
      projectId,
      imageId,
      params,
    );
    const payload = response.data.map(assignId);

    yield* put(loadObjectsSuccess(payload));
  } catch (error) {
    const errorMessage = getErrorMessage(
      error,
      'Object detection failed to predict objects',
    );

    yield* put(loadObjectsError(errorMessage));
    yield* put(handleError({ message: errorMessage, error }));
  }
}

function* addLabelHandler(action: ActionType<typeof addLabel>) {
  const predictedLabel = yield* select((state: RootState) =>
    objectDetectionPredictedDataByIdSelector(state, action.payload),
  );
  if (predictedLabel) {
    yield* put(
      addLabels([
        {
          id: uuidv4(),
          classId: predictedLabel.classId,
          bbox: predictedLabel.bbox,
          toolUsed: ImageTool.ObjectDetection,
          zIndex: OBJECT_DETECTION_DEFAULT_Z_INDEX,
        },
      ]),
    );
    // TODO:: handle actual success/error
    yield* put(confirmAddLabel(action.payload));
  }
}

function* addObjectDetectionLabelsHandler() {
  const data = yield* select(objectDetectionPredictedDataSelector);

  if (data.length > 0) {
    yield* put(
      addLabels(
        data.map((datum) => ({
          id: uuidv4(),
          classId: datum.classId,
          bbox: datum.bbox,
          toolUsed: ImageTool.ObjectDetection,
          zIndex: OBJECT_DETECTION_DEFAULT_Z_INDEX,
        })),
      ),
    );

    yield* put(setActiveTool(ImageTool.Default));
  }
}

function* setConfidenceHandler() {
  yield* put(loadObjects());
}

function* setMaxDetectionsHandler(action: ActionType<typeof setMaxDetections>) {
  const currentMax = yield* select(objectDetectionMaxDetectionsSelector);
  let confirmed = action.payload;

  if (confirmed > OBJECT_DETECTION_MAX_OBJECTS_TO_SHOW) {
    confirmed = OBJECT_DETECTION_MAX_OBJECTS_TO_SHOW;
  }

  if (confirmed < OBJECT_DETECTION_MIN_OBJECTS_TO_SHOW) {
    confirmed = OBJECT_DETECTION_MIN_OBJECTS_TO_SHOW;
  }

  if (confirmed !== currentMax) {
    yield* put(confirmMaxDetections(confirmed));
    yield* put(loadObjects());
  }
}

function* resetMaxDetectionsHandler(
  _action: ActionType<typeof resetMaxDetections>,
) {
  yield* put(setMaxDetections(OBJECT_DETECTION_DEFAULT_MAX_DETECTIONS));
}

function* loadObjectDetectionModelHandler() {
  const projectId = yield* select(activeProjectIdSelector);

  yield* call(loadModelHandler, {
    projectId,
    loadApiCall: apiLoadObjectDetectionModel,
    messages: MODEL_MESSAGES,
    toolId: ImageTool.ObjectDetection,
    modelLoadStartAction: loadObjectDetectionModelStart,
    modelLoadSuccessAction: loadObjectDetectionModelSuccess,
    modelLoadErrorAction: loadObjectDetectionModelError,
    toolName: ImageToolTitleMap[ImageTool.ObjectDetection],
  });
}

function* objectDetectionModelLoadedHandler(
  action: PayloadAction<ModelChangePayload>,
) {
  const { status, progress, id } = action.payload;

  yield* call(modelLoadedHandler, {
    id,
    modelIdSelector: objectDetectionModelIdSelector,
    modelLoadedSelector: objectDetectionModelLoadedSelector,
    progress,
    status,
    updateAction: updateObjectDetectionModel,
    updateModelIndicator: updateObjectDetectionModelShowDot,
  });
}

function* objectDetectionModelUpdatedHandler(
  action: PayloadAction<ModelChangePayload>,
) {
  const { status, progress, id } = action.payload;

  yield* put(
    updateObjectDetectionModel({
      status,
      progress,
      id,
    }),
  );
}

function* updateObjectDetectionModelHandler(
  action: ActionType<typeof updateObjectDetectionModel>,
) {
  const { status, progress, id } = action.payload;

  yield* call(updateModelHandler, {
    id,
    loadAction: loadObjectDetectionModel,
    progress,
    status,
    successAction: updateObjectDetectionModelSuccess,
  });
}

function* resetProjectHandler() {
  yield* put(resetData());
  yield* put(resetModel());
}

function* setActiveToolHandler() {
  yield* put(resetData());
}

export function* objectDetectionSaga() {
  yield* debounce(1000, loadObjects, loadObjectsHandler);
  yield* takeEvery([setConfidence, resetConfidence], setConfidenceHandler);
  yield* takeEvery([setActiveTool, resetActiveTool], setActiveToolHandler);
  yield* takeEvery(addLabel, addLabelHandler);
  yield* takeEvery(addObjectDetectionLabels, addObjectDetectionLabelsHandler);
  yield* takeEvery(resetProject, resetProjectHandler);
  yield* takeEvery(
    createModelChangePattern(OBJECT_DETECTION_FAMILY_NAME, MODEL_LOADED),
    objectDetectionModelLoadedHandler,
  );
  yield* takeEvery(
    createModelChangePattern(OBJECT_DETECTION_FAMILY_NAME, MODEL_UPDATED),
    objectDetectionModelUpdatedHandler,
  );
  yield* takeEvery(
    updateObjectDetectionModel,
    updateObjectDetectionModelHandler,
  );
  yield* takeLatest(setMaxDetections, setMaxDetectionsHandler);
  yield* takeLatest(resetMaxDetections, resetMaxDetectionsHandler);
  yield* takeLatest(adjustMaxDetections, adjustMaxDetectionsHandler);
  yield* takeLatest(adjustConfidence, adjustConfidenceHandler);
  yield* takeEvery(loadObjectDetectionModel, loadObjectDetectionModelHandler);
}
