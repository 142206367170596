/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ExportSession = {
  /**
   * Export session ID
   */
  sessionId?: string;
  /**
   * Project ID
   */
  projectId?: string;
  /**
   * Export session name
   */
  exportName?: string;
  /**
   * Export format
   */
  format?: ExportSession.format;
  /**
   * Export job status
   */
  status?: ExportSession.status;
  /**
   * Signed URLs for publicly accessing images/videos
   */
  signUrls?: boolean;
  /**
   * Username of the export creator
   */
  username?: string | null;
  /**
   * Export Job ID
   */
  exportJobId?: string;
  createDate?: string;
};

export namespace ExportSession {

  /**
   * Export format
   */
  export enum format {
    JSON_V1_1 = 'json_v1.1',
    SEMANTIC_PNG = 'semantic_png',
    JSON_COCO = 'json_coco',
    PASCAL = 'pascal',
    IMAGES = 'images',
    VIDEOS = 'videos',
  }

  /**
   * Export job status
   */
  export enum status {
    NEW = 'NEW',
    FAILED_TO_EXPORT = 'FAILED_TO_EXPORT',
    FAILED = 'FAILED',
    EXPORTED = 'EXPORTED',
  }


}

