import { ApiTagGroup } from '../schemas/tagGroup';
import { TagGroupType } from '../constants/tagGroup';

export const tagGroupTypeLabels: Record<TagGroupType, string> = {
  [TagGroupType.Single]: 'Single Choice',
  [TagGroupType.Multi]: 'Multiple Choice',
};

export type TagGroup = ApiTagGroup;

export const DEFAULT_GROUP_ID = 'default';
export const DEFAULT_GROUP_NAME = 'Default tag group';
export const DEFAULT_GROUP_TYPE = TagGroupType.Multi;
