import { createSelector } from '@reduxjs/toolkit';
import groupBy from 'lodash/groupBy';

import { imageViewSelector } from '../../imageView.selectors';
import { adapter } from './imageTagsPrediction.slice';
import { imageViewActiveTagsSelector } from '../../project/imageTags/imageTags.selectors';
import { sortObjectsAlphaNumeric } from '../../imageView.util';
import { modelStatusChecks } from '../models/modelStatus.constants';
import { tagClassesMapSelector } from '../../../../project/annotationTaxonomy/tagClasses/tagClasses.selectors';
import { PredictedImageTag } from '../../../../../../api/domainModels/imageTag';
import { DEFAULT_GROUP_ID } from '../../../../../../api/domainModels/tagGroup';
import { imageViewIsImageTagsPredictionToggledSelector } from '../../sidebar/imageTagsPredictionToggle/imageTagsPredictionToggle.selectors';

const imageTagsPredictionSelector = createSelector(
  imageViewSelector,
  (imageView) => imageView.tools.imageTagsPrediction,
);
const imageTagsPredictionDataSelector = createSelector(
  imageTagsPredictionSelector,
  (imageTagsPrediction) => imageTagsPrediction.data,
);
export const imageTagsPredictionThresholdSelector = createSelector(
  imageTagsPredictionSelector,
  (imageTagsPrediction) => imageTagsPrediction.threshold,
);
const imageTagsPredictionStateSelectors = adapter.getSelectors(
  imageTagsPredictionDataSelector,
);
const imageTagsPredictionSelectors =
  imageTagsPredictionStateSelectors.selectAll;

export const imageTagsPredictionDataLoadingStateSelector = createSelector(
  imageTagsPredictionDataSelector,
  (predictionData) => predictionData.loadingState,
);

export const imageTagsPredictionPredictedClassesSelector = createSelector(
  [
    imageTagsPredictionSelectors,
    tagClassesMapSelector,
    imageViewIsImageTagsPredictionToggledSelector,
  ],
  (imageTagsPredictionItems, tagClasses, toggled) => {
    const tagsMappedWithName = imageTagsPredictionItems.map(
      ({ classId, ...rest }) => ({
        ...rest,
        classId,
        name: tagClasses[classId]?.name || '',
        groupId: tagClasses[classId]?.groupId || DEFAULT_GROUP_ID, // fallback for ts satisfaction
      }),
    );

    return toggled
      ? sortObjectsAlphaNumeric<PredictedImageTag>('name', tagsMappedWithName)
      : [];
  },
);

export const imageTagsPredictionAvailablePredictedClassesSelector =
  createSelector(
    [imageTagsPredictionPredictedClassesSelector, imageViewActiveTagsSelector],
    (predictedTagsRaw, activeTags) => {
      const activeClassIds = activeTags.map((tag) => tag.classId);

      return predictedTagsRaw.filter(
        (tag) => !activeClassIds.includes(tag.classId),
      );
    },
  );

export const imageTagsGroupedPredictionClassesSelector = createSelector(
  [imageTagsPredictionAvailablePredictedClassesSelector],
  (predictedTags) => groupBy(predictedTags, 'groupId'),
);

export const imageTagsPredictionModelStatusSelector = createSelector(
  imageTagsPredictionSelector,
  (imageTagsPrediction) => imageTagsPrediction.model.status,
);

export const imageTagsPredictionModelIdSelector = createSelector(
  imageTagsPredictionSelector,
  (imageTagsPrediction) => imageTagsPrediction.model.id,
);

export const imageTagsPredictionModelAvailableSelector = createSelector(
  imageTagsPredictionModelIdSelector,
  (modelId) => typeof modelId === 'number',
);

export const imageTagsPredictionModelLoadedSelector = createSelector(
  [
    imageTagsPredictionModelAvailableSelector,
    imageTagsPredictionModelStatusSelector,
  ],
  (available, status) =>
    !!(available && status && modelStatusChecks.isLoaded(status)),
);
