import axios from 'axios';

import {
  ApiFrameImportJob,
  ApiFrameImportJobParams,
  ApiVideoUploadData,
} from '../schemas/frameImports';
import { apiWrapper } from '../utils';
import { NEW_API_URL } from '../../constants/paths';
import {
  ApiPaginated,
  headers,
  transformRequest,
  transformResponse,
} from '../helpers';

export const apiLoadFrameImportJobs = ({
  projectId,
  params,
}: {
  projectId: string;
  params?: {
    offset?: number;
    limit?: number;
  };
}) =>
  apiWrapper(
    axios.request<ApiPaginated<ApiFrameImportJob[]>>({
      method: 'get',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${projectId}/frame_imports`,
      params,
    }),
  );

export const apiStartFrameImportJob = ({
  projectId,
  params,
}: {
  projectId: string;
  params: ApiFrameImportJobParams;
}) =>
  apiWrapper(
    axios.request<ApiFrameImportJob>({
      method: 'post',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${projectId}/frame_imports`,
      data: params,
    }),
  );

export const apiRetryFrameImportJob = ({
  projectId,
  frameImportId,
}: {
  projectId: string;
  frameImportId: string;
}) =>
  apiWrapper(
    axios.request<ApiFrameImportJob>({
      method: 'post',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${projectId}/frame_imports/${frameImportId}/retry`,
    }),
  );

export const apiLoadVideoUploadData = ({
  projectId,
  data,
}: {
  projectId: string;
  data: {
    count: number;
  };
}) =>
  apiWrapper(
    axios.request<ApiVideoUploadData>({
      method: 'post',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${projectId}/video-uploads`,
      data,
    }),
  );
