import { call, put, select, takeEvery, takeLatest } from 'typed-redux-saga';

import {
  ImageLabel,
  imageLabelDataMapper,
} from '../../../../../api/domainModels/imageLabel';
import {
  apiLoadProjectImageLabels,
  apiLoadProjectLabel,
} from '../../../../../api/requests/imageLabels';
import { getErrorMessage } from '../../../../../api/utils';
import { handleError } from '../../../commonFeatures/errorHandler/errorHandler.actions';
import { activeProjectIdSelector } from '../../../project/project.selectors';
import { updateErrorFinderLabelSuccess } from '../../errorFinder/labels/labels.slice';
import { loadAll } from '../../../../utils/api';
import { imageViewImageIdSelector } from '../currentImage/currentImage.selectors';
import { shouldPerformPrediction } from '../tools/labelClassPrediction/labelClassPrediction.slice';
import { imageLabelsMapSelector } from './labels.selectors';
import { addLabels, initializeLabels, upsertLabel } from './labels.slice';
import {
  initializeLabelsFailure,
  initializeLabelsStart,
  initializeLabelsSuccess,
  syncLabelsFailure,
} from './labelSync/labelSync.slice';
import { setSelectedLabelsIds } from './selectedLabels/selectedLabels.slice';
import { ErrorFinderAction } from '../../../../../api/constants/consensusScoring';

function* listHandler(action: ActionType<typeof initializeLabelsStart>) {
  const { labelIdToSet } = action.payload;

  try {
    const params = {
      projectId: yield* select(activeProjectIdSelector),
      imageId: yield* select(imageViewImageIdSelector),
    };
    const labels = yield* loadAll({
      params,
      apiHelper: apiLoadProjectImageLabels,
      fromBackend: imageLabelDataMapper.fromBackend,
    });
    const isLabelToSetValid =
      labelIdToSet && !!labels.find(({ id }) => id === labelIdToSet);

    yield* put(initializeLabels(labels));

    const labelsMap = yield* select(imageLabelsMapSelector);

    yield* put(initializeLabelsSuccess(labelsMap));

    if (labelIdToSet && isLabelToSetValid) {
      yield* put(setSelectedLabelsIds([labelIdToSet]));
    }
  } catch (error) {
    yield* put(
      initializeLabelsFailure(
        getErrorMessage(error, 'Could not load image labels'),
      ),
    );
  }
}

function* addHandler(action: ActionType<typeof addLabels>) {
  yield* put(shouldPerformPrediction(action.payload));
}

function* updateErrorFinderLabelHandler(
  action: ActionType<typeof updateErrorFinderLabelSuccess>,
) {
  const { labelId, efAction } = action.payload.changes;
  const projectId = yield* select(activeProjectIdSelector);
  const imageId = yield* select(imageViewImageIdSelector);
  const labels = yield* select(imageLabelsMapSelector);

  if (!labelId || !imageId || !projectId) return;

  let label: ImageLabel | undefined;

  // can't fetch a shallowly deleted label
  if (efAction !== ErrorFinderAction.DeleteLabel) {
    try {
      const { data } = yield* call(apiLoadProjectLabel, {
        projectId,
        labelId,
      });

      label = imageLabelDataMapper.fromBackend(data);
    } catch (error) {
      const message = getErrorMessage(error, 'Sync went wrong');

      yield* put(syncLabelsFailure(message));

      yield* put(handleError({ error, message }));

      return;
    }
  }

  if (efAction !== ErrorFinderAction.AddLabel) {
    // if action differs from ADD_LABEL run a check first if labels exists in image
    // for cases where prediction is accepted after label was deleted
    const deletedLabel: ImageLabel | undefined = labels[labelId];

    if (!deletedLabel) return;

    if (efAction === ErrorFinderAction.DeleteLabel) {
      label = { ...deletedLabel, isDeleted: true };
    }
  }
  if (label) yield* put(upsertLabel(label));
}

export function* labelsSaga() {
  // keep takeLatest here to avoid wrong labels on image switch
  yield* takeLatest(initializeLabelsStart, listHandler);
  yield* takeEvery(addLabels, addHandler);
  yield* takeEvery(
    updateErrorFinderLabelSuccess,
    updateErrorFinderLabelHandler,
  );
}
