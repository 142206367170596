import { put, select, take, call, takeEvery } from 'typed-redux-saga';

import {
  applyMultipleFilters,
  FilterType,
  setFilters,
} from '../imageGallery/bottomBar/filters/filters.slice';
import { loadErrorFinderLabels } from '../../errorFinder/labels/labels.slice';
import { imageViewImageGalleryFiltersCSRunIdSelector } from '../imageGallery/bottomBar/filters/filters.selectors';
import { consensusScoringRunTypeSelector } from './consensusScoring.selectors';
import {
  loadCSData,
  setConsensusScoringEnabled,
} from './consensusScoring.slice';
import { setSelectedLabelsIds } from '../labels/selectedLabels/selectedLabels.slice';
import {
  loadRunsFailure,
  loadRunsSuccess,
} from '../../errorFinder/runs/runs.slice';
import { runsListLoadingStateSelector } from '../../errorFinder/runs/runs.selectors';
import { statusChecks } from '../../../../../constants/status';
import { imageViewImageIdSelector } from '../currentImage/currentImage.selectors';
import { getRunLockedResultsState } from '../../errorFinder/runs/lockedResults/lockedResults.slice';
import { semanticReviewMaxNumberOfErrorsSelector } from '../../errorFinder/labels/semanticReview.selectors';
import { RunType } from '../../../../../api/constants/consensusScoring';

function* setFiltersHandler(action: ActionType<typeof setFilters>) {
  if (
    action.payload.filter === FilterType.CSEFRunId ||
    action.payload.filter === FilterType.CSUserAction
  ) {
    yield* call(requestConsensusScoringData);
    yield* put(setConsensusScoringEnabled(true));
  }
}

function* requestConsensusScoringData() {
  const runId = yield* select(imageViewImageGalleryFiltersCSRunIdSelector);

  if (runId) {
    yield* put(getRunLockedResultsState(runId));
  }
  yield* put(loadCSData());
}

function* loadCSDataHandler() {
  const runId = yield* select(imageViewImageGalleryFiltersCSRunIdSelector);
  const runType = yield* select(consensusScoringRunTypeSelector);
  const imageId = yield* select(imageViewImageIdSelector);
  const maxNumberOfErrors = yield* select(
    semanticReviewMaxNumberOfErrorsSelector,
  );

  if (runId && imageId) {
    const params = {
      runId,
      runType,
      imageId,
      customLimit: 10000,
      customFilters: {
        ...(runType === RunType.SemanticReview ? { maxNumberOfErrors } : {}),
      },
    };

    yield* put(loadErrorFinderLabels(params));
  }
  yield* put(setSelectedLabelsIds([]));
}

function* applyMultipleFiltersHandler(
  action: ActionType<typeof applyMultipleFilters>,
) {
  if (
    'csEfRunId' in action.payload &&
    typeof action.payload.csEfRunId === 'string'
  ) {
    const runsLoadingState = yield* select(runsListLoadingStateSelector);

    if (
      statusChecks.isInitial(runsLoadingState.status) ||
      statusChecks.isInProgress(runsLoadingState.status)
    ) {
      const result = yield* take([loadRunsSuccess, loadRunsFailure]);

      if (result.type === loadRunsFailure.type) {
        return;
      }
    }
    yield* call(requestConsensusScoringData);
    yield* put(setConsensusScoringEnabled(true));
  }
}

export function* consensusScoringSaga() {
  yield* takeEvery(setFilters, setFiltersHandler);
  yield* takeEvery(loadCSData, loadCSDataHandler);
  yield* takeEvery(applyMultipleFilters, applyMultipleFiltersHandler);
}
