import { put, select, takeEvery, call } from 'typed-redux-saga';
import { push } from 'connected-react-router';

import {
  apiArchiveProject,
  apiDuplicateProject,
  apiLoadProject,
  apiLoadProjects,
  apiMoveProjectToWorkspace,
} from '../../../../api/requests/project';
import {
  apiLoadWorkspaces,
  apiArchiveWorkspace,
} from '../../../../api/requests/workspace';
import {
  loadWorkspaces,
  archiveWorkspace,
  loadWorkspacesError,
  loadWorkspacesSuccess,
  archiveWorkspaceSuccess,
  archiveWorkspaceError,
} from './workspaces/workspaces.slice';
import { createWorkspaceSaga } from './createWorkspace/createWorkspace.saga';
import { getProjectById } from '../../../utils/backendParams';
import { getErrorMessage } from '../../../../api/utils';
import {
  loadProjectsSuccess,
  loadProjectsError,
  loadProjects,
  archiveProject,
  archiveProjectSuccess,
  archiveProjectError,
  addProjectToList,
  duplicateProject,
  moveProjectToWorkspace,
  updateProjectInList,
  projectUpdated,
} from './projects/projects.slice';
import { navigateToProjectList } from './projectList.actions';
import { handleError } from '../../commonFeatures/errorHandler/errorHandler.actions';
import { hideModals } from '../../ui/modals/modals.slice';
import {
  projectByIdSelector,
  projectListDataSelector,
} from './projects/projects.selectors';
import { workspacesListWorkspaceNameSelector } from './workspaces/workspaces.selectors';
import { enqueueNotification } from '../../ui/stackNotifications/stackNotifications.slice';
import { notifyJobProgress } from '../../ws/ws.actions';
import { NOTIFICATION_SENT } from '../../ws/ws.constants';
import { WebsocketNotificationPayload } from '../../../../api/domainModels/websocketTypes';
import { Notification } from '../../../../api/codegen';

function* loadWorkspacesHandler() {
  try {
    const response = yield* call(apiLoadWorkspaces);

    yield* put(loadWorkspacesSuccess(response.data));
  } catch (error: any) {
    yield* put(
      enqueueNotification({
        message: getErrorMessage(error, 'Not able to fetch workspaces'),
        options: { variant: 'error', allowOutsideOfEditor: true, error },
      }),
    );
    yield* put(
      loadWorkspacesError(
        getErrorMessage(error, 'Not able to load workspaces'),
      ),
    );
  }
}

function* loadProjectsHandler() {
  try {
    const { data } = yield* call(apiLoadProjects);

    yield* put(loadProjectsSuccess(data));
  } catch (error) {
    yield* put(
      loadProjectsError(getErrorMessage(error, 'Not able to fetch projects')),
    );
  }
}

function* archiveProjectHandler(action: ActionType<typeof archiveProject>) {
  try {
    const projects = yield* select(projectListDataSelector);
    const project = getProjectById(projects, action.payload);

    if (!project) {
      return;
    }

    yield* call(apiArchiveProject, { projectId: action.payload });
    yield* put(archiveProjectSuccess(action.payload));
    yield* put(hideModals());
    yield* put(
      enqueueNotification({
        message: `Project ${project.projectName} has been successfully archived`,
        options: {
          variant: 'success',
          allowOutsideOfEditor: true,
        },
      }),
    );
  } catch (error) {
    yield* put(
      archiveProjectError(
        getErrorMessage(error, 'Not able to delete the project'),
      ),
    );
  }
}

function* duplicateProjectHandler(action: ActionType<typeof duplicateProject>) {
  const { projectId, copyLabels = true } = action.payload;
  const selectedProject = yield* select((state: RootState) =>
    projectByIdSelector(state, projectId),
  );

  if (!selectedProject) {
    return;
  }

  const data = {
    name: `Copy of ${selectedProject.projectName}`,
    workspaceId: selectedProject.workspaceId,
    copyLabels,
  };

  try {
    const response = yield* call(apiDuplicateProject, { projectId }, data);

    yield* put(
      notifyJobProgress({
        id: response.data.id,
        progress: 0,
        projectId: response.data.projectId,
        meta: {
          sourceProjectId: projectId,
        },
      }),
    );
  } catch (error) {
    yield* put(
      handleError({
        error,
        message: 'Not able to duplicate the project',
        allowOutsideOfEditor: true,
      }),
    );
  }
}

function* archiveWorkspaceHandler(action: ActionType<typeof archiveWorkspace>) {
  try {
    yield* call(apiArchiveWorkspace, { workspaceId: action.payload });
    yield* put(archiveWorkspaceSuccess(action.payload));
    yield* put(hideModals());
    yield* put(push('/workspaces'));
  } catch (error) {
    yield* put(
      archiveWorkspaceError(
        getErrorMessage(error, 'Not able to delete the workspace'),
      ),
    );
  }
}

function* navigateToProjectListHandler() {
  yield* put(push('/workspaces'));
}

function* moveProjectToWorkspaceHandler(
  action: ActionType<typeof moveProjectToWorkspace>,
) {
  const { projectId, workspaceId } = action.payload;

  try {
    const moveResponse = yield* call(
      apiMoveProjectToWorkspace,
      { projectId },
      { workspaceId },
    );
    const response = yield* call(apiLoadProject, {
      projectId: moveResponse.data.projectId,
    });
    const project = response.data;
    const workspaceName = yield* select((state) =>
      workspacesListWorkspaceNameSelector(state, workspaceId),
    );

    yield* put(
      updateProjectInList({
        id: project.projectId,
        changes: project,
      }),
    );
    yield* put(
      enqueueNotification({
        message: `Project ${project.projectName} has been successfully moved to workspace ${workspaceName}`,
        options: {
          variant: 'success',
          allowOutsideOfEditor: true,
        },
      }),
    );
    yield* put(hideModals());
  } catch (error) {
    handleError({
      error,
      message: 'Not able to move the project',
      allowOutsideOfEditor: true,
    });
  }
}

function* projectCopiedHandler(projectId: string) {
  try {
    const { data } = yield* call(apiLoadProject, { projectId });

    yield* put(addProjectToList(data));
  } catch (error) {
    handleError({
      error,
      message: 'Not able to copy the project',
      allowOutsideOfEditor: true,
    });
  }
}

function* addNotificationFromWebsocketHandler({
  payload: notification,
}: WebsocketNotificationPayload & { type: string }) {
  if (
    notification.subject ===
      Notification.subject.NOTIFICATION_SUBJECT_PROJECT_COPIED &&
    notification.projectId
  ) {
    yield* call(projectCopiedHandler, notification.projectId);
  }
}

function* projectUpdatedHandler(action: ActionType<typeof projectUpdated>) {
  const { data } = yield* call(apiLoadProject, { projectId: action.payload });

  yield* put(
    updateProjectInList({
      id: data.projectId,
      changes: data,
    }),
  );
}

function* projectListSaga() {
  yield* takeEvery(loadProjects, loadProjectsHandler);
  yield* takeEvery(archiveProject, archiveProjectHandler);
  yield* takeEvery(duplicateProject, duplicateProjectHandler);
  yield* takeEvery(moveProjectToWorkspace, moveProjectToWorkspaceHandler);
  yield* takeEvery(archiveWorkspace, archiveWorkspaceHandler);
  yield* takeEvery(navigateToProjectList, navigateToProjectListHandler);
  yield* takeEvery(loadWorkspaces, loadWorkspacesHandler);
  yield* takeEvery(NOTIFICATION_SENT, addNotificationFromWebsocketHandler);
  yield* takeEvery(projectUpdated, projectUpdatedHandler);
}

export const projectListSagas = [createWorkspaceSaga, projectListSaga];
