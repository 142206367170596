import { createSelector } from '@reduxjs/toolkit';

import { componentsAdapter, parametersAdapter } from './metrics.slice';
import { modelPlaygroundSelector } from '../../modelPlayground.selectors';

export const experimentMetricsStateSelector = createSelector(
  modelPlaygroundSelector,
  (modelPlayground) => modelPlayground.activeExperiment.metrics,
);

export const experimentMetricsComponentsStateSelector = createSelector(
  experimentMetricsStateSelector,
  (state) => state.components,
);

const componentsSelectors = componentsAdapter.getSelectors(
  experimentMetricsComponentsStateSelector,
);
export const experimentMetricsDataSelector = componentsSelectors.selectAll;
export const experimentMetricsDataCountSelector =
  componentsSelectors.selectTotal;

export const selectedMetricsSelector = createSelector(
  experimentMetricsDataSelector,
  (metrics) => metrics.filter((metric) => metric.selected || metric.frozen),
);
export const selectedMetricsIdsSelector = createSelector(
  selectedMetricsSelector,
  (selectedMetrics) => selectedMetrics.map((metric) => metric.id),
);
export const selectedMetricsComponentIdsSelector = createSelector(
  selectedMetricsSelector,
  (selectedMetrics) => selectedMetrics.map((metric) => metric.componentId),
);
export const experimentMetricsLoadingStateSelector = createSelector(
  experimentMetricsComponentsStateSelector,
  (state) => state.loadingState,
);
export const experimentMetricsUpdateLoadingStateSelector = createSelector(
  experimentMetricsComponentsStateSelector,
  (state) => state.updateLoadingState,
);

export const experimentMetricsParametersStateSelector = createSelector(
  experimentMetricsStateSelector,
  (state) => state.parameters,
);

const parametersSelectors = parametersAdapter.getSelectors(
  experimentMetricsParametersStateSelector,
);
export const metricParametersByIdSelector = parametersSelectors.selectById;
export const metricParametersMapSelector = parametersSelectors.selectEntities;

export const metricParametersUpdateLoadingStateSelector = createSelector(
  experimentMetricsStateSelector,
  (state) => state.parametersUpdateLoadingState,
);
export const metricParameterByIdSelector = (
  state: RootState,
  componentId: string,
  id: string,
) => {
  const parameters = metricParametersByIdSelector(state, componentId);
  if (!parameters || parameters.data.length === 0) return null;

  return parameters.data.find((parameter) => parameter.id === id);
};
