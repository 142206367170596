import { ModelFamily } from '../../../../../../api/constants/modelFamily';

export const MODEL_MESSAGES = {
  availability:
    'Semantic Segmentation (S) is now available. You can select it in the toolbar',
  progress: 'Loading semantic segmentation model',
  error: 'Not able load semantic segmentation model',
};
export const SEMANTIC_SEGMENTATION_FAMILY_NAME = ModelFamily.SemanticSegmentor;
export const SEMANTIC_SEGMENTATION_DEFAULT_Z_INDEX = 0;
export const SEMANTIC_SEGMENTATION_DEFAULT_MIN_SIZE = 0;
