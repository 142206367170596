import { createSelector } from '@reduxjs/toolkit';

import { AdvancedOptionType } from '../../../../../api/domainModels/advancedOptions';
import { adapter } from './advancedOptions.slice';
import { editedProjectSelector } from '../editedProject.selectors';

const advancedOptionsStateSelector = createSelector(
  editedProjectSelector,
  (editedProject) => editedProject.advancedOptions,
);
const selectors = adapter.getSelectors(advancedOptionsStateSelector);

const advancedOptionsSelector = selectors.selectAll;

export const advancedOptionsDefaultLabelTypeSelector = createSelector(
  advancedOptionsSelector,
  (advancedOptions) =>
    advancedOptions.find(
      (option) => !!option[AdvancedOptionType.AutomatedToolModeFormKey],
    )?.[AdvancedOptionType.AutomatedToolModeFormKey] as string,
);

export const advancedOptionsModalUseIESelector = createSelector(
  advancedOptionsSelector,
  (advancedOptions) =>
    advancedOptions.find(
      (option) => option[AdvancedOptionType.ModelUseIE] !== undefined,
    )?.[AdvancedOptionType.ModelUseIE] as boolean,
);

export const advancedOptionsLoadingStateSelector = createSelector(
  advancedOptionsStateSelector,
  (state) => state.loadingState,
);

export const advancedOptionsSaveLoadingStateSelector = createSelector(
  advancedOptionsStateSelector,
  (state) => state.saveLoadingState,
);
