/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SetupIntentStatus = {
  status?: SetupIntentStatus.status;
};

export namespace SetupIntentStatus {

  export enum status {
    UNKNOWN = 'unknown',
    IN_PROGRESS = 'in_progress',
    FAILED = 'failed',
    SUCCEEDED = 'succeeded',
  }


}

