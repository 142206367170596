import { call, put, select, takeEvery } from 'typed-redux-saga';

import { getErrorMessage } from '../../../../../api/utils';
import {
  loadProjectCollaborators,
  loadProjectCollaboratorsSuccess,
  loadProjectCollaboratorsFailure,
  addProjectCollaborator,
  addProjectCollaboratorSuccess,
  addProjectCollaboratorFailure,
  deleteProjectCollaborator,
  deleteProjectCollaboratorSuccess,
  deleteProjectCollaboratorFailure,
  updateProjectCollaborator,
  updateProjectCollaboratorSuccess,
  updateProjectCollaboratorFailure,
} from './collaborators.slice';
import { editedProjectDataSelector } from '../editedProject.selectors';
import { hideModals } from '../../../ui/modals/modals.slice';
import { enqueueNotification } from '../../../ui/stackNotifications/stackNotifications.slice';
import {
  apiLoadProjectCollaborators,
  apiAddProjectCollaborator,
  apiDeleteProjectCollaborator,
  apiUpdateProjectCollaborator,
} from '../../../../../api/requests/projectCollaboration';
import { projectCollaboratorDataMapper } from '../../../../../api/domainModels/projectCollaborator';
import { loadWorkspaceCollaborators } from '../../managedWorkspace/collaborators/collaborators.slice';

function* loadCollaboratorsHandler(
  action: ActionType<
    | typeof loadProjectCollaborators
    | typeof deleteProjectCollaboratorSuccess
    | typeof addProjectCollaboratorSuccess
    | typeof updateProjectCollaboratorSuccess
  >,
) {
  const projectId = action.payload;

  try {
    const response = yield* call(apiLoadProjectCollaborators, projectId);
    const data = response.data.map(projectCollaboratorDataMapper.fromBackend);
    yield* put(loadProjectCollaboratorsSuccess(data));

    const project = yield* select(editedProjectDataSelector);

    if (project.data && !project.data.isPro) {
      yield* put(loadWorkspaceCollaborators(project.data.workspaceId));
    }
  } catch (error) {
    yield* put(
      loadProjectCollaboratorsFailure(
        getErrorMessage(error, 'Not able to fetch users'),
      ),
    );
  }
}

export function* addProjectCollaboratorHandler(
  action: ActionType<typeof addProjectCollaborator>,
) {
  const { projectId, params } = action.payload;

  try {
    yield* call(apiAddProjectCollaborator, projectId, params);
    yield* put(addProjectCollaboratorSuccess(projectId));
    yield* put(
      enqueueNotification({
        message: `An invite has been sent to ${params.email}`,
        options: {
          variant: 'success',
          allowOutsideOfEditor: true,
        },
      }),
    );
    put(hideModals());
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to add a collaborator');

    yield* put(addProjectCollaboratorFailure(message));
    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          error,
          allowOutsideOfEditor: true,
        },
      }),
    );
  }
}

function* deleteProjectCollaboratorHandler(
  action: ActionType<typeof deleteProjectCollaborator>,
) {
  const { projectId, collaboratorId } = action.payload;

  try {
    yield* call(apiDeleteProjectCollaborator, projectId, collaboratorId);
    yield* put(deleteProjectCollaboratorSuccess(projectId));
    yield* put(hideModals());
  } catch (error) {
    yield* put(
      deleteProjectCollaboratorFailure(
        getErrorMessage(error, 'Not able to delete this user'),
      ),
    );
  }
}

function* updateProjectCollaboratorHandler(
  action: ActionType<typeof updateProjectCollaborator>,
) {
  const { projectId, collaboratorId, params } = action.payload;

  try {
    yield* call(
      apiUpdateProjectCollaborator,
      projectId,
      collaboratorId,
      params,
    );
    yield* put(updateProjectCollaboratorSuccess(projectId));
    yield* put(hideModals());
  } catch (error) {
    yield* put(
      updateProjectCollaboratorFailure(
        getErrorMessage(error, 'Not able to update this user'),
      ),
    );
  }
}

export function* editedProjectCollaboratorsSaga() {
  yield* takeEvery(
    [
      loadProjectCollaborators,
      deleteProjectCollaboratorSuccess,
      addProjectCollaboratorSuccess,
      updateProjectCollaboratorSuccess,
    ],
    loadCollaboratorsHandler,
  );
  yield* takeEvery(addProjectCollaborator, addProjectCollaboratorHandler);
  yield* takeEvery(deleteProjectCollaborator, deleteProjectCollaboratorHandler);
  yield* takeEvery(updateProjectCollaborator, updateProjectCollaboratorHandler);
}
