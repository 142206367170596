import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { ImageLabel } from '../../../../../../api/domainModels/imageLabel';

export const adapter = createEntityAdapter<string>({
  selectId: (id) => id,
  sortComparer: (a, b) => (a > b ? 1 : -1),
});
const { actions, reducer: selectedLabelsReducer } = createSlice({
  name: 'imageViewSelectedLabels',
  initialState: adapter.getInitialState({
    contextMenu: {
      visible: false,
      top: 0,
      left: 0,
    },
  }),
  reducers: {
    convertShape(
      _state,
      _action: PayloadAction<{
        labels: ImageLabel[];
        projectId: string;
        imageId: string;
      }>,
    ) {},
    mergePolygonsIntoSingleMask(
      _state,
      _action: PayloadAction<{
        labels: ImageLabel[];
        projectId: string;
        imageId: string;
      }>,
    ) {},
    mergeMasksIntoSingleMask(
      _state,
      _action: PayloadAction<{
        labels: ImageLabel[];
        projectId: string;
        imageId: string;
      }>,
    ) {},
    deselectLabels(state, action: PayloadAction<string[]>) {
      adapter.removeMany(state, action.payload);
    },
    setSelectedLabelsIds(state, action: PayloadAction<string[]>) {
      if (!state.ids.length && !action.payload.length) {
        return; // disable needless rerenders if both states are empty
      }
      adapter.setAll(state, action.payload);
    },
    toggleLabelSelection(state, action: PayloadAction<string>) {
      if (state.entities[action.payload]) {
        adapter.removeOne(state, action.payload);
      } else {
        adapter.addOne(state, action.payload);
      }
    },
    showContextMenu(
      state,
      action: PayloadAction<{ top: number; left: number }>,
    ) {
      state.contextMenu = {
        ...action.payload,
        visible: true,
      };
    },
    hideContextMenu(state) {
      state.contextMenu.visible = false;
    },
    bringToFront(
      _state,
      _action: PayloadAction<{ projectId: string; imageId: string }>,
    ) {},
    sendToBack(
      _state,
      _action: PayloadAction<{ projectId: string; imageId: string }>,
    ) {},
    selectAllLabels() {},
    selectLastLabel() {},
    selectNextLabel() {},
    selectPreviousLabel() {},
    resetSelection(state) {
      adapter.removeAll(state);
    },
  },
});

export const {
  convertShape,
  mergePolygonsIntoSingleMask,
  mergeMasksIntoSingleMask,
  deselectLabels,
  setSelectedLabelsIds,
  toggleLabelSelection,
  showContextMenu,
  hideContextMenu,
  bringToFront,
  sendToBack,
  selectAllLabels,
  selectLastLabel,
  selectNextLabel,
  selectPreviousLabel,
  resetSelection,
} = actions;
export { selectedLabelsReducer };
