import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

export enum PermanentMessageTypes {
  Proxy = 'proxy',
  Maintenance = 'maintenance',
  Incident = 'incident',
}

type PermanentMessage = {
  id: PermanentMessageTypes;
  message: string;
};
export const adapter = createEntityAdapter<PermanentMessage>();
const initialState = adapter.getInitialState();

const { actions, reducer: permanentMessagesReducer } = createSlice({
  name: 'permanentMessages',
  initialState,
  reducers: {
    setPermanentMessage(state, action: PayloadAction<PermanentMessage>) {
      adapter.addOne(state, action.payload);
    },
  },
});

export { permanentMessagesReducer };
export const { setPermanentMessage } = actions;
