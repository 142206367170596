import { takeEvery } from 'typed-redux-saga';
import { getRecoil, setRecoil } from 'recoil-nexus';

import {
  updateVideoPredictionJobProgressFromWebsocket,
  updateVideoPredictionJobStatusFromWebsocket,
  updateVideoProcessedFromWebsocket,
  updateVideoSegmentPredictionsFromWebsocket,
} from './recoilWrapper.slice';
import {
  VideoAvailability,
  VideoSegmentPredictionJobStatus,
} from '../../../../../api/constants/videos';
import { nullableProjectIdState } from '../../../../../recoil/state/project';
import {
  nullableVideoIdState,
  videoAvailabilityState,
} from '../../../../../recoil/state/video';
import {
  pendingSegmentPredictionsState,
  segmentPredictionJobState,
} from '../../../../../recoil/state/segmentPrediction';

async function updateVideoProcessedFromWebsocketHandler(
  action: ActionType<typeof updateVideoProcessedFromWebsocket>,
) {
  const { projectId, videoId } = action.payload;

  const activeProjectId = getRecoil(nullableProjectIdState);
  const activeVideoId = getRecoil(nullableVideoIdState);

  if (
    !activeProjectId ||
    !activeVideoId ||
    activeProjectId !== projectId ||
    activeVideoId !== videoId
  ) {
    return;
  }

  setRecoil(videoAvailabilityState(activeVideoId), VideoAvailability.Unset);
}

async function updateVideoPredictionJobProgressFromWebsocketHandler(
  action: ActionType<typeof updateVideoPredictionJobProgressFromWebsocket>,
) {
  const { projectId, videoId, progress } = action.payload;

  const activeProjectId = getRecoil(nullableProjectIdState);
  const activeVideoId = getRecoil(nullableVideoIdState);

  if (
    !activeProjectId ||
    !activeVideoId ||
    activeProjectId !== projectId ||
    activeVideoId !== videoId
  ) {
    return;
  }

  setRecoil(segmentPredictionJobState, {
    status: VideoSegmentPredictionJobStatus.Running,
    progress: progress * 100,
  });
}

async function updateVideoPredictionJobStatusFromWebsocketHandler(
  action: ActionType<typeof updateVideoPredictionJobStatusFromWebsocket>,
) {
  const { projectId, videoId, status } = action.payload;

  const activeProjectId = getRecoil(nullableProjectIdState);
  const activeVideoId = getRecoil(nullableVideoIdState);
  const segmentPredoctionJob = getRecoil(segmentPredictionJobState);

  if (
    !activeProjectId ||
    !activeVideoId ||
    activeProjectId !== projectId ||
    activeVideoId !== videoId
  ) {
    return;
  }

  const progress =
    status === VideoSegmentPredictionJobStatus.Done
      ? 100
      : segmentPredoctionJob.progress;

  setRecoil(segmentPredictionJobState, {
    status,
    progress,
  });
}

async function updateVideoSegmentPredictionsFromWebsocketHandler(
  action: ActionType<typeof updateVideoSegmentPredictionsFromWebsocket>,
) {
  const { projectId, videoId, predictions } = action.payload;

  const activeProjectId = getRecoil(nullableProjectIdState);
  const activeVideoId = getRecoil(nullableVideoIdState);
  const prevPredictions = getRecoil(pendingSegmentPredictionsState);

  if (
    !activeProjectId ||
    !activeVideoId ||
    activeProjectId !== projectId ||
    activeVideoId !== videoId
  ) {
    return;
  }

  setRecoil(pendingSegmentPredictionsState, [
    ...prevPredictions,
    ...predictions,
  ]);
}

export function* videoRecoilWrapperSaga() {
  yield* takeEvery(
    updateVideoProcessedFromWebsocket,
    updateVideoProcessedFromWebsocketHandler,
  );
  yield* takeEvery(
    updateVideoPredictionJobProgressFromWebsocket,
    updateVideoPredictionJobProgressFromWebsocketHandler,
  );
  yield* takeEvery(
    updateVideoPredictionJobStatusFromWebsocket,
    updateVideoPredictionJobStatusFromWebsocketHandler,
  );
  yield* takeEvery(
    updateVideoSegmentPredictionsFromWebsocket,
    updateVideoSegmentPredictionsFromWebsocketHandler,
  );
}
