import { call, put, select, takeEvery } from 'typed-redux-saga';
import { PayloadAction } from '@reduxjs/toolkit';

import { activeProjectIdSelector } from '../../../../project/project.selectors';
import { apiLoadSegmentPredictorModel } from '../../../../../../api/requests/projectTools';
import {
  MODEL_MESSAGES,
  SEGMENT_PREDICTION_FAMILY_NAME,
} from './segmentPrediction.constats';
import { VideoTool, VideoToolTitleMap } from '../tools.constants';
import {
  resetModel,
  loadSegmentPredictorModel,
  loadSegmentPredictorModelError,
  loadSegmentPredictorModelStart,
  loadSegmentPredictorModelSuccess,
  updateSegmentPredictorModel,
  updateSegmentPredictorModelSuccess,
  updateSegmentPredictorModelShowDot,
} from './segmentPrediction.slice';
import {
  ModelChangePayload,
  createModelChangePattern,
} from '../../../imageView/tools/models/models.constants';
import {
  loadModelHandler,
  modelLoadedHandler,
  updateModelHandler,
} from '../../../imageView/tools/models/models.saga';
import {
  segmentPredictorModelIdSelector,
  segmentPredictorModelLoadedSelector,
} from './segmentPrediction.selectors';
import { resetProject } from '../../../imageView/imageView.actions';
import { MODEL_LOADED, MODEL_UPDATED } from '../../../../ws/ws.constants';

function* loadSegmentPredictorModelHandler() {
  const projectId = yield* select(activeProjectIdSelector);

  yield* call(loadModelHandler, {
    projectId,
    loadApiCall: apiLoadSegmentPredictorModel,
    messages: MODEL_MESSAGES,
    toolId: VideoTool.SegmentPrediction,
    modelLoadStartAction: loadSegmentPredictorModelStart,
    modelLoadSuccessAction: loadSegmentPredictorModelSuccess,
    modelLoadErrorAction: loadSegmentPredictorModelError,
    toolName: VideoToolTitleMap[VideoTool.SegmentPrediction],
  });
}

function* segmentModelLoadedHandler(action: PayloadAction<ModelChangePayload>) {
  const { status, progress, id } = action.payload;

  yield* call(modelLoadedHandler, {
    id,
    modelIdSelector: segmentPredictorModelIdSelector,
    modelLoadedSelector: segmentPredictorModelLoadedSelector,
    progress,
    status,
    updateAction: updateSegmentPredictorModel,
    updateModelIndicator: updateSegmentPredictorModelShowDot,
  });
}

function* segmentModelUpdatedHandler(
  action: PayloadAction<ModelChangePayload>,
) {
  const { status, progress, id } = action.payload;

  yield* put(
    updateSegmentPredictorModel({
      status,
      progress,
      id,
    }),
  );
}

function* updateSegmentPredictorModelHandler(
  action: ActionType<typeof updateSegmentPredictorModel>,
) {
  const { status, progress, id } = action.payload;

  yield* call(updateModelHandler, {
    id,
    loadAction: loadSegmentPredictorModel,
    progress,
    status,
    successAction: updateSegmentPredictorModelSuccess,
  });
}

function* resetProjectHandler() {
  yield* put(resetModel());
}

export function* segmentPredictionSaga() {
  yield* takeEvery(resetProject, resetProjectHandler);
  yield* takeEvery(
    createModelChangePattern(SEGMENT_PREDICTION_FAMILY_NAME, MODEL_LOADED),
    segmentModelLoadedHandler,
  );
  yield* takeEvery(
    createModelChangePattern(SEGMENT_PREDICTION_FAMILY_NAME, MODEL_UPDATED),
    segmentModelUpdatedHandler,
  );
  yield* takeEvery(
    updateSegmentPredictorModel,
    updateSegmentPredictorModelHandler,
  );
  yield* takeEvery(loadSegmentPredictorModel, loadSegmentPredictorModelHandler);
}
