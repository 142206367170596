import { put, select, takeEvery, call } from 'typed-redux-saga';

import { experimentComponentDataMapper } from '../../../../../../api/domainModels/modelPlayground';
import {
  apiGetExperimentComponents,
  apiUpdateExperimentComponent,
  apiGetComponentParameters,
  apiUpdateComponentParameter,
} from '../../../../../../api/requests/modelPlayground';
import { getErrorMessage } from '../../../../../../api/utils';
import { loadAll } from '../../../../../utils/api';
import { activeProjectIdSelector } from '../../../../project/project.selectors';
import {
  activeExperimentIdSelector,
  isActiveExperimentEditableSelector,
} from '../selectors';
import { parseParameterValue } from '../parameters.helpers';
import {
  selectedSchedulerIdSelector,
  schedulerParameterByIdSelector,
} from './schedulers.selectors';
import {
  loadSchedulers,
  loadSchedulersSuccess,
  loadSchedulersFailure,
  updateExperimentScheduler,
  updateExperimentSchedulerFailure,
  updateExperimentSchedulerSuccess,
  loadSchedulerParameters,
  loadSchedulerParametersSuccess,
  loadSchedulerParametersFailure,
  updateSchedulerParameter,
  updateSchedulerParameterSuccess,
  updateSchedulerParameterFailure,
} from './schedulers.slice';
import { enqueueNotification } from '../../../../ui/stackNotifications/stackNotifications.slice';
import { Component } from '../../../../../../api/constants/modelPlayground';

function* loadSchedulersHandler(_action: ActionType<typeof loadSchedulers>) {
  try {
    const projectId = yield* select(activeProjectIdSelector);
    const experimentId = yield* select(activeExperimentIdSelector);
    const items = yield* loadAll({
      apiHelper: apiGetExperimentComponents,
      params: {
        projectId,
        experimentId,
        componentType: Component.Scheduler,
      },
    });
    const isEditable = yield* select(isActiveExperimentEditableSelector);
    // as BE does not provide initially selected value for scheduler
    // we need to make sure it either exists (user selected it) or preselect it for them
    // we'll be working under assumption that scheduler should always be selected
    const hasSchedulerSelected = items.some((item) => item.selected);
    if (isEditable && items.length > 0 && !hasSchedulerSelected)
      yield* put(updateExperimentScheduler(items[0].id));
    yield* put(
      loadSchedulersSuccess(
        items.map(experimentComponentDataMapper.fromBackend),
      ),
    );
  } catch (e) {
    yield* put(
      loadSchedulersFailure(getErrorMessage(e, 'Not able to load schedulers')),
    );
  }
}

function* updateSchedulerHandler(
  action: ActionType<typeof updateExperimentScheduler>,
) {
  try {
    const projectId = yield* select(activeProjectIdSelector);
    const experimentId = yield* select(activeExperimentIdSelector);

    if (!experimentId) {
      return;
    }

    const componentType: Component = Component.Scheduler;
    const params = { projectId, experimentId, componentType };
    const data = { schedulerId: action.payload };
    const { data: response } = yield* call(() =>
      apiUpdateExperimentComponent(params, data),
    );
    yield* put(
      updateExperimentSchedulerSuccess(
        experimentComponentDataMapper.fromBackend(response),
      ),
    );
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to update scheduler');

    yield* put(updateExperimentSchedulerFailure(message));
    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
          refresh: false,
        },
      }),
    );
  }
}

function* loadParametersHandler(
  action: ActionType<typeof loadSchedulerParameters>,
) {
  try {
    const projectId = yield* select(activeProjectIdSelector);
    const experimentId = yield* select(activeExperimentIdSelector);
    const componentId = action.payload;
    const componentType: Component = Component.Scheduler;
    const items = yield* loadAll({
      apiHelper: apiGetComponentParameters,
      params: {
        projectId,
        experimentId,
        componentId,
        componentType,
      },
    });
    yield* put(loadSchedulerParametersSuccess(items));
  } catch (e) {
    yield* put(
      loadSchedulerParametersFailure(
        getErrorMessage(e, 'Not able to load parameters'),
      ),
    );
  }
}

function* updateParameterHandler(
  action: ActionType<typeof updateSchedulerParameter>,
) {
  try {
    const projectId = yield* select(activeProjectIdSelector);
    const experimentId = yield* select(activeExperimentIdSelector);
    const componentId = yield* select(selectedSchedulerIdSelector);

    if (!componentId || !experimentId) return;

    const componentType: Component = Component.Scheduler;
    const params = { projectId, experimentId, componentId, componentType };
    const { schedulerParameterId, value } = action.payload;
    const data = action.payload;
    const editedParameter = yield* select((state: RootState) =>
      schedulerParameterByIdSelector(state, schedulerParameterId),
    );
    if (editedParameter) {
      data.value = parseParameterValue(editedParameter, value);
    }
    const { data: response } = yield* call(
      apiUpdateComponentParameter,
      params,
      data,
    );
    yield* put(
      updateSchedulerParameterSuccess({
        changes: response,
        id: schedulerParameterId,
      }),
    );
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to update parameter');

    yield* put(updateSchedulerParameterFailure(message));
    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
          refresh: false,
        },
      }),
    );
  }
}

export function* schedulersSaga() {
  yield* takeEvery(loadSchedulers, loadSchedulersHandler);
  yield* takeEvery(updateExperimentScheduler, updateSchedulerHandler);
  yield* takeEvery(loadSchedulerParameters, loadParametersHandler);
  yield* takeEvery(updateSchedulerParameter, updateParameterHandler);
}
