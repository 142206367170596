import { AxiosResponse } from 'axios';
import { put, takeEvery, select } from 'typed-redux-saga';

import {
  apiLoadLabelClassConfidenceHist,
  apiLoadLabelClassIouHist,
} from '../../../../../../api/requests/consensusScoring';
import { getErrorMessage } from '../../../../../../api/utils';
import { activeProjectIdSelector } from '../../../../project/project.selectors';
import {
  activeRunIdSelector,
  activeRunTypeSelector,
} from '../../errorFinder.selectors';
import {
  loadHist,
  loadLabelClassConfidenceHist,
  loadLabelClassIouHist,
  loadHistFailure,
  loadHistSuccess,
  HistogramType,
  Histogram,
} from './histogram.slice';
import { enqueueNotification } from '../../../../ui/stackNotifications/stackNotifications.slice';
import { LabelClassConfidenceHist } from '../../../../../../api/domainModels/consensusScoring';

const ENDPOINT: Record<HistogramType, any> = {
  classConfidence: apiLoadLabelClassConfidenceHist,
  classIou: apiLoadLabelClassIouHist,
};

function* loadHandler(action: ActionType<typeof loadHist>) {
  const { labelClassId, type } = action.payload;

  try {
    const projectId = yield* select(activeProjectIdSelector);
    const runId = yield* select(activeRunIdSelector);
    const runType = yield* select(activeRunTypeSelector);

    if (!runType) return;

    const { data }: AxiosResponse<LabelClassConfidenceHist> = yield* ENDPOINT[
      type
    ]({
      projectId,
      runId,
      runType,
      labelClassId,
    });
    yield* put(loadHistSuccess({ id: type, data }));
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to load histogram data');

    yield* put(loadHistFailure({ id: type, message }));
    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
        },
      }),
    );
  }
}

function* loadConfidenceHandler(
  action: ActionType<typeof loadLabelClassConfidenceHist>,
) {
  const labelClassId = action.payload;
  yield* put(loadHist({ labelClassId, type: Histogram.Confidence }));
}

function* loadIouHandler(action: ActionType<typeof loadLabelClassIouHist>) {
  const labelClassId = action.payload;
  yield* put(loadHist({ labelClassId, type: Histogram.Iou }));
}

export function* runHistogramSaga() {
  yield* takeEvery(loadLabelClassConfidenceHist, loadConfidenceHandler);
  yield* takeEvery(loadLabelClassIouHist, loadIouHandler);
  yield* takeEvery(loadHist, loadHandler);
}
