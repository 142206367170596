import { createSelector } from '@reduxjs/toolkit';

import { imageViewSelector } from '../../imageView.selectors';
import { activeProjectIdSelector } from '../../../../project/project.selectors';

const imageViewImageAttributesPredictionToggleSelector = createSelector(
  imageViewSelector,
  (imageView) => imageView.sidebar.labelAttributesPredictionToggle,
);

export const imageViewIsAttributesPredictionToggledSelector = createSelector(
  activeProjectIdSelector,
  imageViewImageAttributesPredictionToggleSelector,
  (projectId, predictionState) => !!predictionState[projectId],
);
