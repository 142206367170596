import axios from 'axios';

import {
  ApiErrorFinderRun,
  ApiErrorLabel,
  ApiLabelConfidence,
  ApiRunErrorsPerClass,
  ApiRunConfusionMatrix,
  ApiLabelClassConfidenceHist,
  ApiErrorFinderRunCreditsEstimation,
  ApiErrorFinderRunLockedResults,
  ApiGetErrorFinderRunCreditsParams,
  ApiCreateErrorFinderRunParams,
  ApiLoadErrorFinderRunsParams,
  ApiLoadErrorFinderRunsResponse,
  ApiLoadErrorFinderRunParams,
  ApiDeleteErrorFinderRunParams,
  ApiGetErrorFinderRunLockedResultsParams,
  ApiUnlockErrorFinderRunParams,
  ApiLoadErrorLabelsParams,
  ApiLoadErrorLabelsResponse,
  ApiChangeErrorLabelActionParams,
  ApiLoadLabelConfidenceActionParams,
  ApiLoadRunErrorsPerClassParams,
  ApiLoadLabelClassConfidenceHistParams,
  ApiLoadLabelClassIouHistParams,
  ApiLoadRunConfusionMatrixParams,
  ApiErrorTagResult,
  ApiErrorAttributeResult,
  ApiChangeTagErrorResultActionParams,
  ApiChangeTagErrorResultActionData,
  ApiChangeAttributeErrorResultActionParams,
  ApiChangeAttributeErrorResultActionData,
  ApiLoadErrorLabelsBlobParams,
  ApiLoadErrorLabelsBlobResponse,
  ApiChangeErrorLabelActionData,
  ApiChangeSemanticErrorResultActionParams,
  ApiChangeSemanticErrorResultActionData,
  ApiChangeSemanticErrorResultResponse,
} from '../schemas/consensusScoring';
import { apiWrapper } from '../utils';
import { API_URL_V2, NEW_API_URL } from '../../constants/paths';
import {
  ApiDeleteResponse,
  headers,
  transformRequest,
  transformResponse,
} from '../helpers';
import { convertToBackendObject } from '../../redux/utils/backendParams';
import { RunType } from '../constants/consensusScoring';

export const runTypeApiPrefix: Record<RunType, string> = {
  [RunType.ClassReview]: 'cl',
  [RunType.ObjectReview]: 'od',
  [RunType.InstanceReview]: 'is',
  [RunType.TagReview]: 'tag', // Unused for now but for ts satisfaction
  [RunType.AttributeReview]: 'attribute', // Unused for now but for ts satisfaction
  [RunType.SemanticReview]: 'semantic', // Unused for now but for ts satisfaction
};

export const runTypeApiResults: Record<RunType, string> = {
  [RunType.ClassReview]: 'cl_labels',
  [RunType.ObjectReview]: 'od_labels',
  [RunType.InstanceReview]: 'is_labels',
  [RunType.TagReview]: 'tagger_images',
  [RunType.AttributeReview]: 'attr_labels',
  [RunType.SemanticReview]: 'semseg_labels',
};

export const apiGetErrorFinderRunCreditsEstimation = (
  params: ApiGetErrorFinderRunCreditsParams,
  data: Partial<ApiErrorFinderRun>,
) =>
  apiWrapper(
    axios.request<ApiErrorFinderRunCreditsEstimation>({
      method: 'post',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/ef_runs_credits`,
      data,
    }),
  );

export const apiCreateErrorFinderRun = (
  params: ApiCreateErrorFinderRunParams,
  data: Partial<ApiErrorFinderRun>,
) =>
  apiWrapper(
    axios.request<ApiErrorFinderRun>({
      method: 'post',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/ef_runs`,
      data,
    }),
  );

export const apiLoadErrorFinderRuns = (params: ApiLoadErrorFinderRunsParams) =>
  apiWrapper(
    axios.request<ApiLoadErrorFinderRunsResponse>({
      method: 'get',
      headers,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/ef_runs`,
      params: convertToBackendObject(params.params),
    }),
  );

export const apiLoadErrorFinderRun = (params: ApiLoadErrorFinderRunParams) =>
  apiWrapper(
    axios.request<ApiErrorFinderRun>({
      method: 'get',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/ef_runs/${params.runId}`,
    }),
  );

export const apiDeleteErrorFinderRun = (
  params: ApiDeleteErrorFinderRunParams,
) =>
  apiWrapper(
    axios.request<ApiDeleteResponse>({
      method: 'delete',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/ef_runs/${params.runId}`,
    }),
  );

export const apiGetErrorFinderRunLockedResultsState = (
  params: ApiGetErrorFinderRunLockedResultsParams,
) =>
  apiWrapper(
    axios.request<ApiErrorFinderRunLockedResults>({
      method: 'get',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/ef_runs/${params.runId}/has_locked_results`,
    }),
  );

export const apiUnlockErrorFinderRunResults = (
  params: ApiUnlockErrorFinderRunParams,
) =>
  apiWrapper(
    axios.request({
      method: 'get',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/ef_runs/${params.runId}/unlock`,
    }),
  );

export const apiLoadErrorLabels = (params: ApiLoadErrorLabelsParams) =>
  apiWrapper(
    axios.request<ApiLoadErrorLabelsResponse>({
      method: 'get',
      headers,
      transformRequest,
      transformResponse,
      url: `${API_URL_V2}/aics/projects/${params.projectId}/runs/${
        params.runId
      }/${runTypeApiResults[params.runType]}`,
      params: convertToBackendObject(params.params),
    }),
  );

export const apiLoadErrorLabelsBlobs = (params: ApiLoadErrorLabelsBlobParams) =>
  apiWrapper(
    axios.request<ApiLoadErrorLabelsBlobResponse>({
      method: 'get',
      headers,
      transformResponse,
      url: `${API_URL_V2}/aics/projects/${params.projectId}/runs/${
        params.runId
      }/${runTypeApiResults[params.runType]}/${params.resultId}/blobs`,
    }),
  );

export const apiChangeErrorLabelAction = (
  params: ApiChangeErrorLabelActionParams,
  data: ApiChangeErrorLabelActionData,
) =>
  apiWrapper(
    axios.request<Partial<ApiErrorLabel>>({
      method: 'put',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/ef_runs/${
        params.runId
      }/${runTypeApiResults[params.runType]}/${params.id}`,
      data,
    }),
  );

export const apiLoadLabelConfidence = (
  params: ApiLoadLabelConfidenceActionParams,
) =>
  apiWrapper(
    axios.request<ApiLabelConfidence[]>({
      method: 'get',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/ef_runs/${params.runId}/cl_label_class/${params.labelId}/confidence`,
    }),
  );

export const apiLoadRunErrorsPerClass = (
  params: ApiLoadRunErrorsPerClassParams,
) =>
  apiWrapper(
    axios.request<ApiRunErrorsPerClass[]>({
      method: 'get',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/ef_runs/${params.runId}/cl_errors_per_class`,
    }),
  );

export const apiLoadLabelClassConfidenceHist = (
  params: ApiLoadLabelClassConfidenceHistParams,
) =>
  apiWrapper(
    axios.request<ApiLabelClassConfidenceHist>({
      method: 'get',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/ef_runs/${
        params.runId
      }/${runTypeApiPrefix[params.runType]}_confidence_hist${
        params.labelClassId ? `/${params.labelClassId}` : ''
      }`,
    }),
  );

export const apiLoadLabelClassIouHist = (
  params: ApiLoadLabelClassIouHistParams,
) =>
  apiWrapper(
    axios.request<ApiLabelClassConfidenceHist>({
      method: 'get',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/ef_runs/${
        params.runId
      }/${runTypeApiPrefix[params.runType]}_iou_hist${
        params.labelClassId ? `/${params.labelClassId}` : ''
      }`,
    }),
  );

export const apiLoadRunConfusionMatrix = (
  params: ApiLoadRunConfusionMatrixParams,
) =>
  apiWrapper(
    axios.request<ApiRunConfusionMatrix[]>({
      method: 'get',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/ef_runs/${params.runId}/cl_confusion_matrix`,
    }),
  );

export const apiChangeTagErrorResultAction = (
  params: ApiChangeTagErrorResultActionParams,
  data: ApiChangeTagErrorResultActionData,
) =>
  apiWrapper(
    axios.request<Partial<ApiErrorTagResult>>({
      method: 'put',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/ef_runs/${params.runId}/tag_review_results/${params.imageId}`,
      data,
    }),
  );

export const apiChangeAttributeErrorResultAction = (
  params: ApiChangeAttributeErrorResultActionParams,
  data: ApiChangeAttributeErrorResultActionData,
) =>
  apiWrapper(
    axios.request<Partial<ApiErrorAttributeResult>>({
      method: 'put',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/ef_runs/${params.runId}/attribute_review_results/${params.labelId}`,
      data,
    }),
  );

export const apiChangeSemanticErrorResultAction = (
  params: ApiChangeSemanticErrorResultActionParams,
  data: ApiChangeSemanticErrorResultActionData,
) =>
  apiWrapper(
    axios.request<ApiChangeSemanticErrorResultResponse>({
      method: 'put',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/ef_runs/${params.runId}/semantic_review_results/${params.resultId}`,
      data,
    }),
  );
