import axios from 'axios';

import {
  ApiTagClass,
  ApiLoadProjectTagClassesParams,
  ApiLoadProjectTagClassesResponse,
  ApiCreateProjectTagClassParams,
  ApiUpdateProjectTagClassParams,
  ApiDeleteProjectTagClassParams,
} from '../schemas/tagClass';
import { apiWrapper } from '../utils';
import { NEW_API_URL } from '../../constants/paths';
import {
  ApiDeleteResponse,
  headers,
  transformRequest,
  transformResponse,
} from '../helpers';

export const apiLoadProjectTagClasses = (
  params: ApiLoadProjectTagClassesParams,
) =>
  apiWrapper(
    axios.request<ApiLoadProjectTagClassesResponse>({
      method: 'get',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/tag_classes`,
    }),
  );

export const apiCreateProjectTagClass = (
  params: ApiCreateProjectTagClassParams,
  data: Partial<ApiTagClass>,
) =>
  apiWrapper(
    axios.request<ApiTagClass>({
      method: 'post',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/tag_classes`,
      data,
    }),
  );

export const apiUpdateProjectTagClass = (
  params: ApiUpdateProjectTagClassParams,
  data: Partial<ApiTagClass>,
) =>
  apiWrapper(
    axios.request<ApiTagClass>({
      method: 'put',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/tag_classes/${params.tagClassId}`,
      data,
    }),
  );

export const apiDeleteProjectTagClass = (
  params: ApiDeleteProjectTagClassParams,
) =>
  apiWrapper(
    axios.request<ApiDeleteResponse>({
      method: 'delete',
      headers,
      url: `${NEW_API_URL}/projects/${params.projectId}/tag_classes/${params.tagClassId}`,
    }),
  );
