/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BatchIDRequest } from '../models/BatchIDRequest';
import type { Notification } from '../models/Notification';
import type { PaginationMeta } from '../models/PaginationMeta';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { OmitReadonly } from '../core/utils/OmitReadonly';

export class NotificationsService {

  /**
   * Retrieve notifications
   * @returns any Returns list of notifications
   * @throws ApiError
   */
  public static getV1Notifications({
    offset,
    limit,
  }: {
    /**
     * Offset for this paginated query
     */
    offset?: number,
    /**
     * Limit for this paginated query
     */
    limit?: number,
  }): CancelablePromise<{
    meta: PaginationMeta;
    items: Array<Notification>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/notifications',
      query: {
        'offset': offset,
        'limit': limit,
      },
    });
  }

  /**
   * Marks notifications as noticed
   * @returns void
   * @throws ApiError
   */
  public static putV1NotificationsNotice({
    requestBody,
  }: {
    requestBody: OmitReadonly<BatchIDRequest>,
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/v1/notifications/notice',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Marks notifications as noticed and read
   * @returns void
   * @throws ApiError
   */
  public static putV1NotificationsRead({
    requestBody,
  }: {
    requestBody: OmitReadonly<BatchIDRequest>,
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/v1/notifications/read',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Retrieve brief notifications status
   * @returns any Brief notifications status
   * @throws ApiError
   */
  public static getV1NotificationsStatus(): CancelablePromise<{
    /**
     * Amount of notifications that are not noticed
     */
    unnoticed?: number;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/notifications/status',
    });
  }

}
