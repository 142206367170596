import { call, put, select, takeEvery } from 'typed-redux-saga';

import { apiLoadExperimentConfusionMatrixIterationsRequest } from '../../../../../../../api/requests/modelPlayground';
import { getErrorMessage } from '../../../../../../../api/utils';
import { activeProjectIdSelector } from '../../../../../project/project.selectors';
import { dashboardActiveExperimentSelector } from '../../activeExperiment/activeExperiment.selectors';
import {
  loadExperimentConfusionMatrixIterations,
  loadExperimentConfusionMatrixIterationsFailure,
  loadExperimentConfusionMatrixIterationsStart,
  loadExperimentConfusionMatrixIterationsSuccess,
  resetWidget,
} from './confusionMatrix.slice';
import { refetchWidgetData } from '../widgetsLiveUpdate/widgetsLiveUpdate.actions';
import { activeSplitIdSelector } from '../../../activeSplit/activeSplitId/activeSplitId.selectors';
import { splitByIdSelector } from '../../../splits/data/data.selectors';
import { setActiveExperimentId } from '../../activeExperiment/activeExperiment.slice';
import { enqueueNotification } from '../../../../../ui/stackNotifications/stackNotifications.slice';
import { ModelFamily } from '../../../../../../../api/constants/modelFamily';

function* loadExperimentConfusionMatrixIterationsHandler(
  action: ActionType<
    typeof loadExperimentConfusionMatrixIterations | typeof refetchWidgetData
  >,
) {
  const projectId = yield* select(activeProjectIdSelector);
  const experiment = yield* select(dashboardActiveExperimentSelector);
  const activeSplitId = yield* select(activeSplitIdSelector);

  if (!experiment || typeof experiment.iterationNum !== 'number') {
    return;
  }

  if (action.type === refetchWidgetData.type && activeSplitId) {
    const activeSplit = yield* select((state: RootState) =>
      splitByIdSelector(state, activeSplitId),
    );

    if (!activeSplit) {
      return;
    }

    const { modelFamily } = activeSplit;

    if (
      ![
        ModelFamily.Classifier,
        // TODO: Uncomment when this widget is visible for other families
        // ModelFamily.Detector,
        // ModelFamily.Segmentor,
      ].includes(modelFamily)
    ) {
      return;
    }
  }

  try {
    yield* put(loadExperimentConfusionMatrixIterationsStart());
    const { data } = yield* call(
      apiLoadExperimentConfusionMatrixIterationsRequest,
      {
        projectId,
        experimentId: experiment.id,
        iterationId: experiment.iterationNum,
      },
    );

    yield* put(
      loadExperimentConfusionMatrixIterationsSuccess({
        experimentId: experiment.id,
        iteration: data,
      }),
    );
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to load widget data');

    yield* put(loadExperimentConfusionMatrixIterationsFailure(message));
    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
          refresh: false,
        },
      }),
    );
  }
}

export function* setActiveExperimentIdHandler() {
  yield* put(resetWidget());
}

export function* confusionMatrixSaga() {
  yield* takeEvery(
    [loadExperimentConfusionMatrixIterations, refetchWidgetData],
    loadExperimentConfusionMatrixIterationsHandler,
  );
  yield* takeEvery(setActiveExperimentId, setActiveExperimentIdHandler);
}
