import axios from 'axios';

import {
  ApiBoxToInstanceParams,
  ApiBoxToInstanceResponseItem,
} from '../schemas/boxToInstance';
import { apiWrapper } from '../utils';
import { API_URL } from '../../constants/paths';
import { headers, transformRequest, transformResponse } from '../helpers';

export const apiCreateProjectImageBoxToInstance = (
  params: ApiBoxToInstanceParams,
) =>
  apiWrapper(
    axios.request<ApiBoxToInstanceResponseItem[]>({
      method: 'post',
      headers,
      transformRequest,
      transformResponse,
      url: `${API_URL}/project/${params.projectId}/image/${params.imageId}/box_to_instance`,
    }),
  );
