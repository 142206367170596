import axios from 'axios';

import {
  ApiAttribute,
  ApiLabelAttributeValue,
  ApiAttributePrediction,
  ApiLoadProjectAttributesParams,
  ApiLoadProjectAttributesResponse,
  ApiCreateProjectAttributeParams,
  ApiDeleteProjectAttributeParams,
  ApiUpdateProjectAttributeParams,
  ApiLoadProjectImageAttributesParams,
  ApiLoadProjectImageAttributesResponse,
  ApiLoadProjectImageMultiLabelAttributesParams,
  ApiLoadProjectImageMultiLabelAttributesResponse,
  UpdateProjectImageBulkLabelAttributesParams,
  ApiUpdateProjectImageLabelAttributeParams,
  ApiUpdateProjectImageLabelAttributeResponse,
  ApiGetProjectImageLabelAttributePredictionParams,
  ApiLoadProjectImageLabelAttributesParams,
  ApiLoadProjectImageLabelAttributesResponse,
} from '../schemas/attribute';
import { API_URL, API_URL_V2, NEW_API_URL } from '../../constants/paths';
import { apiWrapper } from '../utils';
import {
  ApiDeleteResponse,
  headers,
  transformRequest,
  transformResponse,
} from '../helpers';

export const apiLoadProjectAttributes = (
  params: ApiLoadProjectAttributesParams,
) =>
  apiWrapper(
    axios.request<ApiLoadProjectAttributesResponse>({
      method: 'get',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/attributes`,
      params: {
        offset: params.offset,
        limit: params.limit,
      },
    }),
  );

export const apiCreateProjectAttribute = (
  params: ApiCreateProjectAttributeParams,
  data: Partial<ApiAttribute>,
) =>
  apiWrapper(
    axios.request<ApiAttribute>({
      method: 'post',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/attributes`,
      data,
    }),
  );

export const apiDeleteProjectAttribute = (
  params: ApiDeleteProjectAttributeParams,
) =>
  apiWrapper(
    axios.request<ApiDeleteResponse>({
      method: 'delete',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/attributes/${params.attributeId}`,
    }),
  );

export const apiUpdateProjectAttribute = (
  params: ApiUpdateProjectAttributeParams,
  data: Partial<ApiAttribute>,
) =>
  apiWrapper(
    axios.request<ApiAttribute>({
      method: 'put',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/attributes/${params.attributeId}`,
      data,
    }),
  );

export const apiLoadProjectImageAttributes = (
  params: ApiLoadProjectImageAttributesParams,
) => {
  const { imageId, projectId, ...queryParams } = params;

  return apiWrapper(
    axios.request<ApiLoadProjectImageAttributesResponse>({
      method: 'get',
      headers,
      transformRequest,
      transformResponse,
      url: `${API_URL_V2}/projects/${projectId}/images/${imageId}/label_attributes`,
      params: queryParams,
    }),
  );
};

export const apiLoadProjectImageMultiLabelAttributes = (
  params: ApiLoadProjectImageMultiLabelAttributesParams,
  data: string[], // label ids
) =>
  apiWrapper(
    axios.request<ApiLoadProjectImageMultiLabelAttributesResponse>({
      method: 'post',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/images/${params.imageId}/editable_attributes`,
      data,
    }),
  );

export const apiUpdateProjectImageBulkLabelAttributes = (
  params: UpdateProjectImageBulkLabelAttributesParams,
  data: Array<Partial<ApiLabelAttributeValue>>,
) =>
  apiWrapper(
    axios.request<ApiLabelAttributeValue[]>({
      method: 'post',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/images/${params.imageId}/label_attributes`,
      data,
    }),
  );

export const apiUpdateProjectImageLabelAttribute = (
  params: ApiUpdateProjectImageLabelAttributeParams,
  data: Partial<ApiLabelAttributeValue> & { predictedValues?: string },
) =>
  apiWrapper(
    axios.request<ApiUpdateProjectImageLabelAttributeResponse>({
      method: 'post',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/labels/${params.labelId}/attributes/${params.attributeId}`,
      data,
    }),
  );

export const apiGetProjectImageLabelAttributePrediction = (
  params: ApiGetProjectImageLabelAttributePredictionParams,
) =>
  apiWrapper(
    axios.request<ApiAttributePrediction[]>({
      method: 'post',
      headers,
      transformRequest,
      transformResponse,
      url: `${API_URL}/project/${params.projectId}/label/${params.labelId}/attribute_prediction`,
      data: {
        modelId: params.modelId,
        confidenceThreshold: params.confidenceThreshold,
      },
    }),
  );

export const apiLoadProjectImageLabelAttributes = (
  params: ApiLoadProjectImageLabelAttributesParams,
) =>
  apiWrapper(
    axios.request<ApiLoadProjectImageLabelAttributesResponse>({
      method: 'get',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/labels/${params.labelId}/attributes`,
    }),
  );
