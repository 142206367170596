import { push } from 'connected-react-router';
import { call, put, select, takeEvery } from 'typed-redux-saga';

import {
  apiLoadExperiments,
  apiLoadExperiment,
  apiDeleteExperiment,
  apiCreateExperiment,
  apiCreateExperimentOnDemand,
  apiUpdateExperiment,
  apiCopyExperiment,
  apiLoadExperimentExportFormats,
  apiLoadExperimentExportFormatOptions,
  apiInitiateExperimentExport,
  apiStopTrainingRun,
} from '../../../../../../api/requests/modelPlayground';
import { getErrorMessage } from '../../../../../../api/utils';
import { activeProjectIdSelector } from '../../../../project/project.selectors';
import { loadAll } from '../../../../../utils/api';
import { hideModals, showModal } from '../../../../ui/modals/modals.slice';
import { setExperimentsTotal } from '../experimentsPagination/experimentsPagination.slice';
import { activeExperimentIdSelector } from '../../activeExperiment/selectors';
import { dashboardActiveExperimentIdSelector } from '../../dashboard/activeExperiment/activeExperiment.selectors';
import { updateRunFromWebsocket } from '../../activeExperiment/runs/runs.slice';
import {
  loadExperiments,
  loadAllFilteredExperiments,
  loadExperimentsSuccess,
  loadExperimentsFailure,
  loadExperiment,
  loadExperimentSuccess,
  loadExperimentFailure,
  createExperiment,
  createExperimentSuccess,
  createExperimentFailure,
  removeExperiment,
  removeExperimentFailure,
  removeExperimentSuccess,
  updateExperiment,
  updateExperimentFailure,
  updateExperimentSuccess,
  copyExperiment,
  copyExperimentSuccess,
  loadExperimentExportFormats,
  loadExperimentExportFormatsSuccess,
  loadExperimentExportFormatsFailure,
  loadExperimentExportFormatOptions,
  loadExperimentExportFormatOptionsFailure,
  loadExperimentExportFormatOptionsSuccess,
  experimentExport,
  experimentExportSuccess,
  experimentExportFailure,
  stopExperiment,
  modifyExperiment,
  createExperimentOnDemand,
} from './experimentsData.slice';
import {
  experimentByRunIdSelector,
  experimentsByIdSelector,
} from './experimentsData.selectors';
import { setActiveExperimentId } from '../../dashboard/activeExperiment/activeExperiment.slice';
import { updateTrainingMetricFromWebsocket } from '../../dashboard/widgets/metricsPerIteration/metricsPerIteration.slice';
import { loadTemplates } from '../templates/templates.slice';
import { enqueueNotification } from '../../../../ui/stackNotifications/stackNotifications.slice';
import { Experiment } from '../../../../../../api/domainModels/modelPlayground';
import {
  loadArchitecturesSuccess,
  updateExperimentArchitectureSuccess,
} from '../../activeExperiment/architectures/architectures.slice';
import { activeSplitSelector } from '../../activeSplit/activeSplitId/activeSplitId.selectors';

function* loadHandler() {
  try {
    const projectId = yield* select(activeProjectIdSelector);
    const { data } = yield* call(apiLoadExperiments, {
      projectId,
      params: {},
    });
    const { items, meta } = data;

    yield* put(loadExperimentsSuccess(items));
    yield* put(setExperimentsTotal(meta.total));
  } catch (error) {
    yield* put(
      loadExperimentsFailure(
        getErrorMessage(error, 'Not able to load experiments'),
      ),
    );
  }
}

function* loadAllFilteredHandler(
  action: ActionType<typeof loadAllFilteredExperiments>,
) {
  try {
    const projectId = yield* select(activeProjectIdSelector);
    const params = action.payload;
    const items = yield* loadAll({
      apiHelper: apiLoadExperiments,
      params: {
        projectId,
        params,
      },
    });
    yield* put(loadExperimentsSuccess(items));
  } catch (error) {
    yield* put(
      loadExperimentsFailure(
        getErrorMessage(error, 'Not able to load experiments'),
      ),
    );
  }
}

function* loadOneHandler(action: ActionType<typeof loadExperiment>) {
  try {
    const projectId = yield* select(activeProjectIdSelector);
    const { id: experimentId } = action.payload;

    if (!projectId || !experimentId) return;
    const { data } = yield* call(apiLoadExperiment, {
      projectId,
      experimentId,
    });
    yield* put(loadExperimentSuccess(data));
    yield* put(loadTemplates({ modelFamily: data.modelFamily }));
  } catch (error) {
    yield* put(
      loadExperimentFailure(
        getErrorMessage(error, 'Not able to load experiment'),
      ),
    );
  }
}

function* createHandler(action: ActionType<typeof createExperiment>) {
  const projectId = yield* select(activeProjectIdSelector);
  const activeSplit = yield* select(activeSplitSelector);
  const payload: Partial<Experiment> = {
    ...action.payload.data,
    modelFamily: action.payload.modelFamily,
  };

  if (activeSplit) {
    payload.splitId = activeSplit.id;
  }

  try {
    const response = yield* call(
      apiCreateExperiment,
      {
        projectId,
      },
      payload,
    );

    yield* put(createExperimentSuccess(response.data));
    yield* put(hideModals());

    yield* put(
      push(
        `/projects/${projectId}/playground/experiments/${response.data.id}/architecture`,
      ),
    );
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to create an experiment');

    yield* put(createExperimentFailure(message));
    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
          refresh: false,
        },
      }),
    );
  }
}

function* createOnDemandHandler(
  action: ActionType<typeof createExperimentOnDemand>,
) {
  const { templateId, modelFamily } = action.payload;
  const projectId = yield* select(activeProjectIdSelector);

  try {
    yield* call(
      apiCreateExperimentOnDemand,
      {
        projectId,
        modelFamily,
      },
      { templateId },
    );
    yield* put(hideModals());
    yield* put(
      showModal({ modalName: 'startExperimentFromAIAssistantsSuccess' }),
    );
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to start an experiment');

    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
          refresh: false,
        },
      }),
    );
  }
}

function* updateHandler(action: ActionType<typeof updateExperiment>) {
  const projectId = yield* select(activeProjectIdSelector);
  let experimentId = yield* select(activeExperimentIdSelector);

  if (!experimentId) {
    experimentId = action.payload.id;
  }

  const payload = { ...action.payload };

  try {
    const { data } = yield* call(
      apiUpdateExperiment,
      {
        projectId,
        experimentId,
      },
      payload,
    );
    yield* put(updateExperimentSuccess({ changes: data, id: experimentId }));
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to update the experiment');

    yield* put(updateExperimentFailure(message));
    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
          refresh: false,
        },
      }),
    );
  }
}

function* removeHandler(action: ActionType<typeof removeExperiment>) {
  const projectId = yield* select(activeProjectIdSelector);
  const dashboardActiveExperimentId = yield* select(
    dashboardActiveExperimentIdSelector,
  );
  const id = action.payload;

  try {
    yield* call(apiDeleteExperiment, {
      projectId,
      experimentId: id,
    });
    yield* put(removeExperimentSuccess(id));
    yield* put(hideModals());
    if (dashboardActiveExperimentId === id) {
      yield* put(setActiveExperimentId(null));
    }
  } catch (error) {
    yield* put(
      removeExperimentFailure(
        getErrorMessage(error, 'Not able to remove experiment'),
      ),
    );
  }
}

function* updateExperimentStatusHandler(
  action: ActionType<typeof updateRunFromWebsocket>,
) {
  const { id: runId, status, errorCode, errorMessage } = action.payload;
  const experiment = yield* select((state: RootState) =>
    experimentByRunIdSelector(state, runId),
  );

  if (!experiment || experiment.status === status) return;

  yield* put(
    updateExperimentSuccess({
      changes: { status, errorCode, errorMessage },
      id: experiment.id,
    }),
  );
}

function* copyHandler(action: ActionType<typeof copyExperiment>) {
  const projectId = yield* select(activeProjectIdSelector);
  const { experimentId, name, color } = action.payload;

  try {
    const { data } = yield* call(
      apiCopyExperiment,
      {
        projectId,
        experimentId,
      },
      {
        name: `Copy of ${name}`,
        color,
      },
    );
    yield* put(copyExperimentSuccess(data));
    yield* put(
      push(`/projects/${projectId}/playground/experiments/${data.id}/basic`),
    );
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to copy the experiment');

    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
          refresh: false,
        },
      }),
    );
  }
}

function* stopHandler(action: ActionType<typeof stopExperiment>) {
  const projectId = yield* select(activeProjectIdSelector);
  const { trainJobId } = action.payload;

  try {
    yield* call(apiStopTrainingRun, {
      projectId,
      trainJobId,
    });
    yield* put(hideModals());
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to stop the experiment');

    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
          refresh: false,
        },
      }),
    );
  }
}

function* exportFormatsLoadHandler(
  action: ActionType<typeof loadExperimentExportFormats>,
) {
  const { experimentId: expId } = action.payload;

  const projectId = yield* select(activeProjectIdSelector);
  const experimentId = expId || (yield* select(activeExperimentIdSelector));

  if (!projectId || !experimentId) return;

  try {
    const items = yield* loadAll({
      apiHelper: apiLoadExperimentExportFormats,
      params: {
        projectId,
        experimentId,
      },
    });

    yield* put(loadExperimentExportFormatsSuccess(items));
  } catch (error) {
    const message = getErrorMessage(
      error,
      'Not able to load the experiment format',
    );

    yield* put(loadExperimentExportFormatsFailure(message));
    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
          refresh: false,
        },
      }),
    );
  }
}

function* exportFormatOptionsLoadHandler(
  action: ActionType<typeof loadExperimentExportFormatOptions>,
) {
  const projectId = yield* select(activeProjectIdSelector);
  const { experimentId, exportFormatId } = action.payload;

  try {
    const items = yield* loadAll({
      apiHelper: apiLoadExperimentExportFormatOptions,
      params: {
        projectId,
        experimentId,
        exportFormatId,
      },
    });

    yield* put(loadExperimentExportFormatOptionsSuccess(items));
  } catch (error) {
    const message = getErrorMessage(
      error,
      'Not able to load experiment format options',
    );

    yield* put(loadExperimentExportFormatOptionsFailure(message));
    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
          refresh: false,
        },
      }),
    );
  }
}

function* experimentExportHandler(action: ActionType<typeof experimentExport>) {
  const { experimentId, format, exportFormatOptions } = action.payload;
  const projectId = yield* select(activeProjectIdSelector);
  const experiment: Experiment | undefined = yield* select((state) =>
    experimentsByIdSelector(state, experimentId),
  );

  if (!experiment) return;

  const paramValues = Object.entries(exportFormatOptions).reduce(
    (acc, [key, value]) => {
      acc.push({
        id: key,
        selectedValue: value,
      });

      return acc;
    },
    [] as { id: string; selectedValue: string | number | boolean }[],
  );

  try {
    const { data } = yield* call(
      apiInitiateExperimentExport,
      {
        projectId,
        format,
        experimentId,
      },
      {
        exportName: experiment.name,
        paramValues,
      },
    );
    yield* put(experimentExportSuccess(data));
    yield* put(
      enqueueNotification({
        message: `Your export for experiment ${experiment.name} is being created. The progress information is available in the notification center.`,
        options: {
          variant: 'success',
          allowOutsideOfEditor: true,
        },
      }),
    );
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to export the experiment');

    yield* put(experimentExportFailure(message));
    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
          refresh: false,
        },
      }),
    );
  }
}

function* updateTrainingMetricFromWebsocketHandler(
  action: ActionType<typeof updateTrainingMetricFromWebsocket>,
) {
  const experiment = yield* select((state: RootState) =>
    experimentByRunIdSelector(state, action.payload.id),
  );

  if (experiment) {
    yield* put(
      modifyExperiment({
        id: experiment.id,
        changes: {
          iterationNum: action.payload.data.index,
        },
      }),
    );
  }
}

export function* experimentsDataSaga() {
  yield* takeEvery(experimentExport, experimentExportHandler);
  yield* takeEvery(
    [
      loadExperimentExportFormats,
      loadArchitecturesSuccess,
      updateExperimentArchitectureSuccess,
    ],
    exportFormatsLoadHandler,
  );
  yield* takeEvery(
    loadExperimentExportFormatOptions,
    exportFormatOptionsLoadHandler,
  );
  yield* takeEvery(loadExperiments, loadHandler);
  yield* takeEvery(loadAllFilteredExperiments, loadAllFilteredHandler);
  yield* takeEvery(loadExperiment, loadOneHandler);
  yield* takeEvery(createExperiment, createHandler);
  yield* takeEvery(createExperimentOnDemand, createOnDemandHandler);
  yield* takeEvery(updateExperiment, updateHandler);
  yield* takeEvery(removeExperiment, removeHandler);
  yield* takeEvery(copyExperiment, copyHandler);
  yield* takeEvery(stopExperiment, stopHandler);
  yield* takeEvery(updateRunFromWebsocket, updateExperimentStatusHandler);
  yield* takeEvery(
    updateTrainingMetricFromWebsocket,
    updateTrainingMetricFromWebsocketHandler,
  );
}
