import { expandedPanelsSaga } from './expandedPanels/expandedPanels.saga';
import { stackNotifications } from './stackNotifications/stackNotifications.saga';
import { applicationStatusSaga } from './applicationStatus/applicationStatus.saga';
import { controlledTooltipSaga } from './controlledTooltip/controlledTooltip.saga';

export const uiSagas = [
  expandedPanelsSaga,
  stackNotifications,
  applicationStatusSaga,
  controlledTooltipSaga,
];
