import { createSelector } from '@reduxjs/toolkit';
import uniqBy from 'lodash/uniqBy';

import {
  componentsAdapter,
  parametersAdapter,
  previewIdsAdapter,
} from './transforms.slice';
import { getMetricText } from '../../../../../../containers/modelPlayground/helpers';
import { modelPlaygroundSelector } from '../../modelPlayground.selectors';

export const experimentTransformsStateSelector = createSelector(
  modelPlaygroundSelector,
  (modelPlayground) => modelPlayground.activeExperiment.transforms,
);

export const experimentTransformsComponentsStateSelector = createSelector(
  experimentTransformsStateSelector,
  (state) => state.components,
);

const componentsSelectors = componentsAdapter.getSelectors(
  experimentTransformsComponentsStateSelector,
);
export const experimentTransformsDataSelector = componentsSelectors.selectAll;

export const selectedTransformsSelector = createSelector(
  experimentTransformsDataSelector,
  (state) => state.filter((transform) => transform.selected),
);

export const transformsOrdersSelector = createSelector(
  experimentTransformsDataSelector,
  (state) =>
    state
      .filter((transform) => transform.norder !== null)
      .map((transform) => transform.norder) as number[],
);

export const transformsMaxOrderSelector = createSelector(
  transformsOrdersSelector,
  (orders) => (orders.length > 0 ? Math.max(...orders) : -1),
);

export const uniqueTransformsSelector = createSelector(
  experimentTransformsDataSelector,
  (transforms) => uniqBy(transforms, 'componentId'),
);

export const uniqueTransformsAsOptionsSelector = createSelector(
  uniqueTransformsSelector,
  (transforms) =>
    transforms.map((transform) => ({
      text: getMetricText(transform.name),
      value: transform.componentId,
      helperUrl: transform.wikiUrl,
    })),
);

export const experimentTransformsLoadingStateSelector = createSelector(
  experimentTransformsComponentsStateSelector,
  (state) => state.loadingState,
);
export const experimentTransformsUpdateLoadingStateSelector = createSelector(
  experimentTransformsComponentsStateSelector,
  (state) => state.updateLoadingState,
);

export const experimentTransformsParametersStateSelector = createSelector(
  experimentTransformsStateSelector,
  (state) => state.parameters,
);

const parametersSelectors = parametersAdapter.getSelectors(
  experimentTransformsParametersStateSelector,
);
export const transformParametersByIdSelector = parametersSelectors.selectById;
export const transformParametersMapSelector =
  parametersSelectors.selectEntities;

export const transformParametersUpdateLoadingStateSelector = createSelector(
  experimentTransformsStateSelector,
  (state) => state.parametersUpdateLoadingState,
);

export const transformParameterByIdSelector = (
  state: RootState,
  componentId: string,
  transformParameterId: string,
) => {
  const parameters = transformParametersByIdSelector(state, componentId);

  if (!parameters || parameters.data.length === 0) return null;

  return parameters.data.find(
    (parameter) => parameter.id === transformParameterId,
  );
};

export const transformsPreviewSelector = createSelector(
  experimentTransformsStateSelector,
  (state) => state.preview,
);
export const transformsPreviewLoadingStateSelector = createSelector(
  experimentTransformsStateSelector,
  (state) => state.previewLoadingState,
);

export const experimentTransformsPreviewIdsStateSelector = createSelector(
  experimentTransformsStateSelector,
  (state) => state.previewIds,
);

const previewIdsSelectors = previewIdsAdapter.getSelectors(
  experimentTransformsPreviewIdsStateSelector,
);
export const previewIdsSelector = previewIdsSelectors.selectAll;
