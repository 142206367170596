import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SelectedDatasetState = { id: string | null };

const initialState: SelectedDatasetState = { id: null };

const { actions, reducer: selectedDatasetReducer } = createSlice({
  name: 'editedProject/selectedDataset',
  initialState,
  reducers: {
    setSelectedDataset: (state, action: PayloadAction<string | null>) => {
      state.id = action.payload;
    },
  },
});

export const { setSelectedDataset } = actions;

export { selectedDatasetReducer };
