import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
  Update,
} from '@reduxjs/toolkit';

import {
  ImportSession,
  ImportSessionFormValues,
} from '../../../../../../api/domainModels/imports';
import { loadingStateBuilder } from '../../../../../utils/loadingState';
import { setProjectId } from '../../../../core/imageView/project/project.slice';

export type FinalizeSessionFormValues = {
  newImageStatus: string;
  removeExistedLabels: boolean;
};

export const adapter = createEntityAdapter<ImportSession>({
  sortComparer: (a, b) =>
    new Date(b.createDate).getTime() < new Date(a.createDate).getTime()
      ? -1
      : 1,
});
const initialState = adapter.getInitialState({
  listLoadingState: loadingStateBuilder.initial(),
  itemLoadingState: loadingStateBuilder.initial(),
});

const { actions, reducer: importSessionsReducer } = createSlice({
  name: 'importSessions',
  initialState,
  reducers: {
    loadImportSessionsStart(
      state,
      action: PayloadAction<{ projectId?: string; initialFetch?: boolean }>,
    ) {
      const { initialFetch } = action.payload;
      if (initialFetch) {
        state.listLoadingState = loadingStateBuilder.inProgress();
      }
    },
    loadImportSessionsSuccess(state, action: PayloadAction<ImportSession[]>) {
      state.listLoadingState = loadingStateBuilder.success();
      adapter.setAll(state, action.payload);
    },
    loadImportSessionsFailure(state, action: PayloadAction<string>) {
      state.listLoadingState = loadingStateBuilder.failure(action.payload);
    },
    createImportSessionStart(
      state,
      _action: PayloadAction<{
        values: ImportSessionFormValues;
        onNextStep: () => void;
      }>,
    ) {
      state.itemLoadingState = loadingStateBuilder.inProgress();
    },
    createImportSessionSuccess(state, action: PayloadAction<ImportSession>) {
      state.itemLoadingState = loadingStateBuilder.success();
      adapter.addOne(state, action.payload);
    },
    createImportSessionFailure(state, action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.failure(action.payload);
    },
    updateImportSessionStart(
      state,
      _action: PayloadAction<{
        values: ImportSessionFormValues & { id: string };
        onNextStep: () => void;
      }>,
    ) {
      state.itemLoadingState = loadingStateBuilder.inProgress();
    },
    updateImportSessionSuccess(
      state,
      action: PayloadAction<Update<ImportSession>>,
    ) {
      state.itemLoadingState = loadingStateBuilder.success();
      adapter.updateOne(state, action.payload);
    },
    updateImportSessionFailure(state, action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.failure(action.payload);
    },
    removeImportSessionStart(state, _action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.inProgress();
    },
    removeImportSessionSuccess(state, action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.success();
      adapter.removeOne(state, action.payload);
    },
    removeImportSessionFailure(state, action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.failure(action.payload);
    },
    finalizeImportSessionStart(
      state,
      _action: PayloadAction<FinalizeSessionFormValues>,
    ) {
      state.itemLoadingState = loadingStateBuilder.inProgress();
    },
    finalizeImportSessionSuccess(state) {
      state.itemLoadingState = loadingStateBuilder.success();
    },
    finalizeImportSessionFailure(state, action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.failure(action.payload);
    },
    updateImportSessionStatusFromWebsocket(
      _,
      _action: PayloadAction<ImportSession & { projectId: string }>,
    ) {},
    updateImportSession(state, action: PayloadAction<ImportSession>) {
      adapter.upsertOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setProjectId, () => initialState);
  },
});

export { importSessionsReducer };
export const {
  loadImportSessionsStart,
  loadImportSessionsSuccess,
  loadImportSessionsFailure,
  createImportSessionStart,
  createImportSessionSuccess,
  createImportSessionFailure,
  updateImportSessionStart,
  updateImportSessionSuccess,
  updateImportSessionFailure,
  removeImportSessionStart,
  removeImportSessionSuccess,
  removeImportSessionFailure,
  finalizeImportSessionStart,
  finalizeImportSessionSuccess,
  finalizeImportSessionFailure,
  updateImportSessionStatusFromWebsocket,
  updateImportSession,
} = actions;
