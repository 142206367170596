import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
  Update,
} from '@reduxjs/toolkit';

import { ServiceAccount } from '../../../../../api/domainModels/serviceAccount';
import { loadingStateBuilder } from '../../../../utils/loadingState';
import { resetManagedWorkspaceId } from '../../../commonFeatures/workspaceId/workspaceId.slice';

export type ServiceAccountFormValues = {
  name: string;
  email: string | null;
  roleId: number;
};

export const serviceAccountsAdapter = createEntityAdapter<ServiceAccount>({
  sortComparer: (a, b) => (a.id < b.id ? 1 : -1),
});
const initialState = serviceAccountsAdapter.getInitialState({
  listLoadingState: loadingStateBuilder.initial(),
  itemLoadingState: loadingStateBuilder.initial(),
});

const { actions, reducer: serviceAccountsReducer } = createSlice({
  name: 'serviceAccounts',
  initialState,
  reducers: {
    resetServiceAccountsLoadingState(state) {
      state.itemLoadingState = loadingStateBuilder.initial();
    },
    loadServiceAccountsStart(state) {
      state.listLoadingState = loadingStateBuilder.inProgress();
    },
    loadServiceAccountsSuccess(state, action: PayloadAction<ServiceAccount[]>) {
      state.listLoadingState = loadingStateBuilder.success();
      serviceAccountsAdapter.setAll(state, action.payload);
    },
    loadServiceAccountsFailure(state, action: PayloadAction<string>) {
      state.listLoadingState = loadingStateBuilder.failure(action.payload);
      serviceAccountsAdapter.setAll(state, []);
    },
    addServiceAccountStart(
      state,
      _action: PayloadAction<ServiceAccountFormValues>,
    ) {
      state.itemLoadingState = loadingStateBuilder.inProgress();
    },
    addServiceAccountFailure(state, action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.failure(action.payload);
    },
    addServiceAccountSuccess(state, action: PayloadAction<ServiceAccount>) {
      state.itemLoadingState = loadingStateBuilder.success();
      serviceAccountsAdapter.addOne(state, action.payload);
    },
    updateServiceAccountStart(
      state,
      _action: PayloadAction<ServiceAccountFormValues & { id: string }>,
    ) {
      state.itemLoadingState = loadingStateBuilder.inProgress();
    },
    updateServiceAccountFailure(state, action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.failure(action.payload);
    },
    updateServiceAccountSuccess(
      state,
      action: PayloadAction<Update<ServiceAccount>>,
    ) {
      state.itemLoadingState = loadingStateBuilder.success();
      serviceAccountsAdapter.updateOne(state, action.payload);
    },
    removeServiceAccountStart(state, _action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.inProgress();
    },
    removeServiceAccountFailure(state, action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.failure(action.payload);
    },
    removeServiceAccountSuccess(state, action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.success();
      serviceAccountsAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetManagedWorkspaceId, () => initialState);
  },
});

export { serviceAccountsReducer };
export const {
  loadServiceAccountsStart,
  loadServiceAccountsSuccess,
  loadServiceAccountsFailure,
  addServiceAccountFailure,
  addServiceAccountStart,
  addServiceAccountSuccess,
  resetServiceAccountsLoadingState,
  updateServiceAccountFailure,
  updateServiceAccountStart,
  updateServiceAccountSuccess,
  removeServiceAccountFailure,
  removeServiceAccountStart,
  removeServiceAccountSuccess,
} = actions;
