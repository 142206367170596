import { push } from 'connected-react-router';
import {
  call,
  put,
  select,
  takeEvery,
  takeLatest,
  delay,
} from 'typed-redux-saga';

import {
  apiLoadCustomer,
  apiLoadIntentStatus,
  apiUpdatePaymentPlan,
} from '../../../../../api/requests/payment';
import { THREE_D_SECURE_INTENT_SUCCESS } from '../../../../../constants/threeDSecure';
import { PackageName } from '../../../../../constants/packageFeatures';
import {
  savePaymentInformationSuccess,
  threeDSecureError,
  threeDSecureSuccess,
  updatePaymentPlan,
} from '../../managedWorkspace/payments/payments.slice';
import { hideModals, showModal } from '../../../ui/modals/modals.slice';
import {
  resetFreeWorkspace,
  updateWorkspaceSubscription,
} from '../workspaces/workspaces.slice';
import { createWorkspaceWorkspaceIdSelector } from './createWorkspace.selectors';
import { handleError } from '../../../commonFeatures/errorHandler/errorHandler.actions';
import { notifyPaymentInformationSaved } from '../../../../atoms/payments/actions';
import { apiCreateWorkspace } from '../../../../../api/requests/workspace';
import { getErrorMessage } from '../../../../../api/utils';
import {
  createWorkspace,
  createWorkspaceFailure,
  createWorkspaceSuccess,
  navigateToWorkspace,
  saveWorkspacePaymentInformation,
  saveWorkspacePaymentInformationFailure,
  selectWorkspacePlan,
  updateWorkspacePlan,
  updateWorkspacePlanFailure,
  updateWorkspacePlanSuccess,
} from './createWorkspace.slice';
import { CREATE_WORKSPACE_ENTITY } from './createWorkspace.constants';
import { isErrorInvalidCoupon } from '../../../../atoms/payments/util';
import {
  packageIsDowngradingSelector,
  packageTitleByNameSelector,
} from '../../../commonFeatures/packageFeatures/packageFeatures.selectors';
import { saveBillingDetailsHandler } from '../../../../atoms/payments/saveBillingDetailsHandler';
import { savePaymentOptionHandler } from '../../../../atoms/payments/savePaymentOptionHandler';

function* savePaymentInformationHandler(
  action: ActionType<typeof saveWorkspacePaymentInformation>,
) {
  try {
    yield* call(saveBillingDetailsHandler, action.payload.workspaceId);

    const paymentOption = yield* call(
      savePaymentOptionHandler,
      action.payload.stripeRef,
      action.payload.elementsRef,
      action.payload.workspaceId,
    );
    yield* put(savePaymentInformationSuccess());

    if (paymentOption.redirectUrl) {
      yield* put(
        showModal({
          modalName: 'threeDSecure',
          modalProps: {
            redirectUrl: paymentOption.redirectUrl,
          },
        }),
      );
    } else {
      yield* put(notifyPaymentInformationSaved(action.payload.entityName));
    }
  } catch (error) {
    yield* put(
      saveWorkspacePaymentInformationFailure(
        getErrorMessage(error, 'Not able to update payment information'),
      ),
    );
  }
}

function* createWorkspaceHandler(action: ActionType<typeof createWorkspace>) {
  const data = action.payload;

  try {
    const response = yield* call(apiCreateWorkspace, data);
    const workspace = response.data;

    yield* put(createWorkspaceSuccess(workspace));
    yield* put(
      showModal({
        modalName: 'upgradeWorkspacePlan',
      }),
    );
  } catch (error) {
    yield* put(
      createWorkspaceFailure(
        getErrorMessage(error, 'Not able to create workspace'),
      ),
    );
  }
}

function* selectWorkspacePlanHandler(
  action: ActionType<typeof selectWorkspacePlan>,
) {
  const workspaceId = yield* select(createWorkspaceWorkspaceIdSelector);

  if (!workspaceId) return;
  if (action.payload === PackageName.Free) {
    yield* put(
      updatePaymentPlan({ targetPlanName: action.payload, workspaceId }),
    );
  } else {
    yield* put(
      showModal({
        modalName: 'projectListEditPaymentMethod',
      }),
    );
  }
}

function* threeDSecureCompletedHandler(
  action: ActionType<typeof threeDSecureSuccess>,
) {
  const { setupIntentId, pathname } = action.payload;

  if (!pathname.includes('workspaces')) return;

  try {
    const result = yield* call(apiLoadIntentStatus, setupIntentId);

    yield* put(hideModals());

    if (result.data.status === THREE_D_SECURE_INTENT_SUCCESS) {
      yield* put(notifyPaymentInformationSaved(CREATE_WORKSPACE_ENTITY));
    } else {
      yield* put(
        showModal({
          modalName: 'projectListEditPaymentMethod',
        }),
      );
      yield* put(
        yield* put(threeDSecureError({ message: '3DS authentication failed' })),
      );
    }
  } catch (error) {
    const errorMessage = 'Could not get 3DS result';

    yield* put(
      handleError({
        message: errorMessage,
        allowOutsideOfEditor: true,
        error,
      }),
    );
  }
}

// This saga was extracted from web/src/redux/atoms/payments/handleUpdatePaymentPlan.ts, it should be reused, when
// web/src/redux/pages/managedWorkspace/payments/reducer.js will be refactored to slices
function* updateWorkspacePlanHandler(
  action: ActionType<typeof updateWorkspacePlan>,
) {
  const { workspaceId, targetPlanName, coupon } = action.payload;

  try {
    yield* delay(2000);

    yield* call(apiUpdatePaymentPlan, {
      workspaceId,
      targetPlanName,
      coupon,
      scheduleUpgrade: undefined,
    });
    const { data: customer } = yield* apiLoadCustomer(workspaceId);

    yield* put(
      updateWorkspacePlanSuccess({
        workspaceId,
        customer,
      }),
    );
  } catch (error) {
    const defaultErrorMessage = 'Not able to update plan';
    const invalidCouponErrorMessage =
      'Invalid promotion coupon, please go back and try again or proceed without it';

    const apiErrorMessage = getErrorMessage(error, defaultErrorMessage);

    // If error is invalid coupon code use different error message due to different flow
    if (isErrorInvalidCoupon(apiErrorMessage)) {
      yield* put(
        updateWorkspacePlanFailure(
          `${defaultErrorMessage}: ${invalidCouponErrorMessage}`,
        ),
      );
    } else {
      yield* put(updateWorkspacePlanFailure(apiErrorMessage));
    }
  }
}

function* updateWorkspacePlanSuccessHandler(
  action: ActionType<typeof updateWorkspacePlanSuccess>,
) {
  const { workspaceId, customer } = action.payload;
  const { subscription } = customer;

  if (workspaceId) {
    const subscriptionTitle = yield* select((state: RootState) =>
      packageTitleByNameSelector(state, subscription.planCurrentPeriod),
    );

    // update also subscription and it's title directly in workspace
    if (subscriptionTitle) {
      yield* put(
        updateWorkspaceSubscription({
          id: workspaceId,
          subscription: subscription.planCurrentPeriod,
          subscriptionTitle,
        }),
      );
    }
  }

  const isDowngrade = yield* select(packageIsDowngradingSelector, subscription);

  yield* put(
    showModal({
      modalName: 'projectListPurchaseCompleted',
      modalProps: {
        subscription,
        isDowngrade,
      },
    }),
  );
}

function* navigateToWorkspaceHandler() {
  const workspaceId = yield* select(createWorkspaceWorkspaceIdSelector);

  yield* put(resetFreeWorkspace());
  yield* put(hideModals());
  yield* put(push(`/workspace/${workspaceId}/payments`));
}

function* paymentInformationSavedHandler(
  action: ActionType<typeof notifyPaymentInformationSaved>,
) {
  if (action.payload !== CREATE_WORKSPACE_ENTITY) return;

  yield* put(showModal({ modalName: 'projectListUpgradePlanConfirmation' }));
}

export function* createWorkspaceSaga() {
  yield* takeEvery(selectWorkspacePlan, selectWorkspacePlanHandler);
  yield* takeEvery(createWorkspace, createWorkspaceHandler);
  yield* takeEvery(
    notifyPaymentInformationSaved,
    paymentInformationSavedHandler,
  );
  yield* takeEvery(navigateToWorkspace, navigateToWorkspaceHandler);
  yield* takeEvery(
    saveWorkspacePaymentInformation,
    savePaymentInformationHandler,
  );
  yield* takeEvery(updateWorkspacePlan, updateWorkspacePlanHandler);
  yield* takeEvery(
    updateWorkspacePlanSuccess,
    updateWorkspacePlanSuccessHandler,
  );
  yield* takeLatest(threeDSecureSuccess, threeDSecureCompletedHandler);
}
