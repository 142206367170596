import { put, takeEvery, call, select, debounce } from 'typed-redux-saga';

import {
  apiLoadAutomatedLabelingJobs,
  apiLoadAutomatedLabelingEstimation,
  apiStartAutomatedLabelingJob,
} from '../../../../api/requests/automatedLabeling';
import { getErrorMessage } from '../../../../api/utils';
import { handleError } from '../../commonFeatures/errorHandler/errorHandler.actions';
import { activeProjectIdSelector } from '../project.selectors';
import {
  loadAutomatedLabelingJobsStart,
  loadAutomatedLabelingJobsSuccess,
  loadAutomatedLabelingJobsFailure,
  updateAutomatedLabelingJob,
  updateAutomatedLabelingJobStatusFromWs,
  updateAutomatedLabelingJobProgressFromWs,
  loadAutomatedLabelingEstimationStart,
  loadAutomatedLabelingEstimationSuccess,
  loadAutomatedLabelingEstimationFailure,
  startAutomatedLabelingJobStart,
  startAutomatedLabelingJobSuccess,
  startAutomatedLabelingJobFailure,
} from './automatedLabeling.slice';
import { AutomatedLabelingJobStatus } from '../../../../api/constants/automatedLabelling';
import {
  ModelFamily,
  modelFamilyApiToModelFamily,
} from '../../../../api/constants/modelFamily';

function* loadAutomatedLabelingJobsStartHandler(
  action: ActionType<typeof loadAutomatedLabelingJobsStart>,
) {
  try {
    const { projectId } = action.payload;

    const { data } = yield* call(apiLoadAutomatedLabelingJobs, { projectId });

    yield* put(loadAutomatedLabelingJobsSuccess(data));
  } catch (error) {
    const message = getErrorMessage(
      error,
      'Not able to load automated labeling jobs',
    );

    yield* put(loadAutomatedLabelingJobsFailure(message));
    yield* put(handleError({ message, error, allowOutsideOfEditor: true }));
  }
}

function* startAutomatedLabelingJobStartHandler(
  action: ActionType<typeof startAutomatedLabelingJobStart>,
) {
  try {
    const projectId = yield* select(activeProjectIdSelector);

    const { modelId, modelFamily, ...data } = action.payload;

    const {
      data: { jobId },
    } = yield* call(
      apiStartAutomatedLabelingJob,
      { modelId, projectId },
      { ...data, externalEventId: 'string' },
    );

    yield* put(
      startAutomatedLabelingJobSuccess({
        jobId,
        status: AutomatedLabelingJobStatus.INIT,
        startDate: new Date().toISOString(),
        imagesProcessed: null,
        modelFamily: modelFamily
          ? modelFamilyApiToModelFamily[modelFamily]
          : ModelFamily.Detector,
        progress: 0,
      }),
    );
  } catch (error) {
    const message = getErrorMessage(
      error,
      'Not able to start automated labeling job',
    );

    yield* put(startAutomatedLabelingJobFailure(message));
  }
}

function* loadAutomatedLabelingEstimationStartHandler(
  action: ActionType<typeof loadAutomatedLabelingEstimationStart>,
) {
  const projectId = yield* select(activeProjectIdSelector);

  try {
    const { modelId, datasetId, quantity } = action.payload;

    const { data } = yield* call(
      apiLoadAutomatedLabelingEstimation,
      { projectId },
      { modelId, datasetId, quantity },
    );

    yield* put(loadAutomatedLabelingEstimationSuccess(data));
  } catch (error) {
    const message = getErrorMessage(
      error,
      'Not able to load automated labeling credits estimation',
    );

    yield* put(loadAutomatedLabelingEstimationFailure(message));
    yield* put(handleError({ message, error, allowOutsideOfEditor: true }));
  }
}

function* updateAutomatedLabelingJobProgressFromWsHandler(
  action: ActionType<typeof updateAutomatedLabelingJobProgressFromWs>,
) {
  const { projectId, ...changes } = action.payload;

  const currentProjectId = yield* select(activeProjectIdSelector);

  if (projectId !== currentProjectId) return;

  yield* put(
    updateAutomatedLabelingJob({
      id: changes.jobId,
      changes: { ...changes, status: AutomatedLabelingJobStatus.RUNNING },
    }),
  );
}

function* updateAutomatedLabelingJobStatusFromWsHandler(
  action: ActionType<typeof updateAutomatedLabelingJobStatusFromWs>,
) {
  const { projectId } = action.payload;

  const currentProjectId = yield* select(activeProjectIdSelector);

  if (projectId !== currentProjectId) return;

  yield* put(loadAutomatedLabelingJobsStart({ projectId }));
}

export function* automatedLabelingSaga() {
  yield* takeEvery(
    loadAutomatedLabelingJobsStart,
    loadAutomatedLabelingJobsStartHandler,
  );
  yield* takeEvery(
    startAutomatedLabelingJobStart,
    startAutomatedLabelingJobStartHandler,
  );
  yield* debounce(
    500,
    loadAutomatedLabelingEstimationStart,
    loadAutomatedLabelingEstimationStartHandler,
  );
  yield* takeEvery(
    updateAutomatedLabelingJobProgressFromWs,
    updateAutomatedLabelingJobProgressFromWsHandler,
  );
  yield* takeEvery(
    updateAutomatedLabelingJobStatusFromWs,
    updateAutomatedLabelingJobStatusFromWsHandler,
  );
}
