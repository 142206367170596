import { TriggerIds } from '../../@types/editedProject/Trigger.types';
import { Feature } from '../../util/features.types';
import {
  ALLOW_FINGER_STORAGE_PREFIX,
  FEATURE_STORAGE_PREFIX,
  FILTERS_STORAGE_PREFIX,
  GUIDES_STORAGE_PREFIX,
  HIDE_ATOM_STRATEGY_WARNING_STORAGE_PREFIX,
  HIDE_DISABLE_SAM_WARNING_STORAGE_PREFIX,
  LIVE_UPDATE_TOGGLE_STORAGE_PREFIX,
  SUPPRESS_SIZE_WARNING_STORAGE_PREFIX,
  TRACKPAD_PANNING_STORAGE_PREFIX,
  VIDEO_COPIED_SEGMENT_STORAGE_PREFIX,
  VIDEO_GALLERY_STORAGE_PREFIX,
  VIDEO_HOTKEYS_PANEL_STORAGE_PREFIX,
  VIDEO_NO_ACCEPT_PREDICTION_STORAGE_PREFIX,
  VIDEO_PLAYBACK_SPEED_STORAGE_PREFIX,
  VIDEO_PREV_VOLUME_STORAGE_PREFIX,
  VIDEO_SEEK_TIME_STORAGE_PREFIX,
  VIDEO_SHOW_ZOOM_STORAGE_PREFIX,
  VIDEO_SIDEBAR_STORAGE_PREFIX,
  VIDEO_VOLUME_STORAGE_PREFIX,
  ZOOM_SPEED_STORAGE_PREFIX,
} from './constants';

export type FeatureStorageKey = `${typeof FEATURE_STORAGE_PREFIX}/${Feature}`;
export type TriggerStatusStorageKey = `${TriggerIds}-${string}`;
export type TriggerDataStorageKey = `${TriggerIds}-${string}-data`;

export type GeneralLocalStorageKey =
  typeof SUPPRESS_SIZE_WARNING_STORAGE_PREFIX;

export type ImageViewLocalStorageKey =
  | typeof ALLOW_FINGER_STORAGE_PREFIX
  | typeof FILTERS_STORAGE_PREFIX
  | typeof ZOOM_SPEED_STORAGE_PREFIX
  | typeof GUIDES_STORAGE_PREFIX
  | typeof TRACKPAD_PANNING_STORAGE_PREFIX
  | typeof HIDE_ATOM_STRATEGY_WARNING_STORAGE_PREFIX
  | typeof HIDE_DISABLE_SAM_WARNING_STORAGE_PREFIX
  | typeof LIVE_UPDATE_TOGGLE_STORAGE_PREFIX;

export type VideoViewLocalStorageKey =
  | typeof VIDEO_GALLERY_STORAGE_PREFIX
  | typeof VIDEO_HOTKEYS_PANEL_STORAGE_PREFIX
  | typeof VIDEO_SIDEBAR_STORAGE_PREFIX
  | typeof VIDEO_SHOW_ZOOM_STORAGE_PREFIX
  | typeof VIDEO_VOLUME_STORAGE_PREFIX
  | typeof VIDEO_PREV_VOLUME_STORAGE_PREFIX
  | typeof VIDEO_PLAYBACK_SPEED_STORAGE_PREFIX
  | typeof VIDEO_SEEK_TIME_STORAGE_PREFIX
  | typeof VIDEO_NO_ACCEPT_PREDICTION_STORAGE_PREFIX
  | typeof VIDEO_COPIED_SEGMENT_STORAGE_PREFIX;

export type LocalStorageKey =
  | GeneralLocalStorageKey
  | ImageViewLocalStorageKey
  | VideoViewLocalStorageKey
  | FeatureStorageKey
  | TriggerStatusStorageKey
  | TriggerDataStorageKey;

export type LocalStorageValue = string | number | boolean | object;

export const getFeatureStorageKey = (key: Feature): FeatureStorageKey =>
  `${FEATURE_STORAGE_PREFIX}/${key}`;

export const getTriggerStatusKey = (
  triggerId: TriggerIds,
  projectId: string,
): TriggerStatusStorageKey => `${triggerId}-${projectId}`;

export const getTriggerDataKey = (
  triggerId: TriggerIds,
  projectId: string,
): TriggerDataStorageKey => `${triggerId}-${projectId}-data`;
