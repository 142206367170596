export enum AdvancedOptionType {
  AutomatedToolModeFormKey = 'automatedToolsDefaultLabelType',
  ModelUseIE = 'pretrained',
}
export type AdvancedOption = Record<AdvancedOptionType, string | boolean>;

export enum AdvancedOptionApiType {
  AutomatedToolModeSettingKey = 'automated_tools_default_label_type',
  ModelUseIE = 'models.predictions.use_ie',
}
export type AdvancedOptionApi = Record<AdvancedOptionApiType, string | boolean>;

export type AdvancedOptionApiSetting = {
  settingValue: Partial<AdvancedOptionApi>;
};
