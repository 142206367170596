import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { setProjectId } from '../../../../core/imageView/project/project.slice';

type InitialState = {
  id: string | null;
};

const initialState: InitialState = {
  id: null,
};

const { actions, reducer: importSessionReducer } = createSlice({
  name: 'importSession',
  initialState,
  reducers: {
    setActiveImportSessionId(state, action: PayloadAction<string>) {
      state.id = action.payload;
    },
    resetActiveImportSessionId(state) {
      state.id = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setProjectId, () => initialState);
  },
});

export { importSessionReducer };
export const { setActiveImportSessionId, resetActiveImportSessionId } = actions;
