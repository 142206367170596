import { DefaultValue, atom, selector, selectorFamily } from 'recoil';

import {
  VideoPredictedSegment,
  VideoSegmentPredictionJob,
} from '../../api/domainModels/video';
import { createRecoilEntityAdapterFamily } from '../../helpers/recoil/entityAdapter';
import { hoveredSegmentIdState, selectedSegmentIdState } from './segment';
import { VideoSegmentPredictionJobStatus } from '../../api/constants/videos';

export const segmentPredictionCoil = createRecoilEntityAdapterFamily<
  VideoPredictedSegment,
  []
>({
  name: 'video/segmentPredictions',
  paramState: selector({
    key: 'segmentPredictionsParams',
    get: () => [],
  }),
  sortComparer: (a, b) => a.startTimeMs - b.startTimeMs,
  initialState: () => [],
});

export const predictedSegmentsByActivityIdState = selectorFamily({
  key: 'currentVideo/predictedSegments/byActivityId',
  get:
    (activityId: string) =>
    ({ get }) => {
      const predictedSegments = get(segmentPredictionCoil.selectAll);

      return predictedSegments.filter(
        (predictedSegment) => predictedSegment.activityTypeId === activityId,
      );
    },
});

export const hoveredPredictedSegmentState = selector({
  key: 'video/predictedSegments/hovered',
  get: ({ get }) => {
    const id = get(hoveredSegmentIdState);

    if (!id) return null;

    const predictedSegment = get(segmentPredictionCoil.selectById(id));

    return predictedSegment || null;
  },
  set: ({ set }, value: DefaultValue | VideoPredictedSegment | null) => {
    if (value && 'id' in value) {
      set(hoveredSegmentIdState, value.id);
    } else {
      set(hoveredSegmentIdState, null);
    }
  },
});

export const selectedPredictedSegmentState =
  selector<VideoPredictedSegment | null>({
    key: 'video/predictedSegments/selected',
    get: ({ get }) => {
      const id = get(selectedSegmentIdState);

      return id ? get(segmentPredictionCoil.selectById(id)) || null : null;
    },
    set: ({ set }, value: DefaultValue | VideoPredictedSegment | null) => {
      if (value && 'id' in value) {
        set(selectedSegmentIdState, value.id);
      } else {
        set(selectedSegmentIdState, null);
      }
    },
  });

// This is workaround atom for updating segmentPredictionCoil state from ws updates
// Because of implementation of RecoilEntityAdapterFamily we cannot update its state directly with setRecoil (recoil-nexus) method
// Instead in recoilWrapper.saga.ts we update this atom and in SegmentPredictionToolbar (which is responsible to maintaining this Coil)
// We use useRecoilCallback to repopulate segmentPredictionCoil state on any changes of this atom
export const pendingSegmentPredictionsState = atom<VideoPredictedSegment[]>({
  key: 'video/pendingSegmentPredictions',
  default: [],
});

// This atom is for initial segmentPredictionJob state we get on /status endpoint
// It is used to determine if prediction job is already done or not
// And just in case when job is done we use this atom state for segmentPredictionCoil to (re)fetch predictions
export const segmentPredictionJobInitialStatusState =
  atom<VideoSegmentPredictionJobStatus>({
    key: 'video/segmentPredictionJob/initialState',
    default: VideoSegmentPredictionJobStatus.Pending,
  });

// This atom is actual state of segmentPredictionJob (updated on trigger endpoints /start|pause or ws respective updates)
// It is used to show SegmentPredictionToolbar states and progress bar
export const segmentPredictionJobState = atom<VideoSegmentPredictionJob>({
  key: 'video/segmentPredictionJob',
  default: { status: VideoSegmentPredictionJobStatus.Pending, progress: 0 },
});

const toBeAcceptedPredictedSegmentIdState = atom<string | null>({
  key: 'video/predictedSegments/toBeAcceptedId',
  default: null,
});

export const toBeAcceptedPredictedSegmentState =
  selector<VideoPredictedSegment | null>({
    key: 'video/predictedSegments/toBeAcceptedSegment',
    get: ({ get }) => {
      const predictedSegmentId = get(toBeAcceptedPredictedSegmentIdState);

      if (!predictedSegmentId) {
        return null;
      }

      return get(segmentPredictionCoil.selectById(predictedSegmentId)) || null;
    },
    set: ({ set }, value) => {
      set(
        toBeAcceptedPredictedSegmentIdState,
        value && 'id' in value ? value.id : null,
      );
    },
  });
