import { combineReducers } from 'redux';

import { splitsReducer } from './splits/splits.reducer';
import { experimentsReducer } from './experiments/experiments.reducer';
import { activeExperimentReducer } from './activeExperiment/activeExperiment.reducer';
import { activeSplitReducer } from './activeSplit/activeSplit.reducer';
import { dashboardReducer } from './dashboard/dashboard.reducer';

export const modelPlaygroundReducer = combineReducers({
  splits: splitsReducer,
  experiments: experimentsReducer,
  activeExperiment: activeExperimentReducer,
  activeSplit: activeSplitReducer,
  dashboard: dashboardReducer,
});
