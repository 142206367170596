import { createSelector } from '@reduxjs/toolkit';

import { projectListAdapter } from './projects.slice';
import { workspaceIdSelector } from '../../../commonFeatures/workspaceId/workspaceId.selectors';
import { sectionsSelector } from '../../sections.selectors';
import { Project1 } from '../../../../../api/codegen/models/Project1';

const projectListStateSelector = createSelector(
  sectionsSelector,
  (sections) => sections.projectList.projects,
);
const selectors = projectListAdapter.getSelectors(projectListStateSelector);

export const projectListDataSelector = selectors.selectAll;
export const projectByIdSelector = selectors.selectById;
export const projectListLoadingStateSelector = createSelector(
  projectListStateSelector,
  (state) => state.loadingState,
);
export const archiveProjectLoadingStateSelector = createSelector(
  projectListStateSelector,
  (state) => state.archiveProjectLoadingState,
);

export const projectListSelector = selectors.selectAll;
export const projectListMapSelector = selectors.selectEntities;

export const currentWorkspaceProjectsSelector = createSelector(
  workspaceIdSelector,
  projectListSelector,
  (currentWorkspaceId, projects) =>
    projects.filter(({ workspaceId }) => workspaceId === currentWorkspaceId),
);

export const currentWorkspaceImageProjectsAsOptionsSelector = createSelector(
  currentWorkspaceProjectsSelector,
  (projects) =>
    projects
      .filter(({ contentType }) => contentType === Project1.contentType.IMAGES)
      .map(({ projectName, projectId }) => ({
        key: projectId,
        value: projectId,
        text: projectName,
      })),
);

export const isProProjectSelector = createSelector(
  projectListDataSelector,
  (_: RootState, projectId: string) => projectId,
  (projects, projectId) => {
    const project = projects.find((p) => p.projectId === projectId);

    return !!project?.isPro;
  },
);
