import { put, takeEvery } from 'typed-redux-saga';

import { resetActiveImportSessionId } from './importSession.slice';
import { resetImportFiles } from '../files/importFiles.slice';
import { resetUploadFiles } from '../uploads/importUploads.slice';
import { resetLabelClasses } from '../labelClass/importLabelClass.slice';
import { resetParseLogs } from '../parseLogs/importParseLogs.slice';

function* resetActiveSessionHandler() {
  yield* put(resetImportFiles());
  yield* put(resetUploadFiles());
  yield* put(resetLabelClasses());
  yield* put(resetParseLogs());
}

export function* importSessionSaga() {
  yield* takeEvery(resetActiveImportSessionId, resetActiveSessionHandler);
}
