/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Page to fetch (NEXT or PREV)
 */
export enum Page {
  NEXT = 'NEXT',
  PREV = 'PREV',
}
