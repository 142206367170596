import axios from 'axios';

import {
  ApiWebhook,
  ApiWebhookEvent,
  ApiLoadApiWebhookLogsResponse,
  ApiLoadProjectWebhookLogsParams,
  ApiLoadApiWebhookResponse,
  ApiLoadProjectWebhookParams,
  ApiCreateProjectWebhookParams,
  ApiUpdateProjectWebhookParams,
  ApiDeleteProjectWebhookParams,
} from '../schemas/webhook';
import { NEW_API_URL } from '../../constants/paths';
import { apiWrapper } from '../utils';
import {
  ApiDeleteResponse,
  headers,
  transformRequest,
  transformResponse,
} from '../helpers';

export const apiLoadProjectWebhookEvents = () =>
  apiWrapper(
    axios.request<ApiWebhookEvent[]>({
      headers,
      transformResponse,
      url: `${NEW_API_URL}/webhook-events`,
    }),
  );

export const apiLoadProjectWebhookLogs = (
  params: ApiLoadProjectWebhookLogsParams,
) =>
  apiWrapper(
    axios.request<ApiLoadApiWebhookLogsResponse>({
      headers,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/webhooks/${params.webhookId}/logs`,
      params: {
        offset: params.offset,
        limit: params.limit,
      },
    }),
  );

export const apiLoadProjectWebhooks = (params: ApiLoadProjectWebhookParams) =>
  apiWrapper(
    axios.request<ApiLoadApiWebhookResponse>({
      headers,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/webhooks`,
      params: {
        limit: params.limit,
        offset: params.offset,
      },
    }),
  );

export const apiCreateProjectWebhook = (
  params: ApiCreateProjectWebhookParams,
  data: Partial<ApiWebhook>,
) =>
  apiWrapper(
    axios.request<ApiWebhook>({
      method: 'post',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/webhooks`,
      data,
    }),
  );

export const apiUpdateProjectWebhook = (
  params: ApiUpdateProjectWebhookParams,
  data: Partial<ApiWebhook>,
) =>
  apiWrapper(
    axios.request<ApiWebhook>({
      method: 'put',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/webhooks/${params.webhookId}`,
      data,
    }),
  );

export const apiDeleteProjectWebhook = (
  params: ApiDeleteProjectWebhookParams,
) =>
  apiWrapper(
    axios.request<ApiDeleteResponse>({
      method: 'delete',
      headers,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/webhooks/${params.webhookId}`,
      data: null, // without this we got 415 status code
    }),
  );
