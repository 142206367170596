import { combineReducers } from 'redux';

import { atomResultReducer } from './atomResult.slice';
import { imageViewAtomValuesReducer } from './atomValues.slice';
import { imageViewAtomModelReducer } from './atomModel.slice';
import { atomEditingStateReducer } from './atomEditingState/atomEditingState.slice';

export const atomReducer = combineReducers({
  result: atomResultReducer,
  values: imageViewAtomValuesReducer,
  model: imageViewAtomModelReducer,
  editingState: atomEditingStateReducer,
});
