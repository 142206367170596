import {
  createSlice,
  PayloadAction,
  createEntityAdapter,
  Update,
} from '@reduxjs/toolkit';

import {
  ExperimentRun,
  RunStatus,
  RestartExperimentTrainingData,
} from '../../../../../../api/domainModels/modelPlayground';
import { loadingStateBuilder } from '../../../../../utils/loadingState';
import { setActiveExperimentId } from '../activeExperimentId/activeExperimentId.slice';
import { setEditedProjectId } from '../../../../sections/editedProject/project/project.slice';
import { ExperimentRunErrorCode } from '../../../../../../api/constants/modelPlayground';

export const adapter = createEntityAdapter<ExperimentRun>();
const initialState = adapter.getInitialState({
  loadingState: loadingStateBuilder.initial(),
  startLoadingState: loadingStateBuilder.initial(),
});

const { actions, reducer: experimentRunsReducer } = createSlice({
  name: 'experimentRuns',
  initialState,
  reducers: {
    loadRuns(state) {
      state.loadingState = loadingStateBuilder.inProgress('Loading runs');
      adapter.removeAll(state);
    },
    loadRunsFailure(state, action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.failure(action.payload);
    },
    loadRunsSuccess(state, action: PayloadAction<ExperimentRun[]>) {
      state.loadingState = loadingStateBuilder.success();
      state.startLoadingState = loadingStateBuilder.initial();

      adapter.setAll(state, action.payload);
    },
    updateRunFromWebsocket: {
      reducer(
        state,
        action: PayloadAction<{
          id: string;
          status: RunStatus;
          errorCode: ExperimentRunErrorCode | null;
          errorMessage: string | null;
        }>,
      ) {
        const { id, status, errorCode, errorMessage } = action.payload;
        adapter.updateOne(state, {
          id,
          changes: { trainStatus: status, errorCode, errorMessage },
        });
      },
      prepare(payload) {
        return {
          payload,
          meta: {
            noLogging: true,
          },
        };
      },
    },
    startRun(state, _action: PayloadAction<string | undefined>) {
      state.startLoadingState = loadingStateBuilder.inProgress(
        'Starting the experiment',
      );
    },
    startRunFailure(state, action: PayloadAction<string>) {
      state.startLoadingState = loadingStateBuilder.failure(action.payload);
    },
    startRunSuccess(state, action: PayloadAction<ExperimentRun>) {
      state.startLoadingState = loadingStateBuilder.success();
      adapter.addOne(state, action.payload);
    },
    restartRun(
      state,
      _action: PayloadAction<{
        experimentId: string;
        data: RestartExperimentTrainingData;
      }>,
    ) {
      state.loadingState = loadingStateBuilder.inProgress(
        'Restarting the experiment',
      );
    },
    restartRunFailure(state, action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.failure(action.payload);
    },
    restartRunSuccess(state, action: PayloadAction<Update<ExperimentRun>>) {
      state.loadingState = loadingStateBuilder.success();
      adapter.updateOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setActiveExperimentId, () => initialState);
    builder.addCase(setEditedProjectId, () => initialState);
  },
});

export { experimentRunsReducer };
export const {
  loadRuns,
  loadRunsSuccess,
  loadRunsFailure,
  updateRunFromWebsocket,
  startRun,
  startRunSuccess,
  startRunFailure,
  restartRun,
  restartRunSuccess,
  restartRunFailure,
} = actions;
