import { createSelector } from '@reduxjs/toolkit';

import { adapter } from './importLabelClass.slice';
import { editedProjectSelector } from '../../editedProject.selectors';

const importLabelClassesStateSelector = createSelector(
  editedProjectSelector,
  (editedProject) => editedProject.imports.labelClasses,
);

const selectors = adapter.getSelectors(importLabelClassesStateSelector);

export const importLabelClassesSelector = selectors.selectAll;

export const importLabelClassesIdsSelector = selectors.selectIds as (
  state: RootState,
) => string[]; // todo why as?

export const importLabelClassByIdSelector = selectors.selectById;

export const importLabelClassListLoadingStateSelector = createSelector(
  importLabelClassesStateSelector,
  (state) => state.listLoadingState,
);

export const ignoredImportLabelClassesSelector = createSelector(
  importLabelClassesSelector,
  (labelClasses) => labelClasses.filter((label) => label.ignoreClass),
);

export const notMatchedImportLabelClassesSelector = createSelector(
  importLabelClassesSelector,
  (labelClasses) =>
    labelClasses.filter((label) => !label.refClassId && !label.ignoreClass),
);

export const matchedImportLabelClassesSelector = createSelector(
  importLabelClassesSelector,
  (labelClasses) => labelClasses.filter((label) => label.refClassId),
);

export const isImportLabelMatchingFinishedSelector = createSelector(
  importLabelClassesSelector,
  ignoredImportLabelClassesSelector,
  matchedImportLabelClassesSelector,
  (all, ignored, matched) =>
    all.length > 0 && all.length === ignored.length + matched.length,
);
