import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RunCreditsEstimation } from '../../../../../../api/domainModels/consensusScoring';
import {
  loadingStateBuilder,
  LoadingState,
} from '../../../../../utils/loadingState';
import { ErrorFinderRunFormValuesInner } from '../runs.slice';

type CreditsEstimationState = {
  data: RunCreditsEstimation | null;
  loadingState: LoadingState;
};

const initialState: CreditsEstimationState = {
  data: null,
  loadingState: loadingStateBuilder.initial(),
};

const { actions, reducer: runCreditsEstimationReducer } = createSlice({
  name: 'runCreditsEstimation',
  initialState,
  reducers: {
    getRunCreditsEstimation(
      state,
      _action: PayloadAction<Partial<ErrorFinderRunFormValuesInner>>,
    ) {
      state.loadingState = loadingStateBuilder.inProgress();
      state.data = null;
    },
    getRunCreditsEstimationSuccess(
      state,
      action: PayloadAction<RunCreditsEstimation>,
    ) {
      state.loadingState = loadingStateBuilder.success();
      state.data = action.payload;
    },
    getRunCreditsEstimationFailure(state, action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.failure(action.payload);
    },
  },
});

export { runCreditsEstimationReducer };
export const {
  getRunCreditsEstimation,
  getRunCreditsEstimationSuccess,
  getRunCreditsEstimationFailure,
} = actions;
