import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  LoadingState,
  loadingStateBuilder,
} from '../../../../utils/loadingState';
import { Image } from '../../../../../api/domainModels/images';
import { ImageStatus } from '../../../../../api/domainModels/imageStatus';

type CurrentImageState = {
  imageId: string | null;
  offset: number | null;
  loadingState: LoadingState;
  imageObjectId: number | null;
  properties: Image | null;
  loadLimit: number;
};
const initialState: CurrentImageState = {
  imageId: null,
  offset: null,
  loadingState: loadingStateBuilder.initial(),
  imageObjectId: null,
  properties: null,
  loadLimit: 0,
};

const { actions, reducer: currentImageReducer } = createSlice({
  name: 'imageViewCurrentImage',
  initialState,
  reducers: {
    confirmImageId(state, action: PayloadAction<string>) {
      state.imageId = action.payload;
    },
    loadImage(
      _state,
      _action: PayloadAction<{ imageId: string; projectId: string }>,
    ) {},
    loadImageError(state, action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.failure(action.payload);
    },
    loadImageStart(state, action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.inProgress(action.payload);
    },
    loadImageSuccess(
      state,
      action: PayloadAction<{ imageObjectId: number; properties: Image }>,
    ) {
      state.imageObjectId = action.payload.imageObjectId;
      state.properties = action.payload.properties;
      state.loadingState = loadingStateBuilder.success();
    },
    setImageId(
      _state,
      _action: PayloadAction<{
        imageId: string;
        labelIdToSet?: string;
      }>,
    ) {},
    setImageOffset(state, action: PayloadAction<number>) {
      state.offset = action.payload;
    },
    setImageStatus(_state, _action: PayloadAction<ImageStatus>) {},
    setImageToDoneAndGoNext(_state) {},
    setImageStatusSuccess(
      state,
      action: PayloadAction<{
        status: ImageStatus;
        imageId: string;
      }>,
    ) {
      if (action.payload.imageId === state.imageId && state.properties) {
        state.properties.imageStatus = action.payload.status;
      }
    },
    setLoadLimit(state, action: PayloadAction<number>) {
      state.loadLimit = action.payload;
    },
    resetImageState(state) {
      state.loadingState = initialState.loadingState;
      state.properties = initialState.properties;
      state.imageId = initialState.imageId;
    },
    notifyIfImageIsLocked() {},
    renameCurrentImage(state, action: PayloadAction<{ name: string }>) {
      if (state.properties) {
        state.properties.imageName = action.payload.name;
      }
    },
  },
});

export const {
  confirmImageId,
  loadImage,
  loadImageError,
  loadImageStart,
  loadImageSuccess,
  setImageId,
  setImageOffset,
  setImageStatus,
  setImageToDoneAndGoNext,
  setImageStatusSuccess,
  setLoadLimit,
  resetImageState,
  notifyIfImageIsLocked,
  renameCurrentImage,
} = actions;
export { currentImageReducer };
