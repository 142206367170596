import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import {
  WidgetSemanticInferencesItem,
  SemanticInferencesParams,
} from '../../../../../../../api/domainModels/widgets';
import { ApiPaginationMeta } from '../../../../../../../api/helpers';
import { loadingStateBuilder } from '../../../../../../utils/loadingState';
import { setEditedProjectId } from '../../../../../sections/editedProject/project/project.slice';

type WidgetSemanticInference = WidgetSemanticInferencesItem & { id: string };

export const itemsAdapter = createEntityAdapter<WidgetSemanticInference>();

const initialState = {
  loadingState: loadingStateBuilder.initial(),
  items: itemsAdapter.getInitialState(),
  meta: null as ApiPaginationMeta | null,
  offset: 0,
  limit: 2,
  opacity: 0.5,
};

const { actions, reducer: semanticInferencesReducer } = createSlice({
  name: 'widgets/semanticInferences',
  initialState,
  reducers: {
    loadSemanticInferences(
      state,
      action: PayloadAction<Partial<SemanticInferencesParams>>,
    ) {
      state.loadingState = loadingStateBuilder.inProgress();
      if (action.payload.offset) {
        state.offset = action.payload.offset;
      }
      if (action.payload.limit) {
        state.limit = action.payload.limit;
      }
    },
    loadSemanticInferencesFailure(state, action: PayloadAction<string>) {
      itemsAdapter.removeAll(state.items);
      state.loadingState = loadingStateBuilder.failure(action.payload);
    },
    loadSemanticInferencesSuccess(
      state,
      action: PayloadAction<{
        items: WidgetSemanticInference[];
        meta: ApiPaginationMeta;
      }>,
    ) {
      itemsAdapter.removeAll(state.items);
      state.loadingState = loadingStateBuilder.success();
      const { items, meta } = action.payload;
      itemsAdapter.setAll(state.items, items);
      state.meta = meta;
    },
    setSemanticInferenceOpacity(
      state,
      action: PayloadAction<{ opacity: number }>,
    ) {
      state.opacity = action.payload.opacity;
    },
    resetWidget() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setEditedProjectId, () => initialState);
  },
});

export { semanticInferencesReducer };
export const {
  loadSemanticInferences,
  loadSemanticInferencesSuccess,
  loadSemanticInferencesFailure,
  setSemanticInferenceOpacity,
  resetWidget,
} = actions;
