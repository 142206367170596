import { createAction } from '@reduxjs/toolkit';

import { JobProgress } from '../../../constants/jobProgress';

const prefix = 'NOTIFICATION';

export const subscribeSubject = createAction<{
  identifier: 'project' | 'user';
  id: string;
  authKey: string;
  eventsList?: string[];
  logMessages?: boolean;
}>(`${prefix}/subscribeSubject`);

export const unsubscribeSubject = createAction<{
  identifier: 'project' | 'user';
}>(`${prefix}/unsubscribeSubject`);

export const notifyJobProgress = createAction<JobProgress>(
  `${prefix}/jobProgress`,
);
