import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Project } from '../../../../../api/domainModels/project';
import {
  LoadingState,
  loadingStateBuilder,
} from '../../../../utils/loadingState';
import { setProjectId } from '../../../core/imageView/project/project.slice';

export type ProjectState = {
  projectId: string | null;
  loadingState: LoadingState;
  updateLoadingState: LoadingState;
  data: Project | null;
};

const initialState: ProjectState = {
  projectId: null,
  loadingState: loadingStateBuilder.initial(),
  updateLoadingState: loadingStateBuilder.initial(),
  data: null,
};

const { actions, reducer: projectReducer } = createSlice({
  name: 'editedProject/project',
  initialState,
  reducers: {
    setEditedProjectId: (
      state,
      action: PayloadAction<{ id: string; overrideEventsList?: number[] }>,
    ) => {
      state.projectId = action.payload.id;
    },
    resetEditedProjectId: (state, _action: PayloadAction) => {
      state.projectId = null;
    },
    loadProject: (
      state,
      _action: PayloadAction<{ id: string; overrideEventsList?: number[] }>,
    ) => {
      state.loadingState = loadingStateBuilder.inProgress('Loading project');
    },
    loadProjectFailure: (state, action: PayloadAction<string>) => {
      state.loadingState = loadingStateBuilder.failure(action.payload);
    },
    loadProjectSuccess: (state, action: PayloadAction<Project>) => {
      state.loadingState = loadingStateBuilder.success();
      state.data = action.payload;
    },
    updateProject: (state, _action: PayloadAction<Partial<Project>>) => {
      state.updateLoadingState =
        loadingStateBuilder.inProgress('Updating project');
    },
    updateProjectFailure: (state, action: PayloadAction<string>) => {
      state.updateLoadingState = loadingStateBuilder.failure(action.payload);
    },
    updateProjectSuccess: (state, action: PayloadAction<Project>) => {
      state.updateLoadingState = loadingStateBuilder.success();
      state.data = { ...state.data, ...action.payload }; // PUT project does not return full contents of GET
    },
    initImageProject: (_state, _action: PayloadAction<{ id: string }>) => {},
    initVideoProject: (_state, _action: PayloadAction<{ id: string }>) => {},
    goToAutomatedLabeling: (_state, _action: PayloadAction) => {},
  },
  extraReducers: (builder) => {
    builder.addCase(setProjectId, () => initialState);
  },
});

export const {
  setEditedProjectId,
  resetEditedProjectId,
  loadProject,
  loadProjectFailure,
  loadProjectSuccess,
  updateProject,
  updateProjectFailure,
  updateProjectSuccess,
  initImageProject,
  initVideoProject,
  goToAutomatedLabeling,
} = actions;

export { projectReducer };
