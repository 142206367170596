import {
  call,
  select,
  takeLatest,
  put,
  all,
  takeEvery,
} from 'typed-redux-saga';

import {
  apiLoadProjectImageAttributes,
  apiUpdateProjectImageLabelAttribute,
} from '../../../../../api/requests/attribute';
import { getErrorMessage } from '../../../../../api/utils';
import { selectedLabelsIdsSelector } from '../labels/selectedLabels/selectedLabels.selectors';
import { setSelectedLabelsIds } from '../labels/selectedLabels/selectedLabels.slice';
import { loadAttributesPrediction } from '../tools/labelAttributesPrediction/labelAttributesPrediction.slice';
import {
  loadLabelAttributeValuesFailure,
  loadLabelAttributeValuesStart,
  loadLabelAttributeValuesSuccess,
  updateLabelAttributeValueFailure,
  updateLabelAttributeValueStart,
  updateLabelAttributeValueSuccess,
  addLabelAttributeValues,
  addLabelAttributeValuesSuccess,
} from './labelAttributeValues.slice';
import { activeProjectIdSelector } from '../../../project/project.selectors';
import { syncLabelsSuccess } from '../labels/labelSync/labelSync.slice';
import {
  deleteACRelationByWebsocket,
  updateACRelationByWebsocket,
} from '../../../project/annotationTaxonomy/ACRelations/ACRelations.slice';
import {
  removeAttributeFromWebsockets,
  upsertAttributeFromWebsockets,
} from '../../../project/annotationTaxonomy/attributes/attributes.slice';
import { attributesSelector } from '../../../project/annotationTaxonomy/attributes/attributes.selectors';
import {
  deleteKCRelationByWebsocket,
  updateKCRelationByWebsocket,
} from '../../../project/annotationTaxonomy/KCRelations/KCRelations.slice';
import {
  LabelAttributeValue,
  LoadProjectImageAttributesParams,
} from '../../../../../api/domainModels/attribute';
import { imageViewImageIdSelector } from '../currentImage/currentImage.selectors';
import { loadAllKeysetPagination } from '../../../../utils/api';
import { loadImageSuccess } from '../currentImage/currentImage.slice';
import { attributeValuesByLabelIdSelector } from './labelAttributeValues.selectors';
import {
  addNewAttributeToLabelSuccess,
  changeAttributeReviewErrorActionSuccess,
  removeExistingAttributeFromLabelSuccess,
} from '../../errorFinder/labels/attributeReview.slice';
import { ErrorType } from '../../../../../api/domainModels/consensusScoring';
import { ErrorFinderAction } from '../../../../../api/constants/consensusScoring';
import { AttributeType } from '../../../../../api/constants/attribute';

function* listHandler() {
  try {
    const projectId = yield* select(activeProjectIdSelector);
    const imageId = yield* select(imageViewImageIdSelector);

    if (!projectId || !imageId) {
      return;
    }

    yield* put(loadLabelAttributeValuesStart());

    const params: LoadProjectImageAttributesParams = {
      projectId,
      imageId,
      limit: 1000,
    };

    const data = yield* loadAllKeysetPagination<LabelAttributeValue>(
      apiLoadProjectImageAttributes,
      params,
    );

    yield* put(loadLabelAttributeValuesSuccess(data));
  } catch (e) {
    yield* put(
      loadLabelAttributeValuesFailure(
        getErrorMessage(e, 'Could not load image label attributes'),
      ),
    );
  }
}

function* selectionHandler() {
  const selectedLabelIds = yield* select(selectedLabelsIdsSelector);
  const attributes = yield* select(attributesSelector);

  if (attributes.length === 0) return;

  if (selectedLabelIds.length === 1) {
    yield* put(loadAttributesPrediction());
  }
}

function* updateHandler(
  action: ActionType<typeof updateLabelAttributeValueStart>,
) {
  try {
    const projectId = yield* select(activeProjectIdSelector);
    const { data } = yield* call(
      apiUpdateProjectImageLabelAttribute,
      {
        projectId,
        attributeId: action.payload.id,
        labelId: action.payload.labelId,
      },
      action.payload,
    );
    yield* put(
      updateLabelAttributeValueSuccess({
        ...action.payload,
        ...data,
      }),
    );
  } catch (e) {
    yield* put(
      updateLabelAttributeValueFailure(
        getErrorMessage(e, 'Could not update image label attribute'),
      ),
    );
  }
}

// handler when copy label to copy its attribute values
function* addLabelAttributeValuesHandler(
  action: ActionType<typeof addLabelAttributeValues>,
) {
  const data = action.payload;

  yield* all(
    data.map(function* ({ copiedFrom: copiedFromLabelId, id: labelId }) {
      if (!copiedFromLabelId) return;

      const copiedLabelAttributes = yield* select(
        attributeValuesByLabelIdSelector(copiedFromLabelId),
      );

      const newLabelAttributes = copiedLabelAttributes.map((attr) => ({
        ...attr,
        labelId,
      }));

      yield* put(addLabelAttributeValuesSuccess(newLabelAttributes));
    }),
  );
}

function* changeAttributeReviewErrorActionSuccessHandler(
  action: ActionType<typeof changeAttributeReviewErrorActionSuccess>,
) {
  const { errorType, efAction, curValueId, predValueId, attributeId, labelId } =
    action.payload;

  if (
    curValueId &&
    [ErrorType.ExtraAttribute, ErrorType.Misclassification].includes(
      errorType,
    ) &&
    [ErrorFinderAction.Accept, ErrorFinderAction.ChangedManually].includes(
      efAction,
    )
  ) {
    yield* call(removeExistingAttributeValueSuccessHandler, {
      attributeId,
      labelId,
      attributeToRemoveId: curValueId,
    });
  }

  if (
    predValueId &&
    [ErrorType.MissingAttribute, ErrorType.Misclassification].includes(
      errorType,
    ) &&
    [ErrorFinderAction.Accept, ErrorFinderAction.ChangedManually].includes(
      efAction,
    )
  ) {
    yield* call(addAttributeValueSuccessHandler, {
      attributeId,
      labelId,
      attributeToAddId: predValueId,
    });
  }
}

function* addNewAttributeToLabelSuccessHandler(
  action: ActionType<typeof addNewAttributeToLabelSuccess>,
) {
  const { attributeId, labelId, valueId } = action.payload;

  yield* call(addAttributeValueSuccessHandler, {
    attributeId,
    labelId,
    attributeToAddId: valueId,
  });
}

function* removeExistingAttributeFromLabelSuccessHandler(
  action: ActionType<typeof removeExistingAttributeFromLabelSuccess>,
) {
  const { attributeId, labelId, attributeToRemoveId } = action.payload;

  yield* call(removeExistingAttributeValueSuccessHandler, {
    attributeId,
    labelId,
    attributeToRemoveId,
  });
}

function* addAttributeValueSuccessHandler({
  labelId,
  attributeId,
  attributeToAddId,
}: {
  labelId: string;
  attributeId: string;
  attributeToAddId: string;
}) {
  const labelAttributes = yield* select(
    attributeValuesByLabelIdSelector(labelId),
  );

  const labelAttribute = labelAttributes.find(({ id }) => id === attributeId);

  if (labelAttribute) {
    const newValue =
      labelAttribute.type === AttributeType.Multi
        ? [...(labelAttribute.value as string[]), attributeToAddId]
        : attributeToAddId;

    yield* put(
      updateLabelAttributeValueSuccess({
        ...labelAttribute,
        value: newValue,
      }),
    );
  }
}

function* removeExistingAttributeValueSuccessHandler({
  labelId,
  attributeId,
  attributeToRemoveId,
}: {
  labelId: string;
  attributeId: string;
  attributeToRemoveId: string;
}) {
  const labelAttributes = yield* select(
    attributeValuesByLabelIdSelector(labelId),
  );

  const labelAttribute = labelAttributes.find(({ id }) => id === attributeId);

  if (labelAttribute) {
    const newValue =
      labelAttribute.type === AttributeType.Multi
        ? (labelAttribute.value as string[])?.filter(
            (value) => value !== attributeToRemoveId,
          )
        : null;

    yield* put(
      updateLabelAttributeValueSuccess({
        ...labelAttribute,
        value: newValue,
      }),
    );
  }
}

export function* labelAttributesValuesSaga() {
  yield* takeLatest(
    [
      syncLabelsSuccess,
      updateACRelationByWebsocket,
      deleteACRelationByWebsocket,
      updateKCRelationByWebsocket,
      deleteKCRelationByWebsocket,
      upsertAttributeFromWebsockets,
      removeAttributeFromWebsockets,
      setSelectedLabelsIds,
    ],
    selectionHandler,
  );
  yield* takeLatest(updateLabelAttributeValueStart, updateHandler);
  yield* takeLatest(addLabelAttributeValues, addLabelAttributeValuesHandler);
  yield* takeLatest([loadImageSuccess], listHandler);
  yield* takeEvery(
    changeAttributeReviewErrorActionSuccess,
    changeAttributeReviewErrorActionSuccessHandler,
  );
  yield* takeEvery(
    removeExistingAttributeFromLabelSuccess,
    removeExistingAttributeFromLabelSuccessHandler,
  );
  yield* takeEvery(
    addNewAttributeToLabelSuccess,
    addNewAttributeToLabelSuccessHandler,
  );
}
