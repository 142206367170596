import { createSelector } from '@reduxjs/toolkit';

import { adapter } from './importSessions.slice';
import { editedProjectSelector } from '../../editedProject.selectors';

const importSessionStateSelector = createSelector(
  editedProjectSelector,
  (editedProject) => editedProject.imports.sessions,
);

const selectors = adapter.getSelectors(importSessionStateSelector);

export const importSessionsSelector = selectors.selectAll;
export const importSessionByIdSelector = selectors.selectById;

export const importSessionListLoadingStateSelector = createSelector(
  importSessionStateSelector,
  (state) => state.listLoadingState,
);

export const importSessionItemLoadingStateSelector = createSelector(
  importSessionStateSelector,
  (state) => state.itemLoadingState,
);
