import { createSelector } from '@reduxjs/toolkit';

import { widgetsSelector } from '../widgets.selectors';
import { itemsAdapter } from './classificationInferences.slice';

const classificationInferencesSelector = createSelector(
  widgetsSelector,
  (widgets) => widgets.classificationInferences,
);

export const classificationInferencesLoadingStateSelector = createSelector(
  classificationInferencesSelector,
  ({ loadingState }) => loadingState,
);

export const classificationInferencesItemsStateSelector = createSelector(
  classificationInferencesSelector,
  ({ items }) => items,
);

export const classificationInferencesLimitSelector = createSelector(
  classificationInferencesSelector,
  ({ limit }) => limit,
);

export const classificationInferencesOffsetSelector = createSelector(
  classificationInferencesSelector,
  ({ offset }) => offset,
);

const itemsSelectors = itemsAdapter.getSelectors(
  classificationInferencesItemsStateSelector,
);

export const classificationInferencesItemsSelector = itemsSelectors.selectAll;

export const classificationInferencesMetaSelector = createSelector(
  classificationInferencesSelector,
  ({ meta }) => meta,
);
