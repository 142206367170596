import { takeEvery, select, put } from 'typed-redux-saga';

import { confirmImageId } from '../currentImage/currentImage.slice';
import { activeProjectIdSelector } from '../../../project/project.selectors';
import { persistImageId } from './persistedProjectImage.slice';

function* confirmImageIdHandler(action: ActionType<typeof confirmImageId>) {
  const projectId = yield* select(activeProjectIdSelector);

  yield* put(
    persistImageId({
      projectId,
      imageId: action.payload,
    }),
  );
}

export function* persistedProjectImageSaga() {
  yield* takeEvery(confirmImageId, confirmImageIdHandler);
}
