import { put, takeEvery } from 'typed-redux-saga';

import { resetProject } from '../../imageView.actions';
import { updateObjectDetectionModel } from '../objectDetection/objectDetection.slice';
import { updateSemanticSegmentationModel } from '../semanticSegmentation/semanticSegmentation.slice';
import { updateInstanceSegmentationModel } from '../instanceSegmentation/instanceSegmentation.slice';
import { updateImageTagsPredictionModel } from '../imageTagsPrediction/imageTagsPrediction.slice';
import {
  updateAutomatedLabelingModelFromToolModels,
  resetModelState,
} from './automatedLabeling.slice';

function* modelLoadedHandler(
  action: ActionType<
    | typeof updateObjectDetectionModel
    | typeof updateSemanticSegmentationModel
    | typeof updateInstanceSegmentationModel
    | typeof updateImageTagsPredictionModel
  >,
) {
  yield* put(updateAutomatedLabelingModelFromToolModels(action.payload));
}

function* resetProjectHandler() {
  yield* put(resetModelState());
}

export function* automatedLabelingSaga() {
  yield* takeEvery(
    [
      updateObjectDetectionModel,
      updateSemanticSegmentationModel,
      updateInstanceSegmentationModel,
      updateImageTagsPredictionModel,
    ],
    modelLoadedHandler,
  );
  yield* takeEvery(resetProject, resetProjectHandler);
}
