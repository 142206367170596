import { call, put, takeEvery, select } from 'typed-redux-saga';

import { apiLoadRunErrorsPerClass } from '../../../../../../api/requests/consensusScoring';
import { getErrorMessage } from '../../../../../../api/utils';
import { activeProjectIdSelector } from '../../../../project/project.selectors';
import {
  loadErrorsPerClass,
  loadErrorsPerClassFailure,
  loadErrorsPerClassSuccess,
} from './errorsPerClass.slice';
import { enqueueNotification } from '../../../../ui/stackNotifications/stackNotifications.slice';

function* loadErrorsPerClassHandler(
  action: ActionType<typeof loadErrorsPerClass>,
) {
  const { runId } = action.payload;
  try {
    const projectId = yield* select(activeProjectIdSelector);

    const { data } = yield* call(apiLoadRunErrorsPerClass, {
      projectId,
      runId,
    });
    yield* put(loadErrorsPerClassSuccess(data));
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to load errors per class');

    yield* put(loadErrorsPerClassFailure(message));
    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
        },
      }),
    );
  }
}

export function* runErrorsPerClassSaga() {
  yield* takeEvery(loadErrorsPerClass, loadErrorsPerClassHandler);
}
