import React from 'react';
import { Alert, Box, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { FallbackProps } from 'react-error-boundary';

import { getErrorMessage } from '../../../api/utils';

export type ErrorIndicatorProps = {
  message?: string;
  styleProps?: SxProps<Theme>;
  tiny?: boolean;
};

export const ErrorIndicator: React.FC<ErrorIndicatorProps> = ({
  message,
  styleProps,
  tiny = false,
}) => {
  const displayMessage =
    typeof message === 'string'
      ? message
          .split('\n')
          // eslint-disable-next-line react/no-array-index-key
          .map((line, index) => <div key={String(index)}>{line}</div>)
      : 'Error';
  const icon = !tiny ? undefined : false;

  return (
    <Box sx={styleProps}>
      <Alert severity="error" icon={icon}>
        {displayMessage}
      </Alert>
    </Box>
  );
};

export const ErrorFallback = ({ error }: FallbackProps) => (
  <ErrorIndicator message={getErrorMessage(error)} />
);
