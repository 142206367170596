import { call, put, takeEvery, select } from 'typed-redux-saga';

import { apiLoadRunConfusionMatrix } from '../../../../../../api/requests/consensusScoring';
import { getErrorMessage } from '../../../../../../api/utils';
import { activeProjectIdSelector } from '../../../../project/project.selectors';
import {
  loadConfusionMatrix,
  loadConfusionMatrixFailure,
  loadConfusionMatrixSuccess,
} from './confusionMatrix.slice';
import { enqueueNotification } from '../../../../ui/stackNotifications/stackNotifications.slice';

function* loadConfusionMatrixHandler(
  action: ActionType<typeof loadConfusionMatrix>,
) {
  const { runId } = action.payload;

  try {
    const projectId = yield* select(activeProjectIdSelector);

    const { data } = yield* call(apiLoadRunConfusionMatrix, {
      projectId,
      runId,
    });
    yield* put(loadConfusionMatrixSuccess(data));
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to load histogram data');

    yield* put(loadConfusionMatrixFailure(message));
    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
        },
      }),
    );
  }
}

export function* runConfusionMatrixSaga() {
  yield* takeEvery(loadConfusionMatrix, loadConfusionMatrixHandler);
}
