/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Error finder action
 */
export enum EFActionQuery {
  NOT_AN_ERROR = 'NOT_AN_ERROR',
  CHANGE_CLASS = 'CHANGE_CLASS',
  CHANGED_MANUALLY = 'CHANGED_MANUALLY',
  CHANGE_SHAPE = 'CHANGE_SHAPE',
  ADD_LABEL = 'ADD_LABEL',
  DELETE_LABEL = 'DELETE_LABEL',
  NONE = 'NONE',
}
