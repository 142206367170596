import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  value: '',
};
const { actions, reducer: workspaceIdReducer } = createSlice({
  name: 'workspaceId',
  initialState,
  reducers: {
    setManagedWorkspaceId(
      state,
      action: PayloadAction<{
        workspaceId: string;
      }>,
    ) {
      state.value = action.payload.workspaceId;
    },
    resetManagedWorkspaceId() {
      return initialState;
    },
    setWorkspaceId(
      state,
      action: PayloadAction<{
        workspaceId: string;
      }>,
    ) {
      state.value = action.payload.workspaceId;
    },
  },
});
export { workspaceIdReducer };
export const {
  setManagedWorkspaceId,
  resetManagedWorkspaceId,
  setWorkspaceId,
} = actions;
