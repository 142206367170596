import axios from 'axios';

import {
  ApiExplainabilityRunFormValues,
  ApiClassificationInferencesParams,
  ApiClassificationInferencesResponse,
  ApiWidgetTrainingParamsVisualization,
  ApiExplainabilityRun,
  ApiLoadTrainingParamsVisualizationParams,
  ApiExperimentMetricsParams,
  ApiExperimentMetricsData,
  ApiExperimentMetricsResponse,
  ApiExperimentMetricsMetaResponse,
  ApiTaggerInferencesApiParams,
  ApiTaggerInferencesResponse,
  ApiDetectorInferencesApiParams,
  ApiDetectorInferencesResponse,
  ApiSegmentorInferencesApiParams,
  ApiSegmentorInferencesResponse,
  ApiSemanticInferencesApiParams,
  ApiSemanticInferencesResponse,
  ApiAttributerInferencesApiParams,
  ApiAttributerInferencesResponse,
  ApiGetMetricsPerIterationParams,
  ApiMetricsPerIterationResponse,
  ApiLoadExplainabilityRunsParams,
  ApiLoadApiExplainabilityRunsResponse,
  ApiExplainabilityRunResultsResponse,
  ApiExplainabilityRunResultsParams,
  ApiLoadExplainabilityMethodsParams,
  ApiLoadApiExplainabilityMethodsResponse,
} from '../schemas/widgets';
import { apiWrapper } from '../utils';
import { NEW_API_URL } from '../../constants/paths';
import { headers, transformRequest, transformResponse } from '../helpers';
import { convertToBackendObject } from '../../redux/utils/backendParams';

export const apiGetWidgetClassificationInferences = ({
  projectId,
  experimentId,
  ...params
}: ApiClassificationInferencesParams) =>
  apiWrapper(
    axios.request<ApiClassificationInferencesResponse>({
      method: 'get',
      headers,
      transformResponse,
      url: `${NEW_API_URL}/widgets/${projectId}/experiments/${experimentId}/classification_inferences`,
      params: params ? convertToBackendObject(params) : null,
    }),
  );

export const apiLoadTrainingParamsVisualization = (
  params: ApiLoadTrainingParamsVisualizationParams,
) =>
  apiWrapper(
    axios.request<ApiWidgetTrainingParamsVisualization>({
      headers,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/experiments/${params.experimentId}/training_params_visualization`,
    }),
  );

export const apiLoadWidgetExperimentsMetrics = (
  params: ApiExperimentMetricsParams,
  data: ApiExperimentMetricsData,
) =>
  apiWrapper(
    axios.request<ApiExperimentMetricsResponse>({
      method: 'post',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/widgets/${params.projectId}/experiment_metrics`,
      data,
    }),
  );

export const apiLoadWidgetExperimentsMetricsMeta = (
  params: ApiExperimentMetricsParams,
) =>
  apiWrapper(
    axios.request<ApiExperimentMetricsMetaResponse>({
      method: 'get',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/widgets/${params.projectId}/experiment_metrics/meta`,
    }),
  );

export const apiGetWidgetTaggerInferences = ({
  projectId,
  experimentId,
  params,
}: ApiTaggerInferencesApiParams) =>
  apiWrapper(
    axios.request<ApiTaggerInferencesResponse>({
      method: 'get',
      headers,
      transformResponse,
      url: `${NEW_API_URL}/widgets/${projectId}/experiments/${experimentId}/tagger_inferences`,
      params: params ? convertToBackendObject(params) : null,
    }),
  );

export const apiGetWidgetDetectorInferences = ({
  projectId,
  experimentId,
  params,
}: ApiDetectorInferencesApiParams) =>
  apiWrapper(
    axios.request<ApiDetectorInferencesResponse>({
      method: 'get',
      headers,
      transformResponse,
      url: `${NEW_API_URL}/widgets/${projectId}/experiments/${experimentId}/detector_inferences`,
      params: params ? convertToBackendObject(params) : null,
    }),
  );

export const apiGetWidgetSegmentorInferences = ({
  projectId,
  experimentId,
  params,
}: ApiSegmentorInferencesApiParams) =>
  apiWrapper(
    axios.request<ApiSegmentorInferencesResponse>({
      method: 'get',
      headers,
      transformResponse,
      url: `${NEW_API_URL}/widgets/${projectId}/experiments/${experimentId}/segmentor_inferences`,
      params: params ? convertToBackendObject(params) : null,
    }),
  );

export const apiGetWidgetSemanticInferences = ({
  projectId,
  experimentId,
  params,
}: ApiSemanticInferencesApiParams) =>
  apiWrapper(
    axios.request<ApiSemanticInferencesResponse>({
      method: 'get',
      headers,
      transformResponse,
      url: `${NEW_API_URL}/widgets/${projectId}/experiments/${experimentId}/semseg_inferences`,
      params: params ? convertToBackendObject(params) : null,
    }),
  );

export const apiGetWidgetAttributerInferences = ({
  projectId,
  experimentId,
  params,
}: ApiAttributerInferencesApiParams) =>
  apiWrapper(
    axios.request<ApiAttributerInferencesResponse>({
      method: 'get',
      headers,
      transformResponse,
      url: `${NEW_API_URL}/widgets/${projectId}/experiments/${experimentId}/attributer_inferences`,
      params: params ? convertToBackendObject(params) : null,
    }),
  );

export const apiLoadMetricsPerIteration = (
  params: ApiGetMetricsPerIterationParams,
  data: { experimentIds: string[] },
) =>
  apiWrapper(
    axios.request<ApiMetricsPerIterationResponse>({
      method: 'post',
      headers,
      transformRequest,
      transformResponse,
      data,
      url: `${NEW_API_URL}/training/${params.projectId}/experiment_data`,
    }),
  );

export const apiLoadExplainabityRuns = (
  params: ApiLoadExplainabilityRunsParams,
) =>
  apiWrapper(
    axios.request<ApiLoadApiExplainabilityRunsResponse>({
      method: 'get',
      headers,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/experiments/${params.experimentId}/explainability`,
      params: {
        limit: params.limit,
        offset: params.offset,
      },
    }),
  );

export const apiAddExplainabilityRun = (
  params: { projectId: string; experimentId: string },
  data: ApiExplainabilityRunFormValues,
) =>
  apiWrapper(
    axios.request<ApiExplainabilityRun>({
      method: 'post',
      headers,
      transformResponse,
      transformRequest,
      url: `${NEW_API_URL}/projects/${params.projectId}/experiments/${params.experimentId}/explainability`,
      data,
    }),
  );

export const apiDeleteExplainabilityRun = (params: {
  projectId: string;
  experimentId: string;
  explainabilityRunId: string;
}) =>
  apiWrapper(
    axios.request({
      method: 'delete',
      headers,
      transformResponse,
      transformRequest,
      url: `${NEW_API_URL}/projects/${params.projectId}/experiments/${params.experimentId}/explainability/${params.explainabilityRunId}`,
    }),
  );

export const apiStopExplainabilityRun = (params: {
  projectId: string;
  experimentId: string;
  explainabilityRunId: string;
}) =>
  apiWrapper(
    axios.request({
      method: 'put',
      headers,
      transformResponse,
      transformRequest,
      url: `${NEW_API_URL}/projects/${params.projectId}/experiments/${params.experimentId}/explainability/${params.explainabilityRunId}/stop`,
    }),
  );

export const apiLoadExplainabilityRunResults = (
  params: ApiExplainabilityRunResultsParams,
) =>
  apiWrapper(
    axios.request<ApiExplainabilityRunResultsResponse>({
      method: 'get',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/experiments/${params.experimentId}/explainability/${params.explainabilityRunId}`,
      params: { offset: params.offset, limit: params.limit },
    }),
  );

export const apiLoadExplainabityMethods = (
  params: ApiLoadExplainabilityMethodsParams,
) =>
  apiWrapper(
    axios.request<ApiLoadApiExplainabilityMethodsResponse>({
      method: 'get',
      headers,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/experiments/${params.experimentId}/explainability_methods`,
      params: {
        limit: params.limit,
        offset: params.offset,
      },
    }),
  );
