/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Type of error
 */
export enum AttributesErrorTypeQuery {
  NOT_AN_ERROR = 'NOT_AN_ERROR',
  MISCLASSIFICATION = 'MISCLASSIFICATION',
  MISSING_ATTRIBUTE = 'MISSING_ATTRIBUTE',
  EXTRA_ATTRIBUTE = 'EXTRA_ATTRIBUTE',
}
