import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  loadingStateBuilder,
  LoadingState,
} from '../../../../utils/loadingState';
import { resetManagedWorkspaceId } from '../../../commonFeatures/workspaceId/workspaceId.slice';

type CreditState = {
  loadingState: LoadingState;
  balance: number;
  used: number;
};

const initialState: CreditState = {
  loadingState: loadingStateBuilder.initial(),
  balance: -1,
  used: -1,
};

const { actions, reducer: creditsReducer } = createSlice({
  name: 'workspace/credits',
  initialState,
  reducers: {
    loadWorkspaceCredits(state, _action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.inProgress();
      state.balance = -1;
      state.used = -1;
    },
    loadWorkspaceCreditSuccess(
      state,
      action: PayloadAction<{ creditsLeft: number; creditsUsed: number }>,
    ) {
      state.loadingState = loadingStateBuilder.success();
      state.balance = action.payload.creditsLeft;
      state.used = action.payload.creditsUsed;
    },
    loadWorkspaceCreditFailure(
      state,
      action: PayloadAction<{ message: string }>,
    ) {
      state.loadingState = loadingStateBuilder.failure(action.payload.message);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetManagedWorkspaceId, () => initialState);
  },
});

export const {
  loadWorkspaceCredits,
  loadWorkspaceCreditSuccess,
  loadWorkspaceCreditFailure,
} = actions;
export { creditsReducer };
