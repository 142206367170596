import { call, put, select, takeEvery } from 'typed-redux-saga';

import { apiLoadSplitPartitions } from '../../../../../../api/requests/modelPlayground';
import { getErrorMessage } from '../../../../../../api/utils';
import { activeProjectIdSelector } from '../../../../project/project.selectors';
import {
  loadSplitPartitionsSuccess,
  loadSplitPartitionsStart,
  loadSplitPartitionsFailure,
} from './partitions.slice';

function* loadHandler(action: ActionType<typeof loadSplitPartitionsStart>) {
  const projectId = yield* select(activeProjectIdSelector);

  try {
    const { data } = yield* call(apiLoadSplitPartitions, {
      projectId,
      splitId: action.payload.splitId,
    });

    yield* put(loadSplitPartitionsSuccess(data.items));
  } catch (e) {
    const error = getErrorMessage(e, 'Could not load split partitions');

    yield* put(loadSplitPartitionsFailure(error));
  }
}

export function* partitionsSaga() {
  yield* takeEvery(loadSplitPartitionsStart, loadHandler);
}
