import {
  put,
  select,
  takeLatest,
  all,
  takeEvery,
  call,
  debounce,
} from 'typed-redux-saga';
import { PayloadAction } from '@reduxjs/toolkit';

import {
  loadObjects,
  loadObjectsSuccess,
  loadObjectsFailure,
  loadInstanceSegmentationModel,
  updateInstanceSegmentationModel,
  loadInstanceSegmentationModelSuccess,
  loadInstanceSegmentationModelStart,
  loadInstanceSegmentationModelFailure,
  addLabelsFromProposedObjects,
  updateInstanceSegmentationModelSuccess,
  addLabelFromProposedObject,
  confirmAddLabelFromProposedObject,
  resetData,
  resetModel,
  setMaxDetections,
  setConfidence,
  adjustMaxDetections,
  adjustConfidence,
  confirmMaxDetections,
  setMaskerThreshold,
  updateInstanceSegmentationModelShowDot,
  toggleUseSAM,
  resetMaxDetections,
  resetConfidence,
  resetMaskerThreshold,
  rejectLabel,
} from './instanceSegmentation.slice';
import { getErrorMessage } from '../../../../../../api/utils';
import {
  apiLoadInstanceSegmentationModel,
  apiLoadInstanceSegmentationObjects,
} from '../../../../../../api/requests/projectTools';
import {
  instanceSegmentationModelIdSelector,
  instanceSegmentationPredictedDataSelector,
  instanceSegmentationModelLoadedSelector,
  instanceSegmentationPredictedDataByIdSelector,
  instanceSegmentationMaxDetectionsSelector,
  instanceSegmentationConfidenceSelector,
  instanceSegmentationMaskerThresholdSelector,
  instanceSegmentationUseSAMSelector,
  instanceSegmentationPredictedDataCountSelector,
} from './instanceSegmentation.selectors';
import { labelClassPredictionEnabledSelector } from '../labelClassPrediction/labelClassPrediction.selectors';
import {
  MAX_OBJECTS_TO_SHOW,
  MIN_OBJECTS_TO_SHOW,
} from '../../../../../../constants/tools';
import { getMaskDataUpdate } from '../../../../../atoms/maskDataUpdate.saga';
import {
  getResultFromWorker,
  MASK_WORKER,
  TerminationError,
} from '../../../../../../workers/workerManager';
import { METHOD_BORDER } from '../../../../../../workers/mask/constants';
import { handleError } from '../../../../commonFeatures/errorHandler/errorHandler.actions';
import { activeProjectIdSelector } from '../../../../project/project.selectors';
import { imageViewImageIdSelector } from '../../currentImage/currentImage.selectors';
import { resetActiveTool, setActiveTool } from '../tools.slice';
import {
  createModelChangePattern,
  ModelChangePayload,
} from '../models/models.constants';
import { MODEL_LOADED, MODEL_UPDATED } from '../../../../ws/ws.constants';
import {
  MODEL_MESSAGES,
  INSTANCE_SEGMENTATION_FAMILY_NAME,
  INSTANCE_SEGMENTATION_DEFAULT_Z_INDEX,
  INSTANCE_SEGMENTATION_DEFAULT_MAX_DETECTIONS,
} from './instanceSegmentation.constants';
import {
  loadModelHandler,
  modelLoadedHandler,
  updateModelHandler,
} from '../models/models.saga';
import { adjustNumValue, createLabel } from '../../imageView.util';
import { resetProject } from '../../imageView.actions';
import { ImageLabel } from '../../../../../../api/domainModels/imageLabel';
import { addLabels } from '../../labels/labels.slice';
import { addActiveToolEntityId } from '../activeToolData/activeToolData.slice';
import { Bbox, Polygon } from '../../../../../../@types/imageView/types';
import { advancedOptionsDefaultLabelTypeSelector } from '../../../../sections/editedProject/advancedOptions/advancedOptions.selectors';
import { LabelType } from '../../../../../../api/constants/label';
import { storeObject } from '../../imageView.helpers';
import { retrieveObject } from '../../../../../../helpers/imageView/data.helpers';
import { convertMaskLabelToPolygon } from '../../labels/conversions';
import {
  ImageViewTool,
  ImageTool,
  ImageToolTitleMap,
} from '../tools.constants';
import { enqueueNotification } from '../../../../ui/stackNotifications/stackNotifications.slice';
import { uuidv4 } from '../../../../../../util/uuidv4';
import { resetHoveredPotentialObjectClassId } from '../../annotationsVisualState/annotationsVisualState.slice';

const createMaskObject = ({
  mask,
  bbox,
  classId,
}: {
  bbox: ImageLabel['bbox'];
  mask: ImageLabel['mask'];
  classId: string | null;
}) => ({
  id: uuidv4(),
  classId,
  mask,
  bbox,
  toolUsed: ImageTool.InstanceSegmentation as ImageViewTool,
  type: LabelType.Mask,
});
const createPolygonObject = ({
  bbox,
  polygon,
  classId,
}: {
  bbox: Bbox;
  polygon: Polygon;
  classId: string | null;
}) => ({
  id: uuidv4(),
  bbox,
  classId,
  polygon,
  toolUsed: ImageTool.InstanceSegmentation as ImageViewTool,
  type: LabelType.Polygon,
});
// todo @V2debt type API
function* labelGenerator(label: ImageLabel) {
  const { imageDataId } = yield* getMaskDataUpdate(label);
  const borderData = yield* getResultFromWorker(MASK_WORKER, {
    method: METHOD_BORDER,
    imageData: retrieveObject(imageDataId),
  });
  const borderDataId = storeObject(borderData);

  yield* all(
    [imageDataId, borderDataId].map((id) => put(addActiveToolEntityId(id))),
  );

  return {
    ...label,
    borderDataId,
    imageDataId,
  };
}

function* loadObjectsHandler(action: ActionType<typeof loadObjects>) {
  const projectId = yield* select(activeProjectIdSelector);
  const imageId = yield* select(imageViewImageIdSelector);
  const modelId = yield* select(instanceSegmentationModelIdSelector);
  const confidence = yield* select(instanceSegmentationConfidenceSelector);
  const useClassifier = yield* select((state) =>
    labelClassPredictionEnabledSelector(state, projectId),
  );
  const maskerThreshold = yield* select(
    instanceSegmentationMaskerThresholdSelector,
  );
  const maxDetections = yield* select(
    instanceSegmentationMaxDetectionsSelector,
  );
  const useSAM = yield* select(instanceSegmentationUseSAMSelector);

  const params = {
    confidenceThreshold: confidence / 100,
    maskerThreshold,
    modelId,
    maxDetectionsPerImage: maxDetections,
    viewport: action.payload,
    useClassifier,
    useAsPrompt: useSAM,
  };

  try {
    const response = yield* call(
      apiLoadInstanceSegmentationObjects,
      projectId,
      imageId,
      params,
    );
    const objects = response.data.map(createLabel) as ImageLabel[];
    const payload = yield* all(objects.map(labelGenerator));

    yield* put(loadObjectsSuccess(payload));
  } catch (error) {
    const message = getErrorMessage(
      error,
      'Instance segmentation failed to predict objects',
    );

    if (!(error instanceof TerminationError)) {
      yield* put(handleError({ message, error }));
    } else {
      yield* put(
        enqueueNotification({
          message,
          options: {
            variant: 'error',
            error,
          },
        }),
      );
    }
    yield* put(loadObjectsFailure(message));
  }
}

function* addLabelFromProposedObjectHandler(
  action: ActionType<typeof addLabelFromProposedObject>,
) {
  const data = yield* select((state: RootState) =>
    instanceSegmentationPredictedDataByIdSelector(state, action.payload),
  );
  const defaultLabelType = yield* select(
    advancedOptionsDefaultLabelTypeSelector,
  );

  if (data) {
    let labelToAdd;
    const { bbox, mask, classId } = data;

    if (defaultLabelType === LabelType.Polygon) {
      const polygon: Polygon = yield* convertMaskLabelToPolygon({
        bbox,
        mask,
      });

      labelToAdd = createPolygonObject({ polygon, classId, bbox });
    } else {
      labelToAdd = createMaskObject({ mask, bbox, classId });
    }

    yield* put(
      addLabels([
        { ...labelToAdd, zIndex: INSTANCE_SEGMENTATION_DEFAULT_Z_INDEX },
      ]),
    );
    yield* put(confirmAddLabelFromProposedObject(action.payload));
  }
}

function* addLabelsFromProposedObjectsHandler() {
  const data = yield* select(instanceSegmentationPredictedDataSelector);
  const defaultLabelType = yield* select(
    advancedOptionsDefaultLabelTypeSelector,
  );

  if (data.length > 0) {
    const labelsToAdd = [] as any[];

    if (defaultLabelType === LabelType.Polygon) {
      for (const entry of data) {
        const { bbox, mask, classId } = entry;
        const polygon: Polygon = yield* convertMaskLabelToPolygon({
          bbox,
          mask,
        });

        labelsToAdd.push({
          ...createPolygonObject({ classId, polygon, bbox }),
          zIndex: INSTANCE_SEGMENTATION_DEFAULT_Z_INDEX,
        });
      }
    } else {
      for (const entry of data) {
        const { bbox, mask, classId } = entry;

        labelsToAdd.push({
          ...createMaskObject({ classId, mask, bbox }),
          zIndex: INSTANCE_SEGMENTATION_DEFAULT_Z_INDEX,
        });
      }
    }

    yield* put(addLabels(labelsToAdd));
    yield* put(setActiveTool(ImageTool.Default));
  }
}

function* instanceSegmentationModelLoadedHandler(
  action: PayloadAction<ModelChangePayload>,
) {
  const { status, progress, id } = action.payload;

  yield* call(modelLoadedHandler, {
    id,
    modelIdSelector: instanceSegmentationModelIdSelector,
    modelLoadedSelector: instanceSegmentationModelLoadedSelector,
    progress,
    status,
    updateAction: updateInstanceSegmentationModel,
    updateModelIndicator: updateInstanceSegmentationModelShowDot,
  });
}

function* instanceSegmentationModelUpdatedHandler(
  action: PayloadAction<ModelChangePayload>,
) {
  const { status, progress, id } = action.payload;

  yield* put(
    updateInstanceSegmentationModel({
      status,
      progress,
      id,
    }),
  );
}

function* updateInstanceSegmentationModelHandler(
  action: ActionType<typeof updateInstanceSegmentationModel>,
) {
  const { status, progress, id } = action.payload;

  yield* call(updateModelHandler, {
    id,
    loadAction: loadInstanceSegmentationModel,
    progress,
    status,
    successAction: updateInstanceSegmentationModelSuccess,
  });
}

function* loadInstanceSegmentationModelHandler() {
  const projectId = yield* select(activeProjectIdSelector);

  yield* call(loadModelHandler, {
    projectId,
    loadApiCall: apiLoadInstanceSegmentationModel,
    messages: MODEL_MESSAGES,
    toolId: ImageTool.InstanceSegmentation,
    modelLoadStartAction: loadInstanceSegmentationModelStart,
    modelLoadSuccessAction: loadInstanceSegmentationModelSuccess,
    modelLoadErrorAction: loadInstanceSegmentationModelFailure,
    toolName: ImageToolTitleMap[ImageTool.InstanceSegmentation],
  });
}

function* resetProjectHandler() {
  yield* put(resetData());
  yield* put(resetModel());
}

function* setActiveToolHandler() {
  yield* put(resetData());
}

function* resetActiveToolIfNoPredictionsLeft() {
  yield* put(resetHoveredPotentialObjectClassId());

  const instanceSegmentationPredictedDataCount = yield* select(
    instanceSegmentationPredictedDataCountSelector,
  );

  if (instanceSegmentationPredictedDataCount === 0) {
    yield* put(resetActiveTool());
  }
}

function* adjustMaxDetectionsHandler(
  action: ActionType<typeof adjustMaxDetections>,
) {
  const { increment, visibleAreaBbox } = action.payload;
  const value = yield* select(instanceSegmentationMaxDetectionsSelector);
  const newValue = adjustNumValue({
    increment,
    value,
    maxValue: MAX_OBJECTS_TO_SHOW,
  });

  if (newValue) {
    yield* put(
      setMaxDetections({
        maxDetections: newValue,
        visibleAreaBbox,
      }),
    );
  }
}

function* adjustConfidenceHandler(action: ActionType<typeof adjustConfidence>) {
  const { increment, visibleAreaBbox } = action.payload;
  const value = yield* select(instanceSegmentationConfidenceSelector);
  const newValue = adjustNumValue({
    increment,
    value,
  });

  if (newValue) {
    yield* put(setConfidence({ confidence: newValue, visibleAreaBbox }));
  }
}

function* setMaxDetectionsHandler(action: ActionType<typeof setMaxDetections>) {
  const currentMaxDetections = yield* select(
    instanceSegmentationMaxDetectionsSelector,
  );
  let confirmed = action.payload.maxDetections;

  if (confirmed > MAX_OBJECTS_TO_SHOW) {
    confirmed = MAX_OBJECTS_TO_SHOW;
  }

  if (confirmed < MIN_OBJECTS_TO_SHOW) {
    confirmed = MIN_OBJECTS_TO_SHOW;
  }

  if (confirmed !== currentMaxDetections) {
    yield* put(confirmMaxDetections(confirmed));
    yield* put(loadObjects(action.payload.visibleAreaBbox));
  }
}

function* resetMaxDetectionsHandler(
  action: ActionType<typeof resetMaxDetections>,
) {
  const { visibleAreaBbox } = action.payload;

  yield* put(
    setMaxDetections({
      maxDetections: INSTANCE_SEGMENTATION_DEFAULT_MAX_DETECTIONS,
      visibleAreaBbox,
    }),
  );
}

function* reloadObjectsHandler(
  action: ActionType<
    | typeof setConfidence
    | typeof resetConfidence
    | typeof setMaskerThreshold
    | typeof resetMaskerThreshold
    | typeof toggleUseSAM
  >,
) {
  yield* put(loadObjects(action.payload.visibleAreaBbox));
}

export function* instanceSegmentationSaga() {
  yield* takeLatest(setMaxDetections, setMaxDetectionsHandler);
  yield* takeLatest(resetMaxDetections, resetMaxDetectionsHandler);
  yield* takeLatest(
    [
      setConfidence,
      resetConfidence,
      setMaskerThreshold,
      resetMaskerThreshold,
      toggleUseSAM,
    ],
    reloadObjectsHandler,
  );
  yield* takeLatest(adjustMaxDetections, adjustMaxDetectionsHandler);
  yield* takeLatest(adjustConfidence, adjustConfidenceHandler);
  yield* debounce(1000, loadObjects, loadObjectsHandler);
  yield* takeEvery(
    addLabelsFromProposedObjects,
    addLabelsFromProposedObjectsHandler,
  );
  yield* takeEvery(
    addLabelFromProposedObject,
    addLabelFromProposedObjectHandler,
  );
  yield* takeEvery(
    createModelChangePattern(INSTANCE_SEGMENTATION_FAMILY_NAME, MODEL_LOADED),
    instanceSegmentationModelLoadedHandler,
  );
  yield* takeEvery(
    createModelChangePattern(INSTANCE_SEGMENTATION_FAMILY_NAME, MODEL_UPDATED),
    instanceSegmentationModelUpdatedHandler,
  );
  yield* takeEvery(
    updateInstanceSegmentationModel,
    updateInstanceSegmentationModelHandler,
  );
  yield* takeEvery(
    loadInstanceSegmentationModel,
    loadInstanceSegmentationModelHandler,
  );
  yield* takeEvery(resetProject, resetProjectHandler);
  yield* takeEvery([setActiveTool, resetActiveTool], setActiveToolHandler);
  yield* takeEvery(
    [rejectLabel, confirmAddLabelFromProposedObject],
    resetActiveToolIfNoPredictionsLeft,
  );
}
