import { Store } from '@reduxjs/toolkit';
import { addBreadcrumb, captureMessage, setTag } from '@sentry/react';
import { AxiosError, AxiosPromise, AxiosResponse } from 'axios';

import { logout } from '../redux/state/commonFeatures/auth/auth.slice';
import { setApplicationOutdated } from '../redux/state/ui/applicationOutdated/applicationOutdated.slice';
import {
  PermanentMessageTypes,
  setPermanentMessage,
} from '../redux/state/ui/permanentMessages/permanentMessages.slice';
import { disableVersionCheckFeature } from '../util/features';

let store: Store;

export const injectStoreApiRequest = (_store: Store) => {
  store = _store;
};

const isAxiosError = <T>(error: any): error is AxiosError<T> =>
  (error as AxiosError).isAxiosError !== undefined;

const isNotAuthorized = <T>(response: AxiosResponse<T>) =>
  response.status === 401;

export const apiWrapperReactQuery = async <T>(promise: AxiosPromise<T>) => {
  try {
    const result = await promise;
    addBreadcrumb({
      timestamp: Date.now(),
      data: {
        url: result.config.url,
        method: result.config.method,
        status_code: result.status,
        request_id: result.headers['x-request-id'],
        version: result.headers['x-version'],
      },
    });
    if (!result.headers['x-version']) {
      const regex = /\bapi\b.*\bhasty.ai\b/;
      const regexCheck = regex.exec(result.request.responseURL);
      if (regexCheck && regexCheck[0]) {
        store.dispatch(
          setPermanentMessage({
            id: PermanentMessageTypes.Proxy,
            message:
              'You seem to be using a proxy. It may cause application malfunction',
          }),
        );
      }
    }

    if (
      result.headers['x-version'] &&
      result.headers['x-version'] !== window.REACT_APP_VERSION &&
      process.env.REACT_APP_OUTDATED_CHECK !== '0' &&
      !disableVersionCheckFeature
    ) {
      store.dispatch(setApplicationOutdated());
    }

    return result;
  } catch (error) {
    if (isAxiosError<T>(error)) {
      const res = error.response;

      const requestId = res?.headers['x-request-id'];
      const projectId =
        new RegExp('projects\\/(.*?)\\/').exec(error.config.url || '')?.[1] ||
        undefined;

      setTag('request_id', requestId);
      setTag('project_id', projectId);

      addBreadcrumb({
        timestamp: Date.now(),
        data: {
          url: error.config.url,
          method: error.config.method,
          status_code: res?.status,
          request_id: requestId,
          project_id: projectId,
          version: res?.headers?.['x-version'],
        },
      });
      captureMessage(error?.message, {
        level: 'warning',
      });
      if (error.response && isNotAuthorized(error.response)) {
        // case of non-authorized request
        store.dispatch(logout({ skipRedirect: true, logoutTime: +new Date() }));
      }
    }

    throw error;
  }
};
