import { Project1 } from '../../../../../api/codegen';

export enum ProjectPrivilegeGroup {
  Administrate = 'admin',
  Annotate = 'annotate',
  Manage = 'manage',
  ErrorFinder = 'error_finder',
  ModelPlayground = 'model_playground',
  Reporting = 'reporting',
}

export const ProjectPrivileges = {
  Administrate: {
    EditClasses: 'ADMIN_EDIT_CLASSES',
    ImageUpload: 'ADMIN_IMAGE_UPLOAD',
    ImageManage: 'ADMIN_IMAGE_MANAGE',
    DatasetManage: 'ADMIN_DATASET_MANAGE',
    Export: 'ADMIN_EXPORT',
    Collaborators: 'ADMIN_COLLABORATORS',
    Import: 'ADMIN_IMPORT',
    AdvancedOptions: 'ADMIN_ADVANCED_SETTINGS',
    VideoUpload: 'ADMIN_VIDEO_UPLOAD',
    Webhooks: 'ADMIN_WEBHOOKS',
  },
  Manage: {
    ManageRoles: 'ADMIN_EDIT_ROLES',
  },
  Annotate: {
    Annotation: 'ANNOTATE_ANNOTATION',
    Supervision: 'ANNOTATE_SUPERVISION',
    AutomatedLabeling: 'ANNOTATE_FINISH_HIM',
    Comments: 'PROJECT_COMMENTS',
  },
  Reporting: {
    ProjectReports: 'REPORTING_PROJECT_REPORTS',
    IndividualReports: 'REPORTING_INDIVIDUAL_REPORTS',
    OwnReport: 'REPORTING_OWN_REPORT',
  },
  Review: {
    ErrorFinder: 'ERROR_FINDER',
  },
  Train: {
    ModelPlayground: 'MODEL_PLAYGROUND',
  },
};

// needs to be an array for persistent order
export type Privileges = {
  id: ProjectPrivilegeGroup;
  title: string;
  privileges: {
    id: string;
    title: string;
    description: string;
    contentType?: Project1.contentType;
    isProOnly?: boolean;
  }[];
  simple?: boolean;
  contentType?: Project1.contentType;
};

export const projectPrivilegeTableStructure: Privileges[] = [
  {
    id: ProjectPrivilegeGroup.Administrate,
    title: 'Administrate',
    privileges: [
      {
        id: ProjectPrivileges.Administrate.EditClasses,
        title: 'Edit taxonomy',
        description:
          'Allows manipulation of label classes, attributes and tags',
        contentType: Project1.contentType.IMAGES,
      },
      {
        id: ProjectPrivileges.Administrate.EditClasses,
        title: 'Edit activities',
        description: 'Allows manipulation of activities',
        contentType: Project1.contentType.VIDEOS,
      },
      {
        id: ProjectPrivileges.Administrate.ImageUpload,
        title: 'Upload images',
        description: 'Allows to upload new images',
        contentType: Project1.contentType.IMAGES,
      },
      {
        id: ProjectPrivileges.Administrate.ImageManage,
        title: 'Manage images',
        description: 'Allows to move, rename or delete images',
        contentType: Project1.contentType.IMAGES,
      },
      {
        id: ProjectPrivileges.Administrate.DatasetManage,
        title: 'Manage datasets',
        description: 'Allows to create, rename or delete datasets',
      },
      {
        id: ProjectPrivileges.Administrate.VideoUpload,
        title: 'Upload videos',
        description: `Allows for the creation of new datasets
          and uploading of new videos`,
        contentType: Project1.contentType.VIDEOS,
      },
      {
        id: ProjectPrivileges.Administrate.Export,
        title: 'Export',
        description: 'Allows exporting of project labels to file',
      },
      {
        id: ProjectPrivileges.Administrate.Collaborators,
        title: 'Invite/Edit/Remove Users',
        description: 'Allows invitation of new users and assigning of roles',
      },
      {
        id: ProjectPrivileges.Administrate.Import,
        title: 'Import',
        description: 'Allows importing project labels and label classes',
        contentType: Project1.contentType.IMAGES,
      },
      {
        id: ProjectPrivileges.Administrate.AdvancedOptions,
        title: 'Set advanced options and active learning',
        description: 'Allows to set Advanced options including Active learning',
        contentType: Project1.contentType.IMAGES,
      },
      {
        id: ProjectPrivileges.Administrate.Webhooks,
        title: 'Webhooks',
        description: 'Allows to create/edit/remove Webhooks',
        isProOnly: true,
        contentType: Project1.contentType.IMAGES,
      },
    ],
  },
  {
    id: ProjectPrivilegeGroup.Manage,
    title: 'Manage Roles',
    privileges: [
      {
        id: ProjectPrivileges.Manage.ManageRoles,
        title: 'Manage Roles',
        description: 'Allows adding, editing and removal of roles',
      },
    ],
  },
  {
    id: ProjectPrivilegeGroup.Annotate,
    title: 'Annotation',
    privileges: [
      {
        id: ProjectPrivileges.Annotate.Annotation,
        title: 'Annotation',
        description: `Allows adding, editing or deleting of
          labels, attribute values, and tags`,
        contentType: Project1.contentType.IMAGES,
      },
      {
        id: ProjectPrivileges.Annotate.Supervision,
        title: 'Supervision',
        description: 'Allows setting of image status to Done',
        contentType: Project1.contentType.IMAGES,
      },
      {
        id: ProjectPrivileges.Annotate.Annotation,
        title: 'Annotation',
        description: `Allows adding, editing or deleting of
          segments`,
        contentType: Project1.contentType.VIDEOS,
      },
      {
        id: ProjectPrivileges.Annotate.Supervision,
        title: 'Supervision',
        description: 'Allows setting of video status to Done',
        contentType: Project1.contentType.VIDEOS,
      },
      {
        id: ProjectPrivileges.Annotate.AutomatedLabeling,
        title: 'Automated labeling',
        description: 'Allows to run automated labeling',
        contentType: Project1.contentType.IMAGES,
      },
      {
        id: ProjectPrivileges.Annotate.Comments,
        title: 'Comments',
        description:
          'Allows commenting and collaboration. Premium feature, contact sales for more information',
        contentType: Project1.contentType.IMAGES,
      },
    ],
  },
  {
    id: ProjectPrivilegeGroup.Reporting,
    title: 'Project Summary',
    contentType: Project1.contentType.IMAGES,
    privileges: [
      {
        id: ProjectPrivileges.Reporting.ProjectReports,
        title: 'Project Summary',
        description: 'Allows viewing of project level report',
      },
    ],
  },
  {
    id: ProjectPrivilegeGroup.ErrorFinder,
    title: 'AI Consensus Scoring',
    contentType: Project1.contentType.IMAGES,
    privileges: [
      {
        id: ProjectPrivileges.Review.ErrorFinder,
        title: 'AI Consensus Scoring',
        description: 'Allows to use the AI Consensus Scoring tool',
      },
    ],
  },
  {
    id: ProjectPrivilegeGroup.ModelPlayground,
    title: 'Model Playground',
    contentType: Project1.contentType.IMAGES,
    privileges: [
      {
        id: ProjectPrivileges.Train.ModelPlayground,
        title: 'Model Playground',
        description: 'Allows to use the Model Playground',
      },
    ],
  },
];

projectPrivilegeTableStructure.forEach((group) => {
  group.simple = group.privileges.length === 1;
});
