import { SagaMiddleware } from '@redux-saga/core';
import { Saga } from '@redux-saga/types';

export const createSagaInjector = (
  runSaga: SagaMiddleware['run'],
  rootSaga: Saga,
) => {
  // Create a dictionary to keep track of injected sagas
  const injectedSagas = new Map();

  const isInjected = (key: string) => injectedSagas.has(key);

  const injectSaga = (key: string, saga: Saga) => {
    // We cancel saga if it is already injected
    if (isInjected(key)) {
      injectedSagas.get(key).cancel();
    }

    // Sagas return task when they executed, which can be used
    // to cancel them
    const task = runSaga(saga);

    // Save the task if we want to cancel it in the future
    injectedSagas.set(key, task);
  };

  const unplugSaga = (key: string) => {
    const saga = injectedSagas.get(key);
    saga.cancel();
    injectedSagas.delete(key);
  };

  // Inject the root saga as it a statically loaded file,
  injectSaga('root', rootSaga);

  return {
    injectSaga,
    unplugSaga,
  };
};
