import { createSelector } from '@reduxjs/toolkit';

import { imageViewSelector } from '../../imageView.selectors';
import { modelStatusChecks } from '../models/modelStatus.constants';
import { activeLabelClassSelector } from '../../labelClasses/labelClasses.selectors';
import { LabelClassType } from '../../../../../../api/domainModels/labelClass';
import { activeToolIdSelector } from '../tools.selectors';
import { TOOLS_INCLUDING_CLASS_PREDICTION } from './labelClassPrediction.constants';
import { ImageTool } from '../tools.constants';

const labelClassPredictionSelector = createSelector(
  imageViewSelector,
  (imageView) => imageView.tools.labelClassPrediction,
);

export const labelClassPredictionModelSelector = createSelector(
  labelClassPredictionSelector,
  (labelClassPrediction) => labelClassPrediction.model,
);

export const labelClassPredictionDataSelector = createSelector(
  labelClassPredictionSelector,
  (labelClassPrediction) => labelClassPrediction.data,
);

export const labelClassPredictionLoadingStateSelector = createSelector(
  labelClassPredictionSelector,
  (labelClassPrediction) => labelClassPrediction.loadingState,
);

export const labelClassPredictionModelIdSelector = createSelector(
  labelClassPredictionModelSelector,
  (model) => model.id,
);

export const labelClassPredictionModelAvailableSelector = createSelector(
  labelClassPredictionModelIdSelector,
  (modelId) => typeof modelId === 'number',
);

export const labelClassPredictionModelStatusSelector = createSelector(
  labelClassPredictionModelSelector,
  (model) => model.status,
);

export const labelClassPredictionModelLoadingStateSelector = createSelector(
  labelClassPredictionModelSelector,
  (model) => model.loadingState,
);

export const labelClassPredictionModelLoadedSelector = createSelector(
  [
    labelClassPredictionModelAvailableSelector,
    labelClassPredictionModelStatusSelector,
  ],
  (available, status) =>
    !!(available && status && modelStatusChecks.isLoaded(status)),
);

export const labelClassPredictionEnabledSelector = createSelector(
  [
    (_: RootState, projectId: string) => projectId,
    labelClassPredictionDataSelector,
  ],
  (projectId, data) => !!data[projectId],
);

export const shouldPredictLabelClassesSelector = createSelector(
  [
    labelClassPredictionModelAvailableSelector,
    activeLabelClassSelector,
    activeToolIdSelector,
    (state: RootState, projectId: string) =>
      labelClassPredictionEnabledSelector(state, projectId),
  ],
  (predictionAvailable, activeLabelClass, toolId, predictionEnabled) =>
    predictionAvailable &&
    predictionEnabled &&
    activeLabelClass?.type === LabelClassType.Object &&
    !TOOLS_INCLUDING_CLASS_PREDICTION.includes(toolId as ImageTool),
);
