import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiKeysetPaginationDirection } from '../../../../../api/helpers';

export type PaginationState = {
  page: number;
  direction: ApiKeysetPaginationDirection | null;
  itemsPerPage: number;
  resultsCount: number | null;
  cursorNext: string | null;
  cursorPrev: string | null;
};
export const initialState: PaginationState = {
  page: 1,
  direction: null,
  itemsPerPage: 100,
  resultsCount: null,
  cursorNext: null,
  cursorPrev: null,
};

const { actions, reducer: efPaginationReducer } = createSlice({
  name: 'efPagination',
  initialState,
  reducers: {
    setErrorFinderPage(
      state,
      action: PayloadAction<ApiKeysetPaginationDirection>,
    ) {
      state.page = action.payload === 'NEXT' ? state.page + 1 : state.page - 1;
      state.direction = action.payload;
    },
    setErrorFinderCursor(
      state,
      action: PayloadAction<{
        cursorNext: string | null;
        cursorPrev: string | null;
      }>,
    ) {
      state.cursorNext = action.payload.cursorNext;
      state.cursorPrev = action.payload.cursorPrev;
    },
    setErrorFinderItemsPerPage(state, action: PayloadAction<number>) {
      state.itemsPerPage = action.payload;
    },
    setErrorFinderItemsPerPageInView(state, action: PayloadAction<number>) {
      // same, as setEfItemsPerPage, but it will trigger url change handlers
      state.itemsPerPage = action.payload;
    },
    setErrorFinderResultsCount(
      state,
      action: PayloadAction<{ count: number | null }>,
    ) {
      state.resultsCount = action.payload.count;
    },
    resetErrorFinderPage(state) {
      state.page = initialState.page;
      state.direction = initialState.direction;
      state.cursorNext = initialState.cursorNext;
      state.cursorPrev = initialState.cursorPrev;
    },
  },
});

export { efPaginationReducer };
export const {
  setErrorFinderPage,
  setErrorFinderCursor,
  setErrorFinderItemsPerPage,
  setErrorFinderItemsPerPageInView,
  setErrorFinderResultsCount,
  resetErrorFinderPage,
} = actions;
