import { put, takeEvery, select, call } from 'typed-redux-saga';

import {
  apiGetErrorFinderRunLockedResultsState,
  apiUnlockErrorFinderRunResults,
} from '../../../../../../api/requests/consensusScoring';
import { getErrorMessage } from '../../../../../../api/utils';
import { activeProjectIdSelector } from '../../../../project/project.selectors';
import { activeRunIdSelector } from '../../errorFinder.selectors';
import { loadErrorFinderLabelsInitial } from '../../labels/labels.slice';
import {
  getRunLockedResultsState,
  getRunLockedResultsStateSuccess,
  getRunLockedResultsStateFailure,
  unlockRunResults,
  unlockRunResultsSuccess,
  unlockRunResultsFailure,
  unlockRunResultsInAE,
} from './lockedResults.slice';
import { loadErrorsPerClass } from '../summary/errorsPerClass.slice';
import { loadCSData } from '../../../imageView/consensusScoring/consensusScoring.slice';
import { invalidateGallery } from '../../../imageView/imageGallery/imageGalleryItems/imageGalleryItems.slice';
import { imageViewImageGalleryFiltersCSRunIdSelector } from '../../../imageView/imageGallery/bottomBar/filters/filters.selectors';
import { enqueueNotification } from '../../../../ui/stackNotifications/stackNotifications.slice';

function* loadHandler(action: ActionType<typeof getRunLockedResultsState>) {
  try {
    const runId = action.payload;
    const projectId = yield* select(activeProjectIdSelector);

    if (!projectId || !runId) return;

    const { data } = yield* call(apiGetErrorFinderRunLockedResultsState, {
      projectId,
      runId,
    });
    yield* put(getRunLockedResultsStateSuccess(data));
  } catch (error) {
    const message = getErrorMessage(
      error,
      'Not able to load locked results info',
    );

    yield* put(getRunLockedResultsStateFailure(message));
    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
        },
      }),
    );
  }
}

function* unlockHandler() {
  try {
    const projectId = yield* select(activeProjectIdSelector);
    const runId = yield* select(activeRunIdSelector);
    yield* call(apiUnlockErrorFinderRunResults, {
      projectId,
      runId,
    });
    yield* put(unlockRunResultsSuccess());
    yield* put(loadErrorFinderLabelsInitial());
    yield* put(loadErrorsPerClass({ runId }));
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to unlock results');

    yield* put(unlockRunResultsFailure(message));
    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
        },
      }),
    );
  }
}

function* unlockInAEHandler() {
  try {
    const projectId = yield* select(activeProjectIdSelector);
    const runId = yield* select(imageViewImageGalleryFiltersCSRunIdSelector);

    if (!runId) throw new Error();

    yield* call(apiUnlockErrorFinderRunResults, {
      projectId,
      runId,
    });
    yield* put(unlockRunResultsSuccess());
    yield* put(invalidateGallery());
    yield* put(loadCSData());
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to unlock results');

    yield* put(unlockRunResultsFailure(message));
    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
        },
      }),
    );
  }
}

export function* runLockedResultsSaga() {
  yield* takeEvery(getRunLockedResultsState, loadHandler);
  yield* takeEvery(unlockRunResults, unlockHandler);
  yield* takeEvery(unlockRunResultsInAE, unlockInAEHandler);
}
