import clamp from 'lodash/clamp';
import shortid from 'shortid';

export const adjustNumValue = ({
  increment,
  maxValue = 100,
  minValue = 0,
  value,
}: {
  increment: boolean;
  maxValue?: number;
  minValue?: 0;
  value: number;
}) => {
  const step = 2;
  let newValue = increment ? value + step : value - step;

  newValue = clamp(newValue, minValue, maxValue);

  return value !== newValue ? newValue : null;
};
// todo @V2Debt - this function should not be used, potential label should be created instead
export const createLabel = ({
  bbox,
  classId,
  createdOn,
  externalEventId,
  id,
  imageId,
  labeledBy,
  mask,
  polygon,
  toolUsed,
  transactionId,
  updatedOn,
  zIndex,
}: {
  bbox?: [number, number, number, number] | null;
  classId?: string | null;
  createdOn: string;
  externalEventId?: string | null;
  id?: string;
  imageId?: string;
  labeledBy?: string;
  mask?: number[] | null;
  polygon?: [number, number][] | null;
  toolUsed?: any;
  transactionId?: string | null;
  updatedOn?: string | null;
  zIndex?: number;
}) => ({
  bbox: bbox || null,
  classId: classId || null,
  createdOn,
  externalEventId: externalEventId || null,
  id: id || shortid(),
  imageId: imageId || shortid(),
  labeledBy: labeledBy || '',
  mask: mask || null,
  polygon: polygon || null,
  toolUsed: toolUsed || null,
  transactionId: transactionId || null,
  updatedOn: updatedOn || createdOn,
  zIndex: zIndex || 0,
});

export const sortObjectsAlphaNumeric = <T extends Record<string, any>>(
  key: string,
  objects: T[],
): T[] => [
  ...objects.sort((a, b) => {
    if (a[key] > b[key]) return 1;
    if (a[key] < b[key]) return -1;

    return 0;
  }),
];

export const IMAGE_VIEW_PATHNAME_SEGMENT = 'image';

export const assignId = <T>(item: T) => ({
  ...item,
  id: shortid(),
});
