import axios from 'axios';

import {
  ApiConvertToImagesSessionFormValues,
  ApiConvertToImageSessionApi,
} from '../schemas/convertToImages';
import { apiWrapper } from '../utils';
import { NEW_API_URL } from '../../constants/paths';
import { headers, transformRequest, transformResponse } from '../helpers';

// Currently BE is supporting just one video in videosIds so we are doing conversion
export const apiLoadConvertToImageSessions = ({
  projectId,
}: {
  projectId: string;
}) =>
  apiWrapper(
    axios.request<ApiConvertToImageSessionApi[]>({
      method: 'get',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${projectId}/video_cut_sessions`,
    }),
  );

export const apiAddConvertToImageSession = (
  params: { projectId: string },
  data: ApiConvertToImagesSessionFormValues,
) =>
  apiWrapper(
    axios.request<ApiConvertToImageSessionApi>({
      method: 'post',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/video_cut_sessions`,
      data,
    }),
  );
