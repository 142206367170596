/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Type of error
 */
export enum ODISErrorTypeQuery {
  NOT_AN_ERROR = 'NOT_AN_ERROR',
  LOW_IOU = 'LOW_IOU',
  MISSING_LABEL = 'MISSING_LABEL',
  EXTRA_LABEL = 'EXTRA_LABEL',
}
