import { ApiWebhook, ApiWebhookEvent, ApiWebhookLog } from '../schemas/webhook';

const webhookLogsFromBackend = ({
  request,
  response: { status },
  ...rest
}: ApiWebhookLog) => {
  const { event } = JSON.parse(request.body);

  return {
    ...rest,
    status,
    event,
  };
};

export type WebhookLogItem = ReturnType<typeof webhookLogsFromBackend>;
export type WebhookEvent = ApiWebhookEvent;
export type Webhook = ApiWebhook;
export type WebhookLog = {
  id: string; // webhookId
  items: WebhookLogItem[];
};

export const webhookLogsDataMapper = {
  fromBackend: webhookLogsFromBackend,
};
