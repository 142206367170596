import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';

import {
  ModelStatus,
  modelStatusChecks,
  statusCheck,
  ToolModel,
} from '../models/modelStatus.constants';
import { KeypointsDetection } from '../../../../../../api/domainModels/keypointsDetection';
import {
  LoadingState,
  loadingStateBuilder,
} from '../../../../../utils/loadingState';

export type EnrichedKeypointsDetection = KeypointsDetection & {
  schemaId?: string;
  classId?: string;
  id: string;
};
type KeypointsDetectionState = {
  model: ToolModel;
  data: EntityState<EnrichedKeypointsDetection> & {
    loadingState: LoadingState;
  };
  instanceThreshold: number;
  keypointThreshold: number;
  maxDetections: number;
};
export const adapter = createEntityAdapter<EnrichedKeypointsDetection>();
const initialState: KeypointsDetectionState = {
  model: {
    id: null,
    loadingState: loadingStateBuilder.initial(),
    status: null,
    progress: null,
  },
  data: adapter.getInitialState({
    loadingState: loadingStateBuilder.initial(),
  }),
  instanceThreshold: 0.1,
  keypointThreshold: 0.1,
  maxDetections: 30,
};

const { actions, reducer: keypointsDetectionReducer } = createSlice({
  name: 'imageViewKeypointsDetection',
  initialState,
  reducers: {
    loadKeypointsDetectionModel(_state, _action: PayloadAction<string>) {},
    loadKeypointsDetectionModelError(state, action: PayloadAction<string>) {
      state.model.loadingState = loadingStateBuilder.failure(action.payload);
    },
    loadKeypointsDetectionModelStart(state, action: PayloadAction<string>) {
      state.model.loadingState = loadingStateBuilder.inProgress(action.payload);
    },
    loadKeypointsDetectionModelSuccess(state) {
      state.model.loadingState = loadingStateBuilder.success();
    },
    updateKeypointsDetectionModel(
      _state,
      _action: PayloadAction<{
        status: ModelStatus;
        progress: number;
        id: number;
        modelUseIE?: boolean;
      }>,
    ) {},
    updateKeypointsDetectionModelSuccess(
      state,
      action: PayloadAction<{
        id: number | null;
        status: ModelStatus;
        progress: number | null;
      }>,
    ) {
      const { id, status, progress } = action.payload;

      state.model.id = modelStatusChecks.isLoaded(status) ? id : state.model.id;
      state.model.status = statusCheck(status, state.model.status);
      state.model.progress = progress || 0;
    },
    updateKeypointsDetectionModelShowDot(
      state,
      action: PayloadAction<boolean>,
    ) {
      const showDot = action.payload;

      state.model = {
        ...state.model,
        showDot,
      };
    },
    setInstanceThreshold(
      state,
      _action: PayloadAction<{ threshold: number }>,
    ) {},
    setKeypointThreshold(
      state,
      _action: PayloadAction<{ threshold: number }>,
    ) {},
    setMaxDetections(state, _action: PayloadAction<{ detections: number }>) {},
    loadDetections(state) {
      state.data.loadingState = loadingStateBuilder.inProgress();
    },
    loadKeypointsFailure(state, action: PayloadAction<{ message: string }>) {
      state.data.loadingState = loadingStateBuilder.failure(
        action.payload.message,
      );
    },
    loadKeypointsSuccess(
      state,
      action: PayloadAction<EnrichedKeypointsDetection[]>,
    ) {
      state.data.loadingState = loadingStateBuilder.success();
      adapter.setAll(state.data, action.payload);
    },
    addLabels() {},
    addLabel(state, _action: PayloadAction<{ detectionId: string }>) {},
    confirmAddLabel(state, action: PayloadAction<{ detectionId: string }>) {
      adapter.removeOne(state.data, action.payload.detectionId);
    },
    confirmKeypointsThreshold(
      state,
      action: PayloadAction<{ threshold: number }>,
    ) {
      state.keypointThreshold = action.payload.threshold;
    },
    confirmInstanceThreshold(
      state,
      action: PayloadAction<{ threshold: number }>,
    ) {
      state.instanceThreshold = action.payload.threshold;
    },
    confirmMaxDetections(state, action: PayloadAction<{ detections: number }>) {
      state.maxDetections = action.payload.detections;
    },
    resetData(state) {
      state.data = initialState.data;
    },
  },
});

export { keypointsDetectionReducer };

export const {
  addLabel,
  addLabels,
  confirmAddLabel,
  loadDetections,
  loadKeypointsDetectionModel,
  loadKeypointsDetectionModelError,
  loadKeypointsDetectionModelStart,
  loadKeypointsDetectionModelSuccess,
  loadKeypointsFailure,
  loadKeypointsSuccess,
  setInstanceThreshold,
  confirmKeypointsThreshold,
  setKeypointThreshold,
  confirmInstanceThreshold,
  setMaxDetections,
  confirmMaxDetections,
  updateKeypointsDetectionModel,
  updateKeypointsDetectionModelShowDot,
  updateKeypointsDetectionModelSuccess,
  resetData,
} = actions;
// todo create ticket for common slices and sagas the rest of the tools / or separated?
