/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type LabelClass1 = {
  /**
   * Label class ID
   */
  readonly id?: string;
  /**
   * Class name
   */
  name: string;
  /**
   * Class description
   */
  description?: string | null;
  /**
   * Class type object or background
   */
  type?: LabelClass1.type | null;
  /**
   * Label color
   */
  color?: string | null;
  /**
   * URL to class icon (for future usage)
   */
  iconUrl?: string | null;
  /**
   * Label class order
   */
  norder?: number | null;
  /**
   * Parent label (for future usage)
   */
  parentId?: string | null;
  /**
   * External ID (for API)
   */
  externalId?: string | null;
  /**
   * Flag to indicate if description should be used as text prompt
   */
  useDescriptionAsPrompt?: boolean | null;
};

export namespace LabelClass1 {

  /**
   * Class type object or background
   */
  export enum type {
    OBJECT = 'object',
    BACKGROUND = 'background',
  }


}

