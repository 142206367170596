import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReactStripeElements } from 'react-stripe-elements';

import {
  LoadingState,
  loadingStateBuilder,
} from '../../../../utils/loadingState';
import { Workspace } from '../../../../../api/domainModels/workspace';
import { showModal } from '../../../ui/modals/modals.slice';
import { threeDSecureError } from '../../managedWorkspace/payments/payments.slice';
import { CustomerBillingStatus } from '../../../../../api/domainModels/payment';

const initialState: {
  name: string | null;
  workspaceId: string | null;
  loadingState: LoadingState;
} = {
  name: null,
  workspaceId: null,
  loadingState: loadingStateBuilder.initial(),
};
const { actions, reducer: createWorkspaceReducer } = createSlice({
  name: 'createWorkspace',
  initialState,
  reducers: {
    createWorkspace(
      state,
      _action: PayloadAction<{
        workspaceName: string;
        description: string;
        backgroundColor: string;
      }>,
    ) {
      state.loadingState = loadingStateBuilder.inProgress();
    },
    createWorkspaceSuccess(state, action: PayloadAction<Workspace>) {
      state.workspaceId = action.payload.workspaceId;
      state.loadingState = loadingStateBuilder.success();
    },
    createWorkspaceFailure(state, action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.failure(action.payload);
    },
    selectWorkspacePlan(state, action: PayloadAction<string>) {
      state.name = action.payload;
    },
    saveWorkspacePaymentInformation(
      state,
      _action: PayloadAction<{
        stripeRef: ReactStripeElements.InjectedStripeProps['stripe'];
        elementsRef: ReactStripeElements.InjectedStripeProps['elements'];
        workspaceId: string;
        entityName: string;
      }>,
    ) {
      state.loadingState = loadingStateBuilder.inProgress();
    },
    saveWorkspacePaymentInformationFailure(
      state,
      action: PayloadAction<string>,
    ) {
      state.loadingState = loadingStateBuilder.failure(action.payload);
    },
    updateWorkspacePlan(
      state,
      _action: PayloadAction<{
        targetPlanName: string;
        workspaceId: string;
        coupon?: string;
      }>,
    ) {
      state.loadingState = loadingStateBuilder.inProgress();
    },
    updateWorkspacePlanSuccess(
      state,
      _action: PayloadAction<{
        workspaceId: string;
        customer: CustomerBillingStatus;
      }>,
    ) {
      state.loadingState = loadingStateBuilder.success();
    },
    updateWorkspacePlanFailure(state, action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.failure(action.payload);
    },
    navigateToWorkspace() {},
    upgradeWorkspace(state, action: PayloadAction<string>) {
      state.workspaceId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(showModal, (state) => {
      state.loadingState = loadingStateBuilder.initial();
    });
    builder.addCase(threeDSecureError, (state, { payload: { message } }) => {
      state.loadingState = loadingStateBuilder.failure(message);
    });
  },
});

export const {
  createWorkspace,
  createWorkspaceSuccess,
  createWorkspaceFailure,
  selectWorkspacePlan,
  saveWorkspacePaymentInformation,
  saveWorkspacePaymentInformationFailure,
  updateWorkspacePlan,
  updateWorkspacePlanSuccess,
  updateWorkspacePlanFailure,
  navigateToWorkspace,
  upgradeWorkspace,
} = actions;
export { createWorkspaceReducer };
