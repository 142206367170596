import axios from 'axios';

import { ApiLoadPackageFeaturesResponse } from '../schemas/packageFeatures';
import { apiWrapper } from '../utils';
import { NEW_API_URL } from '../../constants/paths';
import { headers, transformRequest, transformResponse } from '../helpers';

export const apiLoadPackageFeatures = () =>
  apiWrapper(
    axios.request<ApiLoadPackageFeaturesResponse>({
      method: 'get',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/package_features`,
    }),
  );
