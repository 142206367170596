import { createSelector } from '@reduxjs/toolkit';

import { modelStatusChecks } from '../../../imageView/tools/models/modelStatus.constants';

export const segmentPredictorSelector = (state: RootState) =>
  state.core.videoView.tools.segmentPrediction;

export const segmentPredictorModelSelector = createSelector(
  segmentPredictorSelector,
  (segmentPredictor) => segmentPredictor.model,
);

export const segmentPredictorModelIdSelector = createSelector(
  segmentPredictorModelSelector,
  (segmentPredictorModel) => segmentPredictorModel.id,
);

export const segmentPredictorModelStatusSelector = createSelector(
  segmentPredictorModelSelector,
  (segmentPredictorModel) => segmentPredictorModel.status,
);

export const segmentPredictorModelLoadingStateSelector = createSelector(
  segmentPredictorModelSelector,
  (segmentPredictorModel) => segmentPredictorModel.loadingState,
);

export const segmentPredictorModelAvailableSelector = createSelector(
  segmentPredictorModelIdSelector,
  (modelId) => typeof modelId === 'number',
);

export const segmentPredictorModelLoadedSelector = createSelector(
  [segmentPredictorModelAvailableSelector, segmentPredictorModelStatusSelector],
  (available, status) =>
    !!(available && status && modelStatusChecks.isLoaded(status)),
);
