import { createSelector } from '@reduxjs/toolkit';

import { projectSelector } from '../project.selectors';

export const projectInfoStateSelector = createSelector(
  projectSelector,
  (state) => state.projectInfo,
);

export const projectInfoProjectSelector = createSelector(
  projectInfoStateSelector,
  (projectInfo) => projectInfo.data,
);

export const projectLoadingStateSelector = createSelector(
  projectInfoStateSelector,
  (projectInfo) => projectInfo.loadingState,
);

export const projectNameSelector = createSelector(
  projectInfoStateSelector,
  (projectInfo) => projectInfo.data?.projectName,
);
