/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type EditableAttribute = {
  /**
   * Attribute ID
   */
  id: string;
  /**
   * Attribute name
   */
  name: string;
  /**
   * Attribute type
   */
  type: EditableAttribute.type;
  /**
   * Attribute description
   */
  description?: string | null;
  /**
   * Attribute ordering
   */
  norder?: number;
  /**
   * true - if attribute value is defined for provided label
   */
  isConflicting?: boolean;
};

export namespace EditableAttribute {

  /**
   * Attribute type
   */
  export enum type {
    SELECTION = 'SELECTION',
    MULTIPLE_SELECTION = 'MULTIPLE-SELECTION',
    TEXT = 'TEXT',
    INT = 'INT',
    FLOAT = 'FLOAT',
    BOOL = 'BOOL',
  }


}

