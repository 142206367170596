import { createSelector } from '@reduxjs/toolkit';

import { labelClassesMapSelector } from '../../../project/annotationTaxonomy/labelClasses/labelClasses.selectors';
import { selectedLabelsSelector } from '../labels/selectedLabels/selectedLabels.selectors';
import { imageViewSelector } from '../imageView.selectors';

const labelClassesStateSelector = createSelector(
  imageViewSelector,
  (imageView) => imageView.labelClasses,
);

export const activeLabelClassIdSelector = createSelector(
  labelClassesStateSelector,
  (labelClassesState) => labelClassesState.activeLabelClassId,
);

export const activeLabelClassSelector = createSelector(
  [activeLabelClassIdSelector, labelClassesMapSelector],
  (id, labelClassesMap) => (id ? labelClassesMap[id] : null),
);

export const selectedLabelsClassesSelector = createSelector(
  selectedLabelsSelector,
  (selectedLabels) => {
    const classes = selectedLabels.map((label) => label.classId);

    return [...new Set([...classes])];
  },
);
