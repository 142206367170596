export const videoTimeFormatter = (time: number) =>
  new Date(time).toISOString().slice(11, -1);

const pad = (n: number, isMs = false) => `00${n}`.slice(!isMs ? -2 : -3);

export const convertMsToTimeString = (_t: number) => {
  const { hrs, mins, secs, ms } = convertMsToTime(_t);

  return {
    hrs: pad(hrs),
    mins: pad(mins),
    secs: pad(secs),
    ms: pad(ms, true),
  };
};

export const convertMsToTime = (_t: number) => {
  let t = _t;
  const ms = t % 1000;
  t = (t - ms) / 1000;
  const secs = t % 60;
  t = (t - secs) / 60;
  const mins = t % 60;
  const hrs = (t - mins) / 60;

  return { hrs, mins, secs, ms };
};

export const convertTimeToMs = (
  hrs: number,
  mins: number,
  secs: number,
  ms: number,
) => ms + secs * 1000 + mins * 60 * 1000 + hrs * 60 * 60 * 1000;

export const formatVideoMarker = (_t: number, showMs?: boolean) => {
  const { hrs, mins, secs, ms } = convertMsToTime(_t);

  return `${hrs ? `${pad(hrs)}h` : ''}${mins ? `${pad(mins)}m` : ''}${pad(
    secs,
  )}s${showMs ? `${pad(ms, true)}ms` : ''}`;
};
