/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ImageStatus } from './ImageStatus';

export type CreateExportSessionRequest = {
  /**
   * Name of the export session.
   */
  exportName: string;
  /**
   * Export format
   */
  format: CreateExportSessionRequest.format;
  /**
   * List of dataset IDs
   */
  datasetIds?: Array<string>;
  /**
   * List of video statuses to be filtered. This is only in effect for video projects
   */
  videoStatuses?: Array<ImageStatus>;
  /**
   * List of image statuses to be filtered. This is only in effect for image projects
   */
  imageStatuses?: Array<ImageStatus>;
  /**
   * Whether to add signed URLs for publicly accessing images/videos
   */
  signUrls?: boolean;
  /**
   * Semantic pixel encoding format
   */
  semanticFormat?: CreateExportSessionRequest.semanticFormat | null;
  /**
   * The order in which the labels should be drawn. Only applicable for images
   */
  labelsOrder?: Array<'z_index' | 'class_type' | 'class_order'> | null;
};

export namespace CreateExportSessionRequest {

  /**
   * Export format
   */
  export enum format {
    JSON_V1_1 = 'json_v1.1',
    SEMANTIC_PNG = 'semantic_png',
    JSON_COCO = 'json_coco',
    PASCAL = 'pascal',
    VIDEOS = 'videos',
    IMAGES = 'images',
  }

  /**
   * Semantic pixel encoding format
   */
  export enum semanticFormat {
    GS_ASC = 'gs_asc',
    GS_DESC = 'gs_desc',
    CLASS_COLOR = 'class_color',
  }


}

