import { takeEvery, put, select } from 'typed-redux-saga';

import { apiLoadProjectInferenceSummary } from '../../../../../api/requests/inferences';
import { getErrorMessage } from '../../../../../api/utils';
import { loadAll } from '../../../../utils/api';
import { activeProjectIdSelector } from '../../project.selectors';
import {
  loadInferenceSummary,
  loadInferenceSummaryFailure,
  loadInferenceSummarySuccess,
} from './summary.slice';

function* loadHandler() {
  const projectId = yield* select(activeProjectIdSelector);
  if (!projectId) return;

  try {
    const items = yield* loadAll({
      apiHelper: apiLoadProjectInferenceSummary,
      params: { projectId },
    });

    yield* put(loadInferenceSummarySuccess(items));
  } catch (e) {
    yield* put(
      loadInferenceSummaryFailure(
        getErrorMessage(e, 'Could not load inference summary'),
      ),
    );
  }
}

export function* inferenceSummarySaga() {
  yield* takeEvery(loadInferenceSummary, loadHandler);
}
