import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type FiltersStateType = {
  dataset: string | null;
  status: string | null;
  search: string | null;
  tags: string | null;
  csEfRunId: string | null;
  csUserAction: string | null;
  csErrorType: string | null;
  aldiSessionId: string | null;
};

export enum FilterType {
  Dataset = 'dataset',
  Status = 'status',
  Search = 'search',
  Tags = 'tags',
  CSEFRunId = 'csEfRunId',
  CSUserAction = 'csUserAction',
  CSErrorType = 'csErrorType',
  AldiSessionId = 'aldiSessionId',
}

type FiltersPayload = PayloadAction<{
  filter: FilterType;
  value: string | null;
}>;

const initialState: FiltersStateType = {
  dataset: null,
  status: null,
  search: '',
  tags: null,
  csEfRunId: null,
  csUserAction: null,
  csErrorType: null,
  aldiSessionId: null,
};
const { actions, reducer: imageViewBottomBarFiltersReducer } = createSlice({
  name: 'imageViewBottomBarFilters',
  initialState,
  reducers: {
    setFilters(state, action: FiltersPayload) {
      const { filter, value } = action.payload;

      state[filter] = value;
    },
    setFiltersSilent(state, action: FiltersPayload) {
      const { filter, value } = action.payload;

      state[filter] = value;
    },
    applyFilters(_state, _payload: FiltersPayload) {},
    applyMultipleFilters(
      _state,
      _action: PayloadAction<
        Record<string, string | string[] | null | undefined>
      >,
    ) {},
    setMultipleFilters(state, action: PayloadAction<[FilterType, string][]>) {
      action.payload.forEach(([field, value]) => {
        state[field] = value;
      });
    },
    initializeFilters(state, action: PayloadAction<FiltersStateType>) {
      state.dataset = action.payload.dataset;
      state.status = action.payload.status;
      state.search = action.payload.search;
      state.tags = action.payload.tags;
      state.csEfRunId = action.payload.csEfRunId;
      state.csUserAction = action.payload.csUserAction;
      state.csErrorType = action.payload.csErrorType;
      state.aldiSessionId = action.payload.aldiSessionId;
    },
    resetFilters(state) {
      state.dataset = initialState.dataset;
      state.status = initialState.status;
      state.search = initialState.search;
      state.tags = initialState.tags;
      state.csEfRunId = initialState.csEfRunId;
      state.csUserAction = initialState.csUserAction;
      state.csErrorType = initialState.csErrorType;
      state.aldiSessionId = initialState.aldiSessionId;
    },
  },
});

export { imageViewBottomBarFiltersReducer };
export const {
  applyFilters,
  applyMultipleFilters,
  setMultipleFilters,
  setFilters,
  setFiltersSilent,
  resetFilters,
  initializeFilters,
} = actions;
