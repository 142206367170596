import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { ImageStatus } from '../../../../../../api/domainModels/imageStatus';
import { SplitPartitionObject } from '../../../../../../api/domainModels/modelPlayground';
import { loadingStateBuilder } from '../../../../../utils/loadingState';
import { setActiveSplitId } from '../activeSplitId/activeSplitId.slice';
import { setEditedProjectId } from '../../../../sections/editedProject/project/project.slice';

export type SplitResultFilter = {
  partitionId: string | null;
  imageName: string;
  datasetIds: string[];
  imageStatuses: ImageStatus[];
  labelClassIds: string[];
};

export const adapter = createEntityAdapter<SplitPartitionObject>({
  selectId: (item) => item.labelId || item.imageId,
});

const initialFilterState = {
  partitionId: null,
  imageName: '',
  datasetIds: [],
  imageStatuses: [],
  labelClassIds: [],
} as SplitResultFilter;
const initialState = {
  loadingState: loadingStateBuilder.initial(),
  items: adapter.getInitialState(),
  filter: initialFilterState,
  defaultFilter: initialFilterState,
  pagination: {
    page: 1,
    itemsPerPage: 100,
    total: 0,
  },
};
const { actions, reducer: splitResultsReducer } = createSlice({
  name: 'splitResults',
  initialState,
  reducers: {
    setSplitResultsFilter(
      state,
      action: PayloadAction<Partial<SplitResultFilter>>,
    ) {
      state.loadingState = loadingStateBuilder.inProgress();
      state.filter = {
        ...state.filter,
        ...action.payload,
      };
      state.pagination.page = 1;
      adapter.setAll(state.items, []);
    },
    setSplitResultsDefaultFilter(
      state,
      action: PayloadAction<Partial<SplitResultFilter>>,
    ) {
      state.defaultFilter = {
        ...state.defaultFilter,
        ...action.payload,
      };
      state.filter = { ...state.defaultFilter };
    },
    resetSplitResultsFilter(state, _action: unknown) {
      state.loadingState = loadingStateBuilder.inProgress();
      state.filter = {
        ...state.defaultFilter,
      };
      state.pagination.page = 1;
      adapter.setAll(state.items, []);
    },
    loadSplitResultsFailure(state, action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.failure(action.payload);
    },
    loadSplitResultsSuccess(
      state,
      action: PayloadAction<SplitPartitionObject[]>,
    ) {
      state.loadingState = loadingStateBuilder.success();
      adapter.setAll(state.items, action.payload);
    },
    setSplitResultsPage(state, action: PayloadAction<number>) {
      state.loadingState = loadingStateBuilder.inProgress();

      state.pagination.page = action.payload;
    },
    setSplitResultsTotal(state, action: PayloadAction<number>) {
      state.pagination.total = action.payload;
    },
    setSplitResultsItemsPerPage(state, action: PayloadAction<number>) {
      state.pagination.page = 1;
      state.pagination.itemsPerPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setActiveSplitId, () => initialState);
    builder.addCase(setEditedProjectId, () => initialState);
  },
});

export { splitResultsReducer };
export const {
  loadSplitResultsFailure,
  loadSplitResultsSuccess,
  setSplitResultsFilter,
  setSplitResultsDefaultFilter,
  resetSplitResultsFilter,
  setSplitResultsPage,
  setSplitResultsTotal,
  setSplitResultsItemsPerPage,
} = actions;
