const elementsPerColor = 4;
const opacityOffset = 3;
const FILLED_PIXEL_OPACITY_THRESHOLD = 100;

export const isPixelFilled = ({ data, width }, x, y) =>
  data[(y * width + x) * elementsPerColor + opacityOffset] >=
  FILLED_PIXEL_OPACITY_THRESHOLD;

export const paintImageData = ({ imageData, color }) => {
  const { width, height } = imageData;
  const newData = new Uint8ClampedArray(imageData.data.length);

  for (let y = 0; y < height; y += 1) {
    for (let x = 0; x < width; x += 1) {
      if (isPixelFilled(imageData, x, y)) {
        const offset = (y * width + x) * elementsPerColor;

        [
          newData[offset],
          newData[offset + 1],
          newData[offset + 2],
          newData[offset + 3],
        ] = color;
      }
    }
  }

  return new ImageData(newData, width, height);
};

export const scaleImageData = ({ imageData, canvasFactory, scale }) => {
  const srcCanvas = canvasFactory(imageData.width, imageData.height);
  const destCanvas = canvasFactory(
    Math.floor(imageData.width * scale),
    Math.floor(imageData.height * scale),
  );

  const srcCtx = srcCanvas.getContext('2d');
  const destCtx = destCanvas.getContext('2d');

  srcCtx.putImageData(imageData, 0, 0);

  destCtx.scale(scale, scale);
  destCtx.drawImage(srcCanvas, 0, 0);

  return destCtx.getImageData(0, 0, destCanvas.width, destCanvas.height);
};

export const getImageDataBounds = ({ imageData }) => {
  const { width, height } = imageData;
  let empty = true;
  let maxX = 0;
  let minX = width;
  let maxY = 0;
  let minY = height;

  for (let y = 0; y < height; y += 1) {
    for (let x = 0; x < width; x += 1) {
      if (isPixelFilled(imageData, x, y)) {
        empty = false;
        y >= maxY && (maxY = y);
        x >= maxX && (maxX = x);
        y <= minY && (minY = y);
        x <= minX && (minX = x);
      }
    }
  }

  return empty ? null : [minX, minY, maxX + 1, maxY + 1];
};
