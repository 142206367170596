import {
  createSlice,
  PayloadAction,
  createEntityAdapter,
} from '@reduxjs/toolkit';

import { JobProgress } from '../../../../constants/jobProgress';

export const adapter = createEntityAdapter<JobProgress>();

const { actions, reducer: jobProgressReducer } = createSlice({
  name: 'jobProgress',
  initialState: adapter.getInitialState(),
  reducers: {
    updateJobProgress(state, action: PayloadAction<JobProgress>) {
      const { id, progress } = action.payload;
      const existingProgress = state.entities[id];
      if (existingProgress && existingProgress.progress > progress) {
        return;
      }
      adapter.upsertOne(state, action.payload);
    },
  },
});

export { jobProgressReducer };

export const { updateJobProgress } = actions;
