import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const { actions, reducer: imageViewImageTagsPredictionToggleReducer } =
  createSlice({
    name: 'imageViewImageTagsPredictionToggle',
    initialState: {} as Record<string /* projectId */, boolean>,
    reducers: {
      toggleTagClassPrediction(
        state,
        action: PayloadAction<string /* projectId */>,
      ) {
        const currentValue = state[action.payload];

        state[action.payload] = !currentValue;
      },
    },
  });

export { imageViewImageTagsPredictionToggleReducer };
export const { toggleTagClassPrediction } = actions;
