import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';

import {
  LoadingState,
  loadingStateBuilder,
} from '../../../../utils/loadingState';
import { setProjectId } from '../../../core/imageView/project/project.slice';

export const dataAdapter = createEntityAdapter<{
  id: string;
  name: string;
  loadingState: LoadingState;
}>();
const initialState: {
  data: EntityState<{ id: string; name: string; loadingState: LoadingState }>;
  totalToUpload: number;
} = {
  data: dataAdapter.getInitialState(),
  totalToUpload: 0,
};
const { actions, reducer: imageUploadReducer } = createSlice({
  name: 'imageUpload',
  initialState,
  reducers: {
    uploadImage(state, action: PayloadAction<{ id: string; name: string }>) {
      dataAdapter.addOne(state.data, {
        id: action.payload.id,
        name: action.payload.name,
        loadingState: loadingStateBuilder.inProgress(),
      });
    },
    uploadImageSuccess(state, action: PayloadAction<{ id: string }>) {
      dataAdapter.updateOne(state.data, {
        id: action.payload.id,
        changes: {
          loadingState: loadingStateBuilder.success(),
        },
      });
    },
    uploadImageError(
      state,
      action: PayloadAction<{ id: string; message: string }>,
    ) {
      dataAdapter.updateOne(state.data, {
        id: action.payload.id,
        changes: {
          loadingState: loadingStateBuilder.failure(action.payload.message),
        },
      });
    },
    uploadImages(
      state,
      _action: PayloadAction<{
        projectId: string;
        datasetId: string;
        images: File[];
      }>,
    ) {},
    uploadImagesToSelectedDataset(
      state,
      action: PayloadAction<{ images: File[] }>,
    ) {
      state.totalToUpload = action.payload.images.length;
    },
    resetImageUpload() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setProjectId, () => initialState);
  },
});
export const {
  uploadImage,
  uploadImageSuccess,
  uploadImageError,
  uploadImages,
  uploadImagesToSelectedDataset,
  resetImageUpload,
} = actions;
export { imageUploadReducer };
