import { createSelector } from '@reduxjs/toolkit';

import {
  PackageFeature,
  PackageName,
  isFreePackage,
  isFreeOrParkedPackage,
} from '../../../../constants/packageFeatures';
import { managedWorkspaceDataSelector } from '../../sections/managedWorkspace/managedWorkspace.selectors';
import { workspacesListMapSelector } from '../../sections/projectList/workspaces/workspaces.selectors';
import { DisplayPlanName } from '../../../../components/managedWorkspace/tabs/payments/planSwitcher/planText';
import { commonFeaturesSelector } from '../commonFeatures.selectors';
import { CustomerBillingStatus } from '../../../../api/domainModels/payment';

const packageFeaturesSelector = createSelector(
  commonFeaturesSelector,
  (commonFeatures) => commonFeatures.packageFeatures,
);

export const packageFeaturesPlansSelector = createSelector(
  packageFeaturesSelector,
  (packageFeatures) => packageFeatures.plans,
);

export const packageFeaturesFeatureListSelector = createSelector(
  packageFeaturesSelector,
  (packageFeatures) => packageFeatures.features,
);

export const imagePackageFeatureSelector = createSelector(
  packageFeaturesFeatureListSelector,
  (packageFeatureList) =>
    packageFeatureList?.find(
      (feature) => feature.name === PackageFeature.ImageUpload,
    ),
);

export const aldiPackageFeatureSelector = createSelector(
  packageFeaturesFeatureListSelector,
  (packageFeatureList) =>
    packageFeatureList?.find((feature) => feature.name === PackageFeature.Aldi),
);

export const videoPackageFeatureSelector = createSelector(
  packageFeaturesFeatureListSelector,
  (packageFeatureList) =>
    packageFeatureList?.find(
      (feature) => feature.name === PackageFeature.VideoUpload,
    ),
);

export const modelPlaygroundPackageFeatureSelector = createSelector(
  packageFeaturesFeatureListSelector,
  (packageFeatureList) =>
    packageFeatureList?.find(
      (feature) => feature.name === PackageFeature.ModelPlayground,
    ),
);

export const packageFeaturesLoadingStateSelector = createSelector(
  packageFeaturesSelector,
  (packageFeatures) => packageFeatures.loadingState,
);

export const parkingPackageSelector = createSelector(
  packageFeaturesPlansSelector,
  (plans) =>
    plans
      ? plans.find(({ name }: { name: string }) => name === PackageName.Parked)
      : null,
);

export const parkingPackageNameSelector = createSelector(
  parkingPackageSelector,
  (plan) => plan?.name,
);

export const parkingPackageTitleSelector = createSelector(
  parkingPackageSelector,
  (plan) => plan?.title,
);

export const newWorkspacePackageFeaturesPlansSelector = createSelector(
  packageFeaturesPlansSelector,
  (plans) =>
    plans
      ? plans
          // regular monthly plans are grouped into a single card
          .filter(
            ({ name }) =>
              ![
                PackageName.MonthlyLarge,
                PackageName.MonthlyMedium,
                PackageName.MonthlySmall,
              ].includes(name),
          )
          .filter(
            ({ name }: { name: string }) =>
              name !== PackageName.Parked && name !== PackageName.Trial,
          )
      : null,
);

export const packageFeaturesPlansEmptySelector = createSelector(
  packageFeaturesPlansSelector,
  (plans) => !plans,
);

export const packageFeaturesForPlanCardsSelector = createSelector(
  [packageFeaturesPlansSelector, managedWorkspaceDataSelector],
  (plans, workspace) =>
    plans
      ? plans
          .filter(({ name }) => {
            // if workspace is on free/trial plan it can be upgraded but not parked
            if (
              workspace?.subscription &&
              isFreePackage(workspace?.subscription)
            ) {
              return (
                workspace?.subscription === name || !isFreeOrParkedPackage(name)
              );
            }

            return !isFreePackage(name);
          })
          // regular monthly plans are grouped into a single card
          .filter(
            ({ name }) =>
              ![
                PackageName.MonthlyLarge,
                PackageName.MonthlyMedium,
                PackageName.MonthlySmall,
              ].includes(name),
          )
          .map(({ name, ...rest }) => ({
            ...rest,
            name: name as DisplayPlanName,
          }))
          .sort(
            (a: { price: number }, b: { price: number }) => a.price - b.price,
          )
      : null,
);

export const packageFeaturesSelfServiceSelector = createSelector(
  [packageFeaturesPlansSelector],
  (plans) =>
    plans
      ? plans
          .filter(({ name }) =>
            [
              PackageName.MonthlyLarge,
              PackageName.MonthlyMedium,
              PackageName.MonthlySmall,
            ].includes(name),
          )
          .sort(
            (a: { price: number }, b: { price: number }) => a.price - b.price,
          )
      : null,
);

export const isWorkspaceOnCustomSubscriptionSelector = createSelector(
  [
    workspacesListMapSelector,
    packageFeaturesPlansSelector,
    (_: RootState, workspaceId: string) => workspaceId,
  ],
  (workspaces, plans, workspaceId) =>
    plans
      ? !plans.find(
          ({ name }) => name === workspaces[workspaceId]?.subscription,
        )
      : true,
);

export const packageTitleByNameSelector = createSelector(
  [packageFeaturesPlansSelector, (_: RootState, planName: string) => planName],
  (plans, planName) =>
    plans ? plans.find(({ name }) => name === planName)?.title : null,
);

export const packageIsDowngradingSelector = createSelector(
  [
    packageFeaturesPlansSelector,
    (_: RootState, subscription: CustomerBillingStatus['subscription']) =>
      subscription,
  ],
  (plans, subscription) => {
    const currentPlanPrice = plans
      ? plans.find(({ name }) => name === subscription.planCurrentPeriod)?.price
      : undefined;

    const nextPlanPrice = plans
      ? plans.find(({ name }) => name === subscription.planNextPeriod)?.price
      : undefined;

    return (
      !!currentPlanPrice && !!nextPlanPrice && currentPlanPrice > nextPlanPrice
    );
  },
);
