import axios from 'axios';

import {
  ApiRankingJobFormValues,
  ApiHeuristic,
  ApiHeuristicRankingJob,
  ApiSetHeuristicResponse,
} from '../schemas/heuristic';
import { apiWrapper } from '../utils';
import { API_URL } from '../../constants/paths';
import { headers, transformRequest, transformResponse } from '../helpers';
import { convertToBackendObject } from '../../redux/utils/backendParams';
import { ModelFamily } from '../constants/modelFamily';

export const apiLoadHeuristics = ({
  projectId,
  params,
}: {
  projectId: string;
  params?: {
    offset: number;
    limit: number;
  };
}) =>
  apiWrapper(
    axios.request<ApiHeuristic[]>({
      method: 'get',
      headers,
      transformResponse,
      url: `${API_URL}/projects/${projectId}/heuristics`,
      params: params ? convertToBackendObject(params) : null,
    }),
  );

export const apiSetHeuristic = ({
  projectId,
  family,
  heuristicId,
}: {
  projectId: string;
  family: ModelFamily;
  heuristicId: string | null;
}) =>
  apiWrapper(
    axios.request<ApiSetHeuristicResponse>({
      method: 'post',
      headers,
      transformRequest,
      transformResponse,
      url: `${API_URL}/projects/${projectId}/heuristics/model/${family}`,
      data: {
        heuristicId,
      },
    }),
  );

export const apiLoadHeuristicsRankingJobs = ({
  projectId,
}: {
  projectId: string;
}) =>
  apiWrapper(
    axios.request<ApiHeuristicRankingJob[]>({
      method: 'get',
      headers,
      transformRequest,
      transformResponse,
      url: `${API_URL}/projects/${projectId}/rankings/sessions`,
    }),
  );

export const apiAddHeuristicRankingJob = (
  params: { projectId: string },
  data: ApiRankingJobFormValues,
) =>
  apiWrapper(
    axios.request<ApiHeuristicRankingJob>({
      method: 'post',
      headers,
      transformRequest,
      transformResponse,
      url: `${API_URL}/projects/${params.projectId}/rankings`,
      data,
    }),
  );

export const apiRemoveHeuristicRankingJob = (params: {
  projectId: string;
  sessionId: ApiHeuristicRankingJob['sessionId'];
}) =>
  apiWrapper(
    axios.request<void>({
      method: 'delete',
      headers,
      transformRequest,
      transformResponse,
      url: `${API_URL}/projects/${params.projectId}/rankings/sessions/${params.sessionId}`,
    }),
  );
