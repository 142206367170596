import { createSelector } from '@reduxjs/toolkit';

import { componentsAdapter, parametersAdapter } from './schedulers.slice';
import { modelPlaygroundSelector } from '../../modelPlayground.selectors';

export const experimentSchedulersStateSelector = createSelector(
  modelPlaygroundSelector,
  (modelPlayground) => modelPlayground.activeExperiment.schedulers,
);
export const experimentSchedulersComponentsStateSelector = createSelector(
  experimentSchedulersStateSelector,
  (state) => state.components,
);

const componentsSelectors = componentsAdapter.getSelectors(
  experimentSchedulersComponentsStateSelector,
);
export const experimentSchedulersDataSelector = componentsSelectors.selectAll;

export const selectedSchedulerSelector = createSelector(
  experimentSchedulersDataSelector,
  (schedulers) => schedulers.find((scheduler) => scheduler.selected),
);
export const selectedSchedulerIdSelector = createSelector(
  selectedSchedulerSelector,
  (selectedScheduler) => selectedScheduler?.id,
);
export const selectedSchedulerComponentIdSelector = createSelector(
  selectedSchedulerSelector,
  (selectedScheduler) => selectedScheduler?.componentId,
);
export const experimentSchedulersLoadingStateSelector = createSelector(
  experimentSchedulersComponentsStateSelector,
  (state) => state.loadingState,
);
export const experimentSchedulersUpdateLoadingStateSelector = createSelector(
  experimentSchedulersComponentsStateSelector,
  (state) => state.updateLoadingState,
);

export const experimentSchedulersParametersStateSelector = createSelector(
  experimentSchedulersStateSelector,
  (state) => state.parameters,
);

const parametersSelectors = parametersAdapter.getSelectors(
  experimentSchedulersParametersStateSelector,
);
export const schedulerParametersSelector = parametersSelectors.selectAll;

export const schedulerParametersLoadingStateSelector = createSelector(
  experimentSchedulersParametersStateSelector,
  (state) => state.loadingState,
);
export const schedulerParametersUpdateLoadingStateSelector = createSelector(
  experimentSchedulersParametersStateSelector,
  (state) => state.updateLoadingState,
);
export const schedulerParameterByIdSelector = (
  state: RootState,
  id: string,
) => {
  const parameters = schedulerParametersSelector(state);

  return parameters.find((parameter) => parameter.id === id);
};
