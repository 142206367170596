import { Bbox } from '../@types/imageView/types';
import { ImageLabel } from '../api/domainModels/imageLabel';
import { KeypointSchema, MinimalKeypoint } from '../api/domainModels/keypoints';

export type KeypointConnection = {
  id: string;
  color: string;
  x1: number;
  y1: number;
  x2: number;
  y2: number;
};

type MinimalKeypoints = MinimalKeypoint[] | undefined;

export const getKeypointConnectionsFromSkeleton = (
  keypoints: MinimalKeypoints = [],
  keypointSkeleton: KeypointSchema['keypointSkeleton'],
  absoluteScale: number,
  bbox: Bbox = [0, 0, 0, 0],
): KeypointConnection[] => {
  if (!keypointSkeleton) return [];

  return keypointSkeleton
    .map(({ color, keypointClassIdA, keypointClassIdB, id }) => {
      const classA = keypoints.find(
        (keypoint) => keypoint.keypointClassId === keypointClassIdA,
      );
      const classB = keypoints.find(
        (keypoint) => keypoint.keypointClassId === keypointClassIdB,
      );

      if (!classA || !classB) return undefined;

      return {
        id,
        color,
        x1: (classA.x - bbox[0]) * absoluteScale,
        y1: (classA.y - bbox[1]) * absoluteScale,
        x2: (classB.x - bbox[0]) * absoluteScale,
        y2: (classB.y - bbox[1]) * absoluteScale,
      };
    })
    .filter(Boolean) as KeypointConnection[];
};

export const getAvailableKeypoints = (keypoints: ImageLabel['keypoints']) =>
  keypoints?.filter((keypoint) => !keypoint.isDeleted) || [];
