import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import undoable from 'redux-undo';

import { resetKeypoints } from '../keypoints.actions';
import { KeypointsPoint } from '../../../../../../../api/domainModels/imageLabel';

const initialState = {
  points: [] as KeypointsPoint[],
};
const { actions, reducer } = createSlice({
  name: 'keypointValues',
  initialState,
  reducers: {
    addKeypoint(state, action: PayloadAction<{ point: KeypointsPoint }>) {
      state.points.push(action.payload.point);
    },
    addKeypoints(
      state,
      action: PayloadAction<{
        points: KeypointsPoint[];
      }>,
    ) {
      state.points = action.payload.points;
    },
    undoKeypoints() {},
    redoKeypoints() {},
    clearKeypointsHistory() {},
    removeLastKeypoint(state) {
      state.points.pop();
    },
    discardKeypointsValues() {},
    moveKeypoint(
      state,
      action: PayloadAction<{ id: string; x: number; y: number }>,
    ) {
      const index = state.points.findIndex(
        (point) => point.id === action.payload.id,
      );

      if (index !== -1) {
        state.points[index].x = action.payload.x;
        state.points[index].y = action.payload.y;
      }
    },
    togglePointOcclusion(state, action: PayloadAction<{ id: string }>) {
      const index = state.points.findIndex(
        (point) => point.id === action.payload.id,
      );

      if (index !== -1) {
        state.points[index] = {
          ...state.points[index],
          visible: !state.points[index].visible,
        };
      }
    },
    removeKeypoint(state, action: PayloadAction<{ id: string }>) {
      const index = state.points.findIndex(
        (point) => point.id === action.payload.id,
      );

      if (index !== -1) {
        state.points = state.points.filter(
          (point) => point.id !== action.payload.id,
        );
      }
    },
    acceptKeypointsResult() {},
  },
  extraReducers: (builder) => {
    builder.addCase(resetKeypoints, () => initialState);
  },
});

export const {
  addKeypoint,
  addKeypoints,
  moveKeypoint,
  clearKeypointsHistory,
  undoKeypoints,
  redoKeypoints,
  removeLastKeypoint,
  discardKeypointsValues,
  togglePointOcclusion,
  removeKeypoint,
  acceptKeypointsResult,
} = actions;

export const keypointsValuesReducer = undoable(reducer, {
  undoType: undoKeypoints.type,
  redoType: redoKeypoints.type,
  clearHistoryType: clearKeypointsHistory.type,
});
