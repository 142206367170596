import axios from 'axios';

import { headers, transformResponse, transformRequest } from '../helpers';
import { apiWrapper } from '../utils';
import { NEW_API_URL } from '../../constants/paths';
import {
  ApiUploadVideoChunksToCloudParams,
  ApiUploadVideoToBackendData,
  ApiUploadVideoToBackendParams,
  ApiUploadVideoToCloudParams,
} from '../schemas/videoUpload';

export const apiUploadVideoToCloud = (params: ApiUploadVideoToCloudParams) =>
  apiWrapper(
    axios.request<void>({
      method: 'put',
      withCredentials: false,
      url: params.uploadData.url,
      data: params.binaryString,
      headers: {
        'Content-Type': 'video/*',
      },
      onUploadProgress: params.onUploadProgress,
    }),
  );

export const apiUploadVideoChunksToCloud = (
  params: ApiUploadVideoChunksToCloudParams,
) =>
  apiWrapper(
    axios.request<void>({
      method: 'put',
      withCredentials: false,
      url: params.url,
      data: params.data,
      headers: {
        'Content-Type': 'video/*',
        'Content-Range': `bytes ${params.bytes.start}-${params.bytes.end - 1}/${
          params.bytes.total
        }`,
      },
      validateStatus: (status) => status === 308 || status === 200,
      onUploadProgress: params.onUploadProgress,
    }),
  );

export const apiUploadVideoToBackend = (
  params: ApiUploadVideoToBackendParams,
  data: ApiUploadVideoToBackendData,
) =>
  apiWrapper(
    axios.request<void>({
      method: 'post',
      url: `${NEW_API_URL}/projects/${params.projectId}/videos`,
      headers,
      transformResponse,
      transformRequest,
      data,
    }),
  );
