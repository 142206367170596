import { select, put, take, call, takeLatest } from 'typed-redux-saga';
import clamp from 'lodash/clamp';
import { push } from 'connected-react-router';

import { imageViewCurrentImageOffsetSelector } from '../currentImage/currentImage.selectors';
import {
  imageGalleryItemsDataSelector,
  imageGalleryItemsTotalSelector,
} from '../imageGallery/imageGalleryItems/imageGalleryItems.selectors';
import {
  loadGalleryImagesError,
  loadGalleryImagesSuccess,
  requestGalleryImageLoad,
} from '../imageGallery/imageGalleryItems/imageGalleryItems.slice';
import { goToNextImage, goToPreviousImage } from './imageNavigation.actions';
import { setImageOffset } from '../currentImage/currentImage.slice';
import { activeProjectIdSelector } from '../../../project/project.selectors';
import { IMAGE_VIEW_PATHNAME_SEGMENT } from '../imageView.util';
import { labelSyncPreventsNavigationSelector } from '../labels/labelSync/labelSync.selectors';
import { enqueueNotification } from '../../../ui/stackNotifications/stackNotifications.slice';
import { routerSelector } from '../../../root.selectors';

function* getImageIdByOffset(offset: number) {
  // if the image in the gallery, no network requests are required
  const galleryData = yield* select(imageGalleryItemsDataSelector);

  if (galleryData && galleryData[offset]) {
    return galleryData[offset].id;
  }

  return null;
}

function* shiftCurrentImageOffset(adjustment: number) {
  const cannotLeave: boolean = yield* select(
    labelSyncPreventsNavigationSelector,
  );

  if (cannotLeave) {
    yield* put(
      enqueueNotification({
        message: 'Cannot navigate while the workspace state is syncing.',
        options: {
          variant: 'info',
        },
      }),
    );

    return;
  }

  const currentOffset = yield* select(imageViewCurrentImageOffsetSelector);
  const total = yield* select(imageGalleryItemsTotalSelector);
  const projectId = yield* select(activeProjectIdSelector);

  if (currentOffset === null) return;

  const newOffset = clamp(currentOffset + adjustment, 0, total);

  if (currentOffset !== newOffset) {
    yield* put(setImageOffset(newOffset));
    // navigate to image on the new offset
    let newImageId: string | null = yield* getImageIdByOffset(newOffset);

    if (!newImageId) {
      yield* put(requestGalleryImageLoad(newOffset));
      // wait until images are loaded
      yield* take([loadGalleryImagesSuccess, loadGalleryImagesError]);
      newImageId = yield* getImageIdByOffset(newOffset);
    }

    const {
      location: { search },
    } = yield* select(routerSelector);

    if (newImageId) {
      yield* put(
        push(
          `/projects/${projectId}/${IMAGE_VIEW_PATHNAME_SEGMENT}/${newImageId}${search}`,
        ),
      );
    }
  }
}

function* goToPreviousImageHandler() {
  yield* call(shiftCurrentImageOffset, -1);
}

function* goToNextImageHandler() {
  yield* call(shiftCurrentImageOffset, 1);
}

export function* imageNavigationSaga() {
  yield* takeLatest(goToNextImage, goToNextImageHandler);
  yield* takeLatest(goToPreviousImage, goToPreviousImageHandler);
}
