import { put, takeEvery, call } from 'typed-redux-saga';

import {
  apiGetAdvancedOption,
  apiSaveAdvancedOption,
} from '../../../../../api/requests/advancedOptions';
import { getErrorMessage } from '../../../../../api/utils';
import {
  loadAdvancedOptionFailure,
  loadAdvancedOptionStart,
  loadAdvancedOptionSuccess,
  saveAdvancedOptionFailure,
  saveAdvancedOptionStart,
  saveAdvancedOptionSuccess,
} from './advancedOptions.slice';
import { AdvancedOptionApiSetting } from '../../../../../api/domainModels/advancedOptions';

function* loadHandler(action: ActionType<typeof loadAdvancedOptionStart>) {
  try {
    const { projectId, optionKey } = action.payload;
    const { data } = yield* call(apiGetAdvancedOption, {
      projectId,
      optionKey,
    });

    yield* put(loadAdvancedOptionSuccess(data));
  } catch (error) {
    yield* put(
      loadAdvancedOptionFailure(
        getErrorMessage(error, 'Not able to load option'),
      ),
    );
  }
}

function* updateHandler(action: ActionType<typeof saveAdvancedOptionStart>) {
  try {
    const { projectId, optionKey, value } = action.payload;
    const settingValue: AdvancedOptionApiSetting = {
      settingValue: { [optionKey]: value },
    };
    const { data } = yield* call(
      apiSaveAdvancedOption,
      {
        projectId,
        optionKey,
      },
      settingValue,
    );
    yield* put(saveAdvancedOptionSuccess(data));
  } catch (error) {
    yield* put(
      saveAdvancedOptionFailure(
        getErrorMessage(error, 'Not able to update option'),
      ),
    );
  }
}

export function* advancedOptionsSaga() {
  yield* takeEvery(loadAdvancedOptionStart, loadHandler);
  yield* takeEvery(saveAdvancedOptionStart, updateHandler);
}
