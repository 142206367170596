import { createSelector } from '@reduxjs/toolkit';

import { splitsMapSelector } from '../../splits/data/data.selectors';
import { modelPlaygroundSelector } from '../../modelPlayground.selectors';

export const activeSplitIdSelector = createSelector(
  modelPlaygroundSelector,
  (modelPlayground) => modelPlayground.activeSplit.activeSplitId.id,
);
export const activeSplitSelector = createSelector(
  [activeSplitIdSelector, splitsMapSelector],
  (id, splitsMap) => splitsMap[id],
);

export const activeSplitDefaultMetricsSelector = createSelector(
  [activeSplitSelector],
  (split) => split?.defaultMetric,
);
