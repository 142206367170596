/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Project2 = {
  /**
   * Project identifier
   */
  readonly id?: string;
  /**
   * Project name
   */
  name: string;
  /**
   * User owner identifier
   */
  readonly ownerId?: string;
  /**
   * Workspace identifier
   */
  workspaceId?: string | null;
  /**
   * Project description
   */
  description?: string | null;
  /**
   * Project creation date
   */
  readonly createdOn?: string;
  /**
   * Content type (images or videos)
   */
  contentType?: Project2.contentType;
};

export namespace Project2 {

  /**
   * Content type (images or videos)
   */
  export enum contentType {
    IMAGES = 'IMAGES',
    VIDEOS = 'VIDEOS',
  }


}

