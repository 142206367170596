import { put, takeEvery, call, select } from 'typed-redux-saga';

import {
  apiAddHeuristicRankingJob,
  apiRemoveHeuristicRankingJob,
  apiLoadHeuristicsRankingJobs,
} from '../../../../api/requests/heuristic';
import { modelFamilyLabels } from '../../../../api/domainModels/modelFamily';
import { getErrorMessage } from '../../../../api/utils';
import { handleError } from '../../commonFeatures/errorHandler/errorHandler.actions';
import { imageViewImageGalleryFiltersAldiSessionIdSelector } from '../../core/imageView/imageGallery/bottomBar/filters/filters.selectors';
import {
  applyFilters,
  FilterType,
} from '../../core/imageView/imageGallery/bottomBar/filters/filters.slice';
import { hideModals } from '../../ui/modals/modals.slice';
import { enqueueNotification } from '../../ui/stackNotifications/stackNotifications.slice';
import { activeProjectIdSelector } from '../project.selectors';
import {
  heuristicsRankingJobsSelector,
  heuristicsRankingJobsPendingRankingJobSelector,
  heuristicsRunningRankingJobsSelector,
} from './heuristicsRankingJobs.selectors';
import {
  addHeuristicRankingJob,
  addHeuristicRankingJobSuccess,
  addHeuristicRankingJobsFailure,
  addPendingHeuristicRankingJob,
  applyPendingHeuristicRankingJob,
  loadHeuristicsRankingJobs,
  loadHeuristicsRankingJobsFailure,
  loadHeuristicsRankingJobsSuccess,
  removeHeuristicRankingJob,
  removeHeuristicRankingJobFailure,
  removeHeuristicRankingJobSuccess,
  resetPendingHeuristicRankingJob,
  updateHeuristicRankingJobSuccess,
  updateHeuristicRankingJobFromWebsocket,
  updateIsNewRankingJobAvailableIndicator,
} from './heuristicsRankingJobs.slice';
import {
  HeuriscticRankingJobStatus,
  HeuristicRankingOrigin,
} from '../../../../api/constants/heuristic';
import { ModelFamily } from '../../../../api/constants/modelFamily';

function* rankingJobsListHandler(
  action: ActionType<typeof loadHeuristicsRankingJobs>,
) {
  const { projectId } = action.payload;

  try {
    const { data } = yield* call(apiLoadHeuristicsRankingJobs, {
      projectId,
    });

    yield* put(loadHeuristicsRankingJobsSuccess(data));
  } catch (error) {
    const message = getErrorMessage(
      error,
      'Not able to load heuristic ranking jobs',
    );

    yield* put(loadHeuristicsRankingJobsFailure(message));

    yield* put(handleError({ message, error, allowOutsideOfEditor: true }));
  }
}

function* addItemHandler(action: ActionType<typeof addHeuristicRankingJob>) {
  const { projectId, ...payload } = action.payload;

  try {
    const { data } = yield* call(
      apiAddHeuristicRankingJob,
      { projectId },
      payload,
    );

    yield* put(addHeuristicRankingJobSuccess(data));
    yield* put(hideModals());
  } catch (error) {
    const message = getErrorMessage(
      error,
      'Not able to add heuristics running ranking job',
    );

    const regex = new RegExp(
      `/${ModelFamily.Detector}|${ModelFamily.Segmentor}|${ModelFamily.SemanticSegmentor}/gi`,
    );

    const errorMsg = message.replace(regex, (matched) => {
      const modelFamily = matched as ModelFamily;

      return modelFamilyLabels[modelFamily];
    });

    yield* put(addHeuristicRankingJobsFailure(errorMsg));
  }
}

function* newRankingAvailableHandler() {
  const pendingRankingJob = yield* select(
    heuristicsRankingJobsPendingRankingJobSelector,
  );

  if (!pendingRankingJob) return;

  yield* put(
    updateHeuristicRankingJobSuccess({
      id: pendingRankingJob.sessionId,
      changes: pendingRankingJob,
    }),
  );
  yield* put(
    applyFilters({
      filter: FilterType.AldiSessionId,
      value: pendingRankingJob.sessionId,
    }),
  );
  yield* put(resetPendingHeuristicRankingJob());
}

function* removeRankingJobHandler(
  action: ActionType<typeof removeHeuristicRankingJob>,
) {
  const { projectId, sessionId } = action.payload;

  try {
    yield* call(apiRemoveHeuristicRankingJob, { projectId, sessionId });

    yield* put(removeHeuristicRankingJobSuccess({ sessionId }));
    yield* put(hideModals());
  } catch (error) {
    const message = getErrorMessage(
      error,
      'Not able to remove heuristics ranking job',
    );

    yield* put(removeHeuristicRankingJobFailure(message));

    yield* put(handleError({ message, error, allowOutsideOfEditor: true }));
  }
}

function* updateRankingJobFromWebsocketHandler(
  action: ActionType<typeof updateHeuristicRankingJobFromWebsocket>,
) {
  const { projectId, ...wsHeuristicRankingJob } = action.payload;
  const { modelFamily, status, origin, sessionName, heuristicName } =
    wsHeuristicRankingJob;

  const isRunDone = status === HeuriscticRankingJobStatus.Done;
  const isRunFailed = status === HeuriscticRankingJobStatus.Failed;
  const isRunRunning = status === HeuriscticRankingJobStatus.Running;
  const isOnDemandRankingJob = origin === HeuristicRankingOrigin.OnDemand;

  const activeProjectId = yield* select(activeProjectIdSelector);

  if (activeProjectId !== projectId) {
    return;
  }

  const runningRankingJobs = yield* select(
    heuristicsRunningRankingJobsSelector,
  );

  const runningRankingJob = runningRankingJobs.find(
    (rankingJob) => rankingJob.sessionId === wsHeuristicRankingJob.sessionId,
  );

  if (isRunRunning) {
    if (runningRankingJob) {
      yield* put(
        updateHeuristicRankingJobSuccess({
          id: runningRankingJob.sessionId,
          changes: { ...runningRankingJob, ...wsHeuristicRankingJob },
        }),
      );
    } else {
      yield* put(addHeuristicRankingJobSuccess(wsHeuristicRankingJob));
    }
  }

  if (isRunFailed) {
    const message = `Heuristics ranking job ${sessionName} for ${modelFamilyLabels[modelFamily]} ${heuristicName} has failed. Please contact Hasty support.`;

    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
          error: message,
        },
      }),
    );

    yield* put(
      updateHeuristicRankingJobSuccess({
        id: wsHeuristicRankingJob.sessionId,
        changes: { ...runningRankingJob, ...wsHeuristicRankingJob },
      }),
    );
  }

  if (isRunDone) {
    yield* put(
      enqueueNotification({
        message:
          'You Active learning rank is complete. Go to the Annotation Environment to use it.',
        options: {
          variant: 'success',
          allowOutsideOfEditor: true,
        },
      }),
    );

    if (isOnDemandRankingJob) {
      yield* put(
        updateHeuristicRankingJobSuccess({
          id: wsHeuristicRankingJob.sessionId,
          changes: {
            ...runningRankingJob,
            ...wsHeuristicRankingJob,
          },
        }),
      );
      yield* put(updateIsNewRankingJobAvailableIndicator(true));
    } else {
      const heuristicsRankingJobs = yield* select(
        heuristicsRankingJobsSelector,
      );

      const maybeExistingRankingJobIndex = heuristicsRankingJobs.findIndex(
        ({ modelFamily, heuristicId }) =>
          modelFamily === wsHeuristicRankingJob.modelFamily &&
          heuristicId === wsHeuristicRankingJob.heuristicId,
      );

      const activeRankingJobId = yield* select(
        imageViewImageGalleryFiltersAldiSessionIdSelector,
      );

      if (maybeExistingRankingJobIndex === -1) {
        yield* put(addHeuristicRankingJobSuccess(wsHeuristicRankingJob));
        yield* put(updateIsNewRankingJobAvailableIndicator(true));
      } else if (activeRankingJobId) {
        const activeRankingJob = heuristicsRankingJobs.find(
          ({ sessionId }) => sessionId === activeRankingJobId,
        );

        if (
          activeRankingJob &&
          activeRankingJob.modelFamily === wsHeuristicRankingJob.modelFamily &&
          activeRankingJob.heuristicId === wsHeuristicRankingJob.heuristicId
        ) {
          yield* put(addPendingHeuristicRankingJob(wsHeuristicRankingJob));
        } else {
          yield* put(
            updateHeuristicRankingJobSuccess({
              id: wsHeuristicRankingJob.sessionId,
              changes: wsHeuristicRankingJob,
            }),
          );
          yield* put(updateIsNewRankingJobAvailableIndicator(true));
        }
      } else {
        yield* put(
          updateHeuristicRankingJobSuccess({
            id: wsHeuristicRankingJob.sessionId,
            changes: wsHeuristicRankingJob,
          }),
        );
        yield* put(updateIsNewRankingJobAvailableIndicator(true));
      }
    }
  }
}

export function* heuristicsRankingJobsSaga() {
  yield* takeEvery(loadHeuristicsRankingJobs, rankingJobsListHandler);
  yield* takeEvery(addHeuristicRankingJob, addItemHandler);
  yield* takeEvery(applyPendingHeuristicRankingJob, newRankingAvailableHandler);
  yield* takeEvery(removeHeuristicRankingJob, removeRankingJobHandler);
  yield* takeEvery(
    updateHeuristicRankingJobFromWebsocket,
    updateRankingJobFromWebsocketHandler,
  );
}
