import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { AttributeClassMapping } from '../../../../../api/domainModels/attributeClasses';
import { loadingStateBuilder } from '../../../../utils/loadingState';

export const selectId = (
  acm: Pick<AttributeClassMapping, 'classId' | 'attributeId'>,
) => `${acm.attributeId}-${acm.classId}`;

export const relationsAdapter = createEntityAdapter<AttributeClassMapping>({
  selectId,
});

const { actions, reducer: ACRelationsReducer } = createSlice({
  name: 'ACRelations',
  initialState: relationsAdapter.getInitialState({
    loadingState: loadingStateBuilder.initial(),
  }),
  reducers: {
    loadACRelations(state) {
      state.loadingState = loadingStateBuilder.inProgress();
    },
    loadACRelationsSuccess(
      state,
      action: PayloadAction<AttributeClassMapping[]>,
    ) {
      state.loadingState = loadingStateBuilder.success();
      relationsAdapter.setAll(state, action.payload);
    },
    loadACRelationsFailure(state, action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.failure(action.payload);
      relationsAdapter.setAll(state, []);
    },
    processACRelationsStart(state) {
      state.loadingState = loadingStateBuilder.inProgress();
    },
    processACRelationsFailure(state, action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.failure(action.payload);
    },
    updateACManySuccess(state, action: PayloadAction<AttributeClassMapping[]>) {
      state.loadingState = loadingStateBuilder.success();
      relationsAdapter.upsertMany(state, action.payload);
    },
    updateACRelationByWebsocket(
      state,
      action: PayloadAction<AttributeClassMapping>,
    ) {
      relationsAdapter.upsertOne(state, action.payload);
    },
    deleteACManySuccess(state, action: PayloadAction<AttributeClassMapping[]>) {
      state.loadingState = loadingStateBuilder.success();
      relationsAdapter.removeMany(state, action.payload.map(selectId));
    },
    deleteACRelationByWebsocket(
      state,
      action: PayloadAction<AttributeClassMapping>,
    ) {
      relationsAdapter.removeOne(state, selectId(action.payload));
    },
  },
});

export { ACRelationsReducer };
export const {
  loadACRelations,
  loadACRelationsFailure,
  loadACRelationsSuccess,
  processACRelationsStart,
  processACRelationsFailure,
  updateACManySuccess,
  deleteACManySuccess,
  updateACRelationByWebsocket,
  deleteACRelationByWebsocket,
} = actions;
