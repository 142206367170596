import { call, put, takeEvery, select } from 'typed-redux-saga';

import { apiLoadWidgetExperimentsMetrics } from '../../../../../../../api/requests/widgets';
import { getErrorMessage } from '../../../../../../../api/utils';
import { activeProjectIdSelector } from '../../../../../project/project.selectors';
import {
  loadInferenceTime,
  loadInferenceTimeSuccess,
  loadInferenceTimeFailure,
  updateMetricsColor,
} from './inferenceTime.slice';
import { widgetInferenceTimeMapper } from '../../../../../../../api/domainModels/widgets';
import { updateExperimentSuccess } from '../../../experiments/experimentsData/experimentsData.slice';
import { enqueueNotification } from '../../../../../ui/stackNotifications/stackNotifications.slice';

function* loadInferenceTimeHandler(
  action: ActionType<typeof loadInferenceTime>,
) {
  const projectId = yield* select(activeProjectIdSelector);
  const experimentIds = action.payload;

  try {
    const { data } = yield* call(
      apiLoadWidgetExperimentsMetrics,
      {
        projectId,
      },
      { experimentIds },
    );

    const items = data.items.map(widgetInferenceTimeMapper.fromBackend);

    yield* put(loadInferenceTimeSuccess(items));
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to fetch widget data');

    yield* put(loadInferenceTimeFailure(message));
    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
          refresh: false,
        },
      }),
    );
  }
}

function* updateExperimentSuccessHandler(
  action: ActionType<typeof updateExperimentSuccess>,
) {
  const { changes, id } = action.payload;

  if (changes.color)
    yield* put(updateMetricsColor({ id, color: changes.color }));
}

export function* inferenceTimeSaga() {
  yield* takeEvery(loadInferenceTime, loadInferenceTimeHandler);
  yield* takeEvery(updateExperimentSuccess, updateExperimentSuccessHandler);
}
