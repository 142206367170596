import { call, put, select, takeEvery } from 'typed-redux-saga';

import { attributeDataMapper } from '../../../../../api/domainModels/attribute';
import { attributeValueDataMapper } from '../../../../../api/domainModels/attributeValue';
import {
  apiCreateProjectAttribute,
  apiDeleteProjectAttribute,
  apiLoadProjectAttributes,
  apiUpdateProjectAttribute,
} from '../../../../../api/requests/attribute';
import { WebsocketAttributeUpdatedPayload } from '../../../../../api/domainModels/websocketTypes';
import { getErrorMessage } from '../../../../../api/utils';
import { setProjectId } from '../../../core/imageView/project/project.slice';
import { initImageProject } from '../../../sections/editedProject/project/project.slice';
import { loadAll } from '../../../../utils/api';
import { hideModals } from '../../../ui/modals/modals.slice';
import { ATTRIBUTE_DELETED, ATTRIBUTE_UPDATED } from '../../../ws/ws.constants';
import { activeProjectIdSelector } from '../../project.selectors';
import {
  addAttribute,
  addAttributeFailure,
  addAttributeSuccess,
  loadAttributesFailure,
  loadAttributesStart,
  loadAttributesSuccess,
  removeAttribute,
  removeAttributeFailure,
  removeAttributeFromWebsockets,
  removeAttributeSuccess,
  resetAttributeLoadingState,
  updateAttribute,
  updateAttributeFailure,
  updateAttributeOrder,
  updateAttributeSuccess,
  upsertAttributeFromWebsockets,
} from './attributes.slice';
import {
  attributeByIdSelector,
  attributesSelector,
} from './attributes.selectors';
import { processACRelations } from '../ACRelations/ACRelations.saga';
import { getNewOrder } from '../../../../../util/norder';

function* listHandler(
  action: ActionType<typeof initImageProject | typeof setProjectId>,
) {
  const projectId = action.payload.id;

  yield* put(loadAttributesStart());

  try {
    const attributes = yield* loadAll({
      apiHelper: apiLoadProjectAttributes,
      params: { projectId },
      fromBackend: attributeDataMapper.fromBackend,
    });

    yield* put(loadAttributesSuccess(attributes));
  } catch (error) {
    yield* put(
      loadAttributesFailure(
        getErrorMessage(error, 'Not able to load attributes'),
      ),
    );
  }
}

function* addAttributeHandler(action: ActionType<typeof addAttribute>) {
  const projectId = yield* select(activeProjectIdSelector);
  const values = action.payload;
  try {
    const { data } = yield* call(
      apiCreateProjectAttribute,
      {
        projectId,
      },
      attributeDataMapper.toBackend(values),
    );
    const newAttribute = attributeDataMapper.fromBackend(data);

    try {
      yield* processACRelations(
        newAttribute.id,
        action.payload.labelClasses.map((labelClassId, index) => ({
          classId: labelClassId,
          attributeId: newAttribute.id,
          classOrder: 0,
          attributeOrder: index,
        })),
      );
      yield* put(hideModals());
    } catch (error) {
      // do nothing, error processed in that saga. We only catch to not hide the modal
    } finally {
      // see comment in updateAttributeHandler
      yield* put(addAttributeSuccess(newAttribute));
    }
  } catch (error) {
    yield* put(
      addAttributeFailure(
        getErrorMessage(error, 'Not able to create the attribute'),
      ),
    );
  }
}

function* updateAttributeHandler(action: ActionType<typeof updateAttribute>) {
  const projectId = yield* select(activeProjectIdSelector);
  const { id, ...values } = action.payload;
  try {
    const { data } = yield* call(
      apiUpdateProjectAttribute,
      {
        projectId,
        attributeId: id,
      },
      attributeDataMapper.toBackend(values),
    );
    try {
      yield* processACRelations(
        id,
        action.payload.labelClasses.map((labelClassId, index) => ({
          classId: labelClassId,
          attributeId: id,
          classOrder: 0,
          attributeOrder: index,
        })),
      );

      yield* put(hideModals());
    } catch (error) {
      // do nothing, error processed in that saga. We only catch to not hide the modal
    } finally {
      // have it here so that the status is loading while the relations are processed,
      // and make it success in any case, since we have a separate loading state for relations
      yield* put(
        updateAttributeSuccess({
          changes: attributeDataMapper.fromBackend(data),
          id,
        }),
      );
    }
  } catch (error) {
    yield* put(
      updateAttributeFailure(
        getErrorMessage(error, 'Not able to update the attribute'),
      ),
    );
  }
}

function* removeAttributeHandler(action: ActionType<typeof removeAttribute>) {
  const projectId = yield* select(activeProjectIdSelector);
  const id = action.payload;
  try {
    yield* call(apiDeleteProjectAttribute, {
      projectId,
      attributeId: id,
    });
    yield* put(removeAttributeSuccess(id));
    yield* put(hideModals());
  } catch (error) {
    yield* put(
      removeAttributeFailure(
        getErrorMessage(error, 'Not able to remove the attribute'),
      ),
    );
  }
}

function* reorderAttributeHandler(
  action: ActionType<typeof updateAttributeOrder>,
) {
  const projectId = yield* select(activeProjectIdSelector);
  const { id, index } = action.payload;
  const attribute = yield* select((state: RootState) =>
    attributeByIdSelector(state, id),
  );
  if (!attribute) {
    // eslint-disable-next-line no-console
    console.log('failed attribute reorder, label not found for id ', id);

    return;
  }
  // here we're calculating the new norder as the median between
  // the two attributes' norders we're dropping the dragged attribute in
  const attributes = yield* select(attributesSelector);
  const newOrder = getNewOrder(attributes, index);

  if (newOrder !== null) {
    try {
      yield* call(
        apiUpdateProjectAttribute,
        {
          projectId,
          attributeId: id,
        },
        attributeDataMapper.toBackend({
          ...attribute,
          norder: newOrder,
        }),
      );
      yield* put(
        updateAttributeSuccess({
          changes: { norder: newOrder },
          id,
        }),
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('failed attribute reorder', error);
    }
  }
}

function* websocketAttributeUpdatedHandler({
  id,
  name,
  attributeType,
  order,
  description,
  lovList,
  projectId,
}: WebsocketAttributeUpdatedPayload & { type: string }) {
  const currentProjectId = yield* select(activeProjectIdSelector);
  if (currentProjectId && currentProjectId === projectId) {
    yield* put(
      upsertAttributeFromWebsockets({
        id,
        name,
        type: attributeType,
        norder: order,
        description,
        values: lovList.map(attributeValueDataMapper.fromBackend),
      }),
    );
  }
}

function* websocketAttributeRemovedHandler({
  id,
  projectId,
}: WebsocketAttributeUpdatedPayload & { type: string }) {
  const currentProjectId = yield* select(activeProjectIdSelector);
  if (currentProjectId && currentProjectId === projectId) {
    yield* put(removeAttributeFromWebsockets(id));
  }
}

function* hideModalsHandler() {
  yield* put(resetAttributeLoadingState());
}

export function* attributesSaga() {
  yield* takeEvery([initImageProject, setProjectId], listHandler);
  yield* takeEvery(addAttribute, addAttributeHandler);
  yield* takeEvery(updateAttribute, updateAttributeHandler);
  yield* takeEvery(removeAttribute, removeAttributeHandler);
  yield* takeEvery(updateAttributeOrder, reorderAttributeHandler);
  yield* takeEvery(hideModals, hideModalsHandler);
  yield* takeEvery(ATTRIBUTE_UPDATED, websocketAttributeUpdatedHandler);
  yield* takeEvery(ATTRIBUTE_DELETED, websocketAttributeRemovedHandler);
}
