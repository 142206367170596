import styled from 'styled-components';

// TODO: colors in this file come from the them directly so they are not theme aware.
// This should be fixed in future if we want to support multiple themes for payment methods
import { theme } from '../../../../../theme';

export const style = {
  base: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
    '::placeholder': {
      color: theme.palette.interactive.light,
    },
    ':-webkit-autofill': {
      color: theme.palette.common.white,
    },
    '-webkit-box-shadow': `0 0 0 ${theme.palette.background.default} inset`,
    iconColor: theme.palette.common.white,
  },
  invalid: {
    color: theme.palette.error.main,
  },
};

export const Notices = styled.div`
  margin-top: 1em;
`;

export const TabContentElement = styled.div`
  padding: 1em;
`;
