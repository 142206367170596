import { put, select, takeEvery } from 'typed-redux-saga';

import { ImageLabel } from '../../../../../api/domainModels/imageLabel';
import { ImageTool, ImageViewTool } from '../tools/tools.constants';
import {
  selectedLabelsIdsSelector,
  selectedLabelsSelector,
} from '../labels/selectedLabels/selectedLabels.selectors';
import { maxImageLabelZIndexSelector } from '../labels/labels.selectors';
import {
  setClipboard,
  cutSelectedLabels,
  copySelectedLabels,
  duplicateSelectedLabels,
  pasteLabelsFromClipboard,
} from './clipboard.slice';
import {
  clipboardDataSelector,
  clipboardSourceImageIdSelector,
} from './clipboard.selectors';
import { addLabels, deleteLabels } from '../labels/labels.slice';
import { setSelectedLabelsIds } from '../labels/selectedLabels/selectedLabels.slice';
import { enqueueNotification } from '../../../ui/stackNotifications/stackNotifications.slice';
import { uuidv4 } from '../../../../../util/uuidv4';
import { addLabelAttributeValues } from '../labelAttributeValues/labelAttributeValues.slice';

const labelToClipboardMapper = (label: ImageLabel) => {
  const { bbox, classId, mask, polygon, type, keypoints } = label;

  return {
    bbox,
    classId,
    mask,
    polygon,
    type,
    keypoints: keypoints
      ? keypoints.map((keypoint) => ({ ...keypoint, id: uuidv4() }))
      : keypoints,
    copiedFrom: label.id,
  };
};

const clipboardLabelToSaveMapper =
  (zIndex: number) => (label: Partial<ImageLabel>) => ({
    ...label,
    id: uuidv4(),
    zIndex,
    toolUsed: ImageTool.Copy as ImageViewTool,
  });

const duplicateLabelsMapper =
  (offset: number, zIndex: number) => (label: Partial<ImageLabel>) => {
    const { bbox, classId, mask, polygon, type, keypoints } = label;
    const newBbox = bbox
      ? (bbox.map((x) => x + offset) as [number, number, number, number])
      : bbox;
    const newPolygon = polygon
      ? (polygon.map((pair) => pair.map((x) => x + offset)) as [
          number,
          number,
        ][])
      : polygon;
    const newKeypoints = keypoints
      ? keypoints.map((keypoint) => ({
          ...keypoint,
          id: uuidv4(),
          x: keypoint.x + offset,
          y: keypoint.y + offset,
        }))
      : keypoints;

    return {
      id: uuidv4(),
      bbox: newBbox,
      classId,
      mask,
      polygon: newPolygon,
      zIndex,
      type,
      keypoints: newKeypoints,
      toolUsed: ImageTool.Copy as ImageViewTool,
    };
  };

const checkLabelsCoordinatesAgainstImage = ({
  data,
  imageWidth,
  imageHeight,
}: {
  data: Partial<ImageLabel>[];
  imageWidth: number;
  imageHeight: number;
}) => {
  let labelOutsideOfImage = false;

  data.forEach((label) => {
    if (label.bbox) {
      const [x1, y1, x2, y2] = label.bbox;
      if (
        x1 > imageWidth ||
        x2 > imageWidth ||
        y1 > imageHeight ||
        y2 > imageHeight
      ) {
        labelOutsideOfImage = true;

        return;
      }
    }
    if (label.polygon && !label.bbox) {
      for (const coordinate of label.polygon) {
        const [x, y] = coordinate;
        if (x > imageWidth || y > imageHeight) {
          labelOutsideOfImage = true;
        }
        if (labelOutsideOfImage) return;
      }
    }
  });

  return labelOutsideOfImage;
};

function* cutSelectedLabelsHandler(
  action: ActionType<typeof cutSelectedLabels>,
) {
  const { imageId } = action.payload;
  const selectedLabels = yield* select(selectedLabelsSelector);
  const selectedLabelsIds = yield* select(selectedLabelsIdsSelector);

  if (selectedLabels.length > 0) {
    yield* put(
      setClipboard({
        data: selectedLabels.map(labelToClipboardMapper),
        imageId,
      }),
    );
    yield* put(deleteLabels(selectedLabelsIds));
  }
}

function* copySelectedLabelsHandler(
  action: ActionType<typeof copySelectedLabels>,
) {
  const { imageId } = action.payload;
  const selectedLabels = yield* select(selectedLabelsSelector);

  if (selectedLabels.length > 0) {
    yield* put(
      setClipboard({
        data: selectedLabels.map(labelToClipboardMapper),
        imageId,
      }),
    );
  }
}

function* duplicateSelectedLabelsHandler(
  action: ActionType<typeof duplicateSelectedLabels>,
) {
  const { imageWidth, imageHeight } = action.payload;
  const selectedLabels = yield* select(selectedLabelsSelector);
  const zIndex = yield* select(maxImageLabelZIndexSelector);
  const data = selectedLabels.map(duplicateLabelsMapper(15, zIndex + 1));

  const fallsOutsideOfImageBounds = checkLabelsCoordinatesAgainstImage({
    data,
    imageWidth,
    imageHeight,
  });

  if (fallsOutsideOfImageBounds) {
    yield* put(
      enqueueNotification({
        message:
          'Cannot duplicate labels, some of the labels fall outside of image coordinates',
        options: {
          variant: 'info',
        },
      }),
    );

    return;
  }

  if (data.length > 0) {
    yield* put(addLabels(data));
    yield* put(setSelectedLabelsIds(data.map((datum) => datum.id)));
  }
}

function* pasteLabelsFromClipboardHandler(
  action: ActionType<typeof pasteLabelsFromClipboard>,
) {
  const { imageId, imageWidth, imageHeight } = action.payload;
  const sourceImageId = yield* select(clipboardSourceImageIdSelector);
  const clipboardData = yield* select(clipboardDataSelector);

  if (sourceImageId !== imageId) {
    const fallsOutsideOfImageBounds = checkLabelsCoordinatesAgainstImage({
      data: clipboardData,
      imageWidth,
      imageHeight,
    });

    if (fallsOutsideOfImageBounds) {
      yield* put(
        enqueueNotification({
          message:
            'Cannot paste labels, some of the labels fall outside of image coordinates',
          options: {
            variant: 'info',
          },
        }),
      );

      return;
    }
  }

  const zIndex = yield* select(maxImageLabelZIndexSelector);
  const data = clipboardData.map(clipboardLabelToSaveMapper(zIndex));

  if (data.length > 0) {
    yield* put(addLabels(data));
    yield* put(addLabelAttributeValues(data));
    yield* put(setSelectedLabelsIds(data.map((datum) => datum.id)));
  }
}

export function* clipboardSaga() {
  yield* takeEvery(cutSelectedLabels, cutSelectedLabelsHandler);
  yield* takeEvery(copySelectedLabels, copySelectedLabelsHandler);
  yield* takeEvery(duplicateSelectedLabels, duplicateSelectedLabelsHandler);
  yield* takeEvery(pasteLabelsFromClipboard, pasteLabelsFromClipboardHandler);
}
