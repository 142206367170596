import axios from 'axios';

import {
  ApiProjectRolePrivilege,
  ApiGrantPrivilegesResponse,
  ApiRevokePrivilegesResponse,
} from '../schemas/projectPrivileges';
import { API_URL } from '../../constants/paths';
import { apiWrapper } from '../utils';
import { headers, transformRequest, transformResponse } from '../helpers';

export const apiLoadProjectRolePrivileges = (projectId: string) =>
  apiWrapper(
    axios.request<ApiProjectRolePrivilege[]>({
      method: 'get',
      url: `${API_URL}/project/${projectId}/role_privileges`,
      headers,
      transformRequest,
      transformResponse,
    }),
  );

export const apiGrantProjectPrivileges = (
  projectId: string,
  roleId: number,
  privilegeIds: string[],
) =>
  apiWrapper(
    axios.request<ApiGrantPrivilegesResponse>({
      method: 'post',
      url: `${API_URL}/project/${projectId}/role/${roleId}/grant`,
      data: {
        privilege_ids: privilegeIds,
      },
      headers,
      transformRequest,
      transformResponse,
    }),
  );

export const apiRevokeProjectPrivileges = (
  projectId: string,
  roleId: number,
  privilegeIds: string[],
) =>
  apiWrapper(
    axios.request<ApiRevokePrivilegesResponse>({
      method: 'post',
      url: `${API_URL}/project/${projectId}/role/${roleId}/revoke`,
      data: {
        privilege_ids: privilegeIds,
      },
      headers,
      transformRequest,
      transformResponse,
    }),
  );
