import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type ProjectState = {
  projectId: string | null;
};
const initialState: ProjectState = {
  projectId: null,
};

const { actions, reducer: videoViewProjectReducer } = createSlice({
  name: 'videoViewProject',
  initialState,
  reducers: {
    setVideoViewProjectId(state, action: PayloadAction<{ id: string }>) {
      state.projectId = action.payload.id;
    },
  },
});

export { videoViewProjectReducer };

export const { setVideoViewProjectId } = actions;
