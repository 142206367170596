import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  LoadingState,
  loadingStateBuilder,
} from '../../../../utils/loadingState';
import { InferenceSummaryItem } from '../../../../../api/domainModels/inferences';

type InferenceMonitoringState = {
  items: InferenceSummaryItem[];
  loadingState: LoadingState;
};

const initialState: InferenceMonitoringState = {
  items: [],
  loadingState: loadingStateBuilder.initial(),
};

const { actions, reducer: summaryReducer } = createSlice({
  name: 'inferenceMonitoring/summary',
  initialState,
  reducers: {
    loadInferenceSummary(state) {
      state.items = [];
      state.loadingState = loadingStateBuilder.inProgress();
    },
    loadInferenceSummarySuccess(
      state,
      action: PayloadAction<InferenceSummaryItem[]>,
    ) {
      state.items = action.payload;
      state.loadingState = loadingStateBuilder.success();
    },
    loadInferenceSummaryFailure(state, action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.failure(action.payload);
    },
  },
});

export { summaryReducer };
export const {
  loadInferenceSummary,
  loadInferenceSummaryFailure,
  loadInferenceSummarySuccess,
} = actions;
