import React, { useCallback, useEffect } from 'react';
import { Box, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { TooltipProps } from '@mui/material/Tooltip/Tooltip';

import { BasicTooltip } from '../../../../tooltips/BasicTooltip';
import { hideControlledTooltip } from '../../../../../redux/state/ui/controlledTooltip/controlledTooltip.slice';
import { ControlledTooltipId } from '../../../../../constants/controlledTooltip';

type BasicControlledTooltipProps = TooltipProps & {
  timeout?: number;
  id: ControlledTooltipId;
};
export const BasicControlledTooltip: React.FC<BasicControlledTooltipProps> = ({
  title,
  children,
  timeout,
  id,
  ...rest
}) => {
  const dispatch = useDispatch();
  const handleClose = useCallback(() => {
    dispatch(hideControlledTooltip({ id }));
  }, [dispatch, id]);

  useEffect(() => {
    let scopeTimeout: number;

    if (timeout) {
      scopeTimeout = setTimeout(handleClose, timeout);
    }

    return () => {
      if (scopeTimeout) {
        clearTimeout(scopeTimeout);
      }
    };
  }, [handleClose, timeout]);

  return (
    <BasicTooltip
      placement="left"
      {...rest}
      title={
        <Box position="relative" minHeight={timeout ? 0 : 50}>
          {!timeout && (
            <IconButton
              onClick={handleClose}
              sx={{ position: 'absolute', right: 0, top: 0 }}
            >
              <CloseIcon />
            </IconButton>
          )}
          <Box pt={timeout ? 0 : 4}>{title}</Box>
        </Box>
      }
      open
      onClose={handleClose}
      disableFocusListener
      disableHoverListener
      disableTouchListener
    >
      {children}
    </BasicTooltip>
  );
};
