import { select, put, takeEvery, call } from 'typed-redux-saga';

import {
  apiDeleteImportedFile,
  apiLoadImportedFiles,
  apiParseImportedFiles,
} from '../../../../../../api/requests/imports';
import { getErrorMessage } from '../../../../../../api/utils';
import { handleError } from '../../../../commonFeatures/errorHandler/errorHandler.actions';
import { activeProjectIdSelector } from '../../../../project/project.selectors';
import { loadAll } from '../../../../../utils/api';
import { removeUploadFile } from '../uploads/importUploads.slice';
import {
  loadImportedFilesStart,
  loadImportedFilesSuccess,
  loadImportedFilesFailure,
  parseImportedFilesStart,
  parseImportedFilesFailure,
  removeImportedFileStart,
  removeImportedFileSuccess,
  removeImportedFileFailure,
  updateImportFileStatusFromWebsocket,
  updateImportedFile,
  updateImportedFiles,
} from './importFiles.slice';
import {
  importedFilesCumulativeStatusSelector,
  importFileByIdSelector,
  importFilesIdsSelector,
} from './importFiles.selectors';
import { activeImportSessionIdSelector } from '../session/importSession.selectors';
import {
  loadImportSessionsStart,
  updateImportSession,
} from '../sessions/importSessions.slice';
import { importSessionByIdSelector } from '../sessions/importSessions.selectors';
import {
  loadParseLogsStart,
  resetParseLogs,
} from '../parseLogs/importParseLogs.slice';
import { loadAllLabelClassesStart } from '../labelClass/importLabelClass.slice';
import { enqueueNotification } from '../../../../ui/stackNotifications/stackNotifications.slice';
import { ImportFileStatus } from '../../../../../../api/constants/import';

function* listHandler(action: ActionType<typeof loadImportedFilesStart>) {
  const { sessionId: sId, projectId: pId } = action.payload;
  const projectId = pId || (yield* select(activeProjectIdSelector));
  const sessionId = sId || (yield* select(activeImportSessionIdSelector));

  try {
    const items = yield* loadAll({
      apiHelper: apiLoadImportedFiles,
      params: {
        projectId,
        sessionId,
      },
    });

    yield* put(loadImportedFilesSuccess(items));
  } catch (error) {
    const errorMessage = getErrorMessage(
      error,
      'Not able to load imported files.',
    );

    yield* put(loadImportedFilesFailure(errorMessage));
    yield* put(
      handleError({ message: errorMessage, error, allowOutsideOfEditor: true }),
    );
  }
}

function* parseHandler() {
  const projectId = yield* select(activeProjectIdSelector);
  const sessionId = yield* select(activeImportSessionIdSelector);

  if (!sessionId) return;

  try {
    const {
      data: { id: parseJobId },
    } = yield* call(apiParseImportedFiles, { projectId, sessionId });

    const session = yield* select((state: RootState) =>
      importSessionByIdSelector(state, sessionId),
    );
    const fileIds = yield* select(importFilesIdsSelector);
    const updateStatuses = fileIds.map((fileId) => ({
      id: fileId,
      changes: {
        fileStatus: ImportFileStatus.Parsing,
        importedAttributes: 0,
        importedLabels: 0,
        importedLabelClasses: 0,
        importedTags: 0,
      },
    }));

    if (session) {
      yield* put(updateImportSession({ ...session, parseJobId }));
    }
    yield* put(updateImportedFiles(updateStatuses));
    yield* put(resetParseLogs());
    yield* put(
      enqueueNotification({
        message:
          'Parsing your files can take some time, you can close the modal and return to see the results later. We will notify you once parsing is finished.',
        options: {
          variant: 'success',
          allowOutsideOfEditor: true,
        },
      }),
    );
  } catch (error) {
    const errorMessage = getErrorMessage(
      error,
      'Not able to parse the imported files.',
    );

    yield* put(parseImportedFilesFailure(errorMessage));
    yield* put(
      handleError({ message: errorMessage, error, allowOutsideOfEditor: true }),
    );
  }
}

function* removeHandler(action: ActionType<typeof removeImportedFileStart>) {
  const projectId = yield* select(activeProjectIdSelector);
  const sessionId = yield* select(activeImportSessionIdSelector);
  const { fileId } = action.payload;

  if (!sessionId) return;

  const file = yield* select((state: RootState) =>
    importFileByIdSelector(state, fileId),
  );

  try {
    yield* call(apiDeleteImportedFile, { projectId, sessionId, fileId });
    yield* put(removeImportedFileSuccess(fileId));
    if (file) {
      yield* put(removeUploadFile(file.fileName));
    }
  } catch (error) {
    const errorMessage = getErrorMessage(
      error,
      'Not able to delete the imported file.',
    );

    yield* put(removeImportedFileFailure(errorMessage));

    yield* put(
      handleError({ message: errorMessage, error, allowOutsideOfEditor: true }),
    );
  }
}

function* updateFileStatusHandler(
  action: ActionType<typeof updateImportFileStatusFromWebsocket>,
) {
  const { id: fileId, fileStatus, sessionId, projectId } = action.payload;

  const file = yield* select((state: RootState) =>
    importFileByIdSelector(state, fileId),
  );

  if (!file) return;

  yield* put(
    updateImportedFile({
      ...file,
      fileStatus,
    }),
  );

  const importedFilesCumulativeStatus = yield* select(
    importedFilesCumulativeStatusSelector,
  );
  if (
    [ImportFileStatus.Processed, ImportFileStatus.Failed].includes(
      importedFilesCumulativeStatus,
    )
  ) {
    yield* put(loadImportSessionsStart({ projectId, initialFetch: false }));
    yield* put(
      loadImportedFilesStart({ projectId, sessionId, initialFetch: false }),
    );
    yield* put(
      loadAllLabelClassesStart({ projectId, sessionId, initialFetch: false }),
    );
    yield* put(
      loadParseLogsStart({ projectId, sessionId, initialFetch: false }),
    );
  }
}

export function* importFileSaga() {
  yield* takeEvery(loadImportedFilesStart, listHandler);
  yield* takeEvery(parseImportedFilesStart, parseHandler);
  yield* takeEvery(removeImportedFileStart, removeHandler);
  yield* takeEvery(
    updateImportFileStatusFromWebsocket,
    updateFileStatusHandler,
  );
}
