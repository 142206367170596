import { imageViewSagas } from './imageView/imageView.saga';
import { videoViewSagas } from './videoView/videoView.saga';
import { modelPlaygroundSagas } from './modelPlayground/modelPlayground.sagas';
import { errorFinderSagas } from './errorFinder/errorFinder.sagas';

export const coreSagas = [
  ...imageViewSagas,
  ...modelPlaygroundSagas,
  ...errorFinderSagas,
  ...videoViewSagas,
];
