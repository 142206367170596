import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingState, loadingStateBuilder } from '../../../utils/loadingState';
import { LookupField } from './configs/lookupField';
import {
  ABGroup,
  Lookup,
  ProfileResponse,
  SignInResponse,
  UpdateProfileParams,
  UpdateProfileResponse,
} from '../../../../api/domainModels/auth';

export type SelectLookups = Record<LookupField, Lookup[]>;
type InitialState = {
  abGroup: ABGroup | null;
  loadingState: LoadingState;
  profileUpdateLoadingState: LoadingState;
  profileLoadingState: LoadingState;
  updateSettingsLoadingState: LoadingState;
  updateFeaturesLoadingState: LoadingState;
  logoutLoadingState: LoadingState;
  userId: string | null;
  settings: Record<string, boolean | null>;
  features: Record<string, boolean>;
  username: string | null;
  email: string | null;
  acceptTerms: boolean | null;
  lookups: SelectLookups;
  jobTitle: string | null;
  jobSector: string | null;
  companyType: string | null;
  companyName: string | null;
  useCaseDescription: string | null;
  logoutTime: number | null;
};
const initialState: InitialState = {
  abGroup: null,
  loadingState: loadingStateBuilder.initial(),
  profileUpdateLoadingState: loadingStateBuilder.initial(),
  // to prevent url blinking on page reload, check web/src/app/routes/LoginRouteSwitch.tsx:L27
  profileLoadingState: loadingStateBuilder.inProgress(),
  updateSettingsLoadingState: loadingStateBuilder.initial(),
  updateFeaturesLoadingState: loadingStateBuilder.initial(),
  logoutLoadingState: loadingStateBuilder.initial(),
  logoutTime: null,
  userId: null,
  settings: {},
  features: {},
  username: null,
  email: null,
  acceptTerms: null,
  lookups: {} as Record<LookupField, Lookup[]>,
  jobTitle: null,
  jobSector: null,
  companyType: null,
  companyName: null,
  useCaseDescription: null,
};
const { actions, reducer: authReducer } = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    checkLoginToken() {},
    signIn(
      state,
      _action: PayloadAction<{
        token: string;
        redirectTo: string;
      }>,
    ) {},
    signInStart(state) {
      state.loadingState = loadingStateBuilder.inProgress('Signing in');
    },
    signInSuccess(state, action: PayloadAction<SignInResponse>) {
      state.loadingState = loadingStateBuilder.success();

      const {
        abGroup,
        userId,
        username,
        acceptTerms,
        settings,
        features,
        email,
        jobTitle,
        jobSector,
        companyType,
        companyName,
        useCaseDescription,
      } = action.payload;

      state.abGroup = abGroup;
      state.userId = userId;
      state.username = username;
      state.acceptTerms = acceptTerms;
      state.settings = settings;
      state.features = features;
      state.email = email;
      state.jobTitle = jobTitle;
      state.jobSector = jobSector;
      state.companyType = companyType;
      state.companyName = companyName;
      state.useCaseDescription = useCaseDescription;
    },
    resetSignInLoadingState(state) {
      state.loadingState = initialState.loadingState;
    },
    signInFailure(state, action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.failure(action.payload);
    },
    signInFailureRequest() {},
    logout(
      state,
      action: PayloadAction<{ skipRedirect?: boolean; logoutTime?: number }>,
    ) {
      const { logoutTime: logoutTimeNew } = action.payload;

      state.logoutLoadingState = loadingStateBuilder.inProgress();

      if (!state.logoutTime && logoutTimeNew) {
        state.logoutTime = logoutTimeNew;
      }
    },
    logoutFailure(state) {
      state.logoutTime = null;
      state.logoutLoadingState = loadingStateBuilder.failure();
    },
    logoutSuccess() {
      return {
        ...initialState,
        logoutTime: null,
        profileLoadingState: loadingStateBuilder.initial(),
      };
    },
    sendEmailLink(state, _action: PayloadAction<{ email: string }>) {
      state.loadingState = loadingStateBuilder.inProgress('Sending email link');
    },
    sendEmailLinkSuccess(state) {
      state.loadingState = loadingStateBuilder.success();
    },
    sendEmailLinkFailure(state, action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.failure(action.payload);
    },
    updateProfile(state, _action: PayloadAction<Partial<UpdateProfileParams>>) {
      state.profileUpdateLoadingState =
        loadingStateBuilder.inProgress('Updating profile');
    },
    updateProfileSuccess(state, action: PayloadAction<UpdateProfileResponse>) {
      state.profileUpdateLoadingState = loadingStateBuilder.success();
      const { username, acceptTerms, settings } = action.payload;

      state.username = username;
      state.acceptTerms = acceptTerms;
      state.settings = settings;
    },
    updateProfileFailure(state, action: PayloadAction<string>) {
      state.profileUpdateLoadingState = loadingStateBuilder.failure(
        action.payload,
      );
    },
    updateUserSettings(state, _action: PayloadAction<Record<string, boolean>>) {
      state.updateSettingsLoadingState = loadingStateBuilder.inProgress(
        'Updating preferences',
      );
    },
    updateUserSettingsSuccess(
      state,
      action: PayloadAction<Record<string, boolean>>,
    ) {
      state.updateSettingsLoadingState = loadingStateBuilder.success();
      state.settings = {
        ...state.settings,
        ...action.payload,
      };
    },
    updateUserSettingsFailure(state, action: PayloadAction<string>) {
      state.updateSettingsLoadingState = loadingStateBuilder.failure(
        action.payload,
      );
    },
    updateUserFeatures(state, _action: PayloadAction<Record<string, boolean>>) {
      state.updateFeaturesLoadingState = loadingStateBuilder.inProgress(
        'Updating preferences',
      );
    },
    updateUserFeaturesSuccess(
      state,
      action: PayloadAction<Record<string, boolean>>,
    ) {
      state.updateFeaturesLoadingState = loadingStateBuilder.success();
      state.features = {
        ...state.features,
        ...action.payload,
      };
    },
    updateUserFeaturesFailure(state, action: PayloadAction<string>) {
      state.updateFeaturesLoadingState = loadingStateBuilder.failure(
        action.payload,
      );
    },
    loadProfile(state) {
      state.profileLoadingState = loadingStateBuilder.inProgress();
    },
    loadProfileSuccess(state, action: PayloadAction<ProfileResponse>) {
      state.profileLoadingState = loadingStateBuilder.success();

      const {
        abGroup,
        userId,
        username,
        acceptTerms,
        settings,
        features,
        email,
        jobTitle,
        jobSector,
        companyType,
        companyName,
        useCaseDescription,
      } = action.payload;

      state.abGroup = abGroup;
      state.userId = userId;
      state.username = username;
      state.acceptTerms = acceptTerms;
      state.settings = settings;
      state.features = features;
      state.email = email;
      state.jobTitle = jobTitle;
      state.jobSector = jobSector;
      state.companyType = companyType;
      state.companyName = companyName;
      state.useCaseDescription = useCaseDescription;
    },
    loadProfileFailure(state, action: PayloadAction<string>) {
      state.profileLoadingState = loadingStateBuilder.failure(action.payload);
    },
    loadLookups() {},
    loadLookupsSuccess(state, action: PayloadAction<SelectLookups>) {
      state.lookups = action.payload;
    },
  },
});

export const {
  checkLoginToken,
  signIn,
  signInStart,
  signInSuccess,
  signInFailure,
  signInFailureRequest,
  resetSignInLoadingState,
  logout,
  logoutFailure,
  logoutSuccess,
  sendEmailLink,
  sendEmailLinkSuccess,
  sendEmailLinkFailure,
  updateProfile,
  updateProfileSuccess,
  updateProfileFailure,
  updateUserSettings,
  updateUserSettingsSuccess,
  updateUserSettingsFailure,
  updateUserFeatures,
  updateUserFeaturesSuccess,
  updateUserFeaturesFailure,
  loadProfile,
  loadProfileSuccess,
  loadProfileFailure,
  loadLookups,
  loadLookupsSuccess,
} = actions;
export { authReducer };
