import { createSelector } from '@reduxjs/toolkit';

import { RunType } from '../../../../api/constants/consensusScoring';

export const errorFinderSelector = (state: RootState) => state.core.errorFinder;

export const errorFinderGeneralSelector = (state: RootState) =>
  state.core.errorFinder.general;

export const activeRunSelector = createSelector(
  errorFinderGeneralSelector,
  (state) => state.activeRun,
);

export const activeRunIdSelector = createSelector(
  activeRunSelector,
  (activeRun) => activeRun.id,
);

export const activeRunTypeSelector = createSelector(
  activeRunSelector,
  (activeRun) => activeRun.type,
);

export const isClassReviewRunTypeSelector = createSelector(
  activeRunTypeSelector,
  (runType) => runType === RunType.ClassReview,
);

export const isTagReviewRunTypeSelector = createSelector(
  activeRunTypeSelector,
  (runType) => runType === RunType.TagReview,
);

export const isOdIsReviewRunTypeSelector = createSelector(
  activeRunTypeSelector,
  (runType) =>
    runType === RunType.ObjectReview || runType === RunType.InstanceReview,
);

export const isAttributeReviewRunTypeSelector = createSelector(
  activeRunTypeSelector,
  (runType) => runType === RunType.AttributeReview,
);

export const errorFinderOpacitySelector = createSelector(
  errorFinderGeneralSelector,
  (state) => state.opacity,
);

export const errorFinderPredictedOpacitySelector = createSelector(
  errorFinderGeneralSelector,
  (state) => state.predictedOpacity,
);

export const currentLabelIdSelector = createSelector(
  errorFinderGeneralSelector,
  (state) => state.currentLabelId,
);
