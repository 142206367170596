import { ModelFamily } from '../../../../../../api/constants/modelFamily';
import { ImageTool } from '../tools.constants';

export const TOOLS_DISABLING_CLASS_PREDICTION = [
  ImageTool.SemanticSegmentation,
];

// these tools support class prediction being toggled on in UI even if semantic class is selected
export const TOOLS_ENABLING_CLASS_PREDICTION = [
  ImageTool.InstanceSegmentation,
  ImageTool.ObjectDetection,
];

// these tools do not need class prediction performed as they already predict a class
export const TOOLS_INCLUDING_CLASS_PREDICTION = [
  ImageTool.InstanceSegmentation,
  ImageTool.ObjectDetection,
];

export const MODEL_MESSAGES = {
  availability:
    'Label class prediction is now available. You can turn on it with the toggle on the right',
  progress: 'Loading label class prediction model',
  error: 'Not able to load label class prediction model',
};
export const LABEL_CLASS_PREDICTION_FAMILY_NAME = ModelFamily.Classifier;
