import { call, put, select, takeEvery } from 'typed-redux-saga';

import {
  updateProject,
  updateProjectFailure,
  updateProjectSuccess,
} from './project.slice';
import { activeProjectIdSelector } from '../../../project/project.selectors';
import { getErrorMessage } from '../../../../../api/utils';
import { apiUpdateProject } from '../../../../../api/requests/project';
import { DOMAIN_ID } from '../../../../../constants/project';

function* updateProjectHandler(action: ActionType<typeof updateProject>) {
  const values = { ...action.payload, domainId: DOMAIN_ID };
  const projectId = yield* select(activeProjectIdSelector);

  try {
    const response = yield* call(apiUpdateProject, { projectId }, values);
    const project = response.data;

    yield* put(updateProjectSuccess(project));
  } catch (error) {
    yield* put(
      updateProjectFailure(
        getErrorMessage(error, 'Not able to update the project'),
      ),
    );
  }
}

export function* projectSaga() {
  yield* takeEvery(updateProject, updateProjectHandler);
}
