import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
  Update,
} from '@reduxjs/toolkit';

import { ImportLabelClass } from '../../../../../../api/domainModels/imports';
import { loadingStateBuilder } from '../../../../../utils/loadingState';
import { setProjectId } from '../../../../core/imageView/project/project.slice';

export type LabelClassFormValues = Pick<
  ImportLabelClass,
  'classId' | 'className' | 'refClassId' | 'imageId' | 'color' | 'ignoreClass'
>;

export const adapter = createEntityAdapter<ImportLabelClass>({
  selectId: (item) => item.classId,
});
const initialState = adapter.getInitialState({
  listLoadingState: loadingStateBuilder.initial(),
  itemLoadingState: loadingStateBuilder.initial(),
});

const { actions, reducer: importLabelClassReducer } = createSlice({
  name: 'importLabelClass',
  initialState,
  reducers: {
    loadAllLabelClassesStart(
      state,
      action: PayloadAction<{
        projectId?: string;
        sessionId?: string;
        initialFetch?: boolean;
      }>,
    ) {
      const { initialFetch = false } = action.payload;
      if (initialFetch) {
        state.listLoadingState = loadingStateBuilder.inProgress();
      }
    },
    loadAllLabelClassesSuccess(
      state,
      action: PayloadAction<ImportLabelClass[]>,
    ) {
      state.listLoadingState = loadingStateBuilder.success();
      adapter.setAll(state, action.payload);
    },
    loadAllLabelClassesFailure(state, action: PayloadAction<string>) {
      state.listLoadingState = loadingStateBuilder.failure(action.payload);
    },
    loadLabelClassStart(state, _action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.inProgress();
    },
    loadLabelClassSuccess(state, action: PayloadAction<ImportLabelClass>) {
      state.itemLoadingState = loadingStateBuilder.success();
      adapter.upsertOne(state, action.payload);
    },
    loadLabelClassFailure(state, action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.failure(action.payload);
    },
    updateLabelClassStart(state, _action: PayloadAction<LabelClassFormValues>) {
      state.itemLoadingState = loadingStateBuilder.inProgress();
    },
    updateLabelClassSuccess(
      state,
      action: PayloadAction<Update<ImportLabelClass>>,
    ) {
      state.itemLoadingState = loadingStateBuilder.success();
      adapter.updateOne(state, action.payload);
    },
    updateLabelClassFailure(state, action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.failure(action.payload);
    },
    finishMatchingLabelClassesStart(
      _,
      _action: PayloadAction<{ onNextStep: () => void }>,
    ) {},
    resetLabelClasses(state) {
      state.listLoadingState = loadingStateBuilder.initial();
      adapter.removeAll(state);
    },
    skipLoadingLabelClasses(state) {
      state.listLoadingState = loadingStateBuilder.success();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setProjectId, () => initialState);
  },
});

export { importLabelClassReducer };
export const {
  loadAllLabelClassesStart,
  loadAllLabelClassesSuccess,
  loadAllLabelClassesFailure,
  loadLabelClassStart,
  loadLabelClassSuccess,
  loadLabelClassFailure,
  updateLabelClassStart,
  updateLabelClassSuccess,
  updateLabelClassFailure,
  finishMatchingLabelClassesStart,
  resetLabelClasses,
  skipLoadingLabelClasses,
} = actions;
