import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
  Update,
} from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import {
  ExplainabilityMethod,
  ExplainabilityRun,
  ExplainabilityRunResult,
  ExplainabilityRunFormValues,
} from '../../../../../../../api/domainModels/widgets';
import { ApiPaginationMeta } from '../../../../../../../api/helpers';
import { loadingStateBuilder } from '../../../../../../utils/loadingState';
import { setEditedProjectId } from '../../../../../sections/editedProject/project/project.slice';
import { ExplainabilityRunJobStatus } from '../../../../../../../api/constants/widgets';

export const runsAdapter = createEntityAdapter<ExplainabilityRun>({
  sortComparer: (a, b) => (dayjs(a.startedOn).isAfter(b.startedOn) ? -1 : 1),
});
export const methodsAdapter = createEntityAdapter<ExplainabilityMethod>();
export const resultsAdapter = createEntityAdapter<ExplainabilityRunResult>({
  selectId: ({ resultId }) => resultId,
});

const initialState = {
  runs: runsAdapter.getInitialState({
    itemLoadingState: loadingStateBuilder.initial(),
    listLoadingState: loadingStateBuilder.initial(),
  }),
  methods: methodsAdapter.getInitialState({
    listLoadingState: loadingStateBuilder.initial(),
  }),
  results: resultsAdapter.getInitialState({
    listLoadingState: loadingStateBuilder.initial(),
    meta: null as ApiPaginationMeta | null,
    selectedResultId: null as string | null,
  }),
  showSaliency: false,
};

const { actions, reducer: explainabilityReducer } = createSlice({
  name: 'widgets/explainability',
  initialState,
  reducers: {
    loadExplainabilityRunsStart(
      state,
      _action: PayloadAction<{
        projectId: string;
        experimentId: string;
      }>,
    ) {
      state.runs.listLoadingState = loadingStateBuilder.inProgress();
    },
    loadExplainabilityRunsFailure(state, action: PayloadAction<string>) {
      state.runs.listLoadingState = loadingStateBuilder.failure(action.payload);
      runsAdapter.setAll(state.runs, []);
    },
    loadExplainabilityRunsSuccess(
      state,
      action: PayloadAction<ExplainabilityRun[]>,
    ) {
      state.runs.listLoadingState = loadingStateBuilder.success();

      runsAdapter.setAll(state.runs, action.payload);
    },
    loadExplainabilityMethodsStart(
      state,
      _action: PayloadAction<{
        projectId: string;
        experimentId: string;
      }>,
    ) {
      state.methods.listLoadingState = loadingStateBuilder.inProgress();
    },
    loadExplainabilityMethodsFailure(state, action: PayloadAction<string>) {
      state.methods.listLoadingState = loadingStateBuilder.failure(
        action.payload,
      );
      methodsAdapter.setAll(state.methods, []);
    },
    loadExplainabilityMethodsSuccess(
      state,
      action: PayloadAction<ExplainabilityMethod[]>,
    ) {
      state.methods.listLoadingState = loadingStateBuilder.success();

      methodsAdapter.setAll(state.methods, action.payload);
    },
    addExplainabilityRunStart(
      state,
      _action: PayloadAction<
        ExplainabilityRunFormValues & {
          projectId: string;
          experimentId: string;
        }
      >,
    ) {
      state.runs.itemLoadingState = loadingStateBuilder.inProgress();
    },
    addExplainabilityRunFailure(state, action: PayloadAction<string>) {
      state.runs.itemLoadingState = loadingStateBuilder.failure(action.payload);
    },
    addExplainabilityRunSuccess(
      state,
      action: PayloadAction<ExplainabilityRun>,
    ) {
      state.runs.listLoadingState = loadingStateBuilder.success();

      runsAdapter.addOne(state.runs, action.payload);
    },
    removeExplainabilityRunStart(
      state,
      _action: PayloadAction<{
        projectId: string;
        experimentId: string;
        explainabilityRunId: string;
      }>,
    ) {
      state.runs.itemLoadingState = loadingStateBuilder.inProgress();
    },
    removeExplainabilityRunFailure(state, action: PayloadAction<string>) {
      state.runs.itemLoadingState = loadingStateBuilder.failure(action.payload);
    },
    removeExplainabilityRunSuccess(state, action: PayloadAction<string>) {
      state.runs.listLoadingState = loadingStateBuilder.success();

      runsAdapter.removeOne(state.runs, action.payload);
    },
    updateExplainabilityRunJobProgressFromWs(
      _state,
      _action: PayloadAction<{
        jobId: string;
        projectId: string;
        experimentId: string;
      }>,
    ) {},
    updateExplainabilityRunJobStatusFromWs(
      _state,
      _action: PayloadAction<{
        jobId: string;
        projectId: string;
        experimentId: string;
      }>,
    ) {},
    updateExplainabilityRun(
      state,
      action: PayloadAction<Update<ExplainabilityRun>>,
    ) {
      runsAdapter.updateOne(state.runs, action.payload);
    },
    setShowSaliency(state, action: PayloadAction<boolean>) {
      state.showSaliency = action.payload;
    },
    toggleSetSaliency(state) {
      state.showSaliency = !state.showSaliency;
    },
    resetRunItemLoadingState(state) {
      state.runs.itemLoadingState = loadingStateBuilder.initial();
    },
    loadExplainabilityRunResultsStart(
      state,
      _action: PayloadAction<{
        projectId: string;
        experimentId: string;
        explainabilityRunId: string;
        limit: number;
        offset: number;
      }>,
    ) {
      state.results.listLoadingState = loadingStateBuilder.inProgress();
    },
    loadExplainabilityRunResultsSuccess(
      state,
      action: PayloadAction<{
        items: ExplainabilityRunResult[];
        meta: ApiPaginationMeta;
      }>,
    ) {
      const { items, meta } = action.payload;

      state.results.listLoadingState = loadingStateBuilder.success();
      state.results.meta = meta;
      resultsAdapter.setAll(state.results, items);
    },
    loadExplainabilityRunResultsFailure(state, action: PayloadAction<string>) {
      state.results.listLoadingState = loadingStateBuilder.failure(
        action.payload,
      );
    },
    setSelectedResultId(state, action: PayloadAction<string | null>) {
      state.results.selectedResultId = action.payload;
    },
    goToNextResult() {},
    goToPreviousResult() {},
    stopExplainabilityRunStart(
      state,
      _action: PayloadAction<{
        projectId: string;
        experimentId: string;
        explainabilityRunId: string;
      }>,
    ) {
      state.runs.itemLoadingState = loadingStateBuilder.inProgress();
    },
    stopExplainabilityRunSuccess: {
      reducer(state, action: PayloadAction<Update<ExplainabilityRun>>) {
        state.runs.itemLoadingState = loadingStateBuilder.success();
        runsAdapter.updateOne(state.runs, action.payload);
      },
      prepare(payload) {
        return {
          payload: {
            id: payload,
            changes: {
              status: ExplainabilityRunJobStatus.Canceled,
            },
          },
        };
      },
    },
    stopExplainabilityRunFailure(state, action: PayloadAction<string>) {
      state.runs.itemLoadingState = loadingStateBuilder.failure(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setEditedProjectId, () => initialState);
  },
});

export { explainabilityReducer };
export const {
  loadExplainabilityRunsStart,
  loadExplainabilityRunsSuccess,
  loadExplainabilityRunsFailure,
  addExplainabilityRunStart,
  addExplainabilityRunSuccess,
  addExplainabilityRunFailure,
  removeExplainabilityRunStart,
  removeExplainabilityRunSuccess,
  removeExplainabilityRunFailure,
  updateExplainabilityRunJobProgressFromWs,
  updateExplainabilityRunJobStatusFromWs,
  updateExplainabilityRun,
  resetRunItemLoadingState,

  stopExplainabilityRunStart,
  stopExplainabilityRunSuccess,
  stopExplainabilityRunFailure,

  loadExplainabilityMethodsStart,
  loadExplainabilityMethodsSuccess,
  loadExplainabilityMethodsFailure,

  setShowSaliency,
  toggleSetSaliency,

  loadExplainabilityRunResultsStart,
  loadExplainabilityRunResultsSuccess,
  loadExplainabilityRunResultsFailure,
  setSelectedResultId,
  goToNextResult,
  goToPreviousResult,
} = actions;
