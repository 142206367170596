import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  LoadingState,
  loadingStateBuilder,
} from '../../../../utils/loadingState';
import { resetManagedWorkspaceId } from '../../../commonFeatures/workspaceId/workspaceId.slice';

export type InvoicesState = {
  loadingState: LoadingState;
  data: any;
};
const initialState: InvoicesState = {
  loadingState: loadingStateBuilder.initial(),
  data: { invoices: [], creditsUsage: [] },
};

const { actions, reducer: invoicesReducer } = createSlice({
  name: 'managedWorkspace/invoices',
  initialState,
  reducers: {
    loadInvoices(state, _action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.inProgress('Loading invoices');
    },
    loadInvoicesSuccess(state, action: PayloadAction<any>) {
      state.loadingState = loadingStateBuilder.success();
      state.data = action.payload;
    },
    loadInvoicesFailure(state, action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.failure(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetManagedWorkspaceId, () => initialState);
  },
});

export const { loadInvoices, loadInvoicesFailure, loadInvoicesSuccess } =
  actions;
export { invoicesReducer };
