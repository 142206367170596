/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ModelFamilyStatus = {
  /**
   * Model Family
   */
  modelFamily: ModelFamilyStatus.modelFamily;
  /**
   * AI Assistant status
   */
  status: ModelFamilyStatus.status;
  /**
   * When the model will be trained next
   */
  trainsNext: string;
  /**
   * Number of objects needed
   */
  requiredObjects: number;
  /**
   * Number of existed needed
   */
  existedObjects: number;
  /**
   * If label or image used as train object
   */
  trainUnit: ModelFamilyStatus.trainUnit;
  /**
   * What metric is used to evaluate model (Y axis on charts)
   */
  targetMetric: ModelFamilyStatus.targetMetric;
  /**
   * Metrics used to evaluate the model (Y axis on charts)
   */
  targetMetrics: Array<{
    /**
     * Name of the metric to use in fetching values for the chart
     */
    name?: 'AverageLoss' | 'Loss' | 'Accuracy' | 'mAP' | 'Mask mAP' | 'Keypoint mAP' | 'mIoU' | 'HammingScore';
    /**
     * Display title for the metric
     */
    title?: string;
  }>;
  /**
   * Model ID of the active model
   */
  modelId: number | null;
  /**
   * Name of the experiment if model is from model playground
   */
  experimentName: string | null;
  /**
   * Name of the split if model is from model playground
   */
  splitName: string | null;
  /**
   * Maximum number of objects used for training a default model
   */
  maxObjects: number;
  /**
   * Keypoint schema ID if model family is keypoint detector
   */
  keypointSchemaId: string | null;
};

export namespace ModelFamilyStatus {

  /**
   * Model Family
   */
  export enum modelFamily {
    ATTRIBUTER = 'attributer',
    CLASSIFIER = 'classifier',
    DETECTOR = 'detector',
    IMAGE_TAGGER = 'image-tagger',
    SEGMENTOR = 'segmentor',
    SEMANTIC_SEGMENTOR = 'semantic-segmentor',
    ACTIVITY_RECOGNIZER = 'activity-recognizer',
    KEYPOINT_DETECTOR = 'keypoint-detector',
  }

  /**
   * AI Assistant status
   */
  export enum status {
    NOT_ACTIVATED = 'Not activated',
    ACTIVATED = 'Activated',
    FAILED = 'Failed',
    TRAINING = 'Training',
    DONE = 'Done',
  }

  /**
   * If label or image used as train object
   */
  export enum trainUnit {
    IMAGE = 'image',
    LABEL = 'label',
    SEGMENT = 'segment',
  }

  /**
   * What metric is used to evaluate model (Y axis on charts)
   */
  export enum targetMetric {
    LOSS = 'Loss',
    ACCURACY = 'Accuracy',
    KEYPOINT_M_AP = 'Keypoint mAP',
  }


}

