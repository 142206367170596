import { call, put, takeEvery, select } from 'typed-redux-saga';

import { apiLoadSplitPartitionObjects } from '../../../../../../api/requests/modelPlayground';
import { getErrorMessage } from '../../../../../../api/utils';
import { activeProjectIdSelector } from '../../../../project/project.selectors';
import { loadSplitPartitionsSuccess } from '../partitions/partitions.slice';
import { activeSplitIdSelector } from '../activeSplitId/activeSplitId.selectors';
import {
  splitResultsFilterSelector,
  splitResultsPaginationSelector,
} from './results.selectors';
import {
  loadSplitResultsFailure,
  loadSplitResultsSuccess,
  resetSplitResultsFilter,
  setSplitResultsFilter,
  setSplitResultsDefaultFilter,
  setSplitResultsPage,
  setSplitResultsTotal,
  setSplitResultsItemsPerPage,
} from './results.slice';

function* setDefaultFiltersHandler(
  action: ActionType<typeof loadSplitPartitionsSuccess>,
) {
  const partitions = action.payload;
  if (!partitions.length) {
    return;
  }

  yield* put(
    setSplitResultsDefaultFilter({
      partitionId: partitions[0].id,
    }),
  );
}

function* filterHandler() {
  const projectId = yield* select(activeProjectIdSelector);
  const splitId = yield* select(activeSplitIdSelector);
  const { partitionId, imageName, datasetIds, imageStatuses, labelClassIds } =
    yield* select(splitResultsFilterSelector);
  const pagination = yield* select(splitResultsPaginationSelector);

  const filters = {
    ...(datasetIds.length ? { datasetIds: datasetIds.join(',') } : {}),
    ...(imageStatuses.length ? { imageStatuses: imageStatuses.join(',') } : {}),
    ...(labelClassIds.length ? { labelClassIds: labelClassIds.join(',') } : {}),
    ...(imageName ? { imageName } : {}),
  };

  try {
    if (partitionId) {
      const { data } = yield* call(apiLoadSplitPartitionObjects, {
        projectId,
        splitId,
        partitionId,
        params: {
          limit: pagination.itemsPerPage,
          offset: pagination.itemsPerPage * (pagination.page - 1),
          ...filters,
        },
      });
      yield put(loadSplitResultsSuccess(data.items));
      yield put(setSplitResultsTotal(data.meta.total));
    }
  } catch (e) {
    yield* put(
      loadSplitResultsFailure(
        getErrorMessage(e, 'Could not load split results'),
      ),
    );
  }
}

export function* resultsSaga() {
  yield* takeEvery(loadSplitPartitionsSuccess, setDefaultFiltersHandler);
  yield* takeEvery(
    [
      setSplitResultsDefaultFilter,
      setSplitResultsFilter,
      resetSplitResultsFilter,
      setSplitResultsPage,
      setSplitResultsItemsPerPage,
    ],
    filterHandler,
  );
}
