import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { WidgetBestPerforming } from '../../../../../../../api/domainModels/widgets';
import { Experiment } from '../../../../../../../api/domainModels/modelPlayground';
import { loadingStateBuilder } from '../../../../../../utils/loadingState';
import { setEditedProjectId } from '../../../../../sections/editedProject/project/project.slice';

export const adapter = createEntityAdapter<WidgetBestPerforming>({
  selectId: (item) => item.experimentId,
});

export enum BestPerformingType {
  Speed = 'fps',
  Metric = 'number',
}

const initialState = adapter.getInitialState({
  loadingState: loadingStateBuilder.initial(),
});

const { actions, reducer: bestPerformingReducer } = createSlice({
  name: 'widgets/bestPerforming',
  initialState,
  reducers: {
    loadBestPerforming(state, _action: PayloadAction<Experiment['id'][]>) {
      state.loadingState = loadingStateBuilder.inProgress();
    },
    loadBestPerformingFailure(state, action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.failure(action.payload);
      adapter.setAll(state, []);
    },
    loadBestPerformingSuccess(
      state,
      action: PayloadAction<WidgetBestPerforming[]>,
    ) {
      state.loadingState = loadingStateBuilder.success();

      adapter.setAll(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setEditedProjectId, () => initialState);
  },
});

export { bestPerformingReducer };
export const {
  loadBestPerforming,
  loadBestPerformingSuccess,
  loadBestPerformingFailure,
} = actions;
