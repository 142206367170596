import { splitsSagas } from './splits/splits.sagas';
import { experimentsSagas } from './experiments/experiments.sagas';
import { activeExperimentSagas } from './activeExperiment/sagas';
import { activeSplitSagas } from './activeSplit/activeSplit.sagas';
import { dashboardSagas } from './dashboard/dashboard.sagas';

export const modelPlaygroundSagas = [
  ...splitsSagas,
  ...experimentsSagas,
  ...activeExperimentSagas,
  ...dashboardSagas,
  ...activeSplitSagas,
];
