import { createSelector } from '@reduxjs/toolkit';

import { widgetsSelector } from '../widgets.selectors';
import { itemsAdapter } from './semanticInferences.slice';

const semanticInferencesSelector = createSelector(
  widgetsSelector,
  (widgets) => widgets.semanticInferences,
);

export const semanticInferencesItemsStateSelector = createSelector(
  semanticInferencesSelector,
  ({ items }) => items,
);

const itemsSelectors = itemsAdapter.getSelectors(
  semanticInferencesItemsStateSelector,
);

export const semanticInferencesItemsSelector = itemsSelectors.selectAll;

export const semanticInferencesMetaSelector = createSelector(
  semanticInferencesSelector,
  ({ meta }) => meta,
);

export const semanticInferencesMetaTotalSelector = createSelector(
  semanticInferencesMetaSelector,
  (meta) => meta?.total || 0,
);

export const semanticInferencesLimitSelector = createSelector(
  semanticInferencesSelector,
  ({ limit }) => limit,
);

export const semanticInferencesOffsetSelector = createSelector(
  semanticInferencesSelector,
  ({ offset }) => offset,
);

export const semanticInferencesOpacitySelector = createSelector(
  semanticInferencesSelector,
  ({ opacity }) => opacity,
);
