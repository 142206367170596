import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RunLockedResults } from '../../../../../../api/domainModels/consensusScoring';
import {
  loadingStateBuilder,
  LoadingState,
} from '../../../../../utils/loadingState';

type LockedResultsState = {
  data: RunLockedResults | null;
  loadingState: LoadingState;
  unlockLoadingState: LoadingState;
};

const initialState: LockedResultsState = {
  data: null,
  loadingState: loadingStateBuilder.initial(),
  unlockLoadingState: loadingStateBuilder.initial(),
};

const { actions, reducer: lockedResultsReducer } = createSlice({
  name: 'lockedResults',
  initialState,
  reducers: {
    getRunLockedResultsState(state, _action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.inProgress();
      state.data = null;
    },
    getRunLockedResultsStateSuccess(
      state,
      action: PayloadAction<RunLockedResults>,
    ) {
      state.loadingState = loadingStateBuilder.success();
      state.data = action.payload;
    },
    getRunLockedResultsStateFailure(state, action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.failure(action.payload);
    },
    resetRunLockedResultsState(state) {
      state.data = initialState.data;
      state.loadingState = initialState.loadingState;
      state.unlockLoadingState = initialState.unlockLoadingState;
    },
    unlockRunResults(state) {
      state.unlockLoadingState = loadingStateBuilder.inProgress();
    },
    unlockRunResultsInAE(state) {
      state.unlockLoadingState = loadingStateBuilder.inProgress();
    },
    unlockRunResultsSuccess(state) {
      state.unlockLoadingState = loadingStateBuilder.success();
      state.data = null;
    },
    unlockRunResultsFailure(state, action: PayloadAction<string>) {
      state.unlockLoadingState = loadingStateBuilder.failure(action.payload);
    },
  },
});

export { lockedResultsReducer };
export const {
  getRunLockedResultsState,
  getRunLockedResultsStateSuccess,
  getRunLockedResultsStateFailure,
  resetRunLockedResultsState,
  unlockRunResults,
  unlockRunResultsInAE,
  unlockRunResultsSuccess,
  unlockRunResultsFailure,
} = actions;
