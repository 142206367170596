import { createAction, Action } from '@reduxjs/toolkit';

import { ModelStatus } from './modelStatus.constants';

export type ModelChangePayload = {
  family: string;
  projectId: string;
  status: ModelStatus;
  progress: number;
  id: number;
  modelUseIE: boolean;
};
export const createModelChangePattern =
  (family: string, changeType: string) => (action: Action) => {
    const modelChangeAction = createAction<ModelChangePayload>(changeType);

    return modelChangeAction.match(action) && action.payload.family === family;
  };
