import { put, select, takeEvery, call } from 'typed-redux-saga';

import { apiLoadTrainingParamsVisualization } from '../../../../../../../api/requests/widgets';
import { getErrorMessage } from '../../../../../../../api/utils';
import { handleError } from '../../../../../commonFeatures/errorHandler/errorHandler.actions';
import { activeProjectIdSelector } from '../../../../../project/project.selectors';
import { activeExperimentIdSelector } from '../../../activeExperiment/selectors';
import {
  loadTrainingParamsVisualization,
  loadTrainingParamsVisualizationFailure,
  loadTrainingParamsVisualizationSuccess,
} from './trainParamsVisualization.slice';

function* loadVisualizationHandler() {
  const projectId = yield* select(activeProjectIdSelector);
  const experimentId = yield* select(activeExperimentIdSelector);

  if (!projectId || !experimentId) {
    return;
  }

  try {
    const { data } = yield* call(apiLoadTrainingParamsVisualization, {
      projectId,
      experimentId,
    });

    yield* put(loadTrainingParamsVisualizationSuccess(data));
  } catch (error) {
    const message = getErrorMessage(
      error,
      'Not able to fetch train parameters visualization data',
    );

    yield* put(loadTrainingParamsVisualizationFailure(message));

    yield* put(handleError({ message, error, allowOutsideOfEditor: true }));
  }
}

export function* trainParamsVisualizationSaga() {
  yield* takeEvery(loadTrainingParamsVisualization, loadVisualizationHandler);
}
