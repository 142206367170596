import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { loadingStateBuilder } from '../../../../utils/loadingState';
import {
  LabelAttributeValue,
  MultiLabelAttributeValue,
} from '../../../../../api/domainModels/attribute';

// export const selectId = (lav: Pick<LabelAttributeValue, 'id' | 'labelId'>) =>
//   `${lav.labelId}:${lav.id}`;
export const multiLabelAttributeValuesAdapter =
  createEntityAdapter<MultiLabelAttributeValue>({
    sortComparer: (a, b) => (a.norder > b.norder ? 1 : -1),
  });

const { actions, reducer: multiLabelAttributeValuesReducer } = createSlice({
  name: 'imageView/multiLabelAttributeValues',
  initialState: multiLabelAttributeValuesAdapter.getInitialState({
    loadingState: loadingStateBuilder.success(),
    updateLoadingState: loadingStateBuilder.initial(),
  }),
  reducers: {
    loadMultiLabelAttributeValues(state) {
      multiLabelAttributeValuesAdapter.setAll(state, []);
      state.loadingState = loadingStateBuilder.inProgress(
        'Loading attribute values',
      );
    },
    loadMultiLabelAttributeValuesSuccess(
      state,
      action: PayloadAction<MultiLabelAttributeValue[]>,
    ) {
      multiLabelAttributeValuesAdapter.setAll(state, action.payload);
      state.loadingState = loadingStateBuilder.success();
    },
    loadMultiLabelAttributeValuesFailure(state, action: PayloadAction<string>) {
      multiLabelAttributeValuesAdapter.setAll(state, []);
      state.loadingState = loadingStateBuilder.failure(action.payload);
    },
    updateMultiLabelAttributeValue(
      state,
      _action: PayloadAction<{
        data: MultiLabelAttributeValue & {
          value: LabelAttributeValue['value'];
        };
        selectedLabelIds: string[];
      }>,
    ) {
      state.updateLoadingState = loadingStateBuilder.initial();
    },
    updateMultiLabelAttributeValueSuccess(
      state,
      _action: PayloadAction<LabelAttributeValue[]>,
    ) {
      state.updateLoadingState = loadingStateBuilder.success();
    },
    updateMultiLabelAttributeValueFailure(
      state,
      action: PayloadAction<string>,
    ) {
      state.updateLoadingState = loadingStateBuilder.failure(action.payload);
    },
  },
});

export const {
  loadMultiLabelAttributeValues,
  loadMultiLabelAttributeValuesFailure,
  loadMultiLabelAttributeValuesSuccess,
  updateMultiLabelAttributeValue,
  updateMultiLabelAttributeValueFailure,
  updateMultiLabelAttributeValueSuccess,
} = actions;
export { multiLabelAttributeValuesReducer };
