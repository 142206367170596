import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  ProjectCollaborator,
  ProjectCollaboratorParams,
} from '../../../../../api/domainModels/projectCollaborator';
import {
  LoadingState,
  loadingStateBuilder,
} from '../../../../utils/loadingState';
import { setProjectId } from '../../../core/imageView/project/project.slice';

export type CollaboratorState = {
  loadingState: LoadingState;
  updateLoadingState: LoadingState;
  actionLoadingState: LoadingState;
  data: ProjectCollaborator[];
};

const initialState: CollaboratorState = {
  loadingState: loadingStateBuilder.initial(),
  updateLoadingState: loadingStateBuilder.initial(),
  actionLoadingState: loadingStateBuilder.initial(),
  data: [],
};

const { actions, reducer: collaboratorsReducer } = createSlice({
  name: 'managedProject/collaborators',
  initialState,
  reducers: {
    loadProjectCollaborators: (state, _action: PayloadAction<string>) => {
      state.loadingState = loadingStateBuilder.inProgress(
        'Loading collaborators',
      );
    },
    loadProjectCollaboratorsFailure: (state, action: PayloadAction<string>) => {
      state.loadingState = loadingStateBuilder.failure(action.payload);
    },
    loadProjectCollaboratorsSuccess: (
      state,
      action: PayloadAction<ProjectCollaborator[]>,
    ) => {
      state.loadingState = loadingStateBuilder.success();
      state.data = action.payload;
    },
    addProjectCollaborator: (
      state,
      _action: PayloadAction<{
        projectId: string;
        params: ProjectCollaboratorParams;
      }>,
    ) => {
      state.actionLoadingState = loadingStateBuilder.inProgress(
        'Adding collaborator',
      );
    },
    addProjectCollaboratorSuccess: (state, _action: PayloadAction<string>) => {
      state.actionLoadingState = loadingStateBuilder.success();
    },
    addProjectCollaboratorFailure: (state, action: PayloadAction<string>) => {
      state.actionLoadingState = loadingStateBuilder.failure(action.payload);
    },
    updateProjectCollaborator: (
      state,
      _action: PayloadAction<{
        projectId: string;
        collaboratorId: string;
        params: Pick<ProjectCollaboratorParams, 'roleId'>;
      }>,
    ) => {
      state.updateLoadingState = loadingStateBuilder.inProgress(
        'Updating collaborator',
      );
    },
    updateProjectCollaboratorSuccess: (
      state,
      _action: PayloadAction<string>,
    ) => {
      state.updateLoadingState = loadingStateBuilder.success();
    },
    updateProjectCollaboratorFailure: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.updateLoadingState = loadingStateBuilder.failure(action.payload);
    },
    deleteProjectCollaborator: (
      state,
      _action: PayloadAction<{ projectId: string; collaboratorId: string }>,
    ) => {
      state.actionLoadingState = loadingStateBuilder.inProgress(
        'Deleting collaborator',
      );
    },
    deleteProjectCollaboratorSuccess: (
      state,
      _action: PayloadAction<string>,
    ) => {
      state.actionLoadingState = loadingStateBuilder.success();
    },
    deleteProjectCollaboratorFailure: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.actionLoadingState = loadingStateBuilder.failure(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setProjectId, () => initialState);
  },
});

export const {
  loadProjectCollaborators,
  loadProjectCollaboratorsFailure,
  loadProjectCollaboratorsSuccess,
  addProjectCollaborator,
  addProjectCollaboratorSuccess,
  addProjectCollaboratorFailure,
  updateProjectCollaborator,
  updateProjectCollaboratorSuccess,
  updateProjectCollaboratorFailure,
  deleteProjectCollaborator,
  deleteProjectCollaboratorSuccess,
  deleteProjectCollaboratorFailure,
} = actions;

export { collaboratorsReducer };
