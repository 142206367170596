import { createSelector } from '@reduxjs/toolkit';
import isEqual from 'lodash/isEqual';

import { SplitResultFilter, adapter } from './results.slice';
import { modelPlaygroundSelector } from '../../modelPlayground.selectors';

const resultsStateSelector = createSelector(
  modelPlaygroundSelector,
  (modelPlayground) => modelPlayground.activeSplit.results,
);

const selectors = adapter.getSelectors(
  createSelector(resultsStateSelector, (state) => state.items),
);

export const splitResultsLoadingStateSelector = createSelector(
  resultsStateSelector,
  (state) => state.loadingState,
);
export const splitResultsSelector = selectors.selectAll;
export const splitResultItemByIdSelector = selectors.selectById;

export const splitResultsPaginationSelector = createSelector(
  resultsStateSelector,
  (state) => state.pagination,
);
export const splitResultsFilterSelector = createSelector(
  resultsStateSelector,
  (state) => state.filter,
);

export const splitResultsFilterActiveCountSelector = createSelector(
  resultsStateSelector,
  (state) => {
    const { filter, defaultFilter } = state;
    const filterKeys = Object.keys(filter) as (keyof SplitResultFilter)[];

    // Count the number of filters that are not equal to the default filter
    return filterKeys.reduce((count, key) => {
      if (Array.isArray(filter[key])) {
        return count + (isEqual(filter[key], defaultFilter[key]) ? 0 : 1);
      }

      return count + (filter[key] === defaultFilter[key] ? 0 : 1);
    }, 0);
  },
);
