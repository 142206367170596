import axios from 'axios';
import qs from 'query-string';

import {
  ApiSignInParams,
  ApiSignInResponse,
  ApiSendEmailLinkParams,
  ApiSignInWithEmailTokenParams,
  ApiSignInWithEmailTokenResponse,
  ApiUpdateProfileParams,
  ApiUpdateProfileResponse,
  ApiProfileResponse,
  ApiLookupsResponse,
} from '../schemas/auth';
import { API_URL, NEW_API_URL } from '../../constants/paths';
import { apiWrapper } from '../utils';
import { headers, transformRequest, transformResponse } from '../helpers';

export const apiSignIn = (data: ApiSignInParams) =>
  axios.request<ApiSignInResponse>({
    method: 'post',
    url: `${NEW_API_URL}/gcsignin-web`,
    data: qs.stringify(data),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    },
    transformResponse,
  });

export const apiLogOut = () =>
  apiWrapper(
    axios.request({
      method: 'delete',
      headers,
      url: `${NEW_API_URL}/auth/session`,
    }),
  );

export const apiSendEmailLink = (data: ApiSendEmailLinkParams) =>
  apiWrapper(
    axios.request({
      method: 'post',
      url: `${API_URL}/token-auth/email-request`,
      data,
    }),
  );

export const apiSignInWithEmailToken = (data: ApiSignInWithEmailTokenParams) =>
  axios.request<ApiSignInWithEmailTokenResponse>({
    method: 'post',
    url: `${API_URL}/token-auth/enter`,
    data,
    headers,
    transformRequest,
    transformResponse,
  });

export const apiUpdateProfile = (params: Partial<ApiUpdateProfileParams>) =>
  apiWrapper(
    axios.request<ApiUpdateProfileResponse>({
      method: 'put',
      url: `${API_URL}/profile`,
      data: params,
      headers,
      transformRequest,
      transformResponse,
    }),
  );

export const apiUpdateSettings = <T extends Record<string, boolean>>(
  settings: T,
) =>
  apiWrapper(
    axios({
      method: 'put',
      url: `${API_URL}/profile`,
      data: {
        settings,
      },
      headers,
      transformRequest,
      transformResponse,
    }),
  );

export const apiUpdateFeatures = (features: Record<string, boolean>) =>
  apiWrapper(
    axios({
      method: 'put',
      url: `${API_URL}/profile`,
      data: {
        features,
      },
      headers,
      transformRequest,
      transformResponse,
    }),
  );

export const apiLoadProfile = () =>
  apiWrapper(
    axios.request<ApiProfileResponse>({
      method: 'get',
      url: `${API_URL}/profile`,
      transformRequest,
      transformResponse,
    }),
  );

export const apiLoadLookups = () =>
  apiWrapper(
    axios.request<ApiLookupsResponse>({
      method: 'get',
      url: `${NEW_API_URL}/lookups`,
      transformRequest,
      transformResponse,
    }),
  );
