import { createSelector } from '@reduxjs/toolkit';

import { experimentsByIdSelector } from '../experiments/experimentsData/experimentsData.selectors';
import { modelPlaygroundSelector } from '../modelPlayground.selectors';

export const activeExperimentIdSelector = createSelector(
  modelPlaygroundSelector,
  (modelPlayground) => modelPlayground.activeExperiment.activeExperimentId.id,
);

export const activeExperimentSelector = (state: RootState) => {
  const id = activeExperimentIdSelector(state);

  if (!id) {
    return null;
  }

  return experimentsByIdSelector(state, id);
};

export const activeExperimentStatusSelector = (state: RootState) =>
  activeExperimentSelector(state)?.status;

export const isActiveExperimentEditableSelector = (state: RootState) =>
  activeExperimentStatusSelector(state) === 'NEW';
