import { call, put, takeEvery } from 'typed-redux-saga';

import {
  initImageProject,
  initVideoProject,
} from '../../sections/editedProject/project/project.slice';
import { apiLoadProjectUsers } from '../../../../api/requests/projectUsers';
import { projectUsersDataMapper } from '../../../../api/domainModels/projectUsers';
import { getErrorMessage } from '../../../../api/utils';
import {
  loadProjectUsersStart,
  loadProjectUsersSuccess,
  loadProjectUsersFailure,
} from './projectUsers.slice';
import { setProjectId } from '../../core/imageView/project/project.slice';
import { setVideoViewProjectId } from '../../core/videoView/project/videoViewProject.slice';

function* listHandler(
  action: ActionType<
    | typeof setProjectId
    | typeof setVideoViewProjectId
    | typeof initImageProject
    | typeof initVideoProject
  >,
) {
  const projectId = action.payload.id;

  yield* put(loadProjectUsersStart());

  try {
    const { data } = yield* call(apiLoadProjectUsers, {
      projectId,
    });

    const users = data.map(projectUsersDataMapper.fromBackend);

    yield* put(loadProjectUsersSuccess(users));
  } catch (e) {
    yield* put(
      loadProjectUsersFailure(
        getErrorMessage(e, 'Not able to load project users'),
      ),
    );
  }
}

export function* projectUsersSaga() {
  yield* takeEvery(
    [setProjectId, setVideoViewProjectId, initImageProject, initVideoProject],
    listHandler,
  );
}
