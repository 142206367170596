import {
  ApiProjectCollaborator,
  ApiProjectCollaboratorParams,
} from '../schemas/projectCollaborator';

const fromBackend = ({
  collaboratorName: name,
  collaboratorEmail: email,
  isOwner,
  ...rest
}: ApiProjectCollaborator) => ({
  ...rest,
  name,
  email,
  readOnly: !!isOwner,
});

export type ProjectCollaborator = ReturnType<typeof fromBackend>;

export const projectCollaboratorDataMapper = {
  fromBackend,
};

export type ProjectCollaboratorParams = ApiProjectCollaboratorParams;
export type GenericCollaboratorFormValues = ProjectCollaboratorParams;
