import { createSelector } from '@reduxjs/toolkit';

import { imageViewSelector } from '../imageView.selectors';

const currentImageSelector = createSelector(
  imageViewSelector,
  (imageView) => imageView.currentImage,
);

export const imageViewImageIdSelector = createSelector(
  currentImageSelector,
  (currentImage) => currentImage.imageId,
);

export const imageViewCurrentImageOffsetSelector = createSelector(
  currentImageSelector,
  (currentImage) => currentImage.offset,
);

export const imageViewCurrentImageStatusSelector = createSelector(
  currentImageSelector,
  (currentImage) => currentImage.properties?.imageStatus,
);

export const imageViewCurrentImageNameSelector = createSelector(
  currentImageSelector,
  (currentImage) => currentImage.properties?.imageName,
);

export const imageViewCurrentImageDatasetNameSelector = createSelector(
  currentImageSelector,
  (currentImage) => currentImage.properties?.datasetName,
);

export const imageViewCurrentImageDatasetIdSelector = createSelector(
  currentImageSelector,
  (currentImage) => currentImage.properties?.datasetId,
);

export const imageViewCurrentImageLoadingStateSelector = createSelector(
  currentImageSelector,
  (currentImage) => currentImage.loadingState,
);

export const imageViewCurrentImagePropertiesSelector = createSelector(
  currentImageSelector,
  (currentImage) => currentImage.properties,
);

export const imageViewCurrentImageImageObjectIdSelector = createSelector(
  currentImageSelector,
  (currentImage) => currentImage.imageObjectId,
);

export const imageViewCurrentImageLoadLimitSelector = createSelector(
  currentImageSelector,
  (currentImage) => currentImage.loadLimit,
);
