import axios from 'axios';

import { ApiLoadBillingPricesResponse } from '../schemas/billing';
import { apiWrapper } from '../utils';
import { NEW_API_URL } from '../../constants/paths';
import { headers, transformRequest, transformResponse } from '../helpers';

export const apiLoadBillingPrices = () =>
  apiWrapper(
    axios.request<ApiLoadBillingPricesResponse>({
      method: 'get',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/billing/prices`,
    }),
  );
