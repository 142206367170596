export enum WorkspacePrivilegeGroup {
  Projects = 'projects',
  ProjectSetup = 'projectSetup',
  ProjectAnnotation = 'projectAnnotation',
  ProjectAdministration = 'projectAdministration',
  ProjectSupervision = 'projectSupervision',
  Workspace = 'workspace',
  User = 'user',
}

export const WorkspacePrivileges = {
  Projects: {
    ViewAll: 'PROJECT_VIEW_ALL',
    Create: 'PROJECT_CREATE',
    Archive: 'PROJECT_ARCHIVE',
    Duplicate: 'PROJECT_DUPLICATE',
    AutomatedLabeling: 'PROJECT_FINISH_HIM',
    ErrorFinder: 'PROJECT_ERROR_FINDER',
    ModelPlayground: 'PROJECT_MODEL_PLAYGROUND',
    AnnotateAll: 'PROJECT_ANNOTATE_ALL',
    AdministrateAll: 'PROJECT_ADMINISTRATE_ALL',
    SuperviseAll: 'PROJECT_SUPERVISE_ALL',
  },
  Workspace: {
    Edit: 'WORKSPACE_EDIT',
    BillingHistory: 'WORKSPACE_BILLING_HISTORY',
    Payment: 'WORKSPACE_PAYMENT',
    Roles: 'WORKSPACE_EDIT_ROLES',
    ApiAccounts: 'WORKSPACE_API_KEY',
  },
  Users: {
    Invite: 'USER_INVITE',
    Remove: 'USER_REMOVE',
  },
};

// needs to be an array for persistent order
export const workspacePrivilegeTableStructure: {
  id: WorkspacePrivilegeGroup;
  title: string;
  simple?: boolean;
  privileges: { id: string; title: string; description: string }[];
}[] = [
  {
    id: WorkspacePrivilegeGroup.Workspace,
    title: 'Workspace management',
    privileges: [
      {
        id: WorkspacePrivileges.Workspace.Edit,
        title: 'Edit basics',
        description: 'Allows to edit the workspace',
      },
      {
        id: WorkspacePrivileges.Workspace.Roles,
        title: 'Edit roles',
        description: 'Allows to create, edit, delete and assign user roles',
      },
      {
        id: WorkspacePrivileges.Workspace.Payment,
        title: 'Edit payment and plans',
        description: 'Allows to edit payment info and change plans',
      },
      {
        id: WorkspacePrivileges.Workspace.BillingHistory,
        title: 'Access billing history',
        description: 'Allows to view the billing history',
      },
      {
        id: WorkspacePrivileges.Workspace.ApiAccounts,
        title: 'Access api accounts management',
        description: 'Allows to manage API access',
      },
    ].filter(Boolean),
  },
  {
    id: WorkspacePrivilegeGroup.User,
    title: 'User management',
    privileges: [
      {
        id: WorkspacePrivileges.Users.Invite,
        title: 'Invite users',
        description: 'Allows to invite new users to workspace',
      },
      {
        id: WorkspacePrivileges.Users.Remove,
        title: 'Remove users',
        description: 'Allows to remove users from the workspace',
      },
    ],
  },
  {
    id: WorkspacePrivilegeGroup.ProjectSetup,
    title: 'Project setup',
    privileges: [
      {
        id: WorkspacePrivileges.Projects.Create,
        title: 'Create project',
        description: 'Allows to create, edit projects',
      },
      {
        id: WorkspacePrivileges.Projects.Archive,
        title: 'Archive project',
        description: 'Allows to archive projects',
      },
      {
        id: WorkspacePrivileges.Projects.Duplicate,
        title: 'Duplicate project',
        description: 'Allows to duplicate projects',
      },
    ],
  },
  {
    id: WorkspacePrivilegeGroup.Projects,
    title: 'Can view all projects',
    privileges: [
      {
        id: WorkspacePrivileges.Projects.ViewAll,
        title: 'Can view all projects',
        description: 'Allows to view all projects',
      },
    ],
  },
  {
    id: WorkspacePrivilegeGroup.ProjectAnnotation,
    title: 'Can annotate all projects',
    privileges: [
      {
        id: WorkspacePrivileges.Projects.AnnotateAll,
        title: 'Can annotate all projects',
        description: 'Allows to annotate all projects',
      },
      {
        id: WorkspacePrivileges.Projects.AutomatedLabeling,
        title: 'Automated labeling',
        description: 'Allows to run automated labeling',
      },
      {
        id: WorkspacePrivileges.Projects.ErrorFinder,
        title: 'AI Consensus Scoring',
        description: 'Allows to run AI Consensus Scoring for all projects',
      },
      {
        id: WorkspacePrivileges.Projects.ModelPlayground,
        title: 'Model Playground',
        description: 'Allows to use Model Playground for all projects',
      },
    ],
  },
  {
    id: WorkspacePrivilegeGroup.ProjectAdministration,
    title: 'Can administrate all projects',
    privileges: [
      {
        id: WorkspacePrivileges.Projects.AdministrateAll,
        title: 'Can administrate all projects',
        description: 'Allows to administrate all projects',
      },
    ],
  },
  {
    id: WorkspacePrivilegeGroup.ProjectSupervision,
    title: 'Can supervise all projects',
    privileges: [
      {
        id: WorkspacePrivileges.Projects.SuperviseAll,
        title: 'Can supervise all projects',
        description: 'Allows to supervise all projects',
      },
    ],
  },
];

workspacePrivilegeTableStructure.forEach((group) => {
  group.simple = group.privileges.length === 1;
});
