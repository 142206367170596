import {
  alpha,
  createTheme,
  outlinedInputClasses,
  PaletteOptions,
  sliderClasses,
  tableCellClasses,
  Theme,
} from '@mui/material';

import { FONT_FAMILY } from './attrs/common';

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    paper: string;
    default: string;
    menu: string;
    body: string;
    secondary: string;
    dark: string;
    darkSpecial: string;
    lightBlue: string;
    toolbar: string;
  }
  interface TypeText {
    primary: string;
    secondary: string;
    dark: string;
  }
  interface TypeAction {
    tooltip: string;
    hover: string;
  }
  interface PaletteOptions {
    interactive?: PaletteColorOptions;
    neutral?: PaletteColorOptions;
  }

  interface Palette {
    interactive: {
      light: string;
      main: string;
      dark: string;
    };
    neutral: {
      light: string;
      main: string;
      dark: string;
    };
  }

  interface CommonColors {
    transparent: string;
    black: string;
    white: string;
    green: string;
    red: string;
    purple: string;
  }
}

declare module 'styled-components' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefaultTheme extends Theme {}
}

const palette = {
  mode: 'dark',
  primary: {
    main: '#6dacff',
    contrastText: alpha('#ffffff', 0.9),
    light: '#8ee5f4',
  },
  secondary: {
    main: '#34373e',
    contrastText: alpha('#ffffff', 0.9),
    light: alpha('#ffffff', 0.5),
  },
  interactive: {
    light: '#9a9a9a',
    main: '#1e1f28',
    dark: '#595c66',
  },
  success: {
    main: '#00daa4',
  },
  error: {
    main: '#f3325e',
  },
  warning: {
    main: '#da712b',
    light: '#fd8024',
    dark: '#ffca11',
  },
  neutral: {
    light: '#999999',
    main: '#636363',
    dark: '#0f1012',
  },
  action: {
    hover: '#34373e',
    tooltip: '#1e1f28',
  },
  text: {
    primary: alpha('#ffffff', 0.9),
    secondary: alpha('#ffffff', 0.5),
    dark: '#191a21',
  },
  background: {
    paper: '#34373e',
    default: '#28292f',
    menu: '#1e1f28',
    body: '#28292f',
    secondary: '#191a21',
    dark: '#11141b',
    darkSpecial: '#222328',
    lightBlue: '#3675b6',
    toolbar: '#2d2f37',
  },
  common: {
    transparent: '#00000000',
    black: '#000000',
    white: '#ffffff',
    green: '#bdf41f',
    red: '#994005',
    purple: '#7f76e8',
  },
} as const;

const originalTheme = createTheme();

export const theme = createTheme({
  palette: palette as PaletteOptions,
  typography: {
    fontFamily: FONT_FAMILY,
    fontSize: 13,
    button: {
      textTransform: 'none',
      fontWeight: 500,
      lineHeight: 1,
      fontSize: '1rem',
    },
    body1: {
      fontSize: '1rem',
    },
    body2: {
      fontSize: '0.92rem',
    },
    h1: {
      fontSize: 22,
      lineHeight: '26px',
      fontWeight: 'bold',
    },
    h2: {
      fontWeight: 'bold',
    },
    h3: {
      fontWeight: 'bold',
    },
    h4: {
      fontWeight: 'bold',
    },
    h5: {
      fontWeight: 'bold',
    },
    h6: {
      fontSize: 16,
      lineHeight: 1.7,
    },
    subtitle1: {
      fontSize: '1.3rem',
    },
    subtitle2: {
      fontSize: '1.1rem',
    },
  },
  shape: {
    borderRadius: 0,
  },
  components: {
    MuiButtonBase: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          borderRadius: 0,
          backgroundImage: 'none',
          overflowY: 'auto',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          borderRadius: 0,
          lineHeight: '1.7rem',
          fontSize: '1rem',
          whiteSpace: 'nowrap',
          paddingLeft: 24,
          paddingRight: 24,
        },
        sizeSmall: {
          fontWeight: 'normal',
          fontSize: '0.9rem',
          height: 32,
          letterSpacing: 0,
        },
        sizeMedium: {
          height: 40,
          letterSpacing: 0,
        },
        sizeLarge: {
          height: 48,
          letterSpacing: 0.15,
        },
        containedPrimary: {
          backgroundColor: palette.primary.main,
          color: palette.neutral.dark,
          ':hover': {
            backgroundColor: palette.primary.main,
            color: palette.neutral.dark,
            opacity: 0.8,
          },
        },
        contained: {
          color: alpha(palette.common.white, 0.9),
        },
        outlined: {
          color: palette.common.white,
          border: `1px dashed ${palette.common.white}`,
          background: palette.interactive.main,
          '&:hover': {
            border: `1px dashed ${palette.common.white}`,
            color: palette.common.white,
            background: alpha(palette.common.white, 0.1),
          },
          ':disabled': {
            background: alpha(palette.common.white, 0.1),
            border: `1px dashed ${alpha(palette.common.white, 0.1)}`,
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          // there's no colorError property in classes list to override it!
          '&.MuiRadio-colorError': {
            color: palette.error.main,
          },
          // there's no colorSuccess property in classes list to override it!
          '&.MuiRadio-colorSuccess': {
            color: palette.success.main,
          },
          // there's no colorWarning property in classes list to override it!
          '&.MuiRadio-colorWarning': {
            color: palette.warning.main,
          },
        },
        colorPrimary: {
          color: palette.primary.main,
        },
        colorSecondary: {
          color: palette.secondary.main,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          // there's no colorError property in classes list to override it!
          '&.MuiCheckbox-colorError': {
            color: palette.error.main,
          },
          // there's no colorSuccess property in classes list to override it!
          '&.MuiCheckbox-colorSuccess': {
            color: palette.success.main,
          },
          // there's no colorWarning property in classes list to override it!
          '&.MuiCheckbox-colorWarning': {
            color: palette.warning.main,
          },
        },
        colorPrimary: {
          color: palette.primary.main,
        },
        colorSecondary: {
          color: palette.secondary.main,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          '&:last-child': {
            paddingBottom: 16,
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        InputProps: {
          notched: false,
        },
        InputLabelProps: {
          shrink: true,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecorationColor: 'inherit',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: alpha(palette.common.white, 0.1),
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        notched: false,
      },
      styleOverrides: {
        root: {
          backgroundColor: palette.interactive.main,
          borderRadius: 0,
          [`&.Mui-error .${outlinedInputClasses.notchedOutline}`]: {
            borderStyle: 'solid!important',
            borderWidth: '1px!important',
            borderColor: palette.error.main,
          },
        },
        notchedOutline: {
          border: 'none',
        },
        input: {
          padding: '7.5px 14px',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          transform: 'none',
          position: 'static',
          paddingBottom: 6,
          color: palette.interactive.light,
          display: 'flex',
          alignItems: 'center',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          fontSize: '1rem',
          backgroundColor: palette.interactive.main,
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
          backgroundColor: palette.background.menu,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          backgroundColor: palette.background.default,
        },
        stickyHeader: {
          [`.${tableCellClasses.head}`]: {
            backgroundColor: palette.background.default,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          border: 'none',
          fontSize: '1rem',
          padding: '0.8rem 1.2rem',
        },
        head: {
          color: alpha(palette.common.white, 0.5),
          borderBottom: `1px solid ${palette.secondary.main}`,
          fontWeight: 'normal',
          whiteSpace: 'nowrap',
        },
        // Our theme was overriding the padding prop passed to TableCell,
        // so this over-overrides it back to normal behavior.
        paddingNone: {
          padding: 'none',
        },
      },
    },
    MuiTableRow: {
      defaultProps: {
        hover: true,
      },
      styleOverrides: {
        root: {
          '&:nth-of-type(even)': {
            backgroundColor: palette.secondary.main,
            [`& > .${tableCellClasses.root}`]: {
              backgroundColor: palette.secondary.main,
            },
          },
          '&:nth-of-type(odd)': {
            backgroundColor: palette.background.default,
            [`& > .${tableCellClasses.root}`]: {
              backgroundColor: palette.background.default,
            },
          },
          '&:hover': {
            backgroundColor: `${palette.neutral.main}!important`,
            [`& > .${tableCellClasses.root}`]: {
              backgroundColor: `${palette.neutral.main}!important`,
            },
          },
        },
        head: {
          '&:hover': {
            backgroundColor: 'inherit',
          },
          '&:nth-of-type(even)': {
            backgroundColor: 'inherit',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '12px',
          padding: '16px 16px',
          color: palette.text.primary,
          backgroundColor: palette.action.tooltip,
          maxWidth: '300px',
        },
        arrow: {
          color: palette.action.tooltip,
        },
      },
    },
    MuiAccordion: {
      defaultProps: {
        disableGutters: true,
        square: true,
        TransitionProps: { timeout: 150 },
      },
      styleOverrides: {
        root: {
          backgroundColor: palette.background.default,
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          backgroundColor: palette.interactive.main,
          padding: '0 8px',
          minHeight: 36,
        },
        content: {
          margin: 0,
          alignItems: 'center',
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 8,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        filled: {
          padding: '0.6rem',
        },
        icon: {
          top: 'calc(50% - 9px)',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          [`&.${outlinedInputClasses.root}`]: {
            padding: '0 7px',
          },
          [`&.${outlinedInputClasses.root} input`]: {
            paddingRight: '16px',
            paddingTop: '5.5px',
            display: 'flex',
            flex: 1,
          },
        },
        endAdornment: {
          top: 'calc(50% - 12px)',
        },
        paper: {
          boxShadow: originalTheme.shadows[3],
        },
        tag: {
          marginLeft: 0,
        },
      },
    },
    MuiMenu: {
      defaultProps: {
        PaperProps: {
          elevation: 3,
        },
      },
      styleOverrides: {
        paper: {
          boxShadow: originalTheme.shadows[3],
          overflow: 'visible',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        container: {
          height: 'unset',
          marginTop: '4rem',
          marginBottom: '4rem',
        },
        paper: {
          overflowY: 'visible',
          backgroundColor: palette.background.body,
          paddingLeft: 48,
          paddingRight: 48,
          paddingTop: 64,
          paddingBottom: 48,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '1.7rem',
          marginLeft: '1.5rem',
          marginRight: '1.5rem',
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          margin: '0 1.5rem 1.5rem 1.5rem',
          padding: 0,
          overflow: 'visible',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          margin: '1.5rem',
          padding: 0,
          justifyContent: 'space-between',
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          overflow: 'auto',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          ':hover': {
            color: 'inherit',
            backgroundColor: palette.background.default,
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          backgroundColor: palette.background.default,
          paddingTop: 13,
          paddingBottom: 13,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        message: {
          fontSize: '1rem',
        },
        standardInfo: {
          color: palette.common.white,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          color: palette.common.white,
          height: 6,
          borderRadius: 1.5,
          width: 150,
        },
        rail: {
          backgroundColor: palette.background.paper,
          opacity: 1,
        },
        track: {
          border: 0,
        },
        thumb: {
          '&:hover': {
            backgroundColor: palette.primary.main,
            boxShadow: 'none',
          },
          '&:focus, &.Mui-active, &.Mui-focusVisible': {
            backgroundColor: palette.primary.main,
            boxShadow: `0 0 5px ${palette.primary.main}`,
          },
        },
        sizeSmall: {
          width: 120,
          [`.${sliderClasses.thumb}`]: {
            width: '14px',
            height: '14px',
          },
          [`.${sliderClasses.rail}`]: {
            height: 4,
          },
          [`.${sliderClasses.track}`]: {
            height: 4,
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: '12px',
          fontWeight: 400,
          color: alpha(palette.common.white, 0.5),
          '&:hover': {
            color: palette.primary.main,
          },
          '&.Mui-disabled': {
            color: alpha(palette.common.white, 0.1),
          },
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          paddingTop: 6,
          paddingBottom: 6,
          lineHeight: 1.5,
          fontSize: '1rem',
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          margin: '0 4px',
          color: palette.secondary.light,
          '&.Mui-selected': {
            color: palette.background.dark,
            backgroundColor: palette.primary.main,
            '&:hover': {
              color: palette.background.dark,
              backgroundColor: palette.primary.main,
              border: 'none',
            },
          },
          '&:hover': {
            border: `1px solid ${palette.secondary.light}`,
            backgroundColor: 'transparent',
          },
        },
        ellipsis: {
          pointerEvents: 'none',
        },
      },
    },
  },
});
