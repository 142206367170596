import { select, put, takeEvery, all, call } from 'typed-redux-saga';

import {
  apiUploadImportFile,
  apiGetImportUploadsUrls,
  apiUploadFileToCloud,
} from '../../../../../../api/requests/imports';
import { getErrorMessage } from '../../../../../../api/utils';
import { pluralize } from '../../../../../../helpers/plural';
import { readFileAsBinary } from '../../../../../../util/file';
import { activeProjectIdSelector } from '../../../../project/project.selectors';
import { loadingStateBuilder } from '../../../../../utils/loadingState';
import { importUploadsSuccessCountSelector } from './importUploads.selectors';
import {
  uploadImportFilesStart,
  uploadImportFileStart,
  uploadImportFileSuccess,
  uploadImportFileFailure,
} from './importUploads.slice';
import { loadImportedFilesStart } from '../files/importFiles.slice';
import { handleError } from '../../../../commonFeatures/errorHandler/errorHandler.actions';
import { activeImportSessionIdSelector } from '../session/importSession.selectors';
import {
  ProcessFileParams,
  splitUploadsToChunks,
} from '../../../../../utils/api';
import { FILES_UPLOAD_CHUNK } from '../../../../../../constants/project';
import { importSessionByIdSelector } from '../sessions/importSessions.selectors';
import { updateImportSession } from '../sessions/importSessions.slice';
import { enqueueNotification } from '../../../../ui/stackNotifications/stackNotifications.slice';

type UploadDataParams = {
  id: string;
  url: string;
};

type UploadFileParams = {
  projectId: string;
  sessionId: string;
  file: any;
  uploadData: UploadDataParams;
};

function* uploadFile(params: UploadFileParams) {
  const { projectId, sessionId, file, uploadData } = params;

  try {
    const fileBinary: unknown = yield* call(readFileAsBinary, file);

    yield* call(apiUploadFileToCloud, {
      fileBinary,
      uploadUrl: uploadData.url,
    });
    yield* call(apiUploadImportFile, {
      projectId,
      sessionId,
      uploadId: uploadData.id,
      filename: file.name,
    });
    yield* put(
      uploadImportFileSuccess({
        fileName: file.name,
        loadingState: loadingStateBuilder.success(),
      }),
    );

    return true;
  } catch (error) {
    const errorMessage = getErrorMessage(
      error,
      `Not able to upload file ${file.name}`,
    );

    yield* put(
      uploadImportFileFailure({
        errorMessage,
        fileName: file.name,
        loadingState: loadingStateBuilder.failure(errorMessage),
      }),
    );

    yield* put(
      handleError({ message: errorMessage, error, allowOutsideOfEditor: true }),
    );
  }
}

function* processFileChunk(params: ProcessFileParams) {
  const { projectId, sessionId, files } = params;

  try {
    const {
      data: { items: uploadData },
    } = yield* call(apiGetImportUploadsUrls, {
      projectId,
      filesCount: files.length,
    });

    yield* all(
      files.map((file, index) =>
        uploadFile({
          projectId,
          sessionId,
          file,
          uploadData: uploadData[index],
        }),
      ),
    );

    const countSuccess = yield* select(importUploadsSuccessCountSelector);

    if (countSuccess) {
      yield* put(
        enqueueNotification({
          message: `${pluralize(countSuccess, 'file')} successfully uploaded`,
          options: {
            variant: 'success',
            allowOutsideOfEditor: true,
          },
        }),
      );
    }

    yield* put(
      loadImportedFilesStart({ projectId, sessionId, initialFetch: false }),
    );

    return true;
  } catch {
    return false;
  }
}

function* uploadsHandler(action: ActionType<typeof uploadImportFilesStart>) {
  const projectId = yield* select(activeProjectIdSelector);
  const sessionId = yield* select(activeImportSessionIdSelector);
  const { files } = action.payload;

  if (!sessionId) return;

  const session = yield* select((state: RootState) =>
    importSessionByIdSelector(state, sessionId),
  );

  if (session && session.parseJobId) {
    // parseJobId from BE is renewed on every parse session (which need to be triggered after any upload)
    // so we reset it here (on fresh upload) for better tracking of upload/parse progress
    yield* put(updateImportSession({ ...session, parseJobId: '' }));
  }

  yield* all(
    files.map((file) =>
      put(
        uploadImportFileStart({
          fileName: file.name,
          loadingState: loadingStateBuilder.inProgress(),
        }),
      ),
    ),
  );

  yield* splitUploadsToChunks({
    projectId,
    sessionId,
    files,
    chunkLength: FILES_UPLOAD_CHUNK,
    processFileChunk,
  });
}

export function* importUploadSaga() {
  yield* takeEvery(uploadImportFilesStart, uploadsHandler);
}
