import { createSelector } from '@reduxjs/toolkit';

import { errorFinderSelector } from '../errorFinder.selectors';
import { adapter } from './semanticReview.slice';
import { labelClassesMapSelector } from '../../../project/annotationTaxonomy/labelClasses/labelClasses.selectors';
import { EnrichedErrorSemanticLabel } from '../../../../../api/domainModels/consensusScoring';

export const semanticReviewStateSelector = createSelector(
  errorFinderSelector,
  (errorFinder) => errorFinder.semanticReview,
);

export const semanticReviewDataStateSelector = createSelector(
  semanticReviewStateSelector,
  (semanticReviewState) => semanticReviewState.data,
);

const selectors = adapter.getSelectors(semanticReviewDataStateSelector);

export const semanticReviewResultsSelector = selectors.selectAll;
export const semanticReviewResultIdsSelector = selectors.selectIds as (
  state: RootState,
) => string[];
export const semanticReviewResultByIdSelector = selectors.selectById;
export const semanticReviewResultsTotalSelector = selectors.selectTotal;

export const semanticReviewMaxNumberOfErrorsSelector = createSelector(
  semanticReviewStateSelector,
  (semanticReview) => semanticReview.maxNumberOfErrors,
);

export const semanticReviewPredictedClassIdSelector = createSelector(
  semanticReviewStateSelector,
  (semanticReview) => semanticReview.predictedClassId,
);

export const semanticLabelsForDisplaySelector = createSelector(
  [semanticReviewResultsSelector, labelClassesMapSelector],
  (labels, labelClassesMap) =>
    labels.map((label) => {
      const { predClassId } = label;

      return {
        ...label,
        labelClass: predClassId ? labelClassesMap[predClassId] : null,
      } as EnrichedErrorSemanticLabel;
    }),
);

export const semanticLabelsUnresolvedTotalSelector = createSelector(
  semanticReviewResultsSelector,
  (labels) => labels.filter((label) => label.efAction === null).length,
);

export const semanticLabelsResolvedTotalSelector = createSelector(
  [semanticReviewResultsSelector, semanticLabelsUnresolvedTotalSelector],
  (allLabels, unresolvedLabelsLength) =>
    allLabels.length - unresolvedLabelsLength,
);
