import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { WidgetMultipleGpuConsumption } from '../../../../../../../api/domainModels/widgets';
import { Experiment } from '../../../../../../../api/domainModels/modelPlayground';
import { loadingStateBuilder } from '../../../../../../utils/loadingState';
import { setEditedProjectId } from '../../../../../sections/editedProject/project/project.slice';

export const adapter = createEntityAdapter<WidgetMultipleGpuConsumption>({
  selectId: (item) => item.experimentId,
  sortComparer: (a, b) => (dayjs(a.createdAt).isAfter(b.createdAt) ? -1 : 1),
});
const initialState = adapter.getInitialState({
  loadingState: loadingStateBuilder.initial(),
});

const { actions, reducer: multipleGpuConsumptionReducer } = createSlice({
  name: 'widgets/multipleGpuConsumption',
  initialState,
  reducers: {
    loadMultipleGpuConsumption(
      state,
      _action: PayloadAction<Experiment['id'][]>,
    ) {
      state.loadingState = loadingStateBuilder.inProgress();
    },
    loadMultipleGpuConsumptionFailure(state, action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.failure(action.payload);
      adapter.setAll(state, []);
    },
    loadMultipleGpuConsumptionSuccess(
      state,
      action: PayloadAction<WidgetMultipleGpuConsumption[]>,
    ) {
      state.loadingState = loadingStateBuilder.success();
      adapter.setAll(state, action.payload);
    },
    skipLoadingMultipleGpuConsumption(state) {
      state.loadingState = loadingStateBuilder.success();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setEditedProjectId, () => initialState);
  },
});

export { multipleGpuConsumptionReducer };
export const {
  loadMultipleGpuConsumption,
  loadMultipleGpuConsumptionSuccess,
  loadMultipleGpuConsumptionFailure,
  skipLoadingMultipleGpuConsumption,
} = actions;
