import { createSelector } from '@reduxjs/toolkit';

import { imageViewSelector } from '../imageView.selectors';

export const activeToolIdSelector = createSelector(
  imageViewSelector,
  (imageView) => imageView.tools.activeTool.toolId,
);

export const areGuidesVisibleSelector = createSelector(
  imageViewSelector,
  (imageView) => imageView.tools.activeTool.areGuidesVisible,
);

export const isTrackpadPanningEnabledSelector = createSelector(
  imageViewSelector,
  (imageView) => imageView.tools.activeTool.isTrackpadPanningEnabled,
);

export const zoomSpeedSelector = createSelector(
  imageViewSelector,
  (imageView) => imageView.tools.activeTool.zoomSpeed,
);
