import {
  createSlice,
  PayloadAction,
  createEntityAdapter,
  Update,
} from '@reduxjs/toolkit';

import { loadingStateBuilder } from '../../../../utils/loadingState';
import {
  ExternalBucket,
  ExternalBucketFormValues,
} from '../../../../../api/domainModels/externalBuckets';
import { resetManagedWorkspaceId } from '../../../commonFeatures/workspaceId/workspaceId.slice';

export type PreviewExternalBucketContentFormValues = {
  bucketId: string;
  prefix?: string;
};

export type UploadFromExternalBucketFormValues =
  PreviewExternalBucketContentFormValues & {
    copy: boolean;
  };

export const adapter = createEntityAdapter<ExternalBucket>({
  selectId: ({ credentialId }) => credentialId,
});

const initialState = {
  credentials: adapter.getInitialState({
    listLoadingState: loadingStateBuilder.initial(),
    itemLoadingState: loadingStateBuilder.initial(),
  }),
  upload: {
    previewFiles: [] as string[],
    previewLoadingState: loadingStateBuilder.initial(),
    uploadLoadingState: loadingStateBuilder.initial(),
  },
};
const { actions, reducer: externalBucketsReducer } = createSlice({
  name: 'managedWorkspace/externalBuckets',
  initialState,
  reducers: {
    loadAllExternalBucketsCredentials(
      state,
      _action: PayloadAction<{ workspaceId: string }>,
    ) {
      state.credentials.listLoadingState = loadingStateBuilder.inProgress();
    },
    loadExternalBucketsCredentialsSuccess(
      state,
      action: PayloadAction<ExternalBucket[]>,
    ) {
      state.credentials.listLoadingState = loadingStateBuilder.success();
      adapter.setAll(state.credentials, action.payload);
    },
    loadExternalBucketsCredentialsFailure(
      state,
      action: PayloadAction<string>,
    ) {
      state.credentials.listLoadingState = loadingStateBuilder.failure(
        action.payload,
      );
    },
    deleteExternalBucketCredentials(
      state,
      _action: PayloadAction<{ workspaceId: string; credentialId: string }>,
    ) {
      state.credentials.itemLoadingState = loadingStateBuilder.inProgress();
    },
    deleteExternalBucketCredentialSuccess(
      state,
      action: PayloadAction<string>,
    ) {
      state.credentials.itemLoadingState = loadingStateBuilder.success();
      adapter.removeOne(state.credentials, action.payload);
    },
    deleteExternalBucketCredentialFailure(
      state,
      action: PayloadAction<string>,
    ) {
      state.credentials.itemLoadingState = loadingStateBuilder.failure(
        action.payload,
      );
    },
    addExternalBucketCredentials(
      state,
      _action: PayloadAction<
        ExternalBucketFormValues & { workspaceId: string }
      >,
    ) {
      state.credentials.itemLoadingState = loadingStateBuilder.inProgress();
    },
    addExternalBucketCredentialsSuccess(
      state,
      action: PayloadAction<ExternalBucket>,
    ) {
      state.credentials.itemLoadingState = loadingStateBuilder.success();
      adapter.addOne(state.credentials, action.payload);
    },
    addExternalBucketCredentialsFailure(state, action: PayloadAction<string>) {
      state.credentials.itemLoadingState = loadingStateBuilder.failure(
        action.payload,
      );
    },
    updateExternalBucketCredentials(
      state,
      _action: PayloadAction<
        ExternalBucketFormValues & { workspaceId: string; credentialId: string }
      >,
    ) {
      state.credentials.itemLoadingState = loadingStateBuilder.inProgress();
    },
    updateExternalBucketCredentialsSuccess(
      state,
      action: PayloadAction<Update<ExternalBucket>>,
    ) {
      state.credentials.itemLoadingState = loadingStateBuilder.success();
      adapter.updateOne(state.credentials, action.payload);
    },
    updateExternalBucketCredentialsFailure(
      state,
      action: PayloadAction<string>,
    ) {
      state.credentials.itemLoadingState = loadingStateBuilder.failure(
        action.payload,
      );
    },
    resetExternalBucketState(state) {
      state.credentials.itemLoadingState = loadingStateBuilder.initial();
      state.upload.previewLoadingState = loadingStateBuilder.initial();
      state.upload.uploadLoadingState = loadingStateBuilder.initial();
      state.upload.previewFiles = [];
    },
    previewExternalBucketContent(
      state,
      _action: PayloadAction<
        PreviewExternalBucketContentFormValues & { workspaceId: string }
      >,
    ) {
      state.upload.previewLoadingState = loadingStateBuilder.inProgress();
    },
    previewExternalBucketContentSuccess(
      state,
      action: PayloadAction<{ files: string[] }>,
    ) {
      state.upload.previewLoadingState = loadingStateBuilder.success();
      state.upload.previewFiles = action.payload.files;
    },
    previewExternalBucketContentFailure(state, action: PayloadAction<string>) {
      state.upload.previewFiles = [];
      state.upload.previewLoadingState = loadingStateBuilder.failure(
        action.payload,
      );
    },
    uploadFromExternalBucket(
      state,
      _action: PayloadAction<
        UploadFromExternalBucketFormValues & {
          workspaceId: string;
          datasetId: string;
        }
      >,
    ) {
      state.upload.uploadLoadingState = loadingStateBuilder.inProgress();
    },
    uploadFromExternalBucketSuccess(state) {
      state.upload.previewFiles = [];
      state.upload.previewLoadingState = loadingStateBuilder.initial();
      state.upload.uploadLoadingState = loadingStateBuilder.success();
    },
    uploadFromExternalBucketFailure(state, action: PayloadAction<string>) {
      state.upload.previewFiles = [];
      state.upload.previewLoadingState = loadingStateBuilder.initial();
      state.upload.uploadLoadingState = loadingStateBuilder.failure(
        action.payload,
      );
    },
    resetPreviewState(state) {
      state.upload.previewFiles = [];
      state.upload.previewLoadingState = loadingStateBuilder.initial();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetManagedWorkspaceId, () => initialState);
  },
});

export const {
  loadAllExternalBucketsCredentials,
  loadExternalBucketsCredentialsSuccess,
  loadExternalBucketsCredentialsFailure,
  deleteExternalBucketCredentials,
  deleteExternalBucketCredentialSuccess,
  deleteExternalBucketCredentialFailure,
  addExternalBucketCredentials,
  addExternalBucketCredentialsSuccess,
  addExternalBucketCredentialsFailure,
  updateExternalBucketCredentials,
  updateExternalBucketCredentialsSuccess,
  updateExternalBucketCredentialsFailure,
  previewExternalBucketContent,
  previewExternalBucketContentSuccess,
  previewExternalBucketContentFailure,
  uploadFromExternalBucket,
  uploadFromExternalBucketSuccess,
  uploadFromExternalBucketFailure,
  resetExternalBucketState,
  resetPreviewState,
} = actions;

export { externalBucketsReducer };
