import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
  Update,
} from '@reduxjs/toolkit';

import { ImportFile } from '../../../../../../api/domainModels/imports';
import { loadingStateBuilder } from '../../../../../utils/loadingState';
import { setProjectId } from '../../../../core/imageView/project/project.slice';

export const adapter = createEntityAdapter<ImportFile>();
const initialState = adapter.getInitialState({
  listLoadingState: loadingStateBuilder.initial(),
  itemLoadingState: loadingStateBuilder.initial(),
});

const { actions, reducer: importFilesReducer } = createSlice({
  name: 'importFiles',
  initialState,
  reducers: {
    loadImportedFilesStart(
      state,
      action: PayloadAction<{
        projectId?: string;
        sessionId?: string;
        initialFetch?: boolean;
      }>,
    ) {
      const { initialFetch = false } = action.payload;
      if (initialFetch) {
        state.listLoadingState = loadingStateBuilder.inProgress();
      }
    },
    loadImportedFilesSuccess(state, action: PayloadAction<ImportFile[]>) {
      state.listLoadingState = loadingStateBuilder.success();
      adapter.setAll(state, action.payload);
    },
    loadImportedFilesFailure(state, action: PayloadAction<string>) {
      state.listLoadingState = loadingStateBuilder.failure(action.payload);
    },
    parseImportedFilesStart() {},
    parseImportedFilesFailure(state, action: PayloadAction<string>) {
      state.listLoadingState = loadingStateBuilder.failure(action.payload);
    },
    removeImportedFileStart(state, _action: PayloadAction<{ fileId: string }>) {
      state.itemLoadingState = loadingStateBuilder.inProgress();
    },
    removeImportedFileSuccess(state, action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.success();
      adapter.removeOne(state, action.payload);
    },
    removeImportedFileFailure(state, action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.failure(action.payload);
    },
    resetImportFiles(state) {
      state.listLoadingState = loadingStateBuilder.initial();
      adapter.removeAll(state);
    },
    updateImportFileStatusFromWebsocket(
      _,
      _action: PayloadAction<ImportFile & { projectId: string }>,
    ) {},
    updateImportedFile(state, action: PayloadAction<ImportFile>) {
      adapter.upsertOne(state, action.payload);
    },
    updateImportedFiles(state, action: PayloadAction<Update<ImportFile>[]>) {
      adapter.updateMany(state, action.payload);
    },
    skipLoadingImportedFiles(state) {
      state.listLoadingState = loadingStateBuilder.success();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setProjectId, () => initialState);
  },
});

export { importFilesReducer };
export const {
  loadImportedFilesStart,
  loadImportedFilesSuccess,
  loadImportedFilesFailure,
  parseImportedFilesStart,
  parseImportedFilesFailure,
  removeImportedFileStart,
  removeImportedFileSuccess,
  removeImportedFileFailure,
  resetImportFiles,
  updateImportFileStatusFromWebsocket,
  updateImportedFile,
  updateImportedFiles,
  skipLoadingImportedFiles,
} = actions;
