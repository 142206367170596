import axios from 'axios';

import {
  ApiVideoActivity,
  ApiCreateActivityParams,
  ApiUpdateActivityParams,
  ApiDeleteActivityParams,
  ApiLoadActivitiesResponse,
  ApiDeleteActivityResponse,
  ApiLoadActivitiesParams,
} from '../schemas/activity';
import { NEW_API_URL } from '../../constants/paths';
import { ErrorParams, recoilApiWrapper } from '../utils';
import { headers, transformRequest, transformResponse } from '../helpers';

export const apiCreateActivity = (
  { params, data }: ApiCreateActivityParams,
  errorParams: ErrorParams,
) =>
  recoilApiWrapper(
    axios.request<ApiVideoActivity>({
      method: 'post',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/activity_types`,
      data,
    }),
    errorParams,
  );

export const apiUpdateActivity = (
  { params, data }: ApiUpdateActivityParams,
  errorParams: ErrorParams,
) =>
  recoilApiWrapper(
    axios.request<ApiVideoActivity>({
      method: 'put',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/activity_types/${params.id}`,
      data,
    }),
    errorParams,
  );

export const apiDeleteActivity = (
  { params }: ApiDeleteActivityParams,
  errorParams: ErrorParams,
) =>
  recoilApiWrapper(
    axios.request<ApiDeleteActivityResponse>({
      method: 'delete',
      headers,
      url: `${NEW_API_URL}/projects/${params.projectId}/activity_types/${params.id}`,
    }),
    errorParams,
  );

export const apiLoadActivities = (
  { params }: ApiLoadActivitiesParams,
  errorParams: ErrorParams,
) =>
  recoilApiWrapper(
    axios.request<ApiLoadActivitiesResponse>({
      method: 'get',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/activity_types`,
      params: params.pagination,
    }),
    errorParams,
  );
