/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * User feedback filter
 */
export enum FeedbackQuery {
  GOOD = 'GOOD',
  BAD = 'BAD',
}
