/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { KeypointClass } from './KeypointClass';
import type { KeypointSkeleton } from './KeypointSkeleton';

export type KeypointSchema1 = {
  /**
   * Keypoint schema ID
   */
  readonly id?: string;
  /**
   * Name of the keypoint schema
   */
  name?: string;
  /**
   * Project ID
   */
  readonly projectId?: string;
  /**
   * Description of the keypoint schema
   */
  description?: string | null;
  /**
   * Keypoint schema type
   */
  type?: KeypointSchema1.type;
  /**
   * User who created the keypoint schema
   */
  readonly createdBy?: string | null;
  /**
   * Time that keypoint schema was created
   */
  readonly createDate?: string;
  /**
   * User who updated the keypoint schema
   */
  readonly updatedBy?: string | null;
  /**
   * Time that keypoint schema was last edited
   */
  readonly updateDate?: string | null;
  /**
   * Sorting order
   */
  norder?: number | null;
  /**
   * List of keypoint classes for this keypoint schema
   */
  keypointClasses?: Array<KeypointClass> | null;
  /**
   * List of connections between keypoint classes for this keypoint schema
   */
  keypointSkeleton?: KeypointSkeleton | null;
  /**
   * Keypoint schema template ID
   */
  templateId?: string | null;
};

export namespace KeypointSchema1 {

  /**
   * Keypoint schema type
   */
  export enum type {
    POSE = 'pose',
    POINTS = 'points',
  }


}

