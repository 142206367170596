export enum RunMetric {
  Accuracy = 'accuracy',
  AverageLoss = 'averageLoss',
  HammingScore = 'hammingScore',
  Loss = 'loss',
  Miou = 'miou',
  MAP = 'mAP',
  MaskMAP = 'Mask mAP',
}

export enum ExplainabilityRunJobStatus {
  Initial = 'INIT',
  Running = 'RUNNING',
  Failed = 'FAILED',
  Done = 'DONE',
  Canceled = 'CANCELED',
}
