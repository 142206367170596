import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
  EntityState,
  Update,
} from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import {
  ConvertToImageSession,
  ConvertToImagesSessionFormValues,
} from '../../../../../api/domainModels/convertToImages';
import { FrameImportJobStatus } from '../../../../../api/domainModels/frameImports';
import {
  LoadingState,
  loadingStateBuilder,
} from '../../../../utils/loadingState';
import { setProjectId } from '../../../core/imageView/project/project.slice';

export const sessionAdapter = createEntityAdapter<ConvertToImageSession>({
  sortComparer: (a, b) => (dayjs(a.startedOn).isAfter(b.startedOn) ? -1 : 1),
});

type InitialState = {
  sessions: EntityState<ConvertToImageSession> & {
    listLoadingState: LoadingState;
    itemLoadingState: LoadingState;
  };
};

const initialState: InitialState = {
  sessions: sessionAdapter.getInitialState({
    listLoadingState: loadingStateBuilder.initial(),
    itemLoadingState: loadingStateBuilder.initial(),
  }),
};

const { actions, reducer: convertToImagesReducer } = createSlice({
  name: 'convertToImages',
  initialState,
  reducers: {
    loadConvertToImagesSessionsStart(state) {
      state.sessions.listLoadingState = loadingStateBuilder.inProgress();
    },
    loadConvertToImagesSessionsSuccess(
      state,
      action: PayloadAction<ConvertToImageSession[]>,
    ) {
      state.sessions.listLoadingState = loadingStateBuilder.success();
      sessionAdapter.setAll(state.sessions, action.payload);
    },
    loadConvertToImagesSessionsFailure(state, action: PayloadAction<string>) {
      state.sessions.listLoadingState = loadingStateBuilder.failure(
        action.payload,
      );
    },
    addConvertToImagesSessionStart(
      state,
      _action: PayloadAction<ConvertToImagesSessionFormValues>,
    ) {
      state.sessions.itemLoadingState = loadingStateBuilder.inProgress();
    },
    addConvertToImagesSessionSuccess(
      state,
      action: PayloadAction<ConvertToImageSession>,
    ) {
      state.sessions.itemLoadingState = loadingStateBuilder.success();
      sessionAdapter.addOne(state.sessions, action.payload);
    },
    addConvertToImagesSessionFailure(state, action: PayloadAction<string>) {
      state.sessions.itemLoadingState = loadingStateBuilder.failure(
        action.payload,
      );
    },
    updateConvertToImagesSessionFromWebsocket(
      _state,
      _action: PayloadAction<{
        id: string;
        projectId: string;
        status: FrameImportJobStatus;
        completedOn: number;
        importedImages: number;
      }>,
    ) {},
    updateConvertToImagesSession(
      state,
      action: PayloadAction<Update<ConvertToImageSession>>,
    ) {
      sessionAdapter.updateOne(state.sessions, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setProjectId, () => initialState);
  },
});

export { convertToImagesReducer };
export const {
  loadConvertToImagesSessionsStart,
  loadConvertToImagesSessionsSuccess,
  loadConvertToImagesSessionsFailure,
  addConvertToImagesSessionStart,
  addConvertToImagesSessionSuccess,
  addConvertToImagesSessionFailure,
  updateConvertToImagesSessionFromWebsocket,
  updateConvertToImagesSession,
} = actions;
