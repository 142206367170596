/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ImportSession2 = {
  /**
   * Import session ID
   */
  readonly id?: string;
  /**
   * Import session name
   */
  name?: string;
  readonly createDate?: string;
  /**
   * Import status
   */
  readonly status?: ImportSession2.status;
  /**
   * Total number of imported labels
   */
  readonly importedLabels?: number;
  /**
   * The job id for parse step
   */
  readonly parseJobId?: string | null;
  /**
   * The job id for import step
   */
  readonly importJobId?: string | null;
  /**
   * Total number of imported files
   */
  readonly importedFiles?: number;
  /**
   * Total number of imported label classes
   */
  readonly importedLabelClasses?: number;
  /**
   * Total number of imported tags
   */
  readonly importedTags?: number;
  /**
   * Total number of imported attributes
   */
  readonly importedAttributes?: number;
  /**
   * Username of the import creator
   */
  readonly username?: string | null;
  /**
   * Total number of effected images
   */
  readonly effectedImages?: number;
};

export namespace ImportSession2 {

  /**
   * Import status
   */
  export enum status {
    NEW = 'NEW',
    PARSED = 'PARSED',
    FAILED_TO_PARSE = 'FAILED_TO_PARSE',
    FAILED = 'FAILED',
    IMPORTED = 'IMPORTED',
  }


}

