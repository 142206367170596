import camelCase from 'lodash/camelCase';

import { MAX_RAM_BYTES } from '../../containers/modelPlayground/split/sections/widgetsView/constants';
import {
  ApiWidgetClassificationInferencesItem,
  ApiWidgetClassificationInferencesMeta,
  ApiWidgetExperimentMetric,
  ApiWidgetExperimentMetricItem,
  ApiWidgetExperimentMetricMetricsItem,
  ApiWidgetMetricsPerIteration,
  ApiTrainingRunMetric,
  ApiWidgetTaggerInferencesItem,
  ApiWidgetAttributerInferencesItem,
  ApiWidgetDetectorInferencesItem,
  ApiWidgetDetectorInferenceLabelItem,
  ApiWidgetSegmentorInferencesItem,
  ApiWidgetSegmentorInferenceLabelItem,
  ApiWidgetSemanticInferencesItem,
  ApiWidgetSemanticInferenceLabelItem,
  ApiWidgetTrainingParamsVisualization,
  ApiExplainabilityRun,
  ApiExplainabilityRunResult,
  ApiExplainabilityMethod,
  ApiClassificationInferencesParams,
  ApiDetectorInferencesParams,
  ApiExplainabilityRunFormValues,
  ApiSegmentorInferencesParams,
  ApiSemanticInferencesParams,
} from '../schemas/widgets';
import { RunMetric } from '../constants/widgets';

export const RunMetricArrayFieldNames = ['Start', 'End', 'Step'];

const fromBackendWidgetExperimentMetric = (
  item: ApiWidgetExperimentMetricItem,
) => {
  const metricValues = item.metrics.reduce((acc, metric) => {
    const { name, trainValue, validationValue } = metric;

    return {
      ...acc,
      [`${name}_train`]: trainValue,
      [`${name}_val`]: validationValue,
    };
  }, {} as { [key: string]: number | null });

  return { ...item, ...metricValues, metrics: metricValues };
};

const fromBackendWidgetExperimentMetaMetric = ({
  id,
  ...entry
}: ApiWidgetExperimentMetric) => ({
  id: camelCase(id),
  ...entry,
});

const fromBackendWidgetRunningTime = ({
  experimentId,
  color,
  name,
  createdAt,
  trainTime,
}: ApiWidgetExperimentMetricItem) => ({
  experimentId,
  color,
  name,
  createdAt,
  trainTime: trainTime === null ? trainTime : trainTime / 1000, // from ms to s
});

const fromBackendWidgetInferenceTime = ({
  experimentId,
  color,
  name,
  createdAt,
  inferenceFreq,
}: ApiWidgetExperimentMetricItem) => ({
  experimentId,
  color,
  name,
  createdAt,
  inferenceFreq,
});

const fromBackendWidgetMetricsPerIteration = ({
  metrics,
  ...entry
}: ApiWidgetMetricsPerIteration) => {
  const mappedMetrics: Partial<Record<RunMetric, TrainingRunMetric[]>> = {};
  metrics.forEach((metric) => {
    mappedMetrics[metric.title] = metric.chartData;
  });

  return {
    metrics: mappedMetrics,
    ...entry,
  };
};

const fromBackendWidgetBestPerforming = ({
  item,
  defaultMetricName,
}: {
  item: ApiWidgetExperimentMetricItem;
  defaultMetricName?: string;
}) => {
  const { experimentId, color, name, inferenceFreq: speed } = item;

  if (!defaultMetricName)
    return { experimentId, color, name, metric: null, speed };

  const defaultMetric = item.metrics.find(
    ({ name }) => name === defaultMetricName,
  );

  if (!defaultMetric) {
    return { experimentId, color, name, speed, metric: null };
  }

  return {
    experimentId,
    color,
    name,
    speed,
    metric: defaultMetric.validationValue,
  };
};

const fromBackendWidgetMultipleGpuConsumption = ({
  experimentId,
  color,
  name,
  createdAt,
  gpuUsage,
}: ApiWidgetExperimentMetricItem) => ({
  experimentId,
  color,
  name,
  createdAt,
  gpuUsage: gpuUsage === null ? gpuUsage : gpuUsage / MAX_RAM_BYTES,
});

export const widgetRunningTimeMapper = {
  fromBackend: fromBackendWidgetRunningTime,
};

export const widgetInferenceTimeMapper = {
  fromBackend: fromBackendWidgetInferenceTime,
};

export const widgetBestPerformingMapper = {
  fromBackend: fromBackendWidgetBestPerforming,
};

export const widgetExperimentMetricMapper = {
  fromBackend: fromBackendWidgetExperimentMetric,
};

export const widgetExperimentMetricMetaMapper = {
  fromBackend: fromBackendWidgetExperimentMetaMetric,
};

export const widgetMetricsPerIterationMapper = {
  fromBackend: fromBackendWidgetMetricsPerIteration,
};

export const widgetMultipleGpuConsumptionMapper = {
  fromBackend: fromBackendWidgetMultipleGpuConsumption,
};

export type WidgetExperimentMetric = ReturnType<
  typeof fromBackendWidgetExperimentMetaMetric
>;
export type WidgetExperimentMetricItem = ReturnType<
  typeof fromBackendWidgetExperimentMetric
>;
export type WidgetExperimentMetricMetricsItem =
  ApiWidgetExperimentMetricMetricsItem;
export type WidgetExperimentMetricMappedItem = ReturnType<
  typeof fromBackendWidgetMetricsPerIteration
>;
export type WidgetClassificationInferencesItem =
  ApiWidgetClassificationInferencesItem;
export type WidgetClassificationInferencesMeta =
  ApiWidgetClassificationInferencesMeta;
export type WidgetRunningTime = ReturnType<typeof fromBackendWidgetRunningTime>;
export type WidgetInferenceTime = ReturnType<
  typeof fromBackendWidgetInferenceTime
>;
export type WidgetMetricsPerIteration = ReturnType<
  typeof fromBackendWidgetMetricsPerIteration
>;
export type TrainingRunMetric = ApiTrainingRunMetric;
export type WidgetBestPerforming = ReturnType<
  typeof fromBackendWidgetBestPerforming
>;
export type WidgetMultipleGpuConsumption = ReturnType<
  typeof fromBackendWidgetMultipleGpuConsumption
>;

export type WidgetAttributerInferencesItem = ApiWidgetAttributerInferencesItem;

export type WidgetTaggerInferencesItem = ApiWidgetTaggerInferencesItem;

export type WidgetDetectorInferencesItem = ApiWidgetDetectorInferencesItem;
export type WidgetDetectorInferenceLabelItem =
  ApiWidgetDetectorInferenceLabelItem;

export type WidgetSegmentorInferencesItem = ApiWidgetSegmentorInferencesItem;
export type WidgetSegmentorInferenceLabelItem =
  ApiWidgetSegmentorInferenceLabelItem;

export type WidgetSemanticInferencesItem = ApiWidgetSemanticInferencesItem;
export type WidgetSemanticInferenceLabelItem =
  ApiWidgetSemanticInferenceLabelItem;

export type WidgetTrainingParamsVisualization =
  ApiWidgetTrainingParamsVisualization;

export type ExplainabilityRun = ApiExplainabilityRun;
export type ExplainabilityRunResult = ApiExplainabilityRunResult;
export type ExplainabilityMethod = ApiExplainabilityMethod;
export type ClassificationInferencesParams = ApiClassificationInferencesParams;
export type DetectorInferencesParams = ApiDetectorInferencesParams;
export type ExplainabilityRunFormValues = ApiExplainabilityRunFormValues;
export type SegmentorInferencesParams = ApiSegmentorInferencesParams;
export type SemanticInferencesParams = ApiSemanticInferencesParams;
