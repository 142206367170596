import { ProjectPrivileges } from '../redux/state/sections/editedProject/roles/privileges';
import { TriggerIds } from '../@types/editedProject/Trigger.types';

type TriggerPrivileges = Record<TriggerIds, Record<string, string>>;

export const triggerPrivileges: TriggerPrivileges = {
  [TriggerIds.UserMission]: {
    [ProjectPrivileges.Administrate.ImageUpload]: 'upload images',
    [ProjectPrivileges.Administrate.EditClasses]: 'edit taxonomy',
    [ProjectPrivileges.Annotate.Annotation]: 'annotate',
  },
  [TriggerIds.DataCleaning]: {
    [ProjectPrivileges.Review.ErrorFinder]: 'use the AI Consensus Scoring tool',
  },
  [TriggerIds.OptimizeModels]: {
    [ProjectPrivileges.Train.ModelPlayground]: 'use the Model Playground',
  },
};

export const triggersPrivilegeIds = (triggerId: TriggerIds) => {
  const privileges = triggerPrivileges[triggerId];

  return Object.keys(privileges).map((pId) => pId);
};
