import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
  Update,
} from '@reduxjs/toolkit';

import {
  TriggerData,
  TriggerStatus,
  TriggerWidget,
} from '../../../../../@types/editedProject/Trigger.types';
import { setProjectId } from '../../../core/imageView/project/project.slice';

export const adapter = createEntityAdapter<TriggerWidget>();
const initialState = adapter.getInitialState({});

const { actions, reducer: triggersReducer } = createSlice({
  name: 'triggers',
  initialState,
  reducers: {
    loadTriggerDataFromLocalStorage() {},
    loadTriggerDataFromLocalStorageSuccess(
      state,
      action: PayloadAction<TriggerWidget[]>,
    ) {
      adapter.setAll(state, action.payload);
    },
    updateTriggerStatus(
      _state,
      _action: PayloadAction<{ id: string; status: TriggerStatus }>,
    ) {},
    updateTriggerStatusSuccess(
      state,
      action: PayloadAction<Update<TriggerWidget>>,
    ) {
      adapter.updateOne(state, action.payload);
    },
    updateTriggerData(
      _state,
      _action: PayloadAction<{ id: string; data: TriggerData }>,
    ) {},
  },
  extraReducers: (builder) => {
    builder.addCase(setProjectId, () => initialState);
  },
});

export const {
  loadTriggerDataFromLocalStorage,
  loadTriggerDataFromLocalStorageSuccess,
  updateTriggerStatus,
  updateTriggerStatusSuccess,
  updateTriggerData,
} = actions;

export { triggersReducer };
