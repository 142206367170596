/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Type of error
 */
export enum EFErrorType {
  NOT_AN_ERROR = 'NOT_AN_ERROR',
  LOW_IOU = 'LOW_IOU',
  MISSING_LABEL = 'MISSING_LABEL',
  EXTRA_LABEL = 'EXTRA_LABEL',
  MISCLASSIFICATION = 'MISCLASSIFICATION',
  EXTRA_TAG = 'EXTRA_TAG',
  MISSING_TAG = 'MISSING_TAG',
  EXTRA_ATTRIBUTE = 'EXTRA_ATTRIBUTE',
  MISSING_ATTRIBUTE = 'MISSING_ATTRIBUTE',
  EXTRA_AREA = 'EXTRA_AREA',
  MISSING_AREA = 'MISSING_AREA',
}
