import { call, put, select, takeEvery, takeLatest } from 'typed-redux-saga';

import { ImageTool } from '../tools.constants';
import { setImageId } from '../../currentImage/currentImage.slice';
import { setActiveTool, trySetActiveTool } from '../tools.slice';
import {
  confirmSetUseSAM,
  getSAMResult,
  getSAMResultFailure,
  resetSAMResult,
  setBrush,
  setBrushToolWithBrush,
  setBrushToolWithEraser,
  setEraser,
  setSAMLabelData,
  setUseSAM,
} from './drawing.slice';
import {
  ActionTrigger,
  resetTrigger,
  triggerAction,
} from '../../toolbarActionTrigger/toolbarActionTrigger.slice';
import { apiGetMaskEditorPrediction } from '../../../../../../api/requests/maskEditorPrediction';
import { activeProjectIdSelector } from '../../../../project/project.selectors';
import {
  imageViewCurrentImagePropertiesSelector,
  imageViewImageIdSelector,
} from '../../currentImage/currentImage.selectors';
import { editedMaskLabelSelector } from '../../labels/selectedLabels/selectedLabels.selectors';
import { getErrorMessage } from '../../../../../../api/utils';
import { enqueueNotification } from '../../../../ui/stackNotifications/stackNotifications.slice';
import { showModal } from '../../../../ui/modals/modals.slice';
import {
  getResultFromWorkerPool,
  MASK_WORKER,
} from '../../../../../../workers/workerManager';
import { METHOD_BORDER } from '../../../../../../workers/mask/constants';
import { getMaskDataUpdate } from '../../../../../atoms/maskDataUpdate.saga';
import { setSelectedLabelsIds } from '../../labels/selectedLabels/selectedLabels.slice';
import {
  editedMaskHasChangesSelector,
  SAMLoadingStateSelector,
} from './drawing.selectors';
import { statusChecks } from '../../../../../../constants/status';
import { Bbox } from '../../../../../../@types/imageView/types';
import {
  getLocalStorageItem,
  upsertLocalStorageItem,
} from '../../../../../../helpers/localStorage';
import { HIDE_DISABLE_SAM_WARNING_STORAGE_PREFIX } from '../../../../../../helpers/localStorage/constants';

function* setBrushToolWithEraserHandler() {
  yield* put(trySetActiveTool(ImageTool.Drawing));
  yield* put(setEraser());
}

function* setBrushToolWithBrushHandler() {
  yield* put(trySetActiveTool(ImageTool.Drawing));
  yield* put(setBrush());
}

function* setImageIdHandler() {
  yield* put(triggerAction(ActionTrigger.Discard));
  yield* put(resetTrigger());
  yield* put(resetSAMResult());
}

function* setUseSAMHandler(action: ActionType<typeof setUseSAM>) {
  const { useSAM } = action.payload;
  const hasChanges = yield* select(editedMaskHasChangesSelector);

  const suppressWarning = getLocalStorageItem<boolean>(
    HIDE_DISABLE_SAM_WARNING_STORAGE_PREFIX,
    false,
  );

  if (!hasChanges || suppressWarning) {
    yield* put(confirmSetUseSAM({ useSAM }));
  } else {
    yield* put(
      showModal({
        modalName: 'confirmToggleSAM',
        modalProps: {
          useSAM,
        },
      }),
    );
  }
}

function* confirmSetUseSAMHandler(action: ActionType<typeof confirmSetUseSAM>) {
  const SAMLoadingState = yield* select(SAMLoadingStateSelector);

  const suppressWarning = getLocalStorageItem<boolean>(
    HIDE_DISABLE_SAM_WARNING_STORAGE_PREFIX,
    false,
  );

  if (action.payload.neverShow && !suppressWarning) {
    upsertLocalStorageItem(HIDE_DISABLE_SAM_WARNING_STORAGE_PREFIX, true);
  }

  if (action.payload.useSAM && statusChecks.isInitial(SAMLoadingState.status)) {
    const editedMaskHasChanges = yield* select(editedMaskHasChangesSelector);
    if (editedMaskHasChanges) {
      yield* put(triggerAction(ActionTrigger.Discard));
    }

    yield* put(getSAMResult());
  }
}

function* getSAMResultHandler() {
  const projectId = yield* select(activeProjectIdSelector);
  const imageId = yield* select(imageViewImageIdSelector);
  const imageProperties = yield* select(
    imageViewCurrentImagePropertiesSelector,
  );
  const editedLabel = yield* select(editedMaskLabelSelector);
  if (!editedLabel || !imageId || !editedLabel.bbox || !editedLabel.mask) {
    yield* put(getSAMResultFailure());

    return;
  }

  const params = {
    projectId,
    imageId,
  };
  const data = {
    bbox: editedLabel.bbox,
    mask: editedLabel.mask,
    viewport: [
      0,
      0,
      imageProperties?.width || 0,
      imageProperties?.height || 0,
    ] as Bbox,
  };

  try {
    const response = yield* call(apiGetMaskEditorPrediction, params, data);
    const SAMLabelData = { ...response.data, borderData: null };

    const { imageData } = yield* getMaskDataUpdate({
      id: editedLabel.id,
      mask: response.data.mask,
      bbox: response.data.bbox,
    });

    SAMLabelData.borderData = yield* call(
      getResultFromWorkerPool,
      MASK_WORKER,
      {
        method: METHOD_BORDER,
        imageData,
      },
    );

    yield* put(setSAMLabelData(SAMLabelData));
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to load enhanced mask');

    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          error,
        },
      }),
    );
    yield* put(getSAMResultFailure());
  } finally {
    yield* put(setActiveTool(ImageTool.Drawing));
  }
}

function* setSelectedLabelsIdsHandler() {
  yield* put(resetSAMResult());
}

export function* drawingSaga() {
  yield* takeEvery(setBrushToolWithEraser, setBrushToolWithEraserHandler);
  yield* takeEvery(setBrushToolWithBrush, setBrushToolWithBrushHandler);
  yield* takeLatest(setImageId, setImageIdHandler);
  yield* takeEvery(setUseSAM, setUseSAMHandler);
  yield* takeEvery(getSAMResult, getSAMResultHandler);
  yield* takeEvery(confirmSetUseSAM, confirmSetUseSAMHandler);
  yield* takeEvery(setSelectedLabelsIds, setSelectedLabelsIdsHandler);
}
