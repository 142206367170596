import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SplitPartition } from '../../../../../../api/domainModels/modelPlayground';
import { loadingStateBuilder } from '../../../../../utils/loadingState';
import { setActiveSplitId } from '../activeSplitId/activeSplitId.slice';
import { setEditedProjectId } from '../../../../sections/editedProject/project/project.slice';

const initialState = {
  loadingState: loadingStateBuilder.initial(),
  items: [] as SplitPartition[],
};
const { actions, reducer: partitionsReducer } = createSlice({
  name: 'mp/activeSplit/partitions',
  initialState,
  reducers: {
    loadSplitPartitionsStart(
      state,
      _action: PayloadAction<{ splitId: string }>,
    ) {
      state.loadingState = loadingStateBuilder.inProgress();
      state.items = [];
    },
    loadSplitPartitionsFailure(state, action: PayloadAction<string>) {
      state.loadingState = loadingStateBuilder.failure(action.payload);
    },
    loadSplitPartitionsSuccess(state, action: PayloadAction<SplitPartition[]>) {
      state.loadingState = loadingStateBuilder.success();
      state.items = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setActiveSplitId, () => initialState);
    builder.addCase(setEditedProjectId, () => initialState);
  },
});

export { partitionsReducer };
export const {
  loadSplitPartitionsStart,
  loadSplitPartitionsFailure,
  loadSplitPartitionsSuccess,
} = actions;
