import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
  Update,
} from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import {
  HeuristicRankingJob,
  RankingJobFormValues,
} from '../../../../api/domainModels/heuristic';
import { LoadingState, loadingStateBuilder } from '../../../utils/loadingState';
import { hideModals } from '../../ui/modals/modals.slice';

type HeuristicsRankingJobsState = {
  itemLoadingState: LoadingState;
  listLoadingState: LoadingState;
  pendingRankingJob: HeuristicRankingJob | null;
  isNewRankingJobAvailableIndicator: boolean;
  isFilterPrefilledIndicator: boolean;
};

export const adapter = createEntityAdapter<HeuristicRankingJob>({
  selectId: (session) => session.sessionId,
  sortComparer: (a, b) => (dayjs(a.startTime).isAfter(b.startTime) ? -1 : 1),
});

const initialState = adapter.getInitialState<HeuristicsRankingJobsState>({
  itemLoadingState: loadingStateBuilder.initial(),
  listLoadingState: loadingStateBuilder.initial(),
  pendingRankingJob: null,
  isNewRankingJobAvailableIndicator: false,
  isFilterPrefilledIndicator: false,
});

const { actions, reducer: heuristicsRankingJobsReducer } = createSlice({
  name: 'heuristicsRankingJobs',
  initialState,
  reducers: {
    loadHeuristicsRankingJobs(
      state,
      _action: PayloadAction<{ projectId: string }>,
    ) {
      state.listLoadingState = loadingStateBuilder.inProgress();
    },
    loadHeuristicsRankingJobsFailure(state, action: PayloadAction<string>) {
      state.listLoadingState = loadingStateBuilder.failure(action.payload);
      adapter.removeAll(state);
    },
    loadHeuristicsRankingJobsSuccess(
      state,
      action: PayloadAction<HeuristicRankingJob[]>,
    ) {
      state.listLoadingState = loadingStateBuilder.success();
      adapter.setAll(state, action.payload);
    },
    addHeuristicRankingJob(
      state,
      _action: PayloadAction<
        RankingJobFormValues & {
          projectId: string;
        }
      >,
    ) {
      state.itemLoadingState = loadingStateBuilder.inProgress();
    },
    addHeuristicRankingJobSuccess(
      state,
      action: PayloadAction<HeuristicRankingJob>,
    ) {
      state.itemLoadingState = loadingStateBuilder.success();

      adapter.addOne(state, action.payload);
    },
    addHeuristicRankingJobsFailure(state, action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.failure(action.payload);
    },
    addPendingHeuristicRankingJob(
      state,
      action: PayloadAction<HeuristicRankingJob>,
    ) {
      state.pendingRankingJob = action.payload;
    },
    applyPendingHeuristicRankingJob() {},
    resetPendingHeuristicRankingJob(state) {
      state.pendingRankingJob = null;
    },
    updateIsFilterPrefilledIndicator(state, action: PayloadAction<boolean>) {
      state.isFilterPrefilledIndicator = action.payload;
    },
    updateIsNewRankingJobAvailableIndicator(
      state,
      action: PayloadAction<boolean>,
    ) {
      state.isNewRankingJobAvailableIndicator = action.payload;
    },
    updateHeuristicRankingJobSuccess(
      state,
      action: PayloadAction<Update<HeuristicRankingJob>>,
    ) {
      adapter.updateOne(state, action.payload);
    },
    updateHeuristicRankingJobFromWebsocket(
      _,
      _action: PayloadAction<HeuristicRankingJob & { projectId: string }>,
    ) {},
    removeHeuristicRankingJob(
      state,
      _action: PayloadAction<{ projectId: string; sessionId: string }>,
    ) {
      state.itemLoadingState = loadingStateBuilder.inProgress();
    },
    removeHeuristicRankingJobSuccess(
      state,
      action: PayloadAction<{ sessionId: string }>,
    ) {
      state.itemLoadingState = loadingStateBuilder.success();

      adapter.removeOne(state, action.payload.sessionId);
    },
    removeHeuristicRankingJobFailure(state, action: PayloadAction<string>) {
      state.itemLoadingState = loadingStateBuilder.failure(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(hideModals, (state) => {
      state.itemLoadingState = loadingStateBuilder.initial();
    });
  },
});

export { heuristicsRankingJobsReducer };
export const {
  addHeuristicRankingJob,
  addHeuristicRankingJobSuccess,
  addHeuristicRankingJobsFailure,
  addPendingHeuristicRankingJob,
  applyPendingHeuristicRankingJob,
  loadHeuristicsRankingJobs,
  loadHeuristicsRankingJobsFailure,
  loadHeuristicsRankingJobsSuccess,
  removeHeuristicRankingJob,
  removeHeuristicRankingJobFailure,
  removeHeuristicRankingJobSuccess,
  resetPendingHeuristicRankingJob,
  updateHeuristicRankingJobSuccess,
  updateHeuristicRankingJobFromWebsocket,
  updateIsFilterPrefilledIndicator,
  updateIsNewRankingJobAvailableIndicator,
} = actions;
