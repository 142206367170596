import axios from 'axios';

import {
  ApiAtomPredictionParams,
  ApiAtomPredictionData,
  ApiAtomPredictionResponse,
} from '../schemas/atom';
import { apiWrapper } from '../utils';
import { API_URL } from '../../constants/paths';
import { headers, transformRequest, transformResponse } from '../helpers';

export const apiRequestAtomPrediction = (
  params: ApiAtomPredictionParams,
  data: ApiAtomPredictionData,
) =>
  apiWrapper(
    axios.request<ApiAtomPredictionResponse[]>({
      method: 'post',
      headers,
      transformRequest,
      transformResponse,
      url: `${API_URL}/project/${params.projectId}/image/${params.imageId}/atom`,
      data,
    }),
  );
