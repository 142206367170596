import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { VideoSegmentPredictionJobStatus } from '../../../../../api/constants/videos';
import { VideoPredictedSegment } from '../../../../../api/domainModels/video';

// This will be wrapper for Recoil related websocket updates
// Since Recoil dont have access to ws context itself
// We'll use same approach for redux state with triggering action
// and in relevant saga we'll update recoil state instead of redux state
// In future we can split these into separate slices per entity (e.g. Video, Segment, etc.)
const { actions, reducer: videoViewRecoilWrapperReducer } = createSlice({
  name: 'recoilWrapper',
  initialState: null,
  reducers: {
    updateVideoProcessedFromWebsocket(
      _state,
      _action: PayloadAction<{
        projectId: string;
        videoId: string;
      }>,
    ) {},
    updateVideoPredictionJobStatusFromWebsocket(
      _state,
      _action: PayloadAction<{
        projectId: string;
        videoId: string;
        status: VideoSegmentPredictionJobStatus;
      }>,
    ) {},
    updateVideoPredictionJobProgressFromWebsocket(
      _state,
      _action: PayloadAction<{
        projectId: string;
        videoId: string;
        progress: number;
      }>,
    ) {},
    updateVideoSegmentPredictionsFromWebsocket(
      _state,
      _action: PayloadAction<{
        projectId: string;
        videoId: string;
        predictions: VideoPredictedSegment[];
      }>,
    ) {},
  },
});

export { videoViewRecoilWrapperReducer };
export const {
  updateVideoProcessedFromWebsocket,
  updateVideoPredictionJobStatusFromWebsocket,
  updateVideoPredictionJobProgressFromWebsocket,
  updateVideoSegmentPredictionsFromWebsocket,
} = actions;
