export enum RunType {
  ClassReview = 'CLASS_REVIEW',
  ObjectReview = 'OBJECT_REVIEW',
  InstanceReview = 'INSTANCE_REVIEW',
  TagReview = 'TAG_REVIEW',
  AttributeReview = 'ATTRIBUTE_REVIEW',
  SemanticReview = 'SEMANTIC_REVIEW',
}

export enum ErrorFinderAction {
  NotError = 'NOT_AN_ERROR',
  ChangedClass = 'CHANGE_CLASS',
  ChangedShape = 'CHANGE_SHAPE',
  AddLabel = 'ADD_LABEL',
  DeleteLabel = 'DELETE_LABEL',
  ChangedManually = 'CHANGED_MANUALLY',
  Accept = 'ACCEPT', // for tag/attribute/semantic review
  None = 'NONE',
}
