import { call, put, select, takeEvery, take } from 'typed-redux-saga';
import { PayloadAction } from '@reduxjs/toolkit';
import { LocationChangeAction, LOCATION_CHANGE } from 'connected-react-router';

import {
  apiLoadErrorFinderRuns,
  apiLoadErrorFinderRun,
  apiCreateErrorFinderRun,
  apiDeleteErrorFinderRun,
} from '../../../../../api/requests/consensusScoring';
import { WebsocketRunPayload } from '../../../../../api/domainModels/websocketTypes';
import {
  errorFinderRunDataMapper,
  RunStatus,
} from '../../../../../api/domainModels/consensusScoring';
import { getErrorMessage } from '../../../../../api/utils';
import { PREVIEW_MODE_PERCENTAGE } from '../../../../../constants/errorFinder';
import { activeProjectIdSelector } from '../../../project/project.selectors';
import { hideModals } from '../../../ui/modals/modals.slice';
import {
  EF_RUN_TRAINING_STARTED,
  EF_RUN_DONE,
  UPDATE_WS_RUN_PROGRESS,
  EF_RUN_STARTED,
  EF_RUN_TRAINING_PROGRESS,
} from '../../../ws/ws.constants';
import { setRunsTotal } from './pagination/pagination.slice';
import { runsPaginationSelector } from './pagination/pagination.selectors';
import {
  resetListLoadingState,
  resetItemLoadingState,
  loadRuns,
  loadRunsSuccess,
  loadRunsFailure,
  loadRun,
  loadRunSuccess,
  loadRunFailure,
  createRun,
  createRunSuccess,
  createRunFailure,
  removeRun,
  removeRunFailure,
  removeRunSuccess,
  updateRunSuccess,
} from './runs.slice';
import { setActiveRunId, setActiveRunType } from '../errorFinder.slice';
import { activeRunIdSelector } from '../errorFinder.selectors';

export type RunsParams = {
  itemsPerPage?: number;
  page?: number;
};

function* getParams({ itemsPerPage, page }: RunsParams = {}) {
  const pagination = yield* select(runsPaginationSelector);
  const limit = itemsPerPage || pagination.itemsPerPage;
  const offset = (page || pagination.page) * limit - limit;

  return {
    offset,
    limit,
  };
}

function* loadAllHandler(action: ActionType<typeof loadRuns>) {
  try {
    const projectId = yield* select(activeProjectIdSelector);
    const params = yield* getParams(action.payload);

    if (!projectId) {
      yield* put(resetListLoadingState());

      return;
    }

    const { data } = yield* call(apiLoadErrorFinderRuns, {
      projectId,
      params,
    });
    const { items, meta } = data;
    yield* put(
      loadRunsSuccess(items.map(errorFinderRunDataMapper.fromBackend)),
    );
    yield* put(setRunsTotal(meta.total));
  } catch (e) {
    yield* put(
      loadRunsFailure(
        getErrorMessage(e, 'Not able to load AI consensus scoring runs'),
      ),
    );
  }
}

function* loadOneHandler(action: ActionType<typeof loadRun>) {
  try {
    const projectId = yield* select(activeProjectIdSelector);
    const { id: runId, persistRunType } = action.payload;
    const { data } = yield* call(apiLoadErrorFinderRun, {
      projectId,
      runId,
    });

    const payload = errorFinderRunDataMapper.fromBackend(data);

    if (persistRunType) {
      yield* put(setActiveRunType({ runType: payload.type }));
    }

    yield* put(loadRunSuccess(payload));
  } catch (e) {
    yield* put(
      loadRunFailure(
        getErrorMessage(e, 'Not able to load AI consensus scoring run'),
      ),
    );
  }
}

function* createHandler(action: ActionType<typeof createRun>) {
  const projectId = yield* select(activeProjectIdSelector);
  const payload = { ...action.payload };
  payload.previewPcnt = payload.previewMode ? PREVIEW_MODE_PERCENTAGE : 1;
  try {
    const { data } = yield* call(
      apiCreateErrorFinderRun,
      {
        projectId,
      },
      payload,
    );
    yield* put(createRunSuccess(errorFinderRunDataMapper.fromBackend(data)));
    yield* put(hideModals());
  } catch (error) {
    const message = getErrorMessage(
      error,
      'Not able to create an AI consensus scoring run',
    );

    yield* put(createRunFailure(message));
  }
}

function* removeHandler(action: ActionType<typeof removeRun>) {
  const projectId = yield* select(activeProjectIdSelector);
  const id = action.payload;

  try {
    yield* call(apiDeleteErrorFinderRun, {
      projectId,
      runId: id,
    });
    yield* put(removeRunSuccess(id));
    yield* put(hideModals());
  } catch (e) {
    yield* put(removeRunFailure(getErrorMessage(e, 'Not able to remove run')));
  }
}

function* websocketRunStartedHandler({
  payload: { id },
}: PayloadAction<WebsocketRunPayload & { type: string }>) {
  yield* put(
    updateRunSuccess({
      id,
      changes: { status: RunStatus.Running },
    }),
  );
}

function* websocketTrainingStartedHandler({
  payload: { id },
}: PayloadAction<WebsocketRunPayload & { type: string }>) {
  yield* put(
    updateRunSuccess({
      id,
      changes: { status: RunStatus.Running, training: true },
    }),
  );
}

function* websocketRunDoneHandler({
  id,
}: WebsocketRunPayload & { type: string }) {
  yield* put(updateRunSuccess({ id, changes: { status: RunStatus.Done } }));
  yield* put(loadRun({ id }));
}

function* hideModalsHandler() {
  yield* put(resetItemLoadingState());
}

function* updateWSRunProgressHandler(
  action: PayloadAction<{ runId: string; progress: number; eta: number }>,
) {
  yield* put(
    updateRunSuccess({
      id: action.payload.runId,
      changes: {
        progress: +(action.payload.progress * 100).toFixed(0),
        eta: action.payload.eta,
      },
    }),
  );
}

function* updateTrainingProgressHandler(
  action: PayloadAction<{ runId: string; progress: number; eta: number }>,
) {
  yield* put(
    updateRunSuccess({
      id: action.payload.runId,
      changes: {
        progress: +(action.payload.progress * 100).toFixed(0),
        training: true,
      },
    }),
  );
}

function* urlSearchParamsChangeHandler(
  action: LocationChangeAction<{ from?: string }>,
) {
  if (action.payload.location?.state?.from) {
    return;
  }

  const runId = yield* select(activeRunIdSelector);

  if (!runId) {
    yield* take(setActiveRunId.type);
  }

  yield* put(loadRun({ id: runId }));
}

export function* runsSaga() {
  yield* takeEvery(loadRuns, loadAllHandler);
  yield* takeEvery(loadRun, loadOneHandler);
  yield* takeEvery(createRun, createHandler);
  yield* takeEvery(removeRun, removeHandler);
  yield* takeEvery(hideModals, hideModalsHandler);
  yield* takeEvery(EF_RUN_STARTED, websocketRunStartedHandler);
  yield* takeEvery(EF_RUN_TRAINING_STARTED, websocketTrainingStartedHandler);
  yield* takeEvery(EF_RUN_DONE, websocketRunDoneHandler);
  yield* takeEvery(UPDATE_WS_RUN_PROGRESS, updateWSRunProgressHandler);
  yield* takeEvery(EF_RUN_TRAINING_PROGRESS, updateTrainingProgressHandler);
  yield* takeEvery(
    (action: PayloadAction<any> | LocationChangeAction) =>
      action.type === LOCATION_CHANGE &&
      action.payload.location.pathname.endsWith('/summary'),
    urlSearchParamsChangeHandler,
  );
}
