import { put, select, takeEvery, call } from 'typed-redux-saga';

import {
  apiCreateImportSession,
  apiUpdateImportSession,
  apiLoadImportSessions,
  apiDeleteImportSession,
  apiFinalizeImportSession,
} from '../../../../../../api/requests/imports';
import { getErrorMessage } from '../../../../../../api/utils';
import { handleError } from '../../../../commonFeatures/errorHandler/errorHandler.actions';
import { reloadLabelClasses } from '../../../../project/annotationTaxonomy/labelClasses/labelClasses.slice';
import { activeProjectIdSelector } from '../../../../project/project.selectors';
import { loadAll } from '../../../../../utils/api';
import { hideModals } from '../../../../ui/modals/modals.slice';
import { activeImportSessionIdSelector } from '../session/importSession.selectors';
import {
  setActiveImportSessionId,
  resetActiveImportSessionId,
} from '../session/importSession.slice';
import { importSessionByIdSelector } from './importSessions.selectors';
import {
  loadImportSessionsStart,
  loadImportSessionsSuccess,
  loadImportSessionsFailure,
  createImportSessionStart,
  createImportSessionSuccess,
  createImportSessionFailure,
  updateImportSessionStart,
  updateImportSessionSuccess,
  updateImportSessionFailure,
  removeImportSessionStart,
  removeImportSessionSuccess,
  removeImportSessionFailure,
  finalizeImportSessionStart,
  finalizeImportSessionSuccess,
  finalizeImportSessionFailure,
  updateImportSessionStatusFromWebsocket,
  updateImportSession,
} from './importSessions.slice';

function* listHandler(action: ActionType<typeof loadImportSessionsStart>) {
  const { projectId: pId } = action.payload;
  const projectId = pId || (yield* select(activeProjectIdSelector));

  if (!projectId) return;

  try {
    const sessions = yield* loadAll({
      apiHelper: apiLoadImportSessions,
      params: {
        projectId,
      },
    });

    yield* put(loadImportSessionsSuccess(sessions));
  } catch (error) {
    const errorMessage = getErrorMessage(
      error,
      'Not able to load import sessions.',
    );

    yield* put(loadImportSessionsFailure(errorMessage));
    yield* put(
      handleError({ message: errorMessage, error, allowOutsideOfEditor: true }),
    );
  }
}

function* createHandler(action: ActionType<typeof createImportSessionStart>) {
  const projectId = yield* select(activeProjectIdSelector);

  const { values, onNextStep } = action.payload;

  try {
    const { data } = yield* call(
      apiCreateImportSession,
      {
        projectId,
      },
      values,
    );

    yield* put(createImportSessionSuccess(data));
    yield* put(setActiveImportSessionId(data.id));

    onNextStep && onNextStep();
  } catch (error) {
    const errorMessage = getErrorMessage(
      error,
      'Not able to create import session.',
    );

    yield* put(createImportSessionFailure(errorMessage));
    yield* put(
      handleError({ message: errorMessage, error, allowOutsideOfEditor: true }),
    );
  }
}

function* updateHandler(action: ActionType<typeof updateImportSessionStart>) {
  const projectId = yield* select(activeProjectIdSelector);

  const { values, onNextStep } = action.payload;
  const { id: sessionId, ...rest } = values;

  try {
    const { data } = yield* call(
      apiUpdateImportSession,
      {
        projectId,
        sessionId,
      },
      rest,
    );

    yield* put(
      updateImportSessionSuccess({
        id: sessionId,
        changes: data,
      }),
    );

    onNextStep && onNextStep();
  } catch (error) {
    const errorMessage = getErrorMessage(
      error,
      'Not able to update import session.',
    );

    yield* put(updateImportSessionFailure(errorMessage));
    yield* put(
      handleError({ message: errorMessage, error, allowOutsideOfEditor: true }),
    );
  }
}

function* removeHandler(action: ActionType<typeof removeImportSessionStart>) {
  const projectId = yield* select(activeProjectIdSelector);
  const sessionId = action.payload;

  try {
    yield* call(apiDeleteImportSession, { projectId, sessionId });
    yield* put(removeImportSessionSuccess(sessionId));
    yield* put(hideModals());
  } catch (error) {
    const errorMessage = getErrorMessage(
      error,
      'Not able to delete the import session.',
    );

    yield* put(removeImportSessionFailure(errorMessage));
    yield* put(
      handleError({ message: errorMessage, error, allowOutsideOfEditor: true }),
    );
  }
}

function* finalizeHandler(
  action: ActionType<typeof finalizeImportSessionStart>,
) {
  const projectId = yield* select(activeProjectIdSelector);
  const sessionId = yield* select(activeImportSessionIdSelector);

  if (!sessionId) return;

  const values = action.payload;

  try {
    const {
      data: { id: importJobId },
    } = yield* call(
      apiFinalizeImportSession,
      {
        projectId,
        sessionId,
      },
      values,
    );

    const session = yield* select((state: RootState) =>
      importSessionByIdSelector(state, sessionId),
    );

    if (session) {
      yield* put(updateImportSession({ ...session, importJobId }));
    }
    yield* put(finalizeImportSessionSuccess());
    yield* put(hideModals());
    yield* put(resetActiveImportSessionId());
  } catch (error) {
    const errorMessage = getErrorMessage(
      error,
      'Not able to finalize import session.',
    );

    yield* put(finalizeImportSessionFailure(errorMessage));
    yield* put(
      handleError({ message: errorMessage, error, allowOutsideOfEditor: true }),
    );
  }
}

function* updateSessionStatusHandler(
  action: ActionType<typeof updateImportSessionStatusFromWebsocket>,
) {
  const { id: sessionId, status, projectId } = action.payload;

  const session = yield* select((state: RootState) =>
    importSessionByIdSelector(state, sessionId),
  );

  if (!session) return;

  yield* put(
    updateImportSession({
      ...session,
      status,
    }),
  );
  yield* put(reloadLabelClasses({ id: projectId })); // we need to reload them in case that was in imported file
}

export function* importSessionsSaga() {
  yield* takeEvery(loadImportSessionsStart, listHandler);
  yield* takeEvery(createImportSessionStart, createHandler);
  yield* takeEvery(updateImportSessionStart, updateHandler);
  yield* takeEvery(removeImportSessionStart, removeHandler);
  yield* takeEvery(finalizeImportSessionStart, finalizeHandler);
  yield* takeEvery(
    updateImportSessionStatusFromWebsocket,
    updateSessionStatusHandler,
  );
}
