import axios from 'axios';

import {
  ApiTagGroup,
  ApiLoadProjectTagGroupsParams,
  ApiLoadProjectTagGroupsResponse,
  ApiCreateProjectTagGroupParams,
  ApiUpdateProjectTagGroupParams,
  ApiDeleteProjectTagGroupParams,
} from '../schemas/tagGroup';
import { apiWrapper } from '../utils';
import { NEW_API_URL } from '../../constants/paths';
import {
  ApiDeleteResponse,
  headers,
  transformRequest,
  transformResponse,
} from '../helpers';

export const apiLoadProjectTagGroups = (
  params: ApiLoadProjectTagGroupsParams,
) =>
  apiWrapper(
    axios.request<ApiLoadProjectTagGroupsResponse>({
      method: 'get',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/tag_groups`,
      params: {
        limit: params.limit,
        offset: params.offset,
      },
    }),
  );

export const apiCreateProjectTagGroup = (
  params: ApiCreateProjectTagGroupParams,
  data: Partial<ApiTagGroup>,
) =>
  apiWrapper(
    axios.request<ApiTagGroup>({
      method: 'post',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/tag_groups`,
      data,
    }),
  );

export const apiUpdateProjectTagGroup = (
  params: ApiUpdateProjectTagGroupParams,
  data: Partial<ApiTagGroup>,
) =>
  apiWrapper(
    axios.request<ApiTagGroup>({
      method: 'put',
      headers,
      transformRequest,
      transformResponse,
      url: `${NEW_API_URL}/projects/${params.projectId}/tag_groups/${params.tagGroupId}`,
      data,
    }),
  );

export const apiDeleteProjectTagGroup = (
  params: ApiDeleteProjectTagGroupParams,
) =>
  apiWrapper(
    axios.request<ApiDeleteResponse>({
      method: 'delete',
      headers,
      url: `${NEW_API_URL}/projects/${params.projectId}/tag_groups/${params.tagGroupId}`,
    }),
  );
