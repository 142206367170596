export const getNewOrder = (items: any[], index: number) => {
  const leftItem = items[index - 1];
  const rightItem = items[index];

  let newOrder: number | null = null;

  if (leftItem && rightItem) {
    newOrder = (leftItem.norder + rightItem.norder) / 2;
  } else if (leftItem) {
    newOrder = leftItem.norder + 0.5;
  } else if (rightItem) {
    newOrder = rightItem.norder - 0.5;
  }

  return newOrder;
};
