import { Store } from '@reduxjs/toolkit';
import {
  DefaultOptions,
  QueryCache,
  QueryClient,
  QueryClientProvider,
  QueryObserverOptions,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ReactNode } from 'react';

import { API_URL_BASE } from '../constants/paths';
import { handleError } from '../redux/state/commonFeatures/errorHandler/errorHandler.actions';
import { OpenAPI } from './codegen';
import { getErrorMessage } from './utils';

let store: Store;

export const injectStoreApiProvider = (_store: Store) => {
  store = _store;
};

OpenAPI.BASE = API_URL_BASE;
OpenAPI.WITH_CREDENTIALS = true;

const DEFAULT_QUERY_OPTIONS: QueryObserverOptions = {
  cacheTime: Infinity,
  staleTime: Infinity,
  refetchOnWindowFocus: false,
  retry: false,
};

export const DEFAULT_QUERY_CLIENT_OPTIONS: DefaultOptions = {
  queries: DEFAULT_QUERY_OPTIONS,
  mutations: { retry: DEFAULT_QUERY_OPTIONS.retry },
};

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => {
      if (query.meta?.errorMessage) {
        const message = getErrorMessage(error, query.meta.errorMessage);
        const additionalProps = query.meta?.notificationProps ?? {};
        store.dispatch(handleError({ message, error, ...additionalProps }));
      }
    },
  }),
});

queryClient.setDefaultOptions(DEFAULT_QUERY_CLIENT_OPTIONS);

interface ApiProviderProps {
  children?: ReactNode;
}

export const ApiProvider = ({ children }: ApiProviderProps) => (
  <QueryClientProvider client={queryClient}>
    {children}
    {process.env.REACT_APP_REACT_QUERY_DEVTOOLS === 'true' && (
      <ReactQueryDevtools initialIsOpen={false} />
    )}
  </QueryClientProvider>
);
