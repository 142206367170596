import { projectUsersSaga } from './projectUsers/projectUsers.saga';
import { projectOperationsSaga } from './operations/operations.saga';
import { inferenceSummarySaga } from './inferenceMonitoring/summary/summary.saga';
import { inferenceItemsSaga } from './inferenceMonitoring/inferences/inferences.saga';
import { fileManagerImagesSaga } from './fileManager/images/images.saga';
import { fileManagerVideosSaga } from './fileManager/videos/videos.saga';
import { heuristicsSaga } from './heuristics/heuristics.saga';
import { heuristicsRankingJobsSaga } from './heuristicsRankingJobs/heuristicsRankingJobs.saga';
import { projectInfoSaga } from './projectInfo/projectInfo.saga';
import { annotationTaxonomySagas } from './annotationTaxonomy/annotationTaxonomy.sagas';
import { automatedLabelingSaga } from './automatedLabeling/automatedLabeling.saga';
import { jobProgressSaga } from './jobProgress/jobProgress.saga';
import { attributesSaga } from './annotationTaxonomy/attributes/attributes.saga';

export const projectSagas = [
  attributesSaga,
  fileManagerImagesSaga,
  fileManagerVideosSaga,
  heuristicsSaga,
  heuristicsRankingJobsSaga,
  inferenceItemsSaga,
  inferenceSummarySaga,
  projectOperationsSaga,
  projectUsersSaga,
  projectInfoSaga,
  automatedLabelingSaga,
  jobProgressSaga,
  ...annotationTaxonomySagas,
];
