import { push } from 'connected-react-router';
import groupBy from 'lodash/groupBy';
import { call, put, takeEvery, select } from 'typed-redux-saga';
import { setUser, configureScope } from '@sentry/react';

import {
  apiLogOut,
  apiSignIn,
  apiSendEmailLink,
  apiUpdateProfile,
  apiLoadProfile,
  apiUpdateFeatures,
  apiUpdateSettings,
  apiLoadLookups,
  apiSignInWithEmailToken,
} from '../../../../api/requests/auth';
import { Lookup } from '../../../../api/domainModels/auth';
import { getErrorMessage } from '../../../../api/utils';
import { logWebsocketMessagesFeature } from '../../../../util/features';
import { subscribeSubject, unsubscribeSubject } from '../../ws/ws.actions';
import { hideModals } from '../../ui/modals/modals.slice';
import { ACCOUNT_PREFERENCES_FIELDS } from './configs/accountPreferences';
import { tryTrack } from '../../../../analytics/analyticsWrapper';
import {
  signIn,
  signInStart,
  signInSuccess,
  signInFailure,
  signInFailureRequest,
  logout,
  sendEmailLink,
  updateProfile,
  updateUserSettings,
  updateUserFeatures,
  updateUserFeaturesSuccess,
  updateUserFeaturesFailure,
  loadProfile,
  loadProfileSuccess,
  loadProfileFailure,
  updateProfileSuccess,
  updateProfileFailure,
  sendEmailLinkSuccess,
  sendEmailLinkFailure,
  updateUserSettingsFailure,
  updateUserSettingsSuccess,
  loadLookups,
  loadLookupsSuccess,
  logoutSuccess,
  logoutFailure,
  checkLoginToken,
} from './auth.slice';
import { LookupField } from './configs/lookupField';
import { getTrackingDataFromCookies } from './auth.helpers';
import { enqueueNotification } from '../../ui/stackNotifications/stackNotifications.slice';
import { authLogoutTimeSelector } from './auth.selectors';

export function* signInHandler(action: ActionType<typeof signIn>) {
  const { token, redirectTo } = action.payload;
  try {
    yield* put(signInStart());

    const { data } = yield* call(apiSignIn, {
      idtoken: token,
      ...getTrackingDataFromCookies(),
    });

    const { abGroup, userId, wsAuthKey } = data;

    setUser({
      id: userId,
      abGroup,
    });

    tryTrack((a) => a.identify(userId, { abGroup }));
    yield* put(
      subscribeSubject({
        identifier: 'user',
        id: userId,
        authKey: wsAuthKey,
        logMessages: logWebsocketMessagesFeature,
      }),
    );
    yield* put(signInSuccess(data));
    yield* put(push(redirectTo || '/workspaces'));
  } catch (error) {
    yield* put(signInFailure(getErrorMessage(error, 'Not able to sign in')));
  }
}

function* signInFailureRequestHandler() {
  yield* put(signInFailure("Couldn't obtain token"));
}

const debouceTime = 5000; // in ms (5 sec)

function* logoutHandler(action: ActionType<typeof logout>) {
  const { skipRedirect = false, logoutTime: logoutTimeNew } = action.payload;
  const logoutTimeOld = yield* select(authLogoutTimeSelector);

  const shouldSkipLogout =
    logoutTimeOld &&
    logoutTimeNew &&
    logoutTimeNew !== logoutTimeOld &&
    logoutTimeNew - logoutTimeOld < debouceTime;

  if (shouldSkipLogout) return;

  try {
    yield* call(apiLogOut);
    yield* put(logoutSuccess());

    configureScope((scope) => scope.setUser(null));
    tryTrack((a) => a.reset());

    yield* put(unsubscribeSubject({ identifier: 'user' }));

    if (!skipRedirect) yield* put(push('/'));
  } catch (error) {
    yield* put(logoutFailure());
    yield* put(
      enqueueNotification({
        message: getErrorMessage(error, 'Unable to log out'),
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
          error,
        },
      }),
    );
  }
}

function* sendEmailLinkHandler(action: ActionType<typeof sendEmailLink>) {
  const { email } = action.payload;

  try {
    yield* call(apiSendEmailLink, { email });
    yield* put(sendEmailLinkSuccess());
    yield* put(
      enqueueNotification({
        message: 'Magic link sent',
        options: {
          variant: 'success',
          allowOutsideOfEditor: true,
        },
      }),
    );
  } catch (error) {
    yield* put(
      sendEmailLinkFailure(
        getErrorMessage(error, 'Not able to email sign in link'),
      ),
    );
  }
}

function* updateProfileHandler(action: ActionType<typeof updateProfile>) {
  const values = action.payload;

  try {
    const { data } = yield* call(apiUpdateProfile, values);

    yield* put(updateProfileSuccess(data));
    yield* put(hideModals());
  } catch (error) {
    yield* put(
      updateProfileFailure(
        getErrorMessage(error, 'Unable to update your profile'),
      ),
    );
  }
}

function* updateUserSettingsHandler(
  action: ActionType<typeof updateUserSettings>,
) {
  const settings = action.payload;
  const processedSettings = Object.values(ACCOUNT_PREFERENCES_FIELDS).reduce(
    (acc, field) => {
      // after a submit, assume nulls in the fields are false, because null means the user did not decide
      // on a specific item. Also, filter out possible future fields that we do not render yet.
      acc[field] = settings[field] || false;

      return acc;
    },
    {} as Record<string, boolean>,
  );

  try {
    const response = yield* call(apiUpdateSettings, processedSettings);
    yield* put(updateUserSettingsSuccess(response.data.settings));
    yield* put(hideModals());
  } catch (error) {
    yield* put(
      updateUserSettingsFailure(
        getErrorMessage(error, 'Unable to update your preferences'),
      ),
    );
  }
}

function* updateFeaturesHandler(action: ActionType<typeof updateUserFeatures>) {
  try {
    const response = yield* call(apiUpdateFeatures, action.payload);

    yield* put(updateUserFeaturesSuccess(response.data.features));
  } catch (error) {
    yield* put(
      updateUserFeaturesFailure(
        getErrorMessage(error, 'Unable to update your preferences'),
      ),
    );
  }
}

function* loadProfileHandler() {
  try {
    const {
      data: { userId, wsAuthKey, abGroup },
      data,
    } = yield* call(apiLoadProfile);

    yield* put(loadProfileSuccess(data));
    setUser({
      id: userId,
      abGroup,
    });
    tryTrack((a) => a.identify(userId));
    yield* put(
      subscribeSubject({
        identifier: 'user',
        id: userId,
        authKey: wsAuthKey,
        logMessages: logWebsocketMessagesFeature,
      }),
    );
  } catch (error) {
    yield* put(
      loadProfileFailure(getErrorMessage(error, 'Unable to load your profile')),
    );
  }
}

function* loadLookupsHandler() {
  try {
    const { data } = yield* call(apiLoadLookups);

    yield* put(
      loadLookupsSuccess(
        groupBy(data, 'name') as Record<LookupField, Lookup[]>,
      ),
    );
  } catch (error) {
    const message = getErrorMessage(error, 'Unable to fetch data');

    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
          error,
        },
      }),
    );
  }
}

export function* checkLoginTokenHandler() {
  const token = new URLSearchParams(window.location.search).get('token');
  if (token) {
    try {
      yield* put(signInStart());

      const { data } = yield* call(apiSignInWithEmailToken, {
        token,
        ...getTrackingDataFromCookies(),
      });
      const { abGroup, userId, wsAuthKey } = data;

      setUser({
        id: userId,
        abGroup,
      });

      tryTrack((a) => a.identify(userId));
      yield* put(
        subscribeSubject({
          identifier: 'user',
          id: userId,
          authKey: wsAuthKey,
          logMessages: logWebsocketMessagesFeature,
        }),
      );

      yield* put(signInSuccess(data));
      yield* put(push('/workspaces'));
    } catch (error) {
      yield* put(
        signInFailure(
          getErrorMessage(error, 'Login link is invalid or has expired'),
        ),
      );
    }
  }
}

export function* authSaga() {
  yield* takeEvery(signIn, signInHandler);
  yield* takeEvery(signInFailureRequest, signInFailureRequestHandler);
  yield* takeEvery(logout, logoutHandler);
  yield* takeEvery(sendEmailLink, sendEmailLinkHandler);
  yield* takeEvery(updateProfile, updateProfileHandler);
  yield* takeEvery(updateUserSettings, updateUserSettingsHandler);
  yield* takeEvery(updateUserFeatures, updateFeaturesHandler);
  yield* takeEvery(loadProfile, loadProfileHandler);
  yield* takeEvery(loadLookups, loadLookupsHandler);
  yield* takeEvery(checkLoginToken, checkLoginTokenHandler);
}
