import { call, put, select, takeEvery } from 'typed-redux-saga';

import { apiLoadSplitStrategies } from '../../../../../../api/requests/modelPlayground';
import { getErrorMessage } from '../../../../../../api/utils';
import { activeProjectIdSelector } from '../../../../project/project.selectors';
import {
  loadSplitStrategiesSuccess,
  loadSplitStrategiesStart,
  loadSplitStrategiesFailure,
} from './strategies.slice';

function* loadHandler() {
  const projectId = yield* select(activeProjectIdSelector);

  if (!projectId) return;

  try {
    const { data } = yield* call(apiLoadSplitStrategies, {
      projectId,
    });
    yield* put(loadSplitStrategiesSuccess(data.items));
  } catch (e) {
    const error = getErrorMessage(e, 'Could not load split strategies');
    yield* put(loadSplitStrategiesFailure(error));
  }
}

export function* splitStrategiesSaga() {
  yield* takeEvery(loadSplitStrategiesStart, loadHandler);
}
