import { createSelector } from '@reduxjs/toolkit';

import { relationsAdapter } from './KCRelations.slice';

const relationsStateSelector = (state: RootState) =>
  state.project.annotationTaxonomy.KCRelations;

export const KCRelationsLoadingStateSelector = createSelector(
  relationsStateSelector,
  (state) => state.loadingState,
);

const selectors = relationsAdapter.getSelectors(relationsStateSelector);

export const KCRelationsSelector = selectors.selectAll;
export const KCRelationsMapSelector = selectors.selectEntities;

export const KCRelationsForLabelClassSelector = createSelector(
  KCRelationsSelector,
  (_: RootState, labelClassId: string | undefined) => labelClassId,
  (relations, labelClassId) =>
    relations
      .filter((r) => r.labelClassId === labelClassId)
      .sort((a, b) => (a.labelClassOrder < b.labelClassOrder ? -1 : 1)),
);
export const KCRelationsForKeypointsSelector = createSelector(
  KCRelationsSelector,
  (_: RootState, keypointSchemaId: string) => keypointSchemaId,
  (relations, keypointSchemaId) =>
    relations.filter((r) => r.keypointSchemaId === keypointSchemaId),
);
