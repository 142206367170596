import { combineReducers } from '@reduxjs/toolkit';

import { videoViewToolsReducer } from './tools/tools.slice';
import { videoViewProjectReducer } from './project/videoViewProject.slice';
import { videoViewRecoilWrapperReducer } from './recoilWrapper/recoilWrapper.slice';

export const videoViewReducer = combineReducers({
  project: videoViewProjectReducer,
  tools: videoViewToolsReducer,
  recoilWrapper: videoViewRecoilWrapperReducer,
});
