import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type ActiveToolDataState = number[];

const { actions, reducer: activeToolDataReducer } = createSlice({
  name: 'newImageViewActiveToolData',
  initialState: [] as ActiveToolDataState,
  reducers: {
    addActiveToolEntityId(state, action: PayloadAction<number>) {
      state.push(action.payload);
    },
    cleanupActiveToolState() {
      return [];
    },
    requestCleanupActiveToolEntities() {},
  },
});

export const {
  addActiveToolEntityId,
  cleanupActiveToolState,
  requestCleanupActiveToolEntities,
} = actions;
export { activeToolDataReducer };
