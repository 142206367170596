import { combineReducers } from 'redux';

import { experimentsDataReducer } from './experimentsData/experimentsData.slice';
import { experimentsPaginationReducer } from './experimentsPagination/experimentsPagination.slice';
import { hiddenExperimentsReducer } from './hiddenExperiments/hiddenExperiments.slice';
import { templatesReducer } from './templates/templates.slice';

export const experimentsReducer = combineReducers({
  data: experimentsDataReducer,
  pagination: experimentsPaginationReducer,
  hiddenExperiments: hiddenExperimentsReducer,
  templates: templatesReducer,
});
