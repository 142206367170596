import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { ParseFileStatus } from '../../../../../../api/domainModels/imports';
import { loadingStateBuilder } from '../../../../../utils/loadingState';
import { setProjectId } from '../../../../core/imageView/project/project.slice';

export type ParseLog = {
  logId: number;
  logDate: string;
  logSeverity: ParseFileStatus;
  logMessage: string;
  logs?: string[];
};

export type ImportFileParseLogs = {
  id: string; // fileId
  [ParseFileStatus.Warning]?: ParseLog[];
  [ParseFileStatus.Error]?: ParseLog[];
};

export const adapter = createEntityAdapter<ImportFileParseLogs>();
const initialState = adapter.getInitialState({
  listLoadingState: loadingStateBuilder.initial(),
  itemLoadingState: loadingStateBuilder.initial(),
});

const { actions, reducer: importFilesParseLogsReducer } = createSlice({
  name: 'importFilesParseLogs',
  initialState,
  reducers: {
    loadParseLogsStart(
      state,
      action: PayloadAction<{
        projectId?: string;
        sessionId?: string;
        initialFetch?: boolean;
      }>,
    ) {
      const { initialFetch = false } = action.payload;
      if (initialFetch) {
        state.listLoadingState = loadingStateBuilder.inProgress();
      }
    },
    loadParseLogsSuccess(state, action: PayloadAction<ImportFileParseLogs[]>) {
      state.listLoadingState = loadingStateBuilder.success();
      adapter.setAll(state, action.payload);
    },
    loadParseLogsFailure(state, action: PayloadAction<string>) {
      state.listLoadingState = loadingStateBuilder.failure(action.payload);
    },
    skipLoadingParseLogs(state) {
      state.listLoadingState = loadingStateBuilder.success();
    },
    resetParseLogs(state) {
      adapter.removeAll(state);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setProjectId, () => initialState);
  },
});

export { importFilesParseLogsReducer };
export const {
  loadParseLogsStart,
  loadParseLogsSuccess,
  loadParseLogsFailure,
  skipLoadingParseLogs,
  resetParseLogs,
} = actions;
