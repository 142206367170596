import { validate } from 'jsonschema';

import {
  ExperimentParameterValue,
  ExperimentParameter,
  customParameterTypes,
} from '../../../../api/domainModels/modelPlayground';
import { ParameterType } from '../../../../api/constants/modelPlayground';

export const getInitialValuesForParametersForm = (
  parameters: ExperimentParameter[],
) => {
  const values = {} as {
    [key: string]: ExperimentParameterValue['value'] | null;
  };
  parameters.forEach((parameter: ExperimentParameter) => {
    const id =
      parameter.type === ParameterType.Weights
        ? ParameterType.Weights
        : parameter.id;
    const value = parameter.selectedValue;
    if (
      parameter.type === ParameterType.Array &&
      value === null &&
      parameter.schema.minItems
    ) {
      const arrValue = [] as any[];
      for (let i = 0; i < parameter.schema.minItems; i++) {
        arrValue.push(null);
      }
      values[id] = arrValue;
    } else {
      values[id] = value;
    }
  });

  return values;
};

export const isParameterValid = (parameter: ExperimentParameter) => {
  if (!parameter.schema) return true;
  if (!parameter.schema.required && parameter.selectedValue === null) {
    // special case for when it's of type array
    return true;
  }

  // yet another special case that the lib cannot process because type is unknown to it
  if (
    parameter.schema.required &&
    customParameterTypes.includes(parameter.schema.type as ParameterType)
  ) {
    return parameter.selectedValue !== null;
  }

  return validate(parameter.selectedValue, parameter.schema).valid;
};

export const getParameterFirstInvalidMessage = (
  parameter: ExperimentParameter,
) => {
  if (!parameter.schema) return true;
  if (!parameter.schema.required && parameter.selectedValue === null) {
    // special case for when it's of type array
    return undefined;
  }

  // yet another special case that the lib cannot process because type is unknown to it
  if (
    parameter.schema.required &&
    customParameterTypes.includes(parameter.schema.type as ParameterType)
  ) {
    return parameter.selectedValue === null ? 'value must be set' : undefined;
  }

  const res = validate(parameter.selectedValue, parameter.schema);

  return res.errors[0]?.message;
};

export const getParametersArrayFieldErrors = (
  parameter: ExperimentParameter,
) => {
  if (parameter.type !== ParameterType.Array) return [];
  if (!parameter.schema) return [];
  if (!parameter.schema.required && parameter.selectedValue === null) {
    // special case for when it's of type array
    return [];
  }

  const res = validate(parameter.selectedValue, parameter.schema);

  return res.errors.map(({ path, message }) => ({
    message,
    index: path[0] as number,
  }));
};

export const canSaveParameter = ({
  parameter,
  newValue,
  selectedValue,
  previousValue,
}: {
  parameter?: ExperimentParameter;
  newValue: any;
  selectedValue: any;
  previousValue: any;
}): boolean => {
  const hasInvalidParameter =
    parameter &&
    getParameterFirstInvalidMessage({
      ...parameter,
      selectedValue,
    });

  return (
    previousValue !== undefined &&
    previousValue !== newValue &&
    !hasInvalidParameter
  );
};
