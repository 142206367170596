import { all, call, put, select, takeEvery } from 'typed-redux-saga';
import { PayloadAction } from '@reduxjs/toolkit';
import shortid from 'shortid';

import { apiRequestAtomPrediction } from '../../../../../../api/requests/atom';
import { apiLoadAtomModel } from '../../../../../../api/requests/projectTools';
import { getErrorMessage } from '../../../../../../api/utils';
import { METHOD_BORDER } from '../../../../../../workers/mask/constants';
import {
  METHOD_IMAGE_DATA_TO_RLE,
  METHOD_RLE_TO_IMAGE_DATA,
} from '../../../../../../workers/rle/constants';
import {
  getResultFromWorker,
  MASK_WORKER,
  RLE_WORKER,
  TerminationError,
} from '../../../../../../workers/workerManager';
import { handleError } from '../../../../commonFeatures/errorHandler/errorHandler.actions';
import { MODEL_LOADED, MODEL_UPDATED } from '../../../../ws/ws.constants';
import { activeProjectIdSelector } from '../../../../project/project.selectors';
import { imageViewImageIdSelector } from '../../currentImage/currentImage.selectors';
import { activeLabelClassIdSelector } from '../../labelClasses/labelClasses.selectors';
import {
  createModelChangePattern,
  ModelChangePayload,
} from '../models/models.constants';
import {
  loadModelHandler,
  modelLoadedHandler,
  updateModelHandler,
} from '../models/models.saga';
import {
  atomDataByIdSelector,
  atomDataSelector,
  atomInputBboxSelector,
  atomInputScribbleBboxSelector,
  atomInputScribbleMaskSelector,
  atomModelIdSelector,
  atomModelLoadedSelector,
  atomPointsSelector,
  atomVisibleAreaBboxSelector,
} from './atom.selectors';
import {
  acceptAtomResult,
  acceptAtomResults,
  loadAtomResultFailure,
  loadAtomResultsSuccess,
  loadAtomResultStart,
  loadAtomResultSuccess,
  rejectAtomResult,
  resetAtomResult,
} from './atomResult.slice';
import {
  addAtomBbox,
  addAtomPoint,
  addAtomScribble,
  AtomPoint,
  clearAtomHistory,
  clearAtomValues,
  moveAtomPoint,
  redoAtom,
  removeLastAtomPoint,
  undoAtom,
} from './atomValues.slice';
import {
  loadAtomModel,
  loadAtomModelError,
  loadAtomModelStart,
  loadAtomModelSuccess,
  updateAtomModel,
  updateAtomModelSuccess,
} from './atomModel.slice';
import { ATOM_FAMILY_NAME, MODEL_MESSAGES } from './constants';
import { addLabels } from '../../labels/labels.slice';
import {
  addActiveToolEntityId,
  requestCleanupActiveToolEntities,
} from '../activeToolData/activeToolData.slice';
import { advancedOptionsDefaultLabelTypeSelector } from '../../../../sections/editedProject/advancedOptions/advancedOptions.selectors';
import { Bbox, Polygon } from '../../../../../../@types/imageView/types';
import { ImageLabel } from '../../../../../../api/domainModels/imageLabel';
import { LabelType } from '../../../../../../api/constants/label';
import { resetActiveTool, setActiveTool } from '../tools.slice';
import { storeObject } from '../../imageView.helpers';
import { retrieveObject } from '../../../../../../helpers/imageView/data.helpers';
import { convertMaskLabelToPolygon } from '../../labels/conversions';
import { getHeight, getWidth } from '../../../../../../util/bbox';
import {
  ImageViewTool,
  ImageTool,
  ImageToolTitleMap,
} from '../tools.constants';
import { enqueueNotification } from '../../../../ui/stackNotifications/stackNotifications.slice';
import { showModal } from '../../../../ui/modals/modals.slice';
import { uuidv4 } from '../../../../../../util/uuidv4';
import { atomEditingStateSaga } from './atomEditingState/atomEditingState.saga';
import {
  atomEditingStateMaskSizeLevelSelector,
  atomEditingStateStrategySelector,
  atomEditingStateUseViewportSelector,
} from './atomEditingState/atomEditingState.selectors';
import {
  resetMaskSizeLevel,
  setMaskSizeLevel,
} from './atomEditingState/atomEditingState.slice';
import {
  ActionTrigger,
  triggerAction,
} from '../../toolbarActionTrigger/toolbarActionTrigger.slice';
import { AtomPredictionResponse } from '../../../../../../api/domainModels/atom';

const createMaskObject = ({
  classId,
  mask,
  bbox,
}: {
  classId: string;
  bbox: ImageLabel['bbox'];
  mask: ImageLabel['mask'];
}) => ({
  id: uuidv4(),
  classId,
  mask,
  bbox,
  toolUsed: ImageTool.Atom as ImageViewTool,
  type: LabelType.Mask,
});
const createPolygonObject = ({
  bbox,
  classId,
  polygon,
}: {
  bbox: Bbox;
  classId: string;
  polygon: Polygon;
}) => ({
  id: uuidv4(),
  bbox,
  classId,
  polygon,
  toolUsed: ImageTool.Atom as ImageViewTool,
  type: LabelType.Polygon,
});

function* clearAtomValuesHandler() {
  yield* put(requestCleanupActiveToolEntities());
  yield* put(clearAtomHistory());
  yield* put(resetAtomResult());
}

function* setActiveToolHandler() {
  yield* put(clearAtomValues());
}

function* processAtomResultHandler(data: AtomPredictionResponse) {
  const imageData = yield* getResultFromWorker(RLE_WORKER, {
    method: METHOD_RLE_TO_IMAGE_DATA,
    rle: {
      data: data.mask,
      width: getWidth(data.bbox),
      height: getHeight(data.bbox),
    },
  });
  const borderData = yield* getResultFromWorker(MASK_WORKER, {
    method: METHOD_BORDER,
    imageData,
  });
  const borderDataId = storeObject(borderData);
  const maskId = storeObject(data.mask);

  yield* put(addActiveToolEntityId(borderDataId));
  yield* put(addActiveToolEntityId(maskId));

  return { borderDataId, maskId, bbox: data.bbox, id: shortid() };
}

function* valuesChangedHandler() {
  const points = yield* select(atomPointsSelector);
  const strategy = yield* select(atomEditingStateStrategySelector);

  if (points.length === 0 && strategy === 'points') {
    yield* put(requestCleanupActiveToolEntities());
    yield* put(resetAtomResult());

    return;
  }

  const projectId = yield* select(activeProjectIdSelector);
  const imageId = yield* select(imageViewImageIdSelector);

  if (!imageId) {
    return;
  }

  const data = yield* select(atomDataSelector);
  const visibleAreaBbox = yield* select(atomVisibleAreaBboxSelector);
  const useViewport = yield* select(atomEditingStateUseViewportSelector);
  const maskSizeLevel = yield* select(atomEditingStateMaskSizeLevelSelector);

  const payload = {
    chargedPoints: [] as AtomPoint[],
    bbox: [0, 0, 0, 0] as Bbox,
    mask: [] as number[],
  };

  if (strategy === 'points') {
    payload.chargedPoints = points;
    payload.bbox = data[0]?.bbox as Bbox;
    payload.mask = retrieveObject(data[0]?.maskId) || [];
  } else if (strategy === 'box' || strategy === 'multiInstance') {
    payload.bbox = yield* select(atomInputBboxSelector);
  } else {
    payload.mask = (yield* select(atomInputScribbleMaskSelector)) as number[];
    payload.bbox = yield* select(atomInputScribbleBboxSelector);
  }

  yield* put(loadAtomResultStart());
  yield* put(requestCleanupActiveToolEntities());

  const shouldUseViewport = useViewport ? visibleAreaBbox : undefined;

  try {
    const { data } = yield* call(
      apiRequestAtomPrediction,
      {
        projectId,
        imageId,
      },
      {
        ...payload,
        viewport: shouldUseViewport,
        maskSizeLevel,
        multiInstance: strategy === 'multiInstance',
      },
    );

    const filteredData = data.filter((datum) => datum.mask.length !== 0);

    if (filteredData.length === 0) {
      yield* put(
        enqueueNotification({
          message: 'Atom failed to predict',
          options: {
            variant: 'error',
          },
        }),
      );

      yield* put(resetAtomResult());

      return;
    }

    const result: {
      borderDataId: number;
      maskId: number;
      bbox: Bbox;
      id: string;
    }[] = yield* all(data.map(processAtomResultHandler));

    if (strategy === 'multiInstance') {
      yield* put(loadAtomResultsSuccess(result));
    } else {
      yield* put(loadAtomResultSuccess(result));
    }
    yield* put(triggerAction(ActionTrigger.Discard));
  } catch (error) {
    const message = getErrorMessage(error, 'Atom failed to predict');

    if (!(error instanceof TerminationError)) {
      yield* put(handleError({ message, error }));
    } else {
      yield* put(
        enqueueNotification({
          message,
          options: {
            variant: 'error',
            error,
          },
        }),
      );
    }
    yield* put(loadAtomResultFailure(message));
  }
}

function* acceptAtomResultHandler(
  action: ActionType<typeof acceptAtomResult | typeof acceptAtomResults>,
) {
  const activeLabelClassId = yield* select(activeLabelClassIdSelector);
  const toolsPreferencesOption = yield* select(
    advancedOptionsDefaultLabelTypeSelector,
  );
  let data = yield* select(atomDataSelector);

  if (!activeLabelClassId) {
    yield* put(
      showModal({
        modalName: 'upsertLabelClass',
        modalProps: { preLabelCreation: true },
      }),
    );

    return;
  }

  if (action?.payload?.id) {
    const dataById = yield* select(atomDataByIdSelector, action.payload.id);

    if (dataById) {
      data = [dataById];
    }
  }

  try {
    let labelsToAdd = yield* all(
      data.map(function* ({ borderDataId, bbox }) {
        let labelToAdd;
        const width = getWidth(bbox);
        const height = getHeight(bbox);

        if (borderDataId !== null) {
          const borderData = retrieveObject(borderDataId);
          const tempCanvas = document.createElement('canvas');
          tempCanvas.width = width;
          tempCanvas.height = height;
          const ctx = tempCanvas.getContext('2d');

          if (!ctx) {
            return;
          }

          const p = new Path2D(borderData);
          ctx.stroke(p);
          ctx.fill(p);
          const imageData = ctx.getImageData(0, 0, width, height);

          const mask = yield* getResultFromWorker(RLE_WORKER, {
            method: METHOD_IMAGE_DATA_TO_RLE,
            imageData,
          });

          if (mask) {
            if (toolsPreferencesOption === LabelType.Polygon) {
              const polygon: Polygon = yield* convertMaskLabelToPolygon({
                bbox: bbox as Bbox,
                mask: mask.data,
              });
              labelToAdd = createPolygonObject({
                bbox: bbox as Bbox,
                polygon,
                classId: activeLabelClassId,
              });
            } else {
              labelToAdd = createMaskObject({
                mask: mask.data,
                bbox: bbox as Bbox,
                classId: activeLabelClassId,
              });
            }
          }
        }

        return labelToAdd;
      }),
    );

    labelsToAdd = labelsToAdd.filter(Boolean);

    yield* put(
      addLabels(labelsToAdd as Array<Partial<ImageLabel> & { id: string }>),
    );
    yield* put(clearAtomValues());
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to add labels');

    if (!(error instanceof TerminationError)) {
      yield* put(handleError({ message, error }));
    } else {
      yield* put(
        enqueueNotification({
          message,
          options: {
            variant: 'error',
            error,
          },
        }),
      );
    }
  }
}

function* rejectAtomResultHandler() {
  yield* put(clearAtomValues());
}

function* loadAtomModelHandler() {
  const projectId = yield* select(activeProjectIdSelector);

  yield* call(loadModelHandler, {
    projectId,
    loadApiCall: apiLoadAtomModel,
    messages: MODEL_MESSAGES,
    toolId: ImageTool.Atom,
    modelLoadStartAction: loadAtomModelStart,
    modelLoadSuccessAction: loadAtomModelSuccess,
    modelLoadErrorAction: loadAtomModelError,
    toolName: ImageToolTitleMap[ImageTool.Atom],
  });
}

function* atomModelLoadedHandler(action: PayloadAction<ModelChangePayload>) {
  const { status, progress, id, modelUseIE } = action.payload;

  yield* call(modelLoadedHandler, {
    id,
    modelIdSelector: atomModelIdSelector,
    modelLoadedSelector: atomModelLoadedSelector,
    progress,
    status,
    modelUseIE,
    updateAction: updateAtomModel,
  });
}

function* atomModelUpdatedHandler(action: PayloadAction<ModelChangePayload>) {
  const { status, progress, id, modelUseIE } = action.payload;

  yield* put(
    updateAtomModel({
      status,
      progress,
      id,
      modelUseIE,
    }),
  );
}

function* updateAtomModelHandler(action: ActionType<typeof updateAtomModel>) {
  const { status, progress, id, modelUseIE } = action.payload;

  yield* call(updateModelHandler, {
    id,
    loadAction: loadAtomModel,
    progress,
    status,
    modelUseIE,
    successAction: updateAtomModelSuccess,
  });
}

function* atomSaga() {
  yield* takeEvery(clearAtomValues, clearAtomValuesHandler);
  yield* takeEvery([setActiveTool, resetActiveTool], setActiveToolHandler);
  yield* takeEvery(
    [
      addAtomPoint,
      moveAtomPoint,
      removeLastAtomPoint,
      undoAtom,
      redoAtom,
      addAtomBbox,
      addAtomScribble,
      setMaskSizeLevel,
      resetMaskSizeLevel,
    ],
    valuesChangedHandler,
  );
  yield* takeEvery(
    [acceptAtomResult, acceptAtomResults],
    acceptAtomResultHandler,
  );
  yield* takeEvery(rejectAtomResult, rejectAtomResultHandler);
  yield* takeEvery(loadAtomModel, loadAtomModelHandler);
  yield* takeEvery(
    createModelChangePattern(ATOM_FAMILY_NAME, MODEL_LOADED),
    atomModelLoadedHandler,
  );
  yield* takeEvery(
    createModelChangePattern(ATOM_FAMILY_NAME, MODEL_UPDATED),
    atomModelUpdatedHandler,
  );
  yield* takeEvery(updateAtomModel, updateAtomModelHandler);
}

export const atomSagas = [atomSaga, atomEditingStateSaga];
