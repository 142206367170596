import cookies from 'js-cookie';
import qs from 'query-string';

import {
  IMMEDIATE_REFERRER_COOKIE_NAME,
  ORIGINAL_REFERRER_COOKIE_NAME,
  WEBSITE_SEARCH_COOKIE_NAME,
} from './configs/userSourceAnalytics';

export const getTrackingDataFromCookies = () => {
  const search = cookies.get(WEBSITE_SEARCH_COOKIE_NAME);
  let campaignData = {};

  if (search) {
    campaignData = qs.parse(search);
  }

  return {
    immediate_referrer: cookies.get(IMMEDIATE_REFERRER_COOKIE_NAME),
    original_referrer: cookies.get(ORIGINAL_REFERRER_COOKIE_NAME),
    ...campaignData,
  };
};
