import { call, put, select, takeEvery } from 'typed-redux-saga';

import {
  loadSSLParameters,
  loadSSLParametersFailure,
  loadSSLParametersSuccess,
  updateSSLParameter,
  updateSSLParameterFailure,
  updateSSLParameterSuccess,
} from './SSL.slice';
import { activeProjectIdSelector } from '../../../../project/project.selectors';
import { activeExperimentIdSelector } from '../selectors';
import {
  apiGetSSLParameters,
  apiUpdateSSLParameter,
} from '../../../../../../api/requests/modelPlayground';
import { getErrorMessage } from '../../../../../../api/utils';

function* loadSSLParametersHandler() {
  try {
    const projectId = yield* select(activeProjectIdSelector);
    const experimentId = yield* select(activeExperimentIdSelector);

    if (!experimentId) {
      return;
    }

    const {
      data: { items },
    } = yield* call(apiGetSSLParameters, {
      experimentId,
      projectId,
    });

    yield* put(loadSSLParametersSuccess(items));
  } catch (error) {
    yield* put(
      loadSSLParametersFailure(
        getErrorMessage(error, 'Not able to load parameters'),
      ),
    );
  }
}

function* updateSSLParameterHandler(
  action: ActionType<typeof updateSSLParameter>,
) {
  const { semiSupervisedParameterId } = action.payload;
  const projectId = yield* select(activeProjectIdSelector);
  const experimentId = yield* select(activeExperimentIdSelector);

  if (!experimentId) {
    return;
  }

  try {
    const { data } = yield* call(
      apiUpdateSSLParameter,
      { projectId, experimentId },
      action.payload,
    );

    yield* put(
      updateSSLParameterSuccess({
        id: semiSupervisedParameterId,
        changes: data,
      }),
    );
  } catch (error) {
    yield* put(updateSSLParameterFailure());
  }
}

export function* SSLSaga() {
  yield* takeEvery(loadSSLParameters, loadSSLParametersHandler);
  yield* takeEvery(updateSSLParameter, updateSSLParameterHandler);
}
