import invert from 'lodash/invert';

import { ImageTool } from '../redux/state/core/imageView/tools/tools.constants';
import { ModelTool } from '../redux/state/core/imageView/tools/models/models.selectors';
import { ModelFamily } from '../api/constants/modelFamily';
import { VideoTool } from '../redux/state/core/videoView/tools/tools.constants';

export const TOOLS_TO_RESET_ON_PROJECT_RESET: ImageTool[] = [
  ImageTool.ObjectDetection,
  ImageTool.InstanceSegmentation,
  ImageTool.SemanticSegmentation,
  ImageTool.BoxToInstance,
  ImageTool.Drawing,
  ImageTool.KeypointsDetection,
  ImageTool.TextPrompt,
];

export const toolIdToModelFamilyEnum: Record<string, ModelFamily> = {
  [ImageTool.LabelClassification]: ModelFamily.Classifier,
  [ImageTool.SemanticSegmentation]: ModelFamily.SemanticSegmentor,
  [ImageTool.InstanceSegmentation]: ModelFamily.Segmentor,
  [ImageTool.ObjectDetection]: ModelFamily.Detector,
  [ImageTool.ImageTagsPrediction]: ModelFamily.ImageTagger,
  [ImageTool.AttributesPrediction]: ModelFamily.Attributer,
  [ImageTool.KeypointsDetection]: ModelFamily.KeypointDetector,
  [VideoTool.SegmentPrediction]: ModelFamily.SegmentPredictor,
};

export const modelFamilyEnumToToolId = invert(
  toolIdToModelFamilyEnum,
) as Record<ModelFamily, ModelTool>;

export const MAX_OBJECTS_TO_SHOW = 1000;
export const MIN_OBJECTS_TO_SHOW = 0;
export const MAX_MASK_SIZE = 100;
export const MIN_MASK_SIZE = 0;
export const MAX_MASKS_TO_SHOW = 100;
