import { createSelector } from '@reduxjs/toolkit';

import { ImageLabel } from '../../../../../../api/domainModels/imageLabel';
import {
  imageLabelsMapSelector,
  imageLabelsIdsSelector,
  maxImageLabelZIndexSelector,
  allLabelsHaveSameZIndexSelector,
  imageLabelsCountSelector,
  minImageLabelZIndexSelector,
} from '../labels.selectors';
import { adapter } from './selectedLabels.slice';
import { activeToolIdSelector } from '../../tools/tools.selectors';
import { panningEnabledSelector } from '../../panning/panning.selectors';
import { consensusScoringEnabledSelector } from '../../consensusScoring/consensusScoring.selectors';
import { ImageTool } from '../../tools/tools.constants';
import { LabelType } from '../../../../../../api/constants/label';
import { labelClassKeypointsSchemaSelector } from '../../../../project/annotationTaxonomy/annotationTaxonomy.selectors';
import { imageViewSelector } from '../../imageView.selectors';
import {
  SAMLabelDataSelector,
  useSAMSelector,
} from '../../tools/drawing/drawing.selectors';

const CONTEXT_MENU_RESTRICTED_TOOLS = [
  ImageTool.ObjectDetection,
  ImageTool.Pan,
];

const selectedLabelsStateSelector = createSelector(
  imageViewSelector,
  (imageView) => imageView.selectedLabels,
);
const selectedLabelsSelectors = adapter.getSelectors(
  selectedLabelsStateSelector,
);

export const selectedLabelsIdsSelector = selectedLabelsSelectors.selectAll;
export const selectedLabelsIdsCountSelector =
  selectedLabelsSelectors.selectTotal;
export const selectedLabelsIdsMapSelector =
  selectedLabelsSelectors.selectEntities;

export const isLabelSelectedSelector = createSelector(
  selectedLabelsIdsMapSelector,
  (_: RootState, id: string) => id,
  (selectedLabelsIdsMap, id) => !!selectedLabelsIdsMap[id],
);

export const isLabelSingleSelectedSelector = createSelector(
  selectedLabelsIdsMapSelector,
  selectedLabelsIdsCountSelector,
  (_: RootState, id: string) => id,
  (selectedLabelsIdsMap, count, id) =>
    count === 1 && !!selectedLabelsIdsMap[id],
);

export const selectedLabelsSelector = createSelector(
  [selectedLabelsIdsSelector, imageLabelsMapSelector],
  (selectedLabelsIds, labelsMap) =>
    selectedLabelsIds
      .map((labelId) => labelsMap[labelId])
      .filter(Boolean) as ImageLabel[],
);

export const maskEditAvailableSelector = createSelector(
  [selectedLabelsSelector],
  (selectedLabels) =>
    selectedLabels.length === 1 && selectedLabels[0].type === LabelType.Mask,
);

export const keypointsEditAvailableSelector = createSelector(
  [selectedLabelsSelector],
  (selectedLabels) =>
    selectedLabels.length === 1 &&
    selectedLabels[0].type === LabelType.Keypoints,
);

export const selectedLabelsClassIdsSelector = createSelector(
  selectedLabelsSelector,
  (selectedLabels) =>
    Array.from(
      new Set(
        selectedLabels
          .map((label) => label.classId)
          .filter(Boolean) as string[],
      ),
    ),
);

export const selectedLabelIndexSelector = createSelector(
  [selectedLabelsIdsSelector, imageLabelsIdsSelector],
  (selectedLabelsIds, labelsIds) =>
    labelsIds.findIndex((labelId) => labelId === selectedLabelsIds[0]),
);

export const nextLabelIdToSelectedSelector = createSelector(
  [selectedLabelIndexSelector, imageLabelsIdsSelector],
  (selectedLabelIndex, labelsIds) =>
    labelsIds[selectedLabelIndex + 1] || labelsIds[0],
);

export const previousLabelIdToSelectedSelector = createSelector(
  [selectedLabelIndexSelector, imageLabelsIdsSelector],
  (selectedLabelIndex, labelsIds) =>
    labelsIds[selectedLabelIndex - 1] || labelsIds[labelsIds.length - 1],
);

export const selectionContextMenuOptionsSelector = createSelector(
  selectedLabelsStateSelector,
  (selectedLabelsState) => selectedLabelsState.contextMenu,
);

export const selectionContextMenuOpenSelector = createSelector(
  selectionContextMenuOptionsSelector,
  activeToolIdSelector,
  panningEnabledSelector,
  consensusScoringEnabledSelector,
  (menuOptions, activeTool, isPanning, CSEnabled) =>
    menuOptions.visible &&
    !CONTEXT_MENU_RESTRICTED_TOOLS.includes(activeTool as ImageTool) &&
    !CSEnabled &&
    !isPanning,
);

export const selectedLabelsCanBeSentToBackSelector = createSelector(
  selectedLabelsSelector,
  minImageLabelZIndexSelector,
  allLabelsHaveSameZIndexSelector,
  imageLabelsCountSelector,
  (selectedLabels, minIndex, sameIndex, count) => {
    const zIndex = minIndex || 0; // account for the case when they are all zero
    const allowIfSameIndex = count > 1 && sameIndex;

    return (
      allowIfSameIndex || selectedLabels.some((label) => label.zIndex > zIndex)
    );
  },
);

export const selectedLabelsCanBeBroughtToFrontSelector = createSelector(
  selectedLabelsSelector,
  maxImageLabelZIndexSelector,
  allLabelsHaveSameZIndexSelector,
  imageLabelsCountSelector,
  (selectedLabels, maxIndex, sameIndex, count) => {
    const zIndex = maxIndex || 0; // account for the case when they are all zero
    const allowIfSameIndex = count > 1 && sameIndex;

    return (
      allowIfSameIndex || selectedLabels.some((label) => label.zIndex < zIndex)
    );
  },
);

export const editedMaskLabelSelector = createSelector(
  [selectedLabelsSelector, useSAMSelector, SAMLabelDataSelector],
  (selectedLabels, useSAM, SAMLabelData) => {
    if (selectedLabels.length === 1 && selectedLabels[0].mask) {
      if (useSAM && SAMLabelData?.mask && SAMLabelData?.bbox) {
        return {
          ...selectedLabels[0],
          mask: SAMLabelData.mask,
          bbox: SAMLabelData.bbox,
          borderData: SAMLabelData.borderData,
        };
      }

      return selectedLabels[0];
    }

    return null;
  },
);

export const editedKeypointsLabelSelector = createSelector(
  selectedLabelsSelector,
  (selectedLabels) => {
    if (selectedLabels.length === 1 && selectedLabels[0].keypoints)
      return selectedLabels[0];

    return null;
  },
);

export const isEditingMaskSelector = createSelector(
  editedMaskLabelSelector,
  (editedMaskLabel) => !!editedMaskLabel,
);

export const selectedLabelsCanChangeClassIdsSelector = createSelector(
  (state: RootState) => state,
  (state: RootState, targetClassId: string | undefined) =>
    labelClassKeypointsSchemaSelector(state, targetClassId),
  selectedLabelsSelector,
  (state, targetSchema, selectedLabels) =>
    selectedLabels
      .filter((selectedLabel) => {
        if (!selectedLabel.keypoints) return true;

        const labelSchema = labelClassKeypointsSchemaSelector(
          state,
          selectedLabel.classId || undefined,
        );

        return labelSchema.id === targetSchema?.id;
      })
      .map((selectedLabel) => selectedLabel.id),
);
