import { createSelector } from '@reduxjs/toolkit';

import { imageViewSelector } from '../../imageView.selectors';
import { modelStatusChecks } from '../models/modelStatus.constants';
import { adapter } from './objectDetection.slice';

export const objectDetectionSelector = createSelector(
  imageViewSelector,
  (imageView) => imageView.tools.objectDetection,
);

export const objectDetectionModelIdSelector = createSelector(
  objectDetectionSelector,
  (objectDetection) => objectDetection.model.id,
);

export const objectDetectionModelStatusSelector = createSelector(
  objectDetectionSelector,
  (objectDetection) => objectDetection.model.status,
);

export const objectDetectionConfidenceSelector = createSelector(
  objectDetectionSelector,
  (objectDetection) => objectDetection.confidence,
);

export const objectDetectionMaxDetectionsSelector = createSelector(
  objectDetectionSelector,
  (objectDetection) => objectDetection.maxDetections,
);

export const objectDetectionDataSelector = createSelector(
  objectDetectionSelector,
  (objectDetection) => objectDetection.data,
);

export const objectDetectionDataSelectors = adapter.getSelectors(
  objectDetectionDataSelector,
);

export const objectDetectionPredictedDataSelector =
  objectDetectionDataSelectors.selectAll;

export const objectDetectionPredictedDataByIdSelector =
  objectDetectionDataSelectors.selectById;

export const objectDetectionPredictedDataCountSelector =
  objectDetectionDataSelectors.selectTotal;

export const objectDetectionLoadingStateSelector = createSelector(
  objectDetectionSelector,
  (objectDetection) => objectDetection.data.loadingState,
);

export const objectDetectionModelAvailableSelector = createSelector(
  objectDetectionModelIdSelector,
  (modelId) => typeof modelId === 'number',
);

export const objectDetectionModelLoadedSelector = createSelector(
  [objectDetectionModelAvailableSelector, objectDetectionModelStatusSelector],
  (available, status) =>
    !!(available && status && modelStatusChecks.isLoaded(status)),
);
