import { call, put, select, takeEvery } from 'typed-redux-saga';

import {
  apiGetExperimentRuns,
  apiStartExperimentRun,
  apiRestartExperimentRun,
} from '../../../../../../api/requests/modelPlayground';
import { getErrorMessage } from '../../../../../../api/utils';
import { loadAll } from '../../../../../utils/api';
import { activeProjectIdSelector } from '../../../../project/project.selectors';
import { updateExperimentSuccess } from '../../experiments/experimentsData/experimentsData.slice';
import { activeExperimentIdSelector } from '../selectors';
import {
  loadRuns,
  loadRunsSuccess,
  loadRunsFailure,
  startRun,
  startRunSuccess,
  startRunFailure,
  restartRun,
  restartRunSuccess,
  restartRunFailure,
} from './runs.slice';
import { hideModals } from '../../../../ui/modals/modals.slice';
import { enqueueNotification } from '../../../../ui/stackNotifications/stackNotifications.slice';

function* loadRunsHandler() {
  try {
    const projectId = yield* select(activeProjectIdSelector);
    const experimentId = yield* select(activeExperimentIdSelector);

    if (!experimentId) return;
    const items = yield* loadAll({
      apiHelper: apiGetExperimentRuns,
      params: {
        projectId,
        experimentId,
      },
    });
    yield* put(loadRunsSuccess(items));
  } catch (e) {
    yield* put(
      loadRunsFailure(getErrorMessage(e, 'Not able to load experiment runs')),
    );
  }
}

function* startRunHandler(action: ActionType<typeof startRun>) {
  try {
    const eId = action.payload;
    const projectId = yield* select(activeProjectIdSelector);
    const experimentId = eId || (yield* select(activeExperimentIdSelector));

    if (!experimentId) {
      return;
    }

    const params = { projectId, experimentId };
    const { data } = yield* call(apiStartExperimentRun, params);

    yield* put(startRunSuccess(data));
    yield* put(
      updateExperimentSuccess({
        changes: { lastRunId: data.id, status: data.trainStatus },
        id: experimentId,
      }),
    );
  } catch (error) {
    const message = getErrorMessage(error, 'Not able to start the experiment');
    yield* put(startRunFailure(message));
    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
          refresh: false,
        },
      }),
    );
  }
}

function* restartRunHandler(action: ActionType<typeof restartRun>) {
  const { experimentId, data: dataParameters } = action.payload;

  const projectId = yield* select(activeProjectIdSelector);
  const params = { projectId, experimentId };

  try {
    const { data } = yield* call(apiRestartExperimentRun, params, {
      parameters: dataParameters,
    });

    yield* put(restartRunSuccess({ id: data.id, changes: data }));
    yield* put(
      updateExperimentSuccess({
        changes: { lastRunId: data.id, status: data.trainStatus },
        id: experimentId,
      }),
    );
    yield* put(hideModals());
  } catch (error) {
    const message = getErrorMessage(
      error,
      'Not able to restart the experiment',
    );

    yield* put(restartRunFailure(message));
    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
          allowOutsideOfEditor: true,
          refresh: false,
        },
      }),
    );
  }
}

export function* runsSaga() {
  yield* takeEvery(loadRuns, loadRunsHandler);
  yield* takeEvery(startRun, startRunHandler);
  yield* takeEvery(restartRun, restartRunHandler);
}
