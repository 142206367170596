import type { Reducer } from 'react';
import { combineReducers } from 'redux';

import { enhancedHistory } from './enhancedHistory';
import { constructStaticReducers } from '../state/root.reducers';

const staticReducers = constructStaticReducers(enhancedHistory);

export const constructRootReducer = <T extends Reducer<unknown, unknown>>(
  asyncReducers?: T,
) =>
  combineReducers({
    ...staticReducers,
    ...asyncReducers,
  });

export type StaticRootState = ReturnType<
  ReturnType<typeof constructRootReducer>
>;
