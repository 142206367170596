import { all, call, put, select, takeEvery } from 'typed-redux-saga';
import { PayloadAction } from '@reduxjs/toolkit';
import { Effect } from '@redux-saga/types';

import {
  apiLoadClassPrediction,
  apiLoadClassificationModel,
} from '../../../../../../api/requests/projectTools';
import { getErrorMessage } from '../../../../../../api/utils';
import { handleError } from '../../../../commonFeatures/errorHandler/errorHandler.actions';
import { activeProjectIdSelector } from '../../../../project/project.selectors';
import { imageViewImageIdSelector } from '../../currentImage/currentImage.selectors';
import {
  labelClassPredictionModelIdSelector,
  labelClassPredictionModelLoadedSelector,
  shouldPredictLabelClassesSelector,
} from './labelClassPrediction.selectors';
import {
  createModelChangePattern,
  ModelChangePayload,
} from '../models/models.constants';
import { MODEL_LOADED, MODEL_UPDATED } from '../../../../ws/ws.constants';
import {
  LABEL_CLASS_PREDICTION_FAMILY_NAME,
  MODEL_MESSAGES,
} from './labelClassPrediction.constants';
import {
  loadModelHandler,
  modelLoadedHandler,
  updateModelHandler,
} from '../models/models.saga';
import {
  updateLabelClassPredictionModel,
  loadLabelClassPredictionModel,
  loadLabelClassPredictionModelFailure,
  updateLabelClassPredictionModelSuccess,
  resetLoadingState,
  resetModel,
  loadLabelClassPredictionModelStart,
  loadLabelClassPredictionModelSuccess,
  loadLabelClassPredictionSuccess,
  loadLabelClassPredictionFailure,
  loadLabelClassPredictionStart,
  shouldPerformPrediction,
  updateLabelClassPredictionModelShowDot,
} from './labelClassPrediction.slice';
import { resetProject } from '../../imageView.actions';
import { labelClassesMapSelector } from '../../../../project/annotationTaxonomy/labelClasses/labelClasses.selectors';
import { ImageLabel } from '../../../../../../api/domainModels/imageLabel';
import { updateLabels } from '../../labels/labels.slice';
import { ImageTool, ImageToolTitleMap } from '../tools.constants';
import { enqueueNotification } from '../../../../ui/stackNotifications/stackNotifications.slice';
import { Polygon } from '../../../../../../@types/imageView/types';

// todo @V2Debt type API
export function* loadPredictionClass(params: Partial<ImageLabel>) {
  const projectId = yield* select(activeProjectIdSelector);
  const imageId = yield* select(imageViewImageIdSelector);
  const modelId = yield* select(labelClassPredictionModelIdSelector);
  yield* put(loadLabelClassPredictionStart());

  const keypointsCoordinatesData: Polygon | null =
    params.keypoints?.map((keypoint) => [keypoint.x, keypoint.y]) || null;

  try {
    const response = yield* call(
      apiLoadClassPrediction,
      projectId,
      imageId,
      modelId,
      params,
      keypointsCoordinatesData,
    );

    yield* put(loadLabelClassPredictionSuccess());

    return response.data;
  } catch (error) {
    const message = 'Not able to get label class prediction';
    const errorMessage = getErrorMessage(error, message);

    yield* put(loadLabelClassPredictionFailure(errorMessage));
    yield* put(handleError({ message: errorMessage, error }));

    return false;
  }
}

function* labelClassPredictionModelLoadedHandler(
  action: PayloadAction<ModelChangePayload>,
) {
  const { status, progress, id } = action.payload;

  yield* call(modelLoadedHandler, {
    id,
    modelIdSelector: labelClassPredictionModelIdSelector,
    modelLoadedSelector: labelClassPredictionModelLoadedSelector,
    progress,
    status,
    updateAction: updateLabelClassPredictionModel,
    updateModelIndicator: updateLabelClassPredictionModelShowDot,
  });
}
function* labelClassPredictionModelUpdatedHandler(
  action: PayloadAction<ModelChangePayload>,
) {
  const { status, progress, id } = action.payload;

  yield* put(
    updateLabelClassPredictionModel({
      status,
      progress,
      id,
    }),
  );
}
function* updateLabelClassPredictionModelHandler(
  action: ActionType<typeof updateLabelClassPredictionModel>,
) {
  const { status, progress, id } = action.payload;

  yield* call(updateModelHandler, {
    id,
    loadAction: loadLabelClassPredictionModel,
    progress,
    status,
    successAction: updateLabelClassPredictionModelSuccess,
  });
}
function* loadLabelClassPredictionModelHandler() {
  const projectId = yield* select(activeProjectIdSelector);

  yield* call(loadModelHandler, {
    projectId,
    loadApiCall: apiLoadClassificationModel,
    messages: MODEL_MESSAGES,
    toolId: ImageTool.LabelClassification,
    modelLoadStartAction: loadLabelClassPredictionModelStart,
    modelLoadSuccessAction: loadLabelClassPredictionModelSuccess,
    modelLoadErrorAction: loadLabelClassPredictionModelFailure,
    toolName: ImageToolTitleMap[ImageTool.LabelClassification],
  });
}

function* resetProjectHandler() {
  yield* put(resetLoadingState());
  yield* put(resetModel());
}

type UpdateForClassPrediction = { id: string; classId: string };
function* getUpdateForClassPrediction(
  label: Partial<ImageLabel> & { id: string },
): Generator<Effect, UpdateForClassPrediction | null> {
  // todo @RA type
  const prediction = yield* call(loadPredictionClass, label);

  if (prediction && label.classId !== prediction.classId) {
    return {
      id: label.id,
      classId: prediction.classId,
    };
  }

  return null;
}

function* shouldPerformPredictionHandler(
  action: ActionType<typeof shouldPerformPrediction>,
) {
  const projectId = yield* select(activeProjectIdSelector);
  const isPredictionRequired = yield* select((state: RootState) =>
    shouldPredictLabelClassesSelector(state, projectId),
  );
  const generateNotificationMessage = (labelClass: string) =>
    `Updated label with class ${labelClass}.`;

  if (isPredictionRequired) {
    const mergedData = yield* all(
      action.payload.map(getUpdateForClassPrediction),
    );
    const data = mergedData.filter(
      (datum): datum is UpdateForClassPrediction => datum !== null,
    );

    if (data.length > 0) {
      const labelClassesMap = yield* select(labelClassesMapSelector);

      yield* put(
        updateLabels(
          data.map((datum) => ({
            id: datum.id,
            changes: {
              classId: datum.classId,
            },
          })),
        ),
      );

      yield* all(
        data.map((item) => {
          const message = generateNotificationMessage(
            labelClassesMap[item.classId]?.name || '<Unknown class>',
          );

          return put(
            enqueueNotification({
              message,
              options: {
                variant: 'info',
              },
            }),
          );
        }),
      );
    }
  }
}

export function* labelClassPredictionSaga() {
  yield* takeEvery(
    createModelChangePattern(LABEL_CLASS_PREDICTION_FAMILY_NAME, MODEL_LOADED),
    labelClassPredictionModelLoadedHandler,
  );
  yield* takeEvery(
    createModelChangePattern(LABEL_CLASS_PREDICTION_FAMILY_NAME, MODEL_UPDATED),
    labelClassPredictionModelUpdatedHandler,
  );
  yield* takeEvery(
    updateLabelClassPredictionModel,
    updateLabelClassPredictionModelHandler,
  );
  yield* takeEvery(
    loadLabelClassPredictionModel,
    loadLabelClassPredictionModelHandler,
  );
  yield* takeEvery(shouldPerformPrediction, shouldPerformPredictionHandler);
  yield* takeEvery(resetProject, resetProjectHandler);
}
