import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { setEditedProjectId } from '../../../../sections/editedProject/project/project.slice';

type PaginationState = {
  page: number;
  itemsPerPage: number;
  total: number;
};
const initialState: PaginationState = {
  page: 1,
  itemsPerPage: 20,
  total: 0,
};

const { actions, reducer: experimentsPaginationReducer } = createSlice({
  name: 'experimentsPagination',
  initialState,
  reducers: {
    setExperimentsPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setExperimentsTotal(state, action: PayloadAction<number>) {
      state.total = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setEditedProjectId, () => initialState);
  },
});

export { experimentsPaginationReducer };
export const { setExperimentsPage, setExperimentsTotal } = actions;
