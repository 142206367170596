import { createSelector } from '@reduxjs/toolkit';
import groupBy from 'lodash/groupBy';

import { activeTagsAdapter } from './imageTags.slice';
import { sortObjectsAlphaNumeric } from '../../imageView.util';
import { statusChecks } from '../../../../../../constants/status';
import {
  tagClassesSelector,
  tagClassesListLoadingStateSelector,
  tagClassesMapSelector,
} from '../../../../project/annotationTaxonomy/tagClasses/tagClasses.selectors';
import { TagClass } from '../../../../../../api/domainModels/tagClass';
import { ImageTag } from '../../../../../../api/domainModels/imageTag';
import {
  DEFAULT_GROUP_ID,
  DEFAULT_GROUP_NAME,
} from '../../../../../../api/domainModels/tagGroup';
import { imageViewSelector } from '../../imageView.selectors';

const imageTagsSelector = createSelector(
  imageViewSelector,
  (imageView) => imageView.project.imageTags,
);

const activeTagsSelector = createSelector(
  imageTagsSelector,
  (imageTags) => imageTags.activeTags,
);
const activeTagsStateSelectors =
  activeTagsAdapter.getSelectors(activeTagsSelector);
const activeTagsSelectors = activeTagsStateSelectors.selectAll;

export const imageViewTagClassesSelector = createSelector(
  tagClassesSelector,
  (tagClasses) => sortObjectsAlphaNumeric<TagClass>('name', tagClasses),
);

export const imageViewActiveTagsLoadingStateSelector = createSelector(
  imageTagsSelector,
  (imageTags) => imageTags.listLoadingState,
);

export const imageViewActiveTagsItemLoadingStateSelector = createSelector(
  imageTagsSelector,
  (imageTags) => imageTags.itemLoadingState,
);

export const imageViewActiveTagsSelector = createSelector(
  [activeTagsSelectors],
  (activeTagClasses) =>
    sortObjectsAlphaNumeric<ImageTag>('name', activeTagClasses),
);

export const imageViewGroupedActiveTagsSelector = createSelector(
  [imageViewActiveTagsSelector, tagClassesMapSelector],
  (activeTags, tagClassesMap) => {
    const activeWithGroup = activeTags.map(({ id, classId, name }) => {
      const tagClass = tagClassesMap[classId];

      return {
        id: classId,
        tagId: id,
        name,
        groupId: tagClass?.groupId || DEFAULT_GROUP_ID, // fallback for ts satisfaction
        groupName: tagClass?.groupName || DEFAULT_GROUP_NAME, // fallback for ts satisfaction
      };
    });

    return groupBy(activeWithGroup, 'groupId');
  },
);

export const imageViewTagClassesAsOptionsEnrichedSelector = createSelector(
  [tagClassesListLoadingStateSelector, imageViewTagClassesSelector],
  (loadingState, tagClasses) => {
    if (statusChecks.isSuccess(loadingState.status)) {
      return tagClasses.map(({ id, name }) => ({
        key: id,
        text: name,
        value: id,
      }));
    }

    return [];
  },
);

export const imageViewTagClassesAsOptionsWithAllSelector = createSelector(
  [imageViewTagClassesAsOptionsEnrichedSelector],
  (options) => [
    {
      text: 'All',
      key: 'all',
      value: null,
    },
    ...options,
  ],
);
