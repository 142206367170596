import { call, put, select, takeEvery } from 'typed-redux-saga';
import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';

import {
  apiLoadImage,
  apiImageOffset,
} from '../../../../../api/requests/projectImages';
import {
  loadGalleryImagesError,
  clearGallery,
  requestGalleryImageLoad,
  invalidateGallery,
} from './imageGalleryItems/imageGalleryItems.slice';
import { setFilters } from './bottomBar/filters/filters.slice';
import { imageViewImageIdSelector } from '../currentImage/currentImage.selectors';
import { IMAGE_UPDATED } from '../../../ws/ws.constants';
import { imageGalleryItemsDataSelector } from './imageGalleryItems/imageGalleryItems.selectors';
import {
  loadImageError,
  loadImageSuccess,
  setImageOffset,
  setImageStatusSuccess,
} from '../currentImage/currentImage.slice';
import {
  imageViewImageGalleryFiltersDatasetSelector,
  imageViewImageGalleryFiltersStatusSelector,
  imageViewImageGalleryFiltersSearchSelector,
  imageViewImageGalleryFiltersTagsSelector,
  imageViewImageGalleryFiltersCSUserActionSelector,
  imageViewImageGalleryFiltersCSRunIdSelector,
  imageViewImageGalleryFiltersAldiSessionIdSelector,
} from './bottomBar/filters/filters.selectors';
import { activeProjectIdSelector } from '../../../project/project.selectors';
import { selectImage } from './imageGallery.actions';
import { labelSyncPreventsNavigationSelector } from '../labels/labelSync/labelSync.selectors';
import { IMAGE_VIEW_PATHNAME_SEGMENT } from '../imageView.util';
import { ImageStatus } from '../../../../../api/domainModels/imageStatus';
import { enqueueNotification } from '../../../ui/stackNotifications/stackNotifications.slice';
import { getErrorMessage } from '../../../../../api/utils';
import { EfUserAction } from '../../../../../constants/consensusScoring';
import { routerSelector } from '../../../root.selectors';

export function* checkIsImageValid(projectId: string, imageId: string) {
  try {
    yield* call(apiLoadImage, projectId, imageId);
  } catch (error) {
    const message = getErrorMessage(error, 'The image failed to load');

    yield* put(loadGalleryImagesError(message));
    yield* put(
      enqueueNotification({
        message,
        options: {
          variant: 'error',
        },
      }),
    );

    return false;
  }

  return true;
}

export function* retrieveImageOffset() {
  const imageId = yield* select(imageViewImageIdSelector);
  const projectId = yield* select(activeProjectIdSelector);
  const datasetId = yield* select(imageViewImageGalleryFiltersDatasetSelector);
  const imageStatus = yield* select(imageViewImageGalleryFiltersStatusSelector);
  const imageTags = yield* select(imageViewImageGalleryFiltersTagsSelector);
  const searchTerm = yield* select(imageViewImageGalleryFiltersSearchSelector);
  const csUserAction = yield* select(
    imageViewImageGalleryFiltersCSUserActionSelector,
  );
  const aldiSessionId = yield* select(
    imageViewImageGalleryFiltersAldiSessionIdSelector,
  );
  const csEfRunId = yield* select(imageViewImageGalleryFiltersCSRunIdSelector);

  if (!imageId) return null;

  try {
    const response = yield* call(
      apiImageOffset,
      projectId,
      imageId,
      datasetId,
      imageStatus,
      imageTags,
      searchTerm,
      csEfRunId,
      csUserAction as EfUserAction,
      aldiSessionId,
    );
    const { data } = response;

    return data.offset;
  } catch (e) {
    return null;
  }
}

function* setFiltersHandler() {
  yield* put(clearGallery());
}

function* imageUpdatedWebsocketHandler(
  action: PayloadAction<{
    projectId: string;
    id: string;
    status: ImageStatus;
  }>,
) {
  const images = yield* select(imageGalleryItemsDataSelector);
  const { id: imageId, status } = action.payload;

  // only proceed if the gallery contains this image
  // eslint-disable-next-line no-useless-return
  if (!images) return;

  const image = Object.values(images).find((image) => image.id === imageId);

  if (image?.imageStatus !== status) {
    yield* put(
      setImageStatusSuccess({
        imageId,
        status,
      }),
    );
  }
}

function* loadImageHandler() {
  // if the image has been loaded, the gallery items at current offset need to be updated
  const imageId = yield* select(imageViewImageIdSelector);
  const offset = yield* retrieveImageOffset();

  if (typeof offset !== 'number') {
    yield* put(clearGallery());

    return;
  }
  yield* put(setImageOffset(offset));

  const gallery = yield* select(imageGalleryItemsDataSelector);

  if (!(gallery && gallery[offset])) {
    yield* put(requestGalleryImageLoad(offset));

    return;
  }

  // sanity check – if offsets match
  if (gallery[offset]) {
    if (gallery[offset].id !== imageId) {
      yield* put(invalidateGallery());
    }
  }
}

function* selectImageHandler(action: ActionType<typeof selectImage>) {
  const {
    location: { search },
  } = yield* select(routerSelector);

  const cannotLeave: boolean = yield* select(
    labelSyncPreventsNavigationSelector,
  );

  if (cannotLeave) {
    return;
  }

  const projectId = yield* select(activeProjectIdSelector);

  yield* put(
    push(
      `/projects/${projectId}/${IMAGE_VIEW_PATHNAME_SEGMENT}/${action.payload}${search}`,
    ),
  );
}

export function* imageGallerySaga() {
  yield* takeEvery(setFilters, setFiltersHandler);
  yield* takeEvery(IMAGE_UPDATED, imageUpdatedWebsocketHandler);
  yield* takeEvery([loadImageSuccess, loadImageError], loadImageHandler);
  yield* takeEvery(selectImage, selectImageHandler);
}
