// @ts-expect-error
import downstreamPb from 'hasty-protos/websockets/downstream_pb';

const { NotificationEvent } = downstreamPb;

export const PROJECT_BASICS = [
  NotificationEvent.PROJECT_UPDATED,
  NotificationEvent.JOB_PROGRESS,
  NotificationEvent.JOB_RUNNING,
  NotificationEvent.JOB_ENDED,
  NotificationEvent.JOB_FAILED,
];

export const PROJECT_IMAGE_BASICS = [
  ...PROJECT_BASICS,
  NotificationEvent.LABEL_CLASS_UPDATED,
  NotificationEvent.LABEL_CLASS_DELETED,
  NotificationEvent.DATASET_DELETED,
  NotificationEvent.ATTRIBUTE_UPDATED,
  NotificationEvent.ATTRIBUTE_DELETED,
  NotificationEvent.ATTRIBUTE_CLASS_UPDATED,
  NotificationEvent.ATTRIBUTE_CLASS_DELETED,
];

export const PROJECT_ERROR_FINDER = [
  ...PROJECT_IMAGE_BASICS,
  NotificationEvent.EF_RUN_FAILED,
  NotificationEvent.EF_RUN_TRAINING_STARTED,
  NotificationEvent.EF_RUN_TRAINING_PROGRESS,
  NotificationEvent.EF_RUN_TRAINED,
  NotificationEvent.EF_RUN_PREDICTING_STARTED,
  NotificationEvent.EF_RUN_PREDICTING_PROGRESS,
  NotificationEvent.EF_RUN_PREDICTED,
  NotificationEvent.LOCKS_UPDATED,
];

export const PROJECT_MODEL_PLAYGROUND_SPLIT = [
  ...PROJECT_IMAGE_BASICS,
  NotificationEvent.TRAINING_STATUS_UPDATE,
  NotificationEvent.TRAINING_METRICS_UPDATE,
  NotificationEvent.TRAINING_GPU_STATS_UPDATE,
  NotificationEvent.TRAINING_INFERENCES_UPDATE,
];

export const EDIT_IMAGE_PROJECT = [
  ...PROJECT_IMAGE_BASICS,
  ...PROJECT_ERROR_FINDER,
  NotificationEvent.DATASPLIT_CREATED, // double check if not user scope
  NotificationEvent.DATASPLIT_CREATION_FAILED, // double check if not user scope
  NotificationEvent.IMPORT_FILE_STATUS,
  NotificationEvent.IMPORT_SESSION_STATUS,
  NotificationEvent.ALDI_RANKING_STARTED,
  NotificationEvent.ALDI_RANKING_SUCCESSFUL,
  NotificationEvent.ALDI_RANKING_FAILED,
  NotificationEvent.ALDI_RANKING_PROGRESS,
  NotificationEvent.LOCKS_UPDATED,
];

export const EDIT_VIDEO_PROJECT = [
  ...PROJECT_BASICS,
  NotificationEvent.VIDEO_CUT_SUCCESSFUL,
  NotificationEvent.VIDEO_CUT_FAILED,
  NotificationEvent.VIDEO_UPDATED,
  NotificationEvent.VIDEO_PROGRESS,
  NotificationEvent.VIDEO_PROCESSED,
];

export const ANNOTATE_IMAGE_PROJECT = [
  ...PROJECT_IMAGE_BASICS,
  NotificationEvent.MODEL_LABELS_TO_START_TRAIN_PROGRESS,
  NotificationEvent.MODEL_TRAINING_STARTED,
  NotificationEvent.MODEL_TRAINING_PROGRESS,
  NotificationEvent.MODEL_TRAINED,
  NotificationEvent.MODEL_LOADING_STARTED,
  NotificationEvent.MODEL_LOADED,
  NotificationEvent.MODEL_LOADING_FAILED,
  NotificationEvent.MODEL_TRAINING_FAILED,
  NotificationEvent.TAG_CLASS_ADDED,
  NotificationEvent.TAG_CLASS_DELETED,
  NotificationEvent.TAG_CLASS_MODIFIED,
  NotificationEvent.LOCKS_UPDATED,
  NotificationEvent.ALDI_RANKING_STARTED,
  NotificationEvent.ALDI_RANKING_SUCCESSFUL,
  NotificationEvent.ALDI_RANKING_FAILED,
  NotificationEvent.ALDI_RANKING_PROGRESS,
];

export const ANNOTATE_VIDEO_PROJECT = [
  ...PROJECT_BASICS,
  NotificationEvent.MODEL_LABELS_TO_START_TRAIN_PROGRESS,
  NotificationEvent.MODEL_TRAINING_STARTED,
  NotificationEvent.MODEL_TRAINING_PROGRESS,
  NotificationEvent.MODEL_TRAINED,
  NotificationEvent.MODEL_LOADING_STARTED,
  NotificationEvent.MODEL_LOADED,
  NotificationEvent.MODEL_LOADING_FAILED,
  NotificationEvent.MODEL_TRAINING_FAILED,
  NotificationEvent.MODEL_TRAINING_QUEUED,
  NotificationEvent.VIDEO_UPDATED,
  NotificationEvent.VIDEO_PROGRESS,
  NotificationEvent.VIDEO_PROCESSED,
  NotificationEvent.VIDEO_THUMBNAIL_GENERATION_COMPLETED,
  NotificationEvent.VIDEO_STATUS_UPDATED,
  NotificationEvent.VIDEO_ACTIVITY_PROGRESS,
  NotificationEvent.VIDEO_ACTIVITY_PAUSED,
  NotificationEvent.VIDEO_ACTIVITY_COMPLETED,
  NotificationEvent.VIDEO_ACTIVITY_FAILED,
  NotificationEvent.VIDEO_ACTIVITY_PREDICTED,
  NotificationEvent.LOCKS_UPDATED,
];
