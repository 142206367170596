import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  classVisible: false,
  attributesVisible: false,
  filled: false,
  visible: true,
  hoveredPotentialObjectClassId: null as string | null,
};
const { actions, reducer: annotationsVisualStateReducer } = createSlice({
  name: 'imageViewAnnotationsVisualState',
  initialState,
  reducers: {
    fillAnnotations(state) {
      state.filled = true;
    },
    clarifyAnnotations(state) {
      state.filled = false;
    },
    hideAnnotations(state) {
      state.visible = false;
    },
    showAnnotations(state) {
      state.visible = true;
    },
    hideClassName(state) {
      state.classVisible = false;
    },
    showClassName(state) {
      state.classVisible = true;
    },
    hideAttributesName(state) {
      state.attributesVisible = false;
    },
    showAttributesName(state) {
      state.attributesVisible = true;
    },
    setHoveredPotentialObjectClassId(
      state,
      action: PayloadAction<{ classId: string }>,
    ) {
      state.hoveredPotentialObjectClassId = action.payload.classId;
    },
    resetHoveredPotentialObjectClassId(state) {
      state.hoveredPotentialObjectClassId = null;
    },
  },
});

export const {
  clarifyAnnotations,
  fillAnnotations,
  hideAnnotations,
  showAnnotations,
  hideClassName,
  showClassName,
  hideAttributesName,
  showAttributesName,
  setHoveredPotentialObjectClassId,
  resetHoveredPotentialObjectClassId,
} = actions;
export { annotationsVisualStateReducer };
