import clamp from 'lodash/clamp';

import { Bbox } from '../@types/imageView/types';

export const getCenterPoint = ([x1, y1, x2, y2]: [
  number,
  number,
  number,
  number,
]) => [(x1 + x2) / 2, (y1 + y2) / 2];

export const isPointInBounds = (
  [x, y]: [number, number],
  [x1, y1, x2, y2]: Bbox,
) => x >= x1 && x <= x2 && y >= y1 && y <= y2;

export const getWidth = ([x1, , x2]: Bbox) => x2 - x1;

export const getHeight = ([, y1, , y2]: Bbox) => y2 - y1;

export const getDimensions = ([x1, y1, x2, y2]: [
  number,
  number,
  number,
  number,
]) => [x2 - x1, y2 - y1];

export const constrainPoint = (
  [x, y]: [number, number],
  [x1, y1, x2, y2]: Bbox,
) => [clamp(x, x1, x2), clamp(y, y1, y2)];

export const normalizeBbox = ([x1, y1, x2, y2]: [
  number,
  number,
  number,
  number,
]) =>
  [Math.min(x1, x2), Math.min(y1, y2), Math.max(x1, x2), Math.max(y1, y2)].map(
    (value) => (value < 0 ? 0 : value),
  );
